import React from 'react';

import lang from '../../../../../../../lib/language';

import styles from './PersonalContactDetails.module.scss';

type Props = {
    email: string;
    phoneNumber: string;
}

const PersonalContactDetails = ({ email, phoneNumber }: Props) => {
  const contactDetails = {
    [lang.commonOnboardingSignInEmail()]: email,
    [lang.commonOnboardingPhoneVerificationPhoneNumber()]: `${phoneNumber}`,
  };

  return (
    <div className={styles.wrapperContactDetails}>
      <div className={styles.detailsHeader}>
        {lang.commonContactDetails()}
      </div>
      <div className={styles.containerDetails}>
        <div className={styles.card}>
          {
            Object.entries(contactDetails).map(([key, value]) => (
              <div className={styles.cardContent} key={key}>
                <span className={styles.cardKey}>{key}</span>
                <span className={styles.cardValue}>{value}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default PersonalContactDetails;
