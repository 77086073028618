import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus, LinkedAccountStatusEnum, PaymentMethodEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { RootState } from '../../lib/store';
import { refreshPayments } from '../../lib/store/common-actions';
import { closeCardTransferStatus, getCardTransferStatus, getOwnerTradingAccounts, setPaymentStatusUIState } from '../../lib/store/payment';
import { PaymentCall, RefreshPaymentsPayload } from '../../lib/store/payment/types';
import { isUserForVerificationStatus, isUserVerifiedStatus } from '../../lib/store/selectors';
import { formatCurrency, formatNumber } from '../../lib/util/DataHelpers';
import { isCallStatusPendingOrBigger, isCallStatusUIDone } from '../../lib/util/error-handling/StatusByCallHelpers';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import { BaseModal, Button, Preloader } from '../../components';
import { ButtonTypesEnum } from '../../components/Button/Button.types';
import { useBrowserWidth, useIsMobile } from '../../hooks';
import { MAX_NUMBER_REQUESTS } from '../../util/constants';
import { UI_ROUTES } from '../../util/routes';

import CardTransferModal, { Props as CardTransferModalProps } from './modal/cardTransfer/CardTransferModal';
import DepositSlipModal from './modal/depositSlipModal/DepositSlipModal';
import NoVerifiedUserModal from './modal/noActivatedUserModal/NoActivatedUserModal';
import BasicModal from './modal/paymentsModal/BasicModal';
import Transactions from './transactions/Transactions';
import { XL_BREAKPOINT_WIDTH } from './constants';
import LinkedAccountCard from './LinkedAccountCard';
import LinkedAccountSlideCard from './LinkedAccountSlideCard';

import styles from './Funds.module.scss';

type Props = {} & ReduxProps;

const Funds = (props: Props) => {
  const {
    transactions,
    lastTransaction,
    linkedAccounts,
    calculatedCash,
    webLastCardTransfer,
    cardTransferSuccessData,
    cardTransferFailData,
    individual,
    statusByCall,
    callsForRefresh,
    isPaymentsReady,
    isPaymentsAvailableCashReady,
    tradingAccounts,
    refreshPaymentsData,
    setPaymentUIDone,
    getCardDepositStatus,
    closeCardTransfer,
    customRefreshPayments,
    getOwnerTradingAccountsData,
  } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();

  const { browserWidthSize } = useBrowserWidth();

  const isUserVerified = isUserVerifiedStatus(individual);
  const isUserInPendingVerification = isUserForVerificationStatus(individual);

  const locationState: any = history.location.state;
  const isCashLoaded = isCallStatusUIDone(PaymentCall.cashCalculated, statusByCall);

  const [bankAccountStatus, setBankAccountStatus] = useState(
    {
      values: {
        hasLinkedAccounts: true,
        isPendingLinkedAccounts: false,
        bankAccountStatusTitle: lang.mobileFundsScreenModalTitle(),
        bankAccountStatusMessage: '',
      },
    },
  );
  const [counterRequest, setCounterRequest] = useState<number>(MAX_NUMBER_REQUESTS);
  const [isLoadedWalletPageFromDskPage, setIsLoadedWalletPageFromDskPage] = useState<boolean>(false);
  const [showModalPayments, setShowModalPayments] = useState<boolean>(false);
  const [showModalDepositSlip, setShowModalDepositSlip] = useState<boolean>(false);
  const [cardTransferModalData, setCardTransferModalData] = useState<CardTransferModalProps>({
    cardPaymentStatus: 0,
  } as any);

  const handleModalPayments = () => {
    setShowModalPayments(!showModalPayments);
  };

  const handleConfirmDepositSlipModal = () => {
    setShowModalDepositSlip(false);
  };

  const handleBtnFund = () => {
    const isLastCardPaymentMethod = lastTransaction?.payment_method === PaymentMethodEnum.Card;

    history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.enterAmount,
      state: {
        target: lang.mobileBankDialogTitle(),
        isLastCardPaymentMethod },
    });
  };

  const handleBtnWithdraw = () => {
    if (linkedAccounts.length === 0) {
      setBankAccountStatus(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          hasLinkedAccounts: false,
          isPendingLinkedAccounts: false,
          bankAccountStatusMessage: lang.mobileFundsScreenAddBankAccModal(),
        },
      }));
      setShowModalPayments(true);
      return;
    }
    const hasActiveLinkedAccounts = linkedAccounts.find(linkedAccount =>
      linkedAccount.status === LinkedAccountStatusEnum.Active);

    if (!hasActiveLinkedAccounts) {
      setBankAccountStatus(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          hasLinkedAccounts: false,
          isPendingLinkedAccounts: true,
          bankAccountStatusMessage: lang.mobileFundsScreenBankAccPendingModal(),
        },
      }));
      setShowModalPayments(true);
    } else {
      history.push({
        pathname: UI_ROUTES.funds + UI_ROUTES.withdrawalAmount,
        state: {
          previousPage: UI_ROUTES.funds },
      });
    }
  };

  if (location.search.length > 0 && !isLoadedWalletPageFromDskPage) {
    setIsLoadedWalletPageFromDskPage(true);
  }

  useEffect(() => {
    manageSubscriptions('funds', null, false, true);
  }, []);

  useEffect(() => {
    if (locationState?.target === lang.mobileDepositSlipDialogTitle()) {
      setShowModalDepositSlip(true);
      history.replace({ ...history.location, state: '' });
    }
  }, [locationState, setShowModalDepositSlip, history]);

  useEffect(() => {
    const lastPaymentStatus = cardTransferSuccessData?.status;

    if (cardTransferModalData.cardPaymentStatus !== 0) return;

    if (lastPaymentStatus) {
      const amount = webLastCardTransfer?.amount;
      setCardTransferModalData({
        cardPaymentStatus: lastPaymentStatus,
        amount,
      });
      closeCardTransfer();
    }
  }, [
    history,
    webLastCardTransfer,
    transactions,
    setPaymentUIDone,
    cardTransferSuccessData,
    cardTransferFailData,
    closeCardTransfer,
    cardTransferModalData.cardPaymentStatus,
  ]);

  useEffect(() => {
    if (
      isUserVerified
      && location?.search?.includes('orderId=')
      && !isCallStatusPendingOrBigger(PaymentCall.getCardTransferStatus, statusByCall)
    ) {
      getCardDepositStatus();
      history.push(UI_ROUTES.funds);
    }
  }, [getCardDepositStatus, isUserVerified, location?.search, statusByCall, setPaymentUIDone, history]);

  useEffect(() => {
    if (callsForRefresh) {
      refreshPaymentsData();
    }
  }, [callsForRefresh, refreshPaymentsData]);

  useEffect(() => {
    if (isUserVerified) {
      customRefreshPayments({
        [PaymentCall.getPaymentsAll]: true,
        [PaymentCall.getAvailableCash]: true,
      });
    }
  }, [customRefreshPayments, isUserVerified]);

  useEffect(() => {
    if (isUserVerified && !tradingAccounts.length && counterRequest > 0) {
      getOwnerTradingAccountsData();
      setTimeout(() => { setCounterRequest(requestNum => requestNum - 1); }, 1000);
    } else if (!tradingAccounts.length && !counterRequest) {
      history.push({
        pathname: UI_ROUTES.error,
        state: { target: location.pathname, isErrorTradingAccounts: true, isLoadedWalletPageFromDskPage },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterRequest]);

  const handleAvailableCash = () => {
    if (!isUserVerified) {
      return <h4 id="fundsAvailableCash" className={styles.statusMoney}>{formatCurrency(0)}</h4>;
    } if ((!isUserVerified || isCashLoaded) && isPaymentsAvailableCashReady) {
      return <h4 id="fundsAvailableCash" className={styles.statusMoney}>{formatCurrency(calculatedCash ?? 0)}</h4>;
    }

    return <Preloader owner="Funds" text={''} />;
  };

  return (
    <>
      {(!tradingAccounts.length && counterRequest <= MAX_NUMBER_REQUESTS)
        && <Preloader owner="Funds" text="" isFullScreen stylePreset="customOne" />}
      <div className={styles.wrapperLayout}>
        <div className={styles.wrapperFirstSection}>
          {!isMobile && (<h6 id="fundsTitle" className={styles.title}>{lang.fundsScreenTitle()}</h6>)}
          <span className={styles.availableCashText}>{lang.mobileFundsScreenAvailableCash()}</span>
          <div className={styles.availableCash}>
            {handleAvailableCash()}
          </div>
          <div className={styles.wrapperButtons}>
            <Button
              id="fundButton"
              className={styles.btnFund}
              onClick={handleBtnFund}
            >
              {lang.fundsScreenFundBtn()}
            </Button>
            <Button
              variant={ButtonTypesEnum.Tertiary}
              id="withdrawButton"
              className={(
                !isCashLoaded
                || (Number(formatNumber(calculatedCash)) === 0)
                || !isUserVerified)
                ? styles.inactiveBntWithdraw
                : styles.activeBntWithdraw}
              onClick={handleBtnWithdraw}
            >
              {lang.mobileFundsScreenWithdrawBtn()}
            </Button>
          </div>
          {
            browserWidthSize < XL_BREAKPOINT_WIDTH
             && <LinkedAccountSlideCard isUserVerified={isUserVerified} linkedAccounts={linkedAccounts} />
          }
          {
            isPaymentsReady && <Transactions isUserVerified={isUserVerified} browserWidthSize={browserWidthSize} />
          }
        </div>
        {
          browserWidthSize >= XL_BREAKPOINT_WIDTH
          && <LinkedAccountCard isUserVerified={isUserVerified} linkedAccounts={linkedAccounts} />
        }
      </div>
      <BaseModal
        isOpen={showModalPayments
        || showModalDepositSlip
        || !isUserVerified}
        className={!isUserVerified ? styles.blurBackground : ''}
      >
        { showModalPayments && <BasicModal handleModal={handleModalPayments} infoModal={bankAccountStatus} />}
        { showModalDepositSlip && <DepositSlipModal handleModal={handleConfirmDepositSlipModal} />}
        {!isUserVerified && (
          <NoVerifiedUserModal isPendingVerification={isUserInPendingVerification} />
        )}
      </BaseModal>
      { cardTransferModalData.cardPaymentStatus > 0 && <CardTransferModal {...cardTransferModalData} /> }
      { isMobile && <div className={styles.mobileBottom} /> }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  transactions: state.payment.paymentsAll.data,
  lastTransaction: state.payment.lastUserTransaction,
  linkedAccounts: state.payment.linkedAccounts,
  calculatedCash: state.payment.calculatedCash,
  webLastCardTransfer: state.payment.webLastCardTransfer,
  cardTransferSuccessData: state.payment.lastCardTransfer.statusCheckData,
  cardTransferFailData: state.payment.lastCardTransfer.transferFailedData,
  individual: state.crm.individualExtendedInfo,
  callsForRefresh: state.payment.callsForRefresh,
  statusByCall: state.payment.statusByCall,
  tradingAccounts: state.payment.tradingAccounts,
  isPaymentsReady: state.payment.statusByCall[PaymentCall.getPaymentsAll]?.status === CallStatus.READY,
  isPaymentsAvailableCashReady:
    state.payment.statusByCall[PaymentCall.getAvailableCash]?.status === CallStatus.READY,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  closeCardTransfer: () => dispatch(closeCardTransferStatus()),
  getCardDepositStatus: () => dispatch(getCardTransferStatus()),
  customRefreshPayments: (data: RefreshPaymentsPayload) => dispatch(refreshPayments(data)),
  refreshPaymentsData: () => dispatch(refreshPayments()),
  setPaymentUIDone: (call: PaymentCall, isDone: boolean = true) => dispatch(setPaymentStatusUIState({ call, isDone })),
  getOwnerTradingAccountsData: () => dispatch(getOwnerTradingAccounts()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Funds);
