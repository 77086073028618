import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { Action } from 'redux';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import {
  clearSignupToken as clearSignupTokenCall,
  getSignupToken as getSignupTokenCall,
} from '../../../../lib/store/auth';

import puzzleImage from '../../../../assets/bigImages/Puzzle_effects_illustration@2x.png';
import { Button } from '../../../../components';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import config from '../../../../config';
import useIsMobile from '../../../../hooks/useIsMobile';

import styles from './NoActivatedUserModal.module.scss';

type Props = {
    isPendingVerification: boolean;
} & ReduxProps;

const NoActivatedUserModal = (props: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { isPendingVerification, isFetchingPWPSignupURL, PWPSignupURL, clearSignupToken, getSignupToken } = props;
  useEffect(() => {
    if (!isFetchingPWPSignupURL && PWPSignupURL) {
      window.open(PWPSignupURL, isMobile ? '_self' : '_blank');
      clearSignupToken();// removing the signup token URL from the state to avoid the re-redirection on the next render time
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingPWPSignupURL, PWPSignupURL, isMobile]);

  const handleOnProceedClick = (): void => {
    if (isFetchingPWPSignupURL) {
      return;
    }
    getSignupToken();
  };

  const renderTitle = (): JSX.Element => (
    <h6
      id="notActivatedUserFundTitle"
      className={classNames(styles.title, { [styles.centerAligned]: isMobile })}
    >
      {isPendingVerification ? lang.walletAtivationModalContent() : lang.commonNotActivatetFundsPopUpText()}
    </h6>
  );

  return (
    <>
      {!isMobile && renderTitle()}
      <img src={puzzleImage} id="notActivatedUserImage" className={styles.img} alt="puzzle" />
      {isMobile && renderTitle()}
      <div className={styles.buttonWrapper}>
        <Button
          id="notActivatedUserGoBack"
          variant={ButtonTypesEnum.Tertiary}
          fullWidth
          className={styles.btnGoBack}
          onClick={() => history.goBack()}
        >
          {lang.commonGoBackBtn()}
        </Button>
        {!isPendingVerification && (
        <Button
          id="notActivatedUserContinue"
          fullWidth
          onClick={handleOnProceedClick}
        >
          {lang.commonContinueButton()}
        </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetchingPWPSignupURL: state.auth.isFetchingPWPSignupURL,
  PWPSignupURL: state.auth.PWPSignupURL,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSignupToken: () => dispatch(getSignupTokenCall()),
  clearSignupToken: () => dispatch(clearSignupTokenCall()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NoActivatedUserModal);
