import { GenericStatusByCall } from '../../enums/status-by-call';
import { IExecution } from '../../models/execution';
import {
  SORExtendedHoursMarker,
  SOROrderSide,
  SOROrderStatus,
  SOROrderTimeInForce,
  SOROrderType,
  TenNSOROrderStatus,
} from '../../models/gateway/types';
import { PeopleAlsoTradeItem, ReportingCall } from '../../models/reporting/types';
import { NullableNumber } from '../../util/types';
import { CommonPayloadWithCaller } from '../common-actions';
import { SubscribeGroup } from '../market-data/types';


export type GetMyAccountChartDataPayload = {
  accountReferenceId: string;
  fromDate: string;
  toDate: string;
};

export type NetIncomeComponentsChartData = {
  date: string;
  netEquity: number;
  pnlResult: number;
};

export type AccountSummaryPayloadData = {
  accountReferenceId: string;
};

export type OrderDetailPayloadData = AccountSummaryPayloadData & {
  parentOrderId: string;
};

export type OpenPositionsPayloadData = {
  accountReferenceId?: string;
  symbol?: string,
  positionType?: string,
  group?: SubscribeGroup
} | undefined;

export type MyAccountChartDataResponse = {
  date: string;
  netEquity: number;
  pnlResult: number;
};

export type AccountSummaryResponseData = {
  accountReferenceId: string;
  currency: string;
  cashBalance: number;
  tradingCharges: number;
  accountCharges: number;
  realizedPnL: number;
  realTimeCash: number;
  hardBPFactorID: number;
  hardBPFactorON: number;
  netCash: number;
  retailCash: number;
};

export type OpenPosition = {
  accountReferenceId: string;
  symbol: string;
  currency: string;
  positionType: string;
  lastExecutionDateTime: string;
  quantity: number;
  averageOpeningPrice: number;
  cost: number;
};

export type ReportingOrder = {
  symbol: string;
  parentOrderId: string;
  totalQuantity: number;
  sorExecutedQuantity: number;
  aqxExecutedQuantity: number;
  averageFilledPrice: number;
  orderRequestedPrice: number;
  type: SOROrderType;
  side: SOROrderSide;
  orderDate: string;
  timeInForce: SOROrderTimeInForce;
  expirationDate: string;
};

export type OrderDetailResponse = ReportingOrder & {
  status: SOROrderStatus;
  stopPrice: number;
  fillDate: string;
  lastUpdatedDateTime: string;
  orderValue: number;
  grossRealizedPnl: number;
  regulatoryFees: number;
  tradingSessionId?: string;
  taf?: number;
  sec?: number;
};

export type AccountTradeHistoryPayloadData = {
  accountReferenceId?: string;
  fromDate?: string;
  toDate?: string;
  symbol?: string;
  status?: string;
  pageNumber?: number;
  rowsPerPage?: number;
  isPendingOrdersCall?: boolean;
  isSymbolDetails?: boolean;
  tenNStatus?: string;
} & CommonPayloadWithCaller;

export type AccountTradeHistoryResponseData = ReportingOrder & {
  pages: number;
  rowNumber: number;
  orderStopPrice: number;
  aqxLastExecutionDate: string;
  lastUpdatedDate: string;
  status: SOROrderStatus;
  tenNStatus: TenNSOROrderStatus;
  tradingSessionId: SORExtendedHoursMarker;
};

export enum PositionType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum TimeInForce {
  Day,
  GTC,
  AtOpening,
  IOC,
  FOK,
  ExtendedDay,
  GoodTillDate,
  AtClosing,
}

export enum NetComponent {
  NetIncome = 'netIncome',
  PnL = 'profitAndLoss',
}

export type MyStocksContent = {
  id: string;
  name: string;
  icon: string;
  buttons: string;
  quantity: number;
  marketPrice: string;
  positionType: string;
  purchasePrice: string;
  unrealizedProfit: string;
  unrealizedProfitNumber: NullableNumber;
  subtitle: string | JSX.Element;
};

export type PortfolioChartElement = {
  x?: number;
  y?: number;
  symbol: string;
};

export type TradeHistoryParsedOrders = {
  aqxLastExecutionDate: string;
  color: string;
  expirationDate: string;
  lastUpdatedDate: string;
  orderDate: string;
  orderValue: string;
  pages: number;
  parentOrderId: string;
  price: string | number;
  quantity: string | number;
  rowNumber: number;
  tenNParsedStatus: string;
  statusEnum: SOROrderStatus;
  sorExecutedQuantity: number;
  symbol: string;
  timeInForce: SOROrderTimeInForce;
  type: string;
};

type APIResponse<T> = {
  hasLoaded: boolean;
  data: T;
};

export interface ReportingState {
  tradeHistoryOrders: TradeHistoryParsedOrders[];
  tradeHistoryNextPageToRead: number;
  pendingOrders: AccountTradeHistoryResponseData[];
  loadingExecutions: boolean;
  executionsFailed: boolean;
  accountOpenPositionsCompleted: boolean;
  executionsResult: IExecution[];
  accountsAvailableCash: APIResponse<GetAccountsAvailableCashResponse>;
  netIncomeComponents: GetMyAccountChartDataResponse;
  openPositions: OpenPosition[];
  accountSummary: AccountSummaryResponseData;
  orderDetail: OrderDetailResponse;
  orderDetailCompleted: boolean;
  error: RequestError;
  peopleAlsoTradeData: PeopleAlsoTradeItem[];
  statusByCall: GenericStatusByCall<ReportingCall>;
  errorsLog: string[];
  hasMyStocksOrderUpdate: boolean;
}

export type AccountReference = {
  instaCash: number;
  availableCash: number;
};

export type GetAccountsAvailableCashRequest = { accountReferenceId: string }
export type GetAccountsAvailableCashResponse = AccountReference

export type GetMyAccountChartDataResponse = MyAccountChartDataResponse[];
export type GetOpenPositionsDataRequest = { symbol?: string, positionType?: string }
export type RequestError = {
  status: NullableNumber;
  hasRequestError: boolean;
};
