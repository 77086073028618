import React from 'react';

import { FileMimeTypeEnum } from '../../lib/enums';
import { createBase64DataUrl } from '../../lib/util/DataHelpers';

import { logoSources } from '../../util/customLogos';

import styles from './CompanyLogo.module.scss';

type Props = {
  logo: string;
  className?: string;
  symbol?: string;
}

const CompanyLogo = ({ logo, className, symbol }: Props): JSX.Element => {
  // TODO: Remove this after the task ART-3903 is done. This is a temporary solution.
  const customLogoSource = symbol ? logoSources[symbol] : null;
  if (customLogoSource) {
    return (
      <div>
        <img className={[styles.logo, className].join(' ')} src={customLogoSource} alt="symbol logo" />
      </div>
    );
  }

  return (
    <div
      className={[styles.logo, className].join(' ')}
      style={{ backgroundImage: `url(${createBase64DataUrl(logo, FileMimeTypeEnum.Jpeg)})` }}
    />
  );
};

export default CompanyLogo;
