import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import lang from '../../../lib/language';

import IconClose from '../../../assets/img/icon-close-hammer.svg';
import { ClickableImage } from '../../index';

import styles from './FavouritesModal.module.scss';

type Props = {
    isAddedToFavourites: boolean;
    isRemovedFromFavourites: boolean;
    symbol: string
    closeModal: () => void;
    isMobile?: boolean;
}

const FavouritesModal = ({ isAddedToFavourites, isRemovedFromFavourites, symbol, closeModal, isMobile } : Props) => {
  const favouritesRef = useRef<HTMLElement>() as React.RefObject<HTMLDivElement>;

  // close favourites modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (favouritesRef.current && !favouritesRef.current.contains(event.target as HTMLDivElement)) {
        closeModal();
      }
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div
      className={classNames(styles.addToFavouritesMsg, { [styles.mobileWrapper]: isMobile })}
      ref={favouritesRef}
      onBlur={() => closeModal()}
    >
      <div id="favouritesModalMessage">
        {isAddedToFavourites && lang.commonSymbolAddedToWatchlistMsg(symbol)}
        {isRemovedFromFavourites && lang.commonSymbolRemovedToWatchlistMsg(symbol)}
      </div>
      <ClickableImage
        className={styles.icon}
        alt='Close "Add to favourites modal"'
        onClick={() => closeModal()}
        src={IconClose}
      />
    </div>
  );
};

export default FavouritesModal;
