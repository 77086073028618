import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import lang from '../../lib/language';
import { DividendPayerData } from '../../lib/models/fundamental/types';
import { RootState } from '../../lib/store';

import { useIsMobile } from '../../hooks';
import { NoDataAvailable } from '..';

import Chart from './Chart/Chart';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import CompanyInfoMobile from './CompanyInfo/CompanyInfoMobile';

import styles from './DividendPyersAndHighGrowthCompanies.module.scss';

type OwnProps = {
  dividendPayerContent: DividendPayerData[]
}
type Props = OwnProps & ReduxProps;

const DividendPayers = ({ dividendPayerContent, companyLogoAndName }: Props) => {
  const isMobile = useIsMobile();
  const [dividend, setDividend] = useState(dividendPayerContent[0]);

  const onChange = useCallback((key) => {
    const filteredDividends = dividendPayerContent.find(el => el.symbol === key);
    if (filteredDividends) setDividend(filteredDividends);
  }, [dividendPayerContent]);

  if (!dividend) {
    return <div className={styles.noDataAvailable}><NoDataAvailable /></div>;
  }

  return (
    <>
      { isMobile ? (
        <div className={styles.wrapper}>
          <div className={styles.chartDetails}>
            <p className={styles.dotWrapper}>
              <span className={styles.activeDot} />
              {lang.commonDiscoverDividendPayersLegend()}
            </p>
            <Chart
              data={dividendPayerContent}
              yAxis={'dividendYield'}
              currentItemId={dividend.symbol}
              isHighGrowthCompany={false}
              onItemChange={onChange}
            />
          </div>
          <div className={styles.line} />
          <CompanyInfoMobile
            areDividends
            symbol={dividend.symbol}
            logo={companyLogoAndName[dividend.symbol].logo}
            companyName={companyLogoAndName[dividend.symbol].name}
            volume={dividend.volume}
            marketCap={dividend.marketCap}
            prevCloseAndEPS={dividend.close}
            dividendRateAndRevenue={dividend.dividendRate}
          />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <CompanyInfo
            areDividends
            symbol={dividend.symbol}
            logo={companyLogoAndName[dividend.symbol].logo}
            companyName={companyLogoAndName[dividend.symbol].name}
            volume={dividend.volume}
            marketCap={dividend.marketCap}
            prevCloseAndEPS={dividend.close}
            dividendRateAndRevenue={dividend.dividendRate}
          />
          <div className={styles.chartDetails}>
            <p className={styles.dotWrapper}>
              <span className={styles.activeDot} />
              {lang.commonDiscoverDividendPayersLegend()}
            </p>
            <Chart
              data={dividendPayerContent}
              yAxis={'dividendYield'}
              currentItemId={dividend.symbol}
              isHighGrowthCompany={false}
              onItemChange={onChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndName: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DividendPayers);
