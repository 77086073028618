export enum VerificationStatusEnum {
  Unspecified = 1,
  Pending,
  Accepted,
  Declined,
}

export enum VerificationStep {
  PHONE_VERIFICATION,
  POI,
  POA,
  TERMS,
  DONE
}
