import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import styles from './CheckboxControl.module.scss';

type Props = {
  label: string;
  id?: string;
  checked?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
  centered?: boolean;
  secondary?: boolean;
};

const CheckboxControl = (props: Props) => {
  const {
    label,
    id = '',
    onChange,
    className,
    ariaLabel = '',
    checked = false,
    centered = true,
    disabled = false,
    secondary = false,
  } = props;

  return (
    <div className={styles.controlWrapper}>
      <FormControlLabel
        label={label}
        className={[
          styles.checkboxWrapper,
          className,
          !centered && styles.topAligned,
          secondary && styles.secondary,
          disabled && styles.disabled,
        ].join(' ')}
        control={(
          <Checkbox
            inputProps={{
              id,
              'aria-label': ariaLabel,
            }}
            checked={checked}
            disabled={disabled}
            icon={<span className={styles.checkBox} />}
            checkedIcon={<span className={[styles.checkBox, styles.checked].join(' ')} />}
            onChange={() => onChange?.(!checked)}
          />
        )}
      />
    </div>
  );
};

export default CheckboxControl;
