export type BankAccountForm = {
    firstName: string;
    lastName: string;
    bank_name: string;
    bank_country_id: string;
    bic: string;
    iban: string;
    currency_code: string | number;
    amountValue?: string;
    file_storage_id?: string;
  };

export const DefaultBankAccountFormValue: BankAccountForm = {
  firstName: '',
  lastName: '',
  bank_name: '',
  bank_country_id: '',
  bic: '',
  iban: '',
  currency_code: '',
  amountValue: '',
  file_storage_id: '',
};
