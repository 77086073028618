import React from 'react';
import { useHistory } from 'react-router-dom';

import { UI_ROUTES } from '../../util/routes';

import ErrorAddBankAccount from './Wallet/ErrorAddBankAccount/ErrorAddBankAccount';
import ErrorWalletPage from './Wallet/ErrorWalletPage/ErrorWalletPage';

const Error = () => {
  const history = useHistory();
  const { target } = history.location.state as { target: string };

  return (
    <>
      {target === UI_ROUTES.funds && <ErrorWalletPage />}
      {target === `${UI_ROUTES.funds}${UI_ROUTES.uploadBankStatement}` && <ErrorAddBankAccount />}
    </>
  );
};
export default Error;
