import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import lang from '../../../lib/language';
import { ENROLL_AUTH_REQUEST_TYPE } from '../../../lib/models/enroll-requests';
import { enrollAuth } from '../../../lib/store/auth';
import { useInterval } from '../../../lib/util/hooks/TimeoutHooks';

import { Button } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { PhoneAutocompleteValue } from '../../../components/PhoneAutocomplete/PhoneAutocomplete.types';

const CounterStart = 59;

type Props = {
    phoneNumber: PhoneAutocompleteValue
    setIsSendAgain: (isSendAgain: boolean) => void,
}

const Counter = ({ phoneNumber, setIsSendAgain }:Props) => {
  const [isRunning, setIsRunning] = useState(true);
  const [counter, setCounter] = useState<number>(CounterStart);
  const dispatch = useDispatch();

  useInterval(() => {
    setCounter(counter - 1);
  }, isRunning ? 1000 : null);

  useEffect(() => {
    if (counter === 0) {
      setIsRunning(false);
    }
  }, [counter]);
  if (counter === 0) {
    return (
      <Button
        id="sendCodeAgain"
        ariaLabel="send code again"
        variant={ButtonTypesEnum.Link}
        onClick={() => {
          setCounter(CounterStart);
          setIsSendAgain(true);
          dispatch(enrollAuth({
            requestType: ENROLL_AUTH_REQUEST_TYPE.SEND_PHONE_CODE,
            phoneCode: phoneNumber.code.split(' ')[0],
            phoneNumber: phoneNumber.phone,
          }));
          setIsRunning(true);
        }}
      >
        {lang.webSendAgain()}
      </Button>
    );
  }

  return (
    <span className="text-accent">
      {`0:${(counter < 10) ? (`0${counter}`) : counter}`}
    </span>
  );
};

export default Counter;
