export enum OrderStatusEnum {
  New = 'new',
  Initial = 'initial',
  InProgress = 'in progress',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Expired = 'expired',
  FinalReject = 'final rejected',
  TimedOut = 'timedout',
  Queued = 'queued',
  Submitted = 'submitted',
  Cancelled = 'cancelled',
  Modified = 'modified',
  Filled = 'filled',
  PartiallyFilled = 'partial fill',
  PartiallyReject = 'partial reject',
  InternalNew = 'internal new',
  InternalError = 'internal error',
  Pending = 'pending',
  OtherwiseExpired = 'otherwise expired',
  PendingCancelOrModify = 'pending cancel or modify',
}

export const FILLED_ORDER_STATUSES = [
  OrderStatusEnum.Filled,
  OrderStatusEnum.PartiallyFilled,
];

export const CANCELLED_ORDER_STATUSES = [
  OrderStatusEnum.Rejected,
  OrderStatusEnum.Cancelled,
];
