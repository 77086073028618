import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus, DocumentStatusEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { getLegalDeclarations } from '../../../lib/store/crm';
import { CRMCall } from '../../../lib/store/crm/types';
import { getUploadedDocumentStatus, hasLegalDeclarations } from '../../../lib/store/selectors';

import eyeVerrificationWebImage from '../../../assets/bigImages/eye_verrification_web.png';
import idVerrificationWebImage from '../../../assets/bigImages/id_verrification_web.png';
import phone1Image from '../../../assets/bigImages/phone_verification1.png';
import proofOfAddressEffects2Image from '../../../assets/bigImages/proof_of_address_effects2.png';
import { Button, HeaderLogo, Preloader, VerificationStatus } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { useBrowserWidth } from '../../../hooks';
import { SIZE_OF_AN_IMAGE_FOR_THE_SCREEN } from '../../../util/constants';
import { UI_ROUTES } from '../../../util/routes';
import { VerificationStatusEnum, VerificationStep } from '../Verification.types';

import styles from './VerificationLanding.module.scss';

const { Accepted, Pending } = VerificationStatusEnum;

const REDIRECTS = {
  [VerificationStep.PHONE_VERIFICATION]: UI_ROUTES.verification + UI_ROUTES.verificationPhoneNumber,
  [VerificationStep.POI]: UI_ROUTES.verification + UI_ROUTES.verificationPoI,
  [VerificationStep.POA]: UI_ROUTES.verification + UI_ROUTES.verificationPoA,
  [VerificationStep.TERMS]: UI_ROUTES.verification + UI_ROUTES.verificationTermsAndConditions,
  [VerificationStep.DONE]: '',
};

function calculateStep(
  phoneVerifyStatus: VerificationStatusEnum,
  getDocumentStatus: (isPoi:boolean) => VerificationStatusEnum,
  legalDeclarationsStatus: VerificationStatusEnum,
): VerificationStep {
  if (legalDeclarationsStatus === Accepted) {
    return VerificationStep.DONE;
  }
  if ([Pending, Accepted].includes(getDocumentStatus(false))) {
    return VerificationStep.TERMS;
  }
  if ([Pending, Accepted].includes(getDocumentStatus(true))) {
    return VerificationStep.POA;
  }
  if (phoneVerifyStatus === Accepted) {
    return VerificationStep.POI;
  }

  return VerificationStep.PHONE_VERIFICATION;
}

type Props = ReduxProps;

const VerificationLanding = (props: Props) => {
  const { rootState, isPhoneVerified, statusByCall, getLegalDeclarationsList } = props;

  const history = useHistory();
  const { browserWidthSize } = useBrowserWidth();
  const [phoneVeified, setPhoneVeified] = useState<boolean>(false);
  const [ldVerified, setLDVeified] = useState<boolean>(false);
  const [poiVerified, setPOIVeified] = useState<boolean>(false);
  const [poaVerified, setPOAVeified] = useState<boolean>(false);

  const getDocumentStatus = useCallback((isPoI: boolean = false): VerificationStatusEnum => {
    const status = getUploadedDocumentStatus(rootState, isPoI);

    if (!status || (typeof status === 'undefined')) {
      return VerificationStatusEnum.Unspecified;
    }

    switch (status) {
      case DocumentStatusEnum.New:
      case DocumentStatusEnum.InReview:
        return VerificationStatusEnum.Pending;
      case DocumentStatusEnum.Approved:
        return VerificationStatusEnum.Accepted;
      default:
        return VerificationStatusEnum.Declined;
    }
  }, [rootState]);

  const legalDeclarationsStatus = hasLegalDeclarations(rootState)
    ? VerificationStatusEnum.Accepted
    : VerificationStatusEnum.Unspecified;

  useEffect(() => {
    getLegalDeclarationsList({ active: true, isVerified: false });
  }, [getLegalDeclarationsList]);

  const phoneVerifyStatus = isPhoneVerified
    ? VerificationStatusEnum.Accepted
    : VerificationStatusEnum.Unspecified;

  const currentStep = calculateStep(phoneVerifyStatus, getDocumentStatus, legalDeclarationsStatus);

  useEffect(() => history.listen(location => {
    if (history.action === 'POP' && (
      (location.pathname.includes(UI_ROUTES.verification + UI_ROUTES.verificationPhoneOTP) && phoneVeified)
      || (location.pathname.includes(UI_ROUTES.verification + UI_ROUTES.verificationPoI) && poiVerified)
      || (location.pathname.includes(UI_ROUTES.verification + UI_ROUTES.verificationPoA) && poaVerified)
      || (location.pathname.includes(UI_ROUTES.verification + UI_ROUTES.verificationTermsAndConditions) && ldVerified)
      || !location.pathname.includes(UI_ROUTES.verification)
    )) {
      history.push(UI_ROUTES.overview);
    }
  }), [history, ldVerified, phoneVeified, poaVerified, poiVerified]);

  useEffect(() => {
    if (phoneVerifyStatus === VerificationStatusEnum.Accepted) {
      setPhoneVeified(true);
    }
    if (legalDeclarationsStatus === VerificationStatusEnum.Accepted) {
      setLDVeified(true);
    }
    if (getDocumentStatus() === VerificationStatusEnum.Pending
    || getDocumentStatus() === VerificationStatusEnum.Accepted) {
      setPOAVeified(true);
    }
    if (getDocumentStatus(true) === VerificationStatusEnum.Pending
    || getDocumentStatus(true) === VerificationStatusEnum.Accepted) {
      setPOIVeified(true);
    }
  }, [getDocumentStatus, legalDeclarationsStatus, phoneVerifyStatus]);

  const handleImage = (step: number) => {
    switch (step) {
      case 0: return phone1Image;
      case 1: return idVerrificationWebImage;
      case 2: return proofOfAddressEffects2Image;
      default: return eyeVerrificationWebImage;
    }
  };

  return (
    <>
      { (statusByCall[CRMCall.getLegalDeclarations]?.status === CallStatus.PENDING
      || statusByCall[CRMCall.getIndividualExtendedInfo]?.status === CallStatus.PENDING)
      && <div className={styles.loader}><Preloader text="" owner="Verification" /></div>}
      {
        (statusByCall[CRMCall.getLegalDeclarations]?.status === CallStatus.READY
        && statusByCall[CRMCall.getIndividualExtendedInfo]?.status === CallStatus.READY)
        && (
        <div className={styles.wrapperContainer}>
          <div className={styles.containerContent}>
            <Button
              variant={ButtonTypesEnum.Link}
              id="closeVerification"
              className={styles.bntClose}
              ariaLabel="close verification"
              onClick={() => history.push(UI_ROUTES.overview)}
            >
              {lang.commonClose()}
            </Button>
            <div className={styles.wrapperContent}>
              <div className={styles.logoImgContainer}>
                <HeaderLogo />
              </div>
              <h3
                id="verificationTitleLanding"
                aria-label="verification title"
                className={styles.title}
              >
                {lang.commonVerificationTitle()}
              </h3>
              <div className={styles.descriptionText}>
                <p>{lang.commonVerificationProcessTimeDescription()}</p>
                <p>{lang.commonVerificationProcessTimeSubDescription()}</p>
              </div>
              <div className={styles.wrapperVerificationStatus}>
                <VerificationStatus
                  id="PNS"
                  status={phoneVerifyStatus}
                  numberOfRow={1}
                />
                <VerificationStatus
                  id="POI"
                  numberOfRow={2}
                  status={getDocumentStatus(true)}
                />
                <VerificationStatus
                  id="POA"
                  numberOfRow={3}
                  status={getDocumentStatus()}
                />
                <VerificationStatus
                  id="terms"
                  numberOfRow={4}
                  status={legalDeclarationsStatus}
                />
              </div>
              <Button
                fullWidth
                id="proceedVerification"
                className={styles.btnProceed}
                ariaLabel="proceed with the verification"
                onClick={() => history.push(REDIRECTS[currentStep])}
              >
                {lang.commonProceedButton()}
              </Button>
            </div>
          </div>
          <div
            className={
            browserWidthSize <= SIZE_OF_AN_IMAGE_FOR_THE_SCREEN
              ? styles.hideContainerImage
              : styles.containerImage
          }
          >
            <img
              src={handleImage(currentStep)}
              className={styles.image}
              alt=""
            />
          </div>
        </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  rootState: state,
  isPhoneVerified: !!state.crm.individualExtendedInfo?.phone_number_confirmed,
  statusByCall: state.crm.statusByCall,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getLegalDeclarationsList: (data: { active: boolean, isVerified: boolean }) => dispatch(getLegalDeclarations(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(VerificationLanding);
