import { createSelector } from '@reduxjs/toolkit';
/**
 * A collection of mostly pseudo selectors / helper functions
 */
import lodash, { isArray, isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import mainConfig from '../../config';
import { MOCK } from '../../configDebug';
import {
  DocumentStatusEnum,
  DocumentTypeEnum,
  EnrollPhasesEnum,
  FinancialQuestionnaireTypesEnum,
  IndividualActivationStepEnum,
  KnowledgeAndExperienceRedirectStepEnum,
  LinkedAccountStatusEnum,
  PaymentMethodEnum,
  PaymentProcessEnum,
  PaymentStatusEnum,
  PersonalDetailsRedirectStepEnum,
  PurposeAndStatusRedirectStepEnum,
  RecoverPhaseEnum,
} from '../enums';
import { SourceOfFundEnum } from '../enums/source-of-fund.enum';
import { Company } from '../models/company';
import { Payment } from '../models/crm/types';
import {
  ENROLL_METHOD,
  ENROLL_REQUEST_STATE,
  FinancialQuestionnaireSection,
  IndividualExtendedInfoData,
  IndividualFinancialQuestionnaire,
  SourceOfFundsAnswerData,
  TokenDecodedData,
} from '../models/enroll';
import { GWQueryCase, GWQueryResultStatus } from '../models/gateway/types';
import { ReportingCall } from '../models/reporting/types';
import { Balance, Order } from '../models/trading/types';
import CountriesCache from '../store-util/CountriesCache';
import TopGainersCache from '../store-util/top-movers/TopGainersCache';
import TopLosersCache from '../store-util/top-movers/TopLosersCache';
import { CalculatedTradePrice } from '../store-util/TradePriceCache';
import { decodeJwt, isOneOf } from '../util/DataHelpers';
import { datesDifference, getDateTime } from '../util/DateTimeHelpers';
import { isCallStatusReady } from '../util/error-handling/StatusByCallHelpers';
import { concatKeys } from '../util/ObjectTools';
import { NullableNumber, NullableString } from '../util/types';

import { LegalDeclarations } from './crm/types';
import { ChartDataRange } from './market-data/charting/types';
import { SymbolLite, UserFavoriteStocks } from './market-data/types';
import { PaymentState } from './payment/index';
import { GetMyAccountChartDataResponse, MyAccountChartDataResponse, OpenPosition } from './reporting/types';
import { CRMState } from './crm';
import { GatewayState } from './gateway';
import { RootState } from './index';


const ordersSelector = (state: RootState) => state.trading.orders;
const lastOrdersSelector = (state: RootState) => lodash.values(state.trading.lastOrders);

export const allOrders = createSelector(
  [ ordersSelector, lastOrdersSelector ],
  (orders: Order[], lastOrders: Order[]) => (
    orders
      .concat(lastOrders)
      .sort((a: Order, b: Order) => {
        if (a.updatedAt == null || a.updatedAt! < b.updatedAt!) {
          return -1;
        }
        if (b.updatedAt == null || a.updatedAt! > b.updatedAt!) {
          return 1;
        }

        return 0;
      })
  ),
);

const individualExtendedInfoSelector = (state: RootState) => state.crm.individualExtendedInfo;
export const isUserVerified = createSelector(
  [ individualExtendedInfoSelector ],
  (info: IndividualExtendedInfoData | null) => isUserVerifiedStatus(info),
);
export const hasIndividualExtendedInfo = createSelector(
  [ individualExtendedInfoSelector ],
  (info: IndividualExtendedInfoData | null) => info?.email != null,
);


// helper functions

export function isUserEnrollStepEmailSent(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status === 100;
}

export function isUserEnrollStepPendingDocuments(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status === 101;
}

export function isUserEnrollStepPendingAcceptance(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status === 102;
}

export function isUserEnrollStepForVerification(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status === 103;
}

export function isUserForVerificationStatus(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status >= 103;
}

export function isUserVerifiedStatus(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status >= 200;
}

export function hasReachedUserEnrollStepPendingAcceptance(info: IndividualExtendedInfoData | null) {
  return !!info && info.individual_status >= 102;
}

export function isCRMEnrollPending(state: RootState | null) {
  return state?.crm.enroll.status === ENROLL_REQUEST_STATE.PENDING;
}

export function isCRMEnrollReady(state: RootState | null) {
  return state?.crm.enroll.status === ENROLL_REQUEST_STATE.READY;
}

export function isCRMEnrollError(state: RootState | null) {
  return state?.crm.enroll.status === ENROLL_REQUEST_STATE.ERROR;
}

/**
 * Checks if correct answers have been given at 3.5
 * @param financialQuestionnaireSections Sections with questions from state.crm.enroll.financialQuestionnaireSections
 * @param answersIds Answers for question 3.5
 * @returns `true` if both sections and answers are available and answers are correct
 */
export const areGroupedQuestionAnswersCorrect = (
  financialQuestionnaireSections: FinancialQuestionnaireSection[],
  answersIds: number[],
): boolean => {
  const questions = financialQuestionnaireSections?.[1]?.questions?.[4]?.subQuestions;
  const correctAnswers: number[] = [];
  questions?.forEach(({ lookupKey, options }) => {
    if (lookupKey === FinancialQuestionnaireTypesEnum.AVFM) {
      options?.forEach(({ description, id }) => {
        if (description === 'True') correctAnswers.push(id);
      });
    }
    if (lookupKey === FinancialQuestionnaireTypesEnum.POWL) {
      options?.forEach(({ description, id }: any) => {
        if (description === 'False') correctAnswers.push(id);
      });
    }
  });
  return isEqual(answersIds.sort(), correctAnswers.sort());
};

export function hasAnsweredLastQuestionnaire(individual: IndividualExtendedInfoData | null): boolean {
  if (!individual) {
    return false;
  }

  /**
   * If last question type is changed
   * it must be changed here also
   */
  const answerTypes = individual.individual_financial_questionnaires?.map(el => el.financial_questionaire_type);
  const hasAVFMAnswer = answerTypes?.includes(FinancialQuestionnaireTypesEnum.AVFM) ?? false;
  const hasPOWLAnswer = answerTypes?.includes(FinancialQuestionnaireTypesEnum.POWL) ?? false;

  return hasAVFMAnswer && hasPOWLAnswer;
}

/**
 * Checks if user has answered Onboarding Question 3.5 correctly
 * @param individualExtendedInfo From state.crm.individualExtendedInfo
 * @param financialQuestionnaireSections Sections with questions from state.crm.enroll.financialQuestionnaireSections
 * @param isAppropriatenessPopupConfirmed Available at state.auth.isAppropriatenessPopupConfirmed
 * @returns `true` if both sections and answers are available and answers are correct
 */
export const hasCorrectAnswersForLastQuestionnaire = (
  individualExtendedInfo: IndividualExtendedInfoData | null,
  financialQuestionnaireSections: FinancialQuestionnaireSection[] | null,
  isAppropriatenessPopupConfirmed: boolean,
): boolean => {
  if (!individualExtendedInfo) {
    return false;
  }
  if (isAppropriatenessPopupConfirmed || individualExtendedInfo.phone_number_confirmed) {
    return true;
  }

  const { individual_financial_questionnaires: allAnswers } = individualExtendedInfo;
  let hasSelectedRightAnswers = true;
  const answers = (
    allAnswers
      ?.filter(
        ({ financial_questionaire_type: type }) => (
          isOneOf(type, [
            FinancialQuestionnaireTypesEnum.AVFM,
            FinancialQuestionnaireTypesEnum.POWL,
          ])
        ),
      )
      .map(el => el.financial_questionnaire_id)
  );

  if (answers?.length && financialQuestionnaireSections) {
    hasSelectedRightAnswers = areGroupedQuestionAnswersCorrect(financialQuestionnaireSections, answers);
  }

  return hasSelectedRightAnswers;
};

export enum EnrollDetailsEnum {
  PERSONAL_DETAILS,
  DOCUMENT_DETAILS,
  ADDRESS_DETAILS,
  ADDRESS_DETAILS_FULL,
  TAX_DETAILS,
  UPLOADED_DOCUMENTS
}

export function hasEnrollDetails(
  type: EnrollDetailsEnum,
  state: RootState | null,
  infoOnly?: IndividualExtendedInfoData | null,
  customIndex?: number | null,
): boolean {
  let result = false;
  const info = state ? state.crm.individualExtendedInfo : infoOnly;

  switch (type) {
    case EnrollDetailsEnum.PERSONAL_DETAILS:
      result = !!info?.first_name
        && !!info?.last_name
        && info?.nationalities
        && info.nationalities.length > 0;
      break;

    case EnrollDetailsEnum.DOCUMENT_DETAILS:
      result = !!info?.identity_details
        && info.identity_details.length > 0
        && (customIndex ? info.identity_details.length > customIndex : true);
      break;

    case EnrollDetailsEnum.ADDRESS_DETAILS:
      result = !!info?.addresses
        && info.addresses.length > 0
        && (customIndex ? info.addresses.length > customIndex : true);
      break;

    case EnrollDetailsEnum.ADDRESS_DETAILS_FULL:
      result = !!info?.addresses
        && info.addresses.length > 0
        && (customIndex ? info.addresses.length > customIndex : true)
        && (!!info.addresses[customIndex ?? 0]?.city)
        && (!!info.addresses[customIndex ?? 0]?.zip_code)
        && (!!info.addresses[customIndex ?? 0]?.address_line);
      break;

    case EnrollDetailsEnum.TAX_DETAILS:
      result = !!info?.tax_details
        && info.tax_details.length > 0
        && (customIndex ? info.tax_details.length > customIndex : true);
      break;

    default:
      console.warn(`[store/selectors] hasEnrollDetails - unknown type '${type}' for enum EnrollDetailsEnum`);
      break;
  }

  return result;
}

export function hasSourceOfIncomes(state: CRMState) {
  const { individualExtendedInfo } = state;

  return (
    individualExtendedInfo
    && individualExtendedInfo.source_of_incomes
    && (individualExtendedInfo.source_of_incomes.length > 0)
  );
}

export function hasSourceOfFunds(state: CRMState) {
  const { individualExtendedInfo } = state;

  return (
    individualExtendedInfo
    && (individualExtendedInfo.source_of_funds)
    && (individualExtendedInfo.source_of_funds.length > 0)
  );
}

export function hasAllDocumentsUploaded(
  state: RootState,
): boolean {
  const { documents } = state.crm.individualExtendedInfo || {};

  return !!(
    documents
      && documents.length > 1
      && documents.find(item => item.document_type === DocumentTypeEnum.ProofOfAddress)
      && documents.find(item => item.document_type === DocumentTypeEnum.ProofOfIdentity)
  );
}

export function hasPoIDocumentUploaded(state: RootState): boolean {
  const { documents } = state.crm.individualExtendedInfo || {};

  return !!(
    documents
      && documents.length > 0
      && documents.find(item => item.document_type === DocumentTypeEnum.ProofOfIdentity)
  );
}

export function hasPoADocumentUploaded(state: RootState): boolean {
  const { documents } = state.crm.individualExtendedInfo || {};

  return !!(
    documents
      && documents?.length > 0
      && documents.find(item => item.document_type === DocumentTypeEnum.ProofOfAddress)
  );
}

/**
 * Used for getting uploaded
 * PoI or PoA document status of type DocumentStatusEnum
 */

export function getUploadedDocumentStatus(
  state: RootState,
  isPoI: boolean = false,
): DocumentStatusEnum | null | undefined {
  const { documents } = state.crm.individualExtendedInfo || {};

  if (documents && (documents?.length > 0)) {
    const documentType = isPoI ? DocumentTypeEnum.ProofOfIdentity : DocumentTypeEnum.ProofOfAddress;
    const filteredDocuments = documents.find(item => item.document_type === documentType);

    if (filteredDocuments) {
      return filteredDocuments.status;
    }
  }

  return null;
}

export function getResidentialAddressCountryIdAndPhoneCode(state: RootState) {
  const address = state.crm.individualExtendedInfo?.addresses?.find(item => item?.address_type === 'R');
  if (!address) return {};

  // const country = state.crm.countryList?.find(item => item.id === address?.country_id);
  const country = CountriesCache.get()?.find(item => item.id === address?.country_id);
  if (!country) return {};

  return { id: country.id, phoneCode: country.phone_code };
}

export function hasLegalDeclarations(state: RootState): boolean {
  const { individualOwnLegalDeclarations } = state.crm.enroll;
  const legalDeclarations = individualOwnLegalDeclarations?.legal_declarations;

  if (!legalDeclarations) {
    return false;
  }

  return legalDeclarations.length >= Object.keys(LegalDeclarations).length;
}

/**
 * Returns an id or array of ids by type (EnrollDetailsEnum)
 * If customIndex is not provided - returns the item at index 0 if it exists
 * @param type EnrollDetailsEnum denoting personal details, identity document, address or tax details
 * @param state The root state to extract the data from
 * @param infoOnly Optional - provide extended info instead of root state
 * @param customIndex Optional - custom index of the data if required
 * @param asArray Optional - return all ids as an array of such (does not work for the first type - personal details)
 */
export function getEnrollDetailId(
  type: EnrollDetailsEnum,
  state: RootState | null,
  infoOnly?: IndividualExtendedInfoData | null,
  customIndex?: number | null,
  asArray?: boolean | null,
): any {
  if (!hasEnrollDetails(type, state, infoOnly)) return null;

  let result: any;
  const info = state ? state.crm.individualExtendedInfo : infoOnly;

  switch (type) {
    case EnrollDetailsEnum.PERSONAL_DETAILS:
      result = info!.id;
      break;

    case EnrollDetailsEnum.DOCUMENT_DETAILS:
      if (asArray) {
        result = info!.identity_details.map((item: any) => item.id);
      } else {
        result = info!.identity_details[
          customIndex != null ? customIndex : 0
        ]?.id;
      }
      break;

    case EnrollDetailsEnum.ADDRESS_DETAILS:
      if (asArray) {
        result = info!.addresses?.map((item: any) => item.id);
      } else {
        result = info!.addresses[
          customIndex != null ? customIndex : 0
        ]?.id;
      }
      break;

    case EnrollDetailsEnum.TAX_DETAILS:
      if (asArray) {
        result = info!.tax_details.map((item: any) => item.id);
      } else {
        result = info!.tax_details[
          customIndex != null ? customIndex : 0
        ]?.id;
      }
      break;

    default:
      console.warn(`[store/selectors] getEnrollDetailId - unknown type '${type}' for enum EnrollDetailsEnum`);
      break;
  }

  return result;
}

export const getExpirationDate = (state: RootState) => {
  let result: string = '';
  const info = state.crm?.individualExtendedInfo;
  if (info) {
    result = info.identity_details[0]?.expiration_date ?? '';
  }

  return result;
};

/**
 * Returns identity document subtype from extended user info.
 * Returns null if no data found
 * @param state The root state
 * @param customIndex If multiple documents - get by custom index
 */
export function getIdentityDocumentSubType(state: RootState, customIndex: number | null = null) {
  const details = state.crm.individualExtendedInfo?.identity_details;
  if (!details || details.length === 0) return null;

  const index = customIndex != null ? customIndex : details.length - 1;
  return details[index].identity_document_type;
}

// Gateway
/**
 * Get number of retries for symbol and queryCase
 */
export function getRetriesForSymbol(state: GatewayState, symbol: string, queryCase: GWQueryCase) {
  const { resultsRetries, symbolToAccessId } = state;
  return resultsRetries[symbolToAccessId[concatKeys(symbol, queryCase)]];
}

/**
 * Get HTTP status of last results call for symbol and queryCase
 * @param state Gateway state
 * @param symbol Instrument to check (for example AAPL, AMZN etc.)
 * @param queryCase The GWQueryCase to differentiate between data calls
 * @returns The status value of last call
 */
export function getLastStatusForSymbol(state: GatewayState, symbol: string, queryCase: GWQueryCase) {
  const { lastQueryStatus, symbolToAccessId } = state;
  const result = lastQueryStatus[concatKeys(symbol, queryCase)];

  if (result == null) {
    return GWQueryResultStatus.INITIAL;
  }
  return result;
}

/**
 * Checks whether market data feed is available.
 * Returns true:
 *  1) when using gateway - if gateway is connected
 *  2) when using gateway partially - if both market data and gateway are connected
 *  3) when login to gateway enabled but both nbbo & ohlcv are not  - if market data is connected
 *  3) when login to gateway is disabled  - if market data is connected
 */
export function hasMarketDataAvailable(gatewayConnected: boolean, marketConnected: boolean) {
  const { gatewayEnabled } = require('../../configLib').default;
  const { login: gwEnabled, subscribe: gwSubscribeEnabled } = gatewayEnabled;

  const gwOhlcvOrGwNbbo = (
    gwSubscribeEnabled
      ? marketConnected && gatewayConnected // (2)
      : marketConnected // (3)
  );

  const gwOhlcvAndGwNbbo = (
    gwSubscribeEnabled
      ? gatewayConnected // (1)
      : gwOhlcvOrGwNbbo // (2) and (3)
  );

  return (
    gwEnabled
      ? gwOhlcvAndGwNbbo // (1), (2) and (3)
      : marketConnected // (4)
  );

  // TODO: Discuss example for no-nested-ternary
  /*
  return (
    gwEnabled
      ? (
          gwOhlcv && gwNbbo
            ? gatewayConnected // (1)
            : gwOhlcv || gwNbbo
                ? marketConnected && gatewayConnected // (2)
                : marketConnected // (3)
        )
      : marketConnected // (4)
  );
  */
}


/**
 * Checks whether news data is available.
 * Returns `true` if all are fullfilled:
 *  - user is logged in (see isLoggedIn below)
 *  - if gateway is enabled for news - returns true if GW is connected (otherwise returns false)
 *  - if hasMore is true (see param below) and other checks are ok - also checks whether more news is available for loading
 * @param state
 * @param symbol If available - checking news for symbol availability - otherwise latest news availability
 * @param hasMore In addition to checks above - also checks whether more news is available for loading
 */
export function isNewsAvailable(state: RootState, symbol: NullableString = null, hasMore = false) {
  const { gatewayEnabled } = require('../../configLib').default;
  const {
    login: gwEnabled,
    latestNews: gwNewsEnabled,
    newsForSymbol: gwNewsForSymbolEnabled,
  } = gatewayEnabled;
  const gwForNewsEnabled = gwEnabled && (symbol != null ? gwNewsForSymbolEnabled : gwNewsEnabled);
  const {
    gateway: gatewayState,
    news: newsState,
  } = state;
  const {
    isLoading,
    newsForSymbols,
    latestNews,
    allLoaded,
    allLoadedForSymbols,
  } = newsState;
  const isLast = symbol != null ? allLoadedForSymbols[symbol] : allLoaded;
  const news = symbol != null ? newsForSymbols[symbol] || [] : latestNews;
  const hasNoData = news.length === 0 && !isLoading && isLast;
  const hasMoreNews = hasMore ? !isLast : !hasNoData;

  return (
    gwForNewsEnabled
      ? gatewayState.connected && hasMoreNews
      : hasMoreNews
  );
}

/**
 * If `state` param is given `favouriteStocks` and `userCorrelationId` are ignored
 * and favourite stocks and user id are taken from `state`.
 */
export const mapUserFavouriteStocksData = (
  favouriteStocks: UserFavoriteStocks | null,
  userCorrelationId: NullableNumber,
  state?: RootState,
): string[] => {
  const theStocks = getFavoriteStocks(state ?? null, favouriteStocks, userCorrelationId);
  return theStocks.map((data: SymbolLite) => data.symbol);
};

// Auth
export const isLoggedIn = (state: RootState) => (
  hasValidToken(state) && state.crm.connected
);

export const isLoginPending = (state: RootState) => (
  state.auth.status === 'connecting'
);

export function getToken(state: RootState, onlyNormal = true) {
  if (onlyNormal) return state.auth.accessToken;
  return state.auth.accessToken ?? state.auth.enroll.accessToken;
}

export const isTokenDateExpired = (exp: number): boolean => (exp === null || new Date(exp * 1000) <= new Date());

export const getMillisecondsToTokenExpiration = (exp: NullableNumber) => (
  exp == null ? null : exp * 1000 - new Date().getTime()
);

export const isTokenWillBeExpired = (expire: number): boolean => {
  const RENEW_TOKEN_RANGE = 20;// minutes
  const nowMoment = moment();
  const expireDateTime = expire * 1000;
  const newExpMoment = moment(expireDateTime);

  return nowMoment.add(RENEW_TOKEN_RANGE, 'minutes')
    .isAfter(newExpMoment);
};

// eslint-disable-next-line max-len
export const isTokenRefreshLocked = (refreshTokenUnlockTime: NullableNumber): boolean => !!refreshTokenUnlockTime && refreshTokenUnlockTime > moment().unix();

/**
 * @param state RootState to extract token from. If `null` - `theToken` is used
 * @param theToken Direct token value to check
 * @param onlyNormal If false - will also attempt to try enroll token
 */
export function hasValidToken(state: RootState | null, theToken?: NullableString, onlyNormal = true) {
  if (MOCK.ENABLED && (state?.auth.accessToken ?? theToken)) return true;

  const token = theToken ?? (state ? getToken(state, onlyNormal) : null);
  if (!token) return false;

  const { exp } = decodeJwt(token) as TokenDecodedData;

  return !isTokenDateExpired(exp);
}

export function hasManualToken(state: RootState) {
  return (
    state.auth.enroll?.accessToken != null
    && state.auth.enroll?.refreshToken != null
  );
}

export function isGatewayMDFullyEnabled() {
  const { gatewayEnabled } = require('../../configLib').default;
  const {
    login: gwEnabled,
    subscribe: gwSubscribeEnabled,
  } = gatewayEnabled;

  return (gwEnabled && gwSubscribeEnabled);
}

/**
 * Calculates the onboarding recovery step at which to load the app (DUPLICATE of `getRecoverPhase`)
 * @param individual From state.crm.individualExtendedInfo
 * @param financialQuestionnaireSections Sections with questions from state.crm.enroll.financialQuestionnaireSections
 * @param isAppropriatenessPopupConfirmed Available at state.auth.isAppropriatenessPopupConfirmed
 * // TODO: Check which one of the two needs to stay - possible duplicates: (ART-3272)
 *      * functions `getRecoverPhase` and `checkIndividualActivationStep`
 *      * types `IndividualActivationStepEnum` and `RecoverPhaseEnum`
 * Note: Most probably `getRecoverPhase` needs to be deleted in favor of `checkIndividualActivationStep` and its type
 */
export function checkIndividualActivationStep(
  individual: IndividualExtendedInfoData | null,
  financialQuestionnaireSections: FinancialQuestionnaireSection[] | null,
  isAppropriatenessPopupConfirmed: boolean,
): IndividualActivationStepEnum {
  if (!individual) {
    return IndividualActivationStepEnum.InEnrollment;
  }

  if (isUserVerifiedStatus(individual)) {
    return IndividualActivationStepEnum.Activated;
  }

  if (isUserForVerificationStatus(individual)) {
    return IndividualActivationStepEnum.InPendingVerification;
  }

  if (
    hasAnsweredLastQuestionnaire(individual)
    && !isUserEnrollStepForVerification(individual)
    // eslint-disable-next-line max-len
    && hasCorrectAnswersForLastQuestionnaire(individual, financialQuestionnaireSections, isAppropriatenessPopupConfirmed)
  ) {
    return IndividualActivationStepEnum.InVerification;
  }

  return IndividualActivationStepEnum.InEnrollment;
}

export function getIndividualQuestionnaireAnswerByType(
  questionnaireType: FinancialQuestionnaireTypesEnum,
  questionnaires?: IndividualFinancialQuestionnaire[],
): IndividualFinancialQuestionnaire | undefined {
  return questionnaires?.find((questionnaire: IndividualFinancialQuestionnaire) => (
    questionnaire.financial_questionaire_type === questionnaireType
  ));
}

/**
 * Calculates the onboarding recovery step at which to load the app (DUPLICATE of `checkIndividualActivationStep`)
 * @param individual From state.crm.individualExtendedInfo
 * @param financialQuestionnaireSections Sections with questions from state.crm.enroll.financialQuestionnaireSections
 * @param isAppropriatenessPopupConfirmed Available at state.auth.isAppropriatenessPopupConfirmed
 * // TODO: Check which one of the two needs to stay - possible duplicates: (ART-3272)
 *      * functions `getRecoverPhase` and `checkIndividualActivationStep`
 *      * types `IndividualActivationStepEnum` and `RecoverPhaseEnum`
 * Note: Most probably `getRecoverPhase` needs to be deleted in favor of `checkIndividualActivationStep` and its type
 */
export function getRecoverPhase(
  individual: IndividualExtendedInfoData | null,
  financialQuestionnaireSections: FinancialQuestionnaireSection[] | null,
  isAppropriatenessPopupConfirmed: boolean,
): RecoverPhaseEnum {
  let result: RecoverPhaseEnum | null = null;

  switch (true) {
    case (!individual || isUserVerifiedStatus(individual)):
      result = RecoverPhaseEnum.Unspecified;
      break;

    case (!individual?.email_confirmed):
      result = RecoverPhaseEnum.EmailSent;
      break;

    default: {
      const hasAnswered = hasAnsweredLastQuestionnaire(individual);
      // eslint-disable-next-line max-len
      const hasRightAnswers = hasCorrectAnswersForLastQuestionnaire(individual, financialQuestionnaireSections, isAppropriatenessPopupConfirmed);
      result = (
        hasAnswered && (hasRightAnswers || isAppropriatenessPopupConfirmed)
          ? RecoverPhaseEnum.Verification
          : RecoverPhaseEnum.Enroll
      );
      break;
    }
  }

  return result;
}

/**
 * Review in refactoring effort of ART-3272 for removing duplicates of onboarding recovery logic
 */
export function getEnrollRecoveryPhase(
  individual: IndividualExtendedInfoData | null,
): { phase: number, step: number } {
  if (!individual || !hasEnrollDetails(EnrollDetailsEnum.TAX_DETAILS, null, individual)) {
    return {
      phase: EnrollPhasesEnum.PersonalDetails,
      step: PersonalDetailsRedirectStepEnum.PersonalDetails,
    };
  }

  if (!hasEnrollDetails(EnrollDetailsEnum.ADDRESS_DETAILS_FULL, null, individual)) {
    return {
      phase: EnrollPhasesEnum.PersonalDetails,
      step: PersonalDetailsRedirectStepEnum.AddressDetails,
    };
  }

  if (!hasEnrollDetails(EnrollDetailsEnum.DOCUMENT_DETAILS, null, individual)) {
    return {
      phase: EnrollPhasesEnum.PersonalDetails,
      step: PersonalDetailsRedirectStepEnum.DocumentDetails,
    };
  }

  const {
    source_of_funds,
    source_of_incomes,
    purpose_of_trading_id,
    individual_financial_questionnaires,
  } = individual;

  if (!purpose_of_trading_id) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.PurposeOfTrading,
    };
  }

  if (source_of_incomes.length === 0) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.EmploymentStatus,
    };
  }

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.AITI, individual_financial_questionnaires)
  ) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.AmountToInvest,
    };
  }

  if (source_of_funds.length === 0) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.SourceOfFund,
    };
  }

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.XANI, individual_financial_questionnaires)
  ) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.AnnualNetIncome,
    };
  }

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.XENW, individual_financial_questionnaires)
  ) {
    return {
      phase: EnrollPhasesEnum.PurposeAndStatus,
      step: PurposeAndStatusRedirectStepEnum.EstimatedNetWorth,
    };
  }

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.TEWS, individual_financial_questionnaires)
  ) {
    return {
      phase: EnrollPhasesEnum.KnowledgeAndExperience,
      step: KnowledgeAndExperienceRedirectStepEnum.DoYouHaveExperience,
    };
  }

  // eslint-disable-next-line max-len
  const hasTradingKnowledgePassed = getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.TKLP, individual_financial_questionnaires);

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.ATPY, individual_financial_questionnaires)
    && !hasTradingKnowledgePassed
  ) {
    return {
      phase: EnrollPhasesEnum.KnowledgeAndExperience,
      step: KnowledgeAndExperienceRedirectStepEnum.AverageNumberOfTrades,
    };
  }

  if (
    !getIndividualQuestionnaireAnswerByType(FinancialQuestionnaireTypesEnum.AVPY, individual_financial_questionnaires)
    && !hasTradingKnowledgePassed
  ) {
    return {
      phase: EnrollPhasesEnum.KnowledgeAndExperience,
      step: KnowledgeAndExperienceRedirectStepEnum.AverageNumberOfTrades,
    };
  }

  if (!hasTradingKnowledgePassed) {
    return {
      phase: EnrollPhasesEnum.KnowledgeAndExperience,
      step: KnowledgeAndExperienceRedirectStepEnum.TradingKnowledge,
    };
  }

  return {
    phase: EnrollPhasesEnum.KnowledgeAndExperience,
    step: KnowledgeAndExperienceRedirectStepEnum.ChooseCorrectStatement,
  };
}

export function getCompany(symbol: NullableString, state: RootState): Company | undefined {
  if (!symbol || !state?.fundamental?.companies?.find) return undefined;

  return state.fundamental.companies.find(company => company.symbol === symbol) ?? undefined;
}


/**
 * @returns A `Balance` or an Array of [ Balance, index: number ] if `withIndex` is true
 */
export function getCurrentBalance(state: RootState, withIndex = false): Balance | [ Balance, number ] {
  const { crm, trading } = state;
  const { accounts, selectedAccount } = crm;
  const { balances } = trading;
  const theAccount = accounts[selectedAccount];

  let theIndex = -1;
  let res = balances.find((item, index) => {
    let isAMatch = item.account === theAccount;
    if (isAMatch && theIndex === -1) theIndex = index;
    return isAMatch;
  });

  if (!res) {
    res = {} as any; // force empty object - in order to be able to show availBP, etc. props as invalid values (undefined)

    // This is an exception for test environment (and mocked - for testing and mocked mode)
    const isTestOrMockedEnv = isOneOf(mainConfig.currentEnv, [ 'mocked', 'test' ]);
    const hasOneBalance = balances.length === 1;
    const hasOneAccount = crm.accounts.length === 1;
    if (isTestOrMockedEnv && hasOneBalance && hasOneAccount) {
      res = balances[0];
      return withIndex ? [ res, 0 ] : res;
    }

    console.error(`[selectors] getCurrentBalance - could not find balance data for account '${theAccount}'`);
  }

  return withIndex ? [ res!, theIndex ] : res!;
}

export function getCurrentAccount(state: RootState) {
  const { selectedAccount, accounts } = state.crm;
  return accounts[selectedAccount];
}

export function getCurrentBuyingPower(state: RootState) {
  const balance = getCurrentBalance(state) as Balance;
  const sumOfPendingOrders = state.trading.sumOfPendingOrders;

  return sumOfPendingOrders ? balance.availBp - sumOfPendingOrders : balance.availBp;
}

export const getOrdersBySymbol = (
  matchedSymbol: NullableString,
  state: RootState,
): Order[] => state.trading.orders.filter(({ symbol }) => symbol === matchedSymbol);

export const getSymbol = (symbolOrSymbols: string | string[]) => (
  isArray(symbolOrSymbols) ? symbolOrSymbols[0] : symbolOrSymbols
);

export const userAdultYear = (state: RootState): string => {
  const birthDate = state.crm.individualExtendedInfo?.birth_date;
  return getDateTime(true, [ 'plus' ], [ [ 18, 'years' ] ], birthDate);
};

// eslint-disable-next-line max-len
export const getSourceOfFundsData = (state: RootState, sofAnswerData: SourceOfFundEnum): SourceOfFundsAnswerData | undefined => {
  const sourceOfFunds = state.crm.individualExtendedInfo?.source_of_funds;

  return sourceOfFunds && sourceOfFunds.find(source => source.source_of_fund_id === sofAnswerData);
};

export function getSelectedBankAccount(paymentState: PaymentState) {
  return (
    paymentState.linkedAccounts[paymentState.selectedLinkedAccountIndex]
  );
}

export const hasAtLeastOneActiveLinkedAcc = (paymentState: PaymentState): boolean => paymentState.linkedAccounts
  .findIndex(la => la.status === LinkedAccountStatusEnum.Active) !== -1;

export function isDeposit(payment: Payment) {
  return (payment && payment.payment_process === PaymentProcessEnum.Deposit);
}

export function isCardDeposit(payment: Payment) {
  return (
    payment
    && payment.payment_process === PaymentProcessEnum.Deposit
    && payment.payment_method === PaymentMethodEnum.Card
  );
}

export function isPendingCardTransfer(payment: Payment) {
  return (
    payment
    && payment.payment_status === PaymentStatusEnum.Pending
    && payment.payment_method === PaymentMethodEnum.Card
  );
}

export function isWithdrawal(payment: Payment) {
  return (payment && payment.payment_process === PaymentProcessEnum.Withdrawal);
}

export function isPendingTransaction(payment: Payment) {
  return (payment && payment.payment_status <= PaymentStatusEnum.Pending);
}

export const getCurrentPosition = (theSymbol: string, positions: OpenPosition[]) => (
  positions.find(({ symbol }) => symbol === theSymbol)
);

// eslint-disable-next-line max-len
export const getSumOfUrealizedProfits = (state: RootState, priceCache: Partial<Record<string, CalculatedTradePrice>>) => {
  const openPositions = state.reporting.openPositions;
  let sumOfUrealizedProfits = 0;

  openPositions.forEach(el => {
    const currentPrice = priceCache[el.symbol]?.currentPrice;
    if (currentPrice) {
      sumOfUrealizedProfits += el.quantity * (currentPrice - el.averageOpeningPrice);
    }
  });

  return sumOfUrealizedProfits;
};

export const getChartTotalValue = (state: RootState, priceCache: Partial<Record<string, CalculatedTradePrice>>) => {
  const { cashBalance, tradingCharges, accountCharges, realizedPnL } = state.reporting.accountSummary;
  const sumOfUrealizedProfits = getSumOfUrealizedProfits(state, priceCache);

  return (cashBalance + tradingCharges + accountCharges + realizedPnL + sumOfUrealizedProfits);
};

export const getRangeDiffrenceInDays = (
  range: ChartDataRange,
  netIncomeComponents: MyAccountChartDataResponse[],
): number => {
  const currentDay = getDateTime();
  let rangeDifferenceInDays = 0;
  switch (range) {
    case '1M': {
      rangeDifferenceInDays = datesDifference(getDateTime(true, [ 'minus' ], [ [ 1, 'month' ] ]), currentDay, 'days');
      break;
    }
    case '3M': {
      rangeDifferenceInDays = datesDifference(getDateTime(true, [ 'minus' ], [ [ 3, 'months' ] ]), currentDay, 'days');
      break;
    }
    case '1Y': {
      rangeDifferenceInDays = datesDifference(getDateTime(true, [ 'minus' ], [ [ 1, 'year' ] ]), currentDay, 'days');
      break;
    }
    case 'YTD': {
      rangeDifferenceInDays = datesDifference(getDateTime(true, [ 'startOf' ], [ [ 'year' ] ]), currentDay, 'days');
      break;
    }
    default: {
      rangeDifferenceInDays = datesDifference(netIncomeComponents[0].date, currentDay, 'days');
      break;
    }
  }
  return rangeDifferenceInDays;
};

export const preparePnlResultByLengthAndRange = (
  netIncomeComponents: GetMyAccountChartDataResponse,
  range: ChartDataRange,
) => {
  const netIncomeComponentsLength = netIncomeComponents.length;
  let pnlResult = netIncomeComponentsLength ? netIncomeComponents[0].pnlResult : 0;

  if (netIncomeComponentsLength) {
    const rangeDifferenceInDays = getRangeDiffrenceInDays(range, netIncomeComponents);
    pnlResult = rangeDifferenceInDays - netIncomeComponentsLength < 0 ? netIncomeComponents[0].pnlResult : 0;
  }
  return pnlResult;
};

// eslint-disable-next-line max-len
export const getChartPnL = (state: RootState, priceCache: Partial<Record<string, CalculatedTradePrice>>, range: ChartDataRange) => {
  const { tradingCharges, accountCharges, realizedPnL } = state.reporting.accountSummary;
  const netIncomeComponents = state.reporting.netIncomeComponents;
  const sumOfUrealizedProfits = getSumOfUrealizedProfits(state, priceCache);
  const netIncomeComponentsLength = netIncomeComponents.length;

  let pnlResult = 0;
  if (
    netIncomeComponentsLength
    && isCallStatusReady(ReportingCall.getNetEquityComponents, state.reporting.statusByCall)
  ) {
    pnlResult = preparePnlResultByLengthAndRange(netIncomeComponents, range);
  }

  return tradingCharges + accountCharges + realizedPnL + sumOfUrealizedProfits - pnlResult;
};

// TODO: Remove in ART-3128
export const hasUploadDocumentFinished = (state: RootState) => (
  state.crm.enroll.callMethod === ENROLL_METHOD.postIndividualDocumentOwn && state.crm.enroll.status > 1
);

export const hasUploadDocumentPOAFinished = (state: RootState) => (
  state.crm.enroll.callMethod === ENROLL_METHOD.postDocumentOwnBinary && state.crm.enroll.status > 1
);

export const hasUploadDocumentPOIFinished = (state: RootState) => (
  state.crm.enroll.callMethod === ENROLL_METHOD.postIndividualIdentityDetailOwnWithFile && state.crm.enroll.status > 1
);

export const isEnrollFinancialQuestionnaireSuccess = (state: RootState) => {
  if (!state.crm.enroll) {
    return false;
  }

  return state
    .crm.enroll
    .statusByCall[ENROLL_METHOD.postFinancialQuestionnaire]
    .requestState === ENROLL_REQUEST_STATE.READY;
};

export const hasTradingPlatformId = (state: RootState) => (!!state.crm.tradingPlatformId);

export function getTopMoversInitialItems() {
  const { initialData: topGainers, isInitialDataReady: areGainersReady } = TopGainersCache.getInitialItems();
  const { initialData: topLosers, isInitialDataReady: areLosersReady } = TopLosersCache.getInitialItems();

  return {
    topGainers,
    topLosers,
    isInitialDataLoaded: areGainersReady && areLosersReady,
  };
}

/**
 * If `state` param is given `favouriteStocks` and `userCorrelationId` are ignored
 * and favourite stocks and user id are taken from `state`.
 */
export function getFavoriteStocks(
  state: RootState | null,
  favouriteStocks?: UserFavoriteStocks | null,
  userCorrelationId?: NullableNumber,
) {
  const allFavorites: UserFavoriteStocks | undefined | null = state?.marketData.favouriteStocks ?? favouriteStocks;
  const id = state?.crm?.individualExtendedInfo?.user_correlation_id ?? userCorrelationId;
  if (!allFavorites || !id) {
    return [];
  }

  const theStocks = allFavorites[id];
  if (isEmpty(theStocks)) {
    return [];
  }

  return allFavorites[id];
}

export const checkIfUserIsETFRestricted = (symbol: NullableString, state: RootState): boolean => {
  if (!symbol) return false;

  const canTradeETFs = state.ses.canTradeETFs;
  const issueType = getCompany(symbol, state)?.issueType;

  return issueType === 'et' && !canTradeETFs;
};

export const isSymbolETF = (symbol: NullableString, state: RootState): boolean => {
  if (!symbol) return false;
  const issueType = getCompany(symbol, state)?.issueType;

  return issueType === 'et';
};
