/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classNames from 'classnames';

import lang from '../../../../lib/language';

import AlertIcon from '../../../../assets/img/icon-exclamation-mark-warning.svg';
import useIsMobile from '../../../../hooks/useIsMobile';
import TradingRestrictionsModal from '../../../TradingRestrictionsModal/TradingRestrictionsModal';

import styles from './ETFRestrcitions.module.scss';

const ETFRestrcitions = () => {
  const [showETFRestrictionModal, setShowETFRRestrictionModal] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const readMoreLink = (
    <>
      {isMobile && (' ')}
      <span
        className={styles.btnEtfInfo}
        role="button"
        onClick={() => setShowETFRRestrictionModal(true)}
        tabIndex={0}
      >
        {lang.commonStockDetailsReadMore()}
      </span>
    </>
  );

  return (
    <>
      <div className={classNames(styles.wrapper, { [styles.etfRestrictionsWrapper]: isMobile })}>
        <img className={styles.icon} src={AlertIcon} alt="attention" />
        <div>
          <p className={styles.message}>
            {lang.etfTradingRestrictionsMessage()}
            {isMobile && readMoreLink}
          </p>
          {!isMobile && readMoreLink}
        </div>
      </div>
      {showETFRestrictionModal && <TradingRestrictionsModal closeModal={() => setShowETFRRestrictionModal(false)} />}
    </>
  );
};

export default ETFRestrcitions;
