import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, uniq } from 'lodash';

import lang from '../../../lib/language';
import { CompanyLogoAndNameState } from '../../../lib/models/fundamental/types';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { subscribeSymbolStatus, unsubscribeSymbolStatus } from '../../../lib/store/gateway/index';
import { RootState } from '../../../lib/store/index';
import { getOpenPositions } from '../../../lib/store/reporting';
import {
  AccountTradeHistoryResponseData,
  MyStocksContent,
  OpenPosition,
  PortfolioChartElement,
} from '../../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../../lib/store/selectors';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';
import {
  calculateMyStockContent,
  getLongPositions,
  getShortPositions,
  mapMyStocksTableContent,
  sortOpenPositions,
} from '../../../lib/util/my-account-helpers';
import { manageSubscriptions } from '../../../lib/util/TradingHelpers';
import { TypedObject } from '../../../lib/util/types';

import { Navigation } from '../../../components/index';
import { useBrowserWidth, useIsMobile } from '../../../hooks';
import { BASE_WIDTH_SYMBOL_DETAILS } from '../../../util/constants';
import ErrorContent from '../ErrorContent/ErrorContent';
import AccountBreakDownPieChart from '../MyStocks/chart/AcountBreakDownPieChart';
import MyStocksTable from '../MyStocks/MyStocksTable';
import NavigationButtons from '../MyStocks/navigation/NavigationButtons';
import PendingOrders from '../PendingOrders/PendingOrders';

import styles from './MyAccountTableContent.module.scss';

type Props = {
  isMyAccountInitiliazed: boolean;
  isUserVerified: boolean;
}

const MyAccountTableContent = ({ isMyAccountInitiliazed, isUserVerified }: Props) => {
  const [totalValue, setTotalValue ] = useState<number>(0);
  const [sortedOpenPositions, setSortedOpenPositions ] = useState<OpenPosition[]>([]);
  const [chartData, setChartData ] = useState<PortfolioChartElement[]>([]);
  const [selectedIndex, setSelectedIndex ] = useState<PortfolioChartElement>({ symbol: '' });
  const [positionTableContent, setPositionTableContent ] = useState<MyStocksContent[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [activePosition, setActivePosition] = useState<string>(lang.commonAccountBreakdownTotal());
  const [hasShortAndLongPos, setHasShortAndLongPos] = useState<boolean>(true);

  const dispatch = useDispatch();
  const buyingPower = useSelector<RootState, number>((state: RootState) => getCurrentBuyingPower(state));
  const openPositions = useSelector<RootState, OpenPosition[]>((state: RootState) => (state.reporting.openPositions));
  const pendingOrders = useSelector<RootState, AccountTradeHistoryResponseData[]>(
    (state: RootState) => (state.reporting.pendingOrders),
  );
  const companyLogoAndName = useSelector<RootState, TypedObject<CompanyLogoAndNameState>>(
    (state: RootState) => (state.fundamental.companyLogoAndName),
  );
  const hasStocksFetched = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall)
  ));

  const hasMyStocksOrderUpdate = useSelector<RootState, boolean>(
    (state: RootState) => state.reporting.hasMyStocksOrderUpdate,
  );

  const tabs = [
    { text: lang.commonMyAccountButtonMyStocks(),
      component: <MyStocksTable
        activeTab={activePosition}
        tableData={positionTableContent}
        chartData={chartData}
        totalValue={totalValue}
        selectedElement={selectedIndex}
        setSelectedElement={setSelectedIndex}
        setActivePosition={setActivePosition}
        hasShortAndLongPos={hasShortAndLongPos}
      />,
    },
    {
      text: lang.commonMyAccountButtonPendingOrders(),
      component: <PendingOrders />,
    },
  ];

  const tradePricesCache = TradePriceCache.useCustom(data => data);
  const { browserWidthSize } = useBrowserWidth();
  const isMobile = useIsMobile();

  useEffect(() => {
    manageSubscriptions('my-account/account-breakdown', null, true, true);
    if (!openPositions.length) {
      dispatch(getOpenPositions());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortedOpenPositions.length) {
      const data = sortedOpenPositions.map((el, ind) => ({
        x: ind,
        y: Math.abs(Number(tradePricesCache[el.symbol]?.currentPrice) * el.quantity),
        symbol: el.symbol,
      }));

      setChartData(data);
    }
  }, [sortedOpenPositions, tradePricesCache]);

  const handleNavigation = (value) => {
    setActivePosition(value);
  };

  useEffect(() => {
    setSelectedIndex({ symbol: '' });
  }, [activePosition]);

  useEffect(() => {
    if (openPositions.length && hasStocksFetched && !hasMyStocksOrderUpdate) {
      const shortPositions = getShortPositions(openPositions);
      const longPositions = getLongPositions(openPositions);
      if (!shortPositions.length || !longPositions.length) {
        setHasShortAndLongPos(false);
      }
      const positionsData = calculateMyStockContent(activePosition, openPositions, tradePricesCache);
      setSortedOpenPositions(sortOpenPositions(positionsData.data));
      setTotalValue(positionsData.value);
    }
  }, [activePosition, openPositions, hasStocksFetched, tradePricesCache, hasMyStocksOrderUpdate]);

  useEffect(() => {
    if (openPositions.length && !isEmpty(companyLogoAndName) && hasStocksFetched && !hasMyStocksOrderUpdate) {
      setPositionTableContent(
        mapMyStocksTableContent(
          sortedOpenPositions,
          tradePricesCache,
          companyLogoAndName,
        ),
      );
    }
  }, [
    companyLogoAndName, hasMyStocksOrderUpdate, hasStocksFetched, openPositions, sortedOpenPositions, tradePricesCache,
  ]);

  useEffect(() => () => {
    dispatch(unsubscribeSymbolStatus());
  }, [dispatch]);

  useEffect(() => {
    if (hasStocksFetched && isMyAccountInitiliazed) {
      const openPosSymols = openPositions.map(el => el.symbol);
      const pendingSymbols = pendingOrders.map(el => el.symbol);
      const symbolsList = uniq([...openPosSymols, ...pendingSymbols]);
      dispatch(subscribeSymbolStatus(symbolsList));
      if (!openPositions.length && !!pendingOrders.length) {
        setCurrentTab(1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStocksFetched, isMyAccountInitiliazed, dispatch]);

  const handleCurrentTabIndex = (index: number) => {
    if (!index) {
      manageSubscriptions('my-account/positions');
    }
    !index
      ? setCurrentTab(0)
      : setCurrentTab(1);
  };

  return (
    <div
      className={classNames(
        styles.wrapperLayout, { [styles.smallScreen]: browserWidthSize <= BASE_WIDTH_SYMBOL_DETAILS },
      )}
    >
      <div className={classNames(styles.initialTableHeight,
        isUserVerified ? styles.wrapperFirstSection : styles.wrapperFirstSectionFullWidth, {
          [styles.noContent]: !chartData.length,
        })}
      >
        {isMobile && (
        <div className={styles.buyingPowerContainer}>
          <div>{lang.mobileOrderCreateBuyingPower()}</div>
          <div>{formatCurrency(buyingPower ?? 0)}</div>
        </div>
        )}
        {isMobile && isUserVerified && (
          <div className={styles.portfolioChart}>
            <span className={styles.portfolioTitle}>{lang.mobilePortfolioTitle()}</span>
            {hasShortAndLongPos && (
            <NavigationButtons
              activeTab={activePosition}
              handleClick={(value) => { setActivePosition(value); return false; }}
            />
            )}
            <svg width="100%" height="290" viewBox="0 0 400 290">
              <AccountBreakDownPieChart
                chartData={chartData}
                selectedChartIndex={selectedIndex}
                totalStockValue={totalValue}
                setSelectedChartIndex={setSelectedIndex}
              />
            </svg>
          </div>
        )}
        <Navigation
          tabs={tabs}
          hasNoContent={(hasStocksFetched && !openPositions.length && !pendingOrders.length) || !isUserVerified}
          noContentComponent={<ErrorContent tabTitle={lang.commonMyAccountNoStocks()} />}
          setActiveTab={currentTab}
          sendCurrentTabIndex={handleCurrentTabIndex}
        />
      </div>
      {currentTab === 0 && !!chartData.length && isUserVerified && (
      <div className={styles.wrapperSecondSection}>
        <div className={styles.wrapperChart}>
          <div className={styles.horizontalLine} />
          {hasShortAndLongPos && <NavigationButtons activeTab={activePosition} handleClick={handleNavigation} />}
          <div className={!hasShortAndLongPos ? styles.wrapperPieChart : ''}>
            <svg width="100%" height="290" viewBox="0 0 400 290">
              <AccountBreakDownPieChart
                chartData={chartData}
                selectedChartIndex={selectedIndex}
                totalStockValue={totalValue}
                setSelectedChartIndex={setSelectedIndex}
              />
            </svg>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default MyAccountTableContent;
