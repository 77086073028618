import { decode as msgPackDecode } from '@msgpack/msgpack';
import { isArray, isString } from 'lodash';

import config from '../../config';
import configLib from '../../configLib';
import { THROTTLE_OPTIONS, THROTTLE_TIME_PRICE_UPDATES_MS, TOKEN_REFRESH_ENABLED } from '../libSettings';
import {
  GWMarketDataSubscriptionSubType,
  GWMarketDataSummaryBucket,
  GWQueryCase,
  GWRequestType,
  GWResponseType,
  SORCommonOrderResponse,
  SOROrderStatus,
  WebSocketReadyStateAsString,
} from '../models/gateway/types';
import { QueriesCacheType } from '../models/trading/types';
import { markAsReadByMetadata } from '../store/ams/index';
import {
  gatewayConnected,
  gatewayDisconnected,
  reconnectEventAction,
  sorOrderEvent,
  webSocketResponseError,
} from '../store/common-actions';
import {
  dataQuery,
  decodingResponseFailed,
  parsingResponseFailed,
} from '../store/gateway';
import { REST_RETRIES_QUICK_DELAY } from '../store/gateway/constants';
import { confirmInitialPriceDataLoaded } from '../store/market-data';
import { ChartDataPayload } from '../store/market-data/charting/types';
import { BBODataCache, InstrumentSnapshotMap } from '../store/market-data/types';
import { hasValidToken } from '../store/selectors';
import { isFinalStatus } from '../store/trading/helpers';
import { updateBigChartCacheOnLoadChartDataCompleted } from '../store-util/BigChartCache';
import CallsCache from '../store-util/calls-cache/CallsCache';
import InitialSymbolsDataLoadingStatusCache, { InitialSymbolsDataLoadingStatusKey } from '../store-util/InitialSymbolsDataLoadingStatusCache';
import { updateInstrumentSnapshotCache } from '../store-util/InstrumentSnapshotCache';
import { updateNBBOPricesCache } from '../store-util/NBBOPriceCache';
import { updateSmallChartDataCache } from '../store-util/SmallChartDataCache';
import { updateStatsCache } from '../store-util/StatsCache';
import { updateSymbolStatusCache } from '../store-util/SymbolStatusCache';
import {
  updateTradePriceCacheFromChartData,
  updateTradePriceCacheFromInstrumentSnapshot,
  updateTradePriceCacheFromTrade,
} from '../store-util/TradePriceCache';
import { isOneOf } from '../util/DataHelpers';
import {
  lz4Unpickle,
  parseBase64String,
  parseGatewayData,
  parseMarketDataSummaries,
  parseRows,
} from '../util/GatewayHelpers';
import {
  dataArrayToMap,
  extractSymbolsFromListAsString,
  subscribeSymbolsWithDataConversion,
} from '../util/MarketDataHelpers';
import { checkFilter } from '../util/ObjectTools';
import { parseNBBODataToString, parseTradeDataToString, uuidv4 } from '../util/tools';

import { ThrottleFuncSetup, throttleFunctionSetupForSymbolDataUpdates } from './helpers';


const PING_ENABLED = true;
const PING_IN_MS = 50000;
const LOGIN_TIMEOUT = 10; // in seconds

const { WebSocket: WebSocketImpl, Response } = configLib;
let queriesCache: QueriesCacheType = {};
let throttleFunctionSetupTrade: ThrottleFuncSetup | null = null;
let throttleFunctionSetupNbbo: ThrottleFuncSetup | null = null;

// TODO: ART-3959 this logic will be refactored
class GatewayWSService {
  private readonly dispatch: any;

  private token?: string;

  private ws?: WebSocket;

  private connected = false;
  private internalDisconnect = false;

  private pingTimeout?: NodeJS.Timeout;

  private reconnectInterval?: NodeJS.Timeout;

  private loginTimeout?: NodeJS.Timeout;

  private loggedIn = false;

  private isReconnect = false;

  constructor(dispatch: any, token = '') {
    this.token = token;
    this.dispatch = dispatch;
    this.handleGWWebsocketMessages = this.handleGWWebsocketMessages.bind(this);
  }

  public isConnected = (): boolean => this.ws?.readyState === WebSocketImpl.OPEN;

  diconnect(): void {
    this.ws?.close();
  }

  public setToken = (token: string): void => {
    this.token = token;
    /**
     * MOBILE does not have refresh capability but the project is refreshable on the WEB browser.
     * That's why MOB and WEB logic have been kept separate.
     */
    if (configLib.isWeb) {
      this.connect(token, true);
      return;
    }

    this.loginRequest();
    this.internalDisconnect = false;
  };

  connect(token: string, isReconnect = false) {
    this.internalDisconnect = false;
    this.isReconnect = isReconnect;
    if (!hasValidToken(null, token)) {
      console.error(`[GW-WS] ${isReconnect ? 'Reconnecting' : 'Connecting'} Websocket with invalid token`);
      return;
    }
    if (this.isConnected()) {
      console.warn(`[GW-WS] ${isReconnect ? 'Reconnecting' : 'Connecting'} Websocket while previous connection is up`);
      return;
    }

    const url = `wss://${config.gateway.url}/push`;
    console.info(`[GW-WS] ${isReconnect ? 'Reconnecting' : 'Connecting'} Websocket - ${url}`);

    this.token = token;
    this.ws = new WebSocketImpl(url);

    this.ws!.onopen = () => {
      const stateAsString = WebSocketReadyStateAsString[this.ws!.readyState];

      if (this.ws!.readyState !== WebSocketImpl.OPEN) {
        console.warn(`[GW-WS] Websocket established event, but not ready yet - ${stateAsString}`);
        return;
      }
      if (this.connected) {
        console.warn(`[GW-WS] Websocket established event, but already connected - ${stateAsString}`);
        return;
      }

      console.info(`[GW-WS] Websocket connection established - ${url}`);
      this.connected = true;
      this.loginRequest();
    };

    /**
     * @param event In mobile (@types/react-native) it is of type WebSocketCloseEvent, in web - it is not recognized
     */
    this.ws!.onclose = (event: any) => {
      const state = this.ws?.readyState;
      const stateAsString = WebSocketReadyStateAsString[state!];

      if (state !== WebSocketImpl.CLOSED) {
        console.warn(`[GW-WS] Service onclose - but still in a non-closed state - ${stateAsString}`);
      } else {
        this.connected = false;
      }

      const { code, reason } = event;
      if (this.internalDisconnect) {
        console.info('[GW-WS] Closed');
      } else {
        console.warn(`[GW-WS] Service disconnected - ${reason} (${code})`);
        console.debug(`[GW-WS] Service disconnected - ${reason} (${code})`, event);
      }

      if (this.pingTimeout != null) clearTimeout(this.pingTimeout);
      this.dispatch(gatewayDisconnected({ code, reason, isInternal: this.internalDisconnect }));
    };

    this.ws!.onmessage = (response: any) => {
      try {
        this.handleGWWebsocketMessages(response);
      } catch (error) {
        if (configLib.__DEV__) {
          const preview = `Error in handleGWWebsocketMessages - ${error}`;
          console.tron?.error?.(`[GW-WS] ${preview}`);
          console.tron.display!({
            name: 'GW-WS-ERROR',
            preview,
            value: { error, response },
            important: true,
          });
        }
      }
    };

    /**
     * @param event In mobile (@types/react-native) it is of type WebSocketErrorEvent, in web - it is not recognized
     */
    this.ws!.onerror = (event: any) => {
      if (this.internalDisconnect) {
        return;
      }

      const errorMessage = 'WebSocket error occurred';
      console.error(`[GW-WS] Error - ${errorMessage}`, event);

      if (this.pingTimeout != null) clearTimeout(this.pingTimeout);
      if (this.reconnectInterval != null) clearInterval(this.reconnectInterval);

      this.dispatch(webSocketResponseError({ errorMessage }));
    };
  }

  private loginRequest(): void {
    this.sendRequest(GWRequestType.Login, {
      token: this.token,
    });
  }

  reconnect(token: string) {
    if (!TOKEN_REFRESH_ENABLED) {
      return;
    }

    if (this.isConnected()) {
      console.info(`[GW-WS] Skipping reconnect - already connected - status: ${this.ws?.readyState}`);
      return;
    }

    console.info(`[GW-WS] ${this.internalDisconnect ? 'SKIP' : ''} Reconnecting ...`);

    if (this.internalDisconnect) {
      return;
    }

    if (!this.ws) {
      console.warn('[GW-WS] reconnect - websocket object not available (this.ws)');
      return;
    }

    this.connect(token, true);
  }

  disconnect() {
    this.internalDisconnect = true;
    console.info('[GW-WS] Disconnect called');
    if (this.pingTimeout != null) clearTimeout(this.pingTimeout);
    this.ws?.close();
  }

  sendRequest(
    messageType: GWRequestType,
    data: any,
    queryCase?: GWQueryCase | GWMarketDataSummaryBucket,
    caller?: string,
  ) {
    const callName = GWRequestType[messageType];
    let requestData = data;

    if (!this.connected || this.ws?.readyState !== WebSocketImpl.OPEN) {
      console.error(`[GW-WS] Attempt to send a '${callName}' request while not connected`);
      return null;
    }

    this.setPingTimeout();

    if (messageType === GWRequestType.Login) this.startLoginTimeout();

    const traceIdentifier = data?.traceIdentifier ?? uuidv4();

    if (![
      GWQueryCase.News, GWQueryCase.Order, GWQueryCase.NewsSubscribe, GWQueryCase.NewsUnSubscribe,
    ].includes(queryCase as GWQueryCase)
      && typeof data?.symbols === 'object'
      && data.symbols.length
    ) {
      const conversionSymbols = subscribeSymbolsWithDataConversion(data.symbols);
      requestData = { ...requestData, symbols: conversionSymbols };
    }
    if (queryCase === GWQueryCase.Order) {
      const { originalClientOrderId, clientOrderId } = data;
      queriesCache[clientOrderId] = data;
      if (originalClientOrderId) queriesCache[originalClientOrderId] = data;
    } else if (queryCase != null) {
      let symbol = data.symbol;
      if (symbol == null) {
        if (isString(data.symbols)) {
          symbol = data.symbols;
        } else {
          symbol = (data.symbols || [ null ])[0];
        }
      }
      queriesCache[traceIdentifier] = {
        queryCase,
        symbol,
        requestData: data,
      };
    }

    const requestBody = {
      ...requestData,
      messageType: callName,
      traceIdentifier,
    };
    this.ws!.send(JSON.stringify(requestBody));

    const { tronLog, logConfig } = require('../../configDebug');

    const isPriceFlowMessage = isOneOf<GWRequestType>(messageType, [
      GWRequestType.InstrumentSnapshot,
      GWRequestType.MarketDataSummary,
      GWRequestType.MarketDataSubscribeTrade,
      GWRequestType.MarketDataUnsubscribeTrade,
      GWRequestType.MarketDataSubscribeNbbo,
      GWRequestType.MarketDataUnsubscribeNbbo,
      GWRequestType.MarketDataQuery,
    ]);
    let debugSymbols = '';
    if (configLib.__DEV__) debugSymbols = extractSymbolsFromListAsString(data?.symbols, true);
    const chartBucket = data?.bucket ? `, ${data?.bucket}` : '';
    if (logConfig.priceFlow && isPriceFlowMessage) {
      console.log(`[GW-WS] Request <${messageType}>${chartBucket} [price-flow] -- ${debugSymbols}`);
      console.tron.display!({
        name: 'GW-WS',
        preview: `Request <${messageType}>${chartBucket} [price-flow] -- ${debugSymbols}`,
        value: { requestBody, queryCache: queriesCache[traceIdentifier] },
        important: false,
      });
    }

    console.info(`[GW-WS] Request '${callName}'${caller ? `,${caller}` : ''}${chartBucket} (${traceIdentifier}) ${debugSymbols ? `\n${debugSymbols}` : ''}`);
    console.debug(`[GW-WS] Request '${callName}'${chartBucket}  (${traceIdentifier}) ${debugSymbols ? `\n${debugSymbols}` : ''}`, { requestBody, queryCase, queriesCache });
    if (checkFilter(tronLog.gw, callName)) {
      console.tron.display({
        name: 'GW-WS-req',
        preview: `Request '${callName || ''}${chartBucket}' (${traceIdentifier}) -- ${debugSymbols}`,
        value: requestBody,
        important: false,
      });
    }

    return traceIdentifier;
  }

  async handleGWWebsocketMessages(response: any) {
    const { logConfig } = require('../../configDebug');

    let { data: dataRaw } = response;
    let isJson = isString(dataRaw);
    let debugSymbols = '';

    this.setPingTimeout();

    if (!isJson && configLib.isWeb) {
      dataRaw = await (new Response(dataRaw)).arrayBuffer();
    }

    let data: any;
    if (isJson) {
      try {
        data = JSON.parse(dataRaw);
      } catch (error) {
        console.error(`[GW-WS] Error parsing JSON message with length ${dataRaw?.length} chars - ${error}`);
        console.debug(`[GW-WS] Error parsing JSON message with length ${dataRaw?.length} chars`, { dataRaw, error });
        throw error;
      }
    } else {
      data = dataRaw;
      try {
        let uint8Array = new Uint8Array(dataRaw);
        let uncompressed = lz4Unpickle(uint8Array);
        data = msgPackDecode(uncompressed);
      } catch (error) {
        this.dispatch(decodingResponseFailed(`${error}`));
        return;
      }
    }

    let {
      messageType: messageName,
      accessId,
      isCompressed,
      traceIdentifier,
      estimatedDeliveryInSec,
      httpCode,
      resultCode,
    } = data || {};
    let messageType: GWResponseType = GWResponseType[messageName] as GWResponseType ?? messageName;
    let messageSubType: keyof GWMarketDataSubscriptionSubType | any; // TODO: Exhaust all variants instead of any
    let extraInfo = ''; // used in logging only

    // Ignore estimatedDeliveryInSec to get responses as quickly as possible (ART-508)
    // (from GW it comes as a multiple of 10 seconds without real estimation)
    if (estimatedDeliveryInSec > 0) {
      estimatedDeliveryInSec = REST_RETRIES_QUICK_DELAY;
    }

    let statusCode = resultCode || httpCode;
    if (statusCode > 299) {
      // TODO: Review to migrate to a dedicated error handler
      console.error(`[GW-WS] Error code > 299 - caught in handleGWWebsocketMessages - ${messageName} (${statusCode})`, data);
      this.dispatch(webSocketResponseError(data));
      if (messageName !== GWResponseType.OrderRejectedResponse) return;
    }
    let parsedData: any;

    if (isJson) {
      switch (messageType) {
        case GWResponseType[GWResponseType.Login]:
          this.stopLoginTimeout();
          if (this.isReconnect) {
            this.isReconnect = false;
            this.dispatch(reconnectEventAction('GatewayWS'));
            break;
          }
          this.dispatch(gatewayConnected());
          break;

        case GWResponseType[GWResponseType.Subscriptions]:
          CallsCache.processResponseByTraceId(traceIdentifier, 'subscribe');
          break;

        case GWResponseType[GWResponseType.MarketDataQuery]:
        case GWResponseType[GWResponseType.DataQueryResponse]:
          this.dispatch(dataQuery({
            accessId,
            traceIdentifier,
            estimatedDeliveryInSec: REST_RETRIES_QUICK_DELAY,
            cacheData: queriesCache[traceIdentifier],
          }));
          extraInfo = `, accessId=${accessId}`;
          break;

        /**
         * The two cases are needed since the response have been observed to be
         * either `MarketDataSummaryResponse` or `DataSummaryResponse`
         */
        case GWResponseType.MarketDataSummaryResponse:
        case GWResponseType[GWResponseType.MarketDataSummaryResponse as any]: {
          try {
            const { summaries: summariesRaw } = data || {};
            const summaries = parseBase64String(summariesRaw);
            parsedData = parseMarketDataSummaries(summaries.rows);
            const symbols = Object.keys(parsedData);
            debugSymbols = extractSymbolsFromListAsString(symbols, true);
            const cacheData = queriesCache[traceIdentifier] || {};
            const { queryCase } = cacheData;
            const { bucket } = cacheData.requestData || {};
            const symbol = symbols?.[0];
            extraInfo = ` queryCase: ${(GWQueryCase as any)[queryCase]} bucket:${bucket}, symbols:${symbols}, size:${symbols?.length}`;

            if (isOneOf(bucket, [ GWMarketDataSummaryBucket.OneDay, GWMarketDataSummaryBucket.ThreeMonths ])) {
              updateTradePriceCacheFromChartData({ bucket, data: parsedData });
            }

            if (bucket === GWMarketDataSummaryBucket.OneDay) {
              updateSmallChartDataCache(parsedData, traceIdentifier);
            }

            if (queryCase === GWQueryCase.BigChartData) {
              updateBigChartCacheOnLoadChartDataCompleted(
                {
                  symbol,
                  bucket,
                  data: parsedData[symbol],
                } as ChartDataPayload,
                traceIdentifier,
              );
            }
            data = { bucket, parsedData, summaries };
            InitialSymbolsDataLoadingStatusCache.updateStatus(`chart_${bucket as '1D5m' | '3M1D'}` as InitialSymbolsDataLoadingStatusKey, true);
          } catch (error) {
            this.dispatch(confirmInitialPriceDataLoaded());// If we don't call this action here then appConnected action will not be triggered!
            console.error(`[GW-WS] handleGWWebsocketMessages - error parsing '${messageType}' - ${error}`);
            console.tron?.error?.(`[GW-WS] handleGWWebsocketMessages - error parsing '${messageType}' - ${error}`);
          }
          break;
        }

        case GWResponseType.ExecutionReport: {
          const { status } = data || {};
          switch (status) {
            case SOROrderStatus.Canceled:
            case SOROrderStatus.Expired:
            case SOROrderStatus.Fill:
            case SOROrderStatus.New:
            case SOROrderStatus.Rejected:
            case SOROrderStatus.Replace:
            case SOROrderStatus.PartialFill:
            case SOROrderStatus.PartiallyReject:
            case SOROrderStatus.PendingCancel:
            case SOROrderStatus.PendingNew:
            case SOROrderStatus.PendingReplace: {
              const cache = (queriesCache[data.clientOrderId] as any) as (SORCommonOrderResponse | null);
              this.dispatch(sorOrderEvent({ ...data, requestType: cache?.messageType }));
              if (cache?.messageType) {
                if (cache && isFinalStatus(data?.status)) {
                  delete queriesCache[data?.clientOrderId];
                }
              }
              break;
            }
            default:
              console.error(`[GW-WS] handleGWWebsocketMessages - unhandled status for '${messageType}' - '${SOROrderStatus}' (${status})`);
              break;
          }
          break;
        }
        case GWResponseType.OrderRejectedResponse:
        case GWResponseType.ModifyOrCancelReject: {
          const cache = (queriesCache[data.clientOrderId] ?? {}) as any as SORCommonOrderResponse;
          this.dispatch(sorOrderEvent({ ...data, requestType: cache?.messageType }));
          if (cache?.messageType) {
            if (cache && isFinalStatus(data?.status)) {
              delete queriesCache[data?.clientOrderId];
            }
          }
          break;
        }
        case GWResponseType.Error: {
          // TODO handle error
          break;
        }
        case GWResponseType.InstrumentSnapshotResponse: {
          try {
            const { snapshots: snapshotsRaw } = data || {};
            const snapshots = parseBase64String(snapshotsRaw);
            parsedData = parseRows(snapshots.rows, snapshots);
            const finalData = dataArrayToMap(parsedData);
            const cacheData = queriesCache[traceIdentifier] || {};
            const { symbols, includePreMarket: pre, includePostMarket: post } = cacheData.requestData || {};
            const symbol = symbols && symbols.length === 1 ? symbols[0] : null;
            const symbolsReceived = Object.keys(finalData);
            extraInfo = ` symbol:${symbol} symbols:${symbols}, pre:${pre} post:${post}  size:${parsedData?.length}`;
            debugSymbols = extractSymbolsFromListAsString(symbolsReceived, true);

            if (symbolsReceived?.length !== symbols?.length) {
              const diff: string[] = [];
              (symbols as string[]).forEach(current => {
                if (!symbolsReceived.includes(current)) {
                  diff.push(current);
                }
              });
              console.warn(`[GW-WS] handleGWWebsocketMessages - symbols count for InstrumentSnapshot request and response differs - request:${symbols?.length} response:${symbolsReceived?.length} -- difference: ${diff}`, { symbols: symbols.concat().sort(), symbolsReceived, diff });
            }

            parsedData = {
              raw: data,
              parsed1_base64: snapshots,
              parsed2_rows: parsedData,
              final: finalData,
              symbols,
              debugSymbols,
            };
            data = {
              symbolOrSymbols: symbol ?? symbols,
              queryCache: cacheData,
              data: finalData,
            };

            if (logConfig.enabled && logConfig.priceFlow) {
              console.log(`[GW-WS] Response InstrumentSnapshot [price-flow] -- ${debugSymbols}`);
              console.tron.display!({
                name: 'GW-WS-msg',
                preview: `Response InstrumentSnapshot [price-flow] -- ${debugSymbols}`,
                value: { parsedData, queryCache: cacheData },
                important: false,
              });
            }
            InitialSymbolsDataLoadingStatusCache.updateStatus('instrumentSnapshot', true);
            updateInstrumentSnapshotCache(finalData as InstrumentSnapshotMap, traceIdentifier);
            updateStatsCache(finalData as InstrumentSnapshotMap);
            updateTradePriceCacheFromInstrumentSnapshot(finalData as InstrumentSnapshotMap);
            updateNBBOPricesCache(finalData as InstrumentSnapshotMap, 'instr');
          } catch (error) {
            console.error(`[GW-WS] handleGWWebsocketMessages - error parsing '${messageType}' - ${error}`);
          }
          break;
        }

        default:
          console.error(`[GW-WS] Unhandled JSON response '${messageName}'`, {
            response, data, messageType,
          });
          break;
      }
    } else if (isArray(data)) {
      messageType = data[0];
      messageName = GWResponseType[messageType as any];
      messageSubType = data[1][0];

      switch (messageType) {
        case GWResponseType.MarketData: {
          messageName += `/${GWMarketDataSubscriptionSubType[messageSubType]}`;
          try {
            parsedData = parseGatewayData(data);
          } catch (error) {
            this.dispatch(parsingResponseFailed(`${error}`));
            return;
          }
          switch (messageSubType) {
            case GWMarketDataSubscriptionSubType.Trade:
              parsedData = {
                raw: data,
                final: dataArrayToMap(parsedData),
              };
              if (logConfig.tradePriceUpdates) {
                const items = Object.keys(parsedData?.final);
                const debugTitle = `[GW-WS] Trade price update (${items?.length} | ${items})`;
                const debugData = parseTradeDataToString(parsedData.raw).join('\n');
                console.debug(`${debugTitle}:\n${debugData}`);
                console.tron.display!({
                  name: 'GW-WS',
                  priview: debugTitle,
                  value: parsedData,
                  important: false,
                });
              }
              if (THROTTLE_TIME_PRICE_UPDATES_MS) {
                const { enabled, throttleTradePriceUpdates } = logConfig;
                const throttleLogEnabledTrade = enabled && throttleTradePriceUpdates && configLib.__DEV__;

                if (throttleFunctionSetupTrade) {
                  throttleFunctionSetupTrade.setData(parsedData.final);
                  throttleFunctionSetupTrade.call();
                } else {
                  throttleFunctionSetupTrade = throttleFunctionSetupForSymbolDataUpdates(
                    'trade',
                    {
                      logPrefixDataUpdate: '[GW-WS][Trade-Throttle] PRICE UPDATE',
                      logPrefixStartAndFirstCall: '[GW-WS][Trade-Throttle] START & First Call',
                      logPrefixExec: '[GW-WS][Trade-Throttle] EXEC',
                      logEnabled: throttleLogEnabledTrade,
                    },
                    parsedData.final,
                    THROTTLE_TIME_PRICE_UPDATES_MS,
                    THROTTLE_OPTIONS,
                    updateTradePriceCacheFromTrade,
                  );
                }
              } else {
                updateTradePriceCacheFromTrade(parsedData.final);
              }
              break;

            case GWMarketDataSubscriptionSubType.Nbbo:
              parsedData = {
                raw: data,
                final: dataArrayToMap(parsedData),
              };
              if (logConfig.nbboPriceUpdates) {
                const items = Object.keys(parsedData?.final);
                const debugTitle = `[GW-WS] NBBO price update (${items?.length} | ${items})`;
                console.debug(`${debugTitle}\n${parseNBBODataToString(parsedData.raw).join('\n')}`);
                console.tron.display({ name: 'GW-WS', preview: debugTitle, value: parsedData });
              }
              if (THROTTLE_TIME_PRICE_UPDATES_MS) {
                const { enabled, throttleNbboPriceUpdates } = logConfig;
                const throttleLogEnabledNbbo = enabled && throttleNbboPriceUpdates && configLib.__DEV__;

                if (throttleFunctionSetupNbbo) {
                  throttleFunctionSetupNbbo.setData(parsedData.final);
                  throttleFunctionSetupNbbo.call();
                } else {
                  throttleFunctionSetupNbbo = throttleFunctionSetupForSymbolDataUpdates(
                    'nbbo',
                    {
                      logPrefixDataUpdate: '[GW-WS][Nbbo-Throttle] PRICE UPDATE',
                      logPrefixStartAndFirstCall: '[GW-WS][Nbbo-Throttle] START & First Call',
                      logPrefixExec: '[GW-WS][Nbbo-Throttle] EXEC',
                      logEnabled: throttleLogEnabledNbbo,
                    },
                    parsedData.final,
                    THROTTLE_TIME_PRICE_UPDATES_MS,
                    THROTTLE_OPTIONS,
                    (theData: BBODataCache) => updateNBBOPricesCache(theData, 'nbbo'),
                  );
                }
              } else {
                updateNBBOPricesCache(parsedData.final, 'nbbo');
              }
              break;

            case GWMarketDataSubscriptionSubType.SymbolStatus: {
              parsedData = {
                raw: data,
                final: dataArrayToMap(parsedData),
              };

              updateSymbolStatusCache(parsedData.final);
              break;
            }
            default:
              console.error(`[GW-WS] Unknown messageSubType '${messageSubType}' for message '${messageType}'`);
              break;
          }
          break;
        }

        default:
          console.error(`[GW-WS] Unknown response '${messageType}' / subtype '${messageSubType}' case`);
          break;
      }
    }

    if (configLib.__DEV__) {
      if (!messageName?.match(/MarketData.(Nbbo|Trade)/) || logConfig.gatewayWSMessagesPriceUpdates) {
        if (logConfig.gatewayWSMessages) {
          const traceId = ` (trace id: ${traceIdentifier ?? '<n/a>'})`;
          const title = `[GW-WS] Message '${messageName}' (${isJson ? 'json' : 'msgpack'}), ${isJson
            ? `${response.data.length} chars`
            : `${dataRaw.byteLength} bytes`
          }${traceId}${extraInfo}${isCompressed ? ', is compressed' : ''}`;
          console.info(title);
          console.debug(title, { data, response, isCompressed, parsedData, queriesCache });
          console.tron.display({
            name: `GW-WS-msg${isJson ? 'J' : 'M'}`,
            preview: `msg${isJson ? 'J' : 'M'} '${messageName || ''}' ${traceId}}  --  ${extraInfo}`,
            value: data,
            important: false,
          });
        }
      }
    }


    // handling msgpack encoded responses only
    switch (messageType) {
      case GWResponseType.MarketData:
        if (messageSubType === GWMarketDataSubscriptionSubType.Query) {
          this.dispatch(dataQuery({
            accessId,
            traceIdentifier,
            estimatedDeliveryInSec,
            cacheData: queriesCache[traceIdentifier],
          }));
        }
        break;

      default:
        if (!isJson) console.error(`[GW-WS] Unknown message type '${messageType}'`);
        break;
    }

    // clear query cache
    if (queriesCache[traceIdentifier]) delete queriesCache[traceIdentifier];
  }

  private startLoginTimeout() {
    this.loggedIn = false;
    if (this.loginTimeout) clearTimeout(this.loginTimeout);
    this.loginTimeout = setTimeout(() => {
      if (!this.loggedIn) console.error(`[GW-WS] No login message after ${LOGIN_TIMEOUT} seconds`);
    }, LOGIN_TIMEOUT * 1000) as any as NodeJS.Timeout;
  }

  private stopLoginTimeout() {
    if (this.loginTimeout) clearTimeout(this.loginTimeout);
  }

  private setPingTimeout() {
    if (!PING_ENABLED) return;

    if (this.pingTimeout != null) clearTimeout(this.pingTimeout);
    this.pingTimeout = (
      setTimeout(() => this.sendRequest(GWRequestType.Ping, null), PING_IN_MS) as any as NodeJS.Timeout
    );
  }
}

export default GatewayWSService;
