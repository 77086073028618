import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import { Action } from 'redux';

import { DEFAULT_DATERANGEPICKER_FORMAT } from '../../../lib/constants/datePickerConstants';
import { EnrollChanelEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import { CountryData, ENROLL_METHOD, ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import { EnrollIndividualRequestData, EnrollIndividualTaxDetailData, GenderTypeEnum } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import {
  clearCrmEnrollStatusByCode as storeClearCrmEnrollStatusByCode,
  enrollPersonalDetailsWithTaxDetails as storeEnrollPersonalDetailsWithTaxDetails,
} from '../../../lib/store/crm';
import { EnrollDetailsEnum, hasEnrollDetails } from '../../../lib/store/selectors';
import CountriesCache from '../../../lib/store-util/CountriesCache';
import { getDateTime } from '../../../lib/util/DateTimeHelpers';
import { checkErrorSatusCode } from '../../../lib/util/error-handling/ErrorHandlingHelpers';
import {
  createFormControlError,
  validateControl,
  validationArrayCity,
  validationArrayName,
  validationArrayTaxNumber,
} from '../../../lib/util/ValidationSchemes/Validate';
import { ControlValidation, ControlValidity } from '../../../lib/util/ValidationSchemes/ValidationTypes';

import { EnrollProgressBarNextQuestionAction } from '../../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';
import { ButtonTypesEnum } from '../../Button/Button.types';
import { ICountryControlOptions } from '../../CountryControl/CountryControl.types';
import { Button, CheckboxControl, CountryControl, DatepickerControl, InputControl } from '../../index';
import EnrollLayout from '../EnrollLayout/EnrollLayout';
import { EnrollProgressBarData } from '../ProgressBar/ProggressBar.types';

import {
  EnrollDateOfBirthSelector,
  EnrollFirstNameSelector,
  EnrollLastNameSelector,
  EnrollMiddleNameSelector,
  EnrollOtherCitizenshipIdSelector,
  EnrollOtherTaxCountryIdSelector,
  EnrollOtherTaxNumberSelector,
  EnrollPlaceOfBirthSelector,
  EnrollPrimaryCitizenshipSelector,
  EnrollSecondaryCitizenshipIdSelector,
  EnrollSecondaryTaxCountryIdSelector,
  EnrollTaxNumberSelector,
  InitialEnrollPersonalDetailsForm,
} from './EnrollPersonalDetails.constants';
import { EnrollPersonalDetailsForm } from './EnrollPersonalDetails.types';

import styles from './EnrollPersonalDetails.module.scss';

type OwnProps = {
  isBack: boolean;
  setIsBack: Dispatch<React.SetStateAction<boolean>>;
  progressData: EnrollProgressBarData;
  progressBarDispatch: Dispatch<{ type: string }>;
};

type Props = OwnProps & ReduxProps;

const EnrollPersonalDetails = (props: Props) => {
  const {
    isBack,
    setIsBack,
    progressBarDispatch,
    progressData,
    individual,
    isEdit,
    statusByCall,
    requestState,
    callsInQueue,

    enrollPersonalDetailsWithTaxDetails,
    clearCrmEnrollStatusByCode,
  } = props;

  const history = useHistory();

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [formData, setFormData] = useState<EnrollPersonalDetailsForm>(InitialEnrollPersonalDetailsForm);
  const [hasTaxDetaisEdited, setHasTaxDetaisEdited] = useState<boolean>(false);

  const [isTaxCountrySame, setIsTaxCountrySame] = useState<boolean>(true);
  const [isCitizenshipSame, setIsCitizenshipSame] = useState<boolean>(true);
  const [isAddNewCitizenshipClicked, setIsAddNewCitizenshipClicked] = useState<boolean>(false);
  const [isAddAnotherTaxCountryClicked, setIsAddAnotherTaxCountryClicked] = useState<boolean>(false);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);
  const [taxIndexError, setTaxIndexError] = useState<string>('');

  const countries = CountriesCache.use();
  const countryOptions = useMemo((): ICountryControlOptions[] => {
    if (countries && (countries.length > 0)) {
      return countries
        .filter((country: CountryData) => country.id && country.name)
        .sort((current: CountryData, next: CountryData) => {
          if (current.name.toLowerCase() < next.name.toLowerCase()) {
            return -1;
          } if (current.name.toLowerCase() > next.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((country: CountryData) => ({
          label: country.name,
          value: country.id,
        }));
    }

    return [];
  }, [countries]);

  useEffect(() => {
    if (individual?.tax_details) {
      if ((individual?.tax_details.length > 1
        && (formData[EnrollTaxNumberSelector] !== individual?.tax_details[0].tax_number
          || formData[EnrollOtherTaxNumberSelector] !== individual?.tax_details[1].tax_number))
          || (individual?.tax_details.length === 1
        && (formData[EnrollTaxNumberSelector] !== individual?.tax_details[0].tax_number
          || formData[EnrollOtherTaxNumberSelector]))
          || (!individual?.tax_details.length
        && (formData[EnrollTaxNumberSelector] || formData[EnrollOtherTaxNumberSelector]))
          || (formData[EnrollOtherTaxNumberSelector]
        && !isAddAnotherTaxCountryClicked)) {
        setHasTaxDetaisEdited(true);
      } else {
        setHasTaxDetaisEdited(false);
      }
    }
  }, [formData, individual, isAddAnotherTaxCountryClicked]);

  const individualData = useMemo((): EnrollIndividualRequestData => {
    const nationalities: string[] = [];
    if (isCitizenshipSame
      || formData[EnrollSecondaryCitizenshipIdSelector] === formData[EnrollPrimaryCitizenshipSelector]) {
      nationalities.unshift(formData[EnrollPrimaryCitizenshipSelector]);
    } else {
      nationalities.unshift(formData[EnrollSecondaryCitizenshipIdSelector]!);
    }

    if (isAddNewCitizenshipClicked
      && formData[EnrollOtherCitizenshipIdSelector]
      && formData[EnrollOtherCitizenshipIdSelector] !== formData[EnrollPrimaryCitizenshipSelector]) {
      nationalities.push(formData[EnrollOtherCitizenshipIdSelector]!);
    }

    return ({
      first_name: formData[EnrollFirstNameSelector],
      middle_name: formData[EnrollMiddleNameSelector] ?? undefined,
      last_name: formData[EnrollLastNameSelector],
      birth_date: formData[EnrollDateOfBirthSelector],
      birth_place_id: formData[EnrollPrimaryCitizenshipSelector],
      birth_place_location: formData[EnrollPlaceOfBirthSelector],
      gender: GenderTypeEnum.unspecified,
      enroll_channel: EnrollChanelEnum.Web,
      nationalities,
    });
  }, [isCitizenshipSame, formData, isAddNewCitizenshipClicked]);

  const taxDetails = useMemo((): EnrollIndividualTaxDetailData[] => {
    const countryId = (!isTaxCountrySame && formData[EnrollSecondaryTaxCountryIdSelector])
      ? formData[EnrollSecondaryTaxCountryIdSelector]
      : formData[EnrollPrimaryCitizenshipSelector];

    const id = (individual && (individual.tax_details.length > 0)) ? individual.tax_details[0].id : null;

    const taxDetailsData = [{
      id,
      country_id: countryId,
      tax_number: formData[EnrollTaxNumberSelector],
    } as EnrollIndividualTaxDetailData];

    if ((isAddAnotherTaxCountryClicked
      && formData[EnrollOtherTaxCountryIdSelector]
      && formData[EnrollOtherTaxNumberSelector]) || (
      !isAddAnotherTaxCountryClicked
      && individual
      && individual.tax_details?.length > 1
    )) {
      const secondaryTaxDetailId = (individual && individual.tax_details[1]) ? individual.tax_details[1].id : null;

      taxDetailsData.push({
        id: secondaryTaxDetailId,
        country_id: formData[EnrollOtherTaxCountryIdSelector]!,
        tax_number: formData[EnrollOtherTaxNumberSelector]!,
      });
    }

    return taxDetailsData;
  }, [isTaxCountrySame, formData, individual, isAddAnotherTaxCountryClicked]);

  const goBack = useCallback((): void => {
    history.push('');
  }, [history]);

  const goNext = useCallback((): void => {
    setIsBack(false);
    setTaxIndexError('');
    const data = {
      ...individualData,
      first_name: individualData.first_name.trim(),
      middle_name: individualData.middle_name?.trim(),
      last_name: individualData.last_name.trim(),
    };

    enrollPersonalDetailsWithTaxDetails(data, taxDetails, isAddAnotherTaxCountryClicked);
  }, [setIsBack, individualData, enrollPersonalDetailsWithTaxDetails, taxDetails, isAddAnotherTaxCountryClicked]);

  const AddAnotherEntityButton = ({ isClicked, isCitizenship = false }: any): JSX.Element => (
    <Button
      id={isCitizenship ? 'addCitizenship' : 'addTaxCountry'}
      ariaLabel={isCitizenship ? 'add citizenship button' : 'add tax country button'}
      variant={ButtonTypesEnum.Link}
      onClick={() => {
        isCitizenship ? setIsAddNewCitizenshipClicked(!isClicked) : setIsAddAnotherTaxCountryClicked(!isClicked);
      }}
    >
      <div className={styles.button}>
        <span
          className={styles.sign}
          data-content={isClicked ? '-' : '+'}
        />
        <span>
          {
            isCitizenship
              ? lang.commonOnboardingPersonalDetailsAddAnotherCitizenship()
              : lang.commonOnboardingPersonalDetailsAddAnotherTaxCountry()
          }
        </span>
      </div>
    </Button>
  );

  useEffect(() => {
    if (!isInitialised) {
      if (individual) {
        const updatedFormData: EnrollPersonalDetailsForm = {
          ...formData,
          [EnrollFirstNameSelector]: individual.first_name,
          [EnrollMiddleNameSelector]: individual.middle_name ?? null,
          [EnrollLastNameSelector]: individual.last_name,
          [EnrollDateOfBirthSelector]: individual.birth_date,
          [EnrollPlaceOfBirthSelector]: individual.birth_place_location,
          [EnrollPrimaryCitizenshipSelector]: individual.birth_place_id,
        };

        const secondaryCitizenship = individual!.nationalities[0];
        if (secondaryCitizenship !== individual.birth_place_id) {
          updatedFormData[EnrollSecondaryCitizenshipIdSelector] = secondaryCitizenship;
          setIsCitizenshipSame(false);
        }

        if (individual!.nationalities.length > 1) {
          // eslint-disable-next-line prefer-destructuring
          updatedFormData[EnrollOtherCitizenshipIdSelector] = individual!.nationalities[1];
          setIsAddNewCitizenshipClicked(true);
        }

        if (individual.tax_details && (individual.tax_details.length > 0)) {
          const countryIsSame = (individual.tax_details[0].country_id === individual.birth_place_id);

          updatedFormData[EnrollTaxNumberSelector] = individual.tax_details[0].tax_number;
          updatedFormData[EnrollSecondaryTaxCountryIdSelector] = countryIsSame ? null
            : individual.tax_details[0].country_id;

          setIsTaxCountrySame(countryIsSame);

          if (individual.tax_details[1]) {
            updatedFormData[EnrollOtherTaxNumberSelector] = individual.tax_details[1].tax_number;
            updatedFormData[EnrollOtherTaxCountryIdSelector] = individual.tax_details[1].country_id;

            setIsAddAnotherTaxCountryClicked(true);
          }
        }

        setFormData(updatedFormData);
      }

      setIsInitialised(true);
    }
  }, [individual, isInitialised, formData]);

  useEffect(() => {
    if ((hasTaxDetaisEdited
      && (isEdit && (requestState === ENROLL_REQUEST_STATE.READY) && !isBack && !callsInQueue && !taxIndexError))
      || (!hasTaxDetaisEdited && isEdit && (requestState === ENROLL_REQUEST_STATE.READY) && !isBack)
    ) {
      progressBarDispatch({ type: EnrollProgressBarNextQuestionAction });
      clearCrmEnrollStatusByCode();
    }
  }, [
    callsInQueue, hasTaxDetaisEdited, isBack, isEdit, requestState, taxIndexError,
    clearCrmEnrollStatusByCode, progressBarDispatch,
  ]);

  useEffect(() => {
    if (statusByCall) {
      const errorCalls = Object.keys(statusByCall).filter(key => {
        const callMethod = key.substring(0, key.indexOf('_'));
        if (
          callMethod === ENROLL_METHOD.postIndividualTaxDetailOwnBatch.toString()
          || callMethod === ENROLL_METHOD.putIndividualTaxDetailOwn.toString()
          || callMethod === ENROLL_METHOD.deleteTaxDetail.toString()) {
          return statusByCall[key].requestState === ENROLL_REQUEST_STATE.REQUEST_ERROR;
        }
        return false;
      });
      if (errorCalls) {
        for (let index = 0; index < errorCalls.length; index += 1) {
          const currentIndex = errorCalls[index];
          const fieldIndex = currentIndex.substring(currentIndex.indexOf('_') + 1);
          const formKey = fieldIndex === '1' ? EnrollTaxNumberSelector : EnrollOtherTaxNumberSelector;

          setTaxIndexError(fieldIndex);

          const errorMessage = createFormControlError(
            checkErrorSatusCode(
              Number(statusByCall[currentIndex].errorCode),
              lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumberAlreadyInUseMessage(),
              lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumberServerErrorMessage(),
            ),
          );

          if (formKey === EnrollOtherTaxNumberSelector && !isAddAnotherTaxCountryClicked) {
            setTaxIndexError('');
          }
        }
      } else {
        setTaxIndexError('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusByCall]);

  const getControlValidity = (formDataKey: string): ControlValidity => {
    let label: string = '';
    let isRequired = false;
    let validations: ControlValidation[] = [];

    switch (formDataKey) {
      case EnrollFirstNameSelector:
      case EnrollLastNameSelector:
        isRequired = true;

        label = formDataKey === EnrollFirstNameSelector
          ? lang.commonOnboardingPersonalDetailsFirstName()
          : lang.commonOnboardingPersonalDetailsLastName();

        validations = validationArrayName(label);
        break;
      case EnrollMiddleNameSelector:
        label = lang.commonOnboardingPersonalDetailsMiddleName();
        validations = validationArrayName(label);
        break;
      case EnrollTaxNumberSelector:
        isRequired = true;
        label = lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber();
        validations = validationArrayTaxNumber;
        break;
      case EnrollDateOfBirthSelector:
        isRequired = true;
        label = lang.commonOnboardingPersonalDetailsDateOfBirth();
        validations = [];
        break;
      case EnrollPlaceOfBirthSelector:
        isRequired = true;
        label = lang.commonOnboardingPersonalDetailsPlaceOfBirth();
        validations = validationArrayCity(lang.commonOnboardingPersonalDetailsPlaceOfBirth());
        break;
      case EnrollPrimaryCitizenshipSelector:
        isRequired = true;
        label = lang.commonOnboardingPersonalDetailsCountryOfBirth();
        break;
      case EnrollSecondaryCitizenshipIdSelector:
        isRequired = !isCitizenshipSame;
        label = lang.commonOnboardingPersonalDetailsCitizenship();
        break;
      case EnrollSecondaryTaxCountryIdSelector:
        isRequired = !isTaxCountrySame;
        label = lang.commonOnboardingPersonalDetailsTaxDetailsTaxCountry();
        break;
      case EnrollOtherCitizenshipIdSelector:
        isRequired = isAddNewCitizenshipClicked;
        label = lang.commonOnboardingPersonalDetailsCitizenship();
        break;
      case EnrollOtherTaxNumberSelector:
        isRequired = isAddAnotherTaxCountryClicked;
        validations = isAddAnotherTaxCountryClicked ? validationArrayTaxNumber : [];
        label = lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber();
        break;
      case EnrollOtherTaxCountryIdSelector:
        isRequired = isAddAnotherTaxCountryClicked;
        label = lang.commonOnboardingPersonalDetailsTaxDetailsTaxCountry();
        break;
    }

    return validateControl(
      formData[formDataKey],
      validations,
      label,
      isRequired,
    );
  };

  const validateForm = (): boolean => (
    Object.keys(formData)
      .map((formDataKey: string) => {
        const controlCheck = getControlValidity(formDataKey);

        return controlCheck.isValid;
      })
      .every((check: boolean) => check)
  );

  const handleCheckBoxCitizenship = (value: boolean) => {
    const updatedFormData: EnrollPersonalDetailsForm = {
      ...formData,
      [EnrollOtherCitizenshipIdSelector]: null,
    };
    setFormData(updatedFormData);
    setIsAddNewCitizenshipClicked(false);
    setIsCitizenshipSame(value);
  };

  const handleAddAnotherCitizenship = () => {
    if (!isCitizenshipSame && formData[EnrollSecondaryCitizenshipIdSelector]) {
      return countryOptions.filter(country =>
        country.value !== formData[EnrollSecondaryCitizenshipIdSelector]);
    } if (!isCitizenshipSame && !formData[EnrollSecondaryCitizenshipIdSelector]) {
      return countryOptions;
    }
    return countryOptions.filter(country =>
      country.value !== formData[EnrollPrimaryCitizenshipSelector]);
  };

  const handleCheckBoxTaxCountry = (value: boolean) => {
    const updatedFormData: EnrollPersonalDetailsForm = {
      ...formData,
      [EnrollOtherTaxCountryIdSelector]: null,
      [EnrollOtherTaxNumberSelector]: '',
    };
    setFormData(updatedFormData);
    setIsAddAnotherTaxCountryClicked(false);
    setIsTaxCountrySame(value);
  };

  const handleAddAnotherTaxCountry = () => {
    if (!isTaxCountrySame && formData[EnrollSecondaryTaxCountryIdSelector]) {
      return countryOptions.filter(country =>
        country.value !== formData[EnrollSecondaryTaxCountryIdSelector]);
    } if (!isTaxCountrySame && !formData[EnrollSecondaryTaxCountryIdSelector]) {
      return countryOptions;
    }
    return countryOptions.filter(country =>
      country.value !== formData[EnrollPrimaryCitizenshipSelector]);
  };

  useEffect(() => {
    if ((!isTaxCountrySame) && individual) {
      const updatedFormData: EnrollPersonalDetailsForm = {
        ...formData,
        [EnrollSecondaryTaxCountryIdSelector]:
        formData[EnrollSecondaryTaxCountryIdSelector] === individual.birth_place_id
          ? individual.birth_place_id
          : formData[EnrollSecondaryTaxCountryIdSelector],
      };
      setFormData(updatedFormData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaxCountrySame]);

  useEffect(() => {
    if ((!isCitizenshipSame) && individual) {
      const updatedFormData: EnrollPersonalDetailsForm = {
        ...formData,
        [EnrollSecondaryCitizenshipIdSelector]:
        formData[EnrollSecondaryCitizenshipIdSelector] === individual.birth_place_id
          ? individual.birth_place_id
          : formData[EnrollSecondaryCitizenshipIdSelector],
      };
      setFormData(updatedFormData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCitizenshipSame]);

  useEffect(() => {
    if (formData[EnrollSecondaryTaxCountryIdSelector]
      && (formData[EnrollSecondaryTaxCountryIdSelector] === formData[EnrollOtherTaxCountryIdSelector])) {
      const updatedFormData: EnrollPersonalDetailsForm = {
        ...formData,
        [EnrollOtherTaxCountryIdSelector]: null,
        [EnrollOtherTaxNumberSelector]: '',
      };
      setFormData(updatedFormData);
      setIsAddAnotherTaxCountryClicked(false);
    } if (formData[EnrollSecondaryCitizenshipIdSelector]
      && (formData[EnrollSecondaryCitizenshipIdSelector] === formData[EnrollOtherCitizenshipIdSelector])) {
      const updatedFormData: EnrollPersonalDetailsForm = {
        ...formData,
        [EnrollOtherCitizenshipIdSelector]: null,
      };
      setFormData(updatedFormData);
      setIsAddNewCitizenshipClicked(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData[EnrollSecondaryTaxCountryIdSelector], formData[EnrollSecondaryCitizenshipIdSelector]]);

  return (
    <EnrollLayout
      visibleContinueButton
      onBackButtonClicked={goBack}
      onContinueClicked={() => {
        setIsClickedBtnContinue(true);

        if (validateForm()) {
          goNext();
        }
      }}
    >
      <>
        <h3 id="personalDetailsTitle" aria-label="personal details enrollment title" className={styles.title}>
          {`${progressData.currentPhaseIndex}.${progressData.currentStepIndex} ${lang.commonEnrollmentPersonalDetailsTitle()}`}
        </h3>
        <div className={styles.contentWrapper}>
          <InputControl
            name=""
            placeholder=""
            id="firstName"
            ariaLabel="first name input"
            value={formData[EnrollFirstNameSelector]}
            className={styles.primaryMarginBottom}
            label={lang.commonOnboardingPersonalDetailsFirstName()}
            onValueChange={(value: string | number) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollFirstNameSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollFirstNameSelector).errors : []}
          />
          <InputControl
            name=""
            placeholder=""
            id="middleName"
            ariaLabel="middle name input"
            className={styles.primaryMarginBottom}
            value={formData[EnrollMiddleNameSelector]}
            label={lang.commonOnboardingPersonalDetailsMiddleName()}
            onValueChange={(value: string | number) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollMiddleNameSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollMiddleNameSelector).errors : []}
          />
          <InputControl
            name=""
            placeholder=""
            id="lastName"
            ariaLabel="last name input"
            className={styles.primaryMarginBottom}
            value={formData[EnrollLastNameSelector]}
            label={lang.commonOnboardingPersonalDetailsLastName()}
            onValueChange={(value: string | number) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollLastNameSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollLastNameSelector).errors : []}
          />
          <InputControl
            name=""
            placeholder=""
            showInlineHelp
            id="taxNumber"
            ariaLabel="tax number input"
            className={styles.primaryMarginBottom}
            value={formData[EnrollTaxNumberSelector]}
            label={lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber()}
            onValueChange={(value: string | number) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollTaxNumberSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollTaxNumberSelector).errors : []}
          />
          <DatepickerControl
            idDate="dateBirth"
            idInput="dateBirthInput"
            idPopover="dateBirthPopover"
            className={styles.primaryMarginBottom}
            maxDate={getDateTime(true, [ 'minus' ], [ [ 18, 'years' ] ])}
            minDate={moment('1930-01-01').format()}
            value={formData[EnrollDateOfBirthSelector]}
            dateFormat={DEFAULT_DATERANGEPICKER_FORMAT}
            label={lang.commonOnboardingPersonalDetailsDateOfBirth()}
            onDateChange={(value: string | null) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollDateOfBirthSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollDateOfBirthSelector).errors : []}
          />
          <InputControl
            name=""
            placeholder=""
            id="placeBirth"
            ariaLabel="place of birth"
            className={styles.primaryMarginBottom}
            value={formData[EnrollPlaceOfBirthSelector]}
            label={lang.commonOnboardingPersonalDetailsPlaceOfBirth()}
            onValueChange={(value: string | number) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollPlaceOfBirthSelector]: value as string,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollPlaceOfBirthSelector).errors : []}
          />
          <CountryControl
            name=""
            options={countryOptions}
            className={styles.secondaryMarginBottom}
            value={formData[EnrollPrimaryCitizenshipSelector]}
            id="countryBirth"
            ariaLabel="Country of birth"
            placeholder={lang.commonOnboardingPersonalDetailsCountryOfBirth()}
            onCountryChange={(countryId: string) => {
              setFormData((currentState: EnrollPersonalDetailsForm) => ({
                ...currentState,
                [EnrollPrimaryCitizenshipSelector]: countryId,
              }));
            }}
            errors={isClickedBtnContinue ? getControlValidity(EnrollPrimaryCitizenshipSelector).errors : []}
          />
          <div>
            <CheckboxControl
              id="sameCitizenshipCountry"
              ariaLabel="same citizenship and country of birth"
              checked={isCitizenshipSame}
              onChange={(value: boolean) => handleCheckBoxCitizenship(value)}
              label={lang.commonOnboardingPersonalDetailsCitizenshipSameAsConutryOfBirth()}
            />

            {!isCitizenshipSame && (
            <CountryControl
              name=""
              options={countryOptions}
              className={styles.tertiaryMarginBottom}
              value={formData[EnrollSecondaryCitizenshipIdSelector] ?? ''}
              id="citizenship"
              ariaLabel="Citizenship"
              placeholder={lang.commonOnboardingPersonalDetailsCitizenship()}
              onCountryChange={(countryId: string) => {
                setFormData((currentState: EnrollPersonalDetailsForm) => ({
                  ...currentState,
                  [EnrollSecondaryCitizenshipIdSelector]: countryId,
                }));
              }}
              errors={isClickedBtnContinue ? getControlValidity(EnrollSecondaryCitizenshipIdSelector).errors : []}
            />
            )}
          </div>
          <div className={styles.tertiaryMarginBottom}>
            <CheckboxControl
              id="sameTaxCountry"
              ariaLabel="same tax country and country of birth"
              checked={isTaxCountrySame}
              onChange={(value: boolean) => handleCheckBoxTaxCountry(value)}
              label={lang.commonOnboardingPersonalDetailsTaxCountrySameAsCOB()}
            />

            {!isTaxCountrySame && (
            <CountryControl
              name=""
              options={countryOptions}
              value={formData[EnrollSecondaryTaxCountryIdSelector] ?? ''}
              id="taxCountry"
              ariaLabel="Tax country"
              placeholder={lang.commonOnboardingPersonalDetailsTaxDetailsTaxCountry()}
              onCountryChange={(countryId: string) => {
                setFormData((currentState: EnrollPersonalDetailsForm) => ({
                  ...currentState,
                  [EnrollSecondaryTaxCountryIdSelector]: countryId,
                }));
              }}
              errors={isClickedBtnContinue ? getControlValidity(EnrollSecondaryTaxCountryIdSelector).errors : []}
            />
            )}
          </div>
          <div className={styles.linkButtonsMargin}>
            <AddAnotherEntityButton isClicked={isAddNewCitizenshipClicked} isCitizenship />
            {isAddNewCitizenshipClicked && (
            <CountryControl
              name=""
              options={handleAddAnotherCitizenship()}
              value={formData[EnrollOtherCitizenshipIdSelector] ?? ''}
              id="newCitizenship"
              ariaLabel="new citizenship"
              placeholder={lang.commonOnboardingPersonalDetailsCitizenship()}
              onCountryChange={(countryId: string) => {
                setFormData((currentState: EnrollPersonalDetailsForm) => ({
                  ...currentState,
                  [EnrollOtherCitizenshipIdSelector]: countryId,
                }));
              }}
              errors={isClickedBtnContinue ? getControlValidity(EnrollOtherCitizenshipIdSelector).errors : []}
            />
            )}
          </div>
          <div>
            <AddAnotherEntityButton isClicked={isAddAnotherTaxCountryClicked} />
            {isAddAnotherTaxCountryClicked && (
              <>
                <CountryControl
                  name=""
                  options={handleAddAnotherTaxCountry()}
                  className={styles.secondaryMarginBottom}
                  value={formData[EnrollOtherTaxCountryIdSelector] ?? ''}
                  id="anotheTaxCountry"
                  ariaLabel="another tax country"
                  placeholder={lang.commonOnboardingPersonalDetailsTaxDetailsTaxCountry()}
                  onCountryChange={(countryId: string) => {
                    setFormData((currentState: EnrollPersonalDetailsForm) => ({
                      ...currentState,
                      [EnrollOtherTaxCountryIdSelector]: countryId,
                    }));
                  }}
                  errors={isClickedBtnContinue ? getControlValidity(EnrollOtherTaxCountryIdSelector).errors : []}
                />
                <InputControl
                  name=""
                  placeholder=""
                  id="additionTaxNumber"
                  ariaLabel="addition tax number"
                  showInlineHelp
                  className={styles.primaryMarginBottom}
                  value={formData[EnrollOtherTaxNumberSelector]}
                  label={lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber()}
                  onValueChange={(value: string | number) => {
                    setFormData((currentState: EnrollPersonalDetailsForm) => ({
                      ...currentState,
                      [EnrollOtherTaxNumberSelector]: value as string,
                    }));
                  }}
                  errors={isClickedBtnContinue ? getControlValidity(EnrollOtherTaxNumberSelector).errors : []}
                />
              </>
            )}
          </div>
        </div>
      </>
    </EnrollLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  individual: state.crm.individualExtendedInfo,
  isPersonalDetailsUpdate: hasEnrollDetails(EnrollDetailsEnum.PERSONAL_DETAILS, state),
  requestState: state.crm.enroll.status,
  callsInQueue: state.crm.enroll.callsInQueue,
  statusByCall: state.crm.enroll.statusByCall,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  dispatch,
  clearCrmEnrollStatusByCode: () => dispatch(storeClearCrmEnrollStatusByCode()),
});

const mergeProps = (stateProps: ReduxStateProps, dispatchProps: ReduxDispatchProps, ownProps: OwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  enrollPersonalDetailsWithTaxDetails: (
    data: EnrollIndividualRequestData,
    taxDetails: EnrollIndividualTaxDetailData[],
    isAddAnotherTaxCountryClicked: boolean,
  ) => dispatchProps.dispatch(storeEnrollPersonalDetailsWithTaxDetails({
    isPersonalDetailsUpdate: stateProps.isPersonalDetailsUpdate,
    individualData: data,
    taxDetails,
    isAddAnotherTaxCountryClicked,
  })),
  isEdit: hasEnrollDetails(EnrollDetailsEnum.TAX_DETAILS, stateProps.state) && stateProps.isPersonalDetailsUpdate,
});

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
type ReduxStateProps = ReturnType<typeof mapStateToProps>
type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EnrollPersonalDetails);
