import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ModalBody, ModalHeader } from 'reactstrap';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { confirmAppropriatenessPopup } from '../../../lib/store/auth/index';
import { logout as serviceLogout } from '../../../lib/store/common-actions';

import BaseModal from '../../BaseModal/BaseModal';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';

import styles from './AppropriatenessModal.module.scss';

type Props = {
  visible: boolean;
  onContinueClicked: () => void;
} & ReduxProps

const AppropriatenessModal = ({
  visible, onContinueClicked, logout, confirmPopup,
}: Props) => {
  const handleContinue = () => {
    confirmPopup();
    onContinueClicked();
  };

  const onLogoutClicked = () => {
    logout();
  };

  return (
    <BaseModal isOpen={visible}>
      <ModalHeader id="financialKnowledgeAssessmentTitle" className={styles.header}>
        {lang.commonAppropriatenessModalTitle()}
      </ModalHeader>
      <ModalBody>
        <div id="financialKnowledgeAssessmentBody">
          <p>{lang.commonAppropriatenessModalBody1()}</p>
          <p>{lang.commonAppropriatenessModalBody2()}</p>
          <p>{lang.commonAppropriatenessModalBody3()}</p>
        </div>
        <div>
          <Button
            id="financialKnowledgeAssessmentLogout"
            className={styles.logoutBtn}
            fullWidth
            variant={ButtonTypesEnum.Tertiary}
            onClick={onLogoutClicked}
          >
            {lang.commonLogout()}
          </Button>
          <Button
            id="financialKnowledgeAssessmentContinue"
            fullWidth
            onClick={handleContinue}
          >
            {lang.commonContinueButton()}
          </Button>
        </div>
      </ModalBody>
    </BaseModal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  confirmPopup: () => dispatch(confirmAppropriatenessPopup()),
  logout: () => dispatch(serviceLogout()),
});

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AppropriatenessModal);
