import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { CURRENT_YEAR } from '../../../lib/constants/date-time.constants';
import { DATEPICKER_FORMAT_YEARS, DATEPICKER_VIEW_YEARS } from '../../../lib/constants/datePickerConstants';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store/index';
import { userAdultYear } from '../../../lib/store/selectors';
import { ControlError } from '../../../lib/util/ValidationSchemes/ValidationTypes';

import DatepickerControl from '../../DatepickerControl/DatepickerControl';
import { GeneralQuestionsCustomAnswers } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import { KEY_SOURCE_OF_FUND_FROM, KEY_SOURCE_OF_FUND_TO } from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';

import styles from './YearDatePicker.module.scss';

type Props = {
  datePickerToYear: string;
  datePickerFromYear?: string;
  formErrors: ControlError[];
  handleChange: (formKey: string, value: string | number) => void;
  setCustomData: React.Dispatch<React.SetStateAction<GeneralQuestionsCustomAnswers>>
}

const YearDatePicker = ({
  formErrors,
  datePickerToYear,
  datePickerFromYear,
  handleChange,
  setCustomData,
}: Props): JSX.Element => {
  const [dirty, setDirty] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [changeValue, setChangeValue] = useState(false);

  const startYear = useSelector<RootState, string>((state: RootState) => (
    userAdultYear(state)
  ));

  const handleChangeValue = (key: string, value: string | number) => {
    handleChange(key, value);
    setChangeValue(false);
  };

  const handleCustomData = (value: string | number | null, sourceSelector: string) => {
    if (value) {
      setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
        ...currentState,
        [sourceSelector]: value,
      }));
      if (changeValue) {
        handleChangeValue(sourceSelector, value);
      }
      setChangeValue(true);

      if (sourceSelector === KEY_SOURCE_OF_FUND_FROM && value > datePickerToYear) {
        setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
          ...currentState,
          [KEY_SOURCE_OF_FUND_TO]: value,
        }));
      }
      if (sourceSelector === KEY_SOURCE_OF_FUND_TO
        && ((datePickerFromYear && value < datePickerFromYear) || (!datePickerFromYear && !isFirstTime))
      ) {
        setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
          ...currentState,
          [KEY_SOURCE_OF_FUND_FROM]: value,
        }));
      }
    }
  };

  useEffect(() => {
    if (datePickerFromYear) {
      setDirty(true);
    }
  }, [datePickerFromYear]);

  return (
    <Row>
      <Col md={6} className={styles.fromDatepickerControl}>
        <DatepickerControl
          idDate="dateFrom"
          idInput="dateFromInput"
          idPopover="dateFromPopover"
          label={lang.commonFrom()}
          dateFormat={DATEPICKER_FORMAT_YEARS}
          views={DATEPICKER_VIEW_YEARS}
          minDate={startYear}
          maxDate={CURRENT_YEAR.toString()}
          isYearPicker={dirty}
          value={datePickerFromYear}
          errors={formErrors}
          onDateChange={(value: string | null) => {
            handleCustomData(value, KEY_SOURCE_OF_FUND_FROM);
          }}
        />
      </Col>
      <Col md={6}>
        <DatepickerControl
          idDate="dateTo"
          idInput="dateToInput"
          idPopover="dateToPopover"
          label={lang.commonTo()}
          dateFormat={DATEPICKER_FORMAT_YEARS}
          views={DATEPICKER_VIEW_YEARS}
          minDate={startYear}
          maxDate={CURRENT_YEAR.toString()}
          isYearPicker={dirty}
          value={datePickerToYear || CURRENT_YEAR.toString()}
          onDateChange={(value: string | null) => {
            handleCustomData(value, KEY_SOURCE_OF_FUND_TO);
            if (isFirstTime) setIsFirstTime(false);
          }}
        />
      </Col>
    </Row>
  );
};

export default YearDatePicker;
