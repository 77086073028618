import React from 'react';
import { useHistory } from 'react-router-dom';

import { formatCurrency, roundPercentValues } from '../../../lib/util/DataHelpers';

import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import CompanyLogo from '../../CompanyLogo/CompanyLogo';

import styles from './TableItem.module.scss';

type Props = {
  logo: string,
  symbol: string,
  companyName: string,
  yield_indicator: number,
  rate_revenue: number,
  marketCap_eps: string | number,
  volume_marketCap: number,
}

const TableItem = (props: Props) => {
  const {
    logo,
    symbol,
    companyName,
    yield_indicator,
    rate_revenue,
    marketCap_eps,
    volume_marketCap,
  } = props;
  const isMobile = useIsMobile();
  const history = useHistory();

  return (
    <tr onClick={() => history.push(`${UI_ROUTES.symbol}/${symbol}`)}>
      { isMobile ? (
        <div className={styles.row}>
          <div>
            <CompanyLogo logo={logo} className={styles.logo} symbol={symbol} />
          </div>
          <div className={styles.wrapperContent}>
            <div className={styles.companyInfo}>
              <div className={styles.symbol}>{symbol}</div>
              <div className={styles.yield}>
                {`${roundPercentValues(yield_indicator * 100)}%`}
              </div>
            </div>
            <div className={styles.companyName}>{companyName}</div>
          </div>
        </div>
      ) : (
        <>
          <td className={styles.columnSymbolName}>
            <div className={styles.wrapperContent}>
              <CompanyLogo logo={logo} className={styles.logo} symbol={symbol} />
              <div className={styles.companyInfo}>
                <span className={styles.symbol}>{symbol}</span>
                <span className={styles.companyName}>{companyName}</span>
              </div>
            </div>
          </td>
          <td>{`${roundPercentValues(yield_indicator * 100)}%`}</td>
          <td>{formatCurrency(rate_revenue, false, true).toUpperCase()}</td>
          <td>{marketCap_eps}</td>
          <td>{formatCurrency(volume_marketCap, false, true).toUpperCase()}</td>
        </>
      )}
    </tr>
  );
};

export default TableItem;
