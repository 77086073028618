import lang from '../../lib/language';

import ArrowPositiveGrowth from '../../assets/img/icon_arrow_positive_growth.png';
import IconInfinity from '../../assets/img/icon_infinity.png';
import IconMetrics from '../../assets/img/icon_metrics.png';

export const options = [
  {
    title: lang.hammerLoginTitle1(),
    description: lang.hammerLoginDescription1(),
    icon: IconMetrics,
  },
  {
    title: lang.hammerLoginTitle2(),
    description: lang.hammerLoginDescription2(),
    icon: IconInfinity,
  },
  {
    title: lang.hammerLoginTitle3(),
    description: lang.hammerLoginDescription3(),
    icon: ArrowPositiveGrowth,
  },
];
