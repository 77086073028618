import React, { Dispatch, useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { PaymentMethodEnum } from '../../../lib/enums/payments/payment-method.enum';
import lang from '../../../lib/language';
import { FundingWireTransferPostRequest } from '../../../lib/models/crm/types';
import { AccountInfo } from '../../../lib/models/linked-account/types';
import { RootState } from '../../../lib/store';
import {
  createBankTransfer as storeCreateBankTransfer,
  createCardTransfer as storeCreateCardTransfer,
  setBankAccountIdByLastPayment,
} from '../../../lib/store/payment';
import { MAXIMUM_DEPOSIT_AMOUNT_BANK_TRANSFER, MAXIMUM_DEPOSIT_AMOUNT_CARD_TRANSFER, MINIMUM_DEPOSIT_AMOUNT } from '../../../lib/store/payment/constants';
import { CreateCardTransferPayload, PaymentCall } from '../../../lib/store/payment/types';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';

import backArrow from '../../../assets/img/icon-arrow_back.svg';
import iconExpandDown from '../../../assets/img/icon-expand-more-down.svg';
import iconExpandUp from '../../../assets/img/icon-expand-more-up.svg';
import infoIcon from '../../../assets/img/icon-info-outline.svg';
import { BaseModal, Button, ClickableDiv, ClickableImage, Preloader } from '../../../components';
import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import FeeBreakdownModal from '../makeYourFirstDeposit/modal/feeBreakdownModal/FeeBreakdownModal';

import ContentBankDropDown from './components/ContentBankDropDown';
import DepositSlipModal from './DepositSlipModal/DepositSlipModal';

import styles from './EnterAmount.module.scss';

const suggestionAmounts: string[] = ['200', '250', '300', '400', '600', '1200'];

type Props = {} & ReduxProps;

const EnterAmount = (props: Props) => {
  const {
    createBankTransfer,
    createCardTransfer,
    setBankAccountIdValueByLastPayment,
    statusByCall,
    linkedAccounts,
    lastCardTransfer,
    linkedAccountIdByLastPayment,
  } = props;

  const isMobile = useIsMobile();

  const dropDownDivRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const history = useHistory();

  const hasBankAccount = () => linkedAccounts.length > 0;

  const selectedAccountIndex = useSelector<RootState, number>((state: RootState) =>
    state.payment.selectedLinkedAccountIndex);

  const convertedLinkedAccounts = linkedAccounts.slice(0).reverse();
  let linkedAccountsData = convertedLinkedAccounts;

  if (linkedAccountIdByLastPayment) {
    linkedAccountsData = linkedAccounts.filter(account => account.id === linkedAccountIdByLastPayment);
  }

  const fundingMethodName = linkedAccountsData.length > 0 ? linkedAccountsData[0].account_info.bank_name : '';
  let fundingMethodIban = linkedAccountsData.length > 0 ? linkedAccountsData[0].account_info.iban : '';

  if (fundingMethodIban === null) {
    fundingMethodIban = linkedAccountsData[0].account_info.account;
  }

  const [isOpenFundAccount, setIsOpenFundAccount] = useState<boolean>(false);
  const [clickBtnConfirm, setClickBtnConfirm] = useState<boolean>(false);
  const [maxumumDepositValue, setMaxumumDepositValue] = useState<number>(MAXIMUM_DEPOSIT_AMOUNT_CARD_TRANSFER);
  const [showModalFeeBreakdown, setShowModalFeeBreakdown] = useState<boolean>(false);
  const [showBankDepositModal, setShowBankDepositModal] = useState<boolean>(false);
  const [dataValues, setDataValues] = useState({
    linkedAccountData: hasBankAccount() ? linkedAccounts[selectedAccountIndex] : null,
    fundingMethodName: hasBankAccount()
      ? linkedAccounts[selectedAccountIndex].account_info.bank_name
      : lang.commonFirstDepositCard(),
    fundingMethodIban: hasBankAccount() ? (linkedAccounts[selectedAccountIndex].account_info.iban
    || linkedAccounts[selectedAccountIndex].account_info.account) : '',
    amountValue: MINIMUM_DEPOSIT_AMOUNT.toString(),
    paymentMethod: hasBankAccount() ? PaymentMethodEnum.BankTransfer : PaymentMethodEnum.Card,
  });

  const handleChangeInputAmount = (value) => {
    setDataValues({ ...dataValues, amountValue: value });
  };

  const handleConfirm = () => {
    if (dataValues.paymentMethod === PaymentMethodEnum.BankTransfer) {
      const sendLinkedAccontData: AccountInfo = dataValues.linkedAccountData
        ? dataValues.linkedAccountData.account_info : linkedAccountsData[0].account_info;

      setBankAccountIdValueByLastPayment(
        dataValues.linkedAccountData
          ? dataValues.linkedAccountData.id
          : linkedAccountsData[0].id,
      );
      createBankTransfer({
        amount: dataValues.amountValue.toString(),
        sender_bank_details: sendLinkedAccontData,
        recipient_bank_details: sendLinkedAccontData,
      });
    } else {
      createCardTransfer({
        amount: Number(dataValues.amountValue),
        isInitialDeposit: true,
      });
    }
    setClickBtnConfirm(true);
  };

  const handleClickBtnAmount = (value: string) => {
    setDataValues({ ...dataValues, amountValue: value });
  };

  const handleClickFundAccount = (data) => {
    if (data.paymentMethod === PaymentMethodEnum.BankTransfer) {
      setDataValues({
        ...dataValues,
        linkedAccountData: data.linkedAccount,
        fundingMethodName: data.linkedAccount.account_info.bank_name,
        fundingMethodIban: data.linkedAccount.account_info.iban || data.linkedAccount.account_info.account,
        paymentMethod: PaymentMethodEnum.BankTransfer,
      });
    } else {
      setDataValues({
        ...dataValues,
        fundingMethodName: lang.commonFirstDepositCard(),
        fundingMethodIban: '',
        paymentMethod: PaymentMethodEnum.Card,
      });
    }
    setIsOpenFundAccount(!isOpenFundAccount);
  };

  const handleModalFeeBreakdown = () => {
    setShowModalFeeBreakdown(!showModalFeeBreakdown);
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (
      isCallStatusReady(PaymentCall.createBankTransfer, statusByCall)
      && clickBtnConfirm
      && dataValues.paymentMethod === PaymentMethodEnum.BankTransfer) {
      if (isMobile) {
        history.push(UI_ROUTES.funds + UI_ROUTES.depositSlip);
      } else {
        setShowBankDepositModal(true);
      }
      setClickBtnConfirm(false);
    }
  }, [history, clickBtnConfirm, dataValues, statusByCall, isMobile]);

  useEffect(() => {
    if (
      isCallStatusReady(PaymentCall.createCardTransfer, statusByCall)
      && clickBtnConfirm
      && dataValues.paymentMethod === PaymentMethodEnum.Card
    ) {
      window.open(lastCardTransfer.createTransferData?.gateway_url, '_self');
    }
  }, [lastCardTransfer, history, clickBtnConfirm, dataValues, statusByCall]);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (event.target.nodeName === 'IMG' && isOpenFundAccount) {
        setIsOpenFundAccount(false);
      }
      if (dropDownDivRef.current && dropDownDivRef.current.contains(event.target)) {
        inputRef.current && inputRef.current.blur();
        setIsOpenFundAccount(true);
      } else {
        setIsOpenFundAccount(false);
      }
    };
    document.addEventListener('click', handleClickInside);
    return () => document.removeEventListener('click', handleClickInside);
  }, [dropDownDivRef, isOpenFundAccount]);

  useEffect(() => {
    if (dataValues.fundingMethodName !== lang.commonFirstDepositCard()) {
      setMaxumumDepositValue(MAXIMUM_DEPOSIT_AMOUNT_BANK_TRANSFER);
    } else {
      setMaxumumDepositValue(MAXIMUM_DEPOSIT_AMOUNT_CARD_TRANSFER);
    }
  }, [dataValues.fundingMethodName]);

  return (
    <>
      {clickBtnConfirm && <Preloader owner="EnterAmount" text={lang.commonMessageConnectingMerchant()} isFullScreen stylePreset="customOne" />}
      <div className={styles.wrapperContainer}>
        <div className={styles.containerContent}>
          <div className={styles.wrapperContent}>
            <ClickableImage
              id="enterAmountBack"
              alt=""
              src={backArrow}
              className={styles.backArrow}
              onClick={() => history.push({
                pathname: UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit,
                state: { previousPage: UI_ROUTES.funds + UI_ROUTES.enterAmount },
              })}
            />
            <h3 className={styles.title} id="enterAmountTitle">{lang.depositAmountTitle()}</h3>
            <span className={styles.description}>{lang.mobileDepositAmountSubtitle()}</span>
            <div className={styles.errorWrapper}>
              {Number(dataValues.amountValue) > maxumumDepositValue && (
                <div className={styles.textMaximumDeposit}>
                  {lang.mobileDepositAmountMaximumDepositAmount(formatCurrency(maxumumDepositValue))}
                </div>
              )}
            </div>
            <div className={styles.currencyInputWrapper}>
              <div className={styles.firstSection}>
                <div className={styles.amountText}>
                  {lang.mobileDepositAmountLabel()}
                </div>
                <CurrencyInput
                  ref={inputRef}
                  value={dataValues.amountValue}
                  defaultValue={dataValues.amountValue}
                  onValueChange={(value) => handleChangeInputAmount(value as string)}
                  className={(Boolean(dataValues.amountValue)
                        && Number(dataValues.amountValue) < Number(MINIMUM_DEPOSIT_AMOUNT))
                    ? styles.inputError
                    : styles.input}
                  placeholder="$0.00"
                  allowNegativeValue={false}
                  prefix={'$'}
                />
              </div>
            </div>
            <div ref={dropDownDivRef}>
              <div
                id="enterAmountWrapper"
                className={classNames(styles.wrapperShowBankContentBefore,
                  { [styles.wrapperShowBankContentAfter]: isOpenFundAccount })}
              >
                <div className={styles.wrapperInfo}>
                  <p
                    className={classNames(styles.titleInfoBefore,
                      { [styles.titleInfoAfter]: isOpenFundAccount })}
                  >
                    {lang.webAccountFundingMethod()}
                  </p>
                  <p className={styles.methodName}>{dataValues.fundingMethodName || fundingMethodName}</p>
                  <p>{dataValues.fundingMethodIban.length > 0 && dataValues.fundingMethodIban}</p>
                </div>
                <ClickableImage
                  alt=""
                  id="enterAmountArrow"
                  src={isOpenFundAccount ? iconExpandUp : iconExpandDown}
                  className={styles.iconExpand}
                />
              </div>
              {isOpenFundAccount && (
              <ContentBankDropDown
                handleClickFundAccount={handleClickFundAccount}
                convertedLinkedAccounts={convertedLinkedAccounts}
                showCardOption
              />
              )}
            </div>
            <div className={styles.wrapperButtonAmount}>
              {
              suggestionAmounts.map((amount) => (
                <Button
                  id={`suggestion${amount}`}
                  key={amount}
                  className={styles.buttonAmount}
                  onClick={() => handleClickBtnAmount(amount)}
                >
                  {`$${amount}`}
                </Button>
              ))
            }
            </div>
            <Button
              id="enterAmountConfirm"
              fullWidth
              disabled={
              Boolean(!dataValues.amountValue)
              || Number(dataValues.amountValue) < MINIMUM_DEPOSIT_AMOUNT
              || Number(dataValues.amountValue) > maxumumDepositValue
              || clickBtnConfirm || isOpenFundAccount
            }
              onClick={handleConfirm}
            >
              {lang.commonConfirmButton()}
            </Button>
            <ClickableDiv
              className={styles.wrapperInfoText}
              onClick={handleModalFeeBreakdown}
            >
              <img src={infoIcon} alt="infoIcon" />
              <span>{lang.fundsCheckTheFeeBreakdown()}</span>
            </ClickableDiv>
          </div>
        </div>
        <BaseModal isOpen={showModalFeeBreakdown}>
          <FeeBreakdownModal handleModal={handleModalFeeBreakdown} />
        </BaseModal>
        <BaseModal isOpen={showBankDepositModal}>
          <DepositSlipModal />
        </BaseModal>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  linkedAccounts: state.payment.linkedAccounts,
  lastCardTransfer: state.payment.lastCardTransfer,
  statusByCall: state.payment.statusByCall,
  linkedAccountIdByLastPayment: state.payment.linkedAccountIdByLastPayment,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createBankTransfer: (fundingData: FundingWireTransferPostRequest) =>
    dispatch(storeCreateBankTransfer(fundingData)),
  createCardTransfer: (depositData: CreateCardTransferPayload) => dispatch(storeCreateCardTransfer(depositData)),
  setBankAccountIdValueByLastPayment: (id: number) => dispatch(setBankAccountIdByLastPayment(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EnterAmount);
