import { OrderSideEnum } from '../enums/index';
import { OrderTypeEnum } from '../enums/order-type.enum';
import { Order, Position } from '../models/trading/types';
import { OpenPosition } from '../store/reporting/types';

import { formatCurrency, formattedCurrencyToNumber, isNumber } from './DataHelpers';
import { NullableNumber } from './types';

const BIG_STOCKS_AGGRESSIVE_LIMIT_PERCENT = '3%';

export const convertPercentStringToCalculatableNumberForBuyOrder = (percent: string): number => 1 + (Number(percent.replace('%', '')) || 0) * 0.01;

export const convertPercentStringToCalculatableNumberForSellOrder = (percent: string): number => 1 - (Number(percent.replace('%', '')) || 0) * 0.01;

export function validateAggressiveBuyLimitOrder(lastPrice: NullableNumber, limitPrice: NullableNumber): boolean {
  if (lastPrice == null || limitPrice == null) {
    return false;
  }
  const maxPrice = lastPrice * convertPercentStringToCalculatableNumberForBuyOrder(BIG_STOCKS_AGGRESSIVE_LIMIT_PERCENT);

  return limitPrice < maxPrice;
}

export function getMaxBuyLimitPrice(lastPrice: number, type: 'string' | 'number' = 'number'): number | string {
  const numberForBuyOrder = convertPercentStringToCalculatableNumberForBuyOrder(BIG_STOCKS_AGGRESSIVE_LIMIT_PERCENT);
  if (type === 'string') {
    return formatCurrency(lastPrice * numberForBuyOrder);
  }

  return lastPrice * numberForBuyOrder;
}

export function validateAggressiveSellLimitOrder(lastPrice: NullableNumber, limitPrice: NullableNumber): boolean {
  if (lastPrice == null || limitPrice == null) {
    return false;
  }
  const maxPrice = lastPrice * convertPercentStringToCalculatableNumberForSellOrder(
    BIG_STOCKS_AGGRESSIVE_LIMIT_PERCENT,
  );

  return limitPrice > maxPrice;
}

export function getMaxSellLimitPrice(lastPrice: number, type: 'string' | 'number' = 'number'): number | string {
  const numberForSellOrder = convertPercentStringToCalculatableNumberForSellOrder(BIG_STOCKS_AGGRESSIVE_LIMIT_PERCENT);
  if (type === 'string') {
    return formatCurrency(lastPrice * numberForSellOrder);
  }

  return lastPrice * numberForSellOrder;
}

export function getOrderTotalValue(
  type: string,
  quantity: number,
  marketPrice: NullableNumber,
  targetPrice: number,
): number {
  const marketOrderType = type === OrderTypeEnum.Market;
  if (marketOrderType && !isNumber(marketPrice)) {
    return 0;
  }

  return !marketOrderType ? quantity * targetPrice : quantity * marketPrice!;
}

export function checkOrderForSellShort(order: Order, positions: OpenPosition[]): Order {
  const allQty = positions.reduce((accumulator, current) => accumulator + current.quantity, 0);
  if (order.side === OrderSideEnum.Sell && allQty <= 0) {
    return {
      ...order,
      side: OrderSideEnum.SellShort,
    };
  }

  return order;
}
