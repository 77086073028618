import { isEmpty } from 'lodash';

import { DynamicFilterItemType, DynamicFilterObjectType, DynamicFilterType } from './types';

export class DynamicFilterBuilder {
  private readonly eq: DynamicFilterObjectType = {};
  private readonly neq: DynamicFilterObjectType = {};
  private readonly gt: DynamicFilterObjectType = {};
  private readonly gte: DynamicFilterObjectType = {};
  private readonly lt: DynamicFilterObjectType = {};
  private readonly lte: DynamicFilterObjectType = {};
  private readonly inObj: DynamicFilterObjectType = {};
  private readonly nin: DynamicFilterObjectType = {};
  private readonly btn: DynamicFilterObjectType = {};
  private readonly likeObj: DynamicFilterObjectType = {};
  private readonly nlike: DynamicFilterObjectType = {};
  private readonly sw: DynamicFilterObjectType = {};
  private readonly ew: DynamicFilterObjectType = {};

  public equal(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.eq[key];
      return this;
    }

    this.eq[key] = value;
    return this;
  }

  public notEqual(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.neq[key];
      return this;
    }

    this.neq[key] = value;
    return this;
  }

  public greaterThan(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.gt[key];
      return this;
    }
    this.gt[key] = value;
    return this;
  }

  public greaterThanEqual(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.gte[key];
      return this;
    }
    this.gte[key] = value;
    return this;
  }

  public lessThan(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.lt[key];
      return this;
    }
    this.lt[key] = value;
    return this;
  }

  public lessThanEqual(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.lte[key];
      return this;
    }
    this.lte[key] = value;
    return this;
  }

  public in(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.inObj[key];
      return this;
    }
    this.inObj[key] = value;
    return this;
  }

  public notIn(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.nin[key];
      return this;
    }
    this.nin[key] = value;
    return this;
  }

  public between(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.btn[key];
      return this;
    }
    this.btn[key] = value;
    return this;
  }

  public like(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.likeObj[key];
      return this;
    }
    this.likeObj[key] = value;
    return this;
  }

  public notLike(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.nlike[key];
      return this;
    }
    this.nlike[key] = value;
    return this;
  }

  public startWith(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.sw[key];
      return this;
    }
    this.sw[key] = value;
    return this;
  }

  public endWith(key: string, value: DynamicFilterItemType): DynamicFilterBuilder {
    if (value === null) {
      delete this.ew[key];
      return this;
    }
    this.ew[key] = value;
    return this;
  }

  public build(): DynamicFilterType {
    const result: DynamicFilterType = {};

    const equalOrDefault = this.getValueOrDefault(this.eq);
    if (!isEmpty(equalOrDefault)) {
      result.eq = equalOrDefault;
    }

    const notEqualOrDefault = this.getValueOrDefault(this.neq);
    if (!isEmpty(notEqualOrDefault)) {
      result.neq = notEqualOrDefault;
    }

    const greaterThanOrDefault = this.getValueOrDefault(this.gt);
    if (!isEmpty(greaterThanOrDefault)) {
      result.gt = greaterThanOrDefault;
    }

    const greaterThanEqualOrDefault = this.getValueOrDefault(this.gte);
    if (!isEmpty(greaterThanEqualOrDefault)) {
      result.gte = greaterThanEqualOrDefault;
    }

    const lessThanOrDefault = this.getValueOrDefault(this.lt);
    if (!isEmpty(lessThanOrDefault)) {
      result.lt = lessThanOrDefault;
    }

    const lessThanEqualOrDefault = this.getValueOrDefault(this.lte);
    if (!isEmpty(lessThanEqualOrDefault)) {
      result.lte = lessThanEqualOrDefault;
    }

    const inDefault = this.getValueOrDefault(this.inObj);
    if (!isEmpty(inDefault)) {
      result.in = inDefault;
    }

    const notInDefault = this.getValueOrDefault(this.nin);
    if (!isEmpty(notInDefault)) {
      result.nin = notInDefault;
    }

    const betweenOrDefault = this.getValueOrDefault(this.btn);
    if (!isEmpty(betweenOrDefault)) {
      result.btn = betweenOrDefault;
    }

    const likeOrDefault = this.getValueOrDefault(this.likeObj);
    if (!isEmpty(likeOrDefault)) {
      result.like = likeOrDefault;
    }

    const notLikeOrDefault = this.getValueOrDefault(this.nlike);
    if (!isEmpty(notLikeOrDefault)) {
      result.nlike = notLikeOrDefault;
    }

    const startWithOrDefault = this.getValueOrDefault(this.sw);
    if (!isEmpty(startWithOrDefault)) {
      result.sw = startWithOrDefault;
    }

    const endWithOrDefault = this.getValueOrDefault(this.ew);
    if (!isEmpty(endWithOrDefault)) {
      result.ew = endWithOrDefault;
    }

    return result;
  }

  private getValueOrDefault(value: DynamicFilterObjectType): DynamicFilterObjectType {
    if (isEmpty(value)) {
      return {};
    }
    return value;
  }
}
