import {
  isArray,
  isArrayLike, isBoolean, isFinite, isNumber as isNumberLodash, isObject, isObjectLike, isString } from 'lodash';
import moment from 'moment';
import numbro from 'numbro';

import { DEFAULT_DATERANGEPICKER_FORMAT } from '../constants/datePickerConstants';
import {
  FileExtensionEnum, FileMimeTypeEnum, PaymentProcessEnum,
} from '../enums';
import { NextAccountData } from '../models/account';
import { Payment } from '../models/crm/types';
import { TokenDecodedData } from '../models/enroll';
import {
  Balance, Execution, Order, Position, RawBalanceData,
  RawExecutionData,
  RawOrderData, RawPositionData,
} from '../models/trading/types';
import { RootState } from '../store/index';
import { ONE, THOUSAND } from '../store/market-data/charting/constants';
import { UNREALIZED_PROFIT_THOUSAND_HUNDRED_PERCENT } from '../store/reporting/charting/constants';
import { PositionType } from '../store/reporting/types';

import { defaultLogoBase64 } from './assets/logos';
import { restResponseWrapper } from './CommonHelpers';
import {
  AllowedFileExtensions,
  FileSizeRanges,
  INVALID_VALUE,
  NullableNumber,
  NullableString,
  TypedObject,
} from './types';

export const RegExStrings = {
  endingWithDot: /[.]$/g,
  allCommas: /,/g,
  everyThirdDigit: /(?=(\d{3})+(?!\d))/g,
  startingWithComma: /^,/g,
  whitespaces: /\s/g,
  decimalNumber: /^(\d+(\.\d{0,2})?|\.\d{1,2})$/g,
  languageCode: /[\\?&]lang=([^&#]*)/g,
  plusChar: /\+/g,
};

export function selectNextAccount(accounts: string[], selected: number): NextAccountData {
  let newSelected = selected + 1;

  if (newSelected > accounts.length - 1) {
    newSelected = 0;
  }

  return {
    selected: newSelected,
    account: accounts[newSelected],
  };
}

export function selectCurrentAccount(accounts: string[], selected: number): string {
  let newSelected = selected;

  if (newSelected > accounts.length - 1) {
    newSelected = 0;
  }

  return accounts[newSelected];
}

export function isValidCurrencyValue(value: NullableNumber | string) {
  let newValue = value;

  if (typeof newValue === 'string') {
    if (isNaN(newValue as any as number)) return false;

    newValue = parseFloat(newValue);
  }
  return (newValue != null && !isNaN(newValue!));
}

export function formatCurrency(value: NullableNumber | string, doRound = false, useSuffix = false, useMantisa = true) {
  let newValue = value;

  if (!isValidCurrencyValue(newValue)) return INVALID_VALUE;

  if (typeof newValue === 'string') newValue = parseFloat(newValue);

  const mantisa = useMantisa ? { mantissa: doRound ? 0 : 2 } : { };

  return (
    numbro(fixToFloat(newValue as number)).formatCurrency({
      ...mantisa,
      average: useSuffix,
      currencyPosition: 'prefix',
      thousandSeparated: true,
      spaceSeparated: false,
    })
  );
}

export function formatNumber(
  value: NullableNumber | string,
  doRound: boolean = false,
  useSuffix: boolean = false,
  spaceSeparated: boolean = true,
  fixedPosition: number = 2,
  thousandSeparated = true,
): string {
  let newValue = value;

  if (!isValidCurrencyValue(newValue)) {
    return INVALID_VALUE;
  }

  if (typeof newValue === 'string') {
    newValue = parseFloat(newValue);
  }

  return numbro(newValue as number).format({
    average: isBoolean(useSuffix) ? useSuffix : false,
    mantissa: doRound ? 0 : fixedPosition,
    thousandSeparated,
    spaceSeparated,
  });
}

export function formatNumberWithSuffix(
  value: NullableNumber | string,
  suffix: string = '',
  fallBackValue = INVALID_VALUE,
  prefix: string = '',
): string {
  let newValue = formatNumber(value);
  if (newValue === INVALID_VALUE) {
    return fallBackValue;
  }

  return prefix + newValue + suffix;
}

export function formattedCurrencyToNumber(currencyValue: NullableString) {
  // TODO: ART-198 Add constant (INVALID_VALUE) everywhere in code for '-' as formatted currency value that is invalid
  //       Perhaps use numbro for parsing if it stays
  if (currencyValue === INVALID_VALUE) {
    return NaN;
  }

  return parseFloat(currencyValue!.replace('$', '').replace(/,/g, ''));
}

export function formatTransaction(payment: Payment, type: 'amount' | 'date') {
  const { amount, payment_process, create_date } = payment;
  const { getDateTime } = require('./DateTimeHelpers');

  switch (type) {
    case 'amount':
      return (
        amount < 0 || payment_process === PaymentProcessEnum.Withdrawal
          ? `-${formatCurrency(Math.abs(amount))}`
          : `+${formatCurrency(amount)}`
      );

    case 'date':
      return getDateTime(true, [ 'format', 'local' ], [ [ `${DEFAULT_DATERANGEPICKER_FORMAT} \u2022 HH:mm` ], [] ], create_date);

    default:
      break;
  }

  return '';
}

export function calculateCost(order: Order, filledSoFar?: number): number | null {
  const { price, orderQty } = order;
  if (!isValidCurrencyValue(price) || !isValidCurrencyValue(orderQty)) {
    return null;
  }

  return (price! * (filledSoFar != null ? filledSoFar : orderQty!));
}


export function calculateStopOrderValue(quantity: NullableNumber, targetPrice: NullableNumber): number | null {
  if (!isValidCurrencyValue(quantity) || !isValidCurrencyValue(targetPrice)) {
    return null;
  }

  return (quantity! * targetPrice!);
}

export function parseBalance(rawBalance: RawBalanceData): Balance {
  const {
    account,
    availBp,
    totalBp,
    usedBp,
    equity,
    equityPlusRpnl,
    ordExp,
    posExp,
    rpnl,
  } = rawBalance;

  return {
    account,
    availBp: parseFloat(availBp),
    totalBp: parseFloat(totalBp),
    usedBp: parseFloat(usedBp),
    equity: parseFloat(equity),
    equityPlusRpnl: parseFloat(equityPlusRpnl),
    ordExp: parseFloat(ordExp),
    posExp: parseFloat(posExp),
    rpnl: parseFloat(rpnl),
  };
}

export function parsePosition(rawPosition: RawPositionData): Position {
  const { avgPrice, qty, symbol } = rawPosition;
  return {
    symbol,
    qty: parseInt(qty),
    avgPrice: parseFloat(avgPrice),
  };
}

export function parseNumberOrUndefined(value: NullableString): number | undefined {
  if (value == null) return undefined;

  const parsedValue = Number(value);
  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function parseExecution(rawExecution: RawExecutionData): Execution {
  const {
    execId, timeStamp, fillQty, fillPrice,
  } = rawExecution;
  return {
    execId,
    timeStamp,
    fillQty: parseInt(fillQty),
    fillPrice: parseFloat(fillPrice),
  };
}

export function parseOrder(rawOrder: RawOrderData): Order {
  const {
    orderQty,
    price,
    stopPrice,
    filledSoFar,
    fills,
    avgPriceSoFar,
  } = rawOrder;

  return {
    ...rawOrder,
    orderQty: parseNumberOrUndefined(orderQty!),
    price: parseNumberOrUndefined(price!),
    stopPrice: parseNumberOrUndefined(stopPrice!),
    filledSoFar,
    avgPriceSoFar: parseNumberOrUndefined(avgPriceSoFar),
    fills: fills?.map(item => parseExecution(item)) ?? undefined,

  };
}

/**
 *
 * @param path String with $1, $2, etc. for parameters
 * @param pathParams Array of strings for replacing $N with pathParams[N-1] etc
 */
export function applyPathParams(
  path: string,
  pathParams?: (string | number | boolean | null)[] | null,
) {
  if ((!pathParams || pathParams.length === 0) && path.indexOf('$') === -1) {
    return path;
  }

  let resultPath = path;
  let n = 1;

  while (resultPath.indexOf('$') > -1) {
    if (pathParams) {
      const current = pathParams[n - 1];
      if (isObject(current) || current == null || current === '') {
        throw new Error(`[DataHelpers::applyPathParams] Path parameter ${n} is not valid - '${current}'`);
      }

      resultPath = resultPath.replace(`$${n}`, `${current}`);
    } else {
      throw new Error(`[DataHelpers::applyPathParams] Expected path parameter ${n} is not present`);
    }

    n++;
  }

  return resultPath;
}

/**
 * Returns:
 * - a string of concatenated string values,joined by separator (defaults to `/`)
 * - or if `arrayOfNumeric` is `true` - an array of the numeric keys in the enum `enumValue`
 * @param enumValue The enum to join values of
 * @param separator The string to use as separator
 * @param arrayOfNumeric If true - returns an array of only numeric values
 * @returns A string or an array of strings
 */
export function joinEnum(enumValue: any, separator = '/', arrayOfNumeric = false) {
  const keys = Object.keys(enumValue);
  const values = Object.values(enumValue);

  if (arrayOfNumeric && !isNumber(keys[0]) && !isNumber(values[0])) {
    throw new Error('[DataHelpers/joinEnum] Enum has no numeric values');
  }

  if (arrayOfNumeric) {
    return (
      keys
        .filter(item => isNumber(item))
        .map(item => parseInt(item))
    );
  }

  return (
    values
      .filter(item => !isNumber(item))
      .join(separator)
  );
}

export function extractDataNameFromGetCall(callName: string): string {
  return callName[3].toLowerCase() + callName.substring(4);
}

export function newPromiseWithReduxCacheData(data: any) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(success => success(restResponseWrapper({ data, isFromCache: true })));
}

/**
 * Returns `true` if value is a number
 * Returns `false` otherwise, including if NaN or Infinity by default
 * @param checkInvalidNumberValues If set to false - returns `true` in the cases of NaN and Infinity
 */
export function isNumber(value: any, checkInvalidNumberValues = true) {
  if (value == null) return false;
  if (isArray(value)) return false;
  if (isBoolean(value)) return false;

  const asNumber = Number(value);
  if (checkInvalidNumberValues) return isFinite(asNumber);
  return isNumberLodash(asNumber);
}

/**
 * Checks if all properties in source have the same value in target
 * @param source Source data
 * @param target Target data
 * @param skipRegExp
 */
export function isObjectModified(source: any, target: any, skipRegExp?: RegExp | null, useStrictComparison = true) {
  if (source == null && target == null) return false;
  if (source !== target && (source == null || target == null)) return true;

  /* eslint-disable no-restricted-syntax */
  for (const key in source) {
    if (isArray(source[key]) || isArray(target[key])) {
      if (source[key].length !== target[key].length) {
        return true;
      }
    }
    if (Object.prototype.hasOwnProperty.call(source, key) && isObject(source[key])) {
      if (isObjectModified(source[key], target[key], skipRegExp)) {
        return true;
      }
    } else {
      const skip = (skipRegExp && key.search(skipRegExp) > -1);
      if (!skip) {
        if (useStrictComparison && source[key] !== target[key] && !skip) return true;
        if (!useStrictComparison && source[key] != target[key] && !skip) return true; // eslint-disable-line eqeqeq
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return false;
}

/**
 * Returns a new object copy without the skipped properties
 * @param object Source object
 * @param properties List of properties to skip
 */
export function skipPropsObject<T extends any = any>(object: any, properties: string[]): T {
  if (!isObjectLike(object) || !isArrayLike(properties)) {
    console.error(`[DataHelpers] Invalid input - object is ${typeof object}, properties is ${typeof properties}`);
    return object;
  }

  let result = { ...object };
  properties.forEach(prop => delete result[prop]);

  return result;
}

export function getFileMimeType(fileName: string): string {
  const allowedFileExtensions = AllowedFileExtensions.join('|');

  const fileExtensionRegex = new RegExp(`(?!\\.)(${allowedFileExtensions})$`, 'i');
  const fileExtensionMatches = fileName.match(fileExtensionRegex);

  if (!fileExtensionMatches) {
    return FileMimeTypeEnum.PlainText;
  }

  switch (fileExtensionMatches[0].toLowerCase()) {
    case FileExtensionEnum.Pdf:
    case FileExtensionEnum.HTML:
      return FileMimeTypeEnum.Pdf;
    case FileExtensionEnum.Jpg:
    case FileExtensionEnum.Jpeg:
      return FileMimeTypeEnum.Jpg;
    case FileExtensionEnum.Png:
      return FileMimeTypeEnum.Png;
    default:
      return FileMimeTypeEnum.PlainText;
  }
}
/**
 * Returns a string base 64 url string
 * @param imageData The string with the image data
 * @param mimeType Of type FileMimeTypeEnum or string (see also `getFileMimeType` for an example with type string)
 * @return Returns a string base 64 url string
 */
export function createBase64DataUrl(
  imageData: NullableString,
  mimeType: FileMimeTypeEnum | string = FileMimeTypeEnum.Jpeg,
) {
  if (!imageData || !mimeType) return `data:${mimeType};base64,${defaultLogoBase64}`;
  if ((imageData).startsWith('data:image')) return imageData;
  return `data:${mimeType};base64,${imageData}`;
}

export function getDataFromBase64DataUrl(base64DataUrl: string, mimeType: string): string {
  return base64DataUrl.replace(`data:${mimeType};base64,`, '');
}

export const propertyOf = <TObj>(name: keyof TObj) => name;

export function fixToFloat(value: number, toFixed: number = 2): number {
  const nullableValue = parseInt('0', 10).toFixed(toFixed);

  if (!value) {
    return parseFloat(nullableValue);
  }

  const isNegativeValue = value < 0;

  // If the exp is undefined or zero...
  if (+toFixed === 0) {
    return Math.round(value);
  }

  let newValue = Math.abs(value);

  // If the value is not a number or the exp is not an integer...
  if (!((toFixed % 1 === 0))) {
    return parseFloat(nullableValue);
  }

  let toStringArrayValue = newValue.toString().split('e');

  newValue = Math.round(+(`${toStringArrayValue[0]}e${toStringArrayValue[1] ? (+toStringArrayValue[1] + toFixed) : toFixed}`));

  toStringArrayValue = newValue.toString().split('e');

  newValue = +(`${toStringArrayValue[0]}e${toStringArrayValue[1] ? (+toStringArrayValue[1] - toFixed) : -toFixed}`);

  return !isNegativeValue ? newValue : -newValue;
}

/**
 * Returns string:
 * an hour ago,
 * 2 hours ago,
 * 1 year ago
 */
export function getTimeDiffFromNow(dateFromTimestamp: number, withoutSuffix: boolean = false): string {
  return moment(dateFromTimestamp).fromNow(withoutSuffix);
}

/**
 * Returns string as Lorem ipsum...
 */
export function getLimitedText(text: NullableString, maxCharacters: number = 100): string {
  if (!text) return INVALID_VALUE;

  const trimmedText = text.trim();

  if (trimmedText.length <= maxCharacters) {
    return trimmedText;
  }

  return `${trimmedText.substr(0, maxCharacters)}...`;
}

export function decodeJwt(token: NullableString): TokenDecodedData | null {
  try {
    if (token) return require('jwt-decode').default(token);
  } catch (error) {
    console.error(`[DateTimeHelpers] Could not decode token - ${error}`);
  }

  return null;
}


/**
 * Checks if given value is negative
 * negative values can be with `-` sign
 * or as an accounting negative value in braces `(value)`
 */
export function hasNegativeValue(value: string | number | undefined): boolean {
  if (!value) {
    return false;
  }

  const valueToCheck = (typeof value !== 'string') ? value.toString() : value;

  return /^(\(|-)/.test(valueToCheck);
}

/**
 @deprecated
 * use directly SAMPLE_ARRAY.includes(...).
 * This already calls the "includes" method of the Array.
 * There is no difference between them. This wrapper method is redundant. * Checks whether `value` is in `values`
 */
export function isOneOf<T extends any = any, V extends any = any>(value: V, values: T[]) {
  return values?.includes(value as any);
}

/**
 * Shallow comparison of two objects
 */
export function areEqualObjects(object1: any, object2: any, isEmptyEqualToNull: boolean = false) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    const skip = !object1[key] && !object2[key] && isEmptyEqualToNull;
    if (!skip) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
  }
  return true;
}

export const generatingNumberFromBytes = (bytes: number, sizes: FileSizeRanges = 'MB'): number => {
  let step: number;
  switch (sizes) {
    case 'KB': step = 10; break;
    case 'GB': step = 30; break;
    default: step = 20; break;
  }

  return bytes / (2 ** step);
};

// Base64 encodes 3 bytes of binary data on 4 characters.
export const calculateBase64toBytes = (base64: NullableString): number => {
  if (!base64) return 0;
  return Math.ceil(base64.length / 4) * 3;
};

export const isPhotoUnder10MB = (base64: NullableString): boolean => (
  generatingNumberFromBytes(calculateBase64toBytes(base64)) < 10
);
export const hasXDecimalsOrMore = (value: string, numberOfDecimals: number = 2): boolean => value.split('.')[1]?.length >= numberOfDecimals;

export const checkIfAllIsNegative = (data: number[]): boolean => data.every(el => el < 0);

// eslint-disable-next-line max-len
export const unrealizedPnL = (quantity: NullableNumber, marketPrice: NullableNumber, averageOpeningPrice: number) => ((quantity == null || marketPrice == null) ? null : (quantity * (marketPrice - averageOpeningPrice)));

export const roundUnrealizedProfit = (unrealizedProfit: NullableNumber, hasDecimal: boolean): string => {
  if (unrealizedPnL == null) return INVALID_VALUE;

  let unrealizedProfitValue: string = '';

  if (hasDecimal) {
    unrealizedProfitValue = formatCurrency(unrealizedProfit);
  } else {
    unrealizedProfitValue = formatCurrency(unrealizedProfit, true);
  }

  return unrealizedProfit! > 0 ? `+${unrealizedProfitValue}` : unrealizedProfitValue;
};

// eslint-disable-next-line max-len
export const calcUnrealizedProfitPercent = (positionType: string, marketPrice: NullableNumber, averageOpeningPrice: number): string | number => {
  if (marketPrice == null) return INVALID_VALUE;

  const value = positionType === PositionType.Buy
    ? ((marketPrice - averageOpeningPrice) / averageOpeningPrice) * 100
    : ((averageOpeningPrice - marketPrice) / averageOpeningPrice) * 100;

  return Math.abs(value) >= UNREALIZED_PROFIT_THOUSAND_HUNDRED_PERCENT ? Math.floor(value) : formatNumber(value);
};

export const getStringLiteral = <T>(elements: T | T[], separator: string = ', '): string => {
  if (Array.isArray(elements)) {
    return elements.join(separator);
  }
  return String(elements);
};

export const roundPercentValues = (value: number) => {
  switch (true) {
    case value > THOUSAND:
      return formatNumber(value, true);
    case value < ONE:
      return formatNumber(value);
    default: return formatNumber(value, false, false, true, 1);
  }
};

function extractSymbolsNotInCache(state: RootState, symbols: string | string[], returnAsString: boolean) {
  let theSymbols: string[] = [];
  if (symbols) {
    if (isString(symbols)) {
      theSymbols = symbols.split(',');
    } else {
      theSymbols = symbols;
    }
  }

  const result: string[] = [];
  theSymbols.forEach(symbol => {
    if (!state.fundamental.companyLogoAndName[symbol]?.logo) {
      result.push(symbol);
    }
  });

  return returnAsString ? result.join() : result;
}

export function extractSymbolsNotInCacheAsArray(state: RootState, symbols: string | string[]) {
  return extractSymbolsNotInCache(state, symbols, false) as string[];
}

export function extractSymbolsNotInCacheAsString(state: RootState, symbols: string | string[]) {
  return extractSymbolsNotInCache(state, symbols, true) as string;
}
