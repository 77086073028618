import { CURRENT_YEAR } from '../../lib/constants/date-time.constants';
import { SourceOfFundEnum } from '../../lib/enums/source-of-fund.enum';
import { ENROLL_METHOD, FinancialQuestionnaireSectionItem, SourceOfFundsAnswerData, SourceOfFundsAnswerExtraData } from '../../lib/models/enroll';
import { parsePropsFromSourceOfFundsAnswerState } from '../../lib/store/crm/helpers';
import { yearToDate } from '../../lib/util/DateTimeHelpers';
import { compactPropsIntoString } from '../../lib/util/TextHelpers';
import { INVALID_YEAR } from '../../lib/util/types';

import { GeneralQuestionsCustomAnswers } from './EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import {
  KEY_SOURCE_OF_FUND_ADDRESS,
  KEY_SOURCE_OF_FUND_BIRTH,
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_HAS_MAIN,
  KEY_SOURCE_OF_FUND_ID,
  KEY_SOURCE_OF_FUND_IDENTITY_NAME,
  KEY_SOURCE_OF_FUND_SPECIFY_TYPE,
  KEY_SOURCE_OF_FUND_TO,
  KEY_SOURCE_OF_FUND_TYPE,
  KEY_SOURCE_OF_FUND_YEAR,
} from './EnrollGeneralQuestions/EnrollGeneralQuestions.constants';
import {
  ConvertKeyFunction,
  ConvertValueFunction,
  GetterFunction,
  Key,
  NA,
  SetStateValueFunc,
  SoFKey,
  StoreInstance,
} from './types';

export function convertSoFKeyToKey(key: SoFKey) {
  /* eslint-disable no-param-reassign */
  switch (key) {
    case 'id': return KEY_SOURCE_OF_FUND_ID;
    case 'from': return KEY_SOURCE_OF_FUND_FROM;
    case 'to': return KEY_SOURCE_OF_FUND_TO;
    case 'name': return KEY_SOURCE_OF_FUND_IDENTITY_NAME;
    case 'type': return KEY_SOURCE_OF_FUND_TYPE;
    case 'address': return KEY_SOURCE_OF_FUND_ADDRESS;
    case 'specify-type': return KEY_SOURCE_OF_FUND_SPECIFY_TYPE;
    case 'has-main': return KEY_SOURCE_OF_FUND_HAS_MAIN;
    case 'birth': return KEY_SOURCE_OF_FUND_BIRTH;
    case 'year': return KEY_SOURCE_OF_FUND_YEAR;
  }
  /* eslint-enable no-param-reassign */

  return '';
}
export type FormKey = ReturnType<typeof convertSoFKeyToKey>;

/**
 * Creates a setter that updates local cache of type GeneralQuestionsCustomAnswers
 * by property name `key`
 * @param setData The setter of the state
 * @param key Proprerty name
 * @param value Property value
 */
export function stateFactory<
  F extends SetStateValueFunc = SetStateValueFunc,
  K extends Key = Key,
  V = any,
>(setData?: F, state?: Record<K, V> | null, name?: string): StoreInstance {
  return {
    set: (key: Key, value: V) => {
      setData?.((currentState: Record<K, V>) => ({
        ...currentState,
        [key]: value,
      }));
    },
    // eslint-disable-next-line arrow-body-style
    get: (key: Key) => state?.[key],
  };
}

export function getterConvertFactory<
  C extends Key = Key,
  R = any,
  K extends Key = Key,
  V = any,
>(
  cache: Record<K, V>,
  convertKey?: ConvertKeyFunction<C, K> | null,
  convertValue?: ConvertValueFunction<V, R>,
): GetterFunction<
  (typeof convertKey extends NA ? K : C),
  (typeof convertValue extends NA ? V : R)
> {
  return (key: (typeof convertKey extends NA ? K : C)) => {
    if (convertKey) {
      const theKey = convertKey(key);
      return convertValue ? convertValue(cache[theKey]) : cache[theKey];
    }

    const value = cache[key as any];
    if (convertValue) return convertValue(value) as R;

    return value;
  };
}

export function prepareSoFRequestBody(
  getValue: GetterFunction<SoFKey, Key>,
  lastAnswer: SourceOfFundsAnswerData,
  currentQuestion: FinancialQuestionnaireSectionItem | null,
): any {
  const id = getValue('id');
  const from = yearToDate(getValue('from'));
  const to = yearToDate(getValue('to') || CURRENT_YEAR);
  const name = getValue('name') as string;
  const type = getValue('type') as string;
  const hasMain = getValue('has-main');
  const address = getValue('address') as string;
  const customName = compactPropsIntoString([name, address]);
  const customType = hasMain === '1' ? hasMain : null;

  const { getBody, method } = currentQuestion ?? {};
  const { id: lastId } = lastAnswer ?? {};
  const sourceId = `${ENROLL_METHOD[method!]}`.startsWith('post') ? null : lastId;

  if (!getBody) {
    console.warn('[sof-helpers] prepareSoFRequestBody - getBody is null');
    return null;
  }

  let result;
  switch (id) {
    case SourceOfFundEnum.AtHomeTradingAlaric:
    case SourceOfFundEnum.AtHomeTradingFlb:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    case SourceOfFundEnum.SalaryAlaric:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    case SourceOfFundEnum.InheritanceAlaric:
    case SourceOfFundEnum.InheritanceFlb:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    case SourceOfFundEnum.FamilyMemberAlaric:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    case SourceOfFundEnum.PensionOrBenefitProgramAlaric:
    case SourceOfFundEnum.PensionOrGovernmentBenefitsFlb:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    case SourceOfFundEnum.InvestmentsAndSavingsAlaric:
    case SourceOfFundEnum.InvestmentsAndSavingsFlb: {
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    }
    case SourceOfFundEnum.SelfEmploymentAlaric: {
      result = getBody(
        sourceId,
        id,
        from,
        to,
        hasMain ? customName : '',
        hasMain ? customType : '',
      );
      break;
    }
    case SourceOfFundEnum.LoanAlaric:
      result = getBody(
        sourceId,
        id,
        from,
        to,
        name,
        type,
      );
      break;
    default:
      console.warn(`[sof-helpers/prepareSoFRequestBody] Invalid id for answer type - ${id}`);
      break;
  }
  return result;
}
