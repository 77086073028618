import React from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import lang from '../../../lib/language';

import { LatestNews } from '../../../components';
import { useIsMobile } from '../../../hooks';
import { EARNINGS_CONTENT_UNLIMIT_LENGTH } from '../EarningCalendat.constants';
import Table from '../Table/Table';

import styles from './EarningsCalendarContent.module.scss';

const EarningsCalendarContent = () => {
  const isMobile = useIsMobile();
  const { state } = useLocation<{ startDate: string, endDate: string }>();

  return (
    <>
      <div className={classNames(styles.wrapperLayout, { [styles.wrapperLayoutMobile]: isMobile })}>
        <div className={styles.wrapperFirstSection}>
          <h6 className={
            classNames(
              styles.title,
              { [styles.titleMobile]: isMobile },
            )
          }
          >
            {lang.commonDiscoverCalenderSectionEarningsTitle()}
          </h6>
          <Table
            maxElements={EARNINGS_CONTENT_UNLIMIT_LENGTH}
            minDateState={state.startDate}
            maxDateState={state.endDate}
          />
        </div>
        {!isMobile && (
          <div className={styles.wrapperSecondSection}>
            <LatestNews />
          </div>
        )}
      </div>
    </>
  );
};

export default EarningsCalendarContent;
