import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import FilterDropDown from '../../containers/TopGainersAndLosers/components/FilterDropDown/FilterDropDown';
import { useIsMobile } from '../../hooks';
import { NavigationState } from '../../util/constants';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button } from '../index';

import { IProps } from './Navigation.types';

import styles from './Navigation.module.scss';

const Navigation = (props: IProps): JSX.Element => {
  const {
    tabs,
    setActiveTab,
    rightSideLink,
    align = '',
    sendCurrentTabIndex,
    secondaryTabs = false,
    tertiaryTabs = false,
    fluidTabs = false,
    initialActiveTabIndex = 0,
    handleFilterOption,
    currentTabTopMovers,
    hasNoContent,
    noContentComponent,
  } = props;

  const isMobile = useIsMobile();
  const elementsRefs = useRef<HTMLDivElement[]>([]);
  const history = useHistory();

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabSliderWidth, setTabSliderWidth] = useState<number>(30);
  const [tabSliderLeftPosition, setTabSliderLeftPosition] = useState<number>(0);

  const updateTabSliderCSS = useCallback((activeElementIndex) => {
    const activeTabElement = elementsRefs.current[activeElementIndex];

    if (activeTabElement) {
      setTabSliderWidth(activeTabElement.offsetWidth);
      setTabSliderLeftPosition(activeTabElement.offsetLeft);
    }
  }, []);

  const navigation = useMemo(() => tabs.map((item, index) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      ref={(ref: HTMLDivElement) => {
        if (!elementsRefs.current[index]) {
          elementsRefs.current.push(ref);
        }
      }}
      className={classNames(styles.nav, {
        [styles.navActive]: currentTab === index,
        [styles.fluidTab]: fluidTabs,
      })}
      onClick={() => {
        setCurrentTab(index);
        sendCurrentTabIndex && sendCurrentTabIndex(index);
      }}
      key={item.text}
    >
      <div
        className={[
          styles.tab,
          tertiaryTabs && styles.tabTertiary,
          secondaryTabs && styles.tabSecondary,
        ].join(' ')}
      >
        <span className={styles.name}>{item.text}</span>
      </div>
    </div>
  )), [
    tabs,
    fluidTabs,
    currentTab,
    tertiaryTabs,
    secondaryTabs,
    sendCurrentTabIndex,
  ]);

  const sliderStyles = useMemo((): CSSProperties => ({
    left: `${tabSliderLeftPosition}px`,
    width: `${tabSliderWidth}px`,
  }), [tabSliderLeftPosition, tabSliderWidth]);

  useEffect(() => {
    updateTabSliderCSS(currentTab);
  }, [currentTab, updateTabSliderCSS]);

  useEffect(() => {
    if (!isInitialised) {
      setCurrentTab(initialActiveTabIndex);
      sendCurrentTabIndex && sendCurrentTabIndex(initialActiveTabIndex);
      setIsInitialised(true);
    }
  }, [initialActiveTabIndex, isInitialised, sendCurrentTabIndex]);

  useEffect(() => {
    if (setActiveTab !== undefined) {
      setCurrentTab(setActiveTab);
    }
  }, [setActiveTab]);

  return (
    <>
      <div
        className={[
          classNames(styles.container, { [styles.containerMob]: isMobile }),
          (secondaryTabs || tertiaryTabs) && styles.secondaryContainer,
        ].join(' ')}
      >
        <div style={sliderStyles} className={styles.tabSlider} />

        <div
          className={classNames(styles.tabs, {
            [styles.alignLeft]: align === 'left',
            [styles.alignRight]: align === 'right',
            [styles.fluidTabsWrapper]: fluidTabs,
          })}
        >
          {navigation}
        </div>

        { rightSideLink && (
        <Button
          variant={ButtonTypesEnum.Link}
          onClick={() => {
            if (rightSideLink.state) {
              history.push({ pathname: rightSideLink.link,
                state: {
                  [NavigationState]: rightSideLink.state,
                },
              });
            } else {
              history.push(rightSideLink.link);
            }
          }}
          className={styles.viewAll}
        >
          {rightSideLink.text}
        </Button>
        )}
        { handleFilterOption && (
        <span className={isMobile ? styles.filterDropDownMob : styles.filterDropDown}>
          <FilterDropDown
            handleFilterOption={handleFilterOption}
            hasBackground={false}
            hasExpandArrow={false}
            currentTabTopMovers={currentTabTopMovers}
          />
        </span>
        ) }
      </div>
      {hasNoContent ? noContentComponent : tabs[currentTab].component}
    </>
  );
};

export default Navigation;
