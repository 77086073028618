import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../../debug/helpers/entity-redux-logger';
import { TopGainerAndLoserData } from '../../models/fundamental/types';

import { INITIAL_STATE } from './constants';
import { getInitialItems, setInitialItems, updateTopMovers } from './helpers';
import { TopMoversCacheData } from './types';

// TODO : This cache file will be simplified within ART-3785
const TopLosersCache = entity(INITIAL_STATE, entityReduxLogger('TopLosers', 'top-losers'));

const self = TopLosersCache;


/**
 * Must be used only by the `initializeTopMoversDataEpic` epic in `store/fundamental/epics.ts`
 */
export const setTopLosersInitialItems = setInitialItems(self);

/**
 * Must be used only by the `getPriceForTopMovers` helper in `store/fundamental/helpers.ts`
 */
export const updateTopLosers = (newData: TopGainerAndLoserData[]) => {
  updateTopMovers(self, newData);
};

/**
 * Must be used only by the `getPriceForTopMovers` helper in `store/fundamental/helpers.ts`
 */
export const setTopLosersTimeout = (isTimeout = true) => {
  self.set(produce<TopMoversCacheData>(state => {
    state.isTimeout = isTimeout;
    state.isReady = true;
  }));
};

export const setTopLosersReady = (isReady = true) => {
  self.set(produce<TopMoversCacheData>(state => {
    state.isReady = isReady;
  }));
};

// hiding direct setter by not including it in exports
export default {
  use: self.use,
  get: self.get,
  getInitialItems: getInitialItems(self),
};
