import React, { Dispatch, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../../../../lib/language';
import { RootState } from '../../../../../../lib/store';
import { sendResetPassword } from '../../../../../../lib/store/auth';

import Envelop from '../../../../../../assets/img/icon-envelop-verify.png';
import Button from '../../../../../Button/Button';
import { ButtonTypesEnum } from '../../../../../Button/Button.types';

import styles from './ModalChangePassword.module.scss';

type Props = {
    handleModal: () => void;
} & ReduxProps

const ModalChangePassword = (props: Props) => {
  const {
    handleModal,
    resetPassword,
    individual,
  } = props;

  const [infoModal, setInfoModal] = useState<{title: string, isProceed: boolean}>(
    {
      title: lang.mobileChangePasswordModalText(),
      isProceed: false,
    },
  );

  const handleProceed = () => {
    if (infoModal.isProceed) {
      handleModal();
    } else {
      resetPassword();
      setInfoModal({ ...infoModal, title: lang.mobilePasswordChangeEmailTitle(), isProceed: true });
    }
  };

  return (
    <>
      <h6 className={styles.title}>{infoModal.title}</h6>
      {
        !infoModal.isProceed
          ? (
            <Button
              variant={ButtonTypesEnum.Tertiary}
              fullWidth
              className={styles.btnCancel}
              onClick={() => handleModal()}
            >
              {lang.commonCancelButton()}
            </Button>
          )
          : (
            <>
              <span className={styles.messageSentEmail}>
                {
                `${lang.mobilePasswordChangeEmailUnderlineHeading1()} 
                 ${individual!.email} 
                 ${lang.mobilePasswordChangeEmailUnderlineHeading2()}
                 `
                }
              </span>
            </>
          )
      }
      <Button variant={ButtonTypesEnum.Primary} fullWidth onClick={handleProceed}>
        { infoModal.isProceed ? lang.commonOkay() : lang.commonOrderSideButtonProceed() }
      </Button>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetPassword: () => dispatch(sendResetPassword({})),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ModalChangePassword);
