import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { InboxResponse } from '../../../../../../lib/models/ams/types';
import { getMessage, markAsRead } from '../../../../../../lib/store/ams/index';
import { formatInboxreceptionTime } from '../../../../../../lib/util/DateTimeHelpers';

import { CheckboxControl, ClickableDiv } from '../../../../../index';

import NotificationInfo from './NotificationInfo';

import styles from '../AllNotifications.module.scss';

type Props = {
    hasCheckedMessage: boolean;
    isActiveRow: boolean;
    message: InboxResponse;
    handleCheckbox: (message: InboxResponse) => void;
    setMessageData: (message: InboxResponse) => void;
    previousMessageId?: number;
}

const MessageRow = ({
  hasCheckedMessage, isActiveRow, message, previousMessageId, handleCheckbox, setMessageData,
}: Props) => {
  const [isMessageClicked, setIsMessageClicked] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActiveRow) {
      handleSelectedMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveRow]);

  const handleSelectedMessage = ():void => {
    message.id !== previousMessageId && dispatch(getMessage(message.id));
    setMessageData(message);
    message.unread && dispatch(markAsRead(message.id));
    setIsMessageClicked(!isMessageClicked);
  };

  const handleOnClick = (event: Event) => {
    event.preventDefault();
    handleSelectedMessage();
  };

  return (
    <>
      <div key={message.id} className={styles.containerMessage}>
        <CheckboxControl
          secondary
          checked={hasCheckedMessage}
          className={styles.checkbox}
          label={''}
          onChange={() => handleCheckbox(message)}
        />
        <ClickableDiv
          className={(hasCheckedMessage)
            ? styles.checkedMessage
            : styles.uncheckedMessage}
          onClick={handleOnClick}
        >
          <div className={styles.messageTitle}>
            <span className={message.unread
              ? styles.unreadRowDate
              : styles.readRowDate}
            >
              {formatInboxreceptionTime(message.receptionTime)}
            </span>
            { message.unread && <div className={styles.circle} />}
          </div>
          <Typography
            noWrap
            className={message.unread
              ? styles.unreadRowMessageSubject
              : styles.readRowMessageSubject}
          >
            {message.subject}
          </Typography>
          {isMessageClicked && <NotificationInfo messageId={message.id} />}
        </ClickableDiv>
      </div>
    </>
  );
};

export default MessageRow;
