export enum DocumentTypeEnum {
    ProofOfIdentity = 'PID',
    ProofOfAddress = 'ADR',
    ProofOfIncome = 'INC',
    ProofOfFunding = 'FND',
    ProofOfPEPManagerialApproval = 'PEP',
    ProofOfTaxDetail = 'TAX',
    Selfie = 'SLF',
    CertificateOfIncorporation = 'COI',
    CertificateOfCurrentStanding = 'CCS',
    ArticleOfAssociation = 'AOA',
    MemorandumOfAssociation = 'MOA',
    RegisterOfDirectors = 'ROD',
    RegisterOfShareholders = 'ROS',
    Other = 'OTH'
}
