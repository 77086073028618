export enum AdjustmentCategoryEnum {
  MarketData = 'MarketData',
  Platform = 'Platform',
  Software = 'Software',
  Dividend = 'Dividend',
  Invoice = 'Invoice',
  Miscellaneous = 'Miscellaneous',
  OvernightBorrow = 'OvernightBorrow',
  PNL = 'PNL',
  ShortMarginInterest = 'ShortMarginInterest',
  StockLocate = 'StockLocate',
}
