/**
 * This slice is dedicated to File API data
 * TODO: Migrate usage from CRM to this one
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetFileByNameCall } from '../../models/file/types';
import { deleteFileByName } from '../common-actions';
import { processErrorInReducer } from '../helpers';

export interface FileState {
  files: { [fileName: string]: string };
  error: { [code: number]: string };
  deletedFileByName: boolean;
  getFileByName: boolean;
  isGettingFile: boolean;
  didGettingFileFailed: boolean;
}

export const INITIAL_STATE: FileState = {
  files: {},
  error: {},
  deletedFileByName: false,
  getFileByName: false,
  isGettingFile: false,
  didGettingFileFailed: false,
};

const fileSlice = createSlice({
  name: 'file',
  initialState: INITIAL_STATE,
  reducers: {
    getIndividualFilesCompleted(state, action) {
      state.files = { ...state.files, ...action.payload.filesContents };
    },
    getIndividualFilesFailed(state, action) {
      processErrorInReducer(state, action);
    },
    deleteFileByNameFailed(state) {
      state.deletedFileByName = false;
    },
    deleteFileByNameSuccess(state, action) {
      const fileName = action.payload;
      const files = { ...state.files };

      if (typeof files[fileName] !== 'undefined') {
        delete files[fileName];
      }

      state.files = { ...files };
      state.deletedFileByName = true;
    },
    getFileByName(state: FileState, action: PayloadAction<GetFileByNameCall>) {
      state.getFileByName = false;
      state.isGettingFile = true;
      state.didGettingFileFailed = false;
    },
    getFileByNameCancel(state: FileState) {
      state.isGettingFile = false;
      state.didGettingFileFailed = false;
    },
    getFileByNameFailed(state: FileState, action) {
      state.getFileByName = false;
      state.isGettingFile = false;
      state.didGettingFileFailed = true;
      processErrorInReducer(state, action);
    },
    getFileByNameSuccess(state: FileState, action) {
      state.isGettingFile = false;
      state.didGettingFileFailed = false;
      const { customData, data } = action.payload;
      const { fileName } = customData;
      state.files[fileName] = data;
      state.getFileByName = true;
    },
  },
  extraReducers: {
    [deleteFileByName.type](state) {
      state.deletedFileByName = false;
    },
  },
});

export const {
  getIndividualFilesCompleted,
  getIndividualFilesFailed,
  deleteFileByNameFailed,
  deleteFileByNameSuccess,
  getFileByName,
  getFileByNameCancel,
  getFileByNameFailed,
  getFileByNameSuccess,
} = fileSlice.actions;

export default fileSlice.reducer;
