import PasswordValidator from 'password-validator';

// -- Empty String or Special Char validation ---
const EmptyStringOrSpecialSymbolSchema = new PasswordValidator();
EmptyStringOrSpecialSymbolSchema
  .is().min(1)
  .not().symbols(1);

export const validateForEmptyStringOrSpecialSymbol = (validationTarget: string):
boolean => EmptyStringOrSpecialSymbolSchema
  .validate(validationTarget, { list: false }) as boolean;
