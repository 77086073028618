/**
 * Charting Helpers for processing data for Line and Bar Charts (more to come soon ...)
 * Initial commits may have mixed authorship (due to merges of ART-1142 and ART-1145).
 * Originally:
 * @author Alex K <alexander.kostadinov@alaricsecurities.com>
 */
import { maxBy, minBy } from 'lodash';

import { OrderSideEnum } from '../../../enums';
import { CHARTING_TIME_TZ } from '../../../libSettings';
import { GWMarketDataSummaryBucket } from '../../../models/gateway/types';
import { OHLCV } from '../../../models/market-data/types';
import { Order } from '../../../models/trading/types';
import CallsCache from '../../../store-util/calls-cache/CallsCache';
import {
  calculatePoints,
  fiveYearAxisValues,
  monthlyAxisValues,
  roundValue,
  threeMonthsAxisValues,
  yearlyAxisValues,
} from '../../../util/ChartingHelpers';
import { formatNumber, isValidCurrencyValue } from '../../../util/DataHelpers';
import { formatChartingDateTime, timeToFloat, timezoneDiffUTCMarketHours } from '../../../util/DateTimeHelpers';
import { NullableNumber } from '../../../util/types';

import {
  DAILY_CHART_HOURS,
  DAILY_CHART_MINUTES,
  DAILY_CHART_START_IN_MINUTES,
  EMPTY_CHART_DATA,
  LINE_DATA_RATIO,
  MAX_CHART_POINT_Y,
  MAX_VALUE,
  ONE_HUNDREDTH,
  VOLUME_RATIO,
} from './constants';
import {
  BaseChartLineData,
  ChartCombinedPointData,
  ChartDataRange,
  ChartDataSet,
  ChartLineData,
  ChartPoint,
  ChartPointWithData,
} from './types';

const dailyAxisValues = (lastDailyTime: string): string[] => {
  if (!lastDailyTime) return [ '' ];

  let count = 0;
  let dailyValues: string[] = [];

  dailyValues.push(DAILY_CHART_HOURS[0]);

  for (let i = 1; i <= DAILY_CHART_MINUTES[DAILY_CHART_MINUTES.length - 1] - DAILY_CHART_START_IN_MINUTES; i++) {
    if (i + DAILY_CHART_START_IN_MINUTES === DAILY_CHART_MINUTES[count]) {
      dailyValues.push(DAILY_CHART_HOURS[count + 1]);
      count++;
    } else {
      dailyValues.push('');
    }
  }

  return dailyValues;
};

const weeklyAxisValues = (data: OHLCV[]): string[] => {
  let weekDateValues = [ `${data[0].dateAndTime?.substring(8, 10)}/${data[0].dateAndTime?.substring(5, 7)}` ];

  for (let index = 0; index < data.length - 1; index++) {
    const previousWeek = data[index].dateAndTime?.substring(8, 10);
    const week = data[index + 1].dateAndTime?.substring(8, 10);
    if (week !== previousWeek) {
      const month = data[index + 1].dateAndTime?.substring(5, 7);
      weekDateValues.push(`${week}/${month}`);
    } else {
      weekDateValues.push('');
    }
  }

  return weekDateValues;
};


const calculateMinutes = (time: number) => (Math.floor(time) * 60 + (Math.round((time % 1) * 100))) / 5;

export const parseOHLCVToChartData = (data: OHLCV[]) => (
  data.map(({ date, close }) => ({ x: new Date(date).getTime(), y: Number(close) }) as ChartPoint)
);

// eslint-disable-next-line max-len
export const parseOHLCVToLineData = (data: OHLCV[], minDomain: number, lineDiff: number, axisValues: string[], lineDataRatio: number) => data.map(({
  close, date, time, volume,
}, index) => ({
  close,
  volume,
  x: index,
  y: calculateYLabelValue(close, lineDiff, minDomain, lineDataRatio),
  dateAndTime: `${date} ${time}`,
  xAxis: axisValues[index] === '' ? `index${index}` : axisValues[index],
}));

// eslint-disable-next-line max-len
export const parseOHLCVToLineDataDaily = (data: OHLCV[], minDomain: number, lineDiff: number, lineDataRatio: number): ChartLineData[] => {
  let lineData: ChartLineData[] = [];
  const timeDiff = timezoneDiffUTCMarketHours();
  const lastTime = data[data.length - 1].time;
  const lastTimeInMinutes = calculateMinutes(timeToFloat(lastTime)! - timeDiff);

  let dataIndex = 0;

  for (let index = DAILY_CHART_START_IN_MINUTES; index < lastTimeInMinutes + 1; index++) {
    const timeInMinutes = calculateMinutes(timeToFloat(data[dataIndex].time)! - timeDiff);
    if (timeInMinutes === index) {
      const chartIndex = index - DAILY_CHART_START_IN_MINUTES;
      const { close, volume, time, date } = data[dataIndex];

      lineData.push({
        close,
        volume,
        time,
        x: chartIndex,
        y: calculateYLabelValue(data[dataIndex].close, lineDiff, minDomain, lineDataRatio),
        dateAndTime: `${date} ${time}`,
      });
      dataIndex++;
    }
  }

  return lineData;
};

export const parseOHLCVToBarData = (
  data: number[],
  maxVolume: number,
  x: number[],
) => data.map((volume, index) => ({
  x: x[index],
  y: (volume / maxVolume) * VOLUME_RATIO * 100,
}));


const prepareScatterMinMaxData = (lineData: BaseChartLineData[]): ChartPointWithData[] => {
  const MIN_MAX_Y_AXIS_DIFF = 6;
  const scatterMinMaxData: ChartPointWithData[] = [];
  if (lineData.length < 2) {
    return scatterMinMaxData;
  }

  const maxLineData = maxBy(lineData, 'close');
  const minLineData = minBy(lineData, 'close');

  if (maxLineData) {
    const clonedLineData: ChartPointWithData = { ...maxLineData };
    clonedLineData.label = formatNumber(maxLineData.close);
    scatterMinMaxData.push(clonedLineData);
  }

  if (minLineData) {
    const clonedLineData: ChartPointWithData = { ...minLineData };
    clonedLineData.label = formatNumber(minLineData.close);
    if (maxLineData && maxLineData.y - minLineData.y < MIN_MAX_Y_AXIS_DIFF) {
      clonedLineData.y -= MIN_MAX_Y_AXIS_DIFF;
    }
    scatterMinMaxData.push(clonedLineData);
  }

  return scatterMinMaxData;
};

export const prepareChartData = (
  lineClose: NullableNumber,
  inputData: OHLCV[],
  range?: ChartDataRange,
): ChartDataSet => {
  if (!inputData || !inputData.length) {
    return EMPTY_CHART_DATA(false);
  }

  const isOneDayRange = range === '1D';
  const hasLineClose = lineClose != null;
  let axisValue: string[];

  switch (range) {
    case '1D': axisValue = dailyAxisValues(inputData[inputData.length - 1].time); break;
    case '1W': axisValue = weeklyAxisValues(inputData); break;
    case '1M': axisValue = monthlyAxisValues(inputData, 'dateAndTime'); break;
    case '3M': axisValue = threeMonthsAxisValues(inputData, 'dateAndTime'); break;
    case '1Y': axisValue = yearlyAxisValues(inputData, 'dateAndTime'); break;
    case '5Y': axisValue = fiveYearAxisValues(inputData, 'dateAndTime'); break;
      default: axisValue = [ '' ]; break; // eslint-disable-line
  }

  const closes: number[] = inputData.map(({ close }: any) => close);
  let minLineValue = Math.min(...closes);
  let maxLineValue = Math.max(...closes);
  const minCloseValue = minLineValue;
  const maxCloseValue = maxLineValue;

  if (hasLineClose) {
    if (lineClose > maxLineValue) {
      maxLineValue = lineClose;
    }

    if (lineClose < minLineValue) {
      minLineValue = lineClose;
    }
  }

  let lineData: BaseChartLineData[];
  const lineDiff = maxLineValue - minLineValue;
  const { yLabelPoints, step, normMin } = calculatePoints(minLineValue, maxLineValue);
  const lineDataRatio = calculateLineDataRatio(normMin, lineDiff, minLineValue);
  const minLineY = calculateYLabelValue(minLineValue, lineDiff, minLineValue, lineDataRatio);
  const maxLineY = calculateYLabelValue(maxLineValue, lineDiff, minLineValue, lineDataRatio);
  const minCloseY = calculateYLabelValue(minCloseValue, lineDiff, minLineValue, lineDataRatio);
  const maxCloseY = calculateYLabelValue(maxCloseValue, lineDiff, minLineValue, lineDataRatio);

  if (isOneDayRange) {
    lineData = parseOHLCVToLineDataDaily(inputData, minLineValue, lineDiff, lineDataRatio);
  } else {
    lineData = parseOHLCVToLineData(inputData, minLineValue, lineDiff, axisValue, lineDataRatio);
  }

  const yPointAverage = yLabelPoints.reduce((a, b) => a + b, 0) / yLabelPoints.length;
  const scatterMinMaxData = prepareScatterMinMaxData(lineData);
  const volumes: number[] = lineData.map(({ volume }: any) => volume);
  const xIndex: number[] = lineData.map(({ x }: any) => x);
  const maxVolume = Math.max(...volumes);
  const barData = parseOHLCVToBarData(volumes, maxVolume, xIndex);

  // Moved from Web with ART-3892
  let tickValuesWeb: number[] = [];
  if (lineData?.length) {
    const len = range === '1D' ? axisValue.length : lineData.length;
    tickValuesWeb = Array(len).fill(1).map((item, index) => index);
  }
  const maxChartPointWeb = maxCloseY > MAX_CHART_POINT_Y ? maxCloseY : MAX_CHART_POINT_Y;

  return {
    isValid: hasLineClose,
    lineDiff,
    minLineValue,
    maxLineValue,
    minCloseValue,
    maxCloseValue,
    minLineY,
    maxLineY,
    minCloseY,
    maxCloseY,
    lineData,
    barData,
    rawData: inputData,
    axisValue,
    yLabelPoints,
    step,
    lineDataRatio,
    scatterMinMaxData,
    yPointAverage,
    lastClose: closes[closes.length - 1],
    tickValuesWeb,
    maxChartPointWeb,
  };
};

/**
 * Used for filtering out symbols that don't need cache update.
 * Returns `isPending`, `hasExpired` and `hasTimedOut` for all
 * `symbolOrSymbols` with given `bucket`. Sets isPendingValue if given.
 */
export function processChartingCache(
  symbolOrSymbols: string,
  bucket: GWMarketDataSummaryBucket,
  newPendingValue?: boolean,
  caller?: string,
) {
  const keys = genCacheParams(symbolOrSymbols, bucket) as string[];
  let toBeUpdated = false;
  keys.forEach(key => {
    if (newPendingValue != null) {
      const processCallFunction = newPendingValue ? CallsCache.processRequest : CallsCache.processResponse;
      const { toBeUpdated: isDirty } = processCallFunction('getChartingData', key);
      if (isDirty) toBeUpdated = true;
      return;
    }

    if (CallsCache.checkIfDirty('getChartingData', key)) toBeUpdated = true;
  });

  return toBeUpdated;
}

export function genCacheParams(symbolOrSymbols: string, bucket: GWMarketDataSummaryBucket) {
  const symbols = symbolOrSymbols?.split(',') ?? [];
  if (symbols.length === 1) {
    return [ `${symbols[0]}_${bucket}` ];
  }

  return symbols.map(symbol => `${symbol}_${bucket}`);
}

export function checkPricesValid(...values: NullableNumber[]) {
  return !values.some(item => !isValidCurrencyValue(item));
}

const checkPrice = (price: number, side: string | undefined): number => (side === 'SELL' ? -Math.abs(price) : price);

export const scatterLabel = (data: ChartCombinedPointData, range?: ChartDataRange) => {
  if (!range) return [];

  const {
    orders, dateAndTime, close, volume,
  } = data;
  const { x, y } = data as ChartPoint;

  // quick fix for one point tooltip issue
  const isOnePointOnly = x === 0 && isNaN(y);

  const buyOrSell = (order: Order): string => (order.side === OrderSideEnum.Buy ? 'Bought' : 'Sold');
  const dateToStr = formatChartingDateTime(dateAndTime!, range, CHARTING_TIME_TZ);

  if (orders && !isOnePointOnly) {
    if (orders.length === 1) {
      const price = orders[0].stopPrice ?? orders[0].price ?? 0;
      const totalPrice = ((price) * (orders[0].orderQty ?? 0)).toFixed(2);
      return [
        `${buyOrSell(orders[0])} ${orders[0].orderQty} x  $${(price)?.toFixed(2)}`,
        `Cash: $${totalPrice}`,
        dateToStr,
      ];
    }
    const info: any[] = [];
    let cash: number = 0;
    let shares: number = 0;
    orders.forEach((element: Order, index) => {
      let price = element.stopPrice ?? element.price ?? 0;
      info.push(`${buyOrSell(element)} ${element.orderQty} x $${(price)?.toFixed(2)} `);
      price = checkPrice(price, element.side);
      cash += (element.orderQty ?? 1) * (price);
      shares += element.orderQty ?? 0;

      if (index === orders.length - 1) {
        info.push(`Shares: ${shares}`);
        info.push(`Cash:  $ ${cash < 0 ? `(${cash.toFixed(2)})` : cash.toFixed(2)}`);
        info.push(`Orders: ${index + 1}`);
        info.push(dateToStr);
      }
    });

    return info;
  }

  const result = [
    `$${close.toFixed(2)}`,
    `Vol. ${formatNumber(volume, false, true, false).toUpperCase()}`,
    range === '1D' ? dateToStr.replace(/.* /, '') : dateToStr,
  ];

  return result;
};

// we give [0, 20, 40, 60, 80, 100] for tickFormat values and return price
// eslint-disable-next-line max-len
export function tickFormatCalculation(tickValue: number, lineDiff: number, minLineValue: number, lineDataRatio: number) {
  const v1 = ((tickValue - (lineDataRatio * MAX_VALUE)) / MAX_VALUE) * lineDiff;
  const v2 = (1 - lineDataRatio);

  return (v1 / v2 + minLineValue);
}

/**
 * Converts price data to Y value in range 0 ... 100
 */
export function calculateYLabelValue(value: number, lineDiff: number, minLineValue: number, lineDataRatio: number) {
  if (lineDiff === 0) {
    return lineDataRatio * MAX_VALUE + MAX_VALUE / 2;
  }
  const v2 = (1 - lineDataRatio);

  return ((((value - minLineValue) * v2) / lineDiff) * MAX_VALUE) + (lineDataRatio * MAX_VALUE);
}

const calculateLineDataRatio = (normMin: number, lineDiff: number, minLineValue: number): number => {
  let lineDataRatio = LINE_DATA_RATIO * 100;
  const minLinePoint = calculateYLabelValue(normMin, lineDiff, minLineValue, lineDataRatio * ONE_HUNDREDTH);

  if (minLinePoint < lineDataRatio) {
    lineDataRatio = (lineDataRatio - minLinePoint) + lineDataRatio;
  }

  return lineDataRatio * ONE_HUNDREDTH;
};

// eslint-disable-next-line max-len
export const oneValueRounding = (tick: number, lineDataRatio: number, minValue: number, value: number, maxValue: number, step: number) => {
  switch (tick) {
    case lineDataRatio * MAX_VALUE:
      return roundValue(minValue, step);
    case (lineDataRatio * MAX_VALUE + MAX_VALUE) / 2:
      return roundValue(value, step);
    case MAX_VALUE:
      return roundValue(maxValue, step);
    default:
      return 0;
  }
};
