import { ApiOkResponse, ApiResponse } from 'apisauce';

import { MOCK } from '../../../configDebug';
import { ams, fundamental } from '../../__offline-data__';
import pdfJson from '../../__offline-data__/file/pdf.json';
import { OrderSideEnum, OrderStatusEnum } from '../../enums';
import {
  InboxMessage,
  InboxPageResponse,
  SummaryResponse,
  UnreadCountResponse,
} from '../../models/ams/types';
import { SignupTokenData } from '../../models/auth/types';
import { PagedResponse } from '../../models/common-response-types';
import {
  FundingWireTransferPostRequest,
  GetWireTransferBankAccountPostRequest,
  Payment,
  PaymentsFilter,
  RegisterBinaryDocumentPayload,
} from '../../models/crm/types';
import {
  DividendPayerData,
  Earning,
  ETF,
  ETFDetailParams,
  ETFDetailResponse,
  ETFParams,
  HighGrowthCompanyData,
  IPO,
  NewStockData,
  PageableFilterRequest,
  PopularStock,
  Sector,
  SectorDetail,
  SectorDetailParams,
  Split,
  TopGainerAndLoserData,
} from '../../models/fundamental/types';
import { GWMarketDataSummaryBucket, GWQueryCase, GWRequestType } from '../../models/gateway/types';
import { LinkedAccountRequestData } from '../../models/linked-account/types';
import { SubscriptionTopic } from '../../models/market-data/types';
import { News, NewsData } from '../../models/news';
import { PeopleAlsoTradeItem, PeopleAlsoTradeParams } from '../../models/reporting/types';
import { StockDescriptor } from '../../models/stock-descriptor';
import { GetDetailsFunctionalityType, Order, QueriesCacheType } from '../../models/trading/types';
import { gatewayConnected, sorOrderEvent } from '../../store/common-actions';
import { SearchEarningsPayload } from '../../store/fundamental/types';
import { dataQuery } from '../../store/gateway';
import { createSuccessResponse } from '../../store/helpers';
import {
  connected as marketDataConnected,
  stockHistoryLoaded,
  stockRecentLoaded,
} from '../../store/market-data';
import { EMPTY_CALCULATED_PRICE_DATA, EMPTY_PRICE_DATA } from '../../store/market-data/helpers';
import { InstrumentSnapshotMap } from '../../store/market-data/types';
import { latestNewsLoadCompleted, newsForSymbolLoadCompleted } from '../../store/news';
import { createBankTransfer } from '../../store/payment';
import {
  AccountSummaryPayloadData,
  AccountTradeHistoryPayloadData,
  GetAccountsAvailableCashRequest,
  GetMyAccountChartDataPayload,
  OpenPositionsPayloadData,
  OrderDetailPayloadData,
} from '../../store/reporting/types';
import { ReportingFilePayloadData } from '../../store/reportingFile/types';
import { connected as tradingConnected } from '../../store/trading';
import { updateBigChartCacheOnLoadChartDataCompleted } from '../../store-util/BigChartCache';
import { updateInstrumentSnapshotCache } from '../../store-util/InstrumentSnapshotCache';
import { DEBUG_MARKET_STATE } from '../../store-util/MarketStateCache';
import { _setNBBOPricesCache_InUnitTestsAndMockedMode } from '../../store-util/NBBOPriceCache';
import { updateSmallChartDataCache } from '../../store-util/SmallChartDataCache';
import { updateStatsCache } from '../../store-util/StatsCache';
import { _setTradePricesCache_InUnitTestsAndMockedMode, updateTradePriceCacheFromInstrumentSnapshot } from '../../store-util/TradePriceCache';
import { tradingConfig } from '../../trading-settings';
import { newPromiseWithReduxCacheData } from '../../util/DataHelpers';
import { getDateTime } from '../../util/DateTimeHelpers';
import { parseGatewayData, parseRows } from '../../util/GatewayHelpers';
import { dataArrayToMap, processMDQueryData, processOHLCVResponse } from '../../util/MarketDataHelpers';
import { uuidv4 } from '../../util/tools';
import { NullableNumber, NullableString } from '../../util/types';
import { getTopGainersJson, getTopLosersJson } from '../fundamental/helpers';
import { MarketDataClientStatus } from '../MarketDataClient.service';
import { TradeClientStatus } from '../TradeClient.service';
import {
  IAMSService,
  IAuthService,
  ICommonAuthService,
  ICRMService,
  IFileService,
  IFundamentalService,
  IMarketDataService,
  INewsService,
  IPaymentService,
  IReportingFileService,
  IReportingService,
  ITradingService,
  RxJsData,
} from '../types';

import MockedOMSAndMDS from './MockedOMSAndMDS';
import {
  calculateDelay,
  generateOfflineResponse,
  generateOHLCVSequence,
  getLegalDeclarationsCommon,
  getOkResponse,
  newPromise,
  newPromiseForData,
} from './offline-helpers';
import { MOCKED_CHART_POINTS } from './offlineSettings';

export class OfflineAuthService implements IAuthService, ICommonAuthService {
  getSignupToken(token: string): Promise<ApiResponse<SignupTokenData>> {
    return new Promise<ApiOkResponse<SignupTokenData>>((res, rej) => {
      newPromise('authApi/getSignupToken');
    });
  }
  getAvailableClients() { return newPromise('empty'); }
  authorize(config: any) {
    if (MOCK.TRADING_MODE === 'static') {
      _setTradePricesCache_InUnitTestsAndMockedMode({
        AAPL: {
          currentPrice: 123.09,
          prevPrice: 114.93,
          symbol: 'AAPL',
          currentPriceDate: '2022-04-02',
          change: '0.02',
          changePercent: '0.39',
          direction: '',
          priceData: EMPTY_PRICE_DATA,
          calculatedSymbolPrices: EMPTY_CALCULATED_PRICE_DATA,
          changePercentWithSign: '',
        },
        TSLA: {
          currentPrice: 1101.09,
          prevPrice: 1099.93,
          symbol: 'TSLA',
          currentPriceDate: '2022-04-01',
          change: '0.12',
          changePercent: '0.02',
          direction: '',
          priceData: EMPTY_PRICE_DATA,
          calculatedSymbolPrices: EMPTY_CALCULATED_PRICE_DATA,
          changePercentWithSign: '',
        },
      });
      _setNBBOPricesCache_InUnitTestsAndMockedMode({
        AAPL: { askprice: 123.09, bidprice: 114.93, symbol: 'AAPL' },
        TSLA: { askprice: 1101.09, bidprice: 1099.93, symbol: 'TSLA' },
      });
    }
    DEBUG_MARKET_STATE.enable();
    return newPromise('auth/authorize');
  }
  refresh(config: any, data: any) { return newPromise('empty'); }
  // common service
  setToken() { }
  registerUser() { return newPromise('auth/registerUser'); }
  confirmEmail(email: string, token: string) { return newPromise('auth/confirmEmail'); }
  resendEmail(email: string, first_name: NullableString, last_name: NullableString, country_of_residence: NullableString) { return newPromise('auth/resendEmail'); }
  sendPhoneCode(userId: number, phone: string) { return newPromise('auth/sendPhoneCode'); }
  verifyPhoneNumber(userId: number, code: string) { return newPromise('auth/verifyPhoneNumber'); }
  getUserPermissions() { return newPromise('auth/userPermissions'); }
  getTokenManually(email: string, token: string, clientId: '10nMobile' | '10nWeb', grant_type?: string) {
    return newPromise('empty');
  }
  sendResetPassword(email: string, client: string, return_url: string) { return newPromise('empty'); }
  sendNonAppropriatenessEmail(email: string, token: string) { return newPromise('crm/sendNonAppropriatenessEmail'); }
}

export class OfflinePaymentService implements IPaymentService {
  api = {} as any;
  setToken() { }
  getCardTransferStatus() { return newPromise('empty'); }
}

export class OfflineCRMService implements ICRMService {
  api = {} as any;
  setToken() { }
  getAccounts() { return newPromise('crm/accounts'); }
  getAccountInfo(accountReferenceId: string) { return newPromise('crm/accountInfo'); }
  getAccountDetails(accountId: NullableNumber, accountReferenceId?: string) {
    return newPromise('crm/accountDetails');
  }
  getTradingPlatforms(): Promise<any> { return newPromise('empty'); }
  getCountryList() { return newPromise('crm/country'); }
  enrollGet(methodName: string, params: any[]) {
    if (methodName) {
      let callName: string = `${methodName[3].toLowerCase()}${methodName.substring(4)}`;
      return newPromise(`crm/${callName}`);
    }
    console.debug(`[OfflineCRMService::GET] Method name should be defined - methodName='${methodName}', params='${params}'`);
    throw new Error(`[OfflineCRMService::GET] Method name should be defined - methodName='${methodName}', params='${params}'`);
  }
  enrollPost(methodName: string, requestBody: any): Promise<any> {
    if (methodName) {
      let callName: string = `${methodName[4].toLowerCase()}${methodName.substring(5)}`;
      return newPromise(`crm/${callName}`);
    }
    console.debug(`[OfflineCRMService::POST] Method name should be defined - methodName='${methodName}'`, requestBody);
    throw new Error(`[OfflineCRMService::POST] Method name should be defined - methodName='${methodName}'`);
  }
  public async enrollDelete(methodName: string, taxDetailId: number | null | undefined): Promise<any> {
    let callName: string = `${methodName[5].toLowerCase()}${methodName.substring(7)}`;
    return newPromise(`crm/${callName}`);
  }
  enrollPut(methodName: string, requestBody: any): Promise<any> {
    if (methodName) {
      let callName: string = `${methodName[3].toLowerCase()}${methodName.substring(4)}`;
      return newPromise(`crm/${callName}`);
    }
    console.debug(`[OfflineCRMService::PUT] Method name should be defined - methodName='${methodName}'`, requestBody);
    throw new Error(`[OfflineCRMService::PUT] Method name should be defined - methodName='${methodName}'`);
  }
  registerBinaryDocument(payload: RegisterBinaryDocumentPayload, token: string, fileNames?: string[]) {
    return newPromise('crm/individual-document/own');
  }
  getPayments(filter: PaymentsFilter): Promise<ApiResponse<Payment[]>> {
    return newPromise('crm/getPayments') as Promise<ApiResponse<Payment[]>>;
  }
  getLinkedAccountsByIndividualId(accountId: number) {
    return newPromise('crm/linkedAccounts');
  }
  registerLinkedAccount(linkedAccountRequestData: LinkedAccountRequestData) {
    return newPromise('crm/registerLinkedAccount');
  }
  getWireTransferBankAccount(filter: GetWireTransferBankAccountPostRequest) {
    return newPromise('crm/getWireTransferBankAccount');
  }
  createBankTransfer(fundingRequestData: FundingWireTransferPostRequest) {
    return newPromise(createBankTransfer.type);
  }
  getFinancialQuestionnaireTypes(): Promise<any> {
    return newPromise('crm/financialQuestionnaireTypes');
  }
  getFinancialQuestionnaireList(): Promise<any> {
    return newPromise('crm/financialQuestionnaireOwnList');
  }
  getPurposeOfTrading(): Promise<any> {
    return newPromise('crm/purposeOfTradingActive');
  }
  getEmploymentStatusList(): Promise<any> {
    return newPromise('crm/employmentStatusOwn');
  }
  getSourceOfFundList(isActive: boolean): Promise<any> {
    return newPromise('crm/sourceOfFundList');
  }
  getSourceOfFundOwn(isActive: boolean): Promise<any> {
    return newPromise('crm/sourceOfFundOwn');
  }
  getOwnerTradingAccounts(individualId: number): Promise<any> {
    return newPromise('crm/ownerTradingAccounts');
  }
  getLegalDeclarationsList(active: boolean | undefined) {
    return getLegalDeclarationsCommon(active);
  }
  registerWithdrawalRequest(): Promise<any> {
    return newPromise('crm/registerWithdrawalRequest');
  }
  postCreateCardTransfer(): Promise<any> {
    return newPromise('crm/cardTransferResponse');
  }
  getLegalDeclarationsDynamicFile(templateName: string): Promise<ApiResponse<string>> {
    return newPromise('crm/getLegalDeclarationsDynamicFile') as Promise<ApiResponse<string>>;
  }
}

let mockedOMSAndMDS: MockedOMSAndMDS;

export class OfflineTradingService implements ITradingService {
  dispatch: any;
  status: any;

  constructor(dispatch: any) {
    this.dispatch = dispatch;
    this.status = TradeClientStatus.INITIAL;
    if (MOCK.TRADING_MODE !== 'static') mockedOMSAndMDS = new MockedOMSAndMDS(dispatch);
  }

  isConnected() { return true; }
  setToken(token: string): void {
  }

  connect(token: string) {
    this.status = TradeClientStatus.SOCKET_CONNECTED;
    this.dispatch(tradingConnected());
  }
  reconnect(): void { this.connect('reconnect'); }
  disconnect(): void {}
  newOrder(order: Order): Promise<any> {
    let timeNow = getDateTime();
    let newOrderRequest = {
      ...tradingConfig,
      timeInForce: 'Day',
      messageType: 'neworder',
      side: OrderSideEnum.Buy,
      reqId: 'n/a in mock',
      ...order,
      status: OrderStatusEnum.InProgress,
      createdAt: timeNow,
      updatedAt: timeNow,
    };

    console.info('[MOCK] [T] newOrder request', newOrderRequest);

    if (MOCK.TRADING_MODE === 'static') {
      return newPromise('trading/newOrder', null, false, newOrderRequest);
    }

    mockedOMSAndMDS.neworder(newOrderRequest as any);
    return newPromise('trading/newOrder', false, false, newOrderRequest);
  }
  getDetails(account: string, functionality: GetDetailsFunctionalityType): Promise<any> {
    if (MOCK.TRADING_MODE === 'static') {
      return newPromise(`trading/${functionality}`);
    }

    switch (functionality) {
      case 'getBalances':
        return newPromise('trading/getBalances', false, false, mockedOMSAndMDS.getBalances());
      case 'getOrders':
        return newPromise('trading/getOrders', false, false, mockedOMSAndMDS.getOrders());
      case 'getOrderHistory':
        return newPromise('trading/getOrderHistory', false, false, mockedOMSAndMDS.getOrderHistory());
      case 'getPositions':
        return newPromise('trading/getPositions', false, false, mockedOMSAndMDS.getPositions());
      default:
        console.warn(`[OfflineGateway] Not known getDetails functionality - ${functionality}`);
    }

    return newPromise('empty');
  }
  getOrders(account: string): Promise<any> {
    return newPromise('trading/getOrders');
  }
  getPositions(account: string): Promise<any> {
    return newPromise('trading/getPositions');
  }
  cancelOrder(order: Order, account: string): Promise<any> {
    return newPromise('trading/cancelOrder');
  }
  modifyOrder(order: Order, account: string): Promise<any> {
    return newPromise('trading/modifyOrder');
  }
}

export class OfflineMarketDataService implements IMarketDataService {
  dispatch: any;
  status: any;
  ohlcv: any = {
    default: { queryCase: null, symbol: 'AAPL' },
    id: 0,
  };
  ohlcvCache: QueriesCacheType = {};

  constructor(dispatch: any) {
    this.dispatch = dispatch;
    this.status = MarketDataClientStatus.INITIAL;
  }
  setToken(token: string): void {
  }
  connect(token: string) {
    this.status = MarketDataClientStatus.SUCCESSFUL;
    setTimeout(() => this.dispatch(marketDataConnected()), calculateDelay());
  }
  reconnect(): void {}
  disconnect(): void {}
  subscribe(topic: SubscriptionTopic, symbols: string[]): Promise<any> {
    mockedOMSAndMDS?.subscribe(symbols);
    return newPromise('empty');
  }
  unsubscribe(topic: SubscriptionTopic, symbols: string[]): Promise<any> {
    mockedOMSAndMDS?.unsubscribe(symbols);
    return newPromise('empty');
  }
  query(
    topic: string,
    symbols: string[],
    startTime?: string | Date,
    endTime?: string | Date,
    bucket?: any,
    queryCase?: any,
  ) {
    const { ohlcv, ohlcvCache } = this;
    let data;

    switch (topic) {
      case 'ohlcv':
        data = generateOfflineResponse('market/ohlcv');
        ohlcvCache[ohlcv.id] = ohlcv.default;
        ohlcvCache[ohlcv.id].queryCase = queryCase;
        data.ref = ohlcv.id;
        ohlcv.id++;
        break;
      case 'nbbo':
        data = generateOfflineResponse('market/nbbo');
        break;
      default:
        throw new Error(`[MOCK] [MD] Query '${topic}' not implemented`);
    }
    processOHLCVResponse(this.dispatch, data, this.ohlcvCache);
  }
  isSubscribed(topic: SubscriptionTopic, symbol: string): boolean {
    return true;
  }
  async loadTopic(topic: string) {
    switch (topic) {
      case 'ohlcv': {
        const ohlcv = await newPromise('market/ohlcv');
        this.dispatch(stockHistoryLoaded(ohlcv));
        this.dispatch(stockRecentLoaded(ohlcv));
        // eslint-disable-next-line no-promise-executor-return
        return new Promise(success => true);
      }

      case 'nbbo': {
        const nbboData = await newPromise('market/nbbo');
        // eslint-disable-next-line no-promise-executor-return
        return new Promise(success => true);
      }

      default:
        throw new Error(`[MOCK] Unknown topic '${topic}' for MD::subscribe`);
    }
  }
}

export class OfflineFundamentalService implements IFundamentalService {
  setToken(token: string): void {
  }
  async searchCompanies(query: string): Promise<any> {
    const path = 'fundamental/search';
    let result = generateOfflineResponse(path);
    result.data = result?.data.filter((item: StockDescriptor) => (
      item?.symbol.toLowerCase().includes(query.toLowerCase())
      || item?.companyName.toLowerCase().includes(query.toLowerCase())
    ));

    return newPromise(path, false, false, result);
  }
  getCompany(symbol: string) {
    return newPromise('fundamental/company');
  }
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  getCompanyLogo(symbols: string): Promise<any> {
    let data = fundamental.companyLogo;

    // add any absent logos (using data[0] as data)
    const symbolsArray = symbols?.split(',') || [];
    symbolsArray.forEach(symbol => {
      if (data.filter((item: any) => item.symbol === symbol).length === 0) {
        let newItem = { ...data[0] };
        newItem.symbol = symbol;
        data.push(newItem);
      }
    });

    return newPromise('fundamental/companyLogo', false, false);
  }
  getCompanyLogoAndName(symbols: string): Promise<any> {
    const data = fundamental.companyLogoAndName;

    const symbolsArray = symbols?.split(',') || [];
    symbolsArray.forEach(symbol => {
      if (data.filter((item: any) => item.symbol === symbol).length === 0) {
        const newItem = { ...data[0] };
        newItem.symbol = symbol;
        data.push(newItem);
      }
    });

    return newPromise('fundamental/companyLogoAndName', false, false);
  }
  getCompanyLogoMD5(symbols: string): Promise<any> {
    return newPromise('fundamental/companyLogoMD5');
  }
  getEarnings(symbol: string[], startDate?: string, stopDate?: string) {
    return newPromise('fundamental/earnings') as Promise<ApiResponse<Earning[]>>;
  }
  searchEarnings(params?: SearchEarningsPayload) {
    const data: PagedResponse<Earning> = require('../../__offline-data__/fundamental/earningsSearch.json');
    return newPromiseForData(data);
  }
  getKeyStats(symbol: string) {
    return newPromise('fundamental/stats');
  }
  getPopularStocks(constrained: boolean, count: number): Promise<ApiResponse<PopularStock[]>> {
    const data: PopularStock[] = require('../../__offline-data__/fundamental/popularStocks.json');
    return newPromiseForData(data);
  }

  getNewStocks() {
    const data: NewStockData[] = require('../../__offline-data__/fundamental/newStocks.json');
    return newPromiseForData(data);
  }

  getDividendPayers(request?: PageableFilterRequest) {
    const data: DividendPayerData[] = require('../../__offline-data__/fundamental/dividendPayers.json');
    return newPromiseForData(data);
  }
  getHighGrowthCompanies(request?: PageableFilterRequest) {
    const data: HighGrowthCompanyData[] = require('../../__offline-data__/fundamental/highGrowtCompanies.json');
    return newPromiseForData(data);
  }
  getMarketIndexes(symbols?: string[]) {
    const data: [] = require('../../__offline-data__/fundamental/marketIndexes.json');
    return newPromiseForData(data);
  }
  getTopGainers(
    minValue: number,
    maxValue: number,
    isSymbolUniverseSymbols?: boolean,
  ): Promise<ApiResponse<TopGainerAndLoserData[]>> {
    const data: TopGainerAndLoserData[] = getTopGainersJson(minValue, maxValue);
    return newPromiseForData(data);
  }

  getTopLosers(
    minValue: number,
    maxValue: number,
    isSymbolUniverseSymbols?: boolean,
  ): Promise<ApiResponse<TopGainerAndLoserData[]>> {
    const data: TopGainerAndLoserData[] = getTopLosersJson(minValue, maxValue);
    return newPromiseForData(data);
  }

  getSectors(): Promise<ApiResponse<Sector[]>> {
    const data: Sector[] = fundamental.sectors;
    return newPromiseForData(data);
  }

  getSectorDetail(params: SectorDetailParams): Promise<ApiResponse<SectorDetail>> {
    const data: SectorDetail = fundamental.sector;
    data.sectorId = params.sectorId;
    return newPromiseForData(data);
  }

  getETFs(params: ETFParams): Promise<ApiResponse<PagedResponse<ETF>>> {
    const data: PagedResponse<ETF> = fundamental.ETFData;
    return newPromiseForData(data);
  }

  getETFDetails(params: ETFDetailParams): Promise<ApiResponse<PagedResponse<ETFDetailResponse>>> {
    const data: PagedResponse<ETFDetailResponse> = fundamental.etfDetails;
    return newPromiseForData(data);
  }

  getIPOs(startDate?: string, endDate?: string) {
    const data: IPO[] = require('../../__offline-data__/fundamental/ipos.json');
    return newPromiseForData(data);
  }

  getSplits(symbols: string[], startDate?: string, endDate?: string) {
    const data: Split[] = require('../../__offline-data__/fundamental/splits.json');
    return newPromiseForData(data);
  }
}

export class OfflineNewsService implements INewsService {
  setToken(token: string): void {
  }
  // eslint-disable-next-line default-param-last
  public getNews(symbol: NullableString, pageSize: number = 5, page?: number, rxJsData?: RxJsData) {
    const { data } = generateOfflineResponse('news/news');
    const { action, observer } = rxJsData || {};
    const result = { ...data };

    if (symbol) {
      result.customData = symbol;
      observer?.next(newsForSymbolLoadCompleted(result));
    } else {
      observer?.next(latestNewsLoadCompleted(result));
    }
  }
}

export class OfflineReportingService implements IReportingService {
  setToken(token: string): void {
  }
  getPaymentsAccountsIntividual(
    config: {
      page_size: number,
      page_number: number,
      order_column: string,
      payment_method: number,
      transaction_overview_day: number
    },
  ) {
    return newPromise('reporting/paymentsAccountsIntividual');
  }
  executions() {
    return newPromise('reporting/executions');
  }
  getAccountsAvailableCash(requstBody: GetAccountsAvailableCashRequest) {
    return newPromise('crm/getAccountsAvailableCash');
  }
  getNetEquityComponents(requstBody: GetMyAccountChartDataPayload) {
    return newPromise('reporting/getNetEquityComponents');
  }
  getAccountPendingNewOrderHistory(requstBody: AccountTradeHistoryPayloadData) {
    return newPromise('reporting/getAccountPendingNewOrderHistory');
  }
  getAccountSummaryData(requstBody: AccountSummaryPayloadData) {
    return newPromise('reporting/getAccountSummaryData');
  }
  getOrderDetailData(requstBody: OrderDetailPayloadData) {
    return newPromise('reporting/getOrderDetailData');
  }
  getAccountTradeHistory(requstBody: AccountTradeHistoryPayloadData) {
    return newPromise('reporting/getAccountTradeHistory');
  }
  getOpenPositionsData(requstBody: OpenPositionsPayloadData) {
    return newPromise('reporting/getOpenPositionsData');
  }
  getPeopleAlsoTradeData(params: PeopleAlsoTradeParams) {
    const data: PeopleAlsoTradeItem[] = require('../../__offline-data__/reporting/peopleAlsoTrade.json');
    return newPromiseForData(data);
  }
  getBPInClosedMarket(requstBody: { accountReferenceId: string }) {
    return newPromiseForData({ accountReferenceId: 'test', buyingPowerId: 9 });
  }
}

export class OfflineReportingFileService implements IReportingFileService {
  setToken(token: string): void {
  }
  get(requstBody: ReportingFilePayloadData) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<InboxPageResponse>>((res, rej) => newPromise('download-order-confirmation-report'));
  }
}

export class OfflineFileService implements IFileService {
  setToken(token: string): void {
  }
  get(fileName: string) {
    const parts = fileName.split('.');
    if (parts.length === 2 && parts[1].toLowerCase() === 'pdf') {
      return new Promise<ApiResponse<string>>((success, reject) => {
        setTimeout(() => success(getOkResponse(pdfJson.pdf)), 1000);
      });
    }
    const response = createSuccessResponse('');

    return newPromise('file/get', null, false, response) as Promise<ApiResponse<string>>;
  }

  upload(data: any, fileName: string) { return newPromise('file/upload'); }

  deleteByName(fileName: string) {
    const response = createSuccessResponse('');

    return newPromise('file/deleteByName', false, false, response) as Promise<ApiResponse<null>>;
  }
}

export class OfflineAmsService implements IAMSService {
  setToken(token: string): void {
  }
  getAllMessages() {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<InboxPageResponse>>((res, rej) => newPromise('ams/getAllMessages'));
  }
  deleteMessages(ids: number | number[]) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<void>>((res, rej) => newPromise('ams/voidResponse'));
  }
  getMessage(id: number) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<InboxMessage>>((res, rej) => newPromise('ams/getMessage'));
  }
  markAllMessagesAsRead() {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<void>>((res, rej) => newPromise('ams/voidResponse'));
  }
  markAsRead(ids: number | number[]) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<void>>((res, rej) => newPromise('ams/voidResponse'));
  }
  notifyUnreadCount() {
    const data: UnreadCountResponse = ams.notifyUnreadCountResponse;
    return newPromiseForData(data);
  }
  markAsReadByMetadata() {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise<ApiOkResponse<void>>((res, rej) => newPromise('ams/voidResponse'));
  }
  // eslint-disable-next-line no-promise-executor-return
  getSummary = () => new Promise<ApiOkResponse<SummaryResponse>>((res, rej) => newPromise('ams/getSummary.json'));
}

class GatewayMock {
  dispatch: any;
  name: string = 'GW-WS-MOCK';
  public static connected = false;
  constructor(dispatch: any) {
    this.dispatch = (action: any, delay: 0) => setTimeout(() => dispatch(action), delay * 1000);
    if (dispatch == null) this.name = 'GW-Rest-MOCK';
  }
}
const gatewayProxy = {
  get(target: any, prop: string, receiver: any) {
    const func = (function offlineGatewayFunc(...args: any[]) {
      console.debug(`[${target.name}] Calling ${prop} - '${GWRequestType[args[0]]}'`, { callName: prop, args });
      let data;
      switch (prop) {
        case 'disconnect':
        case 'reconnect':
          // these do not need implementation so far
          // added here to not produce error in default case
          break;

        case 'connect': {
          const isReconnect = !!args[1];
          const isConnectDisabled = isReconnect ? !!MOCK.GATEWAY?.disableReconnect : !!MOCK.GATEWAY?.disableConnect;
          if (isConnectDisabled) {
            console.warn(`[${target.name}] Skipping ${isReconnect ? 're' : ''}connect ... (see configDebug => MOCK.GATEWAY)`, args);
          } else {
            GatewayMock.connected = true; // eslint-disable-line no-param-reassign
            target.dispatch(gatewayConnected());
          }
          break;
        }

        case 'sendRequest': {
          const requestType: GWRequestType = args[0];
          const requestName: string = GWRequestType[requestType];
          const requestData: any = args[1];
          const queryCase: GWQueryCase = args[2];
          const queryName: NullableString = queryCase != null ? ` / ${GWQueryCase[queryCase] ?? queryCase}` : '';
          const symbol = ((requestData?.symbols || [])[0] || requestData.symbol);
          const symbols: string[] = requestData?.symbols ?? (requestData.symbol ?? []);

          console.log(`[${target.name}] Request ${requestName} (${requestType})${queryName}`);
          console.debug(`[${target.name}] Request ${requestName} full body (${requestType})${queryName}`, args);

          if (!GatewayMock.connected && requestType !== GWRequestType.Login) {
            console.warn(`[${target.name}] Skipping request ${requestName} (${requestType})  -- not connected --   (see configDebug => MOCK.GATEWAY)`);
            return;
          }

          let result;

          switch (requestType) {
            case GWRequestType.Login:
              target.dispatch(gatewayConnected());
              break;

            case GWRequestType.GetNewsArticles:
              data = generateOfflineResponse('gateway/news');
              target.dispatch(dataQuery({
                accessId: {
                  data,
                  cacheData: {
                    queryCase: GWQueryCase.News,
                    symbol,
                    requestData,
                  },
                },
              } as any), 1);
              break;

            case GWRequestType.MarketDataQuery:
              if (MOCK.TRADING_MODE === 'static') {
                data = require('../../__offline-data__/market-data/CRSR_days.json');
              } else {
                data = generateOHLCVSequence(
                  'AAPL',
                  [ 100.01, 200.01 ],
                  [ 100.01, 200.01 ],
                  [ 100.01, 200.01 ],
                  [ 100.01, 200.01 ],
                  [ 100, 1000000 ],
                  1000,
                );
              }
              processMDQueryData((action: any) => target.dispatch(action, 1), { symbol, queryCase }, data);
              break;

            case GWRequestType.MarketDataSubscribe:
            case GWRequestType.MarketDataSubscribeTrade:
            case GWRequestType.MarketDataSubscribeNbbo:
              mockedOMSAndMDS?.subscribe(symbols);
              break;

            case GWRequestType.MarketDataUnsubscribe:
            case GWRequestType.MarketDataUnsubscribeTrade:
            case GWRequestType.MarketDataUnsubscribeNbbo:
              mockedOMSAndMDS?.unsubscribe(symbols);
              break;

            case GWRequestType.GetLatestNewsArticles:
              data = generateOfflineResponse('gateway/news');
              // Generate random ids for having unique keys for mock mode when pressed load more button
              data.newsArticles.data = data.newsArticles.data.map((news: News) => ({ ...news, id: uuidv4() }));
              target.dispatch(latestNewsLoadCompleted(parseGatewayData(data) as NewsData));
              break;

            case GWRequestType.GetLatestNewsArticlesBySymbols:
              data = generateOfflineResponse('gateway/newsForSymbol');
              result = {
                ...(parseGatewayData(data) as NewsData),
                customData: args[1]?.symbols,
              };
              target.dispatch(newsForSymbolLoadCompleted(result));
              break;

            case GWRequestType.MarketDataSummary: {
              let staticData = require('../../__offline-data__/gateway/md/chart-1D5m.json');
              if (requestData?.bucket === GWMarketDataSummaryBucket.FiveYears) {
                staticData = require('../../__offline-data__/market-data/AAPL_5Y.json');
              }

              const mockData = (
                MOCK.TRADING_MODE === 'static'
                  ? staticData
                  : generateOHLCVSequence(
                    'AAPL',
                    [ 100.01, 200.01 ],
                    [ 100.01, 200.01 ],
                    [ 100.01, 200.01 ],
                    [ 100.01, 200.01 ],
                    [ 100, 1000000 ],
                    MOCKED_CHART_POINTS,
                  )
              );
              const smallChartData: any = {};
              symbols?.forEach(item => { smallChartData[item] = mockData.rows; });

              updateSmallChartDataCache(smallChartData, '');
              updateBigChartCacheOnLoadChartDataCompleted({ symbol, bucket: requestData?.bucket, data: mockData.rows }, '');
              break;
            }

            case GWRequestType.NewOrderRequest: {
              if (MOCK.TRADING_NEW_ORDER === 'accepted') {
                target.dispatch(sorOrderEvent(generateOfflineResponse('gateway/newOrderAccepted')));
              } else {
                target.dispatch(sorOrderEvent(generateOfflineResponse('gateway/newOrderRejected')));
              }
              break;
            }

            case GWRequestType.InstrumentSnapshot: {
              data = generateOfflineResponse('gateway/instrumentSnapshot');
              const parsedData = parseRows(data.rows, data);
              const parsedSymbols = parsedData.map(({ sym }: { sym: string }) => sym);
              requestData.symbols.forEach((currentSymbol: string) => {
                if (!parsedSymbols.includes(currentSymbol)) {
                  const count = parsedData.length;
                  const randomIndex = Math.round(Math.random() * count);
                  // duplicate some other symbol data to fill in missing ones
                  parsedData.push({
                    ...parsedData[randomIndex < count ? randomIndex : count - 1],
                    sym: currentSymbol,
                  });
                }
              });
              data = dataArrayToMap(parsedData) as InstrumentSnapshotMap;
              updateInstrumentSnapshotCache(data, '');
              updateStatsCache(data);
              updateTradePriceCacheFromInstrumentSnapshot(data);
              break;
            }

            default:
              console.warn(`[${target.name}] ${requestName} (${requestType}) - unhandled request`);
              break;
          }
          break;
        }

        case 'get': {
          const { data: resultsData, cacheData } = args[0] as any;
          const { queryCase: currentCase } = cacheData;
          const asGWRawData = [ { rows: resultsData } ];

          console.debug(`[${target.name}] RESULTS REQUEST '${GWQueryCase[currentCase]}'`, args);
          return newPromiseWithReduxCacheData(asGWRawData); // eslint-disable-line consistent-return
        }

        case 'isConnected': {
          // eslint-disable-next-line consistent-return
          return true;
        }

        default:
          throw new Error(`[${target.name}] '${prop}' not implemented in gatewayProxy`);
      }
    });

    return (...args: any[]) => func.apply(target, args);
  },
};

export class OfflineGatewayProxy {
  constructor(dispatch: any) {
    // eslint-disable-next-line no-constructor-return
    return new Proxy(new GatewayMock(dispatch), gatewayProxy);
  }
}
