import React, { useEffect, useState } from 'react';
import { PaddingProps, VictoryArea, VictoryAxis, VictoryChart, VictoryGroup, VictoryLine } from 'victory';

import { DAILY_MAX_DOMAIN } from '../../../lib/store/market-data/charting/constants';
import SmallChartDataCache from '../../../lib/store-util/SmallChartDataCache';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { chartLineClose, getChartLineColorByLineClose } from '../../../lib/util/ChartingHelpers';
import { useLineClose } from '../../../lib/util/hooks/TradingDataHooks';

import { useIsMobile } from '../../../hooks';
import { LINE_CHART_HEIGHT, LINE_CHART_HEIGHT_CARD, LINE_CHART_HEIGHT_MOB, LINE_CHART_WIDTH } from '../constants';

import GradientFill from './ChartLineGradientFill';

type OwnProps = {
    symbol: string,
    isChartInCard?: boolean,
}

type Props = OwnProps;

const ChartLine = (props: Props) => {
  const { symbol, isChartInCard } = props;
  const isMobile = useIsMobile();

  const getMaxChartHeight = (): number => {
    if (isChartInCard) {
      return LINE_CHART_HEIGHT_CARD;
    }

    return isMobile ? LINE_CHART_HEIGHT_MOB : LINE_CHART_HEIGHT;
  };

  const maxChartHeight = getMaxChartHeight();
  const [maxChartDomainY, setMaxChartDomainY] = useState<number>(maxChartHeight);
  const [minChartDomainY, setMinChartDomainY] = useState<number>(0);

  const { closeValues } = SmallChartDataCache.use(symbol);

  const [lineClose, setLineClose] = useState(TradePriceCache.get(symbol)?.priceData.lineClose);
  useLineClose(symbol, lineClose, setLineClose, 'ChartLine');

  useEffect(() => {
    if (lineClose && closeValues) {
      const maxValueY = Math.max(...closeValues);
      const minValueY = Math.min(...closeValues);
      lineClose > maxValueY ? setMaxChartDomainY(lineClose) : setMaxChartDomainY(maxValueY);
      lineClose < minValueY ? setMinChartDomainY(lineClose) : setMinChartDomainY(minValueY);
    }
  }, [closeValues, lineClose]);

  const status = ''; // TODO: implement status text if needed
  const hasNoData = closeValues.length === 0 || !lineClose;
  const stroke = getChartLineColorByLineClose(lineClose, closeValues[closeValues.length - 1]);

  const getChartPadding = (): PaddingProps => {
    const style: PaddingProps = { left: 0, right: 40 };

    if (isChartInCard) {
      style.left = 10;
      style.right = 0;
      return style;
    }

    if (isMobile) {
      style.left = 0;
      style.right = 0;
      return style;
    }

    return style;
  };

  return (
    <>
      {hasNoData
        ? <span style={{ color: stroke }}>{status}</span>
        : (
          <VictoryChart
            width={LINE_CHART_WIDTH}
            height={maxChartHeight}
            padding={getChartPadding()}
            minDomain={{ y: minChartDomainY - 0.001 * minChartDomainY }}
            maxDomain={{ x: DAILY_MAX_DOMAIN, y: maxChartDomainY + 0.001 * maxChartDomainY }}
          >
            <VictoryGroup
              data={closeValues}
            >
              {!isChartInCard && <GradientFill id={`smallChart${symbol}`} color={stroke} />}
              <VictoryLine style={{ data: { stroke, strokeWidth: 1 } }} />
              {!isChartInCard && <VictoryArea style={{ data: { fill: `url(#smallChart${symbol})` } }} />}
            </VictoryGroup>
            {lineClose && (
            <VictoryAxis
              crossAxis={false}
              style={{
                axis: { stroke: '#c6c8da', strokeDasharray: 3 },
                tickLabels: { fontSize: 0 },
              }}
              offsetY={chartLineClose(
                lineClose, minChartDomainY, maxChartDomainY - minChartDomainY, maxChartHeight,
              )}
            />
            )}
          </VictoryChart>
        )}
    </>
  );
};

export default ChartLine;
