import moment from 'moment';

import { CURRENT_YEAR } from '../../../constants/date-time.constants';
import lang from '../../../language';
import {
  calculatePoints,
  fiveYearAxisValues,
  monthlyAxisValues,
  roundValue,
  threeMonthsAxisValues,
  yearlyAxisValues,
} from '../../../util/ChartingHelpers';
import { formatCurrency } from '../../../util/DataHelpers';
import {
  datesDifference,
  dateToMonth,
  dateToYear,
  getDateTime,
  leapYear,
  parseDateToUTC,
} from '../../../util/DateTimeHelpers';
import { EMPTY_CHART_DATA, TEN_THOUSAND, THOUSAND } from '../../market-data/charting/constants';
import { ChartDataRange } from '../../market-data/charting/types';
import { getRangeDiffrenceInDays, preparePnlResultByLengthAndRange } from '../../selectors';
import { NetIncomeComponentsChartData } from '../types';

import { CHART_AREA_Y1, ZERO_POINT_Y1 } from './constants';

const getFilteredInputDataValues = (
  inputData: NetIncomeComponentsChartData[],
  range: ChartDataRange,
): NetIncomeComponentsChartData[] => {
  const rangeDiffrence = getRangeDiffrenceInDays(range, inputData);
  const inputLength = inputData.length;
  return inputLength > 1 && inputLength > rangeDiffrence ? inputData.slice(1) : inputData;
};

export const prepareMyAccountChartData = (
  inputData: NetIncomeComponentsChartData[],
  range: ChartDataRange,
  netComponent: string,
) => {
  if (!inputData || !inputData.length) return EMPTY_CHART_DATA(false);

  let prices: number[];
  const filteredInputDataValues = getFilteredInputDataValues(inputData, range);

  if (netComponent === 'netIncome') {
    prices = filteredInputDataValues.map(el => el.netEquity);
  } else {
    const zeroElement = preparePnlResultByLengthAndRange(inputData, range);
    prices = filteredInputDataValues.map(({ pnlResult }) => pnlResult - zeroElement);
  }

  let aMonth = 0;
  const dates = filteredInputDataValues.map(el => el.date);
  const startDate = parseDateToUTC(filteredInputDataValues[0].date) as string;
  const monthAfterStartDate = getDateTime(true, [ 'plus' ], [ [ 1, 'month' ] ], startDate);
  aMonth = datesDifference(startDate, monthAfterStartDate);

  // eslint-disable-next-line max-len
  const { axisValue, isPeriodShorterThanRange } = defineAxisValue(filteredInputDataValues, range, aMonth, startDate, monthAfterStartDate, parseDateToUTC(inputData[0].date) as string);

  const minLineValue = Math.min(...prices);
  const maxLineValue = Math.max(...prices);
  const lineDiff = maxLineValue - minLineValue;

  const minChartPointDiffCoef = lineDiff ? 0.05 : 0.03;

  const { yLabelPoints, step } = calculatePoints(minLineValue, maxLineValue);

  const normDiff = Math.abs(yLabelPoints[yLabelPoints.length - 1] - yLabelPoints[0]);

  const minChartPointDiff = minChartPointDiffCoef * normDiff;

  let minChartPoint: number;
  const isOneNegativeValue = !lineDiff && yLabelPoints[yLabelPoints.length - 1] < 0;
  if (!lineDiff) {
    minChartPoint = yLabelPoints[yLabelPoints.length - 1] < 0 ? yLabelPoints[yLabelPoints.length - 1] : yLabelPoints[0];
  } else {
    minChartPoint = yLabelPoints[0] < 0
      ? yLabelPoints[0] - minChartPointDiff : yLabelPoints[0] - minChartPointDiff;
  }

  const lineData = parseEquityToLineData(prices, axisValue, minChartPoint, dates);

  const minChartPointCoef = Math.abs(minChartPoint)
  / (Math.abs(yLabelPoints[isOneNegativeValue ? 0 : yLabelPoints.length - 1]) + Math.abs(minChartPoint));
  const zeroLineHeight = minChartPointCoef * CHART_AREA_Y1 + ZERO_POINT_Y1;

  const fillColor = getMyAccountChartStyles(prices[0], prices[prices.length - 1]);

  return {
    isValid: true,
    lineDiff,
    minChartPoint,
    lineData,
    axisValue,
    yLabelPoints,
    step,
    fillColor,
    aMonth,
    zeroLineHeight,
    isPeriodShorterThanRange,
  };
};

// eslint-disable-next-line max-len
export const parseEquityToLineData = (data: number[], axisValues: string[], minChartPoint: number, dates: string[]) => data.map((el, index) => ({
  value: el,
  x: index,
  y: el - minChartPoint,
  date: dates[index],
  xAxis: axisValues[index] === '' ? `index${index}` : axisValues[index],
}));

export const getMyAccountChartStyles = (firstValue: number, lastValue: number) => (lastValue >= firstValue ? '#4AD295' : '#EF5B5B');


export const oneValueRounding = (tick: number, minValue: number, value: number, maxValue: number, step: number) => {
  switch (tick) {
    case minValue:
      return roundValue(minValue, step);
    case value:
      return roundValue(value, step);
    case maxValue:
      return roundValue(maxValue, step);
    default:
      return 0;
  }
};

const calculateDifference = (inputValues: NetIncomeComponentsChartData[]): number => (
  datesDifference(
    parseDateToUTC(inputValues[0].date) as string,
    parseDateToUTC(inputValues[inputValues.length - 1].date) as string,
  )
);

const allAxisValues = (inputValues: NetIncomeComponentsChartData[]) => {
  let axisValues;
  const yearDays = 366;
  const difference = calculateDifference(inputValues);
  if (difference > yearDays) {
    axisValues = fiveYearAxisValues(inputValues, 'date');
  } else {
    axisValues = calculateRelativeTimeAxisValues(inputValues);
  }

  return axisValues;
};

const calculateRelativeTimeAxisValues = (inputValues: NetIncomeComponentsChartData[]) => {
  let axisValues;
  const threeMonthsDays = 93;
  const difference = calculateDifference(inputValues);

  if (difference <= threeMonthsDays) {
    axisValues = threeMonthsAxisValues(inputValues, 'date');
  } else {
    axisValues = yearlyAxisValues(inputValues, 'date');
  }

  return axisValues;
};

const newAccountAxisValue = (fromDate: string, toDate: string) => {
  let monthlyValues: string[] = [];
  let dayOfTheWeek = moment(fromDate).weekday();
  const firstAxisValuePositionHelper = 9;
  let monday = '';

  if (dayOfTheWeek === 1) {
    monday = fromDate;
    monthlyValues.push(`${monday.substring(8, 10)}/${monday.substring(5, 7)}`);
  } else if (dayOfTheWeek === 0) {
    monday = getDateTime(true, [ 'plus' ], [ [ 1, 'days' ] ], fromDate);
    monthlyValues.push('');
    monthlyValues.push(`${monday.substring(8, 10)}/${monday.substring(5, 7)}`);
  } else {
    monday = getDateTime(true, [ 'plus' ], [ [ 8 - dayOfTheWeek, 'days' ] ], fromDate);

    // fill with empty strings till monday
    for (let i = 1; i < firstAxisValuePositionHelper - dayOfTheWeek; i++) {
      monthlyValues.push('');
    }

    monthlyValues.push(`${monday.substring(8, 10)}/${monday.substring(5, 7)}`);
  }

  // loop through the rest of the days in the month and fill the monthlyValues with the mondays and the rests days with an empty string
  for (let i = 1; i < datesDifference(monday, toDate); i++) {
    if (i % 7 === 0) {
      const nextMonday = getDateTime(true, [ 'plus' ], [ [ i, 'days' ] ], monday);
      monthlyValues.push(`${nextMonday.substring(8, 10)} / ${nextMonday.substring(5, 7)}`);
    } else {
      monthlyValues.push('');
    }
  }

  return monthlyValues;
};

export const formatOrdinateValues = (value: number) => (value >= TEN_THOUSAND || value <= (-TEN_THOUSAND)
  ? `${formatCurrency(Number(value) / THOUSAND, false, false, false)}K` : formatCurrency(value, false, false, false));

export const performanceRangeText = (range: ChartDataRange, firstDate: string): string => {
  let text: string = '';

  switch (range) {
    case '1M':
      text = lang.performanceRangeTextPastMonth();
      break;
    case '3M':
      text = lang.performanceRangeTextPastThreeMonths();
      break;
    case '1Y':
      text = lang.performanceRangeTextPastYear();
      break;
    case 'YTD':
      text = lang.performanceRangeTextPastYearToDate(CURRENT_YEAR);
      break;
    case 'ALL':
      text = lang.performanceRangeTextAll(firstDate);
      break;
    default:
      text = '';
      break;
  }

  return text;
};

const defineAxisValue = (
  data: NetIncomeComponentsChartData[],
  range: ChartDataRange,
  aMonthInDays: number,
  startDate: string,
  monthAfterStartDate: string,
  previousDay: string,
): { axisValue: string[], isPeriodShorterThanRange: boolean } => {
  let axisValue: string[];
  let isPeriodShorterThanRange = true;

  if (data.length < aMonthInDays) {
    axisValue = newAccountAxisValue(startDate, monthAfterStartDate);
    isPeriodShorterThanRange = true;
  } else {
    let threeMonthsAfter: string = '';
    let isLeapYear: boolean;
    let isLeapNextYear: boolean;
    let yearInDays: number;
    let monthNumber: number;
    let startDateYear: number = dateToYear(startDate);

    switch (range) {
      case '1M': axisValue = monthlyAxisValues(data, 'date'); isPeriodShorterThanRange = false; break;
      case '3M':
        axisValue = threeMonthsAxisValues(data, 'date');
        threeMonthsAfter = getDateTime(true, [ 'plus' ], [ [ 3, 'month' ] ], startDate);
        isPeriodShorterThanRange = axisValue.length < datesDifference(startDate, threeMonthsAfter); break;
      case '1Y': axisValue = calculateRelativeTimeAxisValues(data);
        isLeapYear = leapYear(startDateYear.toString());
        isLeapNextYear = leapYear((startDateYear + 1).toString());
        monthNumber = dateToMonth(startDate);
        // When we start from Jan and Feb in leap year or the rest months in year before leap one, the years days are 366
        yearInDays = (isLeapYear && monthNumber <= 2) || (isLeapNextYear && monthNumber > 2) ? 366 : 365;
        isPeriodShorterThanRange = axisValue.length < yearInDays; break;
      case 'YTD':
        axisValue = calculateRelativeTimeAxisValues(data);
        isPeriodShorterThanRange = dateToYear(previousDay) === startDateYear;
        break;
      case 'ALL': axisValue = allAxisValues(data); isPeriodShorterThanRange = true; break;
      default: axisValue = [ '' ]; break;
    }
  }
  return { axisValue, isPeriodShorterThanRange };
};
