import { createAction } from '@reduxjs/toolkit';

import { AuthRefreshResultData } from '../models/auth/types';
import { CountryData, ENROLL_METHOD, IndividualExtendedInfoData } from '../models/enroll';
import { GWQueryCase, SORCommonOrderResponse, SORErrorResponse } from '../models/gateway/types';
import { AppStateStatus } from '../store-util/AppStateCache';

import { LoadWatchlistChartDataPayload } from './market-data/charting/types';
import { ProcessSymbolSubscribesPayload, ProcessSymbolUnSubscribesPayload } from './market-data/types';
import { RefreshPaymentsPayload } from './payment/types';
import { GetAccountsAvailableCashResponse, OpenPosition } from './reporting/types';


// =======   Action Definitions   =======

// app
/**
 * @param forceClean Removes pending enrollment state and skips auto refresh of token
 */
export const applicationStartup = createAction('app-common/applicationStartup', (forceClean?: boolean) => ({ payload: forceClean }));
export const servicesReady = createAction('app-common/servicesReady');
export const reconnect = createAction<ReconnectPayload>('app-common/reconnect');
export const setAppStatus = createAction<AppStateStatus>('app-common/setAppStatus');


// auth
export const login = createAction<undefined | string>('auth-common/login');
export const logout = createAction('auth-common/logout');
export const autoLogin = createAction('auth-common/autoLogin');
export const autoLoginSuccess = createAction('auth-common/autoLoginSuccess');
export const autoLoginFailed = createAction('auth-common/autoLoginFailed');
export const loginFailed = createAction<{errorStr?: string, errorData?: any} | undefined>('auth-common/loginFailed');
export const authRefreshCompleted = createAction<AuthRefreshResultData>('auth-common/authRefreshCompleted');
export const updateRefreshTokenLockTime = createAction('auth-common/updateRefreshTokenLockTime');
export const getManualTokenSuccess = createAction<AuthRefreshResultData>('auth-common/getManualTokenSuccess');
export const enrollAuthCompleted = createAction<AuthRefreshResultData>('auth-common/enrollAuthCompleted');

// CRM
export const getIndividualExtendedInfo = createAction('crm-common/getIndividualExtendedInfo');
export const individualExtendedInfoSuccess = createAction<IndividualExtendedInfoPayload>('crm-common/individualExtendedInfoSuccess');
export const individualExtendedInfoFailed = createAction<ErrorPayload>('crm-common/individualExtendedInfoFailed');
export const accountsFailed = createAction<ErrorPayload>('crm-common/accountsFailed');
export const enrollSuccess = createAction<EnrollSuccessPayload>('crm-common/enrollSuccess');
export const getCountryListSuccess = createAction<CountryData | undefined>('crm-common/getCountryListSuccess');

// Market Data
export const marketDataDisconnected = createAction<DisconnectedPayload>('md-common/marketDataDisconnected');
export const loadWatchlistChartData = createAction<LoadWatchlistChartDataPayload>('md-common/loadWatchlistChartData');
export const loadDiscoverChartData = createAction<string>('md-common/loadDiscoverChartData');
/**
 * Not called directly
 */
export const processSymbolSubscribesAction = createAction<ProcessSymbolSubscribesPayload>('md-common/processSymbolSubscribes');
export const processSymbolUnSubscribesAction = createAction<ProcessSymbolUnSubscribesPayload>('md-common/processSymbolUnSubscribes');

// Trading
export const tradingDisconnected = createAction('trading-common/tradingDisconnected');
export const getBalances = createAction('trading-common/getBalances');
export const newOrderInit = createAction('trading-common/newOrderInit');

// Payments/Funds
export const refreshPayments = createAction<undefined | RefreshPaymentsPayload>('payments-common/refreshPayments');
export const refreshPaymentsDone = createAction('payments-common/refreshPaymentsDone');
export const getOwnerTradingAccountsFailed = createAction<ErrorPayloadFull>('payments-common/getOwnerTradingAccountsFailed');

// Reporting
export const getAccountsAvailableCash = createAction('reporting-common/getAccountsAvailableCash');
export const getAccountsAvailableCashSuccess = createAction<GetAccountsAvailableCashResponse>('reporting-common/getAccountsAvailableCashSuccess');
export const getAccountsAvailableCashFailed = createAction<ErrorPayloadFull>('reporting-common/getAccountsAvailableCashFailed');
export const getOpenPositionsSuccess = createAction<OpenPosition[]>('reporting-common/getOpenPositionsSuccess');

// File
export const deleteFileByName = createAction<any>('file-common/deleteFileByName');

// Gateway
export const reconnectEventAction = createAction<ReconnectEventActionPayload>('gw-common/reconnectEventAction');
export const gatewayConnected = createAction('gw-common/gatewayConnected');
export const disconnectGateway = createAction('gw-common/disconnectedGateway');
export const gatewayDisconnected = createAction<GatewayDisconnectedPayload>('gw-common/gatewayDisconnected');
/**
 * Currently used for deleting caches created for gateway features - last status, retries, accessId
 */
export const deleteWatchlistItemCache = createAction<any>('gw-common/deleteWatchlistItemCache');
export const webSocketResponseError = createAction<SORErrorResponse>('gw-common/webSocketResponseError');
// SOR (through Gateway)
export const sorOrderEvent = createAction<SORCommonOrderResponse>('sor-common/sorOrderEvent');

// Common
export const clearErrorsLog = createAction('common/clearErrorsLog');


// =======   Action Payload Types   =======

export type ErrorPayloadFull = {
  callName: string
  status?: number
  resultCode?: number
  error: any
  errorData: any
  errorStr: string
  customData?: any
  requestParams?: any
}
export type ErrorPayloadLite = {
  error: string | any
}
export type ErrorPayload = ErrorPayloadFull | ErrorPayloadLite
export type SuccessPayload = {
  callName: string
  status: number
  data?: any
  customData?: any
  statusFromResponse?: number;
}

export type GatewayDisconnectedPayload = {
  code: number
  reason: string
  isInternal: boolean
}

export type DisconnectedPayload = {
  message?: string
}

export type WithIdPayload = {
  id: number | null
}

export type IndividualExtendedInfoPayload = SuccessPayload & IndividualExtendedInfoData

export type EnrollSuccessPayload = (
  Omit<SuccessPayload, 'callName'> & {
    callName: keyof typeof ENROLL_METHOD
  }
)
export type EnrollSuccessCombinedPayload = (
  EnrollSuccessPayload
) | (
  IndividualExtendedInfoPayload
) | (
  SuccessPayload & WithIdPayload
)


export type DeleteWatchlistItemCachePayload = {
  symbol: string
  queryCase: GWQueryCase,
  allQueryCases?: boolean
}

export type ReconnectPayload = {
  gateway?: boolean
  marketData?: boolean
  trading?: boolean
}

/**
 *  Used for debugging, see also ART-3494
 */
export type CommonPayloadWithCaller = {
  caller?: string;
}

export type ReconnectEventActionPayload = 'GatewayWS' | 'appStateChangeEpic' | 'App';
