import { isString } from 'lodash';

import { MARKET_DATA_TIME_ZONE } from '../constants/date-time.constants';
import marketHours, { HalfDayKeys, HolidayKeys, MarketHours } from '../constants/marketHours';
import { BBOData, MarketState, MarketStateInstrumentSnapshot, OHLCV, SymbolStatus, TradeData } from '../models/market-data/types';
import { QueriesCacheType, QueryCacheItemType } from '../models/trading/types';
import { BBODataCache, InstrumentSnapshotData, InstrumentSnapshotMap, SymbolLite, SymbolStatusDataCache, TradeDataCache } from '../store/market-data/types';

import { getDateTime, isDateMarketSaturdayOrSunday, timeToFloat } from './DateTimeHelpers';
import { NullableString } from './types';


/**
 * Used by MarketData.service only. This is a wrapper of `processMDQueryData`
 * @param dispatch The dispatch function to trigger actions
 * @param response The response from the service
 * @param ohlcvQueriesCache The cache data, containing symbol and queryCase
 */
export function processOHLCVResponse(dispatch: any, response: any, ohlcvQueriesCache: QueriesCacheType) {
  const queryCache: QueryCacheItemType = (ohlcvQueriesCache as any)[response.ref] || {};

  processMDQueryData(dispatch, queryCache, response);
}

/**
 * // TODO: Currently the apps are not using OHLCV data from here in favor of MarketDataSummary calls
 *          Must not be deleted though as we might need this data in the future
 *          Therefore leaving commented out switch to remind how it was initially used
 * Used by both MarketData.service and GatewayWSService
 * @param dispatch The dispatch function to trigger actions
 * @param queryCache The cache that contains queryCase and symbol
 * @param response The response from the service
 */
export function processMDQueryData(dispatch: any, queryCache: QueryCacheItemType, response: any) {
  const { queryCase, symbol } = queryCache;
  const ohlcvData: OHLCV[] = (response?.data || response) as OHLCV[];

  /*
  switch (queryCase) {
    case GWQueryCase.OhlcvWatchlistItemRecentPrices:
      // last 30 'close' prices, per one minute (30 items or less)

      if (ohlcvData?.length > 0) {
        const prices = (
          ohlcvData
            .slice(Math.max(response.length - 30, 0))
            .map(ohlcv => ohlcv.close)
        );
        dispatch(watchListItemRecentPricesLoaded({ symbol, prices }));
      }
      break;

    default:
      console.warn(
        `[MarketDataHelpers] Unknown query case '${GWQueryCase[queryCase as GWQueryCase]}' for ref=${response.ref}`,
        response,
      );
      break;
  }
  */
}

/**
 * @param time Time as float in NY time
 * @param pre Pre market start time as float in NY time
 * @param open Market open time as float in NY time
 * @param close Market close time as float in NY time
 * @param after After market start time as float in NY time
 */
function getMarketStateByRange(time: number, pre: number, open: number, close: number, afterEnd?: number) {
  let state;

  switch (true) {
    case (time >= pre && time < open):
      state = MarketStateInstrumentSnapshot.IS_PreMarket;
      break;

    case (time >= open && time < close):
      state = MarketStateInstrumentSnapshot.IS_RegularHours;
      break;

    case (afterEnd && time >= close && time < afterEnd):
      state = MarketStateInstrumentSnapshot.IS_AfterMarket;
      break;

    default: {
      const isAfterMidnight = (time >= 0 && time < pre);
      state = (
        isAfterMidnight
          ? MarketStateInstrumentSnapshot.IS_MarketClosedNextDay
          : MarketStateInstrumentSnapshot.IS_MarketClosed
      );
      break;
    }
  }

  return state;
}

/**
 * NB: Meant to be used through MarketDataCache. Use directly only if check is needed
 *     for market state from time and date different from now.
 * For reference of Market State times - see:
 *  (1) Google Presentation - https://docs.google.com/presentation/d/17j2erZF0NBqA67IF71b8ub0JK5p7-__OPhYCpyMRd00/edit#slide=id.g10947438435_0_36
 *  (2) Library constants at `alaric-retail-terminal-library/constants/marketHours.ts`
 */
export function calculateMarketState(customDateInUtc?: NullableString) {
  const now = getDateTime(true, [ 'tz', 'format' ], [ [ MARKET_DATA_TIME_ZONE ], [ ] ], customDateInUtc);
  const date = now.substring(0, 10);
  const time = now.substring(11, 16);
  const timeAsFloat = timeToFloat(time);
  if (timeAsFloat == null) return null;

  if (isDateMarketSaturdayOrSunday(now)) {
    return MarketStateInstrumentSnapshot.IS_MarketClosedNextDay;
  }

  const {
    halfDays,
    holidays,
    workingTime: {
      open, close, closeHalfDay,
      preMarket, afterMarketEnd,
    },
  } = marketHours as MarketHours;

  const isHalfDay = halfDays[date as HalfDayKeys];
  const isHoliday = holidays[date as HolidayKeys];

  let state: MarketStateInstrumentSnapshot;
  switch (true) {
    case (isHalfDay):
      state = getMarketStateByRange(timeAsFloat, preMarket, open, closeHalfDay);
      break;

    case (isHoliday):
      state = MarketStateInstrumentSnapshot.IS_MarketClosedNextDay;
      break;

    default:
      state = getMarketStateByRange(timeAsFloat, preMarket, open, close, afterMarketEnd);
      break;
  }

  return state;
}

export function convertToMarketState(value: MarketStateInstrumentSnapshot | null) {
  switch (value) {
    case MarketStateInstrumentSnapshot.IS_PreMarket: return MarketState.PreMarket;
    case MarketStateInstrumentSnapshot.IS_RegularHours: return MarketState.RegularHours;
    case MarketStateInstrumentSnapshot.IS_AfterMarket: return MarketState.AfterMarket;
    case MarketStateInstrumentSnapshot.IS_MarketClosed:
    case MarketStateInstrumentSnapshot.IS_MarketClosedNextDay:
      return MarketState.MarketClosed;
    default: return null;
  }
}

export const dataArrayToMap = (data: (TradeData | BBOData | InstrumentSnapshotData | SymbolStatus)[]) => {
  const result: TradeDataCache | BBODataCache | InstrumentSnapshotMap | SymbolStatusDataCache = {};
  const count = data.length;
  for (let i = 0; i < count; i++) {
    const item = data[i];
    result[item.sym] = item;
  }

  return result;
};

/**
 * If `marketState` is not given as an argument or is `null` - it is retrieved from cache
 */
export function isMarketClosed(marketState?: MarketStateInstrumentSnapshot | MarketState | null) {
  const MarketStateCache = require('../store-util/MarketStateCache').default;
  let theState = marketState ?? MarketStateCache.get().marketState;

  return [
    MarketState.MarketClosed,
    MarketStateInstrumentSnapshot.IS_MarketClosedNextDay,
    MarketStateInstrumentSnapshot.IS_MarketClosed,
  ].includes(theState);
}

export function isMarketRegularHours(marketState?: MarketStateInstrumentSnapshot | MarketState | null) {
  const MarketStateCache = require('../store-util/MarketStateCache').default;
  let theState = marketState ?? MarketStateCache.get().marketState;

  return ([ MarketState.RegularHours, MarketStateInstrumentSnapshot.IS_RegularHours ]).includes(theState);
}

export function isMarketOpen(marketState?: MarketStateInstrumentSnapshot | MarketState | null) {
  const MarketStateCache = require('../store-util/MarketStateCache').default;
  let theState = marketState ?? MarketStateCache.get().marketState;

  return ([
    MarketState.RegularHours,
    MarketStateInstrumentSnapshot.IS_RegularHours,
    MarketState.PreMarket,
    MarketStateInstrumentSnapshot.IS_PreMarket,
    MarketState.AfterMarket,
    MarketStateInstrumentSnapshot.IS_AfterMarket,
  ].includes(theState)
  );
}

/**
 * @param inputItems A list of objects with `symbol` property
 * @param asString Default is `true`. If `false` - return as array of strings
 * @param withCount If `true` - return string is prepended with '(<count>)' for example '(2) AAPL,TSLA'
 * @param noSort If `true` - sorting is skipped and result is returned as is
 * @returns A comma separated list of strings or array of strings - depending on `asString` value
 */
function extractSymbolsFromList(
  inputItems: SymbolLite[] | string[],
  asString: boolean,
  withCount?: boolean,
  noSort?: boolean,
) {
  const asArray = isString(inputItems) ? inputItems?.split(',') : inputItems;
  const firstElement = (asArray?.[0] as any);
  if (!asArray || !firstElement) return asString ? (withCount ? '(0)' : '') : []; // eslint-disable-line no-nested-ternary

  let symbolsArray: string[] = [];
  symbolsArray = (
    (!firstElement?.symbol && isString(firstElement))
      ? asArray as string[]
      : (asArray as SymbolLite[]).map(({ symbol }) => symbol)
  );
  if (!noSort) symbolsArray = [ ...symbolsArray ].sort();
  if (!asString) return symbolsArray;

  return (withCount ? `(${symbolsArray.length}) ` : '') + symbolsArray.join();
}

/**
 * @param inputItems A list of objects with `symbol` property
 * @param withCount If `true` - return string is prepended with '(<count>)' - for example '(2) AAPL,TSLA'
 * @param noSort If `true` - sorting is skipped and result is returned as is
 * @returns A comma separated string - for example 'C,NVDA,AMD'
 */
export function extractSymbolsFromListAsString(
  inputItems: SymbolLite[] | string[],
  withCount?: boolean,
  noSort?: boolean,
) {
  return extractSymbolsFromList(inputItems, true, withCount, noSort) as string;
}

/**
 * @param inputItems A list of objects with `symbol` property
 * @param noSort If `true` - sorting is skipped and result is returned as is
 * @returns An array of symbols - for example `['C', 'NVDA', 'AMD']`
 */
export function extractSymbolsFromListAsArray(inputItems: SymbolLite[] | string[], noSort?: boolean) {
  return extractSymbolsFromList(inputItems, false, false, noSort) as string[];
}

export const changeResponseSymbolDataConversion = (symbol: string): string => {
  // This is needed for users that are not verified
  if (symbol.includes('.V')) {
    return symbol.replace('.V', '');
  }
  // This is needed for symbols with a special character (CQS => NASDAQ conversion) so we can receive and manage market data updates
  if (symbol.includes('/')) {
    return symbol.replace('/', '.');
  }

  return symbol;
};


export const changeRequestSymbolDataConversion = (symbol: string): string => (
  // In order to receive and manage market udpates we need to apply conversion to symbols with special character
  symbol.includes('.') ? symbol.replace('.', '/') : symbol
);

export const subscribeSymbolsWithDataConversion = (symbolList: string[]): string[] => (
  symbolList.map(symbol => changeRequestSymbolDataConversion(symbol))
);
