import React from 'react';

import lang from '../../lib/language';

import experienceIllustrationEffectImage from '../../assets/bigImages/experience_illustration_effects.png';
import robotHeadEffectImage from '../../assets/bigImages/robot_head_effects.png';

import styles from './TransitionScreen.module.scss';

type Props = {
  title: string;
  subTitle: string;
}

const TransitionScreen = (props: Props) => {
  const {
    title,
    subTitle,
  } = props;

  return (
    <>
      <h3 className={styles.title} id="titleTransitionScreen" aria-label={title}>{title}</h3>
      <div className={styles.imageWrapper}>
        <img
          src={title === lang.commonOnboardingTransitionPurposeAndStatus()
            ? robotHeadEffectImage
            : experienceIllustrationEffectImage}
          className={styles.image}
          alt=""
        />
      </div>
      <div className={styles.textsWrapper}>
        <p className={
          subTitle === lang.commonOnboardingTransitionPurposeAndStatusDescription()
            ? styles.purposeAndStatusSubTitle
            : styles.subTitle
          }
        >
          {subTitle}
        </p>
      </div>
    </>
  );
};

export default TransitionScreen;
