import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import lang from '../../../lib/language';
import { EmploymentSatusData, ENROLL_METHOD, IndividualExtendedInfoData, RiskData } from '../../../lib/models/enroll';
import { RootState } from '../../../lib/store';
import { enroll } from '../../../lib/store/crm';
import { ControlError } from '../../../lib/util/ValidationSchemes/ValidationTypes';

import { AutocompleteControl } from '../../index';
import { IOption } from '../../SelectControl/SelectControl.types';
import { KEY_EMPLOYMENT_STATUS_BUSINESS_ID } from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';

import styles from './EnrollIndustry.module.scss';

type Props = {
  setValue: Dispatch<SetStateAction<{}>>;
  errors: ControlError[];
  employmentStatusAnswer: EmploymentSatusData;
}

const EnrollIndustry = ({ errors, setValue, employmentStatusAnswer }:Props) => {
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const dispatch = useDispatch();

  const industries = useSelector<RootState, RiskData[]>((state: RootState) => state.crm.enroll.businessOwn);
  const individual = useSelector<RootState, IndividualExtendedInfoData | null>((state: RootState) => (
    state.crm.individualExtendedInfo
  ));

  const options = useMemo((): IOption[] => industries?.map((industry: RiskData) => ({
    label: industry.name,
    value: industry.id,
  })), [industries]);

  useEffect(() => {
    if (!isInitialised) {
      dispatch(enroll({
        callMethod: ENROLL_METHOD.getBusinessOwn,
        callMethodName: ENROLL_METHOD[ENROLL_METHOD.getBusinessOwn],
      }));

      setIsInitialised(true);
    }
  }, [dispatch, isInitialised]);
  let industryId;

  if (individual!.source_of_incomes.length > 0) {
    const employmentStatus = individual!.source_of_incomes[0];

    industryId = employmentStatus.employment_status_id === employmentStatusAnswer.id
      ? employmentStatus.business_id
      : null;
  }

  useEffect(() => {
    if (industryId) {
      setValue((currentState: {[key: string]: string | number}) => ({
        ...currentState,
        [KEY_EMPLOYMENT_STATUS_BUSINESS_ID]: industryId,
      }));
    }
  }, [industryId, setValue]);

  return (
    <>
      <h3 className={styles.title}>{lang.commonIndustryTitle()}</h3>
      <AutocompleteControl
        id="enrollIndustry"
        options={options || []}
        value={industryId ? industryId.toString() : ''}
        placeholder={lang.commonIndustryTitle()}
        className={styles.control}
        displayWithHighlights
        onValueChanged={(value: string | number) => {
          setValue((currentState: {[key: string]: string | number}) => ({
            ...currentState,
            [KEY_EMPLOYMENT_STATUS_BUSINESS_ID]: value,
          }));
        }}
        errors={errors}
      />
    </>
  );
};

export default EnrollIndustry;
