import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { interval, Observable, Observer } from 'rxjs';
import { delayWhen, filter, ignoreElements, mergeMap, tap } from 'rxjs/operators';

import { GWQueryCase, GWRequestType } from '../../models/gateway/types';
import { GetDetailsFunctionalityType } from '../../models/trading/types';
import GlobalService from '../../services/Global.service';
import { LoggerService } from '../../services/logger/Logger.service';
import { TradeClientStatus } from '../../services/TradeClient.service';
import MarketStateCache from '../../store-util/MarketStateCache';
import { getDateTime } from '../../util/DateTimeHelpers';
import { isMarketClosed } from '../../util/MarketDataHelpers';
import { checkOrderForSellShort } from '../../util/TradingValidationsHelper';
import { notifyUnreadCount } from '../ams';
import { getBalances, individualExtendedInfoSuccess, loginFailed, logout } from '../common-actions';
import { getNextAccount } from '../crm';
import { processErrorInResponse, processResponse } from '../helpers';
import { PENDING_ORDERS_REQUEST_FROM_DATE } from '../reporting/constants';
import { AccountTradeHistoryPayloadData } from '../reporting/types';
import { getCurrentAccount, getToken, hasValidToken } from '../selectors';
import { RootState } from '..';

import {
  balancesReceived,
  cancelOrder,
  cancelOrderFailed,
  connected,
  getBalancesCompleted,
  getBalancesRejected,
  getBuiyngPowerFailed,
  getBuiyngPowerSuccess,
  getBuyingPower,
  getOrders,
  getOrdersCompleted,
  getOrdersRejected,
  getPendingNewOrderCompleted,
  getPendingNewOrderFailed,
  getPositions,
  getPositionsCompleted,
  getPositionsRejected,
  modifyOrder,
  newFXOrder,
  newFXOrderFailed,
  newOrder,
} from './index';

const loginSucceededEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(individualExtendedInfoSuccess.type),
  filter(action => hasValidToken(state$.value)),
  tap(action => {
    if (
      [
        TradeClientStatus.INITIAL,
        TradeClientStatus.CLOSED,
        TradeClientStatus.LOGIN_FAILED,
        TradeClientStatus.SOCKET_CONNECTION_FAILED,
      ].includes(GlobalService.tradeService.status)
    ) {
      GlobalService.tradeService.connect(getToken(state$.value)!);
    }
  }),
  ignoreElements(),
);

const disconnectEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(logout.type, loginFailed.type),
  filter(() => !!GlobalService.tradeService),
  filter(() => state$.value.trading.connected),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService.tradeService.disconnect();
  })),
);


const newOrderEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(newOrder.type),
  mergeMap(
    action => new Observable((observer: Observer<any>) => {
      const { clientOrderId, traceIdentifier, requestType } = state$.value.trading.order ?? {};
      let order = {
        ...action.payload.order,
        clientOrderId,
        traceIdentifier,
        requestType,
      };
      order = checkOrderForSellShort(
        order,
        state$.value.reporting.openPositions.filter(item => item.symbol === order.symbol),
      );

      GlobalService.globalService.order('neworder', order);
      observer.next(notifyUnreadCount());
    }),
  ),
);

const getOrdersEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getOrders.type),
  filter(() => state$.value.trading.connected),
  delayWhen(action => returnDelay(action)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    // eslint-disable-next-line max-len
    getDetailsTradingService(action, state$, GetDetailsFunctionalityType.getOrders, getOrdersCompleted, getOrdersRejected);
  })),
);

const getPositionsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getPositions.type),
  filter(() => state$.value.trading.connected),
  delayWhen(action => returnDelay(action)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    // eslint-disable-next-line max-len
    getDetailsTradingService(action, state$, GetDetailsFunctionalityType.getPositions, getPositionsCompleted, getPositionsRejected);
  })),
);

const getBalancesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getBalances.type, getNextAccount.type),
  filter(() => state$.value.trading.connected),
  delayWhen(action => returnDelay(action)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    // eslint-disable-next-line max-len
    getDetailsTradingService(action, state$, GetDetailsFunctionalityType.getBalances, getBalancesCompleted, getBalancesRejected);
  })),
);

// eslint-disable-next-line max-len
const reduceBuyingPowerWithPendingNewOrdersEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getBalancesCompleted.type, balancesReceived.type, getBuiyngPowerSuccess.type),
  filter((action: PayloadAction<AccountTradeHistoryPayloadData>) => (
    !isMarketClosed(MarketStateCache.get().marketState) || action.type !== balancesReceived.type)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getAccountPendingNewOrderHistory';
    const accountReferenceId = getCurrentAccount(state$.value);
    const toDate = getDateTime();

    const requestBody = {
      fromDate: PENDING_ORDERS_REQUEST_FROM_DATE,
      toDate,
      accountReferenceId,
    };

    GlobalService
      .reportingService
      .getAccountPendingNewOrderHistory(requestBody)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getPendingNewOrderCompleted,
          getPendingNewOrderFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
        );
      }).catch(e => observer.next(getPendingNewOrderFailed(e)));
  })),
);

const returnDelay = (action: any) => {
  if (action.type === connected.type) {
    // delay on first call
    return interval(1000);
  }
  return interval(0);
};

const getDetailsTradingService = (
  action: any,
  state$: any,
  functionality: GetDetailsFunctionalityType,
  getDetailCompleted: Function,
  getDetailRejected: Function,
): any => new Observable((observer: Observer<any>) => {
  const { accounts, selectedAccount } = state$.value.crm;
  const callName = `trading/${functionality}`;
  let account = typeof action.payload === 'string' || !action.payload ? action.payload : action.payload.account;
  if (account == null) {
    account = accounts[selectedAccount];
  }

  if (account == null) {
    processErrorInResponse(callName, new Error('[T] Account not found'), action, observer, getDetailRejected);
    return;
  }

  GlobalService.tradeService
    .getDetails(account, functionality)
    .then((response: any) => processResponse(
      callName,
      { account },
      response,
      observer,
      getDetailCompleted,
      getDetailRejected,
      true,
    ))
    .catch(error => processErrorInResponse(callName, error, action, observer, getDetailRejected));
});

const cancelOrderEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(cancelOrder.type),
  mergeMap(
    action => new Observable((observer: Observer<any>) => {
      const account = state$.value.crm.accounts[state$.value.crm.selectedAccount];
      const { clientOrderId, traceIdentifier, requestType } = state$.value.trading.order;
      if (!account) {
        processErrorInResponse(
          'trading/cancelOrder',
          new Error('[T] Account not found'),
          action,
          observer,
          cancelOrderFailed,
        );
        return;
      }

      const data = { clientOrderId, traceIdentifier, ...action.payload, requestType };
      GlobalService.globalService.order('cancel', data);
      observer.next(notifyUnreadCount());
    }),
  ),
);

const modifyOrderEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(modifyOrder.type),
  mergeMap(
    action => new Observable((observer: Observer<any>) => {
      const { clientOrderId, traceIdentifier, requestType } = state$.value.trading.order;
      const data = { clientOrderId, traceIdentifier, ...action.payload, requestType };
      GlobalService.globalService.order('modifyOrder', data);
      observer.next(notifyUnreadCount());
    }),
  ),
);

const getBuyingPowerEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getBuyingPower.type),
  filter(() => state$.value.app.isServicesReady),
  filter(() => isMarketClosed(MarketStateCache.get().marketState)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getBPInCloseMarket';

    const accountReferenceId = getCurrentAccount(state$.value);
    if (!accountReferenceId) {
      observer.next(getBuiyngPowerSuccess({ accountReferenceId: '', buyingPowerId: 0 }));
      console.error(`[reporting/epics] getBuyingPowerEpic - current account (accountReferenceId) not found in state for ${callName}`);
      return;
    }

    GlobalService
      .reportingService
      .getBPInClosedMarket({ accountReferenceId })
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getBuiyngPowerSuccess,
          getBuiyngPowerFailed,
        );
      }).catch(error => processErrorInResponse(callName, error, action, observer, getBuiyngPowerFailed));
  })),
);

const newFXOrderEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(newFXOrder.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const logger = LoggerService.getLogger();
    const callName = 'trading/newFXOrder';
    const account = getCurrentAccount(state$.value);
    const clientOrderId = state$.value.trading.fxOrder.clientOrderId;
    const traceIdentifier = state$.value.trading.fxOrder.traceIdentifier;

    const order = {
      ...action.payload,
      account,
      clientOrderId,
      dateTime: new Date().toISOString(),
      messageType: GWRequestType.NewOrderRequest,
      traceIdentifier,
      destination: 'XENF',
      capacity: 'Agency',
    };

    if (!account) {
      observer.next(newFXOrderFailed());
      logger.error(`User account is not defined in ${callName}`);
      return;
    }

    GlobalService.gatewayWs.sendRequest(GWRequestType.NewOrderRequest, order, GWQueryCase.Order);
  })),
);

export default combineEpics(
  loginSucceededEpic,
  disconnectEpic,
  getOrdersEpic,
  getPositionsEpic,
  getBalancesEpic,
  getBuyingPowerEpic,
  newOrderEpic,
  cancelOrderEpic,
  modifyOrderEpic,
  newFXOrderEpic,
  reduceBuyingPowerWithPendingNewOrdersEpic,
);
