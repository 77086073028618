export enum ModalScreenTypes {
  BUY = 'Buy',
  Sell = 'Sell',
  ORDER = 'Order',
}

export interface WatchListStocks {
  logoBase64?: string;
  companyName?: string;
  symbol: string;
}

export interface WatchListSortedStocks {
  change: number;
  changePercent: number;
  currentPrice: number;
  logoBase64: string,
  companyName: string,
  symbol: string,
}

export enum WatchListHeaderEnum {
  COLUMN_LAST_PRICE = 2,
  COLUMN_CHANGE,
  COLUMN_PERCENT_CHANGE,
}
