import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import StatsCache from '../../../lib/store-util/StatsCache';
import { formatCurrency, formatNumber } from '../../../lib/util/DataHelpers';

import { useIsMobile } from '../../../hooks';

import styles from './KeyStatistics.module.scss';

type Props = {
  symbol: string;
} & ReduxProps;

const KeyStatistics = ({ symbol, symbolKeyStatistics }: Props) => {
  const isMobile = useIsMobile();
  const { avg30Volume, dividendYield, marketCap, peRatio, week52High, week52Low, ttmEps } = symbolKeyStatistics
    .find(el => el.symbol === symbol) ?? {};
  const { open, high, low, prevClose, prevVolume } = StatsCache.use(symbol) ?? { };

  const statisticCardItems = [
    {
      id: 1,
      [lang.commonStockDetailsStatsOpen()]: formatCurrency(open, false, true).toUpperCase(),
      [lang.commonStockDetailsStatsHigh()]: formatCurrency(high, false, true).toUpperCase(),
      [lang.commonStockDetailsStatsLow()]: formatCurrency(low, false, true).toUpperCase(),
    },
    {
      id: 2,
      [lang.commonMarketHoursAtClose()]: formatCurrency(prevClose, false, true).toUpperCase(),
      [lang.commonDiscoverInfoBoxVolume()]: formatNumber(prevVolume, false, true).toUpperCase(),
      'Av. Volume': formatNumber(avg30Volume, false, true).toUpperCase(),
    },
    {
      id: 3,
      [lang.commonStockDetailsStatsWeek52High()]: formatCurrency(week52High, false, true).toUpperCase(),
      [lang.commonStockDetailsStatsWeek52Low()]: formatCurrency(week52Low, false, true).toUpperCase(),
      [lang.commonStockDetailsStatsMarketCap()]: formatCurrency(marketCap, false, true).toUpperCase(),
    },
    {
      id: 4,
      [lang.commonStockDetailsStatsPE()]: formatNumber(peRatio, false, true).toUpperCase(),
      [lang.commonDiscoverInfoBoxEps()]: formatCurrency(ttmEps, false, true).toUpperCase(),
      [lang.commonStockDetailsStatsDivYield()]:
      dividendYield
        ? formatNumber(dividendYield, false, true)
        : lang.commonWatchlistChartNA().toUpperCase(),
    },
  ];

  if (!symbolKeyStatistics.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <h6 className={isMobile ? styles.titleMobile : styles.title}>{lang.commonStockDetailsStatsTitle()}</h6>
      </div>
      <div className={styles.statisticsWrapper}>
        {statisticCardItems.map(item => (
          <div className={styles.card} key={item.id}>
            <div className={styles.cardInner}>
              { Object.entries(item)
                .filter(([key, _]) => key !== 'id')
                .map(([key, value]) => (
                  <div key={key} className={styles.cardContent}>
                    <p className={styles.key}>{key}</p>
                    <p className={styles.value}>{value}</p>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  symbolKeyStatistics: state.fundamental.keyStats,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(KeyStatistics);
