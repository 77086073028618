import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store/index';
import { getCompany } from '../../../lib/store/selectors';
import { formatNumber, getLimitedText } from '../../../lib/util/DataHelpers';
import { INVALID_VALUE } from '../../../lib/util/types';

import { useIsMobile } from '../../../hooks';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';

import styles from './CompanyDetails.module.scss';

type OwnProps = { symbol: string }
type Props = OwnProps & ReduxProps;

const MaxCompanyInfoCharacters = 259;

const CompanyDetails = ({ symbol, getCompanyBySymbol }: Props) => {
  const isMobile = useIsMobile();
  const [showMore, setShowMore] = useState<boolean>(false);
  const symbolCompany = getCompanyBySymbol(symbol);

  const visibleReadMore = symbolCompany
  && symbolCompany.description
  && (symbolCompany.description.trim().length > MaxCompanyInfoCharacters);

  if (!symbolCompany) {
    return null;
  }

  const { companyName, description, ceo, employees, city, state, country, industry, sector } = symbolCompany;

  return (
    <div className={styles.wrapper}>
      <div>
        <h6 className={isMobile ? styles.titleMobile : styles.title}>{lang.commonStockDetailsAboutCompanyTitle()}</h6>
      </div>
      <div>
        <div className={styles.companyName}>{companyName}</div>
        {description && (
          <div className={styles.companyInfo}>
            <p>
              {
                !showMore && visibleReadMore
                  ? getLimitedText(description, MaxCompanyInfoCharacters)
                  : description
              }
              {visibleReadMore && (
              <Button
                variant={ButtonTypesEnum.Link}
                onClick={() => setShowMore(!showMore)}
              >
                <>
                    &nbsp;
                  {`read ${showMore ? 'less' : 'more'}`}
                </>
              </Button>
              )}
            </p>
          </div>
        )}
        <div className={styles.companyInfoCardsWrapper}>
          <div className={styles.wrapperCeoAndIndustry}>
            <div className={styles.wrapperCeo}>
              <p className={styles.label}>{lang.commonStockDetailsAboutCompanyCeo()}</p>
              <p className={styles.value}>{ceo || INVALID_VALUE}</p>
            </div>
            <div className={styles.wrapperIndustry}>
              <p className={styles.label}>{lang.commonStockDetailsAboutCompanyIndustry()}</p>
              <p className={styles.value}>{industry || INVALID_VALUE}</p>
            </div>
          </div>
          <div className={styles.wrapperEmployeesAndSector}>
            <div className={styles.wrapperEmployees}>
              <p className={styles.label}>{lang.commonStockDetailsAboutCompanyEmployees()}</p>
              <p className={styles.value}>{formatNumber(employees, false, false, true, 0)}</p>
            </div>
            <div className={styles.wrapperSector}>
              <p className={styles.label}>{lang.commonStockDetailsAboutCompanySector()}</p>
              <p className={styles.value}>{sector || INVALID_VALUE}</p>
            </div>
          </div>
          <div className={styles.wrapperHeadquarters}>
            <p className={styles.label}>{lang.commonStockDetailsAboutCompanyHeadquarters()}</p>
            {
              (!city && !state && !country)
                ? <p className={styles.value}>{INVALID_VALUE}</p>
                : (
                  <p className={styles.value}>
                    {`${city || INVALID_VALUE},
                    ${state || INVALID_VALUE}  ${country || INVALID_VALUE}`}
                  </p>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getCompanyBySymbol: (symbol: string) => getCompany(symbol, state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CompanyDetails);
