/* eslint-disable import/no-unresolved */
import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { History } from 'history'; // eslint-disable-line
import { Action } from 'redux';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker';

import lang from './lib/language';
import { IGNORE_GATEWAY_CONNECTION } from './lib/libSettings';
import { TokenDecodedData } from './lib/models/enroll';
import { ENROLL_AUTH_REQUEST_TYPE } from './lib/models/enroll-requests';
import { RootState } from './lib/store';
import { getAllMessages } from './lib/store/ams';
import { setOnlineStatus, setWebLoginReady } from './lib/store/app/index';
import { checkAndRefreshToken, enrollAuth } from './lib/store/auth';
import { applicationStartup, login, logout, reconnectEventAction } from './lib/store/common-actions';
import {
  getCurrentAccount,
  getToken,
  hasIndividualExtendedInfo,
  hasValidToken,
  isCRMEnrollReady,
  isLoginPending as storeIsLoginPending,
  isUserForVerificationStatus,
  isUserVerifiedStatus,
} from './lib/store/selectors';
import { decodeJwt } from './lib/util/DataHelpers';

import ChangePasswordConfirmation from './containers/MyProfile/ChangePassword/ChangePasswordConfirmation';
import { startMockedMode } from './util/helpers';
import { UI_ROUTE_MOCKED, UI_ROUTES } from './util/routes';
import {
  MyAccountDocuments,
  MyAccountNotifications,
  myAccountPersonalDetails,
  NewOnMarketTable,
  Preloader,
  ScrollToTop,
  SearchSymbolsMob,
} from './components';
import { MOCK, setLogConfig } from './configDebug';
import {
  AddYourBankAccount,
  BuyAndSell,
  DepositSlip,
  Discover,
  DividendPayersAndHighGrowthCompaniesContainer,
  EarningsCalendarAllItems,
  Enroll,
  EnrollCompleted,
  EnterAmount,
  Error,
  Funds,
  Login,
  MakeYourFirstDeposit,
  Modify,
  MyAccount,
  MyProfile,
  Overview,
  PopularGroupTable,
  Recovery,
  Reports,
  SymbolDetails,
  TopGainersAndLosersList,
  TradeHistory,
  TradingViewChart,
  UploadBankStatement,
  VerificationLanding,
  VerificationPhoneNumber,
  VerificationPhoneOTP,
  VerificationPoA,
  VerificationPoI,
  VerificationTermsConditions,
  WithdrawalAmount,
} from './containers';
import HeaderAndSideBar from './HeaderAndSideBar';
import { useIsMobile } from './hooks';
import useNetwork from './useNetwork';

import styles from './App.module.scss';

type Props = ReduxProps;

const App = (props: Props): JSX.Element => {
  const ref = useRef({ appLoaded: false, path: '' });

  const {
    start,
    isOnline,
    isAuthenticated,
    isAppLoaded,
    isGatewayConnected,
    isGatewayConnectionTimedOut,
    isTokenValid,
    isManualToken,
    loginResultCallback,
    setLoginReady,
    isLoginPending,
    isAppConnected,
    currentAccount,
    extendedInfo,
    isUserVerified,
    getAllDataMessages,
    appLogOut,
    renewToken,
    setNetworkStatus,
    reconnectToGwAndSubscibeSymbols,
    loginRedirect,
  } = props;

  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const { pathname, search, state } = location;

  const networkStatus = useNetwork();

  useEffect(() => {
    if (networkStatus && !isOnline) {
      reconnectToGwAndSubscibeSymbols();
    }
    setNetworkStatus(networkStatus);
  }, [isOnline, networkStatus, reconnectToGwAndSubscibeSymbols, setNetworkStatus]);

  const [isRedirected, setRedirected] = useState<boolean>(false);
  const [isLoadedApp, setIsLoadedApp] = useState<boolean>(false);
  const [isRedirectLogin, setIsRedirectLogin] = useState<boolean>(false);

  const searchParams = new URLSearchParams(window.location.search);
  const isDirectLogin = searchParams.get('directLogin');
  if (isDirectLogin && !isRedirectLogin) {
    setIsRedirectLogin(true);
  }

  useEffect(() => {
    if (isRedirectLogin && !isAuthenticated && isAppLoaded) {
      loginRedirect(history);
    }
  }, [isRedirectLogin, isAppLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEnrollPath = [
    UI_ROUTES.enroll,
    UI_ROUTES.enroll + UI_ROUTES.enrollCompleted,
  ].includes(location.pathname);

  const isFundsPath = [
    UI_ROUTES.funds + UI_ROUTES.withdrawalAmount,
    UI_ROUTES.funds + UI_ROUTES.addYourBankAccount,
    UI_ROUTES.funds + UI_ROUTES.uploadBankStatement,
    UI_ROUTES.funds + UI_ROUTES.enterAmount,
    UI_ROUTES.funds + UI_ROUTES.depositSlip,
    UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit,
  ].includes(location.pathname);

  const isVerificationPath = [
    UI_ROUTES.verification,
    UI_ROUTES.verification + UI_ROUTES.verificationPhoneNumber,
    UI_ROUTES.verification + UI_ROUTES.verificationPhoneOTP,
    UI_ROUTES.verification + UI_ROUTES.verificationPoI,
    UI_ROUTES.verification + UI_ROUTES.verificationPoA,
    UI_ROUTES.verification + UI_ROUTES.verificationTermsAndConditions,
  ].includes(location.pathname);

  const isAuthPath = [
    UI_ROUTES.signUp as string,
    UI_ROUTES.authResult as string,
    UI_ROUTES.emailConfirm as string,
    '/',
  ].includes(location.pathname);

  const isBuyOrSellPath = location.pathname.includes(UI_ROUTES.buy) || location.pathname.includes(UI_ROUTES.sell);
  const isModifyPath = location.pathname.includes(UI_ROUTES.modify);
  const isAuthResultPath = location.pathname === UI_ROUTES.authResult;
  const tradeHistoryPath = location.pathname === UI_ROUTES.tradeHistory;
  const searchSymbolPathMob = location.pathname === UI_ROUTES.searchSymbolsMob;
  const isMockedPath = pathname.includes(UI_ROUTE_MOCKED);

  const visibleHeaderAndSidebar = isMobile
    ? (
      !isEnrollPath
      && !isVerificationPath
      && !isFundsPath
      && (!isAuthPath)
      && !isBuyOrSellPath
      && !isModifyPath
      && !tradeHistoryPath
      && !searchSymbolPathMob
    ) : (
      !isEnrollPath
      && !isVerificationPath
      && (!isAuthPath)
    );

  const emailConfirmPath = [
    UI_ROUTES.emailConfirm as string,
    UI_ROUTES.emailResend as string,
  ].includes(pathname);
  const emailConfirmationOrResentWhileLoggedIn = (emailConfirmPath && isTokenValid && !isManualToken);

  /* define the worker */
  const worker = useMemo(() => new Worker(), []);

  /* fetch the worker message */
  useEffect(() => {
    if (window.Worker) {
      worker.onmessage = (e: MessageEvent<string>) => {
        renewToken();
      };
    }
  }, [renewToken, worker]);

  // did mount
  useEffect(() => {
    if (window.Worker) {
      worker.postMessage('start refresh token timer');
    }
    if (ref.current.appLoaded) return;
    ref.current.appLoaded = true;

    if (emailConfirmationOrResentWhileLoggedIn) {
      appLogOut();
      return;
    }
    if (pathname.includes(UI_ROUTE_MOCKED) && !MOCK.ENABLED) {
      startMockedMode();
      history.replace(pathname);
    } else {
      start();
      getAllDataMessages();
    }

    if (search.includes('debug=')) {
      const queryParams = new URLSearchParams(search);
      setLogConfig(queryParams.get('debug'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isGatewayConnected && !IGNORE_GATEWAY_CONNECTION) {
      setRedirected(false);
    }
  }, [isGatewayConnected]);

  /**
   * The following two useEffect are for handling auth-result
   * */
  useEffect(() => {
    if (isAppLoaded && isAuthResultPath) {
      loginResultCallback();
    }
  }, [loginResultCallback, isAppLoaded, isAuthResultPath]);

  const hasSession = location.search.includes('session_state=');

  useEffect(() => {
    if (
      MOCK.ENABLED
      && !pathname.includes(UI_ROUTE_MOCKED)
      && pathname.match(/[/](overview|symbol|discover|payment|reports|trade-history|my-account)/i)
      && pathname !== ref.current.path
    ) {
      ref.current.path = pathname;
      history.replace(pathname + UI_ROUTE_MOCKED);
    }

    if (!ref.current.appLoaded) return;

    // normal login
    if (isAuthResultPath && isTokenValid && hasSession && !isRedirected) {
      setRedirected(true);
      setLoginReady();
      history.push(UI_ROUTES.overview);
    } else if (!isAuthResultPath && isTokenValid && !isRedirected && isAppLoaded) {
      setRedirected(true);
      setLoginReady();
    }
  }, [
    isAuthResultPath,
    isTokenValid,
    hasSession,
    setLoginReady,
    history,
    isAppLoaded,
    isAuthenticated,
    isRedirected,
    pathname,
    search,
  ]);

  useEffect(() => {
    if (
      isLoadedApp
      && pathname !== UI_ROUTES.emailConfirm
      && pathname !== UI_ROUTES.emailResend
      && pathname !== UI_ROUTES.signUp
      && pathname !== UI_ROUTES.baseURL
      && (!state && pathname === UI_ROUTES.overview)
    ) {
      history.push(isUserVerified ? UI_ROUTES.myAccount : UI_ROUTES.overview);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedApp]);

  if (
    (
      isAuthResultPath
      || isLoginPending
      || (isTokenValid && !isAppConnected && !isManualToken)
      || (isGatewayConnectionTimedOut && !IGNORE_GATEWAY_CONNECTION)
      || emailConfirmationOrResentWhileLoggedIn
    )
    || (MOCK.ENABLED && !isGatewayConnected)
    || (!MOCK.ENABLED && isMockedPath)
    || (!currentAccount && isUserVerified)
    || (isAuthenticated && !extendedInfo)
  ) {
    let message = isLoginPending ? lang.commonMessageLoading() : lang.commonMessageConnecting();
    if (isGatewayConnectionTimedOut) message = lang.commonMessageConnectionTimedOut();
    if (pathname.match(/tradingView/i)) {
      return (
        <div className={styles.loadingIndicator}>
          <div className={styles.spinner} />
        </div>
      );
    }
    return (
      <div className={styles.loader}>
        <Preloader
          isFullScreen
          text={message}
          timeOutSetup={{
            checkIfTimeout: () => !isTokenValid,
            onTimeout: () => {
              appLogOut();
              history.push(UI_ROUTES.signUp);
            },
          }}
          owner="App"
        />
      </div>
    );
  }

  if (!isLoadedApp) {
    setIsLoadedApp(true);
  }

  return (
    <div
      // style={{
      //   zoom: (browserWidthSize <= ZOOM_BEHAVIOR_WIDTH && !isZoomScreen)
      //     ? `${Math.floor(browserWidthSize / 10).toString()}%`
      //     : '' }}
      className={[
        !pathname.startsWith(UI_ROUTES.tradingView) && styles.container,
        isEnrollPath && styles.enrollContainer,
        isVerificationPath && styles.enrollContainer,
        isFundsPath && styles.enrollContainer,
        isAuthPath && styles.enrollContainer,
      ].join(' ')}
    >
      {/* <div> */}
      {(isAuthenticated
        && !pathname.startsWith(UI_ROUTES.myAccountDocuments)
        && !pathname.startsWith(UI_ROUTES.error)
        && !pathname.startsWith(UI_ROUTES.tradingView)
      ) && (
        <>
          {visibleHeaderAndSidebar && <HeaderAndSideBar />}
          <ToastContainer />
        </>
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      <div className={pathname.startsWith(UI_ROUTES.tradingView) ? styles.whiteBody : (
        isEnrollPath
        || isVerificationPath
        || isFundsPath
        || isAuthPath) ? styles.enrollContent : styles.content}
      >

        <div
          className={[
            styles.mainContent,
            // eslint-disable-next-line no-nested-ternary
            pathname.startsWith(UI_ROUTES.tradingView) ? null
              : (isMobile ? styles.contentWrapperMobile : styles.contentWrapper),
            isEnrollPath && styles.enrollContentWrapper,
            isVerificationPath && styles.enrollContentWrapper,
            isFundsPath && styles.enrollContentWrapper,
            isAuthPath && styles.enrollContentWrapper,
          ].join(' ')}
        >
          {
            isAuthenticated ? (
              <>
                <ScrollToTop />
                <Switch>
                  <Route exact path={UI_ROUTES.error} component={Error} />
                  <Route exact path={UI_ROUTES.myAccount} component={MyAccount} />
                  <Route exact path={UI_ROUTES.myAccount + UI_ROUTE_MOCKED} component={MyAccount} />
                  <Route exact path={UI_ROUTES.myAccountNotifications} component={MyAccountNotifications} />
                  <Route exact path={UI_ROUTES.myAccountDocuments} component={MyAccountDocuments} />
                  <Route exact path={UI_ROUTES.myAccountPersonalDetails} component={myAccountPersonalDetails} />
                  <Route path={UI_ROUTES.overview} component={Overview} />
                  <Route exact path={UI_ROUTES.discover} component={Discover} />
                  <Route exact path={UI_ROUTES.discover + UI_ROUTE_MOCKED} component={Discover} />
                  <Route path={`${UI_ROUTES.symbol}/:name`} component={SymbolDetails} />
                  <Route path={`${UI_ROUTES.buy}/:name`} component={BuyAndSell} />
                  <Route path={`${UI_ROUTES.sell}/:name`} component={BuyAndSell} />
                  <Route exact path={UI_ROUTES.funds} component={Funds} />
                  <Route exact path={UI_ROUTES.searchSymbolsMob} component={SearchSymbolsMob} />
                  <Route
                    path={UI_ROUTES.discover + UI_ROUTES.topMovers}
                    component={TopGainersAndLosersList}
                  />
                  <Route
                    path={UI_ROUTES.discover + UI_ROUTES.popularGroup}
                    component={PopularGroupTable}
                  />
                  {/* TODO: Enable New on the Market feature when the backend team fiexes the konown issues */}
                  <Route
                    path={UI_ROUTES.discover + UI_ROUTES.earningsCalendar}
                    component={EarningsCalendarAllItems}
                  />
                  <Route path={UI_ROUTES.tradeHistory} component={TradeHistory} />
                  <Route path={UI_ROUTES.myProfile} component={MyProfile} />
                  <Route
                    exact
                    component={Enroll}
                    path={UI_ROUTES.enroll}
                  />
                  <Route
                    exact
                    component={EnrollCompleted}
                    path={UI_ROUTES.enroll + UI_ROUTES.enrollCompleted}
                  />
                  <Route
                    exact
                    component={MakeYourFirstDeposit}
                    path={UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit}
                  />
                  <Route
                    exact
                    component={AddYourBankAccount}
                    path={UI_ROUTES.funds + UI_ROUTES.addYourBankAccount}
                  />
                  <Route
                    exact
                    component={UploadBankStatement}
                    path={UI_ROUTES.funds + UI_ROUTES.uploadBankStatement}
                  />
                  <Route
                    exact
                    component={EnterAmount}
                    path={UI_ROUTES.funds + UI_ROUTES.enterAmount}
                  />
                  <Route
                    exact
                    component={WithdrawalAmount}
                    path={UI_ROUTES.funds + UI_ROUTES.withdrawalAmount}
                  />
                  <Route
                    exact
                    component={DepositSlip}
                    path={UI_ROUTES.funds + UI_ROUTES.depositSlip}
                  />
                  <Route
                    exact
                    path={UI_ROUTES.verification}
                    component={VerificationLanding}
                  />
                  <Route
                    exact
                    component={VerificationPhoneNumber}
                    path={UI_ROUTES.verification + UI_ROUTES.verificationPhoneNumber}
                  />
                  <Route
                    exact
                    component={VerificationPhoneOTP}
                    path={UI_ROUTES.verification + UI_ROUTES.verificationPhoneOTP}
                  />
                  <Route
                    exact
                    component={VerificationPoI}
                    path={UI_ROUTES.verification + UI_ROUTES.verificationPoI}
                  />
                  <Route
                    exact
                    component={VerificationPoA}
                    path={UI_ROUTES.verification + UI_ROUTES.verificationPoA}
                  />
                  <Route
                    exact
                    component={VerificationTermsConditions}
                    path={UI_ROUTES.verification + UI_ROUTES.verificationTermsAndConditions}
                  />
                  {/* it is currnetcly not used in HammerWeb  */}
                  {/* <Route
                    exact
                    component={Recovery}
                    path={UI_ROUTES.recovery}
                  /> */}
                  <Route
                    exact
                    component={DividendPayersAndHighGrowthCompaniesContainer}
                    path={UI_ROUTES.discover + UI_ROUTES.dividendPayersAndHighGrowthCompaniesPath}
                  />
                  <Route
                    exact
                    component={TradingViewChart}
                    path={`${UI_ROUTES.tradingView}/:name`}
                  />
                  <Route
                    exact
                    component={Reports}
                    path={UI_ROUTES.myAccountReports}
                  />
                  <Route
                    exact
                    component={Modify}
                    path={UI_ROUTES.modify}
                  />
                  <Route exact component={ChangePasswordConfirmation} path={UI_ROUTES.myAccountChangePassword} />
                  <Redirect to={UI_ROUTES.overview} />
                </Switch>
              </>
            ) : (
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path={UI_ROUTES.signUp} component={Login} />
                <Route exact path={UI_ROUTES.emailConfirm} component={Login} />
                <Route exact path={UI_ROUTES.emailResend} component={Login} />
                <Redirect to={UI_ROUTES.signUp} />
              </Switch>
            )
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAppLoaded: state.app.loaded,
  isOnline: state.app.isOnline,
  isAppConnected: state.app.connected,
  isLoginPending: storeIsLoginPending(state),
  isWebLoginReady: state.app.isWebLoginReady,
  isGatewayConnected: state.gateway.connected,
  isGatewayConnectionTimedOut: state.gateway.status === 'timedout',
  isTokenValid: hasValidToken(state),
  isManualToken: state.auth.enroll.isManualToken,
  isAuthenticated: hasValidToken(state) && state.app.loaded,
  extendedInfo: state.crm.individualExtendedInfo,
  currentAccount: getCurrentAccount(state),
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
  isCRMEnrollReadyStatus: isCRMEnrollReady(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loginRedirect: (history: History) => {
    dispatch(login('login'));
  },
  start: () => dispatch(applicationStartup()),
  appLogOut: () => dispatch(logout()),
  loginResultCallback: () => dispatch(login('callback')),
  setLoginReady: () => dispatch(setWebLoginReady()),
  reconnectToGwAndSubscibeSymbols: () => dispatch(reconnectEventAction('App')),
  getAllDataMessages: () => dispatch(getAllMessages()),
  renewToken: () => dispatch(checkAndRefreshToken()),
  setNetworkStatus: (hasConnection: boolean) => dispatch(setOnlineStatus(hasConnection)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(App);
