import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Chip, Paper, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';

import lang from '../../lib/language';
import { getPhoneNumberValidity, validateControl } from '../../lib/util/ValidationSchemes/Validate';
import { ControlError } from '../../lib/util/ValidationSchemes/ValidationTypes';

import Country from '../Country/Country';
import { ICountryControlOptions } from '../CountryControl/CountryControl.types';
import { InputFieldTypesEnum } from '../InputControl/Input.types';
import InputControl from '../InputControl/InputControl';

import { PhoneAutocompleteValue } from './PhoneAutocomplete.types';

import styles from './PhoneAutocomplete.module.scss';

type Props = {
  options: ICountryControlOptions[];
  value: PhoneAutocompleteValue;
  isClickedBtnContinue: boolean;
  onChange: (value: PhoneAutocompleteValue) => void;
}

const PhoneAutocomplete = (props: Props) => {
  const { options, onChange, value, isClickedBtnContinue } = props;

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [isShowCountryCode, setIsShowCountryCode] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<any>([]);

  const [controlValue, setControlValue] = useState<PhoneAutocompleteValue>(value ?? {
    code: '',
    phone: '',
    countryId: '',
    label: '',
  });

  const filterOptionsLetters: (options, state) =>
  ICountryControlOptions[] = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
  });

  const filterOptionsNumbers: (options, state) =>
  ICountryControlOptions[] = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.value as string,
  });

  const onCountryChange = useCallback((country): void => {
    const newValue = {
      ...controlValue,
      code: country.value,
      countryId: country.countryId,
    };
    setControlValue(newValue);
    onChange(newValue);
  }, [controlValue, onChange]);

  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>): boolean => {
    const initialValue = event.target.value;
    const strippedValue = initialValue.replace(/[^0-9]/g, '');

    if (initialValue !== strippedValue) {
      event.target.value = strippedValue;
      return false;
    }
    const newValue = {
      ...controlValue,
      phone: initialValue,
    };
    setControlValue(newValue);
    onChange(newValue);
    return true;
  };

  useEffect(() => {
    if (options.length > 0 && value) {
      const optionToEmit: any = [];
      if (controlValue.code.toString().trim() !== '') {
        const filteredOptions = options.find((option: ICountryControlOptions) => (
          option.value.toString().toLowerCase() === controlValue.code.toString().toLowerCase()));
        optionToEmit.push(filteredOptions);
      } else {
        optionToEmit.push(options[0]);
      }
      setSelectedOption(optionToEmit);
    }
  }, [controlValue, options, value]);

  useEffect(() => {
    if (!isInitialised && (value?.code || value?.phone)) {
      setControlValue(value);
      setIsInitialised(true);
    }
  }, [isInitialised, value]);

  const handleClickOutside = () => {
    setIsShowCountryCode(true);

    if (controlValue.code.toString().trim() !== '') {
      const optionToEmit: any = [];
      const filteredOptions = options.find((option: ICountryControlOptions) => (
        option.value.toString().toLowerCase() === controlValue.code.toString().toLowerCase()));
      optionToEmit.push(filteredOptions);
      setSelectedOption(optionToEmit);
    }
  };

  const handleOnChange = (newValue) => {
    setIsShowCountryCode(true);

    if (newValue.length > 0) {
      return onCountryChange(newValue[newValue.length - 1] as string);
    }

    return (
      (newValue[newValue.length - 1]
        ? setSelectedOption([newValue[newValue.length - 1]])
        : setSelectedOption([]))
    );
  };

  const codeCheck = validateControl(
    value.code,
    [],
    lang.commonOnboardingPhoneVerificationCountry(),
    true,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.countryControl}>
        <div className={styles.phoneControl}>
          <div className={styles.wrapperPhoneControlContent}>
            <div className={styles.flag}>
              <Country id={selectedOption.length > 0
                ? selectedOption[selectedOption.length - 1].countryId
                : controlValue.countryId}
              />
            </div>
            <Autocomplete
              multiple
              autoHighlight
              openOnFocus
              value={selectedOption}
              onChange={(_, newValue) => handleOnChange(newValue)}
              options={options}
              filterOptions={(option: ICountryControlOptions[], state) => {
                const patternOnlyStrings = /[a-zA-Z]/;
                if (patternOnlyStrings.test(state.inputValue)) {
                  return filterOptionsLetters(option, state);
                }
                return filterOptionsNumbers(option, state);
              }}
              onOpen={() => setIsShowCountryCode(false)}
              PaperComponent={({ children }) => <Paper className={styles.paper}>{children}</Paper>}
              renderOption={(option) =>
                <Country id={option.countryId ?? option.value as string} phoneCode={option.value} />}
              getOptionLabel={(option) => option.value}
              getOptionDisabled={(option) => {
                if (selectedOption.length > 0 && selectedOption[0].countryId === option.countryId) {
                  return true;
                }
                return false;
              }}
              onBlur={handleClickOutside}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const countryCode = option.value.replace(/[^+0-9]/g, '');
                  return (
                    <Chip
                      id="countryCode"
                      aria-label="country code"
                      key={countryCode}
                      deleteIcon={<></>}
                      label={countryCode}
                      {...getTagProps({ index })}
                      onDelete={() => {}}
                    />
                  );
                })}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  className={isShowCountryCode ? styles.showInputControlCountry : styles.hideInputControlCountry}
                  label={lang.commonOnboardingPersonalDetailsCountry()}
                />
              )}
            />
          </div>
          {(isClickedBtnContinue && codeCheck.errors.length > 0) && (
            codeCheck.errors.map((error: ControlError) =>
              <p key={error.id} className={styles.error}>{error.error}</p>)
          )}
        </div>
      </div>
      <div className={styles.inputControl}>
        <InputControl
          name=""
          placeholder=""
          id="phoneNumber"
          ariaLabel="phone number"
          value={controlValue.phone}
          onChange={onPhoneNumberChange}
          type={InputFieldTypesEnum.Number}
          label={lang.commonOnboardingPhoneVerificationPhoneNumber()}
          errors={isClickedBtnContinue ? getPhoneNumberValidity(controlValue.phone).errors : []}
        />
      </div>
    </div>
  );
};

export default PhoneAutocomplete;
