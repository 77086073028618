import React, { Dispatch, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { loadDiscoverChartData } from '../../../lib/store/common-actions';
import { getNewStocks as getNewOnTheMarket } from '../../../lib/store/fundamental';
import { RootState } from '../../../lib/store/index';

import { MARKET_SYMBOLS_TABLE_HEADERS } from '../../../util/constants';
import BaseTable from '../../BaseTable/BaseTable';
import MarketTableItem from '../../MarketTableItem/MarketTableItem';
import Preloader from '../../Preloader/Preloader';

import styles from '../NewOnMarketList.module.scss';

const NewOnMarketTable = ({ companyLogoAndName, newStocks, getChartData, getNewStocks }: ReduxProps) => {
  const tableHeaders = useMemo(
    () => MARKET_SYMBOLS_TABLE_HEADERS.map((item: string) => <th key={item}>{item}</th>), [],
  );

  useEffect(() => {
    if (!newStocks.length) {
      getNewStocks();
    } else {
      const newOnTheMarketSymbols = newStocks.map(el => el.symbol).join(',');
      getChartData(newOnTheMarketSymbols);
    }
  }, [getChartData, getNewStocks, newStocks]);

  return (
    <>
      <header className={styles.title}>
        <h6>{lang.commonDiscoverSubtitleNewOnTheMarket()}</h6>
        <span>{lang.commonDiscoverSubtitleNewOnTheMarketPastDays()}</span>
      </header>
      {newStocks.length > 0
        ? (
          <BaseTable>
            <thead><tr>{tableHeaders}</tr></thead>
            <tbody>
              {
                newStocks.map(({ symbol }) => (
                  <MarketTableItem
                    key={symbol}
                    symbol={symbol}
                    logo={companyLogoAndName[symbol]?.logo}
                    name={companyLogoAndName[symbol]?.name}
                  />
                ))
              }
            </tbody>
          </BaseTable>
        )
        : (
          <Preloader isFullScreen text="" owner="New Stocks Table" />
        )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  newStocks: state.fundamental.newStocks,
  companyLogoAndName: state.fundamental.companyLogoAndName,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChartData: (symbols: string) => dispatch(loadDiscoverChartData(symbols)),
  getNewStocks: () => dispatch(getNewOnTheMarket()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NewOnMarketTable);
