import { useEffect, useState } from 'react';

const useBrowserWidth = () => {
  const [browserWidthSize, setBrowserWidthSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const updateWidthBrowser = () => {
      setBrowserWidthSize(window.innerWidth);
    };

    window.addEventListener('resize', updateWidthBrowser);
    return () => window.removeEventListener('resize', updateWidthBrowser);
  }, []);

  return { browserWidthSize };
};

export default useBrowserWidth;
