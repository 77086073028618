export enum FinancialQuestionnaireTypesEnum {
  XENW = 'XENW',
  ELNW = 'ELNW',
  XANI = 'XANI',
  UNSP = 'UNSP',
  TEWS = 'TEWS',
  ATPY = 'ATPY',
  AVPY = 'AVPY',
  TKLP = 'TKLP',
  AVFM = 'AVFM',
  POWL = 'POWL',
  AITI = 'AITI',

  /**
   * Custom property
   * for grouping purpose only
   */

  GroupedQuestion = 'GroupedQuestion',
  SourceOfFund = 'SourceOfFund',
  PurposeOfTrading = 'PurposeOfTrading',
  EmploymentStatus = 'EmploymentStatus',

  /** ***** */
}
