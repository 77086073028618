import React from 'react';
import { Typography } from '@material-ui/core';

import { News } from '../../../lib/models/news';
import { getTimeDiffFromNow } from '../../../lib/util/DataHelpers';

import { useIsMobile } from '../../../hooks';

import styles from './NewsCard.module.scss';

type Props = {
  item: News;
};

const NewsCard = ({ item }: Props): JSX.Element => {
  const { headline, source, dateTime, image, url, summary } = item;
  const isMobile = useIsMobile();

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div
        className={styles.card}
        style={isMobile ? { width: `${window.innerWidth - 40}px` } : {}}
      >
        <div className={styles.wrapperImg}>
          <div className={styles.img} style={{ backgroundImage: `url(${image})` }} />
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.meta}>
            {source}
            <span>{getTimeDiffFromNow(dateTime)}</span>
          </p>
          <Typography noWrap className={isMobile ? styles.titleMobile : styles.title}>{headline}</Typography>
          <Typography noWrap className={isMobile ? styles.descriptionMobile : styles.description}>{summary}</Typography>
        </div>
      </div>
    </a>
  );
};

export default NewsCard;
