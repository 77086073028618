import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { BaseSymbolData } from '../../../lib/models/fundamental/types';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { calculatePriceMovement, roundToCurrencyFormat } from '../../../lib/util/lastPriceChangeHelpers';
import { INVALID_VALUE, PriceMovement } from '../../../lib/util/types';

import { ChartLine, CompanyLogo } from '../../../components';
import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';

import styles from './PopularGroupTable.module.scss';

type Props = {
    item: BaseSymbolData;
}

let priceMovement: PriceMovement;

const PopularGroupTableItem = ({ item }: Props) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const {
    direction, change, changePercentWithSign, currentPrice,
  } = TradePriceCache.use(item.symbol, 'PopularGroupTableItem');

  priceMovement = calculatePriceMovement(currentPrice, item.symbol);

  function handleOnClick() {
    history.push(`${UI_ROUTES.symbol}/${item.symbol}`);
  }

  return (
    <tr className={styles.wrapperRow} onClick={handleOnClick}>
      {
            isMobile ? (
              <>
                <td
                  className={
                      classNames(
                        styles.columnSymbolName,
                        styles.columnSymbolNameWidthMobile,
                      )
                    }
                >
                  <div className={styles.wrapperContent}>
                    <div className={styles.logo}>
                      <CompanyLogo logo={item.logoBase64 || ''} className={styles.logo} symbol={item.symbol} />
                    </div>
                    <div className={styles.companyInfo}>
                      <span className={styles.symbol}>{item.symbol}</span>
                      <span className={styles.companyName}>
                        {item.companyName && item.companyName.length > 12
                          ? `${item.companyName.slice(0, 12)}...`
                          : item.companyName}
                      </span>
                    </div>
                  </div>
                </td>
                <td className={styles.columnChartMobile}><ChartLine symbol={item.symbol} /></td>
                <td className={styles.columnLastPriceMobile}>
                  <div>
                    {formatCurrency(roundToCurrencyFormat(currentPrice || 0))}
                  </div>
                  <div className={
                    classNames(
                      styles.columnPercentChange,
                      { [styles.columnPercentChangeMob]: isMobile },
                    )
                  }
                  >
                    {
                      changePercentWithSign
                        ? (
                          <span className={direction === '-'
                            ? styles.priceTextMinus
                            : styles.priceTextPlus}
                          >
                            {changePercentWithSign}
                          </span>
                        )
                        : INVALID_VALUE
                    }
                  </div>
                </td>
              </>
            ) : (
              <>
                <td className={styles.columnSymbolName}>
                  <div className={styles.wrapperContent}>
                    <div className={styles.logoWrapper}>
                      <CompanyLogo logo={item.logoBase64 || ''} symbol={item.symbol} />
                    </div>
                    <div className={styles.companyInfo}>
                      <span className={styles.symbol}>{item.symbol}</span>
                      <span className={styles.companyName}>
                        {item.companyName}
                      </span>
                    </div>
                  </div>
                </td>
                <td className={styles.columnChart}><ChartLine symbol={item.symbol} /></td>
                <td className={styles.columnLastPrice}>
                  <span className={classNames(styles.currentPrice, {
                    [styles.negativeChange]: priceMovement === 'down',
                    [styles.positiveChange]: priceMovement === 'up',
                  })}
                  >
                    {formatCurrency(currentPrice)}
                  </span>
                </td>
                <td className={styles.columnChange}>
                  {change
                    ? (
                      <span className={direction === '-' ? styles.priceTextMinus : styles.priceTextPlus}>
                        {formatCurrency(change)}
                      </span>
                    )
                    : INVALID_VALUE }
                </td>
                <td className={styles.columnPercentChange}>
                  {changePercentWithSign
                    ? (
                      <span className={direction === '-' ? styles.priceTextMinus : styles.priceTextPlus}>
                        {changePercentWithSign}
                      </span>
                    )
                    : INVALID_VALUE}
                </td>
              </>
            )
        }
    </tr>
  );
};

export default PopularGroupTableItem;
