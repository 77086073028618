import { useCallback, useEffect, useRef } from 'react';

import { NullableNumber } from '../types';

/**
 * Creates an interval to execute `callback` with the given `delay`.
 * The interval is cleared if `delay` is reset to null value.
 */
export const useInterval = (callback: () => void, delay: number | null, owner?: string) => {
  const savedCallback = useRef<() => void>(() => {});
  const id = useRef<NodeJS.Timeout | null>(null);

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [ callback ]);

  // Set up the interval.
  useEffect(() => {
    // early return if neither is defined - not the delay, nor the id
    if (delay == null && id.current == null) return (() => {});

    const tick = () => {
      savedCallback.current();
    };
    if (id.current) {
      clearInterval(id.current);
      id.current = null;
    }
    if (delay != null) {
      id.current = setInterval(tick, delay) as any;
    }
    return () => { if (id.current) clearInterval(id.current); };
  }, [ delay, owner ]);
};

type Callback = (...params: any[]) => void
type UseTimeoutRef<CallbackType extends Function = Callback> = {
  id?: NodeJS.Timeout | number | null
  func: CallbackType | null
  value?: NullableNumber
}
type CounterCallback = (currentValue: number) => void

export const useCounter = (
  callback: CounterCallback | null,
  delay: NullableNumber,
  initialValue: NullableNumber,
  owner?: string,
) => {
  const ref = useRef<UseTimeoutRef<CounterCallback>>({ func: null, id: null, value: initialValue });
  const clearFunc = useCallback(
    () => {
      if (ref.current.id) {
        clearInterval(ref.current.id as any);
        ref.current.id = null;
      }
    },
    [],
  );

  useEffect(() => {
    ref.current.func = callback;
  }, [ callback, owner ]);

  useEffect(() => {
    const { func, id: intervalId } = ref.current;
    const shouldExecute = initialValue != null && delay != null;
    if (!shouldExecute) return () => {};

    let id: NodeJS.Timeout | number;
    if (delay !== null && initialValue != null) {
      ref.current.value = initialValue;
      const tick = () => {
        func?.(ref.current.value!);
        (ref.current.value!)--; // eslint-disable-line no-plusplus
        if (ref.current.value! < 0) {
          clearFunc();
        }
      };
      if (intervalId) clearFunc();
      id = setInterval(tick, delay!);
      ref.current.id = id;
    }

    return clearFunc;
  }, [ clearFunc, delay, initialValue, owner ]);
};

export const useTimeout = (
  callback: Callback | null,
  delay?: number | null,
  owner?: string,
) => {
  const ref = useRef<UseTimeoutRef>({ id: null, func: null });
  const clearFunc = useCallback(
    () => {
      if (ref.current.id) {
        clearTimeout(ref.current.id as any);
        ref.current.id = null;
        ref.current.func = null;
      }
    },
    [],
  );

  useEffect(() => {
    let id: NodeJS.Timeout;
    const execTimeoutCallback = () => {
      ref.current.func?.();
      ref.current.id = null;
    };

    if (callback && delay) {
      if (ref.current.id) clearFunc();
      id = setTimeout(execTimeoutCallback, delay) as any;
      ref.current.id = id;
      ref.current.func = callback;
    } else {
      clearFunc();
    }

    return clearFunc;
  }, [ callback, clearFunc, delay, owner ]);

  useEffect(() => {
    if ((!callback || !delay) && ref.current.id) {
      clearTimeout(ref.current.id as any);
    }
  }, [ callback, delay ]);

  return ref.current.id;
};
