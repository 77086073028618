import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { PaymentProcessEnum } from '../../../../lib/enums';
import { PaymentMethodEnum } from '../../../../lib/enums/payments/payment-method.enum';
import { PaymentStatusEnum } from '../../../../lib/enums/payments/payment-status.enum';
import lang from '../../../../lib/language';
import { formatTransaction } from '../../../../lib/util/DataHelpers';
import { TextHelpers } from '../../../../lib/util/TextHelpers';

import ArrowBack from '../../../../assets/img/blue_icon-arrow-left.svg';
import { Button, ClickableImage } from '../../../../components';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';

import AdjustmentModal from './AdjustmentModal';
import BankTransferModal from './BankTransferModal';
import CardTransferModal from './CardTransferModal';

import styles from './BasicModal.module.scss';

type Props = {
    handleModal: () => void;
    infoModal: any;
}

const BasicModal = ({ handleModal, infoModal }: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    payment_method,
    paymentStatus,
    hasLinkedAccounts,
    bankAccountStatusTitle,
    bankAccountStatusMessage,
    isPendingLinkedAccounts,
    paymentProcess,
    payment,
  } = infoModal.values;

  if (!hasLinkedAccounts) {
    const handleBtnAddAccount = () => {
      history.push({
        pathname: UI_ROUTES.funds + UI_ROUTES.addYourBankAccount,
        state: {
          previousPage: UI_ROUTES.funds },
      });
    };
    return (
      <div className={styles.wrapperBankAccountStatus}>
        <h6 className={styles.title}>{bankAccountStatusTitle}</h6>
        <span className={styles.message}>{bankAccountStatusMessage}</span>
        <Button
          variant={!isPendingLinkedAccounts ? ButtonTypesEnum.Tertiary : undefined}
          fullWidth
          className={classNames(styles.btnOkay,
            { [styles.btnCancel]: !isPendingLinkedAccounts })}
          onClick={() => handleModal()}
        >
          {isPendingLinkedAccounts ? lang.commonOkay() : lang.commonCancelButton()}
        </Button>
        {
          !isPendingLinkedAccounts && (
            <Button
              fullWidth
              onClick={handleBtnAddAccount}
            >
              {lang.mobileFundsScreenAddBankAccModalBtn()}
            </Button>
          )
        }
      </div>
    );
  }

  return (
    <div className={styles.wrapperModal}>
      { isMobile && (
        <ClickableImage
          className={styles.returnArrow}
          src={ArrowBack}
          alt="Back"
          onClick={() => handleModal()}
        />
      )}
      <h6 className={styles.title}>{ formatTransaction(payment, 'amount') }</h6>
      <div className={styles.wrapperDate}>
        <span className={styles.date}>{ formatTransaction(payment, 'date') }</span>
        {paymentStatus === PaymentStatusEnum.Completed
          && <span className={styles.statusCompleted}>{TextHelpers.getPaymentStatusName(paymentStatus)}</span>}
        {paymentStatus === PaymentStatusEnum.ForApproval
          && <span className={styles.statusPending}>{lang.commonOrderStatusPending()}</span>}
        {paymentStatus === PaymentStatusEnum.Rejected
          && <span className={styles.statusError}>{TextHelpers.getPaymentStatusName(paymentStatus)}</span>}
        {paymentStatus === PaymentStatusEnum.Canceled
          && <span className={styles.statusError}>{TextHelpers.getPaymentStatusName(paymentStatus)}</span>}
      </div>
      { payment_method === PaymentMethodEnum.BankTransfer && <BankTransferModal infoModal={infoModal.values} /> }
      { payment_method === PaymentMethodEnum.Card && <CardTransferModal infoModal={infoModal.values} /> }
      { paymentProcess === PaymentProcessEnum.Adjustment && <AdjustmentModal infoModal={infoModal.values} /> }
      { !isMobile && (
        <Button
          fullWidth
          id="closeModal"
          ariaLabel="close modal"
          className={styles.btnClose}
          onClick={() => handleModal()}
        >
          {lang.commonClose()}
        </Button>
      )}
    </div>
  );
};

export default BasicModal;
