import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { MarketState, MarketStateInstrumentSnapshot } from '../models/market-data/types';
import { calculateMarketState, convertToMarketState } from '../util/MarketDataHelpers';


export const DEBUG_MARKET_STATE = {
  active: false,
  enable: (marketState: MarketStateInstrumentSnapshot = MarketStateInstrumentSnapshot.IS_RegularHours) => {
    setTimeout(() => {
      DEBUG_MARKET_STATE.active = true;
      _setMarketStateCache_InUnitTestsAndMockedMode(marketState);
    }, 10);
  },
};

type MarketStateCacheData = {
  marketState: MarketState | null
  marketStateInstrumentSnapshot: MarketStateInstrumentSnapshot | null
}

const calculateMarketStateCache = (): MarketStateCacheData => {
  const marketStateInstrumentSnapshot = calculateMarketState();
  const marketState = convertToMarketState(marketStateInstrumentSnapshot);
  if (!marketState) {
    console.error(`[MarketStateCache] calculateMarketStateCache - could not calculate MarketState ${marketState}`);
  }
  if (!marketStateInstrumentSnapshot) {
    console.error(`[MarketStateCache] calculateMarketStateCache - could not calculate MarketState for Instrument Snapshot ${marketStateInstrumentSnapshot}`);
  }

  return { marketState, marketStateInstrumentSnapshot };
};

const MarketStateCache = entity(calculateMarketStateCache(), entityReduxLogger('MarketState', 'market-state'));

/**
 * Recalculates state and returns its new value.
 * If `isDebug` is `true` - does not recalculate and returns old state (see `refreshMarketStateCache` code below).
 */
const refreshMarketStateCache = () => {
  const { __DEV__: isDev } = require('../../configLib').default;
  const isDebug = (isDev && DEBUG_MARKET_STATE.active);
  const newState = calculateMarketStateCache();
  let oldState = {} as MarketStateCacheData;

  MarketStateCache.set(
    // eslint-disable-next-line consistent-return
    produce(state => {
      if (isDebug) {
        oldState = cloneDeep(state);
        return state;
      }

      /* eslint-disable no-param-reassign */
      const { marketState, marketStateInstrumentSnapshot } = newState;
      if (marketStateInstrumentSnapshot !== state.marketStateInstrumentSnapshot) {
        state.marketState = marketState;
        state.marketStateInstrumentSnapshot = marketStateInstrumentSnapshot;
      }
      /* eslint-enable no-param-reassign */
    }),
  );

  return isDebug ? oldState : newState;
};

// exporting setter only for unit testing and mocked mode
export const _setMarketStateCache_InUnitTestsAndMockedMode = ( // eslint-disable-line no-underscore-dangle
  marketState: MarketStateInstrumentSnapshot,
  marketStateInstrumentSnapshot?: MarketStateInstrumentSnapshot,
) => {
  MarketStateCache.set({
    marketState: convertToMarketState(marketState),
    marketStateInstrumentSnapshot: marketStateInstrumentSnapshot ?? marketState,
  });
};

// hiding direct setter by not including it in exports
export default {
  refresh: refreshMarketStateCache,
  /**
   * Hook for subscribing to state changes
   */
  use: MarketStateCache.use,
  /**
   * Get current state as is (not updating it)
   */
  get: MarketStateCache.get,
};
