import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { FileMimeTypeEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { TEN_MB } from '../../lib/libSettings';
import { generatingNumberFromBytes, getDataFromBase64DataUrl } from '../../lib/util/DataHelpers';

import ClickableDiv from '../ClickableDiv/ClickableDiv';

import DocumentPreview from './components/DocumentsPreview';
import NullableDocumentContainer from './components/NullableDocumentContainer';
import { IUploadFileData } from './Document.types';

import styles from './Document.module.scss';

type Props = {
  dataUrl?: string;
  visibleHint?: boolean;
  height?: number;
  disabledUpload?: boolean;
  errorMessage?: string;
  handleUploadDocument?: Dispatch<SetStateAction<string>>;
  handleUploadFile?: Dispatch<SetStateAction<IUploadFileData>>;
  handleUploadFileAsFileFormat?: Dispatch<SetStateAction<File | undefined>>;
  forceUpdate?: boolean;
  idInput?: string;
  idRemove?: string;
  ariaLabelInput?: string;
  ariaLabelRemove?: string;
};

const Document = (
  {
    dataUrl,
    visibleHint,
    height,
    disabledUpload = false,
    errorMessage,
    idInput,
    idRemove,
    ariaLabelInput,
    ariaLabelRemove,
    handleUploadFile,
    handleUploadDocument,
    handleUploadFileAsFileFormat,
    forceUpdate = false,
  }: Props,
) => {
  const [filePreview, setFilePreview] = useState<string>(!dataUrl ? '' : dataUrl);
  const [fileError, setFileError] = useState<string>(!errorMessage ? '' : errorMessage);

  useEffect(() => () => {
    setFilePreview('');
    setFileError('');
  }, [forceUpdate]);

  const onDrop = useCallback((files: File[]): void => {
    if (files[0]) {
      const file = files[0];
      const allowedFileTypes = [FileMimeTypeEnum.Jpeg, FileMimeTypeEnum.Jpg, FileMimeTypeEnum.Png] as string[];

      if (allowedFileTypes.indexOf(file.type) < 0) {
        setFileError(lang.commonVerificationUploadFileWrongFormatMessage());
      } else if (generatingNumberFromBytes(file.size) > TEN_MB) {
        setFileError(lang.commonVerificationUploadFileSizeExceededMessage());
      } else {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          const base64DataUrl = fileReader.result as string;
          const base64Data = getDataFromBase64DataUrl(base64DataUrl, file.type);
          if (handleUploadFile) {
            handleUploadFile({ fileName: file.name, base64Data });
          }
          if (handleUploadFileAsFileFormat) {
            handleUploadFileAsFileFormat(file);
          }
          if (handleUploadDocument) {
            handleUploadDocument(base64Data);
          }

          setFilePreview(base64DataUrl);
          setFileError('');
        };
      }
    }
  }, [handleUploadFile, handleUploadFileAsFileFormat, handleUploadDocument]);

  const removeDocument = useCallback(() => {
    setFilePreview('');
    setFileError('');

    handleUploadDocument && handleUploadDocument('');
    handleUploadFile && handleUploadFile({ fileName: '', base64Data: '' });
  }, [handleUploadFile, handleUploadDocument]);

  useEffect(() => {
    setFilePreview(!dataUrl ? '' : dataUrl);
    setFileError(!errorMessage ? '' : errorMessage);

    return () => {
      setFilePreview('');
      setFileError('');
    };
  }, [dataUrl, errorMessage]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
    disabled: disabledUpload,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper} {...getRootProps()}>
        <input
          id={idInput}
          aria-label={ariaLabelInput}
          className={styles.inputFile}
          disabled={filePreview.trim() !== '' || disabledUpload}
          type="file"
          {...getInputProps()}
        />
        <div style={{ height: !height ? 145 : height }}>
          {(filePreview.trim() !== '') ? <DocumentPreview filePreview={filePreview} /> : <NullableDocumentContainer />}
        </div>
      </div>

      {((filePreview.trim() !== '') && !disabledUpload) && (
        <ClickableDiv
          id={idRemove}
          aria-label={ariaLabelRemove}
          className={styles.removeButton}
          onClick={() => removeDocument()}
        >
          <i className="fas fa-times" />
        </ClickableDiv>
      )}

      {(fileError && (fileError.trim() !== '')) && <p className={styles.error}>{fileError}</p>}

      {visibleHint && <p className={styles.hint}>*allowed file-types are jpg. , png. , pdf.</p>}
    </div>
  );
};

export default Document;
