import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import lang from '../../../lib/language';
import { sendResetPassword } from '../../../lib/store/auth';

import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { Button } from '../../../components/index';
import { UI_ROUTES } from '../../../util/routes';

import styles from './ChangePassword.module.scss';

type Props = {
    handleModal: () => void;
}

const ChangePasswordModal = ({ handleModal }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleProceed = () => {
    dispatch(sendResetPassword({}));
    history.push(UI_ROUTES.myAccountChangePassword);
    handleModal();
  };

  return (
    <>
      <div className={styles.title}>{lang.mobileChangePasswordModalText()}</div>
      <Button
        variant={ButtonTypesEnum.Tertiary}
        fullWidth
        className={styles.btnCancel}
        onClick={() => handleModal()}
      >
        {lang.commonCancelButton()}
      </Button>
      <Button fullWidth onClick={handleProceed} className={styles.buttonConfirm}>
        {lang.commonOrderSideButtonProceed() }
      </Button>
    </>
  );
};

export default ChangePasswordModal;
