import React, { useState } from 'react';
import classNames from 'classnames';

import lang from '../../lib/language';

import SearchIcon from '../../assets/img/icon-search-dark.svg';

import styles from './Search.module.scss';

type Props = {
  searchStocks: (val: string) => void;
}

const Search = ({ searchStocks }: Props) => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  return (
    <div className={classNames(styles.searchWrapper, { [styles.focused]: isInputFocused })}>
      <div
        className={styles.fieldWrapper}
        onBlur={() => setIsInputFocused(false)}
        onFocus={() => setIsInputFocused(true)}
      >
        <div className={styles.iconSearch}>
          <img src={SearchIcon} alt="search stocks" />
        </div>
        <div className={styles.inputWrapper}>
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={styles.input}
            placeholder={lang.commonSearchForStocks()}
            type="search"
            onChange={(val) => searchStocks(val.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
