import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { data, options, setTextInChart } from './TotalValueBreakdown.constans';

import styles from './TotalValueBreakdown.module.scss';

const { Chart } = require('react-chartjs-2');

Chart.pluginService.register({
  beforeDraw: setTextInChart,
});

function TotalValueBreakdown() {
  return (
    <div className="mt-4 mb-2">
      <h2 className={styles.text}>Total Value Breakdown</h2>
      <div className={styles.content}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}

export default TotalValueBreakdown;
