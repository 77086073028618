import { debounce, DebounceSettings, isObject, throttle, ThrottleSettings } from 'lodash';
import { Action, Dispatch } from 'redux';

import { MDSFeed } from '../models/market-data/types';
import { SubscribedComponent } from '../util/types';

type ActionCache = { action: Action, func: () => void, no: number }
const DEFAULT_OPTIONS_DEBOUNCE = { leading: false, trailing: true };
const DEFAULT_OPTIONS_THROTTLE = { leading: true, trailing: false };

export function debounceAction(
  dispatch: Dispatch,
  action: Action,
  debounceTimeMS = 1000,
  options: DebounceSettings = DEFAULT_OPTIONS_DEBOUNCE,
) {
  const cache = debouncedActions[action.type];
  if (cache) debouncedActions[action.type].no++;

  if (!cache) {
    const func = debounce(
      () => { dispatch(debouncedActions[action.type].action); },
      debounceTimeMS,
      options,
    );
    debouncedActions[action.type] = { action, func, no: 0 };
  } else {
    debouncedActions[action.type].action = action;
    debouncedActions[action.type].no++;
  }

  debouncedActions[action.type]?.func();
}
const debouncedActions: Record<string, ActionCache> = { };


export function throttleAction(
  dispatch: Dispatch,
  action: Action,
  throttleTimeMS = 1000,
  options: ThrottleSettings = DEFAULT_OPTIONS_THROTTLE,
) {
  const cache = throttledActions[action.type];
  if (cache) throttledActions[action.type].no++;

  if (!cache) {
    const func = throttle(
      () => { dispatch(throttledActions[action.type].action); },
      throttleTimeMS,
      options,
    );
    throttledActions[action.type] = { action, func, no: 0 };
  } else {
    throttledActions[action.type].action = action;
  }

  throttledActions[action.type]?.func();
}
const throttledActions: Record<string, ActionCache> = { };

type LogConfig = {
  logPrefixDataUpdate: string
  logPrefixStartAndFirstCall: string
  logPrefixExec: string
  logEnabled: boolean
}
export type ThrottleFuncSetup = {
  call: () => void,
  cancel: () => void,
  setData: (data: any) => void,
  getData: () => any,
}
const dataToLog = (data: any) => (isObject(data) ? Object.keys(data) : data);
let throttleFunctionData: Record<string, any> = {};
/**
 * Creates a throttle function setup to use for throttling symbol data updates
 */
export function throttleFunctionSetupForSymbolDataUpdates(
  name: string,
  logPrefixData: LogConfig,
  initialData: any,
  throttleDelayInMS: number,
  throttleOptions: ThrottleSettings,
  functionToCall: (...args: any[]) => void,
) {
  const { logPrefixDataUpdate, logPrefixStartAndFirstCall, logPrefixExec, logEnabled } = logPrefixData;
  throttleFunctionData[name] = initialData;
  const getData = () => throttleFunctionData[name];
  const throttleFunction = throttle(
    () => {
      const data = getData();
      if (logEnabled) console.tron.log!(`${logPrefixExec} - ${dataToLog(data)}`);
      functionToCall(data);
      setData(null);
    },
    throttleDelayInMS,
    throttleOptions,
  );
  const call = (isFirst?: boolean) => {
    const data = getData();
    if (logEnabled && isFirst) console.tron.log!(`${logPrefixStartAndFirstCall} - ${dataToLog(data)}`);
    throttleFunction();
  };
  const cancel = () => {
    throttleFunction.cancel();
    throttleFunctionData[name] = null;
  };
  const setData = (newData: any) => {
    const data = getData();
    if (logEnabled) console.tron.log!(`${logPrefixDataUpdate} (before throttle) - ${dataToLog(data)}`);
    throttleFunctionData[name] = isObject(newData) && newData ? { ...data, ...newData } : newData;
  };

  call(true);

  return {
    call,
    cancel,
    setData,
    getData,
  };
}

export const getFeedByComponent = (currentComponent: SubscribedComponent | undefined): MDSFeed => (currentComponent === 'popularGroup' ? 'trade' : 'trade,nbbo');
