import { MOCK } from '../../configDebug';
import LibraryConfig from '../../configLib';

import en from './en';


let currentLanguage = { ...en };

function normaliseKey(key: string) {
  if (key.indexOf('mobile') === 0) {
    return key.replace('mobile', 'm');
  }

  if (key.indexOf('web') === 0) {
    return key.replace('web', 'w');
  }

  if (key.indexOf('common') === 0) {
    return key.replace('common', 'c');
  }

  return '<unknown>';
}

const langProxyImpl = {
  get(target: any, prop: string, receiver: any) {
    if (LibraryConfig?.__DEV__ && MOCK?.LANG_TEST) return () => `<${normaliseKey(prop)}>`;

    let result = target[prop] as string;
    if (prop.includes('$$typeof') || prop.includes('prototype')) return result;

    if (result == null) {
      console.error(`[LANG] Error getting string for '${prop}'`);
      return 'n/a';
    }

    if (result?.includes('@@')) {
      return (...args: string[]) => {
        let n = 1;
        while (result.includes('@@')) {
          const replacer = new RegExp(`@@${n}`, 'g');
          const newValue = args?.[n - 1] ?? '';
          result = result?.replace(replacer, newValue);
          n++;
        }

        return result;
      };
    }

    return () => result;
  },
};

type LangFunc = (...args: (string | number)[]) => string;
type ChangeTypeOfKeys<Obj, NewType> = { [K in keyof Obj]: NewType };
type LangType = ChangeTypeOfKeys<typeof en, LangFunc>;

const langProxy = new Proxy(currentLanguage, langProxyImpl) as LangType;

export default langProxy;
