import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Drawer, Link, makeStyles } from '@material-ui/core';

import { RootState } from '../../lib/store';
import { isUserVerifiedStatus } from '../../lib/store/selectors';

import config from '../../config';
import { useBrowserWidth } from '../../hooks';
import { UI_ROUTES } from '../../util/routes';
import { ClickableDiv } from '../index';

import PopoverComponent from './Popover/PopoverComponent';
import { MENU_LIST, SUPPORT_LIST } from './Sidebar.constans';
import { SideBarItem } from './Sidebar.types';

import styles from './Sidebar.module.scss';

const MIDDLE_WIDTH = 960;
const LARGE_WIDTH = 1440;

type Props = {} & ReduxProps;

const Sidebar = (props: Props) => {
  const { individual, hasDeposit } = props;
  const { browserWidthSize } = useBrowserWidth();

  const location = useLocation();
  const isUserVerified = isUserVerifiedStatus(individual);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [popoverLabel, setPopoverLabel] = useState<string>('');
  const [popoverStatus, setPopoverStatus] = useState<HTMLDivElement | null>(null);
  const [showBars, setShowBars] = useState<boolean>(false);

  useEffect(() => {
    if (browserWidthSize <= MIDDLE_WIDTH) {
      setIsOpen(false);
      setShowBars(true);
    } else {
      setShowBars(false);
      setIsOpen(true);
    }
  }, [ browserWidthSize]);

  MENU_LIST[3].path = (!isUserVerified || hasDeposit)
    ? UI_ROUTES.funds
    : UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit;

  const handlePopover = (event, item: SideBarItem) => {
    setPopoverLabel(item.label);
    setPopoverStatus(event.currentTarget);
  };

  const isActiveBtn = (item: SideBarItem): boolean => location.pathname.includes(item.path);

  const handleCLickBtn = () => {
    if (browserWidthSize <= MIDDLE_WIDTH) {
      return setIsOpen(false);
    }

    return null;
  };

  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      background: 'transparent',
      border: 'none',
    },
  }));

  const classes = useStyles();

  return (
    <>
      {showBars
      && (
      <ClickableDiv className={styles.btnSideBar} onClick={() => setIsOpen(!isOpen)}>
        <i className="fas fa-bars" />
      </ClickableDiv>
      )}
      {
        isOpen && (
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            transitionDuration={40}
            variant={browserWidthSize > MIDDLE_WIDTH ? 'persistent' : 'temporary'}
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <div className={styles.wrapperSidebar}>
              <div className={styles.wrapperButton}>
                {
                  MENU_LIST.map((item: SideBarItem) => (
                    <NavLink
                      id={item.id}
                      key={item.id}
                      to={{
                        pathname: item.path,
                        state: {
                          isClickedBtnSidebar: true,
                        },
                      }}
                      aria-owns={popoverStatus ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => handlePopover(event, item)}
                      onMouseLeave={() => setPopoverStatus(null)}
                      onClick={handleCLickBtn}
                    >
                      {
                        (browserWidthSize > MIDDLE_WIDTH && browserWidthSize <= LARGE_WIDTH)
                          ? <div className={isActiveBtn(item) ? styles.activeImage : styles.image}>{item.img}</div>
                          : (
                            <div className={styles.button}>
                              <div className={isActiveBtn(item) ? styles.activeBtn : styles.btn}>
                                <div className={styles.imageAndLabel}>{item.img}</div>
                                <span>{item.label}</span>
                              </div>
                            </div>
                          )
                      }
                    </NavLink>
                  ))
              }
              </div>
              <div className={browserWidthSize > MIDDLE_WIDTH && browserWidthSize <= LARGE_WIDTH
                ? styles.shortDivider
                : styles.longDivider}
              />
              <div className={styles.wrapperButton}>
                {
                  SUPPORT_LIST.map((item: SideBarItem) => (
                    <Link
                      key={item.label}
                      target="_blank"
                      href={config.links.helpCenterUrl}
                      className={location.pathname === item.path ? styles.activeButton : styles.button}
                      aria-owns={popoverStatus ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => handlePopover(event, item)}
                      onMouseLeave={() => setPopoverStatus(null)}
                    >
                      {
                        (browserWidthSize > MIDDLE_WIDTH && browserWidthSize <= LARGE_WIDTH)
                          ? <div className={styles.image}>{item.img}</div>
                          : (
                            <div className={styles.button}>
                              <div className={styles.btn}>
                                <div className={styles.imageAndLabel}>{item.img}</div>
                                <span>{item.label}</span>
                              </div>
                            </div>
                          )
                      }
                    </Link>
                  ))
              }
                {
                (browserWidthSize > MIDDLE_WIDTH && browserWidthSize < LARGE_WIDTH) && (
                <PopoverComponent
                  open={Boolean(popoverStatus)}
                  popoverStatus={popoverStatus}
                  handlePopoverClose={() => setPopoverStatus(null)}
                  label={popoverLabel}
                />
                )
              }
              </div>
            </div>
          </Drawer>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
  hasDeposit: state.payment.hasDeposit,
});
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Sidebar);
