import React from 'react';
import classNames from 'classnames';

import { MarketState } from '../../../../lib/models/market-data/types';
import MarketStateCache from '../../../../lib/store-util/MarketStateCache';
import TradePriceCache from '../../../../lib/store-util/TradePriceCache';
import { CalculatedSymbolPrices } from '../../../../lib/util/ChartingHelpers';

import exclamationMark from '../../../../assets/img/icon-exclamation-mark-warning.svg';
import { useIsMobile } from '../../../../hooks';
import { ButtonTypesEnum } from '../../../Button/Button.types';
import { Button } from '../../../index';
import AddToWatchList from '../../../SymbolDetailsHeader/components/AddToWatchList';

import Company from './Components/Company';
import ExtraPriceInfo from './Components/ExtraPriceInfo';
import Logo from './Components/Logo';
import MainPriceInfo from './Components/MainPriceInfo';
import MarketStatusTime from './Components/MarketStatusTime';

import styles from './ChartHeader.module.scss';

type OwnProps = {
  symbol: string,
  logo?: string,
  company?: string,
  isSymbolInFavorites: boolean,
  handleCreateOrderSellOrBuy: (target: string, isHeaderBtn: boolean) => void;
  toggleFavoritesSubscription: () => void,
}
type Props = OwnProps;

const ChartHeader = (props: Props) => {
  const {
    logo,
    symbol,
    company,
    isSymbolInFavorites,
    handleCreateOrderSellOrBuy,
    toggleFavoritesSubscription,
  } = props;
  const isMobile = useIsMobile();
  const { calculatedSymbolPrices } = TradePriceCache.use(symbol!, 'SymbolDetails/ChartHeader');

  const allData = {
    ...(calculatedSymbolPrices ?? {} as CalculatedSymbolPrices),
  };

  const { marketState } = MarketStateCache.use();

  return (
    <>
      {
        ((marketState === MarketState.PreMarket || marketState === MarketState.MarketClosed)) && (
          <div className={classNames(styles.marketStatusDescription, { [styles.mobileInfoMarketTime]: isMobile })}>
            <img id="marketStatusDescriptionAlertIcon" src={exclamationMark} alt="exclamationMark icon" />
            <MarketStatusTime />
          </div>
        )
      }
      <div className={isMobile ? styles.containerLogoAndCompanyMobile : styles.containerLogoAndCompany}>
        <div className={styles.logoAndCompany}>
          <Logo logo={logo} symbol={symbol} />
          <Company company={company} symbol={symbol} />
        </div>
        <Button
          variant={ButtonTypesEnum.Link}
          className={styles.addToWatchlist}
          onClick={() => toggleFavoritesSubscription()}
        >
          <AddToWatchList isAdded={isSymbolInFavorites} />
        </Button>
      </div>
      {/* Line 1 */}
      <MainPriceInfo
        {...allData}
        symbol={symbol}
        companyName={company}
        handleCreateOrderSellOrBuy={handleCreateOrderSellOrBuy}
      />
      {/* Line 2 */}
      {!isMobile && <ExtraPriceInfo {...allData} />}
    </>
  );
};

export default ChartHeader;
