import React from 'react';

import lang from '../../../../../lib/language';
import { useMarketTimer } from '../../../../../lib/util/hooks/MarketTimerHook';

import styles from '../ChartHeader.module.scss';

const MarketStatusTime = () => {
  const { timerValueAsString, isPreMarket, isEnabled } = useMarketTimer('symbol-details');

  if (!isEnabled) return null;

  return (
    <div className={styles.wrapperMarketInfo}>
      {isPreMarket && <span className={styles.marketText}>{lang.commonPreMarketHoursTradingStarting()}</span>}
      {!isPreMarket && <span className={styles.marketText}>{lang.commonMarketClosedHoursTradingStarting()}</span>}
      <span className={styles.marketTimer}>{timerValueAsString}</span>
    </div>
  );
};

export default MarketStatusTime;
