import { ApiOkResponse, ApiResponse } from 'apisauce';
import { isNumber, random } from 'lodash';
import momenttz from 'moment-timezone';

import { logConfig, MOCK } from '../../../configDebug';
import configLib from '../../../configLib';
import {
  ams,
  auth,
  badRequest,
  crm,
  empty,
  file,
  fundamental,
  gateway,
  market,
  news,
  reporting,
  trading,
} from '../../__offline-data__';
import { MARKET_DATA_TIME_ZONE } from '../../constants/date-time.constants';
import { FILLED_ORDER_STATUSES, OrderSideEnum, OrderStatusEnum } from '../../enums';
import { LegalDeclaration } from '../../models/crm/types';
import { MarketState, MarketStateInstrumentSnapshot, OHLCV } from '../../models/market-data/types';
import { Order, Position } from '../../models/trading/types';
import { restResponseWrapper } from '../../util/CommonHelpers';
import { calculateCost } from '../../util/DataHelpers';
import {
  getMarketWorkingHours,
  isDateMarketDataWorkingDay,
  timeAsFloatToMomentSetterParam,
  timeToFloat,
} from '../../util/DateTimeHelpers';
import { calculateMarketState } from '../../util/MarketDataHelpers';
import { MarketDataWorkingHoursAsNumber, NullableNumber, NullableString } from '../../util/types';

export function generateOfflineResponse(path: string): any {
  const [ serviceName, action ] = path.split('/');
  if (
    ((MOCK.RESPONSE_ERROR as any) instanceof RegExp && path.match(MOCK.RESPONSE_ERROR as any))
    || MOCK.RESPONSE_ERROR === true
    || serviceName === 'badRequest'
  ) return badRequest;

  let result = empty;
  let skipEmptyCheck = false;

  switch (serviceName) {
    case 'auth': result = auth[action]; break;
    case 'crm': result = crm[action]; break;
    case 'file': result = file[action]; break;
    case 'trading': result = trading[action]; break;
    case 'market': result = market[action]; break;
    case 'fundamental': result = fundamental[action]; break;
    case 'news': result = news[action]; break;
    case 'gateway': result = gateway[action]; break;
    case 'reporting': result = reporting[action]; break;
    case 'ams': result = reporting[action]; break;

    case 'empty':
      result = empty;
      skipEmptyCheck = true;
      break;

    default:
      console.warn(`[services/offline] generateOfflineResponse - unknown response case '${path}'`);
      break;
  }

  if (result === empty && !skipEmptyCheck) {
    if (MOCK.THROW_ON_ERROR) {
      throw new Error(`[offline-helpers] Path not implemented - '${path}' for service '${serviceName}'. (THROW_ON_ERROR is ON)`);
    } else if (MOCK.ERROR_RESPONSE_IF_NOT_IMPLEMENTED) {
      result = notImplemented(serviceName, action);
    }
  }

  if (isResponseRest(serviceName, action)) {
    result = restResponseWrapper(result);
  }

  if (configLib.__DEV__ && logConfig.enabled && logConfig.gatewayWSMessages) {
    console.debug(`[MOCK] Offline response for '${path}'`, result);
  }
  return result;
}

function notImplemented(name: string, action: string) {
  return errorResponse(name, action, `[offline-helpers] Not implemented - ${name}/${action}`);
}

function errorResponse(serviceName: string, action: string, message: string): any {
  if (isResponseRest(serviceName)) {
    return {
      ok: false,
      problem: true,
      status: 999,
      originalError: null,
      headers: {},
      data: message,
    };
  }
  return {
    rc: 999,
    error: message,
    messageType: action,
  };
}

/**
 * Create a new promise to return as an offline api mock
 * @param path Example: 'crm/accounts'
 * @param delay In seconds, example: 0.1
 * @param rejectPromise If `true` - the promise is always rejected
 * @param customData If set - it is the returned data
 */
export function newPromise(
  path: string,
  delay?: NullableNumber | boolean,
  rejectPromise = false,
  customData = null as any,
) {
  const generatedDelay = isNumber(MOCK.RESPONSE_DELAY) ? MOCK.RESPONSE_DELAY : random(0.1, 0.5);
  const delayInSeconds = !delay ? generatedDelay : delay;
  const delayInMS = 1000 * (delayInSeconds as number);
  const data = customData ?? generateOfflineResponse(path);

  return new Promise((success, reject) => {
    console.info(`[MOCK] Request for %c'${path}'%c with DELAY=${delayInMS.toFixed(0)}ms`, 'font-weight: bold', 'font-weight: normal;');
    console.debug({ delay, path, data });

    if (rejectPromise) {
      reject(new Error(`[MOCK] [${path}] Thrown by newPromise() because of parameter returnError}`));
      return;
    }

    setTimeout(() => {
      if (configLib.__DEV__ && logConfig.enabled && logConfig.gatewayWSMessages) {
        console.debug(`[MOCK] Response SUCCESS of '${path}'`, { delay, path, responseData: data });
      }

      success(data);
    }, delayInMS);
  });
}

export function newPromiseForData<T>(
  data: T,
  delay?: NullableNumber | boolean,
  rejectPromise = false,
) {
  const generatedDelay = isNumber(MOCK.RESPONSE_DELAY) ? MOCK.RESPONSE_DELAY : random(0.1, 0.5);
  const delayInSeconds = !delay ? generatedDelay : delay;
  const delayInMS = 1000 * (delayInSeconds as number);
  return new Promise<ApiResponse<T>>((success, reject) => {
    console.info(`[MOCK] Request with DELAY=${delayInMS.toFixed(0)}ms`);
    // console.debug({ delay, data });
    if (rejectPromise) {
      reject(new Error('[MOCK] Thrown by newPromise() because of parameter returnError}'));
      return;
    }
    setTimeout(() => {
      console.debug('[MOCK] Response SUCCESS');
      // console.debug('[MOCK] Response SUCCESS ', { delay, responseData: data });
      success({
        ok: true,
        problem: null,
        originalError: null,
        data,
        status: 200,
      });
    }, delayInMS);
  });
}

export function isResponseRest(serviceName: string, action?: string) {
  if (serviceName === 'auth' && ([ 'authorize', 'refresh' ] as any).includes(action)) return false;

  return (serviceName.match('trading|market|gateway') == null);
}

/**
 * Calculates delay based on MOCK.RESPONSE_DELAY
 */
export function calculateDelay() {
  if (typeof MOCK.RESPONSE_DELAY !== 'number') return random(100, 500);

  return MOCK.RESPONSE_DELAY * 1000;
}

export type OMSState = {
  position: Position | null
  urpnl: number
  rpnl: number
  posExp: number
  ordExp: number
}

/**
 * Calculates position -> qty and avgPrice.
 * For short sell -> qty * offerPrice
 * For close buy -> qty * offerPrice
 */
export function calculateOMSState(
  symbol: NullableString,
  orders: Order[],
  bidPrice: number,
  askPrice: number,
  tradePrice: number,
): OMSState | null {
  if (orders.length === 0) return null;

  let rpnl = 0;
  let urpnl = 0;
  let total = 0;
  let qtyTotal = 0;
  let ordExp = 0;
  let openSide: OrderSideEnum | null = null;


  // TODO: Check calculations for rpnl, urpnl, ordExp - they are most probably not correct or not full
  orders.filter(item => item.symbol === symbol).forEach(order => {
    const {
      status, side, orderQty, price, stopPrice, fills, filledSoFar = 0, ordType,
    } = order;
    let offerPrice = side === OrderSideEnum.Buy ? askPrice : bidPrice;
    let orderPrice = stopPrice ?? price!;
    let currentQty = 0;
    let avgPrice = qtyTotal !== 0 && total > 0 ? total / Math.abs(qtyTotal) : 0;

    if (side === OrderSideEnum.Buy && qtyTotal >= 0) {
      // open buy
      if (status === OrderStatusEnum.Filled) {
        currentQty = orderQty!;
        if (!openSide) openSide = side;
      } else if (status === OrderStatusEnum.PartiallyFilled) {
        currentQty = filledSoFar!;
        if (!openSide) openSide = side;
      }
      ordExp += currentQty * orderPrice;
      total += calculateCost(order, currentQty)!;
      qtyTotal += currentQty;
    } else if (side === OrderSideEnum.Sell && qtyTotal > 0) {
      // close sell
      if (status === OrderStatusEnum.Filled) {
        currentQty = orderQty!;
      } else if (status === OrderStatusEnum.PartiallyFilled) {
        currentQty = filledSoFar!;
      }
      if (fills) {
        fills.forEach(({ fillQty, fillPrice }) => {
          total -= fillQty * avgPrice;
          rpnl += fillQty * (avgPrice - fillPrice);
          ordExp -= fillQty * orderPrice;
        });
      } else {
        total -= avgPrice * currentQty!;
        rpnl += currentQty * (avgPrice - orderPrice);
      }
      qtyTotal -= currentQty;
    } else if (side === OrderSideEnum.SellShort && qtyTotal <= 0) {
      // short sell
      if (status === OrderStatusEnum.Filled) {
        currentQty = -orderQty!;
        if (!openSide) openSide = side;
      } else if (status === OrderStatusEnum.PartiallyFilled) {
        currentQty = -filledSoFar!;
        if (!openSide) openSide = side;
      }
      total += Math.abs(currentQty)! * orderPrice;
      qtyTotal += currentQty;
      ordExp += Math.abs(currentQty)! * (2 * offerPrice - orderPrice);
    } else if (side === OrderSideEnum.Buy && qtyTotal < 0) {
      // close buy
      if (status === OrderStatusEnum.Filled) {
        currentQty = orderQty!;
      } else if (status === OrderStatusEnum.PartiallyFilled) {
        currentQty = filledSoFar!;
      }
      if (fills) {
        fills.forEach(({ fillQty, fillPrice }) => {
          total -= fillQty * avgPrice;
          rpnl += fillQty * (avgPrice - fillPrice);
        });
      } else {
        total -= orderQty! * avgPrice;
        rpnl += currentQty * (avgPrice - orderPrice);
      }
      qtyTotal += currentQty;
    } else {
      console.error(`[DataHelpers] Invalid case with calculateOMSState:
      [ORDER] qty='${orderQty!}' side='${side}' type='${ordType}'symbol='${symbol}'
      [Position] qty='${qtyTotal!}' total='${total}' avgPrice='${total / qtyTotal}'
      `);
    }
  });
  let position: Position | null = (
    qtyTotal !== 0
      ? {
        symbol: symbol!,
        qty: qtyTotal,
        avgPrice: total / Math.abs(qtyTotal),
      }
      : null
  );

  // OMS Production env behaviour - shows position even if order is closed
  if (!position) {
    const hasFilledOrders = orders.find(item => (
      item.symbol === symbol
      && FILLED_ORDER_STATUSES.includes(item.status as OrderStatusEnum)
    )) != null;
    if (hasFilledOrders) {
      position = {
        symbol: symbol!,
        qty: 0,
        avgPrice: 0,
      };
    }
  }

  if (position && position.qty !== 0) {
    const { avgPrice, qty } = position;
    if (qty > 0 && openSide === OrderSideEnum.Buy) {
      urpnl += qty * (tradePrice - avgPrice);
    } else if (qty < 0 && openSide === OrderSideEnum.SellShort) {
      urpnl -= qty * (avgPrice - tradePrice);
    }
  }

  return {
    position,
    rpnl,
    urpnl,
    posExp: qtyTotal * tradePrice,
    ordExp,
  };
}

function generateFromRange(range: number[]) {
  return random(range[0], range[1]);
}

// TODO: Update when market closed hours merged ART-755
export function generateOHLCVSequence(
  symbol: string,
  openRange: number[],
  highRange: number[],
  lowRange: number[],
  closeRange: number[],
  volRange: number[],
  count = 10000,
  endDateInUTC = '2021-10-26',
  inMinutes = false,
) {
  const genItem = (date: string, time = '00:00:00.000') => ({
    date,
    time,
    sym: symbol,
    open: generateFromRange(openRange),
    high: generateFromRange(highRange),
    low: generateFromRange(lowRange),
    close: generateFromRange(closeRange),
    volume: generateFromRange(volRange),
  });

  let result: OHLCV[] = [];

  let currentOHLCVDate = momenttz.utc(endDateInUTC).tz(MARKET_DATA_TIME_ZONE);
  for (let index = 0; index < count; index++) {
    while (!isDateMarketDataWorkingDay(currentOHLCVDate.format('YYYY-MM-DD'))) {
      currentOHLCVDate.subtract(1, 'day');
    }
    if (inMinutes) {
      if (calculateMarketState(currentOHLCVDate.utc().format()) !== MarketStateInstrumentSnapshot.IS_RegularHours) {
        if (isDateMarketDataWorkingDay(currentOHLCVDate)) {
          const {
            startTime, endTime,
          } = getMarketWorkingHours(currentOHLCVDate, false, false) as MarketDataWorkingHoursAsNumber;
          const timeAsFloat = timeToFloat(currentOHLCVDate) as number;
          if (timeAsFloat < startTime) {
            currentOHLCVDate.subtract(1, 'day');
            currentOHLCVDate.set(timeAsFloatToMomentSetterParam(endTime));
          } else {
            currentOHLCVDate.set(timeAsFloatToMomentSetterParam(endTime));
          }
        }
        currentOHLCVDate.subtract(1, 'day');
        const { endTime } = getMarketWorkingHours(currentOHLCVDate, true);
        currentOHLCVDate = momenttz.tz(`${currentOHLCVDate.format('YYYY-MM-DD')} ${endTime}`, MARKET_DATA_TIME_ZONE);
      }
    }
    result.unshift(genItem(currentOHLCVDate.format('YYYY-MM-DD'), currentOHLCVDate.format('HH:mm:ss.000')));
    currentOHLCVDate.subtract(1, inMinutes ? 'minute' : 'day');
  }

  return result;
}

export const getLegalDeclarationsCommon = (active: boolean | undefined): Promise<ApiResponse<LegalDeclaration[]>> => {
  if (active === undefined) {
    let data: LegalDeclaration[] = require('../../__offline-data__/crm/legalDeclarations/all.json');
    return newPromiseForData(data, 1);
  }
  let data: LegalDeclaration[] = active
    ? require('../../__offline-data__/crm/legalDeclarations/active.json')
    : require('../../__offline-data__/crm/legalDeclarations/notActive.json');
  return newPromiseForData(data, 1);
};

export const getOkResponse = (data: string): ApiOkResponse<string> => ({
  ok: true,
  problem: null,
  originalError: null,
  data,
  status: 200,
});
