/**
 * Used by components that need to show the market timer, initially these two:
 *    - symbol-details
 *    - short-sell
 *
 * See also `useMarketTimer` hook in `MarketTimerHook.ts`.
 */
import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { MarketTimerMode } from '../util/hooks/types';
import { NullableNumber, NullableString } from '../util/types';

export const TIMER_INITIAL_STATE = {
  timerValue: null as NullableNumber,
  timerValueAsString: null as NullableString,
  isEnabled: false,
  isHidden: false,
  isPreMarket: false,
};
export type MarketTimerStateItem = typeof TIMER_INITIAL_STATE;
type MarketTimerState = Partial<Record<NonNullable<MarketTimerMode>, MarketTimerStateItem>>

const MarketTimerCache = entity({} as MarketTimerState, entityReduxLogger('MarketTimer', 'market-timer'));

export const modesEnabled: NonNullable<MarketTimerMode>[] = [];

export const setTimerState = (
  mode: MarketTimerMode,
  timerValue: NullableNumber,
  timerValueAsString: NullableString,
  isPreMarket = false,
  isEnabled = true,
  isHidden = false,
) => {
  if (!mode) return;
  MarketTimerCache.set(produce(state => {
    state[mode] = {
      timerValue,
      timerValueAsString,
      isEnabled,
      isHidden,
      isPreMarket,
    };
  }));
};

const useData = (mode: MarketTimerMode) => {
  const result = MarketTimerCache.use(data => {
    if (!mode || !data[mode]) return TIMER_INITIAL_STATE;
    return data[mode];
  }) ?? TIMER_INITIAL_STATE;
  return result;
};
const getData = (mode: MarketTimerMode) => {
  const state = MarketTimerCache.get();
  if (!mode || !state[mode]) return TIMER_INITIAL_STATE;
  return state[mode];
};

export default {
  get: getData,
  use: useData,
};
