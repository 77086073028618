import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import lang from '../../lib/language';
import { NewStockData } from '../../lib/models/fundamental/types';
import { RootState } from '../../lib/store';
import { getNewStocks as getNewOnTheMarket } from '../../lib/store/fundamental';
import { FundamentalCall } from '../../lib/store/fundamental/types';
import { isCallStatusReady } from '../../lib/util/error-handling/StatusByCallHelpers';

import { UI_ROUTES } from '../../util/routes';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button, NoDataAvailable } from '../index';

import NewOnMarketItem from './NewOnMarketItem/NewOnMarketItem';

import styles from './NewOnMarketList.module.scss';

const NewOnMarketList = ({ newOnTheMarket, companyLogoAndName, isNewStocksStatusReady, getNewStocks }: ReduxProps) => {
  const history = useHistory();

  useEffect(() => {
    if (!newOnTheMarket.length) {
      getNewStocks();
    }
  }, [getNewStocks, newOnTheMarket.length]);

  return (
    <>
      <div className={styles.wrapperTitle}>
        <h6 className={styles.title}>{lang.commonDiscoverSubtitleNewOnTheMarket()}</h6>
        {(isNewStocksStatusReady && newOnTheMarket.length > 0) && (
        <Button
          variant={ButtonTypesEnum.Link}
          onClick={() => history.push(`${UI_ROUTES.discover}${UI_ROUTES.newOnTheMarket}`)}
        >
          {lang.commonViewAll()}
        </Button>
        )}
      </div>
      {
        isNewStocksStatusReady && newOnTheMarket.length === 0
        && <div className={styles.noDataAvailable}><NoDataAvailable /></div>
      }
      {
        isNewStocksStatusReady && newOnTheMarket.length > 0
        && (
        <div className={styles.container}>
          {newOnTheMarket.slice(0, 8).map((item: NewStockData) => (
            <div key={item.symbol} className={styles.cardWrapper}>
              <NewOnMarketItem symbol={item.symbol} logo={companyLogoAndName[item.symbol]?.logo} />
            </div>
          ))}
        </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  newOnTheMarket: state.fundamental.newStocks,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  isNewStocksStatusReady: isCallStatusReady(FundamentalCall.getNewStocks, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getNewStocks: () => dispatch(getNewOnTheMarket()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NewOnMarketList);
