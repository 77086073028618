import lang from '../../language';
import { DynamicFilterType } from '../../services/filter/types';
import { TypedObject } from '../../util/types';
import { BasePageableRequest } from '../pageable';

export type BaseSymbolData = {
  symbol: string;
  logoBase64?: string;
  companyName?: string;
};

export interface PopularStock extends BaseSymbolData {
  popularity: number;
  symbolUniverseSymbol: boolean;
}

export interface PopularStockParams {
  constrained?: boolean;
  count?: number;
}

export interface DividendPayerData extends BaseSymbolData {
  currentDividendPayment: number;
  dividendYield: number;
  marketCap: number;
  order: number;
  previousDividendPayment: number;
  volume: number;
  close: number;
  dividendRate: number;
}

export type PageableFilterRequest = BasePageableRequest & {
  isSymbolUniverseSymbols?: boolean;
};

export interface HighGrowthCompanyData extends BaseSymbolData {
  avg30Volume: number;
  epsLast12Months: number;
  growthIndicator: number;
  marketCap: number;
  order: number;
  revenue: number;
}

export interface TopGainerAndLoserData extends BaseSymbolData {
  changePercentage?: string;
  price?: number;
  absoluteChange?: string;
}

export const TopGainersAndLosersPriceOption = {
  oneToFive: lang.commonXtoY('$1', '$5'),
  fiveToFifteen: lang.commonXtoY('$5', '$15'),
  fifteenToFifty: lang.commonXtoY('$15', '$50'),
  fiftyToHundred: lang.commonXtoY('$50', '$100'),
  overHundred: lang.commonOverX('$100'),
};

export enum TopGainersAndLosersQueryOption {
  FROM_1_TO_5,
  FROM_5_TO_15,
  FROM_15_TO_50,
  FROM_50_TO_100,
  FROM_100,
  CUSTOM,
}

export interface TopGainerAndLoserPayload {
  minValue: number;
  maxValue: number;
}

export type ETFParams = {
  filters?: DynamicFilterType;
  projections?: string;
  symbolSet?: string;
  size?: number;
  page?: number;
}

export type ETF = {
  symbol: string;
  type: string;
  name?: string;
  companyName?: string;
  logoBase64?: string;
  avg30Volume?: number;
}

export type ETFDetailParams = {
  filters?: DynamicFilterType;
  page?: number;
  projections?: string;
  size?: number;
}

export type ETFDetailResponse = {
  symbol: string;
  avg30Volume: number;
  logoBase64: string;
}

export interface SectorDetailParams {
  sectorId: number;
  page: number;
  size: number;
}

export interface SectorDetail {
  sector: string;
  sectorId: number;
  content: BaseSymbolData[];
  nextPageToRead: number;
  totalElements?: number;
}

export interface Sector {
  id: number;
  sector: string;
  symbolCount: number;
  logoBase64: string;
}

export interface NewStockData extends BaseSymbolData {
  changePercentage: string;
  firstClose: number;
  openingChangePercentage: string;
}

export interface Earning {
  currency: string;
  date: string;
  dateConfirmed: string;
  actualEps: number; // TODO: This property was added to fix TS errors. Check all properties against real data.
  eps: string;
  epsEst: string;
  epsPrior: string;
  epsSurprise: string;
  epsSurprisePercent: string;
  epsType: string;
  exchange: string;
  id: string;
  importance: number;
  name: string;
  notes: string;
  period: string;
  periodYear: number;
  revenue: string;
  revenueEst: string;
  revenuePrior: string;
  revenueSurprise: string;
  revenueSurprisePercent: string;
  revenueType: string;
  symbol: string;
  time: string;
  displayTime?: string;
  displayEpsEst?: string;
  updated: number;
}

export interface IPO {
  created: string;
  currentPrice: number;
  date: string;
  eventId: string;
  filedDate: string;
  firstDayClose: number;
  id: string;
  industry: string;
  ipoStatus: string;
  iposCoopCompanyName: string;
  iposCoopId: number;
  key: string;
  managers: string;
  modified: string;
  offerPrice: number;
  offeringDate: string;
  priceRangeHigh: number;
  priceRangeLow: number;
  rating: number;
  return: number;
  shares: number;
  source: string;
  subKey: string;
  symbol: string;
  updated: string;
  volume: number;
}

export interface Split {
  countryCode: string;
  created: string;
  date: string;
  declaredDate: string;
  description: string;
  exDate: string;
  figi: string;
  flag: string;
  fromFactor: number;
  id: string;
  key: string;
  lastUpdated: string;
  notes: string;
  oldParValue: number;
  oldParValueCurrency: string;
  parValue: number;
  parValueCurrency: string;
  paymentDate: string;
  ratio: number;
  recordDate: string;
  refId: string;
  securityType: string;
  source: string;
  splitType: string;
  subKey: string;
  symbol: string;
  toFactor: number;
  updated: string;
}

export type CompanyLogoAndNameResponse = {
  base64Data: string;
  companyName: string;
  md5: string;
  symbol: string;
  url: string;
};

export type CompanyLogoAndNameState = {
  logo: string;
  name: string;
};

export type StocksSymbolSet = 'NONE' | 'RETAIL';

/**
 * @deprecated
 * Please use DynamicFilter(services/filter/dynamic-filter.ts) for the common filtering solution
 */
export type Filters = Record<string, TypedObject<string>>
