import { FinancialQuestionnaireSection } from '../../lib/models/enroll';

import { EnrollProgressBarData, EnrollProgressBarPhase } from '../../components/Enroll/ProgressBar/ProggressBar.types';
import { EnrollProgressBarInitialData } from '../../components/Enroll/ProgressBar/ProgressBar.constants';

import {
  EnrollProgressBarBackQuestionAction,
  EnrollProgressBarBackSectionAction,
  EnrollProgressBarInitialiseAction,
  EnrollProgressBarNextQuestionAction,
  EnrollProgressBarNextSectionAction,
} from './EnrollProgressBarReducer.constants';

export const EnrollProgressBarReducer = (
  state: EnrollProgressBarData,
  action: {type: string, payload?: { [key: string]: any }},
): EnrollProgressBarData => {
  switch (action.type) {
    case EnrollProgressBarInitialiseAction:
      // eslint-disable-next-line no-case-declarations
      const libPhases = action.payload?.sections.map((questionSection: FinancialQuestionnaireSection) => ({
        index: questionSection.order,
        title: questionSection.header,
        steps: questionSection.questions.length,
      } as EnrollProgressBarPhase));

      return ({
        currentPhaseIndex: action.payload?.phase,
        currentStepIndex: action.payload?.step,
        phases: EnrollProgressBarInitialData.phases
          .concat(...libPhases)
          .sort((current: EnrollProgressBarPhase, next: EnrollProgressBarPhase) => current.index - next.index),
      });
    case EnrollProgressBarNextQuestionAction:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
      };
    case EnrollProgressBarBackQuestionAction:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex - 1,
      };
    case EnrollProgressBarNextSectionAction:
      return {
        ...state,
        currentPhaseIndex: state.currentPhaseIndex + 1,
        currentStepIndex: 0,
      };
    case EnrollProgressBarBackSectionAction:
      // eslint-disable-next-line no-case-declarations
      const filteredPhases = state.phases.find((phase: EnrollProgressBarPhase) => (
        phase.index === (state.currentPhaseIndex - 1)
      ));

      if (filteredPhases) {
        return {
          ...state,
          currentPhaseIndex: filteredPhases.index,
          currentStepIndex: filteredPhases.steps,
        };
      }

      return state;
    default:
      return state;
  }
};
