// TODO: Fields marked as `null` have not been checked
//       because of lack of sample data

import moment from 'moment';

import { CurrencyEnum, DocumentStatusEnum } from '../enums';
import { NullableNumber, NullableString } from '../util/types';

import { OnboardingQuestionMethod } from './crm/types';
import {
  ENROLL_AUTH_REQUEST_TYPE,
  EnrollIndividualAddressRequestData,
  EnrollIndividualDocumentOwnRequestData,
  EnrollIndividualIdentityDetailRequestData,
  EnrollIndividualTaxDetailRequestData,
} from './enroll-requests';

export enum ENROLL_METHOD {
  // GET methods
  getIndividualExtendedInfo,
  getSourceOfFundOwn,
  getEmploymentStatusOwn,
  getBusinessOwn,
  getPurposeOfTrading,
  getFinancialQuestionnaireTypes,
  getFinancialQuestionnaireOwnList,
  getLegalDeclarationList,
  getIndividualOwnLegalDeclarations,
  postEmailLegalDeclarations,
  getPepDetail,
  getIndividualOwnActiveRiskAssessment,
  getLegalDeclarationsDynamicFile,
  // POST / PUT methods
  putIndividual,
  postEnrollmentOwnPersonal,
  putEnrollmentOwnPersonal,
  postIndividualIdentityDetailOwn,
  putIndividualIdentityDetailOwn,
  postIndividualAddressOwn,
  putIndividualAddressOwn,
  postIndividualTaxDetailOwnBatch,
  putIndividualTaxDetailOwn,
  postUploadFile,
  postIndividualSourceOfFund,
  putIndividualSourceOfFund,
  postIndividualSourceOfIncome,
  putIndividualOwnPurposeOfTrading,
  putIndividualOwnLegalDeclarations,
  putIndividualOwnAnnualNetIncome,
  putIndividualOwnEstimatedNetWorth,
  putIndividualOwnEstimatedLiquidWorth,
  postFinancialQuestionnaire,
  postIndividualPepDetail,
  postIndividualDocumentOwn,
  postDocumentOwnBinary,
  postIndividualIdentityDetailOwnWithFile,
  // transitions
  putIndividualTransitionToPendingDocuments,
  putIndividualTransitionToPendingAcceptance,
  putIndividualTransitionToForVerification,
  putOwnFinancialQuestionnaire,
  putIndividualSourceOfIncome,

  // Deposit Transfer endpoints
  postGetWireTransferBankAccount,
  postRegisterLinkedAccount,
  postcreateBankTransfer,

  // delete
  deleteTaxDetail,
  deleteIndividualAddress
}
export type ENROLL_METHOD_NAME = keyof typeof ENROLL_METHOD

export enum ENROLL_REQUEST_STATE {
  INITIAL,
  PENDING,
  READY,
  ERROR,
  REQUEST_ERROR,
  SERVER_ERROR,
}
export type ENROLL_REQUEST_STATE_NAME = keyof typeof ENROLL_REQUEST_STATE

export enum IndividualAddressTypeEnum {
  residential = 'R',
  mailing = 'M'
}

export type ConfirmedStepsData = {
  email?: boolean
  phone?: boolean
  didSendPhone?: boolean
}

export type CountryData = {
  id: string;
  name: string;
  phone_code: string;
  /**
   * The following properties are not a part of `/countries/list` call
   * but were present in `/countries`. Left here for reference if needed in the future.
   */
  alpha3_code?: string;
  numeric_code?: string;
  currency_code?: string;
  has_uk_us_eu_sanctions?: boolean;
  has_un_sanctions?: boolean
  has_fatf_warning?: boolean;
  is_black_listed?: boolean;
  ticpi_score?: number;
  is_eu_member?: boolean;
  is_in_euro_zone?: boolean;
  is_psd_member?: boolean;
}

export type SourceOfFundsOwnData = {
  id: number;
  name: string;
  risk_score: number;
  risk_classification: string;
  is_active: boolean;
  explanatory_text: string; // We need  at home trading period & details to fulfill regulatory requirements for trading
  order: number;
}

export type SourceOfFundsAnswerData = {
  id?: NullableNumber
  source_of_fund_id?: number,
  proof_of_funds_id?: number,
  individual_id?: number,
  from?: string | number, // string when date, number when year
  to?: string | number, // string when date, number when year
  identity_name?: string,
  fund_type?: string,
  review_status?: string,
  review_summary?: NullableString,
}

export type SourceOfFundsAnswerExtraData = SourceOfFundsAnswerData & {
  specifyType?: string
  address?: string
  name?: string
  type?: string
  hasMain?: string | boolean
  year?: string | number
  birth?: string | number
}

export type SourceOfFundParsedAnswerData = SourceOfFundsAnswerData & SourceOfFundsAnswerExtraData;

export type IndividualExtendedInfoData = {
  id: number
  user_correlation_id: number
  individual_status: number
  user_status: number
  creation_stamp: string // "2021-02-05T07:53:56.1241971",
  email: string // "example@mail.com",
  email_confirmed: boolean
  phone_number: string // "+1839233",
  phone_number_confirmed: boolean // "+1839233",
  first_name: string
  middle_name: string
  last_name: string
  birth_date: string // "1978-11-11T00:00:00",
  birth_place_id: string // "BG",
  birth_place_location: string // "London",
  gender: any // { "key": "M", "value": "Male" },
  account_currency?: null
  purpose_of_trading_id: number
  annual_net_income_id: number
  estimated_net_worth_id: number
  estimated_liquid_worth_id: number
  mi_fid_category?: null
  trading_category: number
  bank_account_jurisdiction?: null
  has_financial_sanctions: boolean
  localized_detail?: null
  assigned_to_user?: null
  tenant_id: number
  type_behavior: number
  available_clients: string [] // "10nMobileLocal", "10nMobile", "10nWebLocal", "10nWeb"
  enroll_channel: string // "M"
  pep_detail?: null
  initial_deposit_id: number
  identity_details: EnrollIndividualIdentityDetailRequestData[]
  nationalities: string[] // "BG"
  alias: string // "SN"
  rebates_collector_account: string // the trading acc id is an example of this value
  manual_risk_classification: number
  tax_details: EnrollIndividualTaxDetailRequestData
  addresses: EnrollIndividualAddressRequestData[]
  documents: EnrollIndividualDocumentOwnRequestData[]
  source_of_incomes: any[] // see individualExtendedInfo.json for sample data
  source_of_funds: SourceOfFundsAnswerData[]
  individual_risks: any[] // see individualExtendedInfo.json for sample data
  individual_financial_questionnaires?: IndividualFinancialQuestionnaire[]
}

export class IndividualExtendedInfo {
  private data: IndividualExtendedInfoData;

  constructor(data: IndividualExtendedInfoData) {
    this.data = data;
  }

  getAddressByType(type: string): EnrollIndividualAddressRequestData {
    return (
      this
        .data
        .addresses
        .find(
          (address: EnrollIndividualAddressRequestData) => address.address_type === type,
        ) ?? {}
    ) as EnrollIndividualAddressRequestData;
  }

  getLatestApprovedDocumentByType(type: string): EnrollIndividualDocumentOwnRequestData {
    return this.data.documents
      .slice()
      .sort((
        previous: EnrollIndividualDocumentOwnRequestData,
        next: EnrollIndividualDocumentOwnRequestData,
      ) => moment(next.upload_stamp).unix() - moment(previous.upload_stamp).unix())
      .find((document: EnrollIndividualDocumentOwnRequestData) => (document.status === DocumentStatusEnum.Approved)
          && (document.document_type === type)) as EnrollIndividualDocumentOwnRequestData;
  }
}

export type RiskData = {
  id: number;
  name: string;
  risk_score: number;
  risk_classification: string;
  is_active: boolean;
}
export type EmploymentSatusData = RiskData & {
  business_required: boolean;
  order: number,
}

export type PurposeOfTradingData = {
  id: number;
  name: string;
  is_active: boolean;
  order: number;
}

export type FinancialQuestionnaireTypeData = {
  id: number;
  financial_questionaire_type: string;
  description: string;
  currency: string;
  order: number;
}

export type LegalDeclarationListData = {
  id: number;
  name: string;
  storage_id: string;
  description: string | null;
}

export type LegalDeclarationData = {
  legal_declaration_id: number;
  consent_date: string;
}

export type IndividualOwnLegalDeclarationsData = {
  individual_id: number;
  legal_declarations: LegalDeclarationData[];
}

export type RiskInputData = {
  name: string;
  country: string;
  risk_classification: string;
  risk_score: number
}

export type RiskAssesmentData = {
  risk_input: RiskInputData[],
  risk_score: number;
  weight: number;
  adjusted_score: number;
}

export type IndividualOwnActiveRiskAssessmentData = {
  [key: string]: RiskAssesmentData;
} & {
  calculated_adjusted_score: number;
  outcome_score: number;
  outcome_classification: string;
}

export type PepDetail = {
  id: number,
  category: string,
  question: string,
  risk_score: number,
  tenant_id: number
}

export type PepYesedQuestion = {
  proof_of_pep_id: number, // Corresponds to 'id' from PepDetail
  individual_id: number,
}

export type EnrollCRMState = {
  sourceOfFundsOwn: SourceOfFundsOwnData[];
  country: CountryData[];
  employmentStatusOwn: EmploymentSatusData[];
  businessOwn: RiskData[];
  pepDetail: PepDetail[],
  purposeOfTradingActive: PurposeOfTradingData[];
  legalDeclarationList: LegalDeclarationListData[];
  individualOwnLegalDeclarations?: IndividualOwnLegalDeclarationsData;
  individualOwnActiveRiskAssessment: IndividualOwnActiveRiskAssessmentData[];
  files: any[];
  status: ENROLL_REQUEST_STATE;
  /**
   * This is an old definition of `statusByCall`. Only relevant to onboarding.
   * If you are using it - please ping team lead for assistance.
   * We may possibly convert it to GenericStatusByCall<> which other slices are using
   * as well as main `crm.statusByCall` implementation.
   * Also remember that `crm.statusByCall` and this `crm.enroll.statusByCall` are different.
   * This `crm.enroll` version is only for CRM calls related to onboarding.
   */
  statusByCall: Record<string, {
    callMethodName: ENROLL_METHOD_NAME,
    requestState: ENROLL_REQUEST_STATE,
    requestStateName: ENROLL_REQUEST_STATE_NAME,
    errorCode?: NullableNumber
  }>,
  callMethod: ENROLL_METHOD;
  callMethodName: string;
  callsInQueue: number;
  financialQuestionnaireSections: FinancialQuestionnaireSection[];
  financialQuestionnaireCurrency: CurrencyEnum;
  isPoISent?: boolean;
  isPoASent?: boolean;
  /**
   * initially used in Source Of Funds screens
   */
  isEditSourceOfFunds: boolean;
  enrollProgressInfo: {
    isOpenLastQuestions: boolean,
    isNoExperienceWithStock: boolean;
  };
  bankAccountDetailsOnCreate: {
    account_info: {
      bank_name: string,
      bank_country_id: string,
      bic: string,
      iban: string,
      currency_code: string,
    }
  },
  custom?: any; // used by web or mobile app. Initial usage - mobile for navigation state caching
};

export type EnrollAuthDataState = {
  lastType?: ENROLL_AUTH_REQUEST_TYPE
  status?: ENROLL_REQUEST_STATE // TODO: Rename to status
  email?: string
  userId?: number
  emailToConfirm?: string
  tokenToConfirm?: string
  confirmedSteps: ConfirmedStepsData
  clients?: string[]
  accessToken?: string
  refreshToken?: string
  isManualToken?: boolean
  firstName?: NullableString
  lastName?: NullableString
  country?: NullableString
  phoneCode?: NullableString
  phoneNumber?: NullableString
  statusCode?: number,
  requestCallName?: string,
  isAuthLinkExpired?: boolean,
}

export type EnrollAuthCachedState = {
  firstName?: NullableString
  lastName?: NullableString
  country?: NullableString
  email?: NullableString
}

export type TokenDecodedData = {
  amr: string[] // "pwd"
  aud: string[] // "TokenserverAPI", "Alaric.FileStorage", "Alaric.Payment" ...
  auth_time: number // 1613755825
  client_id: string // "10nMobileLocal"
  email?: string // "example@mail.com"
  email_verified?: string // "false" or "true"
  phone_number?: string // "false" or "true"
  phone_number_verified?: string // "false"
  exp: number // 1613759426
  idp: string // "local"
  iss: string // "https://auth-dev.alaricsecurities.net"
  nbf: number // 1613755826
  role: string // "user"
  scope: string[] // "TokenserverAPI.read", "TokenserverAPI.write", "Alaric.FileStorage" ...
  sub: string // "11181"
  tenant: string // "2"
  user_relation: string // "1"
}

export type AuthClient = {
  client_id: number
  client_name: string // "10nMobileLocal"
}

export type EmploymentStatus = {
  id: number;
  name: string;
  business_required: boolean;
  order: number;
}


export type FinancialQuestionnaireSection = {
  header: string;
  order: number;
  questions: FinancialQuestionnaireQuestion[];

  /**
   * Must be Optional Params
   * because they are not needed
   * when initialising a section
   * but are needed when get a specific question
   */
  totalSections?: number;
  totalQuestions?: number;
}

export type FinancialQuestionnaireSubQuestion = {
  title: string;
  order: number;
  options: FinancialQuestionnaireTypeData[];
  lookupKey: string;
  selectedOption?: number;
};

export type FinancialQuestionnaireOption =
    FinancialQuestionnaireTypeData | PurposeOfTradingData | EmploymentStatus | SourceOfFundsList;

export type FinancialQuestionnaireQuestion = {
  title: string;
  subtitle: string;
  order: number;
  options: FinancialQuestionnaireOption[];
  lookupKey: string;
  currency?: CurrencyEnum;
  method?: OnboardingQuestionMethod;
  subQuestions?: FinancialQuestionnaireSubQuestion[],
}

export type FinancialQuestionnaireSectionItem = {
  sHeader: string;
  sOrder: number;
  totalSections: number;
  totalSQuestions: number;
  qTitle: string;
  qSubtitle: string;
  qOrder: number;
  options: FinancialQuestionnaireOption[];
  selectedOption?: any;
  questionType: any;
  hasCurrencyOption: boolean;
  hasSingleId: boolean;
  method?: ENROLL_METHOD;
  getBody: (...args: any) => any;
  subQuestions?: FinancialQuestionnaireSubQuestion[];
}

export type SourceOfFundsList = {
  id: number;
  name: string;
  explanatory_text: string;
  order: number;
}

export type IndividualFinancialQuestionnaire = {
  financial_questionnaire_id: number;
  financial_questionaire_type: string,
  currency: string;
}
