import React from 'react';

import {
  EU_CREDIT_CARD_FEE, EU_DEBIT_CARD_FEE, NON_EU_AND_BUSINESS_CARD_FEE,
} from '../../../../../lib/constants/cardFees';
import { CurrencyEnum } from '../../../../../lib/enums';
import lang from '../../../../../lib/language';
import { formatNumberWithSuffix } from '../../../../../lib/util/DataHelpers';

import { Button } from '../../../../../components';

import styles from './FeeBreakdownModal.module.scss';

type Props = {
    handleModal: () => void;
};

const FeeBreakdownModal = (props: Props) => {
  const { handleModal } = props;

  return (
    <div className={styles.feeBreakdownWrapper}>
      <h6 className={styles.title}>{lang.popupFeeBreakdownTitle()}</h6>
      <p className={styles.subTitle}>{lang.popupFeeBreakdownCardTitle()}</p>
      <div className={styles.cardFeesWrapper}>
        <div className={styles.firstSection}>
          <p>{lang.popupFeeBreakdownCardEUDebitKey()}</p>
          <div className={styles.cardFeesValues}>
            <p>{lang.popupFeeBreakdownCardEUDebitValue(formatNumberWithSuffix(EU_DEBIT_CARD_FEE, '%'))}</p>
            <p>{lang.popupFeeBreakdownCardEUCreditValue(formatNumberWithSuffix(EU_CREDIT_CARD_FEE, '%'))}</p>
          </div>
        </div>
        <div className={styles.secondSection}>
          <p>{lang.popupFeeBreakdownCardNonEUkey()}</p>
          <p className={styles.cardFeesValues}>{formatNumberWithSuffix(NON_EU_AND_BUSINESS_CARD_FEE, '%')}</p>
        </div>
      </div>
      <p className={styles.subTitle}>{lang.popupFeeBreakdownBankTitle()}</p>
      <div className={styles.bankFeesWrapper}>
        <div className={styles.firstSection}>
          <p>{lang.commonFirstDepositBank()}</p>
          <p className={styles.bankFeesValues}>{`${CurrencyEnum.USD} 1`}</p>
        </div>
        <div className={styles.secondSection}>
          <p>{lang.popupFeeBreakdownMinimumDepositKey()}</p>
          <p className={styles.bankFeesValues}>{lang.popupFeeBreakdownMinimumDepositValue()}</p>
        </div>
      </div>
      <p className={styles.description}>{lang.popupFeeBreakdownDescription()}</p>
      <Button fullWidth onClick={handleModal}>
        {lang.commonClose()}
      </Button>
    </div>
  );
};

export default FeeBreakdownModal;
