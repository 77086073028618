/**
 * This slice is dedicated to application state data
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGNORE_GATEWAY_CONNECTION } from '../../libSettings';
import {
  accountsFailed,
  applicationStartup,
  gatewayConnected,
  gatewayDisconnected,
  login,
  logout,
  marketDataDisconnected,
  servicesReady,
  tradingDisconnected,
} from '../common-actions';

export interface AppState {
  loaded: boolean;
  connected: boolean;
  hasError: boolean;
  isServicesReady: boolean;
  isWebLoginReady: boolean;
  isOnline: boolean;
  isConnectionSlow: boolean;
}

export const INITIAL_STATE: AppState = {
  connected: false,
  loaded: false,
  hasError: false,
  isServicesReady: false,
  isWebLoginReady: false,
  isOnline: true,
  isConnectionSlow: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    /**
     * All services connect - action called in epic when all services connected (crm, trading, md)
     */
    appConnected(state) {
      state.connected = true;
      state.loaded = true;
      state.isOnline = true;
      state.hasError = false;
    },
    /**
     * App has lodaded - called only internally - see crm/epics@startupEpic
     */
    appLoaded(state) {
      state.loaded = true;
    },
    /**
     * This is called only once by Web UI - when authentication is done
     * ATTENTION: Do not use for other purposes
     */
    setWebLoginReady(state) {
      state.isWebLoginReady = true;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload;
    },
    setSlowConnectionStatus(state, action: PayloadAction<boolean>) {
      state.isConnectionSlow = action.payload;
    },
  },
  extraReducers: {
    [applicationStartup.type]: state => {
      state.connected = false;
      state.loaded = false;
      state.isServicesReady = false;
    },
    [login.type]: state => {
      state.hasError = false;
    },
    [servicesReady.type]: state => {
      state.isServicesReady = true;
    },
    [logout.type]: state => INITIAL_STATE,
    [tradingDisconnected.type]: state => {
      // TODO: Figure out app states on different connection issue cases
      //       Currently disabling this - until SOR integration
      // state.connected = false;
      // state.loaded = true;
    },
    [marketDataDisconnected.type]: state => {
      state.connected = false;
      state.loaded = true;
    },
    [gatewayConnected.type]: state => {},
    [gatewayDisconnected.type]: state => {
      if (!IGNORE_GATEWAY_CONNECTION) state.connected = false;
    },
    [accountsFailed.type]: state => {
      state.connected = false;
      state.loaded = true;
    },
  },
});

export const {
  setWebLoginReady,
  setOnlineStatus,
  setSlowConnectionStatus,
  setError,
  appConnected,
  appLoaded,
} = appSlice.actions;

export default appSlice.reducer;
