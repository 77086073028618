import config from '../../config';
import { PredefinedMilisecondsTypeEnum } from '../enums';
import { PeopleAlsoTradeItem, PeopleAlsoTradeParams } from '../models/reporting/types';
import {
  AccountSummaryPayloadData,
  AccountTradeHistoryPayloadData,
  GetAccountsAvailableCashRequest,
  GetMyAccountChartDataPayload,
  OpenPositionsPayloadData,
  OrderDetailPayloadData,
  OrderDetailResponse,
} from '../store/reporting/types';

import { CacheName, HttpMethods } from './cache/types';
import { BaseRequestService } from './BaseRequest.service';

const API_URLS = {
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  executions: '/v1/executions',
  getAccountsAvailableCash: '/v1/10n/get-available-cash',
  getNetEquityComponents: '/v1/10n/get-net-equity-components',
  getOpenPositionsData: '/v1/10n/get-open-positions',
  getAccountSummaryData: '/v1/10n/get-account-summary',
  getOrderDetailData: '/v1/10n/get-order-details',
  getAccountTradeHistory: '/v1/10n/get-account-trade-history',
  getAccountPendingNewOrderHistory: '/v1/10n/get-account-trade-history-pending-new',
  peopleAlsoTrade: '/v1/10n/get-people-also-trade',
  getBPInClosedMarket: '/v1/10n/get-buying-power-oms-off',
};

export class ReportingService extends BaseRequestService {
  protected getBaseUrl(): string {
    return config.reporting.url;
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  public executions() {
    return this.api.get<any[]>(API_URLS.executions, {
      size: 40,
      sort: 'dateTime,desc',
    });
  }

  public getAccountsAvailableCash(requestBody: GetAccountsAvailableCashRequest) {
    const APIPath = API_URLS.getAccountsAvailableCash;
    return this.api.post<any>(APIPath, requestBody);
  }

  public getNetEquityComponents(requestBody: GetMyAccountChartDataPayload) {
    return this.api.post<any>(API_URLS.getNetEquityComponents, requestBody);
  }

  public getAccountSummaryData(requestBody: AccountSummaryPayloadData) {
    const APIPath = API_URLS.getAccountSummaryData;
    return this.api.post<any>(APIPath, requestBody);
  }

  public getOrderDetailData(requestBody: OrderDetailPayloadData) {
    const APIPath = API_URLS.getOrderDetailData;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.orderDetailData,
      HttpMethods.POST,
      PredefinedMilisecondsTypeEnum.fifteenMinutes,
      [ 'parentOrderId', 'accountReferenceId' ],
    );

    return this.api.post<OrderDetailResponse>(APIPath, requestBody);
  }

  public getOpenPositionsData(requestBody: OpenPositionsPayloadData) {
    const APIPath = API_URLS.getOpenPositionsData;
    return this.api.post<any>(APIPath, requestBody);
  }

  public getAccountTradeHistory(requestBody: AccountTradeHistoryPayloadData) {
    return this.api.post<AccountTradeHistoryPayloadData>(API_URLS.getAccountTradeHistory, requestBody);
  }

  public getAccountPendingNewOrderHistory(requestBody: AccountTradeHistoryPayloadData) {
    return this.api.post<AccountTradeHistoryPayloadData>(API_URLS.getAccountPendingNewOrderHistory, requestBody);
  }

  public getPeopleAlsoTradeData(params: PeopleAlsoTradeParams) {
    const APIPath = API_URLS.peopleAlsoTrade;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.peopleAlsoTrade,
      HttpMethods.POST,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'accountReferenceId' ], // Only account ID is dynamic here
    );

    return this.api.post<PeopleAlsoTradeItem[]>(APIPath, params);
  }

  public getBPInClosedMarket(requestBody: { accountReferenceId: string }) {
    // eslint-disable-next-line max-len
    return this.api.post<{accountReferenceId: string, buyingPowerId: number}>(API_URLS.getBPInClosedMarket, requestBody);
  }
}
