import { OrderStatusEnum } from '../../lib/enums/index';
import lang from '../../lib/language';
import { mapOrderStatus } from '../../lib/util/TradingHelpers';

export const statusOptionsData = [
  OrderStatusEnum.Filled,
  OrderStatusEnum.PartiallyFilled,
  OrderStatusEnum.PartiallyReject,
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Rejected,
  OrderStatusEnum.OtherwiseExpired.split(' ')[1],
];

export const TRADE_HISTORY_TABLE_HEADERS = [
  lang.headerMyAccountMyStockSymbolName(),
  lang.commonTradingOrderType(),
  lang.headerMyAccountMyStockQuantity(),
  lang.headerTradeHistoryFillPrice(),
  lang.headerTradeHistoryAmount(),
  lang.commonDate(),
  lang.commonOrderStatusFilterTitle(),
];

export const statusOptionsLabels = statusOptionsData.map(value => mapOrderStatus(value));
