import React from 'react';

import useBrowserHeight from '../../hooks/useBrowserHeight';
import { SMALL_HEIGHT_WINDOW } from '../../util/constants';

import styles from './SymbolDetails.module.scss';

type Props = {
  children: React.ReactNode[],
}

const ScrollWrapper = ({ children }: Props) => {
  const { browserHeightSize } = useBrowserHeight();

  return (
    <div
      style={{ top: window.innerHeight <= SMALL_HEIGHT_WINDOW ? `${112 + browserHeightSize}px` : undefined }}
      className={window.innerHeight <= SMALL_HEIGHT_WINDOW
        ? styles.smallScreenAsideContent
        : styles.bigScreenAsideContent}
    >
      {children}
    </div>
  );
};

export default ScrollWrapper;
