import React, { CSSProperties, useMemo } from 'react';
import moment from 'moment';

import lang from '../../lib/language';
import {
  CacheableCalls,
  CALLS_TO_MEMOIZE,
  FullMemoizedCallSettings,
  PRELOADER_CACHE_TIMEOUT_MS,
  PRELOADER_TIMEOUT_MS,
} from '../../lib/libSettings';
import CallsCache from '../../lib/store-util/calls-cache/CallsCache';
import { useTimeout } from '../../lib/util/hooks/TimeoutHooks';

import styles from './Preloader.module.scss';

type Props = {
  text?: string,
  /**
   * Used for debugging / logging
   */
  owner?: string,
  style?: CSSProperties;
  /**
   * Style preset - currently only one - `customOne`
   */
  stylePreset?: 'customOne';
  secondaryColor?: boolean;
  isFullScreen?: boolean;
  isTextOnly?: boolean;
  /**
   * This is used for cases when using preloader with cache enabled endpoint
   */
  timeOutSetup?: {
    onTimeout: () => void
    checkIfTimeout: () => boolean
  } | null
  /**
   * Used for cases with custom check `checkIfTimeout` callback and `onTimeout` handler
   */
  cacheTimeOutSetup?: {
    cacheName: CacheableCalls | null
    cacheParams?: string | null
    hasNoData: boolean
    setHasNoData: (value: boolean) => void
  } | null
}

const Preloader = (props: Props): JSX.Element => {
  const {
    style,
    secondaryColor = false,
    isFullScreen = false,
    text,
    cacheTimeOutSetup,
    timeOutSetup,
    isTextOnly,
    owner,
    stylePreset,
  } = props;
  const isStylePresetCustomOne = stylePreset === 'customOne';

  const fullscreenStyle = isStylePresetCustomOne ? styles.fullscreenCustomOne : styles.fullscreen;
  const { checkIfTimeout, onTimeout } = timeOutSetup || {};
  const { cacheName, cacheParams, hasNoData, setHasNoData } = cacheTimeOutSetup || {};
  const wrapperClassName = useMemo(
    () => (isStylePresetCustomOne
      ? [styles.loaderBlockFullScreen]
      : [styles.loaderBlock, secondaryColor && styles.whiteTheme].join(' ')),
    [secondaryColor, isStylePresetCustomOne],
  );

  let timeoutForCall;
  if (cacheTimeOutSetup && CALLS_TO_MEMOIZE[cacheName!]) {
    const settings = CALLS_TO_MEMOIZE[cacheName!] as FullMemoizedCallSettings;
    if (settings.timeout) {
      timeoutForCall = moment.duration(...settings.timeout).asMilliseconds();
    }
  }

  useTimeout(
    (
      cacheTimeOutSetup
        ? () => {
          const isPending = CallsCache.checkIfPending(cacheName!, cacheParams);
          setHasNoData?.(!!(isPending && hasNoData));
        }
        : null
    ),
    timeoutForCall ?? PRELOADER_CACHE_TIMEOUT_MS,
    `cache/${owner}`,
  );

  useTimeout(
    timeOutSetup ? () => { if (checkIfTimeout?.()) onTimeout?.(); } : null,
    PRELOADER_TIMEOUT_MS,
    `timeout/${owner}`,
  );

  return (
    <div style={{ flexDirection: 'column' }} className={isFullScreen ? fullscreenStyle : undefined}>
      {!isTextOnly && <PreloaderAnimation style={style} wrapperClassName={wrapperClassName} />}
      <div className={isStylePresetCustomOne ? styles.textFullscreenCustomOne : styles.text}>
        {text ?? lang.commonMessageLoading()}
      </div>
    </div>
  );
};

type PreloaderAnimationProps = {
  style: React.CSSProperties | undefined, // not optional
  wrapperClassName: any
};
const PreloaderAnimation = ({ style, wrapperClassName }: PreloaderAnimationProps) => (
  <div style={style} className={wrapperClassName}>
    <svg viewBox="0 0 100 100" className={styles.svg}>
      <circle className={styles.circle} id="circle-loader" cx="50" cy="50" r="45" />
    </svg>
  </div>
);

export default Preloader;
