export enum LogLevel {
    None,
    Error,
    Warn,
    Info,
    Debug,
    Trace,
}

export enum LogType {
    Tron,
    Console,
    File
}
