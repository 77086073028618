import React, { useEffect } from 'react';

import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import { LatestNews } from '../../components';
import { useIsMobile } from '../../hooks';
import EarningsCalendar from '../EarningsCalendar/EarningsCalendar';
import LongTermInvestments from '../LongTermInvestments/LongTermInvestments';
import MarketIndexes from '../MarketIndexes/MarketIndexes';
import PopularGroups from '../PopularGroups/PopularGroups';
import TopGainersAndLosers from '../TopGainersAndLosers/TopGainersAndLosers';

import styles from './Discover.module.scss';

const Discover = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    manageSubscriptions('discover', null, true, true);
  }, []);

  return (
    <div className={styles.wrapperLayout}>
      <div className={styles.wrapperFirstSection}>
        <MarketIndexes />
        <TopGainersAndLosers />
        <PopularGroups />
        <div className={isMobile ? styles.longTermInvestmentsWrapper : undefined}>
          <LongTermInvestments />
        </div>
        {/* TODO: Enable New on the Market feature when the backend team fiexes the konown issues */}
        <div className={isMobile ? styles.earningsCalendarWrapper : undefined}>
          <EarningsCalendar />
        </div>
      </div>
      <div className={styles.wrapperSecondSection}>
        <LatestNews />
      </div>
    </div>
  );
};

export default Discover;
