import React, { useState } from 'react';

import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import { useTimeout } from '../../lib/util/hooks/TimeoutHooks';

const useCancelOrderTimer = (showFinishModal: boolean, currentOrderStatus?: string, requestStatus?: string) => {
  const [showCancelOrder, setShowCancelOrder] = useState<boolean>(false);
  const [showPendingCancelOrder, setShowPendingCancelOrder] = useState<boolean>(false);
  const [showRejectedCancelOrder, setShowRejectedCancelOrder] = useState<boolean>(false);
  const [hasNoCancelResponse, setHasNoCancelResponse] = useState<boolean>(false);

  useTimeout(() => {
    if (showFinishModal) {
      setShowCancelOrder(currentOrderStatus === OrderStatusEnum.Cancelled);
      setShowPendingCancelOrder(currentOrderStatus === OrderStatusEnum.PendingCancelOrModify);
      setShowRejectedCancelOrder(currentOrderStatus === OrderStatusEnum.Rejected);
    } else {
      setShowCancelOrder(false);
      setShowPendingCancelOrder(false);
      setShowRejectedCancelOrder(false);
    }
    setHasNoCancelResponse(requestStatus === OrderStatusEnum.InProgress);
  }, 1000);

  return {
    cancelOrderTimeoutSuccess: showCancelOrder || showPendingCancelOrder || showRejectedCancelOrder,
    hasNoCancelResponse,
  };
};

export default useCancelOrderTimer;
