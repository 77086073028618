import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { ENROLL_METHOD, ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import { documentUploadCategoriesPOA } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enroll } from '../../../lib/store/crm/index';
import { hasUploadDocumentPOAFinished } from '../../../lib/store/selectors';
import { TextHelpers } from '../../../lib/util/TextHelpers';

import iconFilledCheck from '../../../assets/img/icon-filled-check.png';
import { Document, VerificationLayout } from '../../../components';
import RadioGroupOption from '../../../components/RadioGroup/RadioGroupOption/RadioGroupOption';
import { UI_ROUTES } from '../../../util/routes';
import UploadErrorModal from '../ErrorModals/UploadErrorModal';

import styles from './VerificationPoA.module.scss';

const VerificationPoA = (): JSX.Element => {
  const history = useHistory();

  const [documentCategory, setDocumentCategory] = useState<string>(lang.commonVerificationUploadPoAUtilityBill());
  const [uploadPOADoc, setUploadPOADoc] = useState<string>('');
  const [isPOADocSent, setIsPOADocSent] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [showErroModal, setShowErroModal] = useState<boolean>(false);
  const [uploadPOAFile, setUploadPOAFile] = useState<File>();
  const hasUploadRequestFinished = useSelector<RootState, boolean>(
    (state: RootState) => hasUploadDocumentPOAFinished(state),
  );
  const isPending = useSelector<RootState, boolean>((state: RootState) => state.crm.enroll.callsInQueue > 0);
  const hasError = useSelector<RootState, boolean>(
    (state: RootState) => state.crm.enroll.status === ENROLL_REQUEST_STATE.ERROR,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    uploadPOADoc ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [uploadPOADoc]);

  useEffect(() => {
    if (hasError && isPOADocSent) {
      setShowErroModal(true);
    }
    if (isPOADocSent && hasUploadRequestFinished && !hasError) {
      history.push(UI_ROUTES.verification);
    }
  }, [hasError, history, isPOADocSent, hasUploadRequestFinished]);

  const goToVerifications = () => {
    const files = [uploadPOAFile];
    const callMethod = ENROLL_METHOD.postDocumentOwnBinary;

    dispatch(enroll({
      callMethod,
      callMethodName: ENROLL_METHOD[callMethod],
      requestBody: { files, filesInfo: documentUploadCategoriesPOA[documentCategory] },
    }));
    setIsPOADocSent(true);
  };

  return (
    <>
      <VerificationLayout
        onContinueClicked={goToVerifications}
        btnDisabled={btnDisabled || isPending}
        title={lang.commonVerificationUploadPoATitle()}
      >
        <>
          <div className={styles.firstDescription}>
            <img className={styles.iconFilledCheck} src={iconFilledCheck} alt="icon filled check" />
            <p>{lang.commonOnboardingUploadProofOfAddressText1()}</p>
          </div>
          <div className={styles.secondDescription}>
            <img className={styles.iconFilledCheck} src={iconFilledCheck} alt="icon filled check" />
            <p>{lang.commonOnboardingUploadProofOfAddressText2()}</p>
          </div>
          <p className={styles.titleItem}>
            {lang.commonVerificationUploadPoAChooseDocumentLabel()}
          </p>
          <div className={styles.buttonsWrapper}>
            <RadioGroupOption
              value={''}
              label={lang.commonVerificationUploadPoAUtilityBill()}
              active={documentCategory === lang.commonVerificationUploadPoAUtilityBill()}
              onSelect={() => {
                setDocumentCategory(lang.commonVerificationUploadPoAUtilityBill());
              }}
            />
            <div className={styles.radioBtnBankStatement}>
              <RadioGroupOption
                value={''}
                label={lang.commonVerificationUploadPoABankStatement()}
                active={documentCategory === lang.commonVerificationUploadPoABankStatement()}
                onSelect={() => {
                  setDocumentCategory(lang.commonVerificationUploadPoABankStatement());
                }}
              />
            </div>
          </div>
          <Document
            idInput="uploadPOA"
            idRemove="removePOA"
            ariaLabelInput="upload proof of address"
            ariaLabelRemove="remove proof of address"
            disabledUpload={isPending}
            handleUploadDocument={setUploadPOADoc}
            handleUploadFileAsFileFormat={setUploadPOAFile}
          />
          <p className={styles.hint}>
            {lang.commonUploadDocumentTypesHint(TextHelpers.getAllowedFileExtensionNames())}
          </p>
        </>
      </VerificationLayout>
      {showErroModal && <UploadErrorModal handleClose={(value) => setShowErroModal(value)} />}
    </>
  );
};

export default VerificationPoA;
