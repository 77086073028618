import { PaymentMethodEnum, PaymentProcessEnum, PaymentProviderEnum, PaymentStatusEnum } from '../../enums';
import { CRMState } from '../../store/crm';
import { NullableString } from '../../util/types';
import { ENROLL_METHOD, FinancialQuestionnaireOption, FinancialQuestionnaireTypeData } from '../enroll';
import { FileUploadRegisterData } from '../enroll-requests';
import { AccountInfo } from '../linked-account/types';

export type PaymentsFilter = {
  payment_id?: number;
  payment_status?: PaymentStatusEnum[];
  payment_method?: PaymentMethodEnum[];
  account_owner_name?: string;
  created_by?: string;
  created_by_user_id?: number;
  account_reference_id?: string;
  accounts_id?: number[];
  amount_from?: string;
  amount_to?: string;
  settled_date_from?: string;
  settled_date_to?: string;
  account_owner_id: number;
  is_account_owner_company: boolean;
  date_from: string;
  date_to: string;
  payment_process: number[];
  page_number: number;
  page_size: number;
  order_column: string;
  order_ascending: boolean;
}

export type GetPaymentsPayload = {
  paymentProcess: PaymentProcessEnum[];
  paymentStatus?: PaymentStatusEnum[];
  pageNumber: number;
  pageSize?: number;
  defaultPageNumber: number;
  dateOffset: number;
  // used for queueing when initially loading first 15 and then all deposits
  isQueued?: boolean;
  isAllTransactions?: boolean;
}

export type Payment = {
  payment_id: number;
  payment_status: PaymentStatusEnum;
  amount: number;
  payment_process: PaymentProcessEnum;
  payment_provider: PaymentProviderEnum;
  create_date: string;
  settled_date: string;
  created_by_user_id: number;
  created_by: string;
  payment_method: PaymentMethodEnum;
  payment_reference: string;
  currency_code: string;
  description: string;
  category: string;
  sender_email: string;
  sender_account_number: string;
  sender_sort_code: string;
  sender_iban: string;
  sender_bic: string;
  sender_name: string;
  sender_bank_name: string;
  sender_bank_country_id: string;
  sender_bank_address: string;
  recipient_email: string;
  recipient_account_number: string;
  recipient_sort_code: string;
  recipient_iban: string;
  recipient_bic: string;
  recipient_name: string;
  recipient_bank_name: string;
  recipient_bank_country_id: string;
  recipient_bank_address: string;
  account_id: number;
  account_status: number;
  account_name: string;
  account_group_id: number;
  account_created_date: string;
  account_reference_id: string;
  note: string;
  is_cash_account: boolean;
  is_account_owner_company: boolean;
  account_owner_id: number;
  account_owner_email: string;
  account_owner_name: string;
  account_owner_status: number;
  owner_residential_country_id: string;
  owner_type_behaviour: number;
  tenant_id: number;
}

export type ExtraPaymentData = Record<number, {
  download: string;
  type: string;
  date: string;
  linkedAccountIban?: string;
}>

export type AccountDetails = {
  intraday_leverage: string;
  overnight_leverage: string;
  net_value: number;
  available_cash: number;
  daily_pn_l: number;
  unrealized_pn_l: number;
  buying_power_intra_day: number;
  buying_power_over_night: number;
  positions_value: number;
  last_trade_date: string;
  account_charge_schedules: AccountChargeSchedules[];
  account_id: number;
  account_created_date: string;
  account_status: number;
  is_owner_company: boolean;
  owner_id: number;
  owner_name: string;
  tenant_id: number;
  account_name: string;
  account_full_name: string;
  account_group_id: number;
  account_group_reference_id: string;
  default_custodian_ref_id: string;
  default_clearing_agent_system_name: string;
  default_clearing_account_ref_id: string;
  account_business_type_id: string;
  account_reference_id: string;
  is_cash_account: boolean;
}

export type AccountChargeSchedules = {
  charge_schedule_ref_id: string;
  charge_schedule_name: string;
  client_account: string;
  charge_type_code: string;
  charge_group_code: string;
  is_active: boolean;
}

export type GetWireTransferBankAccountPostRequest = {
  currency_code: string;
  bank_country_code: string;
  payment_process: number;
}

export type WithdrawalRequest = {
  amount: number;
  bank_details: AccountInfo
  payment_provider?: number;
  payment_process?: number;
  payment_method?: number;
  to_internal_account_id?: number;
  currency_code?: string;
}

export type FundingWireTransferPostRequest = {
  payment_provider?: number;
  to_internal_account_id?: number;
  amount: string;
  currency_code?: string;
  sender_bank_details?: AccountInfo;
  recipient_bank_details?: AccountInfo;
  is_initial_deposit?: boolean;
  payment_process?: number;
  payment_method?: number;
}

export type FundingWireTransferResponse = {
  id: number,
  create_date: string,
  status: number,
  payment_provider: number,
  payment_method: number,
  sign: number,
  to_internal_account_id: number,
  amount: number,
  currency_code: string,
  payment_reference: string,
  psp_payment_reference: string,
  sender: AccountInfo,
  recipient: AccountInfo,
}

export type OwnerTradingAccount = {
  id: number;
  reference_id: string;
  is_cash_account: boolean;
  status: TradingAccountStatusesEnum;
  sub_type: AccountSubType
}

export enum TradingAccountStatusesEnum {
  Unspecified,
  ForApproval,
  Rejected,
  Active = 10,
  WithdrawalRestricted,
  Blocked = 20,
  BlockOrderEntry,
  BlockShortSells,
  Closed = 30,
  ClosedCancel
}

export enum AccountSubType
{
    TradingCash = 1,
    Trading = 2,
    FundCash = 10,
    MutualFund = 11,
    InstrumentCertificate = 12
}

export interface LookupInterface {
  [key: string]: string;
}

export class LookupModel {
  private types: { [key: string]: string };

  constructor(data: LookupInterface) {
    this.types = data;
  }

  getTypes(): { [key: string]: string } {
    return this.types;
  }

  extendTypes(types: { [key: string]: string }): LookupModel {
    this.types = { ...this.types, ...types };

    return this;
  }

  getTypeKeys(): string[] {
    return Object.keys(this.types).filter((key: string) => [ 'UNS', 'U', 'UNSP' ].indexOf(key) < 0);
  }

  getTypeValue(key: string): string {
    return (typeof this.types[key] !== 'undefined') ? this.types[key] : '';
  }

  removeType(key: string): void {
    if (this.types[key]) {
      delete this.types[key];
    }
  }
}

export type OnboardingQuestionMethod = ENROLL_METHOD | ((state: CRMState) => ENROLL_METHOD)

export type OnboardingQuestionaireData = {
  purposeOfTrading: FinancialQuestionnaireOption[],
  sourceOfFundList: FinancialQuestionnaireOption[],
  employmentStatusList: FinancialQuestionnaireOption[],
  types: LookupInterface,
  answers: FinancialQuestionnaireTypeData[],
}

export type QuestionaireSuccessPayload = {
  customData: OnboardingQuestionaireData
}

export interface LegalDeclaration {
  id: number;
  name: string;
  storage_id: string;
  template_storage_id: string;
}

export type TradingPlatform = {
  trading_platform_name: string
  trading_platform_id: number
}

export type UploadDocumentsPayload = {
  files: any[],
  filesInfo: FileUploadRegisterData
}

export type RegisterBinaryDocumentPayload = {
  documentType: string,
  files: any[],
  isHiddenForClient: string,
  poiDocumentId: string,
  reviewSummary: string,
  subDocumentType: string,
  uploadedBy: string,
  uploadStamp: string,
}

export type RegisterBinaryPOIDocumentPayload = RegisterBinaryDocumentPayload & {
  review_summary: string;
  is_hidden_for_client?: boolean;
  identity_number?: string;
  expiration_date?: NullableString;
}

export type LegalDeclarationsDynamicFilePayload = {
  templateName: string,
  fileName: string
};

export type LegalDeclarationsDynamicFileResponse = {
  data: string
  customData: { fileName: string }
};

export enum AccountPrefixByTradingPlatformEnum {
  HammerWeb='HWL',
  TenN='TNN'
}
