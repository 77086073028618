import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import lang from '../../../../lib/language';
import { InboxResponse } from '../../../../lib/models/ams/types';
import { formatInboxreceptionTime } from '../../../../lib/util/DateTimeHelpers';

import { UI_ROUTES } from '../../../../util/routes';
import { ButtonTypesEnum } from '../../../Button/Button.types';
import { Button, ClickableDiv } from '../../../index';

import styles from './Notifications.module.scss';

type Props = {
  allMessages: InboxResponse[];
  hasUnreadMessages: boolean;
  handleClose: () => void;
  handleBtnMarkAllAsRead: () => void;
}

const SHOW_MAX_MESSAGES = 5;

const Notifications = (props: Props) => {
  const {
    allMessages,
    hasUnreadMessages,
    handleClose,
    handleBtnMarkAllAsRead,
  } = props;

  const history = useHistory();

  const btnMarkAllAsRead = () => {
    handleBtnMarkAllAsRead();
  };

  if (allMessages.length === 0) {
    return (
      <>
        <div className={styles.headerMessages}>
          <span>{lang.commonInboxTitle()}</span>
        </div>
        <p className={styles.noMessages}>{lang.commonInboxNoMessagesText2()}</p>
      </>
    );
  }

  return (
    <>
      <div className={styles.headerMessages}>
        <span>{lang.commonInboxTitle()}</span>
        <Button
          disabled={!hasUnreadMessages}
          variant={ButtonTypesEnum.Tertiary}
          className={styles.btnMarkAllAsRead}
          onClick={btnMarkAllAsRead}
        >
          {lang.commonInboxMarkAllAsRead()}
        </Button>
      </div>
      {allMessages.slice(0, SHOW_MAX_MESSAGES).map((message) => (
        <ClickableDiv
          key={message.id}
          className={styles.wrapperMessage}
          onClick={() => {
            history.push({
              pathname: `${UI_ROUTES.myAccountNotifications}`,
              state: { messageId: message.id, isViewAll: false },
            });
            handleClose();
          }}
        >
          <div className={styles.messageTitle}>
            <span className={message.unread
              ? styles.unreadRowDate
              : styles.readRowDate}
            >
              {formatInboxreceptionTime(message.receptionTime)}
            </span>
            { message.unread && <div className={styles.circle} />}
          </div>
          <Typography
            noWrap
            className={message.unread
              ? styles.unreadRowMessageSubject
              : styles.readRowMessageSubject}
          >
            {message.subject}
          </Typography>
        </ClickableDiv>
      ))}
      {
        allMessages.length > SHOW_MAX_MESSAGES && (
          <Button
            variant={ButtonTypesEnum.Link}
            className={styles.buttonViewAll}
            onClick={() => {
              history.push({
                pathname: `${UI_ROUTES.myAccountNotifications}`,
                state: { messageId: null, isViewAll: true },
              });
              handleClose();
            }}
          >
            {lang.commonViewAll()}
          </Button>
        )
      }
    </>
  );
};

export default Notifications;
