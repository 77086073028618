import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus, CurrencyEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import { CountryData } from '../../../lib/models/enroll';
import { RootState } from '../../../lib/store';
import { refreshPayments } from '../../../lib/store/common-actions';
import { setBankAccountDetailsOnCreate } from '../../../lib/store/crm';
import { setPaymentCallStatusToInitial } from '../../../lib/store/payment';
import { PaymentCall } from '../../../lib/store/payment/types';
import CountriesCache from '../../../lib/store-util/CountriesCache';
import { getBankNameValidity, getBicSwiftValidity, getIbanValidity } from '../../../lib/util/ValidationSchemes/Validate';

import backArrow from '../../../assets/img/icon-arrow_back.svg';
import {
  AutocompleteControl,
  BaseModal,
  Button,
  ClickableImage,
  CountryControl,
  HeaderLogo,
  InputControl,
} from '../../../components';
import { ICountryControlOptions } from '../../../components/CountryControl/CountryControl.types';
import { IOption } from '../../../components/SelectControl/SelectControl.types';
import { useBrowserWidth } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import AddBankAccountModal from '../makeYourFirstDeposit/modal/AddBankAccountModal';

import { BankAccountForm, DefaultBankAccountFormValue } from './AddYourBankAccount.constants';
import { ValidateForm } from './ValidateForm';

import styles from './AddYourBankAccount.module.scss';

type Props = {} & ReduxProps;

const AddYourBankAccount = (props: Props) => {
  const {
    individual,
    callsForRefresh,
    bankAccountDetailsOnCreate,
    requestStatusRegisterLinkedAccount,
    setBankAccountValuesOnCreate,
    setPaymentStatusByCall,
    refreshPaymentsData,
  } = props;

  const { browserWidthSize } = useBrowserWidth();

  const history = useHistory();
  const locationState = history.location.state as { target: string, previousPage: string };

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);
  const [formData, setFormData] = useState<BankAccountForm>(DefaultBankAccountFormValue);
  const [showModalAddBankAccount, setShowModalAddBankAccount] = useState<boolean>(false);

  const currencies = useMemo((): IOption[] =>
    [{ value: CurrencyEnum.USD, label: CurrencyEnum.USD }, { value: CurrencyEnum.EUR, label: CurrencyEnum.EUR }], []);

  const countryList = CountriesCache.use();
  const countryOptions = useMemo((): ICountryControlOptions[] => {
    if (countryList && (countryList.length > 0)) {
      return countryList
        .filter((country: CountryData) => country.id && country.name)
        .sort((current: CountryData, next: CountryData) => {
          if (current.name.toLowerCase() < next.name.toLowerCase()) {
            return -1;
          } if (current.name.toLowerCase() > next.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((country: CountryData) => ({
          label: country.name,
          value: country.id,
        }));
    }
    return [];
  }, [countryList]);

  useEffect(() => {
    if (!isInitialised && individual) {
      const updatedFormData: BankAccountForm = {
        ...formData,
        firstName: individual!.first_name,
        lastName: individual!.last_name,
        bank_name: bankAccountDetailsOnCreate.account_info.bank_name || '',
        bank_country_id: bankAccountDetailsOnCreate.account_info.bank_country_id
        || individual!.addresses[0].country_id,
        bic: bankAccountDetailsOnCreate.account_info.bic || '',
        iban: bankAccountDetailsOnCreate.account_info.iban || '',
        currency_code: currencies[0].value,
      };
      setFormData(updatedFormData);
      setIsInitialised(true);
      setPaymentStatusByCall();
    }
  }, [isInitialised, formData, individual, currencies, bankAccountDetailsOnCreate, setPaymentStatusByCall]);

  useEffect(() => {
    if (callsForRefresh) refreshPaymentsData();
  }, [callsForRefresh, refreshPaymentsData]);

  useEffect(() => {
    if (requestStatusRegisterLinkedAccount && isInitialised) {
      if (requestStatusRegisterLinkedAccount.status === CallStatus.ERROR) {
        history.push({
          pathname: UI_ROUTES.error,
          state: {
            callName: requestStatusRegisterLinkedAccount.callName,
            errorCode: requestStatusRegisterLinkedAccount.errorCode,
          },
        });
        setShowModalAddBankAccount(false);
      } else if (requestStatusRegisterLinkedAccount.status === CallStatus.READY) {
        setShowModalAddBankAccount(true);
      }
    }
  }, [history, isInitialised, requestStatusRegisterLinkedAccount]);

  const handleUploadStatement = () => {
    setIsClickedBtnContinue(true);
    if (ValidateForm(formData.bank_name, formData.bic, formData.iban)) {
      setBankAccountValuesOnCreate({
        bank_name: formData.bank_name,
        bank_country_id: formData.bank_country_id,
        bic: formData.bic,
        iban: formData.iban,
        currency_code: formData.currency_code,
      });
      history.push({
        pathname: UI_ROUTES.funds + UI_ROUTES.uploadBankStatement,
        state: {
          previousPage: locationState && locationState.previousPage,
        },
      });
    }
  };

  const handleBack = () => {
    setBankAccountValuesOnCreate({});
    history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit,
      state: { previousPage: UI_ROUTES.funds + UI_ROUTES.addYourBankAccount },
    });
  };

  return (
    <>
      <div className={styles.wrapperContainer}>
        <div className={styles.containerContent}>
          <div className={styles.wrapperContent}>
            <ClickableImage
              id="addBankAccountBackArrow"
              alt=""
              src={backArrow}
              className={styles.backArrow}
              onClick={handleBack}
            />
            <h3 className={styles.title} id="addBankAccountTitle">{lang.mobileAddBankAccTitle()}</h3>
            <span className={styles.description}>{lang.mobileAddBankAccSubtitle()}</span>
            <div className={styles.namesWrapper}>
              <div className={styles.line}>
                <p>{lang.commonOnboardingPersonalDetailsFirstName()}</p>
                <p>{formData.firstName}</p>
              </div>
              <div className={styles.line}>
                <p>{lang.commonOnboardingPersonalDetailsLastName()}</p>
                <p>{formData.lastName}</p>
              </div>
            </div>
            <InputControl
              id="addBankAccountBankName"
              name=""
              placeholder=""
              className={styles.input}
              label={lang.mobileAddBankAccInputBank()}
              value={formData.bank_name}
              onValueChange={(value: string | number) => {
                setFormData((currentState: BankAccountForm) => ({
                  ...currentState,
                  bank_name: value as string,
                }));
              }}
              errors={isClickedBtnContinue ? getBankNameValidity(formData.bank_name).errors : []}
            />
            <CountryControl
              id="addBankAccountCountry"
              name=""
              isRequired
              options={countryOptions}
              value={formData.bank_country_id}
              ariaLabel="Bank Country"
              placeholder={lang.mobileAddBankAccInputBankCountry()}
              onCountryChange={(countryId: string) => {
                setFormData((currentState: BankAccountForm) => ({
                  ...currentState,
                  bank_country_id: countryId,
                }));
              }}
            />
            <InputControl
              id="addBankAccountBicCode"
              name=""
              placeholder=""
              className={styles.input}
              label={lang.mobileAddBankAccInputBicSwift()}
              value={formData.bic}
              onValueChange={(value: string | number) => {
                setFormData((currentState: BankAccountForm) => ({
                  ...currentState,
                  bic: value as string,
                }));
              }}
              errors={isClickedBtnContinue ? getBicSwiftValidity(formData.bic).errors : []}
            />
            <InputControl
              id="addBankAccountIBAN"
              name=""
              placeholder=""
              className={styles.input}
              label={lang.mobileAddBankAccInputIBAN()}
              value={formData.iban}
              onValueChange={(value: string | number) => {
                setFormData((currentState: BankAccountForm) => ({
                  ...currentState,
                  iban: value as string,
                }));
              }}
              errors={isClickedBtnContinue ? getIbanValidity(formData.iban).errors : []}
            />
            <AutocompleteControl
              isReadOnly
              id="addBankAccountCurrency"
              options={currencies}
              placeholder={lang.mobileAddBankAccInputCurrency()}
              displayWithHighlights
              className={styles.input}
              value={formData.currency_code.toString()}
              onValueChanged={(value: string | number) => {
                setFormData((currentState: BankAccountForm) => ({
                  ...currentState,
                  currency_code: value as string,
                }));
              }}
            />
            <Button
              className={styles.uploadBtn}
              id="addBankAccountUpload"
              fullWidth
              onClick={handleUploadStatement}
            >
              {lang.mobileAddBankAccUploadBankStatementBtn()}
            </Button>
          </div>
        </div>
      </div>
      <BaseModal isOpen={showModalAddBankAccount}>
        {
          showModalAddBankAccount && (
          <AddBankAccountModal
            previousPage={locationState?.previousPage ?? ''}
            textInfoModal={
              {
                title: lang.mobileBankDetailsSetTitle(),
                description1: lang.webPopupBankDetailsDescription1(),
                description2: lang.webPopupBankDetailsDescription2(),
              }
            }
          />
          )
        }
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
  callsForRefresh: state.payment.callsForRefresh,
  bankAccountDetailsOnCreate: state.crm.enroll.bankAccountDetailsOnCreate,
  requestStatusRegisterLinkedAccount: state.payment.statusByCall[PaymentCall.registerLinkedAccount],
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  refreshPaymentsData: () => dispatch(refreshPayments()),
  setBankAccountValuesOnCreate: (data: any) => dispatch(setBankAccountDetailsOnCreate(data)),
  setPaymentStatusByCall: () => dispatch(setPaymentCallStatusToInitial(null)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddYourBankAccount);
