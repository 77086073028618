import lang from '../../lib/language';

export const DividendPayersTableHeaders = [
  lang.commonDiscoverSubtitleLongTermInvestmentsTableHeader1(),
  lang.commonDiscoverDividendPayersLegend(),
  lang.commonDiscoverInfoBoxDividendRate(),
  lang.commonDiscoverInfoBoxMarketCap(),
  lang.commonDiscoverInfoBoxVolume(),
];

export const HighGrowthTableHeaders = [
  lang.commonDiscoverSubtitleLongTermInvestmentsTableHeader1(),
  lang.commonDiscoverHighGrowthCompaniesLegend(),
  lang.commonDiscoverInfoBoxRevenue(),
  lang.commonDiscoverInfoBoxEps(),
  lang.commonDiscoverInfoBoxMarketCap(),
];

export const xAxisStyle = {
  axis: { stroke: 'none' },
  tickLabels: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fill: '#A0A3B9',
  },
};

export const CHART_HEIGHT = 201;
export const CHART_WIDTH = 524;
export const DATUM_SELECTOR = 'datum';

export const INITIAL_COMPANIES_PER_PAGE = 15;
