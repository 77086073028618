export const chartColors = ['#F6C98E', '#C7D2E6', '#FF8AC5', '#916FDD'];
export const data = {
  maintainAspectRatio: false,
  responsive: false,
  labels: ['APLL', 'b', 'c', 'd'],
  datasets: [
    {
      data: [1240.0, 150, 200, 350],
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
    },
  ],
};

export const setTextInChart = (chart: any) => {
  if (chart.config.options.elements.center) {
    // Get ctx from string
    const { ctx } = chart.chart;

    // Get options from the center object in options
    const centerConfig = chart.config.options.elements.center;
    const { fontStyle } = centerConfig;
    const txt = centerConfig.text;
    const txt2 = centerConfig.text2;
    const { color } = centerConfig;
    const colorValue = centerConfig.color2;
    const sidePadding = centerConfig.sidePadding || 20;
    const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

    // Start with a base font of 35px
    ctx.font = `35px ${fontStyle}`;

    // Get the width of the string and also the width of the element
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = chart.innerRadius * 2;
    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    // Set font settings to draw it correctly.
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.left + chart.chartArea.right) / 4.6;
    const textValueX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const textValueY = (chart.chartArea.left + chart.chartArea.right) / 3.5;
    ctx.font = `${fontSizeToUse - 3}px ${fontStyle}`;
    ctx.fillStyle = color;

    // Draw text in center
    ctx.fillText(txt, centerX, centerY);

    ctx.fillStyle = colorValue;
    ctx.font = `bold ${fontSizeToUse}px ${fontStyle}`;

    ctx.fillText(txt2, textValueX, textValueY);
  }
};

export const options = {
  cutoutPercentage: 70,
  legend: {
    display: false,
  },

  elements: {
    arc: {
      borderWidth: 0,
    },
    center: {
      text: 'Total Value',
      text2: '$ 38,690.00',
      color: '#C0C2D1',
      color2: '#2D2D2D',
      fontStyle: 'Cabin',
      sidePadding: 15,
    },
  },
};
