import { isString, uniq } from 'lodash';
import momenttz from 'moment-timezone';

import { logConfig } from '../../configDebug';
import { MARKET_DATA_TIME_ZONE } from '../constants/date-time.constants';
import {
  CallStatus, OrderSideEnum, OrderStatusEnum, TimeInForceEnum,
} from '../enums';
import { OrderTypeEnum } from '../enums/order-type.enum';
import lang from '../language';
import { CacheableCalls } from '../libSettings';
import { BaseSymbolData } from '../models/fundamental/types';
import { GWMarketDataSummaryBucket, SOROrderRequestDataBase, SOROrderSide, SOROrderType } from '../models/gateway/types';
import { BBOData, TradeData } from '../models/market-data/types';
import { ReportingCall } from '../models/reporting/types';
import { Order, Position } from '../models/trading/types';
import { FundamentalCall } from '../store/fundamental/types';
import { RootState } from '../store/index';
import { processSymbolSubscribesHelper, processSymbolUnsubscribesHelper } from '../store/market-data/helpers';
import { SubscribeGroup, SymbolLite } from '../store/market-data/types';
import { getFavoriteStocks, getTopMoversInitialItems } from '../store/selectors';
import { ModifyOrCancelOrderRequest } from '../store/trading/types';
import CallsCache from '../store-util/calls-cache/CallsCache';
import { composeSmallChartCallCacheParam } from '../store-util/calls-cache/helpers';
import SubscribeGroupCache from '../store-util/SubscribeGroupCache';
import { _setVisibleSymbolsCache } from '../store-util/VisibleSymbolsCache';

import { isCallStatusReady } from './error-handling/StatusByCallHelpers';
import { formatCurrency, isOneOf } from './DataHelpers';
import { extractSymbolsFromListAsArray, extractSymbolsFromListAsString, isMarketRegularHours } from './MarketDataHelpers';
import { getProp, getPropName } from './ObjectTools';
import { parseOrderTicketComponentToComponentType } from './SubscribeHelpers';
import {
  INVALID_VALUE, NullableNumber, NullableString, OrderTicketComponent, SubscriptonData, TypedObject,
} from './types';

const VALID_ORDER_SIDES: string[] = [
  OrderSideEnum.Buy,
  OrderSideEnum.Sell,
] as string[];

export function mapOrderTimeInForce(value: NullableString): string {
  switch (value) {
    case 'DAY':
      return lang.commonOrderTimeInForceDay();

    case 'ON_OPEN':
      return lang.commonOrderTimeInForceOnOpen();

    case 'IOC':
      return lang.commonOrderTimeInForceIOC();

    case 'EXTENDED_DAY':
      return lang.commonOrderTimeInForceExtDay();

    case 'ON_CLOSE':
      return lang.commonOrderTimeInForceOnClose();

    case 'GTC':
      return 'GTC';

    default:
      if (value !== null && value !== undefined && value.length > 0) {
        return value;
      }
      return INVALID_VALUE;
  }
}

export function calculatePositionExposure(position: Position, currentPrice: number): number {
  /**
   * Get the absolute value of the parsed quantity
   * For short positions qty is negative
   */
  return Math.abs(position.qty) * currentPrice;
}

export function calculatePositionUnrealizedPnL(position: Position, currentPrice: number): number {
  const positionQty = position.qty;
  const absolutePositionQty = Math.abs(positionQty);

  /**
   * positionQty more than zero indicates that the position is 'Long'
   */
  if (positionQty > 0) {
    /**
     * Unrealized PnL for 'Long' positions
     *
     * Get absolute value of positionQty(if it -1, -25 ...etc. 1, 25 will be returned)
     */
    return (currentPrice * absolutePositionQty) - (position.avgPrice * absolutePositionQty);
  }

  /**
   * positionQty less than zero indicates that the position is 'Short'
   *
   * Unrealized PnL for 'Short' positions
   */
  return (position.avgPrice * absolutePositionQty) - (currentPrice * absolutePositionQty);
}

export function calculatePositionUnrealizedPnLInPercentage(position: Position, currentPrice: number): number {
  const positionQty = position.qty;
  const absolutePositionQty = Math.abs(positionQty);
  const unrealizedPnL = calculatePositionUnrealizedPnL(position, currentPrice);

  /**
   * positionQty more than zero indicates that the position is 'Long'
   */
  if (positionQty > 0) {
    /**
     * Unrealized PnL for 'Long' positions
     *
     * Get absolute value of positionQty(if it -1, -25 ...etc. 1, 25 will be returned)
     */
    return (unrealizedPnL / (position.avgPrice * absolutePositionQty)) * 100;
  }

  /**
   * positionQty less than zero indicates that the position is 'Short'
   *
   * Unrealized PnL for 'Short' positions
   */
  return (unrealizedPnL / (currentPrice * absolutePositionQty)) * 100;
}

export function getMarketDataTopic(response: any): string | null {
  return (response.topic
    || ((getProp(response, '0') === 'heartbeat') && 'heartbeat')
    || getPropName(response, 'query')
    || getPropName(response, 'subscribe')
    || getPropName(response, 'login')
  );
}

export function calculateSymbolNBBOAndTradePrices(
  symbol: NullableString,
  side: NullableString,
  nbboPrices: TypedObject<BBOData>,
  tradePrices: TypedObject<TradeData[]>,
) {
  if (symbol == null || !isString(symbol)) {
    throw new Error(`[TradingHelpers] calculateSymbolPrices - invalid value for symbol - '${symbol}'`);
  }
  // if ((OrderSideEnum as any)[side!] == null) {
  if (!VALID_ORDER_SIDES.includes(side!)) {
    throw new Error(`[TradingHelpers] calculateSymbolPrices - invalid value for side - '${side}'`);
  }

  const nbboAskPrice: NullableNumber = nbboPrices[symbol!]?.askprice;
  const nbboBidPrice: NullableNumber = nbboPrices[symbol!]?.bidprice;
  const trades = tradePrices[symbol!] ?? [];
  const tradePrice: NullableNumber = trades[trades.length - 1].tradeprice;

  let symbolPrice;
  if (side === OrderSideEnum.Buy) {
    symbolPrice = nbboAskPrice;
  } else {
    symbolPrice = nbboBidPrice;
  }

  return {
    symbolPrice, nbboAskPrice, nbboBidPrice, tradePrice,
  };
}

export function orderStatusToCallStatus(orderStatus?: OrderStatusEnum | null) {
  let callStatus: CallStatus;

  switch (orderStatus) {
    case OrderStatusEnum.Accepted:
    case OrderStatusEnum.Queued:
    case OrderStatusEnum.PartiallyFilled:
    case OrderStatusEnum.Filled:
    case OrderStatusEnum.Cancelled:
    case OrderStatusEnum.Modified:
      callStatus = CallStatus.READY;
      break;

    case OrderStatusEnum.Rejected:
    case OrderStatusEnum.TimedOut:
      callStatus = CallStatus.ERROR;
      break;

    default:
      callStatus = CallStatus.INITIAL;
      break;
  }

  return callStatus;
}

export const mapOrderSORtoSide = (sorSide: SOROrderSide) => {
  switch (sorSide) {
    case SOROrderSide.Buy:
      return OrderSideEnum.Buy;
    case SOROrderSide.Sell:
      return OrderSideEnum.Sell;
    case SOROrderSide.SellShort:
      return OrderSideEnum.SellShort;

    default:
      console.warn(`[TradingHelpers] mapOrderSide - unknown input value - ${sorSide} - using 'Buy' by default`);
      return OrderSideEnum.Buy;
  }
};

export function setSOROrderData(data: Order | ModifyOrCancelOrderRequest) {
  const {
    destination,
    symbol,
    clientOrderId,
    orderQty,
    ordType,
    side = OrderSideEnum.Buy,
    stopPrice,
    price,
    timeInForce,
    includeExtendedHours,
    traceIdentifier,
  } = data as Order;
  let { order, account } = data as ModifyOrCancelOrderRequest;
  if (!order) account = (data as ModifyOrCancelOrderRequest).account;
  const orderData: SOROrderRequestDataBase = {
    clientOrderId: clientOrderId!,
    quantity: orderQty!,
    destination: destination ?? 'INCA',
    account,
    side,
    symbol: symbol!,
    timeInForce: timeInForce as TimeInForceEnum,
    traceIdentifier,
    tradingSessionId: includeExtendedHours ? 'X' : 'M',
  };

  switch (ordType) {
    case OrderTypeEnum.Market:
      orderData.type = 'MarketOrder';
      break;
    case OrderTypeEnum.Limit:
      orderData.type = 'LimitOrder';
      orderData.price = price;
      break;
    case OrderTypeEnum.Stop:
      orderData.type = 'StopOrder';
      orderData.stopPrice = stopPrice;
      break;
    default:
      console.error(`[Global.service] Error setting order type -- ${ordType}`);
      break;
  }

  return orderData;
}

export let prevSubscribeComponent: OrderTicketComponent;

/**
 * Sets the VisibleSymbolsCache to be used when optimizing subscriptions management.
 * Used only in library - by `manageSubscriptions` function above
 * @param symbols Symbols to set. If null - `state` must be provided
 * @param component The component that is visible
 */
const setVisibleSymbols = (symbols: string[] | null, component: OrderTicketComponent) => {
  const { USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH, USE_ONLY_VISIBLE_SYMBOLS_IF_NOT_AUTO_UNSUBSCRIBE } = require('../libSettings');
  const useVisible = !USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH && USE_ONLY_VISIBLE_SYMBOLS_IF_NOT_AUTO_UNSUBSCRIBE;
  if (!useVisible) return;

  const group = parseOrderTicketComponentToSubscribeGroup(component);

  const { symbols: groupSymbols } = SubscribeGroupCache.get(group!);
  const allSymbols = (symbols ?? []).concat(groupSymbols);

  if (!symbols) {
    switch (group) {
      case 'symbol-details':
      case 'create-order': {
        const { store } = require('../../configLib').default;
        const state: RootState = store.getState();
        const peopleAlsoTradeSymbols = extractSymbolsFromListAsArray(state.reporting.peopleAlsoTradeData);
        allSymbols.push(...peopleAlsoTradeSymbols);
        break;
      }

      default: {
        break;
      }
    }
  }

  if (group) {
    _setVisibleSymbolsCache(uniq(allSymbols), group);
  } else {
    _setVisibleSymbolsCache([], component);
  }
};

export const parseOrderTicketComponentToSubscribeGroup = (component: OrderTicketComponent) => {
  let group: SubscribeGroup | null = null;
  switch (component) {
    case 'initial':
      group = component;
      break;

    case 'watchlist/popular':
    case 'watchlist/favorites':
    case 'watchlist':
      group = 'watchlist';
      break;

    case 'discover':
    case 'discover/top-movers':
    case 'discover/popular-group':
      group = 'discover';
      break;

    case 'discover/new-on-the-market':
      group = 'discover';
      break;

    case 'my-account':
    case 'my-account/positions':
    case 'my-account/orders':
    case 'my-account/account-breakdown':
      group = 'my-account';
      break;

    case 'history': {
      group = component;
      break;
    }

    case 'people-also-trade':
      group = component;
      break;

    case 'symbol-details': {
      group = 'symbol-details';
      break;
    }

    case 'chart-details': {
      group = 'chart-details';
      break;
    }

    case 'create-order': {
      group = 'create-order';
      break;
    }

    case 'modify-order': {
      group = 'modify-order';
      break;
    }

    case 'funds': {
      group = null;
      break;
    }

    case 'profile': {
      group = null;
      break;
    }

    default:
      break;
  }

  return group;
};

export const prepareSubscriptonData = (
  group: SubscribeGroup | undefined | null,
  isWeb: boolean,
  component: OrderTicketComponent | undefined,
  useAutoUnsubscribe: boolean,
  state: RootState,
  extraData: unknown,
): SubscriptonData => {
  let symbols: string[] = [];
  const {
    subscribedSymbols,
    subscribedByFeed,
    currentGroup: currentGroupInCache,
    prevGroup: prevGroupInCache,
  } = SubscribeGroupCache.getAllSubscribed();
  const prevGroup = currentGroupInCache !== group ? currentGroupInCache : prevGroupInCache;
  const isPreviousGroupNBBOSubscribed = isWeb
    ? isOneOf<SubscribeGroup>(prevGroup, [ 'symbol-details', 'create-order' ])
    : prevGroup === 'create-order';
  const isNewGroupNBBOSubscribed = isWeb ? isOneOf<SubscribeGroup>(group, [
    'symbol-details',
    'create-order',
  ]) : group === 'create-order';
  const nbboSubscribes = subscribedByFeed.nbbo ?? subscribedByFeed['trade,nbbo'];
  const isNBBOUnsubscribeCase = isPreviousGroupNBBOSubscribed && !isNewGroupNBBOSubscribed && !!nbboSubscribes;
  if (isNBBOUnsubscribeCase) {
    processSymbolUnsubscribesHelper({ symbolOrSymbols: nbboSubscribes!.join(), caller: `manageSubscriptions/${component}`, feed: 'nbbo' });
  }

  let skipCase = false;
  const skipUnsubscribe = !useAutoUnsubscribe;
  let reInitializeGroup = true;
  let hasItems = true;
  let isAllLoaded = false;

  switch (component) {
    case 'watchlist/favorites': {
      const { favouriteStocks } = state.marketData;
      const userCorrelationId = state.crm.individualExtendedInfo?.user_correlation_id;

      if (userCorrelationId) {
        const stocksByCurrentAccount = favouriteStocks[userCorrelationId];

        if (stocksByCurrentAccount.length > 0) {
          stocksByCurrentAccount.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
          reInitializeGroup = true;
        } else {
          skipCase = true;
        }
      }

      break;
    }
    case 'watchlist/popular':
    case 'watchlist': {
      const { popularStocks } = state.fundamental;

      hasItems = popularStocks.length > 0;
      isAllLoaded = isCallStatusReady(FundamentalCall.getPopularStocks, state.fundamental.statusByCall);
      if (hasItems && isAllLoaded) {
        popularStocks.forEach(({ symbol }) => symbols.push(symbol));
        reInitializeGroup = true;
      } else {
        skipCase = true;
      }
      break;
    }
    case 'discover': {
      const { newStocks } = state.fundamental;
      const { topGainers, topLosers } = getTopMoversInitialItems();
      hasItems = topGainers.length > 0 && topLosers.length > 0 && newStocks.length > 0;
      isAllLoaded = (
        isCallStatusReady(FundamentalCall.getTopGainers, state.fundamental.statusByCall)
        && isCallStatusReady(FundamentalCall.getTopLosers, state.fundamental.statusByCall)
        && isCallStatusReady(FundamentalCall.getNewStocks, state.fundamental.statusByCall)
      );
      if (hasItems && isAllLoaded) {
        topGainers.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
        topLosers.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
        newStocks.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
        reInitializeGroup = true;
      } else {
        skipCase = true;
      }
      break;
    }
    case 'discover/top-movers':
    case 'discover/new-on-the-market':
    case 'discover/popular-group': {
      const sectorDetails: BaseSymbolData[] = state.fundamental.sectorDetails.content;

      hasItems = sectorDetails.length > 0;
      isAllLoaded = isCallStatusReady(FundamentalCall.getSectorDetails, state.fundamental.statusByCall);

      if (hasItems && isAllLoaded) {
        sectorDetails.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
      } else {
        skipCase = true;
      }

      break;
    }
    case 'my-account': {
      const { pendingOrders, openPositions } = state.reporting;
      hasItems = pendingOrders.length > 0 && openPositions.length > 0;
      isAllLoaded = (
        isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall)
        && isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall)
      );
      if (hasItems && isAllLoaded) {
        pendingOrders.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
        openPositions.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
        reInitializeGroup = true;
      } else {
        skipCase = true;
      }
      break;
    }

    case 'my-account/orders': {
      const { pendingOrders } = state.reporting;
      const { symbols: myAccountSubscribedSymbols } = SubscribeGroupCache.get('my-account');
      hasItems = pendingOrders.length > 0;
      isAllLoaded = isCallStatusReady(ReportingCall.getAccountPendingOrders, state.reporting.statusByCall);

      if (hasItems && isAllLoaded) {
        pendingOrders.forEach(({ symbol }) => !isOneOf(symbol, myAccountSubscribedSymbols) && symbols.push(symbol));
        reInitializeGroup = false;
      } else {
        skipCase = true;
      }
      break;
    }

    case 'my-account/positions':
    case 'my-account/account-breakdown': {
      const { openPositions } = state.reporting;
      hasItems = openPositions.length > 0;
      isAllLoaded = isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall);
      const { symbols: myAccountSubscribedSymbols } = SubscribeGroupCache.get('my-account');

      if (hasItems && isAllLoaded) {
        openPositions.forEach(({ symbol }) => !isOneOf(symbol, myAccountSubscribedSymbols) && symbols.push(symbol));
        reInitializeGroup = false;
      } else {
        skipCase = true;
      }
      break;
    }

    case 'people-also-trade': {
      const { peopleAlsoTradeData } = state.reporting;
      hasItems = peopleAlsoTradeData.length > 0;

      isAllLoaded = isCallStatusReady(ReportingCall.getPeopleAlsoTradeData, state.reporting.statusByCall);

      if (hasItems && isAllLoaded) {
        peopleAlsoTradeData.forEach(({ symbol }) => !subscribedSymbols[symbol] && symbols.push(symbol));
      }

      break;
    }

    case 'symbol-details':
    case 'create-order':
    case 'modify-order':
    case 'chart-details': {
      if (typeof extraData === 'string') {
        symbols.push(extraData);
      }
      if (component !== 'chart-details') {
        reInitializeGroup = true;
      }
      break;
    }

    case 'funds': {
      SubscribeGroupCache.setGroupOnly('funds');
      break;
    }

    case 'profile': {
      SubscribeGroupCache.setGroupOnly('profile');
      break;
    }

    case 'history': {
      SubscribeGroupCache.setGroupOnly('history');
      break;
    }

    default:
      skipCase = true;
      break;
  }
  symbols = uniq(symbols);

  return {
    symbols,
    subscribedByFeed,
    skipCase,
    skipUnsubscribe,
    reInitializeGroup,
    hasItems,
    isAllLoaded,
  };
};


/**
 * Manages subscriptions by grouping symbols and groups and group-components. Calls subscribe and unsubscribe endpoints.
 * For example the `watchlist` group has either `watchlist/popular` component or `watchlist/favorites` component currently focused.
 * This helper function `manageSubscriptions` is called in two ways:
 *  - when a component is focused - the special mode with `isSetGroupOnly=true` is used to switch to it.
 *    For example on did-mount useEffect:
 *      useEffect(() => {
 *        manageSubscriptions('my-account/orders', null, true);
 *      }, []);
 *  - then when the data arrives - the success/complete action is calling `manageSubscriptions` to do the actual subscribe/unsubscribe calls.
 *    Here is an example in `market-data/epics.ts` in `subscribePositionSymbolsEpic`
 *      manageSubscriptions('my-account/positions', symbolOrSymbols);
 *    where `symbolOrSymbols` are all symbols that need to be subscribed (either only for the component or for the whole group e.i. all components)
 * */
export function manageSubscriptions(
  component: OrderTicketComponent,
  extraData?: unknown,
  isSetGroupOnly?: boolean,
  isInitialComponent?: boolean,
) {
  const configLib = require('../../configLib').default;
  const state: RootState = configLib.store.getState();
  const isWeb = configLib.isWeb;
  const group: SubscribeGroup | undefined | null = parseOrderTicketComponentToSubscribeGroup(component);

  if (isInitialComponent) {
    SubscribeGroupCache.setComponent(parseOrderTicketComponentToComponentType(component));
  }
  // @@@ debug
  console.log(`[@@@] MANAGE - ${component}, ${isSetGroupOnly ? 'GROUP' : ''}`); // eslint-disable-line

  if (isSetGroupOnly) {
    // get chart - if during regular hours and CallsCache has expired
    const isPopularGroupComponent = component === 'discover/popular-group';
    if (isMarketRegularHours() && !isPopularGroupComponent && isSmallChartComponent(component)) {
      const callName: CacheableCalls = 'getChartingData';
      const params = composeSmallChartCallCacheParam(component, typeof extraData === 'string' ? extraData : '');
      const symbolOrSymbols = getSymbolsForComponent(component, state);
      if (symbolOrSymbols) {
        const { default: GlobalService } = require('../services/Global.service');
        const requestFunctionReturnsTraceId = () => GlobalService.globalService.getWatchlistChartData(
          symbolOrSymbols,
          component,
          GWMarketDataSummaryBucket.OneDay,
        );
        CallsCache.processRequest(callName, params, requestFunctionReturnsTraceId);
      }
    }
    if (group) {
      SubscribeGroupCache.setGroupOnly(group);
      if (configLib.__DEV__ && logConfig.subscriptionsFlow) {
        console.tron.log!(`[manageSubscriptions][Summary] Set group only - group: ${group}, component: ${component}`);
        console.tron.log!(`[manageSubscriptions][Detailed] Set group only - group: ${group}, component: ${component}`);
        console.log(`[manageSubscriptions] Set group only - group: ${group}, component: ${component}`);
        console.debug(`[manageSubscriptions] Set group only - group: ${group}, component: ${component}`);
      }
    } else {
      console.error(`[TradingHelpers] manageSubscriptions - group is invalid for component ${component}`);
    }
    setVisibleSymbols(null, group);
    prevSubscribeComponent = component;
    return;
  }

  const { USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH } = require('../libSettings');
  const {
    symbols,
    subscribedByFeed,
    skipCase,
    skipUnsubscribe,
    reInitializeGroup,
    hasItems,
    isAllLoaded,
  } = prepareSubscriptonData(
    group,
    isWeb,
    component,
    USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH,
    state,
    extraData,
  );

  setVisibleSymbols(symbols, group);

  if (configLib.__DEV__ && logConfig.subscriptionsFlow) {
    console.tron.log!(`[manageSubscriptions][Summary]${skipCase ? ' SKIP' : ''} (hasItems:${hasItems}, isAllLoaded:${isAllLoaded}) component: '${prevSubscribeComponent} -> ${component}' group: ${group}, symbols:${symbols.length} [s-flow]`);
    console.tron.log!(`[manageSubscriptions][Detailed]${skipCase ? ' SKIP' : ''} (hasItems:${hasItems}, isAllLoaded:${isAllLoaded}) component: '${prevSubscribeComponent} -> ${component}' group: ${group}, symbols: ${symbols.length} | ${symbols} [s-flow]`);
    console.info(`[manageSubscriptions]${skipCase ? ' SKIP' : ''} (hasItems:${hasItems}, isAllLoaded:${isAllLoaded}) component: '${prevSubscribeComponent} -> ${component}' group: ${group}, symbols:${symbols.length} [s-flow]`);
    console.debug!(`[manageSubscriptions]${skipCase ? ' SKIP' : ''} (hasItems:${hasItems}, isAllLoaded:${isAllLoaded}) component: '${prevSubscribeComponent} -> ${component}' group: ${group}, symbols: ${symbols.length} | ${symbols} [s-flow]`);
  }

  if (skipCase) {
    if (component) prevSubscribeComponent = component;
    return;
  }

  if (symbols.length && group) {
    // subscribe symbols for a component with stocks
    processSymbolSubscribesHelper({
      symbolOrSymbols: symbols.join(),
      group,
      reInitializeGroup,
      caller: `manageSubscriptions/${component}`,
      component,
    });
  } else if (!group && !skipUnsubscribe) {
    // unsubscribe all symbols for a component without stocks
    for (const feed in subscribedByFeed) {
      const unsubscribes = (subscribedByFeed as any)[feed];
      processSymbolUnsubscribesHelper({ symbolOrSymbols: unsubscribes.join(), caller: `manageSubscriptions/${component}` });
    }
  }
  if (component) prevSubscribeComponent = component;
}

export function parseOrderProp(value: any, name: string) {
  if (value == null) return INVALID_VALUE;

  let result = value;

  switch (name) {
    case 'updatedAt':
    case 'createdAt':
      // Reading data as UTC and showing it in EST (New York time)
      // result = moment(value).utc().format('YYYY-MM-DD HH:mm:s.SSS [UTC]');
      result = momenttz.tz(value, MARKET_DATA_TIME_ZONE).format('YYYY-MM-DD HH:mm:ss z');
      break;

    case 'price':
      result = formatCurrency(value);
      break;

    case 'status':
      result = mapOrderStatus(value);
      break;

    case 'timeInForce':
      result = mapOrderTimeInForce(value);
      break;

    default:
      console.warn(`[DataHelpers] parseOrderProp - unknown prop name '${name}' with value '${value}'`);
      break;
  }

  if (result == null) {
    result = INVALID_VALUE;
  }


  return result;
}

export const mapOrderStatus = (orderStatus?: NullableString): string => {
  if (!orderStatus) return INVALID_VALUE;

  let result = '';
  const asArray = orderStatus.split(' ');
  asArray.forEach((word, index) => {
    result += (index > 0 ? ' ' : '') + word.charAt(0).toUpperCase() + word.substr(1);
  });

  return result;
};

export function getExecutionPriceType(orderSide: OrderSideEnum) {
  switch (orderSide) {
    case OrderSideEnum.Buy:
      return 'Ask';
    case OrderSideEnum.Sell:
      return 'Bid';

    default:
      return 'n/a';
  }
}

export const mapOrderType = (side: SOROrderSide, type: SOROrderType, isInPendingOrderTable = false): string => {
  if (!type || !side) return INVALID_VALUE;

  let result: string = SOROrderType[type];

  switch (side) {
    case SOROrderSide.Buy:
      result += ` ${lang.commonOrderSideButtonBuy()}`;
      break;
    case SOROrderSide.Sell:
      result += ` ${lang.commonOrderSideButtonSell()}`;
      break;
    case SOROrderSide.SellShort:
      result += isInPendingOrderTable ? ` ${lang.commonShortSell()}` : ` ${lang.commonOrderSideButtonSell()}`;
      break;
    default:
      break;
  }

  return result;
};

export const convertOrderSideToText = (side: OrderSideEnum): string => {
  switch (side) {
    case OrderSideEnum.Buy: {
      return lang.commonOrderSideButtonBuy();
    }
    case OrderSideEnum.Sell: {
      return lang.commonOrderSideButtonSell();
    }
    case OrderSideEnum.SellShort: {
      return lang.commonShortSell();
    }
    default: return '';
  }
};

const isSmallChartComponent = (component: OrderTicketComponent) => (isOneOf<OrderTicketComponent>(
  component,
  [
    'watchlist',
    'watchlist/popular',
    'watchlist/favorites',
    'discover',
    'discover/top-gainers-all',
    'discover/top-losers-all',
    'discover/new-on-the-market',
    'discover/popular-group',
  ],
));

const getSymbolsForComponent = (component: OrderTicketComponent, state: RootState) => {
  const { isWeb } = require('../../configLib').default ?? {};
  let result: string = '';

  switch (component) {
    case 'watchlist':
    case 'watchlist/popular':
    case 'watchlist/favorites': {
      const { popularStocks } = state.fundamental;
      const favouriteStocks = getFavoriteStocks(state);
      const watchlistStocks: SymbolLite[] = (popularStocks as SymbolLite[]).concat(...favouriteStocks);
      result = extractSymbolsFromListAsString(watchlistStocks);

      break;
    }

    case 'discover': {
      const { topGainers, topLosers } = getTopMoversInitialItems();
      // Setting symbols for Web only since mobile doesn't have any items with visible smallcharts on main screen of Discover
      if (isWeb) {
        const discoverStocks: SymbolLite[] = (topGainers as SymbolLite[]).concat(...topLosers);
        result = extractSymbolsFromListAsString(discoverStocks);
      }
      break;
    }

    case 'discover/top-gainers-all': {
      const { topGainers } = getTopMoversInitialItems();
      result = extractSymbolsFromListAsString(topGainers);
      break;
    }

    case 'discover/top-losers-all': {
      const { topLosers } = getTopMoversInitialItems();
      result = extractSymbolsFromListAsString(topLosers);
      break;
    }

    case 'discover/new-on-the-market': {
      const { newStocks } = state.fundamental;
      result = extractSymbolsFromListAsString(newStocks);
      break;
    }

    case 'discover/popular-group': {
      const { sectorDetails } = state.fundamental;
      result = extractSymbolsFromListAsString(sectorDetails.content);
      break;
    }

    default:
      break;
  }

  return result;
};
