import config from '../../config';
import { logFilterOptions, tronLog } from '../../configDebug';
import LibraryConfig from '../../configLib';
import { GWQueryCase } from '../models/gateway/types';
import { BBOData, Subscriptions, SubscriptionTopic, TradeData } from '../models/market-data/types';
import { QueriesCacheType } from '../models/trading/types';
import { marketDataDisconnected } from '../store/common-actions';
import { connected } from '../store/market-data';
import { BBODataCache, InstrumentSnapshotMap, TradeDataCache } from '../store/market-data/types';
import { updateNBBOPricesCache } from '../store-util/NBBOPriceCache';
import { updateTradePriceCacheFromTrade } from '../store-util/TradePriceCache';
import { dataArrayToMap, processOHLCVResponse } from '../util/MarketDataHelpers';
import { checkFilter, getObjectKeysAsString, getProp } from '../util/ObjectTools';
import { getMarketDataTopic } from '../util/TradingHelpers';

import { IMarketDataService } from './types';


const { __DEV__, WebSocket: WebSocketImpl } = LibraryConfig;

export enum MarketDataClientStatus {
  INITIAL,
  SOCKET_CONNECTING,
  SOCKET_CONNECTED,
  SOCKET_CONNECTION_FAILED,
  LOGGING_IN,
  LOGIN_FAILED,
  SUCCESSFUL,
  CLOSED
}

type Dispatcher = (action: any) => any;


LibraryConfig.getRandomValuesInit();
const uuidv4 = require('uuid').v4;

let ohlcvQueriesCache: QueriesCacheType = {};

export class MarketDataClientService implements IMarketDataService {
  private ws?: WebSocket;

  authToken?: string;

  dispatch: Dispatcher;

  status = MarketDataClientStatus.INITIAL;

  connectionPromise?: Promise<any>;

  connectionPromiseResolver?: (value?: any) => void;

  connectionPromiseRejector?: (err: any) => void;

  asyncRef = 0;

  promiseCompleters: { [key: string]: { resolve: (data?: any) => any, reject: (err?: any) => any } } = {};

  subscriptions: Subscriptions = {
    bbo: [],
    nbbo: [],
    trade: [],
    book: [],
    ohlcv: [],
  };


  constructor(dispatch: Dispatcher) {
    this.dispatch = dispatch;
    this.sendRequest = this.sendRequest.bind(this);
    this.handleMDWebsocketMessages = this.handleMDWebsocketMessages.bind(this);
  }

  public setToken = (token: string): void => {
    this.authToken = token;
    this.reconnect(token);
  };

  connect(token?: string) {
    if (token == null) {
      throw new Error('[MD] Must specify a token in order to connect');
    }
    this.authToken = token;

    const url = config.marketData.url;
    console.info(`[MD] Connecting to ${url}`);
    console.debug(`[MD] Connecting to ${url} token`, token);

    if (
      [
        MarketDataClientStatus.SOCKET_CONNECTED,
        MarketDataClientStatus.SOCKET_CONNECTING,
        MarketDataClientStatus.LOGGING_IN,
        MarketDataClientStatus.SUCCESSFUL,
      ].includes(this.status)
    ) return;

    this.connectionPromise = new Promise((resolve, reject) => {
      this.connectionPromiseResolver = resolve;
      this.connectionPromiseRejector = reject;
    });

    this.status = MarketDataClientStatus.SOCKET_CONNECTING;

    this.ws = new WebSocketImpl(url);
    this.checkConnectionTimeout(this.ws as any, 10000);

    this.ws!.onopen = () => {
      console.info('[MD] Websocket connection established!');
      this.status = MarketDataClientStatus.LOGGING_IN;

      this.sendRequest('login', [ 'login', { token } ]);
    };

    this.ws!.onclose = (event: any) => {
      console.warn('[MD] Alaric Market Data Disconnected', event);
      const currentStatus = this.status;

      // Since close is called after failures as well, we set closed status only if it is not a failure
      if (!this.isConnectionFailed()) {
        this.status = MarketDataClientStatus.CLOSED;
      }

      this.dispatch(marketDataDisconnected({ message: "'onClose' event received" }));

      // Reject the promise if it is not resolved previously
      if (currentStatus !== MarketDataClientStatus.SUCCESSFUL && this.connectionPromiseRejector) {
        // TODO: ART-334
        this.connectionPromiseRejector('[MD] Connection closed');
      }
    };

    this.ws!.onmessage = this.handleMDWebsocketMessages;

    this.ws!.onerror = (err: any) => {
      console.error('[MD] Web socket error', err);
      const currentStatus = this.status;
      this.status = MarketDataClientStatus.SOCKET_CONNECTION_FAILED;
      this.dispatch(marketDataDisconnected({ message: `${err}` }));
      // Reject the promise if it is not resolved previously
      if (currentStatus !== MarketDataClientStatus.SUCCESSFUL && this.connectionPromiseRejector) {
        // TODO: ART-334
        this.connectionPromiseRejector('[MD] Web socket connection error');
      }
    };
  }

  isConnecting = () => [
    MarketDataClientStatus.SOCKET_CONNECTED,
    MarketDataClientStatus.SOCKET_CONNECTING,
    MarketDataClientStatus.LOGGING_IN,
  ].includes(this.status);

  isConnectionFailed = () => [
    MarketDataClientStatus.SOCKET_CONNECTION_FAILED,
    MarketDataClientStatus.LOGIN_FAILED,
  ].includes(this.status);

  private checkConnectionTimeout(ws: WebSocket, timeout: number = 20000) {
    setTimeout(() => {
      if ((ws as any).readyState !== WebSocketImpl.OPEN) {
        (ws as any).close();
        this.status = MarketDataClientStatus.SOCKET_CONNECTION_FAILED;
      }
    }, timeout);
  }

  reconnect(token?: string) {
    this.disconnect();
    this.connect(token ?? this.authToken);
  }

  disconnect() {
    console.info('[MD] Disconnecting');
    if (this.ws) {
      try {
        this.ws!.close();
      } catch (exp) {
        console.warn('[MD] Socket close error', exp);
      }
    }
  }

  sendRequest(callName: string, requestBody: any) {
    if (__DEV__) {
      console.info(`[MD] Request "${callName}" (${getObjectKeysAsString(requestBody)})`);
      console.debug('[MD] Full request body', requestBody);
      if (checkFilter(tronLog.md, callName)) {
        let topic = requestBody.topic || getProp(requestBody, '1.topic');
        if (topic) topic = ` topic: ${topic}`;

        console.tron.display({
          name: `MD-request "${callName}"${topic || ''}`,
          value: requestBody,
          important: false,
        });
      }
    }

    this.ws!.send(JSON.stringify(requestBody));
  }

  handleMDWebsocketMessages(msg: any) {
    const response = JSON.parse(msg.data);
    const topic = getMarketDataTopic(response);

    if (__DEV__) {
      const isHeartBeat = !!msg.data.match('heart');

      if (!isHeartBeat || (isHeartBeat && logFilterOptions.heartbeat)) {
        if (topic === 'trade') {
          console.debug('[MD] Message full body \'trade\'', response);
        } else {
          console.info(`[MD] Message '${topic}' (${getObjectKeysAsString(response)})`);
          console.debug(`[MD] Message full body '${topic}'`, response);
        }
        if (checkFilter(tronLog.md, topic)) {
          console.tron.display({
            name: topic ? `MD-msg "${topic}"` : 'MD-UNKNOWN',
            value: response,
            important: !topic,
          });
        }
      }
    }

    if (response) {
      if ('login' in response) {
        this.handleLoginResult(response);
      } else if (topic) {
        this.handleTopicMessage(topic, response);
      } else if ('subscribe' in response) {
        this.handleSubscribeResult(response);
      } else if ('unsubscribe' in response) {
        this.handleUnsubscribeResult(response);
      } else if ('error' in response) {
        this.handleErrorResponse(response);
      } else if (Array.isArray(response) && response[0] === 'heartbeat') {
        // ignore, heartbeat message
      } else if (response.query == null) console.warn('[MD] Unknown message:', response);
    }
  }

  handleErrorResponse(response: any) {
    if (response.ref) {
      const completers = this.promiseCompleters[response.ref];
      if (completers) {
        completers.reject(response.error);
      }
      delete this.promiseCompleters[response.ref];
    }
  }

  handleLoginResult(response: any) {
    if ('login' in response) {
      if (response.data === true) {
        this.status = MarketDataClientStatus.SUCCESSFUL;
        this.dispatch(connected());
        // TODO: ART-334
        this.connectionPromiseResolver!();
      } else {
        this.status = MarketDataClientStatus.LOGIN_FAILED;
        this.dispatch(marketDataDisconnected({ message: `Login failed (${response?.message || response})` }));
        console.error('[MD] Login failed', response);
        // TODO: ART-334
        this.connectionPromiseRejector!('Login failed');
      }
    }
  }

  handleSubscribeResult(response: any) {
    if ('subscribe' in response) {
      if (response.subscribe === true && response.ref) {
        const completers = this.promiseCompleters[response.ref];
        if (completers) {
          completers.resolve();
        }
        delete this.promiseCompleters[response.ref];
      }
    }
  }

  handleUnsubscribeResult(response: any) {
    if ('unsubscribe' in response) {
      if (response.unsubscribe === true && response.ref) {
        const completers = this.promiseCompleters[response.ref];
        if (completers) {
          completers.resolve();
        }
        delete this.promiseCompleters[response.ref];
      }
    }
  }

  handleOHLCVResult(response: any) {
    processOHLCVResponse(this.dispatch, response, ohlcvQueriesCache);
  }

  handleTopicMessage(topic: string, response: any) {
    switch (topic) {
      case 'trade':
        this.handleTradeReceived(response.data);
        break;
      case 'bbo':
        // this.onBBO.emit(response.data);
        break;
      case 'nbbo':
        this.handleNBBOReceived(response.data);
        break;
      case 'book':
        // this.onBook.emit(response.data);
        break;
      case 'ohlcv':
        this.handleOHLCVResult(response);
        break;
      case 'heartbeat':
        // nothing to do
        break;
      default: {
        const traceId = (ohlcvQueriesCache as any)[response.ref];
        if (traceId) {
          this.handleOHLCVResult(response);
        } else {
          console.warn('[MD] Unknown topic:', response);
        }
        break;
      }
    }
  }

  handleNBBOReceived(nbboData: BBOData[]) {
    updateNBBOPricesCache(dataArrayToMap(nbboData) as BBODataCache | InstrumentSnapshotMap, 'nbbo');
  }

  handleTradeReceived(tradeData: TradeData[]) {
    updateTradePriceCacheFromTrade(dataArrayToMap(tradeData) as TradeDataCache);
  }


  async subscribe(topic: SubscriptionTopic, syms: string[], conflate: boolean = true) {
    // await this.connectionPromise; // TODO: ART-334
    new Promise((resolve, reject) => {
      const ref = `${this.asyncRef++}`;
      const sub = [ 'subscribe', {
        topic, syms, ref, conflate,
      } ];
      this.promiseCompleters[ref] = { resolve, reject };
      this.sendRequest('subscribe', sub);
    })
      .then(() => {
        this.subscriptions[topic] = [
          ...this.subscriptions[topic],
          ...syms.filter(sym => !this.subscriptions[topic].includes(sym)),
        ];
      })
      .catch((exception: any) => console.error(exception));
  }

  async unsubscribe(topic: SubscriptionTopic, syms: string[]) {
    // await this.connectionPromise; // TODO: ART-334
    new Promise((resolve, reject) => {
      const ref = `${this.asyncRef++}`;
      const unsub = [ 'unsubscribe', { topic, syms, ref } ];
      this.promiseCompleters[ref] = { resolve, reject };
      this.sendRequest('unsubscribe', unsub);
    }).then(() => {
      this.subscriptions[topic] = this.subscriptions[topic].filter(sym => !syms.includes(sym));
    }).catch(reason => console.error(reason));
  }

  query(
    topic: string,
    symbols: string[],
    startTime: string,
    endTime: string,
    bucket = '1day', // eslint-disable-line default-param-last
    queryCase?: GWQueryCase,
  ) {
    const traceId = uuidv4();
    if (queryCase != null) {
      ohlcvQueriesCache[traceId] = {
        queryCase,
        symbol: symbols[0],
      };
    }
    const query = [
      'query',
      {
        topic,
        syms: symbols,
        bucket,
        ref: traceId,
        starttime: `${startTime}.000`,
        endtime: `${endTime}.000`,
      },
    ];
    this.sendRequest('query', query);
  }

  isSubscribed(topic: SubscriptionTopic, sym: string) {
    return this.subscriptions[topic].includes(sym);
  }
}
