import PasswordValidator from 'password-validator';

const TwoCharacters = new PasswordValidator();
const FiveCharacters = new PasswordValidator();
const HundredTwentyEightCharacters = new PasswordValidator();
const SixtyFourCharacters = new PasswordValidator();
const ThirtyTwoCharacters = new PasswordValidator();
const FiveHundredTwelveCharacters = new PasswordValidator();
const FourHundredCharacters = new PasswordValidator();
const TenCharacters = new PasswordValidator();
const FourCharactersMin = new PasswordValidator();
const FourCharactersMax = new PasswordValidator();
const TwelveCharacters = new PasswordValidator();
const TwoHundredFiftyFour = new PasswordValidator();
const MinNineChars = new PasswordValidator();
const MaxThirtyFourChars = new PasswordValidator();

TwoCharacters.is().min(2);
FourCharactersMin.is().min(4);
FourCharactersMax.is().max(4);
FiveCharacters.is().min(5);
HundredTwentyEightCharacters.is().max(128);
SixtyFourCharacters.is().max(64);
ThirtyTwoCharacters.is().max(32);
FiveHundredTwelveCharacters.is().max(512);
FourHundredCharacters.is().max(400);
TenCharacters.is().max(10);
TwelveCharacters.is().max(12);
TwoHundredFiftyFour.is().max(254);
MinNineChars.is().min(9);
MaxThirtyFourChars.is().max(34);

export const validateMinNineChars = (value: string): boolean | string[] => MinNineChars
  .validate(value?.trim(), { list: false });
export const validateMaxThirtyFourCharacters = (value: string): boolean | string[] => MaxThirtyFourChars
  .validate(value?.trim(), { list: false });
export const validateMinCharacters = (value: string): boolean | string[] => TwoCharacters
  .validate(value?.trim(), { list: false });
export const validateMinFourCharacters = (value: string): boolean | string[] => FourCharactersMin
  .validate(value?.trim(), { list: false });
export const validateMinCharactersTaxNumber = (value: string): boolean | string[] => FiveCharacters
  .validate(value?.trim(), { list: false });
export const validateMinCharactersPhoneNumber = (value: string): boolean | string[] => FourCharactersMin
  .validate(value?.trim(), { list: false });
export const validateNameMaxCharacters = (value: string): boolean | string[] => HundredTwentyEightCharacters
  .validate(value, { list: false });
export const validateMaxCharactersCity = (value: string): boolean | string[] => SixtyFourCharacters
  .validate(value, { list: false });
export const validateMaxCharactersTaxNumber = (value: string): boolean | string[] => SixtyFourCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersPostCode = (value: string): boolean | string[] => ThirtyTwoCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersAddress = (value: string): boolean | string[] => FiveHundredTwelveCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersSorceOfFundName = (value: string): boolean | string[] => FourHundredCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharacters4 = (value: string): boolean | string[] => FourCharactersMax
  .validate(value?.trim(), { list: false });
export const validateMaxCharacters400 = (value: string): boolean | string[] => FourHundredCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersIDDocument = (value: string): boolean | string[] => TenCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersPhoneNumber = (value: string): boolean | string[] => TwelveCharacters
  .validate(value?.trim(), { list: false });
export const validateMaxCharactersEmailAddress = (value: string): boolean | string[] => TwoHundredFiftyFour
  .validate(value?.trim(), { list: false });
