import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { RootState } from '../../../lib/store';
import { clearSignupToken as clearSignupTokenCall, getSignupToken as getSignupTokenCall } from '../../../lib/store/auth';

import CheckSVG from '../../../assets/img/check-icon.svg';
import ArrowSVG from '../../../assets/img/icon-arrow-right-white.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import ClickableDiv from '../../ClickableDiv/ClickableDiv';
import { Preloader } from '../../index';

import styles from '../SymbolDetailsNotActiveBox.module.scss';

type Props = {
    title: string;
    disabled?: boolean;
    finished?: boolean;
    pending?: boolean;
    redirectPath?: string;
    isRedirectPWP?: boolean;
} & ReduxProps

const ListItem = (props: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const {
    title,
    redirectPath,
    disabled,
    isFetchingPWPSignupURL,
    PWPSignupURL,
    clearSignupToken,
    getSignupToken,
    finished,
    pending,
    isRedirectPWP,
  } = props;

  useEffect(() => {
    if (!isFetchingPWPSignupURL && PWPSignupURL) {
      window.open(PWPSignupURL, isMobile ? '_self' : '_blank');
      clearSignupToken();// removing the signup token URL from the state to avoid the re-redirection on the next render time
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingPWPSignupURL, PWPSignupURL, isMobile]);

  const redirect = () => {
    if (redirectPath) {
      history.push(redirectPath);
    }
    if (isRedirectPWP) {
      if (isFetchingPWPSignupURL) {
        return;
      }
      getSignupToken();
    }
  };
  return (
    <li>
      <ClickableDiv
        className={classNames(styles.contentWrapper,
          {
            [styles.finishedStep]: finished,
            [styles.disabledStep]: disabled,
            [styles.pendingStep]: pending,
          })}
        onClick={redirect}
      >
        <div className={classNames(styles.checkBase, { [styles.pending]: pending })}>
          {finished && <img src={CheckSVG} alt="check icon" />}
          {pending && (<Preloader text="" secondaryColor />)}
        </div>
        <div className={styles.stepWrapper}>
          <p>{title}</p>
          {!finished && !pending && <img src={ArrowSVG} alt="arrow right white" />}
        </div>
      </ClickableDiv>
    </li>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetchingPWPSignupURL: state.auth.isFetchingPWPSignupURL,
  PWPSignupURL: state.auth.PWPSignupURL,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSignupToken: () => dispatch(getSignupTokenCall()),
  clearSignupToken: () => dispatch(clearSignupTokenCall()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ListItem);
