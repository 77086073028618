import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { mapUserFavouriteStocksData } from '../../../lib/store/selectors';
import { manageSubscriptions } from '../../../lib/util/TradingHelpers';

import noMessagesLogo from '../../../assets/img/image_empty.png';
import { UI_ROUTES } from '../../../util/routes';
import { Button } from '../..';
import { ButtonTypesEnum } from '../../Button/Button.types';

import WatchListTable from './WatchListTable';

import styles from '../WatchList.module.scss';

type Props = ReduxProps;

const FavouritesWatchList = (props: Props) => {
  const { favouriteStocks } = props;

  const history = useHistory();

  const dataStocks = favouriteStocks.map(symbol => ({ symbol }));

  useEffect(() => {
    if (dataStocks.length > 0) {
      manageSubscriptions('watchlist/favorites', null, false);
    }
  }, [dataStocks]);

  return (
    <>
      {
        dataStocks.length === 0
          ? (
            <div className={styles.wrapperNoFavouritesStocks}>
              <img className={styles.noMessagesLogo} src={noMessagesLogo} alt="no messages logo" />
              <p className={styles.noStocksMessage}>{lang.commonWatchlistFavoutesLimit()}</p>
              <p className={styles.noStocksMessage}>{lang.commonMyAccountNoStocksMessage1()}</p>
              <div className={styles.wrapperBtnDiscover}>
                <Button variant={ButtonTypesEnum.Link} onClick={() => history.push(UI_ROUTES.discover)}>
                  {lang.commonDiscoverTitle()}
                </Button>
                <p>{lang.commonMyAccountNoStocksMessage2().replace(lang.commonDiscoverTitle().toLowerCase(), '')}</p>
              </div>
            </div>
          )
          : <WatchListTable watchListStocks={dataStocks} />
       }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  favouriteStocks: mapUserFavouriteStocksData(
    state.marketData.favouriteStocks,
    state.crm.individualExtendedInfo?.user_correlation_id,
  ),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FavouritesWatchList);
