import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResponse } from 'apisauce';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { PredefinedMilisecondsTypeEnum } from '../../enums';
import { PagedResponse } from '../../models/common-response-types';
import {
  CompanyLogoAndNameResponse,
  ETF,
  ETFDetailParams,
  ETFDetailResponse,
  ETFParams,
  NewStockData,
  PageableFilterRequest,
  PopularStock,
  PopularStockParams,
  SectorDetail,
  SectorDetailParams,
  TopGainerAndLoserData,
  TopGainerAndLoserPayload,
} from '../../models/fundamental/types';
import { DynamicFilterBuilder } from '../../services/filter/dynamic-filter-builder';
import GlobalService from '../../services/Global.service';
import { popularGroupTitleToName } from '../../util/TextHelpers';
import { manageSubscriptions } from '../../util/TradingHelpers';
import { OrderTicketComponent } from '../../util/types';
import { getOpenPositionsSuccess } from '../common-actions';
import { processErrorInResponse, processResponse } from '../helpers';
import { addCompanyToCache, getWatchlistData } from '../market-data';
import { getAccountPendingOrdersSuccess, getAccountTradeHistorySuccess } from '../reporting/index';
import { AccountTradeHistoryResponseData } from '../reporting/types';
import { CachedResponse } from '../types';
import { RootState } from '..';

import { DEFAULT_PAGE, DEFAULT_SECTOR_DETAILS_SIZE, etfDetailProjections, etfProjections, symbolSet } from './constants';
import { getPriceForTopMovers, processCallCache } from './helpers';
import { getCompany,
  getCompanyCompleted,
  getCompanyFailed,
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  getCompanyLogo,
  getCompanyLogoAndName,
  getCompanyLogoAndNameCompleted,
  getCompanyLogoAndNameFailed,
  getCompanyLogoCompleted,
  getCompanyLogoFailed,
  getDividendPayers,
  getDividendPayersCompleted,
  getDividendPayersFailed,
  getEarningsCompleted,
  getEarningsFailed,
  getETFDetails,
  getETFDetailsCompleted,
  getETFDetailsFailed,
  getETFs,
  getETFsCompleted,
  getETFsFailed,
  getHighGrowthCompanies,
  getHighGrowthCompaniesCompleted,
  getHighGrowthCompaniesFailed,
  getIPOs,
  getIPOsCompleted,
  getIPOsFailed,
  getMarketIndexes,
  getMarketIndexesCompleted,
  getMarketIndexesFailed,
  getNewStocks,
  getNewStocksCompleted,
  getNewStocksFailed,
  getPopularStocks,
  getPopularStocksCompleted,
  getPopularStocksFailed,
  getSectorDetail,
  getSectorDetailCompleted,
  getSectorDetailFailed,
  getSectors,
  getSectorsCompleted,
  getSectorsFailed,
  getSplits,
  getSplitsCompleted,
  getSplitsFailed,
  getTopGainers,
  getTopGainersCompleted,
  getTopGainersFailed,
  getTopLosers,
  getTopLosersCompleted,
  getTopLosersFailed,
  keyStatsCompleted,
  keyStatsFailed,
  loadStock,
  searchCompany,
  searchCompanyCompleted,
  searchCompanyFailed,
  searchEarnings,
  searchEarningsCompleted,
  searchEarningsFailed,
  searchStock,
  searchStockByParams,
  stockSearchCompleted,
  stockSearchFailed,
  stockSearchReset,
} from './index';
import { SearchEarningsPayload, SearchStockPayload } from './types';

const searchStockResetEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(searchStock.type),
  filter((action: PayloadAction<string>) => (
    state$.value.fundamental.stockResults?.length > 0
    || action.payload === ''
  )),
  mergeMap((action: PayloadAction<string>) => {
    const isEmptySearch = action.payload === '';
    return new Observable((observer: Observer<any>) => observer.next(stockSearchReset(isEmptySearch)));
  }),
);

const searchStocksEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(searchStock.type),
  debounceTime(500),
  filter(action => action.payload !== ''),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService
      .fundamentalService
      .searchCompanies(action.payload, undefined)
      .then(response => {
        processResponse(searchStock.type, {}, response, observer, stockSearchCompleted, stockSearchFailed);
      })
      .catch(error => {
        processErrorInResponse(searchStock.type, error, action, observer, stockSearchFailed);
      });
  })),
);

const searchStockByParamsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(searchStockByParams.type),
  debounceTime(PredefinedMilisecondsTypeEnum.halfSecond), // In order to not make HTTP requests for each key press
  filter((action: PayloadAction<SearchStockPayload>) => action.payload.query !== ''),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { query, limit, params } = action.payload;
    GlobalService
      .fundamentalService
      .searchCompanies(query, params, limit)
      .then(response => {
        processResponse(
          searchStockByParams.type,
          {},
          response,
          observer,
          stockSearchCompleted,
          stockSearchFailed,
        );
      })
      .catch(error => {
        processErrorInResponse(searchStockByParams.type, error, action, observer, stockSearchFailed);
      });
  })),
);

/**
 * @deprecated Integrated before but there is no usage in WEB and MOBILE
 * we use it with the start date and end date from other places
 */
const getEarningsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadStock.type),
  mergeMap((action: PayloadAction<{ symbols: string[] }>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getEarningsEpic';
    GlobalService
      .fundamentalService
      .getEarnings(action.payload.symbols)
      .then(response => processResponse(
        callName,
        {},
        response,
        observer,
        getEarningsCompleted,
        getEarningsFailed,
        false,
        null,
        null,
        null,
        null,
        { 404: [] },

      ))
      .catch(error => processErrorInResponse(callName, error, action, observer, getEarningsFailed));
  })),
);

const searchEarningsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(searchEarnings.type),
  mergeMap((action: PayloadAction<SearchEarningsPayload>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/searchEarningsEpic';
    GlobalService
      .fundamentalService
      .searchEarnings(action.payload)
      .then(response => {
        const success = processResponse(
          callName,
          {},
          response,
          observer,
          searchEarningsCompleted,
          searchEarningsFailed,
        );
        if (success && response.data) {
          const symbols: string[] = [];
          const { content } = response.data;

          content.forEach(el => { symbols.push(el.symbol); });
          observer.next(getCompanyLogoAndName(symbols.join()));
        }
      }).catch(error => processErrorInResponse(callName, error, action, observer, searchEarningsFailed));
  })),
);

const searchKeyStatsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(loadStock.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'fundamental/searchKeyStatsEpic';

    GlobalService
      .fundamentalService
      .getKeyStats(action.payload.symbol)
      .then(response => processResponse(
        callName,
        {},
        response,
        observer,
        keyStatsCompleted,
        keyStatsFailed,
      ))
      .catch(error => processErrorInResponse(callName, error, action, observer, getEarningsFailed));
  })),
);

const searchCompanyEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(searchCompany.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { symbol, limit } = action.payload;
    const callName = 'fundamental/searchCompany';

    if (!symbol) {
      processErrorInResponse(
        callName,
        new Error('[fundamental/epic] symbol not found'),
        action,
        observer,
        searchCompanyFailed,
      );

      return;
    }

    GlobalService
      .fundamentalService
      .searchCompanies(symbol, undefined)
      .then(response => {
        const isSuccess = processResponse(
          callName,
          {},
          response,
          observer,
          searchCompanyCompleted,
          searchCompanyFailed,
        );
        if (isSuccess) {
          observer.next(addCompanyToCache({ responseData: response.data, symbols: symbol }));
        } else {
          observer.next(searchCompanyFailed(action.payload));
        }
      })
      .catch(exception => {
        console.error(`[fundamental/epic] ${exception}`);
        observer.next(searchCompanyFailed(action.payload));
      });
  })),
);

// TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
const getCompanyLogoEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getCompanyLogo.type),
  filter(action => processCallCache(getSymbols(action), 'getCompanyLogo') !== ''),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getCompanyLogo';
    const symbols = processCallCache(getSymbols(action), 'getCompanyLogo', true);
    GlobalService
      .fundamentalService
      .getCompanyLogo(symbols)
      .then((response: ApiResponse<any>) => {
        processCallCache(getSymbols(action), 'getCompanyLogo', false);
        processResponse(callName, {}, response, observer, getCompanyLogoCompleted, getCompanyLogoFailed);
      })
      .catch(error => {
        processCallCache(getSymbols(action), 'getCompanyLogo', false);
        processErrorInResponse(callName, error, action, observer, getCompanyLogoFailed);
      });
  })),
);

const getCompanyLogoAndNameEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getCompanyLogoAndName.type),
  filter(action => processCallCache(getSymbols(action), 'getCompanyLogoAndName') !== ''),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'fundamental/getCompanyLogoAndName';
    const symbols = processCallCache(getSymbols(action), 'getCompanyLogoAndName', true);

    GlobalService
      .fundamentalService
      .getCompanyLogoAndName(symbols)
      .then((response: ApiResponse<CompanyLogoAndNameResponse[]>) => {
        processCallCache(getSymbols(action), 'getCompanyLogoAndName', false);
        // eslint-disable-next-line max-len
        const success = processResponse(callName, {}, response, observer, getCompanyLogoAndNameCompleted, getCompanyLogoAndNameFailed);
        if (success && response.data) {
          observer.next(addCompanyToCache({ responseData: response.data, symbols }));
        }
      })
      .catch(error => {
        processCallCache(getSymbols(action), 'getCompanyLogoAndName', false);
        processErrorInResponse(callName, error, action, observer, getCompanyLogoAndNameFailed);
      });
  })),
);

// TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
const getCompanyEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getCompany.type, loadStock.type),
  filter(action => (
    !state$.value.fundamental.companies.length
    || !state$.value.fundamental.companies.find(el => el.symbol === getSymbols(action)))),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getCompany';
    const symbols: string = getSymbols(action);

    GlobalService
      .fundamentalService
      .getCompany(symbols)
      .then((response: ApiResponse<any>) => {
        if ((response as any) as CachedResponse === 'cached') return;

        const isSuccess = processResponse(callName, symbols, response, observer, getCompanyCompleted, getCompanyFailed);
        if (isSuccess) {
          observer.next(addCompanyToCache({ responseData: response.data, symbols }));
        }
      })
      .catch(error => processErrorInResponse(callName, error, action, observer, getCompanyFailed));
  })),
);

const getPopularStocksEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getPopularStocks.type),
  filter(() => {
    const configLib = require('../../../configLib').default;
    const { logConfig } = require('../../../configDebug');
    const isTestMode = configLib.__DEV__ && (logConfig as any)?.mobile?.testMode === 2;
    return !isTestMode;
  }),
  mergeMap((action: PayloadAction<PopularStockParams>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getPopularStocks';
    const { constrained, count } = action.payload ?? {};
    GlobalService
      .fundamentalService
      .getPopularStocks(constrained, count)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getPopularStocksCompleted,
          getPopularStocksFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getPopularStocksFailed,
      ));
  })),
);

const initializePopularWatchlistItemsDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getPopularStocksCompleted.type),
  filter(() => state$.value.gateway.connected || state$.value.marketData.connected),
  filter(() => state$.value.marketData.isInitialPriceDataLoaded),
  mergeMap((action: PayloadAction<PopularStock[]>) => new Observable((observer: Observer<any>) => {
    let symbolOrSymbols = action.payload.map(({ symbol }) => symbol).join(',');
    if (symbolOrSymbols.length > 0) {
      observer.next(getWatchlistData({ symbolOrSymbols, component: 'watchlist/popular', caller: 'fundamental/initializePopularWatchlistItemsDataEpic' }));
    }
  })),
);

// TODO: Remove when merging ART-2173 - optimize logos
const getMyAccountLogosAndCompanyNamesEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getOpenPositionsSuccess.type, getAccountTradeHistorySuccess.type, getAccountPendingOrdersSuccess.type),
  filter(action => (
    action.type !== getOpenPositionsSuccess.type
    || state$.value.marketData.isInitialPriceDataLoaded
  )),
  mergeMap((
    action: PayloadAction<(PopularStock|AccountTradeHistoryResponseData)[]>,
  ) => new Observable((observer: Observer<any>) => {
    const symbolOrSymbols: string[] = [];
    action.payload.forEach(({ symbol }) => {
      if (!state$.value.fundamental.companyLogoAndName[symbol] && !symbolOrSymbols.includes(symbol)) {
        symbolOrSymbols.push(symbol);
      }
    });
    if (symbolOrSymbols.length) {
      observer.next(getCompanyLogoAndName(symbolOrSymbols.join()));
    }
  })),
);

type RangeAction = PayloadAction<{
  startDate?: string,
  endDate?: string,
}>;

const getIPOsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getIPOs.type),
  mergeMap((action: RangeAction) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getIPOs';
    const { startDate, endDate } = action.payload;
    GlobalService
      .fundamentalService
      .getIPOs(startDate, endDate)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getIPOsCompleted,
          getIPOsFailed,
        );
      })
      .catch(error => processErrorInResponse(callName, error, action, observer, getIPOsFailed));
  })),
);

type SplitsAction = PayloadAction<{
  symbols: string[],
  startDate?: string,
  endDate?: string,
}>;

const getSplitsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getSplits.type),
  mergeMap((action: SplitsAction) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getSplits';
    const { symbols, startDate, endDate } = action.payload;
    GlobalService
      .fundamentalService
      .getSplits(symbols, startDate, endDate)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getSplitsCompleted,
          getSplitsFailed,
        );
      })
      .catch(error => processErrorInResponse(callName, error, action, observer, getSplitsFailed));
  })),
);

const getNewStocksEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getNewStocks.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getNewStocks';
    const params = action.payload;

    GlobalService
      .fundamentalService
      .getNewStocks(params)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getNewStocksCompleted,
          getNewStocksFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getNewStocksFailed,
      ));
  })),
);

const getDividendPayersEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getDividendPayers.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap((action: PayloadAction<PageableFilterRequest>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getDividendPayers';
    GlobalService
      .fundamentalService
      .getDividendPayers(action?.payload)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getDividendPayersCompleted,
          getDividendPayersFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getDividendPayersFailed,
      ));
  })),
);

const getHighGrowthCompaniesEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getHighGrowthCompanies.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap((action: PayloadAction<PageableFilterRequest>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getHighGrowthCompanies';
    GlobalService
      .fundamentalService
      .getHighGrowthCompanies(action?.payload)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getHighGrowthCompaniesCompleted,
          getHighGrowthCompaniesFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getHighGrowthCompaniesFailed,
      ));
  })),
);

const getMarketIndexesEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getMarketIndexes.type),
  mergeMap((action: PayloadAction<{ symbols: [] }>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getMarketIndexes';
    GlobalService
      .fundamentalService
      .getMarketIndexes(action.payload.symbols)
      .then(response => processResponse(
        callName,
        {},
        response,
        observer,
        getMarketIndexesCompleted,
        getMarketIndexesFailed,
      ))
      .catch(error => processErrorInResponse(callName, error, action, observer, getMarketIndexesFailed));
  })),
);

const getTopGainersEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getTopGainers.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap((action: PayloadAction<TopGainerAndLoserPayload>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getTopGainers';
    const { minValue, maxValue } = action.payload;

    GlobalService
      .fundamentalService
      .getTopGainers(minValue, maxValue)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getTopGainersCompleted,
          getTopGainersFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
          (data: TopGainerAndLoserData[]) => getPriceForTopMovers(data, 'TopGainers'),
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getTopGainersFailed,
      ));
  })),
);

const getTopLosersEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getTopLosers.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap((action: PayloadAction<TopGainerAndLoserPayload>) => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getTopLosers';
    const { minValue, maxValue } = action.payload;

    GlobalService
      .fundamentalService
      .getTopLosers(minValue, maxValue)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getTopLosersCompleted,
          getTopLosersFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
          (data: TopGainerAndLoserData[]) => getPriceForTopMovers(data, 'TopLosers'),
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getTopLosersFailed,
      ));
  })),
);

const initializeTopMoversDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getTopGainersCompleted.type, getTopLosersCompleted.type),
  filter(() => state$.value.gateway.connected || state$.value.marketData.connected),
  filter(() => state$.value.marketData.isInitialPriceDataLoaded),
  mergeMap((action: PayloadAction<TopGainerAndLoserData[]>) => new Observable((observer: Observer<any>) => {
    let symbolOrSymbols = '';
    action.payload.forEach(({ symbol }) => { symbolOrSymbols += symbolOrSymbols ? `,${symbol}` : symbol; });
    const component: OrderTicketComponent = 'discover/top-movers';
    observer.next(getWatchlistData({ symbolOrSymbols, component, caller: 'fundamental/initializeTopMoversDataEpic' }));
  })),
);

const initializeNewOnTheMarketDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getNewStocksCompleted.type),
  filter(() => state$.value.gateway.connected || state$.value.marketData.connected),
  filter(() => state$.value.marketData.isInitialPriceDataLoaded),
  mergeMap((action: PayloadAction<NewStockData[]>) => new Observable((observer: Observer<any>) => {
    let symbolOrSymbols = '';
    action.payload.forEach(({ symbol }) => { symbolOrSymbols += symbolOrSymbols ? `,${symbol}` : symbol; });
    observer.next(getWatchlistData({ symbolOrSymbols, component: 'discover/new-on-the-market', caller: 'fundamental/initializeNewOnTheMarketDataEpic' }));
  })),
);

const initializeSectorDetailDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getSectorDetailCompleted.type),
  filter(() => state$.value.gateway.connected || state$.value.marketData.connected),
  mergeMap((action: PayloadAction<SectorDetail>) => new Observable((observer: Observer<any>) => {
    const { content, sector } = action.payload;
    const symbolOrSymbols = content?.map(item => item.symbol).join();
    observer.next(getWatchlistData({ symbolOrSymbols, component: 'discover/popular-group', caller: 'fundamental/initializeSectorDetailDataEpic' }));

    const title = popularGroupTitleToName(sector ?? 'untitled_popular_group');
    manageSubscriptions('discover/popular-group', title, true);
  })),
);

const getSectorsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getSectors.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getSectors';
    GlobalService
      .fundamentalService
      .getSectors()
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getSectorsCompleted,
          getSectorsFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getSectorsFailed,
      ));
  })),
);

const getSectorDetailEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getSectorDetail.type),
  filter(() => state$.value.app.isServicesReady),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'fundamental/getSectorDetail';

    const sectorId = action.payload;

    let params: SectorDetailParams = {
      sectorId,
      page: 0,
      size: DEFAULT_SECTOR_DETAILS_SIZE,
    };

    if (state$.value.fundamental.sectorDetails.sectorId === sectorId) {
      params = { ...params, page: state$.value.fundamental.sectorDetails.nextPageToRead };
    }

    GlobalService
      .fundamentalService
      .getSectorDetail(params)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getSectorDetailCompleted,
          getSectorDetailFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getSectorDetailFailed,
      ));
  })),
);

const getETFsEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(getETFs.type),
  mergeMap((action: PayloadAction<PagedResponse<ETF>>) => new Observable((observer: Observer<any>) => {
    const callName = 'fundamental/getETFs';

    const params: ETFParams = {
      filters: new DynamicFilterBuilder().equal('type', 'et').build(),
      projections: etfProjections,
      symbolSet: symbolSet.RETAIL,
      ...action.payload,
    };

    GlobalService
      .fundamentalService
      .getETFs(params)
      .then(response => {
        const success = processResponse(
          callName,
          {},
          response,
          observer,
          getETFsCompleted,
          getETFsFailed,
        );

        if (success && response.data) {
          const symbolOrSymbols = response.data.content.map((etf: ETF) => etf.symbol).join();
          observer.next(getWatchlistData({
            symbolOrSymbols,
            component: 'discover/popular-group',
            caller: 'fundamental/initializeSectorDetailDataEpic',
          }));
          const symbols = response.data.content.map((etf: ETF) => etf.symbol).join(',');
          manageSubscriptions('discover/popular-group', 'ETFs', true);
          observer.next(getETFDetails({ filters: new DynamicFilterBuilder().in('symbol', symbols).build() }));
        }
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getETFsFailed,
      ));
  })),
);

const getETFDetailsEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(getETFDetails.type),
  mergeMap((action: PayloadAction<PagedResponse<ETFDetailResponse>>) => new Observable((observer: Observer<any>) => {
    const callName = 'fundamental/getETFDetails';
    const params: ETFDetailParams = {
      ...action.payload,
      page: DEFAULT_PAGE,
      projections: etfDetailProjections,
      size: DEFAULT_SECTOR_DETAILS_SIZE,
    };

    GlobalService
      .fundamentalService
      .getETFDetails(params)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getETFDetailsCompleted,
          getETFDetailsFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getETFDetailsFailed,
      ));
  })),
);

function getSymbols(action: PayloadAction<string | { symbol: string }>) {
  let symbols: string = '';

  if (action.type === getCompany.type) symbols = action.payload as string;
  if (action.type === getCompanyLogo.type) symbols = action.payload as string;
  if (action.type === getCompanyLogoAndName.type) symbols = action.payload as string;
  if (action.type === loadStock.type) symbols = (action.payload as {symbol: string}).symbol;

  return symbols;
}

/**
 * Returns a list of symbols.
 * If `filterByCacheCallName` is given - filter symbols by it if any in
 * and update its cache if `isPendingValue` is set to `true` or `false`.
 * @param action Has a payload of a string - examples: 'AAPl', 'AAPL,TSLA', 'FB,TSLA,AAPL,AMD' etc.
 * @param filterByCacheCallName
 * @param newPendingValue
 */

export default combineEpics(
  searchStocksEpic,
  searchStockByParamsEpic,
  searchEarningsEpic,
  searchStockResetEpic,
  searchKeyStatsEpic,
  searchCompanyEpic,
  getCompanyEpic,
  getCompanyLogoEpic,
  getCompanyLogoAndNameEpic,
  getPopularStocksEpic,
  initializePopularWatchlistItemsDataEpic,
  getNewStocksEpic,
  getDividendPayersEpic,
  getHighGrowthCompaniesEpic,
  getMarketIndexesEpic,
  getTopGainersEpic,
  getTopLosersEpic,
  getSectorsEpic,
  getSectorDetailEpic,
  getETFsEpic,
  getETFDetailsEpic,
  getEarningsEpic,
  getIPOsEpic,
  getSplitsEpic,
  initializeTopMoversDataEpic,
  initializeSectorDetailDataEpic,
  initializeNewOnTheMarketDataEpic,
  getMyAccountLogosAndCompanyNamesEpic,
);
