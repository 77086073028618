import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrderSideEnum } from '../../lib/enums/order-side.enum';
import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import { Order } from '../../lib/models/trading/types';
import { getSummary } from '../../lib/store/ams/index';
import { getAccountsAvailableCash } from '../../lib/store/common-actions';
import { RootState } from '../../lib/store/index';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../lib/store/reporting/constants';
import { getAccountSummary, getAccountTradeHistory, getOpenPositions, setHasOrderUpdate } from '../../lib/store/reporting/index';
import { OpenPosition, PositionType } from '../../lib/store/reporting/types';
import { isAvailableCashChanged } from '../../lib/store/trading/helpers';
import { resetRequestStatus } from '../../lib/store/trading/index';
import MarketStateCache from '../../lib/store-util/MarketStateCache';
import SubscribeGroupCache from '../../lib/store-util/SubscribeGroupCache';
import { isMarketClosed } from '../../lib/util/MarketDataHelpers';
import { convertOrderSideToText } from '../../lib/util/TradingHelpers';

import useNewOrderTimer from '../OrderActions/useNewOrderTimer';

import OrderResultModal from './OrderResultModal';

const OrderResponseManager = () => {
  const [showResultModifyModal, setShowResultModifyModal] = useState<boolean>(false);
  const [showResultModalCancel, setShowResultModalCancel] = useState<boolean>(false);
  const [notEnoughStocks, setNotEnoughStocks] = useState<boolean>(false);

  const submittedOrder = useSelector<RootState, Order>((state: RootState) => state.trading.order);
  const requestStatus = useSelector<RootState, OrderStatusEnum>((state: RootState) => state.trading.requestStatus);
  const openPositions = useSelector<RootState, OpenPosition[]>((state: RootState) => state.reporting.openPositions);

  const { status, ordType, side: ordSide, orderQty, filledSoFar, symbol } = submittedOrder;
  const showResultModal = [OrderStatusEnum.Accepted, OrderStatusEnum.Rejected].includes(requestStatus);
  const dispatch = useDispatch();

  const { marketState } = MarketStateCache.use();
  const marketClosed = isMarketClosed(marketState);

  const {
    showFillOrder, hasNoResponse, orderTimeoutPassed, showExpired,
  } = useNewOrderTimer(showResultModifyModal && showResultModal, OrderTypeEnum.Limit, status, requestStatus);

  const orderSideAsText = ordSide ? convertOrderSideToText(ordSide) : '';
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;
  const finalRejectedOrder = requestStatus === OrderStatusEnum.FinalReject;

  const showModifyOrderModal = (orderTimeoutPassed || (hasNoResponse && showResultModifyModal)
  || (finalRejectedOrder && showResultModifyModal));

  if (sessionStorage.getItem('showUseDetailsCancelModal') === 'true') {
    sessionStorage.removeItem('showUseDetailsCancelModal');
    document.body.classList.add('modal-open');
    setShowResultModalCancel(true);
  }

  useEffect(() => {
    if (sessionStorage.getItem('showUseDetailsEndModal') === 'true') {
      sessionStorage.removeItem('showUseDetailsEndModal');

      const longPositions = openPositions.find((position: OpenPosition) => (
        (position.symbol === symbol) && (position.positionType === PositionType.Buy)
      ));
      const shortedPositions = openPositions.find((position: OpenPosition) => (
        (position.symbol === symbol) && (position.positionType === PositionType.Sell)
      ));

      if (orderQty
          && ((
            longPositions
          && ordSide === OrderSideEnum.Sell
          && longPositions.quantity < orderQty
          ) || (
            shortedPositions
          && ordSide === OrderSideEnum.Buy
          && Math.abs(shortedPositions.quantity) < orderQty
          ))) {
        setNotEnoughStocks(true);
      }

      document.body.classList.add('modal-open');
      setShowResultModifyModal(true);
    }
  }, [openPositions, ordSide, orderQty, symbol]);

  const onCloseOrderResultModal = () => {
    if (orderInProgress) {
      dispatch(resetRequestStatus());
    }
    if (status && isAvailableCashChanged(status)) {
      dispatch(getAccountsAvailableCash());
    }
    dispatch(
      getAccountTradeHistory({ tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true }),
    );
  };

  const onCloseModifyResultModal = () => {
    onCloseOrderResultModal();
    if (!marketClosed) {
      dispatch(getOpenPositions());

      if (submittedOrder?.status === OrderStatusEnum.Filled) {
        dispatch(getAccountSummary());
      }
      if ([
        OrderStatusEnum.Filled,
        OrderStatusEnum.PartiallyFilled,
        OrderStatusEnum.Rejected,
        OrderStatusEnum.Expired,
      ].includes(status as OrderStatusEnum)) {
        dispatch(getSummary());
      }
    }
    SubscribeGroupCache.setComponent('initial');
    setShowResultModifyModal(false);
    dispatch(setHasOrderUpdate(false));
  };

  const onCloseCancelResultModal = () => {
    onCloseOrderResultModal();

    setShowResultModalCancel(false);
    if ([
      OrderStatusEnum.Filled,
      OrderStatusEnum.PartiallyFilled,
      OrderStatusEnum.Rejected,
      OrderStatusEnum.Expired,
    ].includes(status as OrderStatusEnum)) {
      dispatch(getSummary());
    }
    SubscribeGroupCache.setComponent('initial');
  };

  return (
    <>
      {showResultModifyModal && (
        <OrderResultModal
          closeModal={onCloseModifyResultModal}
          notEnoughStocks={notEnoughStocks}
          isModify
          symbol={symbol || ''}
        />
      )}
      {showResultModalCancel && (
      <OrderResultModal
        closeModal={onCloseCancelResultModal}
        notEnoughStocks={notEnoughStocks}
        symbol={symbol || ''}
      />
      )}
    </>
  );
};

export default OrderResponseManager;
