import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import ClickableDiv from '../ClickableDiv/ClickableDiv';
import { InputControl } from '../index';

import styles from './SharesCalculator.module.scss';

type Props = {
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
  maxQuantity?: number;
  clearNumberOfSharesError?: Dispatch<SetStateAction<string>>;
}

const SharesCalculator = ({
  quantity, setQuantity, clearNumberOfSharesError, disabled, maxQuantity,
}: Props) => {
  const reduceValue = () => {
    clearNumberOfSharesError && clearNumberOfSharesError('');
    if (quantity > 1) {
      setQuantity((currentQuantity: number) => currentQuantity - 1);
    }
  };

  const addValue = () => {
    clearNumberOfSharesError && clearNumberOfSharesError('');
    if (maxQuantity) {
      if (quantity < maxQuantity) {
        setQuantity((currentQuantity: number) => currentQuantity + 1);
      }
    } else {
      setQuantity((currentQuantity: number) => currentQuantity + 1);
    }
  };

  return (
    <>
      <div className={classNames(styles.calculationContainer, { [styles.disabled]: disabled })}>
        <ClickableDiv className={styles.calculationButton} onClick={reduceValue}>
          <span>-</span>
        </ClickableDiv>
        <div className={styles.resultFieldWrapper}>
          <InputControl
            className={styles.resultFieldInput}
            type="number"
            placeholder=""
            withMinimumValue
            name="quantity"
            value={quantity}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              (maxQuantity && Number(event.target.value) > maxQuantity)
                ? setQuantity(maxQuantity) : setQuantity(parseInt(event.target.value, 10));
              clearNumberOfSharesError && clearNumberOfSharesError('');
            }}
          />
        </div>
        <ClickableDiv
          className={[styles.calculationButton, styles.calculationButtonRight].join(' ')}
          onClick={addValue}
        >
          <span>+</span>
        </ClickableDiv>
      </div>
    </>
  );
};

export default SharesCalculator;
