import { isArray, isFunction } from 'lodash';

import { CallStatus, FinancialQuestionnaireTypesEnum } from '../../enums';
import { SourceOfFundEnum } from '../../enums/source-of-fund.enum';
import lang from '../../language';
import {
  LookupInterface,
  LookupModel,
  OnboardingQuestionaireData,
  OnboardingQuestionMethod,
  RegisterBinaryDocumentPayload,
  RegisterBinaryPOIDocumentPayload,
} from '../../models/crm/types';
import {
  CountryData,
  EmploymentSatusData,
  ENROLL_METHOD,
  ENROLL_METHOD_NAME,
  ENROLL_REQUEST_STATE,
  ENROLL_REQUEST_STATE_NAME,
  FinancialQuestionnaireOption,
  FinancialQuestionnaireQuestion,
  FinancialQuestionnaireSection,
  FinancialQuestionnaireSectionItem,
  FinancialQuestionnaireSubQuestion,
  FinancialQuestionnaireTypeData,
  IndividualExtendedInfoData,
  SourceOfFundParsedAnswerData,
  SourceOfFundsAnswerData,
} from '../../models/enroll';
import {
  EnrollIndividualAddressRequestData,
  EnrollIndividualIdentityDetailPayload,
  FileUploadRegisterData,
} from '../../models/enroll-requests';
import { isOneOf } from '../../util/DataHelpers';
import { dateToYear, getDateTime, yearToString } from '../../util/DateTimeHelpers';
import { setCallStatus } from '../../util/error-handling/StatusByCallHelpers';
import { parsePropsFromString } from '../../util/TextHelpers';
import { NullableNumber, NullableString } from '../../util/types';
import { RootState } from '../index';
import { EnrollDetailsEnum, getEnrollDetailId, getExpirationDate, hasSourceOfFunds, hasSourceOfIncomes } from '../selectors';

import { FINANCIAL_QUESTIONS_EXPERIENCE_FILTER, FINANCIAL_QUESTIONS_PURPOSE_AND_STATUS_FILTER } from './constants';
import { CRMState } from './index';
import { CRMCall, UploadDocumentFiles } from './types';

export const initFinancialQuestion = (
  title: string,
  order: number,
  options: FinancialQuestionnaireOption[],
  lookupKey?: string,
  subtitle?: string,
  method?: OnboardingQuestionMethod,
  subQuestions: FinancialQuestionnaireSubQuestion[] = [],
): FinancialQuestionnaireQuestion => ({
  title,
  subtitle: subtitle ?? '',
  order,
  lookupKey: lookupKey ?? '',
  options,
  method,
  subQuestions,
});

export const getFinancialQuestionBody = (questionType: string): (...args: any) => any => {
  switch (questionType) {
    case FinancialQuestionnaireTypesEnum.XANI:
      return (id: number) => ({ annual_net_income_id: id });
    case FinancialQuestionnaireTypesEnum.XENW:
      return (id: number) => ({ estimated_net_worth_id: id });
    case FinancialQuestionnaireTypesEnum.ELNW:
      return (id: number) => ({ estimated_liquid_worth_id: id });
    case FinancialQuestionnaireTypesEnum.PurposeOfTrading:
      return (id: number) => ({ purpose_of_trading_id: id });
    case FinancialQuestionnaireTypesEnum.EmploymentStatus:
      return (id: EmploymentSatusData, employment_status_id: number, business_id: number | null) => ({
        ...id,
        employment_status_id,
        business_id,
      });
    case FinancialQuestionnaireTypesEnum.SourceOfFund:
      return (
        id: NullableNumber,
        source_of_fund_id: number,
        from: string,
        to: string,
        identity_name: NullableString,
        fund_type: NullableString,

      ) => ({
        id,
        source_of_fund_id,
        from,
        to,
        identity_name,
        fund_type,
      } as SourceOfFundsAnswerData);
    default:
      return (idOrIds: number[] | number) => ({
        questionnaire_answer: isArray(idOrIds) ? idOrIds : [ idOrIds ],
      });
  }
};

export const parseFinancialQuestion = (
  state: CRMState,
  sections: FinancialQuestionnaireSection[],
  sectionOrder: number,
  questionOrder: number,
): FinancialQuestionnaireSectionItem | null => {
  const filteredSection = sections.find(x => x.order === sectionOrder);
  const question = filteredSection?.questions.find(q => q.order === questionOrder);

  if (filteredSection && question) {
    const {
      order,
      title,
      method,
      options,
      subtitle,
      lookupKey,
      subQuestions,
    } = question;

    const sortedOptions = [ ...options ].sort((
      current: FinancialQuestionnaireOption,
      next: FinancialQuestionnaireOption,
    ) => (current.order - next.order));

    const result = {
      sHeader: filteredSection?.header,
      sOrder: filteredSection?.order,
      totalSections: sections.length,
      totalSQuestions: filteredSection.questions.length,
      qTitle: setTitle(lookupKey as FinancialQuestionnaireTypesEnum, title),
      qSubtitle: subtitle,
      qOrder: order,
      selectedOption: loadSelectedOption(lookupKey as FinancialQuestionnaireTypesEnum, state.individualExtendedInfo),
      options: sortedOptions,
      questionType: lookupKey,
      subQuestions: subQuestions ? subQuestions.map(el => ({
        ...el,
        // eslint-disable-next-line max-len
        selectedOption: loadSelectedOption(el.lookupKey as FinancialQuestionnaireTypesEnum, state.individualExtendedInfo) }
      )) : [],
      method: isFunction(method) ? method(state) : method,
      getBody: getFinancialQuestionBody(lookupKey),
      hasCurrencyOption: checkHasCurrencyOption(question),
      hasSingleId: checkHasSimpleId(question),
    };

    return result;
  }

  return null;
};

const setTitle = (lookupKey: FinancialQuestionnaireTypesEnum, incomeTitle: string): string => {
  let title: string;
  switch (lookupKey) {
    case FinancialQuestionnaireTypesEnum.AITI:
      title = lang.commonInvestmentAmountSectionTitle();
      break;
    case FinancialQuestionnaireTypesEnum.XANI:
      title = lang.commonAnnualNetIncomeSectionTitle();
      break;
    case FinancialQuestionnaireTypesEnum.XENW:
      title = lang.commonEstimatedNetWorthSectionTitle();
      break;
    case FinancialQuestionnaireTypesEnum.AVPY:
      title = lang.commonAmountTradesPerYearhSectionTitle();
      break;
    case FinancialQuestionnaireTypesEnum.TKLP:
      title = lang.commonLeveragedProductsKnowlegeSectionTitle();
      break;
    default:
      title = incomeTitle;
  }

  return title;
};

export const getFinancialQuestionSubTitle = (sectionType: string): string => {
  switch (sectionType) {
    case FinancialQuestionnaireTypesEnum.XANI:
      return lang.commonGeneralQuestionsSubTitle2();
    case FinancialQuestionnaireTypesEnum.XENW:
      return lang.commonGeneralQuestionsSubTitle3();
    case FinancialQuestionnaireTypesEnum.TKLP:
      return lang.commonLeveragedProductsKnowlegeSectionSubTitle();
    default:
      return '';
  }
};

export const getFinancialQuestionOrder = (sectionType: string): number => {
  switch (sectionType) {
    case FinancialQuestionnaireTypesEnum.TEWS:
    case FinancialQuestionnaireTypesEnum.AVFM:
      return 1;
    case FinancialQuestionnaireTypesEnum.ATPY:
    case FinancialQuestionnaireTypesEnum.POWL:
      return 2;
    case FinancialQuestionnaireTypesEnum.AVPY:
    case FinancialQuestionnaireTypesEnum.AITI:
      return 3;
    case FinancialQuestionnaireTypesEnum.TKLP:
      return 4;
    case FinancialQuestionnaireTypesEnum.GroupedQuestion:
    case FinancialQuestionnaireTypesEnum.XANI:
      return 5;
    case FinancialQuestionnaireTypesEnum.XENW:
      return 6;
    case FinancialQuestionnaireTypesEnum.ELNW:
      return 7;
    default:
      return 0;
  }
};

export const getFinancialQuestionMethod = (questionType: string): ENROLL_METHOD => {
  switch (questionType) {
    case FinancialQuestionnaireTypesEnum.XANI:
      return ENROLL_METHOD.putIndividualOwnAnnualNetIncome;
    case FinancialQuestionnaireTypesEnum.XENW:
      return ENROLL_METHOD.putIndividualOwnEstimatedNetWorth;
    case FinancialQuestionnaireTypesEnum.ELNW:
      return ENROLL_METHOD.putIndividualOwnEstimatedLiquidWorth;
    default:
      return ENROLL_METHOD.putOwnFinancialQuestionnaire;
  }
};

export function parseQuestionnaireDataToSections(data: OnboardingQuestionaireData) {
  const {
    purposeOfTrading,
    sourceOfFundList,
    employmentStatusList,
    types,
    answers,
  } = data ?? {};

  if (!types || !answers) return [];

  delete types.customData;
  delete types.status;
  delete types.callName;

  const typesLookup = new LookupModel(types as LookupInterface);

  const groupedQuestion = initFinancialQuestion(
    lang.commonUnspecifiedKnowledgeAndExperienceSectionTitle(),
    5,
    [],
    FinancialQuestionnaireTypesEnum.GroupedQuestion,
    '',
    ENROLL_METHOD.putOwnFinancialQuestionnaire,
  );

  const knowledgeSection: FinancialQuestionnaireSection = {
    header: lang.commonOnboardingSectionTitleKnowledgeAndExperience(),
    order: 3,
    questions: [],
  };

  const purposeAndStatusSection: FinancialQuestionnaireSection = {
    header: lang.commonOnboardingSectionTitlePurposeAndStatus(),
    order: 2,
    questions: [
      initFinancialQuestion(
        lang.commonSourceOfFundsSectionTitle(),
        1,
        purposeOfTrading,
        FinancialQuestionnaireTypesEnum.PurposeOfTrading,
        '',
        ENROLL_METHOD.putIndividualOwnPurposeOfTrading,
      ),
      initFinancialQuestion(
        lang.commonEmploymentStatusSectionTitle(),
        2,
        employmentStatusList,
        FinancialQuestionnaireTypesEnum.EmploymentStatus,
        '',
        (state: CRMState) => (
          hasSourceOfIncomes(state)
            ? ENROLL_METHOD.putIndividualSourceOfIncome
            : ENROLL_METHOD.postIndividualSourceOfIncome
        ),
      ),
      initFinancialQuestion(
        lang.commonSourceOfFundsTitle(),
        4,
        sourceOfFundList,
        FinancialQuestionnaireTypesEnum.SourceOfFund,
        '',
        (state: CRMState) => (
          hasSourceOfFunds(state)
            ? ENROLL_METHOD.putIndividualSourceOfFund
            : ENROLL_METHOD.postIndividualSourceOfFund
        ),
      ),
    ],
  };


  typesLookup.getTypeKeys()
    .filter((typeKey: string) => typeKey !== FinancialQuestionnaireTypesEnum.ELNW)
    .forEach((typeKey: string) => {
      let lookupKey = typeKey;

      const method = getFinancialQuestionMethod(lookupKey);

      const isGroupedQuestion = ([
        FinancialQuestionnaireTypesEnum.AVFM,
        FinancialQuestionnaireTypesEnum.POWL,
      ] as string[]).includes(typeKey);

      const question: FinancialQuestionnaireQuestion | FinancialQuestionnaireSubQuestion = {
        title: typesLookup.getTypeValue(typeKey),
        order: getFinancialQuestionOrder(lookupKey),
        options: [],
        lookupKey,
        subtitle: getFinancialQuestionSubTitle(lookupKey),
        method,
      };

      answers
        .filter((answer: FinancialQuestionnaireTypeData) => answer.financial_questionaire_type === typeKey)
        .forEach((answer: FinancialQuestionnaireTypeData) => question.options.push(answer as any));

      if (isGroupedQuestion) {
        if (question.lookupKey === FinancialQuestionnaireTypesEnum.AVFM) {
          question.title = lang.commonGroupQuestionStatement1();
        } else if (question.lookupKey === FinancialQuestionnaireTypesEnum.POWL) {
          question.title = lang.commonGroupQuestionStatement2();
        }
        (question.options as FinancialQuestionnaireTypeData[]).sort(el1 => (el1.description === 'True' ? -1 : 1));
        groupedQuestion.subQuestions!.push(question as FinancialQuestionnaireSubQuestion);
      } else if (FINANCIAL_QUESTIONS_PURPOSE_AND_STATUS_FILTER.includes(lookupKey)) {
        purposeAndStatusSection.questions.push(question);
      } else if (FINANCIAL_QUESTIONS_EXPERIENCE_FILTER.includes(lookupKey)) {
        knowledgeSection?.questions.push(question);
      }
    });

  knowledgeSection.questions.push(groupedQuestion);
  knowledgeSection.questions
    .sort((
      current: FinancialQuestionnaireQuestion,
      next: FinancialQuestionnaireQuestion,
    ) => current.order - next.order);

  purposeAndStatusSection.questions
    .sort((
      current: FinancialQuestionnaireQuestion,
      next: FinancialQuestionnaireQuestion,
    ) => current.order - next.order);

  const sortedSections = [
    knowledgeSection,
    purposeAndStatusSection,
  ].sort((
    current: FinancialQuestionnaireSection,
    next: FinancialQuestionnaireSection,
  ) => current.order - next.order);

  return sortedSections;
}

const checkHasCurrencyOption = (currentQuestion: FinancialQuestionnaireQuestion): boolean => (
  !!currentQuestion && isOneOf(currentQuestion.lookupKey, [
    FinancialQuestionnaireTypesEnum.AVPY,
    FinancialQuestionnaireTypesEnum.AITI,
    FinancialQuestionnaireTypesEnum.XANI,
    FinancialQuestionnaireTypesEnum.XENW,
    FinancialQuestionnaireTypesEnum.ELNW,
  ])
);

const checkHasSimpleId = (currentQuestion: FinancialQuestionnaireQuestion): boolean => (
  !!currentQuestion && isOneOf(currentQuestion.lookupKey, [
    FinancialQuestionnaireTypesEnum.XANI,
    FinancialQuestionnaireTypesEnum.XENW,
    FinancialQuestionnaireTypesEnum.ELNW,
  ])
);

/* eslint-disable object-curly-newline */
export function parsePropsFromSourceOfFundsAnswerState( // deserialize data
  componentType: SourceOfFundEnum,
  state: SourceOfFundParsedAnswerData,
  yearsAsString = false,
): SourceOfFundParsedAnswerData {
  let { from, to, identity_name, fund_type } = state;

  from = dateToYear(from);
  to = dateToYear(to);

  if (yearsAsString) {
    from = yearToString(from);
    to = yearToString(to);
  }

  let result: SourceOfFundParsedAnswerData = {};

  switch (componentType) {
    case SourceOfFundEnum.AtHomeTradingAlaric: {
      const [ name, address ] = parsePropsFromString(identity_name, false);
      result = { from, to, name, address };
      break;
    }
    case SourceOfFundEnum.SalaryAlaric: {
      result = { from, to, name: identity_name, address: fund_type };
      break;
    }
    case SourceOfFundEnum.InheritanceAlaric: {
      result = { birth: from, year: to, name: identity_name };
      break;
    }
    case SourceOfFundEnum.FamilyMemberAlaric: {
      result = { from, to, name: identity_name };
      break;
    }
    case SourceOfFundEnum.PensionOrBenefitProgramAlaric: {
      result = { from, to, type: fund_type };
      break;
    }
    case SourceOfFundEnum.InvestmentsAndSavingsAlaric: {
      result = { from, to, type: fund_type, specifyType: identity_name };
      break;
    }
    case SourceOfFundEnum.SelfEmploymentAlaric: {
      const [ name, address ] = parsePropsFromString(identity_name, false);
      result = { from, to, name, address, hasMain: fund_type === '1' };
      break;
    }
    case SourceOfFundEnum.LoanAlaric: {
      result = { from, to, type: fund_type, name: identity_name };
      break;
    }
    default:
      console.warn(`[components/source-of-funds/helpers] getComponentPropsFromState - unknown type ${componentType}`);
      break;
  }

  return result;
}
/* eslint-enable object-curly-newline */

export function parseOnboardingErrorCode(status: number) {
  if (status >= 400 && status <= 499) return ENROLL_REQUEST_STATE.REQUEST_ERROR;
  if (status >= 500 && status <= 599) return ENROLL_REQUEST_STATE.SERVER_ERROR;

  return ENROLL_REQUEST_STATE.ERROR;
}

export const loadSelectedOption = (
  questionType: FinancialQuestionnaireTypesEnum,
  extendedInfo: IndividualExtendedInfoData | null,
): number => {
  if (!extendedInfo) return -1;

  switch (questionType) {
    case FinancialQuestionnaireTypesEnum.SourceOfFund:
      return extendedInfo.source_of_funds[0]?.source_of_fund_id || -1;
    case FinancialQuestionnaireTypesEnum.EmploymentStatus:
      return extendedInfo.source_of_incomes[0]
        ?.employment_status_id || -1;
    case FinancialQuestionnaireTypesEnum.PurposeOfTrading:
      return extendedInfo.purpose_of_trading_id || -1;
    case FinancialQuestionnaireTypesEnum.XANI:
      return extendedInfo.annual_net_income_id || -1;
    case FinancialQuestionnaireTypesEnum.XENW:
      return extendedInfo.estimated_net_worth_id || -1;
    default:
      return getFinQuestionId(extendedInfo, questionType);
  }
};

const getFinQuestionId = (
  extendedInfo: IndividualExtendedInfoData,
  qType: FinancialQuestionnaireTypesEnum,
): number => {
  const result = (
    extendedInfo
      ?.individual_financial_questionnaires
      ?.find(fq => fq.financial_questionaire_type === qType)
      ?.financial_questionnaire_id || -1
  );
  return result;
};

export function setCRMCallStatus(call: CRMCall, status: CallStatus, state: CRMState, errorCode?: number) {
  setCallStatus(call, CRMCall[call], status, state.statusByCall, errorCode, state.errorsLog);
}

// eslint-disable-next-line max-len
export const setEnrollStatusByCall = (state: CRMState, requestState: ENROLL_REQUEST_STATE, callMethod: ENROLL_METHOD, errorCode?: number) => {
  state.enroll.statusByCall[callMethod] = {
    callMethodName: ENROLL_METHOD[callMethod] as ENROLL_METHOD_NAME,
    requestState,
    requestStateName: ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME,
    errorCode,
  };
};

export const getIndividualAddressLine = (
  address: EnrollIndividualAddressRequestData | undefined,
  country: CountryData | undefined,
): string => {
  if (!address) {
    return '';
  }

  return `${address.address_line ?? ''} ${address.state_province_county ?? ''} ${address.zip_code ?? ''} ${address.city ?? ''} 
  ${country?.name ?? ''}`;
};

// eslint-disable-next-line max-len
export const getIndividualPOIRequestBody = (files: UploadDocumentFiles[], filesInfo: FileUploadRegisterData, state: RootState): EnrollIndividualIdentityDetailPayload => {
  const { subType } = filesInfo;
  const identity_number = state.crm.individualExtendedInfo!.identity_details[0].identity_number;

  const expiration_date = getExpirationDate(state);

  return {
    identity_document_type: subType,
    identity_number,
    files,
    is_hidden_for_client: false,
    review_status: 'P',
    review_summary: '',
    expiration_date,
  };
};

// eslint-disable-next-line max-len
export const getBinaryPOIDocumentRequestBody = (files: any[], filesInfo: FileUploadRegisterData, state: RootState): RegisterBinaryPOIDocumentPayload => {
  const { type, subType } = filesInfo;
  const identity_number = state.crm.individualExtendedInfo!.identity_details[0].identity_number;
  const expiration_date = getExpirationDate(state);
  const { userId } = state.auth.enroll;
  const poiDocumentId = getEnrollDetailId(EnrollDetailsEnum.DOCUMENT_DETAILS, state);

  return {
    is_hidden_for_client: false,
    review_summary: '',
    documentType: type,
    files,
    identity_number,
    expiration_date,
    isHiddenForClient: false.toString(),
    poiDocumentId,
    reviewSummary: '',
    subDocumentType: subType,
    uploadStamp: getDateTime(),
    uploadedBy: userId?.toString() ?? '',
  };
};

// eslint-disable-next-line max-len
export const getBinaryDocumentRequestBody = (files: any[], filesInfo: FileUploadRegisterData, state: RootState): RegisterBinaryDocumentPayload => {
  const { type, subType } = filesInfo;
  const { userId } = state.auth.enroll;
  const poaDocId = getEnrollDetailId(EnrollDetailsEnum.ADDRESS_DETAILS, state);

  return {
    documentType: type,
    files,
    isHiddenForClient: false.toString(),
    poiDocumentId: poaDocId,
    reviewSummary: '',
    subDocumentType: subType,
    uploadStamp: getDateTime(),
    uploadedBy: userId?.toString() ?? '',
  };
};
