import React from 'react';

import lang from '../../../../lib/language';
import { TextHelpers } from '../../../../lib/util/TextHelpers';

import styles from './BasicModal.module.scss';

type Adjustment = {
  paymentReference: string;
  description: string
  adjustmentCategory: string;
}

type Props = {
  infoModal: Adjustment
}

const AdjustmentModal = ({ infoModal }: Props) => {
  const {
    paymentReference,
    description,
    adjustmentCategory,
  } = infoModal;

  const adjustmentContent = [
    {
      [lang.commonGeneralQuestionsTypeLabel()]: TextHelpers.getAdjustmentCategoryName(adjustmentCategory),
      [lang.mobileTransactionTypeLabelPaymentRef()]: paymentReference,
    },
  ];

  const adjustmentDetails = [
    {
      [lang.commonDetails()]: description,
    },
  ];

  const renderDetails = (item, index: number) => (
    <div className={styles.card} key={index as number}>
      {
            Object.entries(item)
              .map(([key, value]) => {
                if (!value) {
                  return null;
                }

                return (
                  <div className={styles.cardContent} key={key}>
                    <span className={styles.cardKey}>{key}</span>
                    <span className={styles.cardValue}>{value as string}</span>
                  </div>
                );
              })
            }
    </div>
  );

  return (
    <>
      <div className={styles.wrapperCategoryDetails}>
        <div className={styles.containerDetails}>
          { adjustmentContent.map((item, index) => renderDetails(item, index))}
        </div>
      </div>
      <div className={styles.wrapperCategoryDetails}>
        <div className={styles.containerDetails}>
          { adjustmentDetails.map((item, index) => renderDetails(item, index))}
        </div>
      </div>
    </>
  );
};

export default AdjustmentModal;
