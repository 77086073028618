import React from 'react';

import { PaymentProcessEnum } from '../../../../lib/enums';
import lang from '../../../../lib/language';
import { Payment } from '../../../../lib/models/crm/types';
import { isWithdrawal } from '../../../../lib/store/selectors';
import { TextHelpers } from '../../../../lib/util/TextHelpers';

import styles from './BasicModal.module.scss';

type BankTransfer = {
  category: string;
  bankName: string;
  paymentReference: string;
  payment_method: number;
  paymentProcess: number;
  payment: Payment;
}

type Props = {
  infoModal: BankTransfer
}

const BankTransferModal = ({ infoModal }: Props) => {
  const {
    category,
    payment,
  } = infoModal;

  const {
    recipient_account_number,
    recipient_bank_name,
    recipient_iban,
    payment_reference,
    payment_method,
    payment_process,
    sender_iban,
    sender_account_number,
    sender_bank_name,
  } = payment;

  let bankIbanOrAccountNumber: string;
  if (payment_process === PaymentProcessEnum.Deposit) {
    bankIbanOrAccountNumber = sender_iban || sender_account_number;
  } else {
    bankIbanOrAccountNumber = recipient_iban || recipient_account_number;
  }

  const bankTransferContent = [
    {
      [lang.commonGeneralQuestionsTypeLabel()]: TextHelpers.getPaymentMethodName(payment_method),
      [lang.mobileTransactionTypeLabelCategory()]: category,
    },
  ];
  const bankAccountDetails = [
    {
      [lang.mobileTransactionTypeLabelBank()]: payment_process === PaymentProcessEnum.Deposit
        ? sender_bank_name
        : recipient_bank_name,
      [lang.mobileTransactionTypeLabelAccountN()]: bankIbanOrAccountNumber,
      [lang.mobileTransactionTypeLabelPaymentRef()]: payment_reference,
    },
  ];

  const renderDetails = (item, index: number) => (
    <div className={styles.card} key={index as number}>
      {
            Object.entries(item)
              .map(([key, value]) => {
                if (!value) {
                  return null;
                }

                return (
                  <div className={styles.cardContent} key={key}>
                    <span className={styles.cardKey}>{key}</span>
                    <span className={styles.cardValue}>{value as string}</span>
                  </div>
                );
              })
            }
    </div>
  );

  return (
    <>
      <div className={styles.wrapperCategoryDetails}>
        <div className={styles.containerDetails}>
          { bankTransferContent.map((item, index) => renderDetails(item, index))}
        </div>
      </div>
      <span className={styles.whiteText}>
        {
          isWithdrawal(payment)
            ? lang.mobileTransactionTypeToBankAccountHeading()
            : lang.mobileTransactionTypeFromBankAccountHeading()
          }
      </span>
      <div className={styles.wrapperCategoryDetails}>
        <div className={styles.containerDetails}>
          { bankAccountDetails.map((item, index) => renderDetails(item, index))}
        </div>
      </div>
    </>
  );
};

export default BankTransferModal;
