import { isBoolean, isRegExp } from 'lodash';

import { logFilterOptions, reactotronEnabled } from '../../configDebug';


/**
 * In DEV mode this function replaces original console object with filtered versions of it.
 * Options are defined in configDebug and used in this function as logFilterOptions.
 */
export function patchConsole() {
  const {
    include,
    includeToJSON,
    exclude: {
      info: infoExclude,
      debug: debugExclude,
    },
    showNonString,
    toDebug,
    toReactotron,
    toReactotronWarn,
    toReactotronError,
  } = logFilterOptions;
  const originalInfo = console.info;
  const originalLog = console.log;
  const originalDebug = console.debug;
  const originalWarn = console.warn;
  const originalError = console.error;

  console.log = (...args: any[]) => {
    if (args.length > 0) {
      const arg1 = args[0];

      if (typeof (arg1) !== 'string') {
        if (showNonString) console.debug('[LogFiltering] Non-string Arguments', { args, stacktrace: new Error('stacktrace').stack });
      } else if (arg1.match(includeToJSON)) {
        originalLog(...args.map(item => JSON.stringify(item)));
      } else if (arg1.match(include)) {
        originalLog(...args);
      } else if (toDebug) console.debug(...args);

      if (reactotronEnabled && toReactotron) {
        if (isBoolean(toReactotron) || (isRegExp(toReactotron) && `${arg1}`.match(toReactotron))) {
          console.tron.log(args);
        }
      }
    }
  };
  console.info = (...args: any[]) => {
    if (args.length > 0) {
      const arg1 = args[0];

      if (!arg1 || (typeof (arg1) === 'string' && !arg1.match(infoExclude as any))) {
        originalInfo(...args);
      }
    }
  };
  console.debug = (...args: any[]) => {
    if (args.length > 0) {
      const arg1 = args[0];

      if (!arg1 || (typeof (arg1) === 'string' && !arg1.match(debugExclude as any))) {
        originalDebug(...args);
      }
    }
  };
  console.warn = (...args: any[]) => {
    if (args.length > 0) {
      originalWarn(...args);

      if (toReactotronWarn) {
        console.tron.warn(args[0]);
        console.tron.display!({
          name: 'WARN-DATA',
          preview: `[W] ${args[0]}`,
          value: args.slice(1),
          important: true,
        });
      }
    }
  };
  console.error = (...args: any[]) => {
    if (args.length > 0) {
      originalError(...args);

      if (toReactotronError) {
        console.tron.error(args[0]);
        console.tron.display!({
          name: 'ERR-DATA',
          preview: `[E] ${args[0]}`,
          value: args.slice(1),
          important: true,
        });
      }
    }
  };
  console.info('Log Filtering enabled in alaric-retail-terminal-library/util/LogFiltering.ts');
}

export function patchConsoleTron(noop: any) {
  console.tron = {
    log: noop,
    debug: noop,
    display: noop,
    image: noop,
    clear: noop,
    error: noop,
  };
}
