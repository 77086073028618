import React from 'react';
import FailCheck from 'assets/img/check-fail.svg';
import SuccessCheck from 'assets/img/check-success.svg';

import { CallStatus } from '../../lib/enums/call-status.enum';

import styles from './ProcessFinishedModal.module.scss';

type Props = {
    processFailedMessage: string;
    processSuccessMessageTitle: string;
    callStatus?: CallStatus;
    defaultMessageBody?: string;
    defaultMessageTitle?: string;
    processPendingMessage?: { title: string, body: string };
    processPartiallyRejectedMessage?: { title: string, body: string };
    processSuccessMessageBody?: string;
}

const ModalBody = ({
  callStatus,
  defaultMessageBody,
  processFailedMessage,
  defaultMessageTitle,
  processPendingMessage,
  processSuccessMessageBody,
  processSuccessMessageTitle,
  processPartiallyRejectedMessage,
} : Props) => {
  const errorMessageRender = () => {
    if (processPartiallyRejectedMessage) {
      return (
        <>
          <h6 className={styles.title}>{processPartiallyRejectedMessage.title}</h6>
          <p className={styles.message}>{processPartiallyRejectedMessage.body}</p>
        </>
      );
    }

    return (<h6 className={styles.errorTitle}>{processFailedMessage}</h6>);
  };
  const getModalBody = (): JSX.Element => {
    if (callStatus === CallStatus.READY) {
      return (
        <>
          <img src={SuccessCheck} alt="check success" className={styles.icon} />
          <h6 className={styles.title}>{processSuccessMessageTitle}</h6>
          {processSuccessMessageBody && <p className={styles.message}>{processSuccessMessageBody}</p>}
        </>
      );
    }
    if (processPendingMessage) {
      return (
        <>
          <img src={SuccessCheck} alt="check success" className={styles.icon} />
          <h6 className={styles.title}>{processPendingMessage.title}</h6>
          <p className={styles.message}>{processPendingMessage.body}</p>
        </>
      );
    }
    if (defaultMessageTitle) {
      return (
        <>
          <img src={SuccessCheck} alt="check success" className={styles.icon} />
          <h6 className={styles.title}>{defaultMessageTitle}</h6>
          {defaultMessageBody && <p className={styles.message}>{defaultMessageBody}</p>}
        </>
      );
    }
    return (
      <div className={styles.errorWrapper}>
        <img src={FailCheck} alt="rejected order" className={styles.icon} />
        {errorMessageRender()}
      </div>
    );
  };

  return getModalBody();
};

export default ModalBody;
