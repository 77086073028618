import React, { useMemo } from 'react';

import { FileMimeTypeEnum } from '../../../../../lib/enums';
import { createBase64DataUrl } from '../../../../../lib/util/DataHelpers';

import { logoSources } from '../../../../../util/customLogos';

import styles from '../ChartHeader.module.scss';

type Props = {
    symbol: string;
    logo?: string;
}

const Logo = ({ symbol, logo }: Props) => {
  const theLogo = useMemo(() => createBase64DataUrl(logo!, FileMimeTypeEnum.Png), [logo]);
  // TODO: Remove this after the task ART-3903 is done. This is a temporary solution.
  const customLogoSource = symbol ? logoSources[symbol] : null;

  return (
    <img className={styles.logoStyle} alt="symbol logo" src={customLogoSource || theLogo} />
  );
};

export default Logo;
