import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import classNames from 'classnames';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { PositionType } from '../../../../lib/store/reporting/types';

import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import CompanyLogo from '../../../CompanyLogo/CompanyLogo';
import SymboDeatailsButtons from '../../../SwipeableItems/SymboDeatailsButtons';
import { WATCH_LIST_TABLE_ITEMS } from '../../../WatchList/WatchList.constans';

import TableRows from './TableRows';

import styles from './PendingOrdersContainer.module.scss';

type OwnProps = {
  tableHeaders: { [symbol: string]: string | any };
  data: any;
  isMultipleDivOfRow: boolean;
  initiallyLengthRows?: number;
  isButtonsInRow?: boolean;
  companyLogos?: boolean;
  otherButtons?: string;
  hasSymbol?: boolean;
  selectedPieChartElement?: { colors: string[], symbolName: string };
  pendingOrders?: boolean;
}

type Props = OwnProps & ReduxProps;

let symbol: string = '';

const PendingOrdersContainer = (props: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    tableHeaders,
    data,
    isMultipleDivOfRow,
    isButtonsInRow,
    companyLogos,
    otherButtons,
    companyLogoAndNameData,
    hasSymbol,
    selectedPieChartElement,
    initiallyLengthRows = Number.MAX_VALUE,
    pendingOrders,
  } = props;
  const rowRefs = useRef<any[]>([]);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (symbol && event.composedPath().some(
        el => el.className === 'MuiTableRow-root TableView_clickableParrentTableRow__7AzPF',
      )
      && !event.target.className.includes('ButtonsSellAndBuy')
      && !event.target.className.includes('ButtonsCancelAndModify')
      ) {
        history.push(`${UI_ROUTES.symbol}/${symbol}`);
      }
    };
    document.addEventListener('click', handleClickInside);
    return () => document.removeEventListener('click', handleClickInside);
  }, [history]);

  const handleClickRow = (symbolName: string) => {
    symbol = symbolName;
  };

  const activeRowStyle = classNames(
    styles.rowWrapper,
    pendingOrders ? styles.rowActivePendingOrder : styles.rowMyStockActice,
  );

  return (
    <>
      {isMobile ? (
        <SymboDeatailsButtons data={data} activeRowStyle={activeRowStyle} />
      ) : (
        <TableContainer className={styles.wrapperTable}>
          <Table stickyHeader>
            {!isMobile && (
              <TableBody>
                <TableRow>
                  {tableHeaders.map((column) => (
                    <TableCell
                      key={column.key}
                      className={styles.tableHeader}
                    >
                      <Grid className={isButtonsInRow
                        ? styles.buttons
                        : styles.tableHeaderText}
                      >
                        {column.props.children}
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              {data.map((row, index: number) => {
                let companyLogoAndName;
                if (!hasSymbol) {
                  companyLogoAndName = companyLogoAndNameData[row.name];
                }

                return (
                  <Fragment key={row.id}>
                    {
                      index < initiallyLengthRows && (
                        <TableRow
                          ref={(el) => { rowRefs.current[index] = el; }}
                          key={row.id}
                          className={row.name ? styles.clickableParrentTableRow : styles.noClickableParrentTableRow}
                          onClick={() => handleClickRow(row.name)}
                        >
                          {
                            isMultipleDivOfRow && !hasSymbol && (
                            <TableCell className={styles.tableRow} component="th" scope="row">
                              <Grid className={styles.wrapperFirstColumn}>
                                {
                                  companyLogos
                                    ? (
                                      <CompanyLogo
                                        logo={companyLogoAndName?.logo}
                                        className={styles.image}
                                        symbol={row.name}
                                      />
                                    )
                                    : <img src={row.icon} className={styles.image} alt="" />
                                }
                                <Grid className={styles.columnName}>
                                  <Grid>
                                    {row.name}
                                    {row.positionType === PositionType.Sell
                                    && <span className={styles.positionType}>{lang.commonShort()}</span>}
                                  </Grid>
                                  <Grid className={styles.companyName}>{companyLogoAndName?.name}</Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                            )
                          }
                          {
                            Object.keys(row).map((key) => {
                              const isCheckItem = WATCH_LIST_TABLE_ITEMS.includes(key);
                              if (isCheckItem) {
                                return (
                                  <TableRows
                                    key={key}
                                    row={{ ...row, companyName: companyLogoAndName?.name }}
                                    rowKey={row[key]}
                                    headerKey={key}
                                    otherButtons={otherButtons}
                                  />
                                );
                              }
                              return null;
                            })
                          }
                        </TableRow>
                      )
                    }
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndNameData: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateToProps);
  type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PendingOrdersContainer);
