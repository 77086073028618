import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { OrderTicketComponent } from '../util/types';

type VisibleSymbolsData = {
  symbols: string[]
  component: OrderTicketComponent
}

const VisibleSymbolsCache = entity<VisibleSymbolsData>({
  symbols: [] as string[],
  component: undefined as OrderTicketComponent,
}, entityReduxLogger('VisibleSymbols', 'visible-symbols'));

const self = VisibleSymbolsCache;


/**
 * Only used by setVisibleSymbols in TradingHelpers
 */
export const _setVisibleSymbolsCache = (symbols: string[], component: OrderTicketComponent) => { // eslint-disable-line no-underscore-dangle
  self.set({ symbols, component });
};

export default {
  get: self.get,
};
