import React from 'react';

type Props = {
    fillColor: string;
    y?: number;
}

const ScatterPoint = ({ y, fillColor }: Props) => (
  <circle
    cx={50}
    cy={y}
    r={5}
    fill={fillColor}
    stroke={'#fff'}
    strokeWidth={3}
  />
);

export default ScatterPoint;
