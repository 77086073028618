const MARKET_HOURS = {
  description:
    'This data is based on a file used by MDS. The data was imported to a Google Sheet (see source) and additionally edited there, verified against nyse.com. The NY Time is used considering DST',
  sources: {
    sheet: 'https://docs.google.com/spreadsheets/d/1tLDDCguE5I2T_qEqSDfcumlUA9Ons8LmvCnQweVyA1Q/edit#gid=2117616813',
    slides: 'https://docs.google.com/presentation/d/17j2erZF0NBqA67IF71b8ub0JK5p7-__OPhYCpyMRd00/edit#slide=id.gfd4bd33460_0_5',
  },
  workingTime: {
    preMarket: 4.0,
    open: 9.3,
    close: 16.0,
    afterMarketEnd: 20.0,
    closeHalfDay: 13,
    afterMarketEndHalfDay: 17.0,
  },
  halfDays: {
    '2020-12-24': true,
    '2021-11-26': true,
    '2022-11-25': true,
    '2023-07-03': true,
    '2023-11-24': true,
    '2024-07-03': true,
    '2024-11-29': true,
    '2024-12-24': true,
  },
  holidays: {
    '2020-12-25': true,
    '2021-01-01': true,
    '2021-01-18': true,
    '2021-02-15': true,
    '2021-04-02': true,
    '2021-05-31': true,
    '2021-07-05': true,
    '2021-09-06': true,
    '2021-11-25': true,
    '2021-12-24': true,
    '2022-01-17': true,
    '2022-02-21': true,
    '2022-05-30': true,
    '2022-06-20': true,
    '2022-07-04': true,
    '2022-09-05': true,
    '2022-11-24': true,
    '2022-12-26': true,
    '2023-01-02': true,
    '2023-01-16': true,
    '2023-02-20': true,
    '2023-04-07': true,
    '2023-05-29': true,
    '2023-06-19': true,
    '2023-07-04': true,
    '2023-09-04': true,
    '2023-11-23': true,
    '2023-12-25': true,
    '2024-01-01': true,
    '2024-01-15': true,
    '2024-02-19': true,
    '2024-03-29': true,
    '2024-05-27': true,
    '2024-06-19': true,
    '2024-07-04': true,
    '2024-09-02': true,
    '2024-11-28': true,
    '2024-12-25': true,
  },
};

export type MarketHours = typeof MARKET_HOURS
export type HalfDayKeys = keyof typeof MARKET_HOURS.halfDays
export type HolidayKeys = keyof typeof MARKET_HOURS.holidays

export default MARKET_HOURS;
