import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { isRegExp } from 'lodash';
import { Reducer, StoreEnhancer } from 'redux';
import { createLogger } from 'redux-logger';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import {
  collapsedReduxLogging,
  reactotronEnabled,
  reduxLogging,
  reduxLoggingExclude,
  reduxLoggingInclude,
} from '../../configDebug';
import configLib from '../../configLib';

import amsEpics from './ams/epics';
import appEpics from './app/epics';
import authEpics from './auth/epics';
import crmEpics from './crm/epics';
import fileEpics from './file/epics';
import fundamentalEpics from './fundamental/epics';
import gatewayEpics from './gateway/epics';
import marketDataEpics from './market-data/epics';
import newsEpics from './news/epics';
import paymentEpics from './payment/epics';
import execEpics from './reporting/epics';
import reportingFileEpics from './reportingFile/epics';
import sesEpics from './ses/epics';
import tradingEpics from './trading/epics';
import { TradeState } from './trading/types';
import amsReducer, { AMSState } from './ams';
import appReducer, { AppState } from './app';
import authReducer, { AuthState } from './auth';
import crmReducer, { CRMState } from './crm';
import { debugEntityReducer, debugReducer, logReducer } from './debug-reducers';
import fileReducer, { FileState } from './file';
import fundamentalReducer from './fundamental';
import gatewayReducer, { GatewayState } from './gateway';
import marketDataReducer, { MarketDataState } from './market-data';
import newsReducer from './news';
import paymentReducer, { PaymentState } from './payment';
import executionsReducer from './reporting';
import reportingFileReducer from './reportingFile';
import sesReducer, { SESState } from './ses';
import tradingReducer from './trading';


let store = configLib.store;
let persistor = configLib.storePersistor;

const appPersistConfig: PersistConfig<AppState> = {
  storage: configLib.storage,
  key: 'app',
  whitelist: [],
};


const marketDataPersistConfig: PersistConfig<MarketDataState> = {
  storage: configLib.storage,
  key: 'marketData',
  whitelist: [ 'favouriteStocks', 'errorsLog' ],
};

const tradingPersistConfig: PersistConfig<TradeState> = {
  storage: configLib.storage,
  key: 'trading',
  whitelist: [ 'errorsLog', 'orderInfo' ],
};

const gatewayPersistConfig: PersistConfig<GatewayState> = {
  storage: configLib.storage,
  key: 'gateway',
  whitelist: [ 'errorsLog', 'orderInfo' ],
};

const crmPersistConfig: PersistConfig<CRMState> = {
  storage: configLib.storage,
  key: 'crm',
  whitelist: [ 'errorsLog' ],
};

const filePersistConfig: PersistConfig<FileState> = {
  storage: configLib.storage,
  key: 'file',
  whitelist: [ 'errorsLog' ],
};

const paymentPersistConfig: PersistConfig<PaymentState> = {
  storage: configLib.storage,
  key: 'payment',
  whitelist: [ 'webLastCardTransfer', 'errorsLog', 'linkedAccountIdByLastPayment', 'linkedAccountIdByLastWithdrawal' ],
};

const authPersistConfig: PersistConfig<AuthState> = {
  storage: configLib.storage,
  key: 'auth',
  whitelist: [
    'callBackPending',
    'userData',
    'enrollPending',
    'errorsLog',
    'accessToken',
    'isPasscodeActive',
    'individualAvatar',
    'passcode',
    'isBiometricsActive',
    'refreshToken',
    'isLogin',
    'isAppropriatenessPopupConfirmed',
  ],
};

const amsPersistConfig: PersistConfig<AMSState> = {
  storage: configLib.storage,
  key: 'ams',
  whitelist: [ 'errorsLog' ],
};

const sesPersistConfig: PersistConfig<SESState> = {
  storage: configLib.storage,
  key: 'ses',
  whitelist: [ 'errorsLog' ],
};

function persistReducerWrapper<T extends any = any>(config: PersistConfig<T>, reducer: Reducer) {
  return (
    // eslint-disable-next-line no-underscore-dangle
    configLib.__TEST__
      ? reducer
      : persistReducer(config, reducer)
  ) as Reducer<T>;
}

export let rootEpic: Epic = null as any;
export const rootReducer = combineReducers({
  app: persistReducerWrapper<AppState>(appPersistConfig, appReducer),
  auth: persistReducerWrapper<AuthState>(authPersistConfig, authReducer),
  gateway: persistReducerWrapper<GatewayState>(gatewayPersistConfig, gatewayReducer),
  crm: persistReducerWrapper<CRMState>(crmPersistConfig, crmReducer),
  file: persistReducerWrapper<FileState>(filePersistConfig, fileReducer),
  log: logReducer,
  trading: persistReducerWrapper<TradeState>(tradingPersistConfig, tradingReducer),
  marketData: persistReducerWrapper<MarketDataState>(marketDataPersistConfig, marketDataReducer),
  fundamental: fundamentalReducer,
  reporting: executionsReducer,
  reportingFile: reportingFileReducer,
  news: newsReducer,
  payment: persistReducerWrapper<PaymentState>(paymentPersistConfig, paymentReducer),
  ams: persistReducerWrapper<AMSState>(amsPersistConfig, amsReducer) ?? amsReducer,
  ses: persistReducerWrapper<SESState>(sesPersistConfig, sesReducer) ?? sesReducer,
  ...(
    configLib.__DEV__
      ? {
        debugEntity: debugEntityReducer,
        debug: debugReducer,
      }
      : {}
  ),
});

if (!store) {
  rootEpic = combineEpics(
    appEpics,
    authEpics,
    crmEpics,
    fileEpics,
    tradingEpics,
    fundamentalEpics,
    marketDataEpics,
    execEpics,
    newsEpics,
    gatewayEpics,
    paymentEpics,
    amsEpics,
    reportingFileEpics,
    sesEpics,
  );

  const epicMiddleware = createEpicMiddleware();
  const logger = () => createLogger({
    collapsed: collapsedReduxLogging,
    logErrors: true,
    logger: console,
    predicate: (getState: any, action: any) => {
      const included = (
        isRegExp(reduxLoggingInclude)
          ? action.type.match(reduxLoggingInclude)
          : !!reduxLoggingInclude
      );
      const excluded = (
        isRegExp(reduxLoggingExclude)
          ? action.type.match(reduxLoggingExclude) == null
          : !reduxLoggingExclude
      );
      return included && excluded;
    },
  });


  let enhancers: StoreEnhancer[] | undefined;

  // Reactotron setup
  if (reactotronEnabled && configLib.__DEV__) {
    enhancers = (configLib as any).reactotronInit().enhancers;
  }

  store = configureStore({
    reducer: rootReducer,
    middleware: reduxLogging ? [ epicMiddleware, logger() ] : [ epicMiddleware ],
    enhancers,
  });
  persistor = persistStore(store);
  configLib.setStore(store, persistor);

  epicMiddleware.run(rootEpic);
}

export default store;

export { persistor };
export type RootState = ReturnType<typeof rootReducer>
export type ReduxStore = typeof store
