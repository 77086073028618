import React from 'react';

import lang from '../../../../lib/language';
import { TextHelpers } from '../../../../lib/util/TextHelpers';

import styles from './BasicModal.module.scss';

type CardTransfer = {
  category: string;
  paymentReference: string;
  payment_method: number
}

type Props = {
  infoModal: CardTransfer
}

const CardTransferModal = ({ infoModal }: Props) => {
  const {
    category,
    paymentReference,
    payment_method,
  } = infoModal;

  const cardTransferContent = [
    {
      [lang.commonGeneralQuestionsTypeLabel()]: TextHelpers.getPaymentMethodName(payment_method),
      [lang.mobileTransactionTypeLabelCategory()]: category,
      [lang.mobileTransactionTypeLabelPaymentRef()]: paymentReference,
    },
  ];

  return (
    <div className={styles.wrapperCategoryDetails}>
      <div className={styles.containerDetails}>
        { cardTransferContent.map((item, index) => (
          <div className={styles.card} key={index as number}>
            {
                Object.entries(item)
                  .map(([key, value]) => {
                    if (!value) {
                      return null;
                    }
                    return (
                      <div className={styles.cardContent} key={key}>
                        <span className={styles.cardKey}>{key}</span>
                        <span className={styles.cardValue}>{value as string}</span>
                      </div>
                    );
                  })
                }
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardTransferModal;
