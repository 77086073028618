// This is not currently used. We only have dev and test environments
// Reference: https://alaricsecurities.atlassian.net/wiki/spaces/1/pages/733708289/Environments

/**
 * Check for correct domain name (dev/test suffix) and port.
 *
 * For example for gateway:
 *    - on dev environment - gwdev.alaricsecurities.net:54072
 *    - on test environment - gwtest.alaricsecurities.net:54073
 */

const USE_REPORTING_API_DIRECTLY = false;
const GATEWAY_ROOT = 'gw.alaricsecurities.net';

const mainConfig = {
  auth: {
    issuer: 'https://auth.alaricsecurities.net',
    clientId: 'HammerWeb',
    loginRedirectUrl: 'https://hammer.alaricsecurities.net/auth-result',
    redirectUrlConfirmEmail: 'https://hammer.alaricsecurities.net/email-confirm',
    redirectUrlEmailConfirmed: 'https://hammer.alaricsecurities.net/email-confirmed',
    redirectUrlResetEmail: 'https://hammer.alaricsecurities.net/email-reset',
    redirectUrlResendEmail: 'https://hammer.alaricsecurities.net/email-resend',
    redirectUrlPhoneVerified: 'https://hammer.alaricsecurities.net/phone-verify',
    postLogoutRedirectUrl: 'https://hammer.alaricsecurities.net/authentication/login',
    redirectUrlFunds: 'https://hammer.alaricsecurities.net/funds',
    redirectUrlProfileSecurity: 'https://hammer.alaricsecurities.net/funds',
    // 1 - Alaric, 2 - Flb
    tenantId: 1,
    scopes: [
      'roles',
      'TokenserverAPI.write',
      'TokenserverAPI.read',
      'tenant',
      'Alaric.FileStorage',
      'offline_access',
      'Alaric.Payment',
      'Alaric.Gateway',
      'Alaric.CRM',
      'IdentityServerApi',
      'phone',
      'two_factor_enabled',
      'Alaric.Audit',
      'Alaric.Subscription',
      'Alaric.Entitlement',
      'Alaric.Reporting',
      'platform',
      'Alaric.MarketData',
      'user_relation',
      'Alaric.News',
      'Alaric.Tapi',
      'Alaric.Fundamental',
      'openid',
      'profile',
      'email',
      'Alaric.MS',
    ],
  },
  crm: {
    url: 'https://crm-api.alaricsecurities.net',
  },
  pwp: {
    url: 'https://alaric-pro.alaricsecurities.net/',
  },
  fundamental: {
    url: 'https://fundamental.alaricsecurities.net/api',
  },
  reporting: {
    url: (
      USE_REPORTING_API_DIRECTLY
        ? 'https://rpt.alaricsecurities.net/api'
        : `https://${GATEWAY_ROOT}/reporting`
    ),
  },
  reportingFile: {
    url: 'https://rpt.alaricsecurities.net/api',
  },
  news: {
    url: 'https://news.alaricsecurities.net/api/v1',
  },
  file: {
    url: 'https://file.alaricsecurities.net/api/v1',
  },
  gateway: {
    url: GATEWAY_ROOT,
  },
  marketData: {
    url: 'ws://mds-lb.alaricsecurities.net:8001',
  },
  trading: {
    url: 'wss://tapi-us-east-1.successionsys.com/wstapi/prod',
  },
  payment: {
    url: 'https://pay.alaricsecurities.net/api/v1/Payment',
  },
  ams: {
    url: 'https://ams.alaricsecurities.net/api',
  },
  ses: {
    url: 'https://ses.alaricsecurities.net/api',
  },
  currentEnv: 'prod',
  dateFormat: 'LL',
  links: {
    privacyPolicyUrl: 'https://alaricsecurities.com/privacy-policy/',
    cookiesPolicyUrl: 'https://alaricsecurities.com/cookie-policy/',
    helpCenterUrl: 'https://alaricsecurities.com/contact/#',
    signUpUrl: 'https://alaric-pro.alaricsecurities.net/authentication/sign-up/individual?portalId=HammerWeb',
    etfInfo: 'https://alaricsecurities.com/services/etf-trading/',
  },
};

/** Needed for tests */
export function changeEnv(env: any) { mainConfig.currentEnv = env; }

export default mainConfig;
