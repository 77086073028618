import React from 'react';

import profileImage from '../../assets/img/profile.svg';
import profileBackground from '../../assets/img/profile_background.svg';

import styles from './UserPhoto.module.scss';

type Props = {
  fullSize?: boolean,
};

const UserPhoto = (props: Props) => {
  const { fullSize } = props;

  return (
    <div className={styles.profileWrapper}>
      <img src={profileBackground} alt="profile background" className={fullSize ? styles.fullsizeBackground : styles.background} />
      <img src={profileImage} alt="profile" className={fullSize ? styles.fullsizeProfileImage : styles.profileImage} />
    </div>
  );
};

export default UserPhoto;
