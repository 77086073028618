import { clone } from 'lodash';
import moment from 'moment';

import { MOCK } from '../../configDebug';
import configLib from '../../configLib';
import { IndividualExtendedInfoData } from '../models/enroll';
import { TypedObject } from '../util/types';

import { ExtendedInfoMockEnum } from './types';

export const badRequest = require('./badRequest.json');
export const empty = require('./empty.json');


function mockAuthorise(defaultCase = null) {
  switch (MOCK.EXTENDED_INFO) {
    case ExtendedInfoMockEnum.ENROLL:
      return require('./auth/refresh-email-not-verified.json');

    default:
      if (defaultCase) return defaultCase;
      return require('./auth/refresh.json');
  }
}

// Auth
const authImplementation: any = {
  authorize: mockAuthorise(),
  refresh: mockAuthorise(require('./auth/refresh.json')),
  // common
  registerUser: { data: { id: 198 } },
  userPermissions: require('./auth/userPermissions.json'),
  confirmEmail: require('./auth/refresh.json'),
};
const authProxy = {
  get(target: any, callName: string, receiver: any) {
    if (!authImplementation[callName]) return empty;

    return authImplementation[callName];
  },
};
export const auth = new Proxy(authImplementation, authProxy);

// CRM
const crmImplementation: any = {
  accounts: require('./crm/accounts.json'),
  accountInfo: require('./crm/accountInfo.json'),
  accountDetails: require('./crm/accountDetails.json'),
  // enroll
  sourceOfFundOwn: require('./crm/sourceOfFundOwn.json'),
  country: require('./crm/country.json'),
  employmentStatusOwn: require('./crm/employmentStatusOwn.json'),
  businessOwn: require('./crm/businessOwn.json'),
  purposeOfTradingActive: require('./crm/purposeOfTradingActive.json'),
  financialQuestionnaireTypes: require('./crm/financialQuestionnaireTypes.json'),
  financialQuestionnaireOwnList: require('./crm/financialQuestionnaireOwnList.json'),
  legalDeclarationList: require('./crm/legalDeclarationList.json'),
  individualOwnLegalDeclarations: require('./crm/individualOwnLegalDeclarations.json'),
  individualOwnActiveRiskAssessment: require('./crm/individualOwnActiveRiskAssessment.json'),
  pepDetail: require('./crm/pepDetail.json'),
  getPayments: require('./crm/payments.json'),
  // enroll - other partially or fully filled examples
  individualIdentityDetailOwn: require('./crm/individualIdentityDetailOwn.json'),
  linkedAccounts: require('./crm/individualLinkedAccounts.json'),
  detailedAccount: require('./crm/accountDetails.json'),
  vendorBankAccount: require('./crm/bankAccountInfo.json'),
  fundingWireTransferInfo: require('./crm/fundingWireTransferResponse.json'),
  // complex sets
  ...(individualExtendedInfo(MOCK.EXTENDED_INFO)),
};
type MockedCall = { id: number, count: number };
let callsRegistry: TypedObject<MockedCall> = {};
const crmProxy = {
  get(target: any, callName: string, receiver: any) {
    if ([ '$$typeof', 'prototype' ].includes(callName)) return target[callName];

    if (crmImplementation[callName] === undefined && MOCK.RESPONSE_EMPTY_FALLBACK) {
      console.warn(`[CRM-MOCK] No implementation for '${callName}' - returning empty response`);
      return empty;
    }

    // initial value of callsRegistry for callName
    let isInitial = !callsRegistry[callName];
    if (isInitial) {
      callsRegistry[callName] = { id: 1, count: 1 };
      isInitial = true;
    }
    let current = callsRegistry[callName];

    // update id and count (if not initial)
    if (!isInitial) {
      if (crmImplementation[`${callName}_${current.id + 1}`]) current.id++;
      current.count++;
    }

    // logging
    const { count, id } = current;
    if (id > 1 && crmImplementation[`${callName}_${id}`]) {
      console.debug(`[CRM-MOCK] Calling '${`${callName}_${id}`}' - ${count} time(s) called`);
      return crmImplementation[`${callName}_${id}`];
    }

    console.debug(`[CRM-MOCK] Calling '${callName}' for the first time`);
    return crmImplementation[callName];
  },
};
export const crm = new Proxy(crmImplementation, crmProxy);

// File
export const file: any = {
  upload: 'some-id',
};

// Gateway
export const gateway: any = {
  cnbbo: require('./gateway/md/compressed-2.json'),
  nbbo: require('./gateway/md/nbbo.json'),
  ohlcv: require('./gateway/md/ohlcv-RecentPrices.json'),
  news: require('./gateway/news/news-for-symbols.json'),
  newsForSymbol: require('./gateway/news/news-for-symbols.json'),
  trade: require('./gateway/md/trade.json'),
  // TODO: This case has not yet been received for NewOrderRequest - temporarily using ParentOrder
  newOrderAccepted: require('./gateway/sor/new-order-accepted.json'),
  newOrderRejected: require('./gateway/sor/new-order-rejected.json'),
  instrumentSnapshot: require('./gateway/md/instrument-snapshot.json'),
};

// Trading
export const trading: any = {
  getOrders: require('./trading/orders.json'),
  getPositions: require('./trading/positions.json'),
  newOrder: require('./trading/neworder.json'),
  getBalances: require('./trading/balances.json'),
};

// Market Data
const marketImpl: any = {
  subscribe: require('./market-data/nbbo.json'),
  nbbo: require('./market-data/nbbo.json'),
  ohlcv: require('./market-data/ohlcv.json'),
};
const marketProxy = {
  get(target: any, callName: string, receiver: any) {
    if (callName === 'ohlcv') {
      let tmp = clone(target[callName] ?? []);
      const item0 = clone(tmp[0]);
      if (item0) {
        item0.date = moment().format('YYYY-MM-DD'); // add current date for price info
        tmp = tmp.concat();
        tmp.push(item0);
      }
      return tmp;
    }
    return target[callName];
  },
};
export const market = new Proxy(marketImpl, marketProxy);

// Fundamental
export const fundamental: any = {
  search: require('./fundamental/search.json'),
  company: require('./fundamental/companies.json'),
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  companyLogo: require('./fundamental/companyLogo.json'),
  companyLogoAndName: require('./fundamental/companyLogoAndName.json'),
  companyLogoMD5: require('./fundamental/companyLogoMD5.json'),
  earnings: require('./fundamental/earnings.json'),
  stats: require('./fundamental/stats.json'),
  popularStocks: require('./fundamental/popularStocks.json'),
  sectors: require('./fundamental/sectors.json'),
  sector: require('./fundamental/sector.json'),
};

// News
export const news: any = {
  news: require('./news/news.json'),
  newsForSymbol: require('./news/news.json'),
};

// Reporting
export const reporting: any = {
  getNetEquityComponents: require('./reporting/netEquityComponents.json'),
  getAccountTradeHistory: require('./reporting/accountTradeHistory.json'),
  getOpenPositionsData: require('./reporting/openPositionsData.json'),
  getAccountSummaryData: require('./reporting/accountSummary.json'),
  getOrderDetailData: require('./reporting/orderDetail.json'),
};

// AMS

export const ams: any = {
  getAllMessages: require('./ams/getAllMessages.json'),
  getMessage: require('./ams/getMessage.json'),
  getSummary: require('./ams/getSummary.json'),
  voidResponse: require('./ams/voidResponse.json'),
  notifyUnreadCountResponse: require('./ams/notifyUnreadCount.json'),
};


/**
 * Mock mode for extended info. Populates data per case
 * @param caseNo Default is tradeuser logged in
 */
function individualExtendedInfo(caseNo?: ExtendedInfoMockEnum) {
  let res: TypedObject<IndividualExtendedInfoData | null>;
  switch (caseNo) {
    case ExtendedInfoMockEnum.ENROLL_1_1_PD:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-1.1-pd.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_1_2_PD:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-1.2-pd.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_2_1_FIN_QS:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-2.1-fin-qs.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_2_4_SOF:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-2.4-fin-qs.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_3_5_FIN_QS:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-3.5-fin-qs.json'),
        individualExtendedInfo_1: require('./crm/individualExtendedInfo_onboarding-verification.json'),
        individualExtendedInfo_2: require('./crm/individualExtendedInfo_new.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_VERIFICATION:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-verification.json'),
        individualExtendedInfo_2: require('./crm/individualExtendedInfo_new.json'),
      };
      break;

    case ExtendedInfoMockEnum.ENROLL_DOCUMENT_VERIFICATION:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_onboarding-pending-verification.json'),
      };
      break;

    case ExtendedInfoMockEnum.NULL:
      res = {
        individualExtendedInfo: null,
      };
      break;

    case ExtendedInfoMockEnum.ENROLL:
      res = {
        individualExtendedInfo: {} as IndividualExtendedInfoData,
      };
      break;

    default:
      res = {
        individualExtendedInfo: require('./crm/individualExtendedInfo_new.json'),
      };
      break;
  }

  res.individualExtendedInfo = {
    ...res.individualExtendedInfo,
    enroll_channel: configLib.isWeb ? 'W' : 'M',
  } as any;

  return res;
}
