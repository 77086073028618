import apisauce, { ApisauceInstance } from 'apisauce';

import configLib from '../../configLib';
import { PredefinedMilisecondsTypeEnum } from '../enums';
import { REQUEST_TIMEOUT } from '../libSettings';
import { setSlowConnectionStatus } from '../store/app';
import { NullableString } from '../util/types';

import { CacheService } from './cache/Cache.service';
import { DOT } from './cache/constants';
import { BaseCacheableType, CacheableDataType, CacheName, HttpMethods } from './cache/types';
import { LoggerService } from './logger/Logger.service';

const logger = LoggerService.getLogger();
const cacheService = CacheService.getService();

export abstract class BaseRequestService {
  protected readonly cacheDefinitions: CacheableDataType[] = [];

  api: ApisauceInstance;

    protected abstract getBaseUrl(): string;

    protected addOrUpdateCacheDefinition(
      url: string,
      cacheName: CacheName,
      method = HttpMethods.GET, // eslint-disable-line default-param-last
      cacheTTL = PredefinedMilisecondsTypeEnum.fiveMinutes, // eslint-disable-line default-param-last
      cacheKeys?: string[],
      cacheConditions?: Record<string, NullableString[]>,
    ): void {
      const cacheDefinition = this.cacheDefinitions.find(item => item.cacheName === cacheName);

      if (!cacheDefinition) {
        this.cacheDefinitions.push({ cacheTTL, method, cacheName, url, cacheKeys, cacheConditions });
        return;
      }

      cacheDefinition.cacheName = cacheName;
      cacheDefinition.cacheTTL = cacheTTL;
      cacheDefinition.url = url;
      cacheDefinition.method = method;
      cacheDefinition.cacheKeys = cacheKeys;
      cacheDefinition.cacheConditions = cacheConditions;
    }

    constructor(token: string | null, timeout = REQUEST_TIMEOUT) {
      this.api = apisauce.create({
        baseURL: this.getBaseUrl(),
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
        timeout,
      });

      this.api.axiosInstance.interceptors.request.use(
        async requestConfig => {
          const { cacheKey } = this.getFullCacheKeyAndTTLByConfig(requestConfig) ?? {};

          if (!cacheKey) {
            return requestConfig;
          }

          const cachedItem = await cacheService.getOrRemoveFromCache(cacheKey);

          if (cachedItem) {
            // save the new request for cancellation
            const source = apisauce.CancelToken.source();

            // eslint-disable-next-line no-param-reassign
            requestConfig.cancelToken = source.token;

            source.cancel(cacheKey);
          }

          return requestConfig;
        },
        error => {
          logger.warn('An error occured whil making HTTP request! error:', error);
          return Promise.reject(error);
        },
      );

      this.api.axiosInstance.interceptors.response.use(
        response => {
          configLib.store.dispatch(setSlowConnectionStatus(false));
          const { cacheKey, cacheTTL } = this.getFullCacheKeyAndTTLByConfig(response.config, true) ?? {};

          if (!cacheKey || !cacheTTL) { // Caching not activated for the related request
            return response;
          }

          const { config, headers, request, ...newResponse } = response;// remove config from response

          cacheService.putCache(cacheKey, cacheTTL, newResponse);

          return response;
        },
        async error => {
          if (apisauce.isCancel(error)) { // If the request canceled then return response from the storage
            const cachedItem = await cacheService.getOrRemoveFromCache(error.message);
            return Promise.resolve(cachedItem?.data);
          }

          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            configLib.store.dispatch(setSlowConnectionStatus(true));
          }
          return Promise.reject(error);
        },
      );
    }

    private getFullCacheKeyAndTTLByConfig(
      config: typeof this.api.axiosInstance.defaults,
      isParseJSONNeed = false,
    ): BaseCacheableType | null {
      const url = this.getURLByConfig(config);
      const cacheRequest = this.cacheDefinitions.find(item => item.url === url);

      if (!cacheRequest) { // URL is not defined in cacheable list
        return null;
      }

      const { cacheName, cacheConditions, method, cacheKeys, cacheTTL } = cacheRequest;

      let fullCacheKey = `${cacheName}:${method}`;
      if (!cacheKeys) {
        return { cacheKey: fullCacheKey, cacheTTL };// cache by URL without any param
      }

      const cachedDataOrParam: typeof config.data | typeof config.params = this.getCacheParamBy(
        method,
        isParseJSONNeed,
        config,
      );

      for (const cacheConditionKey in cacheConditions) {
        const valueByPath = this.getItemByDotNotation(cacheConditionKey, cachedDataOrParam);
        if (!cacheConditions[cacheConditionKey].includes(valueByPath)) {
          return null;// Cache condition does not match
        }
      }

      cacheKeys.forEach(item => {
        const valueByPath = this.getItemByDotNotation(item, cachedDataOrParam);
        fullCacheKey = `${fullCacheKey}:${valueByPath}`;
      });

      return { cacheKey: fullCacheKey, cacheTTL };
    }

    private getItemByDotNotation(
      path: string,
      cachedDataOrParam: typeof this.api.axiosInstance.defaults.data | typeof this.api.axiosInstance.defaults.params,
    ): NullableString {
      const splittedLKeys = path.split(DOT);
      let tempData: any = null;

      splittedLKeys.forEach(item => {
        try {
          tempData = JSON.parse(tempData ? tempData[item] : cachedDataOrParam[item]);
        } catch (e) {
          tempData = tempData ? tempData[item] : cachedDataOrParam[item];
        }
      });

      return typeof tempData === 'string' || typeof tempData === 'undefined' ? tempData : String(tempData);
    }

    private getCacheParamBy(
      method: HttpMethods,
      isParseJSONNeed: boolean,
      config: typeof this.api.axiosInstance.defaults,
    ): typeof config.data | typeof config.params {
      // if the method is POST then the request params will be in config.data.
      // if not then requests params will be in config.params
      if (method === HttpMethods.POST) {
        return isParseJSONNeed ? JSON.parse(config.data) : config.data;
      }

      return config.params;
    }

    private getURLByConfig(config: typeof this.api.axiosInstance.defaults) {
      if (!config) {
        return '';
      }

      return `${config.baseURL}${config.url}`;
    }

    public setToken(token: string): void {
      if (!token) {
        console.warn('[BaseRequest.service] setToken - token invalid');
        return;
      }

      this.api.setHeader('Authorization', `Bearer ${token}`);
    }
}
