import { ApiResponse } from 'apisauce';

import config from '../../../config';
import { PredefinedMilisecondsTypeEnum } from '../../enums';
import { PagedResponse } from '../../models/common-response-types';
import { Company, CompanyLogo, CompanyLogoMD5 } from '../../models/company';
import {
  CompanyLogoAndNameResponse,
  DividendPayerData,
  Earning,
  ETF,
  ETFDetailParams,
  ETFDetailResponse,
  ETFParams,
  Filters,
  HighGrowthCompanyData,
  IPO,
  NewStockData,
  PageableFilterRequest,
  PopularStock,
  Sector,
  SectorDetail,
  SectorDetailParams,
  Split,
  StocksSymbolSet,
} from '../../models/fundamental/types';
import { SortingType } from '../../models/pageable';
import { StockDescriptor } from '../../models/stock-descriptor';
import { SymbolKeyStat } from '../../models/symbol-key-stat';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_STOCK_LIMIT,
  DEFAULT_SECTOR_DETAILS_SIZE,
  DEFAULT_SECTOR_PAGE, GET_FIRST_ITEM_SIZE,
} from '../../store/fundamental/constants';
import { SearchEarningsPayload } from '../../store/fundamental/types';
import { NullableString } from '../../util/types';
import { BaseRequestService } from '../BaseRequest.service';
import { DOT } from '../cache/constants';
import { CacheName, HttpMethods } from '../cache/types';
import { DynamicFilterBuilder } from '../filter/dynamic-filter-builder';
import { DynamicFilterType } from '../filter/types';
import { IFundamentalService } from '../types';

import { isPredefinedTopGainersTopLosersFilter } from './helpers';

const API_URLS = {
  searchCompanies: '/companies/search',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  earnings: '/earnings',
  earningsByInterval: '/earnings/by-interval',
  earningsSearch: '/earnings/search',
  keyStats: '/key_stats',
  ETFKeyStats: '/key-stats',
  getCompany: '/companies',
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  getCompanyLogo: '/companies/logo',
  getCompanyLogoAndName: '/companies/logo',
  getCompanyLogoMD5: '/companies/logo-md5',
  popularStocks: '/symbols/popular',
  newStocks: '/symbols/new',
  dividendPayers: '/symbols/dividend-payers',
  highGrowthCompanies: '/companies/high-growth',
  sectors: '/sectors',
  sector: '/symbols/sector',
  getMarketIndexes: '/market-index/latest/search',
  topGainers: '/symbols/top-gainers',
  topGainersCustom: '/symbols/top-gainers/custom',
  topLosers: '/symbols/top-losers',
  topLosersCustom: '/symbols/top-losers/custom',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  ipos: '/ipos',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  splits: '/splits',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  splitsByInterval: '/splits/by-interval',
  etfs: '/symbols',
};

export class FundamentalService extends BaseRequestService implements IFundamentalService {
  protected getBaseUrl(): string {
    return config.fundamental.url;
  }

  private getItems<T>(url: string, urlForInterval: string, allSymbols: string[], startDate?: string, endDate?: string) {
    const symbols = allSymbols?.reduce((prev, curr) => `${prev},${curr}`, '');
    if (startDate !== undefined && endDate !== undefined) {
      return this.api.get<T[]>(urlForInterval, { symbols, startDate, endDate });
    }
    return this.api.get<T[]>(url, { symbols });
  }

  /**
   * @param query text search parameter
   * @param params dynamic filter search parameter
   * @param limit expected result count
   * @param symbolSet Two cases are possible, as follows:
   *   Case 1
   *   If empty or `NONE` - full list of symbols is searched
   *
   *   Case 2
   *   If `RETAIL` - 10n symbol universe is searched
   *
   *   For example if searching 'com':
   *     Case 1 - 5934 symbols are returned (2022-09-23 12pm EEST)
   *     Case 2 - 870 symbols are returned (2022-09-23 12pm EEST)
   */
  public searchCompanies(query: string, params: DynamicFilterType|undefined, limit = 16, symbolSet: StocksSymbolSet = 'RETAIL') {
    const APIPath = API_URLS.searchCompanies;
    const url = this.getBaseUrl() + APIPath;
    const issueTypeKey = `filters${DOT}eq${DOT}issueType`;
    const cacheConditions: Record<string, NullableString[]> = {};
    cacheConditions.limit = [ String(DEFAULT_SEARCH_STOCK_LIMIT) ];
    cacheConditions[issueTypeKey] = [ 'cs', 'et', undefined ];

    this.addOrUpdateCacheDefinition(
      url,
      CacheName.searchCompanies,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.fiveMinutes,
      [ 'text', issueTypeKey, 'limit' ],
      cacheConditions,
    );

    return this.api.get<StockDescriptor[]>(APIPath, {
      text: query,
      searchFields: 'symbol,companyName,description',
      projections: 'symbol,companyName,description,ceo,industry,city,state,issueType',
      symbolSet,
      limit,
      filters: params ? JSON.stringify(params) : null,
    });
  }

  public getEarnings(symbols: string[], startDate?: string, endDate?: string) {
    const APIPath = API_URLS.earnings;
    return this.getItems<Earning>(APIPath, API_URLS.earningsByInterval, symbols, startDate, endDate);
  }

  public searchEarnings(params?: SearchEarningsPayload) {
    const DEFAULT_EARNING_SEARCH_SORT_FIELD = 'date';
    const { symbols, startDate, endDate } = params ?? {};

    const url = FundamentalService.preparePageableRequestURL(
      API_URLS.earningsSearch,
      DEFAULT_EARNING_SEARCH_SORT_FIELD,
      {
        ...params,
        sortAscending: true,
      },
    );

    /**
     * @deprecated
     * Please use DynamicFilterBuilder(services/filter/dynamic-filter-builder) for the common filtering solution
     */
    const filters = {
      in: symbols && symbols.length > 0 ? symbols.join(',') : undefined,
      lte: endDate ? { date: endDate } : undefined,
      gte: startDate ? { date: startDate } : undefined,
    };

    const projections = [ 'date', 'time', 'epsPrior', 'epsEst', 'symbol' ];
    const uri = `${url}&filters=${JSON.stringify(filters)}&projections=${projections}`;
    return this.api.get<PagedResponse<Earning>>(encodeURI(uri));
  }

  public getKeyStats(symbols: string) {
    const APIPath = API_URLS.keyStats;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.keyStatsBySymbols,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'symbols' ],
    );
    return this.api.get<SymbolKeyStat[]>(APIPath, { symbols });
  }

  public getCompany(symbols: string) {
    const APIPath = API_URLS.getCompany;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.companyBySymbols,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'symbols' ],
    );
    return this.api.get<Company[]>(APIPath, { symbols });
  }

  /**
   * Currently not used.
   * @deprecated
   */
  public getCompanyLogoMD5(symbols: string) {
    return this.api.get<CompanyLogoMD5[]>(API_URLS.getCompanyLogoMD5, { symbols });
  }

  /**
   * This one is used together with these actions:
   *   - getCompanyLogo
   *   - getCompanyLogoCompleted
   *   - getCompanyLogoFailed
   */
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  public getCompanyLogo(symbols: string) {
    return this.api.get<CompanyLogo[]>(API_URLS.getCompanyLogo, { symbols });
  }

  public getCompanyLogoAndName(symbols: string) {
    const APIPath = API_URLS.getCompanyLogoAndName;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.companyLogoAndNameBySymbols,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.fiveMinutes,
      [ 'symbols' ],
    );
    return this.api.get<CompanyLogoAndNameResponse[]>(APIPath, { symbols });
  }

  public getPopularStocks(constrained: boolean = true, count: number = 0) {
    const APIPath = API_URLS.popularStocks;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.popularStocks,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.fiveMinutes,
      [ 'count', 'constrained' ],
    );
    return this.api.get<PopularStock[]>(APIPath, {
      constrained,
      count,
      filters: JSON.stringify({
        /**
         * @deprecated
         * Please use DynamicFilterBuilder(services/filter/dynamic-filter-builder) for the common filtering solution
         */
        eq: { symbolType: 'cs' },
      }),
    });
  }

  // TODO: RETAIL symbolSet gives 10n symbols - but now it returns 0 symbols. Temporarily using NONE for this reason. Synced with Hristo
  public getNewStocks(symbolSet: StocksSymbolSet = 'NONE') {
    return this.api.get<NewStockData[]>(API_URLS.newStocks, { symbolSet });
  }

  public getDividendPayers(request?: PageableFilterRequest) {
    const DEFAULT_DIVIDEND_PAYERS_SORT_FIELD = 'dividendYield';
    const APIPath = FundamentalService.preparePageableRequestURL(
      API_URLS.dividendPayers,
      DEFAULT_DIVIDEND_PAYERS_SORT_FIELD,
      request,
    );
    return this.api.get<DividendPayerData[]>(APIPath);
  }

  public getHighGrowthCompanies(request?: PageableFilterRequest) {
    const DEFAULT_HIGH_GROWTH_SORT_FIELD = 'growthIndicator';
    const APIPath = FundamentalService.preparePageableRequestURL(
      API_URLS.highGrowthCompanies,
      DEFAULT_HIGH_GROWTH_SORT_FIELD,
      request,
      /**
         * @deprecated
         * Please use DynamicFilterBuilder(services/filter/dynamic-filter-builder) for the common filtering solution
         */
      { eq: { symbolType: 'cs' } },
    );
    return this.api.get<HighGrowthCompanyData[]>(APIPath);
  }

  private static preparePageableRequestURL(
    url: string,
    defaultSortingField: string,
    request?: PageableFilterRequest,
    filters?: Filters,
  ): string {
    const DEFAULT_FETCH_SYMBOL_UNIVERSE_SYMBOLS = true;
    const DEFAULT_SORTING_TYPE = SortingType.DESC;
    const DEFAULT_PAGE_SIZE = 15;

    /**
     * @deprecated
     * Please use DynamicFilterBuilder(services/filter/dynamic-filter-builder) for the common filtering solution
     */
    const symbolType = filters ? `&filters=${JSON.stringify(filters)}` : '';

    if (!request) {
      return `${`${url}?page=${DEFAULT_PAGE}`
        + `&isSymbolUniverseSymbols=${DEFAULT_FETCH_SYMBOL_UNIVERSE_SYMBOLS}`
        + `&size=${DEFAULT_PAGE_SIZE}`
        + `&sort=${defaultSortingField},${DEFAULT_SORTING_TYPE}`}${
        symbolType}`;
    }

    return `${`${url}?page=${request.page || DEFAULT_PAGE}`
      + `&size=${request.size || DEFAULT_PAGE_SIZE}`
      + `&isSymbolUniverseSymbols=${request.isSymbolUniverseSymbols || DEFAULT_FETCH_SYMBOL_UNIVERSE_SYMBOLS}`
      + `&sort=${request.sort || defaultSortingField},${request.sortAscending ? SortingType.ASC : DEFAULT_SORTING_TYPE}`}${
      symbolType}`;
  }

  public getSectors() {
    const APIPath = API_URLS.sectors;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(url, CacheName.sectors, HttpMethods.GET, PredefinedMilisecondsTypeEnum.oneHour);
    return this.api.get<Sector[]>(APIPath);
  }

  public getSectorDetail(params: SectorDetailParams) {
    const APIPath = API_URLS.sector;
    const url = this.getBaseUrl() + APIPath;
    const cacheConditions: Record<string, NullableString[]> = {};
    cacheConditions.size = [ String(DEFAULT_SECTOR_DETAILS_SIZE) ];
    cacheConditions.page = [ String(DEFAULT_SECTOR_PAGE) ];
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.symbolsBySector,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'sectorId', 'page', 'size' ],
      cacheConditions,
    );
    return this.api.get<SectorDetail>(APIPath, params);
  }

  public getETFs(params: ETFParams) {
    const APIPath = API_URLS.etfs;
    const url = this.getBaseUrl() + APIPath;
    const typeKey = `filters${DOT}eq${DOT}type`;
    const cacheConditions: Record<string, NullableString[]> = {};
    cacheConditions.page = [ String(DEFAULT_PAGE) ];
    cacheConditions.size = [ String(GET_FIRST_ITEM_SIZE) ];
    cacheConditions[typeKey] = [ 'et' ];

    this.addOrUpdateCacheDefinition(
      url,
      CacheName.etfs,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'page', typeKey, 'size' ],
      cacheConditions,
    );
    return this.api.get<PagedResponse<ETF>>(APIPath, params);
  }

  public getETFDetails(params: ETFDetailParams) {
    return this.api.get<PagedResponse<ETFDetailResponse>>(API_URLS.ETFKeyStats, params);
  }

  private static getRange(minValue: number, maxValue: number) {
    return minValue === 100 && maxValue === 100 ? 'FROM_100' : `FROM_${minValue}_TO_${maxValue}`;
  }

  public getTopGainers(minPrice: number, maxPrice: number, isSymbolUniverseSymbols?: boolean) {
    const range = FundamentalService.getRange(minPrice, maxPrice);
    const isPredefined = isPredefinedTopGainersTopLosersFilter(range);
    const topGainersPath = API_URLS.topGainers;
    const url = isPredefined ? topGainersPath : API_URLS.topGainersCustom;
    const params = isPredefined ? { range } : { minPrice, maxPrice };
    const filters = new DynamicFilterBuilder().equal('symbolType', 'cs').build();

    if (isPredefined) {
      const fullURL = this.getBaseUrl() + topGainersPath;
      this.addOrUpdateCacheDefinition(
        fullURL,
        CacheName.topGainers,
        HttpMethods.GET,
        PredefinedMilisecondsTypeEnum.fiveMinutes,
        [ 'range' ],
      );
    }
    return this.api.get<PopularStock[]>(url, { ...params, isSymbolUniverseSymbols, filters });
  }

  public getTopLosers(minPrice: number, maxPrice: number, isSymbolUniverseSymbols?: boolean) {
    const range = FundamentalService.getRange(minPrice, maxPrice);
    const isPredefined = isPredefinedTopGainersTopLosersFilter(range);
    const topLosersPath = API_URLS.topLosers;
    const url = isPredefined ? topLosersPath : API_URLS.topLosersCustom;
    const params = isPredefined ? { range } : { minPrice, maxPrice };

    if (isPredefined) {
      const fullURL = this.getBaseUrl() + topLosersPath;
      this.addOrUpdateCacheDefinition(
        fullURL,
        CacheName.topLosers,
        HttpMethods.GET,
        PredefinedMilisecondsTypeEnum.fiveMinutes,
        [ 'range' ],
      );
    }
    return this.api.get<PopularStock[]>(url, { ...params,
      isSymbolUniverseSymbols,
      filters: JSON.stringify({

        /**
           * @deprecated
           * Please use DynamicFilterBuilder(services/filter/dynamic-filter-builder) for the common filtering solution
           */
        eq: { symbolType: 'cs' },
      }) });
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  public getIPOs() {
    return this.api.get<IPO[]>(API_URLS.ipos);
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  public getSplits(symbols: string[], startDate?: string, endDate?: string) {
    return this.getItems<Split>(API_URLS.splits, API_URLS.splitsByInterval, symbols, startDate, endDate);
  }

  public getMarketIndexes(symbols: string[]): Promise<ApiResponse<string[], string[]>> {
    const url = API_URLS.getMarketIndexes;
    const params = {
      symbols: symbols.join(','), // Convert the symbols array to a comma-separated string
    };
    return this.api.get<string[]>(url, params);
  }
}
