import React, { useEffect, useState } from 'react';

import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { formatCurrency } from '../../lib/util/DataHelpers';
import {
  getMaxBuyLimitPrice,
  getMaxSellLimitPrice,
  validateAggressiveBuyLimitOrder,
  validateAggressiveSellLimitOrder,
} from '../../lib/util/TradingValidationsHelper';

const useValidateOrder = (type: string, price: number, target: number, isBuyOrder: boolean) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [controlCheck, setControlCheck] = useState<boolean>(true);

  useEffect(() => {
    setControlCheck(true);
    setErrorMessage('');

    if (isNaN(price)) {
      setControlCheck(false);
    }
    if ([OrderTypeEnum.Limit, OrderTypeEnum.Stop].includes(type as OrderTypeEnum)) {
      if (type === OrderTypeEnum.Limit) {
        const limitCheck = isBuyOrder
          ? validateAggressiveBuyLimitOrder(price, target)
          : validateAggressiveSellLimitOrder(price, target);

        if (!limitCheck) {
          const lastPrice = isBuyOrder ? getMaxBuyLimitPrice(price) : getMaxSellLimitPrice(price);

          setErrorMessage(
            isBuyOrder
              ? lang.commonTradingAggressiveLimitPriceError(formatCurrency(lastPrice))
              : lang.commonTradingValidationNewOrderSellAggressiveLimitError(formatCurrency(lastPrice)),
          );

          setControlCheck(false);
        }
      }

      if (type === OrderTypeEnum.Stop) {
        const stopCheck = isBuyOrder ? (target < price) : (target > price);

        if (stopCheck) {
          setErrorMessage(
            lang.commonTradingStopPriceError(isBuyOrder ? lang.webTradingMore() : lang.webTradingLess()),
          );

          setControlCheck(false);
        }
      }

      if (target === 0) {
        const priceType = type === OrderTypeEnum.Limit ? lang.webTradingLimit() : lang.webTradingStop();

        setErrorMessage(lang.commonTradingPriceError(priceType));

        setControlCheck(false);
      }
    }
  }, [isBuyOrder, price, target, type]);

  return { errorMessage, controlCheck };
};

export default useValidateOrder;
