import config from '../../config';
import { PredefinedMilisecondsTypeEnum } from '../enums';
import { SubscriptionsEntitlementsResponse } from '../models/subscriptions/types';

import { CacheName, HttpMethods } from './cache/types';
import { BaseRequestService } from './BaseRequest.service';
import { ISESService } from './types';

const API_PATH = {
  entitlements: '/v3/subscriptions/entitlements',
};

export class SESService extends BaseRequestService implements ISESService {
  protected getBaseUrl(): string {
    return config.ses.url;
  }

  public async getSubscriptionsEntitlements() {
    const APIPath = API_PATH.entitlements;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.entitlements,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<SubscriptionsEntitlementsResponse>(APIPath);
  }
}
