import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus } from '../../../../lib/enums/call-status.enum';
import { HttpStatusCodeEnum } from '../../../../lib/enums/http-status-code.enum';
import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { registerLinkedAccount } from '../../../../lib/store/payment';
import { PaymentCall, RegisterLinkedAccountPayload } from '../../../../lib/store/payment/types';

import imageError from '../../../../assets/bigImages/Error_effects_new.png';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { Button, HeaderLogo, Preloader } from '../../../../components/index';
import config from '../../../../config';
import { useIsMobile } from '../../../../hooks';

import styles from './ErrorAddBankAccount.module.scss';

type Props = ReduxProps;

const ErrorAddBankAccount = (props: Props) => {
  const { requestRegisterLinkedAccount, regLinkedAccount } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const {
    errorCodeAddBankAccount,
    dataToSaveRegLinkedAccount,
  } = history.location.state as {
     errorCodeAddBankAccount: number,
     dataToSaveRegLinkedAccount: RegisterLinkedAccountPayload
     };

  const handleTryAgain = () => {
    regLinkedAccount(dataToSaveRegLinkedAccount);
  };

  const handleContactSupport = () => {
    window.open(config.links.helpCenterUrl, '_blank', '');
  };

  return (
    <>
      {requestRegisterLinkedAccount?.status === CallStatus.PENDING
       && <div className={styles.loader}><Preloader text="" /></div>}
      {!isMobile && (
        <div className={styles.logoImgContainer}>
          <HeaderLogo className={styles.headerImg} />
        </div>
      )}
      <div className={styles.wrapperContent}>
        <div className={styles.content}>
          <h3 className={styles.message}>
            {lang.requestErrorOver500()}
          </h3>
          <img src={imageError} className={styles.image} alt="error" />
          <Button
            className={styles.btn}
            fullWidth
            disabled={requestRegisterLinkedAccount?.status === CallStatus.PENDING}
            onClick={
              errorCodeAddBankAccount >= HttpStatusCodeEnum.BadRequest
              && errorCodeAddBankAccount < HttpStatusCodeEnum.InternalServerError
                ? handleContactSupport
                : handleTryAgain
            }
          >
            {
              errorCodeAddBankAccount >= HttpStatusCodeEnum.BadRequest
              && errorCodeAddBankAccount < HttpStatusCodeEnum.InternalServerError
                ? lang.commonButtonContactSupport().split('.').join('')
                : lang.commonButtonsTryAgain()
            }
          </Button>
          <Button
            id="fundsErrorGoBack"
            variant={ButtonTypesEnum.Tertiary}
            fullWidth
            className={styles.btn}
            onClick={() => history.go(-2)}
          >
            {lang.commonGoBackBtn()}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  requestRegisterLinkedAccount: state.payment.statusByCall[PaymentCall.registerLinkedAccount],
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  regLinkedAccount: (data: RegisterLinkedAccountPayload) => dispatch(registerLinkedAccount(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ErrorAddBankAccount);
