import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CallStatus } from '../../enums';
import { GenericStatusByCall } from '../../enums/status-by-call';
import { SubscriptionsEntitlementsResponse } from '../../models/subscriptions/types';
import { ErrorPayloadFull } from '../common-actions';

import { setSESCallStatus } from './helpers';
import { SESCall } from './types';

export interface SESState {
    canTradeETFs: boolean
    errorsLog: string[];
    statusByCall: GenericStatusByCall<SESCall>
}

export const INITIAL_STATE: SESState = {
  canTradeETFs: true,
  errorsLog: [],
  statusByCall: {},
};

const sesSlice = createSlice({
  name: 'ses',
  initialState: INITIAL_STATE,
  reducers: {
    getSubsriptionsEntitlements(state: SESState) {
      setSESCallStatus(SESCall.getSubsriptionsEntitlements, CallStatus.PENDING, state);
    },
    getSubsriptionsEntitlementsSuccess(state: SESState, action: PayloadAction<SubscriptionsEntitlementsResponse>) {
      state.canTradeETFs = action.payload.canTradeETFs;
      setSESCallStatus(SESCall.getSubsriptionsEntitlements, CallStatus.READY, state);
    },
    getSubsriptionsEntitlementsFail(state: SESState, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setSESCallStatus(SESCall.getSubsriptionsEntitlements, CallStatus.ERROR, state, status);
    },
  },
});

export const {
  getSubsriptionsEntitlements,
  getSubsriptionsEntitlementsSuccess,
  getSubsriptionsEntitlementsFail,
} = sesSlice.actions;

export default sesSlice.reducer;
