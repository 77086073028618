import { EnrollProgressBarData, EnrollProgressBarPhasesEnum } from './ProggressBar.types';

export const ProgressBarInitialStepIndex = 1;

export const EnrollProgressBarInitialData: EnrollProgressBarData = {
  phases: [
    {
      index: 1,
      title: EnrollProgressBarPhasesEnum.PersonalData,
      steps: 3,
    },
  ],
  currentPhaseIndex: 1,
  currentStepIndex: 1,
};
