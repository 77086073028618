import config from '../../config';
import { applyPathParams } from '../util/DataHelpers';

import { BaseRequestService } from './BaseRequest.service';

const API_PATH: any = {
  results: '/Results/$1',
};

class GatewayRestService extends BaseRequestService {
  protected getBaseUrl(): string {
    return `https://${config.gateway.url}`;
  }

  public async get(accessId: string) {
    const path = applyPathParams(API_PATH.results, [ accessId ]);

    return this.api.get<any[]>(path);
  }
}

export default GatewayRestService;
