import React from 'react';
import { useHistory } from 'react-router-dom';

import { LinkedAccountStatusEnum, PaymentMethodEnum } from '../../../../lib/enums';
import lang from '../../../../lib/language';
import { LinkedAccount } from '../../../../lib/models/linked-account/types';

import iconAddNew from '../../../../assets/img/icon-add_new.svg';
import iconBank from '../../../../assets/img/icon-bank.svg';
import iconDebitCredit from '../../../../assets/img/icon-debit-credit.svg';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { Button, ClickableDiv } from '../../../../components/index';
import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './ContentBankDropDown.module.scss';

type Props = {
  handleClickFundAccount: (data) => void;
  closeModal?: (action: boolean) => void;
  convertedLinkedAccounts: LinkedAccount[];
  showCardOption?: boolean;
};

const ContentBankDropDown = ({
  handleClickFundAccount,
  closeModal,
  convertedLinkedAccounts,
  showCardOption,
}: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleAddBankAccount = () => history.push({
    pathname: UI_ROUTES.funds + UI_ROUTES.addYourBankAccount,
    state: {},
  });

  return (
    <>
      { isMobile ? (
        <div className={styles.wrapperOrderTypeMenu}>
          <ClickableDiv
            className={styles.orderTypeMenuBackground}
            onClick={() => { closeModal && closeModal(false); }}
          />
          <div className={styles.orderTypes}>
            { showCardOption && (
              <ClickableDiv
                type="button"
                className={styles.orderType}
                onClick={() => handleClickFundAccount({ paymentMethod: PaymentMethodEnum.Card })}
              >
                <div className={styles.orderTypeIcon}>
                  <img src={iconDebitCredit} alt="Debit / Credit" />
                </div>
                <div className={styles.orderTypeText}>
                  <div className={styles.orderTypeTitle}>
                    {lang.commonFirstDepositCard()}
                  </div>
                  <div className={styles.orderTypeDescription}>
                    {lang.commonFirstDepositBank()}
                  </div>
                </div>
              </ClickableDiv>
            )}
            {convertedLinkedAccounts.map((linkedAccount) => (
              <ClickableDiv
                key={linkedAccount.id}
                type="button"
                className={styles.orderType}
                onClick={() => handleClickFundAccount({ paymentMethod: PaymentMethodEnum.BankTransfer, linkedAccount })}
              >
                <div className={styles.orderTypeIcon}>
                  <img src={iconBank} alt="icon bank" />
                </div>
                <div className={styles.orderTypeText}>
                  <div className={styles.orderTypeTitle}>
                    {linkedAccount.account_info.bank_name}
                  </div>
                  <div className={styles.orderTypeDescription}>
                    {linkedAccount.account_info.iban
                      ? linkedAccount.account_info.iban
                      : linkedAccount.account_info.account}
                  </div>
                </div>
              </ClickableDiv>
            ))}
            <ClickableDiv
              type="button"
              className={styles.orderType}
              onClick={handleAddBankAccount}
            >
              <div className={styles.orderTypeIcon}>
                <img src={iconAddNew} alt="icon small plus" />
              </div>
              <div className={styles.orderTypeText}>
                {lang.mobileFundsScreenAddBankAccModalBtn()}
              </div>
            </ClickableDiv>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.wrapperBankAccountDropDown}>
            <ClickableDiv
              id="enterAmountCardTransferOption"
              className={styles.wrapperCardTransfer}
              onClick={() => handleClickFundAccount({ paymentMethod: PaymentMethodEnum.Card })}
            >
              <img src={iconDebitCredit} alt="icon debit credit" />
              <span>{lang.commonFirstDepositCard()}</span>
            </ClickableDiv>
            <span className={styles.labelBankTransfer}>{lang.commonFirstDepositBank()}</span>
            {
            convertedLinkedAccounts.map((linkedAccount) => (
              <ClickableDiv
                id="enterAmountBankTransferOption"
                className={styles.wrapperBank}
                key={linkedAccount.id}
                onClick={() => handleClickFundAccount({ paymentMethod: PaymentMethodEnum.BankTransfer, linkedAccount })}
              >
                <img src={iconBank} alt="icon bank" />
                <div className={styles.contentBank}>
                  <div className={styles.bankDescription}>
                    <p className={styles.bankName}>{linkedAccount.account_info.bank_name}</p>
                    {linkedAccount.status === LinkedAccountStatusEnum.New
                    && <p className={styles.pending}>{lang.commonVerificationStepStatusPending()}</p>}
                  </div>
                  <p className={styles.ibanBank}>
                    {linkedAccount.account_info.iban
                      ? linkedAccount.account_info.iban
                      : linkedAccount.account_info.account}
                  </p>
                </div>
              </ClickableDiv>
            ))
          }
            <div className={styles.wrapperAddNewBankAccount}>
              <img src={iconAddNew} alt="icon small plus" />
              <Button
                id="enterAmountAddBankAccount"
                variant={ButtonTypesEnum.Link}
                className={styles.btnAddNewBank}
                onClick={handleAddBankAccount}
              >
                {lang.mobileFundsScreenAddBankAccModalBtn()}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentBankDropDown;
