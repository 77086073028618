import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { OrderStatusEnum } from '../../enums/order-status.enum';
import { InboxPageRequest, MarkAsReadByMetadata, UnreadCountPayload } from '../../models/ams/types';
import GlobalService from '../../services/Global.service';
import { getStringLiteral } from '../../util/DataHelpers';
import { servicesReady } from '../common-actions';
import { processResponse } from '../helpers';
import { RootState } from '..';

import { DEFAULT_INBOX_PAGE_SIZE, NO_MORE_PAGES_TO_READ } from './constants';
import {
  deleteMessages, deleteMessagesFail, deleteMessagesSuccess,
  getAllMessages, getAllMessagesFail, getAllMessagesSuccess,
  getMessage, getMessageFail, getMessageSuccess,
  getSummary, getSummaryFail, getSummarySuccess,
  markAllMessagesAsRead, markAllMessagesAsReadFail, markAllMessagesAsReadSuccess,
  markAsRead,
  markAsReadByMetadata,
  markAsReadByMetadataFail,
  markAsReadByMetadataSuccess,
  markAsReadFail,
  markAsReadSuccess,
  notifyUnreadCount, notifyUnreadCountFail, notifyUnreadCountSuccess,
} from './index';

type TypeGetAllMessagesEpic = PayloadAction<InboxPageRequest | void>

const getAllMessagesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(servicesReady.type, getAllMessages.type),
  filter(() => state$.value.ams.nextPageToRead > NO_MORE_PAGES_TO_READ),
  mergeMap((action: TypeGetAllMessagesEpic) => new Observable((observer: Observer<any>) => {
    const callName = 'ams/getAllMessages';
    let params: InboxPageRequest = {
      size: DEFAULT_INBOX_PAGE_SIZE,
      page: state$.value.ams.nextPageToRead,
      sort: 'receptionTime,desc', // Get all messages in descending order
    };
    if (
      typeof action.payload === 'object'
      && 'size' in action.payload
    ) {
      params = {
        ...params,
        size: action.payload.size,
      };
    }
    GlobalService
      ?.amsService
      ?.getAllMessages(params)
      .then(response => {
        processResponse(callName, {}, response, observer, getAllMessagesSuccess, getAllMessagesFail);
      });
  })),
);

const deleteMessageEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(deleteMessages.type),
  mergeMap((action: PayloadAction<number | number[]>) => new Observable(
    (observer: Observer<any>) => {
      const callName = 'ams/deleteMessages';

      GlobalService
        ?.amsService
        ?.deleteMessages(action.payload)
        .then(response => {
          processResponse(
            callName,
            {},
            response,
            observer,
            deleteMessagesSuccess,
            deleteMessagesFail,
            false,
            { ids: getStringLiteral(action.payload) },
          );
        });
    },
  )),
);

const getMessageEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getMessage.type),
  mergeMap((action: PayloadAction<number>) => new Observable((observer: Observer<any>) => {
    const callName = 'ams/getMessage';

    GlobalService
      ?.amsService
      ?.getMessage(action.payload)
      .then(response => {
        processResponse(callName, {}, response, observer, getMessageSuccess, getMessageFail);
      });
  })),
);

const markAllMessagesAsReadEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(markAllMessagesAsRead.type),
  mergeMap((action: PayloadAction) => new Observable((observer: Observer<PayloadAction>) => {
    const callName = 'ams/markAllMessagesAsRead';

    GlobalService
      ?.amsService
      ?.markAllMessagesAsRead()
      .then(response => {
        processResponse(callName, {}, response, observer, markAllMessagesAsReadSuccess, markAllMessagesAsReadFail);
      });
  })),
);

const markAsReadEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(markAsRead.type),
  mergeMap((action: PayloadAction<number | number[]>) => new Observable((observer: Observer<any>) => {
    const callName = 'ams/markAsRead';

    GlobalService
      ?.amsService
      ?.markAsRead(action.payload)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          markAsReadSuccess,
          markAsReadFail,
          false,
          { ids: getStringLiteral(action.payload) },
        );
      });
  })),
);

const updateHasUnreadMessagesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    getSummary.type,
    getAllMessagesSuccess.type,
    markAsReadSuccess.type,
    markAllMessagesAsReadSuccess.type,
    deleteMessagesSuccess.type,
  ),
  mergeMap((action: PayloadAction) => new Observable((observer: Observer<any>) => {
    const callName = 'ams/getSummary';

    GlobalService
      ?.amsService
      ?.getSummary()
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getSummarySuccess,
          getSummaryFail,
          false,
          { ids: action.payload },
        );
      });
  })),
);

const notifyUnreadCountEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(notifyUnreadCount.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'ams/notifyUnreadCount';
    const requestBody = {
      notifyInterval: 1,
      includedSubjects: [
        OrderStatusEnum.Cancelled,
        OrderStatusEnum.Expired,
        OrderStatusEnum.Filled,
        OrderStatusEnum.PartiallyReject,
        OrderStatusEnum.Rejected,
      ],
      groupedBySubjects: true,
      importanceLevel: 10,
    };

    GlobalService.amsService.notifyUnreadCount(requestBody)
      .then(response => {
        processResponse(callName, {}, response, observer, notifyUnreadCountSuccess, notifyUnreadCountFail);
      });
  })),
);

const markAsReadByMetadataEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(markAsReadByMetadata.type),
  debounceTime(1000),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'ams/notifyUnreadCount';
    const requestBody = {
      key: 'OrderID',
      values: [ state$.value.trading.order?.clientOrderId ?? '' ],
    };

    GlobalService.amsService.markAsReadByMetadata(requestBody)
      .then(response => {
        processResponse(callName, {}, response, observer, markAsReadByMetadataSuccess, markAsReadByMetadataFail);
      });
  })),
);

export default combineEpics(
  getAllMessagesEpic,
  deleteMessageEpic,
  getMessageEpic,
  markAllMessagesAsReadEpic,
  markAsReadEpic,
  markAsReadByMetadataEpic,
  notifyUnreadCountEpic,
  updateHasUnreadMessagesEpic,
);
