/**
 * Statuses from Alaric Pay API
 */
export enum PaymentPspStatusEnum {
  New = 10,
  Expired = 15,
  ProcessingByPsp = 20,
  Rejected = 30,
  Processed = 40,
  ApprovedByPsp = 50
}
