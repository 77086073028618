import React from 'react';
import { useSelector } from 'react-redux';

import { StockDescriptor } from '../../../lib/models/stock-descriptor';
import { isSymbolInFavourites } from '../../../lib/store/helpers';
import { RootState } from '../../../lib/store/index';
import { SymbolLite } from '../../../lib/store/market-data/types';
import { getFavoriteStocks } from '../../../lib/store/selectors';

import { ButtonTypesEnum } from '../../Button/Button.types';
import { Button } from '../../index';
import StockRow from '../../StocksResult/componets/StockRow';
import AddToWatchlist from '../../SymbolDetailsHeader/components/AddToWatchList';

import styles from './ResultRowMobile.module.scss';

type Props = {
    stock: StockDescriptor
    logo?: string;
    addToFavourites: (symbol: string) => void;
    closeModal: () => void;
}

const ResultRowMobile = ({ logo, stock, addToFavourites, closeModal }: Props) => {
  const favourites = useSelector<RootState, SymbolLite[]>((state: RootState) => getFavoriteStocks(state));

  const onAddToFavouritesClick = (e) => {
    e?.stopPropagation();
    addToFavourites(stock.symbol);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <StockRow logo={logo} isMob stock={stock} closeModal={closeModal} />
        <div className={styles.favouritesBtn}>
          <Button
            variant={ButtonTypesEnum.Link}
            className={styles.addToWatchlist}
            onClick={onAddToFavouritesClick}
          >
            <AddToWatchlist isAdded={isSymbolInFavourites(favourites, stock.symbol)} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResultRowMobile;
