/**
 * This slice is dedicated to Reporting API data
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CallStatus } from '../../enums';
import { PeopleAlsoTradeItem, ReportingCall } from '../../models/reporting/types';
import CallsCache from '../../store-util/calls-cache/CallsCache';
import {
  ErrorPayloadFull,
  getAccountsAvailableCashFailed,
  getAccountsAvailableCashSuccess,
  getOpenPositionsSuccess,
  logout,
} from '../common-actions';

import { parsedAccountTradeHistoryOrdersOrders } from './trading/order-helpers';
import { INITIAL_STATE, NO_MORE_PAGES_TO_READ } from './constants';
import { setReportingCallStatus } from './helpers';
import {
  AccountSummaryResponseData,
  AccountTradeHistoryPayloadData,
  AccountTradeHistoryResponseData,
  GetAccountsAvailableCashResponse,
  GetMyAccountChartDataPayload,
  GetMyAccountChartDataResponse,
  OpenPosition,
  OpenPositionsPayloadData,
  OrderDetailResponse,
  ReportingState,
} from './types';

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: INITIAL_STATE, // TODO: Remove flags for call status and use statusByCall
  reducers: {
    getNetEquityComponents(state, action: PayloadAction<GetMyAccountChartDataPayload>) {
      setReportingCallStatus(ReportingCall.getNetEquityComponents, CallStatus.PENDING, state);
    },
    getNetEquityComponentsSuccess(state, action: PayloadAction<GetMyAccountChartDataResponse>) {
      setReportingCallStatus(ReportingCall.getNetEquityComponents, CallStatus.READY, state);
      state.netIncomeComponents = action.payload;
    },
    getNetEquityComponentsFailed(state: ReportingState, error) {
      setReportingCallStatus(ReportingCall.getNetEquityComponents, CallStatus.ERROR, state, error.payload.status);
    },
    // TO DO: Better error handling when the reporting is implemented in web and mobile
    getAccountSummary(state: ReportingState) {
      setReportingCallStatus(ReportingCall.getAccountSummary, CallStatus.PENDING, state);
    },
    getAccountSummarySuccess(state, action: PayloadAction<AccountSummaryResponseData>) {
      state.accountSummary = action.payload;
      setReportingCallStatus(ReportingCall.getAccountSummary, CallStatus.READY, state);
    },
    getAccountSummaryFailed(state: ReportingState, error) {
      state.error.hasRequestError = true;
      state.error.status = error.payload.status;
      setReportingCallStatus(ReportingCall.getAccountSummary, CallStatus.ERROR, state);
    },
    getOrderDetail(state: ReportingState, action: PayloadAction<string>) {
      state.orderDetailCompleted = false;
    },
    getOrderDetailSuccess(state, action: PayloadAction<OrderDetailResponse>) {
      state.orderDetailCompleted = true;
      state.orderDetail = action.payload;
    },
    getOrderDetailFailed(state: ReportingState, error) {
      state.orderDetailCompleted = true;
      state.error.hasRequestError = true;
      state.error.status = error.payload.status;
    },
    getOpenPositions(state, action: PayloadAction<OpenPositionsPayloadData>) {
      state.accountOpenPositionsCompleted = false; // TODO: Remove and use statusByCall instead
      setReportingCallStatus(ReportingCall.getOpenPositions, CallStatus.PENDING, state);
    },
    getOpenPositionsFailed(state: ReportingState, error) {
      // TODO: Remove and use statusByCall instead - start
      state.error.hasRequestError = true;
      state.accountOpenPositionsCompleted = true;
      // TODO: Remove and use statusByCall instead - end

      state.error.status = error.payload.status;
      setReportingCallStatus(ReportingCall.getOpenPositions, CallStatus.ERROR, state, error.payload.status);
    },
    getAccountTradeHistory(state, action: PayloadAction<AccountTradeHistoryPayloadData>) {
      const { customData, isPendingOrdersCall } = (action.payload as any);
      const { queueLength } = customData ?? {};
      const theStatus = queueLength ? CallStatus.PENDING_QUEUED : CallStatus.PENDING;
      setReportingCallStatus(isPendingOrdersCall
        ? ReportingCall.getAccountPendingOrders
        : ReportingCall.getAccountTradeHistory, theStatus, state, null, customData);
    },
    getAccountTradeHistorySuccess(state, action: PayloadAction<AccountTradeHistoryResponseData[]>) {
      if (action.payload.length > 0) {
        state.tradeHistoryNextPageToRead += 1;

        if (state.tradeHistoryNextPageToRead === action.payload[0].pages) {
          state.tradeHistoryNextPageToRead = NO_MORE_PAGES_TO_READ;
        }
        state.tradeHistoryOrders = state.tradeHistoryOrders
          .concat(parsedAccountTradeHistoryOrdersOrders(action.payload));
      } else {
        state.tradeHistoryOrders = [];
      }
      const { customData } = (action.payload as any);
      const { queueLength = 0 } = customData ?? {};
      const theStatus = queueLength ? null : CallStatus.READY;
      setReportingCallStatus(ReportingCall.getAccountTradeHistory, theStatus, state, null, customData);
    },
    clearAccountTradeHistory(state) {
      state.tradeHistoryOrders = [];
      state.tradeHistoryNextPageToRead = 0;
    },
    getAccountTradeHistoryFailed(state: ReportingState, error) {
      const { customData } = (error.payload);
      const { isPendingOrdersCall } = customData ?? {};
      state.error.hasRequestError = true;
      state.error.status = error.payload.status;
      setReportingCallStatus(isPendingOrdersCall ? ReportingCall.getAccountPendingOrders
        : ReportingCall.getAccountTradeHistory, CallStatus.ERROR, state, error.payload.status);
    },
    getAccountPendingOrdersSuccess(state, action: PayloadAction<AccountTradeHistoryResponseData[]>) {
      state.pendingOrders = action.payload;
      const { customData } = (action.payload as any);
      const { queueLength = 0 } = customData ?? {};
      const theStatus = queueLength ? null : CallStatus.READY;
      setReportingCallStatus(ReportingCall.getAccountPendingOrders, theStatus, state, null, customData);
    },
    getPeopleAlsoTradeData(state, action: PayloadAction) {
      const { toBeUpdated } = CallsCache.checkRequest('customStockList', 'people-also-trade');
      if (toBeUpdated) {
        setReportingCallStatus(ReportingCall.getPeopleAlsoTradeData, CallStatus.PENDING, state);
      }
    },
    getPeopleAlsoTradeDataCompleted(state, action: PayloadAction<PeopleAlsoTradeItem[]>) {
      state.peopleAlsoTradeData = action.payload;
      setReportingCallStatus(ReportingCall.getPeopleAlsoTradeData, CallStatus.READY, state);
    },
    getPeopleAlsoTradeDataFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setReportingCallStatus(ReportingCall.getPeopleAlsoTradeData, CallStatus.ERROR, state, status);
    },
    setHasOrderUpdate(state, action: PayloadAction<boolean>) {
      state.hasMyStocksOrderUpdate = action.payload;
    },
  },
  extraReducers: {
    [logout.type]: state => ({ ...INITIAL_STATE, errorsLog: state.errorsLog }),
    [getAccountsAvailableCashSuccess.type]: (
      state: ReportingState,
      action: PayloadAction<GetAccountsAvailableCashResponse>,
    ) => {
      state.accountsAvailableCash.hasLoaded = true;
      state.accountsAvailableCash.data = action.payload;
    },
    [getAccountsAvailableCashFailed.type]: (state: ReportingState) => {
      state.accountsAvailableCash = { ...INITIAL_STATE.accountsAvailableCash };
    },
    [getOpenPositionsSuccess.type]: (state: ReportingState, action: PayloadAction<OpenPosition[]>) => {
      state.openPositions = action.payload;
      state.accountOpenPositionsCompleted = true;
      setReportingCallStatus(ReportingCall.getOpenPositions, CallStatus.READY, state);
    },
  },
});

export const {
  getNetEquityComponents,
  getNetEquityComponentsSuccess,
  getNetEquityComponentsFailed,
  getAccountSummary,
  getAccountSummarySuccess,
  getAccountSummaryFailed,
  getOrderDetail,
  getOrderDetailSuccess,
  getOrderDetailFailed,
  getOpenPositions,
  getOpenPositionsFailed,
  getAccountTradeHistory,
  getAccountTradeHistorySuccess,
  getAccountTradeHistoryFailed,
  clearAccountTradeHistory,
  getAccountPendingOrdersSuccess,
  getPeopleAlsoTradeData,
  getPeopleAlsoTradeDataCompleted,
  getPeopleAlsoTradeDataFailed,
  setHasOrderUpdate,
} = reportingSlice.actions;

export default reportingSlice.reducer;
