/**
 * This slice is dedicated to Gateway API data
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GWDataQueryPayload,
  GWMarketDataQueryBucket,
  GWQueryCase,
  GWQueryResultStatus,
  GWResponseType,
  OHLCVDataRow,
  OHLCVResultsSuccessPayload,
  OHLCVStateData,
  SORErrorResponse,
  SORExecutionReportResponse,
  SOROrderStatus,
} from '../../models/gateway/types';
import LoaderState from '../../store-util/LoaderState';
import OrderInfoCache from '../../store-util/OrderInfoCache';
import { joinEnum } from '../../util/DataHelpers';
import { getDateTime, parseDateToUTC } from '../../util/DateTimeHelpers';
import { appendToErrorsLog } from '../../util/error-handling/ErrorHandlingHelpers';
import { concatKeys } from '../../util/ObjectTools';
import {
  clearErrorsLog,
  deleteWatchlistItemCache,
  DeleteWatchlistItemCachePayload,
  ErrorPayloadFull,
  gatewayConnected,
  gatewayDisconnected,
  logout,
  reconnectEventAction,
  servicesReady,
  sorOrderEvent,
  webSocketResponseError,
} from '../common-actions';
import { addOrderInfoLog } from '../helpers';
import { OrderInfoLog } from '../trading/types';

export interface GatewayState {
  status: 'idle' | 'connecting' | 'ready' | 'timedout'
  connected: boolean
  data: any,
  resultsRetries: { [accessId: string]: number | null | undefined }
  symbolToAccessId: { [symbolAndQueryCase: string]: string }
  lastQueryStatus: { [symbolAndQueryCase: string]: GWQueryResultStatus | undefined }
  errorsLog: string[]
  orderInfo: OrderInfoLog[]
  isReconnect: boolean;
  ohlcvData: {
    symbol: string,
    minuteData: OHLCVStateData,
    hourData: OHLCVStateData,
    dayData: OHLCVStateData,
    error?: boolean,
  },
}

export const INITIAL_STATE: GatewayState = {
  status: 'idle',
  connected: false,
  data: {},
  resultsRetries: {},
  symbolToAccessId: {},
  lastQueryStatus: {},
  errorsLog: [],
  orderInfo: [],
  isReconnect: false,
  ohlcvData: { symbol: '', minuteData: { data: [] }, hourData: { data: [] }, dayData: { data: [] }, error: false },
};

const crmSlice = createSlice({
  name: 'gateway',
  initialState: INITIAL_STATE,
  reducers: {
    dataQuery(state, action: PayloadAction<GWDataQueryPayload>) {
      const { accessId, retries, cacheData } = action.payload;
      const { symbol, queryCase } = cacheData || {};
      state.lastQueryStatus[concatKeys(symbol, queryCase)] = GWQueryResultStatus.LOADING;
      if (retries != null) {
        state.resultsRetries[accessId] = retries;
        state.symbolToAccessId[concatKeys(symbol, queryCase)] = accessId;
      }
    },
    dataQuerySuccess(state, action: PayloadAction<OHLCVResultsSuccessPayload>) {
      const { customData, status } = action.payload;
      const { accessId, cacheData } = customData;
      const { symbol, queryCase } = cacheData;

      if (action.payload[0] && cacheData.queryCase === GWQueryCase.OHLCV) {
        state.ohlcvData.error = false;
        state.ohlcvData.symbol = symbol;
        const bucket = cacheData.requestData?.bucket;
        const { rows } = action.payload[0];
        rows.forEach(el => {
          const date = parseDateToUTC(el.dateAndTime as string);
          // eslint-disable-next-line no-param-reassign
          el.dateAndTime = Number(getDateTime(true, [ 'unix' ], [ [ ] ], date));
        });
        const { ohlcvData: { dayData, hourData, minuteData } } = state;

        if (bucket === GWMarketDataQueryBucket.OneMinute) {
          state.ohlcvData.minuteData = {
            ...minuteData,
            data: [ ...rows, ...minuteData.data ],
            from: rows[0].dateAndTime as number,
          };
        }
        if (bucket === GWMarketDataQueryBucket.OneDay) {
          state.ohlcvData.dayData = {
            ...dayData,
            data: [ ...rows, ...dayData.data ],
            from: rows[0].dateAndTime as number,
            to: rows[rows.length - 1].dateAndTime as number,
          };
        }
        if (bucket === GWMarketDataQueryBucket.OneHour) {
          state.ohlcvData.hourData = {
            ...hourData,
            data: [ ...rows, ...hourData.data ],
            from: rows[0].dateAndTime as number,
          };
        }
      }
      if (accessId) delete state.resultsRetries[accessId];
      if (symbol) {
        delete state.symbolToAccessId[concatKeys(symbol, queryCase)];
        state.lastQueryStatus[concatKeys(symbol, queryCase)] = status;
      }
    },
    dataQueryError(state, action: PayloadAction<ErrorPayloadFull>) {
      appendToErrorsLog(`[DataQueryError] ${action.payload.errorStr}`, state);
      const { errorData: { status } } = action.payload;
      const { accessId, retries, cacheData } = action.payload.requestParams || {};
      const { symbol, queryCase } = cacheData || {};

      if (retries == null) {
        if (accessId) delete state.resultsRetries[accessId];
        if (symbol) delete state.symbolToAccessId[concatKeys(symbol, queryCase)];
      } else {
        state.resultsRetries[accessId] = retries;
      }

      state.lastQueryStatus[concatKeys(symbol, queryCase)] = status;
    },
    decodingResponseFailed(state, action: PayloadAction<string>) {
      appendToErrorsLog(`[DecodeResponse] ${action.payload}`, state);
    },
    parsingResponseFailed(state, action: PayloadAction<string>) {
      appendToErrorsLog(`[ParseResponse] ${action.payload}`, state);
    },
    restResponseError(state, action: PayloadAction<Error>) {
      appendToErrorsLog(`[RestResponse] ${action.payload}`, state);
    },
    connectionTimedOut(state) {
      state.status = 'timedout';
    },
    /**
     * Payload is a string of comma separated list of symbols - for example 'AAPL,AMZN,TSLA'
     */
    subscribeNews(state, action: PayloadAction<string>) {},
    /**
     * Payload is a string of comma separated list of symbols - for example 'AAPL,AMZN,TSLA'
     */
    unsubscribeNews(state, action: PayloadAction<string>) {},
    /**
     * The payload is a string array of symbols
     */
    subscribeSymbolStatus(state: GatewayState, action: PayloadAction<string[]>) {},
    unsubscribeSymbolStatus(state: GatewayState) {},
    resetIsReconnect(state: GatewayState) {
      state.isReconnect = false;
    },
    resetMarketDataQuery(state: GatewayState) {
      state.ohlcvData.symbol = '';
      state.ohlcvData.dayData = { data: [] };
      state.ohlcvData.hourData = { data: [] };
      state.ohlcvData.minuteData = { data: [] };
    },
  },
  extraReducers: {
    [sorOrderEvent.type]: (state, action: PayloadAction<SORExecutionReportResponse>) => {
      const { messageType, resultCode, status } = action.payload;
      addOrderInfoLog(
        state,
        'sor-order-event',
        `${(SOROrderStatus as any)[status]} ${messageType} (${resultCode})`,
        action.payload,
      );
    },
    [clearErrorsLog.type]: state => {
      state.errorsLog = [];
      state.orderInfo = [];
      OrderInfoCache.deleteAll();
    },
    [servicesReady.type]: state => {
      state.status = 'connecting';
    },
    [gatewayConnected.type]: state => {
      state.status = 'ready';
      state.connected = true;
    },
    [reconnectEventAction.type]: state => {
      state.status = 'ready';
      state.connected = true;
      state.isReconnect = true;
    },
    [gatewayDisconnected.type]: (state, action) => {
      state.status = 'idle';
      state.connected = false;
      const { code, reason } = action.payload || {};
      if (code != null) {
        appendToErrorsLog(`WebSocket disconnected - ${reason} (${code})`, state);
      }
    },
    [logout.type]: state => ({ ...INITIAL_STATE, errorsLog: state.errorsLog }),
    [webSocketResponseError.type]: (state: GatewayState, action: PayloadAction<SORErrorResponse>) => {
      const { errorMessage, resultCode, messageType } = action.payload;

      if (messageType === GWResponseType.MarketDataQuery) {
        state.ohlcvData.error = true;
      }
      LoaderState.setLoading(false);

      appendToErrorsLog(`[WSResponse] Error - statusCode=${resultCode}, message='${errorMessage}'`, state);
    },
    [deleteWatchlistItemCache.type]: (state, action: PayloadAction<DeleteWatchlistItemCachePayload>) => {
      const { symbol, queryCase, allQueryCases } = action.payload;
      if ((!symbol || !queryCase) && !allQueryCases) {
        console.warn(`[gateway/index] Could not delete cache for watch list item - symbol=${symbol}, queryCase=${queryCase} (${GWQueryCase[queryCase]})`);
        return;
      }

      let currentCases: GWQueryCase[] = (queryCase != null ? [ queryCase ] : joinEnum(GWQueryCase, '', true) as GWQueryCase[]);

      currentCases.forEach(item => {
        const key = concatKeys(symbol, item);

        delete state.lastQueryStatus[key];
        delete state.symbolToAccessId[key];
      });
    },
  },
});

export const {
  dataQuery,
  dataQuerySuccess,
  dataQueryError,
  decodingResponseFailed,
  parsingResponseFailed,
  restResponseError,
  connectionTimedOut,
  subscribeNews,
  unsubscribeNews,
  subscribeSymbolStatus,
  unsubscribeSymbolStatus,
  resetIsReconnect,
  resetMarketDataQuery,
} = crmSlice.actions;

export default crmSlice.reducer;
