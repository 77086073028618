import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import iconInbox from 'assets/img/icon-bell.svg';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { getAllMessages, markAllMessagesAsRead, setAllMessagesPageToReadToInitial } from '../../../lib/store/ams';
import { isUserVerifiedStatus } from '../../../lib/store/selectors';

import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import { ClickableDiv, UserDetails, UserPhoto } from '../../index';
import { HeaderDropdownItem } from '../Header.types';
import { LogoutActionSelector } from '../HeaderConstants';
import MyProfile from '../HeaderDropdownNav/myProfile/MyProfile';
import Notifications from '../HeaderDropdownNav/notifications/Notifications';

import styles from './HeaderDropdown.module.scss';

type OwnProps = {
    isUserProfile: boolean;
    actions?: {};
};

type Props = OwnProps & ReduxProps;

const HeaderDropdown = (props: Props) => {
  const {
    isUserProfile,
    actions,
    allMessages,
    hasUnreadMessages,
    isUserVerified,
    individual,
    unreadCount,
    putAllMessagesAsRead,
    setPageToReadToInitial,
    getAllDataMessages,
  } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  let locationState = history.location.state as { dropDownItem: string } | null;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isInitialStateDropdown, setIsInitialStateDropdown] = useState<boolean>(false);

  const toggle = () => {
    if (isMobile) {
      history.push({
        pathname: `${UI_ROUTES.myAccountNotifications}`,
        state: { messageId: null, isViewAll: true },
      });
      return;
    }
    setIsDropdownOpen(prev => !prev);
  };

  const handleClickIconInbox = () => {
    setPageToReadToInitial();
    getAllDataMessages();
  };

  const dropdownItemsMyProfile: HeaderDropdownItem[] = useMemo(() => {
    const items = [
      { id: 1, link: `${UI_ROUTES.myAccountPersonalDetails}`, text: lang.mobileProfilePersonalDetails() },
      { id: 2, link: `${UI_ROUTES.myAccountDocuments}`, text: lang.mobileProfileDocuments(), isDisabled: !isUserVerified },
      { id: 3, link: '', text: lang.mobileProfileChangePassword() },
      { id: 4,
        link: '/',
        text: lang.commonLogout(),
        alignBottom: true,
        action: actions ? actions[LogoutActionSelector] : {},
      },
    ];

    return items;
  }, [actions, isUserVerified]);

  const getDropdownToggle = () => {
    if (!isUserProfile) {
      return (
        <ClickableDiv
          onClick={handleClickIconInbox}
          className={isMobile ? styles.notificationsMobile : styles.notifications}
        >
          {
            hasUnreadMessages
              ? (
                <>
                  {isMobile ? null : <div className={styles.circleUnreadMessages}>{unreadCount}</div>}
                  <img src={iconInbox} alt="bell" />
                </>
              )
              : <img src={iconInbox} alt="bell" />
          }
        </ClickableDiv>
      );
    }

    return <UserPhoto />;
  };

  const handleBtnMarkAllAsRead = () => {
    putAllMessagesAsRead();
  };

  const handleClickBtnDropDown = () => {
    setIsDropdownOpen(false);
  };

  if (!isUserProfile || isInitialStateDropdown) {
    locationState = null;
  }

  useEffect(() => {
    if (locationState && locationState.dropDownItem === lang.mobileProfileDocuments()) {
      setIsDropdownOpen(true);
    }
  }, [locationState]);

  return (

    <Dropdown isOpen={isDropdownOpen} toggle={toggle} className="d-flex align-items-center justify-content-end">
      {!isUserProfile ? null : <UserDetails isSecondary />}
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={isDropdownOpen} onClick={() => setIsInitialStateDropdown(true)}>
        {getDropdownToggle()}
      </DropdownToggle>
      <DropdownMenu right className={isUserProfile ? styles.dropdown : styles.dropdownNotifications}>
        {
          isUserProfile
            ? (
              <MyProfile
                dropdownItemsMyProfile={dropdownItemsMyProfile}
                dropDownItem={locationState ? locationState.dropDownItem : null}
                handleClickBtnDropDown={handleClickBtnDropDown}
              />
            )
            : (
              <Notifications
                handleBtnMarkAllAsRead={handleBtnMarkAllAsRead}
                allMessages={allMessages}
                hasUnreadMessages={hasUnreadMessages}
                handleClose={() => setIsDropdownOpen(false)}
              />
            )
        }
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  allMessages: state.ams.allMessages,
  hasUnreadMessages: state.ams.hasUnreadMessages,
  unreadCount: state.ams.unreadCount,
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
  individual: state.crm.individualExtendedInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  putAllMessagesAsRead: () => dispatch(markAllMessagesAsRead()),
  setPageToReadToInitial: () => dispatch(setAllMessagesPageToReadToInitial()),
  getAllDataMessages: () => dispatch(getAllMessages()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
  type ReduxProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDropdown);
