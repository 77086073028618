import React from 'react';

import lang from '../../../../../../../lib/language';

import styles from './PersonalNameDetails.module.scss';

type Props = {
    firstName: string;
    middleName: string;
    lastName: string;
}

const PersonalNameDetails = ({ firstName, middleName, lastName }: Props) => {
  let nameDetails: object = {};

  if (middleName) {
    nameDetails = {
      [lang.commonOnboardingPersonalDetailsFirstName()]: firstName,
      [lang.commonMiddleName()]: middleName,
      [lang.commonOnboardingPersonalDetailsLastName()]: lastName,
    };
  } else {
    nameDetails = {
      [lang.commonOnboardingPersonalDetailsFirstName()]: firstName,
      [lang.commonOnboardingPersonalDetailsLastName()]: lastName,
    };
  }

  return (
    <div className={styles.wrapperNameDetails}>
      <div className={styles.detailsHeader}>
        <div>{lang.commonNames()}</div>
      </div>
      <div className={styles.containerDetails}>
        <div className={styles.card}>
          {
            Object.entries(nameDetails).map(([key, value]) => (
              <div className={styles.cardContent} key={key}>
                <span className={styles.cardKey}>{key}</span>
                <span className={styles.cardValue}>{value}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default PersonalNameDetails;
