import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CallStatus } from '../../enums';
import { GenericStatusByCall } from '../../enums/status-by-call';
import {
  InboxMessage,
  InboxPageErrorResponse,
  InboxPageRequest,
  InboxPageResponse,
  InboxResponse,
  SummaryResponse,
} from '../../models/ams/types';
import { SuccessPayload } from '../common-actions';

import { NO_MORE_PAGES_TO_READ } from './constants';
import { setAMSCallStatus } from './helpers';
import { AMSCall } from './types';

export interface AMSState {
  allMessages: InboxResponse[]
  currentMessage: InboxMessage | null
  nextPageToRead: number
  hasUnreadMessages: boolean,
  unreadCount: number
  errorsLog: string[];
  statusByCall: GenericStatusByCall<AMSCall>
}

export const INITIAL_STATE: AMSState = {
  allMessages: [],
  currentMessage: null,
  nextPageToRead: 0,
  hasUnreadMessages: false,
  unreadCount: 0,
  errorsLog: [],
  statusByCall: {},
};

const amsSlice = createSlice({
  name: 'ams',
  initialState: INITIAL_STATE,
  reducers: {
    getSummary(state: AMSState) {
      setAMSCallStatus(AMSCall.getSummary, CallStatus.PENDING, state);
    },
    getSummarySuccess(state: AMSState, action: PayloadAction<SummaryResponse>) {
      const { unreadCount } = action.payload;
      state.hasUnreadMessages = unreadCount > 0;
      state.unreadCount = unreadCount;
      setAMSCallStatus(AMSCall.getSummary, CallStatus.READY, state);
    },
    getSummaryFail(state: AMSState, action: PayloadAction<void>) {
      setAMSCallStatus(AMSCall.getSummary, CallStatus.ERROR, state);
    },
    getAllMessages(state: AMSState, action: PayloadAction<InboxPageRequest | void>) {
      // Start from begining
      if (state.nextPageToRead === NO_MORE_PAGES_TO_READ) {
        state.nextPageToRead = 0;
      }
      setAMSCallStatus(AMSCall.getAllMessages, CallStatus.PENDING, state);
    },

    getAllMessagesSuccess(state: AMSState, action: PayloadAction<InboxPageResponse>) {
      const resp = action.payload;
      // if its the first page of data
      if (resp.number === 0) {
        state.allMessages = resp.content;
      } else {
        resp.content.map(msg => state.allMessages.push(msg));
      }

      // resp.number (page number) starts from 0
      if (resp.number < resp.totalPages - 1) {
        state.nextPageToRead = resp.number + 1;
      } else {
        state.nextPageToRead = NO_MORE_PAGES_TO_READ;
      }
      setAMSCallStatus(AMSCall.getAllMessages, CallStatus.READY, state);
    },

    getAllMessagesFail(state: AMSState, action: PayloadAction<InboxPageErrorResponse>) {
      setAMSCallStatus(AMSCall.getAllMessages, CallStatus.ERROR, state);
    },

    getMessage(state: AMSState, action: PayloadAction<number>) {
      setAMSCallStatus(AMSCall.getMessage, CallStatus.PENDING, state);
    },

    getMessageSuccess(state: AMSState, action: PayloadAction<InboxMessage>) {
      state.currentMessage = action.payload;
      setAMSCallStatus(AMSCall.getMessage, CallStatus.READY, state);
    },

    getMessageFail(state: AMSState, action: PayloadAction) {
      setAMSCallStatus(AMSCall.getMessage, CallStatus.ERROR, state);
    },

    deleteMessages(state: AMSState, action: PayloadAction<number | number[]>) {
      setAMSCallStatus(AMSCall.deleteMessages, CallStatus.PENDING, state);
    },

    deleteMessagesSuccess(state: AMSState, action: PayloadAction<SuccessPayload>) {
      const tmpMessagesArray: InboxResponse[] = [];
      state.allMessages.map(msg => {
        if (!action.payload.customData.ids.includes(msg.id.toString())) {
          tmpMessagesArray.push(msg);
        }
        return tmpMessagesArray;
      });
      state.allMessages = tmpMessagesArray;
      setAMSCallStatus(AMSCall.deleteMessages, CallStatus.READY, state);
    },

    deleteMessagesFail(state: AMSState, action: PayloadAction<void>) {
      setAMSCallStatus(AMSCall.deleteMessages, CallStatus.ERROR, state);
    },

    markAllMessagesAsRead(state: AMSState) {
      setAMSCallStatus(AMSCall.markAllAsRead, CallStatus.PENDING, state);
    },

    markAllMessagesAsReadSuccess(state: AMSState) {
      const tmpMessagesArray: InboxResponse[] = [];
      state.allMessages.map(msg => tmpMessagesArray.push({
        ...msg,
        unread: false,
      }));

      state.allMessages = tmpMessagesArray;
      setAMSCallStatus(AMSCall.markAllAsRead, CallStatus.READY, state);
    },

    markAllMessagesAsReadFail(state: AMSState) {
      setAMSCallStatus(AMSCall.markAllAsRead, CallStatus.ERROR, state);
    },

    markAsRead(state: AMSState, action: PayloadAction<number | number[]>) {
      setAMSCallStatus(AMSCall.markAsRead, CallStatus.PENDING, state);
    },

    markAsReadSuccess(state: AMSState, action: PayloadAction<SuccessPayload>) {
      const tmpMessagesArray: InboxResponse[] = [];
      state.allMessages.map(msg => tmpMessagesArray.push({
        ...msg,
        unread: action.payload.customData.ids.includes(msg.id) ? false : msg.unread,
      }));

      state.allMessages = tmpMessagesArray;
      setAMSCallStatus(AMSCall.markAsRead, CallStatus.READY, state);
    },

    markAsReadFail(state: AMSState) {
      setAMSCallStatus(AMSCall.markAsRead, CallStatus.ERROR, state);
    },
    notifyUnreadCount(state: AMSState) {
      setAMSCallStatus(AMSCall.notifyUnreadCount, CallStatus.PENDING, state);
    },
    notifyUnreadCountSuccess(state: AMSState) {
      setAMSCallStatus(AMSCall.notifyUnreadCount, CallStatus.READY, state);
    },
    notifyUnreadCountFail(state: AMSState) {
      setAMSCallStatus(AMSCall.notifyUnreadCount, CallStatus.ERROR, state);
    },
    markAsReadByMetadata(state: AMSState) {
      setAMSCallStatus(AMSCall.markAsReadByMetadata, CallStatus.PENDING, state);
    },

    markAsReadByMetadataSuccess(state: AMSState) {
      setAMSCallStatus(AMSCall.markAsReadByMetadata, CallStatus.READY, state);
    },

    markAsReadByMetadataFail(state: AMSState) {
      setAMSCallStatus(AMSCall.markAsReadByMetadata, CallStatus.ERROR, state);
    },
    setAllMessagesPageToReadToInitial(state) {
      state.nextPageToRead = 0;
    },
  },
  extraReducers: {},
});

export const {
  getAllMessages,
  getAllMessagesSuccess,
  getAllMessagesFail,
  getMessage,
  getMessageSuccess,
  getMessageFail,
  deleteMessages,
  deleteMessagesSuccess,
  deleteMessagesFail,
  markAllMessagesAsRead,
  markAllMessagesAsReadSuccess,
  markAllMessagesAsReadFail,
  markAsRead,
  markAsReadSuccess,
  markAsReadFail,
  getSummary,
  getSummarySuccess,
  getSummaryFail,
  markAsReadByMetadata,
  markAsReadByMetadataSuccess,
  markAsReadByMetadataFail,
  notifyUnreadCount,
  notifyUnreadCountSuccess,
  notifyUnreadCountFail,
  setAllMessagesPageToReadToInitial,
} = amsSlice.actions;

export default amsSlice.reducer;
