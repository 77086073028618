import React from 'react';
import moment from 'moment';

import { pendingOrdersDatePlacedFormat } from '../../../../lib/constants/date-time.constants';
import lang from '../../../../lib/language';
import { SORExtendedHoursMarker } from '../../../../lib/models/gateway/types';
import { getEndOfDayOrderExpirationDate } from '../../../../lib/store/reporting/trading/order-helpers';
import { TimeInForce } from '../../../../lib/store/reporting/types';

import styles from './styles.module.scss';

type Props = {
    expirationDate: string;
    timeInForce: number;
    externalHours: SORExtendedHoursMarker;
    externalHoursStyle: string | undefined;
    isMobile?: boolean;
}

const PendingOrdersExpirationDate = ({
  expirationDate, timeInForce, externalHours, externalHoursStyle, isMobile,
}: Props) => (
  <>
    {(timeInForce === TimeInForce.GTC || timeInForce === TimeInForce.GoodTillDate)
      && (<div>{moment.utc(expirationDate).format(pendingOrdersDatePlacedFormat)}</div>)}
    {(timeInForce === TimeInForce.Day || timeInForce === TimeInForce.ExtendedDay)
      && <div>{getEndOfDayOrderExpirationDate()}</div>}
    {externalHours === 'X' && (
      <>
        {isMobile && (
        <span className={styles.dotMobile}>
          {'\u2022'}
        </span>
        )}
        <div className={externalHoursStyle}>{lang.commonMyAccountPendingOrdersExtHours()}</div>
      </>
    )}
  </>
);

export default PendingOrdersExpirationDate;
