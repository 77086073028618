import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { CallStatus } from '../../../../../../lib/enums/call-status.enum';
import { InboxMessage } from '../../../../../../lib/models/ams/types';
import { RootState } from '../../../../../../lib/store';
import { AMSCall } from '../../../../../../lib/store/ams/types';
import {
  extractDateFromString,
  formatUTCdateToLocalDate,
} from '../../../../../../lib/util/DateTimeHelpers';

import styles from '../AllNotifications.module.scss';

type Props = {
  messageId: number;
}

const NotificationInfo = ({ messageId } : Props) => {
  const currentMessage = useSelector<RootState, InboxMessage | null>((state: RootState) => state.ams.currentMessage);
  const isMessageReady = useSelector<RootState, boolean>(
    (state: RootState) => state.ams.statusByCall[AMSCall.getMessage]?.status === CallStatus.READY,
  );

  if (currentMessage?.id !== messageId) {
    return null;
  }

  return (
    <div className={styles.wrapperMessageDescription}>
      { currentMessage && isMessageReady && (
        ReactHtmlParser(currentMessage.body).map((body) => {
          if (body.type === 'p') {
            const stringDate = extractDateFromString(body.props.children.toString());
            if (stringDate) {
              return body.props.children.toString()
                .replace(`${stringDate}`, `${formatUTCdateToLocalDate(`${stringDate}`)}`);
            }
          }
          return body;
        })
      )}
    </div>
  );
};

export default NotificationInfo;
