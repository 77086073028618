/**
 * Excluding enroll calls - for example:
 *  - getIndividualExtendedInfo
 */

export enum CRMCall {
  getIndividualExtendedInfo,
  getCountryList,
  getAccounts,
  getAccountInfo,
  getAccountDetails,
  getLegalDeclarations,
  getTradingPlatforms,
}

export enum LegalDeclarations {
  ClientAgreement = 'Client Agreement',
  GeneralTerms = 'General Terms and Conditions',
  OrderExecutionPolicy = 'Order Execution Policy',
  RiskWarningDisclosure = 'Risk Warning Disclosure and Notice',
  PrivacyPolicy = 'Privacy Policy',
  ConflictOfInterest = 'Conflict of Interest Summary',
  ClientCategorization = 'Client Categorization Notice',
  ComplaintsHandlingPolicy = 'Complaints Handling Policy',
  Tariff = 'Tariff',
}

export type UploadDocumentFiles = {
  file_name: string,
  file_data: string,
}

export type CRMCallName = keyof typeof CRMCall
