import React, { useEffect, useState } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { ControlError } from '../../lib/util/ValidationSchemes/ValidationTypes';

import iconExpandDown from '../../assets/img/icon-expand-more-down.svg';
import { IOption } from '../SelectControl/SelectControl.types';

import styles from './AutocompleteControl.module.scss';

interface IndustryOptions extends IOption {
  name?: string;
}

const filterOptions: (options: IndustryOptions[], state: any) =>
IndustryOptions[] = createFilterOptions({
  matchFrom: 'start',
});

type Props = {
  options: IOption[];
  placeholder: string;
  id?: string,
  errors?: ControlError[];
  value?: string;
  className?: string;
  displayWithHighlights?: boolean;
  isReadOnly?: boolean;
  onValueChanged?: (value: string | number) => void;
  onBlur?: (value: string | number | null) => void;
}

const textStyles = makeStyles({
  formControlRoot: {
    '& label.Mui-focused': {
      transform: 'translateY(-85%) scale(0.75)',
      color: '#A0A3B9',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translateY(-85%) scale(0.75)',
      color: '#A0A3B9',
    },
  },
});

const AutocompleteControl = (props: Props) => {
  const {
    id,
    value,
    errors = [],
    onBlur,
    options,
    className,
    placeholder,
    onValueChanged,
    displayWithHighlights,
    isReadOnly,
  } = props;

  const textClasses = textStyles();

  const [isControlDirty, setIsControlDirty] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isControlDirty
      && (options && options.length > 0)
      && (value && (value.trim() !== ''))
    ) {
      const filteredOptions = options.find((option: IOption) => (
        option.value.toString().toLowerCase() === value.toLowerCase()));

      const optionToEmit = filteredOptions ?? options[0];

      setSelectedOption(optionToEmit);
    }
  }, [isControlDirty, onValueChanged, options, value]);

  return (
    <>
      <Autocomplete
        disableClearable
        openOnFocus
        autoHighlight
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        options={options}
        filterOptions={filterOptions}
        className={className}
        value={selectedOption ?? null}
        getOptionLabel={(option) => option.label}
        popupIcon={<img id={id} aria-label={placeholder} src={iconExpandDown} alt="up down icon" />}
        onBlur={() => onBlur && onBlur(selectedOption ? selectedOption?.value : null)}
        PaperComponent={({ children }) => (
          <Paper className={styles.paper}>{children}</Paper>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={placeholder}
            className={styles.inputControl}
            InputProps={{
              ...params.InputProps,
              itemID: id,
              'aria-label': `${placeholder} input select`,
              readOnly: !!isReadOnly,
            }}
          />
        )}
        classes={{ root: textClasses.formControlRoot }}
        renderOption={(option, { inputValue }) => {
          if (!displayWithHighlights) {
            return option.label;
          }

          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{ color: part.highlight ? '#2D2D2D' : '#A0A3B9' }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        onChange={(_, option) => {
          setSelectedOption(option);
          setIsControlDirty(true);
          onValueChanged && onValueChanged(option.value);
        }}
        getOptionSelected={(option: IOption, currentOption: IOption) => (
          (currentOption.value !== '') && (option.value === currentOption.value)
        )}
      />
      {errors.map((error: ControlError) => <p key={error.id} className={styles.error}>{error.error}</p>)}
    </>
  );
};

export default AutocompleteControl;
