import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { refreshPayments } from '../../../../lib/store/common-actions';
import { setBankAccountDetailsOnCreate } from '../../../../lib/store/crm/index';

import { Button } from '../../../../components';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './Modal.module.scss';

type Props = {
    textInfoModal: {title: string, description1: string, description2?: string},
    previousPage: string,
} & ReduxProps;

const AddBankAccountModal = (props: Props) => {
  const {
    previousPage,
    textInfoModal,
    callsForRefresh,
    setBankAccountValuesOnCreate,
    refreshPaymentsData,
  } = props;

  const history = useHistory();
  const { title, description1, description2 } = textInfoModal;

  const handleContinue = () => {
    setBankAccountValuesOnCreate();

    if (previousPage === UI_ROUTES.funds) {
      return history.replace(UI_ROUTES.funds);
    }

    return history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.enterAmount,
      state: {
        target: lang.mobileBankDialogTitle() },
    });
  };

  useEffect(() => {
    if (callsForRefresh) {
      refreshPaymentsData();
    }
  }, [callsForRefresh, refreshPaymentsData]);

  return (
    <>
      <h6 id="bankDetailsSetTtile" className={styles.title}>{title}</h6>
      <span id="bankDetailsSetDescription1" className={styles.description}>{description1}</span>
      <span id="bankDetailsSetDescription2" className={styles.description}>{description2}</span>
      <Button
        id="bankDetailsSetContinue"
        fullWidth
        className={styles.btnContinue}
        onClick={handleContinue}
      >
        {lang.commonContinueButton()}
      </Button>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  callsForRefresh: state.payment.callsForRefresh,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setBankAccountValuesOnCreate: () => dispatch(setBankAccountDetailsOnCreate({})),
  refreshPaymentsData: () => dispatch(refreshPayments()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddBankAccountModal);
