import { produce } from 'immer';
import { uniq } from 'lodash';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { DELETE_PREVIOUS_SUBSCRIBE_GROUP } from '../libSettings';
import { MDSFeed } from '../models/market-data/types';
import { SubscribeGroup } from '../store/market-data/types';
import { addToObjectOfArray } from '../util/ObjectTools';
import { NullableString, SubscribedComponent, TypedObject } from '../util/types';

const { logConfig } = require('../../configDebug');


const DEBUG_SYMBOLS = !!(logConfig as any)?.entityDebugEnabled?.length;

type SubscribeGroupData = Partial<Record<SubscribeGroup, TypedObject<MDSFeed>>>
export type SubscribedComponentData = Partial<TypedObject<SubscribedComponent[]>>
export type SubscribeData = {
  data: SubscribeGroupData
  prevGroup?: SubscribeGroup | null
  currentGroup: SubscribeGroup | null
  subscribedSymbols?: string
  unsubscribedSymbols?: string
  debugLastUpdate?: string
  currentComponent?: SubscribedComponent
  componentData?: SubscribedComponentData
  prevComponent?: SubscribedComponent
  debugCacheActionsHistory?: NullableString
  lastDeleted?: TypedObject<MDSFeed> & { group: string }
}
const SubscribeGroupCache = entity<SubscribeData>({
  currentGroup: 'watchlist',
} as SubscribeData, entityReduxLogger('Subscribe', 'subscribes'));

const self = SubscribeGroupCache;

let debugSymbolsTimeout: any;
function debugSymbols(state: SubscribeData, group: SubscribeGroup | null, type: '+' | '-' | 'x', symbols?: string[]) {
  const { __DEV__, __TEST__ } = require('../../configLib').default;
  if (!DEBUG_SYMBOLS || !__DEV__ || __TEST__) return;

  // This timeout is used for debugging sets of changes by separating them with [...set-of-changes],[...another-set-of-changes]
  // For example: [watchlist],[symbol-details,my-account]
  if (!debugSymbolsTimeout) {
    debugSymbolsTimeout = setTimeout(() => {
      debugSymbolsTimeout = null;
      self.set(produce(innerState => {
        // eslint-disable-next-line no-param-reassign
        innerState.debugCacheActionsHistory += ' ..... ';
      }));
    }, 100);
  }

  const subscribes: string[] = [];
  const unsubscribes: string[] = [];

  for (const groupName in state.data) {
    const groupData = state.data[groupName as SubscribeGroup];
    for (const symbol in groupData) {
      if (groupData[symbol]) {
        subscribes.push(symbol);
      } else {
        unsubscribes.push(symbol);
      }
    }
  }
  const sortedSubscribes = uniq(subscribes).sort();
  const sortedUnsubscribes = uniq(unsubscribes).sort();
  const subscribesCount = (sortedSubscribes.length ? `${sortedSubscribes.length} | ` : '');
  const unsubscribesCount = (sortedUnsubscribes.length ? `${sortedUnsubscribes.length} | ` : '');
  state.subscribedSymbols = subscribesCount + sortedSubscribes.join();
  state.unsubscribedSymbols = unsubscribesCount + sortedUnsubscribes.join();
  let historyDraft = state.debugCacheActionsHistory ?? '';
  const isFirstChangeInSet = historyDraft.length === 0 || historyDraft.lastIndexOf(' ') === historyDraft.length - 1;
  state.debugCacheActionsHistory = `${historyDraft}${isFirstChangeInSet ? '' : ','}${type}:${group}`;
  if (symbols) state.debugLastUpdate = symbols.join();
}

export function calculateFeedAfterUnsubscribe(currentFeed: MDSFeed | null, feedToDelete?: MDSFeed) {
  if (!feedToDelete || !currentFeed) return false;

  const currentFeeds: MDSFeed[] = (currentFeed as string).split(',') as MDSFeed[];
  const feedsToDelete: MDSFeed[] = (feedToDelete as string).split(',') as MDSFeed[];
  const isCurrentMultiple = currentFeeds.length > 1;
  const isToDeleteMultiple = feedsToDelete.length > 1;
  if (isToDeleteMultiple) return false;
  if (!isCurrentMultiple && !isToDeleteMultiple) return false;
  // both feeds to be multiple is currently not supported
  if (isCurrentMultiple && isToDeleteMultiple) return false;

  const index = currentFeeds.indexOf(feedToDelete);
  if (index === -1) return false;

  currentFeeds.splice(index, 1);

  return currentFeeds.join();
}

/**
 * @param group The group to set, `null` if all should be set
 * @param symbols The list of symbol to process
 * @param isSubscribe
 * @param feed
 */
function updateState(
  group: SubscribeGroup | null,
  symbols: string[],
  isSubscribe: boolean,
  feed?: MDSFeed,
  skipGroupChange?: boolean,
) {
  self.set(produce(state => {
    const data = state.data ?? {};
    const isNew = group && group !== state.currentGroup;
    if (group && !data[group]) data[group] = {};

    symbols.forEach(symbol => {
      const keys = Object.keys(data);
      for (const checkedGroup of keys) {
        const groupData = data[checkedGroup];

        /* eslint-disable no-continue */
        if (checkedGroup === 'initial') continue;
        if (checkedGroup !== group && groupData[symbol] == null) continue;
        /* eslint-enable no-continue */

        const value = (
          isSubscribe
            ? feed
            : calculateFeedAfterUnsubscribe(state?.lastDeleted?.[symbol] ?? groupData[symbol], feed)
        );
        if (groupData[symbol] !== value) groupData[symbol] = value;
      }
    });

    if (isNew) {
      if (
        DELETE_PREVIOUS_SUBSCRIBE_GROUP
        && group
        && state.prevGroup
        && state.prevGroup !== group
        && state.data[state.prevGroup]
      ) {
        delete state.data[state.prevGroup];
      }
      if (!skipGroupChange && isSubscribe && state.currentGroup !== group) {
        state.prevGroup = state.currentGroup;
        state.currentGroup = group;
      }
    }

    state.data = data;
    debugSymbols(state, group, isSubscribe ? '+' : '-', symbols);
  }));
}

function deleteSymbolFromGroup(symbolOrSymbols: string, group: SubscribeGroup) {
  self.set(produce(state => {
    const symbols = symbolOrSymbols.split(',');
    symbols.forEach(symbol => {
      if (state[group]?.[symbol]) delete state[group][symbol];
    });
    debugSymbols(state, group, 'x');
  }));
}

/**
 * Extracts symbol feed from other groups
 * @param state Extracted state or draft to use
 * @param group The current group which to not include in search
 * @param symbol The symbol to search for
 */
function getSymbolFeedFromOtherGroups(state: SubscribeData, group: SubscribeGroup, symbol: string) {
  let result: MDSFeed = false;
  const groups = Object.keys(state.data).filter(item => item !== group);
  for (const prop in groups) {
    if (
      state.data?.[prop as SubscribeGroup]?.[symbol]
      && Object.prototype.hasOwnProperty.call((state as any).data?.[prop] as any ?? {}, symbol)
    ) {
      result = state.data[prop as SubscribeGroup]![symbol] as MDSFeed;
      break;
    }
  }
  return result;
}

function refreshSubscribeGroup(group: SubscribeGroup, symbols: string[], feed?: MDSFeed) {
  self.set(produce(state => {
    if (state?.data?.[group]) {
      state.lastDeleted = state.data[group];
      state.lastDeleted.group = group;
      state.data[group] = {};
    }

    debugSymbols(state, group, 'x');
  }));
}

function getCurrentSymbols() {
  const state = self.get();
  const group = state.currentGroup!;
  const data = state.data?.[group] ?? {};
  return {
    symbols: Object.keys(data),
    group,
  };
}

// exporting setter only for unit testing and mocked mode
export const _setSubscribeGroupCache_InUnitTestsAndMockedMode = self.set; // eslint-disable-line no-underscore-dangle


/** Set given symbols to subscribed (feed=@feed ) */
const setSubscribed = (symbols: string[], group: SubscribeGroup, feed: MDSFeed) => {
  updateState(group, symbols, true, feed);
};
/** Set given symbols to subscribed (feed=@feed ) - does not change the group */
const setSubscribedNoGroupChange = (symbols: string[], group: SubscribeGroup, feed: MDSFeed) => {
  updateState(group, symbols, true, feed, true);
};
/** Set given symbols to unsubscribed (feed=false ) */
const setUnSubscribed = (symbols: string[], group: SubscribeGroup | null, feed?: MDSFeed) => {
  updateState(group, symbols, false, feed);
};
/** Delete symbols from a group */
const deleteFromGroup = (symbolsOrSymbol: string, group: SubscribeGroup) => {
  deleteSymbolFromGroup(symbolsOrSymbol, group);
};
/** Delete a group */
const refreshGroup = (group: SubscribeGroup, symbols: string[], feed?: MDSFeed) => {
  refreshSubscribeGroup(group, symbols, feed);
};
const setGroupOnly = (group: SubscribeGroup) => {
  const { currentGroup } = self.get();
  if (currentGroup === group) return;

  const { __DEV__ } = require('../../configLib').default;
  if (__DEV__ && logConfig.subscriptionsFlow) {
    console.tron.log!(`[Subscribe-Cache] Set group: ${currentGroup} -> ${group} [s-flow]`);
  }
  self.set(produce(state => {
    if (state.currentGroup !== group) {
      state.prevGroup = state.currentGroup;
      state.currentGroup = group;
    }
  }));
};
const isSubscribed = (group: SubscribeGroup, symbol: string) => {
  const { data } = self.get();
  return !!data?.[group]?.[symbol];
};
/** Returns data related to group */
const get = (group: SubscribeGroup) => {
  const { data, currentGroup, prevGroup, currentComponent } = self.get() ?? {};
  const isSameGroup = group === currentGroup;
  const thePrevGroup = (isSameGroup ? prevGroup : currentGroup);
  const theData = (data ?? {});
  const resultData = (theData[group] ?? {}) as TypedObject<MDSFeed>;
  const symbols = Object.keys(resultData);
  return {
    data: resultData,
    prevData: (thePrevGroup ? theData[thePrevGroup] : {}) ?? {},
    prevGroup: thePrevGroup,
    component: currentComponent,
    currentGroup,
    isSameGroup,
    symbols,
  };
};
/** Gets symbols and their feeds for groups different from current */
const getOther = (group: SubscribeGroup) => {
  const { data, currentGroup, prevGroup } = self.get();
  const theData = (data ?? {});
  const otherSymbolsData: TypedObject<MDSFeed> = {};
  const otherGroups: SubscribeGroup[] = Object.keys(theData).filter(otherGroup => otherGroup !== group) as any;
  otherGroups.forEach(otherGroup => {
    const theGroup = theData[otherGroup] ?? {};
    Object.keys(theGroup).forEach(symbol => {
      otherSymbolsData[symbol] = theGroup[symbol];
    });
  });
  return {
    otherSymbols: Object.keys(otherSymbolsData),
    otherSymbolsData,
  };
};
const getCurrentComponent = (): SubscribedComponent | undefined => self.get().currentComponent;
const setComponent = (component: SubscribedComponent, symbolList?: string[]) => {
  const { currentComponent, componentData } = self.get();
  let symbols = symbolList;

  const { __DEV__ } = require('../../configLib').default;
  if (__DEV__ && logConfig.subscriptionsFlow) {
    console.tron.log!(`[Subscribe-Cache] Set component: ${currentComponent} -> ${component} [s-flow]`);
  }

  if (componentData && componentData[component]?.length && symbolList) {
    symbols = [ ...componentData[component]!, ...symbolList ];
  }

  self.set(produce(state => {
    if (state.currentComponent !== component) {
      state.prevComponent = state.currentComponent;
      state.currentComponent = component;
    }
    state.componentData = {
      [component]: symbols ? uniq(symbols) : [],
    };
  }));
};
const getSymbolsBySelectedComponent = (): string[] | null => {
  const { componentData, currentComponent } = self.get();
  return componentData && currentComponent && currentComponent !== 'initial'
    ? componentData[currentComponent]! : null;
};

const getAllSubscribed = () => {
  const { data, currentGroup, prevGroup } = self.get();
  if (!data) {
    return {
      subscribedByFeed: {},
      subscribedSymbols: {},
      symbols: [],
      currentGroup,
      prevGroup,
    };
  }

  const subscribedByFeed: Partial<Record<Extract<MDSFeed, string>, string[]>> = {};
  const symbols: string[] = [];
  for (const group in data) {
    const groupData = (data as any)[group];
    for (const symbol in groupData) {
      const feed = (groupData as TypedObject<MDSFeed>)[symbol];
      if (feed) {
        addToObjectOfArray(subscribedByFeed, feed as string, [ symbol ]);
        symbols.push(symbol);
      }
    }
  }
  const symbolsWithoutDuplicates = uniq(symbols);
  const subscribedSymbols: Record<string, true> = {};
  symbolsWithoutDuplicates.forEach(symbol => { subscribedSymbols[symbol] = true; });
  return {
    subscribedByFeed,
    subscribedSymbols,
    symbols: symbolsWithoutDuplicates,
    currentGroup,
    prevGroup,
  };
};
/** Returns the whole state */
const getAll = self.get;
const getCurrent = getCurrentSymbols;
const isGroupSelected = (group: SubscribeGroup) => (
  self.get().currentGroup === group
);


export default {
  setSubscribed,
  setSubscribedNoGroupChange,
  setUnSubscribed,
  deleteFromGroup,
  refreshGroup,
  setGroupOnly,
  isSubscribed,
  get,
  getOther,
  getAllSubscribed,
  getSymbolsBySelectedComponent,
  getAll,
  getCurrent,
  isGroupSelected,
  setComponent,
  getCurrentComponent,
  use: self.use,
};
