import React, { Dispatch, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { TopGainerAndLoserData } from '../../../../lib/models/fundamental/types';
import { loadDiscoverChartData } from '../../../../lib/store/common-actions';
import { RootState } from '../../../../lib/store/index';
import { manageSubscriptions } from '../../../../lib/util/TradingHelpers';

import { BaseTable, MarketTableItem } from '../../../../components';
import { useIsMobile } from '../../../../hooks';
import { MARKET_SYMBOLS_TABLE_HEADERS } from '../../../../util/constants';

type OwnProps = {
  topMoversData: TopGainerAndLoserData[],
}
type Props = OwnProps & ReduxProps;

const TopMoversTable = ({ topMoversData, companyLogoAndName, getChartData }: Props) => {
  const isMobile = useIsMobile();
  const tableHeaders = useMemo(
    () => MARKET_SYMBOLS_TABLE_HEADERS.map((item: string) => <th key={item}>{item}</th>), [],
  );

  useEffect(() => {
    manageSubscriptions('discover/top-movers');
  }, []);

  useEffect(() => {
    const topMoversSymbols = topMoversData.map(el => el.symbol).join(',');
    getChartData(topMoversSymbols);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChartData]);

  return (
    <BaseTable>
      {!isMobile && (<thead><tr>{tableHeaders}</tr></thead>)}
      <tbody>
        {
          topMoversData.map(({ symbol }) => (
            <MarketTableItem
              key={symbol}
              symbol={symbol}
              logo={companyLogoAndName[symbol]?.logo}
              name={companyLogoAndName[symbol]?.name}
            />
          ))
        }
      </tbody>
    </BaseTable>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndName: state.fundamental.companyLogoAndName,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getChartData: (symbols: string) => dispatch(loadDiscoverChartData(symbols)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TopMoversTable);
