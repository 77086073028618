import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../lib/language';
import { LegalDeclaration, LegalDeclarationsDynamicFilePayload } from '../../lib/models/crm/types';
import { GetFileByNameCall } from '../../lib/models/file/types';
import { RootState } from '../../lib/store';
import { getLegalDeclarations, getLegalDeclarationsDynamicFile as getDynamicFile } from '../../lib/store/crm';
import { getFileByName } from '../../lib/store/file';

import DownloadIcon from '../../assets/img/icon-white-arrow-in-circle.svg';
import { handleDownloadFile } from '../../util/helpers';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button, Preloader } from '../index';

import { legalDeclarationIds, legalDeclarationsAriaLabels } from './TermsAndConditions.constants';

import styles from './TermsAndConditions.module.scss';

type Props = {
    isVerification?: boolean,
} & ReduxProps;

const TermsAndConditions = (props: Props) => {
  const {
    clientAgreementFiles,
    files,
    isVerification = false,
    legalDeclarations,
    getFileName,
    getLegalDeclarationsList,
    getLegalDeclarationsDynamicFile,
  } = props;
  const [downloadFiles, setDownloadFiles] = useState('');
  const [isStaticFile, setIsStaticFile] = useState<boolean>(true);

  useEffect(() => {
    getLegalDeclarationsList({ active: true, isVerified: !isVerification });
  }, [getLegalDeclarationsList, isVerification]);

  useEffect(() => {
    const storedFiles = isStaticFile ? files[downloadFiles] : clientAgreementFiles[downloadFiles];

    if ((downloadFiles.trim() !== '') && storedFiles) {
      handleDownloadFile(downloadFiles, storedFiles);
    }
  }, [files, downloadFiles, isStaticFile, clientAgreementFiles]);

  const getFile = (legalDeclarationItem: LegalDeclaration) => {
    const { storage_id, template_storage_id } = legalDeclarationItem;

    if (template_storage_id) {
      getLegalDeclarationsDynamicFile({ templateName: template_storage_id, fileName: storage_id });
    } else {
      getFileName({ documentName: storage_id });
    }
    setIsStaticFile(!template_storage_id);
    setDownloadFiles(storage_id);
  };

  if (!legalDeclarations) {
    return <div className={styles.loader}><Preloader text="" owner="PopularGroup" /></div>;
  }

  return (
    <>
      <div className={styles.buttonsWrapper}>
        {
          legalDeclarations?.map((legalDeclaration: LegalDeclaration, index) => (
            <Button
              key={legalDeclaration.id}
              id={legalDeclarationIds[index]}
              ariaLabel={legalDeclarationsAriaLabels[index]}
              className={styles.button}
              variant={ButtonTypesEnum.Link}
              onClick={() => getFile(legalDeclaration)}
            >
              <>
                <span>
                  {legalDeclaration.name.includes(lang.commonTariff()) ? lang.commonTariff() : legalDeclaration.name}
                </span>
                {isVerification && <img src={DownloadIcon} className={styles.icon} alt="arrow down in circle" />}
              </>
            </Button>
          ))
        }
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  legalDeclarations: state.crm.legalDeclarations,
  files: state.file.files,
  clientAgreementFiles: state.crm.files,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getFileName: (documentName: GetFileByNameCall) => dispatch(getFileByName(documentName)),
  getLegalDeclarationsList: (data: { active: boolean, isVerified: boolean }) => dispatch(getLegalDeclarations(data)),
  getLegalDeclarationsDynamicFile: (
    data: LegalDeclarationsDynamicFilePayload,
  ) => dispatch(getDynamicFile(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TermsAndConditions);
