import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CallStatus } from '../../../lib/enums/call-status.enum';
import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store/index';

import { Preloader } from '../../../components/index';
import TableContainerView from '../../../components/TableView/tableContainer/TableContainerView';
import { WATCH_LIST_TABLE_HEADERS_MY_ACCOUNT_PENDING_ORDERS } from '../../../components/WatchList/WatchList.constans';
import ErrorContent from '../ErrorContent/ErrorContent';

import { usePendingOrdersTableContent } from './helpers/usePendingOrdersTableContent';

import styles from '../MyAccount.module.scss';

type Props = ReduxProps;

const PendingOrders = ({ pendingOrders, hasMyStocksOrderUpdate, statusByCall }: Props) => {
  const pendingOrdersTableContent = usePendingOrdersTableContent(pendingOrders, false, hasMyStocksOrderUpdate);

  if (statusByCall[ReportingCall.getAccountTradeHistory]?.status === CallStatus.PENDING && !hasMyStocksOrderUpdate) {
    return <Preloader />;
  }

  const headerItems = WATCH_LIST_TABLE_HEADERS_MY_ACCOUNT_PENDING_ORDERS.map(
    (item: string) => <th key={item}>{item}</th>,
  );

  return pendingOrdersTableContent.length ? (
    <div className={styles.wrapperTable}>
      <TableContainerView
        pendingOrders
        tableHeaders={headerItems}
        data={pendingOrdersTableContent}
        isMultipleDivOfRow
        isButtonsInRow
        companyLogos
        otherButtons={lang.commonMyAccountButtonPendingOrders()}
      />
    </div>
  ) : <ErrorContent tabTitle={lang.commonMyAccountNoOrders()} />;
};

const mapStateToProps = (state: RootState) => ({
  pendingOrders: state.reporting.pendingOrders,
  hasMyStocksOrderUpdate: state.reporting.hasMyStocksOrderUpdate,
  statusByCall: state.reporting.statusByCall,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PendingOrders);
