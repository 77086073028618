import lang from '../../../lib/language';
import { ETF_SECTOR_NAME } from '../../../lib/services/fundamental/helpers';

import BasicMaterials from '../../../assets/popularGroupsLogos/BasicMaterials.png';
import Communications from '../../../assets/popularGroupsLogos/Communications.png';
import ConsumerDiscretionary from '../../../assets/popularGroupsLogos/ConsumerDiscretionary.png';
import ConsumerStaples from '../../../assets/popularGroupsLogos/ConsumerStaples.png';
import Energy from '../../../assets/popularGroupsLogos/Energy.png';
import ETFs from '../../../assets/popularGroupsLogos/ETFs.png';
import FinancialServices from '../../../assets/popularGroupsLogos/FinancialServices.png';
import Healthcare from '../../../assets/popularGroupsLogos/Healthcare.png';
import Industrials from '../../../assets/popularGroupsLogos/Industrials.png';
import RealEstate from '../../../assets/popularGroupsLogos/RealEstate.png';
import Technology from '../../../assets/popularGroupsLogos/Technology.png';
import Utilities from '../../../assets/popularGroupsLogos/Utilities.png';

const groups = {
  ETFs: {
    logo: ETFs,
    name: lang.popularGroupETFs(),
    sectorName: ETF_SECTOR_NAME,
  },
  CommunicationServices: {
    logo: Communications,
    name: lang.popularGroupCommunications(),
    sectorName: 'Communication Services',
  },
  Healthcare: {
    logo: Healthcare,
    name: lang.popularGroupHealthcare(),
    sectorName: 'Healthcare',
  },
  ConsumerCyclical: {
    logo: ConsumerDiscretionary,
    name: lang.popularGroupConsumerDiscretionary(),
    sectorName: 'Consumer Cyclical',
  },
  Technology: {
    logo: Technology,
    name: lang.popularGroupTechnology(),
    sectorName: 'Technology',
  },
  Utilities: {
    logo: Utilities,
    name: lang.popularGroupUtilities(),
    sectorName: 'Utilities',
  },
  ConsumerDefensive: {
    logo: ConsumerStaples,
    name: lang.popularGroupConsumerStaples(),
    sectorName: 'Consumer Defensive',
  },
  FinancialServices: {
    logo: FinancialServices,
    name: lang.popularGroupFinancialServices(),
    sectorName: 'Financial Services',
  },
  BasicMaterials: {
    logo: BasicMaterials,
    name: lang.popularGroupBasicMaterials(),
    sectorName: 'Basic Materials',
  },
  RealEstate: {
    logo: RealEstate,
    name: lang.popularGroupRealEstate(),
    sectorName: 'Real Estate',
  },
  Industrials: {
    logo: Industrials,
    name: lang.popularGroupIndustrials(),
    sectorName: 'Industrials',
  },
  Energy: {
    logo: Energy,
    name: lang.popularGroupEnergy(),
    sectorName: 'Energy',
  },
};

export default groups;
