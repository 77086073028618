import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';

import successIllustrationEffectSecondTransparentImage from '../../../assets/bigImages/success_illustration_effects_second_transparent.png';
import iconFilledCheck from '../../../assets/img/icon-filled-check.png';
import { Button, HeaderLogo } from '../../../components';
import { UI_ROUTES } from '../../../util/routes';

import styles from './EnrollCompleted.module.scss';

const EnrollCompleted = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.logoImgContainer}>
        <HeaderLogo className={styles.headerImg} />
      </div>
      <div className={styles.wrapperContent}>
        <h3 id="accountActivatedTitle" aria-label="the account is activated" className={styles.title}>
          {lang.commonOnboardingYayTitle()}
        </h3>
        <img src={successIllustrationEffectSecondTransparentImage} alt="thumb up" />
        <div className={styles.wrapperCreateYourAccount}>
          <img className={styles.iconCheck} src={iconFilledCheck} alt="icon filled check" />
          <p className={styles.text}>{lang.commonNextStepsButtonTitle_1()}</p>
        </div>
        <div className={styles.wrapperVerticalLinePurple}>
          <div className={styles.verticalLinePurple} />
        </div>
        <div className={styles.wrapperActivateYourAccount}>
          <img className={styles.iconCheck} src={iconFilledCheck} alt="icon filled check" />
          <p className={styles.text}>{lang.commonNextStepsButtonTitle_2()}</p>
        </div>
        <div className={styles.wrapperVerticalLineGrey}>
          <div className={styles.verticalLineGrey} />
        </div>
        <div className={styles.wrapperVerifyYourIdentity}>
          <div className={styles.circleGrey} />
          <p className={styles.text}>{lang.commonNextStepsButtonTitle_4()}</p>
        </div>
        <div className={styles.wrapperVerticalLineGrey}>
          <div className={styles.verticalLineGrey} />
        </div>
        <div className={styles.wrapperMakeYourFirstDeposit}>
          <div className={styles.circleGrey} />
          <p className={styles.text}>{lang.commonNextStepsButtonTitle_3()}</p>
        </div>
        <Button
          fullWidth
          id="enrollmentContinueBtn"
          className={styles.continueButton}
          onClick={() => history.push(UI_ROUTES.verification)}
        >
          {lang.commonContinueButton()}
        </Button>
      </div>
    </>
  );
};

export default EnrollCompleted;
