// --- V A L I D A T E ---
import moment from 'moment';

import lang from '../../language';

import {
  validateMaxCharacters4,
  validateMaxCharacters400,
  validateMaxCharactersAddress,
  validateMaxCharactersCity,
  validateMaxCharactersEmailAddress,
  validateMaxCharactersIDDocument,
  validateMaxCharactersPhoneNumber,
  validateMaxCharactersPostCode,
  validateMaxCharactersSorceOfFundName,
  validateMaxCharactersTaxNumber,
  validateMaxThirtyFourCharacters,
  validateMinCharacters,
  validateMinCharactersPhoneNumber,
  validateMinCharactersTaxNumber,
  validateMinFourCharacters,
  validateMinNineChars,
  validateNameMaxCharacters,
} from './CharactersLengthSchema';
import { validateEmailString } from './EmailSchema';
import { validateForEmptyStringOrSpecialSymbol } from './EmptyStringOrSpecialCharShema';
import { validateForEmptyString } from './EmptyStringSchema';
import { validateIban } from './IBANScheme';
import { PasswordValidationResult, validatePasswordString } from './PasswordSchema';
import {
  validateAddressNumbersOnly,
  validateAlphaNumericString,
  validateInvestmentsAndSavingsSpecify,
  validateSpecialSymbolsAddress,
  validateSpecialSymbolsCity,
  validateSpecialSymbolsEmail,
  validateSpecialSymbolsEmploymentName,
  validateSpecialSymbolsIDDocument,
  validateSpecialSymbolsName,
  validateSpecialSymbolsPostCode,
  validateSpecialSymbolsTaxNumber,
  validateStartWithLetter,
  validateStartWithLetterOrNumber,
} from './SpecialSymbolsSchema';
import { validateSwiftCode } from './SwiftCodeSchema';
import { validateUnderEighteen } from './UnderEighteensSchema';
import {
  ControlError, ControlValidation, ControlValidity, ValidationType,
} from './ValidationTypes';
import { validateWhitespace } from './WhitespaceSchema';

export const createFormControlError = (errorMessage: string): ControlError => ({
  id: moment().unix(),
  error: errorMessage,
});

export const validate = (validationTarget: string, validationType: ValidationType, returnList: boolean = false):
  boolean | PasswordValidationResult | string[] | string | null => {
  switch (validationType) {
    case ValidationType.EmptyStringOrSpecialSymbol:
      return validateForEmptyStringOrSpecialSymbol(validationTarget);
    case ValidationType.MinCharacters:
      return validateMinCharacters(validationTarget);
    case ValidationType.MinCharacters4:
      return validateMinFourCharacters(validationTarget);
    case ValidationType.MinNineChars:
      return validateMinNineChars(validationTarget);
    case ValidationType.MaxThirtyFourChars:
      return validateMaxThirtyFourCharacters(validationTarget);
    case ValidationType.AlphaNumeric:
      return validateAlphaNumericString(validationTarget);
    case ValidationType.MinCharactersTaxNumber:
      return validateMinCharactersTaxNumber(validationTarget);
    case ValidationType.MaxCharactersName:
      return validateNameMaxCharacters(validationTarget);
    case ValidationType.MaxCharactersCity:
      return validateMaxCharactersCity(validationTarget);
    case ValidationType.MaxCharactersPostCode:
      return validateMaxCharactersPostCode(validationTarget);
    case ValidationType.MaxCharactersAddress:
      return validateMaxCharactersAddress(validationTarget);
    case ValidationType.MaxCharactersIDDocument:
      return validateMaxCharactersIDDocument(validationTarget);
    case ValidationType.MaxCharactersSorceOfFundName:
      return validateMaxCharactersSorceOfFundName(validationTarget);
    case ValidationType.MaxCharactersTaxNumber:
      return validateMaxCharactersTaxNumber(validationTarget);
    case ValidationType.MinCharactersPhoneNumber:
      return validateMinCharactersPhoneNumber(validationTarget);
    case ValidationType.MaxCharactersPhoneNumber:
      return validateMaxCharactersPhoneNumber(validationTarget);
    case ValidationType.MaxCharactersEmailAddress:
      return validateMaxCharactersEmailAddress(validationTarget);
    case ValidationType.MaxCharacters4:
      return validateMaxCharacters4(validationTarget);
    case ValidationType.MaxCharacters400:
      return validateMaxCharacters400(validationTarget);
    case ValidationType.EmptyString:
      return validateForEmptyString(validationTarget);
    case ValidationType.InvalidCharactersName:
      return validateSpecialSymbolsName(validationTarget);
    case ValidationType.InvalidCharactersCity:
      return validateSpecialSymbolsCity(validationTarget);
    case ValidationType.InvalidCharactersIDDocument:
      return validateSpecialSymbolsIDDocument(validationTarget);
    case ValidationType.InvalidCharactersCompanyName:
      return validateSpecialSymbolsEmploymentName(validationTarget);
    case ValidationType.InvalidCharactersPostCode:
      return validateSpecialSymbolsPostCode(validationTarget);
    case ValidationType.InvalidCharactersAddress:
      return validateSpecialSymbolsAddress(validationTarget);
    case ValidationType.NotOnlyNumbers:
      return validateAddressNumbersOnly(validationTarget);
    case ValidationType.InvalidCharactersTaxNumber:
      return validateSpecialSymbolsTaxNumber(validationTarget);
    case ValidationType.StartWithLetter:
      return validateStartWithLetter(validationTarget);
    case ValidationType.StartWithLetterOrNumber:
      return validateStartWithLetterOrNumber(validationTarget);
    case ValidationType.InvalidCharactersEmail:
      return validateSpecialSymbolsEmail(validationTarget);
    case ValidationType.Email:
      return validateEmailString(validationTarget);
    case ValidationType.Password:
      return validatePasswordString(validationTarget);
    case ValidationType.SwiftCode:
      return validateSwiftCode(validationTarget);
    case ValidationType.Whitespace:
      return validateWhitespace(validationTarget);
    case ValidationType.IBAN:
      return validateIban(validationTarget);
    case ValidationType.UnderEighteen:
      return validateUnderEighteen(validationTarget);
    case ValidationType.InvestmentSavingSpecify:
      return validateInvestmentsAndSavingsSpecify(validationTarget);
    default:
      return false;
  }
};

export const validateControl = (
  controlValue: string | number,
  validations: ControlValidation[] = [],
  controlLabel: string = '',
  isRequired: boolean = false,
): ControlValidity => {
  const validity: ControlValidity = {
    isValid: true,
    errors: [],
  };

  if (isRequired && (!controlValue || !validate(controlValue.toString(), ValidationType.EmptyString))) {
    validity.isValid = false;
    const error = createFormControlError(lang.commonOnboardingGenericFieldIsRequired(controlLabel));
    validity.errors.push(error);
    return validity;
  }

  if (validations && controlValue) {
    validations.forEach((validation: ControlValidation) => {
      const validationCheck = validate(controlValue.toString(), validation.validationType);
      if (!validationCheck) {
        const error = createFormControlError(validation.errorMessage);
        if (validity.isValid) {
          validity.isValid = false;
        }
        validity.errors.push(error);
      }
    });
  }

  return validity;
};

export const startsWithLetterOrNumberValidation = (label: string) => ({
  validationType: ValidationType.StartWithLetterOrNumber,
  errorMessage: lang.validationMessageStartWithInvalidChar(label),
});

export const startsWithLetterValidation = (label: string) => ({
  validationType: ValidationType.StartWithLetter,
  errorMessage: lang.validationMessageStartWithInvalidChar(label),
});

export const validationArray = (
  invalidCharsValidationType: ValidationType,
  maxCharsValidationType: ValidationType,
  label: string,
  numberMaxChars: number,
  startsWithSpecialSymbol: boolean = true,
): ControlValidation[] => [
  ...(startsWithSpecialSymbol ? [ startsWithLetterValidation(label) ] : []),
  {
    validationType: invalidCharsValidationType,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  },
  {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  },
  {
    validationType: maxCharsValidationType,
    errorMessage: lang.validationMessageMaxCharacters(label, numberMaxChars),
  },
];

export const validationArrayName = (name: string) => validationArray(
  ValidationType.InvalidCharactersName,
  ValidationType.MaxCharactersName,
  name,
  128,
);

export const validationArrayCity = (label: string) => validationArray(
  ValidationType.InvalidCharactersCity,
  ValidationType.MaxCharactersCity,
  label,
  64,
);

export const validationArrayAddress = (label: string) => ([
  {
    validationType: ValidationType.InvalidCharactersAddress,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.NotOnlyNumbers,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MaxCharactersTaxNumber,
    errorMessage: lang.validationMessageMaxCharacters(label, 512),
  },
]);

const taxNumberLabel = lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber();
export const validationArrayTaxNumber = [ {
  validationType: ValidationType.InvalidCharactersTaxNumber,
  errorMessage: lang.validationMessageInvalidCharacters(taxNumberLabel),
}, {
  validationType: ValidationType.MinCharactersTaxNumber,
  errorMessage: lang.validationMessageMinCharacters(taxNumberLabel, 5),
}, {
  validationType: ValidationType.MaxCharactersTaxNumber,
  errorMessage: lang.validationMessageMaxCharacters(taxNumberLabel, 64),
} ];

export const validationArrayEmail = [
  {
    validationType: ValidationType.InvalidCharactersEmail,
    errorMessage: lang.validationMessageInvalidCharacters(lang.commonOnboardingSignInEmail()),
  },
  {
    validationType: ValidationType.Email,
    errorMessage: lang.commonOnboardingEmailConfirmInvalidEmailMessage(),
  },
  {
    validationType: ValidationType.MaxCharactersEmailAddress,
    errorMessage: lang.commonOnboardingEmailConfirmInvalidEmailMessage(),
  },
];

export const getEmailValidity = (email: string) => {
  const label = lang.commonOnboardingSignInEmail();
  return validateControl(email, validationArrayEmail, label, true);
};

export const getTaxNumberValidity = (taxNumber: string) => {
  const label = lang.commonOnboardingPersonalDetailsTaxDetailsTaxNumber();
  return validateControl(taxNumber, validationArrayTaxNumber, label, true);
};

export const getDateOfBirthValidity = (birthDate: string) => {
  const label = lang.commonOnboardingPersonalDetailsDateOfBirth();
  const validations = [ {
    validationType: ValidationType.UnderEighteen,
    errorMessage: lang.commonOnboardingPersonalDetailsUnderEighteenError(),
  } ];
  return validateControl(birthDate, validations, label, true);
};

export const getPlaceOfBirthValidity = (birthDate: string) => {
  const label = lang.commonOnboardingPersonalDetailsPlaceOfBirth();
  const validations = validationArrayCity(label);
  return validateControl(birthDate, validations, label, true);
};

const getNameValidity = (name: string, label: string, isRequired: boolean = true) => {
  const validations = validationArrayName(label);
  return validateControl(name, validations, label, isRequired);
};

export const getBankNameValidity = (name: string) => {
  const label = lang.mobileAddBankAccInputBank();
  const validations = validationArray(
    ValidationType.InvalidCharactersCompanyName,
    ValidationType.MaxCharactersName,
    label,
    128,
  );

  return validateControl(name, validations, label, true);
};

export const getFirstNameValidity = (name: string) => {
  const label = lang.commonOnboardingPersonalDetailsFirstName();
  return getNameValidity(name, label);
};

export const getMiddleNameValidity = (name: string) => {
  const label = lang.commonOnboardingPersonalDetailsMiddleName();
  return getNameValidity(name, label, false);
};

export const getLastNameValidity = (name: string) => {
  const label = lang.commonOnboardingPersonalDetailsLastName();
  return getNameValidity(name, label);
};

export const getNameOfLenderValidity = (name: string) => {
  const label = lang.commonSourceOfFundsLenderNameLabel();
  return getNameValidity(name, label);
};

export const getPostCodeValidity = (value: string) => {
  const code = lang.commonOnboardingPersonalDetailsPostCode();
  const validations = validationArray(
    ValidationType.InvalidCharactersPostCode,
    ValidationType.MaxCharactersPostCode,
    code,
    32,
    false,
  );
  const allValidations = [ startsWithLetterOrNumberValidation(code), ...validations ];
  return validateControl(value, allValidations, code, true);
};

export const getCityValidity = (value: string) => {
  const label = lang.commonOnboardingPersonalDetailsCity();
  const validations = validationArrayCity(label);
  return validateControl(value, validations, label, true);
};

export const getAddressValidity = (value: string) => {
  const label = lang.commonOnboardingPersonalDetailsAddress();
  const validations = validationArrayAddress(label);
  return validateControl(value, validations, label, true);
};

export const getDocumentNumberValidity = (documentNumber: string) => {
  const label = lang.commonOnboardingPersonalDetailsDocumentNumber();
  const validations = validationArray(
    ValidationType.InvalidCharactersIDDocument,
    ValidationType.MaxCharactersIDDocument,
    label,
    10,
    false,
  );
  const allValidations = [ startsWithLetterOrNumberValidation(label), ...validations ];
  return validateControl(documentNumber, allValidations, label, true);
};

export const getBicSwiftValidity = (value: string) => {
  const label = lang.mobileAddBankAccInputBicSwift();
  const validations = [
    {
      validationType: ValidationType.SwiftCode,
      errorMessage: lang.commonValidationTextInvalid(label),
    },
    {
      validationType: ValidationType.MinCharacters,
      errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
    },
  ];
  return validateControl(value.toUpperCase(), validations, label, true);
};

export const getIbanValidity = (value: string) => {
  const label = lang.mobileAddBankAccInputIBAN();
  const validations = [
    {
      validationType: ValidationType.EmptyString,
      errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
    },
    {
      validationType: ValidationType.MinNineChars,
      errorMessage: lang.validationMessageMinCharacters(label, 9),
    },
    {
      validationType: ValidationType.MaxThirtyFourChars,
      errorMessage: lang.validationMessageMaxCharacters(label, 34),
    },
    {
      validationType: ValidationType.AlphaNumeric,
      errorMessage: lang.validationMessageInvalidCharacters(label),
    },
  ];
  return validateControl(value, validations, label, true);
};

export const getDocumentNumberExpireDateValidity = (dateOfExpire: string, isRequired: boolean = false) => {
  const label = lang.commonOnboardingPersonalDetailsDateOfExpiry();
  return validateControl(dateOfExpire, [], label, isRequired);
};

export const getPhoneNumberValidity = (phoneValue: string) => {
  const label = lang.commonOnboardingPhoneVerificationPhoneNumber();
  const validations = [ {
    validationType: ValidationType.MinCharactersPhoneNumber,
    errorMessage: lang.validationMessageMinCharacters(label, 4),
  }, {
    validationType: ValidationType.MaxCharactersPhoneNumber,
    errorMessage: lang.validationMessageMaxCharacters(label, 12),
  }, {
    validationType: ValidationType.AlphaNumeric,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  } ];
  return validateControl(phoneValue, validations, label, true);
};

export const getBrokerNameValidity = (phoneValue: string) => {
  const label = lang.commonSourceOfFundsBrokerNameLabel();
  const validations = [ {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(phoneValue, validations, label, true);
};

export const getNameOfEmployerValidity = (name: string) => {
  const label = lang.commonSourceOfFundsNameOfEmplLabel();
  const validations = [ {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(name, validations, label, true);
};

export const getNameOfCustomerValidity = (name: string) => {
  const label = lang.commonSourceOfFundsNameOfCustomer();
  const validations = [ {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(name, validations, label, true);
};

export const getAddressOfEmployerValidity = (address: string) => {
  const label = lang.commonSourceOfFundsAddressOfEmplLabel();
  const validations = validationArrayAddress(label);
  return validateControl(address, validations, label, true);
};

export const getInvestmentAndSavingsOtherValidity = (value: string) => {
  const label = lang.commonSourceOfFundsInvestmentSavingsTypesPleaseSpecify();
  const validations = [ {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(value, validations, label, true);
};

export const getYearOfInheritanceValidity = (year: string) => {
  const label = lang.commonSourceOfFundsYearOfLabel();
  const validations = [ {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  }, {
    validationType: ValidationType.MinCharacters4,
    errorMessage: lang.validationMessageMinCharacters(label, 4),
  }, {
    validationType: ValidationType.MaxCharacters4,
    errorMessage: lang.validationMessageMaxCharacters(label, 4),
  } ];
  return validateControl(year, validations, label, true);
};

export const getDeceasedPersonNameValidity = (phoneValue: string) => {
  const label = lang.commonSourceOfFundsNameOfDeceasedLabel();
  const validations = [ {
    validationType: ValidationType.StartWithLetter,
    errorMessage: lang.validationMessageStartWithInvalidChar(label),
  }, {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(phoneValue, validations, label, true);
};

export const getDeceasedPersonBirthDateValidity = (year: string) => {
  const label = lang.commonSourceOfFundsBirthYearLabel();
  const validations = [ {
    validationType: ValidationType.MinCharacters4,
    errorMessage: lang.validationMessageMinCharacters(label, 4),
  }, {
    validationType: ValidationType.MaxCharacters4,
    errorMessage: lang.validationMessageMaxCharacters(label, 4),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(year, validations, label, true);
};

export const getFamilyMemberNameValidity = (name: string) => {
  const label = lang.commonSourceOfFundsFamMemberNameLabel();
  const validations = [ {
    validationType: ValidationType.InvalidCharactersName,
    errorMessage: lang.validationMessageInvalidCharacters(label),
  }, {
    validationType: ValidationType.MaxCharacters400,
    errorMessage: lang.validationMessageMaxCharacters(label, 400),
  }, {
    validationType: ValidationType.MinCharacters,
    errorMessage: lang.validationMessageMinCharacters(label, 2),
  }, {
    validationType: ValidationType.EmptyString,
    errorMessage: lang.commonOnboardingGenericFieldIsRequired(label),
  } ];
  return validateControl(name, validations, label, true);
};

export const getSmsCodeValidity = (smsCode: string) => {
  const label = lang.commonOnboardingPhoneVerificationSMSCodeControlLabel();
  return validateControl(smsCode, [], label, true);
};

export const getDocumentDateOfExpireValidity = (expireDate: string) => {
  const isValid = expireDate !== '' && moment(expireDate).add(-30, 'day').isAfter(moment());
  const validity: ControlValidity = {
    isValid,
    errors: [
      {
        id: moment().unix(),
        error: lang.commonOnboardingPersonalDetailsDocumentExpireDateError(),
      },
    ],
  };
  return validity;
};

export const getErrorMessage = (errors: ControlError[]) => {
  const error = errors.find(err => err);
  return error?.error ?? '';
};
