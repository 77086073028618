import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import TradePriceCache from '../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../lib/util/DataHelpers';
import { calculatePriceMovement } from '../../lib/util/lastPriceChangeHelpers';
import { PriceMovement } from '../../lib/util/types';

import { useIsMobile } from '../../hooks';
import { UI_ROUTES } from '../../util/routes';
import ChartLine from '../Chart/ChartLine/ChartLine';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

import styles from './MarketTableItem.module.scss';

type Props = {
  symbol: string;
  logo?: string;
  name?: string;
}
let priceMovement: PriceMovement;

const MarketTableItem = ({ symbol, logo, name }: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const tradeCache = TradePriceCache.use(symbol, 'MarketTableItem');
  const { direction, change, changePercentWithSign, currentPrice } = tradeCache;
  priceMovement = calculatePriceMovement(currentPrice, symbol);

  if (!change || !changePercentWithSign || !currentPrice) {
    return null;
  }

  return (
    <tr className={styles.wrapperRow} key={symbol} onClick={() => history.push(`${UI_ROUTES.symbol}/${symbol}`)}>
      <td className={styles.columnSymbolName}>
        <div className={styles.wrapperContent}>
          <div className={styles.logoWrapper}>
            <CompanyLogo logo={logo || ''} symbol={symbol} />
          </div>
          <div className={styles.companyInfo}>
            <span className={styles.symbol}>{symbol}</span>
            <span className={styles.companyName}>
              {isMobile && name && name.length > 14
                ? `${name.slice(0, 14)}...`
                : name}
            </span>
          </div>
        </div>
      </td>
      <td className={styles.columnChart}><ChartLine symbol={symbol} /></td>
      { !isMobile && (
        <>
          <td className={styles.columnLastPrice}>
            <span className={classNames(styles.currentPrice, {
              [styles.negativeChange]: priceMovement === 'down',
              [styles.positiveChange]: priceMovement === 'up',
            })}
            >
              {formatCurrency(currentPrice)}
            </span>
          </td>
          <td className={styles.columnChange}>
            {
              <span className={direction === '-' ? styles.priceTextMinus : styles.priceTextPlus}>
                {direction === '+' ? `+${formatCurrency(change)}` : formatCurrency(change)}
              </span>
                  }
          </td>
        </>
      )}
      <td className={styles.columnPercentChange}>
        { isMobile && (
          <div className={classNames(styles.currentPrice, {
            [styles.negativeChange]: priceMovement === 'down',
            [styles.positiveChange]: priceMovement === 'up',
          })}
          >
            {formatCurrency(currentPrice)}
          </div>
        )}
        {
          <div className={direction === '-' ? styles.priceTextMinus : styles.priceTextPlus}>
            {changePercentWithSign}
          </div>
        }
      </td>
    </tr>
  );
};

export default MarketTableItem;
