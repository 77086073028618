import { ApiOkResponse } from 'apisauce';
import { clone, isArray, isBoolean, isNumber, isObject, isString } from 'lodash';
import { Observer } from 'rxjs';

import { CallStatus, HttpStatusCodeEnum } from '../enums';
import lang from '../language';
import { GWQueryCase, GWQueryResultStatus } from '../models/gateway/types';
import { QueryResponseMessageByResultCode, QueryResponseMessageByStatus } from '../util/GatewayHelpers';

import { SymbolLite } from './market-data/types';
import { OrderInfoState } from './trading/types';
import { ErrorPayload, logout, SuccessPayload } from './common-actions';

export const errorNoInstance = 'No client instance';
export const errorCaught = 'Error occurred while getting ';

let hasLoggedOutBecauseOfToken = false;

export const processErrorInReducer = (state: any, action: any) => {
  if (!action.payload) {
    state.error = 'Action payload is empty';
    state.errorData = action;
    return;
  }

  const { error, errorData } = action.payload;
  state.error = error;
  state.errorData = errorData;
};

export const processErrorInResponse = (
  callName: string,
  error: any,
  action: any,
  observer: Observer<any>,
  failedAction?: any,
  customData?: any,
  customPayload?: any,
) => {
  const anyError = isArray(error) ? (error as any)[0] : (error as any);
  const theError = anyError?.error ?? anyError?.originalError;
  console.error(
    `[processErrorInResponse] [call: ${callName}] ${isString(error) ? error : theError?.message}`,
    { callName, error },
  );

  let errorData: ErrorPayload | any;

  if (customPayload) {
    errorData = customPayload;
  } else {
    errorData = {
      callName: `${callName}`,
      error: `
        ${errorCaught} ${callName}, 
        action: ${action.type}${!theError?.message ? '' : (` (${theError?.message})`)}
      `,
      errorData: { action, error },
      errorStr: `[processErrorInResponse] [call: ${callName}] ${isObject(error) ? anyError?.message : error}`,
    };

    if (!customPayload && customData) {
      errorData.customData = customData;
    }
  }

  if (failedAction) observer.next(failedAction(errorData));
};

export const messageAndButtonByStatusCode = (statusCode: number) => {
  if (statusCode >= HttpStatusCodeEnum.BadRequest && statusCode < HttpStatusCodeEnum.InternalServerError) {
    return { message: lang.requestErrorOver400(), buttonText: lang.commonButtonContactSupport().replace(/\.+$/, '') };
  }

  return { message: lang.requestErrorOver500(), buttonText: lang.commonButtonsTryAgain() };
};

// TODO: Refactor processResponse - it became too big, mostly because of Gateway cases
//       Consider chucking into into smaller pieces
//       Consider passing a success processing function rather than
//       custom customSuccessPayload, customFailPayload etc.
/**
 * Processes successful response - checking for status value
 * @param callName The name of the call - for any statistical, logging, or debugging purposes
 * @param requestParams Request payload
 * @param response Response body
 * @param observer The observer to use for emitting next action
 * @param successAction Action to dispatch if successfull
 * @param failAction Action to dispatch in error casse
 * @param isWebSocket If this is a websocket response
 * @param customData If customData is needed by success/fail action
 * @param customSuccessPayload Custom success payload
 * @param customFailPayload Custom fail payload
 * @param parserFunction Custom parsing function (not implemented)
 * @param excludedErrorCodes For example if `excludedErrorCodes` is `{ 404: [] }` - on 404 error success action will be dispatched with `[]` as payload
 */
export const processResponse = (
  callName: string,
  requestParams: any,
  response: any,
  observer: Observer<any>,
  successAction?: any,
  failAction?: any,
  isWebSocket = false, // eslint-disable-line default-param-last
  customData?: any,
  customSuccessPayload?: any,
  customFailPayload?: any,
  parserFunction?: (typeof Function) | null,
  excludedErrorCodes?: Record<number, any>,
  updateCacheBeforeSuccessActionDispatchFunction?: (data: any) => void,
  updateCacheAfterSuccessActionDispatchFunction?: (data: any) => void,
): boolean => {
  if (!response) {
    if (customFailPayload) {
      if (failAction) observer.next(failAction(customFailPayload));
    } else {
      const error = new Error(`[${callName}] Response is empty`);
      const processedData: ErrorPayload = {
        callName: `${callName}`,
        status: undefined,
        error,
        errorData: { requestParams, response },
        errorStr: `${error.message}`,
        requestParams,
        customData,
      };
      printError(`${callName}`, error.message, processedData);
      if (failAction) observer.next(failAction(processedData));
    }
    return false;
  } if (response?.problem === 'TIMEOUT_ERROR') {
    const error = new Error(`[${callName}] Response is empty`);
    const processedData: ErrorPayload = {
      callName: `${callName}`,
      status: CallStatus.ERROR,
      error,
      errorData: { requestParams, response },
      errorStr: `${error.message}`,
      requestParams,
      customData,
    };
    if (failAction) observer.next(failAction(processedData));
    return false;
  }

  let {
    data, status, problem, rc,
  } = response;
  if (!status) status = data?.status;
  if (!status && rc != null) status = rc;
  if (response?.originalError?.response?.status) status = response?.originalError?.response?.status;
  const { resultCode } = data || {};
  let resultOk = (resultCode == null || resultCode === GWQueryResultStatus.READY);
  const isGateway = callName.includes('gateway');
  let queryCaseName;
  if (isGateway && callName.includes('query')) {
    queryCaseName = GWQueryCase[customData?.cacheData?.queryCase];
  }

  if (status === HttpStatusCodeEnum.Unauthorized && callName.includes('crm/individualExtendedInfo')) {
    if (!hasLoggedOutBecauseOfToken) {
      observer.next(logout());
      hasLoggedOutBecauseOfToken = true;
    }
    console.warn(`[store/helpers] processResponse - token expired (or not valid) (${status}, ${problem})`);
    return false;
  }

  const hasError = (
    (
      problem
      || (status && status > 299)
      || (isGateway && (![
        GWQueryResultStatus.READY,
        GWQueryResultStatus.STILL_IN_PROCESSING,
      ].includes(status) || !resultOk))
    )
    && (!excludedErrorCodes || excludedErrorCodes[status] == null)
  );

  if (!customSuccessPayload && excludedErrorCodes?.[status] != null) {
    customSuccessPayload = excludedErrorCodes?.[status]; // eslint-disable-line no-param-reassign
  }

  if (hasError) {
    let title;
    if (isGateway) {
      if (resultCode != null) {
        title = QueryResponseMessageByResultCode[resultCode];
      } else {
        title = QueryResponseMessageByStatus[status];
      }
    } else {
      title = data?.title || problem || 'Unknown error';
    }
    const details = `${status}${problem ? ` - ${problem}` : ''}${resultCode ? `/${resultCode}` : ''}`;
    const error = new Error(`[${callName}] ${title} (${details})`);
    const processedData: ErrorPayload = {
      callName: `${callName}`,
      status,
      resultCode,
      error,
      errorData: response,
      errorStr: `${error.message}`,
      requestParams,
      customData,
    };
    printError(`${callName}`, error.message, processedData, queryCaseName);

    if (customFailPayload) {
      if (failAction) observer.next(failAction(customFailPayload));
    } else if (failAction) observer.next(failAction(processedData));

    return false;
  }

  if (!hasError && hasLoggedOutBecauseOfToken) {
    hasLoggedOutBecauseOfToken = false;
  }

  let resultData: SuccessPayload = (clone((isWebSocket ? response : response.data) ?? {}));
  if (isString(resultData) || isNumber(resultData) || isBoolean(resultData)) {
    resultData = {
      callName: `${callName}`, // fixes an error for `callName` being a constant
      status,
      data: resultData,
    };
  } else if (isArray(response) && response[0].data != null) {
    // This case fixes Promise.all responses, original @author Alex K although committer differs
    resultData = {
      callName: `${callName}`,
      status,
      data: response.map(({ data: itemData }) => itemData),
    };
  } else {
    // store the response status if the result data contains property 'status'
    if (resultData.status) {
      resultData.statusFromResponse = status;
    } else {
      resultData.status = status;
    }
    resultData.callName = `${callName}`;
  }
  if (customData) resultData.customData = customData;

  if (customSuccessPayload) {
    if (updateCacheBeforeSuccessActionDispatchFunction) updateCacheBeforeSuccessActionDispatchFunction(response.data);
    if (successAction) observer.next(successAction(customSuccessPayload));
    if (updateCacheAfterSuccessActionDispatchFunction) updateCacheAfterSuccessActionDispatchFunction(response.data);
  } else if (parserFunction != null) {
    try {
      if (updateCacheBeforeSuccessActionDispatchFunction) updateCacheBeforeSuccessActionDispatchFunction(response.data);
      if (successAction) observer.next(successAction(resultData));
      if (updateCacheAfterSuccessActionDispatchFunction) updateCacheAfterSuccessActionDispatchFunction(response.data);
    } catch (error: any) {
      const processedData: ErrorPayload = {
        callName: `${callName}`,
        status,
        resultCode,
        error,
        errorData: response,
        errorStr: `Error in parseFunction: ${error.message}`,
        customData,
      };
      printError(`${callName}`, `error in parseFunction: ${error.message}`, processedData, queryCaseName);

      if (customFailPayload) {
        if (failAction) observer.next(failAction(customFailPayload));
      } else if (failAction) observer.next(failAction(processedData));
      return false;
    }
  } else {
    if (updateCacheBeforeSuccessActionDispatchFunction) updateCacheBeforeSuccessActionDispatchFunction(response.data);
    if (successAction) observer.next(successAction(resultData));
    if (updateCacheAfterSuccessActionDispatchFunction) updateCacheAfterSuccessActionDispatchFunction(response.data);
  }

  return true;
};

export function createSuccessResponse<T>(data: T): ApiOkResponse<T> {
  return {
    ok: true,
    problem: null,
    originalError: null,
    data,
    status: HttpStatusCodeEnum.Ok,
  };
}

/**
 * Prints an error handled in processResponse
 * @param callName The name of the call where the error happened
 * @param errorMessage Error message to print
 * @param data Extra data to print
 * @param queryCaseName Gateway query case if available/relevant
 */
function printError(callName: string, errorMessage: string, data?: any, queryCaseName?: string) {
  const name = queryCaseName ? `[GW-REST] Gateway query '${queryCaseName}'` : callName;
  console.error(`[store/helpers] ${name} response error - ${errorMessage}`, data);
}

/**
 * This data is used to help development of Order Ticket flow
 * figuring out each order cycle.
 * Data is used by `SettingsScreen` in mobile app
 * For more details see:
 *   (1) `store/gateway/index.ts` - orderInfo
 *   (2) `store/trading/index.ts` - orderInfo
 *   (3) `store-util/OrderInfoCache.ts`
 *   (4) `SettingsScreen.tsx`
 */
export function addOrderInfoLog(state: OrderInfoState, action: string, message: string, data?: any) {
  const time = new Date().toISOString();
  state.orderInfo.push({ time, action, message, data: JSON.stringify(data) });
  if (state.orderInfo.length > 20) state.orderInfo.shift();
}

export const isSymbolInFavourites = (favouriteStocks: SymbolLite[], symbol: string) => (
  !!favouriteStocks.find(el => el.symbol === symbol)
);
