import { ChartDataSet } from './types';

export const EMPTY_CHART_DATA = (isValid: boolean) => ({
  isValid,
  lineDiff: 0,
  minLineValue: 0,
  lineData: [],
  barData: [],
  rawData: [],
  scatterLabels: [ [] ],
  axisValue: [],
  yLabelPoints: [],
  step: 0,
  lineDataRatio: 0,
  lastClose: 0,
  tickValuesWeb: [],
  maxChartPointWeb: 0,
}) as ChartDataSet;

export const ONE = 1;
export const TEN = 10;
export const HUNDRED = 100;
export const THOUSAND = 1000;
export const TEN_THOUSAND = 10000;
export const HUNDRED_THOUSAND = 100000;
export const ONE_TENTH = 0.1;
export const ONE_HUNDREDTH = 0.01;
export const ONE_THOUSANDTH = 0.001;

export const HUNDRED_THOUSANDS_BOUNDARY = 250000;
export const TEN_THOUSANDS_BOUNDARY = 25000;
export const THOUSAND_BOUNDARY = 2500;
export const HUNDREDS_BOUNDARY = 250;
export const TENS_BOUNDARY = 25;
export const ONES_BOUNDARY = 2.5;
export const ONE_TENTHS_BOUNDARY = 0.25;

export const MAX_VALUE = 100;
export const SPACE_ABOVE = 0.05;
export const LINE_DATA_RATIO = 0.33;
export const VOLUME_RATIO = 0.3;
export const MAX_CHART_POINT_Y = MAX_VALUE * (1 + SPACE_ABOVE);
export const DAILY_MAX_DOMAIN = 78;

export const ONE_VALUE_MIN_BOUNDARY = 0.06;

export const DAILY_CHART_START_IN_MINUTES = 114;
export const DAILY_CHART_MINUTES = [ 132, 144, 156, 168, 180, 192 ];
export const DAILY_CHART_HOURS = [ '09:30', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00' ];
