import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { processErrorInReducer } from '../helpers';

export interface ReportingFileState {
   file: string;
   isGettingFile: boolean;
   isGettingFileFailed: boolean;
 }

export const INITIAL_STATE: ReportingFileState = {
  file: '',
  isGettingFile: false,
  isGettingFileFailed: false,
};

const reportingFileSlice = createSlice({
  name: 'file',
  initialState: INITIAL_STATE,
  reducers: {
    getReportingFileByOrder(state: ReportingFileState, action: PayloadAction<string>) {
      state.isGettingFile = false;
      state.isGettingFileFailed = false;
    },
    getReportingFileByOrderSuccess(state: ReportingFileState, action) {
      state.isGettingFile = true;
      state.isGettingFileFailed = false;

      const { data } = action.payload;
      state.file = data;
    },
    getReportingFileByOrderFailed(state: ReportingFileState, action) {
      state.isGettingFile = true;
      state.isGettingFileFailed = true;
      processErrorInReducer(state, action);
    },
  },
  extraReducers: {},
});

export const {
  getReportingFileByOrder,
  getReportingFileByOrderSuccess,
  getReportingFileByOrderFailed,
} = reportingFileSlice.actions;

export default reportingFileSlice.reducer;
