import { TypedObject } from './types';

function normaliseMultpartKey(key: string) {
  switch (key) {
    case 'bankName': return 'AccountInfo.BankName';
    case 'bankCountryId': return 'AccountInfo.BankCountryId';
    case 'bic': return 'AccountInfo.Bic';
    case 'iban': return 'AccountInfo.Iban';
    case 'account': return 'AccountInfo.Account';

    default: return key;
  }
}

function normaliseMultpartValue(key: string, value: any) {
  switch (key) {
    case 'file': return value;

    default: return `${value}`;
  }
}

function appendToMultipartData(
  isWeb: boolean,
  sourceObject: TypedObject,
  resultData: any,
  skipProps: string[] = [],
) {
  for (const prop in sourceObject) {
    if (!skipProps.includes(prop)) {
      const key = normaliseMultpartKey(prop);
      const value = normaliseMultpartValue(prop, sourceObject[prop]);
      if (isWeb) {
        resultData.append(key, value);
      } else {
        resultData.push({ name: key, data: value });
      }
    }
  }
}

/**
 * Parses an object into a multipart request body data.
 * The returned result is: for Web - FormData, for Mobile - an Array.
 * All `object` properties are converted to `string` excluding `files`.
 * For each item in `files` property of `object` is added a new item to the returned result.
 * If `skipProps` is populated - respective properties are not included in the result.
 * @param object The input data object
 * @param fileNames If object contains files - use these as filenames. If it doesn't - empty array `[]` needs to be supplied
 * @param skipProps Props to skip
 * @returns Multipart-ready request body
 */
export function parseToMultipartData(
  callType: 'poi-or-poa' | 'bank-account',
  object: TypedObject,
  fileNames: string[],
  skipProps: string[] = [ 'files', 'file' ],
) {
  const { isWeb, FormData } = require('../../configLib').default;
  const hasFilesProp = Object.keys(object).includes('files');
  const hasFileProp = Object.keys(object).includes('file');
  let result: any = isWeb ? new FormData() : [];
  let isBankAccountStatement = callType === 'bank-account';

  appendToMultipartData(isWeb, object, result, skipProps);

  if (hasFilesProp) {
    // `files` are sent as separate items each one with the same key - `files`
    const key = 'files';
    const value = object.files;
    if (isWeb) {
      // Web
      (value as Array<any>).forEach(fileData => {
        result.append(key, fileData);
      });
    } else {
      // Mobile
      (value as Array<any>).forEach(({ data }, index) => {
        result.push({ name: key, filename: fileNames[index], data });
      });
    }
  }

  if (hasFileProp) {
    // `files` are sent as separate items each one with the same key - `files`
    const key = 'file';
    const fileData = object.file;
    if (isWeb) {
      // Web
      result.append(key, fileData);
    } else {
      // Mobile
      const { filename, data } = fileData;
      result.push({ name: key, filename, data });
    }
  }

  if (isBankAccountStatement) {
    const extraParams = {
      isCompanyRelated: 'false',
      'AccountInfo.BankAddress': '',
    };
    appendToMultipartData(isWeb, extraParams, result);
  }

  return result;
}
