import { FileExtensionEnum } from '../enums';
import lang from '../language';
import { MDSFeed, OHLCV } from '../models/market-data/types';
import { SubscribeGroup } from '../store/market-data/types';
import { NetIncomeComponentsChartData } from '../store/reporting/types';

export const INVALID_VALUE: string = lang?.commonInvalidValue();
export const INVALID_YEAR = -1;
export const INVALID_DATE_STRING = 'Invalid Date';
export const INVALID_TIME = -1;
export const ZERO_PERCENT = '0.00%';
export const ZERO_VALUE = '0.00';
export type NullableNumber = number | null | undefined;
export type NullableString = string | null | undefined;

export type LogFilter = boolean | string | RegExp

export type TronFilters = {
  t: LogFilter
  md: LogFilter
  gw: LogFilter
}

/**
 * @deprecated please use Record instead of this
 */
export type TypedObject<T = any> = {
  [key: string]: T
}

export const AllowedFileExtensions = [
  FileExtensionEnum.Jpg,
  FileExtensionEnum.Jpeg,
  FileExtensionEnum.Png,
];

export type WebAuthUserData = {
  expires_at?: number
  accessToken?: string
  refreshToken?: string
}

export type VerifyIdentityStatus = {
  finished: boolean,
  disabled: boolean,
  pending: boolean
}

export type FileSizeRanges = 'KB' | 'MB' | 'GB';
export type MarketDataWorkingHoursAsNumber = {
  startTime: number,
  endTime: number,
  /** End of after market trading hours */
  endAfter?: NullableNumber,
};
export type MarketDataWorkingHoursAsString = {
  startTime: string,
  endTime: string,
  /** End of after market trading hours */
  endAfter?: NullableString,
};
export type MarketDataWorkingHours = MarketDataWorkingHoursAsNumber | MarketDataWorkingHoursAsString;

export interface PieData {
  x: string;
  y: number;
}

/**
 * Components relevant to Order Ticket price update subscribe flows
 * For example:
 *   - 'watchlist' - the watchlist component has to be subscribed to relevant symbols
 *   - 'funds' - this one doesn't have any symbols - unsubscribe any previously subscribed symbols
 *   - 'symbol-details' - only the symbol selected (and possibly people also trade) need to be subscribed
 * etc.
 * */
export type OrderTicketComponent = (
  SubscribeGroup
  | 'watchlist/favorites'
  | 'watchlist/popular'
  | 'discover/top-movers'
  | 'discover/top-gainers-all'
  | 'discover/top-losers-all'
  | 'discover/new-on-the-market'
  | 'discover/popular-group'
  | 'my-account/positions'
  | 'my-account/orders'
  | 'my-account/account-breakdown'
  | 'funds'
  | 'profile'
  | 'people-also-trade'
  | 'chart-details'
  | null | undefined
);

export type SubscribedComponent = (
  'initial'
  | 'newOnTheMarket'
  | 'symbolDetails'
  | 'createOrder'
  | 'popularGroup'
)

export type CombinedOHLCVOrNetIncomeChartData = (OHLCV | NetIncomeComponentsChartData)
export type CombinedOHLCVOrNetIncomeChartDataProperty = keyof OHLCV | keyof NetIncomeComponentsChartData

export type PriceMovement = 'equal' | 'down' | 'up';
export type WatchlistPriceValue = TypedObject<number>;
export type WatchlistPriceMovement = TypedObject<PriceMovement>;

export type SubscriptonData = {
  symbols: string[];
  subscribedByFeed: Partial<Record<Extract<MDSFeed, string>, string[]>>;
  skipCase: boolean;
  skipUnsubscribe: boolean;
  reInitializeGroup: boolean;
  hasItems: boolean;
  isAllLoaded: boolean;
};
