import React from 'react';

import lang from '../../lib/language';

import IconAlert from '../../assets/img/icon-exclamation-mark-warning.svg';
import BaseModal from '../BaseModal/BaseModal';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button } from '../index';

import styles from './ShortSellOrderPlace.module.scss';

type Props = {
  processModal: () => void;
  toggleModal: () => void;
}

const ShortSellOrderPlace = ({ toggleModal, processModal }: Props) => (
  <BaseModal isOpen>
    <h6 className={styles.title}>{lang.commonTradingShortSellDialog()}</h6>
    <div className={styles.content}>
      <div className={styles.generalInfo}>{lang.commonTradingShortSellDialogDescription()}</div>
      <div className={styles.timeAlert}>
        <img className={styles.reportsIcon} src={IconAlert} alt="short sell open hours alert" />
        <span className={styles.info}>{lang.commonTradingShortSellDialogDescriptionWeb()}</span>
      </div>
    </div>
    <div className={styles.actions}>
      <Button
        fullWidth
        variant={ButtonTypesEnum.Tertiary}
        className={styles.cancelBtn}
        onClick={toggleModal}
      >
        {lang.commonCancelButton()}
      </Button>
      <Button
        fullWidth
        className={styles.okBtn}
        onClick={processModal}
      >
        {lang.commonOkay()}
      </Button>
    </div>
  </BaseModal>
);

export default ShortSellOrderPlace;
