import React from 'react';

import lang from '../../lib/language';

import documentsLogo from '../../assets/img/icon-documents.svg';
import helpCenterLogo from '../../assets/img/icon-help.svg';
import logoutLogo from '../../assets/img/icon-logout.svg';
import tradesLogo from '../../assets/img/icon-orders-trades.svg';
import personalDetailsLogo from '../../assets/img/icon-personal-details.svg';
import reportsLogo from '../../assets/img/icon-reports.svg';
import securityLogo from '../../assets/img/icon-security.svg';
import { UI_ROUTES } from '../../util/routes';

import { MyProfileItem } from './MyProfile.types';

export const myProfileIds = {
  personalDetails: 'PersonalDetails',
  reports: 'Reports',
  tradingHistory: 'TradeHistory',
  documents: 'Documents',
  security: 'Security',
  helpCenter: 'HelpCenter',
  logout: 'Logout',
};

export const MENU_LIST: MyProfileItem[] = [
  {
    id: myProfileIds.personalDetails,
    label: lang.myProfilePersonalDetails(),
    path: UI_ROUTES.myAccountPersonalDetails,
    img: personalDetailsLogo,
  },
  // TODO: use it HW-13
  // {
  //   id: myProfileIds.reports,
  //   label: lang.myProfileReports(),
  //   path: UI_ROUTES.myAccountReports,
  //   img: reportsLogo,
  // },
  {
    id: myProfileIds.tradingHistory,
    label: lang.commonTradeHistoryTitle(),
    path: UI_ROUTES.tradeHistory,
    img: tradesLogo,
  },
  {
    id: myProfileIds.documents,
    label: lang.myProfileDocuments(),
    path: UI_ROUTES.myAccountDocuments,
    img: documentsLogo,
  },
  {
    id: myProfileIds.security,
    label: lang.mobileProfileChangePassword(),
    img: securityLogo,
  },
  {
    id: myProfileIds.helpCenter,
    label: lang.myProfileHelpCenter(),
    path: '',
    img: helpCenterLogo,
  },
  {
    id: myProfileIds.logout,
    label: lang.myProfileLogout(),
    path: '',
    img: logoutLogo,
  },
];
