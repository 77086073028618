import React, { useState } from 'react';
import { VictoryLabel, VictoryPie } from 'victory';

import { PIE_CHART_WIDTH, PIE_CHART_WIDTH_HIGHLIGHT } from '../../../../lib/store/reporting/constants';
import { PortfolioChartElement } from '../../../../lib/store/reporting/types';
import { hammerBasicColors } from '../../../../lib/util/chart-colors';
import { useTimeout } from '../../../../lib/util/hooks/TimeoutHooks';
import { renderChartTextContent } from '../../../../lib/util/my-account-helpers';

type Props = {
  chartData: PortfolioChartElement[];
  totalStockValue?: number;
  selectedChartIndex?: PortfolioChartElement,
  setSelectedChartIndex: (chartData: PortfolioChartElement) => void;
}

const AcountBreakDownPieChart = (props: Props) => {
  const {
    chartData,
    totalStockValue,
    selectedChartIndex,
    setSelectedChartIndex,
  } = props;

  const [initialRender, setInitialRender] = useState<boolean>(false);

  // this is needed because of bug in VictoryPie chart animation when initial rendering is set
  // TODO: Set default preloader on the entire screen
  useTimeout(() => setInitialRender(true), 500);

  return (
    <>
      { initialRender && (
      <VictoryPie
        standalone={false}
        data={chartData.length ? chartData : [ { y: 1 } ]}
        height={290}
        startAngle={270}
        endAngle={630}
        animate={{ duration: 1000 }}
        radius={({ index }) => {
          if (chartData.length === 1) {
            return PIE_CHART_WIDTH;
          }

          return index === selectedChartIndex?.x ? PIE_CHART_WIDTH_HIGHLIGHT : PIE_CHART_WIDTH;
        }}
        innerRadius={90}
        labels={() => ''}
        colorScale={chartData.length ? hammerBasicColors : ['#F6F6F8']}
        style={{ data: { cursor: 'pointer' } }}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: (_, { datum, index }) => {
                if (chartData.length) {
                  // workaround for strange Victory bug
                  if (datum.symbol === 'rgb(255, 250, 250)') {
                    // eslint-disable-next-line no-param-reassign
                    datum.symbol = 'SNOW';
                  }
                  setSelectedChartIndex({ x: index, y: datum.y, symbol: datum.symbol });
                }
              },
            },
          },
        ]}
      />
      ) }
      <VictoryLabel
        textAnchor="middle"
        verticalAnchor="middle"
        backgroundPadding={[-10, 20]}
        style={[
          { fill: '#CBD5E0' },
          { fill: '#fff', fontWeight: '600', fontSize: 24, fontFamily: 'Source Sans Pro' }]}
        x={200}
        y={145}
        text={renderChartTextContent(
          chartData && chartData[0]?.symbol ? chartData.length : 0, selectedChartIndex, totalStockValue,
        )}
      />
    </>
  );
};

export default AcountBreakDownPieChart;
