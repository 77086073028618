import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { formatCurrency, formatNumber } from '../../../lib/util/DataHelpers';

import { UI_ROUTES } from '../../../util/routes';
import ClickableDiv from '../../ClickableDiv/ClickableDiv';
import CompanyLogo from '../../CompanyLogo/CompanyLogo';

import styles from './CompanyInfoMobile.module.scss';

type Props = {
    symbol: string;
    volume: number;
    marketCap: number;
    areDividends: boolean;
    prevCloseAndEPS: number;
    dividendRateAndRevenue: number;
    logo?: string;
    companyName?: string;
    }

const CompanyInfoMobile = (props: Props) => {
  const {
    logo,
    companyName,
    areDividends,
    symbol,
    volume,
    marketCap,
    prevCloseAndEPS,
    dividendRateAndRevenue,
  } = props;
  const history = useHistory();
  return (
    <div className={styles.companyInfo}>
      <ClickableDiv className={styles.companyLogoAndName} onClick={() => history.push(`${UI_ROUTES.symbol}/${symbol}`)}>
        {logo
      && (
      <CompanyLogo
        logo={logo}
        className={styles.logo}
        symbol={symbol}
      />
      )}
        <p className={styles.companyName}>{companyName}</p>
      </ClickableDiv>
      <div className={styles.companyStats}>
        <div className={styles.infoColumn}>
          <div className={styles.infoRow}>
            <p className={styles.key}>
              {
            areDividends
              ? lang.commonDiscoverInfoBoxDividendRate()
              : lang.commonDiscoverInfoBoxRevenue()
          }
            </p>
            <p className={styles.value}>{formatCurrency(dividendRateAndRevenue, false, true).toUpperCase()}</p>
          </div>
          <div className={styles.infoRow}>
            <p className={styles.key}>
              {
            areDividends
              ? lang.commonDiscoverInfoBoxVolume()
              : lang.commonStockDetailsStatsAvgVolume()
          }
            </p>
            <p className={styles.value}>{formatNumber(volume, false, true).toUpperCase()}</p>
          </div>
        </div>
        <div className={styles.infoColumn}>
          <div className={styles.infoRow}>
            <p className={styles.key}>
              {
            areDividends
              ? lang.commonDiscoverInfoBoxPrevClose()
              : lang.commonDiscoverInfoBoxEps()
          }
            </p>
            <p className={styles.value}>{formatCurrency(prevCloseAndEPS, false, true).toUpperCase()}</p>
          </div>
          <div className={styles.infoRow}>
            <p className={styles.key}>{lang.commonDiscoverInfoBoxMarketCap()}</p>
            <p className={styles.value}>{formatCurrency(marketCap, false, true).toUpperCase()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoMobile;
