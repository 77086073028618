import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { CurrencyEnum, FinancialQuestionnaireTypesEnum } from '../../../lib/enums';
import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import {
  EmploymentSatusData,
  ENROLL_METHOD,
  ENROLL_REQUEST_STATE,
  FinancialQuestionnaireOption,
  FinancialQuestionnaireSectionItem,
  FinancialQuestionnaireSubQuestion,
  FinancialQuestionnaireTypeData,
  IndividualFinancialQuestionnaire,
  PurposeOfTradingData,
  SourceOfFundsList,
} from '../../../lib/models/enroll';
import { RootState } from '../../../lib/store';
import { sendNonAppropriatenessEmail } from '../../../lib/store/auth/index';
import { enroll, setEnrollProgressInfo, setFinancialQuestionnaireCurrency, setSourceOfFundsIsEdit } from '../../../lib/store/crm';
import { parseFinancialQuestion } from '../../../lib/store/crm/helpers';
import { areGroupedQuestionAnswersCorrect, hasAnsweredLastQuestionnaire, hasCorrectAnswersForLastQuestionnaire } from '../../../lib/store/selectors';
import { skipPropsObject } from '../../../lib/util/DataHelpers';
import { trimObjectProperties } from '../../../lib/util/TextHelpers';
import { getAddressValidity, validateControl, validationArray, validationArrayAddress } from '../../../lib/util/ValidationSchemes/Validate';
import { ValidationType } from '../../../lib/util/ValidationSchemes/ValidationTypes';

import { RecoveryPhasePath } from '../../../App.constants';
import {
  EnrollProgressBarBackQuestionAction,
  EnrollProgressBarNextQuestionAction,
  EnrollProgressBarNextSectionAction,
} from '../../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';
import { UI_ROUTES } from '../../../util/routes';
import { ClickableDiv, RadioGroup } from '../../index';
import { IRadioGroupOption } from '../../RadioGroup/RadioGroup.types';
import AppropriatenessModal from '../AppropriatenessModal/AppropriatenessModal';
import EnrollIndustry from '../EnrollIndustry/EnrollIndustry';
import EnrollLayout from '../EnrollLayout/EnrollLayout';
import EnrollSourceOfFunds from '../EnrollSourceOfFunds/EnrollSourceOfFunds';
import { EnrollProgressBarData } from '../ProgressBar/ProggressBar.types';
import { convertSoFKeyToKey, getterConvertFactory, prepareSoFRequestBody, stateFactory } from '../sof-helpers';
import { SoFKey } from '../types';

import {
  GeneralQuestionsCustomAnswers,
  ValidationFieldName,
} from './EnrollGeneralQuesions.types';
import {
  FinancialQuestionsCurrencyOptions,
  KEY_EMPLOYMENT_STATUS_BUSINESS_ID,
  KEY_EMPLOYMENT_STATUS_ID,
  KEY_SOURCE_OF_FUND_ADDRESS,
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_ID,
  KEY_SOURCE_OF_FUND_IDENTITY_NAME,
  KEY_SOURCE_OF_FUND_TO,
  KEY_SOURCE_OF_FUND_TYPE,
} from './EnrollGeneralQuestions.constants';

import styles from './EnrollGeneralQuestions.module.scss';

type OwnProps = {
  progressBarData: EnrollProgressBarData;
  progressBarDispatch: Dispatch<{ type: string }>;

}
type Props = OwnProps & ReduxProps;

const investmentsAndSavingsOptions = lang.commonSourceOfFundsInvestmentSavingsTypes().split(',');
const KEY = convertSoFKeyToKey;

const nameValidation = (validationFieldName: string) => {
  switch (validationFieldName) {
    case ValidationFieldName.IndividualName: case ValidationFieldName.Inheritance: {
      return ValidationType.InvalidCharactersName;
    }
    case ValidationFieldName.InvestmentAndSavingsSpecify: {
      return ValidationType.InvestmentSavingSpecify;
    }
    default: return ValidationType.InvalidCharactersCompanyName;
  }
};

const EnrollGeneralQuestions = (props: Props): JSX.Element => {
  let employmentStatusId: number | null = null;

  const {
    requestState,
    selectedCurrency,
    progressBarData,
    enrollProgressInfo,
    progressBarDispatch,
    sendAnswers,
    setIsEdit,
    setEnrollProgress,
    setCurrency,
    parseQuestion,
    currentSoF,
    currentSoI,
    sections,
    individualFinancialQuestionnaires,
    hasLastAnswers,
    areLastAnswersCorrect,
    nonAppropriatenessEmail,
    isAppropriatenessPopupConfirmed,
  } = props;

  const history = useHistory();

  const [customQuestionAnswers, setCustomQuestionAnswers] = useState<GeneralQuestionsCustomAnswers>({
    [KEY('to')]: '',
    [KEY('from')]: '',
    [KEY('name')]: '',
    [KEY('type')]: '',
    [KEY('address')]: '',
    [KEY('birth')]: '',
    [KEY('year')]: '',
    [KEY('has-main')]: '',
    [KEY('specify-type')]: '',
  });
  const localStore = stateFactory(setCustomQuestionAnswers, customQuestionAnswers, 'GeneralQs');
  const setValue = localStore.set;
  const getValue = (key: SoFKey) => localStore.get(KEY(key)); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSourceOfFundChange = useCallback(
    (formKey: string, value: string | number): void => {
      if (!formKey) return;
      localStore.set(formKey, value);
    }, [localStore],
  );

  const [currentQuestion, setCurrentQuestion] = useState<FinancialQuestionnaireSectionItem | null>(null);
  const [employmentStatusAnswer, setEmploymentStatusAnswer] = useState<EmploymentSatusData | null>();
  const [sourceOfFundsAnswer, setSourceOfFundsAnswer] = useState<SourceOfFundsList | null>();
  const [errorLabelName, setErrorLabelName] = useState<string>('Field');
  const [validationFieldName, setValidationFieldName] = useState<string>(ValidationFieldName.InitialState);
  const [showAppropriatenessModal, setShowAppropriatenessModal] = useState<boolean>(false);
  const [isChangeCurrency, setIsChangeCurrency] = useState<boolean>(false);
  const [isDisabledButtonContinue, setIsDisabledButtonContinue] = useState<boolean>(false);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);

  const isQuestionEmploymentStatus = (
    !!currentQuestion
    && (currentQuestion.questionType === FinancialQuestionnaireTypesEnum.EmploymentStatus)
  );

  const isQuestionSourceOfFunds = (
    !!currentQuestion
    && (currentQuestion.questionType === FinancialQuestionnaireTypesEnum.SourceOfFund)
  );

  const visibleContinueButton = (): boolean => {
    if (!currentQuestion) {
      return false;
    }

    return (
      (currentQuestion.questionType === FinancialQuestionnaireTypesEnum.GroupedQuestion)
      || (isQuestionEmploymentStatus && !!employmentStatusAnswer)
      || (isQuestionSourceOfFunds && !!sourceOfFundsAnswer)
    );
  };

  const loadQuestion = useCallback((): void => {
    setCurrentQuestion(parseQuestion(progressBarData));
  }, [parseQuestion, progressBarData]);

  const goToNextQuestion = useCallback((): void => {
    setIsChangeCurrency(false);

    if (currentQuestion && (currentQuestion.questionType === FinancialQuestionnaireTypesEnum.TKLP)) {
      setEnrollProgress({ ...enrollProgressInfo, isOpenLastQuestions: true });
    }

    if (isQuestionEmploymentStatus) {
      sendAnswers(
        currentQuestion!.method!,
        currentQuestion!.getBody(
          currentSoI,
          employmentStatusAnswer ? employmentStatusAnswer.id : employmentStatusId,
          employmentStatusAnswer ? customQuestionAnswers[KEY_EMPLOYMENT_STATUS_BUSINESS_ID] : null,
        ),
      );
    }

    if (isQuestionSourceOfFunds) {
      const trimmedCustomQuestionAnswers = trimObjectProperties(customQuestionAnswers);
      const getValueBySoFKey = getterConvertFactory<SoFKey>(trimmedCustomQuestionAnswers, convertSoFKeyToKey);
      const body = prepareSoFRequestBody(
        getValueBySoFKey,
        currentSoF,
        currentQuestion,
      );

      if (body) sendAnswers(currentQuestion!.method!, body);
    }

    if (requestState === ENROLL_REQUEST_STATE.READY) {
      progressBarDispatch({ type: EnrollProgressBarNextQuestionAction });
    }
  }, [
    sendAnswers,
    requestState,
    currentQuestion,
    employmentStatusId,
    progressBarDispatch,
    customQuestionAnswers,
    employmentStatusAnswer,
    isQuestionSourceOfFunds,
    isQuestionEmploymentStatus,
    currentSoF,
    currentSoI,
    enrollProgressInfo,
    setEnrollProgress,
  ]);

  const validateEmploymentForm = useCallback((): boolean => {
    const industryCheck = validateControl(
      customQuestionAnswers[KEY_EMPLOYMENT_STATUS_BUSINESS_ID],
      [],
      lang.commonIndustryTitle(),
      true,
    );

    return industryCheck.isValid;
  }, [customQuestionAnswers]);

  const sourceOfFundCustNameCheck = validateControl(
    getValue('name'),
    validationArray(
      nameValidation(validationFieldName),
      ValidationType.MaxCharactersSorceOfFundName,
      errorLabelName,
      400,
      (validationFieldName === ValidationFieldName.IndividualName
        || validationFieldName === ValidationFieldName.Inheritance
        || validationFieldName === ValidationFieldName.InvestmentAndSavingsSpecify),
    ),
    errorLabelName,
    true,
  );

  const sourceOfFundDateFromCheck = validateControl(
    getValue('from'),
    [],
    validationFieldName === ValidationFieldName.Inheritance
      ? lang.commonSourceOfFundsBirthYearLabel() : lang.commonSourceOfFundsDateFromLabel(),
    true,
  );

  const sourceOfFundTypeCheck = validateControl(
    getValue('type'),
    (validationFieldName === ValidationFieldName.IndividualAddress ? validationArrayAddress(
      lang.commonOnboardingPersonalDetailsAddress(),
    ) : []),
    validationFieldName === ValidationFieldName.IndividualAddress
      ? lang.commonOnboardingPersonalDetailsAddress() : lang.commonGeneralQuestionsTypeLabel(),
    true,
  );

  const sourceOfFundAddressCheck = getAddressValidity(getValue('address'));

  const validateField = useCallback(
    (
      fieldName: string,
      hasTypeValidation: boolean,
      hasNameValidation: boolean,
      hasAddressValidation: boolean = false,
    ): boolean => {
      if (hasTypeValidation && hasNameValidation) {
        return (
          sourceOfFundDateFromCheck.isValid
        && sourceOfFundCustNameCheck.isValid
        && sourceOfFundTypeCheck.isValid
        );
      } if (hasAddressValidation && hasNameValidation) {
        return (
          sourceOfFundDateFromCheck.isValid
        && sourceOfFundAddressCheck.isValid
        && sourceOfFundCustNameCheck.isValid
        );
      } if (hasNameValidation) {
        return (
          sourceOfFundDateFromCheck.isValid
        && sourceOfFundCustNameCheck.isValid
        );
      } if (hasTypeValidation) {
        return (
          sourceOfFundDateFromCheck.isValid
        && sourceOfFundTypeCheck.isValid
        );
      }
      return sourceOfFundDateFromCheck.isValid;
    }, [sourceOfFundDateFromCheck, sourceOfFundCustNameCheck, sourceOfFundTypeCheck, sourceOfFundAddressCheck],
  );

  const sourceOfFundValidationCheck = useCallback(() => {
    switch (sourceOfFundsAnswer?.id) {
      case SourceOfFundEnum.AtHomeTradingAlaric:
      case SourceOfFundEnum.AtHomeTradingFlb:
        return !validateField('sourceOfFundAtHomeTrading', false, true);
      case SourceOfFundEnum.SalaryAlaric:
        return !validateField('sourceOfFundSalary', true, true);
      case SourceOfFundEnum.InheritanceAlaric:
      case SourceOfFundEnum.InheritanceFlb:
        return !validateField('sourceOfFundInheritance', false, true, false);
      case SourceOfFundEnum.FamilyMemberAlaric:
        return !validateField('sourceOfFundFamilyMember', false, true);
      case SourceOfFundEnum.PensionOrBenefitProgramAlaric:
      case SourceOfFundEnum.PensionOrGovernmentBenefitsFlb:
        return !validateField('sourceOfFundPensionBenefitProgram', true, false);
      case SourceOfFundEnum.InvestmentsAndSavingsAlaric:
      case SourceOfFundEnum.InvestmentsAndSavingsFlb: {
        const lastOption = investmentsAndSavingsOptions[investmentsAndSavingsOptions.length - 1];
        const fundTypeData = getValue('type');
        const isLastOption = (fundTypeData === lastOption);
        return !validateField(
          'sourceOfFundInvestmentsAndSavings',
          true,
          isLastOption,
        );
      }
      case SourceOfFundEnum.SelfEmploymentAlaric: {
        const hasMain = getValue('has-main') === '1';

        return !validateField(
          'sourceOfFundSelfEmployment',
          false,
          hasMain,
          hasMain,
        );
      }
      case SourceOfFundEnum.LoanAlaric:
        return !validateField('sourceOfFundLoan', true, true);
      default: return false;
    }
  }, [getValue, sourceOfFundsAnswer, validateField]);

  const goToNextSection = useCallback((): void => {
    progressBarDispatch({ type: EnrollProgressBarNextSectionAction });
    setCustomQuestionAnswers({});
  }, [progressBarDispatch]);

  const callEnrollCompleted = useCallback((appropriatenessModalConfirmed = false) => {
    if (currentQuestion && (currentQuestion.subQuestions && (currentQuestion.subQuestions.length > 0))
    ) {
      let dataToSave = customQuestionAnswers;

      if (Object.keys(dataToSave).length === 0) {
        const tempArr: any = [];
        currentQuestion.subQuestions.forEach(item => tempArr.push(item.selectedOption));
        dataToSave = { ...tempArr };
      }

      const bodyData = Object.keys(dataToSave)
        .filter((key: string) => dataToSave[key] !== '')
        .map((key: string) => Number(dataToSave[key]));

      const areAnswersCorrect = areGroupedQuestionAnswersCorrect(sections, bodyData);

      if (!areAnswersCorrect && !appropriatenessModalConfirmed) {
        setShowAppropriatenessModal(true);
      }

      if (bodyData.length) {
        if (!appropriatenessModalConfirmed && !hasLastAnswers && !areAnswersCorrect) {
          nonAppropriatenessEmail();
        }

        // send answers if any provided. If bodyData is empty then no answers are to be sent
        sendAnswers(currentQuestion!.method!, currentQuestion!.getBody(bodyData));
      }

      if (appropriatenessModalConfirmed || areGroupedQuestionAnswersCorrect(sections, bodyData)) {
        history.push(UI_ROUTES.enroll + UI_ROUTES.enrollCompleted);
      }
    }
  }, [currentQuestion, customQuestionAnswers, hasLastAnswers, history, nonAppropriatenessEmail, sections, sendAnswers]);

  const goToPreviousQuestion = useCallback((): void => {
    progressBarDispatch({ type: EnrollProgressBarBackQuestionAction });
    setCustomQuestionAnswers({});
  }, [progressBarDispatch]);

  const clearSubScreensTriggers = useCallback((): void => {
    setSourceOfFundsAnswer(null);
    setEmploymentStatusAnswer(null);
  }, []);

  const goBack = useCallback((): void => {
    setIsClickedBtnContinue(false);
    setIsChangeCurrency(false);
    if (enrollProgressInfo.isOpenLastQuestions) {
      setEnrollProgress({ ...enrollProgressInfo, isOpenLastQuestions: false });
    } else if (enrollProgressInfo.isNoExperienceWithStock) {
      const sendProgressBarData = Object.assign(progressBarData,
        { currentStepIndex: progressBarData.currentStepIndex - 2 });
      setCurrentQuestion(parseQuestion(sendProgressBarData));
      setEnrollProgress({ ...enrollProgressInfo, isNoExperienceWithStock: false });
    }

    setIsEdit(false);
    setCustomQuestionAnswers({
      [KEY_SOURCE_OF_FUND_FROM]: '',
      [KEY_SOURCE_OF_FUND_TO]: '',
      [KEY_SOURCE_OF_FUND_IDENTITY_NAME]: '',
      [KEY_SOURCE_OF_FUND_TYPE]: '',
      [KEY_SOURCE_OF_FUND_ADDRESS]: '',
    });

    setValidationFieldName(ValidationFieldName.InitialState);

    if (employmentStatusAnswer) {
      setEmploymentStatusAnswer(null);
    }

    if (
      (isQuestionEmploymentStatus && employmentStatusAnswer)
      || (sourceOfFundsAnswer && sourceOfFundsAnswer)
    ) {
      return clearSubScreensTriggers();
    }

    return goToPreviousQuestion();
  }, [
    setIsEdit,
    sourceOfFundsAnswer,
    goToPreviousQuestion,
    clearSubScreensTriggers,
    employmentStatusAnswer,
    isQuestionEmploymentStatus,
    setCurrentQuestion,
    parseQuestion,
    enrollProgressInfo,
    progressBarData,
    setEnrollProgress,
  ]);

  const confirmAppropriatenessModal = () => {
    setShowAppropriatenessModal(false);
    callEnrollCompleted(true);
  };

  const questionsDriver = useCallback((): void => {
    setIsClickedBtnContinue(true);

    const isLastQuestionSurpassed = (
      currentQuestion
      && (progressBarData.currentStepIndex >= currentQuestion?.totalSQuestions)
    );

    const isLastQuestionOfLastSectionSurpassed = (
      currentQuestion
      && isLastQuestionSurpassed

      /**
      * currentQuestion.totalSections length needs to be
      * incremented, because PersonalDetails section is not included
      */
      && (progressBarData.currentPhaseIndex === (currentQuestion.totalSections + 1))
    );

    if ((employmentStatusAnswer && !validateEmploymentForm()) || sourceOfFundValidationCheck()) {
      return;
    }

    if (isLastQuestionOfLastSectionSurpassed) {
      callEnrollCompleted();
    } else if (isLastQuestionSurpassed) {
      goToNextSection();
    } else {
      goToNextQuestion();
      setIsClickedBtnContinue(false);
    }
  }, [
    currentQuestion,
    employmentStatusAnswer,
    progressBarData,
    callEnrollCompleted,
    goToNextSection,
    goToNextQuestion,
    sourceOfFundValidationCheck,
    validateEmploymentForm,
  ]);

  const createClickableDivElement = (
    title: string,
    key: string | number,
    bodyContent: number | number[],
    selectedValue?: number,
    option?: FinancialQuestionnaireTypeData,
    id?: string,
    ariaLabel?: string,
  ): JSX.Element => (
    <ClickableDiv
      id={id}
      key={key}
      aria-label={ariaLabel}
      className={classNames(styles.card, { [styles.active]: selectedValue === key })}
      onClick={() => {
        setIsChangeCurrency(false);
        sendAnswers(currentQuestion!.method!, currentQuestion!.getBody(bodyContent));

        const orderOfLastItem = currentQuestion!.options[currentQuestion!.options.length - 1].order;
        if (
          currentQuestion!.questionType === FinancialQuestionnaireTypesEnum.TEWS
          && orderOfLastItem === option?.order
        ) {
          setEnrollProgress({ ...enrollProgressInfo, isNoExperienceWithStock: true });
          const sendProgressBarData = Object.assign(progressBarData,
            { currentStepIndex: progressBarData.currentStepIndex + 2 });
          setCurrentQuestion(parseQuestion(sendProgressBarData));
        }
        if (requestState === ENROLL_REQUEST_STATE.READY) {
          questionsDriver();
        }
      }}
    >
      {title}
    </ClickableDiv>
  );

  const initDefaultQuestionnaireData = (): JSX.Element[] => {
    if (!currentQuestion?.hasCurrencyOption) {
      return currentQuestion!.options.map((option: FinancialQuestionnaireOption, index: number) => (
        createClickableDivElement(
          (option as FinancialQuestionnaireTypeData).description,
          (option as FinancialQuestionnaireTypeData).id,
            currentQuestion!.hasSingleId ? option.id : [option.id],
            currentQuestion?.selectedOption,
            (option as FinancialQuestionnaireTypeData),
            `option_${index}`,
            `option ${index}`,
        )
      ));
    }

    let questionType: IndividualFinancialQuestionnaire | undefined;

    if (individualFinancialQuestionnaires && individualFinancialQuestionnaires.length > 0) {
      questionType = individualFinancialQuestionnaires.find(
        item => item.financial_questionaire_type === currentQuestion?.questionType,
      );
    }

    let filteredData: FinancialQuestionnaireOption[] = currentQuestion.options.filter(option =>
      (option as FinancialQuestionnaireTypeData).currency === selectedCurrency);

    if (questionType) {
      filteredData = currentQuestion.options.filter(option =>
        (option as FinancialQuestionnaireTypeData).currency === questionType!.currency);
    }

    if (isChangeCurrency) {
      filteredData = currentQuestion.options.filter(option =>
        (option as FinancialQuestionnaireTypeData).currency === selectedCurrency);
    }

    return filteredData
      .map((option: FinancialQuestionnaireOption, index: number) => (
        createClickableDivElement(
          (option as FinancialQuestionnaireTypeData).description,
          (option as FinancialQuestionnaireTypeData).id,
        currentQuestion!.hasSingleId ? option.id : [option.id],
        currentQuestion?.selectedOption,
        undefined,
        `option_${index}`,
        `option ${index}`,
        )
      ));
  };

  const initGroupedQuestion = (): JSX.Element[] => currentQuestion!.subQuestions!
    .map((question: FinancialQuestionnaireSubQuestion, index: number) => {
      const options = question.options.map((option: FinancialQuestionnaireTypeData, ind) => ({
        label: option.description,
        value: option.id,
        id: `option_${index}_${ind}`,
        ariaLabel: `option ${index} ${ind}`,
      } as IRadioGroupOption));

      ((customQuestionAnswers[0] && customQuestionAnswers[1]) || question.selectedOption !== -1)
        ? setIsDisabledButtonContinue(false)
        : setIsDisabledButtonContinue(true);

      return (
        <div key={question.title}>
          <p className="mb-0">{question.title}</p>
          <RadioGroup
            options={options}
            value={customQuestionAnswers[index] || question.selectedOption}
            onChange={(value: string | number) => {
              setValue(`${index}` as any, value);
            }}
          />
        </div>
      );
    });

  const initPurposeOfTradingQuestion = (): JSX.Element[] => (
    currentQuestion!.options.map((option: FinancialQuestionnaireOption, index: number) => (
      createClickableDivElement(
        (option as PurposeOfTradingData).name,
        (option as PurposeOfTradingData).id,
        option.id,
        currentQuestion?.selectedOption,
        undefined,
        `option_${index}`,
        `option ${index}`,
      )
    ))
  );

  const initEmploymentStatusQuestion = (): JSX.Element[] => (
    currentQuestion!.options.map((option: FinancialQuestionnaireOption, index: number) => {
      const currentOption = option as EmploymentSatusData;

      return (
        <ClickableDiv
          key={currentOption.id}
          id={`employment_status_${index}`}
          aria-label={`employment status option ${index}`}
          className={classNames(styles.card, { [styles.active]:
            currentQuestion?.selectedOption === currentOption.id })}
          onClick={() => {
            setCustomQuestionAnswers((currentState: {[key: string]: string | number}) => ({
              ...currentState,
              [KEY_EMPLOYMENT_STATUS_ID]: currentOption.id,
            }));

            employmentStatusId = currentOption.id;

            setEmploymentStatusAnswer(currentOption);

            if (!currentOption.business_required) {
              setEmploymentStatusAnswer(null);

              if (requestState === ENROLL_REQUEST_STATE.READY) {
                questionsDriver();
              }
            }
          }}
        >
          {currentOption.name}
        </ClickableDiv>
      );
    })
  );

  const initSourceOfFundQuestion = (): JSX.Element[] => (
    currentQuestion!.options.map((option: FinancialQuestionnaireOption, index: number) => {
      const currentOption = option as SourceOfFundsList;

      return (
        <ClickableDiv
          key={currentOption.id}
          id={`sof_${index}`}
          aria-label={`source of fund option ${index}`}
          className={classNames(styles.card,
            { [styles.active]: currentOption.id === currentQuestion?.selectedOption })}
          onClick={() => {
            handleSourceOfFundChange(KEY_SOURCE_OF_FUND_ID, currentOption.id);
            setSourceOfFundsAnswer(currentOption);
          }}
        >
          {currentOption.name}
        </ClickableDiv>
      );
    })
  );

  const QuestionPreview = (): any => {
    if (!currentQuestion) {
      return <></>;
    }

    switch (currentQuestion.questionType) {
      case FinancialQuestionnaireTypesEnum.GroupedQuestion:
        return initGroupedQuestion(); // Step 3.5
      case FinancialQuestionnaireTypesEnum.PurposeOfTrading:
        return initPurposeOfTradingQuestion(); // Step 2.1
      case FinancialQuestionnaireTypesEnum.EmploymentStatus:
        return initEmploymentStatusQuestion(); // Step 2.2
      case FinancialQuestionnaireTypesEnum.SourceOfFund:
        return initSourceOfFundQuestion(); // Step 2.4
      default:
        return initDefaultQuestionnaireData(); // Step 2.3, 2.5, 2.6, 3.1, 3.2, 3.3, 3.4
    }
  };

  const content = ((): JSX.Element => {
    if (isQuestionEmploymentStatus && employmentStatusAnswer) {
      return (
        <EnrollIndustry
          errors={isClickedBtnContinue
            ? validateControl(
              customQuestionAnswers[KEY_EMPLOYMENT_STATUS_BUSINESS_ID],
              [],
              lang.commonIndustryTitle(),
              true,
            ).errors : []}
          setValue={setCustomQuestionAnswers}
          employmentStatusAnswer={employmentStatusAnswer}
        />
      );
    }

    if (isQuestionSourceOfFunds && sourceOfFundsAnswer) {
      return (
        <EnrollSourceOfFunds
          formData={customQuestionAnswers}
          selectedAnswer={sourceOfFundsAnswer}
          handleChange={handleSourceOfFundChange}
          errorLabelName={setErrorLabelName}
          validationFieldName={setValidationFieldName}
          isClickedBtnContinue={isClickedBtnContinue}
        />
      );
    }

    let questionType: IndividualFinancialQuestionnaire | undefined;

    if (individualFinancialQuestionnaires && individualFinancialQuestionnaires.length > 0) {
      questionType = individualFinancialQuestionnaires.find(
        item => item.financial_questionaire_type === currentQuestion?.questionType,
      );
    }

    return (
      <>
        <AppropriatenessModal
          visible={showAppropriatenessModal}
          onContinueClicked={confirmAppropriatenessModal}
        />
        <h3 id="generalQuestionTitle" aria-label="general question title" className={styles.title}>
          {`${currentQuestion?.sOrder}.${currentQuestion?.qOrder} ${currentQuestion?.qTitle}`}
        </h3>
        {currentQuestion?.qSubtitle && <p className={styles.subTitle}>{currentQuestion?.qSubtitle}</p>}

        <div className={styles.content}>
          {currentQuestion?.hasCurrencyOption && (
          <RadioGroup
            value={(questionType && !isChangeCurrency) ? questionType.currency : selectedCurrency}
            className={styles.radioGroup}
            options={FinancialQuestionsCurrencyOptions}
            onChange={(value: string | number) => {
              setCurrency(value as CurrencyEnum);
              setIsChangeCurrency(true);
            }}
          />
          )}
          <QuestionPreview />
        </div>
      </>
    );
  })();

  useEffect(() => {
    loadQuestion();
  }, [loadQuestion]);

  useEffect(() => {
    const { sOrder, qOrder } = currentQuestion ?? {};
    if (sOrder === 3 && qOrder === 5 && hasLastAnswers) {
      if (areLastAnswersCorrect) {
        history.push({
          pathname: UI_ROUTES.verification,
          state: {
            [RecoveryPhasePath]: UI_ROUTES.verification,
          },
        });
      } else if (!isAppropriatenessPopupConfirmed && !showAppropriatenessModal && !areLastAnswersCorrect) {
        setShowAppropriatenessModal(true);
      }
    }
  }, [
    showAppropriatenessModal, currentQuestion, history,
    isAppropriatenessPopupConfirmed, hasLastAnswers, areLastAnswersCorrect,
  ]);

  return (
    <EnrollLayout
      onBackButtonClicked={goBack}
      onContinueClicked={questionsDriver}
      visibleContinueButton={visibleContinueButton()}
      disabledSubmitButton={isDisabledButtonContinue}
    >
      {content}
    </EnrollLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  crmState: state.crm,
  requestState: state.crm.enroll.status,
  enrollProgressInfo: state.crm.enroll.enrollProgressInfo,
  selectedCurrency: state.crm.enroll.financialQuestionnaireCurrency,
  sections: state.crm.enroll.financialQuestionnaireSections,
  individualData: state.crm.individualExtendedInfo,
  individualFinancialQuestionnaires: state.crm.individualExtendedInfo?.individual_financial_questionnaires,
  hasLastAnswers: hasAnsweredLastQuestionnaire(state.crm.individualExtendedInfo),
  areLastAnswersCorrect: hasCorrectAnswersForLastQuestionnaire(
    state.crm.individualExtendedInfo,
    state.crm.enroll.financialQuestionnaireSections,
    state.auth.isAppropriatenessPopupConfirmed,
  ),
  isAppropriatenessPopupConfirmed: state.auth.isAppropriatenessPopupConfirmed,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setCurrency: (value: CurrencyEnum) => dispatch(setFinancialQuestionnaireCurrency(CurrencyEnum[value])),
  setIsEdit: (value: boolean) => dispatch(setSourceOfFundsIsEdit(value)),
  setEnrollProgress: (data: any) => dispatch(setEnrollProgressInfo(data)),
  sendAnswers: (methodName: ENROLL_METHOD, data: any) => {
    dispatch(setSourceOfFundsIsEdit(false));
    dispatch(enroll({
      callMethod: methodName,
      callMethodName: ENROLL_METHOD[methodName],
      requestBody: data,
    }));
  },
  nonAppropriatenessEmail: () => dispatch(sendNonAppropriatenessEmail()),
});

const mergeProps = (stateProps: ReduxStateProps, dispatchProps: ReduxDispatchProps, ownProps: OwnProps) => ({
  ...skipPropsObject<Omit<ReduxStateProps, 'crmState' | 'individualData'>>(stateProps, ['crmState', 'individualData']),
  ...dispatchProps,
  ...ownProps,
  currentSoF: stateProps.individualData?.source_of_funds?.[0] ?? {},
  currentSoI: stateProps.individualData?.source_of_incomes?.[0] ?? {},
  parseQuestion: (progressBarData) => {
    const { crmState, sections } = stateProps;
    const { currentPhaseIndex, currentStepIndex } = progressBarData;
    return (
      parseFinancialQuestion(crmState, sections, currentPhaseIndex, currentStepIndex)
    );
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
type ReduxStateProps = ReturnType<typeof mapStateToProps>
type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>
type ReduxProps = ConnectedProps<typeof connector>

export default connector(EnrollGeneralQuestions);
