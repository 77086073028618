/**
 * This will soon be deprecated as we are using Gateway for MDS data
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import {
  filter, ignoreElements, mergeMap,
  tap } from 'rxjs/operators';

import { logConfig } from '../../../configDebug';
import { GWMarketDataSummaryBucket, GWQueryCase } from '../../models/gateway/types';
import { ReportingCall } from '../../models/reporting/types';
import GlobalService from '../../services/Global.service';
import { updateBigChartCacheOnLoadChartData } from '../../store-util/BigChartCache';
import CallsCache from '../../store-util/calls-cache/CallsCache';
import { SMALLCHART_INITIAL } from '../../store-util/calls-cache/constants';
import { composeBigChartCallCacheParam } from '../../store-util/calls-cache/helpers';
import InitialSymbolsDataLoadingStatusCache from '../../store-util/InitialSymbolsDataLoadingStatusCache';
import SubscribeGroupCache from '../../store-util/SubscribeGroupCache';
import { extractSymbolsNotInCacheAsString } from '../../util/DataHelpers';
import { getDateTime } from '../../util/DateTimeHelpers';
import { isCallStatusReady } from '../../util/error-handling/StatusByCallHelpers';
import { extractSymbolsFromListAsString, isMarketRegularHours } from '../../util/MarketDataHelpers';
import { calculateNetEquityComponentsFromDate } from '../../util/my-account-helpers';
import { parseOrderTicketComponentToComponentType } from '../../util/SubscribeHelpers';
import { manageSubscriptions, parseOrderTicketComponentToSubscribeGroup } from '../../util/TradingHelpers';
import { OrderTicketComponent } from '../../util/types';
import {
  gatewayConnected,
  getOpenPositionsSuccess,
  individualExtendedInfoSuccess,
  loadDiscoverChartData,
  loadWatchlistChartData,
  processSymbolSubscribesAction,
  processSymbolUnSubscribesAction,
  reconnect,
  reconnectEventAction,
  ReconnectPayload,
} from '../common-actions';
import { accountsCompleted } from '../crm/index';
import {
  getCompanyLogoAndName,
  getDividendPayers,
  getNewStocks,
  getNewStocksCompleted,
  getPopularStocks,
  getPopularStocksCompleted,
  getSectors,
  getTopGainers,
  getTopGainersCompleted,
  getTopLosers,
  getTopLosersCompleted,
} from '../fundamental/index';
import { FundamentalCall } from '../fundamental/types';
import { resetIsReconnect } from '../gateway/index';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../reporting/constants';
import { getAccountPendingOrdersSuccess, getAccountSummary, getAccountTradeHistory, getAccountTradeHistorySuccess, getNetEquityComponents, getOpenPositions } from '../reporting/index';
import { AccountTradeHistoryResponseData, OpenPosition } from '../reporting/types';
import { getCurrentAccount, getToken, getTopMoversInitialItems, hasValidToken, isGatewayMDFullyEnabled, isUserVerifiedStatus } from '../selectors';
import { getSubsriptionsEntitlements } from '../ses/index';
import { RootState } from '..';

import { ChartDataPayload, LoadChartDataPayload, LoadWatchlistChartDataPayload } from './charting/types';
import { checkIsRequestNeededForBigChartData, getAllInitialSymbols, processSymbolSubscribesHelper, processSymbolUnsubscribesHelper } from './helpers';
import {
  getCustomWatchlistData,
  getMarketDataQuery,
  getWatchlistData,
  loadChartData,
  loadChartDataCompleted,
  loadChartDataFailed,
} from './index';
import { GetWatchlistDataPayload, MarketDataQueryPayload, ProcessSymbolSubscribesPayload, ProcessSymbolUnSubscribesPayload, SubscribeGroup } from './types';


const connectEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(individualExtendedInfoSuccess.type, reconnect.type),
  filter(() => !(isGatewayMDFullyEnabled())), // disable market data if gw is enabled for MD feeds
  filter(action => {
    if (state$.value.marketData.connected) {
      console.warn('[market-data/connectEpic] Already connected!');
      return false;
    }
    return true;
  }),
  filter(action => (action.type !== reconnect.type || ((action.payload as ReconnectPayload).marketData ?? false))),
  filter(action => {
    const configLib = require('../../../configLib').default;
    // ART-443 Temporary fix for web deployment
    if (configLib.isWeb && !configLib.__DEV__) {
      console.warn('[market-data/connectEpic] Skip connecting MD for Web');
      return false;
    }
    return true;
  }),
  tap(action => GlobalService.marketDataService.connect(getToken(state$.value)!)),
  ignoreElements(),
);


/**
 * Loads all initial lists of symbols. Later on used by `loadSymbolDataForInitialListsEpic`
 */
const loadInitialSymbolListsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(gatewayConnected.type, accountsCompleted.type), // TODO: Rename `accountsCompleted` to `getAccountsCompleted`
  filter(() => !state$.value.marketData.isInitialPriceDataLoaded),
  filter(() => hasValidToken(state$.value)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    switch (action.type) {
      case gatewayConnected.type: {
        const defaultTopMoversFilter = state$.value.fundamental.topGainersAndLosersFilterValues; // TODO: Sync with FILTER_RANGE_OPTIONS in Web

        // ses
        observer.next(getSubsriptionsEntitlements());

        // watchlist (popular)
        observer.next(getPopularStocks());

        // discover (top gainers/losers, new on the market, popular groups (sectors), divident payers)
        observer.next(getTopGainers(defaultTopMoversFilter));
        observer.next(getTopLosers(defaultTopMoversFilter));
        observer.next(getNewStocks());
        observer.next(getSectors());
        observer.next(getDividendPayers());
        break;
      }

      case accountsCompleted.type: {
        // my account (my stocks, pending orders, net equite chart)
        observer.next(getOpenPositions());
        observer.next(getAccountTradeHistory({ tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true, caller: 'loadInitialSymbolListsEpic' }));
        const accountReferenceId = getCurrentAccount(state$.value);
        const toDate = getDateTime();
        const fromDate = calculateNetEquityComponentsFromDate('1M');
        observer.next(getNetEquityComponents({ accountReferenceId, fromDate, toDate }));
        observer.next(getAccountSummary());
        break;
      }

      default:
        break;
    }
  })),
);

const loadOnlyInitialSymbolListsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(reconnectEventAction.type),
  filter(() => hasValidToken(state$.value)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const defaultTopMoversFilter = state$.value.fundamental.topGainersAndLosersFilterValues;

    // watchlist (popular)
    observer.next(getPopularStocks());

    // my stocks
    observer.next(getOpenPositions());

    // discover (top gainers/losers, new on the market)
    observer.next(getTopGainers(defaultTopMoversFilter));
    observer.next(getTopLosers(defaultTopMoversFilter));
    observer.next(getNewStocks());

    GlobalService.loadChartDataBySelectedComponent();
  })),
);

/**
 * Check when loaded is done in `store-util/InitialSymbolsDataLoadingStatusCache.ts` -> function `updateStatus`
 * When done `confirmInitialPriceDataLoaded` is dispatched and `marketData.isInitialPriceDataLoaded` is set
 */
const loadSymbolDataForInitialListsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(
    getPopularStocksCompleted.type,
    getTopGainersCompleted.type,
    getTopLosersCompleted.type,
    getNewStocksCompleted.type,
    getOpenPositionsSuccess.type,
    getAccountTradeHistorySuccess.type,
  ),
  filter(() => !state$.value.marketData.isInitialPriceDataLoaded || state$.value.gateway.isReconnect),
  filter(() => isCallStatusReady(FundamentalCall.getPopularStocks, state$.value.fundamental.statusByCall)),
  filter(() => isCallStatusReady(FundamentalCall.getNewStocks, state$.value.fundamental.statusByCall)),
  filter(() => getTopMoversInitialItems().isInitialDataLoaded),
  filter(() => (
    !isUserVerifiedStatus(state$.value.crm.individualExtendedInfo)
    || isCallStatusReady(ReportingCall.getOpenPositions, state$.value.reporting.statusByCall)
  )),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const {
      symbolsAsString,
      byGroup: {
        watchlist,
        discover,
        myAccount,
      },
    } = getAllInitialSymbols(state$.value);

    InitialSymbolsDataLoadingStatusCache.updateStatus('tradeSubscription', true);

    SubscribeGroupCache.setGroupOnly('initial');
    SubscribeGroupCache.setUnSubscribed(watchlist, 'watchlist');
    SubscribeGroupCache.setUnSubscribed(discover, 'discover');
    SubscribeGroupCache.setUnSubscribed(myAccount, 'my-account');

    observer.next(getWatchlistData({
      symbolOrSymbols: symbolsAsString,
      component: 'initial',
      caller: 'loadSymbolDataForInitialListsEpic',
    }));
  })),
);

const getWatchlistDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getWatchlistData.type),
  filter((action: PayloadAction<GetWatchlistDataPayload>) => action.payload.symbolOrSymbols?.length > 0),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const {
      symbolOrSymbols,
      component,
    } = action.payload;
    const isInitialComponent = component === 'initial';
    const group: SubscribeGroup | null = parseOrderTicketComponentToSubscribeGroup(component);

    // get chart data
    const getChartDataFunction = () => GlobalService.globalService.getWatchlistChartData(symbolOrSymbols, component);
    if (isInitialComponent) {
      CallsCache.processRequest('getChartingData', SMALLCHART_INITIAL, getChartDataFunction);
    } else {
      getChartDataFunction();
    }

    // get company logo and name
    if (component !== 'watchlist/popular' && group !== 'discover') {
      observer.next(getCompanyLogoAndName(extractSymbolsNotInCacheAsString(state$.value, symbolOrSymbols)));
    }

    // subscribe to trade feed
    if (group) {
      if (
        SubscribeGroupCache.getCurrent().group === group
      || !state$.value.marketData.isInitialPriceDataLoaded
      || state$.value.gateway.isReconnect
      || parseOrderTicketComponentToComponentType(component) !== 'initial'
      ) {
        observer.next(processSymbolSubscribesAction({
          symbolOrSymbols, group, component, caller: 'getWatchlistDataEpic',
        }));
        if (isInitialComponent) {
          observer.next(resetIsReconnect());
        }
      } else {
        console.tron.log!(`[SKIP-SUBSCRIBE-Summary] getWatchlistDataEpic -- symbols: ${symbolOrSymbols.split(',').length}, group: ${group ?? '<no-group>'} component: ${component} [s-flow]`);
        console.tron.log!(`[SKIP-SUBSCRIBE-Detailed] getWatchlistDataEpic -- symbols: ${symbolOrSymbols.split(',').length} | ${symbolOrSymbols}, group: ${group ?? '<no-group>'} component: ${component} [s-flow]`);
      }
    } else {
      console.warn(`[market-data/epics] getWatchlistDataEpic - skipping subscribe for '${symbolOrSymbols}' (group: ${group}  component: ${component})`, action.payload);
    }
  })),
);

const getCustomWatchlistDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getCustomWatchlistData.type),
  filter((action: PayloadAction<GetWatchlistDataPayload>) => action.payload.symbolOrSymbols?.length > 0),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const fallbackSymbols = extractSymbolsFromListAsString(state$.value.reporting.peopleAlsoTradeData);
    const {
      symbolOrSymbols = fallbackSymbols,
      component,
    } = action.payload;
    const group: SubscribeGroup | null = parseOrderTicketComponentToSubscribeGroup(component);
    const symbols = symbolOrSymbols.split(',');

    // get chart data
    if (isMarketRegularHours()) {
      const getChartDataReturnsTraceId = (
        () => GlobalService.globalService.getWatchlistChartData(symbolOrSymbols, component)
      );
      CallsCache.processRequest('getChartingData', component, getChartDataReturnsTraceId);
    }

    // get company logo and name
    observer.next(getCompanyLogoAndName(extractSymbolsNotInCacheAsString(state$.value, symbolOrSymbols)));

    // subscribe to trade feed
    const { toBeUpdated: subscribeToBeUpdated } = CallsCache.checkRequest('subscribe', component);
    if (subscribeToBeUpdated) {
      const subscribeRequestReturnsTraceId = (
        () => GlobalService.globalService.subscribe(symbols, 'trade', true, 'getCustomWatchlistDataEpic')
      );
      CallsCache.processRequest('subscribe', component, subscribeRequestReturnsTraceId);
      SubscribeGroupCache.setSubscribedNoGroupChange(symbols, group!, 'trade');
    }
  })),
);

export const processSymbolSubscribesEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(processSymbolSubscribesAction.type),
  tap(action => processSymbolSubscribesHelper(action.payload)),
  tap((action: PayloadAction<ProcessSymbolSubscribesPayload>) => {
    const { isFavorites, symbolOrSymbols, group } = action.payload;
    if (isFavorites) {
      GlobalService.globalService.getWatchlistChartData(symbolOrSymbols, group);
    }
  }),
  ignoreElements(),
);

export const processSymbolUnSubscribesEpic = (
  action$: Observable<PayloadAction<ProcessSymbolUnSubscribesPayload>>,
) => action$.pipe(
  ofType(processSymbolUnSubscribesAction.type),
  tap(action => processSymbolUnsubscribesHelper(action.payload)),
  ignoreElements(),
);

/** Subscribes positions in my account (customData.symbol is not defined - it is defined when calling from Symbol Details) */
const subscribePositionSymbolsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getOpenPositionsSuccess.type),
  filter(action => state$.value.marketData.isInitialPriceDataLoaded),
  filter(action => action.payload.length),
  filter(action => !action.payload.customData?.symbol),
  tap((action: PayloadAction<OpenPosition[]>) => {
    const positions = action.payload;
    const symbolOrSymbols: string = uniq(positions.map(({ symbol }) => symbol)).join();
    if (
      SubscribeGroupCache.getCurrent().group === 'my-account'
      || SubscribeGroupCache.getCurrent().group === 'create-order'
    ) {
      manageSubscriptions('my-account/positions', symbolOrSymbols);
    } else {
      const configLib = require('../../../configLib').default;
      if (configLib.__DEV__ && logConfig.subscriptionsFlow) {
        const group: SubscribeGroup = 'my-account';
        const component: OrderTicketComponent = 'my-account/positions';
        console.tron.log!(`[SKIP-SUBSCRIBE-Summary] subscribePositionSymbolsEpic -- symbols: ${symbolOrSymbols.split(',').length}, group: ${group ?? '<no-group>'} component: '${component}' [s-flow]`);
        console.tron.log!(`[SKIP-SUBSCRIBE-Detailed] subscribePositionSymbolsEpic -- symbols: ${symbolOrSymbols.split(',').length} | ${symbolOrSymbols}, group: ${group ?? '<no-group>'} component: ${component} [s-flow]`);
      }
    }
  }),
  ignoreElements(),
);

const subscribePendingOrdersSymbolsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getAccountPendingOrdersSuccess.type),
  filter(action => action.payload.length),
  filter(action => !action.payload?.customData?.isSymbolDetails),
  tap((action: PayloadAction<AccountTradeHistoryResponseData[]>) => {
    const orders = action.payload;
    const symbols: string[] = orders.map(({ symbol }) => symbol);
    const symbolOrSymbols: string = uniq(symbols).join();
    if (SubscribeGroupCache.getCurrent().group === 'my-account') {
      manageSubscriptions('my-account/orders', symbolOrSymbols);
    } else {
      const configLib = require('../../../configLib').default;
      if (configLib.__DEV__ && logConfig.subscriptionsFlow) {
        const group: SubscribeGroup = 'my-account';
        const component: OrderTicketComponent = 'my-account/orders';
        console.tron.log!(`[SKIP-SUBSCRIBE-Summary] subscribePositionSymbolsEpic -- symbols: ${symbolOrSymbols.split(',').length}, group: ${group ?? '<no-group>'} component: '${component}'`);
        console.tron.log!(`[SKIP-SUBSCRIBE-Detailed] subscribePositionSymbolsEpic -- symbols: ${symbolOrSymbols.split(',').length} | ${symbolOrSymbols}, group: ${group ?? '<no-group>'} component: ${component}`);
      }
    }
  }),
  ignoreElements(),
);

/**
 * Load data for big chart in Symbol Details Page / Stock Screen
 */
const loadChartDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(loadChartData.type),
  filter((action: PayloadAction<LoadChartDataPayload>) => (
    !!action.payload.bucket && action.payload.bucket !== GWMarketDataSummaryBucket.Invalid)),
  mergeMap((action: PayloadAction<LoadChartDataPayload>) => new Observable((observer: Observer<any>) => {
    const { symbol, bucket } = action.payload;

    const { hasToMakeRequest, isPreCachedFromSmallChart } = checkIsRequestNeededForBigChartData(action.payload);
    updateBigChartCacheOnLoadChartData(action.payload, hasToMakeRequest, isPreCachedFromSmallChart);

    if (!hasToMakeRequest) {
      console.warn(`[market-data/epics] loadChartDataEpic - skipping call for ${symbol}, ${bucket} - already in cache`);
      return;
    }

    const callsCacheParam = composeBigChartCallCacheParam(symbol, bucket);
    GlobalService.globalService.getSymbolDetailsChartData(symbol, bucket);
    CallsCache.processRequest('getChartingData', callsCacheParam, () => GlobalService.globalService.getSymbolDetailsChartData(symbol, bucket));
  })),
);

/**
 * Load small chart data for Watchlists (Favorites, Popular)
 */
const loadWatchlistChartDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(loadWatchlistChartData.type),
  filter(action => !!action.payload.symbolOrSymbols), // skip calls if no symbols (for example empty Favorites Watchlist)
  filter(action => (
    !!action.payload.bucket && action.payload.bucket !== GWMarketDataSummaryBucket.Invalid
  )),
  filter(action => {
    const { symbolOrSymbols, bucket } = action.payload;
    const { processChartingCache } = require('./charting/helpers');
    const cache = processChartingCache(symbolOrSymbols, bucket!, true, 'loadWatchlistChartDataEpic');
    return cache;
  }),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { symbolOrSymbols } = action.payload;
    GlobalService.globalService.getWatchlistChartData(symbolOrSymbols, 'watchlist');
  })),
);

/**
 * Get Market Data OHLCV for 1s, 1m and 1d resolution
 */
const getMarketDataQueryEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getMarketDataQuery.type),
  mergeMap((action: PayloadAction<MarketDataQueryPayload>) => new Observable((observer: Observer<any>) => {
    const { symbols, bucket, endTime, startTime } = action.payload;
    GlobalService.globalService.query(symbols, startTime, endTime, bucket, GWQueryCase.OHLCV);
  })),
);

const loadDiscoverChartDataEpic = (action$: Observable<any>) => action$.pipe(
  ofType(loadDiscoverChartData.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService.globalService.getWatchlistChartData(action.payload, 'discover');
  })),
);

const loadChartDataCompletedOrFailedEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(loadChartDataCompleted.type, loadChartDataFailed.type),
  tap(action => {
    const { symbol, bucket } = (action as PayloadAction<ChartDataPayload>).payload;
    const { processChartingCache } = require('./charting/helpers');
    processChartingCache(symbol, bucket, false, 'loadChartDataCompletedOrFailedEpic');
  }),
  ignoreElements(),
);

export default combineEpics(
  connectEpic,
  getCustomWatchlistDataEpic,
  getMarketDataQueryEpic,
  getWatchlistDataEpic,
  loadChartDataEpic,
  loadChartDataCompletedOrFailedEpic,
  loadDiscoverChartDataEpic,
  loadInitialSymbolListsEpic,
  loadOnlyInitialSymbolListsEpic,
  loadSymbolDataForInitialListsEpic,
  loadWatchlistChartDataEpic,
  processSymbolSubscribesEpic,
  subscribePendingOrdersSymbolsEpic,
  subscribePositionSymbolsEpic,
);
