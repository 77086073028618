import React from 'react';

import lang from '../../../../lib/language';

import { Button } from '../../../../components';

import styles from './DepositSlipModal.module.scss';

type Props = {
    handleModal: () => void;
};

const DepositSlipModal = (props: Props) => {
  const { handleModal } = props;

  return (
    <>
      <h6 id="depositSlipSentTitle" className={styles.title}>{lang.mobileDepositSlipDialogTitle()}</h6>
      <span id="depositSlipSentContent" className={styles.description}>{lang.webPopupDepositSlipDescription()}</span>
      <Button
        id="depositSlipSentOkay"
        fullWidth
        className={styles.buttonOkay}
        onClick={() => handleModal()}
      >
        {lang.commonOkay()}
      </Button>
    </>
  );
};

export default DepositSlipModal;
