import React from 'react';

import { TopGainerAndLoserData } from '../../../../lib/models/fundamental/types';

import leftArrow from '../../../../assets/img/icon-arrow-left_24px.svg';
import rightArrow from '../../../../assets/img/icon-arrow-right_24px.svg';

import styles from '../../SymbolTradeInfo.module.scss';

type Props = {
  handleSlideIndex: (index: number) => void;
  slideIndex: number;
  topMovers: TopGainerAndLoserData[];
}

let hasMoreData: boolean = false;

const SlideButtons = (props: Props) => {
  const {
    handleSlideIndex,
    slideIndex,
    topMovers,
  } = props;

  hasMoreData = topMovers.length > 5;

  const handleBtnNextData = () => (
    <>
      {
         hasMoreData
           ? (
             <button onClick={() => handleSlideIndex(1)} type="button" className={styles.buttonNext}>
               <img src={rightArrow} alt="right arrow" />
             </button>
           )
           : null
        }
    </>
  );

  return (
    <>
      {
          slideIndex
            ? (
              <button onClick={() => handleSlideIndex(0)} type="button" className={styles.buttonPrevious}>
                <img src={leftArrow} alt="left arrow" />
              </button>
            )
            : handleBtnNextData()
        }
    </>
  );
};

export default SlideButtons;
