import aapl from '../assets/customLogos/icon_aapl.svg';
import abbv from '../assets/customLogos/icon_abbv.svg';
import abc from '../assets/customLogos/icon_abc.svg';
import acgl from '../assets/customLogos/icon_acgl.svg';
import adi from '../assets/customLogos/icon_adi.svg';
import adsk from '../assets/customLogos/icon_adsk.svg';
import afrm from '../assets/customLogos/icon_afrm.svg';
import aig from '../assets/customLogos/icon_aig.svg';
import airc from '../assets/customLogos/icon_airc.svg';
import al from '../assets/customLogos/icon_al.svg';
import alb from '../assets/customLogos/icon_alb.svg';
import all from '../assets/customLogos/icon_all.svg';
import alny from '../assets/customLogos/icon_alny.svg';
import ame from '../assets/customLogos/icon_ame.svg';
import amlx from '../assets/customLogos/icon_amlx.svg';
import amp from '../assets/customLogos/icon_amp.svg';
import amzn from '../assets/customLogos/icon_amzn.svg';
import an from '../assets/customLogos/icon_an.svg';
import anet from '../assets/customLogos/icon_anet.svg';
import anf from '../assets/customLogos/icon_anf.svg';
import anss from '../assets/customLogos/icon_anss.svg';
import apd from '../assets/customLogos/icon_apd.svg';
import apg from '../assets/customLogos/icon_apg.svg';
import aph from '../assets/customLogos/icon_aph.svg';
import apls from '../assets/customLogos/icon_apls.svg';
import apog from '../assets/customLogos/icon_apog.svg';
import app from '../assets/customLogos/icon_app.svg';
import appf from '../assets/customLogos/icon_appf.svg';
import appn from '../assets/customLogos/icon_appn.svg';
import aptv from '../assets/customLogos/icon_aptv.svg';
import arcb from '../assets/customLogos/icon_arcb.svg';
import are from '../assets/customLogos/icon_are.svg';
import argo from '../assets/customLogos/icon_argo.svg';
import aroc from '../assets/customLogos/icon_aroc.svg';
import arw from '../assets/customLogos/icon_arw.svg';
import arw_1 from '../assets/customLogos/icon_arw_1.svg';
import asgn from '../assets/customLogos/icon_asgn.svg';
import atec from '../assets/customLogos/icon_atec.svg';
import ato from '../assets/customLogos/icon_ato.svg';
import atr from '../assets/customLogos/icon_atr.svg';
import atrc from '../assets/customLogos/icon_atrc.svg';
import atvi from '../assets/customLogos/icon_atvi.svg';
import ava from '../assets/customLogos/icon_ava.svg';
import avav from '../assets/customLogos/icon_avav.svg';
import avb from '../assets/customLogos/icon_avb.svg';
import avt from '../assets/customLogos/icon_avt.svg';
import awi from '../assets/customLogos/icon_awi.svg';
import awk from '../assets/customLogos/icon_awk.svg';
import axon from '../assets/customLogos/icon_axon.svg';
import axsm from '../assets/customLogos/icon_axsm.svg';
import ayx from '../assets/customLogos/icon_ayx.svg';
import azek from '../assets/customLogos/icon_azek.svg';
import ba from '../assets/customLogos/icon_ba.svg';
import ball from '../assets/customLogos/icon_ball.svg';
import bax from '../assets/customLogos/icon_bax.svg';
import bc from '../assets/customLogos/icon_bc.svg';
import bke from '../assets/customLogos/icon_bke.svg';
import btu from '../assets/customLogos/icon_btu.svg';
import bwa from '../assets/customLogos/icon_bwa.svg';
import bx from '../assets/customLogos/icon_bx.svg';
import bxc from '../assets/customLogos/icon_bxc.svg';
import c from '../assets/customLogos/icon_c.svg';
import cabo from '../assets/customLogos/icon_cabo.svg';
import cake from '../assets/customLogos/icon_cake.svg';
import cal from '../assets/customLogos/icon_cal.svg';
import calm from '../assets/customLogos/icon_calm.svg';
import cat from '../assets/customLogos/icon_cat.svg';
import cboe from '../assets/customLogos/icon_cboe.svg';
import cbz from '../assets/customLogos/icon_cbz.svg';
import ccl from '../assets/customLogos/icon_ccl.svg';
import cdns from '../assets/customLogos/icon_cdns.svg';
import ceg from '../assets/customLogos/icon_ceg.svg';
import celh from '../assets/customLogos/icon_celh.svg';
import clfd from '../assets/customLogos/icon_clfd.svg';
import cof from '../assets/customLogos/icon_cof.svg';
import coty from '../assets/customLogos/icon_coty.svg';
import cour from '../assets/customLogos/icon_cour.svg';
import crsr from '../assets/customLogos/icon_crsr.svg';
import csx from '../assets/customLogos/icon_csx.svg';
import ctas from '../assets/customLogos/icon_ctas.svg';
import cvbf from '../assets/customLogos/icon_cvbf.svg';
import dbx from '../assets/customLogos/icon_dbx.svg';
import ddog from '../assets/customLogos/icon_ddog.svg';
import dhi from '../assets/customLogos/icon_dhi.svg';
import dice from '../assets/customLogos/icon_dice.svg';
import dino from '../assets/customLogos/icon_dino.svg';
import dis from '../assets/customLogos/icon_dis.svg';
import dnli from '../assets/customLogos/icon_dnli.svg';
import docu from '../assets/customLogos/icon_docu.svg';
import dri from '../assets/customLogos/icon_dri.svg';
import dtm from '../assets/customLogos/icon_dtm.svg';
import eeft from '../assets/customLogos/icon_eeft.svg';
import elf from '../assets/customLogos/icon_elf.svg';
import elv from '../assets/customLogos/icon_elv.svg';
import epc from '../assets/customLogos/icon_epc.svg';
import eqt from '../assets/customLogos/icon_eqt.svg';
import esab from '../assets/customLogos/icon_esab.svg';
import esmt from '../assets/customLogos/icon_esmt.svg';
import expe from '../assets/customLogos/icon_expe.svg';
import fast from '../assets/customLogos/icon_fast.svg';
import fbin from '../assets/customLogos/icon_fbin.svg';
import fcpt from '../assets/customLogos/icon_fcpt.svg';
import fcx from '../assets/customLogos/icon_fcx.svg';
import flr from '../assets/customLogos/icon_flr.svg';
import four from '../assets/customLogos/icon_four.svg';
import foxa from '../assets/customLogos/icon_foxa.svg';
import glng from '../assets/customLogos/icon_glng.svg';
import glpi from '../assets/customLogos/icon_glpi.svg';
import gntx from '../assets/customLogos/icon_gntx.svg';
import gogo from '../assets/customLogos/icon_gogo.svg';
import gpre from '../assets/customLogos/icon_gpre.svg';
import gps from '../assets/customLogos/icon_gps.svg';
import gt from '../assets/customLogos/icon_gt.svg';
import gtn from '../assets/customLogos/icon_gtn.svg';
import gty from '../assets/customLogos/icon_gty.svg';
import hae from '../assets/customLogos/icon_hae.svg';
import hasi from '../assets/customLogos/icon_hasi.svg';
import hei from '../assets/customLogos/icon_hei.svg';
import hi from '../assets/customLogos/icon_hi.svg';
import hlt from '../assets/customLogos/icon_hlt.svg';
import hlx from '../assets/customLogos/icon_hlx.svg';
import hsy from '../assets/customLogos/icon_hsy.svg';
import htz from '../assets/customLogos/icon_htz.svg';
import hwkn from '../assets/customLogos/icon_hwkn.svg';
import hznp from '../assets/customLogos/icon_hznp.svg';
import iac from '../assets/customLogos/icon_iac.svg';
import ias from '../assets/customLogos/icon_ias.svg';
import ibm from '../assets/customLogos/icon_ibm.svg';
import ice from '../assets/customLogos/icon_ice.svg';
import idya from '../assets/customLogos/icon_idya.svg';
import imax from '../assets/customLogos/icon_imax.svg';
import imgn from '../assets/customLogos/icon_imgn.svg';
import intc from '../assets/customLogos/icon_intc.svg';
import ipg from '../assets/customLogos/icon_ipg.svg';
import ipgp from '../assets/customLogos/icon_ipgp.svg';
import irbt from '../assets/customLogos/icon_irbt.svg';
import irdm from '../assets/customLogos/icon_irdm.svg';
import jbl from '../assets/customLogos/icon_jbl.svg';
import jwn from '../assets/customLogos/icon_jwn.svg';
import khc from '../assets/customLogos/icon_khc.svg';
import kkr from '../assets/customLogos/icon_kkr.svg';
import kmx from '../assets/customLogos/icon_kmx.svg';
import knx from '../assets/customLogos/icon_knx.svg';
import kr from '../assets/customLogos/icon_kr.svg';
import kss from '../assets/customLogos/icon_kss.svg';
import ktb from '../assets/customLogos/icon_ktb.svg';
import lbrda from '../assets/customLogos/icon_lbrda.svg';
import lbrdk from '../assets/customLogos/icon_lbrdk.svg';
import lcid from '../assets/customLogos/icon_lcid.svg';
import lh from '../assets/customLogos/icon_lh.svg';
import lkfn from '../assets/customLogos/icon_lkfn.svg';
import lmt from '../assets/customLogos/icon_lmt.svg';
import masi from '../assets/customLogos/icon_masi.svg';
import mbc from '../assets/customLogos/icon_mbc.svg';
import mcd from '../assets/customLogos/icon_mcd.svg';
import mdrx from '../assets/customLogos/icon_mdrx.svg';
import mgni from '../assets/customLogos/icon_mgni.svg';
import mgpi from '../assets/customLogos/icon_mgpi.svg';
import mktx from '../assets/customLogos/icon_mktx.svg';
import mlnk from '../assets/customLogos/icon_mlnk.svg';
import mrvl from '../assets/customLogos/icon_mrvl.svg';
import msgs from '../assets/customLogos/icon_msgs.svg';
import mtn from '../assets/customLogos/icon_mtn.svg';
import mtx from '../assets/customLogos/icon_mtx.svg';
import nex from '../assets/customLogos/icon_nex.svg';
import ngvt from '../assets/customLogos/icon_ngvt.svg';
import nke from '../assets/customLogos/icon_nke.svg';
import nly from '../assets/customLogos/icon_nly.svg';
import nova from '../assets/customLogos/icon_nova.svg';
import ntap from '../assets/customLogos/icon_ntap.svg';
import nuvl from '../assets/customLogos/icon_nuvl.svg';
import nvax from '../assets/customLogos/icon_nvax.svg';
import nymt from '../assets/customLogos/icon_nymt.svg';
import odp from '../assets/customLogos/icon_odp.svg';
import oi from '../assets/customLogos/icon_oi.svg';
import okta from '../assets/customLogos/icon_okta.svg';
import on from '../assets/customLogos/icon_on.svg';
import open from '../assets/customLogos/icon_open.svg';
import osh from '../assets/customLogos/icon_osh.svg';
import pacw from '../assets/customLogos/icon_pacw.svg';
import para from '../assets/customLogos/icon_para.svg';
import paraa from '../assets/customLogos/icon_paraa.svg';
import patk from '../assets/customLogos/icon_patk.svg';
import pbf from '../assets/customLogos/icon_pbf.svg';
import pct from '../assets/customLogos/icon_pct.svg';
import phm from '../assets/customLogos/icon_phm.svg';
import pltr from '../assets/customLogos/icon_pltr.svg';
import pump from '../assets/customLogos/icon_pump.svg';
import pypl from '../assets/customLogos/icon_pypl.svg';
import qnst from '../assets/customLogos/icon_qnst.svg';
import qs from '../assets/customLogos/icon_qs.svg';
import radi from '../assets/customLogos/icon_radi.svg';
import rblx from '../assets/customLogos/icon_rblx.svg';
import rcl from '../assets/customLogos/icon_rcl.svg';
import regn from '../assets/customLogos/icon_regn.svg';
import reta from '../assets/customLogos/icon_reta.svg';
import rh from '../assets/customLogos/icon_rh.svg';
import ritm from '../assets/customLogos/icon_ritm.svg';
import rlay from '../assets/customLogos/icon_rlay.svg';
import roku from '../assets/customLogos/icon_roku.svg';
import run from '../assets/customLogos/icon_run.svg';
import rvnc from '../assets/customLogos/icon_rvnc.svg';
import rxdx from '../assets/customLogos/icon_rxdx.svg';
import rxo from '../assets/customLogos/icon_rxo.svg';
import s from '../assets/customLogos/icon_s.svg';
import safe from '../assets/customLogos/icon_safe.svg';
import sfm from '../assets/customLogos/icon_sfm.svg';
import skin from '../assets/customLogos/icon_skin.svg';
import skx from '../assets/customLogos/icon_skx.svg';
import sky from '../assets/customLogos/icon_sky.svg';
import slb from '../assets/customLogos/icon_slb.svg';
import slg from '../assets/customLogos/icon_slg.svg';
import snbr from '../assets/customLogos/icon_snbr.svg';
import sono from '../assets/customLogos/icon_sono.svg';
import sovo from '../assets/customLogos/icon_sovo.svg';
import splk from '../assets/customLogos/icon_splk.svg';
import spwr from '../assets/customLogos/icon_spwr.svg';
import sstk from '../assets/customLogos/icon_sstk.svg';
import st from '../assets/customLogos/icon_st.svg';
import stt from '../assets/customLogos/icon_stt.svg';
import sumo from '../assets/customLogos/icon_sumo.svg';
import svcf from '../assets/customLogos/icon_svcf.svg';
import sybt from '../assets/customLogos/icon_sybt.svg';
import tell from '../assets/customLogos/icon_tell.svg';
import tenb from '../assets/customLogos/icon_tenb.svg';
import th from '../assets/customLogos/icon_th.svg';
import titn from '../assets/customLogos/icon_titn.svg';
import tmus from '../assets/customLogos/icon_tmus.svg';
import trmk from '../assets/customLogos/icon_trmk.svg';
import tsp from '../assets/customLogos/icon_tsp.svg';
import ttwo from '../assets/customLogos/icon_ttwo.svg';
import twtr from '../assets/customLogos/icon_twtr.svg';
import uaa from '../assets/customLogos/icon_uaa.svg';
import uber from '../assets/customLogos/icon_uber.svg';
import ulta from '../assets/customLogos/icon_ulta.svg';
import unh from '../assets/customLogos/icon_unh.svg';
import ups from '../assets/customLogos/icon_ups.svg';
import v from '../assets/customLogos/icon_v.svg';
import vfc from '../assets/customLogos/icon_vfc.svg';
import vrt from '../assets/customLogos/icon_vrt.svg';
import vrtx from '../assets/customLogos/icon_vrtx.svg';
import wal from '../assets/customLogos/icon_wal.svg';
import wpc from '../assets/customLogos/icon_wpc.svg';
import wsc from '../assets/customLogos/icon_wsc.svg';
import wsfs from '../assets/customLogos/icon_wsfs.svg';
import wsm from '../assets/customLogos/icon_wsm.svg';
import wtfc from '../assets/customLogos/icon_wtfc.svg';
import wynn from '../assets/customLogos/icon_wynn.svg';
import x from '../assets/customLogos/icon_x.svg';
import yext from '../assets/customLogos/icon_yext.svg';
import z from '../assets/customLogos/icon_z.svg';
import zion from '../assets/customLogos/icon_zion.svg';
import zm from '../assets/customLogos/icon_zm.svg';

export const logoSources: Record<string, string> = {
  AAPL: aapl,
  ABBV: abbv,
  ABC: abc,
  ACGL: acgl,
  ADI: adi,
  ADSK: adsk,
  AFRM: afrm,
  AIG: aig,
  AIRC: airc,
  AL: al,
  ALB: alb,
  ALL: all,
  ALNY: alny,
  AME: ame,
  AMLX: amlx,
  AMP: amp,
  AMZN: amzn,
  AN: an,
  ANET: anet,
  ANF: anf,
  ANSS: anss,
  APD: apd,
  APG: apg,
  APH: aph,
  APLS: apls,
  APOG: apog,
  APP: app,
  APPN: appn,
  APPF: appf,
  APTV: aptv,
  ARCB: arcb,
  ARE: are,
  ARGO: argo,
  AROC: aroc,
  ARW_1: arw_1,
  ARW: arw,
  ASGN: asgn,
  ATEC: atec,
  ATO: ato,
  ATR: atr,
  ATRC: atrc,
  ATVI: atvi,
  AVA: ava,
  AVAV: avav,
  AVB: avb,
  AVT: avt,
  AWI: awi,
  AWK: awk,
  AXON: axon,
  AXSM: axsm,
  AYX: ayx,
  AZEK: azek,
  BA: ba,
  BALL: ball,
  BAX: bax,
  BC: bc,
  BKE: bke,
  BTU: btu,
  BWA: bwa,
  BX: bx,
  BXC: bxc,
  C: c,
  CABO: cabo,
  CAKE: cake,
  CAL: cal,
  CALM: calm,
  CAT: cat,
  CBOE: cboe,
  CBZ: cbz,
  CCL: ccl,
  CDNS: cdns,
  CEG: ceg,
  CELH: celh,
  CLFD: clfd,
  COF: cof,
  COTY: coty,
  COUR: cour,
  CRSR: crsr,
  CSX: csx,
  CTAS: ctas,
  CVBF: cvbf,
  DBX: dbx,
  DDOG: ddog,
  DHI: dhi,
  DICE: dice,
  DINO: dino,
  DIS: dis,
  DNLI: dnli,
  DOCU: docu,
  DRI: dri,
  DTM: dtm,
  EEFT: eeft,
  ELF: elf,
  ELV: elv,
  EPC: epc,
  EQT: eqt,
  ESAB: esab,
  ESMT: esmt,
  EXPE: expe,
  FAST: fast,
  FBIN: fbin,
  FCPT: fcpt,
  FCX: fcx,
  FLR: flr,
  FOUR: four,
  FOXA: foxa,
  GLNG: glng,
  GLPI: glpi,
  GNTX: gntx,
  GOGO: gogo,
  GPRE: gpre,
  GPS: gps,
  GT: gt,
  GTN: gtn,
  GTY: gty,
  HAE: hae,
  HASI: hasi,
  HEI: hei,
  HI: hi,
  HLT: hlt,
  HLX: hlx,
  HSY: hsy,
  HTZ: htz,
  HWKN: hwkn,
  HZNP: hznp,
  IAC: iac,
  IAS: ias,
  IBM: ibm,
  ICE: ice,
  IDYA: idya,
  IMAX: imax,
  IMGN: imgn,
  INTC: intc,
  IPG: ipg,
  IPGP: ipgp,
  IRBT: irbt,
  IRDM: irdm,
  JBL: jbl,
  JWN: jwn,
  KHC: khc,
  KKR: kkr,
  KMX: kmx,
  KNX: knx,
  KR: kr,
  KSS: kss,
  KTB: ktb,
  LBRDA: lbrda,
  LBRDK: lbrdk,
  LCID: lcid,
  LH: lh,
  LKFN: lkfn,
  LMT: lmt,
  MASI: masi,
  MBC: mbc,
  MCD: mcd,
  MDRX: mdrx,
  MGNi: mgni,
  MGPI: mgpi,
  MKTX: mktx,
  MLNK: mlnk,
  MRVL: mrvl,
  MSGS: msgs,
  MTN: mtn,
  MTX: mtx,
  NEX: nex,
  NGVT: ngvt,
  NKE: nke,
  NLY: nly,
  NOVA: nova,
  NTAP: ntap,
  NUVL: nuvl,
  NVAX: nvax,
  NYMT: nymt,
  ODP: odp,
  OI: oi,
  OKTA: okta,
  ON: on,
  OPEN: open,
  OSH: osh,
  PACW: pacw,
  PARA: para,
  PARAA: paraa,
  PATK: patk,
  PBF: pbf,
  PCT: pct,
  PHM: phm,
  PLTR: pltr,
  PUMP: pump,
  PYPL: pypl,
  QNST: qnst,
  QS: qs,
  RADI: radi,
  RBLX: rblx,
  RCL: rcl,
  REGN: regn,
  RETA: reta,
  RH: rh,
  RITM: ritm,
  RLAY: rlay,
  ROKU: roku,
  RUN: run,
  RVNC: rvnc,
  RXDX: rxdx,
  RXO: rxo,
  S: s,
  SAFE: safe,
  SFM: sfm,
  SKIN: skin,
  SKX: skx,
  SKY: sky,
  SLB: slb,
  SLG: slg,
  SNBR: snbr,
  SONO: sono,
  SOVO: sovo,
  SPLK: splk,
  SPWR: spwr,
  SSTK: sstk,
  ST: st,
  STT: stt,
  SUMO: sumo,
  SVCF: svcf,
  SYBT: sybt,
  TELL: tell,
  TENB: tenb,
  TH: th,
  TITN: titn,
  TMUS: tmus,
  TRMK: trmk,
  TSP: tsp,
  TTWO: ttwo,
  TWTR: twtr,
  UAA: uaa,
  UBER: uber,
  ULTA: ulta,
  UNH: unh,
  UPS: ups,
  V: v,
  VFC: vfc,
  VRT: vrt,
  VRTX: vrtx,
  WAL: wal,
  WPC: wpc,
  WSC: wsc,
  WSFS: wsfs,
  WSM: wsm,
  WTFC: wtfc,
  WYNN: wynn,
  X: x,
  YEXT: yext,
  Z: z,
  ZION: zion,
  ZM: zm,
};
