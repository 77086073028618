import React from 'react';
import classNames from 'classnames';

import styles from '../ChartHeader.module.scss';

type Props = {
    symbol: string;
    company?: string;
}

const Company = ({ company, symbol }: Props) => {
  const className = classNames(
    styles.companyAndSymbolContainerStyle,
    { [styles.companyAndSymbolContainerStyleHidden]: company || symbol },
  );

  return (
    <div className={className}>
      <p className={styles.symbolStyle}>{symbol}</p>
      <p className={styles.companyStyle}>{company}</p>
    </div>
  );
};

export default Company;
