import config from '../../config';

import { BaseRequestService } from './BaseRequest.service';
import { IPaymentService } from './types';

const API_PATH: any = {
  getCardTransferStatus: '/card-transfer-status/',
};

export class PaymentService extends BaseRequestService implements IPaymentService {
  protected getBaseUrl(): string {
    return config.payment.url;
  }

  constructor(token: string | null) {
    super(token);
    if (token) this.setToken(token);

    this.setToken = this.setToken.bind(this);
  }

  /**
   * For DSK this call is needed in order to:
   *  1. Get card transfer status - PaymentPspStatusEnum (because DSK does not ping our services when the staus is updated)
   *  2. Update the status in CRM DB (see PaymentStatusEnum)
   */
  public getCardTransferStatus(paymentRef: string) {
    return this.api.get(API_PATH.getCardTransferStatus + paymentRef);
  }
}
