import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { Action } from 'redux';

import lang from '../../lib/language';
import { PageableFilterRequest } from '../../lib/models/fundamental/types';
import {
  getDividendPayers as getDividendPayersCompanies,
  getHighGrowthCompanies as getGrowthCompanies,
} from '../../lib/store/fundamental/index';
import { RootState } from '../../lib/store/index';

import { DividendPayerslTable, HighGrowthCompaniesTable, Navigation } from '../../components';
import { useIsMobile } from '../../hooks';

import styles from './DividendPayersAndHighGrowthCompanies.module.scss';

const DividendPayersAndHighGrowthCompaniesContainer = (props: ReduxProps) => {
  const { dividendPayers, highGrowthCompanies, getDividendPayers, getHighGrowthCompanies } = props;

  const isMobile = useIsMobile();
  const history = useHistory();
  const locationState = history.location.state as { currentTab: number | null, currentTabIndex?: number };
  const { currentTab, currentTabIndex } = locationState['navigation-state'];

  const dividendsAndHighGrowthTabs = [
    {
      text: lang.commonDiscoverDividendPayers(),
      component: <DividendPayerslTable content={dividendPayers.content} />,
    },
    {
      text: lang.commonDiscoverHighGrowthCompanies(),
      component: <HighGrowthCompaniesTable content={highGrowthCompanies.content} />,
    },
  ];

  const handleRenderTable = () => {
    if (dividendPayers.content.length > 0) {
      return (
        <>
          <h6 className={styles.title}>{lang.commonDiscoverDividendPayers()}</h6>
          <DividendPayerslTable content={dividendPayers.content} />
        </>
      );
    } if (highGrowthCompanies.content.length > 0) {
      return (
        <>
          <h6 className={styles.title}>{lang.commonDiscoverHighGrowthCompanies()}</h6>
          <HighGrowthCompaniesTable content={highGrowthCompanies.content} />
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    if (currentTab != null) {
      if (!dividendPayers.content.length && !currentTab) {
        getDividendPayers({ page: 0 });
      }
      if (!highGrowthCompanies.content.length && currentTab) {
        getHighGrowthCompanies({ page: 0 });
      }
    }
  }, [getDividendPayers, getHighGrowthCompanies, dividendPayers, highGrowthCompanies, locationState, currentTab]);

  useEffect(() => {
    if (currentTab == null
    && (!dividendPayers.content.length || !highGrowthCompanies.content.length)
    ) {
      getDividendPayers({ page: 0 });
      getHighGrowthCompanies({ page: 0 });
    }
  }, [dividendPayers, highGrowthCompanies, locationState, getDividendPayers, getHighGrowthCompanies, currentTab]);

  return (
    <>
      {(dividendPayers.content.length && highGrowthCompanies.content.length)
        ? (
          <div className={isMobile ? styles.wrapperMob : styles.wrapper}>
            <h6 className={styles.title}>{lang.commonDiscoverSubtitleLongTermInvestments()}</h6>
            <Navigation initialActiveTabIndex={currentTabIndex} tabs={dividendsAndHighGrowthTabs} />
          </div>
        )
        : handleRenderTable()}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dividendPayers: state.fundamental.dividendPayers,
  highGrowthCompanies: state.fundamental.highGrowthCompanies,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getDividendPayers: (payload: PageableFilterRequest) => dispatch(getDividendPayersCompanies(payload)),
  getHighGrowthCompanies: (payload: PageableFilterRequest) => dispatch(getGrowthCompanies(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DividendPayersAndHighGrowthCompaniesContainer);
