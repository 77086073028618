import { OrderStatusEnum } from '../../enums/order-status.enum';
import { TenNSOROrderStatus } from '../../models/gateway/types';
import { getDateTime } from '../../util/DateTimeHelpers';
import { mapOrderStatus } from '../../util/TradingHelpers';

import { ReportingState } from './types';

export const PENDING_ORDERS_REQUEST_FROM_DATE = getDateTime(true, [ 'minus' ], [ [ 3, 'months' ] ]);

export const FINRA_FEE = '$0.000119';
export const SEC_FEE = '$0.021';

export const PENDING_ORDERS_REQUEST_TENNSTATUS = [
  TenNSOROrderStatus.Pending,
  TenNSOROrderStatus.PartialFill,
].join(',');

export const TRADE_HISTORY_ORDERS_TENNSTATUSES = [
  TenNSOROrderStatus.PartialFill,
  TenNSOROrderStatus.Filled,
  TenNSOROrderStatus.Cancelled,
  TenNSOROrderStatus.Rejected,
  TenNSOROrderStatus.Expired,
  TenNSOROrderStatus.PartialReject,
].join(',');

export const DOWNLOAD_ORDER_REPORT_STATUS = [
  OrderStatusEnum.Filled,
  OrderStatusEnum.PartiallyFilled,
  OrderStatusEnum.PartiallyReject,
];

export const ORDERS_REQUEST_PAGE_NUMBER = 1;
export const ORDERS_REQUEST_ROWS_PER_PAGE = 15;
export const AVAILABLE_BORROWED_AMOUNT_COEF = 3;
export const DAILY_INTEREST_BORROWED_FUNDS = '0.008%';

export const TRADE_HISTORY_SIDE: Record<number, string> = {
  1: 'Buy',
  2: 'Sell',
  5: 'Sell',
};

export const COLORS = {
  green: '#4AD295',
  red: '#EF5B5B',
  warning: '#F6C98E',
};

export const PIE_CHART_WIDTH = 115;
export const PIE_CHART_WIDTH_HIGHLIGHT = 125;

export const EMPTY_ACCOUNT_REFERENCE = {
  instaCash: 0,
  availableCash: 0,
};

export const EMPTY_ACCOUNT_SUMMARY = {
  accountReferenceId: '',
  currency: '',
  cashBalance: 0,
  tradingCharges: 0,
  accountCharges: 0,
  realizedPnL: 0,
  realTimeCash: 0,
  hardBPFactorID: 0,
  hardBPFactorON: 0,
  netCash: 0,
  retailCash: 0,
};

export const INITIAL_STATE: ReportingState = {
  tradeHistoryOrders: [],
  tradeHistoryNextPageToRead: 0,
  pendingOrders: [],
  loadingExecutions: false,
  executionsFailed: false,
  accountOpenPositionsCompleted: false,
  executionsResult: [],
  accountsAvailableCash: {
    hasLoaded: false,
    data: EMPTY_ACCOUNT_REFERENCE,
  },
  netIncomeComponents: [],
  openPositions: [],
  accountSummary: EMPTY_ACCOUNT_SUMMARY,
  orderDetail: {
    symbol: '',
    orderDate: '',
    status: 0,
    parentOrderId: '',
    type: 0,
    side: 0,
    totalQuantity: 0,
    sorExecutedQuantity: 0,
    aqxExecutedQuantity: 0,
    averageFilledPrice: 0,
    orderRequestedPrice: 0,
    timeInForce: 0,
    expirationDate: '',
    stopPrice: 0,
    fillDate: '',
    lastUpdatedDateTime: '',
    orderValue: 0,
    grossRealizedPnl: 0,
    regulatoryFees: 0,
    taf: 0,
    sec: 0,
  },
  orderDetailCompleted: false,
  error: {
    status: null,
    hasRequestError: false,
  },
  peopleAlsoTradeData: [],
  statusByCall: {},
  errorsLog: [],
  hasMyStocksOrderUpdate: false,
};

export const NO_MORE_PAGES_TO_READ = -1;

// TODO:In order to use this on Mobile, this was copied from WEB to LIB. WEB needs to be refactored to use the same in WEB
export const statusOptionsData = [
  OrderStatusEnum.Filled,
  OrderStatusEnum.PartiallyFilled,
  OrderStatusEnum.PartiallyReject,
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Rejected,
  OrderStatusEnum.OtherwiseExpired.split(' ')[1],
];

// TODO:In order to use this on Mobile, this was copied from WEB to LIB. WEB needs to be refactored to use the same in WEB
export const statusOptionsLabels = statusOptionsData.map(value => mapOrderStatus(value));
