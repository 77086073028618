import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { CURRENT_YEAR } from '../../../lib/constants/date-time.constants';
import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { setSourceOfFundsIsEdit } from '../../../lib/store/crm';
import { getSourceOfFundsData } from '../../../lib/store/selectors';
import { validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import AutocompleteControl from '../../AutocompleteControl/AutocompleteControl';
import { IOption } from '../../SelectControl/SelectControl.types';
import { GeneralQuestionsCustomAnswers } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import {
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_TO,
  KEY_SOURCE_OF_FUND_TYPE,
} from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';
import YearDatePicker from '../YearDatePicker/YearDatePicker';

import styles from '../EnrollSourceOfFunds/EnrollSourceOfFunds.module.scss';

const options: IOption[] = [
  { label: lang.commonSourceOfFundsPension(), value: lang.commonSourceOfFundsPension() },
  { label: lang.commonSourceOfFundsSocialBenefits(), value: lang.commonSourceOfFundsSocialBenefits() },
];

type ReduxStateProps = {
  sourceOfFunds?: any;
  isEditSourceOfFunds?: boolean;
  isClickedBtnContinue: boolean;
}

type Props = {
  formData: GeneralQuestionsCustomAnswers;
  handleChange: (formKey: string, value: string | number) => void;
} & ReduxStateProps

const EnrollPension = ({
  formData,
  handleChange,
  sourceOfFunds,
  isEditSourceOfFunds,
  isClickedBtnContinue,
}: Props):JSX.Element => {
  const [customData, setCustomData] = useState(formData);
  const dispatch = useDispatch();

  if (sourceOfFunds && !isEditSourceOfFunds) {
    customData[KEY_SOURCE_OF_FUND_FROM] = sourceOfFunds.from;
    customData[KEY_SOURCE_OF_FUND_TO] = sourceOfFunds.to;
    customData[KEY_SOURCE_OF_FUND_TYPE] = sourceOfFunds.fund_type;
    dispatch(setSourceOfFundsIsEdit(true));
    handleChange('', '');
  }

  const datePickerFromYear = customData[KEY_SOURCE_OF_FUND_FROM] as string;
  const datePickerToYear = customData[KEY_SOURCE_OF_FUND_TO] as string || CURRENT_YEAR.toString();

  return (
    <div className={styles.wrapper}>
      <YearDatePicker
        formErrors={isClickedBtnContinue
          ? validateControl(
            datePickerFromYear as string,
            [],
            lang.commonSourceOfFundsDateFromLabel(),
            true,
          ).errors
          : []}
        datePickerToYear={datePickerToYear}
        datePickerFromYear={datePickerFromYear}
        handleChange={handleChange}
        setCustomData={setCustomData}
      />
      <Row className={styles.row}>
        <Col md={12}>
          <AutocompleteControl
            isReadOnly
            id="enrollPension"
            options={options}
            placeholder={lang.commonSourceOfFundsPensionBenefitsTypesTitle()}
            value={customData[KEY_SOURCE_OF_FUND_TYPE] as string}
            onValueChanged={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_TYPE]: value,
              }));
            }}
            onBlur={(value: string | number | null) => {
              value && handleChange(KEY_SOURCE_OF_FUND_TYPE, value);
            }}
            errors={isClickedBtnContinue
              ? validateControl(
                customData[KEY_SOURCE_OF_FUND_TYPE],
                [],
                lang.commonSourceOfFundsPensionBenefitsTypesTitle(),
                true,
              ).errors
              : []}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState): any => ({
  sourceOfFunds: getSourceOfFundsData(state, SourceOfFundEnum.PensionOrBenefitProgramAlaric),
  isEditSourceOfFunds: state.crm.enroll.isEditSourceOfFunds,
});

export default connect(mapStateToProps)(EnrollPension);
