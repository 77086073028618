import React, { useMemo } from 'react';

import { ButtonTypesEnum } from './Button.types';

import styles from './Button.module.scss';

type Props = {
  id?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ariaLabel?: string;
  variant?: ButtonTypesEnum;
  fullWidth?: boolean;
  children: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  secondarySize?: boolean;
}

const Button = (props: Props): JSX.Element => {
  const {
    id,
    children,
    onClick,
    ariaLabel,
    className,
    disabled = false,
    fullWidth = false,
    secondarySize = false,
    variant = ButtonTypesEnum.Primary,
  } = props;

  const buttonTypeStyles = useMemo(() => {
    switch (variant) {
      case ButtonTypesEnum.Primary:
        return styles.primary;
      case ButtonTypesEnum.Secondary:
        return styles.secondary;
      case ButtonTypesEnum.Tertiary:
        return styles.tertiary;
      case ButtonTypesEnum.Link:
        return styles.link;
      default:
        return styles.primary;
    }
  },
  [variant]);

  const buttonClasses = useMemo((): string => (
    [
      styles.btn,
      className,
      buttonTypeStyles,
      fullWidth && styles.block,
      secondarySize && styles.secondarySize,
    ].join(' ')
  ), [buttonTypeStyles, className, fullWidth, secondarySize]);

  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled}
      className={buttonClasses}
    >
      {children}
    </button>
  );
};

export default Button;
