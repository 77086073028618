import React from 'react';

import lang from '../../../../lib/language';
import { navigationButtonTextLong, navigationButtonTextShort, navigationButtonTextTotal } from '../../../../lib/util/my-account-helpers';

import { ClickableDiv } from '../../../../components/index';

import styles from './NavigationButtons.module.scss';

type Props = {
    activeTab: string;
    handleClick: (value: string) => void;
}

const NavigationButtons = ({ activeTab, handleClick }: Props) => {
  const setActiveTabStyle = (currentTab: string) => {
    if (currentTab === activeTab) {
      return styles.activeTab;
    }

    return '';
  };

  return (
    <div className={styles.buttonsWrapper}>
      <ClickableDiv
        className={setActiveTabStyle(navigationButtonTextTotal)}
        onClick={() => handleClick(lang.commonAccountBreakdownTotal())}
      >
        <span>{lang.commonAccountBreakdownTotal()}</span>
      </ClickableDiv>
      <ClickableDiv
        className={setActiveTabStyle(navigationButtonTextLong)}
        onClick={() => handleClick(lang.commonAccountBreakdownLong())}
      >
        <span>{lang.commonAccountBreakdownLong()}</span>
      </ClickableDiv>
      <ClickableDiv
        className={setActiveTabStyle(navigationButtonTextShort)}
        onClick={() => handleClick(lang.commonShort())}
      >
        <span>{lang.commonShort()}</span>
      </ClickableDiv>
    </div>
  );
};

export default NavigationButtons;
