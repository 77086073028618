import { DEFAULT_PAGE_RESPONSE } from '../../models/pageable';

import { FundamentalState, SectorEnum } from './types';

export const DEFAULT_SEARCH_STOCK_LIMIT = 15;
export const SEARCH_STOCK_MAX_LIMIT = 60;

export const INITIAL_STATE: FundamentalState = {
  stockQuery: '',
  limit: DEFAULT_SEARCH_STOCK_LIMIT,
  stockResults: [],
  symbol: '',
  keyStats: [],
  // TODO: Stop using company name form companies after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  companies: [],
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  logosBase64: {},
  companyLogoAndName: {},
  error: undefined,
  errorsLog: [],
  popularStocks: [],
  newStocks: [],
  dividendPayers: DEFAULT_PAGE_RESPONSE,
  highGrowthCompanies: DEFAULT_PAGE_RESPONSE,
  sectors: [],
  sectorDetails: {
    content: [],
    nextPageToRead: 0,
    sectorId: 0,
    sector: '',
  },
  topGainersAndLosersFilterValues: {
    minValue: 15,
    maxValue: 50,
  },
  IPOs: [],
  earnings: [],
  earningsSearchResponse: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    size: 0,
    number: 0,
    numberOfElements: 0,
    sort: [],
    status: 0,
  },
  splits: [],
  statusByCall: {},
  marketIndexes: [],
  ETFData: {
    nextPage: 0,
    totalElements: 0,
    content: [],
  },
};

export const NAMES_SECTORS_CONTAINING_IMAGES: string[] = [
  SectorEnum.Communications,
  SectorEnum.Healthcare,
  SectorEnum.ConsumerCyclical,
  SectorEnum.Technology,
  SectorEnum.Utilities,
  SectorEnum.ConsumerDefensive,
  SectorEnum.FinancialServices,
  SectorEnum.BasicMaterials,
  SectorEnum.RealEstate,
  SectorEnum.Industrials,
  SectorEnum.Energy,
];

export const GET_FIRST_ITEM_SIZE = 1;
export const DEFAULT_PAGE = 0;
export const DEFAULT_SECTOR_DETAILS_SIZE = 15;
export const DEFAULT_SECTOR_PAGE = 0;
export const NO_MORE_PAGES_TO_READ = -1;

export const marketIndexesArray = [
  {
    symbol: '^DJI',
    shownAs: 'DOW Industrial',
    indexName: 'Dow Jones Industrial Average',
  },
  {
    symbol: '^GSPC',
    shownAs: 'S&P 500',
    indexName: 'S&P 500',
  },
  {
    symbol: '^NDX',
    shownAs: 'NASDAQ 100',
    indexName: 'NASDAQ 100',
  },
  {
    symbol: '^RUI',
    shownAs: 'Russell 1000',
    indexName: 'Russell 1000',
  },
  {
    symbol: '^VIX',
    shownAs: 'CBOE VIX',
    indexName: 'CBOE Volatility Index',
  },
];

export const symbolSet = {
  RETAIL: 'RETAIL',
};

export const etfProjections = [ 'symbol', 'type', 'name' ].join(',');
export const etfDetailProjections = [ 'symbol', 'avg30Volume', 'logoBase64' ].join(',');
