import React, { useCallback, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';

import { RootState } from '../../lib/store';
import { CHART_DATA_SYMBOL_RANGE } from '../../lib/store/market-data/charting/types';

import ClickableDiv from '../ClickableDiv/ClickableDiv';

import { chartData, chartOptions } from './constants';

import styles from './PortfolioSummary.module.scss';

const LineHeightAnnotation = require('chartjs-plugin-lineheight-annotation');

type ReduxProps = {
  totalAccountValue?: string;
}

type Props = ReduxProps;

const PortfolioSummary = ({ totalAccountValue } : Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onDateRangeItemClick = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const dateRange = useMemo(
    () =>
      CHART_DATA_SYMBOL_RANGE.slice(0, 5).map((item: string, index: number) => (
        <ClickableDiv
          key={item}
          className={classNames(styles.dataRangeItem, {
            [styles.activeItem]: activeIndex === index,
          })}
          onClick={() => onDateRangeItemClick(index)}
        >
          {item.substring(0, 2)}
        </ClickableDiv>
      )),
    [activeIndex, onDateRangeItemClick],
  );

  return (
    <>
      <Row className="mt-4">
        <Col md={12}>
          <h1 className={styles.headerText}>Portfolio Summary</h1>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <h1 className={styles.bodyText}>Total Value</h1>
        </Col>
        <Col md={12}>
          <h1 className={styles.valueText}>{totalAccountValue}</h1>
        </Col>
      </Row>
      <Row className={styles.datesRow}>
        <Col md={12} sm={12} xs={12}>
          <div className={styles.dateRangeContainer}>{dateRange}</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div style={{ marginRight: '-13px' }}>
            <Line
              data={chartData}
              options={chartOptions}
              height={150}
              plugins={[LineHeightAnnotation]}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: RootState): any => ({
  totalAccountValue: state.crm.totalAccountValue,
});

export default connect(mapStateToProps)(PortfolioSummary);
