import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { InstrumentSnapshotData, InstrumentSnapshotMap } from '../store/market-data/types';

import CallsCache from './calls-cache/CallsCache';


const InstrumentSnapshotCache = entity<Record<string, InstrumentSnapshotData>>({}, entityReduxLogger('InstrumentSnapshot', 'instrument-snapshot'));
const self = InstrumentSnapshotCache;

/**
 * Returns only symbols that are not present in cache
 */
function getOnlyNew(symbols: string[]) {
  const currentCache = self.get();
  const result: string[] = [];
  symbols.forEach(symbol => {
    if (!currentCache[symbol]) {
      result.push(symbol);
    }
  });
  return result;
}

/** Only used by market-data/index reducer for instrumentSnapshotReceived */
export function updateInstrumentSnapshotCache(newData: InstrumentSnapshotMap, traceId: string) {
  CallsCache.processResponseByTraceId(traceId, 'getInstrumentSnapshot');
  self.set(produce(state => {
    Object.entries(newData).forEach(([ key, value ]) => {
      state[key] = value;
    });
  }));
}

export default {
  get: (symbol: string) => InstrumentSnapshotCache.get()[symbol],
  getOnlyNew,
  use: (symbol: string) => InstrumentSnapshotCache.use()[symbol],
  useAll: InstrumentSnapshotCache.use,
};
