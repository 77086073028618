export type HeaderDropdownItem = {
  link: string;
  text: string;
  alignBottom?: boolean;
  isDisabled?: boolean
  action?: (...args: any[]) => void;
};

export interface IHeaderDropdownNavProps {
  dropdownItemsMyProfile: HeaderDropdownItem[];
}

export enum MyProfileOptions {
  PPERSONAL_DETAILS = 1,
  DOCUMENTS,
  CHANGE_PASSWORD,
  LOG_OUT
}
