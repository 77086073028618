import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';

import { ControlError } from '../../lib/util/ValidationSchemes/ValidationTypes';

import iconExpandDown from '../../assets/img/icon-expand-more-down.svg';
import Country from '../Country/Country';

import { ICountryControlOptions, ICountryControlProps } from './CountryControl.types';

import styles from './CountryControl.module.scss';

const textStyles = makeStyles({
  formControlRoot: {
    '& label.Mui-focused': {
      transform: 'translate(34px, -100%) scale(0.75)',
      color: '#A0A3B9',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(34px, -100%) scale(0.75)',
      color: '#A0A3B9',
    },
  },
});

const CountryControl = (
  {
    id,
    name,
    placeholder,
    value,
    options,
    onSelectChange,
    errors = [],
    className,
    onCountryChange,
    asPhoneControl,
    onSubmit,
    ariaLabel,
  }: ICountryControlProps,
): JSX.Element => {
  const textClasses = textStyles();

  const [isControlDirty, setIsControlDirty] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const filterOptions: (options: ICountryControlOptions[], state: any) =>
  ICountryControlOptions[] = createFilterOptions({
    matchFrom: asPhoneControl ? 'any' : 'start',
  });

  useEffect(() => {
    if (!isControlDirty && (options.length > 0) && value) {
      let optionToEmit = options[0];

      if (value.trim() !== '') {
        const filteredOptions = options.find((option: ICountryControlOptions) => (
          option.value.toString().toLowerCase() === value.toLowerCase()));

        optionToEmit = filteredOptions ?? options[0];
      }

      setSelectedOption(optionToEmit);
    }
  }, [isControlDirty, onCountryChange, options, value]);

  const paperComponent = (children: any) => (
    <Paper
      className={styles.paper}
      style={asPhoneControl ? { width: 300 } : {}}
    >
      {children}
    </Paper>
  );

  const inputComponent = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={placeholder}
      className={styles.inputControl}
      InputProps={{
        ...params.InputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
        'aria-label': ariaLabel,
        itemID: id,
      }}
    />
  );

  const optionComponent = (option: any) => (
    <Country
      name={option.label as string}
      id={option.countryId ?? option.value as string}
      phoneCode={asPhoneControl ? option.value : null}
    />
  );

  const handleOnKeyPress = (event: { key: any }) => {
    const { key } = event;
    key === 'Enter' && onSubmit?.();
  };

  return (
    <div
      className={[
        styles.wrapper,
        asPhoneControl && styles.phoneControl,
        className,
      ].join(' ')}
    >
      {selectedOption && (
        <div className={styles.flag}>
          <Country id={selectedOption.countryId ?? selectedOption.value.toString()} />
        </div>
      )}
      <Autocomplete
        openOnFocus
        disableClearable
        autoHighlight
        options={options}
        filterOptions={filterOptions}
        value={selectedOption ?? null}
        className={styles.control}
        popupIcon={<img id="countryChoiceIcon" aria-label="country choice drop down icon" src={iconExpandDown} alt="up down icon" />}
        getOptionLabel={(option) => (asPhoneControl ? option.value.toString() : option.label)}
        classes={{ root: textClasses.formControlRoot }}
        PaperComponent={({ children }) => paperComponent(children)}
        renderInput={(params: AutocompleteRenderInputParams) => inputComponent(params)}
        renderOption={(option: ICountryControlOptions) => optionComponent(option)}
        onChange={(event: ChangeEvent<any>, selectedValue) => {
          const option = selectedValue as ICountryControlOptions;

          setSelectedOption(option);
          setIsControlDirty(true);
          onSelectChange && onSelectChange(event);
          onCountryChange && onCountryChange(option.value as string);
        }}
        getOptionSelected={(option: ICountryControlOptions, currentOption: ICountryControlOptions) => (
          (currentOption.value !== '') && (option.value === currentOption.value)
        )}
        onKeyUp={handleOnKeyPress}
      />

      {errors.map((error: ControlError) => (<p key={error.id} className={styles.error}>{error.error}</p>))}
    </div>
  );
};

export default CountryControl;
