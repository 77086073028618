export default {
  // -----------   Common   -----------

  common10n: '10n',
  commonAll: 'All',
  commonViewAll: 'View All',
  commonXtoY: '@@1 to @@2',
  commonOver: 'Over',
  commonOverX: 'Over @@1',
  commonUpTo: 'Up to',
  commonDate: 'Date',
  commonSearch: 'Search',
  commonShowAll: 'Show All',
  commonDocuments: 'Documents',
  commonClose: 'Close',
  commonFavourites: 'Favourites',
  commonRemove: 'Remove',
  commonDefault: 'Default',
  commonLastPrice: 'Last Price',
  commonIndustry: 'Industry',
  commonNext: 'NEXT',
  commonConfirm: 'CONFIRM',
  commonOK: 'OK',
  commonSave: 'Save',
  commonLoadMore: 'Load more',
  commonDetails: 'Details',
  commonButtonsTryAgain: 'Try again',
  commonButtonContactSupport: 'Contact Support.',
  commonSupport: 'support',
  commonLogout: 'Log out',
  commonPopupTitleInformation: 'Information',
  commonReturnToHomePage: 'Return to Home Page',
  commonYear: 'Year',
  commonNoInternetConnection: 'No Internet Connection',
  commonPoorInternetConnection: 'Poor Internet Connection',
  commonOrderSummary: 'Order Summary',
  commonSymbol: 'Symbol',
  commonAsset: 'Asset',
  commonLearnMoreAt: 'Learn more here.',

  // Request error messages
  requestErrorOver400: 'Unknown error.',
  requestErrorOver500: 'Ooops. Something crashed!',

  // Change Password
  commonChangePasswordNewPassword: 'New Password',
  commonChangePasswordCurrentPassword: 'Current Password',

  // common preloading messages
  commonMessageLoading: 'Loading @@1...',
  commonMessageConnecting: 'Connecting ...',
  commonMessageRefreshing: 'Refreshing ...',
  commonMessageConnectionTimedOut: 'Connection timed out. @@1Please check your internet connection and try again ...',
  commonMessageNoData: 'No data available.',
  commonMessageConnectingMerchant: 'Connecting merchant ...',

  // orders
  commonOrderSideButtonBuy: 'Buy',
  commonOrderSideButtonSell: 'Sell',
  commonOrderSideButtonKeepIt: 'No, keep it',
  commonOrderSideButtonProceed: 'Yes, proceed',

  // order status map
  commonOrderStatusPending: 'Pending',
  commonOrderStatusPendingApproval: 'Pending approval',
  commonOrderStatusCompleted: 'Completed',
  commonOrderStatusUnknown: 'Unknown',
  commonOrderStatusAccepted: 'Accepted',
  commonOrderStatusCanceled: 'Canceled',
  commonOrderStatusSubmitted: 'Submitted',
  commonOrderStatusQueued: 'Queued',
  commonOrderStatusRejected: 'Rejected',
  commonOrderStatusInProgress: 'In Progress',
  commonOrderStatusFilterFilled: 'Filled',
  commonOrderStatusFilterPartiallyFilled: 'Partially Filled',
  commonOrderStatusFilterTitle: 'Status',
  commonOrderStatusFilterAll: 'All',
  commonOrderStatusFilterExpired: 'Expired',
  commonOrderStatusFilterCancelled: 'Cancelled',
  commonOrderStatusUndefined: 'Undefined',

  // order time in force map
  commonOrderTimeInForceDay: 'Day',
  commonOrderTimeInForceOnOpen: 'On open',
  commonOrderTimeInForceIOC: 'IOC',
  commonOrderTimeInForceExtDay: 'Extended day',
  commonOrderTimeInForceOnClose: 'On close',

  // watchlist
  commonWatchlist: 'Watchlist',
  commonWatchlistTitle: 'Welcome@@1',
  commonWatchlistChartLoading: 'Loading',
  commonWatchlistChartNoData: 'No data',
  commonWatchlistChartNA: 'n/a',
  commonWatchlistRetry: 'Retry',
  commonWatchlistCongratulations: 'Congratulations',
  commonWatchlistYourAccountHasBeenVerified: 'Your account has been verified',
  symbolDetailsAddToWatchlist: 'Add to Favourites',
  symbolDetailsAddedToWatchlist: 'Added to Favourites',
  commonSelectWatchlist: 'Select Watchlist:',
  commonSymbolAddedToWatchlistMsg: '@@1 has been added to your Favourites watchlist.',
  commonSymbolRemovedToWatchlistMsg: '@@1 has been removed from your Favourites watchlist.',
  commonWatchlistNoStocks: 'No stocks have been added to favourites',
  commonWatchlistFavoutesLimit: 'Add up to 15 of your favourite stocks here',
  commonWatchlistFavoutesLimitReached: 'Your favourites watchlist is already full - 15 out of 15 stocks.',
  commonQuantityXAveragePrice: 'Qty x Avg. Price',
  commonUnrealizedProfit: 'Unrealized Profit',
  commonMarketValue: 'Market Value',

  // watchlist headers my account -> my stocks
  headerMyAccountMyStockSymbolName: 'Symbol/Name',
  headerMyAccountMyStockQuantity: 'Quantity',
  headerMyAccountMyStockPurchasePrice: 'Purchase Price',
  headerMyAccountMyStockMarketPrice: 'Market Price',
  headerMyAccountMyStockUnrealizedProfit: 'Unrealized Profit',
  headerMyAccountMyStockUnrealizedProfitPercent: 'Unrealized Profit %',
  headerMyAccountMyStockButtons: 'buttons',

  // watchlist headers my account -> pending orders
  headerMyAccountPendingOrderSymbolName: 'Symbol/Name',
  headerMyAccountPendingOrderDatePlaced: 'Date Placed',
  headerMyAccountPendingOrderType: 'Type',
  headerMyAccountPendingOrderQuantity: 'Quantity',
  headerMyAccountPendingOrderSetPrice: 'Set Price',
  headerMyAccountPendingOrderExpiryDate: 'Expiry Date',
  headerMyAccountPendingOrderButtons: 'buttons',

  // verification
  commonVerificationVerifyingIdentity: 'Verifying Identity',
  commonVerificationYourDocumentsAreUnderReview: 'Your documents are under review.',
  commonVerificationThisMayTakeUpToTwoBusinessDays: 'This may take up to 2 business days.',
  commonVerificationPhoneSubtitle: 'This step will help us further protect your personal details.',

  // stock details
  commonStockDetailsStatsTitle: 'Statistics',
  commonStockDetailsStatsAvgVolume: 'Avg. Volume',
  commonStockDetailsStatsPrevClose: 'Prev. Close',
  commonStockDetailsStatsOpen: 'Open',
  commonStockDetailsStatsLow: 'Low',
  commonStockDetailsStatsHigh: 'High',
  commonStockDetailsStatsMarketCap: 'Mkt. Cap',
  commonStockDetailsStatsWeek52Low: '52Wk. Low',
  commonStockDetailsStatsWeek52High: '52Wk. High',
  commonStockDetailsStatsPE: 'P/E Ratio',
  commonStockDetailsStatsDivYield: 'Div. Yield',
  commonStockDetailsAboutCompanyTitle: 'About the company',
  commonStockDetailsAboutCompanyCeo: 'CEO',
  commonStockDetailsAboutCompanyEmployees: 'Employees',
  commonStockDetailsAboutCompanySector: 'Sector',
  commonStockDetailsAboutCompanyIndustry: 'Industry',
  commonStockDetailsAboutCompanyHeadquarters: 'Headquarters',
  commonStockDetailsRecentNewsTitle: 'Latest News',
  commonStockDetailsMarketClosedMessage: 'Тhe stock market is currently closed.\nPre-market starts in: ',
  commonStockDetailsStatsPeopleAlsoTradeTitle: 'People also trade',
  commonStockDetailsReadMore: 'Read more',
  commonStockDetailsShowMore: 'Show more',
  commonStockDetailsShowLess: 'Show less',

  // other
  commonSearchForStocks: 'Search for stocks ...',
  commonSearchForSymbolTitle: 'Symbols',
  commonSearchForETFsTitle: 'ETFs',
  commonSearchForSymbol: 'Search by symbol ...',
  commonSearchForNoSymbolFound: 'No symbols found',
  commonInvalidValue: '-',
  commonNextButton: 'Next',
  commonConfirmButton: 'Confirm',
  commonContinueButton: 'Continue',
  commonDoneButton: 'Done',
  commonSendButton: 'Send',
  commonProceedButton: 'Proceed',
  commonFrom: 'From',
  commonTo: 'To',
  commonYearPicker: 'Pick year',
  commonCancelButton: 'Cancel',
  commonFundButton: 'Fund',
  commonRequestChangeButton: 'Request Change',
  commonYes: 'Yes',
  commonNo: 'No',
  commonOkay: 'Okay',
  commonDontShowAgain: 'Please, do not show this again',
  commonNotNow: 'Not now',
  commonGoBackBtn: 'Go Back',

  commonNotActivatetFundsPopUpText: 'Before funding your account you have to finalize your registration.',

  // onboarding
  commonOnboardingInitialCountryTitle: 'Choose your Country',
  commonOnboardingInitialCountryLabel: 'Country of residence',

  // Next Steps Activation screen
  commonNextStepsTitle: '3 more steps to start trading!',
  commonNextStepsTitleVerification: '2 more steps to start trading!',
  commonNextStepsTitleActive: '1 more step to start trading!',
  commonNextStepsButtonTitle_1: 'Create your account',
  commonNextStepsButtonTitle_2: 'Activate your account',
  commonNextStepsButtonTitle_3: 'Make your first deposit',
  commonNextStepsButtonTitle_4: 'Verify your identity',

  // account activation
  commonOnboardingAccountActivationTitle: 'Account Activation',

  // Enrollment Recovery
  commonEnrollmentPersonalDetailsTitle: "Let's start with the basics",
  commonEnrollmentRecoveryTitle: 'Recovering Enrollment',
  commonEnrollmentRecoverySubTitle: 'The application has reopened your previous step of the enrollment process. You can now continue',
  commonEnrollmentRecoveryButtonTitle: 'The application has reopened your previous step of the enrollment process. You can now continue',

  // Not Active Individual Notification
  commonNotActiveIndividualNotificationEnrollStepTitle: 'One step closer to start trading!',
  commonNotActiveIndividualNotificationEnrollStepDescription:
    'Now that you have your sign-up ready, you need to Activate your account. Verify it and make your first Deposit!',
  commonNotActiveIndividualNotificationVerificationStepTitle: 'Verify your identity!',
  commonNotActiveIndividualNotificationVerificationStepDescription: "And fund your account. Once done you'll be set to trade.",
  mobileNotActiveIndividualNotificationVerificationStepDescriptionRow1: 'And fund your account. Once done you\'ll be',
  mobileNotActiveIndividualNotificationVerificationStepDescriptionRow2: 'set to trade.',
  commonNotActiveIndividualNotificationPendingVerificationStepTitle: 'Nearly there!',
  commonNotActiveIndividualNotificationPendingVerificationStepDescription:
    'We are verifying your documents.\nThis may take up to 3 business days.',

  // General Questions
  commonGeneralQuestionsTypeLabel: 'Type',
  commonGeneralBinaryQuestionsError: 'Please pick any of the two options.',

  commonGeneralQuestionsError: 'Please pick @@1.',
  commonGeneralQuestionsDateError: "Please pick 'from' date.",
  commonGeneralQuestionsDateFromBiggerThanToError: "Please pick year smaller than 'to' year.",

  commonGeneralQuestionsSubTitle1:
    'The questions that follow rate your trading experience and knowledge and help us keep your best interest at heart',
  commonGeneralQuestionsSubTitle2:
    'We are required by regulatory authorities to collect this. Rest assured, your information is protected.',
  commonGeneralQuestionsSubTitle3: 'Savings, investments and other assets (including property)',
  commonUnspecifiedKnowledgeAndExperienceSectionTitle: 'Please choose the correct statement:',
  commonEmploymentStatusSectionTitle: 'What is your employment status?',
  commonInvestmentAmountSectionTitle: 'How much do you plan to invest?',
  commonAnnualNetIncomeSectionTitle: 'What is your annual net income?',
  commonEstimatedNetWorthSectionTitle: 'What is your estimated net worth?',
  commonAmountTradesPerYearhSectionTitle: 'What is the amount of your trades per year?',
  commonLeveragedProductsKnowlegeSectionTitle: 'What is your knowledge of leveraged products?',
  commonLeveragedProductsKnowlegeSectionSubTitle: 'Stocks on margin, CFDs, Forex, futures, options',
  commonGroupQuestionStatement1:
    'When the value of your account falls below the required margin it will trigger an automated close-out of positions.',
  commonGroupQuestionStatement2: 'To place a $10,000 order on a 10:1 leverage, you will need to commit $2,000 of your capital.',
  commonAppropriatenessModalTitle: 'Financial Knowledge Assessment',
  commonAppropriatenessModalBody1: 'We need to inform you that on the basis of the answers provided about your knowledge and experience in the investment field, trading leveraged products is not appropriate for you. Trading leveraged products carries a high degree of risk and may result in losses greater than your initial deposit.',
  commonAppropriatenessModalBody2: 'By clicking Continue you declare you understand the warning and you are aware of the risks of trading leveraged products and confirm that you have the necessary knowledge and expertise to fully appreciate the risks involved.',
  commonAppropriatenessModalBody3: 'We have also sent you an email with the information above.',
  commonIndustryTitle: 'Industry of your occupation',
  commonInlineHelpButton: 'Not sure what to do? Contact us',
  commonSourceOfFundsFromLabel: 'From',
  commonSourceOfFundsToLabel: 'To',
  commonSourceOfFundsDateFromLabel: 'Date from',
  commonSourceOfFundsSectionTitle: 'What is your main purpose of trading with 10n?',
  commonSourceOfFundsSalaryTitle: 'Salary',
  commonSourceOfFundsSalarySubtitle: 'We need employment period & details to fulfill regulatory requirements for trading',
  commonSourceOfFundsNameOfEmplLabel: 'Name of employer',
  commonSourceOfFundsNameOfCustomer: 'Name of customer',
  commonSourceOfFundsAddressOfEmplLabel: 'Address of employer',

  commonSourceOfFundsSelfEmployedTitle: 'Self-employed',
  commonSourceOfFundsSelfEmployedSubtitle: 'We need self-employment period & details to fulfill regulatory requirements for trading',
  commonSourceOfFundsHaveMainCustomer: 'I have a main customer',
  commonSourceOfFundsNameOfCustomerLabel: 'Name of main customer',
  commonSourceOfFundsAddressOfCustomerLabel: 'Address of main customer',

  commonSourceOfFundsHomeTradingTitle: 'At home trading',
  commonSourceOfFundsHomeTradingSubtitle: 'We need at home trading period & details to fulfill regulatory requirements for trading',
  commonSourceOfFundsBrokerNameLabel: 'Broker name',

  commonSourceOfFundsFamMemberTitle: 'Family member',
  commonSourceOfFundsFamMemberSubtitle:
    'Please share the period and name of the person providing you with the funds. We need this to fulfill regulatory requirements',
  commonSourceOfFundsFamMemberNameLabel: 'Name of family member',

  commonSourceOfFundsInheritanceTitle: 'Inheritance',
  commonSourceOfFundsInheritanceSubtitle: 'We need details about your source of funds to fulfill regulatory requirements for trading',
  commonSourceOfFundsYearOfLabel: 'Year of inheritance',
  commonSourceOfFundsNameOfDeceasedLabel: 'Name of deceased person',
  commonSourceOfFundsBirthYearLabel: 'Birth year of deceased person',

  commonSourceOfFundsInvestmentSavingsTitle: 'Investment & Savings',
  commonSourceOfFundsInvestmentSavingsSubtitle: 'We need details about your source of funds to fulfill regulatory requirements for trading',
  commonSourceOfFundsInvestmentSavingsTypesTitle: 'Type',
  commonSourceOfFundsInvestmentSavingsTypes: 'Real estate,Financial instruments,Dividents,Other',
  commonSourceOfFundsInvestmentSavingsTypesPleaseSpecify: 'Please specify',
  commonSourceOfFundsInvestmentSavingsTypesRealEstate: 'Real estate',

  commonSourceOfFundsTitle: 'What is the source of funds you intend to invest?',
  commonSourceOfFundsPensionBenefitsTitle: 'Pension & Benefits Program',
  commonSourceOfFundsPensionBenefitsSubtitle: 'We need details about your source of funds to fulfill regulatory requirements for trading',
  commonSourceOfFundsPensionBenefitsTypes: 'Pension,Social benefits',
  commonSourceOfFundsPensionBenefitsTypesTitle: 'Type',
  commonSourceOfFundsPension: 'Pension',
  commonSourceOfFundsSocialBenefits: 'Social benefits',

  commonSourceOfFundsLoanTitle: 'Loan',
  commonSourceOfFundsLoanSubtitle: 'We need details about your source of funds to fulfill regulatory requirements for trading',
  commonSourceOfFundsLenderNameLabel: 'Name of lender',
  commonSourceOfFundsLenderType: 'Type of lender',
  commonSourceOfFundsLoanTypes: 'Bank / Other institution,Another person / Entitiy',

  // onboarding
  commonOnboardingSectionTitlePersonalData: 'Personal Data',
  commonOnboardingSectionTitleKnowledgeAndExperience: 'Experience',
  commonOnboardingSectionTitlePurposeAndStatus: 'Purpose & Status',
  commonOnboardingSectionTitleAgreementsAndPolicies: 'Agreements & Policies',

  // transitions screens
  commonOnboardingTransitionButtonTitle: 'Go ahead',
  commonOnboardingTransitionPurposeAndStatus: 'Purpose & Status',
  commonOnboardingTransitionPurposeAndStatusDescription: 'This chapter will help us define your trading profile.',
  commonOnboardingTransitionExperience: 'Experience',
  commonOnboardingTransitionExperienceDescription:
    'The questions that follow rate your trading experience and knowledge and help us keep your best interest at heart',

  // startup screen
  commonOnboardingCarousel1Title: 'Welcome to 10n',
  commonOnboardingCarousel1Description: 'Your gateway to the world of trading.\nAt any time. At any place.\nCommission Free!',
  commonOnboardingCarousel2Title: 'What is 10n?',
  commonOnboardingCarousel2Description: 'A solution to the stock market for any aspiring trader. Shape your portfolio as you desire!',
  commonOnboardingCarousel3Title: 'All you need. All in one app.',
  commonOnboardingCarousel3Description:
    'Continuous updates, statistics, news and metrics give you all the needed tools to start trading with ease and confidence!',
  // sign in screen (email, name etc.)
  commonOnboardingSignInTitle: 'Start by creating your account',
  commonOnboardingSignInSubtitle: '',
  commonOnboardingSignInEmail: 'Email',
  commonOnboardingSignInPassword: 'Password',
  commonOnboardingSignInPrivacyPolicy: 'Privacy Policy',
  commonOnboardingSignInCoockiePolicy: 'Cookies Policy',
  commonOnboardingSignIn10nPrivacyPolicy: '10n Privacy Policy',
  commonOnboardingSignInOrRegisterWith: 'Or register with:',
  commonOnboardingSignInButtonCreateAccount: 'Create account',
  commonOnboardingSignInValidation8Chart: 'Minimum 8 characters',
  commonOnboardingSignInValidation32Chart: 'Maximum 30 characters',
  commonOnboardingSignInValidationUpperCaseLetter: 'Uppercase letter',
  commonOnboardingSignInValidationLowerCaseLetter: 'Lowercase letter',
  commonOnboardingSignInValidationSpecialSymbol: 'Special symbol',
  commonOnboardingSignInValidationNumber: 'Number',
  commonOnboardingSignInValidationLatinCharacters: 'Only Latin characters',
  commonOnboardingSignInErrorMessage: 'Error registering user.',
  commonOnboardingSignInErrorMessagePart1: 'Please@@1',
  commonOnboardingSignInErrorMessagePart2: 'contact support',
  commonOnboardingSignInErrorMessagePart3: '@@1for assistance with your application.',

  commonPolicyAgreement: 'I agree that my personal data will be used according to ',
  commonAnd: ' and ',
  commonPolicyAgreementErrorMessage:
    'Please indicate that you have read and agree with the 10n Privacy Policy',

  // confirm email
  commonOnboardingMismatchTitle: 'Link Mismatch',
  commonOnboardingMismatchDescription:
    "The email from the link does not match your registered email. Please use your link from '@@1' or login with '@@2' in order to use current link.",
  commonOnboardingEmailConfirmTitle: 'Verify your email',
  commonOnboardingEmailConfirmProgress: 'Checking email confirmation ...',
  commonOnboardingEmailConfirmResending: 'Resending email ...',
  commonOnboardingEmailConfirmDescription1: 'We sent a verification email to',
  commonOnboardingEmailConfirmDescription2: 'Please follow the link inside that email to continue.',
  commonOnboardingEmailConfirmButtonOpenEmailApp: 'Open email app',
  commonOnboardingEmailConfirmButtonResendEmail: 'Resend email',
  commonOnboardingEmailConfirmButtonResendEmailIn: 'Resend email in',
  commonOnboardingEmailConfirmInvalidEmailMessage: 'Email address is invalid.',
  commonOnboardingEmailConfirmedTitle: 'Email confirmed!',
  commonOnboardingEmailConfirmedDescription1: 'Thanks for signing up!\nNow you can hop on and explore the world of 10n.',
  commonOnboardingEmailConfirmedDescription2: 'We hope you will enjoy the ride.',
  commonOnboardingEmailAccountHasBeenConfirmed: 'The account has been confirmed.',
  // Expired verification modal
  commonOnboardingExpiredVerificationLinkTitle: 'The verification link has expired.',
  commonOnboardingExpiredVerificationLinkBody: 'Please sign up again or log in if you have already registered.',
  // recovery
  commonOnboardingRecoveryTitle: 'Recovering Enrollment',
  commonOnboardingRecoveryDescription: 'The application has reopened your previous step of the enrollment process. You can now continue.',
  // phone verification
  commonOnboardingPhoneVerificationTitle: "What's your phone number?",
  commonOnboardingPhoneVerificationSubtitle: 'This step is needed to protect your details.',
  commonOnboardingPhoneVerificationCountry: 'Country',
  commonOnboardingPhoneVerificationPhoneNumber: 'Phone Number',
  commonOnboardingPhoneVerificationPhoneNumberInvalid: 'Phone number is invalid!',
  commonOnboardingPhoneVerificationSearchCodePlaceholder: 'Search...',
  commonOnboardingPhoneVerificationSMSCodeTitle: 'Verify your phone number',
  commonOnboardingPhoneVerificationSMSCodeSubitle: "Check your SMS messages. We've sent you the code at @@1",
  commonOnboardingPhoneVerificationSMSCodeNotReceived: "Haven't received your code?",
  commonOnboardingPhoneVerificationSMSCodeExpriesIn: 'Resend code in - ',
  commonOnboardingPhoneVerificationResend: 'Resend Code',
  commonOnboardingPhoneVerificationSMSCodeControlLabel: '6-digit code',
  commonOnboardingPhoneVerificationInvalidCodeLength: 'Invalid code length',
  commonOnboardingPhoneVerificationCodeDoesNotMatch: "Code doesn't match",
  commonOnboardingPhoneVerificationCodeSendAgainErrorMessage: 'Please try again',
  commonOnboardingPhoneVerificationCodeClientErrorMessage: 'Invalid phone number',

  // countries
  commonOnboardingCountriesSearch: 'Search countries ...',
  commonOnboardingCountriesNoMatch: 'No options',
  // personal details
  commonOnboardingGenericRequiredField: 'The field',
  commonOnboardingGenericFieldFrom: 'From',
  commonOnboardingGenericFieldIsRequired: '@@1 is required.',
  commonOnboardingPersonalDetailsTitle: "Let's start with the basics",
  commonOnboardingPersonalDetailsSubtitle: 'International law requires all brokerages to collect this information',
  commonOnboardingPersonalDetailsFirstName: 'First Name',
  commonOnboardingPersonalDetailsFirstNameErrorMessage:
    'First name may only contain latin letters, be at least 2 characters and not have special symbols.',
  commonOnboardingPersonalDetailsStringErrorMessage:
    '@@1 may only contain latin letters, be at least 2 characters and not have special symbols.',
  commonOnboardingPersonalDetailsLastName: 'Last Name',
  commonOnboardingPersonalDetailsMiddleNameOptional: 'Middle Name (optional)',
  commonOnboardingPersonalDetailsLastNameErrorMessage:
    'Last name may only contain latin letters, be at least 2 characters and not have special symbols.',
  commonOnboardingPersonalDetailsMiddleName: 'Middle Name (optional)',
  commonOnboardingPersonalDetailsMiddleNameErrorMessage:
    'Middle name is optional. If included - may only contain latin letters, be at least 2 characters and not have special symbols.',
  commonOnboardingPersonalDetailsDateOfBirth: 'Date of birth',
  commonOnboardingPersonalDetailsDateOfBirthErrorMessage: 'Please select a date of birth.',
  commonOnboardingPersonalDetailsNationality: 'Nationality',
  commonOnboardingPersonalDetailsCountryOfBirth: 'Country of birth',
  commonOnboardingPersonalDetailsPlaceOfBirth: 'Place of birth',
  commonOnboardingPersonalDetailsNationalityCitizenship: 'Nationality / Citizenship',
  commonOnboardingPersonalDetailsNamedCountryErrorMessage: 'Please pick @@1.',
  commonOnboardingPersonalDetailsDocumentError: 'Error',
  commonOnboardingPersonalDetailsDocumentType: 'Document type',
  commonOnboardingPersonalDetailsDocumentTypeErrorMessage: 'Please pick your document type.',
  commonOnboardingPersonalDetailsDocumentTitle: 'And finally your ID document',
  // TODO: delete this text when the mobile change is made
  commonOnboardingPersonalDetailsDocumentSubtitle:
    'We need this due to regulatory requirements. The document should not be expired and have a photo of you.',
  commonOnboardingPersonalDetailsIDDocumentSubtitle: 'The document should not be expired and contain a photo',
  commonOnboardingPersonalDetailsDocumentID: 'ID Document',
  commonOnboardingPersonalDetailsDocumentSubtypeUnspecified: 'Unspecified',
  commonOnboardingPersonalDetailsDocumentSubtypePassport: 'Passport',
  commonOnboardingPersonalDetailsDocumentSubtypeIdentityCard: 'Identity Card',
  commonOnboardingPersonalDetailsDocumentSubtypeDrivingLicense: 'Driving License',
  commonOnboardingPersonalDetailsDocumentNonExpring: 'Non-expiring',
  commonOnboardingPersonalDetailsDocumentNumber: 'Document ID',
  commonOnboardingPersonalDetailsDocumentNumberErrorMessage: 'Document ID may not be empty nor contain special symbols.',
  commonOnboardingPersonalDetailsDateOfExpiry: 'Date of expiry',
  commonOnboardingPersonalDetailsDateOfExpiryErrorMessage: 'Please select expiration date.',
  commonOnboardingPersonalDetailsCitizenshipSameAsConutryOfBirth: 'Citizenship same as country of birth',
  commonOnboardingPersonalDetailsResidentialAddress: "Let's continue with your address",
  commonOnboardingPersonalDetailsResidentialAddressSubtitle: 'The address should match the one on your ID document.',
  commonOnboardingPersonalDetailsMailingAddress: 'Mailing address',
  commonOnboardingPersonalDetailsPhoneNumber: 'Phone number',
  commonOnboardingPersonalDetailsView: 'View Personal details',
  commonOnboardingPersonalDetailsCountry: 'Country',
  commonOnboardingPersonalDetailsCountryErrorMessage: 'Please pick a country.',
  commonOnboardingPersonalDetailsCity: 'City',
  commonOnboardingPersonalDetailsCityErrorMessage: 'City may not be empty nor contain special symbols.',
  commonOnboardingPersonalDetailsPostCode: 'Post Code',
  commonOnboardingPersonalDetailsPostCodeErrorMessage: 'Post code may not be empty nor contain special symbols.',
  commonOnboardingPersonalDetailsAddress: 'Address',
  commonOnboardingPersonalDetailsAddressErrorMessage: 'Address line may not be empty nor contain special symbols.',
  commonOnboardingPersonalDetailsSameAsMailingAddress: 'Mailing address same as residential',
  commonOnboardingPersonalDetailsTaxDetailsTitle: 'Tax Details',
  commonOnboardingPersonalDetailsTaxDetailsMainCountry: 'Main tax country',
  commonOnboardingPersonalDetailsTaxDetailsTaxNumber: 'Tax number',
  commonOnboardingPersonalDetailsTaxDetailsTaxNumberErrorMessage: 'Tax number may not be empty.',
  commonOnboardingPersonalDetailsTaxDetailsTaxNumberAlreadyInUseMessage: 'Tax number already in use.',
  commonOnboardingPersonalDetailsTaxDetailsTaxNumberServerErrorMessage: 'An error occurred. Please try again!',
  commonOnboardingPersonalDetailsTaxDetailsMainTaxDetails: 'Main tax details',
  commonOnboardingPersonalDetailsTaxDetailsTaxCountry: 'Tax country',
  commonOnboardingPersonalDetailsTaxDetailsSecondaryTaxDetails: 'Secondary tax details',
  commonOnboardingPersonalDetailsTaxDetailsAddFromDifferentCountry: 'Add Tax details from different country',
  commonOnboardingPersonalDetailsTaxNumberMessage:
    'Tax number is usually your national Id number. You could also check with local tax authorities for guidance.',
  commonOnboardingPersonalDetailsCitizenship: 'Citizenship',
  commonOnboardingPersonalDetailsTaxCountrySameAsCOB: 'Tax country same as country of birth',
  commonOnboardingPersonalDetailsAddAnotherCitizenship: 'Add another citizenship',
  commonOnboardingPersonalDetailsAnotherCitizenshipDefaultParameter: 'Another',
  commonOnboardingPersonalDetailsAnotherCitizenshipParameter:
    'Secondary,Tertiary,Quaternary,Quinary,Senary,Septenary,Octonary,Nonary,Denary',
  commonOnboardingPersonalDetailsAddAnotherTaxCountry: 'Add another tax country',
  commonOnboardingPersonalDetailsAddressUnderlineText: 'Street, apt, suite, etc.',
  commonOnboardingPersonalDetailsErrorMessage1: 'Error sending personal data',
  commonOnboardingPersonalDetailsErrorMessage2: 'Error sending document data',
  commonOnboardingPersonalDetailsErrorMessage3: 'Error sending tax details data',
  commonOnboardingPersonalDetailsUnderEighteenError: 'You must be at least 18 years old to register an account.',
  commonOnboardingPersonalDetailsChooseDocument: 'Choose document',

  // Common validations
  commonValidationTextInvalid: '@@1 is invalid.',
  commonOnboardingPersonalDetailsDocumentExpireDateError: 'Expiry date should be later than "sys date + 30 days".',

  // Tax Details
  commonTaxDetailsDescription: '10n is required to collect this information',

  // Edit Document
  commonEditDocumentUploadProofOfIdentity: 'Upload Proof of identity*',
  commonEditDocumentBothSides: 'Both sides of ID',
  commonEditDocumentNonExpiringDocument: 'Non-expiring document',

  // Legal declarations
  commonOnboardingLegalTitle: 'Terms & Conditions',
  commonOnboardingLegalText1: 'I have read, understood and confirm my acceptance of the above.',
  commonOnboardingLegalText2:
    'I am applying for margined/leveraged trading. I understand that it is possible for my losses to exceed my initial investment.',
  commonOnboardingLegalCheckBtn1:
    'I agree to be bound by all of the above declarations and I acknowledge that I am electronically entering into a legally binding contract in the form of the Client Agreement.',
  commonOnboardingLegalCheckBtn2:
    'I agree to the terms of the Privacy Policy which describes how 10n processes and stores personal data and my rights in relation to that data.',
  commonPep1: 'I declare that in the past 12 months I have neither been a',
  commonPep2: ' politically exposed person (PEP) ',
  commonPep3: 'nor have I been a family member, a related person or a close business associate of a PEP.',
  commonPepDescriptionTitle: 'Politically exposed person (PEP)',
  commonPepDescription: 'Heads of State, heads of government, ministers and deputy or assistant ministers;\n'
    + 'Members of parliament or of similar legislative bodies;\n'
    + 'Members of constitutional courts, of supreme courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances;\n'
    + 'Members of courts of auditors;\n'
    + 'Members of the boards of central banks;\n'
    + 'Ambassadors, chargés d\'affaires or a state’s diplomatic representative;\n'
    + 'High-ranking officers in the armed forces;\n'
    + 'Members of the administrative, management or supervisory bodies of State-owned enterprises and companies where the sole owner is a State;\n'
    + 'Mayor or deputy mayor of a municipality, mayor or deputy mayor of a region, or chairperson of a municipal council;\n'
    + 'Members of the governing bodies of political parties;\n'
    + 'Directors and deputy directors of international organisations, members of the management board or supervisory board of international organisations, or persons having equivalent function in such organisations.',

  commonOnboardingLegalCheckBtn4: 'Email a copy to me',
  commonOnboardingLegalError: 'Error occurred.',
  commonOnboardingLegalSubmitBtnText: 'Done',

  commonTheInformationIHaveProvided: 'The information I have provided is true and correct and I will notify of any material changes to it.',
  commonIHaveRead: 'I have read, understood and confirm my acceptance of the above.',
  commonIUnderstand: 'I understand that it is possible for my losses to exceed my initial investment.',

  commonWelcomeTo10nTrade: '@@1 @@2 Welcome to 10n Trade',
  commonYouMayRead: 'While your account is under review, you may read the attached Terms of Service documentation.',

  // Yay Activated
  commonOnboardingYayTitle: 'Yay! Your account is activated!',
  commonOnboardingYaySubtitle: 'Payment methods unlocked.',
  commonOnboardingYaySubmitBtnText: 'Continue',
  commonOnboardingYayNextStepsLeft: 'Two more steps',

  // upload
  commonOnboardingUploadBtn: 'Upload',
  commonOnboardingUploadCondition1: 'Take a photo of both sides of your ID',
  commonOnboardingUploadCondition2: 'The document has a valid expiry date',
  commonOnboardingUploadCondition3: 'Your details are clearly visible',
  commonOnboardingUploadTitle: 'Please upload the following documents',
  commonOnboardingUploadProofOfID: 'Upload Proof of identity',
  commonOnboardingUploadPassport: 'Upload your Passport',
  commonOnboardingUploadBothSidesID: 'Both sides of ID',
  commonOnboardingUploadProofOfAddress: 'Upload Proof of address',
  commonOnboardingUploadProofOfAddressText1:
    'Bank statement / Utility bill issued within the last 3 month',
  commonOnboardingUploadProofOfAddressText2: 'Your full name, address and issuer name must be clearly visible',
  commonOnboardingUploadProofOfAddressText3: 'Accepted file types: jpg, png only - up to 10mb',
  commonOnboardingUploadDocDescription:
    'To verify your account, we will need a photo of your identity document. Make sure of the following:',
  commonOnboardingUploadBankStatementOrUtilityBill: 'Bank statement or Utility bill',
  commonOnboardingUploadDocumentConfirmDescription: 'Please make sure the text is clear and your entire document is visible.',
  commonOnboardingUploadBackSideOfCardButton: 'Take a new photo',
  commonOnboardingUploadSelfieTitle: 'Take a Selfie',
  commonOnboardingUploadSelfieDescription:
    'Тo prove your identity and prevent financial fraud, please take a selfie.\n\nJust make sure of the following:\n\n• You are alone in the photo;\n\n• You are facing the camera;\n\n• The image is clear and bright',
  commonOnboardingUploadSelfieProceedButton: 'PROCEED',
  commonOnboardingUploadSelfieConfirmDescription: 'Make sure your face is clear enough and the photo is not blurry.',
  commonOnboardingUploadSelfieConfirmConfirmButton: 'CONFIRM PHOTO',
  commonOnboardingUploadSelfieConfirmSnapButton: 'SNAP',
  commonOnboardingAndroidCamerPermissionsTitle: 'Permission to use camera',
  commonOnboardingAndroidCamerPermissionsMessage: 'We need your permission to use your camera',
  commonOnboardingAndroidCamerPermissionsPositiveButton: 'Ok',
  commonOnboardingAndroidCamerPermissionsNegativeButton: 'Cancel',
  commonOnboardingAndroidAudioPermissionsTitle: 'Permission to use audio recording',
  commonOnboardingAndroidAudioPermissionsMessage: 'We need your permission to use your audio',
  commonOnboardingAndroidAudioPermissionsPositiveButton: 'Ok',
  commonOnboardingAndroidAudioPermissionsNegativeButton: 'Cancel',

  // Verification
  commonVerificationTitle: 'Verification',
  commonVerificationSubtitle: 'To meet regulatory requirement for trading we need to verify your identity',
  commonVerificationProcessTimeDescription: 'Usually the verification process of your documents',
  commonVerificationProcessTimeSubDescription: 'takes up to 3 business days',
  commonVerificationVerifyPhoneNumberButton: 'Verify your phone number',
  commonVerificationPhoneVerified: 'Phone verified',
  commonVerificationVerifyPhoneNumberButtonDescription: 'For additional security and quick access',
  commonVerificationUploadIdDocumentButton: 'Upload your ID document',
  commonVerificationIdDocumentUploaded: 'ID document uploaded',
  commonVerificationUploadIdDocumentButtonDescription: 'A copy of the document used for activation',
  commonVerificationPoAButton: 'Proof of address',
  commonVerificationPoAUploaded: 'Proof of address uploaded',
  commonVerificationPoAButtonDescription: 'A clear image of utility bill or bank statement',
  commonVerificationStepStatusPending: 'Pending verification',
  commonVerificationStepStatusAccepted: 'Completed',
  commonVerificationUploadPoITitle: 'Upload your ID document',
  commonVerificationUploadPoIPassportTitle: 'Upload Passport photo',
  commonVerificationUploadPoISubTitle:
    'To verify your account, we will need a photo of your identity document. Make sure of the following:',
  commonVerificationUploadPoIBullet1: 'Upload a photo of both sides of your ID document',
  commonVerificationUploadPoIBullet2: 'The document has a valid expiry date',
  commonVerificationUploadPoIBullet3: 'Your details are clearly visible',
  commonVerificationUploadFrontIDImageTitle: 'Front of your ID',
  commonVerificationUploadIDImageSubtitle: 'Hold up your ID and take a picture.',
  commonVerificationUploadBackIDImageTitle: 'Back of your ID',
  commonVerificationUploadPassportImageTitle: 'Passport',
  commonVerificationUploadPoATitle: 'Upload Proof of address',
  commonVerificationUploadPoASubTitle1:
    'You could use a digital/electronic bank statement or utility bill (e.g. gas, electricity, water or landline phone bill but not a mobile phone bill).',
  commonVerificationUploadPoASubTitle2: 'The document must bear your full name, address and issuer name. It must be issued',
  commonVerificationUploadPoASubTitle3: 'within the last 3 months.',
  commonVerificationUploadPoAChooseDocumentLabel: 'Choose document type:',
  commonVerificationUploadPoAUtilityBill: 'Utility Bill',
  commonVerificationUploadPoABankStatement: 'Bank Statement',
  commonVerificationUploadFileSizeExceededMessage: 'Maximum file size of 10mb exceeded',
  commonVerificationUploadFileWrongFormatMessage: 'Unsupported file format. Please check accepted file types',
  commonVerificationTermsButton: 'Terms of Services',
  commonVerificationTermsButtonDescription: 'Legal agreements and use of services',

  // Upload document
  commonUploadDocumentTypesHint: '*Accepted file types: @@1 only - up to 10mb',
  commonUploadDocumentLabel1: 'Drop your image here, or',
  commonUploadDocumentLabel2: 'browse',
  commonUploadDocumentWebTitle1:
    'To verify your account we need a copy of the first page of a recent bank statement from the last 3 months.',
  commonUploadDocumentWebTitle2: 'The document must have clearly visible:',
  commonUploadDocumentWebLabel1: 'Your full name and address',
  commonUploadDocumentWebLabel2: 'The bank name',
  commonUploadDocumentWebLabel3: 'Your bank account',
  commonUploadDocumentErrorTitle: 'Unsuccessful upload',
  commonUploadDocumentErrorBody: 'Unfortunately there was an issue with the upload.',
  commonUploadDocumentErrorBody1: 'Please make sure that the file type is one of the following jpg, jpeg or png and up to 10mb.',

  // discover
  commonDiscoverTitle: 'Discover',

  // Discover - Calendars
  commonDiscoverSubtitleCalenders: 'Calendars',
  commonDiscoverCalenderSectionIPOs: 'IPOs',
  commonDiscoverCalenderSectionIPOsTitle: 'IPO Calendar',
  commonDiscoverCalenderSectionEarnings: 'Earnings',
  commonDiscoverCalenderSectionEarningsTitle: 'Earnings calendar',
  commonDiscoverCalenderSectionEarningsDescription: 'Check company earning\nannouncements and prepare\nfor market changes.',
  commonDiscoverCalenderSectionCompanyEvents: 'Company Events',
  commonDiscoverCalenderSectionCompanyEventsTitle: 'Company Events',
  commonDiscoverCalenderSectionAll: 'All',
  commonDiscoverCalenderSectionAllTitle: 'All Calendars',
  commonDiscoverCalenderEventsToday: 'Today',
  commonDiscoverCalenderItems: 'Events',
  commonDiscoverCalenderSectionTimeBeforeMarketOpen: 'Before Market Open',
  commonDiscoverCalenderSectionTimeAfterMarketClose: 'After Market Close',
  commonDiscoverCalenderSectionTimeNotSupplied: 'Time Not Supplied',
  commonDiscoverCalenderSectionTimeEPSEstimate: 'EPS estimate • $@@1',
  commonDiscoverCalenderSectionTimeNoEPSEstimate: 'EPS esimtate -',

  // Discover - New on the market
  commonDiscoverSubtitleNewOnTheMarket: 'New on the Market',
  commonDiscoverSubtitleNewOnTheMarketPastDays: 'For the past 30 days',

  // Discover - Long term investments
  commonDiscoverSubtitleLongTermInvestments: 'Long term investments',
  commonDiscoverSubtitleLongTermInvestmentsTableHeader1: 'Symbol/Name',
  commonDiscoverSubtitleLongTermInvestmentsTableHeader2: 'Current Q',
  commonDiscoverSubtitleLongTermInvestmentsTableHeader3: 'Prev Q',
  commonDiscoverSubtitleLongTermInvestmentsTableHeader4: 'Market Cap',
  commonDiscoverSubtitleLongTermInvestmentsTableHeader5: 'Volume',

  // Discover - News
  commonDiscoverNews: 'Latest news',

  // Discover - Popular groups
  commonDiscoverSubtitlePopularGroups: 'Popular Groups',
  commonDiscoverPopularGroupsStocks: 'Stocks',
  commonDiscoverPopularGroupsSortBy: 'Sort by',

  popularGroupCommunications: 'Communications',
  popularGroupHealthcare: 'Healthcare',
  popularGroupConsumerDiscretionary: 'Consumer Discretionary',
  popularGroupTechnology: 'Technology',
  popularGroupUtilities: 'Utilities',
  popularGroupConsumerStaples: 'Consumer Staples',
  popularGroupFinancialServices: 'Financial Services',
  popularGroupBasicMaterials: 'Basic Materials',
  popularGroupRealEstate: 'Real estate',
  popularGroupIndustrials: 'Industrials',
  popularGroupEnergy: 'Energy',
  popularGroupETFs: 'ETFs',

  // Discover - Charts - Dividend payers - High growth companies
  commonDiscoverDividendPayers: 'Dividend payers',
  commonDiscoverDividendPayersDescription: 'Dividend yields for the past twelve months',
  commonDiscoverHighGrowthCompaniesDescription: 'The fastest growing market players over the past twelve months',
  commonDiscoverDividendPayersLegend: 'Dividend yield',
  commonDiscoverHighGrowthCompanies: 'Growth companies',
  commonDiscoverHighGrowthCompaniesLegend: 'Growth indicator',
  commonDiscoverInfoBoxMarketCap: 'Market Cap',
  commonDiscoverInfoBoxRevenue: 'Revenue',
  commonDiscoverInfoBoxEps: 'EPS',
  commonDiscoverInfoBoxVolume: 'Volume',
  commonDiscoverInfoBoxPrevQ: 'Prev Q',
  commonDiscoverInfoBoxPrevClose: 'Prev close',
  commonDiscoverInfoBoxDividendRate: 'Dividend rate',
  commonGrowthIndicatorDescription: 'The average of Revenue growth and EPS growth for the past twelve months',

  // Market Hours
  commonMarketHoursPre: 'Pre Market',
  commonMarketHoursAfter: 'After Hours',
  commonMarketHoursAtClose: 'At Close',
  commonPreMarketHoursTradingStarting: 'Regular trading hours start in:',
  commonMarketClosedHoursTradingStarting: 'The stock market is currently closed. Pre-market starts in:',

  // Trading tickets
  commonTradingOrder: 'Order',
  commonTradingOrderType: 'Order Type',
  commonTradingOrderTypeLimit: 'limit',
  commonTradingOrderTypeStop: 'stop',
  commonTradingExpiryDate: 'Expiry Date',
  commonTradingSelectDate: 'Select Date',
  commonTradingExtendedHoursTrade: 'Extended Trading Hours',
  commonTradingOrderValue: 'Order Value',
  commonConfirmOrderButton: 'Confirm Order',
  commonExpirationOrderEndOfDaySubText: 'Order will expire at the end of the trading day when the markets close',
  commonExpirationOrderGoodTillCanceledSubText: 'Order will expire only when you manually cancel the order or after 90 days',
  commonExpirationOrderGoodTillDateSubText: 'Order will expire at the end of the selected date',
  commonHaveShortedStocksTitle: 'Cover your shorted stocks',
  commonHaveShortedStocksDescription1: 'Currently you have',
  commonHaveShortedStocksDescription1SubText1: '@@1 shorted @@2',
  commonHaveShortedStocksDescription1SubText2: 'stocks.',
  commonHaveShortedStocksDescription2:
    'You need to buy back all the shorted stocks first, before you can place a regular Buy order.',
  commonHaveShortedStocksDescription3: 'If you would like to learn more about short selling follow the link',
  commonTradingPriceError: 'You should enter @@1 price for this order',
  commonTradingAggressiveLimitPriceError: 'Price must be lower than @@1',
  commonTradingAggressiveLimitPriceError1: 'Buy Price must be lower than @@1',
  commonTradingStopPriceError: 'Stop price should be @@1 than market price.',
  commonTradingExpiryDateError: 'You should enter Expiry Date.',
  commonTradingShortSellDialog: 'You are about to place a Short sell order',
  commonRegularTradingHoursStartDialogTitle: 'Placing Short Sell orders',
  commonTradingShortSellDialogDescription:
    'Short selling is an advanced form of trading. It poses additional risks and potential of losses that you should consider.',
  commonTradingShortSellDialogDescriptionWeb: 'Short selling is available during regular trading hours only.',
  commonRegularTradingHoursStartDialogDescription:
    'Short Selling is available only during regular trading hours. Regular trading hours start in:',
  commonRegularTradingShortSellDescription: 'Please be advised that a daily interest fee of @@1 will be applied',
  commonTradingLinkHere: 'link here',
  commonTradingHere: 'here.',
  commonTradingNotEnoughBuyingPowerTitle: 'Not enough buying power',
  commonTradingNotEnoughBuyingPowerDescription: 'Please add additional funds to your account to complete your trade.',
  commonNotEnoughSharesAvailable: 'Please use a smaller number of shares',

  // Trading validation
  commonTradingValidationNewOrderEnterNumberOfShares: 'You should enter number of shares for this order.',
  commonTradingValidationNewOrderCloseBuyNumberOfShares: 'Number of shares should not be higher than @@1',
  commonTradingValidationNewOrderEnterLimitOrStopPrice: 'You should enter @@1 price for this order.',
  commonTradingValidationNewOrderAggressiveBuyLimitPrice: 'Price must be lower than @@1.',
  commonTradingValidationNewOrderAggressiveSellLimitPrice: 'Price must be higher than @@1.',
  commonTradingValidationNewOrderBuyStopPrice: 'Stop price should be higher than market price.',
  commonTradingValidationNewOrderSellStopPrice: 'Stop price should be lower than market price.',
  commonTradingValidationNewOrderBuyingPower: 'Not enough buying power for this order.',
  commonTradingValidationNewOrderSellAggressiveLimitError: 'Price must be higher than @@1',
  commonTradingValidationNewOrderSellAggressiveLimitError1: 'Sell Price must be higher than @@1',

  // Halted stock
  commonHaltedSymbolPopupTitle: 'Unavailable for trading',
  commonHaltedSymbolInfo: 'Temporarily halted for trading by the exchange.',
  commonHaltedSymbolPopupContent: 'Sell and buy orders for @@1 are currently restricted due to a trading halt.',

  // Inbox messages
  commonInboxNoMessagesText: "There's nothing here yet.",
  commonInboxNoMessagesText2: 'No messages',
  commonInboxSelectedMessagesText: '@@1 Selected',
  commonInboxDeleteMessagesTitle: 'Delete @@1 messages',
  commonInboxDeleteMessagesTitle2: 'Delete message',
  commonInboxAreYouSureYouWantToDeleteMessageText1: 'Are you sure you want to delete this messages?',
  commonInboxAreYouSureYouWantToDeleteMessageText1WithNumber: 'Are you sure you want to delete @@1 messages?',
  commonInboxAreYouSureYouWantToDeleteMessageText2: 'This is permanent.',

  // -----------   Web   -----------

  webLogoutIn: 'Logout in ',

  webMyAssetsTitle: 'My Assets',
  webPortfolioSummaryTitle: 'Portfolio Summary',

  // signUp
  webSignInEmailNotReceived: "Haven't received a confirmation email? Check your spam folder or",
  webSignInSubTitle: 'If you already have an account. You can',
  webSignInButton: 'Log in here!',
  webSendAgain: 'Send Again.',
  webResendEmail: 'resend email in',

  // onboarding
  webOnboardingEndScreenSubmitBtnText: 'Go to payments',

  // Trading
  webTradingMarketAskPrice: 'Market / @@1 price:',
  webTradingCurrentlyPlacedBuyOrder: 'Currently you are placing @@1 order which will execute at the best @@2 price',
  webTradingCurrentlyAskPrice: 'Current Ask price: @@1',
  webTradingCurrentlyBidPrice: 'Current Bid price: @@1',
  webTradingLastTradePrice: 'Last trade price: @@1',
  webTradingDisclaimerPopover:
    'The price we show on our charts represents the last fulfilled trade on the market. This is why there might be a difference from the current market / @@1 price.',
  webTradingAskAndBidPricesGeneralInfo: 'Buy orders will execute at the best Ask price and Sell orders will execute at the best Bid price.',
  webTradingIncludeExtendedHoursTrading: 'Include Extended Trading Hours ',
  webTradingBuyShortedPositionsDescription1: 'You have just had a previously placed short sell order for',
  webTradingBuyShortedPositionsDescription1Subtext1: '@@1 stocks',
  webTradingBuyShortedPositionsDescription1Subtext2: 'filled',
  webTradingBuyShortedPositionsDescription2: 'Before you can place a regular Buy order you need to buy the shorted stocks first.',
  webTradingIncorrectStocks: 'Not enough available stocks. \n Please try again',
  webTradingShortedStocks: 'Shorted stocks: @@1',
  webTradingAvailableShares: 'Shares Available: @@1',
  webTradingAsk: 'Ask',
  webTradingBid: 'Bid',
  webTradingMore: 'more',
  webTradingLess: 'less',
  webTradingLimit: 'limit',
  webTradingStop: 'stop',
  webTradingHighLowLimitPriceAlert: '@@1 Limit Price',
  webTradingHighLowLimitPriceAlertHigh: 'High',
  webTradingHighLowLimitPriceAlertHigher: 'higher',
  webTradingHighLowLimitPriceAlertLow: 'Low',
  webTradingHighLowLimitPriceAlertLower: 'lower',
  webTradingHighLowLimitPriceAlertDescription:
    'Your selected limit price is @@1 than the market stock price. This could lead to immediate fulfilment of your order. Would you like to continue?',
  webTradingEditPriceButton: 'Edit Price',

  // (1/7) order message - placed
  orderMessageTitlePlaced: '@@1 @@2 order placed',
  orderMessageBodyPlaced: 'Your order for @@1 x @@2 was placed',
  // (2/7) order message - filled
  orderMessageTitleFilled: '@@1 @@2 order filled',
  orderMessageBodyFilled: 'Your order for @@1 x @@2 was filled',
  orderMessageTitlePartiallyFilled: '@@1 @@2 order partially filled',
  orderMessageBodyPartiallyFilled: '@@1 / @@2 @@3 were filled',
  // (3/7) order message - being processed
  orderMessageTitlePending: '@@1 @@2 @@3 order pending',
  orderMessageBodyPending: 'Your order for @@1 x @@2 is being processed',
  orderMessageBodyPendingWeb: 'Your @@1 @@2 order for @@3 x @@4 is being processed',
  // (4/7) order message - rejected
  orderMessageTitleRejected: '@@1 @@2 order rejected',
  orderMessageBodyRejected: 'Your order for @@1 x @@2 was rejected',
  orderMessageTitlePartiallyRejectedTitle: '@@1 @@2 order partially rejected',
  orderMessageBodyPartiallyRejectedBody: '@@1 / @@2 @@3 were rejected',
  orderMessageBodyRejectedWeb: 'Your @@1 @@2 order for @@3 x @@4 was rejected',
  orderMessageExpired: 'Your @@1 @@2 order for @@3 x @@4 has expired',
  // (5/7) order message - error
  orderMessageTitleError: 'Unable to place your order for @@1 x @@2',
  orderMessageBodyError: '',
  // (6/7) order message - modify
  orderMessageTitleModify: 'Modify @@1 @@2 @@3 order',
  orderMessageBodyModifySuccess: 'Your order for @@1 x @@2 was modified',
  orderMessageModifySuccessWeb: 'Your @@1 @@2 order for @@3 x @@4 was modified',
  orderMessageBodyModifyPending: 'Your request to modify @@1 x @@2 is being processed',
  orderMessageBodyModifyFail: 'Order modification failed',
  // (7/7) order message - cancel
  orderMessageTitleCancel: 'Cancel @@1 @@2 order',
  orderMessageBodyCancelSuccess: 'Your order for @@1 x @@2 was cancelled',
  orderMessageCancelSuccessWeb: 'Your @@1 @@2 order for @@3 x @@4 was cancelled',
  orderMessageCancelAccepted: 'Cancel order request accepted',
  orderMessageBodyCancelPending: 'Your request to cancel @@1 x @@2 is being processed',
  orderMessageBodyCancelFail: 'Order cancellation failed',

  // order popup
  webOrderPopupCancelTitle: 'Cancel Order',
  webOrderPopupAreYouSureToCancelLabel: 'Are you sure you want to cancel your order?',
  webPopupCardTransferDescription1: 'Тhe card must be in your name.',
  webPopupCardTransferDescription2: 'Deposits from other cards will be rejected.',
  webPopupBankTransferDescription1: 'The bank account must be in your name.',
  webPopupBankTransferDescription2: 'Deposits from other accounts will be rejected.',
  webPopupBankDetailsDescription1: 'Verification may take up to 3 business days.',
  webPopupBankDetailsDescription2: 'You can proceed with your deposit comfortably.',
  webPopupDepositSlipDescription: 'A copy of the deposit slip was sent to your email.',
  webAddNewBankAccountButton: 'Add new bank Account',
  orderPopupFailResultSubTitle: 'Please try again.',

  popupFeeBreakdownTitle: 'Fee Breakdown',
  popupFeeBreakdownCardTitle: 'Card Fees:',
  popupFeeBreakdownCardEUDebitKey: 'EU Debit / Credit Cards',
  popupFeeBreakdownCardEUDebitValue: 'Debit Cards: @@1',
  popupFeeBreakdownCardEUCreditValue: 'Credit Cards: @@1',
  popupFeeBreakdownCardNonEUkey: 'Non-EU and Business Cards',
  popupFeeBreakdownBankTitle: 'Bank Fees:',
  popupFeeBreakdownMinimumDepositKey: 'Minimum Deposit',
  popupFeeBreakdownMinimumDepositValue: 'No Minimum Deposit',
  popupFeeBreakdownDescription: '*Your bank may apply additional charges.',

  webFundsDividendLabel: 'Dividend',
  webFundsTransactionsTitle: 'Transactions',

  webAccountFundingMethod: 'Funding method',

  // ETF Trading restrictions
  etfTradingRestrictionsModalTitle: 'ETF trading restrictions',
  etfTradingRestrictionsModalContent: 'Packaged products (ETFs) offered currently on 10n do not have KIDs. They can only be traded by users verified as professional traders. Learn more at ',
  etfTradingRestrictionsEtfURL: 'www.10n.com/ETFs',
  etfTradingRestrictionsMessage: 'View Only symbol due to ETF trading restrictions.',
  // -----------   Mobile   -----------

  mobileCommonButtonsCancel: 'Cancel',
  mobileCommonButtonsExit: 'Exit',

  mobileFundingPickerTitle: 'Select funding method:',
  mobilePhotoPickerTitle: 'Select option:',
  mobilePhotoPickerCameraAction: 'Take a photo',
  mobilePhotoPickerGalleryAction: 'Browse Gallery',

  mobileConfirmPhotoScreenFrontOfIdTitle: 'Is the front of your ID clear enough?',
  mobileConfirmPhotoScreenFrontOfIdSubtitle: 'Please make sure that the entire card is visible and then upload the back of your ID.',
  mobileConfirmPhotoScreenBackOfIdTitle: 'Is the back of your ID clear enough?',
  mobileConfirmPhotoScreenBackOfIdSubtitle: 'Please make sure that the entire card is visible.',
  mobileConfirmPhotoScreenSecondaryButtonGallery: 'Choose another file',

  mobileAddBankAccTitle: 'Add your bank account',
  mobileAddBankAccSubtitle: 'To verify your account we also need a copy of a recent bank statement',
  mobileAddBankAccInputBank: 'Bank name',
  mobileAddBankAccInputBankCountry: 'Bank Country',
  mobileAddBankAccInputBicSwift: 'Bic / Swift Code',
  mobileAddBankAccInputIBAN: 'IBAN / Account',
  mobileAddBankAccInputCurrency: 'Currency',
  mobileAddBankAccUploadBankStatementBtn: 'Upload bank statement',

  fundsScreenTitle: 'Wallet',
  fundsScreenFundBtn: 'Add Money',
  walletNoActiveAccountPleaseContact: 'Funds withdrawals from your account are currently not available. Please contact ',
  walletWithdrawalsRestricted: 'Withdrawals Restricted',
  fundsCheckTheFeeBreakdown: 'Check the fee breakdown for full details',
  mobileFundsScreenAvailableCash: 'Available Cash',
  mobileFundsScreenWithdrawBtn: 'Withdraw',
  mobileFundsScreenBankAccounts: 'Bank accounts:',
  mobileFundsScreenAddBankAccountBtn: 'Add Bank Account',
  mobileFundsScreenRecentTransactions: 'Recent Transactions',
  mobileFundsScreenViewAllBtn: 'View All',
  mobileFundsScreenTransactionDeposit: 'Deposit',
  mobileFundsScreenTransactionWithdraw: 'Withdraw',
  mobileFundsScreenModalTitle: 'Bank account required',
  mobileFundsScreenAddBankAccModal:
    'Please add а bank account to initiate a withdrawal. The verification process takes up to 3 business days.',
  mobileFundsScreenAddBankAccModalBtn: 'Add bank account',
  mobileFundsScreenBankAccPendingModal: 'The verification is still ongoing. This may take up to 3 business days.',
  mobileFundsScreenSuccessCardDepositDialog: '@@1 was funded to \n your account',
  mobileFundsScreenPendingCardDepositDialog: 'Your deposit for @@1 \n is pending',
  mobileFundsScreenFailedCardDepositDialog: 'Something went wrong',

  mobileFundsViewAllScreenTransactions: 'Transactions',
  mobileTransactionTypeToBankAccountHeading: 'To Bank Account',
  mobileTransactionTypeFromBankAccountHeading: 'From Bank Account',
  mobileTransactionTypeLabelDetails: 'Details',
  mobileTransactionTypeLabelType: 'Type',
  mobileTransactionTypeLabelPaymentRef: 'Payment reference',
  mobileTransactionTypeLabelBank: 'Bank',
  mobileTransactionTypeLabelAccountN: 'Account No',
  mobileTransactionTypeLabelCategory: 'Category',

  mobileWithdrawScreenTitle: 'Withdrawal',
  mobileWithdrawScreenSubtitle: 'Withdrawal Fee: @@1 USD • Your bank may charge you for currency conversion and transfer fees.',
  mobileWithdrawScreenAvailableCash: 'Available Cash',
  mobileWithdrawScreenRequestBtn: 'Request withdrawal',
  mobileWithdrawScreenModalTitle: 'Withdrawal Request',
  mobileWithdrawScreenModal:
    'Withdrawal requests are processed within 1 business day.\n\n Depending on your bank transfers may take up to 5 business days.',
  mobileWithdrawScreenInfoModalTitle: 'Available cash: @@1',
  mobileWithdrawScreenInfoModalPersonalCash: 'Personal Cash: @@1',
  mobileWithdrawScreenInfoModalInstaCash: '10n Insta-Cash: @@1',
  mobileWithdrawScreenInfoModalDailyInterest: 'Daily interest = @@1',
  mobileWithdrawScreenInfoModalDescription:
    'The available cash for withdrawal is calculated at the end of each day. It depends on unrealized profits, current orders and account cash transfers.',
  mobileWithdrawScreenBankAccount: 'Bank account',

  mobileCardDialogTitle: 'Card transfer selected',
  mobileCardDialogDescription: 'Тhe card must be in your name. Deposits from other cards will be rejected',
  mobileBankDialogTitle: 'Bank transfer selected',
  mobileBankDialogDescription: 'The bank account must be in your name. Deposits from other accounts will be rejected',
  mobileDepositSlipDialogTitle: 'Deposit slip sent!',
  mobileDepositSlipDialogDescription:
    'A copy of the deposit slip was sent to your email. \n\n Your paymnet code will be valid unitl 00.00.2021.',

  mobileDepositSlipInputBank: "Beneficiary's Bank",
  mobileDepositSlipInputName: "Beneficiary's Name",
  mobileDepositSlipLabelPaymentCode: 'Payment Code',
  mobileDepositSlipTitle: 'Deposit slip successfully created',
  mobileDepositSlipSubtitle: 'Use the information below for your bank transfer',
  mobileDepositSlipAlert: 'Make sure to use this code as a reason for payment',
  mobileDepositSlipCopyBtn: 'Copy Slip',
  mobileDepositSlipModalTitle: 'Deposit slip sent',
  mobileDepositSlipModalDescription: 'A copy of the deposit slip was sent to your email',
  mobileDepositSlipCopiedMessage: 'Deposit slip was copied to Clipboard',

  mobileBankDetailsSetTitle: 'Bank details have been set!',
  mobileBankDetailsSetSubtitle1: 'Verification may take up to 3 business days.',
  mobileBankDetailsSetSubtitle2: 'You can proceed with your',
  mobileBankDetailsSetSubtitle3: 'deposits comfortably.',

  depositAmountTitle: 'Fund your account',
  depositAmountChangeMethod: 'Change Funding Method',
  mobileDepositAmountSubtitle: 'Your bank may charge you for currency conversion and transfer fees.',
  mobileDepositAmountLabel: 'Amount',
  mobileDepositAmountBtn: 'Fund Account',
  mobileDepositAmountSourceOfFundBankTitle: 'Bank Transfer',
  mobileDepositAmountMinimumDepositAmount: 'Minimum deposit: @@1',
  mobileDepositAmountMaximumDepositAmount: 'Maximum deposit: @@1',

  mobileSecurityScreenTitle: 'Security',
  mobileSecurityScreenSubtitle: 'Choose your desired method of security according to your preferences',
  mobileSecurityTwoFactorLabel: 'Two-Factor Authentication',
  mobileSecurityPasscodeLabel: 'Passcode',
  mobileSecurityBiometricsLabel: 'Use biometrics',
  mobileSecurityChangePasswordLabel: 'Change password',
  mobileSecurityChangePasscode: 'Change passcode',
  mobileSecuritySnackMessageEnabled: 'Your passcode has been enabled.',
  mobileSecuritySnackMessageDisabled: 'Your passcode has been disabled.',
  mobileSecuritySnackMessageUpdated: 'Your passcode has been updated.',
  mobileSecuritySnackMessageDontMatch: "Your passcode doesn't match.",
  mobileSecuritySnackMessageDontAvaileble: 'Biometrics is not available.',
  mobilePasscodeAlertMessage: 'Do you want to exit the process?',
  mobileChangePasswordModalText: 'Are you sure you want to change your password?',
  mobileSecurityForgotPasscode: 'Forgot your passcode?',
  mobileSecurityUsePassword: 'Use your password',

  mobilePasswordChangeEmailTitle: 'Check your email',
  mobilePasswordChangeEmailUnderlineHeading1: "We've just sent an email to ",
  mobilePasswordChangeEmailUnderlineHeading2: ' with password reset instructions',

  commonPassCode: 'Change your passcode',

  mobileWatchlistStocks: 'Popular',
  mobileOrdersTitle: 'View Orders',
  mobileSortButton: 'Sort by',
  mobileWatchlistNoData: 'Data could not be loaded',
  mobileSortByLatest: 'Latest',
  mobileSortByLastPrice: 'Last Price',
  mobileSortByPercentChange: 'Percent Change',

  // splash screen
  mobileSplashText: 'Alaric Trader',
  mobileSplashOfflineDemo: 'Offline Demo',

  // Passcode dialog
  mobilePasscodeEnterPasscode: 'Enter your existing passcode',
  mobilePasscodeSetup: 'Set up your new passcode',
  mobilePasscodeConfirm: 'Confirm your new passcode',
  mobileEnterYourPasscode: 'Enter your passcode',
  mobilePasscodeWelcomeBack: 'Welcome back. You\'ve been missed!',
  mobilePasscodeEasyAccess: 'For easy access to your trading account',

  // order create
  mobileOrderCreateTitle: 'Create @@1 Order',
  mobileOrderCreateTabMarket: 'Market',
  mobileOrderCreateTabLimit: 'Limit',
  mobileOrderCreateTabStop: 'Stop',
  mobileOrderCreateSymbol: 'Symbol',
  mobileOrderCreateMarketPrice: 'Market Price',
  mobileOrderCreateButtonReviewOrder: 'Review Order',
  mobileOrderCreateSelectShares: 'Select Shares',
  mobileOrderCreateTargetPrice: 'Target Price',
  mobileOrderCreateTimeInForce: 'Time in force',
  mobileOrderCreateValue: 'Value',
  mobileOrderCreateAvailableBuyingPower: 'Available Buying Power',
  mobileOrderCreateCommission: 'Commission',
  mobileOrderCreateCommissionFree: 'FREE',
  mobileOrderCreateCost: 'Order Cost',
  mobileOrderCreateBuyingPower: 'Buying Power',
  mobileOrderCreateStopMessage: 'Set a price above the current price that converts your order to a market order.',
  mobileOrderCreateLimitMessage: 'Set the maximum price you are willing to pay per share.',
  // order review
  mobileOrderReviewTitle: 'Review Order',
  mobileOrderReviewMessage: 'Highly volatile stocks can have slight difference in price during order execution',
  mobileOrderReviewButtonSubmit: 'Submit',
  mobileOrderReviewButtonCancel: 'Cancel',
  mobileOrderReviewNoPriceError: 'Price is not available.\nEither market is closed or there is a connection issue.',
  // order popup
  mobileOrderPopupGoToPortfolio: 'Go to Portfolio',
  mobileOrderPopupGoToOrders: 'Go to Orders',
  mobileOrderPopupOrderSatus: 'Order @@1',
  // view orders
  mobileOrdersViewTabPlaced: 'Placed',
  mobileOrdersViewTabCanceled: 'Canceled',
  // order details
  mobileOrderDetailsTitle: 'Order Details',
  mobileOrderDetailsType: 'Type',
  mobileOrderDetailsCreatedAt: 'Created At',
  mobileOrderDetailsUpdatedAt: 'Updated At',
  mobileOrderDetailsStatus: 'Status',
  mobileOrderDetailsPrice: 'Price',
  mobileOrderDetailsEnteredQuantity: 'Entered Quantity',

  // portfolio
  mobilePortfolioTitle: 'Portfolio',
  mobilePortfolioAvg: 'Avg: @@1',
  mobilePortfolioYourStocks: 'Your stocks',
  mobilePortfolioAccountValue: 'Account Value',
  portfolioValue: 'Portfolio Value',

  // account
  mobileAccountTitle: 'Account',
  mobileAccountTotalValue: 'Total Value',
  mobileAccountBuyingPower: 'Buying power',
  mobileAccountFundAccount: 'Fund Account',
  mobileAccountWithdrawal: 'Withdrawal',
  mobileAccountTotalValueBreakdownCash: 'CASH',
  mobileAccountTotalValueBreakdownTitle: 'Total Value Breakdown',
  mobileAccountTradeOperations: 'Trade Operations',
  mobileAccountViewOrders: 'View Orders',
  mobileAccountTradeTransactionHistory: 'Trade Transaction History',
  mobileAccountTradeTransactionHistoryExecuted: 'Executed',

  // Trade History
  commonTradeHistoryNoTradeIn90Days: "You don't have any trades made in the past 90 days.",
  commonTradeHistoryNothingHere: "There's nothing here",
  commonTradeHistoryDetailsPrice: '@@1 price',

  // profile
  mobileProfileHeaderTitle: 'Profile',
  mobileProfileVersion: 'Version',
  mobileProfileBuild: 'build',
  mobileProfileTitle: 'Hello@@1!',
  mobileProfileAccountSettingsTitle: 'Account Settings',
  mobileProfilePersonalDetails: 'Personal details',
  mobileProfileNotificationSettings: 'Notification Settings',
  mobileProfileDocuments: 'Documents',
  mobileProfileChangePassword: 'Change password',
  mobileProfileChangePasscode: 'Change passcode',
  mobileProfileDeviceSettings: 'Device Settings',
  mobileProfileFingerprint: 'Fingerprint',
  mobileProfileSupportCenter: 'Support Center',
  mobileProfileHelp: 'Help',
  mobileProfileGuides: 'Guides',
  mobileProfileOptionsAndETFsActivations: 'Options and ETFs activations',
  mobileProfileSettings: 'Settings',
  mobileEditProfileResidentialAddress: 'Edit Residential address',
  mobileEditProfileIDDocument: 'Edit ID document',
  mobileEditProfileName: 'Edit Name',

  // New Profile
  mobileProfileMyProfile: 'My Profile',
  mobileProfileInbox: 'Inbox',
  mobileProfileInboxDescription: 'System messages & Updates',
  mobileProfilePersonalInformation: 'Personal information',
  mobileProfilePersonalInformationDescription: 'Personal & Contact details, Address details',
  mobileProfileSecurity: 'Security',
  mobileProfileSecurityDescription: 'Password, Passcode, Biometrics',
  mobileProfileMyReports: 'Reports',
  mobileProfileMyReportsDescription: 'Your monthly statements',
  mobileProfileMyNotifications: 'Notifications',
  mobileProfileMyNotificationsDescription: 'Preferences',

  commonProfileMySupportCenter: 'Help Center',
  commonProfileMySupportCenterDescription: 'Support & FAQ',
  commonProfileTradeHistoryDescription: 'Trade Account Activity',
  commonProfileDocumentsDescription: 'Terms of service, Tariff, etc.',

  // tips & notifications
  mobileProfileTipsAndNotificationsTitle: 'Tips & Notifications',
  mobileProfileTipsAndNotificationsPushNotification: 'Push notification',
  mobileProfileTipsAndNotificationsTips: 'Tips',
  mobileProfileTipsAndNotificationsMarketingMessages: 'Marketing messages',
  mobileProfileTipsAndNotifications: '',

  // inbox
  mobileInboxTitle: 'Inbox',
  mobileInboxBegin: 'Begin',
  mobileInboxEnd: 'End',

  // charts
  mobileChartInterval: '1D,1W,1M,3M,1Y,5Y',

  // set passcode
  mobileSetPasscodeSetupYourPasscode: 'Set up your passcode',
  mobileSetPasscodePleaseConfirm1: 'Please',
  mobileSetPasscodePleaseConfirm2: 'Confirm your passcode',
  mobileSetPasscodeDescription: 'This adds a convenient second layer of security for easy access',
  mobileSetPasscodeResend: 'RESEND',

  // onboarding
  mobileOnboardingSignUp: 'SIGN UP',
  mobileOnboardingLogIn: 'Log In',
  mobileOnboardingAlreadyHaveAccount: 'You already have account?',
  mobileOnboardingPhotoIsClearButton: 'Upload and proceed',
  mobileOnboardingUploadConfirmTitle: 'Is this clear enough?',
  mobileOnboardingUploadRetakeButton: 'Take a new photo',
  mobileOnboardTextIsClear: 'It is clear',

  // news
  mobileNewsBrokenLinkTitle: 'Broken Link',
  mobileNewsBrokenLinkMessage: 'This article lacks URL. Please report this.',

  // stock search
  mobileStocksLabel: 'Stocks',
  mobileNoStocksFound: 'No stocks found',
  commonNoETFsFound: 'No ETFs found',
  mobileStockSearchLoading: 'Loading stock list',
  mobileStockSearchHint: 'Search for stocks...',
  mobileStockSearchDescription: 'Search by symbol or company name',

  // industry search
  mobileIndustrySearchLoading: 'Loading ....',
  mobileIndustrySearchPlaceholder: 'Search...',

  // watchlist when not activated
  mobileWatchlistActivationBoxTitle: 'Activate your account!',
  mobileWatchlistActivationBoxDescription: "Then go ahead with the next steps: Verify and Deposit. Once done you'll be all set to trade.",
  mobileWatchlistActivationBoxDescriptionRow1: 'Then go ahead with the next steps: Verify and',
  mobileWatchlistActivationBoxDescriptionRow2: 'Deposit. Once done you\'ll be all set to trade.',

  // Make your first deposit
  commonFirstDepositTitle: 'Make your first deposit',
  commonFirstDepositDescription1: 'Minimum deposit requirements:',
  commonFirstDepositDescription2: '@@1 or your currency equivalent',
  commonFirstDepositBank: 'Bank Transfer',
  commonFirstDepositBankDescription: 'Up to 5 business days',
  commonFirstDepositCard: 'Debit/Credit Card',
  commonFirstDepositCardFree: 'Transaction fees - up to @@1 of deposit',
  commonFirstDepositCardInstant: 'Within minutes',

  // Top Gainers and Losers, Movers
  commonTopMovers: 'Top Movers',
  commonTopGainers: 'Top Gainers',
  commonTopGainersAndLosersMarketCap: 'Market Cap',
  commonTopGainersAndLosersPercentChange2: '% Change',
  commonTopGainersAndLosersPercentChange: 'Percent Change',
  commonTopGainersAndLosersPriceRange: 'Price Range',
  commonTopGainersAndLosersPriceRangeFilter: 'Price Range: $@@1 to $@@2',
  commonTopGainersAndLosersPriceRangeFilterOver: 'Price Range: Over $@@1',
  commonTopGainersAndLosersReset: 'Reset',
  commonTopGainersAndLosersTradeVolume: 'Trade Volume',
  commonTopGainersFilterBy: 'Filter by:',
  commonTopLosers: 'Top Losers',

  // Trading tickets
  mobileTradingTicketBuy: 'Buy',
  mobileTradingTicketSell: 'Sell',
  mobileTradingTicketOrderType: 'Order type',
  mobileTradingTicketChangeOrderType: 'Change Order Type',
  mobileTradingTicketSelectOrderType: 'Select order type',
  mobileTradingTicketNumberOfShares: 'Number of Shares',
  mobileTradingTicketShortedStocks: 'Shares Available:',
  mobileTradingTicketMarketPrice: 'Market Price',
  mobileTradingTicketMarketOrder: 'Market Order',
  mobileTradingTicketSellMarketOrder: 'Sell Market Order',
  mobileTradingTicketBuyMarketOrder: 'Buy Market Order',
  mobileTradingTicketLimitPrice: 'Limit Price',
  mobileTradingTicketLimitOrder: 'Limit Order',
  mobileTradingTicketStopPrice: 'Stop Price',
  mobileTradingTicketStopOrder: 'Stop Order',
  mobileTradingTicketReviewOrder: 'Review order',
  mobileTradingTicketEstimatedOrderValue: 'Order value',
  mobileTradingTicketBuyingPower: 'Buying power',
  mobileTradingTicketNotice: 'Please be advised that interested fees will be applied on the following trading day',
  mobileTradingTicketExecutedAtBestAvailablePrice: 'Executed at the best available price',
  mobileTradingTicketExecutedAtLimitPriceOrLover: 'Executed at limit price or better',
  mobileTradingTicketExecutedWhenStopPriceIsReached: 'Executed when stop price is reached',
  mobileTradingTicketNotEnoughBuyingPower: 'Not enough buying power',
  mobileTradingTicketNotEnoughBuyingPowerDescription: 'Please add additional funds to your account to complete your trade.',
  mobileTradingTicketYouHaveSuccessfullyPlacedLimitOrder: 'You have successfully placed Limit Order',
  mobileTradingTicketYouHaveSuccessfullyPlacedMarketOrder: 'You have successfully placed Market Order',
  mobileTradingTicketYouHaveSuccessfullyPlacedStopOrder: 'You have successfully placed Stop Order',
  mobileTradingTicketYourOrderWasSuccessfullyPlaced: 'Your order was successfully placed',
  mobileTradingTicketDateOfExpireTitle: 'Set Expiry Date',
  mobileTradingTicketDateOfExpireHowLong: 'How long your order will stay open without filling?',
  mobileTradingTicketDateOfExpireMaxDaysOrderValid: 'The maximum number of days your order is valid',
  mobileTradingTicketDateOfExpireEndOfDay: 'End of Day',
  mobileTradingTicketDateOfExpireGoodTillCancelled: 'Good till Cancelled (GTC)',
  mobileTradingTicketDateOfExpireGoodTillDate: 'Good till Date',
  mobileTradingTicketYourOrderRejected: 'Your order was rejected',
  mobileTradingTicketCurrentAskPrice: 'Current Ask price:',
  mobileTradingTicketCurrentBidPrice: 'Current Bid price:',
  mobileTradingTicketLastTradePrice: 'Last trade price:',

  commonTradeHistoryTitle: 'Trade History',
  tradingMarketLastTradePrice: 'Market Price / Last trade price: @@1',

  // Stock screen
  mobileStockScreenChartTitle: 'Charting Solution Here',
  mobileStockScreenPeopleAlsoTradeItemTitle: 'Charting Solution Here',
  mobileStockScreenStockTileToday: 'Today',

  // Shares Owned
  commonHistory: 'History',
  commonSharesOwned: 'Shares Owned',
  commonShort: 'Short',
  commonViewHistory: 'View History',
  commonTitleHistory: 'Trade History',
  commonQtAvgPrice: 'Qty / Avg. price',
  commonPL: 'P/L',
  commonValue: 'Value',
  commonYouHaveTraded: "You've traded this stock in the past 90 days.",

  // Pending Orders
  commonPendingOrders: 'Pending Orders',

  // Order summary
  commonOrderNo: 'Order No',
  commonOrderDate: 'Order Date',
  commonMarketSell: 'Market Sell',
  commonMarketBuy: 'Market Buy',
  commonMarketLimitBuy: 'Limit Buy',
  commonMarketLimitSell: 'Limit Sell',
  commonExpirationDate: 'Expiration date',
  commonCancelationDate: 'Cancelation date',
  commonBoughtShares: 'Bought Shares',
  commonSoldShares: 'Sold Shares',
  commonFilledPrice: 'Fill Price',
  commonFilledDate: 'Filled date',
  commonPAndL: 'P&L',
  commonRegulatoryFees: 'Regulatory Fees',
  commonViewDetailedReport: 'View detailed report',
  commonCancelOrder: 'Cancel Order',
  commonModify: 'Modify',
  commonModifyOrder: 'Modify Order',

  commonRegulatoryFeesMessage: 'Regulatory fees: @@1 \n These are obligatory trading fees \n collected by FINRA and SEC \n\n FINRA Fee (TAF): @@2 \n $0.000119 per every stock sale \n SEC Fee: @@3 \n\n $0.021 for every sale worth of $1,000',
  commonRegulatoryFeesMessageFINRA: 'FINRA Fee (TAF): @@1',
  commonRegulatoryFeesMessageFINRADesc: '@@1 per every stock sale',
  commonRegulatoryFeesMessageSEC: 'SEC Fee: @@1',
  commonRegulatoryFeesMessageSECDesc: '@@1 for every sale worth of $1,000',
  commonRegulatoryFeesMessageTotal: 'Total: @@1',

  // My Account
  commonMyAccount: 'My Account',
  commonMyAccountChargeTypeTitle: 'Account chart type:',
  commonMyAccountTotalValueTitle: 'Total Value',
  commonMyAccountPLTitle: 'P&L',
  commonMyAccountPastYearLabel: 'Past Year',
  commonMyAccountBuyingPowerLabel: 'Buying Power:',
  commonMyAccountNoStocks: 'No stocks yet.',
  commonMyAccountNoOrders: 'No orders yet.',
  // TODO: optimization of the langProxyImpl function in order to accepts components and render their render method
  commonMyAccountNoStocksMessage1: 'Go explore the world of trading and',
  commonMyAccountNoStocksMessage2: 'discover the stocks for you.',

  commonMyAccountButtonMyStocks: 'My Stocks',
  commonMyAccountButtonPendingOrders: 'Pending orders',
  commonMyAccountButtonAccountBreakdown: 'Account Breakdown',
  commonMyAccountPendingOrdersPartiallFill: 'Partial Fill',
  commonMyAccountPendingOrdersPartialReject: 'Partial Reject',
  commonMyAccountPendingOrdersExtHours: 'Ext. Hours',

  commonAccountBreakdown: 'Account Breakdown',
  commonAccountBreakdownCashToInvest: 'Cash',
  commonAccountBreakdownUsed: 'Used',
  commonShortSell: 'Short Sell',
  commonAccountBreakdownAvailable: 'Available',
  commonAccountBreakdownInvestments: 'Investments',
  commonAccountBreakdownUnrealizedProfit: 'Unrealized Profit',
  commonAccountBreakdownProfitFromInvestedCache: 'Profit from invested cash',
  commonAccountBreakdownProfitFromShortedStocks: 'Profit shorted stocks',
  commonAccountBreakdownProfitIfYouWouldLikeToLearnMore: 'If you would like to learn more about short selling ',
  commonAccountBreakdownProfitPressHere: 'press here',
  commonAccountBreakdownBorrowedFunds: 'Borrowed Funds',
  commonAccountBreakdownBorrowedFundsMargin: 'Borrowed Funds (Margin)',
  commonAccountBreakdownTotalAmountAvailableToBorrow: 'The total amount available to borrow is up to @@1 times your funds.',
  commonAccountBreakdownYourDailyInterestForBorrowedFunds: 'Your daily interest for borrowed funds is: ',
  commonAccountBreakdownTotalStockValue: 'Total Stock Value',
  commonAccountBreakdownShortedStockValue: 'Shorted Stock Value',
  commonAccountBreakdownMakeInvestmentToTrackProtfolioMsg: 'Once you make your investments, you can track your portfolio breakdown here.',
  commonAccountBreakdownTotal: 'Total',
  commonAccountBreakdownLong: 'Long',
  commonAccountBreakdownPieChartStocks: '@@1 Stocks',
  commonAccountBreakdownPieChartElementShare: 'Share @@1%',

  // headers account breakdown
  headerAccountBreakdownSymbolName: 'Symbol/Name',
  headerAccountBreakdownQuantity: 'Quantity',
  headerAccountBreakdownOpenValue: 'Open Value',
  headerAccountBreakdownMarketValue: 'Market Value',

  // headers earnings calendar
  headerEarningsCalendarEarningsCallTime: 'Earnings Call Time',
  headerEarningsCalendarEarningsPrevEPS: 'Prev EPS',
  headerEarningsCalendarEarningsEPSEstimate: 'EPS estimate',
  headerEarningsCalendarEarningsEPSEst: 'EPS est.',

  // headers trade history
  headerTradeHistoryFillPrice: 'Fill Price',
  headerTradeHistoryAmount: 'Amount',

  // Buttons account breakdown table
  accountBreakdownTableOtherButton: 'Other',
  accountBreakdownTableStockLabel: 'stocks',

  // Inbox
  commonInboxTitle: 'Inbox',
  commonInboxMarkAllAsRead: 'Mark all as read',
  commonInboxSubject: 'Subject',
  commonInboxShowLess: 'show Less',

  // Documents
  commonStatements: 'Statements',
  commonClientAgreement: 'Client Agreement',
  commonGeneralTermsAndConditions: 'General Terms and Conditions',
  commonOrderExecutionPolicy: 'Order Execution Policy',
  commonRiskWarningDisclosureAndNotice: 'Risk Warning Disclosure and Notice',
  commonConflictsOfInterestSummary: 'Conflicts of Interest Summary',
  commonClientCategorizationNotice: 'Client Categorization Notice',
  commonComplaintsHandlingPolicy: 'Complaints Handling Policy',
  common10NTariff: '10n Tariff',
  commonTariff: 'Tariff',
  commonDownloadingFile: 'Downloading file...',

  // Notification Settings
  commonMyStocksAndOrders: 'My Stocks and orders',
  commonEverythingAboutYourTradingActivity: 'Everything about your trading activity:',
  commonOrderIsFilledCancelledOrRejected: 'Order is filled, cancelled or rejected, etc.',
  commonPushNotification: 'Push Notification.',
  commonFundsMovement: 'Funds movement',
  commonFundsMovementDescription: 'Changes to your funds and all transfers in and out as well as their current status',
  commonMyAccountStatus: 'My Account status',
  commonMyAccountStatusDescription: 'Changes in margins, funds warnings, etc.',
  commonBenefitsAndPromotions: 'Benefits and Promotions',
  commonBenefitsAndPromotionsDescription: 'Referral updates and ways to enhance your 10n experience',

  // My Profile / Personal Details
  commonPersonalDetails: 'Personal Details',
  commonNames: 'Names',
  commonEditNames: 'Edit Names',
  commonMiddleName: 'Middle Name',
  commonContactDetails: 'Contact Details',
  commonContactEditContactDetails: '  Edit Contact Details',
  commonEdit: 'Edit',
  commonAddresses: 'Addresses',
  commonEditAddresses: 'Edit Addresses',
  commonResidential: 'Residential',
  commonResidentialAddress: 'Residential Address',
  commonMailing: 'Mailing',
  commonMailingAddress: 'Mailing Address',
  commonSameAsResidentialAddress: 'Same as Residential Address',
  commonSameAsMailingAddress: 'Same as mailing address',
  commonPersonalDetailsContactUsDescription: 'If you need to change any of your contact information, please ',
  commonPersonalDetailsContactUsButton: 'contact us.',

  // Account Deletion
  accountDelitionPart1: 'If you wsih to request personal data and account deletion visit the ',
  accountDelitionPart2: 'data privacy section ',
  accountDelitionPart3: 'on our website.',

  // PEP Questions
  commonCheckYesIfApplicable: "Check 'YES' if applicable",

  // Common validations
  validationMessageInvalidCharacters: '@@1 contains invalid characters',
  validationMessageMinCharacters: '@@1 should be at least @@2 characters',
  validationMessageMaxCharacters: '@@1 should be no more than @@2 characters',
  validationMessageStartWithInvalidChar: '@@1 starts with invalid character',

  // My Account - net income cmponents chart
  performanceRangeTextToday: 'Today',
  performanceRangeTextPastMonth: 'Past Month',
  performanceRangeTextPastThreeMonths: 'Past 3 Months',
  performanceRangeTextPastYear: 'Past Year',
  performanceRangeTextPastYearToDate: 'Since 01 Jan @@1',
  performanceRangeTextAll: 'Since @@1',

  // Date range error message
  dateRangeMaxDateErrorMessage: 'Date should not be later than @@1',
  dateRangeMinDateErrorMessage: 'Date should not be earlier than @@1',

  // Symbol details screen
  positionAverageOpenPrice: 'Average open price',
  positionAUnrealisedProfitValue: 'Unrealised profit (USD)',
  positionAUnrealisedProfitPercent: 'Unrealised profit (%)',

  // MyProfile mobile screen
  myProfilePersonalDetails: 'Personal Details',
  myProfileReports: 'Reports',
  myProfileTradingHistory: 'Trading History',
  myProfileDocuments: 'Documents',
  myProfileSecurity: 'Security',
  myProfileHelpCenter: 'Help Center',
  myProfileLogout: 'Logout',

  // Trading View Chart
  tradingViewChartPopupMessage: 'Advanced Charting',
  tradingViewChartLabel: 'Adv. Chart',

  // Hammer Login Page
  hammerLoginTitle1: 'Trade with Hammer Lite',
  hammerLoginTitle2: 'Invest with Ease',
  hammerLoginTitle3: 'Trade like a Pro',

  hammerLoginDescription1: 'The trading platform packed with all the great features with 0% Commission.',
  hammerLoginDescription2: 'Everything you need to be an effective trader - all in One place.',
  hammerLoginDescription3: 'The best of US stocks, Extended trading hours, Short selling, Portfolio management and more.',

  hammerLoginPolicyAgreement: 'Your information will be processed according to our ',

  // Hammer ETFs
  etfTradingRestrictionsModalContentHW: 'Packaged products (ETFs) offered currently on Hammer do not have KIDs. They can only be traded by users verified as professional traders.',

  // Hammer Activation box
  activeYourAccountContent: "Once your application is verified you can make your first deposit. Then you'll be all set to trade.",
  walletAtivationModalContent: 'Your account is being verified. You will be notified when the process is complete.',

  // Reports
  reportsSummary: 'Summary',
  reportsSelectOption: 'Select Option',
  reportsChooseDate: 'Choose Date',
  reportsChooseFormat: 'Choose file format',
  reportsFileFormat: 'File Format',

  totalBySymbol: 'Total by Symbol',
  totalByDate: 'Total by Date',
  summaryByDate: 'Summary by Date',
  detailedReport: 'Detailed Report',
  feesByDestination: 'Fees by Destination',
  adjustments: 'Adjustments',

  totalBySymbolDescription: 'All data for each traded symbol',
  totalByDateDescription: 'All trade data for the selected period',
  summaryByDateDescription: 'A snapshot of daily performance',
  detailedReportDescription: 'A broad overview of trade data for the day',
  feesByDestinationDescription: 'A complete overview of trading fee costs',
  adjustmentsDescription: 'All transfers and fees attributed to the account',

  startingBalance: 'Start Balance',
  endBalance: 'End Balance',
  tradingFeesSummary: 'Trading Fees Summary',
  feesSummary: 'Fees Summary',
  symbolAsset: 'Symbol: @@1      Asset: @@2',
  depositsWithdrawalsAndTransfers: 'Deposit, Withdrawals & Transfers',
  totalforAllSymbols: 'Total for all symbols',
  generatedOnDate: 'Generated on @@1',

  // Report table headers

  currencyHeader: 'Currency',
  cashHeader: 'Cash',
  unrealizedPnlHeader: 'Unrealized PnL',
  totalHeader: 'Total',
  totalFeesHeader: 'Total Fees',
  totalBalanceHeader: 'Total Balance',
  ordersHeader: 'Orders',
  fillsHeader: 'Fills',
  qtyHeader: 'Qty',
  grossHeader: 'Gross',
  commHeader: 'Comm',
  tradingFeesHeader: 'Trading Fees',
  nonTradingFeesHeader: 'Non-Trading Fees',
  netHeader: 'Net',
  ecnfeeHeader: 'ecnFee',
  tafHeader: 'taf',
  secHeader: 'sec',
  clrHeader: 'clr',
  prfHeader: 'prf',
  nsccHeader: 'nscc',
  depositsHeader: 'Deposit',
  withdrawalsHeader: 'Withdrawal',
  transferInHeader: 'Transfer In',
  transferOutHeader: 'Transfer Out',
};
