import React from 'react';

import { ClickableDiv } from '../../../components/index';
import { useIsMobile } from '../../../hooks';

import styles from './ContentDropDown.module.scss';

type Props = {
  onChange: (data) => void;
  closeModal?: (action: boolean) => void;
  options: string[];
};

const ContentDropDown = ({
  onChange,
  closeModal,
  options,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <>
      { isMobile ? (
        <div className={styles.containerMobile}>
          <ClickableDiv
            className={styles.background}
            onClick={() => { closeModal && closeModal(false); }}
          />
          <div className={styles.options}>
            {options.map((option) => (
              <ClickableDiv
                key={option}
                type="button"
                className={styles.option}
                onClick={() => onChange(option)}
              >
                {option}
              </ClickableDiv>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.containerOptions}>
            {
            options.map((option) => (
              <ClickableDiv
                id="enterAmountBankTransferOption"
                className={styles.option}
                key={option}
                onClick={() => onChange(option)}
              >
                {option}
              </ClickableDiv>
            ))
          }
          </div>
        </div>
      )}
    </>
  );
};

export default ContentDropDown;
