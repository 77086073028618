import React from 'react';
import { Input, Label } from 'reactstrap';

import { IRadioGroupOption } from '../RadioGroup.types';

import styles from './RadioGroupOption.module.scss';

const RadioGroupOption = ({ label, value, active, id, ariaLabel, onSelect }: IRadioGroupOption) => (
  <Label className={styles.container}>
    <Input id={`input_${id}`} aria-label={`input ${ariaLabel}`} type="checkbox" value={value} checked={active} onChange={onSelect} />
    <span id={`span_${id}`} aria-label={`span ${ariaLabel}`} className={styles.checkmark} />
    <span className={styles.label}>{label}</span>
  </Label>
);

export default RadioGroupOption;
