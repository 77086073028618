import React, { useState } from 'react';
// eslint-disable-next-line
import {
  SwipeableList,
  SwipeableListItem,
  Type as ListType,
} from 'react-swipeable-list';

import { NullableString } from '../../lib/util/types';

import ButtonsCancelAndModify from '../TableView/tableContainer/ButtonsCancelAndModify/ButtonsCancelAndModify';

import styles from './SymbolDetailsButtons.module.scss';

type Props = {
    data: any;
    activeRowStyle: string;
}

const SymboDeatailsButtons = ({ activeRowStyle, data }: Props) => {
  const [selectedRowKey, setSelectedRowKey] = useState<NullableString>();

  const onSwipeProgress = (rowKey:string, progress: number, direction: string) => {
    const MIN_PROGRESS_TO_OPEN_ROW = 28;
    if (rowKey !== selectedRowKey && progress >= MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(rowKey);
      return;
    }

    if (selectedRowKey && rowKey !== selectedRowKey) {
      setSelectedRowKey(null);
      return;
    }

    if (rowKey === selectedRowKey && progress < MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(null);
    }
  };

  return (

    <SwipeableList
      fullSwipe={false}
      type={ListType.IOS}
      className={styles.wrapperTable}
    >
      {data.map((row, index: number) => {
        const x = index;
        const rowKey = row.id;
        return (
          <SwipeableListItem
            key={rowKey}
            onSwipeProgress={(progress: number, direction: string) =>
              onSwipeProgress(
                rowKey,
                progress,
                direction,
              )}
            trailingActions={(
              <div className={styles.trailingActions}>
                <ButtonsCancelAndModify row={row} isSwipeable />
              </div>
          )}
            maxSwipe={10}
            className={selectedRowKey === rowKey ? activeRowStyle : styles.rowWrapper}
          >
            <div className={styles.row}>
              <div className={styles.order}>
                <div className={styles.orderValueAndDate}>
                  <div className={styles.quantityAndPrice}>
                    {row.totalQuantity}
                    x
                    {row.setPrice}
                  </div>
                  <div className={styles.expiryDate}>
                    {row.expiryDate}
                  </div>
                </div>
                <div className={styles.orderDetails}>
                  <div className={styles.orderType}>
                    {row.type}
                  </div>
                  <div className={styles.orderStatus}>
                    {row.quantity}
                  </div>
                </div>
              </div>
            </div>
          </SwipeableListItem>
        );
      })}
    </SwipeableList>
  );
};
export default SymboDeatailsButtons;
