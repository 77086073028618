import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { OrderStatusEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import { Order } from '../../../lib/models/trading/types';
import { getSummary } from '../../../lib/store/ams';
import { getAccountsAvailableCash } from '../../../lib/store/common-actions';
import { RootState } from '../../../lib/store/index';
import { getAccountTradeHistory, getOpenPositions as getPositions } from '../../../lib/store/reporting';
import {
  PENDING_ORDERS_REQUEST_TENNSTATUS,
  TRADE_HISTORY_ORDERS_TENNSTATUSES,
} from '../../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, OpenPosition, PositionType } from '../../../lib/store/reporting/types';
import { isAvailableCashChanged } from '../../../lib/store/trading/helpers';
import { newOrder as serviceNewOrder, resetRequestStatus } from '../../../lib/store/trading/index';
import MarketStateCache from '../../../lib/store-util/MarketStateCache';
import { isOneOf } from '../../../lib/util/DataHelpers';
import { isMarketClosed, isMarketRegularHours } from '../../../lib/util/MarketDataHelpers';
import { orderStatusToCallStatus } from '../../../lib/util/TradingHelpers';

import ModalCreateOrder from '../../../containers/SymbolDetails/modalCreateOrder/ModalCreateOrder';
import { ProcessFinishedModal, RegularTradingHoursStartModal, ShortSellOrderPlace } from '../../index';
import {
  proccessPartiallyRejectedMessage,
  proccessSuccessMessageBody,
  proccessSuccessMessageTitle,
  processFailMessageManager,
} from '../OrderHelpers';
import useNewOrderTimer from '../useNewOrderTimer';

type Props = {
    symbol: string;
    openOrderModal: ({ target: string, isModalOpened: boolean });
} & ReduxProps;

const CreateOrderModal = (props: Props) => {
  const {
    openOrderModal,
    positions,
    requestStatus,
    submittedOrder,
    symbol,
    getOrders,
    getOpenPositions,
    newOrder,
    resetStatus,
    getMessageSummary,
    getAccountAvailableCash,
  } = props;
  const [order, setOrder] = useState<Order>({});
  const [fromReview, setFromReview] = useState<boolean>();
  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
  const [positionsQuantity, setPositionsQuantity] = useState<number>(0);
  const [notEnoughStocks, setNotEnoughStocks] = useState<boolean>(false);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [hasBtnSellBuyClicked, setHasBtnSellBuyClicked] = useState<boolean>(false);
  const [visibleWarningShortSellDialog, setVisibleWarningShortSellDialog] = useState<boolean>(false);
  const [visibleRegularTradingHoursStartDialog, setVisibleRegularTradingHoursStartDialog] = useState<boolean>(false);

  const { marketState } = MarketStateCache.use();
  const marketClose = isMarketClosed(marketState);
  const { ordType, orderQty, side, status, filledSoFar } = submittedOrder;
  const rejectedOrder = requestStatus === OrderStatusEnum.Rejected;
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;
  const {
    orderTimeoutPassed, showExpired, showFillOrder, hasNoResponse,
  } = useNewOrderTimer(showFinishModal, ordType, status, requestStatus);
  const showResultModal = (orderTimeoutPassed || hasNoResponse || rejectedOrder) && showFinishModal;

  useEffect(() => {
    if (openOrderModal.isModalOpened) {
      setHasBtnSellBuyClicked(true);
      // check in case there is an undentified error with the new order
      if (showFinishModal) {
        setShowFinishModal(!showFinishModal);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOrderModal]);

  useEffect(() => {
    if (showFinishModal) {
      setFromReview(false);
      setShowOrderModal(false);
      setHasBtnSellBuyClicked(false);
    }
  }, [showFinishModal]);

  const handleSellReviewOrder = (isFromReview?: boolean) => {
    const symbolPositions = positions.find((position: OpenPosition) => (position.symbol === symbol));
    const hasShortPositions = symbolPositions?.positionType === PositionType.Sell;

    if (!isMarketRegularHours(marketState) && (!symbolPositions || hasShortPositions)) {
      setVisibleRegularTradingHoursStartDialog(true);
    } else {
      if (!positions.length && !isFromReview) {
        setVisibleWarningShortSellDialog(true);
        return;
      }
      const longPositions = positions.find((position: OpenPosition) => (
        (position.symbol === symbol) && (position.positionType === PositionType.Buy)
      ));

      if (isFromReview) {
        if (longPositions && order.orderQty && longPositions.quantity < order.orderQty) {
          setNotEnoughStocks(true);
        } else {
          newOrder(order);
          setShowFinishModal(true);
        }
      }
      setPositionsQuantity(longPositions ? longPositions.quantity : 0);
      setShowOrderModal(!isFromReview);
    }
  };
  const handleBuyReviewOrder = (isFromReview?: boolean) => {
    const shortedPositions = positions.find((position: OpenPosition) => (
      (position.symbol === symbol) && (position.positionType === PositionType.Sell)
    ));

    if (!isFromReview) {
      setShowOrderModal(true);
    }
    if (isFromReview) {
      if (shortedPositions && order.orderQty && Math.abs(shortedPositions.quantity) < order.orderQty) {
        setNotEnoughStocks(true);
      } else {
        newOrder(order);
        setShowFinishModal(true);
      }
    }

    setPositionsQuantity(shortedPositions ? Math.abs(shortedPositions.quantity) : 0);
  };

  useEffect(() => {
    if (hasBtnSellBuyClicked && !orderInProgress && !showFinishModal) {
      if (openOrderModal.target === PositionType.Sell) {
        handleSellReviewOrder(fromReview);
      } else if (openOrderModal.target === PositionType.Buy) {
        handleBuyReviewOrder(fromReview);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromReview, requestStatus, hasBtnSellBuyClicked, openOrderModal.target]);

  const handlePopUp = (value) => {
    setShowOrderModal(value);
    setHasBtnSellBuyClicked(false);
  };

  const closeShortSellOrderPlaceModal = () => {
    setVisibleWarningShortSellDialog(false);
    setHasBtnSellBuyClicked(false);
  };

  const porceedShortSellOrderPlaceModal = () => {
    setVisibleWarningShortSellDialog(false);
    setPositionsQuantity(0);
    setShowOrderModal(true);
  };

  const closeRegularTradingHoursStartDialog = () => {
    setVisibleRegularTradingHoursStartDialog(false);
    setHasBtnSellBuyClicked(false);
  };

  const onCloseOrderResultModal = () => {
    if (orderInProgress) {
      resetStatus();
    }
    setShowFinishModal(false);
    if (!marketClose) {
      getOpenPositions(symbol);
    }
    if (status && isAvailableCashChanged(status)) {
      getAccountAvailableCash();
    }
    // TODO: Check if these calls can be made as one and populate trade history and pending orders together
    const commonData = { symbol, isSymbolDetails: true };
    getOrders({ ...commonData, tenNStatus: TRADE_HISTORY_ORDERS_TENNSTATUSES });
    getOrders({ ...commonData, tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
  };

  if (isOneOf(status, [
    OrderStatusEnum.Filled,
    OrderStatusEnum.PartiallyFilled,
    OrderStatusEnum.Rejected,
    OrderStatusEnum.Expired,
  ])) {
    getMessageSummary();
  }

  return (
    <>
      {(visibleWarningShortSellDialog && isMarketRegularHours(marketState)) && (
      <ShortSellOrderPlace
        processModal={porceedShortSellOrderPlaceModal}
        toggleModal={closeShortSellOrderPlaceModal}
      />
      )}
      {showResultModal && (
      <ProcessFinishedModal
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        processFailedMessage={
          processFailMessageManager(rejectedOrder, showExpired, symbol, ordType!, side!, orderQty ?? 0)
        }
        processPartiallyRejectedMessage={partiallyRejectedOrder
          ? proccessPartiallyRejectedMessage(symbol, ordType!, side!, orderQty!, filledSoFar) : undefined}
        processSuccessMessageTitle={rejectedOrder ? '' : proccessSuccessMessageTitle(ordType!, side!, status, showFillOrder)}
        processSuccessMessageBody={rejectedOrder
          ? '' : proccessSuccessMessageBody(symbol, status, orderQty, ordType, showFillOrder, filledSoFar)}
        defaultMessageTitle={orderInProgress ? lang.orderMessageBodyPendingWeb(ordType!, side!, orderQty ?? 0, symbol) : ''}
        toggleModal={onCloseOrderResultModal}
      />
      )}
      {notEnoughStocks && (
        <ProcessFinishedModal
          processSuccessMessageTitle=""
          toggleModal={() => { setNotEnoughStocks(false); setShowFinishModal(false); }}
          processFailedMessage={lang.webTradingIncorrectStocks()}
          callStatus={orderStatusToCallStatus(OrderStatusEnum.Rejected)}
        />
      )}
      {visibleRegularTradingHoursStartDialog && (
      <RegularTradingHoursStartModal
        toggleModal={closeRegularTradingHoursStartDialog}
      />
      )}
      <ModalCreateOrder
        symbol={symbol}
        handlePopUp={handlePopUp}
        orderSide={openOrderModal.target}
        positionsQuantity={positionsQuantity}
        isOpenModalCreateOrder={showOrderModal}
        setOrderInfo={(value) => setOrder(value)}
        setFromReview={(value) => setFromReview(value)}
      />
    </>
  );
};

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  requestStatus: state.trading.requestStatus,
  submittedOrder: state.trading.order,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetStatus: () => dispatch(resetRequestStatus()),
  newOrder: (order: Order) => dispatch(serviceNewOrder({ order })),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getOpenPositions: (symbol: string) => dispatch(getPositions({ symbol })),
  getMessageSummary: () => dispatch(getSummary()),
  getAccountAvailableCash: () => dispatch(getAccountsAvailableCash()),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CreateOrderModal);
