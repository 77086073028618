import React, { useState } from 'react';
import { Modal } from 'reactstrap';

import { PaymentPspStatusEnum, PredefinedMilisecondsTypeEnum } from '../../../../lib/enums';
import lang from '../../../../lib/language';
import { Payment } from '../../../../lib/models/crm/types';
import { formatCurrency } from '../../../../lib/util/DataHelpers';
import { useTimeout } from '../../../../lib/util/hooks/TimeoutHooks';
import { NullableNumber } from '../../../../lib/util/types';

import iconError from '../../../../assets/img/check-fail.svg';
import iconSeccess from '../../../../assets/img/icon-check-success.svg';
import iconPending from '../../../../assets/img/icon-pending_24px.svg';

import styles from './CardTransferModal.module.scss';

export type Props = {
  cardPaymentStatus: number
  amount?: NullableNumber
};

const CardTransferModal = (props: Props) => {
  const {
    cardPaymentStatus,
    amount,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  useTimeout(
    () => {
      setIsOpen(false);
    },
    PredefinedMilisecondsTypeEnum.threeSeconds,
    'CardTransferModal',
  );

  const handleWrapperContent = (icon: string, text: string) => (
    <>
      <img className={styles.icon} src={icon} alt="icon card transfer" />
      <h6 className={styles.description}>{text}</h6>
    </>
  );

  return (
    <Modal
      centered
      isOpen={isOpen}
      contentClassName={styles.modal}
    >
      <div className={styles.wrapperContent}>
        {
          cardPaymentStatus === PaymentPspStatusEnum.Rejected
          && handleWrapperContent(iconError, lang.mobileFundsScreenFailedCardDepositDialog())
        }
        {
          cardPaymentStatus === PaymentPspStatusEnum.Processed
          && handleWrapperContent(iconSeccess, lang.mobileFundsScreenSuccessCardDepositDialog(formatCurrency(amount)))
        }
        {
          cardPaymentStatus === PaymentPspStatusEnum.ApprovedByPsp
          && handleWrapperContent(iconPending, lang.mobileFundsScreenPendingCardDepositDialog(formatCurrency(amount)))
        }
      </div>
    </Modal>
  );
};

export default CardTransferModal;
