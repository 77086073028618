import { OrderSideEnum } from '../../lib/enums';
import { Order } from '../../lib/models/trading/types';
import { getDateTime } from '../../lib/util/DateTimeHelpers';

export const CHART_CONTAINER_HEIGHT = '90%';
export const CHART_CONTAINER_WIDTH = 900;
export const LINE_CLOSE_LABEL_X = 790;
export const LINE_CLOSE_LABEL_Y_UNDER = 20;
export const LINE_CLOSE_LABEL_Y_ABOVE = -20;
export const LINE_CHART_WIDTH = 200;
export const LINE_CHART_HEIGHT = 30;
export const LINE_CHART_HEIGHT_MOB = 70;
export const LINE_CHART_HEIGHT_CARD = 70;

export const CHART_CONTAINER_PADDING = {
  bottom: 25,
  left: 40,
  right: 20,
};

export const VICTORY_ZERO_VALUE = 0.00001;
// TODO: Remove hardcoded version of orders with and replace with real data
export const MOCKED_ORDERS: Order[] = [{
  updatedAt: getDateTime(true, [ 'minus' ], [[3, 'hours']]),
  price: 1093.7399,
  stopPrice: 1093.7399,
  side: OrderSideEnum.Sell,
  orderQty: 2,
}, {
  updatedAt: getDateTime(true, [ 'minus' ], [[5, 'hours']]),
  price: 1041.04,
  stopPrice: 1041.04,
  side: OrderSideEnum.Buy,
  orderQty: 2,
}];
