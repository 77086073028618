import React from 'react';

import lang from '../../../lib/language';

import { ReactComponent as UploadIcon } from '../../../assets/img/icon-upload.svg';

import styles from '../Document.module.scss';

const NullableDocumentContainer = (): JSX.Element => (
  <div className={styles.fileUpload}>
    <div className={styles.fileUploadMessage}>
      <UploadIcon />
      <p className={styles.fileUploadInfosMessage}>
        {lang.commonUploadDocumentLabel1()}
        <span className={styles.decorated}>
            &nbsp;
          {lang.commonUploadDocumentLabel2()}
        </span>
      </p>
    </div>
  </div>
);

export default NullableDocumentContainer;
