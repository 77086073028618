import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { OrderSideEnum } from '../../../lib/enums/order-side.enum';
import { OrderStatusEnum } from '../../../lib/enums/order-status.enum';
import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { Order } from '../../../lib/models/trading/types';
import { getSummary } from '../../../lib/store/ams/index';
import { getAccountsAvailableCash } from '../../../lib/store/common-actions';
import { RootState } from '../../../lib/store/index';
import {
  getAccountTradeHistory,
  getOpenPositions as getPositions,
  setHasOrderUpdate,
} from '../../../lib/store/reporting';
import {
  PENDING_ORDERS_REQUEST_TENNSTATUS,
  TRADE_HISTORY_ORDERS_TENNSTATUSES,
} from '../../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, OpenPosition, PositionType } from '../../../lib/store/reporting/types';
import { isAvailableCashChanged } from '../../../lib/store/trading/helpers';
import { newOrder as serviceNewOrder, resetRequestStatus } from '../../../lib/store/trading/index';
import MarketStateCache from '../../../lib/store-util/MarketStateCache';
import SymbolStatusCache from '../../../lib/store-util/SymbolStatusCache';
import { isOneOf } from '../../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';
import { checkIfSymbolIsHalted } from '../../../lib/util/HaltSymbolHelper';
import { isMarketClosed } from '../../../lib/util/MarketDataHelpers';
import { orderStatusToCallStatus } from '../../../lib/util/TradingHelpers';

import { Preloader, ProcessFinishedModal } from '../../index';
import {
  proccessPartiallyRejectedMessage,
  proccessSuccessMessageBody,
  proccessSuccessMessageTitle,
  processFailMessageManager,
} from '../OrderHelpers';
import useNewOrderTimer from '../useNewOrderTimer';

import CreateTicketOrder from './CreateTicketOrder';

type OwnProps = {
    symbol: string;
    isUserRestricted?: boolean;
}
type Props = OwnProps & ReduxProps

const OrderNavigation = (props: Props) => {
  const {
    symbol,
    positions,
    requestStatus,
    submittedOrder,
    accountOpenPositionsCompleted,
    isUserRestricted,
    newOrder,
    getOpenPositions,
    getOrders,
    myStocksUpdate,
    resetStatus,
    getMessageSummary,
    getAccountAvailableCash,
  } = props;

  const [order, setOrder] = useState<Order>({});
  const [updateOrder, setUpdateOrder] = useState<boolean>(false);
  const [fromReview, setFromReview] = useState<boolean>(false);
  const [notEnoughStocks, setNotEnoughStocks] = useState<boolean>(false);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [orderInitialized, setOrderInitialized] = useState<boolean>(false);
  const [positionsQuantity, setPositionsQuantity] = useState<number | null>(null);

  const { marketState } = MarketStateCache.use();
  const symbolsStatusCache = SymbolStatusCache.use(symbol);
  const marketClosed = isMarketClosed(marketState);
  const { ordType, orderQty, side, status, filledSoFar } = submittedOrder;
  const {
    orderTimeoutPassed, showFillOrder, showExpired, hasNoResponse,
  } = useNewOrderTimer(showFinishModal, ordType, status, requestStatus);
  const rejectedOrder = requestStatus === OrderStatusEnum.Rejected;
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;
  const showOrderModal = (orderTimeoutPassed || hasNoResponse || rejectedOrder) && showFinishModal;

  useEffect(() => {
    setOrderInitialized(false);
    if (orderInProgress) {
      setOrderInitialized(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  const managePositions = (isFromReview: boolean) => {
    const longPositions = positions.find((position: OpenPosition) => (
      (position.symbol === symbol) && (position.positionType === PositionType.Buy)
    ));
    const shortedPositions = positions.find((position: OpenPosition) => (
      (position.symbol === symbol) && (position.positionType === PositionType.Sell)
    ));

    if (isFromReview) {
      if (order.orderQty
        && ((
          longPositions
          && order.side === OrderSideEnum.Sell
          && longPositions.quantity < order.orderQty
        ) || (
          shortedPositions
          && order.side === OrderSideEnum.Buy
          && Math.abs(shortedPositions.quantity) < order.orderQty
        ))) {
        setNotEnoughStocks(true);
      } else {
        newOrder(order);
        setShowFinishModal(true);
      }
      setFromReview(false);
    }
    if (shortedPositions) {
      setPositionsQuantity(Math.abs(shortedPositions.quantity));
    } else if (longPositions) {
      setPositionsQuantity(longPositions.quantity);
    } else {
      setPositionsQuantity(0);
    }
    setUpdateOrder(false);
    setOrderInitialized(true);
  };

  useEffect(() => {
    if (showFinishModal) {
      setFromReview(false);
    }
  }, [showFinishModal]);

  useEffect(() => {
    if (orderInProgress) {
      return;
    }
    if (accountOpenPositionsCompleted) {
      managePositions(fromReview);
    } else if (updateOrder) {
      setOrderInitialized(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountOpenPositionsCompleted, fromReview, positions, requestStatus]);

  if (!orderInitialized) {
    return <Preloader style={{ height: '465px' }} text="" />;
  }

  const onCloseResultOrderModal = () => {
    setShowFinishModal(false);
    if (orderInProgress) {
      resetStatus();
    }
    if (!marketClosed) {
      setUpdateOrder(true);
      getOpenPositions(symbol);
      myStocksUpdate(false);
    }
    if (isOneOf(status, [
      OrderStatusEnum.Filled,
      OrderStatusEnum.PartiallyFilled,
      OrderStatusEnum.Rejected,
      OrderStatusEnum.Expired,
    ])) {
      getMessageSummary();
    }
    if (status && isAvailableCashChanged(status)) {
      getAccountAvailableCash();
    }
    // TODO: Check if these calls can be made as one and populate trade history and pending orders together
    const commonData = { symbol, isSymbolDetails: true };
    getOrders({ ...commonData, tenNStatus: TRADE_HISTORY_ORDERS_TENNSTATUSES });
    getOrders({ ...commonData, tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
  };

  return (
    <>
      <CreateTicketOrder
        symbol={symbol}
        isSymbolHalted={checkIfSymbolIsHalted(symbolsStatusCache)}
        isUserRestricted={isUserRestricted}
        setFromReview={(value) => setFromReview(value)}
        positionsQuantity={positionsQuantity || null}
        setOrderInfo={(value) => setOrder(value)}
      />
      {notEnoughStocks && (
      <ProcessFinishedModal
        processSuccessMessageTitle=""
        callStatus={orderStatusToCallStatus(OrderStatusEnum.Rejected)}
        processFailedMessage={lang.webTradingIncorrectStocks()}
        toggleModal={() => {
          if (!marketClosed) {
            setUpdateOrder(true);
          }
          setShowFinishModal(false);
          setNotEnoughStocks(false);
        }}
      />
      )}
      {showOrderModal && (
      <ProcessFinishedModal
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        processFailedMessage={
          processFailMessageManager(rejectedOrder, showExpired, symbol, ordType!, side!, orderQty ?? 0)
        }
        processPartiallyRejectedMessage={partiallyRejectedOrder
          ? proccessPartiallyRejectedMessage(symbol, ordType!, side!, orderQty!, filledSoFar) : undefined}
        processSuccessMessageTitle={rejectedOrder
          ? '' : proccessSuccessMessageTitle(ordType!, side!, status, showFillOrder)}
        processSuccessMessageBody={rejectedOrder
          ? '' : proccessSuccessMessageBody(symbol, status, orderQty, ordType, showFillOrder, filledSoFar)}
        defaultMessageTitle={orderInProgress ? lang.orderMessageBodyPendingWeb(ordType!, side!, orderQty ?? 0, symbol) : ''}
        toggleModal={onCloseResultOrderModal}
      />
      )}
    </>
  );
};

const mapStateProps = (state: RootState) => ({
  submittedOrder: state.trading.order,
  positions: state.reporting.openPositions,
  requestStatus: state.trading.requestStatus,
  accountOpenPositionsCompleted: isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetStatus: () => dispatch(resetRequestStatus()),
  newOrder: (order: Order) => dispatch(serviceNewOrder({ order })),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getOpenPositions: (symbol?: string) => dispatch(getPositions({ symbol })),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
  getMessageSummary: () => dispatch(getSummary()),
  getAccountAvailableCash: () => dispatch(getAccountsAvailableCash()),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OrderNavigation);
