/**
 * The following helpers are strictly for use only in trading slice in Redux.
 */
import { OrderStatusEnum } from '../../enums/order-status.enum';
import {
  GWRequestType,
  GWResponseType,
  SORCommonOrderResponse,
  SORErrorResponse,
  SORExecutionReportResponse,
  SOROrderStatus,
} from '../../models/gateway/types';
import MarketStateCache from '../../store-util/MarketStateCache';
import { isOneOf } from '../../util/DataHelpers';
import { isMarketClosed } from '../../util/MarketDataHelpers';
import { getProp } from '../../util/ObjectTools';

import { TradeState } from './types';

export const orderFinalStatuses = [
  SOROrderStatus.Fill,
  SOROrderStatus.Canceled,
  SOROrderStatus.Replace,
  SOROrderStatus.Rejected,
  SOROrderStatus.Expired,
  SOROrderStatus.PartiallyReject,
];

export const isFinalStatus = (status: SOROrderStatus) => orderFinalStatuses.includes(status);

export const isAvailableCashChanged = (status: string) => (
  status === OrderStatusEnum.Filled
  || status === OrderStatusEnum.PartiallyFilled
  || status === OrderStatusEnum.PartiallyReject
);

export const updateOrder = (
  state: TradeState,
  data: SORCommonOrderResponse | SORExecutionReportResponse | SORErrorResponse,
) => {
  const { status, messageType, clientOrderId } = data as SORCommonOrderResponse ?? {};
  const { filled } = data as SORExecutionReportResponse ?? {};

  const orderId = getProp(state, 'order.clientOrderId');
  const originalOrderId = getProp(state, 'order.originalClientOrderId');

  const isCurrentOrder = orderId != null && orderId === clientOrderId;
  /**
   * Happens on modify and cancel. The original order is the parent order.
   */
  const isOriginalOrder = originalOrderId != null && originalOrderId === clientOrderId;

  // rejected cases
  const rejectMessages = [ GWResponseType.OrderRejectedResponse, GWResponseType.ModifyOrCancelReject ];
  const isFinalReject = isOneOf<GWResponseType>(messageType, rejectMessages);
  const isServerError = messageType === GWResponseType.Error;

  // check if the status is final
  state.isFinalOrderStatus = isFinalStatus(status);

  const isRejected = status === SOROrderStatus.Rejected;
  if (((isFinalReject || isRejected) && (isCurrentOrder || isOriginalOrder)) || isServerError) {
    state.order.status = OrderStatusEnum.Rejected;
    state.requestStatus = (isFinalReject || isServerError) ? OrderStatusEnum.FinalReject : OrderStatusEnum.Rejected;
    state.lastSorOrderData = data as SORErrorResponse;
    return;
  }

  // new order cases
  if (isCurrentOrder) {
    state.requestStatus = OrderStatusEnum.Accepted;

    switch (status) {
      // TODO: Status New in response when order was Modified - final status
      case SOROrderStatus.New:
        if (state.order.requestType === GWRequestType.ModifyOrderRequest) {
          state.order.status = OrderStatusEnum.Modified;
        } else {
          state.order.status = OrderStatusEnum.Accepted;
        }
        break;
      // case SOROrderStatus.New:
      case SOROrderStatus.PendingNew:
        state.order.status = OrderStatusEnum.Accepted;
        break;

      case SOROrderStatus.Fill:
        state.order.status = OrderStatusEnum.Filled;
        break;

      case SOROrderStatus.PartialFill:
        state.order.status = OrderStatusEnum.PartiallyFilled;
        state.order.filledSoFar = filled;
        break;

      case SOROrderStatus.PartiallyReject:
        state.order.status = OrderStatusEnum.PartiallyReject;
        state.order.filledSoFar = filled;
        break;

      case SOROrderStatus.Expired:
        state.order.status = OrderStatusEnum.Expired;
        break;

      default:
        state.order.status = OrderStatusEnum.Accepted;
        console.warn(`[trading/helpers] updateOrder - generic case for '${messageType}' -- order status: '${SOROrderStatus[status]}'`);
        break;
    }
    return;
  }

  // modify or cancel cases
  if (isOriginalOrder) {
    switch (status) {
      case SOROrderStatus.Fill:
      case SOROrderStatus.PartiallyReject:
        state.order.status = OrderStatusEnum.Rejected;
        state.requestStatus = OrderStatusEnum.Rejected;
        break;

      case SOROrderStatus.Replace:
        if (state.order.requestType === GWRequestType.ModifyOrderRequest) {
          state.order.status = OrderStatusEnum.PendingCancelOrModify;
          state.requestStatus = OrderStatusEnum.Accepted;
        }
        break;

      case SOROrderStatus.Expired:
      case SOROrderStatus.PendingReplace:
      case SOROrderStatus.PendingCancel:
        state.order.status = OrderStatusEnum.PendingCancelOrModify;
        break;

      case SOROrderStatus.Canceled:
        state.order.status = OrderStatusEnum.Cancelled;
        state.requestStatus = OrderStatusEnum.Accepted;
        break;

      default:
        break;
    }
  }
};

// eslint-disable-next-line max-len
export const hasBPUpdated = (orderStatus: SOROrderStatus): boolean => [ SOROrderStatus.PendingNew, SOROrderStatus.Canceled ]
  .includes(orderStatus) && isMarketClosed(MarketStateCache.get().marketState);
