import React from 'react';

import styles from '../Document.module.scss';

type Props = {
    filePreview: string;
}

const DocumentPreview = ({ filePreview }: Props): JSX.Element => (
  <div className={[styles.fileUpload, styles.hasPreview].join(' ')}>
    <div className={styles.fileUploadPreview}>
      <span className={styles.fileUploadRender}>
        <img
          alt=""
          src={filePreview as string}
          className={styles.fileUploadPreviewImg}
        />
      </span>
    </div>
  </div>
);
export default DocumentPreview;
