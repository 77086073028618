import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { FileMimeTypeEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { TEN_MB } from '../../lib/libSettings';
import { generatingNumberFromBytes, getDataFromBase64DataUrl } from '../../lib/util/DataHelpers';

import { ButtonTypesEnum } from '../Button/Button.types';
import { IUploadFileData } from '../Document/Document.types';
import { Button } from '..';

import styles from './UploadBankStatementImage.module.scss';

type Props = {
    uploadDisabled: boolean | undefined;
    handleUploadDocument?: Dispatch<SetStateAction<string>>;
    handleUploadFile?: Dispatch<SetStateAction<IUploadFileData>>;
    handleUploadFileAsFileFormat?: Dispatch<SetStateAction<File | undefined>>;
    handleConfirm: () => void;
}

const UploadBankStatementImage = (props: Props) => {
  const {
    uploadDisabled,
    handleUploadDocument,
    handleUploadFile,
    handleUploadFileAsFileFormat,
    handleConfirm,
  } = props;
  const fileInputRef = useRef(null);

  const [filePreview, setFilePreview] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [showFilePreview, setShowFilePreview] = useState<boolean>(false);

  const onDrop = useCallback((files: FileList | null): void => {
    if (files && files[0]) {
      const file = files[0];
      const allowedFileTypes = [FileMimeTypeEnum.Jpeg, FileMimeTypeEnum.Jpg, FileMimeTypeEnum.Png] as string[];

      if (allowedFileTypes.indexOf(file.type) < 0) {
        setFileError(lang.commonVerificationUploadFileWrongFormatMessage());
      } else if (generatingNumberFromBytes(file.size) > TEN_MB) {
        setFileError(lang.commonVerificationUploadFileSizeExceededMessage());
      } else {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          const base64DataUrl = fileReader.result as string;
          const base64Data = getDataFromBase64DataUrl(base64DataUrl, file.type);
          if (handleUploadFile) {
            handleUploadFile({ fileName: file.name, base64Data });
          }
          if (handleUploadFileAsFileFormat) {
            handleUploadFileAsFileFormat(file);
          }
          if (handleUploadDocument) {
            handleUploadDocument(base64Data);
          }

          setShowFilePreview(true);
          setFilePreview(base64DataUrl);
          setFileError('');
        };
      }
    }
  }, [handleUploadFile, handleUploadFileAsFileFormat, handleUploadDocument]);

  const onConfirm = () => {
    setShowFilePreview(false);
    handleConfirm();
  };

  const renderInput = () => (
    <input
      ref={fileInputRef}
      className={styles.input}
      disabled={uploadDisabled}
      type="file"
      onChange={(e) => { onDrop(e.target.files); }}
    />
  );

  const renderBtnUpload = (text: string, variant: ButtonTypesEnum, className?: string) => (
    <Button
      fullWidth
      className={className}
      variant={variant}
      id="uploadBankStatementBackConfirm"
      disabled={uploadDisabled}
      onClick={() => { (fileInputRef as any).current.click(); }}
    >
      {text}
    </Button>
  );

  return (
    <>
      {showFilePreview && (
        <div className={styles.filePreviewWrapper}>
          <div className={styles.imageWrapper}>
            <img src={filePreview} className={styles.image} alt="document" />
          </div>
          <div className={styles.bodyWrapper}>
            <div className={styles.body}>
              <div className={styles.textWrapper}>
                <div className={styles.title}>
                  {lang.mobileOnboardingUploadConfirmTitle()}
                </div>
                <div className={styles.description}>
                  {lang.commonOnboardingUploadDocumentConfirmDescription()}
                </div>
              </div>
              <div className={styles.buttonsWrapper}>
                {renderBtnUpload(lang.mobileOnboardingUploadRetakeButton(), ButtonTypesEnum.Tertiary)}
                <Button
                  fullWidth
                  id="confirmUploadBankStatementBackConfirm"
                  className={styles.continueBtn}
                  onClick={onConfirm}
                >
                  {lang.mobileOnboardTextIsClear()}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {renderInput()}
      {(fileError && (fileError.trim() !== '')) && <p className={styles.error}>{fileError}</p>}
      {!showFilePreview && (
        <div className={styles.buttonWrapper}>
          <div className={styles.button}>
            {renderBtnUpload(lang.commonContinueButton(), ButtonTypesEnum.Primary)}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadBankStatementImage;
