import config from '../../../config';
import { SUBDOMAIN_POSTFIX_BY_ENV } from '../../libSettings';
import { News } from '../../models/news';

const { currentEnv: env } = config;

const DEFAULT_IMAGES = [
  '1_news_2.png',
  '2_globe.png',
  '3_live.png',
  '4_radio_microphone.png',
  '5_on_air.png',
];

const LOCAL_BASE = 'http://localhost:4210';
const URL_BASE = `https://10n${SUBDOMAIN_POSTFIX_BY_ENV}.alaricsecurities.net`;
const IMAGE_BASE = 'images/news';

const getUrlBase = () => {
  const { default: configLib } = require('../../../configLib');
  const { __DEV__, isWeb } = configLib;
  if (isWeb && __DEV__) return LOCAL_BASE;

  return URL_BASE;
};

export function filterDuplicates<T extends any = any>(data: T[], filterProps: string[]) {
  const dataMap: any = {};
  return data?.filter?.((item: any) => {
    const props: string[] = (filterProps ?? []);
    const allValues: any[] = [];
    props.forEach(prop => { allValues.push(item[prop]); });
    const valuesStr = allValues.join('_');
    const cache = dataMap[valuesStr];
    if (!cache) {
      dataMap[valuesStr] = 1;
      return true;
    }

    dataMap[valuesStr]++;

    return false;
  });
}

const handleCheckUrlImage = (url: string) => /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url);

let currentDefaultImageIndex: number = 0;
export const checkImageURLs = (data: News[]) => {
  const filteredDuplicatesData = filterDuplicates<News>(data, [ 'id' ]);

  filteredDuplicatesData.filter(item => {
    const hasImg = !item.image ? false : handleCheckUrlImage(item.image);

    if (!hasImg) {
      item.image = `${getUrlBase()}/${IMAGE_BASE}/${DEFAULT_IMAGES[currentDefaultImageIndex]}`;
      currentDefaultImageIndex += 1;

      if (currentDefaultImageIndex === DEFAULT_IMAGES.length) currentDefaultImageIndex = 0;
    }

    return item;
  });

  return filteredDuplicatesData;
};
