import React from 'react';

import lang from '../../../lib/language';

import iconAdded from '../../../assets/img/icon-check-circle_24px.svg';
import iconAdd from '../../../assets/img/icon-plus-circle_24px.svg';
import { useIsMobile } from '../../../hooks';

import styles from './AddToWatchList.module.scss';

type Props = {
    isAdded?: boolean
}
const AddToWatchlistButton = ({ isAdded } : Props): JSX.Element => {
  const isMobile = useIsMobile();
  return (
    <div className={styles.buttonContent}>
      {
      isAdded
        ? <img src={iconAdded} alt="icon added" />
        : <img src={iconAdd} alt="icon add" />
    }

      {
      isMobile
        ? null
        : <span>{isAdded ? lang.symbolDetailsAddedToWatchlist() : lang.symbolDetailsAddToWatchlist()}</span>
    }
    </div>
  );
};

export default AddToWatchlistButton;
