import { useEffect, useState } from 'react';

const useBrowserHeight = () => {
  const [browserHeightSize, setBrowserHeightSize] = useState<number>(0);

  useEffect(() => {
    const onScroll = () => setBrowserHeightSize(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { browserHeightSize };
};

export default useBrowserHeight;
