import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';

import { UTC_MOMENT_FORMAT } from '../../../lib/constants/date-time.constants';
import lang from '../../../lib/language';
import { Payment } from '../../../lib/models/crm/types';
import { RootState } from '../../../lib/store';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '../../../lib/store/payment/constants';
import { getDateTime } from '../../../lib/util/DateTimeHelpers';
import { TextHelpers } from '../../../lib/util/TextHelpers';

import noMessagesLogo from '../../../assets/img/image_empty.png';
import { BaseModal, BaseTable, Button } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import ClickableDiv from '../../../components/ClickableDiv/ClickableDiv';
import DateRangePickerWrapper from '../../../components/DateRangePickerWrapper/DateRangePickerWrapper';
import MobileFromToDatePicker from '../../../components/MobileFromToDatePicker/MobileFromToDatePicker';
import { WATCH_LIST_TABLE_TRANSACTIONS } from '../../../components/WatchList/WatchList.constans';
import { useIsMobile } from '../../../hooks';
import { XL_BREAKPOINT_WIDTH } from '../constants';
import Modal from '../modal/paymentsModal/BasicModal';

import TransactionItem from './TransactionItem';

import styles from './Transactions.module.scss';

const USE_PAGING = true;

type Props = {
  isUserVerified: boolean,
  browserWidthSize: number,
} & ReduxProps;

const Transactions = (props: Props) => {
  const {
    allTransactions,
    isUserVerified,
    browserWidthSize,
  } = props;
  const isMobile = useIsMobile();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState({ values: {} });
  const [transactionsPage, setTransactionsPage] = useState<number>(1);
  const [transactions, setTransactions] = useState<Payment[]>([]);
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any>({ startDate: '', endDate: '' });
  const [resultsNumber, setResultsNumber] = useState<number>(8);
  let scrollNumber = 0;

  const handleDateChange = (value) => {
    const { startDate, endDate } = value;
    setRangeDate({ ...rangeDate, startDate, endDate });
  };

  useEffect(() => {
    const endIndex = DEFAULT_TRANSACTIONS_PAGE_SIZE * transactionsPage;
    const currentTransactions = allTransactions.slice(0, endIndex);
    const { startDate, endDate } = rangeDate;

    if (startDate) {
      const convertStartDate = getDateTime(true, ['format'], [ [UTC_MOMENT_FORMAT] ], startDate);
      const convertEndDate = getDateTime(true, ['format'], [ [UTC_MOMENT_FORMAT] ], endDate);
      const paymentsByDate: Payment[] = [];

      for (const payment of allTransactions) {
        const convetPaymentDate = getDateTime(true, ['format'], [ [UTC_MOMENT_FORMAT] ], payment.create_date);
        if (Date.parse(convetPaymentDate) <= Date.parse(convertEndDate)
        && Date.parse(convetPaymentDate) >= Date.parse(convertStartDate)
        ) {
          paymentsByDate.push(payment);
        }
      }

      const isOverLimit = paymentsByDate.length > endIndex;

      setHasMorePages(isOverLimit);
      setTransactions(isOverLimit ? paymentsByDate.slice(0, endIndex) : paymentsByDate);
    } else {
      setHasMorePages(currentTransactions.length < allTransactions.length);
      setTransactions(currentTransactions);
    }
  }, [allTransactions, rangeDate, transactionsPage]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const isAtBottom = scrollTop + windowHeight === documentHeight;

    if (isAtBottom) {
      handleButtonLoadMore();
      scrollNumber += 4;
      setTimeout(() => {
        setResultsNumber(scrollNumber + 4);
      }, 250);
    }
  };

  useEffect(() => {
    isMobile && window.addEventListener('scroll', handleScroll);
    !isMobile && window.removeEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalTransfer = () => {
    setShowModal(!showModal);
  };

  const handleClick = (payment: Payment) => {
    const values = {
      hasLinkedAccounts: true,
      amount: payment.amount,
      create_date: payment.create_date,
      payment_method: payment.payment_method,
      category: TextHelpers.getPaymentProcessName(payment.payment_process),
      adjustmentCategory: payment.category,
      bankName: payment.sender_bank_name,
      paymentReference: payment.payment_reference,
      paymentStatus: payment.payment_status,
      paymentProcess: payment.payment_process,
      description: payment.description,
      payment,
    };

    setInfoModal({ ...infoModal, values });
    handleModalTransfer();
  };

  const handleButtonLoadMore = () => {
    if (USE_PAGING) setTransactionsPage(num => num + 1);
    else setTransactionsPage(Math.ceil(allTransactions.length / DEFAULT_TRANSACTIONS_PAGE_SIZE));
  };

  const tableHeaders = useMemo(() => WATCH_LIST_TABLE_TRANSACTIONS.map(
    (item: string) => <th id={`transactionsTableHeader${item}`} key={item}>{item}</th>,
  ), []);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.titleViewWrapper}>
        <h6 id="transactionsTitle" className={styles.title}>{lang.webFundsTransactionsTitle()}</h6>
        { isMobile && allTransactions.length > 0 && (
          <MobileFromToDatePicker
            label={lang.commonDate()}
            initialState={{
              startDate: new Date(allTransactions[allTransactions.length - 1].create_date),
              endDate: new Date(),
            }}
            maxDateCalendar={new Date()}
            minDateCalendar={new Date(allTransactions[allTransactions.length - 1].create_date)}
            onDateSelect={handleDateChange}
          />
        )}
      </div>
      <div className={styles.wrapperDateRangePicker}>
        {allTransactions.length > 0 && (
        <>
          { !isMobile && (
            <DateRangePickerWrapper
              initialState={{
                startDate: new Date(allTransactions[allTransactions.length - 1].create_date),
                endDate: new Date(),
              }}
              maxDateCalendar={new Date()}
              minDateCalendar={new Date(allTransactions[allTransactions.length - 1].create_date)}
              onDateSelect={handleDateChange}
            />
          )}
        </>
        )}
      </div>
      {
        transactions.length === 0
        && (
        <div className={styles.wrapperNoTransactions}>
          <img className={styles.noMessagesLogo} src={noMessagesLogo} alt="no messages logo" />
          <p className={styles.noMessageText}>{lang.commonInboxNoMessagesText()}</p>
        </div>
        )
      }
      {
        (isUserVerified && allTransactions.length > 0) && (
        // eslint-disable-next-line no-nested-ternary
        <div className={isMobile
          ? styles.wrapperTransactionsMobile
          : (
            browserWidthSize >= XL_BREAKPOINT_WIDTH
              ? styles.wrapperTableFullScreen
              : styles.wrapperTableNoFullScreen
          )}
        >
          { isMobile ? (
            <>
              {transactions.slice(0, resultsNumber).map((payment, index) => {
                const key = `transactionsItem_${index}`;
                return (
                  <ClickableDiv key={key} onClick={() => handleClick(payment)}>
                    <TransactionItem payment={payment} />
                  </ClickableDiv>
                );
              })}
            </>
          ) : (
            <BaseTable id="transactionsTable">
              <thead>
                {transactions.length > 0 && (<tr>{tableHeaders}</tr>)}
              </thead>
              <tbody>
                {transactions.map((payment, index) => (
                  <tr id={`transactionsItem_${index}`} key={payment.payment_reference} onClick={() => handleClick(payment)}>
                    <TransactionItem payment={payment} />
                  </tr>
                ))}
              </tbody>
            </BaseTable>
          )}
          {
            hasMorePages && !isMobile && (
              <Button
                className={classNames(styles.btnLoadMore, { [styles.hideBtnLoadMore]: !hasMorePages })}
                variant={ButtonTypesEnum.Link}
                onClick={handleButtonLoadMore}
              >
                {lang.commonLoadMore()}
              </Button>
            )
          }
          { isMobile ? (
            <>
              {showModal && <Modal handleModal={handleModalTransfer} infoModal={infoModal} />}
            </>
          ) : (
            <BaseModal isOpen={showModal}>
              {showModal && <Modal handleModal={handleModalTransfer} infoModal={infoModal} />}
            </BaseModal>
          )}
        </div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  allTransactions: state.payment.paymentsAll.data,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Transactions);
