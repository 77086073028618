import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { CountryData } from '../models/enroll';

const CountriesCache = entity<CountryData[]>([], entityReduxLogger('Countries', 'countries'));

export function updateCountriesCache(newItems: CountryData[]) {
  CountriesCache.set(newItems);
}

export default {
  get: CountriesCache.get,
  use: CountriesCache.use,
};
