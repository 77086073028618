import { isFunction } from 'lodash';
import { StoreEnhancer } from 'redux';

import configLib from '../configLib';

declare global {
  interface Console {
    tron: any
  }
}

/* eslint-disable import/no-extraneous-dependencies  */
const { Reactotron } = configLib;
const reactotronApisauce = require('reactotron-apisauce');
const { trackGlobalErrors } = require('reactotron-react-js');
const { reactotronRedux } = require('reactotron-redux');
/* eslint-enable import/no-extraneous-dependencies  */

const reactotron = Reactotron
  .configure({ name: 'Web App', secure: false })
  .use(trackGlobalErrors({ offline: false }))
  .use(reactotronApisauce())
  .use(reactotronRedux())
  .connect();

let enhancers: StoreEnhancer[] | undefined;
const hasEnhancerFunction = isFunction(reactotron?.createEnhancer);
if (hasEnhancerFunction) {
  enhancers = [reactotron.createEnhancer()];
}

console.tron = reactotron; // eslint-disable-line no-console

console.info(`[ReactotronConfig] Reactotron ${hasEnhancerFunction ? 'enabled' : 'partially enabled'}`); // eslint-disable-line no-console

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  reactotron,
  enhancers,
};
