import React from 'react';

import lang from '../../lib/language';

import { useIsMobile } from '../../hooks';

import PopularGroupItems from './PopularGroupItems/PopularGroupItems';

import styles from './PopularGroups.module.scss';

const PopularGroups = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <h6 className={isMobile ? styles.titleMobile : styles.title}>
        {lang.commonDiscoverSubtitlePopularGroups()}
      </h6>
      {isMobile ? (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <PopularGroupItems />
          </div>
        </div>
      ) : (
        <PopularGroupItems />
      )}
    </>
  );
};

export default PopularGroups;
