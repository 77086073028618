import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ISlideToggleProps } from './SlideToggle.types';

import styles from './SlideToggleControl.module.scss';

const useClasses = makeStyles({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: '#916FDD',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#916FDD',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 20 / 2,
    backgroundColor: '#D0D1DC',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
});

const SlideToggleControl = (props: ISlideToggleProps): JSX.Element => {
  const {
    name,
    label,
    value,
    className,
    onToggleChange,
    disabled = false,
    inlineLabel = false,
  } = props;

  const [checked, setChecked] = useState(value);
  const [isControlDirty, setIsControlDirty] = useState<boolean>(false);

  const classes = useClasses();

  useEffect(() => {
    if (!isControlDirty) {
      setChecked(value);
    }
  }, [isControlDirty, value]);

  return (
    <div className={[styles.wrapper, className].join(' ')}>
      {!inlineLabel && <p className={styles.textItem}>{label}</p>}
      <div className={styles.switchItemsWrapper}>
        <FormControlLabel
          control={(
            <Switch
              focusVisibleClassName="test"
              checked={checked}
              disabled={disabled}
              name={name}
              inputProps={{ id: 'switcher', 'aria-label': `switch for ${label}` }}
              onChange={(event: ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
                setChecked(checkedValue);
                setIsControlDirty(true);
                onToggleChange && onToggleChange(checkedValue);
              }}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          )}
          label={inlineLabel && label}
        />

      </div>
    </div>
  );
};

export default SlideToggleControl;
