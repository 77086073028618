export const legalDeclarationsAriaLabels = [
  'Client Agreement',
  'General Terms and Conditions',
  'Order Execution Policy',
  'Risk Warning Disclosure and Notice',
  'Conflict of Interest Summary',
  'Client Categorization Notice',
  'Complaints Handling Policy',
  'Tariff',
  'Privacy Policy',
];

export const legalDeclarationIds = [
  'clientAgreement',
  'generalTerms',
  'orderExecutionPolicy',
  'riskWarningDisclosure',
  'conflictOfInterest',
  'clientCategorization',
  'complaintsHandlingPolicy',
  'Tariff',
  'privacyPolicy',
];
