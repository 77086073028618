import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CallStatus, PredefinedMilisecondsTypeEnum } from '../../lib/enums';
import { PROCESS_CANCEL_MODAL_DELAY_MS, PROCESS_FINISHED_MODAL_DELAY_MS } from '../../lib/libSettings';

import filledOrderLogo from '../../assets/img/greenCheckmark.svg';
import processOrderLogo from '../../assets/img/icon_order_processed.svg';
import rejectedOrderLogo from '../../assets/img/icon_order_rejected.svg';

import styles from './FinalPopupOrderMobile.module.scss';

type Props = {
  processFailedMessage: string;
  processSuccessMessageTitle: string;
  toggleModal: (arg?: any) => void;
  logo?: string;
  callStatus?: CallStatus;
  isCancelOrder?: boolean;
  processSuccessMessageBody?: string;
  processPendingMessage?: { title: string, body: string };
  defaultMessageTitle?: string;
  defaultMessageBody?: string;
  processPartiallyRejectedMessage?: { title: string, body: string };
};

const ProcessFinishedModal = (
  {
    callStatus,
    toggleModal,
    logo,
    processFailedMessage,
    processSuccessMessageBody,
    processSuccessMessageTitle,
    isCancelOrder = false,
    processPendingMessage,
    processPartiallyRejectedMessage,
    defaultMessageTitle,
    defaultMessageBody,
  }: Props,
): JSX.Element => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const errorMessageRender = () => {
    if (processPartiallyRejectedMessage) {
      return (
        <>
          <h6>{processPartiallyRejectedMessage.title}</h6>
          <p className={styles.message}>{processPartiallyRejectedMessage.body}</p>
        </>
      );
    }

    return (<h6>{processFailedMessage}</h6>);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOpened(true);
    }, PredefinedMilisecondsTypeEnum.oneSecond);

    return (() => {
      clearTimeout(timer);
    });
  }, []); // eslint-disable-line

  const getIcon = () => {
    if (callStatus === CallStatus.READY) {
      return filledOrderLogo;
    }
    if (callStatus === CallStatus.ERROR) {
      return rejectedOrderLogo;
    }
    return processOrderLogo;
  };

  const getModalBody = (): JSX.Element => {
    if (callStatus === CallStatus.READY) {
      return (
        <>
          <h6>{processSuccessMessageTitle}</h6>
          {processSuccessMessageBody && <p className={styles.message}>{processSuccessMessageBody}</p>}
        </>
      );
    }
    if (processPendingMessage) {
      return (
        <>
          <h6>{processPendingMessage.title}</h6>
          <p className={styles.message}>{processPendingMessage.body}</p>
        </>
      );
    }
    if (defaultMessageTitle) {
      return (
        <>
          <h6>{defaultMessageTitle}</h6>
          {defaultMessageBody && <p className={styles.message}>{defaultMessageBody}</p>}
        </>
      );
    }

    return (
      <>
        {errorMessageRender()}
      </>
    );
  };

  return (
    <div className={classNames(styles.popupWrapper, { [styles.cancelOrderWrapper]: isCancelOrder })}>
      {modalOpened && (
      <div className={styles.container}>
        <div className={styles.logoAndNameWrapper}>
          { logo && (
          <div className={styles.logoWrapper}>
            <div className={styles.logoInner}>
              <img src={`data:image/png;base64,${logo}`} className={styles.logo} alt="logo" />
              <img src={getIcon()} className={styles.checkmark} alt="checkmark" />
            </div>
          </div>
          )}
          <div className={styles.textWrapper}>
            {getModalBody()}
          </div>
        </div>
        <button className={styles.closeBtn} type="button" onClick={() => toggleModal(false)}>
          Okay
        </button>
      </div>
      )}
    </div>
  );
};

export default ProcessFinishedModal;
