import React from 'react';
import ExclamationMark from 'assets/img/exclamation-mark.svg';

import styles from './Error.module.scss';

type Props = {
  errorMessage: string;
  hiddenIcon?: boolean;
}

const Error = ({ errorMessage, hiddenIcon }: Props) => (
  <div className={styles.wrapper}>
    {!hiddenIcon && <img src={ExclamationMark} alt="exclamation mark" className={styles.image} />}

    <p className={styles.error}>{errorMessage}</p>
  </div>
);

export default Error;
