import { MarketStateInstrumentSnapshot } from '../models/market-data/types';
import { DEBUG_MARKET_STATE } from '../store-util/MarketStateCache';
import { NullableNumber } from '../util/types';

const {
  IS_PreMarket,
  IS_RegularHours,
  IS_AfterMarket,
  IS_MarketClosed,
  IS_MarketClosedNextDay,
} = MarketStateInstrumentSnapshot;
const MARKET_STATES = [
  IS_PreMarket,
  IS_RegularHours,
  IS_AfterMarket,
  IS_MarketClosed,
  IS_MarketClosedNextDay,
];
let currentMarketState: NullableNumber = null;

export const toggleDebugSettingsOption = (option: 'market-state', customData?: any) => {
  switch (option) {
    case 'market-state':
      if (currentMarketState != null) {
        currentMarketState!++;
        if (currentMarketState > MARKET_STATES.length - 1) currentMarketState = 0;
        DEBUG_MARKET_STATE.enable(MARKET_STATES[currentMarketState]);
      } else {
        currentMarketState = 0;
        DEBUG_MARKET_STATE.enable(MARKET_STATES[currentMarketState]);
      }
      break;

    default:
      break;
  }
};

export const USE_NORMALIZED_ENTITY_STATE = true;
