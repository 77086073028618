import React, { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { DividendPayerData, HighGrowthCompanyData } from '../../../lib/models/fundamental/types';
import { roundPercentValues } from '../../../lib/util/DataHelpers';

import { useIsMobile } from '../../../hooks';
import { CHART_HEIGHT, CHART_WIDTH, DATUM_SELECTOR, xAxisStyle } from '../DividendPyersAndHighGrowthCompanies.constants';

type Props = {
  data: HighGrowthCompanyData[] | DividendPayerData[];
  yAxis: string;
  currentItemId: string;
  isHighGrowthCompany: boolean
  onItemChange: (id: number) => void
}

const Chart = ({ data, yAxis, isHighGrowthCompany, onItemChange, currentItemId }: Props) => {
  const isMobile = useIsMobile();
  const [selectedSymbol, setSelectedSymbol] = useState(currentItemId);
  return (
    <svg
      height={CHART_HEIGHT}
      width="100%"
      preserveAspectRatio="none"
      viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}
    >
      <VictoryChart
        standalone={false}
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        padding={{
          top: 23,
          bottom: 28,
          right: 20,
          left: 20,
        }}
      >
        <VictoryAxis
          style={xAxisStyle}
        />
        <VictoryBar
          data={data}
          x="symbol"
          y={yAxis}
          alignment="middle"
          barWidth={isMobile ? 40 : 18}
          cornerRadius={4}
          animate={{
            duration: 500,
            onLoad: { duration: 1000 },
          }}
          labels={({ datum }) => `${
            isHighGrowthCompany ? roundPercentValues(datum.growthIndicator) : Math.round(datum.dividendYield * 100)
          }%`}
          style={{
            labels: { fontSize: 12, fill: '#FFFFFF' },
            data: {
              cursor: 'pointer',
              fill: ({ datum }) => (selectedSymbol === datum.symbol ? '#3BB0FF' : '#1D3960'),
            },
          }}
          events={[{
            target: 'data',
            eventHandlers: {
              onClick: () => [{
                target: 'data',
                mutation: (props: any) => {
                  if (props[DATUM_SELECTOR]) {
                    const { symbol } = props[DATUM_SELECTOR];
                    onItemChange(symbol);
                    setSelectedSymbol(symbol);
                  }
                },
              }],
            },
          }]}
        />
      </VictoryChart>
    </svg>
  );
};

export default Chart;
