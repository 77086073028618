/**
 * This slice is dedicated to News API data & consumed through Gateway
 * (It is possible to consume it directly for debugging purposes for example)
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGE_SIZE, News, NewsData } from '../../models/news';
import { GetNewsParams } from '../types';

import { checkImageURLs } from './helpers';

export interface NewsState {
  isLoading: boolean,
  latestNews: News[];
  allLoaded: boolean;
  newsForSymbols: {[symbol: string]: News[]};
  allLoadedForSymbols: {[symbol: string]: boolean};
  queuedCalls: GetNewsParams[];
  error: any;
}

export const INITIAL_STATE: NewsState = {
  isLoading: false,
  latestNews: [],
  allLoaded: false,
  newsForSymbols: {},
  allLoadedForSymbols: {},
  queuedCalls: [],
  error: {},
};

export interface NewsForSymbolData {
    symbol: string,
    latestNews: News[],
}

const newsSlice = createSlice({
  name: 'news',
  initialState: INITIAL_STATE,
  reducers: {
    getLatestNews(state, action: PayloadAction<GetNewsParams>) {
      if (action.payload?.page === 1) state.latestNews = [];
      state.isLoading = true;
    },
    getNewsForSymbol(state, action: PayloadAction<GetNewsParams>) {
      const { page, pageSize = DEFAULT_PAGE_SIZE, symbol } = action.payload;
      let newsForSymbol = state.newsForSymbols[symbol!];

      if (newsForSymbol != null && page === 1) {
        state.newsForSymbols[symbol!] = [];
      }

      if (newsForSymbol == null) newsForSymbol = [];

      state.isLoading = true;
    },
    latestNewsLoadCompleted(state, action: PayloadAction<NewsData>) {
      const { totalPageCount, pageNumber, data } = action.payload;
      state.latestNews = state.latestNews.concat(data);
      state.latestNews = checkImageURLs(state.latestNews);

      state.allLoaded = (totalPageCount === pageNumber);
      state.isLoading = false;
    },
    latestNewsLoadFailed(state, action) {
      state.error = action.payload;
      state.allLoaded = true;
      state.queuedCalls = [];
      state.isLoading = false;
    },
    newsForSymbolLoadCompleted(state, action: PayloadAction<NewsData>) {
      const {
        customData: symbol, data, totalPageCount, pageNumber,
      } = action.payload;
      const currentItems = state.newsForSymbols[symbol];
      const newItems = data || action.payload || [];

      if (currentItems != null) {
        state.newsForSymbols[symbol] = (currentItems).concat(newItems);
      } else {
        state.newsForSymbols[symbol] = newItems;
      }

      state.newsForSymbols[symbol] = checkImageURLs(state.newsForSymbols[symbol]);
      state.allLoadedForSymbols[symbol] = (totalPageCount === pageNumber);
      state.isLoading = false;
    },
    newsForSymbolLoadFailed(state, action) {
      const { customData: symbol, status, resultCode } = action.payload;
      if (status === 404 || resultCode === 404) {
        state.allLoadedForSymbols[symbol] = true;
      } else {
        state.newsForSymbols[action.payload] = [];
        state.error = action.payload;
        delete state.allLoadedForSymbols[symbol];
      }
      state.queuedCalls = [];
      state.isLoading = false;
    },
    addCallToQueue(state, action: PayloadAction<GetNewsParams>) {
      state.queuedCalls.push(action.payload);
    },
    removeCallFromQueue(state) {
      state.queuedCalls.shift();
    },
    resetIsLoading(state) {
      state.isLoading = false;
    },
  },
});

export const {
  getLatestNews,
  latestNewsLoadFailed,
  latestNewsLoadCompleted,
  getNewsForSymbol,
  newsForSymbolLoadFailed,
  newsForSymbolLoadCompleted,
  addCallToQueue,
  removeCallFromQueue,
  resetIsLoading,
} = newsSlice.actions;

export default newsSlice.reducer;
