import React from 'react';

import lang from '../../lib/language';

import discoverLogo from '../../assets/img/icon_discover.svg';
import helpLogo from '../../assets/img/icon-help_24px.svg';
import myAccountLogo from '../../assets/img/icon-myaccount_24px.svg';
import watchlistLogo from '../../assets/img/icon-watchlist.svg';
import ordersTradersLogo from '../../assets/img/orders-trades-logo.svg';
import paymentLogo from '../../assets/img/payment-logo.svg';
import { UI_ROUTES } from '../../util/routes';

import { SideBarItem } from './Sidebar.types';

export const MENU_LIST: SideBarItem[] = [
  { id: 'myAccountMenuItem', label: lang.commonMyAccount(), path: '/my-account', img: <img src={myAccountLogo} alt="icon my account" /> },
  { id: 'watchlistMenuItem', label: lang.commonWatchlist(), path: '/overview', img: <img src={watchlistLogo} alt="icon watchlist" /> },
  { id: 'discoverMenuItem', label: lang.commonDiscoverTitle(), path: '/discover', img: <img src={discoverLogo} alt="icon discover" /> },
  {
    id: 'fundsMenuItem',
    label: lang.fundsScreenTitle(),
    path: UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit,
    img: <img src={paymentLogo} alt="icon payment" />,
  },
  {
    id: 'historyMenuItem',
    label: lang.commonTitleHistory(),
    path: '/trade-history',
    img: <img src={ordersTradersLogo} alt="icon up and down arrows" /> },
];

export const SUPPORT_LIST: SideBarItem[] = [
  {
    id: 'helpCenterMenuItem',
    label: lang.commonProfileMySupportCenter(),
    path: '',
    img: <img src={helpLogo} alt="icon help Logo" />,
  },
];
