import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import lang from '../../../lib/language';

import TradeViewBtnIcon from '../../../assets/img/trade_chart_btn.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import { UI_ROUTES } from '../../../util/routes';
import ChartPopover from '../Popover/ChartPopover';

import styles from './TradeViewChartButton.module.scss';

const popoverId = 'trade-chart';

type Props = {
    symbol: string;
}

const TradeViewChartButton = ({ symbol }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [popoverStatus, setPopoverStatus] = useState<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();
  const history = useHistory();

  const handleMouseEnter = (event) => {
    event.preventDefault();
    setPopoverStatus(event.currentTarget);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setPopoverStatus(null);
    setIsHovered(false);
  };

  return (
    <div className={styles.infoPopover}>
      <div
        className={classNames(isMobile ? styles.containerMobile : styles.container, { [styles.hovered]: isHovered })}
        onMouseEnter={!isMobile ? handleMouseEnter : undefined}
        onMouseLeave={!isMobile ? handleMouseLeave : undefined}
      >
        {isMobile && (
          <a
            href={`${UI_ROUTES.tradingView}/${symbol}`}
            target={'_self'}
            rel="noreferrer"
          >
            {lang.tradingViewChartLabel()}
          </a>
        )}
        <ChartPopover
          open={Boolean(popoverStatus)}
          popoverStatus={popoverStatus}
          handlePopoverClose={() => setPopoverStatus(null)}
          label={lang.tradingViewChartPopupMessage()}
        />
        {!isMobile && (
        <div className={styles.buttonWrapper}>
          <img src={TradeViewBtnIcon} alt="Tradeview Chart" />
          <a
            className={styles.buttonHref}
            rel="noreferrer"
            href={`${UI_ROUTES.tradingView}/${symbol}`}
            target={'_blank'}
          >
            {'-'}
          </a>
        </div>
        )}
      </div>
    </div>
  );
};

export default TradeViewChartButton;
