import React, { useCallback, useEffect, useState } from 'react';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';

import lang from '../../../lib/language';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { manageSubscriptions } from '../../../lib/util/TradingHelpers';
import { NullableNumber } from '../../../lib/util/types';

import InfoIcon from '../../../assets/img/icon-info-outline.svg';
import { ClickableImage } from '../../index';

import styles from '../styles.module.scss';

const popoverId = 'market-price-popover';

type Props = {
    symbol: string;
    tradePrice: number | null;
    bidPrice: NullableNumber;
    askPrice: NullableNumber;
    isMarketOpen: boolean;
    isMyAccount?: boolean;
    isSymbolDetailsBigScreen?: boolean;
    isUserRestricted?: boolean;
}

const MarketPriceInfo = ({
  askPrice,
  bidPrice,
  isMarketOpen,
  isMyAccount,
  isSymbolDetailsBigScreen,
  isUserRestricted,
  tradePrice,
  symbol,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isMyAccount) {
      manageSubscriptions('create-order', symbol);
    }
  }, [isMyAccount, symbol]);

  const handlePopoverClick = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const handlePopoverClose = useCallback(() => setAnchorEl(null), []);

  return (
    <div className={classNames(styles.wrapperMarketPrice, { [styles.marketDisabled]: isUserRestricted })}>
      <div className={styles.wrapperTextAndPopover}>
        {lang.headerMyAccountMyStockMarketPrice()}
        {isMarketOpen && !isUserRestricted && (
        <div className={styles.infoPopover}>
          <ClickableImage
            src={InfoIcon}
            onClick={handlePopoverClick}
            ariadescribedby={popoverId}
            variant="contained"
          />
          <Popover
            id={popoverId}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            classes={{ paper: styles.popoverPaper }}
          >
            <p className={styles.marketInfoHeader}>
              {lang.tradingMarketLastTradePrice(formatCurrency(tradePrice))}
            </p>
            <p>{lang.webTradingCurrentlyAskPrice(` ${formatCurrency(askPrice)}`)}</p>
            <p className={styles.marketInfoBidPrice}>
              {lang.webTradingCurrentlyBidPrice(` ${formatCurrency(bidPrice)}`)}
            </p>
            <p className={styles.marketInfoHeader}>
              {isSymbolDetailsBigScreen
                ? lang.webTradingAskAndBidPricesGeneralInfo()
                : lang.webTradingDisclaimerPopover()}
            </p>
          </Popover>
        </div>
        )}
      </div>
      <div className={styles.rightAligned}>{formatCurrency(tradePrice)}</div>
    </div>
  );
};
export default MarketPriceInfo;
