import React from 'react';
import classNames from 'classnames';

import lang from '../../lib/language';

import PackageInfo from '../../../package.json';
import commitSha from '../../commit-sha';
import config from '../../config';
import { useIsMobile } from '../../hooks';
import { startMockedMode } from '../../util/helpers';
import ClickableDiv from '../ClickableDiv/ClickableDiv';

import styles from './AppVersion.module.scss';

const AppVersion = () => {
  const isMobile = useIsMobile();

  return (
    <ClickableDiv
      className={
                classNames(
                  styles.wrapperAppVersion,
                  isMobile ? styles.wrapperAppVersionMob : styles.wrapperAppVersionWeb,
                )
            }
      onClick={startMockedMode}
    >
      <p className={styles.appVersionText}>
        {`${lang.mobileProfileVersion()} ${PackageInfo.version} (${config.currentEnv})`}
      </p>
      <p className={styles.appVersionBuildText}>
        {`${lang.mobileProfileBuild()} ${commitSha}`}
      </p>
    </ClickableDiv>
  );
};

export default AppVersion;
