import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import {
  delay,
  filter, mergeMap,
} from 'rxjs/operators';

import { logConfig, MOCK } from '../../../configDebug';
import configLib from '../../../configLib';
import { PredefinedMilisecondsTypeEnum } from '../../enums';
import { DISCONNECT_ON_APP_MINIMISE_OR_LOCK, RECONNECT_TAPI_AND_GW_ON_GW_DOWN, SHOW_UI_AFTER_CONNECTED_DELAY } from '../../libSettings';
import { ReportingCall } from '../../models/reporting/types';
import { CacheService } from '../../services/cache/Cache.service';
import GlobalService from '../../services/Global.service';
import AppStateCache, { AppStateStatus } from '../../store-util/AppStateCache';
import InitialSymbolsDataLoadingStatusCache from '../../store-util/InitialSymbolsDataLoadingStatusCache';
import LoaderState from '../../store-util/LoaderState';
import TopGainersCache from '../../store-util/top-movers/TopGainersCache';
import TopLosersCache from '../../store-util/top-movers/TopLosersCache';
import { extractStatusNamesFromStatusByCall, formatCallStatus } from '../../util/error-handling/StatusByCallHelpers';
import { extractSymbolsFromListAsString } from '../../util/MarketDataHelpers';
import { loginSucceeded } from '../auth';
import {
  accountsFailed,
  applicationStartup,
  authRefreshCompleted,
  autoLoginSuccess,
  gatewayConnected,
  gatewayDisconnected,
  getAccountsAvailableCashFailed,
  getOwnerTradingAccountsFailed,
  logout,
  reconnectEventAction,
  servicesReady,
  setAppStatus,
  tradingDisconnected,
} from '../common-actions';
import { accountDetailsFailed } from '../crm';
import { CRMCall } from '../crm/types';
import { getNewStocksCompleted, getNewStocksFailed, getPopularStocksCompleted, getPopularStocksFailed, getTopGainersCompleted, getTopGainersFailed, getTopLosersCompleted, getTopLosersFailed } from '../fundamental/index';
import { FundamentalCall } from '../fundamental/types';
import { confirmInitialPriceDataLoaded } from '../market-data';
import { confirmInitialPaymentsLoaded, getIndividualLinkedAccountsFailed, getPaymentsAllFailed } from '../payment/index';
import { getFavoriteStocks, getToken, hasValidToken } from '../selectors';
import { getPositionsCompleted, getPositionsRejected } from '../trading';
import { RootState } from '..';

import { appConnected, appLoaded, setError, setWebLoginReady } from './index';

const cacheService = CacheService.getService();

const startupEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(applicationStartup.type),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    GlobalService.createServicesWithoutToken();
    observer.next(appLoaded());
    setTimeout(() => {
      cacheService.checkAndEvictExpiredCaches();
    }, PredefinedMilisecondsTypeEnum.twentySeconds);
  })),
);

const webLoginReadyEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(setWebLoginReady.type),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    if (!checkToken(observer, state$.value)) return;

    GlobalService.connect(getToken(state$.value)!);
    observer.next(servicesReady());
  })),
);

const reconnectEventEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(reconnectEventAction.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService.connect(getToken(state$.value)!, true);
  })),
);

const reconnectOnGatewayDownEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(gatewayDisconnected.type),
  filter(() => hasValidToken(state$.value)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const appState = AppStateCache.getCurrentAppStatus();
    if (RECONNECT_TAPI_AND_GW_ON_GW_DOWN) {
      if (appState === 'active') {
        GlobalService.connect(state$.value.auth.accessToken!, true);
      }
    }
  })),
);

const appStatusChangeEpic = (
  action$: Observable<PayloadAction<AppStateStatus>>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(setAppStatus.type),
  filter(() => !configLib.isWeb), // initially setup for mobile only with ART-3968
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const isGatewayConnected = !!GlobalService.gatewayWs?.isConnected();
    const isUserLoggedIn = hasValidToken(state$.value);
    const currentAppStatus = AppStateCache.getCurrentAppStatus();
    const isPrevStateBackground = currentAppStatus === 'background';

    switch (action.payload) {
      case 'background':
        if (DISCONNECT_ON_APP_MINIMISE_OR_LOCK) {
          if (isUserLoggedIn) {
            LoaderState.setLoading(true);
          }
          GlobalService.disconnect();
        }
        break;

      case 'active':
        if (!isGatewayConnected && isUserLoggedIn && isPrevStateBackground) {
          GlobalService.connect(getToken(state$.value)!, true);
        }
        break;

      default:
        break;
    }

    // call the complete action to update Redux state after epic
    AppStateCache.setCurrentAppStatus(action.payload);
  })),
);

const refreshWebEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(appLoaded.type),
  filter(() => configLib.isWeb),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    initServices(observer, state$.value, 'refreshWebEpic');
  })),
);

const loginEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(loginSucceeded.type, authRefreshCompleted.type, autoLoginSuccess.type),
  filter(() => hasValidToken(state$.value)),
  filter(() => !state$.value.app.isServicesReady),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    initServices(observer, state$.value, 'loginEpic');
  })),
);

const pushNotificationsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  configLib.isWeb
    ? ofType(servicesReady.type)
    : ofType(loginSucceeded.type, authRefreshCompleted.type, autoLoginSuccess.type),
  filter(() => !MOCK.ENABLED),
  filter(() => hasValidToken(state$.value)),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    (configLib as any).PushNotificationService().init?.(state$.value.auth.accessToken!);
  })),
);

const checkAppConnectedEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    gatewayConnected.type,
    confirmInitialPriceDataLoaded.type,
  ),
  filter(() => state$.value.marketData.isInitialPriceDataLoaded),
  delay(SHOW_UI_AFTER_CONNECTED_DELAY),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    AppStateCache.setCurrentAppStatus('active');
    observer.next(appConnected());
  })),
);

const checkNetworkAndDataStateEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    getPopularStocksCompleted.type,
    getPopularStocksFailed.type,
    getTopGainersCompleted.type,
    getTopGainersFailed.type,
    getTopLosersCompleted.type,
    getTopLosersFailed.type,
    getNewStocksCompleted.type,
    getNewStocksFailed.type,
    getPositionsCompleted.type,
    getPositionsRejected.type,
    confirmInitialPaymentsLoaded.type,
    confirmInitialPriceDataLoaded.type,
    appConnected.type,
    gatewayDisconnected.type,
    gatewayConnected.type,
    tradingDisconnected.type,
    'debugPing',
  ),
  filter(() => !!logConfig.debugNetworkAndDataEnabled),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    const { connected: appConnectedStatus, hasError: appHasError } = state$.value.app;
    const {
      errorsLog: gatewayErrorsLog,
    } = state$.value.gateway;
    const {
      statusByCall: crmStatusByCall,
      errorsLog: crmErrorsLog,
      error: crmError,
      errorData: crmErrorData,
    } = state$.value.crm;
    const { statusByCall: fundamentalStatusByCall, errorsLog: fundamentalErrorsLog } = state$.value.fundamental;
    const {
      openPositions,
      pendingOrders,
      statusByCall: reportingStatusByCall,
      errorsLog: reportingErrorsLog,
      error: reportingError,
    } = state$.value.reporting;

    const favoriteStocks = getFavoriteStocks(state$.value);
    const { popularStocks, newStocks } = state$.value.fundamental;
    const { initialData: topGainers } = TopGainersCache.get();
    const { initialData: topLosers } = TopLosersCache.get();
    const totalSymbols = (
      favoriteStocks.length
      + popularStocks.length
      + topGainers.length
      + topLosers.length
      + newStocks.length
      + openPositions.length
    );
    const gatewayErrorsCount = gatewayErrorsLog?.length ?? 0;
    const crmErrorsCount = crmErrorsLog?.length ?? 0;
    const fundamentalErrorsCount = fundamentalErrorsLog?.length ?? 0;
    const reportingErrorsCount = reportingErrorsLog?.length ?? 0;
    const totalErrorsCount = gatewayErrorsCount + crmErrorsCount + fundamentalErrorsCount + reportingErrorsCount;
    const currentErrors = (console as any)?.currentErrors;
    const currentErrorsCount = currentErrors?.length ?? 0;
    const services = `${
      state$.value.gateway.connected ? 'GW' : 'gw'
    }:${state$.value.gateway.status}  ${
      state$.value.crm.connected ? 'CRM' : 'crm'
    }  Auth:${
      state$.value.auth.status
    }  ${
      state$.value.trading.connected ? 'T' : 't'
    }`;
    const initialSymbolsLoadingState = InitialSymbolsDataLoadingStatusCache.get();

    const payload = {
      slice: 'networkAndDataCheck',
      data: {
        services,
        statuses: (console as any)?.statusCodes ?? '<none>',
        [`currentErrors (${currentErrorsCount})`]: currentErrors ?? '<no errors>',
        [`errors (${totalErrorsCount})`]: {
          [`gateway (${gatewayErrorsCount})`]: gatewayErrorsLog.concat().reverse(),
          [`crm (${crmErrorsCount})`]: { errorsLog: crmErrorsLog.concat().reverse(), error: crmError, errorData: crmErrorData },
          [`fundamental (${fundamentalErrorsCount})`]: fundamentalErrorsLog.concat().reverse(),
          [`reporting (${reportingErrorsCount})`]: { errorsLog: reportingErrorsLog.concat().reverse(), error: reportingError },
          appHasError,
        },
        'crm-extended-info': formatCallStatus(crmStatusByCall[CRMCall.getIndividualExtendedInfo]),
        'crm-accounts': formatCallStatus(crmStatusByCall[CRMCall.getAccounts]),
        popular: formatCallStatus(fundamentalStatusByCall[FundamentalCall.getPopularStocks]),
        topGainers: formatCallStatus(fundamentalStatusByCall[FundamentalCall.getTopGainers]),
        topLosers: formatCallStatus(fundamentalStatusByCall[FundamentalCall.getTopLosers]),
        newStocks: formatCallStatus(fundamentalStatusByCall[FundamentalCall.getNewStocks]),
        positions: formatCallStatus(reportingStatusByCall[ReportingCall.getOpenPositions]),
        'orders-all': formatCallStatus(reportingStatusByCall[ReportingCall.getAccountTradeHistory]),
        'orders-pending': formatCallStatus(reportingStatusByCall[ReportingCall.getAccountPendingOrders]),
        initialPaymentsLoaded: state$.value.payment.isInitialPaymentsDataLoaded,
        initialPricesLoaded: state$.value.marketData.isInitialPriceDataLoaded,
        appConnected: appConnectedStatus,
        initialSymbolsLoadingState,
        slicePaymentStatusByCall: extractStatusNamesFromStatusByCall(state$.value.payment.statusByCall),
        sliceFundamentalStatusByCall: extractStatusNamesFromStatusByCall(fundamentalStatusByCall),
        sliceReportingStatusByCall: extractStatusNamesFromStatusByCall(reportingStatusByCall),
        sliceCRMStatusByCall: extractStatusNamesFromStatusByCall(crmStatusByCall),
        cacheInitialStatus: InitialSymbolsDataLoadingStatusCache.get(),
        [`symbols(${totalSymbols})`]: {
          popular: extractSymbolsFromListAsString(popularStocks, true),
          favorites: extractSymbolsFromListAsString(favoriteStocks, true),
          discoverTopGainers: extractSymbolsFromListAsString(topGainers, true),
          discoverTopLosers: extractSymbolsFromListAsString(topLosers, true),
          discoverNewOnTheMarket: extractSymbolsFromListAsString(newStocks, true),
          myAccountPositions: extractSymbolsFromListAsString(openPositions, true),
          'myAccountOrders (not included in total)': extractSymbolsFromListAsString(pendingOrders, true),
        },
      },
    };

    observer.next({ type: 'debug', payload });
  })),
);


const accountsFailedEpic = (action$: Observable<any>) => action$.pipe(
  ofType(
    accountsFailed.type,
    accountDetailsFailed.type,
    getOwnerTradingAccountsFailed.type,
    getPaymentsAllFailed.type,
    getAccountsAvailableCashFailed.type,
    getIndividualLinkedAccountsFailed.type,
  ),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    observer.next(setError(true));
    observer.next(logout());
  })),
);

const appLogoutEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(logout.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService.disconnect();
    cacheService.checkAndEvictAllCaches();
    observer.next(applicationStartup());
  })),
);

function checkToken(observer: Observer<any>, state: RootState | null, token?: string) {
  const isValid = hasValidToken(state, token);
  if (!isValid && !!state?.auth.accessToken) {
    // logout if expired
    observer.next(logout());
  }

  return isValid;
}

function initServices(observer: Observer<any>, state: RootState, epicName: string) {
  if (!configLib?.store) {
    console.error(`[app/epics] ${epicName} - store not available`);
    return;
  }
  if (!checkToken(observer, state)) return;

  GlobalService.createServices(getToken(state)!, configLib.store.dispatch);
  if (!configLib?.isWeb) {
    GlobalService.connect(getToken(state)!);
    observer.next(servicesReady());
  }
}
const epics = [
  startupEpic,
  webLoginReadyEpic,
  reconnectEventEpic,
  reconnectOnGatewayDownEpic,
  refreshWebEpic,
  loginEpic,
  pushNotificationsEpic,
  checkAppConnectedEpic,
  accountsFailedEpic,
  appStatusChangeEpic,
  appLogoutEpic,
];
if (configLib.__DEV__) epics.push(checkNetworkAndDataStateEpic);

export default combineEpics(...epics);
