import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import lang from '../../../lib/language';

import { useIsMobile } from '../../../hooks';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';
import NoResultsFound from '../../NoResultsFound/NoResultsFound';
import Preloader from '../../Preloader/Preloader';

import styles from './LatestNewsContent.module.scss';

type Props = {
  newsCards: JSX.Element[];
  isLoggedIn: boolean;
  gatewayConnected: boolean;
  isLoading: boolean;
  gwEnabled: boolean;
  gwNewsForSymbolEnabled: boolean;
  gwLatestNewsEnabled: boolean;
  isMounted: boolean;
  isNewsAvailable: (selectedSymbol?: string, hasMore?: boolean) => boolean;
  getLatestNews: (page?: number, pageSize?: number) => void;
  getNewsForSymbol: (symbol: string, page?: number, pageSize?: number) => void;
  selectedSymbol?: string;
};

const LatestNewsContent = (
  {
    selectedSymbol,
    gwEnabled,
    gwNewsForSymbolEnabled,
    isLoggedIn,
    newsCards,
    gatewayConnected,
    gwLatestNewsEnabled,
    isMounted,
    isLoading,
    isNewsAvailable,
    getNewsForSymbol,
    getLatestNews,
  }: Props,
): JSX.Element => {
  const isMobile = useIsMobile();
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  const gatewayNewsEnabled = gwEnabled && (selectedSymbol ? gwNewsForSymbolEnabled : gwLatestNewsEnabled);
  const isGatewayDown = gatewayNewsEnabled && !gatewayConnected;

  const preloaderStyles: CSSProperties = { transform: 'scale(0.5)' };

  const loadMore = useCallback(() => {
    const page = !isInitialLoadDone ? 1 : undefined;

    if (selectedSymbol) {
      getNewsForSymbol(selectedSymbol, page);
    } else {
      getLatestNews(page);
    }
  }, [selectedSymbol, isInitialLoadDone, getNewsForSymbol, getLatestNews]);

  useEffect(() => {
    setIsInitialLoadDone(false);
  }, [selectedSymbol]);

  useEffect(() => {
    if (!isInitialLoadDone && isMounted && isLoggedIn) {
      loadMore();
      setIsInitialLoadDone(true);
    }
  }, [isMounted, loadMore, isInitialLoadDone, isLoggedIn]);

  if (!isLoggedIn || !isMounted) {
    return <Preloader style={preloaderStyles} isTextOnly owner="LatestNews-2" />;
  }

  if (!isNewsAvailable(selectedSymbol)) {
    return (
      !isGatewayDown ? <NoResultsFound label="News" />
        : <NoResultsFound fullText="We are currently experiencing minor technical difficulties. Please try again later." />
    );
  }

  const hasMoreNews = isNewsAvailable(selectedSymbol, true);

  return (
    <div className={isMobile ? styles.wrapperLatestNewsMobile : styles.wrapperLatestNews}>
      { newsCards }
      {isLoading && (
      <div className={styles.wrapperPreloader}>
        <Preloader style={preloaderStyles} owner="LatestNews" />
      </div>
      )}
      {/* {!isLoading && hasMoreNews && (
        <div className={styles.wrapperLoadMore}>
          <Button
            fullWidth
            variant={ButtonTypesEnum.Tertiary}
            onClick={loadMore}
          >
            {lang.commonLoadMore()}
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default LatestNewsContent;
