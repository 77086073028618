import React from 'react';

import lang from '../../lib/language';

import BaseModal from '../BaseModal/BaseModal';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button } from '../index';

import styles from './HighLowLimitPriceAlert.module.scss';

type Props = {
  isBuyOrder: boolean;
  toggleModal: () => void;
  processModal: () => void;
}

const HighLowLimitPriceAlert = ({ toggleModal, processModal, isBuyOrder }: Props) => (
  <BaseModal isOpen>
    <h6 className={styles.title}>
      {lang.webTradingHighLowLimitPriceAlert(
        isBuyOrder ? lang.webTradingHighLowLimitPriceAlertHigh() : lang.webTradingHighLowLimitPriceAlertLow(),
      )}
    </h6>
    <div className={styles.content}>
      <p>
        {lang.webTradingHighLowLimitPriceAlertDescription(
          isBuyOrder ? lang.webTradingHighLowLimitPriceAlertHigher() : lang.webTradingHighLowLimitPriceAlertLower(),
        )}
      </p>
    </div>
    <div className={styles.actions}>
      <Button
        variant={ButtonTypesEnum.Tertiary}
        fullWidth
        className={styles.button}
        onClick={processModal}
      >
        {lang.commonContinueButton()}
      </Button>
      <Button fullWidth onClick={toggleModal}>
        {lang.webTradingEditPriceButton()}
      </Button>
    </div>
  </BaseModal>
);

export default HighLowLimitPriceAlert;
