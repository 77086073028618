import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import lang from '../../lib/language';
import { StockDescriptor } from '../../lib/models/stock-descriptor';
import { processSymbolSubscribesAction, processSymbolUnSubscribesAction } from '../../lib/store/common-actions';
import { DEFAULT_SEARCH_STOCK_LIMIT, SEARCH_STOCK_MAX_LIMIT } from '../../lib/store/fundamental/constants';
import { getCompanyLogoAndName } from '../../lib/store/fundamental/index';
import { FundamentalCall } from '../../lib/store/fundamental/types';
import { isSymbolInFavourites } from '../../lib/store/helpers';
import { RootState } from '../../lib/store/index';
import { SymbolLite } from '../../lib/store/market-data/types';
import { getFavoriteStocks } from '../../lib/store/selectors';
import { FAVOURITE_SYMBOLS_MAX_LIMIT } from '../../lib/util/constants';
import { isCallStatusError, isCallStatusPending, isCallStatusReady } from '../../lib/util/error-handling/StatusByCallHelpers';

import ErrorIcon from '../../assets/img/CrashIcon.png';
import SearchIcon from '../../assets/img/search.png';
import { ButtonTypesEnum } from '../Button/Button.types';
import FavouriteSymbolsLimitModal from '../FavouriteSymbolsLimitModal/FavouriteSymbolsLimitModal';
import { Button, Preloader } from '../index';
import ResultRowMobile from '../SearchSymbolsMob/Search/ResultRowMobile';

import FavouritesModal from './componets/FavouritesModal';
import StockRow from './componets/StockRow';

import styles from './StocksResult.module.scss';

type Props = {
  noSymbolsFoundMessage: string;
  currentPageNumber: number;
  handleLoadMore: () => void;
  closeModal?: () => void
  isMob?: boolean;
}

const StocksResult = ({ noSymbolsFoundMessage, currentPageNumber, isMob, closeModal, handleLoadMore }: Props) => {
  const stocks = useSelector<RootState, StockDescriptor[]>((state: RootState) => state.fundamental.stockResults);
  const query = useSelector<RootState, string>((state: RootState) => state.fundamental.stockQuery);
  const isSearching = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusPending(FundamentalCall.searchStocks, state.fundamental.statusByCall)
  ));
  const isSearchingCompleted = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusReady(FundamentalCall.searchStocks, state.fundamental.statusByCall)
  ));
  const isSearchingCrashed = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusError(FundamentalCall.searchStocks, state.fundamental.statusByCall)
  ));
  const userCorrelationId = useSelector<RootState, number | undefined>(
    (state: RootState) => state.crm.individualExtendedInfo?.user_correlation_id,
  );
  const favourites = useSelector<RootState, SymbolLite[]>((state: RootState) => getFavoriteStocks(state));
  const companyLogoAndNameData = useSelector((state: RootState) => state.fundamental.companyLogoAndName);

  const [isAddedToFavourites, setIsAddedToFavourites] = useState<boolean>(false);
  const [isRemovedFromFavourites, setIsRemovedFromFavourites] = useState<boolean>(false);
  const [logosLoaded, setLogosLoaded] = useState<boolean>(false);
  const [selectedSymbol, setSelectedSymbol] = useState<string>('');
  const [showFavouritesLimitMessage, setShowFavouritesLimitMessage] = useState<boolean>(false);

  const dispatch = useDispatch();

  const filteredResults = stocks.filter((stock) => {
    const { symbol, companyName } = stock;
    return (
      symbol && symbol.startsWith(query.toUpperCase()))
        || (companyName && companyName.toUpperCase().startsWith(query.toUpperCase()));
  });

  useEffect(() => {
    if (filteredResults.length && isSearchingCompleted) {
      const symbols = filteredResults.map(
        stock => stock.symbol,
      ).slice((currentPageNumber - 1) * DEFAULT_SEARCH_STOCK_LIMIT);
      dispatch(getCompanyLogoAndName(symbols.join()));
    }
  }, [dispatch, isSearchingCompleted, filteredResults, currentPageNumber]);

  const handleEndReached = (e) => {
    e.stopPropagation();
    if (isSearching) {
      return;
    }
    handleLoadMore();
  };

  const onAddToFavourites = useCallback((symbol) => {
    setSelectedSymbol(symbol);
    if (isSymbolInFavourites(favourites, symbol)) {
      dispatch(processSymbolUnSubscribesAction({
        symbolOrSymbols: symbol,
        isRemovingFromFavorites: true,
        group: 'watchlist',
        caller: 'SymbolDetailsHeader',
        userCorrelationId,
      }));
      setIsRemovedFromFavourites(true);
      setIsAddedToFavourites(false);
      setShowFavouritesLimitMessage(false);
    } else if (favourites.length >= FAVOURITE_SYMBOLS_MAX_LIMIT) {
      setShowFavouritesLimitMessage(true);
      setIsRemovedFromFavourites(false);
      setIsAddedToFavourites(false);
    } else {
      dispatch(processSymbolSubscribesAction({
        symbolOrSymbols: symbol,
        isFavorites: true,
        group: 'watchlist',
        caller: 'SymbolDetailsHeader',
        userCorrelationId,
      }));
      setShowFavouritesLimitMessage(false);
      setIsRemovedFromFavourites(false);
      setIsAddedToFavourites(true);
    }
  }, [dispatch, favourites, userCorrelationId]);

  const closeFavouritesModal = () => {
    setIsAddedToFavourites(false);
    setIsRemovedFromFavourites(false);
  };

  useEffect(() => {
    if (isSearchingCompleted) {
      setLogosLoaded(
        !!filteredResults.length
        && filteredResults.every(el => Object.keys(companyLogoAndNameData).includes(el.symbol)),
      );
    } else {
      setLogosLoaded(false);
    }
  }, [companyLogoAndNameData, filteredResults, isSearchingCompleted]);

  const renderStockResults = () => {
    switch (true) {
      case query === '':
        return (
          <div className={classNames(styles.iconWrapper, { [styles.searchIconMob]: isMob })}>
            <img src={SearchIcon} alt="lookig for symbol" />
            <p className={styles.text}>
              {lang.mobileStockSearchDescription()}
            </p>
          </div>
        );
      case isSearching || (filteredResults.length && isSearchingCompleted && !logosLoaded):
        return (
          <div className={isMob ? styles.mobLoader : styles.webLoader}>
            <Preloader isFullScreen owner="StockResult" />
          </div>
        );
      case isSearchingCrashed:
        return (
          <div className={classNames(styles.iconWrapper, { [styles.searchIconMob]: isMob })}>
            <img src={ErrorIcon} alt="error searching symbol" />
            <p className={styles.text}>{lang.requestErrorOver500()}</p>
          </div>
        );
      case !filteredResults.length && isSearchingCompleted:
        return (
          <div className={classNames(styles.noSymbols, { [styles.noSymbolsMob]: isMob })}>{noSymbolsFoundMessage}</div>
        );
      default:
        return (
          filteredResults.map(stock => (isMob ? (
            <ResultRowMobile
              stock={stock}
              key={stock.symbol}
              logo={companyLogoAndNameData[stock.symbol]?.logo}
              addToFavourites={onAddToFavourites}
              closeModal={() => closeModal && closeModal()}
            />
          ) : (
            <StockRow
              stock={stock}
              logo={companyLogoAndNameData[stock.symbol]?.logo}
              key={stock.symbol}
              closeModal={() => closeModal && closeModal()}
            />
          ))));
    }
  };

  return (
    <div className={classNames(styles.resultWrapper, { [styles.resultWrapperMob]: isMob })}>
      {renderStockResults()}
      { filteredResults
        && filteredResults.length === (DEFAULT_SEARCH_STOCK_LIMIT * currentPageNumber)
        && filteredResults.length <= SEARCH_STOCK_MAX_LIMIT
        && (
          <Button
            className={styles.btnLoadMore}
            variant={ButtonTypesEnum.Link}
            onClick={(e) => handleEndReached(e)}
          >
            {lang.commonLoadMore()}
          </Button>
        )}
      {(isAddedToFavourites || isRemovedFromFavourites) && (
        <FavouritesModal
          isAddedToFavourites={isAddedToFavourites}
          isRemovedFromFavourites={isRemovedFromFavourites}
          symbol={selectedSymbol}
          closeModal={closeFavouritesModal}
          isMobile
        />
      )}
      {showFavouritesLimitMessage && (
        <FavouriteSymbolsLimitModal closeMessage={() => setShowFavouritesLimitMessage(false)} isMobile />
      )}
    </div>
  );
};

export default StocksResult;
