import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store/index';
import { OpenPosition } from '../../../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../../../lib/store/selectors';
import TradePriceCache from '../../../../lib/store-util/TradePriceCache';
import { formatCurrency, roundUnrealizedProfit } from '../../../../lib/util/DataHelpers';
import {
  getLongPositions, getShortPositions, getTotalValue, getUnrealizedProfit,
} from '../../../../lib/util/my-account-helpers';

import styles from './CashDetail.module.scss';

type Props = {
  isSmallScreen?: boolean;
  setCashValue: (value: number) => void;
  setPortfolioValue: (value: number) => void;
}

const CashDetails = ({ isSmallScreen, setCashValue, setPortfolioValue }: Props) => {
  const [availbale, setAvailbale] = useState<number>(0);
  const [investments, setInvestments] = useState<number>(0);
  const [unrealizedProfitPos, setUnrealizedProfitPos] = useState<number | null>(0);

  const openPositions = useSelector<RootState, OpenPosition[]>((state: RootState) => state.reporting.openPositions);
  const buyingPower = useSelector<RootState, number>((state: RootState) => getCurrentBuyingPower(state));
  const availableCash = useSelector<RootState, number>(
    (state: RootState) => state.reporting.accountsAvailableCash.data?.availableCash,
  );

  const tradePricesCache = TradePriceCache.useCustom(data => data); // TODO: Optimize this by moving this hook to each item (TradePriceCache.use(symbol, 'ItemComponentName')). Thus only the relevant items will be rendered - not the whole Watchlist Table.

  useEffect(() => {
    if (availableCash) {
      if (availableCash > 0 && !!buyingPower) {
        const availbaleSum = buyingPower - availableCash;
        setAvailbale(availbaleSum);
        setCashValue(availbaleSum);
      } else {
        setAvailbale(availableCash);
        setCashValue(availableCash);
      }
      setAvailbale(availableCash);
      setCashValue(availableCash);
    } else {
      setAvailbale(0);
      setCashValue(0);
    }
  }, [availableCash, buyingPower, setCashValue]);

  useEffect(() => {
    if (openPositions) {
      const longPositionList = getLongPositions(openPositions);
      const shortPositionList = getShortPositions(openPositions);
      const investmentsSum = getTotalValue(openPositions);

      setInvestments(investmentsSum);
      const unrealizedProfitSum = (
        (getUnrealizedProfit(longPositionList, tradePricesCache) || 0)
        + (getUnrealizedProfit(shortPositionList, tradePricesCache) || 0));

      setPortfolioValue(investmentsSum + unrealizedProfitSum);
      setUnrealizedProfitPos(unrealizedProfitSum);
    }
  }, [openPositions, setPortfolioValue, tradePricesCache]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.row}>
        <div className={styles.titleWrapper}>
          <div className={classNames(styles.indicator,
            { [styles.accentCircle]: investments || unrealizedProfitPos })}
          />
          <span className={styles.cardTitle}>{lang.mobilePortfolioTitle()}</span>
        </div>
        <span>{formatCurrency(investments + (unrealizedProfitPos ?? 0))}</span>
      </div>
      <div className={classNames(styles.row, styles.investments)}>
        <span className={styles.cardSubTitle}>
          {lang.commonAccountBreakdownInvestments()}
        </span>
        <span>{formatCurrency(investments)}</span>
      </div>
      <div className={classNames(styles.row, styles.unrealizedProfit)}>
        <span className={styles.cardSubTitle}>
          {lang.commonAccountBreakdownUnrealizedProfit()}
        </span>
        <span>{roundUnrealizedProfit(unrealizedProfitPos, true)}</span>
      </div>
      <div className={styles.horizontalLine} />
      <div className={styles.row}>
        <div className={styles.titleWrapper}>
          <div className={classNames(styles.indicator,
            { [styles.orangeCircle]: availbale })}
          />
          <span className={styles.cardTitle}>{lang.commonAccountBreakdownCashToInvest()}</span>
        </div>
        <span>{formatCurrency(availbale)}</span>
      </div>
    </div>
  );
};

export default CashDetails;
