import { PaymentProcessEnum } from '../../enums';
import { GetPaymentsPayload, Payment } from '../../models/crm/types';
import {
  CreateCardTransferSuccessResponse,
  GetCardTransferStatusSuccessResponse,
  TransferFailResponse,
} from '../../models/payment/types';
import { NullableNumber } from '../../util/types';


export enum PaymentCall {
  cashCalculated,
  getAvailableCash,
  getOwnerTradingAccounts,
  getPayments,
  getPaymentsAll,
  getWireTransferBankAccount,
  getIndividualLinkedAccounts,
  registerLinkedAccount,
  registerWithdrawalRequest,
  uploadDocumentForLinkedAccount,
  /**
   * aka Create Bank Deposit Transfer
   * aka Bank Deposit Transaction
   */
  createBankTransfer,
  /**
   * aka Create Card Deposit Transfer (Card Deposit Transaction)
   */
  createCardTransfer,
  getCardTransferStatus,
}

export type PaymentCallName = keyof typeof PaymentCall;

export type TransactionsState = {
  process: PaymentProcessEnum;
  data: Payment[];
  filteredOut: Payment[];
  ignored: Payment[];
  // extraData: ExtraPaymentData;
  pageSize: number;
  orderColumn: string;
  orderAscending: boolean;
  isDepositsCall?: boolean;
  isAllTransactionsCall?: boolean;
  callsInQueue: GetPaymentsPayload[];
}

export type LastCardTransferType = {
  amount: number;
  // create card transfer
  createTransferData?: CreateCardTransferSuccessResponse
  statusCheckData?: GetCardTransferStatusSuccessResponse
  transferFailedData?: TransferFailResponse
}

export type CreateCardTransferPayload = {
  amount: number,
  isInitialDeposit: boolean
}
export type WebLastPaymentCache = {
  amount?: NullableNumber
  payment_reference?: string
}

export type RegisterLinkedAccountPayload = {
  /**
  * The format of `file` is native and not used directly in TypeScript.
  * For example Web uses FileReader and Mobile the library `react-native-blob-util` which generates the type within native code.
  * The `file` type is not used in the TypeScript world.
  */
  file?: any
} & BankAccountDetails;

export type UploadBankStatementPayload = {
  file: { fileName: string, base64Data: string }
} & BankAccountDetails

export type BankAccountDetails = {
  iban: string
  bankName: string
  swiftCode: string
  currencyCode: string
  bankCountryId: string
  bankAddress?: string;
}

export type PaymentStatusUIStatePayload = {
  call: PaymentCall
  /**
   * If true - CallStatus.UI_DONE is set as status
   * else - CallStatus.UI_PENDING is set as status
   */
  isDone: boolean
}

export type RefreshPaymentsPayload = Partial<Record<PaymentCall, boolean>>
