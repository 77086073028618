/**
 * CallsCache implements a mechanism of monitoring calls state:
 *   a) based on CALLS_TO_MEMOIZE in `libSettings.ts`
 *   b) saves when a call to a server would time out
 *   c) saves when a call to a server would expire
 *   d) saves if a call to a server is pending (isPending=true) or has finished (isPending=false)
 *   e) then b) and c) are used to decide when the call should be repeated because of expiring or timing out:
 *       - if the saved `timeoutDate` is in the past
 *       - or if the saved `expDate` is in the past
 * To find examples - search for matches of `CallsCache.`.
 * The main usages are intended to be:
 *   1) `CallsCache.processRequest`
 *   2) `CallsCache.processResponse`
 */
import { cloneDeep, isBoolean } from 'lodash';
import moment from 'moment';

import { logToDebugState } from '../../debug/helpers/debug-log-state';
import { CacheableCalls, CALLS_TO_MEMOIZE } from '../../libSettings';
import { NullableString } from '../../util/types';

import { CachedCallState, DataByTraceId, RequestCache, RequestDataByTraceId } from './types';

const requests: Record<string, RequestCache> = {};
const keysByTraceId: Record<string, DataByTraceId> = {};

let debugIntervalId = null as any;


// =======     Private Functions     ======= //

/** Enables debugging CallsCache state in Reactotron if `logConfig.callsCacheDebugEnabled` is truthy */
function startDebug() {
  const { callsCacheDebugEnabled } = require('../../../configDebug')?.logConfig ?? {};
  if (!callsCacheDebugEnabled || debugIntervalId) return;

  debugIntervalId = setInterval(() => {
    const data = getDebugState();
    logToDebugState(data, 'callsCache');
  }, 1000);
}


/**
 * Modifies calls cache as follows:
 *  - if newPendingValue is `true` - the call is in progress with updated `timeoutDate` and `expDate`
 *  - if newPendingValue is `false` - the call has been received
 * Returns `CachedCallState` for a request with `name` and `params`.
 * If also updates the request cache:
 *     * if `newPendingValue` is truthy - sets `isPending` to `true` unless already
 *       pending or both `hasExpired` and `hasTimedOut` are `false`
 *     * if `newPendingValue` is falsy` - sets `isPending` to `false`
 * @param name The name of the request to check if pending - for example `getCompanyLogo`.
 * @param params Extra string for distinguishing between calls - for a example see `genCacheParams` for
 *               the `getChartingData` call in `store/market-data/charting/helpers.ts`
 * @param isRequest If `true` or `false` - the request cache is updating `isPending` to the value of `isRequest`.
 * @returns `CachedCallState` For calls that are just cached/memoized - `hasExpired` is always true
 */
function processCall(
  name: CacheableCalls,
  params: NullableString,
  isRequest: boolean,
  isOnlyCheck?: boolean,
  errorMessage?: string,
): CachedCallState {
  startDebug();

  const key = params ? `${name}_${params}` : name;

  const cacheSettings = CALLS_TO_MEMOIZE[name];
  const isCacheable = !isBoolean(cacheSettings) ? cacheSettings?.isCached : cacheSettings as boolean;
  if (!isCacheable) {
    console.warn(`[services/helpers] CallsCache - processCall - '${name}' is set as not cacheable (${key})`);
    return { key, wasPending: null, toBeUpdated: null, isFirstTime: null, errorMessage };
  }

  const oldCache = requests[key];
  const { isPending: wasPending = false } = oldCache || {};
  if (isRequest && wasPending) {
    console.warn(`[services/helpers] CallsCache - processCall - '${name}' making a call while one still in progress (${key})`);
    if (oldCache && errorMessage) {
      oldCache.errorMessage = errorMessage;
    }
    return { key, wasPending: true, toBeUpdated: false, isFirstTime: false, errorMessage };
  }

  const isFirstTime = oldCache?.isFirstTime ?? true;
  let lastUpdateAt = oldCache?.lastUpdateAt;
  const isDirty = checkIfDirty(name, params);
  const isRequestToBeUpdated = isRequest && isDirty;
  const isResponse = !isRequest;
  let result = { key, wasPending: false, toBeUpdated: true, isFirstTime: true, errorMessage };
  lastUpdateAt = moment();

  if (isRequest && !isOnlyCheck) {
    if (isRequestToBeUpdated) {
      requests[key] = {
        isPending: true,
        lastUpdateAt,
        timedoutDate: getTimedOutDate(key, name),
        isFirstTime,
      };
    } else if (requests[key]) {
      requests[key].lastUpdateAt = lastUpdateAt;
    }
  } else if (isResponse && !isOnlyCheck) {
    requests[key] = {
      isPending: false,
      lastUpdateAt,
      expDate: getExpiredDate(key, name),
      isFirstTime: false,
    };
  } else if (!isOnlyCheck) {
    console.warn(`[CallsCache] processCall - trying to process a non-pending response - '${key}' // only-check: ${isOnlyCheck}  pending: ${wasPending} first: ${isFirstTime}`);
  }

  if (errorMessage) {
    requests[key].errorMessage = errorMessage;
  }

  result = { key, wasPending, isFirstTime, toBeUpdated: isRequestToBeUpdated, errorMessage };

  return result;
}

function setTraceId(traceId: string, key: string, callName: CacheableCalls, callNameParams?: string) {
  keysByTraceId[traceId] = { key, callName, callNameParams };
}
/**
 * Get CachedCallState for the `key` from `requests`
 */
function getByTraceId(traceId: string, deleteAfterUsing: boolean = false) {
  const dataByTraceId = keysByTraceId[traceId] ?? {};
  const { key, callName, callNameParams } = dataByTraceId ?? {};
  if (deleteAfterUsing && dataByTraceId) {
    deleteTraceIdKey(traceId);
  }
  if (!key) return null;

  return {
    ...requests[key],
    key,
    callName,
    callNameParams,
  };
}

function deleteTraceIdKey(traceId: string) {
  delete keysByTraceId[traceId];
}

function getFullState() {
  return {
    keys: keysByTraceId,
    requests,
  };
}

function deleteCache(name: CacheableCalls, params?: NullableString) {
  const key = params ? `${name}_${params}` : name;
  delete requests[key];
}

function getCallState(name: CacheableCalls, params?: NullableString) {
  const key = params ? `${name}_${params}` : name;
  const cache = requests[key];
  const { isFirstTime = true, isPending } = cache || {};
  const hasExpired = checkIfExpired(name, params);
  const hasTimedOut = checkIfTimedOut(name, params);
  const isDirty = isFirstTime || hasTimedOut || hasExpired;
  return {
    isDirty,
    isFirstTime,
    hasTimedOut,
    hasExpired,
    isPending,
  };
}

function getExpiredDate(key: string, name: CacheableCalls) {
  const cacheSettings = CALLS_TO_MEMOIZE[name];
  const cache = requests[key];
  const lastUpdateAt = cache?.lastUpdateAt ?? moment();
  const expiration = !isBoolean(cacheSettings) ? cacheSettings?.expire : null;
  return expiration ? lastUpdateAt.clone().add(...expiration) : undefined;
}

function getTimedOutDate(key: string, name: CacheableCalls) {
  const cacheSettings = CALLS_TO_MEMOIZE[name];
  const cache = requests[key];
  const lastUpdateAt = cache?.lastUpdateAt ?? moment();
  const timedout = !isBoolean(cacheSettings) ? cacheSettings?.timeout : null;
  return (timedout && lastUpdateAt?.isValid) ? lastUpdateAt.clone().add(...timedout) : undefined;
}


// =======     Public Functions     ======= //

/**
 * Process call state on request :
 *  a) updates `lastUpdated`
 *  b) sets `isPending` to `true` and updates `timedoutDate` if call is to be updated
 *  c) saves `traceId` to `keysByTraceId` if the 3rd parameter `requestFunctionReturnsTraceId` is given
 * Note: For more details see `processCall`.
 * @param name The call name
 * @param params Call params as string - `null` if none
 * @param requestFunctionReturnsTraceId The request function that returns trace id, if processing response requires trace id
 * @returns `CachedCallState`
 */
function processRequest(name: CacheableCalls, params: NullableString, requestFunctionReturnsTraceId?: () => string) {
  let result = processCall(name, params, true);
  let traceId = '';
  if (requestFunctionReturnsTraceId && result.toBeUpdated) {
    traceId = requestFunctionReturnsTraceId();
    if (traceId) {
      setTraceId(traceId, result.key, name, params ?? '');
    } else {
      console.warn(`[CallsCache] processRequest - function 'requestFunctionReturnsTraceId' for call '${result.key}' returns invalid trace identifier - '${traceId}'. Reverting cache state to not pending!`);
      if (traceId === null) {
        result = processResponse(name, params, 'not connected');
      }
    }
  }
  return { ...result, traceId };
}

/**
 * Similar to `processRequest` but just checks current call state without updating it
 */
function checkRequest(name: CacheableCalls, params: NullableString) {
  const result = processCall(name, params, true, true);
  return result;
}

/**
 * Process call state on response - set `isPending` to false
 */
function processResponse(name: CacheableCalls, params: NullableString, errorMessage?: string) {
  return processCall(name, params, false, false, errorMessage);
}

function processResponseCustomCases(callData: RequestDataByTraceId) {
  const { isWeb } = require('../../../configLib').default ?? {};
  const { isPending, key, callName, callNameParams } = callData;

  if (callNameParams === 'SMALLCHART_INITIAL') {
    const { composeSmallChartCallCacheParam } = require('./helpers');
    // common
    processResponse(callName, composeSmallChartCallCacheParam('watchlist'));

    // project custom
    if (isWeb) {
      processResponse(callName, composeSmallChartCallCacheParam('discover'));
    }
  }
}

/**
 * @param traceId The trace identifier to use as a key
 * @param fallbackCallName Fallback call name in case trace identifier cache is not found (or key is invalid)
 */
function processResponseByTraceId(traceId: NullableString, fallbackCallName?: CacheableCalls | string) {
  if (!traceId) {
    console.warn(`[CallsCache] processResponseByTraceId - invalid traceId for call '${fallbackCallName}'`);
    return {};
  }

  const callData = getByTraceId(traceId, true) ?? {} as RequestDataByTraceId;

  const { isPending, key, callName, callNameParams } = callData;

  if (isPending && callName) {
    return processResponse(callName, callNameParams);
  }

  processResponseCustomCases(callData);

  console.warn(`[CallsCache] processResponseByTraceId - invalid data for call '${callName ?? fallbackCallName}' (key '${key}')`, { callData, traceId });
  return {};
}

/**
 * Checks if call needs refreshing (if `isDirty` is `true`)
 */
function checkIfDirty(name: CacheableCalls, params?: NullableString) {
  return getCallState(name, params).isDirty;
}

/**
 * Checks if call is pending (response not received yet)
 */
function checkIfPending(name: CacheableCalls, params?: NullableString) {
  return getCallState(name, params).isPending;
}

/**
 * Checks if call cache has expired
 */
function checkIfExpired(name: CacheableCalls, params?: NullableString) {
  const key = params ? `${name}_${params}` : name;
  const cache = requests[key];
  if (!cache) return false;

  const { expDate } = cache;
  if (!expDate) return false;

  const diff = expDate.diff(moment(), 'seconds');
  return (diff <= 0);
}

/**
 * Checks if call has timed out
 */
function checkIfTimedOut(name: CacheableCalls, params?: NullableString) {
  const key = params ? `${name}_${params}` : name;
  const cache = requests[key];
  if (!cache) return false;

  const { timedoutDate } = cache;
  if (!timedoutDate) return false;

  const diff = timedoutDate.diff(moment(), 'milliseconds');
  return (diff <= 0);
}

function getDebugState() {
  const { callsCacheDebugFilterInclude, callsCacheDebugFilterExclude } = require('../../../configDebug')?.logConfig ?? {};

  const rawData = cloneDeep(getFullState());
  const { requests: rawRequests, keys } = rawData;
  const data = { keys, requests: {} as Record<string, RequestCache> };
  // filter data based on `logConfig.callsCacheDebugFilterInclude` and `logConfig.callsCacheDebugFilterExclude`
  for (const prop in rawRequests) {
    if (
      prop.match(callsCacheDebugFilterInclude)
        && (!callsCacheDebugFilterExclude || !prop.match(callsCacheDebugFilterExclude))
    ) {
      data.requests[prop] = rawRequests[prop];
    }
  }
  return data;
}

// =======     Type Exports     ======= //

export type ProcessCallReturn = ReturnType<typeof processCall>;
export type ProcessCallFunction = (name: CacheableCalls, params: NullableString) => ProcessCallReturn;


// =======     Default Export     ======= //

export default {
  processRequest,
  processResponse,
  processResponseByTraceId,
  checkRequest,
  checkIfDirty,
  checkIfExpired,
  checkIfTimedOut,
  checkIfPending,
  getDebugState,
};
