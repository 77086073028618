import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Sector } from '../../../lib/models/fundamental/types';
import { getAllSectors } from '../../../lib/services/fundamental/helpers';
import { RootState } from '../../../lib/store';
import { getETFs, getSectors } from '../../../lib/store/fundamental';
import { DEFAULT_PAGE, GET_FIRST_ITEM_SIZE } from '../../../lib/store/fundamental/constants';

import { Preloader } from '../../../components';
import ClickableDiv from '../../../components/ClickableDiv/ClickableDiv';
import { UI_ROUTES } from '../../../util/routes';
import { SECTORS_PER_ROW } from '../PopularGroups.constants';
import { NewOnThePopularGroupsData } from '../PopularGroups.types';

import groups from './groups';

import styles from './PopularGroupItems.module.scss';

const PopularGroupItems = () => {
  const sectors = useSelector<RootState, Sector[]>((state: RootState) => getAllSectors(state));
  const canTradeETFs = useSelector<RootState, boolean>((state: RootState) => state.ses.canTradeETFs);
  const totalETFs = useSelector<RootState, number>((state: RootState) => state.fundamental.ETFData.totalElements);
  const isLoading = useSelector<RootState, boolean>((state: RootState) => state.news.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  let lengthGroupsRow = '';
  let renderGroups : NewOnThePopularGroupsData[] = [];

  useEffect(() => {
    if (canTradeETFs) {
      dispatch(getETFs({ size: GET_FIRST_ITEM_SIZE, page: DEFAULT_PAGE }));
    }
  }, [canTradeETFs, dispatch]);

  const renderGroup = (groupsData: NewOnThePopularGroupsData[], index: number) => {
    renderGroups = [];
    lengthGroupsRow = '';

    return (
      <div key={index}>
        <div className={styles.wrapperRowGroups}>
          {
            groupsData.map((group) => {
              const sectorName = group.sector.replace(/ /g, '');
              const groupValues = groups[sectorName];

              return (
                <ClickableDiv
                  key={group.id}
                  className={styles.group}
                  onClick={() => history.push({
                    pathname: `${UI_ROUTES.discover}${UI_ROUTES.popularGroup}/${group.sector}`,
                    state: { group, sector: groupValues.name },
                  })}
                >
                  <div>
                    <img className={styles.icon} src={groupValues.logo} alt="" />
                    <span className={styles.itemCount}>{`+${group.symbolCount}`}</span>
                  </div>
                  <div className={styles.itemName}>
                    {groupValues.name}
                  </div>
                </ClickableDiv>
              );
            })
         }
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getSectors());
  }, [dispatch]);

  if (sectors.length === 0
    || (canTradeETFs && (typeof (totalETFs) === 'undefined' || totalETFs === 0))
  ) {
    return <Preloader text="" owner="PopularGroups" />;
  }

  const sortedSectors: NewOnThePopularGroupsData[] = [];

  Object.values(groups).forEach(group => {
    const findSector = sectors.find(sector => sector.sector === group.sectorName);
    findSector && sortedSectors.push(findSector);
  });

  return (
    <div className={styles.row}>
      {
          sortedSectors.map((item, index) => {
            lengthGroupsRow += item.sector;
            renderGroups.push(item);
            if (renderGroups.length === SECTORS_PER_ROW
                || index === sortedSectors.length - 1 // Return last X sectors in case they are less than SECTORS_PER_ROW.
            ) {
              return renderGroup(renderGroups, index);
            }
            return null;
          })
        }
    </div>
  );
};

export default PopularGroupItems;
