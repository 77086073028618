import React from 'react';
import classNames from 'classnames';

import lang from '../../../lib/language';

import styles from './SearchMob.module.scss';

type Props = {
  searchStocks: (val: string) => void;
}

const SearchMob = ({ searchStocks }: Props) => (
  <div className={classNames(styles.searchWrapper)}>
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      className={styles.input}
      placeholder={lang.commonOnboardingPhoneVerificationSearchCodePlaceholder()}
      type="search"
      onChange={(val) => searchStocks(val.target.value)}
    />
  </div>
);

export default SearchMob;
