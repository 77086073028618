import React, { useEffect, useState } from 'react';

import { SOROrderSide, SOROrderType } from '../../../../lib/models/gateway/types';
import { isOrderPartialyFill } from '../../../../lib/store/reporting/trading/order-helpers';
import { AccountTradeHistoryResponseData } from '../../../../lib/store/reporting/types';
import { formatCurrency } from '../../../../lib/util/DataHelpers';
import {
  setPendingOrdersDatePlaced,
  setPendingOrdersPrice,
  sortPendingOrders,
} from '../../../../lib/util/my-account-helpers';
import { mapOrderType } from '../../../../lib/util/TradingHelpers';

import useIsMobile from '../../../../hooks/useIsMobile';

import PendengOrdersQuantity from './PendengOrdersQuantity';
import PendingOrdersExpirationDate from './PendingOrdersExpirationDate';

import styles from '../../MyAccount.module.scss';

type PendingOrdersContent = {
  id: string,
  datePlaced: string,
  type: string,
  quantity: JSX.Element,
  initialPrice: number | null,
  setPrice: string,
  expiryDate: JSX.Element,
  buttons: string;
  name?: string,
  icon?: string,
  subtitle?: string,
};

export const usePendingOrdersTableContent = (
  accountTradeHistory: AccountTradeHistoryResponseData[], hasSymbol = false, hasMyStocksOrderUpdate?: boolean,
) => {
  const [pendingOrders, setPendingOrders] = useState<PendingOrdersContent[]>([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!accountTradeHistory.length) {
      setPendingOrders([]);
    }
    if (!hasMyStocksOrderUpdate) {
      const content: PendingOrdersContent[] = [];
      const sortedTradeHistory = sortPendingOrders(accountTradeHistory);

      for (let index = 0; index < sortedTradeHistory.length; index += 1) {
        const {
          side,
          type,
          symbol,
          orderDate,
          tenNStatus,
          timeInForce,
          parentOrderId,
          totalQuantity,
          expirationDate,
          orderStopPrice,
          tradingSessionId,
          orderRequestedPrice,
          sorExecutedQuantity,
        } = sortedTradeHistory[index];
        const initialPrice = setPendingOrdersPrice(type, orderRequestedPrice, orderStopPrice);

        const symbolDetailsorder = {
          id: parentOrderId,
          datePlaced: setPendingOrdersDatePlaced(orderDate),
          type: mapOrderType(side, type, true),
          side,
          plainType: SOROrderType[type],
          quantity: <PendengOrdersQuantity
            sorExecutedQuantity={sorExecutedQuantity}
            totalQuantity={totalQuantity}
            className={styles.quantityStatus}
            isMobile={isMobile}
          />,
          totalQuantity,
          setPrice: formatCurrency(initialPrice),
          initialPrice,
          expiryDate: <PendingOrdersExpirationDate
            expirationDate={expirationDate}
            timeInForce={timeInForce}
            externalHoursStyle={isMobile ? styles.externalHoursStyleMob : styles.externalHoursStyle}
            externalHours={tradingSessionId}
            isMobile={isMobile}
          />,
          name: symbol,
          tradingSessionId,
          timeInForce,
          partialFillQuantity: isOrderPartialyFill(tenNStatus) ? sorExecutedQuantity : 0,
          buttons: '',
        };

        const myAccountOrder = { ...symbolDetailsorder, subTitle: '', icon: '' };
        content.push(hasSymbol ? symbolDetailsorder : myAccountOrder);
      }
      setPendingOrders(content);
    }
  }, [accountTradeHistory, hasMyStocksOrderUpdate, hasSymbol, isMobile]);

  return pendingOrders;
};
