import moment from 'moment';

import { pendingOrdersDatePlacedFormat } from '../constants/date-time.constants';
import lang from '../language';
import { CompanyLogoAndNameState } from '../models/fundamental/types';
import { SOROrderType } from '../models/gateway/types';
import { ChartDataRange } from '../store/market-data/charting/types';
import {
  AccountTradeHistoryResponseData,
  MyAccountChartDataResponse,
  MyStocksContent,
  NetComponent,
  OpenPosition,
  PortfolioChartElement,
  PositionType,
} from '../store/reporting/types';
import { preparePnlResultByLengthAndRange } from '../store/selectors';
import { CalculatedTradePrice, CalculatedTradePriceDataMap } from '../store-util/TradePriceCache';

import {
  calcUnrealizedProfitPercent,
  formatCurrency,
  formatNumber,
  formatNumberWithSuffix,
  roundUnrealizedProfit,
  unrealizedPnL,
} from './DataHelpers';
import { convertDateToFormatedString, getDateTime, parseDateToUTC } from './DateTimeHelpers';
import { INVALID_VALUE, NullableNumber, PieData, TypedObject } from './types';

const formatNumberWrapper = (price: number) => formatNumber(price, false, false, true, 2, false);

export const navigationButtonTextTotal = lang.commonAccountBreakdownTotal();
export const navigationButtonTextLong = lang.commonAccountBreakdownLong();
export const navigationButtonTextShort = lang.commonShort();

export const setPendingOrdersDatePlaced = (
  orderDate: string,
) => moment.utc(orderDate).format(pendingOrdersDatePlacedFormat);

const absoluteDateValue = (date: string) => new Date(date).valueOf();

export const sortPendingOrders = (pendingOrders: AccountTradeHistoryResponseData[]) => (
  [ ...pendingOrders ].sort((a, b) => (
    absoluteDateValue(a.expirationDate) !== absoluteDateValue(b.expirationDate)
      ? absoluteDateValue(b.expirationDate) - absoluteDateValue(a.expirationDate)
      : absoluteDateValue(b.orderDate) - absoluteDateValue(a.orderDate)
  ))
);

export const sortOpenPositions = (openPositions: OpenPosition[]) => (
  [ ...openPositions ].sort((a, b) => (
    absoluteDateValue(b.lastExecutionDateTime) - absoluteDateValue(a.lastExecutionDateTime)
  ))
);


// eslint-disable-next-line max-len
export const calculateMyStockContent = (activePosition: string, positions: OpenPosition[], currentPrice: Partial<Record<string, CalculatedTradePrice>>) => {
  const longPositions = getLongPositions(positions);
  const shortPositions = getShortPositions(positions);
  if (activePosition === navigationButtonTextLong) {
    return {
      data: longPositions,
      value: calculateTotalPostionsValue(positions, currentPrice).totalLongPos,
    };
  }
  if (activePosition === navigationButtonTextShort) {
    return {
      data: shortPositions,
      value: calculateTotalPostionsValue(positions, currentPrice).totalShortPos,
    };
  }

  return {
    data: positions,
    value: calculateTotalPostionsValue(positions, currentPrice).totalPositions,
  };
};

// eslint-disable-next-line max-len
export const renderChartTextContent = (stocks: number, selectedChartIndex?: PortfolioChartElement, totalStockValue?: number): string[] => (
  selectedChartIndex?.symbol ? [
    selectedChartIndex.symbol,
    selectedChartIndex.y ? formatCurrency(selectedChartIndex.y) : '',
    selectedChartIndex.y && totalStockValue
      ? lang.commonAccountBreakdownPieChartElementShare(formatNumber((selectedChartIndex.y / totalStockValue) * 100)) : '',
  ] : [
    lang.commonMyAccountTotalValueTitle(),
    totalStockValue ? `${formatCurrency(totalStockValue)}` : `${formatCurrency(0)}`,
    lang.commonAccountBreakdownPieChartStocks(stocks),
  ]
);

export const mapMyStocksTableContent = (
  sortedOpenPositions: OpenPosition[],
  cache: Partial<Record<string, CalculatedTradePrice>>,
  companyLogoAndName: TypedObject<CompanyLogoAndNameState>,
): MyStocksContent[] => {
  const tableContentPos: MyStocksContent[] = [];

  for (let index = 0; index < sortedOpenPositions.length; index += 1) {
    const { symbol, quantity, averageOpeningPrice, positionType } = sortedOpenPositions[index];
    const absoluteQuantity = Math.abs(quantity);
    const marketPrice = cache[sortedOpenPositions[index].symbol]?.currentPrice;
    const unrealizedProfitNumber = unrealizedPnL(quantity, marketPrice!, averageOpeningPrice);
    const unrealizedProfitPercent = calcUnrealizedProfitPercent(positionType, marketPrice!, averageOpeningPrice);

    tableContentPos.push({
      id: symbol,
      name: symbol,
      subtitle: companyLogoAndName[symbol]?.name,
      icon: companyLogoAndName[symbol]?.logo,
      quantity: absoluteQuantity,
      purchasePrice: formatCurrency(sortedOpenPositions[index].averageOpeningPrice),
      marketPrice: formatCurrency(marketPrice),
      unrealizedProfit: `${roundUnrealizedProfit(unrealizedProfitNumber, true)}
      (${Math.abs(Number(unrealizedProfitPercent))}%)`,
      buttons: '',
      positionType,
      unrealizedProfitNumber,
    });
  }
  return tableContentPos;
};

/**
 * TODO: Review this when cleaning code with ART-3368. It is used only in Mobile
 * Goals:
 *   (1) Sync with Web to have 1 implementation
 *   (2) Remove or merge with all other code having in mind rendering and TradePriceCache usage
 */
export const mapSingleOpenPositionToStockData = (
  value: OpenPosition,
  currentPrice?: NullableNumber,
  cachedPrices?: Partial<Record<string, CalculatedTradePrice>>,
) => {
  const { symbol, quantity, averageOpeningPrice, positionType } = value;
  const purchasePrice = formatCurrency(formatNumberWrapper(averageOpeningPrice));
  const thePrice = currentPrice ?? cachedPrices?.[symbol]?.currentPrice;
  const unrealizedProfitNumber = unrealizedPnL(quantity, thePrice, averageOpeningPrice);
  const profit = roundUnrealizedProfit(unrealizedProfitNumber, true);
  const percent = calcUnrealizedProfitPercent(positionType, thePrice, averageOpeningPrice);
  const unrealizedProfit = `${profit} (${Math.abs(Number(percent))}%)`;
  return {
    averageOpeningPrice,
    thePrice,
    positionType,
    purchasePrice,
    quantity,
    symbol,
    unrealizedProfit,
    unrealizedProfitNumber,
    openPositionData: value,
  };
};
export type SingleOpenPositionStockData = ReturnType<typeof mapSingleOpenPositionToStockData>;

export const mapOpenPositionsToStockData = (openPositions: OpenPosition[]) => (
  sortOpenPositions(openPositions)
    .map(value => mapSingleOpenPositionToStockData(value))
);

// eslint-disable-next-line max-len
export const setPendingOrdersPrice = (type: SOROrderType, orderRequestedPrice: number | null, orderStopPrice: number | null) => {
  switch (type) {
    case SOROrderType.Limit:
      return orderRequestedPrice;
    case SOROrderType.Stop:
      return orderStopPrice;
    default: return null;
  }
};

export const getTotalValue = (positions: OpenPosition[]) => (
  positions
    .map(pos => (
      pos.positionType === PositionType.Buy ? pos.quantity : Math.abs(pos.quantity)
    ) * pos.averageOpeningPrice)
    .reduce((prev, next) => prev + next, 0)
);

export const getUnrealizedProfit = (
  positions: OpenPosition[],
  cache: CalculatedTradePriceDataMap,
) => positions
  .map(position => {
    const lastPrice = cache[position?.symbol]?.currentPrice;
    return unrealizedPnL(position.quantity, lastPrice ?? 0, position.averageOpeningPrice);
  })
  .reduce((prev, next) => (prev ?? 0) + (next ?? 0), 0);

export const getLongPositions = (positions: OpenPosition[]): OpenPosition[] => (
  positions.filter(position => position.positionType === PositionType.Buy)
);

export const getShortPositions = (positions: OpenPosition[]): OpenPosition[] => (
  positions.filter(position => position.positionType === PositionType.Sell)
);

export const sortPositionsByPriceDescending = (
  positions: OpenPosition[],
  cache: CalculatedTradePriceDataMap,
) => (
  positions.sort((a, b) => {
    const priceA = cache[a.symbol]?.currentPrice ?? 0;
    const priceB = cache[b.symbol]?.currentPrice ?? 0;
    return priceA - priceB;
  })
);

export const mapPositionsToPieChartData = (
  positions: OpenPosition[],
  cache: CalculatedTradePriceDataMap,
): PieData[] => (
  positions.map(position => ({
    x: position.symbol,
    y: Math.abs(position.quantity * (cache[position.symbol]?.currentPrice ?? 0)),
  }))
);

export const calculateTotalPostionsValue = (
  openPositions: OpenPosition[],
  marketPriceCache: Partial<Record<string, CalculatedTradePrice>>,
) => {
  const total = openPositions.reduce(
    (acc, val) => (val.positionType === PositionType.Buy
      ? {
        short: acc.short,
        long: acc.long + val.quantity * Number(marketPriceCache[val.symbol]?.currentPrice ?? 0),
      }
      : {
        long: acc.long,
        short: acc.short + Math.abs(val.quantity) * Number(marketPriceCache[val.symbol]?.currentPrice ?? 0),
      }),
    { long: 0, short: 0 },
  );

  return { totalLongPos: total.long, totalShortPos: total.short, totalPositions: total.long + total.short };
};

export const checkSymbolPostionType = (symbol: string, positions: OpenPosition[]) => (
  positions.find(el => el.symbol === symbol)?.positionType
);

export const calculateNetEquityComponentsFromDate = (currentRange: ChartDataRange): string => {
  let fromDate: string = '';
  const date = new Date();
  const currentYearUTC = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const stringCurrentYearDate = convertDateToFormatedString(currentYearUTC);

  switch (currentRange) {
    case '1M':
      fromDate = getDateTime(true, [ 'minus' ], [ [ 1, 'month' ] ]);
      break;
    case '3M':
      fromDate = getDateTime(true, [ 'minus' ], [ [ 3, 'months' ] ]);
      break;
    case '1Y':
      fromDate = getDateTime(true, [ 'minus' ], [ [ 1, 'year' ] ]);
      break;
    case 'YTD':
      fromDate = parseDateToUTC(stringCurrentYearDate) as string;
      break;
    case 'ALL':
      fromDate = getDateTime(true, [ 'minus' ], [ [ 5, 'years' ] ]);
      break;
    default: fromDate = getDateTime(true, [ 'minus' ], [ [ 1, 'day' ] ]);
  }

  return getDateTime(true, [ 'minus' ], [ [ 1, 'day' ] ], fromDate);
};

export const calculateMyAccountValueChangeAndPecent = (
  range: ChartDataRange,
  currentValue: number,
  data: any,
  netComponent: string,
  netIncomeComponents: MyAccountChartDataResponse[],
  setAbsoluteCurrentChange: (value: number) => void,
  setPeriodAbsoluteChange: (value: number) => void,
  setPeriodPercentChange: (value: number) => void,
  setPersentChange: (value: number) => void,
  setTodayAbsoluteChange: (value: number) => void,
  showPerformance: boolean,
) => {
  if (netIncomeComponents && netIncomeComponents.length) {
    const firstNetIncomeComponent = netIncomeComponents[0].netEquity;

    if (showPerformance) {
      let yesterdayValue = 0;
      if (netIncomeComponents.length > 1) {
        yesterdayValue = netComponent === NetComponent.NetIncome
          ? netIncomeComponents[netIncomeComponents.length - 2].netEquity
          : netIncomeComponents[netIncomeComponents.length - 2].pnlResult;
      }

      const zeroElement = preparePnlResultByLengthAndRange(netIncomeComponents, range);
      const todayChange = netComponent === NetComponent.PnL
        ? (currentValue + zeroElement) - yesterdayValue
        : currentValue - yesterdayValue;

      if (netComponent === NetComponent.NetIncome) {
        setAbsoluteCurrentChange(yesterdayValue !== 0 ? todayChange : 0);
        setPersentChange(yesterdayValue !== 0 ? (todayChange / Math.abs(yesterdayValue)) * 100 : 0);
      }
      if (netComponent === NetComponent.NetIncome) {
        const firstDateValue = (data && data.isPeriodShorterThanRange) ? 0 : firstNetIncomeComponent;
        const periodChange = currentValue - firstDateValue;
        setPeriodAbsoluteChange(periodChange);
        if (firstNetIncomeComponent !== 0) {
          setPeriodPercentChange((periodChange / firstNetIncomeComponent) * 100);
        }
      }
      setTodayAbsoluteChange(todayChange);
    } else if (netComponent === NetComponent.NetIncome) {
      const currentChange = currentValue - firstNetIncomeComponent;
      setAbsoluteCurrentChange(currentChange);
      if (firstNetIncomeComponent !== 0) {
        setPersentChange((currentChange / Math.abs(firstNetIncomeComponent)) * 100);
      }
    }
  }
};

export const checkChartForNegativeValue = (
  netIncomeComponents: MyAccountChartDataResponse[],
  netComponent: string,
  setHasNegativeValues: (value: boolean) => void,
) => {
  if (netIncomeComponents && netIncomeComponents.length) {
    const isLossProfitAndLoss = netIncomeComponents.find(el => el.pnlResult - netIncomeComponents[0].pnlResult < 0);
    const isLossNetIncome = netIncomeComponents.find(el => el.netEquity < 0);

    const isLoss = (isLossProfitAndLoss && netComponent === NetComponent.PnL)
    || (isLossNetIncome && netComponent === NetComponent.NetIncome);

    setHasNegativeValues(isLoss ?? false);
  }
};

export const calculateCashPortfolioProportion = (cash: number, portfolio: number): string => {
  const positiveCashValue = cash > 0 ? cash : 0;
  const positivePortfolioValue = portfolio > 0 ? portfolio : 0;
  let cashValue = 0;
  let portfolioValue = 0;

  if ((positiveCashValue + positivePortfolioValue) > 0) {
    cashValue = (100 * positiveCashValue) / (positiveCashValue + positivePortfolioValue);
    portfolioValue = 100 - cashValue;
  }

  return `${cashValue ? formatNumber(cashValue) : 0}% / ${portfolioValue ? formatNumber(portfolioValue) : 0}%`;
};
