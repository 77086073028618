import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import SearchSymbolModal from './components/SearchSymbolModal/SearchSymbolModal';
import { getTabName } from './util/helpers';
import { Downbar, Header, Sidebar } from './components';
import { useIsMobile } from './hooks';

import styles from './App.module.scss';

const HeaderAndSideBar = (): JSX.Element => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const renderDownbar = (): JSX.Element => (
    <div className={classNames(styles.downBar, { [styles.hide]: getTabName(location) === 0 })}>
      <Downbar />
    </div>
  );

  return (
    <>
      <div className={isMobile ? styles.headerMobile : styles.header}>
        <Header isSearchActive={(value) => setIsSearchOpen(value)} />
      </div>
      { isMobile
        ? renderDownbar()
        : <Sidebar /> }
      {isSearchOpen && <SearchSymbolModal onClose={() => setIsSearchOpen(false)} />}
    </>
  );
};

export default HeaderAndSideBar;
