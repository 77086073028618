/**
 * Services wrapper to support:
 *   - switching between same service provides (for example news from Gateway vs directly from News service)
 *   - initialization of services without token and with
 *   - connecting and disconnecting groups of services
 * */
import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import { logConfig } from '../../configDebug';
import configLib from '../../configLib';
import { BATCH_LOAD_WATCHLST_CHART, CACHED_MARKET_DATA_CALLS } from '../libSettings';
import {
  GWMarketDataQueryBucket, GWMarketDataSummaryBucket, GWQueryCase, GWRequestType,
} from '../models/gateway/types';
import { MDSFeed } from '../models/market-data/types';
import { Order } from '../models/trading/types';
import { hasValidToken, isGatewayMDFullyEnabled } from '../store/selectors';
import { ModifyOrCancelOrderRequest } from '../store/trading/types';
import CallsCache from '../store-util/calls-cache/CallsCache';
import InstrumentSnapshotCache from '../store-util/InstrumentSnapshotCache';
import OrderInfoCache from '../store-util/OrderInfoCache';
import SubscribeGroupCache from '../store-util/SubscribeGroupCache';
import SymbolStatusCache from '../store-util/SymbolStatusCache';
import TradePriceCache from '../store-util/TradePriceCache';
import { setSOROrderData } from '../util/TradingHelpers';
import { NullableString, OrderTicketComponent } from '../util/types';

import GatewayRestService from './GatewayRest.service';
import GatewayWSService from './GatewayWS.service';
import { getFeedByComponent } from './helpers';
import services from './index';
import { TradeClientStatus } from './TradeClient.service';
import {
  IAMSService,
  IAuthService,
  ICommonAuthService,
  ICRMService,
  IFileService,
  IFundamentalService,
  IMarketDataService,
  INewsService,
  IPaymentService,
  IReportingFileService,
  IReportingService,
  ISESService,
  ITradingService,
  RxJsData,
} from './types';

const {
  gatewayEnabled: GW_ENABLED,
  sorApiEnabled: SOR_ENABLED,
  __DEV__,
} = configLib;

class GlobalService {
  dispatch: (action: PayloadAction<any>) => void;

  public static gatewayWs: GatewayWSService;

  public static gatewayRest: GatewayRestService;

  public static globalService: GlobalService;

  public static marketDataService: IMarketDataService;

  public static newsService: INewsService;

  public static fileService: IFileService;

  // TODO: Next ones
  public static authService: IAuthService & ICommonAuthService;

  public static crmService: ICRMService;

  public static fundamentalService: IFundamentalService;

  public static reportingService: IReportingService;

  public static reportingFileService: IReportingFileService;

  public static tradeService: ITradingService;

  public static paymentService: IPaymentService;

  public static amsService: IAMSService;

  public static sesService: ISESService;

  public static createServicesWithoutToken() {
    GlobalService.authService = services.createAuthService();
    GlobalService.crmService = services.createCRMService(null);
  }

  public static updateServicesToken(token: string) {
    GlobalService.fundamentalService.setToken(token);
    GlobalService.gatewayWs.setToken(token);
    GlobalService.tradeService.setToken(token);
    GlobalService.gatewayRest.setToken(token);
    GlobalService.crmService.setToken(token);
    GlobalService.newsService.setToken(token);
    GlobalService.fileService.setToken(token);
    GlobalService.fundamentalService.setToken(token);
    GlobalService.reportingService.setToken(token);
    GlobalService.reportingFileService.setToken(token);
    GlobalService.paymentService.setToken(token);
    GlobalService.amsService.setToken(token);
    GlobalService.marketDataService?.setToken(token);
    GlobalService.sesService.setToken(token);
  }

  // TODO: ART-3959 this logic will be refactored
  public static createServices(token: string, dispatch: Dispatch) {
    GlobalService.globalService = new GlobalService(dispatch);
    // WebSocket
    GlobalService.gatewayWs = services.createGatewayWSService(dispatch);
    GlobalService.tradeService = services.createTradingService(dispatch, token);
    // REST
    GlobalService.gatewayRest = services.createGatewayRestService(token);
    GlobalService.crmService = services.createCRMService(token);
    GlobalService.newsService = services.createNewsService(token);
    GlobalService.fileService = services.createFileService(token);
    GlobalService.fundamentalService = services.createFundamentalService(token);
    GlobalService.reportingService = services.createReportingService(token);
    GlobalService.reportingFileService = services.createReportingFileService(token);
    GlobalService.paymentService = services.createPaymentService(token);
    GlobalService.amsService = services.createAmsService(token);
    GlobalService.sesService = services.createSESService(token);
    // to be deprecated
    if (!isGatewayMDFullyEnabled()) {
      GlobalService.marketDataService = services.createMarketDataService(dispatch);
    }
  }

  public static loadChartDataBySelectedComponent(): void {
    const symbols = SubscribeGroupCache.getSymbolsBySelectedComponent();
    if (isEmpty(symbols)) {
      return;
    }

    const currentComponent = SubscribeGroupCache.getCurrentComponent();
    const theFeed = getFeedByComponent(currentComponent);
    const caller = 'seleted-component-symbols';
    GlobalService?.globalService?.subscribe(symbols!, theFeed, true, caller);

    // 1D5m or custom bucket
    GlobalService.gatewayWs.sendRequest(
      GWRequestType.MarketDataSummary,
      {
        symbols,
        bucket: GWMarketDataSummaryBucket.OneDay,
      },
      GWQueryCase.SmallChartData,
      caller,
    );

    // 3M1D - temporary patch for previousClose/lineClose and chart color in some market states. Data is not available on Instrument Snapshot
    GlobalService.gatewayWs.sendRequest(
      GWRequestType.MarketDataSummary,
      {
        bucket: GWMarketDataSummaryBucket.ThreeMonths,
        symbols,
      },
      GWQueryCase.SmallChartData,
      caller,
    );
  }

  /**
   * Connects Web Socket services - Gateway and TAPI
   * (Direct WS connection to MDS is not used any more but is still not removed from code and can be easily used for debugging for example)
   */
  public static connect(token: string, isReconnect = false) {
    if (!hasValidToken(null, token)) {
      console.error(`[GlobalService] connect - invalid token: '${token?.substr(0, 50)}'`);
      return;
    }
    const isGatewayConnected = !!GlobalService.gatewayWs?.isConnected();
    const isTradeServiceConnected = GlobalService.tradeService?.isConnected();
    if (!isGatewayConnected) {
      GlobalService.gatewayWs.connect(token, isReconnect);
    }
    if (!isTradeServiceConnected) {
      GlobalService.tradeService.connect(token);
    }
    if (!isGatewayMDFullyEnabled()) {
      GlobalService.marketDataService.connect(token);
    }
  }

  /**
   * Disonnects Web Socket services - Gateway and TAPI
   * (Direct WS connection to MDS is not used any more but is still not removed from code and can be easily used for debugging for example)
   */
  public static disconnect() {
    const isGatewayConnected = !!GlobalService.gatewayWs?.isConnected();
    const isTradeServiceConnected = GlobalService.tradeService?.isConnected();
    if (isTradeServiceConnected) {
      GlobalService.tradeService?.disconnect();
    }
    if (isGatewayConnected) {
      GlobalService.gatewayWs?.disconnect();
    }
    if (!isGatewayMDFullyEnabled()) {
      GlobalService.marketDataService?.disconnect();
    }
  }

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  query(
    symbols: string[],
    startTime: Date | string = new Date(), // eslint-disable-line default-param-last
    endTime: Date | string = new Date(), // eslint-disable-line default-param-last
    bucket: GWMarketDataQueryBucket,
    queryCase: GWQueryCase,
    topic?: string,
  ) {
    if (GW_ENABLED.login && GW_ENABLED.query) {
      GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataQuery, {
        symbols,
        bucket: bucket || GWMarketDataQueryBucket.OneDay,
        endTime,
        startTime,
      }, queryCase);
    } else {
      // TODO: Remove this else when fully migrated to Gateway
      if (!GlobalService.marketDataService) {
        console.warn("[GlobalService] Using 'query' while MD is not initialized.");
        return;
      }

      // TODO: Remove this else when fully migrated to Gateway
      let bucketAsString: string = '1day';
      switch (bucket) {
        case GWMarketDataQueryBucket.OneDay: break;
        case GWMarketDataQueryBucket.OneSecond: bucketAsString = '1sec'; break;
        case GWMarketDataQueryBucket.OneMinute: bucketAsString = '1min'; break;
        default: console.error(`[GlobalService] Unknown bucket '${bucket})' for '${topic}' query and symbols=${symbols}`);
      }

      GlobalService.marketDataService.query(
        topic ?? '',
        symbols,
        startTime,
        endTime,
        bucketAsString,
        queryCase,
      );
    }
  }

  /**
   * Request for subscribing a symbol for price updates.
   * Instrument Snapshot is used as a starting price.
   * @param symbols Symbols to subscribe
   * @param conflate An option for the request (usually true)
   */
  // eslint-disable-next-line default-param-last
  subscribe(symbols: string[], feed: MDSFeed = 'trade', conflate: boolean, caller: string) {
    let traceId = '';

    if (__DEV__ && logConfig.subscriptionsFlow) {
        console.tron.log!(`+ [API-Call-Summary-Subscribe] -- symbols: ${symbols.length} -- feed: ${feed} [s-flow]`);
        console.tron.log!(`+ [API-Call-Detailed-Subscribe] -- symbols: ${symbols.length} | ${symbols} -- feed: ${feed} [s-flow]`);
    }
    const USE_SEPARATE_CALLS = true;
    if (GW_ENABLED.login && GW_ENABLED.subscribe) {
      GlobalService.globalService.getInstrumentSnapshot(symbols, caller, true, true);
      let requestType: GWRequestType | null = null;
      switch (feed) {
        case 'trade':
          requestType = GWRequestType.MarketDataSubscribeTrade;
          break;
        case 'nbbo':
          requestType = GWRequestType.MarketDataSubscribeNbbo;
          break;
        default:
          break;
      }
      if (requestType) {
        traceId = GlobalService.gatewayWs.sendRequest(requestType, { symbols });
      } else if (USE_SEPARATE_CALLS) {
        GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataSubscribeNbbo, { symbols });
        traceId = GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataSubscribeTrade, { symbols });
      } else {
        traceId = GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataSubscribe, { symbols });
      }
    } else { // TODO: Remove this else when fully migrated to Gateway
      if (!GlobalService.marketDataService) {
        console.warn("[GlobalService] Using 'subscribe' while MD is not initialized.");
        return '';
      }
      // Gateway subscribes these two together.
      // Direct MDS connection is not supported any more (last used only for debugging)
      // but last known version had them as separate topic calls - see below:
      GlobalService.marketDataService.subscribe(feed as any, symbols, conflate);
    }

    CallsCache.processRequest('subscribe', symbols.join(), () => traceId);

    return traceId;
  }

  unsubscribe(symbols: string[], feed: MDSFeed = 'trade') {
    if (__DEV__ && logConfig.subscriptionsFlow) {
      console.tron.log!(`- [API-Call-Summary-UNSubscribe] -- symbols: ${symbols.length} -- feed: ${feed} [s-flow]`);
      console.tron.log!(`- [API-Call-Detailed-UNSubscribe] -- symbols: ${symbols.length} | ${symbols} -- feed: ${feed} [s-flow]`);
    }
    if (GW_ENABLED.login && GW_ENABLED.subscribe) {
      let requestType = GWRequestType.MarketDataUnsubscribe;
      switch (feed) {
        case 'trade':
          requestType = GWRequestType.MarketDataUnsubscribeTrade;
          break;
        case 'nbbo':
          requestType = GWRequestType.MarketDataUnsubscribeNbbo;
          break;
        default:
          break;
      }
      GlobalService.gatewayWs.sendRequest(requestType, { symbols });
    } else {
      if (!GlobalService.marketDataService) {
        console.warn("[GlobalService] Using 'unsubscribe' while MD is not initialized.");
        return;
      }

      // Gateway unsubscribes these two together.
      // Direct MDS connection is not supported any more (last used only for debugging)
      // but last known version had them as separate topic calls - see below:
      GlobalService.marketDataService.unsubscribe(feed as any, symbols);
    }
  }

  newsSubscribe(symbol: NullableString) {
    const isGWEnabled = GW_ENABLED.login;
    const isGWNewsSubscribeEnabled = GW_ENABLED.newsSubscribe;

    if (isGWEnabled && isGWNewsSubscribeEnabled) {
      GlobalService.gatewayWs.sendRequest(GWRequestType.NewsSubscribe, {
        symbols: symbol || '',
      }, GWQueryCase.NewsSubscribe);
    } else if (!isGWEnabled) {
      console.warn("[GlobalService] No implementation to run for 'newsSubscribe' since Gateway is disabled.");
    } else if (!isGWNewsSubscribeEnabled) {
      console.warn("[GlobalService] Subscribing to News is disabled (see configLib - 'newsSubscribe').");
    }
  }

  newsUnSubscribe(symbol: NullableString) {
    const isGWEnabled = GW_ENABLED.login;
    const isGWNewsSubscribeEnabled = GW_ENABLED.newsSubscribe;

    if (isGWEnabled && isGWNewsSubscribeEnabled) {
      GlobalService.gatewayWs.sendRequest(GWRequestType.NewsUnsubscribe, {
        symbols: symbol || '',
      }, GWQueryCase.NewsUnSubscribe);
    } else if (!isGWEnabled) {
      console.warn("[GlobalService] No implementation to run for 'newsUnSubscribe' since Gateway is disabled.");
    } else if (!isGWNewsSubscribeEnabled) {
      console.warn("[GlobalService] UnSubscribing from News is disabled (see configLib - 'newsSubscribe').");
    }
  }

  getNews(symbol: NullableString, pageSize?: number, pageNumber?: number, rxJsData?: RxJsData) {
    const isGWEnabled = GW_ENABLED.login;
    const isGWNewsEnabled = symbol == null ? GW_ENABLED.latestNews : GW_ENABLED.newsForSymbol;
    const { action } = rxJsData || { data: [] }; // TODO: Remove if not needed

    if (isGWEnabled && isGWNewsEnabled) {
      let messageType = symbol ? GWRequestType.GetLatestNewsArticlesBySymbols : GWRequestType.GetLatestNewsArticles;
      const { gwType } = action?.payload || {};
      if (gwType != null) messageType = gwType;

      let symbolData: any = { symbols: symbol };
      if (messageType === GWRequestType.GetNewsArticles) {
        symbolData = { symbol };
      }

      GlobalService.gatewayWs.sendRequest(messageType, {
        pageSize,
        pageNumber,
        ...symbolData,
      }, GWQueryCase.News);
    } else {
      GlobalService.newsService.getNews(symbol, pageSize, pageNumber, rxJsData);
    }
  }

  getSymbolDetailsChartData(
    symbolOrSymbols: string,
    bucket?: GWMarketDataSummaryBucket | null,
    queryCase?: GWQueryCase,
  ) {
    const symbols = symbolOrSymbols.split(',');
    const component: OrderTicketComponent = 'symbol-details';

    if (bucket) {
      return this.loadChartData(symbols, component, bucket, 'getSymbolDetails');
    }

    [
      GWMarketDataSummaryBucket.OneDay,
      GWMarketDataSummaryBucket.OneWeek,
      GWMarketDataSummaryBucket.OneMonth,
      GWMarketDataSummaryBucket.ThreeMonths,
      GWMarketDataSummaryBucket.OneYear,
      GWMarketDataSummaryBucket.FiveYears,
    ].forEach((currentBucket, index) => {
      setTimeout(() => {
        this.loadChartData(symbols, component, currentBucket, 'getSymbolDetails-forEach');
      }, 10 * index);
    });

    return '';
  }

  private loadChartData(
    symbols: string[],
    component: OrderTicketComponent,
    bucket?: GWMarketDataSummaryBucket,
    caller?: string,
  ) {
    const isSmallChart = component !== 'symbol-details';
    const queryCase = isSmallChart ? GWQueryCase.SmallChartData : GWQueryCase.BigChartData;
    const hasNoLineCloseForBigChart = (
      bucket === '1D5m'
      && !isSmallChart
      && !TradePriceCache.get(symbols[0])?.priceData?.lineClose
    );
    let hasToSkip3MCall = !!bucket && !hasNoLineCloseForBigChart;

    // 1D5m or custom bucket
    const traceId = GlobalService.gatewayWs.sendRequest(
      GWRequestType.MarketDataSummary,
      {
        symbols,
        bucket: bucket ?? GWMarketDataSummaryBucket.OneDay,
      },
      queryCase,
      caller,
    );
    if (hasToSkip3MCall) return traceId;

    // 3M1D - temporary patch for previousClose/lineClose and chart color in some market states. Data is not available on Instrument Snapshot
    GlobalService.gatewayWs.sendRequest(
      GWRequestType.MarketDataSummary,
      {
        bucket: GWMarketDataSummaryBucket.ThreeMonths,
        symbols,
      },
      queryCase,
      caller,
    );

    return traceId;
  }

  getWatchlistChartData(symbolOrSymbols: string, component: OrderTicketComponent, bucket?: GWMarketDataSummaryBucket) {
    const symbols = symbolOrSymbols?.split(',');

    if (BATCH_LOAD_WATCHLST_CHART) {
      return this.loadChartData(symbols, component, undefined, 'getWatchlistData');
    }

    symbols?.forEach(symbol => this.loadChartData([ symbol ], component, undefined, 'getWatchlistData-forEach'));
    return null;
  }

  order(action: 'neworder' | 'modifyOrder' | 'cancel', data: Order | ModifyOrCancelOrderRequest) {
    let isGWCall = (SOR_ENABLED as any)[action];
    let messageType: GWRequestType | null = null;

    if (isGWCall) {
      let orderData = {};
      messageType = (data as Order)?.requestType ?? (data as ModifyOrCancelOrderRequest)?.order?.requestType ?? null;

      switch (messageType) {
        case GWRequestType.NewOrderRequest:
          orderData = Object.assign(setSOROrderData(data), {
            capacity: 'Agency',
            status: 'New',
            messageType,
            dateTime: new Date().toISOString(),
          });
          break;
        case GWRequestType.ModifyOrderRequest:
          orderData = {
            ...setSOROrderData(data),
            originalClientOrderId: data.originalClientOrderId,
            messageType,
          };
          break;
        case GWRequestType.CancelOrderRequest:
          orderData = { ...data };
          break;
        default:
          console.error(`[Global.service] order - unknown order request - ${messageType}`);
          break;
      }
      OrderInfoCache.push(orderData); // this is just for debug
      GlobalService.gatewayWs.sendRequest(messageType!, orderData, GWQueryCase.Order);
    } else {
      const { order, account } = (data as ModifyOrCancelOrderRequest) ?? {};
      // TODO: Implement this else if needed - the goal is to use SOR only.
      //       In this else case - direct OMS connection through Trading API is used.
      //       Since it is not intended to be supported, it is recommended to use tapi-public instead in case
      //       Trading API connection is needed.
      switch (action) {
        case 'neworder':
          GlobalService.tradeService.newOrder(data);
          break;
        case 'modifyOrder': {
          GlobalService.tradeService.modifyOrder(data ?? order, account);
          break;
        }
        case 'cancel': {
          GlobalService.tradeService.cancelOrder(order, account);
          break;
        }
        default:
          break;
      }
    }
  }

  /**
   * @param symbolOrSymbolsSymols {string|string[]} for which to get Instrument Snapshot. If a string - one symbol, if array of string - one or many.
   * @param caller {string} Where is this requested from - for example an epic
   * @param includePreMarket {boolean} A parameter for the call - usually used as `true`
   * @param includePostMarket {boolean} A parameter for the call - usually used as `true`
   * @param forceRefresh {boolean} If `true` - will request data for all symbols, without checknig cache
   */
  getInstrumentSnapshot(
    symbolOrSymbols: string | string[],
    caller: string,
    includePreMarket?: boolean,
    includePostMarket?: boolean,
    forceRefresh?: boolean,
  ) {
    const requestedSymbols: string[] = isString(symbolOrSymbols) ? [ symbolOrSymbols ] : symbolOrSymbols as any;

    const symbols = (
      forceRefresh || !CACHED_MARKET_DATA_CALLS.instrumentSnapshot
        ? requestedSymbols
        : InstrumentSnapshotCache.getOnlyNew(requestedSymbols)
    );

    if (symbols.length === 0) {
      console.warn(`[GlobalService] getInstrumentSnapshot(${requestedSymbols}) - skipping call because symbols are already cached - requested:${requestedSymbols.length} new:${symbols.length}`);
      return;
    }

    const requestForInstrumentSnapshotReturnsTraceId = () => (
      GlobalService.gatewayWs.sendRequest(
        GWRequestType.InstrumentSnapshot,
        {
          symbols,
          includePreMarket,
          includePostMarket,
        },
        GWQueryCase.InstrumentSnapshot,
        caller,
      )
    );

    const cacheParams = symbols.join();
    CallsCache.processRequest('getInstrumentSnapshot', cacheParams, requestForInstrumentSnapshotReturnsTraceId);
  }

  /* Subscibe for symbol status. It can be halted or allowed for trading */
  subscribeSymbolStatus = (symbols: string[]) => {
    GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataSubscribeSymbolStatus, { symbols });
  };

  /* Unsubscibe from symbol status updates */
  unsubscribeSymbolStatus = () => {
    const symbolStatus = SymbolStatusCache.getAll();
    let symbols: string[] = [];
    if (symbolStatus) {
      symbols = Object.keys(symbolStatus);
    }

    if (symbols.length) {
      GlobalService.gatewayWs.sendRequest(GWRequestType.MarketDataUnsubscribeSymbolStatus, { symbols });
      SymbolStatusCache.clear();
    }
  };
}


export default GlobalService;
