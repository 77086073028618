import React from 'react';
import classNames from 'classnames';

import { checkPricesValid } from '../../../../../lib/store/market-data/charting/helpers';
import MarketStateCache from '../../../../../lib/store-util/MarketStateCache';
import { CalculatedSymbolPrices, checkHideExtraPriceInfoInChartHeader } from '../../../../../lib/util/ChartingHelpers';

import useIsMobile from '../../../../../hooks/useIsMobile';
import { getChangeStyle } from '../../../getChangeStyle';
import { priceChange } from '../helpers';

import InfoStatus from './InfoStatus';

import styles from '../ChartHeader.module.scss';

const ExtraPriceInfo = (props: CalculatedSymbolPrices): JSX.Element | null => {
  const {
    currentExtraPrice,
    changeExtraPrice,
    changedPercentExtraPrice,
    extraPriceDirection,
    hasAfterMarketData,
  } = props;
  const { marketState } = MarketStateCache.use();
  const isMobile = useIsMobile();

  if (checkHideExtraPriceInfoInChartHeader(marketState, hasAfterMarketData)) return null;

  const noData = !checkPricesValid(currentExtraPrice, changeExtraPrice, changedPercentExtraPrice);
  const changeStyle = getChangeStyle(extraPriceDirection);
  const priceChangeStyle = classNames({}, {
    [styles.priceChangeAndPercentStyleHidden]: noData,
  });
  const containerStyle = classNames(
    styles.extraPriceContainer,
    { [styles.extraPriceContainerHidden]: noData },
  );

  return (
    <div className={classNames(styles.containerStyle, { [styles.mobileStyleExtraPrice]: isMobile })}>
      <span className={priceChangeStyle} style={changeStyle}>
        { priceChange(changeExtraPrice, changedPercentExtraPrice) }
      </span>
      <InfoStatus type={'close'} />
    </div>
  );
};

export default ExtraPriceInfo;
