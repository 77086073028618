import { CurrencyEnum } from '../../../lib/enums';

import { IRadioGroupOption } from '../../RadioGroup/RadioGroup.types';

export const KEY_EMPLOYMENT_STATUS_ID = 'enroll-employment-status-id';
export const KEY_EMPLOYMENT_STATUS_BUSINESS_ID = 'enroll-employment-status-business-id';

export const KEY_SOURCE_OF_FUND_ID = 'enroll-source-of-fund-id'; // id
export const KEY_SOURCE_OF_FUND_FROM = 'enroll-source-of-fund-from'; // from
export const KEY_SOURCE_OF_FUND_TO = 'enroll-source-of-fund-to'; // to
export const KEY_SOURCE_OF_FUND_IDENTITY_NAME = 'enroll-source-of-fund-identity-name'; // name
export const KEY_SOURCE_OF_FUND_TYPE = 'enroll-source-of-fund-fund-type'; // type
export const KEY_SOURCE_OF_FUND_ADDRESS = 'enroll-source-of-fund-address'; // address
export const KEY_SOURCE_OF_FUND_HAS_MAIN = 'enroll-source-of-fund-has-main-customer'; // has main
export const KEY_SOURCE_OF_FUND_SPECIFY_TYPE = 'enroll-source-of-fund-specify-type'; // specify
export const KEY_SOURCE_OF_FUND_BIRTH = 'enroll-source-of-fund-specify-type'; // birth
export const KEY_SOURCE_OF_FUND_YEAR = 'enroll-source-of-fund-specify-type'; // year

export const FinancialQuestionsCurrencyOptions: IRadioGroupOption[] = [
  {
    label: 'Euro',
    value: CurrencyEnum.EUR,
    id: 'Euro',
    ariaLabel: 'Euro',
  },
  {
    label: 'USD',
    value: CurrencyEnum.USD,
    id: 'USD',
    ariaLabel: 'USD',
  },
];
