import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useLocation } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { displayDateFormatNoDay, MARKET_DATA_DATE } from '../../lib/constants/date-time.constants';
import lang from '../../lib/language';
import { convertDateToFormatedString, datesDifference, getDateTime } from '../../lib/util/DateTimeHelpers';

import CalendarIcon from '../../assets/img/icon-calendar.svg';
import ClearIcon from '../../assets/img/icon-clear.svg';
import { useBrowserWidth, useIsMobile } from '../../hooks';
import { DateRangeState } from '../../util/types';
import { ClickableDiv, ClickableImage } from '../index';

import './calendarOverride.scss';
import styles from './MobileFromToDatePicker.module.scss';

type Props = {
  label: string;
  minDateState?: string;
  maxDateState?: string;
  minDateCalendar?: Date | string;
  maxDateCalendar?: Date | string;
  initialState: DateRangeState;
  onDateSelect?: (date: DateRangeState) => void;
}

const DateRangePickerWrapper = ({
  label,
  minDateState,
  maxDateState,
  minDateCalendar,
  maxDateCalendar,
  initialState,
  onDateSelect,
}: Props) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const { browserWidthSize } = useBrowserWidth();

  const [dateRangeState, setDateRangeState] = useState<DateRangeState>(
    {
      startDate: minDateState ? new Date(minDateState) : initialState?.startDate,
      endDate: maxDateState ? new Date(maxDateState) : initialState?.endDate,
      key: 'selection',
    },
  );
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
  const [inputValueStartDate, setInputValueStartDate] = useState<string | undefined>(
    minDateState ?? moment(initialState?.startDate).format(MARKET_DATA_DATE),
  );
  const [inputValueEndDate, setInputValueEndDate] = useState<string | undefined>(
    maxDateState ?? moment(initialState?.endDate).format(MARKET_DATA_DATE),
  );

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isDatesSelected, setIsDatesSelected] = useState<boolean>(false);
  const [paperPopover, setPaperPopover] = useState<number>(90);

  const selectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValueFrom = '';
    let inputValueTo = '';
    if (startDate) inputValueFrom += moment(startDate).format(MARKET_DATA_DATE);
    if (endDate) inputValueTo += moment(endDate).format(MARKET_DATA_DATE);

    if (inputValueFrom !== inputValueTo) {
      setDisplayCalendar(false);
      setIsDatesSelected(true);
      setAnchorEl(null);
      onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueEndDate });
    }

    setErrorMessage('');
    setInputValueStartDate(inputValueFrom);
    setInputValueEndDate(inputValueTo);
    setDateRangeState({ startDate, endDate, key: selection.key });
  };

  const popoverClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const openDateRangeCalendar = (e) => {
    setAnchorEl(e.currentTarget);
    setDisplayCalendar(true);
  };

  const monthChange = (value) => {
    const element = document.getElementById('dateRangeWrapper');
    let diffMaxDate = 1;
    let diffMinDate = 1;
    const localizedValue = getDateTime(false, ['local'], [ [], [] ], value);
    if (maxDateCalendar) {
      diffMaxDate = datesDifference(localizedValue, getDateTime(false, ['local'], [ [], [] ], maxDateCalendar), 'months');
    }
    if (minDateCalendar) {
      diffMinDate = datesDifference(localizedValue, getDateTime(false, ['local'], [ [], [] ], minDateCalendar), 'months');
    }

    diffMaxDate < 1 ? element!.classList.add('disabledRigthArrow') : element!.classList.remove('disabledRigthArrow');
    diffMinDate < 1 ? element!.classList.add('disabledLeftArrow') : element!.classList.remove('disabledLeftArrow');
  };

  const checkDates = () => {
    const element = document.getElementById('dateRangeWrapper');
    const { startDate, endDate } = dateRangeState;
    let diffMaxDate = 1;
    let diffMinDate = 1;

    if (startDate && minDateCalendar) {
      diffMinDate = datesDifference(
        getDateTime(false, ['local'], [ [], [] ], startDate),
        getDateTime(false, ['local'], [ [], [] ], minDateCalendar),
        'months',
      );
    }
    if (endDate && maxDateCalendar) {
      diffMaxDate = datesDifference(
        getDateTime(false, ['local'], [ [], [] ], endDate),
        getDateTime(false, ['local'], [ [], [] ], maxDateCalendar),
        'months',
      );
    }

    diffMaxDate < 1 ? element!.classList.add('disabledRigthArrow') : element!.classList.remove('disabledRigthArrow');
    diffMinDate < 1 ? element!.classList.add('disabledLeftArrow') : element!.classList.remove('disabledLeftArrow');
  };

  const chosenRange = () => {
    onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueEndDate });
  };

  useEffect(() => {
    setPaperPopover(90 - Math.floor(window.innerWidth / 10));
  }, [browserWidthSize]);

  const clearDates = () => {
    setErrorMessage('');
    setInputValueEndDate(moment(initialState?.endDate).format(MARKET_DATA_DATE));
    setInputValueStartDate(moment(initialState?.startDate).format(MARKET_DATA_DATE));
    setIsDatesSelected(false);
    setDateRangeState((prevState) => ({
      ...prevState,
      startDate: initialState?.startDate,
      endDate: initialState?.endDate,
    }));
    onDateSelect && onDateSelect({
      startDate: moment(initialState?.startDate).format(MARKET_DATA_DATE),
      endDate: moment(initialState?.endDate).format(MARKET_DATA_DATE),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames({
        [styles.inputWrapper]: isDatesSelected === false,
        [styles.inputWrapperSelected]: isDatesSelected === true,
      })}
      >
        <ClickableDiv className={styles.date} onClick={openDateRangeCalendar}>
          <img
            src={CalendarIcon}
            alt="Calendar"
          />
          <div className={styles.dateText}>
            { isDatesSelected && inputValueEndDate && inputValueStartDate
              ? (
                convertDateToFormatedString(inputValueStartDate).concat(' - ', convertDateToFormatedString(inputValueEndDate))
              )
              : label}
          </div>
        </ClickableDiv>
        { isDatesSelected && inputValueEndDate && inputValueStartDate && (
          <ClickableImage src={ClearIcon} onClick={clearDates} />
        )}
      </div>
      {errorMessage && <p className={styles.errorMsg}>{errorMessage}</p>}
      <Popover
        anchorReference={isMobile ? 'anchorPosition' : undefined}
        anchorPosition={isMobile ? { top: window.innerHeight / 2, left: window.innerWidth / 2 } : undefined}
        classes={{ paper: classNames(styles.paper, { [styles.paperMobile]: isMobile }) }}
        style={{
          backgroundColor: 'rgba(45, 45, 45, 0.9)',
          zIndex: 4000,
        }}
        open={displayCalendar}
        className={styles.popover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        TransitionProps={{ onEntered: checkDates, onExit: chosenRange }}
        onClose={popoverClose}
      >
        <div id="dateRangeWrapper" className={styles.dateRange}>
          <DateRange
            className={styles.dateRangePicker}
            onChange={selectDateRanges}
            onShownDateChange={monthChange}
            anchorEl={anchorEl}
            minDate={minDateCalendar}
            maxDate={maxDateCalendar}
            ranges={[dateRangeState]}
            showDateDisplay={false}
            monthDisplayFormat={displayDateFormatNoDay}
            rangeColors={['#1D3960', '#00FF00']}
            weekdayDisplayFormat={'eeeeee'}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DateRangePickerWrapper;
