import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { CallStatus } from '../../lib/enums';
import lang from '../../lib/language';
import { RootState } from '../../lib/store/index';
import { PaymentCall } from '../../lib/store/payment/types';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../lib/store/reporting/constants';
import { getAccountSummary, getAccountTradeHistory, getOpenPositions } from '../../lib/store/reporting/index';
import { AccountTradeHistoryPayloadData } from '../../lib/store/reporting/types';
import { getCurrentAccount, isUserVerifiedStatus } from '../../lib/store/selectors';
import { getBuyingPower } from '../../lib/store/trading/index';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import { BaseModal, Button, NotVerifiedNotification, SymbolDetailsNotActiveBox } from '../../components';
import Chart from '../../components/Chart/NetComponentsChart/Chart';
import OrderResponseManager from '../../components/OrderResponseManager/OrderResponseManager';
import { useBrowserWidth, useIsMobile } from '../../hooks';
import { BASE_WIDTH_SYMBOL_DETAILS } from '../../util/constants';

import CashPortfolioChart from './CashPortfolioChart/CashPortfolioChart';
import AccountBreakDown from './TableContent/MyAccountTableContent';

import styles from './MyAccount.module.scss';

type Props = ReduxProps;

const MyAccount = (props: Props) => {
  const {
    accountReferenceId,
    individualData,
    hasDeposit,
    isUserVerified,
    isPaymentsReady,
    openPositions,
    transactions,
    getTradeHistory,
    getPositions,
    getSummary,
    fetchBuyingPowerAndPutGlobalState,
  } = props;

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenPieChartModal, setIsOpenPieChartModal] = useState<boolean>(false);
  const [isUserReadyToTrade, setIsUserReadyToTrade] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const { browserWidthSize } = useBrowserWidth();
  const isSmallWebScreen = browserWidthSize <= BASE_WIDTH_SYMBOL_DETAILS;

  useEffect(() => {
    if (accountReferenceId) {
      getPositions();
      getTradeHistory({ tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, rowsPerPage: 30, isPendingOrdersCall: true });
      getSummary();
      setIsInitialized(true);
    }
  }, [accountReferenceId, getPositions, getSummary, getTradeHistory]);

  useEffect(() => {
    manageSubscriptions('my-account', null, true, true);
  }, []);

  useEffect(() => {
    if ((!isUserVerified || isPaymentsReady) && !hasDeposit && individualData) {
      setIsUserReadyToTrade(false);
    }
  }, [isUserVerified, isPaymentsReady, hasDeposit, individualData]);

  useEffect(() => {
    if (accountReferenceId) {
      fetchBuyingPowerAndPutGlobalState();
    }
  }, [accountReferenceId]);// eslint-disable-line react-hooks/exhaustive-deps

  const handlePopUp = (value: boolean) => {
    setIsOpenPieChartModal(value);
  };

  const renderCashPortfolioChart = (): JSX.Element => {
    if (isUserReadyToTrade) {
      return isSmallWebScreen ? (
        <BaseModal
          customStyle={{
            paddingBottom: 90,
            margin: 0,
            width: window.innerWidth,
            marginTop: window.innerWidth / 2.5,
            position: 'fixed',
            alignSelf: 'flex-end',
          }}
          className={styles.pieChartModalWrpper}
          isOpen={isOpenPieChartModal}
          toggle={() => handlePopUp(false)}
        >
          <h6 className={styles.pieChartModalTitle}>{lang.commonMyAccountButtonAccountBreakdown()}</h6>
          <div className={styles.pieChartModalContent}>
            <CashPortfolioChart isSmallScreen={browserWidthSize <= BASE_WIDTH_SYMBOL_DETAILS} />
          </div>
          <Button onClick={() => handlePopUp(false)}>
            {lang.commonClose()}
          </Button>
        </BaseModal>
      ) : (
        <div className={styles.chashBalance}>
          <CashPortfolioChart />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.myAccountWrapper}>
      <OrderResponseManager />
      {!isMobile && <h6 className={styles.title}>{lang.commonMyAccount()}</h6>}
      <div className={styles.wrapperLayout}>
        <div className={styles.wrapperFirstSection}>
          { isSmallWebScreen
            && individualData
            && transactions.length === 0
            && (
            <div className={styles.notVerified}>
              <NotVerifiedNotification
                individual={individualData}
                hasUserName={false}
                isPaymentsReady={isPaymentsReady}
              />
            </div>
            )}
          <div className={styles.wrapperAccount}>
            <Chart
              isSmallScreen={isSmallWebScreen}
              handleChartPopUp={(value) => handlePopUp(value)}
            />
          </div>
        </div>
        <div className={styles.wrapperSecondSection}>
          {!isUserReadyToTrade && !isSmallWebScreen
            && <SymbolDetailsNotActiveBox className={['d-none', 'd-lg-block', styles.notVerified].join(' ')} />}
          {renderCashPortfolioChart()}
        </div>
      </div>
      <AccountBreakDown isMyAccountInitiliazed={isInitialized} isUserVerified={isUserReadyToTrade} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountReferenceId: getCurrentAccount(state),
  individualData: state.crm.individualExtendedInfo,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  openPositions: state.reporting.openPositions,
  hasDeposit: state.payment.hasDeposit,
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
  isPaymentsReady: state.payment.statusByCall[PaymentCall.getPaymentsAll]?.status === CallStatus.READY,
  transactions: state.payment.paymentsAll.data,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getTradeHistory: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getPositions: () => dispatch(getOpenPositions()),
  getSummary: () => dispatch(getAccountSummary()),
  fetchBuyingPowerAndPutGlobalState: () => dispatch(getBuyingPower()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MyAccount);
