import { useRef } from 'react';

import TradePriceCache from '../../store-util/TradePriceCache';
import { NullableNumber, NullableString } from '../types';

import { useInterval } from './TimeoutHooks';


const MAX_RETRIES_LINE_CLOSE = 5;
const LINE_CLOSE_RETRIES_DELAY = 1000;


export const useLineClose = (
  symbol: NullableString,
  initialLineCloseValue: NullableNumber,
  setLineClose: (value: NullableNumber) => void,
  caller: string,
  retriesCount = MAX_RETRIES_LINE_CLOSE,
  retriesDelay = LINE_CLOSE_RETRIES_DELAY,
) => {
  const ref = useRef({
    timeout: retriesDelay as number | null,
    value: initialLineCloseValue,
    count: 0,
  });
  useInterval(
    () => {
      const { timeout, value } = ref.current;

      ref.current.count++;
      if (value == null && symbol) {
        const currentValue = TradePriceCache.get(symbol).priceData.lineClose;

        if (currentValue != null) {
          ref.current.timeout = null;
          ref.current.value = currentValue;
          setLineClose(currentValue);
        }
      }
      if (ref.current.count === retriesCount || !symbol) {
        ref.current.timeout = null;
        setLineClose(NaN); // forces a render to stop the interval if symbol or retriesCount are not defined
      }
    },
    ref.current.value && symbol ? null : ref.current.timeout,
    caller,
  );
};
