import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history'; // eslint-disable-line

import lang from '../../lib/language';
import { ENROLL_REQUEST_STATE } from '../../lib/models/enroll';
import { ENROLL_AUTH_REQUEST_TYPE, REQUEST_CALL_NAME_CONFIRM_EMAIL, REQUEST_CALL_NAME_GET_MANUAL_TOKEN } from '../../lib/models/enroll-requests';
import { RootState } from '../../lib/store';
import { enrollAuth } from '../../lib/store/auth/index';
import { login as serviceLogin } from '../../lib/store/common-actions';
import { parseOnboardingErrorCode } from '../../lib/store/crm/helpers';
import { useInterval } from '../../lib/util/hooks/TimeoutHooks';

import allInOneAppWebImage from '../../assets/bigImages/all_in_one_app_web.png';
import portfolioWebImage from '../../assets/bigImages/portfolio_web.png';
import worldOfTradingWebImage from '../../assets/bigImages/world_of_trading_web.png';
import HammerLiteImage from '../../assets/img/hammer_lite-light.png';
import Button from '../../components/Button/Button';
import { ButtonTypesEnum } from '../../components/Button/Button.types';
import EmailConfirmedModal from '../../components/Enroll/EmailConfirmedModal/EmailConfirmedModal';
import ExpiredVerificationModal from '../../components/Enroll/ExpiredVerificationModal/ExpiredVerificationModal';
import LoginCarousel from '../../components/LoginCarousel/LoginCarousel';
import config from '../../config';

import styles from './Login.module.scss';

const images = [allInOneAppWebImage, worldOfTradingWebImage, portfolioWebImage];

type Props = ReduxProps;

const Login = (props: Props): JSX.Element => {
  const {
    login, appLoaded, accessToken, responseStatus, responseStatusCode, requestCallName, tradingPlatformId, authenticate,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const [emailConfirmedModal, setEmailConfirmedModal] = useState<boolean>(false);
  const [isVerifyLinkExpired, setIsVerifyLinkExpired] = useState<boolean>(false);
  const [currentIndexImage, setCurrentIndexImage] = useState<number>(0);

  useEffect(() => {
    if (location.search?.match(/token[=]/) && appLoaded) {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams) {
        authenticate(
          queryParams.get('Email'),
          queryParams.get('token'),
          queryParams.get('FirstName'),
          queryParams.get('LastName'),
          queryParams.get('CountryOfResidence'),
        );
      }
    }
  }, [appLoaded, authenticate, location.search]);

  useEffect(() => {
    if (accessToken && tradingPlatformId) {
      setEmailConfirmedModal(true);
    }
  }, [accessToken, tradingPlatformId]);

  useEffect(() => {
    if (
      responseStatus
      && responseStatus === ENROLL_REQUEST_STATE.ERROR
      && parseOnboardingErrorCode(responseStatusCode!) === ENROLL_REQUEST_STATE.REQUEST_ERROR
      && (requestCallName === REQUEST_CALL_NAME_CONFIRM_EMAIL || requestCallName === REQUEST_CALL_NAME_GET_MANUAL_TOKEN)
    ) {
      setIsVerifyLinkExpired(true);
    }
  }, [requestCallName, responseStatus, responseStatusCode]);

  useInterval(() => {
    currentIndexImage === images.length - 1
      ? setCurrentIndexImage(0)
      : setCurrentIndexImage(currentIndexImage + 1);
  }, 5000);

  return (
    <>
      <div className={styles.wrapperContainer}>
        <div className={styles.containerLogin}>
          <div className={styles.logo}>
            <img src={HammerLiteImage} alt="Hammer Lite" />
          </div>
          <div className={styles.carousel}>
            <LoginCarousel />
          </div>
          <div className={styles.buttonsAndPolicies}>
            <Button
              className={styles.loginBtn}
              variant={ButtonTypesEnum.Primary}
              id="loginButton"
              fullWidth
              onClick={() => login(history)}
            >
              {lang.mobileOnboardingLogIn()}
            </Button>
            <a href={config.links.signUpUrl}>
              <Button
                className={styles.signUpBtn}
                variant={ButtonTypesEnum.Tertiary}
                id="signUpButton"
                fullWidth
              >
                {lang.mobileOnboardingSignUp()}
              </Button>
            </a>
            <div className={styles.policies}>
              {lang.hammerLoginPolicyAgreement()}
              <a href={config.links.privacyPolicyUrl} className={styles.link}>
                {lang.commonOnboardingSignInPrivacyPolicy()}
              </a>
              {lang.commonAnd()}
              <a href={config.links.cookiesPolicyUrl} className={styles.link}>
                {lang.commonOnboardingSignInCoockiePolicy()}
              </a>
            </div>
          </div>
        </div>
        {emailConfirmedModal && <EmailConfirmedModal isOpened={emailConfirmedModal} />}
        {isVerifyLinkExpired && <ExpiredVerificationModal />}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  login: (history: History) => {
    dispatch(serviceLogin('login'));
  },
  authenticate: (
    email: string | null,
    token: string | null,
    firstName: string | null,
    lastName: string | null,
    country: string | null,
  ) => {
    dispatch(enrollAuth({
      requestType: ENROLL_AUTH_REQUEST_TYPE.CONFIRM_EMAIL, email, token, firstName, lastName, country,
    }));
  },
});

const mapStateToProps = (state: RootState) => ({
  appLoaded: state.app.loaded,
  accessToken: state.auth.enroll.accessToken,
  crmConnected: state.crm.connected,
  responseStatus: state.auth.enroll?.status,
  responseStatusCode: state.auth.enroll?.statusCode,
  requestCallName: state.auth.enroll?.requestCallName,
  tradingPlatformId: state.crm.tradingPlatformId,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Login);
