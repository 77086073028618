import React from 'react';

import lang from '../../../lib/language';

import leftArrow from '../../../assets/img/icon-arrow-left.svg';
import config from '../../../config';
import { ButtonTypesEnum } from '../../Button/Button.types';
import { Button, ClickableImage } from '../../index';

import styles from './EnrollLayout.module.scss';

type Props = {
  children?: JSX.Element;
  onContinueClicked: () => void;
  visibleContinueButton: boolean;
  onBackButtonClicked: () => void;
  title?: string;
  subTitle?: string;
  disabledSubmitButton?: boolean;
  secondaryRowContent?: JSX.Element;
  continueButtonText?: string;
  hiddenInlineHelp?: boolean;
  isTransitionScreen?: Boolean;
}

const EnrollLayout = (props: Props): JSX.Element => {
  const {
    title,
    children,
    subTitle,
    onContinueClicked,
    onBackButtonClicked,
    visibleContinueButton,
    disabledSubmitButton = false,
    secondaryRowContent,
    continueButtonText,
    hiddenInlineHelp,
    isTransitionScreen,
  } = props;

  const handleHelpBtn = () => {
    setTimeout(() => {
      window.open(config.links.helpCenterUrl, '_blank');
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={isTransitionScreen ? styles.transitionScreen : styles.noTransitionScreen}>
          <ClickableImage
            src={leftArrow}
            alt="left arrow pink"
            id="enrollmentBackArrow"
            aria-label="enrollment back arrow"
            className={styles.backButton}
            onClick={onBackButtonClicked}
          />
          <div>
            {title && <h3 id="enrollmentTitle" className={styles.title} aria-label="enrollment title">{title}</h3>}
            {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
            {children}
          </div>
        </div>
        <div>
          {secondaryRowContent}
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.actionsInner}>
            {visibleContinueButton && (
              <Button
                fullWidth
                id="enrollmentContinueBtn"
                ariaLabel={`enrollment ${continueButtonText ?? 'continue'}`}
                className={styles.continueButton}
                onClick={onContinueClicked}
                disabled={disabledSubmitButton}
              >
                {continueButtonText ?? lang.commonContinueButton()}
              </Button>
            )}
            {!hiddenInlineHelp && (
            <Button
              fullWidth
              id="contactUs"
              ariaLabel="contact us link"
              className={styles.helpButton}
              variant={ButtonTypesEnum.Tertiary}
              onClick={handleHelpBtn}
            >
              {lang.commonInlineHelpButton()}
            </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollLayout;
