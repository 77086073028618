import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import lang from '../../../lib/language';
import { IndividualExtendedInfoData } from '../../../lib/models/enroll';
import { RootState } from '../../../lib/store/index';

import CheckEmailImage from '../../../assets/img/image_checkemail.png';
import { Button } from '../../../components/index';

import styles from './ChangePassword.module.scss';

const ChangePasswordConfirmation = () => {
  const history = useHistory();
  const individual = useSelector<RootState, IndividualExtendedInfoData | null>(
    (state: RootState) => state.crm.individualExtendedInfo,
  );

  return (
    <div className={styles.confirmationWrapper}>
      <div>{lang.mobileChangePasswordModalText()}</div>
      <h5 className={styles.emailTitle}>{lang.mobilePasswordChangeEmailTitle()}</h5>
      <div className={styles.messageSentEmail}>
        <span>{lang.mobilePasswordChangeEmailUnderlineHeading1()}</span>
        <span className={styles.email}>{individual?.email}</span>
        <span>{lang.mobilePasswordChangeEmailUnderlineHeading2()}</span>
      </div>
      <Button fullWidth onClick={() => history.goBack()} className={styles.buttonConfirm}>
        {lang.commonOkay() }
      </Button>
    </div>
  );
};

export default ChangePasswordConfirmation;
