import React from 'react';
import { useHistory } from 'react-router-dom';

import { LinkedAccountStatusEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { LinkedAccount } from '../../lib/models/linked-account/types';

import iconAddNew from '../../assets/img/icon-add_new_no_background.svg';
import iconBank from '../../assets/img/icon-bank.svg';
import { ClickableDiv } from '../../components';
import { UI_ROUTES } from '../../util/routes';

import styles from './Funds.module.scss';

type Props = {
  linkedAccounts: LinkedAccount[];
  isUserVerified: boolean;
}

const LinkedAccountCard = (props: Props) => {
  const { linkedAccounts, isUserVerified } = props;

  const history = useHistory();

  const handleBtnAddAccount = () => {
    history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.addYourBankAccount,
      state: {
        previousPage: UI_ROUTES.funds },
    });
  };

  const handleCard = (linkedAccount: LinkedAccount) => (
    <div key={linkedAccount.id} className={styles.containerCardBankTransfer}>
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={iconBank} alt="icon bank" />
      </div>
      <div className={styles.wrapperInfo}>
        <div className={styles.statusBank}>
          <div>
            <p className={styles.transferText}>{linkedAccount.account_info.bank_name}</p>
            <p className={styles.iban}>
              {linkedAccount.account_info.iban
                ? linkedAccount.account_info.iban
                : linkedAccount.account_info.account}
            </p>
          </div>
          {linkedAccount.status === LinkedAccountStatusEnum.New
         && <div className={styles.pending}>{lang.commonOrderStatusPending()}</div>}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.wrapperSecondsSection}>
      <h6 className={styles.title}>{lang.mobileFundsScreenBankAccounts()}</h6>
      {
        isUserVerified && linkedAccounts.map(linkedAccount => {
          if (linkedAccount.status === LinkedAccountStatusEnum.Inactive) {
            return null;
          }
          return handleCard(linkedAccount);
        })
      }
      <ClickableDiv className={styles.btnAddBankAccount} onClick={handleBtnAddAccount}>
        <img src={iconAddNew} alt="icon small plus" />
        <span>{lang.mobileFundsScreenAddBankAccModalBtn()}</span>
      </ClickableDiv>
    </div>
  );
};

export default LinkedAccountCard;
