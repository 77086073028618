import React from 'react';

import styles from './NoResultsFound.module.scss';

type Props = {
  label?: string;
  fullText?: string;
}

const NoResultsFound = ({ label, fullText }: Props): JSX.Element => (
  <div className={styles.wrapper}>
    {
        fullText
          ? (
            <>
              <i className="fas fa-exclamation-circle" />
              <p>{fullText}</p>
            </>
          )
          : (
            <>
              <i className="fa fa-search" />
              <p>
                {'No '}
                {label != null ? <span className={styles.text}>{label}</span> : 'Results'}
                {' Found'}
              </p>
            </>
          )
      }
  </div>
);

export default NoResultsFound;
