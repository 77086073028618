import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { AddressTypeEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import {
  CountryData,
  ENROLL_REQUEST_STATE,
  IndividualExtendedInfo,
  IndividualExtendedInfoData,
} from '../../../lib/models/enroll';
import { ENROLL_AUTH_REQUEST_TYPE } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enrollAuth } from '../../../lib/store/auth';
import CountriesCache from '../../../lib/store-util/CountriesCache';
import { checkErrorSatusCode } from '../../../lib/util/error-handling/ErrorHandlingHelpers';
import { createFormControlError, getPhoneNumberValidity, validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import { PhoneAutocomplete, VerificationLayout } from '../../../components';
import { ICountryControlOptions } from '../../../components/CountryControl/CountryControl.types';
import {
  PhoneAutocompleteErrors,
  PhoneAutocompleteValue,
} from '../../../components/PhoneAutocomplete/PhoneAutocomplete.types';
import { UI_ROUTES } from '../../../util/routes';
import { PhoneNumberSelector } from '../Verification.constants';

type OwnProps = {};
type Props = OwnProps & ReduxProps;

const VerificationPhoneNumber = (props: Props) => {
  const { sendPhoneCode } = props;

  const history = useHistory();

  const requestState = useSelector<RootState, ENROLL_REQUEST_STATE | undefined>((state: RootState) => (
    state.auth.enroll.status
  ));
  const statusCode = useSelector<RootState, number | undefined>((state: RootState) => (
    state.auth.enroll.statusCode
  ));
  const phoneCode = useSelector<RootState, string | null | undefined>((state: RootState) => (
    state.auth.enroll.phoneCode
  ));
  const phoneNumber = useSelector<RootState, string | null | undefined>((state: RootState) => (
    state.auth.enroll.phoneNumber
  ));
  const individual = useSelector<RootState, IndividualExtendedInfoData | null>((state: RootState) => (
    state.crm.individualExtendedInfo
  ));

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [value, setValue] = useState<PhoneAutocompleteValue>({ phone: '', code: '', countryId: '' });
  const [errors, setErrors] = useState<PhoneAutocompleteErrors>({ phone: [], code: [] });
  const [hasMadeRequest, setHasMadeRequest] = useState<boolean>(false);

  const countries = CountriesCache.use();
  const options = useMemo((): ICountryControlOptions[] => {
    if (countries && (countries.length > 0)) {
      return countries
        .filter((country: CountryData) => country.id && country.name && country.phone_code)
        .sort((current: CountryData, next: CountryData) => {
          if (current.name.toLowerCase() < next.name.toLowerCase()) {
            return -1;
          } if (current.name.toLowerCase() > next.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((country: CountryData) => ({
          label: country.name,
          value: `${country.phone_code} ${country.name}`,
          countryId: country.id,
        }));
    }

    return [];
  }, [countries]);

  const onNextClick = useCallback(() => {
    const countryCode = value.code.split(' ')[0];
    sendPhoneCode(countryCode, value.phone);
    setHasMadeRequest(true);
  }, [sendPhoneCode, value]);

  const validateForm = useCallback((): boolean => {
    const codeCheck = validateControl(
      value.code,
      [],
      lang.commonOnboardingPhoneVerificationCountry(),
      true,
    );

    const phoneCheck = getPhoneNumberValidity(value.phone);

    if (!codeCheck.isValid) {
      setErrors((currState: PhoneAutocompleteErrors) => ({
        ...currState,
        code: codeCheck.errors,
      }));
    }

    if (!phoneCheck.isValid) {
      setErrors((currState: PhoneAutocompleteErrors) => ({
        ...currState,
        phone: phoneCheck.errors,
      }));
    }

    return (codeCheck.isValid && phoneCheck.isValid);
  }, [value]);

  useEffect(() => {
    if (!isInitialised) {
      if (phoneCode && phoneNumber) {
        const countryCodeValue = options.find(el => el.value.toString().includes(phoneCode))!.value.toString();

        setValue((currState: PhoneAutocompleteValue) => ({
          ...currState,
          phone: phoneNumber,
          code: countryCodeValue,
        }));
        setIsInitialised(true);
        return;
      }
      if (individual && (individual.addresses && individual.addresses.length > 0)) {
        const individualModel = new IndividualExtendedInfo(individual);
        const residentialAddress = individualModel.getAddressByType(AddressTypeEnum.Residential);

        const filteredOptions = options?.find((option: ICountryControlOptions) => (
          option.countryId === residentialAddress.country_id
        ));

        if (filteredOptions) {
          setValue((currState: PhoneAutocompleteValue) => ({
            ...currState,
            code: filteredOptions.value.toString(),
            countryId: filteredOptions.countryId,
          }));
          setIsInitialised(true);
        }
      }
    }
  }, [individual, isInitialised, options, phoneCode, phoneNumber]);

  useEffect(() => {
    if (hasMadeRequest) {
      if (requestState === ENROLL_REQUEST_STATE.READY) {
        history.push(
          UI_ROUTES.verification + UI_ROUTES.verificationPhoneOTP,
          {
            [PhoneNumberSelector]: value,
          },
        );
      } else if (requestState === ENROLL_REQUEST_STATE.ERROR && statusCode) {
        const errorMsg = createFormControlError(
          checkErrorSatusCode(
            statusCode,
            lang.commonOnboardingPhoneVerificationCodeClientErrorMessage(),
            lang.commonOnboardingPhoneVerificationCodeSendAgainErrorMessage(),
          ),
        );
        setHasMadeRequest(false);
        setBtnDisabled(false);
        setErrors((currState: PhoneAutocompleteErrors) => ({
          ...currState,
          phone: [errorMsg],
        }));
      } else {
        setBtnDisabled(true);
      }
    }
  }, [statusCode, hasMadeRequest, history, requestState, value]);

  return (
    <VerificationLayout
      title={lang.commonOnboardingPhoneVerificationTitle()}
      subtitle={lang.commonVerificationPhoneSubtitle()}
      errors={errors}
      onContinueClicked={() => {
        setIsClickedBtnContinue(true);

        if (validateForm()) {
          onNextClick();
        }
      }}
      btnDisabled={btnDisabled}
    >
      <PhoneAutocomplete
        value={value}
        options={options}
        onChange={(controlValue: PhoneAutocompleteValue) => setValue(controlValue)}
        isClickedBtnContinue={isClickedBtnContinue}
      />
    </VerificationLayout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendPhoneCode: (phoneCode: string, phoneNumber: string) => dispatch(enrollAuth({
    requestType: ENROLL_AUTH_REQUEST_TYPE.SEND_PHONE_CODE,
    phoneCode,
    phoneNumber,
  })),
});

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(VerificationPhoneNumber);
