import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment/moment';

import { CURRENT_YEAR } from '../../../lib/constants/date-time.constants';
import { DATEPICKER_FORMAT_YEARS, DATEPICKER_VIEW_YEARS } from '../../../lib/constants/datePickerConstants';
import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { setSourceOfFundsIsEdit } from '../../../lib/store/crm';
import { getMinYearOfDeceasedPerson, yearToDate } from '../../../lib/util/DateTimeHelpers';
import { getDeceasedPersonNameValidity, validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import DatepickerControl from '../../DatepickerControl/DatepickerControl';
import InputControl from '../../InputControl/InputControl';
import { GeneralQuestionsCustomAnswers } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import {
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_IDENTITY_NAME,
  KEY_SOURCE_OF_FUND_TO,
} from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';

import styles from './EnrollInheritance.module.scss';

type ReduxStateProps = {
  sourceOfFunds?: any;
  isEditSourceOfFunds?: boolean;
  isClickedBtnContinue: boolean;
}

type Props = {
  formData: GeneralQuestionsCustomAnswers;
  handleChange: (formKey: string, value: string | number) => void;
} & ReduxStateProps

const EnrollInheritance = ({
  formData,
  handleChange,
  sourceOfFunds,
  isEditSourceOfFunds,
  isClickedBtnContinue,
}: Props) => {
  const [customData, setCustomData] = useState(formData);
  const [changeValue, setChangeValue] = useState(false);
  const birthDate = useSelector<RootState, string | undefined>((state: RootState) => (
    state.crm.individualExtendedInfo?.birth_date
  ));
  const dispatch = useDispatch();

  const handleChangeValue = (key: string, value: string | number) => {
    handleChange(key, value);
    setChangeValue(false);
  };

  const handleCustomData = (value: string | number | null, sourceSelector: string) => {
    if (value) {
      setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
        ...currentState,
        [sourceSelector]: value,
      }));
      if (changeValue) {
        handleChangeValue(sourceSelector, value);
      }
      setChangeValue(true);
    }
  };

  const data = sourceOfFunds.find(
    (source: {
      source_of_fund_id: SourceOfFundEnum
    }) => source.source_of_fund_id === SourceOfFundEnum.InheritanceAlaric,
  );

  if (data && !isEditSourceOfFunds) {
    customData[KEY_SOURCE_OF_FUND_FROM] = data.from;
    customData[KEY_SOURCE_OF_FUND_TO] = data.to;
    customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] = data.identity_name;
    dispatch(setSourceOfFundsIsEdit(true));
    handleChange('', '');
  }

  return (
    <div className={styles.wrapper}>
      <Row className={styles.row}>
        <Col md={12}>
          <DatepickerControl
            idDate="dateInheritance"
            idInput="dateInheritanceInput"
            idPopover="dateInheritancePopover"
            label={lang.commonSourceOfFundsYearOfLabel()}
            maxDate={moment().format()}
            minDate={birthDate}
            dateFormat={DATEPICKER_FORMAT_YEARS}
            views={DATEPICKER_VIEW_YEARS}
            value={customData[KEY_SOURCE_OF_FUND_TO] as string || yearToDate(CURRENT_YEAR)}
            onDateChange={(value: string | null) => {
              handleCustomData(value, KEY_SOURCE_OF_FUND_TO);
            }}
            errors={isClickedBtnContinue
              ? validateControl(
                customData[KEY_SOURCE_OF_FUND_TO],
                [],
                lang.commonSourceOfFundsDateFromLabel(),
                true,
              ).errors
              : []}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col md={12}>
          <InputControl
            id="namedDeceasedPerson"
            name=""
            placeholder=""
            ariaLabel="name of deceased person"
            label={lang.commonSourceOfFundsNameOfDeceasedLabel()}
            value={customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string}
            onValueChange={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_IDENTITY_NAME]: value,
              }));
            }}
            onBlur={(value: string | number) => {
              handleChange(KEY_SOURCE_OF_FUND_IDENTITY_NAME, value);
            }}
            errors={isClickedBtnContinue
              ? getDeceasedPersonNameValidity(customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string).errors
              : []}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col md={12}>
          <DatepickerControl
            idDate="dateDeceasedPerson"
            idInput="dateDeceasedPersonInput"
            idPopover="dateDeceasedPersonPopover"
            label={lang.commonSourceOfFundsBirthYearLabel()}
            minDate={getMinYearOfDeceasedPerson}
            maxDate={CURRENT_YEAR.toString()}
            dateFormat={DATEPICKER_FORMAT_YEARS}
            views={DATEPICKER_VIEW_YEARS}
            value={customData[KEY_SOURCE_OF_FUND_FROM] as string}
            onDateChange={(value: string | null) => {
              handleCustomData(value, KEY_SOURCE_OF_FUND_FROM);
            }}
            errors={isClickedBtnContinue
              ? validateControl(
                customData[KEY_SOURCE_OF_FUND_FROM],
                [],
                lang.commonSourceOfFundsBirthYearLabel(),
                true,
              ).errors
              : []}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState): any => ({
  sourceOfFunds: state.crm.individualExtendedInfo?.source_of_funds,
  isEditSourceOfFunds: state.crm.enroll.isEditSourceOfFunds,
});

export default connect(mapStateToProps)(EnrollInheritance);
