import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { OHLCV } from '../models/market-data/types';
import { SmallChartData } from '../store/market-data/charting/types';

import CallsCache from './calls-cache/CallsCache';


export type SmallChartDataMap = Record<string, SmallChartData>

const SmallChartDataCache = entity<SmallChartDataMap>({}, entityReduxLogger('SmallChart', 'small-chart'));

const EMPTY_SMALL_CHART_DATA: SmallChartData = {
  ohlcvData: [] as OHLCV[],
  closeValues: [] as number[],
  isReady: false,
};

/**
 * Used only by Gateway service to update cache from response messages.
 * @param symbol The stock symbol
 * @param newData The chart data sent with update
 * @param traceId The trace identifier for the call
 */
export const updateSmallChartDataCache = (newData: Record<string, OHLCV[]>, traceId: string) => {
  SmallChartDataCache.set(
    produce(state => {
      /* eslint-disable no-param-reassign */
      Object.entries(newData ?? {}).forEach(([ symbol, data ]) => {
        if (data) {
          state[symbol] = {
            ohlcvData: data,
            closeValues: data.map(({ close }) => close),
            isReady: true,
          };
        }
      });
      const { __DEV__ } = require('../../configLib').default;
      const { logConfig } = require('../../configDebug');
      if (__DEV__ && logConfig.chart) {
        // eslint-disable-next-line prefer-spread
        const debugTitle = `[SmallChart] State updated - ${Object.keys(newData)}`;
        const debugData = {
          newData,
          state: { ...state },
          callsCount1D: state.callsCount1D,
        };
        console.info(debugTitle);
        console.debug(debugTitle, debugData);
        console.tron.display({ name: debugTitle, value: debugData });
      }
    /* eslint-enable no-param-reassign */
    }),
  );

  // process CallsCache for smallChart
  CallsCache.processResponseByTraceId(traceId, 'getChartingData_small');
};

// hiding direct setter by not including it in exports
export default {
  get: (symbol: string) => SmallChartDataCache.get()[symbol] ?? EMPTY_SMALL_CHART_DATA,
  getCount: () => Object.keys(SmallChartDataCache.get()).length,
  use: (symbol: string) => SmallChartDataCache.use(data => data?.[symbol] ?? EMPTY_SMALL_CHART_DATA),
  useAll: SmallChartDataCache.use,
};
