export enum ValidationType {
  IBAN,
  Email,
  Password,
  SwiftCode,
  Whitespace,
  EmptyString,
  MinCharacters,
  MinCharacters4,
  UnderEighteen,
  StartWithLetter,
  FirstCharLetter,
  LatinLettresOnly,
  CityMinCharacters,
  MaxCharactersCity,
  MaxCharactersName,
  MaxCharactersAddress,
  MaxCharactersPostCode,
  InvalidCharactersCity,
  InvalidCharactersName,
  InvalidCharactersEmail,
  MaxCharactersTaxNumber,
  MinCharactersTaxNumber,
  MaxCharactersIDDocument,
  InvestmentSavingSpecify,
  StartWithLetterOrNumber,
  MinCharactersPhoneNumber,
  MaxCharactersPhoneNumber,
  InvalidCharactersAddress,
  InvalidCharactersPostCode,
  MaxCharactersEmailAddress,
  EmptyStringOrSpecialSymbol,
  InvalidCharactersTaxNumber,
  InvalidCharactersIDDocument,
  MaxCharactersSorceOfFundName,
  MaxCharacters4,
  MaxCharacters400,
  MaxCharactersInvestmentSavings,
  InvalidCharactersCompanyName,
  InvalidCharactersInvestmentSavings,
  MinNineChars,
  MaxThirtyFourChars,
  AlphaNumeric,
  NotOnlyNumbers,
}

export type ControlError = {
    id: number,
    error: string
  };

export type ControlValidation = {
    validationType: ValidationType,
    errorMessage: string
  };

export type ControlValidity = {
    isValid: boolean;
    errors: ControlError[];
  }

