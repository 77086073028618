import config from '../../config';
import { PredefinedMilisecondsTypeEnum } from '../enums';
import {
  InboxMessage,
  InboxPageRequest,
  InboxPageResponse,
  MarkAsReadByMetadata,
  SummaryResponse,
  UnreadCountPayload,
  UnreadCountResponse,
} from '../models/ams/types';
import { getStringLiteral } from '../util/DataHelpers';

import { CacheName, HttpMethods } from './cache/types';
import { BaseRequestService } from './BaseRequest.service';
import { IAMSService } from './types';

const API_PATH = {
  messages: '/messages/inbox',
  getSummary: '/messages/inbox/summary',
  markAllAsRead: '/messages/inbox/mark-all-as-read',
  markAsRead: '/messages/inbox/mark-as-read?ids=$1',
  markAsReadByMetadata: '/messages/inbox/mark-as-read-by-metadata',
  notifyUnreadCount: '/messages/inbox/notify-unread-count',
};
export class AMSService extends BaseRequestService implements IAMSService {
  protected getBaseUrl(): string {
    return config.ams.url;
  }

  constructor(token: string | null) {
    super(token);
    if (token) this.setToken(token);

    this.setToken = this.setToken.bind(this);
  }

  public async getAllMessages(params: InboxPageRequest) {
    return this.api.get<InboxPageResponse, void>(API_PATH.messages, params);
  }

  public async deleteMessages(ids: number | number[]) {
    return this.api.delete<void>(API_PATH.messages, {
      ids: getStringLiteral(ids),
    });
  }

  public async getMessage(id: number) {
    // Add caching logic in ART-4234
    return this.api.get<InboxMessage, void>(`${API_PATH.messages}/${id}`);
  }

  public async markAllMessagesAsRead() {
    return this.api.put<void>(API_PATH.markAllAsRead);
  }

  public async markAsRead(ids: number | number[]) {
    return this.api.put<void>(API_PATH.markAsRead.replace('$1', getStringLiteral(ids)));
  }

  public async notifyUnreadCount(requestBody?: UnreadCountPayload) {
    return this.api.put<UnreadCountResponse>(API_PATH.notifyUnreadCount, requestBody);
  }

  public async getSummary() {
    return this.api.get<SummaryResponse, void>(API_PATH.getSummary);
  }

  public async markAsReadByMetadata(requestBody: MarkAsReadByMetadata) {
    return this.api.put<void>(API_PATH.markAsReadByMetadata, requestBody);
  }
}
