import { produce } from 'immer';
import { entity } from 'simpler-state';

import configLib from '../../configLib';

const INITIAL_STATE = {
  currentStatus: 'unknown' as AppStateStatus,
  isTokenRefresh: false,
};
export type AppStateStatus = 'active' | 'background' | 'inactive' | 'unknown' | 'extension'
export type AppStateCacheData = typeof INITIAL_STATE;

const options = {
  storage: configLib.storage as any,
  serializeFn: JSON.stringify,
  deserializeFn: JSON.parse,
};
const self = entity(INITIAL_STATE);

function setCurrentAppStatus(value: AppStateStatus) {
  self.set(produce(state => {
    state.currentStatus = value;
  }));
}

function setIsTokenRefresh(value: boolean) {
  self.set(produce(state => {
    state.isTokenRefresh = value;
  }));
}

export default {
  useCurrentAppStatus: () => self.use(data => data.currentStatus),
  getCurrentAppStatus: () => self.get().currentStatus,
  setIsTokenRefresh,
  getIsTokenRefresh: () => self.get().isTokenRefresh,
  setCurrentAppStatus,
};
