import React from 'react';
import { Popover } from '@material-ui/core';

import styles from './PopoverComponent.module.scss';

type Props = {
    open: boolean;
    handlePopoverClose: () => void;
    popoverStatus: HTMLDivElement | null;
    label: string;
}

const PopoverComponent = ({ open, handlePopoverClose, popoverStatus, label }: Props) => (
  <Popover
    id="mouse-over-popover"
    className={styles.wrapperPopover}
    open={open}
    anchorEl={popoverStatus}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={handlePopoverClose}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <div className={styles.arrowBox} />
    <p>{label}</p>
  </Popover>
);

export default PopoverComponent;
