export type SubscriptionTopic = 'bbo' | 'nbbo' | 'trade' | 'book' | 'ohlcv';

export type Subscriptions = {
  bbo: string[],
  nbbo: string[],
  trade: string[],
  book: string[],
  ohlcv: string[]
};

export interface BBOData {
  askpartid: string;
  askprice: number;
  asksize: number;
  bidpartid: string;
  bidprice: number;
  bidsize: number;
  quotetime: string;
  sym: string;
  symbolonly: string;
  askdirection: string;
  biddirection: string;
}

export interface TradeData {
  isirregular: boolean;
  sym: string;
  symbolonly: string;
  totalvolume: number;
  tradedirection: '+' | '-';
  tradepartid: string;
  tradeprice: number;
  tradequalifier: string;
  tradeseqnum: number;
  tradesize: number;
  tradetime: string;
  vwap: number;
}

export type SymbolStatus = {
  sym: string;
  kdbRecvTime: string;
  updateTime: string;
  trade: string;
  reason: string;
  ssri: string;
  limitHigh: number;
  limitLow: number;
}

export interface Bucket {
  time: number;
  duration: string;
  closed: boolean;
  openTradeSeqNum?: number;
  closeTradeSeqNum?: number;

  close: number;
  high: number;
  low: number;
  open: number;
  volume: number;
}

export type OHLCV = {
  close: number;
  date: string; // "2020-06-24"
  time: string; // "13:48:00.000"
  // The gateway format of date and time is `dateAndTime`
  // It is parsed into `date` and `time` - see `parseRows` in GatewayHelpers
  dateAndTime?: string;
  high: number;
  low: number;
  open: number;
  sym: string;
  volume: number;
}

export enum MarketState {
  PreMarket = 1,
  RegularHours = 2,
  AfterMarket = 3,
  MarketClosed = 4,
}

/**
 * This is an artificial state because of the way InstrumentSnapshot
 * is working for our MDS (Market Data Service).
 * This implementation is aiming to explicitly make incompatible MarketState & MarketStateInstrumentSnapshot
 * and to motivate for a removal.
 * Removal will be possible after a patch in MDS. We would initiate this when possible after MVP.
 */
export enum MarketStateInstrumentSnapshot {
  IS_PreMarket = 10,
  IS_RegularHours = 20,
  IS_AfterMarket = 30,
  IS_MarketClosed = 40,
  IS_MarketClosedNextDay = 50,
}

/** Feed name or false if unsubscribed */
export type MDSFeed = 'trade' | 'nbbo' | 'trade,nbbo' | false
