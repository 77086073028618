import { CurrencyEnum, PaymentProcessEnum } from '../../enums';
import { GetPaymentsPayload, GetWireTransferBankAccountPostRequest } from '../../models/crm/types';

import { TransactionsState } from './types';


export const MINIMUM_DEPOSIT_AMOUNT = 1;
export const MAXIMUM_DEPOSIT_AMOUNT_CARD_TRANSFER = 5000;
export const MAXIMUM_DEPOSIT_AMOUNT_BANK_TRANSFER = 999999;
export const DEFAULT_TRANSACTIONS_PAGE_SIZE = 15;
export const DEFAULT_TRANSACTIONS_DATE_OFFSET = 365;
export const ALL_TRANSACTIONS_PAGE_SIZE = 1000;
export const ALL_TRANSACTIONS_DATE_OFFSET = 5 * DEFAULT_TRANSACTIONS_DATE_OFFSET;

export const INITIAL_TRANSACTIONS_STATE: TransactionsState = {
  process: PaymentProcessEnum.Deposit,
  data: [],
  filteredOut: [],
  ignored: [],
  pageSize: DEFAULT_TRANSACTIONS_PAGE_SIZE,
  orderColumn: 'payment_id', // orderColumn should be name of parameter from response model(see ../../models/crm/types/Payment)
  orderAscending: false,
  callsInQueue: [] as GetPaymentsPayload[],
};

export const TRANSACTIONS_FILTER = {
  paymentProcess: [
    PaymentProcessEnum.Deposit,
    PaymentProcessEnum.Withdrawal,
    PaymentProcessEnum.Adjustment,
  ],
  pageNumber: 1,
  defaultPageNumber: 1,
  pageSize: DEFAULT_TRANSACTIONS_PAGE_SIZE,
  dateOffset: DEFAULT_TRANSACTIONS_DATE_OFFSET,
};

export const ALL_TRANSACTIONS_FILTER = {
  paymentProcess: [
    PaymentProcessEnum.Deposit,
    PaymentProcessEnum.Withdrawal,
    PaymentProcessEnum.Adjustment,
  ],
  pageNumber: 1,
  defaultPageNumber: 1,
  pageSize: ALL_TRANSACTIONS_PAGE_SIZE,
  dateOffset: ALL_TRANSACTIONS_DATE_OFFSET,
  isAllTransactions: true,
};

export const DEPOSIT_WIRE_TRANSFER_FILTER: GetWireTransferBankAccountPostRequest = {
  currency_code: CurrencyEnum.USD,
  bank_country_code: 'BG',
  payment_process: PaymentProcessEnum.Deposit,
};
