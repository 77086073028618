export const RequiredIbanKeySelector = false;
export const IncorrectIbanKeySelector = false;

export const validateIban = (value: string): boolean => {
  let controlValue = value && value.toString().trim();

  if (!controlValue) {
    return RequiredIbanKeySelector;
  }

  controlValue = controlValue.toUpperCase().split(/[\s]+/).join('');

  /**
   * Check iban length(15 - 31 symbols). For BG - 22.
   */
  if (!controlValue.match(/^[A-Z0-9]{15,31}/)) {
    return IncorrectIbanKeySelector;
  }

  const begin = controlValue.substr(0, 4);
  const rest = controlValue.substr(4, 23);

  let reversedValue = rest + begin;

  const latinUpperLetters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  ];

  const letterNumberReplacements: string[] = [];

  for (let i = 10; i <= 35; i++) {
    letterNumberReplacements.push(i.toString());
  }

  latinUpperLetters.forEach((letter: string, index: number) => {
    while (reversedValue.indexOf(letter) >= 0) {
      reversedValue = reversedValue.replace(letter, letterNumberReplacements[index]);
    }
  });

  const divisor = 97;
  const partLength = 10;

  while (reversedValue.length > partLength) {
    const part = parseInt(reversedValue.substring(0, partLength), 10);

    reversedValue = (part % divisor) + reversedValue.substring(partLength);
  }

  const modulus = parseInt(reversedValue, 10) % divisor;

  return (modulus !== 1) ? IncorrectIbanKeySelector : true;
};
