import React from 'react';
import { VictoryLabel, VictoryPie } from 'victory';

import { PIE_CHART_WIDTH_HIGHLIGHT } from '../../../lib/store/reporting/constants';
import { hammerPortfolioCashColors } from '../../../lib/util/chart-colors';

type Props = {
  chartData: number[];
  labelText: string;
  chartValue: string;
}

const PieChart = ({ chartData, chartValue, labelText }: Props) => (
  <>
    <VictoryPie
      standalone={false}
      data={chartData.length ? chartData.map((val, ind) => ({ x: ind, y: val })) : [ { y: 1 } ]}
      height={250}
      startAngle={260}
      endAngle={640}
      animate={{ duration: 1000 }}
      radius={PIE_CHART_WIDTH_HIGHLIGHT}
      innerRadius={100}
      labels={() => ''}
      colorScale={chartData.length ? hammerPortfolioCashColors : ['#F6F6F8']}
    />
    )
    <VictoryLabel
      textAnchor="middle"
      verticalAnchor="middle"
      backgroundPadding={[-10, 18]}
      style={[
        { fill: '#CBD5E0' },
        { fill: '#fff', fontWeight: '600', fontSize: 24, fontFamily: 'Source Sans Pro' }]}
      x={200}
      y={125}
      text={[labelText, chartValue]}
    />
  </>
);

export default PieChart;
