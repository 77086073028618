import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import {
  CHART_DATA_NET_INCOME_COMPONENTS_RANGE,
  CHART_DATA_SYMBOL_RANGE,
  ChartDataPortion,
  ChartDataRange,
} from '../../../lib/store/market-data/charting/types';

import { useIsMobile } from '../../../hooks';
import { ClickableDiv } from '../../index';
import TradeViewChartButton from '../TradingViewChartButton/TradeViewChartButton';

import styles from './ScopeButtons.module.scss';

const getItemText = (item: string, index: number, isMyAccountSection: boolean): string => {
  if (isMyAccountSection) {
    return index < 3 ? item.substring(0, 2) : item;
  }
  return item.substring(0, 2);
};

type Props = {
  symbol?: string;
  chartDataByRange?: ChartDataPortion;
  isDisabled?: boolean;
  isMyAccountSection?: boolean;
  onScopeButtonClick?: () => void;
  changeRange: (range: ChartDataRange) => void;
}

const ScopeButtons = ({
  symbol,
  changeRange,
  chartDataByRange,
  isDisabled,
  isMyAccountSection = false,
  onScopeButtonClick,
}: Props): JSX.Element => {
  const isMobile = useIsMobile();
  const [activeDateRange, setActiveDateRange] = useState(0);

  useEffect(() => {
    if (chartDataByRange || isMyAccountSection) {
      const data = isMyAccountSection ? CHART_DATA_NET_INCOME_COMPONENTS_RANGE : CHART_DATA_SYMBOL_RANGE;
      const range = data[activeDateRange];
      changeRange(range);
    }
  }, [activeDateRange, chartDataByRange, changeRange, isMyAccountSection]);

  useEffect(() => {
    if (symbol) {
      setActiveDateRange(0);
    }
  }, [symbol]);

  const handleClickButton = (e, index) => {
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    setActiveDateRange(index);
    onScopeButtonClick && onScopeButtonClick();
  };

  const dateRange = () => {
    const rangeValues = isMyAccountSection ? CHART_DATA_NET_INCOME_COMPONENTS_RANGE : CHART_DATA_SYMBOL_RANGE;
    return rangeValues.map((item: string, index: number) => (
      <ClickableDiv
        key={item}
        className={classNames(styles.dataRangeItem,
          { [styles.activeItem]: activeDateRange === index,
            [styles.mobileSymbolDetails]: isMobile && !isMyAccountSection,
            [styles.dateRangeContainerPadding]: !isMyAccountSection,
          })}
        onClick={(e) => handleClickButton(e, index)}
      >
        {getItemText(item, index, isMyAccountSection)}
      </ClickableDiv>
    ));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.dataRangeWrapper}>
          {dateRange()}
          {!isMyAccountSection && isMobile && (<div className={styles.emptyScopeButton} />)}
        </div>
        {!isMyAccountSection && !isMobile && (
        <div className={styles.tradingView}>
          <TradeViewChartButton symbol={symbol!} />
        </div>
        )}
      </div>
      <div className={styles.horizontalLine} />
    </>
  );
};

export default ScopeButtons;
