import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';

import { UI_ROUTES } from '../../../util/routes';
import { ButtonTypesEnum } from '../../Button/Button.types';
import { Button, HeaderLogo } from '../../index';
import { EnrollProgressBarData } from '../ProgressBar/ProggressBar.types';
import ProgressBar from '../ProgressBar/ProgressBar';

import styles from './EnrollHeader.module.scss';

type Props = {
  className?: string;
  visibleProgressBar?: boolean;
  progressBarData?: EnrollProgressBarData
  isParentInitialised?:boolean;
}

const EnrollHeader = (props: Props) => {
  const {
    className,
    progressBarData,
    visibleProgressBar = false,
    isParentInitialised,
  } = props;

  const history = useHistory();

  return (
    <div className={[styles.wrapper, className].join(' ')}>
      <div className={styles.contentWrapper}>
        <div className={styles.logo}>
          <HeaderLogo />
        </div>
        {(visibleProgressBar && isParentInitialised) && (
        <>
          {progressBarData && <ProgressBar data={progressBarData} className={styles.progress} />}
          <Button
            id="closeEnrollment"
            ariaLabel="close enrollment"
            variant={ButtonTypesEnum.Tertiary}
            onClick={() => history.push(`${UI_ROUTES.overview}`)}
            className={styles.closeButton}
          >
            {lang.commonClose()}
          </Button>
        </>
        )}
      </div>
    </div>
  );
};

export default EnrollHeader;
