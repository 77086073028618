import React, { useEffect, useState } from 'react';

import lang from '../../../../../lib/language';

import { useIsMobile } from '../../../../../hooks';
import { Preloader } from '../../../../index';
import Navigation from '../../../../Navigation/Navigation';
import TermsAndConditions from '../../../../TermsAndConditions/TermsAndConditions';

import PersonalDetailsComponent from './components/personalDetailsComponent/PersonalDetailsComponent';

import styles from './PersonalDetails.module.scss';

const PersonalDetails = () => {
  const isMobile = useIsMobile();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const tabs = [
    { text: lang.mobileProfilePersonalDetails(),
      component: <PersonalDetailsComponent />,
    },
    { text: lang.mobileProfileDocuments(),
      component: <TermsAndConditions />,
    },
  ];

  return (
    <>
      { loading ? (
        <div className={styles.loader}><Preloader owner="Personal Details" text="" /></div>
      ) : (
        <>
          {isMobile ? (
            <PersonalDetailsComponent />
          ) : (
            <div className={styles.wrapperLayout}>
              <div className={styles.wrapperFirstSection}>
                <h6 className={styles.title}>{lang.mobileProfileHeaderTitle()}</h6>
                <Navigation tabs={tabs} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PersonalDetails;
