/**
 * Statuses from Alaric CRM API
 */
export enum PaymentStatusEnum {
  New = 1,
  ForApproval = 2,
  OnHold = 3,
  Pending = 4,
  Rejected = 5,
  Processed = 6,
  Canceled = 7,
  Approved = 8,
  CanceledByClient = 9,
  Completed = 10,
}
