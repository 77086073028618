import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';

import { Button } from '../../../../components';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './Modal.module.scss';

type Props = {
    handleModal: () => void;
    textInfoModal: {title: string, description1: string, description2?: string}
} & ReduxProps;

const BankTransferModal = (props: Props) => {
  const { handleModal, textInfoModal, hasBankAccount } = props;

  const history = useHistory();
  const { title, description1, description2 } = textInfoModal;

  const handleContinue = () => {
    if (!hasBankAccount) {
      return history.push(UI_ROUTES.funds + UI_ROUTES.addYourBankAccount);
    }
    return history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.enterAmount,
      state: {
        target: lang.mobileBankDialogTitle() },
    });
  };

  return (
    <>
      <h6 className={styles.title}>{title}</h6>
      <span className={styles.description}>{description1}</span>
      <span className={styles.description}>{description2}</span>
      <div className={styles.buttonWrapper}>
        <Button
          variant={ButtonTypesEnum.Tertiary}
          fullWidth
          className={styles.btnCancel}
          onClick={() => handleModal()}
        >
          {lang.commonCancelButton()}
        </Button>
        <Button fullWidth onClick={handleContinue}>
          {lang.commonConfirmButton()}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  hasBankAccount: state.payment.hasBankAccount,
});
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(BankTransferModal);
