/**
 * The file `configLib` is used for injecting project specific libraries and implementations
 * into library code - which is pure TypeScript, platform agnostic.
 */
import storage from 'redux-persist/lib/storage';

const noop = (...args: any[]) => {};

const configLib = {
  __DEV__: (process.env.NODE_ENV === 'development'),
  __TEST__: (process.env.NODE_ENV === 'test'),
  AuthService: () => require('./services/Auth.service').default, // eslint-disable-line
  getRandomValuesInit: () => {},
  storage,
  bugReport: (title: string, message: string) => {
    alert(`${title} - ${message}`);
  },
  isWeb: true,
  isHW: true,
  disablePopups: (value: boolean) => {},
  disablePopupsFilter: noop,
  errorHandlingInit: () => null,
  Reactotron: require('reactotron-react-js').default, // eslint-disable-line
  reactotronInit: () => require('./util/ReactotronConfig').default, // eslint-disable-line global-require
  WebSocket,
  Response,
  fetchFunc: fetch,
  FormData,

  // TODO: Remove this when fully switched to Gateway
  // possible markers: (1) all MD & T calls are implemented (2) connection is stable (WEB-366, ART-525)
  gatewayEnabled: {
    login: true,
    subscribe: true,
    query: true,
    latestNews: true,
    newsForSymbol: true,
    newsSubscribe: false, // TODO: Enable when/if needed
  },
  // TODO: Remove this when fully switched to SOR
  // and Trading API will not be used (even for testing/debugging)
  sorApiEnabled: {
    neworder: true,
    cancel: true,
    modifyOrder: true,
  },
  store: null as any,
  storePersistor: null as any,
  _setProp__ForUnitTestingOnly: (name: any, value: any) => {},
  setStore: (value: any, persistor?: any) => {},
  enableDev: (value: any) => {},
  base64: require('base-64'), // eslint-disable-line global-require
  PushNotificationService: () => require('./services/PushNotification.service').default, // eslint-disable-line global-require
  CrashReportingService: () => require('./services/CrashReporting.service').default, // eslint-disable-line global-require
};
type ConfigLibKey = keyof typeof configLib;

// eslint-disable-next-line no-underscore-dangle
configLib._setProp__ForUnitTestingOnly = (name: ConfigLibKey, value: any) => {
  configLib[name] = value;
};

configLib.setStore = (value: any, persistor: any) => {
  configLib.store = value;
  configLib.storePersistor = persistor;
};

configLib.enableDev = () => {
  configLib.__DEV__ = true;
};

export default configLib;
