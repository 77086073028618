import { isEmpty } from 'lodash';

import configLib from '../../../configLib';
import { LoggerService } from '../logger/Logger.service';

import { ALL_CACHE_KEYS, CACHE_VALUE_DELIMITER } from './constants';
import { CacheableData } from './types';

const logger = LoggerService.getLogger();

export class CacheService {
  private static service: CacheService;

  private readonly storage = configLib.storage;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    // In order to avoid having multiple instances constructor is set as private
  }

  static getService(): CacheService {
    if (!this.service) {
      this.service = new CacheService();
    }
    return this.service;
  }

  private async saveCacheData(cacheKey: string, data: CacheableData): Promise<void> {
    if (!cacheKey) {
      return;
    }

    try {
      await this.storage.setItem(cacheKey, JSON.stringify(data));
      await this.addCacheKeyToCachedList(cacheKey);
    } catch (e) {
      logger.warn('Cannot put data to cache! error:', e);
      await this.evictCache(cacheKey);
    }
  }

  public async putCache(cacheKey: string, ttl: number, data: any): Promise<void> {
    const cachableData: CacheableData = {
      data,
      cacheTTL: ttl,
      cacheTime: new Date().getTime(),
    };

    await this.saveCacheData(cacheKey, cachableData);
  }

  public async getOrRemoveFromCache(cacheKey: string): Promise<CacheableData | null> {
    if (!cacheKey) {
      return null;
    }

    const cachedItemStr = await this.storage.getItem(cacheKey);
    // @ts-ignore //ignored to avoid "error TS1345: An expression of type 'void' cannot be tested for truthiness"
    if (!cachedItemStr) {
      return null;
    }

    const cachedItem = JSON.parse(cachedItemStr) as CacheableData;

    if (this.isExpired(cachedItem)) {
      await this.evictCache(cacheKey);
      return null;
    }

    return cachedItem;
  }

  public async checkAndEvictCache(cacheKey: string): Promise<void> {
    if (!cacheKey) {
      return;
    }

    await this.getOrRemoveFromCache(cacheKey);
  }

  private isExpired(cachedItem: CacheableData | undefined): boolean {
    if (!cachedItem) {
      return false;
    }
    const dateNow = new Date().getTime();
    return (dateNow - cachedItem.cacheTime) > cachedItem.cacheTTL;
  }

  public async evictCache(cacheKey: string): Promise<void> {
    if (!cacheKey) {
      return;
    }

    await this.storage.removeItem(cacheKey);
    await this.removeCacheKeyFromCachedList(cacheKey);
  }

  public async checkAndEvictExpiredCaches(): Promise<void> {
    const cacheKeys = await this.getAllCachedKeys();
    if (isEmpty(cacheKeys)) {
      return;
    }

    cacheKeys.forEach(async item => {
      await this.getOrRemoveFromCache(item);
    });
  }

  private async getAllCachedKeys(): Promise<string[]> {
    const existingKeys = await this.storage.getItem(ALL_CACHE_KEYS);
    // @ts-ignore //ignored to avoid "error TS1345: An expression of type 'void' cannot be tested for truthiness"
    if (!existingKeys) {
      return [];
    }

    // @ts-ignore //ignored to avoid "error TS2339: Property 'split' does not exist on type 'never'"
    return existingKeys.split(CACHE_VALUE_DELIMITER);
  }

  private async addCacheKeyToCachedList(cacheKey: string): Promise<void> {
    const existingKeys = await this.storage.getItem(ALL_CACHE_KEYS);

    // @ts-ignore //ignored to avoid "error TS1345: An expression of type 'void' cannot be tested for truthiness"
    if (!existingKeys) {
      await this.storage.setItem(ALL_CACHE_KEYS, cacheKey);
      return;
    }

    await this.storage.setItem(ALL_CACHE_KEYS, (existingKeys + CACHE_VALUE_DELIMITER + cacheKey));
  }

  private async removeCacheKeyFromCachedList(cacheKey: string): Promise<void> {
    const cacheKeys = await this.getAllCachedKeys();
    const filteredKeys = cacheKeys.filter(item => item !== cacheKey).join(CACHE_VALUE_DELIMITER);
    await this.storage.setItem(ALL_CACHE_KEYS, filteredKeys);
  }

  public async checkAndEvictAllCaches() {
    const cacheKeys = await this.getAllCachedKeys();
    if (isEmpty(cacheKeys)) {
      return;
    }

    cacheKeys.forEach(async item => {
      await this.evictCache(item);
    });
  }
}
