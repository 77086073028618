import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { refreshPayments } from '../../../lib/store/common-actions';
import { setBankAccountDetailsOnCreate } from '../../../lib/store/crm/index';

import ThumbsUp from '../../../assets/bigImages/ThumbsUp.png';
import { Button } from '../../../components';
import { UI_ROUTES } from '../../../util/routes';

import styles from './AddBankAccountScreen.module.scss';

type Props = {
    isOpen: boolean;
    previousPage: string;
} & ReduxProps;

const AddBankAccountScreen = (props: Props) => {
  const { setBankAccountValuesOnCreate, previousPage, isOpen } = props;
  const history = useHistory();
  const handleContinue = () => {
    setBankAccountValuesOnCreate();

    if (previousPage === UI_ROUTES.funds) {
      return history.replace(UI_ROUTES.funds);
    }

    return history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.enterAmount,
      state: {
        target: lang.mobileBankDialogTitle() },
    });
  };
  return (
    <>
      {isOpen && (
        <div className={styles.wrapper}>
          <img className={styles.image} src={ThumbsUp} alt="Thumb Up" />
          <div className={styles.textWrapper}>
            <h6 className={styles.title}>{lang.mobileBankDetailsSetTitle()}</h6>
            <span className={styles.description}>{lang.mobileBankDetailsSetSubtitle1()}</span>
            <div className={styles.secondDescription}>
              <span className={styles.description}>{lang.mobileBankDetailsSetSubtitle2()}</span>
              <span className={styles.description}>{lang.mobileBankDetailsSetSubtitle3()}</span>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.continueBtn}>
              <Button
                fullWidth
                onClick={handleContinue}
              >
                {lang.commonContinueButton()}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setBankAccountValuesOnCreate: () => dispatch(setBankAccountDetailsOnCreate({})),
});

const connector = connect(null, mapDispatchToProps);
  type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddBankAccountScreen);
