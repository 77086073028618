import React from 'react';

import lang from '../../lib/language';

import discoverLogo from '../../assets/img/icon_discover.svg';
import myAccountLogo from '../../assets/img/icon-myaccount_24px.svg';
import profileLogo from '../../assets/img/icon-profile.svg';
import watchlistLogo from '../../assets/img/icon-watchlist.svg';
import paymentLogo from '../../assets/img/payment-logo.svg';
import { UI_ROUTES } from '../../util/routes';

import { DownBarItem } from './Downbar.types';

export const MENU_LIST: DownBarItem[] = [
  { id: 'watchlistMenuItem', label: lang.commonWatchlist(), path: '/overview', img: <img src={watchlistLogo} alt="icon watchlist" /> },
  { id: 'discoverMenuItem', label: lang.commonDiscoverTitle(), path: '/discover', img: <img src={discoverLogo} alt="icon discover" /> },
  { id: 'myAccountMenuItem', label: lang.commonMyAccount(), path: '/my-account', img: <img src={myAccountLogo} alt="icon my account" /> },
  {
    id: 'fundsMenuItem',
    label: lang.fundsScreenTitle(),
    path: UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit,
    img: <img src={paymentLogo} alt="icon payment" />,
  },
  {
    id: 'myProfile',
    label: lang.mobileProfileMyProfile(),
    path: '/my-profile',
    img: <img src={profileLogo} alt="icon my profile" /> },
];
