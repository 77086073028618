import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import lang from '../../../../lib/language';
import { TopGainerAndLoserPayload } from '../../../../lib/models/fundamental/types';
import { getTopGainers, getTopLosers } from '../../../../lib/store/fundamental';
import { FundamentalCall } from '../../../../lib/store/fundamental/types';
import { RootState } from '../../../../lib/store/index';
import TopGainersCache from '../../../../lib/store-util/top-movers/TopGainersCache';
import TopLosersCache from '../../../../lib/store-util/top-movers/TopLosersCache';
import { isCallStatusError } from '../../../../lib/util/error-handling/StatusByCallHelpers';

import { Navigation, NoDataAvailable, Preloader } from '../../../../components';
import { useIsMobile } from '../../../../hooks';
import { usePrevious } from '../../../../util/helpers';
import FilterDropDown from '../FilterDropDown/FilterDropDown';
import { FILTER_RANGE_OPTIONS } from '../FilterDropDown/FilterDropDownOptions';
import TopMoversTable from '../TopMoversTable/TopMoversTable';

import styles from './TopGainersAndLosersList.module.scss';

const TopGainersAndLosersList = (props: ReduxProps) => {
  // TODO : This component will be simplified within ART-3785
  const {
    getTopGainersData, isGettingTopMoversFailed, getTopLosersData,
  } = props;

  const isMobile = useIsMobile();
  const history = useHistory();

  const locationState = history.location.state as { currentTab: number, optionValue: string, optionFilter: number[] };

  const [currentTab, setCurrentTabIndex] = useState<number>(locationState.currentTab);
  const [optionFilter, setOptionFilter] = useState<number[]>(locationState.optionFilter);
  const [optionValue, setOptionValue] = useState<string>(locationState.optionValue);
  const [isInitialStateOptionFilter, setIsInitialStateOptionFilter] = useState<null | boolean>(null);
  const [isDifferentTab, setIsDifferentTab] = useState<boolean>(false);
  const [changeFilter, setChangeFilter] = useState<boolean>(false);
  const [emptyData, setEmptyData] = useState<boolean>(false);

  const isTopGainers = currentTab === 0;

  const { data, isReady, isTimeout } = isTopGainers ? TopGainersCache.use() : TopLosersCache.use();

  const prevAmount = usePrevious(data);
  useEffect(() => {
    if (prevAmount !== data) {
      setChangeFilter(false);
    }
  }, [prevAmount, data]);

  const tabs = [
    { text: lang.commonTopGainers(), component: <></> },
    { text: lang.commonTopLosers(), component: <></> },
  ];

  const handleFilterOption = (option: number[], valueOption: string) => {
    setIsInitialStateOptionFilter(false);
    setOptionFilter(option);
    setOptionValue(valueOption);
    setChangeFilter(true);
    setEmptyData(false);
  };

  useEffect(() => {
    if (isTopGainers) {
      (isInitialStateOptionFilter && isDifferentTab)
        ? getTopGainersData({
          minValue: FILTER_RANGE_OPTIONS[2][0],
          maxValue: FILTER_RANGE_OPTIONS[2][1],
        })
        : getTopGainersData({
          minValue: optionFilter[0],
          maxValue: optionFilter[1],
        });
    } else {
      (isInitialStateOptionFilter && isDifferentTab)
        ? getTopLosersData({
          minValue: FILTER_RANGE_OPTIONS[2][0],
          maxValue: FILTER_RANGE_OPTIONS[2][1],
        })
        : getTopLosersData({
          minValue: optionFilter[0],
          maxValue: optionFilter[1],
        });
    }
  }, [
    isTopGainers,
    optionFilter,
    isInitialStateOptionFilter,
    isDifferentTab,
    getTopGainersData,
    getTopLosersData,
  ]);

  const handleCurrentTabIndex = (index: number) => {
    setCurrentTabIndex(index);
    if (isInitialStateOptionFilter !== null) {
      setIsDifferentTab(true);
      setOptionValue('');
    }
    setIsInitialStateOptionFilter(true);
  };

  const renderTable = () => {
    const isDataLoading = changeFilter || (isInitialStateOptionFilter && !changeFilter && data.length === 0);

    if (isDataLoading && !emptyData && !isTimeout && !isGettingTopMoversFailed) {
      return <Preloader style={{ width: '100%', marginTop: 80, position: 'fixed' }} isFullScreen text="" owner="TopMovers" />;
    }
    if ((emptyData && isDataLoading && isTimeout) || isGettingTopMoversFailed) {
      return <div className={styles.noData}><NoDataAvailable /></div>;
    }

    return <TopMoversTable topMoversData={data} />;
  };

  return (
    <div className={styles.wrapper}>
      <h6 className={isMobile ? styles.titleMobile : styles.title}>{lang.commonTopMovers()}</h6>
      <Navigation
        tabs={tabs}
        initialActiveTabIndex={currentTab}
        sendCurrentTabIndex={handleCurrentTabIndex}
      />
      <div className={isMobile ? styles.wrapperFilterMobile : styles.wrapperFilter}>
        <FilterDropDown
          optionValue={optionValue}
          handleFilterOption={handleFilterOption}
          hasBackground
          hasExpandArrow
        />
      </div>
      {renderTable()}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isGettingTopMoversFailed: isCallStatusError(FundamentalCall.getTopLosers, state.fundamental.statusByCall)
    || isCallStatusError(FundamentalCall.getTopGainers, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getTopGainersData: (filter: TopGainerAndLoserPayload) => dispatch(getTopGainers(filter)),
  getTopLosersData: (filter: TopGainerAndLoserPayload) => dispatch(getTopLosers(filter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TopGainersAndLosersList);
