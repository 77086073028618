import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, TableCell } from '@material-ui/core';
import classNames from 'classnames';

import { OrderStatusEnum } from '../../../../lib/enums';
import lang from '../../../../lib/language';
import { mapOrderStatus } from '../../../../lib/util/TradingHelpers';

import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import ButtonsCancelAndModify from '../../../TableView/tableContainer/ButtonsCancelAndModify/ButtonsCancelAndModify';
import ButtonsSellAndBuy from '../../../TableView/tableContainer/ButtonsSellAndBuy/ButtonsSellAndBuy';
import { basic, green, red, warning } from '../../../TableView/tableContainer/colors';
import { TABLE_HEADERS_PENDING_ORDERS } from '../../../WatchList/WatchList.constans';

import styles from './PendingOrdersContainer.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    '&:nth-last-child(2)': {
      color: (props: any) => {
        let status: string = '';
        if (props.status) {
          status = props.status.toLowerCase();
        }
        switch (status) {
          case OrderStatusEnum.Filled: return green;
          case OrderStatusEnum.Cancelled: return red;
          case OrderStatusEnum.PartiallyFilled: return warning;
          default: return basic;
        }
      },
    },
  },
}));

type Props = {
  row: any;
  rowKey: string;
  headerKey: string;
  otherButtons?: string;
}

const TableRows = (props: Props) => {
  const { row, rowKey, headerKey, otherButtons } = props;
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const classes = useStyles(row);

  const handleButtons = (item: { status: string }) => {
    switch (pathname) {
      case UI_ROUTES.tradeHistory:
        return item.status === lang.commonOrderStatusFilterPartiallyFilled()
          ? (
            <ButtonsCancelAndModify row={row} />
          )
          : null;
      case UI_ROUTES.myAccount:
      case `${UI_ROUTES.symbol}/${row.name}`:
        if (otherButtons === lang.commonMyAccountButtonMyStocks()) {
          return (
            <ButtonsSellAndBuy row={row} />
          );
        }

        return (
          <ButtonsCancelAndModify row={row} />
        );
      default: return null;
    }
  };

  const handleRowKey = (key: string) => {
    switch (key) {
      case OrderStatusEnum.Filled: return mapOrderStatus(key);
      case OrderStatusEnum.Rejected: return mapOrderStatus(OrderStatusEnum.Cancelled);
      case OrderStatusEnum.PartiallyFilled: return mapOrderStatus(key);
      default: return key;
    }
  };

  return (
    <>
      <TableCell
        className={classNames(styles.tableRow, {
          [styles.unrealizedProfitGreen]: headerKey === 'unrealizedProfit' && row.unrealizedProfitNumber > 0,
          [styles.unrealizedProfitRed]: headerKey === 'unrealizedProfit' && row.unrealizedProfitNumber < 0,
        })}
        align="left"
        classes={{
          root: classes.root,
        }}
      >
        { handleRowKey(rowKey)}
        {headerKey === TABLE_HEADERS_PENDING_ORDERS[TABLE_HEADERS_PENDING_ORDERS.length - 1]
        && handleButtons(row)}
      </TableCell>
    </>
  );
};

export default TableRows;
