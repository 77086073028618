import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus } from '../../../../../lib/enums';
import lang from '../../../../../lib/language';
import { InboxResponse } from '../../../../../lib/models/ams/types';
import { RootState } from '../../../../../lib/store';
import { deleteMessages, getAllMessages, markAllMessagesAsRead, markAsRead } from '../../../../../lib/store/ams';
import { NO_MORE_PAGES_TO_READ } from '../../../../../lib/store/ams/constants';
import { AMSCall } from '../../../../../lib/store/ams/types';

import iconDelete from '../../../../../assets/img/icon-delete_24px.svg';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { ButtonTypesEnum } from '../../../../Button/Button.types';
import { BaseModal, Button, CheckboxControl, ClickableDiv, ClickableImage, Preloader } from '../../../../index';

import MessageRow from './components/MessageRow';
import NoMessages from './components/NoMessages';
import DeleteMessageModal from './modal/DeleteMessageModal';

import styles from './AllNotifications.module.scss';

type Props = {} & ReduxProps;

const AllNotifications = (props: Props) => {
  const {
    allMessages,
    hasUnreadMessages,
    nextPageToRead,
    statusByCall,
    getAllDataMessages,
    putAllMessagesAsRead,
    markMessageAsRead,
    messagesDelete,
  } = props;

  const isMobile = useIsMobile();
  const history = useHistory();
  const locationState = history.location.state as { messageId: number | null, isViewAll: boolean};

  const [listMessages, setListMessages] = useState<InboxResponse[]>([]);
  const [messageData, setMessageData] = useState<InboxResponse | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isDeleteMessages, setIsDeleteMessages] = useState<boolean>(false);

  const handleCheckbox = (message: InboxResponse) => {
    const hasCheckedMessage = listMessages.some(listMessage => listMessage.id === message.id);

    if (hasCheckedMessage) {
      const filterMessages = listMessages.filter(listMessage => listMessage.id !== message.id);
      setListMessages(filterMessages);
    } else {
      setListMessages([ ...listMessages, message ]);
    }
  };

  const handleKeepMessages = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteMessages = () => {
    const listIdOfMessages = listMessages.map(message => message.id);

    messagesDelete(listIdOfMessages);
    setIsDeleteMessages(true);
    setListMessages([]);
    setShowDeleteModal(false);
  };

  const handleMarkAllAsRead = () => {
    putAllMessagesAsRead();
  };

  const handleBtnLoadMore = () => {
    nextPageToRead !== NO_MORE_PAGES_TO_READ && getAllDataMessages();
  };

  useEffect(() => {
    if (allMessages.length && isDeleteMessages && statusByCall[AMSCall.deleteMessages]?.status === CallStatus.READY) {
      setIsDeleteMessages(false);
    }
  }, [allMessages, statusByCall, isDeleteMessages, markMessageAsRead]);

  useEffect(() => {
    if (allMessages.length && isInitialLoad) {
      const selectedMessage = allMessages.find(message => message.id === locationState.messageId);
      if (!locationState.isViewAll && selectedMessage) {
        setMessageData(selectedMessage);
        markMessageAsRead(locationState.messageId!);
      }
      setIsInitialLoad(false);
    }
  }, [allMessages, locationState, isInitialLoad, markMessageAsRead]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const isAtBottom = scrollTop + windowHeight === documentHeight;

    if (isAtBottom) {
      handleBtnLoadMore();
    }
  };

  useEffect(() => {
    isMobile && window.addEventListener('scroll', handleScroll);
    !isMobile && window.removeEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  if (allMessages.length === 0) {
    return isMobile ? (
      <div className={styles.wrapperLayout}>
        <div className={styles.wrapperMessages}>
          <div className={styles.headerMessages}>
            <h5>
              {lang.commonInboxTitle()}
            </h5>
          </div>
          <NoMessages />
        </div>
      </div>
    ) : <NoMessages />;
  }

  return (
    <>
      {
        (statusByCall[AMSCall.getAllMessages]?.status === CallStatus.PENDING
        || statusByCall[AMSCall.deleteMessages]?.status === CallStatus.PENDING
        || statusByCall[AMSCall.getMessage]?.status === CallStatus.PENDING) && (
        <div className={styles.loader}><Preloader text="" owner="Notifications" /></div>
        )
      }
      <div className={styles.wrapperLayout}>
        <div className={styles.wrapperMessages}>
          <div className={styles.headerMessages}>
            <h5>
              {listMessages.length === 0
                ? lang.commonInboxTitle()
                : lang.commonInboxSelectedMessagesText(listMessages.length)}
            </h5>
            <div className={styles.readAndDeleteMessages}>
              {
                  listMessages.length === 0
                    ? (
                      <Button
                        disabled={!hasUnreadMessages}
                        variant={ButtonTypesEnum.Tertiary}
                        className={styles.btnMarkAllAsRead}
                        onClick={handleMarkAllAsRead}
                      >
                        {lang.commonInboxMarkAllAsRead()}
                      </Button>
                    )
                    : (
                      <ClickableImage
                        alt=""
                        src={iconDelete}
                        className={styles.deleteIcon}
                        onClick={() => setShowDeleteModal(true)}
                      />
                    )
                }
            </div>
          </div>
          {!!allMessages.length && allMessages.map((message) => {
            const hasCheckedMessage = listMessages.some((listMessage) => listMessage.id === message.id);

            return (
              <MessageRow
                key={message.id}
                isActiveRow={locationState.messageId === message.id}
                message={message}
                previousMessageId={messageData?.id}
                hasCheckedMessage={hasCheckedMessage}
                handleCheckbox={handleCheckbox}
                setMessageData={(value) => setMessageData(value)}
              />
            );
          })}
          {
            nextPageToRead !== NO_MORE_PAGES_TO_READ && !isMobile && (
            <Button
              variant={ButtonTypesEnum.Link}
              onClick={handleBtnLoadMore}
              className={(
                statusByCall[AMSCall.getAllMessages]?.status === CallStatus.PENDING
                || statusByCall[AMSCall.deleteMessages]?.status === CallStatus.PENDING)
                ? styles.hideBtnLoadMore : styles.btnLoadMore}
            >
              {lang.commonLoadMore()}
            </Button>
            )
          }
        </div>
      </div>
      <BaseModal isOpen={showDeleteModal}>
        <DeleteMessageModal
          handleKeepMessages={handleKeepMessages}
          handleDeleteMessages={handleDeleteMessages}
          listMessages={listMessages}
        />
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  allMessages: state.ams.allMessages,
  hasUnreadMessages: state.ams.hasUnreadMessages,
  nextPageToRead: state.ams.nextPageToRead,
  statusByCall: state.ams.statusByCall,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getAllDataMessages: () => dispatch(getAllMessages()),
  putAllMessagesAsRead: () => dispatch(markAllMessagesAsRead()),
  markMessageAsRead: (id: number) => dispatch(markAsRead(id)),
  messagesDelete: (ids: number[]) => dispatch(deleteMessages(ids)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
  type ReduxProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(AllNotifications);
