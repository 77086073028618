import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';

import lang from '../../lib/language';

import { Button } from '../../components';
import FullsizeDateRangePickerWrapper from '../../components/DateRangePickerWrapper/FullsizeDateRangePickerWrapper';

import Selector from './components/Selector';
import { options } from './Reports.constants';

import styles from './Reports.module.scss';

const Reports = () => {
  const [selectedOption, setSelectedOption] = useState('totalBySymbol');
  const [rangeDate, setRangeDate] = useState<any>({ startDate: '', endDate: '' });
  const [reportType, setReportType] = useState<string>('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDateChange = (value) => {
    const { startDate, endDate } = value;
    setRangeDate({ ...rangeDate, startDate, endDate });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yy = String(today.getFullYear());
    return `${dd}/${mm}/${yy}`;
  };

  const getOneWeekAgoDate = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const dd = String(oneWeekAgo.getDate()).padStart(2, '0');
    const mm = String(oneWeekAgo.getMonth() + 1).padStart(2, '0');
    const yy = String(oneWeekAgo.getFullYear());
    return `${dd}/${mm}/${yy}`;
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperFirstSection}>
          <h6 className={styles.title}>{lang.myProfileReports()}</h6>
          <FormControl fullWidth component="fieldset">
            <div className={styles.optionSelect}>
              {lang.reportsSelectOption()}
            </div>
            <RadioGroup
              aria-label="options"
              name="options"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {options.map((option) => (
                <div className={styles.row}>
                  <div className={styles.textContainer}>
                    <div className={styles.name}>
                      {option.name}
                    </div>
                    <div className={styles.description}>
                      {option.description}
                    </div>
                  </div>
                  <FormControlLabel value={option.value} control={<Radio style={{ color: selectedOption === option.value ? '#3BB0FF' : 'white' }} />} label="" />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.wrapperSecondSection}>
          <div className={styles.container}>
            <div>
              {lang.reportsChooseDate()}
              <FullsizeDateRangePickerWrapper
                initialState={{
                  startDate: new Date(new Date().getDate() - 7),
                  endDate: new Date(),
                }}
                maxDateCalendar={new Date()}
                minDateCalendar={new Date(new Date().getFullYear() - 2)}
                onDateSelect={handleDateChange}
              />
            </div>
            <div className={styles.selector}>
              <div className={styles.selectorTitle}>{lang.reportsChooseFormat()}</div>
              <Selector />
            </div>
            <Button
              id="createReport"
              className={styles.createReportBtn}
              fullWidth
            >
              {lang.commonConfirmButton()}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
