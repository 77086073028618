import { ApiResponse } from 'apisauce';

import config from '../../config';
import { PredefinedMilisecondsTypeEnum } from '../enums';
import {
  FundingWireTransferPostRequest,
  GetWireTransferBankAccountPostRequest,
  LegalDeclaration,
  LookupInterface,
  OwnerTradingAccount,
  Payment,
  PaymentsFilter,
  RegisterBinaryDocumentPayload,
  RegisterBinaryPOIDocumentPayload,
  WithdrawalRequest,
} from '../models/crm/types';
import {
  CountryData,
  EmploymentStatus,
  FinancialQuestionnaireTypeData,
  PurposeOfTradingData,
  SourceOfFundsList,
  SourceOfFundsOwnData,
} from '../models/enroll';
import { LinkedAccount, LinkedAccountRequestData } from '../models/linked-account/types';
import { CreateCardTransferRequest } from '../models/payment/types';
import { applyPathParams } from '../util/DataHelpers';
import { parseToMultipartData } from '../util/RequestHelpers';
import { NullableNumber } from '../util/types';

import { CacheName, HttpMethods } from './cache/types';
import { BaseRequestService } from './BaseRequest.service';
import { ICRMService } from './types';

/**
 * Path params marked given as $1, $2 etc.
 */
const API_PATH = {
  accountsList: '/api/v1/account-structure/all-trading-accounts-of-auth-user',
  account: '/api/v1/account-structure/account',
  accountInfo: '/api/v1/report/account-info',
  accountDetails: '/api/v1/report/account-detail/',

  // individual
  putIndividual: '/api/v1/individual',

  // enroll
  getIndividualExtendedInfo: '/api/v1/individual/extended-info',
  getSourceOfFundOwn: '/api/v1/source-of-fund/own?active=$1',
  getEmploymentStatusOwn: '/api/v1/employment-status/own',
  getBusinessOwn: '/api/v1/business/own',
  getPurposeOfTrading: '/api/v1/purpose-of-trading/own',
  getFinancialQuestionnaireTypes: '/api/v1/lookup/financial-questionnaire-types',
  getFinancialQuestionnaireOwnList: '/api/v1/financial-questionnaire/own/list',
  getFinancialQuestionnaireList: '/api/v1/financial-questionnaire/list',
  getLegalDeclarationList: '/api/v1/legal-declaration/list/?active=true',
  getIndividualOwnLegalDeclarations: '/api/v1/individual/own/legal-declarations',
  getLegalDeclarationsDynamicFile: '/api/v1/legal-declaration/generate/own',
  postEmailLegalDeclarations: '/api/v1/legal-declaration/send-declarations',
  getPepDetail: '/api/v1/pep-detail',
  getSourceOfFundList: '/api/v1/source-of-fund/list?active=$1',
  postEnrollmentOwnPersonal: '/api/v1.1/enrollment/own/personal',
  putEnrollmentOwnPersonal: '/api/v1/enrollment/own/$1/personal',
  postIndividualIdentityDetailOwn: '/api/v1/individual-identity-detail/own',
  putIndividualIdentityDetailOwn: '/api/v1/individual-identity-detail/own',
  postIndividualAddressOwn: '/api/v1/individual-address/own',
  putIndividualAddressOwn: '/api/v1/individual-address/own',
  postIndividualTaxDetailOwnBatch: '/api/v1/individual-tax-detail/own/batch',
  putIndividualTaxDetailOwn: '/api/v1/individual-tax-detail/own',
  deleteTaxDetail: '/api/v1/individual-tax-detail/$1',
  deleteIndividualAddress: '/api/v1/individual-address/$1',
  postIndividualSourceOfFund: '/api/v1/individual-source-of-Fund/own',
  putIndividualSourceOfFund: '/api/v1/individual-source-of-Fund/own',
  postIndividualSourceOfIncome: '/api/v1/individual-source-of-income/own',
  putIndividualOwnPurposeOfTrading: '/api/v1/individual/own/purpose-of-trading',
  putIndividualOwnLegalDeclarations: '/api/v1/individual/own/legal-declarations',
  putIndividualOwnAnnualNetIncome: '/api/v1/individual/own/annual-net-income',
  putIndividualOwnEstimatedNetWorth: '/api/v1/individual/own/estimated-net-worth',
  putIndividualOwnEstimatedLiquidWorth: '/api/v1/individual/own/estimated-liquid-worth',
  putOwnFinancialQuestionnaire: '/api/v1/individual/own/financial-questionnaire',
  putIndividualSourceOfIncome: '/api/v1/individual-source-of-income/own',

  // transitions
  putIndividualTransitionToPendingDocuments: '/api/v1/individual/$1/transition-to-pending-documents',
  putIndividualTransitionToPendingAcceptance: '/api/v1/individual/$1/transition-to-pending-acceptance',
  putIndividualTransitionToForVerification: '/api/v1/individual/$1/transition-to-for-verification',
  postFinancialQuestionnaire: '/api/v1/financial-questionnaire',
  postIndividualPepDetail: '/api/v1/individual-pep-detail/own',
  /**
   * @deprecated Use {@link postDocumentOwnBinary} instead. BE will not support this in the next
   */
  postIndividualDocumentOwn: '/api/v1/individual-document/own',
  postDocumentOwnBinary: '/api/v1.2/individual-document/own',
  postIndividualIdentityDetailOwnWithFile: 'api/v1/individual-identity-detail/own/create-with-file',
  getIndividualOwnActiveRiskAssessment: '/api/v1/individual/own/active-risk-assessment/true',
  getPaymentsAccountsIndividual: '/api/v1/report/payments-accounts-individual',

  // Linked Accounts
  getLinkedAccountsByIndividualId: '/api/v1/LinkedAccount/by-individual/',
  registerLinkedAccount: '/api/v1/LinkedAccount',

  // Payments
  getWireTransferBankAccount: '/api/v1/Payment/get-wire-transfer-bank-account',
  postCreateBankTransfer: '/api/v1/Payment/funding/wire-transfer',
  getOwnerTradingAccounts: '/api/v1/account-structure/by-owner/$1/1',
  registerWithdrawalRequest: '/api/v1/Payment/withdrawal',
  postCreateCardTransfer: '/api/v1/Payment/funding/card-transfer',

  //  Open endpoints - publicly accessible and do not require a token.
  getCountryList: '/api/v1/country/list',
  legalDeclarationsList: '/api/v1/legal-declaration/list',
  getClientPlatforms: '/api/v1/TradingPlatform/get-client-platforms',
};

export class CRMService extends BaseRequestService implements ICRMService {
  protected getBaseUrl(): string {
    return config.crm.url;
  }

  constructor(token: string | null) {
    super(token);
    if (token) this.setToken(token);

    this.setToken = this.setToken.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.getAccountInfo = this.getAccountInfo.bind(this);
    this.getAccountDetails = this.getAccountDetails.bind(this);
    this.enrollGet = this.enrollGet.bind(this);
    this.enrollPost = this.enrollPost.bind(this);
    this.registerBinaryDocument = this.registerBinaryDocument.bind(this);
    this.enrollDelete = this.enrollDelete.bind(this);
    this.getPayments = this.getPayments.bind(this);
    this.getLinkedAccountsByIndividualId = this.getLinkedAccountsByIndividualId.bind(this);
    this.registerLinkedAccount = this.registerLinkedAccount.bind(this);
    this.getWireTransferBankAccount = this.getWireTransferBankAccount.bind(this);
    this.createBankTransfer = this.createBankTransfer.bind(this);
    this.getFinancialQuestionnaireTypes = this.getFinancialQuestionnaireTypes.bind(this);
    this.getFinancialQuestionnaireList = this.getFinancialQuestionnaireList.bind(this);
    this.getPurposeOfTrading = this.getPurposeOfTrading.bind(this);
    this.getEmploymentStatusList = this.getEmploymentStatusList.bind(this);
    this.getSourceOfFundOwn = this.getSourceOfFundOwn.bind(this);
    this.getSourceOfFundList = this.getSourceOfFundList.bind(this);
    this.getOwnerTradingAccounts = this.getOwnerTradingAccounts.bind(this);
    this.registerWithdrawalRequest = this.registerWithdrawalRequest.bind(this);
    this.postCreateCardTransfer = this.postCreateCardTransfer.bind(this);
  }

  public async getAccounts() {
    return this.api.get<any[]>(API_PATH.accountsList);
  }

  public async getAccountInfo(accountReferenceId: string) {
    return this.api.get<any[]>(API_PATH.accountInfo, { accountReferenceId });
  }

  public getAccountDetails(accountId: NullableNumber, accountReferenceId?: string) {
    return this.api.post<any[]>(API_PATH.accountDetails, {
      account_reference_id: accountReferenceId ?? null,
      account_id: accountId,
    });
  }

  public getCountryList() {
    const APIPath = API_PATH.getCountryList;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(url, CacheName.countries, HttpMethods.GET, PredefinedMilisecondsTypeEnum.oneHour);
    return this.api.get<CountryData[]>(APIPath);
  }

  public getTradingPlatforms() {
    const requestParams = {
      portalId: '10N',
      useForEnrolment: false,
    };

    const APIPath = API_PATH.getClientPlatforms;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.clientPlatforms,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'portalId', 'useForEnrolment' ],
    );

    return this.api.get<any>(APIPath, requestParams, { timeout: 3000 });
  }

  /**
   * Makes a GET enroll request with given call name and path parameters (the last is optional)
   * @param callName For example getIndividualExtendedInfo - see ENROLL_METHODS in library models
   * @param pathParams Any path parameters - in API_PATH params are marked with $N - for example $1, $2 etc.
   */
  public async enrollGet(callName: string, pathParams?: string[]) {
    let path = (API_PATH as any)[callName];
    if (!path) {
      throw new Error(`[CRM::enrollGet] No such path - path='${path}', key='${callName}'`);
    }

    const resultPath: string = applyPathParams(path, pathParams);

    return this.api.get<any>(resultPath);
  }

  public async enrollPost(callName: string, requestBody: any) {
    let path = (API_PATH as any)[callName];
    if (!path) {
      throw new Error(`[CRM::enrollPost] No such path - path='${path}', key='${callName}'`);
    }

    return this.api.post<any>(path, requestBody);
  }

  public async enrollPut(callName: string, requestBody: any, pathParams: string[] | undefined) {
    let path = (API_PATH as any)[callName];
    if (!path) {
      throw new Error(`[CRM::enrollPut] No such path - path='${path}', key='${callName}'`);
    }

    path = applyPathParams(path, pathParams);

    return this.api.put<any>(path, requestBody);
  }

  // eslint-disable-next-line max-len
  public async registerBinaryDocument(rawData: RegisterBinaryDocumentPayload|RegisterBinaryPOIDocumentPayload, token: string, fileNames: string[] = []) {
    const { isWeb, sendMultipartData } = require('../../configLib').default;
    const url = `${config.crm.url}${API_PATH.postDocumentOwnBinary}`;

    const requestBody = parseToMultipartData('poi-or-poa', rawData, fileNames);

    // Mobile
    if (sendMultipartData && !isWeb) {
      return sendMultipartData(url, token, requestBody);
    }

    // Web
    return this.api.post(API_PATH.postDocumentOwnBinary, requestBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public async registerLinkedAccount(rawData: LinkedAccountRequestData, token: string) {
    const { isWeb, sendMultipartData } = require('../../configLib').default;
    const url = `${config.crm.url}${API_PATH.registerLinkedAccount}`;

    const requestBody = parseToMultipartData('bank-account', rawData, []);

    // Mobile
    if (sendMultipartData && !isWeb) {
      return sendMultipartData(url, token, requestBody);
    }

    // Web
    return this.api.post(API_PATH.registerLinkedAccount, requestBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public async enrollDelete(callName: string, taxDetailId: number | null | undefined) {
    let path = (API_PATH as any)[callName];
    if (!path) {
      console.error(`[CRM::enrollDelete] No such path - path='${path}', key='${callName}'`);
    }

    return this.api.delete<any[]>(path.replace('$1', taxDetailId?.toString()));
  }

  getLegalDeclarationsList(active: boolean | undefined) {
    const APIPath = API_PATH.legalDeclarationsList;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.legalDeclarations,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<LegalDeclaration[]>(APIPath, { active });
  }

  postCreateCardTransfer(request: CreateCardTransferRequest) {
    return this.api.post(API_PATH.postCreateCardTransfer, request);
  }

  public getPayments(filter: PaymentsFilter): Promise<ApiResponse<Payment[]>> {
    return this.api.post<Payment[]>(API_PATH.getPaymentsAccountsIndividual, filter);
  }

  public async getLinkedAccountsByIndividualId(individualId: number) {
    return this.api.get<LinkedAccount[]>(API_PATH.getLinkedAccountsByIndividualId + individualId);
  }

  public getWireTransferBankAccount(filter: GetWireTransferBankAccountPostRequest) {
    const APIPath = API_PATH.getWireTransferBankAccount;
    const url = this.getBaseUrl() + APIPath;

    this.addOrUpdateCacheDefinition(
      url,
      CacheName.wireTransferBankAccount,
      HttpMethods.POST,
      PredefinedMilisecondsTypeEnum.oneHour,
      [ 'currency_code', 'bank_country_code', 'payment_process' ],
    );

    return this.api.post<GetWireTransferBankAccountPostRequest>(APIPath, filter);
  }

  public createBankTransfer(fundingRequestData: FundingWireTransferPostRequest) {
    return this.api.post<FundingWireTransferPostRequest>(API_PATH.postCreateBankTransfer, fundingRequestData);
  }

  public async getFinancialQuestionnaireTypes(): Promise<ApiResponse<LookupInterface>> {
    const APIPath = API_PATH.getFinancialQuestionnaireTypes;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.financialQuestionnaireTypes,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<LookupInterface>(APIPath);
  }

  public async getFinancialQuestionnaireList(): Promise<ApiResponse<FinancialQuestionnaireTypeData[]>> {
    const APIPath = API_PATH.getFinancialQuestionnaireOwnList;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.financialQuestionnaireOwnList,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<FinancialQuestionnaireTypeData[]>(APIPath);
  }

  public async getPurposeOfTrading(): Promise<ApiResponse<PurposeOfTradingData[]>> {
    const APIPath = API_PATH.getPurposeOfTrading;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.purposeOfTrading,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<PurposeOfTradingData[]>(API_PATH.getPurposeOfTrading);
  }

  public async getEmploymentStatusList(isActive: boolean = true): Promise<ApiResponse<EmploymentStatus[]>> {
    const APIPath = `${API_PATH.getEmploymentStatusOwn}?active=${JSON.stringify(isActive)}`;
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.employmentStatus,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<EmploymentStatus[]>(APIPath);
  }

  public async getSourceOfFundOwn(getActive: boolean = true): Promise<ApiResponse<SourceOfFundsOwnData[]>> {
    const APIPath = API_PATH.getSourceOfFundOwn.replace('$1', getActive.toString());
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.sourceOfFundOwn,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<SourceOfFundsOwnData[]>(APIPath);
  }

  public async getSourceOfFundList(getActive: boolean = true): Promise<ApiResponse<SourceOfFundsList[]>> {
    const APIPath = API_PATH.getSourceOfFundList.replace('$1', getActive.toString());
    const url = this.getBaseUrl() + APIPath;
    this.addOrUpdateCacheDefinition(
      url,
      CacheName.sourceOfFundList,
      HttpMethods.GET,
      PredefinedMilisecondsTypeEnum.oneHour,
    );
    return this.api.get<SourceOfFundsList[]>(APIPath);
  }

  public async getOwnerTradingAccounts(individualId: number): Promise<ApiResponse<OwnerTradingAccount[]>> {
    return this.api.get<OwnerTradingAccount[]>(API_PATH.getOwnerTradingAccounts.replace('$1', individualId.toString()));
  }

  public registerWithdrawalRequest(withdrawalRequest: WithdrawalRequest) {
    return this.api.post<any>(API_PATH.registerWithdrawalRequest, withdrawalRequest);
  }

  public async getLegalDeclarationsDynamicFile(templateName: string) {
    return this.api.get<string>(API_PATH.getLegalDeclarationsDynamicFile, {
      templateName,
    });
  }
}
