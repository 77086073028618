import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { News } from '../../../lib/models/news';
import { RootState } from '../../../lib/store';
import {
  getLatestNews as serviceGetLatestNews,
  getNewsForSymbol as serviceGetNewsForSymbol,
} from '../../../lib/store/news';
import {
  isLoggedIn as serviceIsLoggedIn,
  isNewsAvailable as serviceIsNewsAvailable,
} from '../../../lib/store/selectors';

import LibraryConfig from '../../../configLib';
import { useIsMobile } from '../../../hooks';
import LatestNewsContent from '../LatestNewsContent/LatestNewsContent';
import NewsCard from '../NewsCard/NewsCard';

import styles from './LatestNews.module.scss';

const {
  login: gwEnabled,
  latestNews: gwLatestNewsEnabled,
  newsForSymbol: gwNewsForSymbolEnabled,
} = LibraryConfig.gatewayEnabled;

type OwnProps = {
  selectedSymbol?: string;
};
type Props = OwnProps & ReduxProps;

const LatestNews = (
  {
    latestNews,
    newsForSymbols,
    isLoggedIn,
    gatewayConnected,
    isLoading,
    isNewsAvailable,
    getLatestNews,
    getNewsForSymbol,
    selectedSymbol,
  }: Props,
): JSX.Element => {
  const isMobile = useIsMobile();
  const [isMounted, setIsMounted] = useState(false);

  const newsCards = useMemo(() => {
    let data: News[];

    if (selectedSymbol != null) {
      data = newsForSymbols[selectedSymbol] || [];
    } else {
      data = latestNews;
    }

    return data?.map((item: News) => <NewsCard key={item.id} item={item} />);
  }, [ latestNews, newsForSymbols, selectedSymbol ]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <h6 className={isMobile ? styles.headerTextMobile : styles.headerText}>{lang.commonDiscoverNews()}</h6>
      <LatestNewsContent
        isLoading={isLoading}
        gwEnabled={gwEnabled}
        newsCards={newsCards}
        isMounted={isMounted}
        isLoggedIn={isLoggedIn}
        selectedSymbol={selectedSymbol}
        isNewsAvailable={isNewsAvailable}
        gatewayConnected={gatewayConnected}
        gwLatestNewsEnabled={gwLatestNewsEnabled}
        gwNewsForSymbolEnabled={gwNewsForSymbolEnabled}
        getLatestNews={getLatestNews}
        getNewsForSymbol={getNewsForSymbol}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isNewsAvailable: (selectedSymbol?: string, hasMore?: boolean) =>
    serviceIsNewsAvailable(state, selectedSymbol, hasMore),
  isLoggedIn: serviceIsLoggedIn(state),
  newsForSymbols: state.news.newsForSymbols,
  latestNews: state.news.latestNews,
  isLoading: state.news.isLoading,
  gatewayConnected: state.gateway.connected,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getLatestNews: (page?: number, pageSize?: number) =>
    dispatch(serviceGetLatestNews({ page, pageSize })),
  getNewsForSymbol: (symbol: string, page?: number, pageSize?: number) =>
    dispatch(serviceGetNewsForSymbol({ symbol, page, pageSize })),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LatestNews);
