import React, { Dispatch, useEffect, useLayoutEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Action } from 'redux';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory';

import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store/index';
import { ChartDataRange } from '../../../lib/store/market-data/charting/types';
import { formatOrdinateValues, oneValueRounding, prepareMyAccountChartData } from '../../../lib/store/reporting/charting/helpers';
import { getNetEquityComponents as getNetEquity } from '../../../lib/store/reporting/index';
import { GetMyAccountChartDataPayload, NetComponent } from '../../../lib/store/reporting/types';
import { getCurrentAccount, getCurrentBuyingPower } from '../../../lib/store/selectors';
import { roundValue } from '../../../lib/util/ChartingHelpers';
import { checkIfAllIsNegative, formatCurrency } from '../../../lib/util/DataHelpers';
import { getDateTime } from '../../../lib/util/DateTimeHelpers';
import { isCallStatusError, isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';
import {
  calculateMyAccountValueChangeAndPecent,
  calculateNetEquityComponentsFromDate,
  checkChartForNegativeValue,
} from '../../../lib/util/my-account-helpers';

import iconExpandDown from '../../../assets/img/icon-expand-more-down.svg';
import noTradesPlaceholder from '../../../assets/img/line-chart_placeholder.svg';
import noTradesPlaceholderMobile from '../../../assets/img/line-chart_placeholder_mobile.svg';
import { useIsMobile } from '../../../hooks';
import { ClickableImage, Preloader } from '../../index';
import { CHART_CONTAINER_PADDING, CHART_CONTAINER_WIDTH } from '../constants';
import GradientFill from '../GradientFill';
import ScopeButtons from '../ScopeButtons/ScopeButtons';

import CustomTooltip from './ChartComponents/CustomTooltip';
import ScatterPoint from './ChartComponents/ScatterPoint';
import PerformanceChangeHeader from './PerformanceChange/PerformanceChangeHeader';
import PerformanceChangeTotalValue from './PerformanceChange/PerformanceChangeTotalValue';
import useCurrentValue from './useCurrentValue';
import ErrorState from './ЕrrorState';

import styles from './chart.module.scss';

type OwnProps = {
  isSmallScreen?: boolean
  handleChartPopUp?: (value: boolean) => void;
}
type Props = ReduxProps & OwnProps;

const toDate = getDateTime();

const Chart = ({
  netIncomeComponents,
  accountReferenceId,
  buyingPower,
  hasDeposit,
  isAccountSummaryLoaded,
  isNetComponentsLoaded,
  isOpenPositionsLoaded,
  hasMyStocksOrderUpdate,
  getNetEquityComponents,
  isSmallScreen,
  handleChartPopUp,
}: Props): JSX.Element => {
  const [showLastPriceValue, setShowLastPriceValue] = useState<boolean>(false);
  const [tickValues, setTickValues] = useState<number[]>([]);
  const [chartValue, setChartValue] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [currentRange, setCurrentRange] = useState<ChartDataRange>('1M');
  const [currentDate, setCurrentDate] = useState<string>(lang.performanceRangeTextToday());
  const [netComponent, setNetComponent] = useState<string>(NetComponent.NetIncome);
  const [hasNegativeValues, setHasNegativeValues] = useState<boolean>(false);
  const [chartWrapperHeight, setChartWrapperHeight] = useState<number>(0);
  const [showPerformance, setShowPerformance] = useState<boolean>(true);
  const [todayAbsoluteChange, setTodayAbsoluteChange] = useState<number>(0);
  const [periodAbsoluteChange, setPeriodAbsoluteChange] = useState<number>(0);
  const [absoluteCurrentChange, setAbsoluteCurrentChange] = useState<number>(0);
  const [periodPercentChange, setPeriodPercentChange] = useState<number | undefined>(undefined);
  const [persentChange, setPersentChange] = useState<number | undefined>(undefined);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const { currentValue } = useCurrentValue(
    showPerformance, chartValue, netComponent, currentRange, hasMyStocksOrderUpdate,
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    calculateMyAccountValueChangeAndPecent(
      currentRange,
      currentValue,
      data,
      netComponent,
      netIncomeComponents,
      setAbsoluteCurrentChange,
      setPeriodAbsoluteChange,
      setPeriodPercentChange,
      setPersentChange,
      setTodayAbsoluteChange,
      showPerformance,
    );
  }, [currentValue, currentRange, data, netComponent, netIncomeComponents, showPerformance]);

  useEffect(() => {
    const fromDate = calculateNetEquityComponentsFromDate(currentRange);
    getNetEquityComponents({ accountReferenceId, fromDate, toDate });
  }, [accountReferenceId, getNetEquityComponents, currentRange]);

  useLayoutEffect(() => {
    function updateSize() {
      const windowWitdth = document.getElementById('charContainer')?.offsetWidth;
      if (windowWitdth) {
        setChartWrapperHeight(isMobile ? windowWitdth / 2 : windowWitdth / 3);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [isMobile]);

  useEffect(() => {
    checkChartForNegativeValue(netIncomeComponents, netComponent, setHasNegativeValues);
  }, [netComponent, netIncomeComponents]);

  useEffect(() => {
    if (!hasMyStocksOrderUpdate) {
      setShowLastPriceValue(isOpenPositionsLoaded && isAccountSummaryLoaded);
    }
  }, [hasMyStocksOrderUpdate, isAccountSummaryLoaded, isOpenPositionsLoaded]);

  const renderEmptyDataManager = () => {
    if (!hasDeposit || !netIncomeComponents.length) {
      return (
        <img className={styles.backgroundImage} src={isMobile ? noTradesPlaceholderMobile : noTradesPlaceholder} alt="icon reports" />
      );
    }
    return (
      <ErrorState
        payload={{
          accountReferenceId,
          fromDate: getDateTime(true, [ 'minus', 'minus' ], [ [ 1, 'months' ], [1, 'day']]),
          toDate,
        }}
      />
    );
  };

  useEffect(() => {
    let timeout;
    const clearFunc = (() => clearTimeout(timeout));
    if (!netIncomeComponents) return clearFunc;
    if (netIncomeComponents && netIncomeComponents.length) {
      const len = netIncomeComponents.length;
      const values: number[] = Array(len).fill(1).map((item, index) => index);
      setTickValues(values);
    }
    return clearFunc;
  }, [netIncomeComponents]);

  useEffect(() => {
    if (netIncomeComponents && isNetComponentsLoaded) {
      const {
        lineDiff,
        minChartPoint,
        lineData,
        axisValue,
        yLabelPoints,
        step,
        aMonth,
        zeroLineHeight,
        isPeriodShorterThanRange,
      } = prepareMyAccountChartData(netIncomeComponents, currentRange, netComponent);
      setData({
        lineDiff,
        minChartPoint,
        lineData,
        axisValue,
        yLabelPoints,
        step,
        aMonth,
        zeroLineHeight,
        isPeriodShorterThanRange });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netComponent, netIncomeComponents, isNetComponentsLoaded]);

  useEffect(() => {
    setIsUserVerified(!(!data || !hasDeposit || !netIncomeComponents.length));
  }, [data, hasDeposit, netIncomeComponents.length]);

  const renderCurrentValue = () => {
    if (!hasDeposit) {
      return formatCurrency(0);
    }
    if (showLastPriceValue) {
      return formatCurrency(currentValue);
    }

    return <Preloader style={{ width: '16px' }} text="" />;
  };

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.wrapperDetails}>
        <div className={styles.details}>
          <div className={styles.performanceData}>
            <div className={styles.balance}>
              <h4 className={styles.detailsValue}>
                {renderCurrentValue()}
              </h4>
              {isSmallScreen && isUserVerified && (
              <ClickableImage
                id="showPrtfolioChart"
                alt="portfolio pie chart"
                src={iconExpandDown}
                className={styles.iconExpand}
                onClick={() => handleChartPopUp && handleChartPopUp(true)}
              />
              )}
            </div>
            <div className={styles.wrapperPastPeriod}>
              {data && (
              <PerformanceChangeHeader
                date={currentDate}
                isPrevDayExist={netIncomeComponents?.length > 1}
                isChartPointed={!showPerformance}
                absolutePeriodChange={absoluteCurrentChange}
                todayAbsoluteChange={todayAbsoluteChange}
                persentPeriodChange={persentChange}
                isProfitAndLoss={netComponent === NetComponent.PnL}
              />
              )}
            </div>
            { !isMobile && (
              <>
                <div className={styles.verticalLine} />
                <div>
                  <span className={styles.powerText}>{lang.commonMyAccountBuyingPowerLabel()}</span>
                  <span className={styles.powerValue}>{formatCurrency(buyingPower)}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.wrapperChart}>
        {!isMobile && (
          <ScopeButtons
            isMyAccountSection
            changeRange={(range: ChartDataRange) => setCurrentRange(range)}
          />
        )}
        <div className={styles.performanceWrapper}>
          {
          data
          && showPerformance
          && netComponent === NetComponent.NetIncome
          && isNetComponentsLoaded
          && hasDeposit
          && !!netIncomeComponents.length
          && (
            <PerformanceChangeTotalValue
              range={currentRange}
              persentPeriodChange={periodPercentChange}
              absolutePeriodChange={periodAbsoluteChange}
              firstDate={netIncomeComponents[0].date}
              isPeriodShorterThanRange={data?.isPeriodShorterThanRange}
            />
          )
          }
        </div>
        <div id="charContainer" style={{ height: `${chartWrapperHeight}px` }}>
          {isUserVerified ? (
            <VictoryChart
              width={CHART_CONTAINER_WIDTH}
              padding={{ ...!isMobile ? CHART_CONTAINER_PADDING : {}, top: 70 }}
              maxDomain={{ x: data.lineData.length < data.aMonth ? data.aMonth - 1 : data.lineData.length - 1 }}
              minDomain={{ x: 0, y: 0 }}
              events={[{
                childName: 'all',
                target: 'parent',
                eventHandlers: {
                  onMouseLeave: () => ({
                    mutation: () => {
                      setShowPerformance(true);
                    },
                  }),
                },
              }]}
              containerComponent={(
                <VictoryVoronoiContainer
                  labels={({ datum }) => `${Math.round(datum.x)}, ${Math.round(datum.y)}`}
                  onActivated={(d) => {
                    setShowPerformance(false);
                    setChartValue(d.length ? d[0].value : 0);
                    setCurrentDate(d.length ? d[0].date : lang.performanceRangeTextToday());
                  }}
                  labelComponent={(
                    <CustomTooltip fillColor={'#3BB0FF'} showPerformance={showPerformance} />
                    )}
                />
                )}
            >
              {netIncomeComponents.length === 1
                && (
                <VictoryScatter
                  data={[{ ...data.lineData[0], x: 0, y: (data.yLabelPoints[1] - data.minChartPoint) }]}
                  dataComponent={(
                    <ScatterPoint fillColor={'#3BB0FF'} />
                  )}
                />
                )}
              {!isMobile && (
              <VictoryAxis
                dependentAxis
                fixLabelOverlap
                tickFormat={(t) => {
                  const normMinChartPoint = t + data.minChartPoint;
                  if (data.lineDiff === 0) {
                    return formatOrdinateValues(
                      Number(oneValueRounding(
                        normMinChartPoint,
                        data.yLabelPoints[0], data.yLabelPoints[1], data.yLabelPoints[2], data.step,
                      )),
                    );
                  }

                  return formatOrdinateValues(Number(roundValue(normMinChartPoint, data.step)));
                }}
                tickValues={data.yLabelPoints.map(el => el - data.minChartPoint)}
                orientation={'left'}
                offsetX={10}
                style={{
                  axis: { stroke: 'none' },
                  tickLabels: { fontSize: isMobile ? 18 : 12, fill: '#A0A3B9', textAnchor: 'start' },
                }}
              />
              )}
              <VictoryGroup data={data.lineData}>
                <GradientFill color={'#3BB0FF'} id={'chart'} />
                <VictoryLine
                  width={CHART_CONTAINER_WIDTH}
                  animate={{
                    duration: 500,
                    onLoad: { duration: 0 },
                  }}
                  style={{ data: { stroke: '#3BB0FF', strokeWidth: 3 } }}
                />
                <VictoryArea
                  width={CHART_CONTAINER_WIDTH}
                  style={{ data: { fill: 'url(#chart)' } }}
                  animate={{
                    duration: 500,
                    onLoad: { duration: 0 },
                  }}
                />
              </VictoryGroup>
              {(hasNegativeValues && !checkIfAllIsNegative(data.yLabelPoints)) && (
              <VictoryAxis
                crossAxis={false}
                style={{
                  axis: { stroke: '#c6c8da', strokeDasharray: 3 },
                  tickLabels: { fontSize: 0 },
                }}
                offsetY={data.zeroLineHeight}
              />
              )}
              {!isMobile && (
              <VictoryAxis
                tickFormat={index => data.axisValue[index]}
                offsetY={20}
                tickValues={tickValues}
                crossAxis={false}
                style={{
                  axis: { stroke: '#A0A3B9' },
                  ticks: { stroke: '#A0A3B9', size: (datum) => (data.axisValue[datum?.tick] ? 5 : 0) },
                  tickLabels: { fontSize: 15, padding: 0, fill: '#A0A3B9' },
                }}
              />
              )}
            </VictoryChart>
          ) : renderEmptyDataManager()}
        </div>
        {isMobile && (
          <div className={classNames(styles.scopeButtons, { [styles.disabled]: !isUserVerified })}>
            <ScopeButtons
              isDisabled={!isUserVerified}
              isMyAccountSection
              changeRange={(range: ChartDataRange) => setCurrentRange(range)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  netIncomeComponents: state.reporting.netIncomeComponents,
  accountReferenceId: getCurrentAccount(state),
  buyingPower: getCurrentBuyingPower(state),
  hasDeposit: state.payment.hasDeposit,
  hasMyStocksOrderUpdate: state.reporting.hasMyStocksOrderUpdate,
  isNetComponentsLoaded: isCallStatusReady(ReportingCall.getNetEquityComponents, state.reporting.statusByCall),
  isOpenPositionsLoaded: isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall)
  || isCallStatusError(ReportingCall.getOpenPositions, state.reporting.statusByCall),
  isAccountSummaryLoaded: isCallStatusReady(ReportingCall.getAccountSummary, state.reporting.statusByCall)
  || isCallStatusError(ReportingCall.getAccountSummary, state.reporting.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getNetEquityComponents: (payload: GetMyAccountChartDataPayload) => dispatch(getNetEquity(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Chart);
