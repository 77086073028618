import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { RootState } from '../../lib/store';
import { isUserVerifiedStatus } from '../../lib/store/selectors';

import config from '../../config';
import { useIsMobile } from '../../hooks';
import { UI_ROUTES } from '../../util/routes';

import { MENU_LIST } from './Downbar.constants';
import { DownBarItem } from './Downbar.types';

import styles from './Downbar.module.scss';

const MIDDLE_WIDTH = 960;

type Props = {} & ReduxProps;

const Downbar = (props: Props) => {
  const { individual, hasDeposit } = props;
  const isMobile = useIsMobile();
  const location = useLocation();
  const isUserVerified = isUserVerifiedStatus(individual);

  const [destination, setDestination] = useState<string>(location.pathname);

  MENU_LIST[3].path = (!isUserVerified || hasDeposit)
    ? UI_ROUTES.funds
    : UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit;

  const options = () => (
    MENU_LIST.map((item: DownBarItem) => (
      <button
        type="button"
        className={location.pathname === item.path ? styles.activeButton : styles.button}
        key={item.id}
      >
        <NavLink
          id={item.id}
          key={item.id}
          to={{
            pathname: item.path,
            state: {
              isClickedBtnSidebar: true,
            },
          }}
          onClick={() => { setDestination(item.path); }}
        >
          <div className={styles.image}>{item.img}</div>
          <div className={location.pathname === item.path ? styles.activeLabel : styles.label}>{item.label}</div>
        </NavLink>
      </button>
    )));

  return (
    <div className={styles.Wrapper}>
      <div className={styles.buttonsWrapper}>
        {options()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
  hasDeposit: state.payment.hasDeposit,
});
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Downbar);
