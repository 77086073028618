import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { CallStatus } from '../../lib/enums';
import { RootState } from '../../lib/store';
import { getIndividualExtendedInfo } from '../../lib/store/common-actions';
import { PaymentCall } from '../../lib/store/payment/types';
import { isUserVerifiedStatus } from '../../lib/store/selectors';

import {
  LatestNews,
  NotVerifiedNotification,
  Preloader,
  WatchList,
} from '../../components';
import { useIsMobile } from '../../hooks';

import styles from './Overview.module.scss';

type OwnProps = {
  selectedSymbol: string;
}

type Props = OwnProps & ReduxProps;

const Overview = (props: Props) => {
  const {
    individualData,
    selectedSymbol,
    isPaymentsReady,
    isUserVerified,
    transactions,
    getIndividual,
  } = props;
  const isMobile = useIsMobile();
  useEffect(() => {
    if (!individualData) {
      getIndividual();
    }
  },
  [getIndividual, individualData]);

  return (
    <>
      {
        !individualData
          ? <div className={styles.loader}><Preloader owner="Overview" /></div>
          : (
            <div className={styles.wrapperLayout}>
              <div className={styles.wrapperFirstSection}>
                <div className={isUserVerified && transactions.length === 0 ? styles.fundBox : styles.verificationBox}>
                  <NotVerifiedNotification
                    individual={individualData}
                    hasUserName={!isPaymentsReady}
                    isPaymentsReady={isPaymentsReady}
                  />
                </div>
                <WatchList />
              </div>
              {isMobile ? null : (
                <div className={styles.wrapperSecondSection}>
                  <LatestNews selectedSymbol={selectedSymbol} />
                </div>
              )}
            </div>
          )
        }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  individualData: state.crm.individualExtendedInfo,
  isPaymentsReady: state.payment.statusByCall[PaymentCall.getPaymentsAll]?.status === CallStatus.READY,
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
  transactions: state.payment.paymentsAll.data,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getIndividual: () => dispatch(getIndividualExtendedInfo()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Overview);
