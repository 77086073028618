import React from 'react';

import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import { SourceOfFundsList } from '../../../lib/models/enroll';

import {
  EnrollAtHomeTrading,
  EnrollFamilyMember,
  EnrollInheritance,
  EnrollInvestmentsSavings,
  EnrollLoan,
  EnrollPension,
  EnrollSalary,
  EnrollSelfEmployed,
} from '../../index';
import { GeneralQuestionsCustomAnswers, ValidationFieldName } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';

import styles from './EnrollSourceOfFunds.module.scss';

type Props = {
  selectedAnswer: SourceOfFundsList;
  formData: GeneralQuestionsCustomAnswers;
  handleChange: (formKey: string, value: string | number) => void;
  isClickedBtnContinue: boolean;
  errorLabelName: React.Dispatch<React.SetStateAction<string>>;
  validationFieldName: React.Dispatch<React.SetStateAction<string>>;
}

const EnrollSourceOfFunds = ({
  selectedAnswer,
  formData,
  isClickedBtnContinue,
  handleChange,
  errorLabelName,
  validationFieldName,
}: Props) => {
  const content = ((): JSX.Element => {
    switch (selectedAnswer.id) {
      case SourceOfFundEnum.SalaryAlaric:
        errorLabelName(lang.commonSourceOfFundsNameOfEmplLabel());
        validationFieldName(ValidationFieldName.IndividualAddress);
        return (
          <EnrollSalary
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.SelfEmploymentAlaric:
      case SourceOfFundEnum.SelfEmploymentFlb:
        errorLabelName(lang.commonSourceOfFundsNameOfCustomer());
        validationFieldName(ValidationFieldName.IndividualAddress);
        return (
          <EnrollSelfEmployed
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.AtHomeTradingAlaric:
      case SourceOfFundEnum.AtHomeTradingFlb:
        errorLabelName(lang.commonSourceOfFundsBrokerNameLabel());
        return (
          <EnrollAtHomeTrading
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.FamilyMemberAlaric:
      case SourceOfFundEnum.ParentalAllowanceFlb:
        validationFieldName(ValidationFieldName.IndividualName);
        errorLabelName(lang.commonSourceOfFundsFamMemberNameLabel());
        return (
          <EnrollFamilyMember
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.InheritanceFlb:
      case SourceOfFundEnum.InheritanceAlaric:
        validationFieldName(ValidationFieldName.Inheritance);
        errorLabelName(lang.commonSourceOfFundsNameOfDeceasedLabel());
        return (
          <EnrollInheritance
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.InvestmentsAndSavingsFlb:
      case SourceOfFundEnum.InvestmentsAndSavingsAlaric:
        validationFieldName(ValidationFieldName.InvestmentAndSavingsSpecify);
        errorLabelName(lang.commonSourceOfFundsInvestmentSavingsTypesTitle());
        return (
          <EnrollInvestmentsSavings
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.PensionOrBenefitProgramAlaric:
      case SourceOfFundEnum.PensionOrGovernmentBenefitsFlb:
        errorLabelName(lang.commonGeneralQuestionsTypeLabel());
        return (
          <EnrollPension
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      case SourceOfFundEnum.LoanAlaric:
        errorLabelName(lang.commonSourceOfFundsLenderNameLabel());
        return (
          <EnrollLoan
            formData={formData}
            handleChange={handleChange}
            isClickedBtnContinue={isClickedBtnContinue}
          />
        );
      default:
        return <></>;
    }
  })();

  return (
    <>
      <h3 className={styles.title}>{selectedAnswer.name}</h3>
      <p className={styles.subTitle}>{selectedAnswer.explanatory_text}</p>
      {content}
    </>
  );
};

export default EnrollSourceOfFunds;
