import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LinkedAccountStatusEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { LinkedAccount } from '../../lib/models/linked-account/types';
import { RootState } from '../../lib/store';
import { updateSelectedLinkedAccountIndex } from '../../lib/store/payment';

import iconAddNew from '../../assets/img/icon-add_new_no_background.svg';
import leftArrow from '../../assets/img/icon-arrow-left_24px.svg';
import rightArrow from '../../assets/img/icon-arrow-right_24px.svg';
import iconBank from '../../assets/img/icon-bank.svg';
import { ClickableDiv } from '../../components';
import { useIsMobile } from '../../hooks';
import { UI_ROUTES } from '../../util/routes';

import ContentBankDropDown from './enterAmount/components/ContentBankDropDown';

import styles from './Funds.module.scss';

type Props = {
  linkedAccounts: LinkedAccount[];
  isUserVerified: boolean;
}

const LinkedAccountSlideCard = (props: Props) => {
  const { linkedAccounts, isUserVerified } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedAccountIndex = useSelector<RootState, number>((state: RootState) =>
    state.payment.selectedLinkedAccountIndex);

  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [isBankMenuOpened, setIsBankMenuOpened] = useState<boolean>(false);

  const handleBtnAddAccount = () => {
    history.push({
      pathname: UI_ROUTES.funds + UI_ROUTES.addYourBankAccount,
      state: {
        previousPage: UI_ROUTES.funds },
    });
  };

  const handleSlideCard = (linkedAccount: LinkedAccount, id: string) => (
    <ClickableDiv
      id={id}
      key={linkedAccount.id}
      className={styles.wrapperSlideCardBankTransfer}
      onClick={handleOpenBankMenu}
    >
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={iconBank} alt="icon bank" />
      </div>
      <div className={styles.wrapperInfo}>
        <div className={styles.statusBank}>
          <div>
            <p className={styles.transferText}>{linkedAccount.account_info.bank_name}</p>
            <p className={styles.iban}>
              {linkedAccount.account_info.iban
                ? linkedAccount.account_info.iban
                : linkedAccount.account_info.account}
            </p>
          </div>
          {linkedAccount.status === LinkedAccountStatusEnum.New
           && <p className={styles.pending}>{lang.commonOrderStatusPending()}</p>}
        </div>
      </div>
    </ClickableDiv>
  );

  const handleOpenBankMenu = () => {
    document.body.classList.add('modal-open');
    setIsBankMenuOpened(true);
  };

  const handleCloseBankMenu = () => {
    document.body.classList.remove('modal-open');
    setIsBankMenuOpened(false);
  };

  const handleSelectBank = (bank) => {
    handleCloseBankMenu();
    const index = linkedAccounts.findIndex(account => account.id === bank.linkedAccount.id);
    dispatch(updateSelectedLinkedAccountIndex(index));
  };

  return (
    <>
      {isBankMenuOpened && (
        <ContentBankDropDown
          closeModal={handleCloseBankMenu}
          handleClickFundAccount={handleSelectBank}
          convertedLinkedAccounts={linkedAccounts}
        />
      )}
      <div className={styles.wrapperSlideHeader}>
        <h6 id="bankAccountsTitle" className={styles.title}>{lang.mobileFundsScreenBankAccounts()}</h6>
        {
          isUserVerified && linkedAccounts.length > 1 && (
          <ClickableDiv className={styles.btnAddBankAccount} onClick={handleBtnAddAccount}>
            <img src={iconAddNew} alt="icon small plus" />
            <span>{lang.mobileFundsScreenAddBankAccModalBtn()}</span>
          </ClickableDiv>
          )
        }
      </div>
      <div className={styles.wrapperSlideBankAccounts}>
        {
            isUserVerified && linkedAccounts.length > 0 && handleSlideCard(linkedAccounts[selectedAccountIndex], `linkedAccount_${selectedAccountIndex}`)
        }
        {
            (linkedAccounts.length === 0 || linkedAccounts.length === 1) && (
            <ClickableDiv id="addBankAcount" className={styles.slideBtnAddBankAccount} onClick={handleBtnAddAccount}>
              <img src={iconAddNew} alt="icon small plus" />
              <span>{lang.mobileFundsScreenAddBankAccModalBtn()}</span>
            </ClickableDiv>
            )
        }
        {!isMobile && (
          <>
            {
              slideIndex + 2 < linkedAccounts.length && (
              <button onClick={() => setSlideIndex(index => index + 2)} type="button" className={styles.buttonNext}>
                <img src={rightArrow} alt="right arrow" />
              </button>
              )
            }
            {
              slideIndex > 0 && (
              <button onClick={() => setSlideIndex(index => index - 2)} type="button" className={styles.buttonPrevious}>
                <img src={leftArrow} alt="left arrow" />
              </button>
              )
            }
          </>
        )}
      </div>
    </>
  );
};

export default LinkedAccountSlideCard;
