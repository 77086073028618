import { PayloadAction } from '@reduxjs/toolkit';
import { isArray, uniq } from 'lodash';

import { logConfig } from '../../configDebug';
import configLib from '../../configLib';
import { USE_NORMALIZED_ENTITY_STATE } from '../debug/debugSettings';
import { ENTITY_DEBUG_ACTION_TYPE } from '../debug/helpers/constants';
import { DebugSlice } from '../debug/helpers/types';
import { getProp, isNull } from '../util/ObjectTools';
import { TypedObject } from '../util/types';

import { parseSymbolPriceSourceData } from './market-data/helpers';

export function logReducer(state: any, action: any) {
  const { __DEV__ } = configLib;
  if (__DEV__ && !isNull(action.payload, 'payload.error')) {
    const error = getProp(action, 'payload.error.message');
    console.error(`Error in action ${action.type} - ${error}`, action);
    console.tron?.error?.(`Error in action ${action.type} - ${error}`, action);
  }
  if (__DEV__ && logConfig.actions) {
    const { caller, symbols, symbolOrSymbols } = action?.payload ?? {};
    const theSymbols = symbols ?? symbolOrSymbols;
    const symbolsAsString = isArray(theSymbols) ? theSymbols.join() : theSymbols ?? '';
    console.info(`[Redux-Action] '${action.type}' ${caller ? `\n[Caller] ${caller}` : ''}\n${symbolsAsString}`);
    if (logConfig.actions >= 2) console.debug(`[Redux-Action] '${action.type}' ${caller ? `\n[Caller] ${caller}` : ''}\n${symbolsAsString}`, action.payload);
  }

  return state ?? {};
}

function normaliseDebugEntityState(slice: DebugSlice, nextState: any) {
  if (!USE_NORMALIZED_ENTITY_STATE) return nextState;

  switch (slice) {
    case 'subscribes': {
      if (!nextState?.data) return nextState;
      const normalizedState = { ...nextState };
      const { data } = normalizedState;
      const result: TypedObject<TypedObject<string>> = {};
      const groups: TypedObject<TypedObject<string[]>> = {};
      for (const group in data) {
        const groupData = data[group];
        const feeds = uniq(Object.values(groupData));
        groups[group] = {};
        result[group] = {};
        feeds.forEach(feed => { groups[group][feed as string] = [] as string[]; });
        for (const symbol in groupData) {
          const feed = groupData[symbol];
          groups[group][feed].push(symbol);
        }
        for (const feed in groups[group]) {
          const symbols = groups[group][feed];
          result[group][feed === 'false' ? ' ' : feed.toUpperCase()] = `(${symbols.length})   ${symbols.sort().join()}`;
        }
        normalizedState.data = result;
      }
      return normalizedState;
    }

    default:
      return nextState;
  }
}

export function debugEntityReducer(
  state: any,
  action: PayloadAction<{name: string, slice: DebugSlice, prev: any, next: any}>,
) {
  if (action.type !== ENTITY_DEBUG_ACTION_TYPE) return state ?? {};

  const { name, slice, prev, next } = action.payload ?? {};
  if (state) state[slice] = next ? normaliseDebugEntityState(slice, next) : {};

  return state ?? {};
}

export function debugReducer(
  state: any,
  action: PayloadAction<{slice: DebugSlice, data: any}>,
) {
  if (action.type !== 'debug') return state ?? {};

  const { slice, data } = action.payload ?? {};
  if (state) state[slice] = data ?? {};

  return state ?? {};
}
