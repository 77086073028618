import React from 'react';

import styles from './BaseTable.module.scss';

type Props = {
  children: any;
  id?: string
  className?: string;
  borderless?: boolean;
};

const BaseTable = ({ id, children, className, borderless = false }: Props) => (
  <table id={id} className={[styles.table, className].join(' ')}>
    { children }
  </table>
);

export default BaseTable;
