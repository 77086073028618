import React from 'react';

import lang from '../../../../lib/language';
import { formatNumber } from '../../../../lib/util/DataHelpers';

type Props = {
  sorExecutedQuantity: number,
  totalQuantity: number,
  className: string | undefined,
  isMobile?: boolean;
}

const PendengOrdersQuantity = ({ sorExecutedQuantity, totalQuantity, className, isMobile }: Props) => (
  <>
    {sorExecutedQuantity && sorExecutedQuantity < totalQuantity ? (
      <>
        <div>
          <span>{`${sorExecutedQuantity} / ${totalQuantity.toString()}`}</span>
        </div>
        <div className={className}>{lang.commonMyAccountPendingOrdersPartiallFill()}</div>
      </>
    ) : (
      <>{!isMobile && <div>{formatNumber(totalQuantity, true)}</div>}</>
    )}
  </>
);

export default PendengOrdersQuantity;
