import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { BBODataCache, InstrumentSnapshotMap } from '../store/market-data/types';
import { NullableNumber } from '../util/types';


type CalculatedNBBOPrice = {
  symbol: string
  askprice: NullableNumber
  bidprice: NullableNumber
}
type CalculatedNBBOPriceData = Partial<Record<string, CalculatedNBBOPrice>>

const NBBOPricesCache = entity({} as CalculatedNBBOPriceData, entityReduxLogger('NBBO', 'nbbo'));

export const generateEmptyNBBOPrice = (symbol: string) => ({
  symbol,
  askprice: null,
  bidprice: null,
} as CalculatedNBBOPrice);

type DataSourceType = 'instr' | 'nbbo';

function updateNewState(
  newCache: BBODataCache | InstrumentSnapshotMap,
  state: CalculatedNBBOPriceData,
  sourceType: DataSourceType,
) {
  for (const symbol in newCache) {
    const cachedItem: CalculatedNBBOPrice = generateEmptyNBBOPrice(symbol);
    state[symbol] = cachedItem;
    cachedItem.symbol = symbol;
    switch (sourceType) {
      case 'instr': {
        const instumentSnapshot = (newCache as InstrumentSnapshotMap)[symbol];
        const { askPrice, bidPrice } = instumentSnapshot;
        cachedItem.askprice = askPrice;
        cachedItem.bidprice = bidPrice;
        break;
      }
      case 'nbbo': {
        const nbbo = (newCache as BBODataCache)[symbol];
        const { bidprice, biddirection, askprice, askdirection } = nbbo;
        cachedItem.askprice = askprice;
        cachedItem.bidprice = bidprice;
        break;
      }
      default:
        break;
    }
  }
}

/** Used only by Gateway service to update cache from response messages. */
export const updateNBBOPricesCache = (newItems: BBODataCache | InstrumentSnapshotMap, sourceType: DataSourceType) => {
  NBBOPricesCache.set(
    produce(state => updateNewState(newItems, state, sourceType)),
  );
};


// exporting setter only for unit testing and mocked mode
export const _setNBBOPricesCache_InUnitTestsAndMockedMode = NBBOPricesCache.set; // eslint-disable-line no-underscore-dangle

// hiding direct setter by not including it in exports
export default {
  use: (symbol: string) => NBBOPricesCache.use()[symbol] ?? generateEmptyNBBOPrice(symbol),
  get: (symbol: string) => NBBOPricesCache.get()[symbol] ?? generateEmptyNBBOPrice(symbol),
  getAll: NBBOPricesCache.get,
  useAll: NBBOPricesCache.use,
};
