import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { PeopleAlsoTradeItem, PeopleAlsoTradeParams, ReportingCall } from '../../models/reporting/types';
import GlobalService from '../../services/Global.service';
import CallsCache from '../../store-util/calls-cache/CallsCache';
import { getDateTime } from '../../util/DateTimeHelpers';
import { isCallStatusPendingQueued } from '../../util/error-handling/StatusByCallHelpers';
import { extractSymbolsFromListAsString } from '../../util/MarketDataHelpers';
import { OrderTicketComponent } from '../../util/types';
import {
  getAccountsAvailableCash,
  getAccountsAvailableCashFailed,
  getAccountsAvailableCashSuccess,
  getOpenPositionsSuccess,
} from '../common-actions';
import { processErrorInResponse, processResponse } from '../helpers';
import { getCustomWatchlistData } from '../market-data';
import { getCurrentAccount } from '../selectors';
import { RootState } from '..';

import {
  EMPTY_ACCOUNT_SUMMARY,
  ORDERS_REQUEST_PAGE_NUMBER,
  ORDERS_REQUEST_ROWS_PER_PAGE,
  PENDING_ORDERS_REQUEST_FROM_DATE,
} from './constants';
import {
  getAccountPendingOrdersSuccess,
  getAccountSummary,
  getAccountSummaryFailed,
  getAccountSummarySuccess,
  getAccountTradeHistory,
  getAccountTradeHistoryFailed,
  getAccountTradeHistorySuccess,
  getNetEquityComponents,
  getNetEquityComponentsFailed,
  getNetEquityComponentsSuccess,
  getOpenPositions,
  getOpenPositionsFailed,
  getOrderDetail,
  getOrderDetailFailed,
  getOrderDetailSuccess,
  getPeopleAlsoTradeData,
  getPeopleAlsoTradeDataCompleted,
  getPeopleAlsoTradeDataFailed,
} from './index';
import {
  AccountReference,
  AccountTradeHistoryPayloadData,
  GetAccountsAvailableCashRequest,
  GetMyAccountChartDataPayload,
  OpenPositionsPayloadData,
} from './types';


const getAccountsAvailableCashEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getAccountsAvailableCash.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getAccountsAvailableCash';
    if (!state$.value.crm.accountInfo[0]?.account_reference_id) {
      console.error('[reporting/epics] getAccountsAvailableCashEpic - accountInfo not found');
      return;
    }
    const requestData: GetAccountsAvailableCashRequest = {
      accountReferenceId: state$.value.crm.accountInfo[0].account_reference_id,
    };
    const payloadFor404: AccountReference = { availableCash: 0, instaCash: 0 };

    GlobalService
      .reportingService
      .getAccountsAvailableCash(requestData)
      .then(res => {
        processResponse(
          callName,
          requestData,
          res,
          observer,
          getAccountsAvailableCashSuccess,
          getAccountsAvailableCashFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: payloadFor404 },
        );
      })
      .catch(error => {
        processErrorInResponse(
          callName,
          error,
          action,
          observer,
          getAccountsAvailableCashFailed,
        );
      });
  })),
);

const getNetEquityComponentsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getNetEquityComponents.type),
  mergeMap((action: PayloadAction<GetMyAccountChartDataPayload>) => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getNetEquityComponents';
    let { accountReferenceId, fromDate, toDate } = action.payload;
    if (!accountReferenceId) {
      accountReferenceId = getCurrentAccount(state$.value);
    }
    if (!accountReferenceId) {
      observer.next(getNetEquityComponentsSuccess([]));
      console.error(`[reporting/epics] getNetEquityComponentsEpic - current account (accountReferenceId) not found in action.payload or state for ${callName}`);
      return;
    }

    GlobalService
      .reportingService
      .getNetEquityComponents({ accountReferenceId, fromDate, toDate })
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getNetEquityComponentsSuccess,
          getNetEquityComponentsFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
        );
      }).catch(e => observer.next(getNetEquityComponentsFailed(e)));
  })),
);

const getAccountSummaryEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getAccountSummary.type),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getAccountSummary';
    const accountReferenceId = getCurrentAccount(state$.value);
    if (!accountReferenceId) {
      observer.next(getAccountSummarySuccess(EMPTY_ACCOUNT_SUMMARY));
      console.error(`[reporting/epics] getAccountSummaryEpic - current account (accountReferenceId) not found in state for ${callName}`);
      return;
    }

    GlobalService
      .reportingService
      .getAccountSummaryData({ accountReferenceId })
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getAccountSummarySuccess,
          getAccountSummaryFailed,
        );
      }).catch(e => observer.next(getAccountSummaryFailed(e)));
  })),
);

const getOrderDetailEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getOrderDetail.type),
  mergeMap((action: PayloadAction<string>) => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getOrderDetail';
    const parentOrderId = action.payload;
    const accountReferenceId = getCurrentAccount(state$.value);
    if (!parentOrderId) {
      observer.next(getOrderDetailFailed({ status: null }));
      console.error(`[reporting/epics] getOrderDetailEpic - parentOrderId not found in action.payload for ${callName}`);
      return;
    }
    if (!accountReferenceId) {
      observer.next(getAccountSummarySuccess(EMPTY_ACCOUNT_SUMMARY));
      console.error(`[reporting/epics] getOrderDetailEpic - current account (accountReferenceId) not found in state for ${callName}`);
      return;
    }

    GlobalService
      .reportingService
      .getOrderDetailData({ accountReferenceId, parentOrderId })
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getOrderDetailSuccess,
          getOrderDetailFailed,
        );
      }).catch(e => {
        observer.next(getOrderDetailFailed(e));
      });
  })),
);

const getOpenPositionsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getOpenPositions.type),
  mergeMap((action: PayloadAction<OpenPositionsPayloadData>) => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getOpenPositions';
    const {
      symbol,
      positionType,
      accountReferenceId = getCurrentAccount(state$.value),
      group,
    } = action.payload ?? {};
    if (!accountReferenceId) {
      observer.next(getOpenPositionsSuccess([]));
      console.error(`[reporting/epics] getOpenPositionsEpic - current account (accountReferenceId) not found in action.payload or state for ${callName}`);
      return;
    }


    GlobalService
      .reportingService
      .getOpenPositionsData({ accountReferenceId, symbol, positionType })
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getOpenPositionsSuccess,
          getOpenPositionsFailed,
          false,
          { group, symbol },
          null,
          null,
          null,
          { 404: [] },
        );
      }).catch(e => observer.next(getOpenPositionsFailed(e)));
  })),
);

export const tradeHistoryQueue: PayloadAction<any>[] = [];
const getAccountTradeHistoryEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getAccountTradeHistory.type),
  filter(action => {
    if (isCallStatusPendingQueued(ReportingCall.getAccountTradeHistory, state$.value.reporting.statusByCall)) {
      tradeHistoryQueue.push(action);
      return false;
    }
    return true;
  }),
  mergeMap((action: PayloadAction<AccountTradeHistoryPayloadData>) => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getAccountTradeHistory';
    const accountReferenceId = getCurrentAccount(state$.value);
    if (!accountReferenceId) {
      observer.next(getAccountTradeHistorySuccess([]));
      console.error(`[reporting/epics] getAccountTradeHistoryEpic - current account (accountReferenceId) not found in state for ${callName}`);
      return;
    }

    const {
      fromDate = PENDING_ORDERS_REQUEST_FROM_DATE,
      toDate = getDateTime(),
      pageNumber = ORDERS_REQUEST_PAGE_NUMBER,
      rowsPerPage = ORDERS_REQUEST_ROWS_PER_PAGE,
      symbol,
      status,
      tenNStatus,
      isPendingOrdersCall,
      isSymbolDetails,
    } = action.payload;

    const requestBody = {
      fromDate,
      toDate,
      pageNumber,
      status,
      tenNStatus,
      rowsPerPage,
      symbol,
      accountReferenceId,
    };

    GlobalService
      .reportingService
      .getAccountTradeHistory(requestBody)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          isPendingOrdersCall ? getAccountPendingOrdersSuccess : getAccountTradeHistorySuccess,
          getAccountTradeHistoryFailed,
          false,
          { isSymbolDetails, queueLength: tradeHistoryQueue.length, isPendingOrdersCall },
          null,
          null,
          null,
          { 404: [] },
        );
        if (tradeHistoryQueue.length) {
          let theAction = tradeHistoryQueue.shift() as PayloadAction<any>;
          if (!theAction.payload.customData) theAction.payload.customData = {};
          theAction.payload.customData.isFromQueue = true;
          theAction.payload.customData.queueLength = tradeHistoryQueue.length;
          observer.next(theAction);
        }
      }).catch(e => observer.next(getAccountTradeHistoryFailed(e)));
  })),
);

const getPeopleAlsoTradeDataEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getPeopleAlsoTradeData.type),
  filter(() => state$.value.app.isServicesReady),
  filter(() => {
    const component: OrderTicketComponent = 'people-also-trade';
    const { toBeUpdated } = CallsCache.checkRequest('customStockList', component);
    const isEpicEnabled = toBeUpdated ?? false;
    // @@@ debug
    console.log(`[@@@] update people - ${isEpicEnabled}`); // eslint-disable-line
    return isEpicEnabled;
  }),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'reporting/getPeopleAlsoTradeData';
    const crm = state$.value.crm;

    const accountReferenceId = crm.accountInfo[0]?.account_reference_id
      || crm.accountDetails.account_reference_id
      || crm.accountInfo.accountReferenceId;

    const params: PeopleAlsoTradeParams = {
      accountReferenceId,
      daysBack: 2,
      tenNAccounstOnly: false,
    };

    GlobalService
      .reportingService
      .getPeopleAlsoTradeData(params)
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getPeopleAlsoTradeDataCompleted,
          getPeopleAlsoTradeDataFailed,
        );
      })
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        getPeopleAlsoTradeDataFailed,
      ));
  })),
);

const initializePeopleAlsoTradeItemsDataEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getPeopleAlsoTradeDataCompleted.type),
  filter(() => state$.value.gateway.connected || state$.value.marketData.connected),
  mergeMap((action: PayloadAction<PeopleAlsoTradeItem[]>) => new Observable((observer: Observer<any>) => {
    const symbolOrSymbols = extractSymbolsFromListAsString(action.payload);
    const component: OrderTicketComponent = 'people-also-trade';

    observer.next(getCustomWatchlistData({
      symbolOrSymbols,
      component: 'people-also-trade',
      caller: 'initializePeopleAlsoTradeItemsDataEpic',
    }));
  })),
);

export default combineEpics(
  initializePeopleAlsoTradeItemsDataEpic,
  getAccountsAvailableCashEpic,
  getNetEquityComponentsEpic,
  getAccountSummaryEpic,
  getOrderDetailEpic,
  getOpenPositionsEpic,
  getAccountTradeHistoryEpic,
  getPeopleAlsoTradeDataEpic,
);
