import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import {
  iif, Observable, Observer, of,
} from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import config from '../../../config';
import configLib from '../../../configLib';
import { RecoverPhaseEnum } from '../../enums/index';
import { TOKEN_REFRESH_ENABLED } from '../../libSettings';
import { RecoveryDataPayload } from '../../models/auth/types';
import { TokenDecodedData } from '../../models/enroll';
import { ENROLL_AUTH_REQUEST_TYPE } from '../../models/enroll-requests';
import GlobalService from '../../services/Global.service';
import AppStateCache from '../../store-util/AppStateCache';
import LoaderState from '../../store-util/LoaderState';
import { decodeJwt } from '../../util/DataHelpers';
import {
  authRefreshCompleted,
  autoLogin,
  autoLoginFailed,
  autoLoginSuccess,
  enrollAuthCompleted,
  getCountryListSuccess,
  getIndividualExtendedInfo,
  getManualTokenSuccess,
  individualExtendedInfoFailed,
  individualExtendedInfoSuccess,
  login,
  loginFailed,
  logout,
  updateRefreshTokenLockTime,
} from '../common-actions';
import { getTradingPlatforms } from '../crm/index';
import { processErrorInResponse, processResponse } from '../helpers';
import { RootState } from '../index';
import {
  getToken,
  hasIndividualExtendedInfo,
  hasManualToken,
  hasValidToken,
  isTokenRefreshLocked,
  isUserVerifiedStatus,
} from '../selectors';

import { isAutoRefreshTokenEnabled } from './helpers';
import {
  AuthState,
  checkAndRefreshToken,
  confirmAppropriatenessPopup,
  enrollAuth,
  enrollAuthFailed,
  getManualToken,
  getManualTokenFailed,
  getSignupToken,
  getSignupTokenFailed,
  getSignupTokenSuccess,
  loginSucceeded,
  sendNonAppropriatenessEmail,
  sendNonAppropriatenessEmailFail,
  sendNonAppropriatenessEmailSuccess,
  sendResetPassword,
  setRecoveryData,
  setRecoveryPhase,
  startAuthRefresh,
} from './index';

const loginEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(login.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    GlobalService
      .authService
      .authorize(action.payload)
      .then((authState: AuthState) => {
        console.debug('[auth/epics] Auth State - login success', authState);
        LoaderState.setLoading(true);
        observer.next(loginSucceeded({
          accessToken: authState.accessToken,
          refreshToken: authState.refreshToken,
          userData: authState,
        }));
      })
      .catch((err: any) => {
        console.error('[auth/epics] Auth State - login error', err);
        if (action.payload !== 'login') {
          observer.next(loginFailed({ errorStr: 'Login failed', errorData: err }));
        }
      });
  })),
);

const enrollAuthEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(enrollAuth.type),
  filter(action => action.payload.tokenDecoded == null),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { requestType } = action.payload;
    const callName = `enrollAuth/${ENROLL_AUTH_REQUEST_TYPE[requestType]}`;
    const { email, phoneCode, phoneNumber } = action.payload;
    const {
      userId, firstName, lastName, country,
    } = state$.value.auth.enroll;

    switch (requestType) {
      // TODO: Remove this enum value and related code with ART-1023
      case ENROLL_AUTH_REQUEST_TYPE.RECOVER:
        return;

      case ENROLL_AUTH_REQUEST_TYPE.REGISTER: {
        const { password, confirmPassword, extraDetails } = action.payload;
        GlobalService
          .authService
          .registerUser(email, password, confirmPassword, extraDetails)
          .then((response: any) => {
            processResponse(callName, {}, response, observer, enrollAuthCompleted, enrollAuthFailed);
          })
          .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollAuthFailed));
        break;
      }

      case ENROLL_AUTH_REQUEST_TYPE.CONFIRM_EMAIL: {
        const { token } = action.payload;
        GlobalService
          .authService
          .confirmEmail(email, token)
          .then((response: any) => {
            processResponse(
              callName,
              {},
              response,
              observer,
              enrollAuthCompleted,
              enrollAuthFailed,
              false,
              action.payload,
            );
          })
          .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollAuthFailed));
        break;
      }

      case ENROLL_AUTH_REQUEST_TYPE.RESEND_EMAIL:
        GlobalService
          .authService
          .resendEmail(email, firstName, lastName, country)
          .then((response: any) => {
            processResponse(callName, {}, response, observer, enrollAuthCompleted, enrollAuthFailed);
          })
          .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollAuthFailed));
        break;

      case ENROLL_AUTH_REQUEST_TYPE.SEND_PHONE_CODE:
        GlobalService.authService.setToken(getToken(state$.value)!);
        GlobalService
          .authService
          .sendPhoneCode(userId as number, (phoneCode + phoneNumber))
          .then((response: any) => {
            processResponse(callName, {}, response, observer, enrollAuthCompleted, enrollAuthFailed);
          })
          .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollAuthFailed));
        break;

      case ENROLL_AUTH_REQUEST_TYPE.VERIFY_PHONE_CODE:
        GlobalService
          .authService
          .verifyPhoneNumber(userId as number, action.payload.code)
          .then((response: any) => {
            processResponse(callName, {}, response, observer, enrollAuthCompleted, enrollAuthFailed);
          })
          .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollAuthFailed));
        break;

      default:
        throw new Error(`[auth/epics] Enroll auth request '${callName}' not implemented`);
    }
  })),
);

const enrollAuthCompletedEpic = (action$: Observable<any>) => action$.pipe(
  ofType(enrollAuthCompleted.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { callName } = action.payload;
    const isVerifyPhone = callName?.split('/')[1] === ENROLL_AUTH_REQUEST_TYPE[ENROLL_AUTH_REQUEST_TYPE.VERIFY_PHONE_CODE];
    const isConfirmEmail = callName?.split('/')[1] === ENROLL_AUTH_REQUEST_TYPE[ENROLL_AUTH_REQUEST_TYPE.CONFIRM_EMAIL];

    if (isVerifyPhone) {
      observer.next(getIndividualExtendedInfo());
    } else if (isConfirmEmail) {
      observer.next(getTradingPlatforms());
      observer.next(getManualToken(action.payload));
    }
  })),
);

const renewTokenEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(startAuthRefresh.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const customConfig = { clientId: config.auth.clientId };

    GlobalService
      .authService
      .refresh(customConfig, { refreshToken: state$.value.auth.refreshToken })
      .then(authState => {
        observer.next(authRefreshCompleted({
          accessToken: authState.accessToken,
          refreshToken: authState.refreshToken,
          userData: authState,
        }));
      })
      .catch((err: any) => {
        observer.next(logout());
      });
  })),
);

const renewWillBeExpiredTokenEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(checkAndRefreshToken.type),
  filter(() => TOKEN_REFRESH_ENABLED),
  filter(() => state$.value.app.isOnline),
  filter(() => isAutoRefreshTokenEnabled(state$.value.auth.accessToken)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { isWeb } = require('../../../configLib').default;
    const token = state$.value.auth.accessToken;

    if (!token) {
      return;
    }

    const { refreshTokenUnlockTime } = state$.value.auth;
    if (isTokenRefreshLocked(refreshTokenUnlockTime)) {
      return;
    }

    // Lock refresh token request for concurrent requests
    observer.next(updateRefreshTokenLockTime());

    const customConfig = { clientId: config.auth.clientId };

    GlobalService.disconnect();
    AppStateCache.setIsTokenRefresh(true);

    GlobalService
      .authService
      .refresh(customConfig, { refreshToken: state$.value.auth.refreshToken })
      .then(authState => {
        AppStateCache.setIsTokenRefresh(false);
        // TODO: Create a converter to map the response to WEB/MOB
        const accessToken = authState[isWeb ? 'access_token' : 'accessToken'];
        const refreshToken = authState[isWeb ? 'refresh_token' : 'refreshToken'];
        observer.next(authRefreshCompleted({
          accessToken,
          refreshToken,
          userData: authState,
        }));
        GlobalService.updateServicesToken(accessToken);
      })
      .catch((err: any) => {
        console.error('an error occured while refresing the token! error:', err);
        observer.next(logout());
      });
  })),
);

const autoLoginOnValidTokenEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(checkAndRefreshToken.type),
  filter(() => !state$.value.app.connected),
  filter(() => !isAutoRefreshTokenEnabled(state$.value.auth.accessToken)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { accessToken, refreshToken } = state$.value.auth;
    observer.next(loginSucceeded({ accessToken, refreshToken }));
  })),
);

/**
 * Used to get token after sign up in order to automatically log in
 */
const getManualTokenEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getManualToken.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'refreshManualToken';
    const { email, token } = action.payload?.customData || {};

    GlobalService
      .authService
      .getTokenManually(email, token, configLib.isWeb ? '10nWeb' : '10nMobile')
      .then((response: any) => {
        processResponse(
          callName,
          { email, token },
          response,
          observer,
          getManualTokenSuccess,
          getManualTokenFailed,
          false,
          response,
        );
      })
      .catch((error: any) => processErrorInResponse(callName, error, action, observer, getManualTokenFailed));
  })),
);

/**
 * Used to get PWP token by HW token to continue enrolment steps without any login
 */
const getSignupTokenEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getSignupToken.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    GlobalService
      .authService
      .getSignupToken(state$.value.auth.accessToken!)
      .then(response => {
        processResponse(
          'getSignupToken',
          {},
          response,
          observer,
          getSignupTokenSuccess,
          getSignupTokenFailed,
        );
      });
  })),
);

const autoLoginEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(autoLogin.type),
  mergeMap(() => (
    iif(
      () => hasManualToken(state$.value),
      of(autoLoginSuccess()),
      of(autoLoginFailed()),
    )
  )),
);


/**
 * Triggered when auto login after sign-up is not successful
 */
const autoLoginFallbackEpic = (action$: Observable<any>) => action$.pipe(
  ofType(autoLoginFailed.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'autoLoginFallback';
    const customConfig = { additionalParameters: {} };
    GlobalService
      .authService
      .authorize(customConfig)
      .then((authState: AuthState) => {
        processResponse(
          callName,
          customConfig,
          authState,
          observer,
          loginSucceeded,
          loginFailed,
          false,
          null,
          authState,
        );
      })
      .catch((error: any) => {
        processErrorInResponse(
          callName,
          error,
          action,
          observer,
          loginFailed,
        );
      });
  })),
);


const recoverEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(loginSucceeded.type, individualExtendedInfoSuccess.type, individualExtendedInfoFailed.type),
  filter(() => !configLib.isWeb), // TODO: Use this epic also for WEB in ART-1023
  filter(() => !isUserVerifiedStatus(state$.value.crm.individualExtendedInfo)),
  filter(() => hasIndividualExtendedInfo(state$.value) || !state$.value.auth.enroll.confirmedSteps.email),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let recoveryData: RecoveryDataPayload | null = null;
    const { financialQuestionnaireSections } = state$.value.crm.enroll;

    switch (action.type) {
      case loginSucceeded.type:
        recoveryData = {
          callType: 'login',
          tokenDecodedData: decodeJwt(getToken(state$.value)) as TokenDecodedData,
          financialQuestionnaireSections,
        };
        break;

      case individualExtendedInfoSuccess.type:
        recoveryData = {
          callType: 'extended-info',
          extendedInfo: action.payload,
          financialQuestionnaireSections,
        };
        break;

      default:
        return;
    }

    observer.next(setRecoveryData(recoveryData));
  })),
);


const sendResetPasswordEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(sendResetPassword.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const email = state$.value.crm.individualExtendedInfo?.email;
    GlobalService
      .authService
      .sendResetPassword(
        email || '',
        config.auth.clientId || '',
        config.auth.redirectUrlProfileSecurity,
      )
      .then(resp => {
        processResponse(
          'sendResetPassword',
          {},
          resp,
          observer,
        );
      });
  })),
);

const automaticLoginInEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getCountryListSuccess.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { MOCK } = require('../../../configDebug');
    const { accessToken, refreshToken } = state$.value.auth;
    if (!MOCK.ENABLED && !!refreshToken) {
      if (TOKEN_REFRESH_ENABLED) {
        observer.next(checkAndRefreshToken());
      } else if (hasValidToken(null, accessToken)) {
        observer.next(loginSucceeded({ accessToken, refreshToken }));
      }
    }
  })),
);

const sendNonAppropriatenessEmailEpic = (
  action$: Observable<PayloadAction<string>>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(sendNonAppropriatenessEmail.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'crm/sendNonAppropriatenessEmail';

    const email = state$.value.crm.individualExtendedInfo?.email ?? '';
    const token = state$.value.auth.accessToken ?? '';

    GlobalService
      .authService
      .sendNonAppropriatenessEmail(email ?? '', token)
      .then((response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          sendNonAppropriatenessEmailSuccess,
          sendNonAppropriatenessEmailFail,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, sendNonAppropriatenessEmailFail));
  })),
);

const confirmAppropriatenessEpic = (action$: Observable<any>) => action$.pipe(
  ofType(confirmAppropriatenessPopup.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    observer.next(setRecoveryPhase(RecoverPhaseEnum.Verification));
  })),
);

export default combineEpics(
  getSignupTokenEpic,
  loginEpic,
  autoLoginEpic,
  enrollAuthEpic,
  enrollAuthCompletedEpic,
  renewTokenEpic,
  renewWillBeExpiredTokenEpic,
  autoLoginOnValidTokenEpic,
  getManualTokenEpic,
  autoLoginFallbackEpic,
  recoverEpic,
  sendResetPasswordEpic,
  sendNonAppropriatenessEmailEpic,
  confirmAppropriatenessEpic,
  automaticLoginInEpic,
);
