import { produce } from 'immer';
import { Entity } from 'simpler-state';

import { TopGainerAndLoserData } from '../../models/fundamental/types';

import { TopMoversCacheData } from './types';

// TODO : This helper file will be simplified within ART-3785

/**
 * Must be used only by `TopGainersCache` and `TopLosersCache`
 */
export const setInitialItems = (self: Entity<TopMoversCacheData>) => (data: TopGainerAndLoserData[]) => {
  self.set(produce<TopMoversCacheData>(state => {
    state.initialData = data.map(({ symbol, companyName }) => ({ symbol, companyName }));
    state.isInitialDataReady = true;
  }));
};

/**
 * Must be used only by `TopGainersCache` and `TopLosersCache`
 */
export const getInitialItems = (self: Entity<TopMoversCacheData>) => () => {
  const { initialData, isInitialDataReady } = self.get();
  return {
    initialData,
    isInitialDataReady,
  };
};

export const updateTopMovers = (self: Entity<TopMoversCacheData>, newData: TopGainerAndLoserData[]) => {
  self.set(produce<TopMoversCacheData>(state => {
    state.data = newData;
    state.isReady = true;
  }));
};
