import { getBankNameValidity, getBicSwiftValidity, getIbanValidity } from '../../../lib/util/ValidationSchemes/Validate';

const ValidateForm = (bank_name: string, bic: string, iban: string) => {
  const bankNameCheck = getBankNameValidity(bank_name);
  const bicShiftCodeCheck = getBicSwiftValidity(bic);
  const ibanCheck = getIbanValidity(iban);

  return (bankNameCheck.isValid && bicShiftCodeCheck.isValid && ibanCheck.isValid);
};

export { ValidateForm };
