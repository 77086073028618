import React from 'react';

type Props = {
    x?: number;
    y?: number;
    fillColor: string;
    showPerformance: boolean;
}

const CustomTooltip = ({ x, y, fillColor, showPerformance }: Props) => (
  <g>
    <line transform={`translate(${x}, 0)`} x1={0} y1={showPerformance ? 0 : 280} y2={'-40px'} stroke="#000" strokeWidth={0.5} />
    <circle cx={x} cy={y} r={showPerformance ? '0' : '5'} stroke={'#fff'} strokeWidth={3} fill={fillColor} />
  </g>
);

export default CustomTooltip;
