import { DocumentStatusEnum, DocumentTypeEnum } from '../enums';
import lang from '../language';
import { UploadDocumentFiles } from '../store/crm/types';
import { NullableNumber } from '../util/types';

import { ENROLL_METHOD, IndividualAddressTypeEnum, PepYesedQuestion } from './enroll';


/* eslint-disable no-multiple-empty-lines */

export enum ENROLL_AUTH_REQUEST_TYPE {
  REGISTER,
  CONFIRM_EMAIL,
  RESEND_EMAIL,
  SEND_PHONE_CODE,
  VERIFY_PHONE_CODE,
  RECOVER,
  GET_CLIENTS,
  SET_CLIENTS,
}

export type EnrollAuthRequestBody = {
  requestType: ENROLL_AUTH_REQUEST_TYPE
}

export type EnrollFileUploadRequestBody = {
  name: string
  data: any
}

// TODO: Rename to EnrollCRMRequestBodyAbstract
export type EnrollRequestBodyAbstract = {
  callMethod: ENROLL_METHOD
  callMethodName: string
  callNo?: number
}

// TODO: Rename to EnrollCRMGetRequestBody
export type EnrollGetRequestBody = {
  params?: string[]
} & EnrollRequestBodyAbstract

// TODO: Rename to EnrollCRMPostRequestBody
export type EnrollPostRequestBody = {
  requestBody: any
} & EnrollRequestBodyAbstract

export type EnrollPutRequestBody = EnrollGetRequestBody

export type EnrollDeleteRequestBody = {
  id: any
} & EnrollRequestBodyAbstract

export type EnrollCall = (
  EnrollGetRequestBody | EnrollPostRequestBody | EnrollPutRequestBody | EnrollDeleteRequestBody
) & {
  isQueued?: boolean
}

// Step 1: POST
export type AuthRegisterRequest = {
  email: string
  confirm_password: string
  password: string
  tenant_id: number
  redirect_url: string
  client: string
  send_email: boolean
  user_type_relation: number
};

// Step 2: GET
export type AuthConfirmEmailQuery = {
  Email: string;
  token: string;
};


// Step 3



// Step 4



export enum GenderTypeEnum {
  unspecified = 'U',
  male = 'M',
  female = 'F'
}

// Step 5: POST
export type EnrollIndividualRequestData = {
  first_name: string
  last_name: string
  middle_name?: string
  birth_date: string // "2021-01-28T19:35:22.387Z"
  birth_place_id: string
  nationalities: string[]
  trace_identifier?: string
  user_correlation_id?: number
  trading_platform_id?: NullableNumber
  gender?: GenderTypeEnum
  enroll_channel: string // 1 letter
  birth_place_location?: string;
  id?: number;
}

// Step 6



// Step 7



// Step 8
export type EnrollIndividualIdentityDetailRequestData = {
  id?: number | null
  identity_document_type: IdentityDocumentSubTypesEnum // 'PAS
  identity_number: string
  review_status?: string
  expiration_date?: string // '2021-01-14T00:33:25.333Z',
  review_summary?: string
  proof_of_identity_id?: number | null
}



// Step 9



// Step 10
export type EnrollIndividualTaxDetailData = {
  id?: number | null
  country_id: string
  tax_number: string
  proof_of_tax_detail_id?: number
  review_status?: string
  review_summary?: string
  trace_identifier?: string
}
export type UpdateIndividualTaxDetailData = {
  individual_id: number | null
} & EnrollIndividualTaxDetailData

export type EnrollIndividualTaxDetailRequestData = EnrollIndividualTaxDetailData[]


// Step 11



// Step 12
export type EnrollIndividualAddressRequestData = {
  id?: number | null
  address_type: IndividualAddressTypeEnum
  country_id: string
  city: string
  state_province_county?: string
  zip_code: string
  address_line: string
  proof_of_address_id?: number
  review_status?: string
  review_summary?: string
  trace_identifier?: string
}


// Step 13



// Step 14



// Step 15



// Step 16



// Step 17



// Step 18



// Step 19



// Step 20



// Step 21
export type EnrollPuropseOfTradingRequestData = {
  individual_id: null
  purpose_of_trading_id: number
  trace_identifier?: null
}

// Step 22



// Step 23
export type EnrollAnnualNetIncomeRequestData = {
  individual_id: null
  annual_net_income_id: number
  trace_identifier?: null
}


// Step 24
export type EnrollEstimatedNetWorthRequestData = {
  individual_id: null
  estimated_net_worth_id: number
  trace_identifier?: null
}

// Step 25
export type EnrollEstimatedLiquidNetWorthRequestData = {
  individual_id: null
  estimated_liquid_worth_id: number
  trace_identifier?: null
}



// Step 26



// Step 27.2: POST/PUT - upload files
export type EnrollIndividualDocumentOwnRequestData = {
  id?: number,
  document_type: string // DocumentTypesEnum
  sub_document_type: string
  file_name: string
  status?: DocumentStatusEnum
  file_storage_id: string[]
  upload_stamp?: string // "2021-01-29T14:44:25.628Z"
  uploaded_by: number // user id
  modify_stamp?: string // "2021-01-29T14:44:25.628Z"
  modified_by?: number
  review_summary?: string
  is_hidden_for_client: boolean | null // true
  poi_document_id?: number
  trace_identifier?: string
}

export type EnrollIndividualIdentityDetailPayload = {
  identity_document_type: string;
  identity_number: string;
  files: UploadDocumentFiles[];
  expiration_date: string;
  review_status: string;
  review_summary: string;
  is_hidden_for_client: boolean;
  trace_identifier?: string;
}

export enum AddressDocumentSubTypesEnum {
  bankStatement = 'BNK',
  utilityBill = 'BIL'
}

export enum IdentityDocumentSubTypesEnum {
  unspecified = 'UNS',
  passport = 'PAS',
  identityCard = 'IDC',
  drivingLicense = 'DRV'
}

export const IdentityDocumentSubTypesLabels = [
  {
    label: lang.commonOnboardingPersonalDetailsDocumentSubtypeIdentityCard(),
    value: IdentityDocumentSubTypesEnum.identityCard,
  },
  {
    label: lang.commonOnboardingPersonalDetailsDocumentSubtypePassport(),
    value: IdentityDocumentSubTypesEnum.passport,
  },
];

/**
 * Used for denoting what is uploaded by the user interface
 * After a file is uploaded it is registered with this info
 */
export type FileUploadRegisterData = {
  type: DocumentTypeEnum
  subType: string
  fileName: string
}

export const AppDocumentUploadCategories: ({[key: string]: FileUploadRegisterData}) = {
  PASSPORT: {
    type: DocumentTypeEnum.ProofOfIdentity,
    subType: IdentityDocumentSubTypesEnum.passport,
    fileName: 'passport.jpg',
  },
  ID_FRONT: {
    type: DocumentTypeEnum.ProofOfIdentity,
    subType: IdentityDocumentSubTypesEnum.identityCard,
    fileName: 'id-front.jpg',
  },
  ID_BACK: {
    type: DocumentTypeEnum.ProofOfIdentity,
    subType: IdentityDocumentSubTypesEnum.identityCard,
    fileName: 'id-back.jpg',
  },
  ADDRESS_PROOF_UTILITY_BILL: {
    type: DocumentTypeEnum.ProofOfAddress,
    subType: AddressDocumentSubTypesEnum.utilityBill,
    fileName: 'proof-of-address.jpg',
  },
  ADDRESS_PROOF_BANK_STATEMENT: {
    type: DocumentTypeEnum.ProofOfAddress,
    subType: AddressDocumentSubTypesEnum.bankStatement,
    fileName: 'proof-of-address.jpg',
  },
  SELFIE: {
    type: DocumentTypeEnum.Selfie,
    subType: '',
    fileName: 'selfie',
  },
};

export const documentUploadCategoriesPOA = {
  'Utility Bill': AppDocumentUploadCategories.ADDRESS_PROOF_UTILITY_BILL,
  'Bank Statement': AppDocumentUploadCategories.ADDRESS_PROOF_BANK_STATEMENT,
};

// Step 28



// Step 29
export type EnrollFinancialQuestionnaireData = {
  financial_questionaire_type: string,
  from: 0,
  to: 0,
  currency: string,
  description: string,
}


// Step 30
export type EnrollPepYesedQuestionsRequestData = PepYesedQuestion[]

// Step 31
export type EnrollLegalDeclarationRequestData = {
  legal_declarations: number[]
}


// Step 32

export type EnrollEmailLegalDeclarationsRequestData = {
  id_list: number[],
  email: string,
  subject: string,
  message: string
}

// Step 33


export type PutIndividualRequestBody = {
  id: number,
  user_correlation_id: number,
  individual_status?: number,
  user_status?: number,
  first_name: string,
  middle_name?: string,
  last_name: string,
  birth_date: string, // 2021-03-31T13:40:19.217Z ,
  birth_place_id: string, // pattern: [A-Z]{2}
  gender?: GenderTypeEnum,
  email: string,
  phone_number?: string,
  platform?: string, // ?
  verification_date?: string, // 2021-03-31T13:40:19.217Z , // ?
  localized_detail_id?: number, // ?
  assigned_to_user?: number | null,
  has_financial_sanctions?: boolean,
  manual_risk_classification?: number, // [0, 1, 2, 3]
  mi_f_i_d_category?: number | null,
  trading_category?: number,
  alias?: string,
  trace_identifier?: string
}

export type IndividualPepDetailData = {
  individual_id: number
}

export const REQUEST_CALL_NAME_CONFIRM_EMAIL = 'enrollAuth/CONFIRM_EMAIL';
export const REQUEST_CALL_NAME_GET_MANUAL_TOKEN = 'refreshManualToken';
