export enum EnrollProgressBarPhasesEnum {
  KnowledgeAndExperience = 'Knowledge & Experience',
  PurposeAndStatus = 'Purpose & Status',
  PersonalData = 'Personal data',
  AgreementsAndPolicies = 'Agreements & Policies',
}

export type EnrollProgressBarPhase = {
  index: number
  title: string;
  steps: number;
}

export type EnrollProgressBarData = {
  phases: EnrollProgressBarPhase[];
  currentPhaseIndex: number;
  currentStepIndex: number;
}
