export enum ReportingCall {
  getNetEquityComponents,
  getAccountSummary,
  getOrderDetail,
  getOpenPositions,
  getAccountTradeHistory,
  getAccountPendingOrders,
  getPeopleAlsoTradeData,
  getBuiyngPower,
}

export interface PeopleAlsoTradeParams {
  accountReferenceId: string;
  daysBack: number;
  symbolNumber?: number;
  tenNAccounstOnly: boolean;
}

export interface PeopleAlsoTradeItem {
  symbol: string;
  rowNumber: number;
}
