export const displayDateFormat = 'DD MMM[,] YYYY';
export const displayDateFormatNoDay = 'MMMM[,] YYYY';
export const displayDateFormatNoYear = 'DD MMMM';
export const displayDateFormat_HH_MM = 'HH:mm';
export const displayDateFormat_MMM_DD = 'MMM DD';
export const UTC_MOMENT_FORMAT = 'YYYY[-]MM[-]DD';
export const utcISOTruncated = 'YYYY[-]MM[-]DD[T]HH:mm:ss';
export const calendarPickerRangeFormat = 'DD MMM YYYY';
export const pendingOrdersDatePlacedFormat = 'D MMM';

export const CURRENT_YEAR = new Date().getFullYear();
function getLastYearsList(range = 20) {
  return Array(range + 1).fill(1).map((item, index) => CURRENT_YEAR - range + index);
}
export const LAST_YEARS = getLastYearsList();

export const MARKET_DATA_TIME_ZONE_SHORT_DST = 'EDT';
export const MARKET_DATA_TIME_ZONE_SHORT_NO_DST = 'EST';
export const MARKET_DATA_TIME_ZONE = 'America/New_York';
export const SOFIA_TIME_ZONE = 'Europe/Sofia';
export const ISTANBUL_TIME_ZONE = 'Europe/Istanbul';

export const LOCAL_DATE = 'DD MMM, YYYY';

export const MARKET_DATA_DATE = 'YYYY-MM-DD';
export const MARKET_DATA_TIME_AND_DATE = 'YYYY-MM-DD HH:mm';
export const MARKET_DATA_TIME_AND_DATE_WITH_TZ = 'YYYY-MM-DD HH:mm Z';
export const MARKET_DATA_TIME_AND_DATE_WITH_TZ2 = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const MARKET_DATA_TIME_AND_DATE_WITH_TZ3 = 'YYYY-MM-DDTHH:mm:ssZ';
export const MARKET_DATA_TIME_AND_DATE2 = 'YYYY-MM-DD HH:mm:ss.SSS';
export const TRADE_HISTORY_DETAILS_ORDER_DATE = 'DD MMM • HH:mm';
export const TRADE_HISTORY_DETAILS_FILLED_DATE = LOCAL_DATE;
export const SECONDS_IN_AN_HOUR = 60 * 60;
export const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;
export const PRE_MARKET_DURATION = SECONDS_IN_AN_HOUR * 9.5;

export const ELAPSED_TIME_INVALID = '--:--';
export const ELAPSED_TIME_ZERO = '00:00';
export const MARKET_CLOSE_NY_TIME = '16:00:00';
export const MARKET_OPEN_NY_TIME = '09:30:00';
