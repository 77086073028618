import { displayDateFormatNoYear, pendingOrdersDatePlacedFormat } from '../../../constants/date-time.constants';
import lang from '../../../language';
import { SOROrderType, TenNSOROrderStatus } from '../../../models/gateway/types';
import { formatCurrency, formatNumber } from '../../../util/DataHelpers';
import { getNextWorkday, isDateMarketDataWorkingDay } from '../../../util/DateTimeHelpers';
import { mapOrderType } from '../../../util/TradingHelpers';
import { INVALID_VALUE } from '../../../util/types';
import { COLORS } from '../constants';
import {
  AccountTradeHistoryResponseData,
  TradeHistoryParsedOrders,
} from '../types';

export const parsedAccountTradeHistoryOrdersOrders = (
  orderResponse: AccountTradeHistoryResponseData[],
): TradeHistoryParsedOrders[] => {
  let filterdTradeHistory: TradeHistoryParsedOrders[] = [];

  for (let index = 0; index < orderResponse.length; index++) {
    const {
      side,
      type,
      status,
      orderDate,
      tenNStatus,
      totalQuantity,
      orderStopPrice,
      averageFilledPrice,
      orderRequestedPrice,
      aqxExecutedQuantity,
      ...filteredObj
    } = orderResponse[index];

    const price = averageFilledPrice || INVALID_VALUE;
    const quantity = mapOrderQuantity(tenNStatus, totalQuantity, aqxExecutedQuantity);

    filterdTradeHistory.push({
      ...filteredObj,
      statusEnum: status,
      tenNParsedStatus: setStatus(tenNStatus),
      type: mapOrderType(side, type),
      orderDate: parseDateToDateAndMonth(orderResponse[index].aqxLastExecutionDate
        || orderResponse[index].lastUpdatedDate),
      color: handleColors(setStatus(tenNStatus)),
      quantity,
      price: formatCurrency(price),
      orderValue: price !== INVALID_VALUE
        ? formatCurrency((Number(quantity) * Number(price)).toString())
        : INVALID_VALUE,
    });
  }

  return filterdTradeHistory;
};

export const handleColors = (status: string): string => {
  switch (status) {
    case lang.commonOrderStatusFilterFilled(): return COLORS.green;
    case lang.commonMyAccountPendingOrdersPartiallFill(): return COLORS.warning;
    default: return COLORS.red;
  }
};

export const isOrderPartialyFill = (tenNStatus: TenNSOROrderStatus): boolean => (
  tenNStatus === TenNSOROrderStatus.PartialFill
);

export const setStatus = (status: TenNSOROrderStatus): string => {
  switch (status) {
    case TenNSOROrderStatus.Pending: return lang.commonOrderStatusPending();
    case TenNSOROrderStatus.PartialFill: return lang.commonMyAccountPendingOrdersPartiallFill();
    case TenNSOROrderStatus.PartialReject: return lang.commonMyAccountPendingOrdersPartialReject();
    case TenNSOROrderStatus.Filled: return lang.commonOrderStatusFilterFilled();
    case TenNSOROrderStatus.Cancelled: return lang.commonOrderStatusFilterCancelled();
    case TenNSOROrderStatus.Rejected: return lang.commonOrderStatusRejected();
    case TenNSOROrderStatus.Expired: return lang.commonOrderStatusFilterExpired();
    default: return lang.commonOrderStatusUndefined();
  }
};

export const mapOrderQuantity = (tenNStatus: TenNSOROrderStatus, totalQuantity: number, aqxExecutedQuantity: number):
string | number => {
  const totalQuantityText = formatNumber(totalQuantity, true);
  const partialFillText = `${formatNumber(aqxExecutedQuantity ?? 0, true)} / ${totalQuantityText}`;


  if (setStatus(tenNStatus) === lang.commonMyAccountPendingOrdersPartiallFill()
  || setStatus(tenNStatus) === lang.commonMyAccountPendingOrdersPartialReject()) {
    return partialFillText;
  }
  if (setStatus(tenNStatus) === lang.commonOrderStatusFilterExpired()
  || setStatus(tenNStatus) === lang.commonOrderStatusFilterCancelled()) {
    return !aqxExecutedQuantity ? totalQuantityText : partialFillText;
  }

  return totalQuantityText;
};

// eslint-disable-next-line max-len
export const mapOrderPrice = (type: SOROrderType, orderRequestedPrice: number, orderStopPrice: number): string | number => {
  switch (type) {
    case SOROrderType.Limit: return orderRequestedPrice;
    case SOROrderType.Stop: return orderStopPrice;
    default: return INVALID_VALUE;
  }
};

// eslint-disable-next-line max-len
export const calculatedOrderValue = (type: number, totalQuantity: number, orderRequestedPrice: number, orderStopPrice: number, averageFilledPrice: number): string => {
  switch (type) {
    case SOROrderType.Limit: return formatCurrency(totalQuantity * orderRequestedPrice);
    case SOROrderType.Stop: return formatCurrency(totalQuantity * orderStopPrice);
    default:
      if (averageFilledPrice === null) {
        return INVALID_VALUE;
      }
      return formatCurrency(totalQuantity * averageFilledPrice);
  }
};

/**
 * Returns a value in format 'DD MMMM' -> for example - 13 May
 */
export const parseDateToDateAndMonth = (value: string): string => {
  const moment = require('moment'); // using `require` enables jest.mock in test setup
  return moment(value).format(displayDateFormatNoYear);
};

export const calculateOrderValue = (price: number, quantity: number): string => (
  formatCurrency(price * quantity)
);

export const hasPartialFillDetail = (tenNStatus: string, filledQuantity: number, totalQuantity: number) => (
  tenNStatus === lang.commonMyAccountPendingOrdersPartiallFill()
  || tenNStatus === lang.commonMyAccountPendingOrdersPartialReject()
  || ((tenNStatus === lang.commonOrderStatusFilterExpired() || tenNStatus === lang.commonOrderStatusFilterCancelled())
  && filledQuantity && filledQuantity < totalQuantity));

export const getEndOfDayOrderExpirationDate = () => {
  const moment = require('moment');
  const today = moment.utc();

  if (isDateMarketDataWorkingDay(today)) {
    return today.format(pendingOrdersDatePlacedFormat);
  }

  return getNextWorkday()?.format(pendingOrdersDatePlacedFormat);
};
