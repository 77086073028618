import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import classNames from 'classnames';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { PositionType } from '../../../lib/store/reporting/types';
import { NullableString } from '../../../lib/util/types';

import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import CompanyLogo from '../../CompanyLogo/CompanyLogo';
import MyAccountButtons from '../../SwipeableItems/MyAccountButtons';
import { WATCH_LIST_TABLE_ITEMS } from '../../WatchList/WatchList.constans';

import TableRows from './TableRows';

// eslint-disable-next-line
import 'react-swipeable-list/dist/styles.css';
import styles from '../TableView.module.scss';

type OwnProps = {
  tableHeaders: { [symbol: string]: string | any };
  data: any;
  isMultipleDivOfRow: boolean;
  initiallyLengthRows?: number;
  isButtonsInRow?: boolean;
  companyLogos?: boolean;
  otherButtons?: string;
  hasSymbol?: boolean;
  selectedPieChartElement?: { colors: string[], symbolName: string };
  pendingOrders?: boolean;
}

type Props = OwnProps & ReduxProps;

let symbol: string = '';
const DEFAULT_NAME_LENGTH = 20;

const TableContainerView = (props: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    tableHeaders,
    data,
    isMultipleDivOfRow,
    isButtonsInRow,
    companyLogos,
    otherButtons,
    companyLogoAndNameData,
    hasSymbol,
    selectedPieChartElement,
    initiallyLengthRows = Number.MAX_VALUE,
    pendingOrders,
  } = props;
  const [selectedRowKey, setSelectedRowKey] = useState<NullableString>();

  const tableRowRef = useRef(null);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (symbol && event.composedPath().some(
        el => el.className === 'MuiTableRow-root TableView_clickableParrentTableRow__y9ohl',
      )
      && !event.target.className.includes('ButtonsSellAndBuy')
      && !event.target.className.includes('ButtonsCancelAndModify')
      ) {
        history.push(`${UI_ROUTES.symbol}/${symbol}`);
      }
    };

    document.addEventListener('click', handleClickInside);
    return () => document.removeEventListener('click', handleClickInside);
  }, [tableRowRef, history, isMobile]);

  const handleClickRow = (symbolName: string) => {
    symbol = symbolName;
  };

  const trimName = (name: string) => {
    if (!name) {
      return '';
    }

    if (name.length > DEFAULT_NAME_LENGTH) {
      return `${name.slice(0, DEFAULT_NAME_LENGTH)}...`;
    }
    return name;
  };

  const handleClickRowMob = (symbolName: string, e: Event): void => {
    e.preventDefault();
    history.push(`${UI_ROUTES.symbol}/${symbolName}`);
  };

  const onSwipeProgress = (rowKey:string, progress: number, direction: string) => {
    const MIN_PROGRESS_TO_OPEN_ROW = pendingOrders ? 28 : 40;
    if (rowKey !== selectedRowKey && progress >= MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(rowKey);
      return;
    }

    if (selectedRowKey && rowKey !== selectedRowKey) {
      setSelectedRowKey(null);
      return;
    }

    if (rowKey === selectedRowKey && progress < MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(null);
    }
  };

  return (
    <div className={styles.tableWrapper}>
      { isMobile ? (
        <MyAccountButtons
          data={data}
          hasSymbol={hasSymbol}
          companyLogos={companyLogos}
          pendingOrders={pendingOrders}
          companyLogoAndNameData={companyLogoAndNameData}
        />
      ) : (
        <TableContainer className={styles.wrapperTable}>
          <Table stickyHeader>
            <TableBody>
              <TableRow>
                {tableHeaders.map((column) => (
                  <TableCell
                    key={column.key}
                    className={styles.tableHeader}
                  >
                    <Grid className={isButtonsInRow
                      ? styles.buttons
                      : styles.tableHeaderText}
                    >
                      {column.props.children}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
            <TableBody>
              {data.map((row, index: number) => {
                let companyLogoAndName;
                if (!hasSymbol) {
                  companyLogoAndName = companyLogoAndNameData[row.name];
                }

                return (
                  <Fragment key={row.id}>
                    {
                      index < initiallyLengthRows && (
                        <TableRow
                          ref={tableRowRef}
                          key={row.id}
                          className={classNames({
                            [styles.clickableParrentTableRow]: row.name,
                            [styles.noClickableParrentTableRow]: !row.name,
                            [styles.chosenItem]: row.name === selectedPieChartElement?.symbolName,
                          })}
                          onClick={() => handleClickRow(row.name)}
                        >
                          {
                            isMultipleDivOfRow && !hasSymbol && (
                            <td className={styles.tableRow}>
                              <Grid className={styles.wrapperFirstColumn}>
                                {
                                  selectedPieChartElement && (
                                    <div
                                      style={{ background: selectedPieChartElement.colors[index] }}
                                      className={styles.colorVerticalLine}
                                    />
                                  )
                                }
                                {
                                  companyLogos
                                    ? (
                                      <CompanyLogo
                                        logo={companyLogoAndName?.logo}
                                        className={styles.image}
                                        symbol={row.name}
                                      />
                                    )
                                    : <img src={row.icon} className={styles.image} alt="" />
                                }
                                <Grid className={styles.columnName}>
                                  <Grid>
                                    <span className={styles.symbol}>{row.name}</span>
                                    {row.positionType === PositionType.Sell
                                    && <span className={styles.positionType}>{lang.commonShort()}</span>}
                                  </Grid>
                                  <Grid className={styles.companyName}>{companyLogoAndName?.name}</Grid>
                                </Grid>
                              </Grid>
                            </td>
                            )
                          }
                          {
                            Object.keys(row).map((key) => {
                              const isCheckItem = WATCH_LIST_TABLE_ITEMS.includes(key);

                              if (isCheckItem) {
                                return (
                                  <TableRows
                                    key={key}
                                    row={{ ...row, companyName: companyLogoAndName?.name }}
                                    rowKey={row[key]}
                                    headerKey={key}
                                    otherButtons={otherButtons}
                                  />
                                );
                              }
                              return null;
                            })
                          }
                        </TableRow>
                      )
                    }
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndNameData: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateToProps);
  type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TableContainerView);
