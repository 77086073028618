import { GenericStatusByCall } from '../../enums/status-by-call';
import { BasePagedResponse, PagedResponse } from '../../models/common-response-types';
import { Company } from '../../models/company';
import {
  CompanyLogoAndNameState,
  DividendPayerData,
  Earning,
  ETF,
  HighGrowthCompanyData,
  IPO,
  NewStockData,
  PageableFilterRequest,
  PopularStock,
  Sector,
  SectorDetail,
  Split,
  TopGainersAndLosersQueryOption,
} from '../../models/fundamental/types';
import { StockDescriptor } from '../../models/stock-descriptor';
import { SymbolKeyStat } from '../../models/symbol-key-stat';
import { DynamicFilterType } from '../../services/filter/types';
import { TypedObject } from '../../util/types';

export type GetSectorsResponse = Sector & {symbolUniverseSymbolCount: number};

export interface FundamentalState {
  stockQuery: string;
  limit: number;
  stockResults: StockDescriptor[];
  symbol: string;
  keyStats: SymbolKeyStat[];
  // TODO: Stop using companies for getting CompanyName. Take it from companyLogoAndName
  companies: Company[];
  // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  logosBase64: TypedObject<string>;
  companyLogoAndName: TypedObject<CompanyLogoAndNameState>;
  error: any;
  errorsLog: string[];
  popularStocks: PopularStock[];
  newStocks: NewStockData[];
  dividendPayers: PagedResponse<DividendPayerData>;
  highGrowthCompanies: PagedResponse<HighGrowthCompanyData>;
  sectors: Sector[];
  sectorDetails: SectorDetail;
  topGainersAndLosersFilterValues: {
    minValue: number;
    maxValue: number;
  };
  marketIndexes: [];
  topLosersLastQuery?: TopGainersAndLosersQueryOption;
  IPOs: IPO[];
  earnings: Earning[];
  earningsSearchResponse: PagedResponse<Earning>;
  splits: Split[];
  statusByCall: GenericStatusByCall<FundamentalCall>;
  ETFData: BasePagedResponse<ETF>;
}

export type SearchEarningsPayload = PageableFilterRequest & {
  symbols?: string[];
  startDate?: string | Date;
  endDate?: string | Date;
};

export type SearchStockPayload = {
  limit: number;
  query: string;
  params: DynamicFilterType;
};

export enum FundamentalCall {
  getPopularStocks,
  getNewStocks,
  getDividendPayers,
  getHighGrowthCompanies,
  getSectors,
  getSectorDetails,
  getTopGainers,
  getTopLosers,
  getIPOs,
  getEarnings,
  searchEarnings,
  getSplits,
  getCompanyLogoAndName,
  searchStocks,
  getMarketIndexes,
  getETFs,
  getETFDetails,
}

export enum SectorEnum {
  Communications = 'Communication Services',
  Healthcare = 'Healthcare',
  ConsumerCyclical = 'Consumer Cyclical',
  Technology = 'Technology',
  Utilities = 'Utilities',
  ConsumerDefensive = 'Consumer Defensive',
  FinancialServices = 'Financial Services',
  BasicMaterials = 'Basic Materials',
  RealEstate = 'Real Estate',
  Industrials = 'Industrials',
  Energy = 'Energy',
}
