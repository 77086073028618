import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action, Dispatch } from 'redux';

import lang from '../../lib/language';
import { GWMarketDataSummaryBucket } from '../../lib/models/gateway/types';
import { MarketState } from '../../lib/models/market-data/types';
import { RootState } from '../../lib/store';
import { processSymbolSubscribesAction, processSymbolUnSubscribesAction } from '../../lib/store/common-actions';
import { getCompanyLogoAndName as getLogoAndName } from '../../lib/store/fundamental';
import { isSymbolInFavourites } from '../../lib/store/helpers';
import {
  loadChartData as storeLoadChartData,
} from '../../lib/store/market-data';
import { ChartDataRange } from '../../lib/store/market-data/charting/types';
import { getFavoriteStocks } from '../../lib/store/selectors';
import BigChartCache from '../../lib/store-util/BigChartCache';
import MarketStateCache from '../../lib/store-util/MarketStateCache';
import SymbolStatusCache from '../../lib/store-util/SymbolStatusCache';
import { FAVOURITE_SYMBOLS_MAX_LIMIT } from '../../lib/util/constants';
import { chartingRangeToGatewaySummaryBucket } from '../../lib/util/GatewayHelpers';
import { checkIfSymbolIsHalted } from '../../lib/util/HaltSymbolHelper';
import { useInterval } from '../../lib/util/hooks/TimeoutHooks';

import { useIsMobile } from '../../hooks';
import ScopeButtons from '../Chart/ScopeButtons/ScopeButtons';
import { ChartContainer } from '../Chart/SymbolChart/ChartContainer';
import ChartHeader from '../Chart/SymbolChart/ChartHeader/ChartHeader';
import FavouriteSymbolsLimitModal from '../FavouriteSymbolsLimitModal/FavouriteSymbolsLimitModal';
import { Preloader } from '../index';
import FavouritesModal from '../StocksResult/componets/FavouritesModal';

import styles from './SymbolDetailsHeader.module.scss';

type OwnProps = {
  symbol: string;
  handleCreateOrderSellOrBuy: (target: string, isHeaderBtn: boolean) => void;
  isUserRestricted?: boolean;
};
type Props = OwnProps & ReduxProps;

const SymbolDetailsHeader = (props: Props) => {
  const {
    symbol,
    loadChartData,
    companyLogoAndName,
    favouriteStocks,
    individual,
    isUserRestricted,
    getCompanyLogoAndName,
    subscribeSymbols,
    unsubscribeSymbols,
    handleCreateOrderSellOrBuy,
  } = props;
  const isMobile = useIsMobile();
  const { dataByRange: chartDataByRange } = BigChartCache.use();
  const isMarketOpen = MarketStateCache.get().marketState === MarketState.RegularHours;

  const [currentRange, setCurrentRange] = useState<ChartDataRange>('1D');
  const [chartWrapperHeight, setChartWrapperHeight] = useState<number>(0);
  const [showNoData, setShowNoData] = useState<boolean>(false);
  const [hasChangedSymbol, setHasChangedSymbol] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [showFavouritesLimitMessage, setShowFavouritesLimitMessage] = useState<boolean>(false);
  const [isAddedToFavourites, setIsAddedToFavourites] = useState<boolean>(false);
  const [isRemovedFromFavourites, setIsRemovedFromFavourites] = useState<boolean>(false);
  const symbolsStatusCache = SymbolStatusCache.use(symbol);
  const dataByRange = currentRange && chartDataByRange ? chartDataByRange[currentRange] : undefined;
  const cacheParams = currentRange ? `${symbol}_${chartingRangeToGatewaySummaryBucket(currentRange)}` : null;

  const toggleFavoritesSubscription = useCallback(() => {
    if (isSymbolInFavourites(favouriteStocks, symbol)) {
      unsubscribeSymbols(processSymbolUnSubscribesAction({
        symbolOrSymbols: symbol,
        isRemovingFromFavorites: true,
        group: 'watchlist',
        caller: 'SymbolDetailsHeader',
        userCorrelationId: individual!.user_correlation_id,
      }));
      setIsRemovedFromFavourites(true);
    } else if (favouriteStocks.length >= FAVOURITE_SYMBOLS_MAX_LIMIT) {
      setShowFavouritesLimitMessage(true);
    } else {
      subscribeSymbols(processSymbolSubscribesAction({
        symbolOrSymbols: symbol,
        isFavorites: true,
        group: 'watchlist',
        caller: 'SymbolDetailsHeader',
        userCorrelationId: individual!.user_correlation_id,
      }));
      setIsAddedToFavourites(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteStocks, unsubscribeSymbols, subscribeSymbols]);

  const logo = useMemo(() => (companyLogoAndName[symbol]
    ? companyLogoAndName[symbol]?.logo : undefined), [companyLogoAndName, symbol]);

  const headerProps = {
    symbol,
    logo,
    company: companyLogoAndName[symbol]?.name,
  };

  const handleBtnRetry = () => {
    setShowNoData(false);
    loadChartData(symbol, chartingRangeToGatewaySummaryBucket(currentRange), true);
  };

  /**
   * This useRef hook is used to avoid duplicate `loadChartData` dispatches on initial (first) render.
   * Initial value `true` is changed to `false` by the last `useEffect` - it has empty array as dependencies therefore
   * it triggers on component mount only.
   * There are two useEffect hooks that dispatch `loadChartData` on first render:
   *    - one with `symbol`
   *    - and another with `currentRange` as dependency
   * The second useEffect - for `symbol` - is checking  `isFirstRef.current` value
   * and thus skips dispatching `loadChartData` on initial render.
   * On the other hand the other `useEffect` - for `symbol` - is not using the check
   * and does dispatch `loadChartData` on first render.
   */
  const isFirstRef = useRef(true);

  useEffect(() => {
    if (!companyLogoAndName[symbol]) {
      getCompanyLogoAndName();
    }
  }, [companyLogoAndName, getCompanyLogoAndName, symbol]);

  useEffect(() => {
    if (dataByRange?.isValid) {
      setCounter(0);
      setHasChangedSymbol(false);
    }
  }, [dataByRange?.isValid]);

  useInterval(() => {
    if (symbol && counter < 10) {
      loadChartData(symbol, GWMarketDataSummaryBucket.OneDay);
      if (!dataByRange?.isValid) {
        setCounter(counter + 1);
      }
    }
  // eslint-disable-next-line no-nested-ternary
  }, currentRange === '1D' && isMarketOpen ? (!dataByRange?.isValid ? 1000 : 5000) : null);

  useEffect(() => {
    if (!hasChangedSymbol) {
      const bucket = chartingRangeToGatewaySummaryBucket(currentRange);
      loadChartData(symbol, bucket, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRange]);

  useEffect(() => {
    if (symbol && !isFirstRef.current) {
      loadChartData(symbol, GWMarketDataSummaryBucket.OneDay, true);
    }
    setHasChangedSymbol(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  useEffect(() => {
    isFirstRef.current = false;
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      const windowWitdth = document.getElementById('charContainer')?.offsetWidth;
      if (windowWitdth) {
        setChartWrapperHeight(windowWitdth / 3);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      {individual && (
      <ChartHeader
        {...headerProps}
        handleCreateOrderSellOrBuy={handleCreateOrderSellOrBuy}
        isSymbolInFavorites={isSymbolInFavourites(favouriteStocks, symbol)}
        toggleFavoritesSubscription={toggleFavoritesSubscription}
      />
      )}
      {checkIfSymbolIsHalted(symbolsStatusCache) && !isUserRestricted
        && <div className={styles.haltedSymbolInfo}>{lang.commonHaltedSymbolInfo()}</div>}
      <div className={styles.chartWrapper}>
        {/* In mobile we need to change the order of ScopeButtons and ChartContainer */}
        { isMobile ? (
          null
        ) : (
          <ScopeButtons
            symbol={symbol}
            chartDataByRange={chartDataByRange}
            changeRange={(range: ChartDataRange) => {
              setCurrentRange(range);
            }}
            onScopeButtonClick={() => { if (hasChangedSymbol) setHasChangedSymbol(!hasChangedSymbol); }}
          />
        )}

        <div id="charContainer" style={{ height: `${chartWrapperHeight}px` }}>
          {dataByRange?.isValid || showNoData ? (
            <ChartContainer
              symbol={symbol}
              dataSet={dataByRange!}
              range={currentRange}
              hasNoData={showNoData}
              handleBtnRetry={handleBtnRetry}
            />
          ) : (
            <div className={styles.noData}>
              <Preloader
                isTextOnly
                cacheTimeOutSetup={{
                  cacheName: cacheParams ? 'getChartingData' : null,
                  cacheParams,
                  hasNoData: !(dataByRange as any)?.isValid,
                  setHasNoData: (value) => setShowNoData(value),
                }}
                owner="ChartContainer"
              />
            </div>
          )}
        </div>
        {/* In mobile we need to change the order of ScopeButtons and ChartContainer */}
        { !isMobile ? (
          null
        ) : (
          <div className={styles.scopeButtons}>
            <ScopeButtons
              symbol={symbol}
              chartDataByRange={chartDataByRange}
              changeRange={(range: ChartDataRange) => {
                setCurrentRange(range);
              }}
              onScopeButtonClick={() => { if (hasChangedSymbol) setHasChangedSymbol(!hasChangedSymbol); }}
            />
          </div>
        )}
      </div>
      { (isRemovedFromFavourites || isAddedToFavourites) && (
        <FavouritesModal
          isMobile={isMobile}
          isAddedToFavourites={isAddedToFavourites}
          isRemovedFromFavourites={isRemovedFromFavourites}
          symbol={symbol}
          closeModal={() => {
            setIsAddedToFavourites(false);
            setIsRemovedFromFavourites(false);
          }}
        />
      )}
      { showFavouritesLimitMessage && (
        <FavouriteSymbolsLimitModal isMobile={isMobile} closeMessage={() => setShowFavouritesLimitMessage(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  favouriteStocks: getFavoriteStocks(state),
  companyLogoAndName: state.fundamental.companyLogoAndName,
  individual: state.crm.individualExtendedInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps) => ({
  subscribeSymbols: (data) => dispatch(data),
  unsubscribeSymbols: (data) => dispatch(data),
  getCompanyLogoAndName: () => dispatch(getLogoAndName(ownProps.symbol)),
  loadChartData: (
    symbol: string,
    bucket: GWMarketDataSummaryBucket,
    hasRangeChanged?: boolean,
  ) => dispatch(storeLoadChartData({ symbol, bucket, hasRangeChanged })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SymbolDetailsHeader);
