import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';

import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store/index';
import { OpenPosition, PositionType } from '../../../lib/store/reporting/types';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import {
  calcUnrealizedProfitPercent,
  formatCurrency,
  formatNumberWithSuffix,
  roundUnrealizedProfit,
  unrealizedPnL,
} from '../../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';

import styles from './SharesOwned.module.scss';

type Props = ReduxProps & {
  symbol: string
};

const SharesOwned = ({
  symbol,
  positions,
  tradeHistoryOrders,
  isOpenPositionsReady,
  isTradeHistoryReady,
}: Props) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const tradePricesCache = TradePriceCache.use(symbol, 'SharesOwned');
  const marketPrice = tradePricesCache.currentPrice;
  const symbolPosition = positions.find(el => el.symbol === symbol);

  const unrealizedPrice = (position: OpenPosition) => {
    const { quantity, averageOpeningPrice } = position;
    if (marketPrice) {
      return roundUnrealizedProfit(unrealizedPnL(quantity, marketPrice, averageOpeningPrice), true);
    }
    return '-';
  };

  const checkPosition = isOpenPositionsReady && positions.find(el => el.symbol === symbol);
  const checkHistoryOrders = isTradeHistoryReady && tradeHistoryOrders.find(el => el.symbol === symbol);

  const renderViewHistoryBtn = (
    <Button
      variant={ButtonTypesEnum.Link}
      onClick={() => history.push({
        pathname: `${UI_ROUTES.tradeHistory}`,
        state: { symbol },
      })}
    >
      {lang.commonViewHistory()}
    </Button>
  );

  const unrealizedProfitPercent = (order: OpenPosition) => calcUnrealizedProfitPercent(
    order.positionType,
    marketPrice!,
    order.averageOpeningPrice,
  );

  if (checkHistoryOrders && !checkPosition) {
    return (
      <div className={styles.wrapperMessage}>
        <span className={styles.message}>{lang.commonYouHaveTraded()}</span>
        {renderViewHistoryBtn}
      </div>
    );
  }

  return (
    <>
      {checkPosition && (
        <div className={styles.wrapperOwnedShares}>
          <div className={isMobile ? styles.headerLineMobile : styles.headerLine}>
            <div className={styles.wrapperTitle}>
              <h6 className={isMobile ? styles.titleMobile : styles.title}>{lang.commonSharesOwned()}</h6>
              { symbolPosition?.positionType === PositionType.Sell
                && <span className={styles.shortTitle}>{lang.commonShort()}</span>}
            </div>
            <div className={styles.renderViewHistoryBtn}>
              {renderViewHistoryBtn}
            </div>
          </div>
          <div className={isMobile ? styles.wrapperMobile : styles.wrapper}>
            {symbolPosition && (
              <>
                {isMobile ? (
                  <div className={isMobile ? styles.cardMobile : styles.card}>
                    <div className={styles.cardInner}>
                      <div className={styles.cardContent}>
                        <p>{lang.commonQuantityXAveragePrice()}</p>
                        <p>{Math.abs(symbolPosition.quantity).toString().concat(' x ').concat(formatCurrency(symbolPosition.averageOpeningPrice))}</p>
                      </div>
                      <div className={styles.cardContent}>
                        <p>{lang.commonUnrealizedProfit()}</p>
                        <p>
                          {marketPrice ? (
                            <span className={Number(
                              (unrealizedProfitPercent(symbolPosition)),
                            ) < 0 ? styles.negative : styles.positive}
                            >
                              {unrealizedPrice(symbolPosition).concat(' ')}
                              {`(${unrealizedProfitPercent(symbolPosition).toString().substring(Number(unrealizedProfitPercent(symbolPosition)) < 0 ? 1 : 0)}%)`}
                            </span>
                          ) : '-'}
                        </p>
                      </div>
                      <div className={styles.cardContent}>
                        <p>{lang.commonMarketValue()}</p>
                        <p>{marketPrice ? formatCurrency(Math.abs(symbolPosition.quantity) * marketPrice) : '-'}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={styles.card}>
                      <div className={styles.cardInner}>
                        <div className={styles.cardContent}>
                          <p>{lang.headerAccountBreakdownQuantity()}</p>
                          <p>{Math.abs(symbolPosition.quantity)}</p>
                        </div>
                        <div className={styles.cardContent}>
                          <p>{lang.positionAverageOpenPrice()}</p>
                          <p>{formatCurrency(symbolPosition.averageOpeningPrice)}</p>
                        </div>
                        <div className={styles.cardContent}>
                          <p>{lang.headerAccountBreakdownOpenValue()}</p>
                          <p>{formatCurrency(symbolPosition.cost)}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.card}>
                      <div className={styles.cardInner}>
                        <div className={styles.cardContent}>
                          <p>{lang.positionAUnrealisedProfitValue()}</p>
                          <p>{unrealizedPrice(symbolPosition)}</p>
                        </div>
                        <div className={styles.cardContent}>
                          <p>{lang.positionAUnrealisedProfitPercent()}</p>
                          <p>
                            {marketPrice ? (
                              <span className={Number(
                                (unrealizedProfitPercent(symbolPosition)),
                              ) < 0 ? styles.negative : styles.positive}
                              >
                                {formatNumberWithSuffix(unrealizedProfitPercent(symbolPosition), '%')}
                              </span>
                            ) : '-'}
                          </p>
                        </div>
                        <div className={styles.cardContent}>
                          <p>{lang.headerAccountBreakdownMarketValue()}</p>
                          <p>{marketPrice ? formatCurrency(Math.abs(symbolPosition.quantity) * marketPrice) : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  tradeHistoryOrders: state.reporting.tradeHistoryOrders,
  isOpenPositionsReady: isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall),
  isTradeHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
  accountOpenPositionsCompleted: state.reporting.accountOpenPositionsCompleted,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SharesOwned);
