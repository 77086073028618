import React from 'react';

import CompanyLogo from '../CompanyLogo/CompanyLogo';

import styles from './SymbolWithLogo.module.scss';

type Props = {
  logo: string | null;
  symbol: string;
  symbolCompany?: string;
  className?: string;
}

const SymbolWithLogo = (props: Props): JSX.Element => {
  const {
    logo,
    symbol,
    className,
    symbolCompany,
  } = props;

  return (
    <div className={[styles.symbolDetailsWrapper, className].join(' ')}>
      {logo && <CompanyLogo logo={logo} className={styles.logo} symbol={symbol} />}

      <div className={styles.symbolDetailsContent}>
        <h6 className={styles.symbol}>{symbol}</h6>
        {symbolCompany && <p className={styles.companyName}>{symbolCompany}</p>}
      </div>
    </div>
  );
};

export default SymbolWithLogo;
