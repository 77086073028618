import React from 'react';
import { Box, Popover } from '@material-ui/core';

import styles from './ChartPopover.module.scss';

type Props = {
    open: boolean;
    handlePopoverClose: () => void;
    popoverStatus: HTMLDivElement | null;
    label: string;
}

const ChartPopover = ({ open, handlePopoverClose, popoverStatus, label }: Props) => (
  <Popover
    id="mouse-over-popover"
    className={styles.wrapperPopover}
    open={open}
    anchorEl={popoverStatus}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    onClose={handlePopoverClose}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '0px 15px',
        height: '44px',
        overflowY: 'hidden',
      },
    }}
  >
    <div className={styles.arrowBox} />
    <p>{label}</p>
  </Popover>
);

export default ChartPopover;
