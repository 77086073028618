import React, { useState } from 'react';
import { Popover } from '@material-ui/core';

import lang from '../../lib/language';
import { FINRA_FEE, SEC_FEE } from '../../lib/store/reporting/constants';

import InfoIcon from '../../assets/img/icon-info-outline.svg';
import { useBrowserWidth } from '../../hooks';
import { ZOOM_BEHAVIOR_WIDTH } from '../../util/constants';
import ClickableImage from '../ClickableImage/ClickableImage';

import styles from './DetailTradeModule.module.scss';

const popoverId = 'regulatory-fees-popover';

type Props = {
    fee: string;
    taf: string;
    sec: string;
}

const RegulatoryFeesInfo = ({ fee, taf, sec }: Props) => {
  const { browserWidthSize } = useBrowserWidth();

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverClick = event => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const handleStyleMarginTop = () => {
    switch (true) {
      case (browserWidthSize >= 600): return '20%';
      case (browserWidthSize >= 500): return '35%';
      case (browserWidthSize >= 400): return '45%';
      case (browserWidthSize >= 300): return '75%';
      default: return '100%';
    }
  };

  return (
    <>
      <span>
        <ClickableImage
          src={InfoIcon}
          onClick={handlePopoverClick}
          aria-describedby={popoverId}
          variant="contained"
          className={styles.popoverTrigger}
        />
      </span>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{
          zoom: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? `${Math.floor(browserWidthSize / 10).toString()}%` : '',
          marginTop: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? handleStyleMarginTop() : '',
          marginLeft: browserWidthSize <= 575 ? '20%' : '',
        }}
        classes={{ paper: styles.popoverPaper }}
      >
        <div className={styles.wrapperMessages}>
          <span>{lang.commonRegulatoryFeesMessageFINRA(taf)}</span>
          <span>{lang.commonRegulatoryFeesMessageFINRADesc(FINRA_FEE)}</span>
          <span>{lang.commonRegulatoryFeesMessageSEC(sec)}</span>
          <span>{lang.commonRegulatoryFeesMessageSECDesc(SEC_FEE)}</span>
          <span>{lang.commonRegulatoryFeesMessageTotal(fee)}</span>
        </div>
      </Popover>
    </>
  );
};

export default RegulatoryFeesInfo;
