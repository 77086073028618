import { CurrencyEnum, OrderSideEnum } from '../../enums/index';
import { OrderTypeEnum } from '../../enums/order-type.enum';
import { GWMarketDataSummaryBucket, GWQueryCase, GWRequestType } from '../gateway/types';


export type TradingConfig = {
  exDestination: string
  subDestination: string
}

export enum GetDetailsFunctionalityType {
  getPositions = 'getPositions',
  getOrders = 'getOrders',
  getOrderHistory = 'getOrderHistory',
  getTransactions = 'getTransactions',
  getBalances = 'getBalances'
}

export type Balance = {
  account: string
  // All below are number strings - for example '136.83'
  availBp: number
  equity?: number
  equityPlusRpnl?: number
  ordExp?: number
  posExp?: number
  rpnl?: number
  totalBp?: number
  usedBp?: number
}

export type BuyingPower = {
  buyingPowerId: number,
  accountReferenceId: string,
}

export type RawBalanceData = Pick<Balance, 'account'> & {
  availBp: string
  equity: string
  equityPlusRpnl: string
  ordExp: string
  posExp: string
  rpnl: string
  totalBp: string
  usedBp: string
}

export type QueryCacheItemType = {
  queryCase: GWQueryCase | GWMarketDataSummaryBucket
  symbol: string
  requestData?: any
}
export type QueriesCacheType = { [key: string]: QueryCacheItemType }


export interface Order {
  status?: string;
  reqId?: string;
  rc?: string;
  requestType?: GWRequestType | null;
  account?: string;
  clientOrderId?: string;
  symbol?: string;
  side?: OrderSideEnum;
  orderQty?: number;
  ordType?: OrderTypeEnum;
  price?: number;
  stopPrice?: number;
  exDestination?: string;
  subDestination?: string;
  timeInForce?: string // Defined as TimeInForceEnum or date if is GTD;
  createdAt?: string;
  updatedAt?: string;
  error?: string;
  avgPriceSoFar?: number;
  filledSoFar?: number;
  fills?: Execution[]
  destination?: string
  includeExtendedHours?: boolean
  originalClientOrderId?: string;
  traceIdentifier?: string;
}

export type RawOrderData = Omit<Order, 'price' | 'stopPrice' | 'orderQty' | 'fills'> & {
  orderQty?: string;
  price?: string;
  stopPrice?: string;
  fills?: RawExecutionData[];
  avgPriceSoFar?: string; // "3550.00000000",
}

export type OrdersMap = { [key: string]: Order };

export type Position = {
  symbol: string;
  qty: number;
  avgPrice: number;
}
export type RawPositionData = Omit<Position, 'qty' | 'avgPrice' > & {
  qty: string; // number string - for example '8'
  avgPrice: string; // number string - for example '136.83920'
}


/** OMS variant */
export type Execution = {
  parentOrder?: Order;
  execId: string; // "070610150001098"
  timeStamp: string // "2021-07-06T15:24:27.017Z"
  fillQty: number;
  fillPrice: number;
}

/** OMS variant */
export type RawExecutionData = Pick<Execution, 'execId' | 'timeStamp'> &{
  fillPrice: string // "3550.0",
  fillQty: string // "1"
}


/**
 * @deprecated
 *
 * Actually RequestStatus should be OrderStatus. The values here are not correct.
 * In the web app its usage is already deprecated.
 *
 * TODO::// Review where this type is using in mobile app and change it with `OrderStatusEnum`(order-status.enum.ts),
 */
export type RequestStatus = 'new' | 'inprogress' | 'accepted' | 'rejected' | 'timedout';

export enum FXType {
  ForexMarket = 19,
  ForexLimit = 22
}

export type FXOrderPayload = {
  quantity: number;
  side: OrderSideEnum;
  symbol: string;
  currency: CurrencyEnum;
  settlCurrency: CurrencyEnum;
  type: FXType;
}

export type FXOrderRequest = FXOrderPayload & {
  account: string;
  clientOrderId: string;
  dateTime: string;
  messageType: GWRequestType;
  traceIdentifier: string;
  destination: string;
  capacity: string;
};
