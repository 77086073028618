import { TimeInForceEnum } from '../../lib/enums';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { TextHelpers } from '../../lib/util/TextHelpers';

import LimitChart from '../../assets/img/icon-limit-chart.svg';
import MarketChart from '../../assets/img/icon-market-chart.svg';
import StopOrderChart from '../../assets/img/icon-stop-chart.svg';
import { DropdownOption } from '../Dropdown/Dropdown.types';

export const OrderTypeDropdownOptions = (
  isMarketHours: boolean,
  isBuyOrder: boolean,
): DropdownOption[] => [
  {
    text: `${TextHelpers.getOrderTypeName(OrderTypeEnum.Market)}  ${lang.commonTradingOrder().toLowerCase()}`,
    value: OrderTypeEnum.Market,
    icon: MarketChart,
    subText: lang.mobileTradingTicketExecutedAtBestAvailablePrice(),
    disabled: !isMarketHours,
  },
  {
    text: `${TextHelpers.getOrderTypeName(OrderTypeEnum.Limit)} ${lang.commonTradingOrder().toLowerCase()}`,
    value: OrderTypeEnum.Limit,
    icon: isBuyOrder ? LimitChart : StopOrderChart,
    subText: lang.mobileTradingTicketExecutedAtLimitPriceOrLover(),
  },
  {
    text: `${TextHelpers.getOrderTypeName(OrderTypeEnum.Stop)} ${lang.commonTradingOrder().toLowerCase()}`,
    value: OrderTypeEnum.Stop,
    icon: isBuyOrder ? StopOrderChart : LimitChart,
    subText: lang.mobileTradingTicketExecutedWhenStopPriceIsReached(),
    disabled: !isMarketHours,
  },
];

export const ExpirationDropDownOptions: DropdownOption[] = [
  {
    text: TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay),
    value: TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay),
    subText: lang.commonExpirationOrderEndOfDaySubText(),
  },
  {
    text: TextHelpers.getTimeInForceName(TimeInForceEnum.GTC),
    value: TimeInForceEnum.GTC,
    subText: lang.commonExpirationOrderGoodTillCanceledSubText(),
  },
];
