import { ApiResponse } from 'apisauce';

import config from '../../config';
import { FILE_REQUEST_TIMEOUT } from '../libSettings';

import { BaseRequestService } from './BaseRequest.service';
import { IFileService } from './types';

const API_PATH = {
  get: '/File',
  upload: '/File',
  deleteByName: '/File?fileName=',
};

export class FileService extends BaseRequestService implements IFileService {
  protected getBaseUrl(): string {
    return config.file.url;
  }

  constructor(token: string | null) {
    super(token, FILE_REQUEST_TIMEOUT);
  }

  get(fileName: string): Promise<ApiResponse<string>> {
    if (!fileName) {
      throw new Error('[File.service] File name not provided');
    }

    return this.api.get<string>(`${API_PATH.get}/${fileName}`);
  }

  async upload(data: string, fileName: string) {
    if (!data) {
      throw new Error('[File.service] No file data given');
    }

    return this.api.post<any[]>(API_PATH.upload, {
      fileName,
      fileData: data,
    });
  }

  deleteByName(fileName: string): Promise<ApiResponse<null>> {
    return this.api.delete<null>(API_PATH.deleteByName + fileName);
  }
}
