import { PredefinedMilisecondsTypeEnum } from '../../enums/predefined-miliseconds-type.enum';
import { TOKEN_MIN_TIME_TO_EXPIRATION } from '../../libSettings';
import { decodeJwt } from '../../util/DataHelpers';
import { NullableString } from '../../util/types';
import { getMillisecondsToTokenExpiration, hasValidToken } from '../selectors';

export function isAutoRefreshTokenEnabled(token: NullableString) {
  if (!hasValidToken(null, token)) return true;

  const tokenDecoded = decodeJwt(token);
  if (tokenDecoded?.exp == null) return true;

  const timeToExpirationInMs = getMillisecondsToTokenExpiration(tokenDecoded?.exp);
  if (timeToExpirationInMs == null) return true;

  return timeToExpirationInMs < TOKEN_MIN_TIME_TO_EXPIRATION;
}
