import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { components } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import ClearIcon from 'assets/img/icon-clear.svg';
import SearchIcon from 'assets/img/icon-search-dark.svg';
import { Action, Dispatch } from 'redux';

import lang from '../../lib/language';
import { StockDescriptor } from '../../lib/models/stock-descriptor';
import { RootState } from '../../lib/store';
import { clearStockSearch as serviceClearStockSearch, getCompanyLogoAndName, searchStock } from '../../lib/store/fundamental';
import { FundamentalCall } from '../../lib/store/fundamental/types';
import { isCallStatusPending } from '../../lib/util/error-handling/StatusByCallHelpers';

import SearchImage from '../../assets/bigImages/search.png';
import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import noMessagesLogo from '../../assets/img/image_empty.png';
import { ClickableDiv, ClickableImage, CompanyLogo, Preloader } from '../../components';
import { useIsMobile } from '../../hooks';

import styles from './SearchSymbols.module.scss';

type GroupedOptions = {
  label: any;
  searchValue: string;
  options: SearchStocksOption[];
};

type SearchStocksOption = {
  label: any;
};

type OwnProps = {
  handleSearchSymbol: (symbol: string) => void;
  searchValue: string;
  handleReset?: () => void;
}

type Props = OwnProps & ReduxProps;

const SearchStocks = (props: Props) => {
  const {
    searchingStocks,
    stocks,
    searchValue,
    companyLogoAndNameData,
    handleReset,
    handleSearchSymbol,
    clearStockSearch,
    searchStocks,
    getDataCompanyLogoAndName,
  } = props;

  const isMobile = useIsMobile();
  const [focusedInput, setFocusedInput] = useState<boolean>(false);
  const [dirtyInput, setDirtyInput] = useState<boolean>(false);
  const [isEmptyString, setIsEmptyString] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>('');
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [isSymbolSearchClicked, setIsSymbolSearchClicked] = useState<boolean>(false);
  const [filteredStocks, setFilteredStocks] = useState<StockDescriptor[]>([]);
  const selectControlStyles = useMemo((): StylesConfig<SearchStocksOption, boolean> => ({
    valueContainer: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      height: 40,
      backgroundColor: '#F7F7FA',
      borderRadius: 6,
      paddingLeft: 40,
      cursor: 'pointer',
    }),
    input: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      fontFamily: 'Source Sans Pro',
      color: '#2D2D2D !important',
      fontSize: 16,
      fontWeight: 400,
      opacity: 1,
    }),
    control: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      border: 0,
      boxShadow: 'none',
    }),
    menu: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      margin: 0,
      border: '1px solid #3BB0FF',
      backgroundColor: '#1D3960',
      color: 'white',
    }),
    menuList: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      maxHeight: 534,
      padding: '16px 0',
    }),
    option: (base: CSSProperties, { isFocused }: { isFocused: boolean }) => ({
      ...base,
      background: isFocused ? '#11305d' : 'transparent',
    }),
    groupHeading: (currentStyles: CSSProperties) => ({
      ...currentStyles,
      fontFamily: 'Source Sans Pro',
      fontSize: '13px',
      color: '#2D2D2D',
      textTransform: 'capitalize',
      fontWeight: 400,
      marginTop: '-16px',
      padding: '0 17px',
    }),
    group: (currentStyles: CSSProperties) => ({ ...currentStyles, padding: '0' }),
    indicatorSeparator: () => ({}),
    loadingIndicator: (base: CSSProperties) => ({
      ...base,
      backgroundColor: '#1D3960',
    }),
    loadingMessage: (base: CSSProperties) => ({
      ...base,
      backgroundColor: '#1D3960',
    }),
  }), []);

  const onChangeHandler = (option) => {
    handleSearchSymbol(option.stockSymbol);
  };

  const onMobileSelectHandler = (symbol: string) => {
    handleSearchSymbol(symbol);
    setIsSymbolSearchClicked(false);
    setIsEmptyString(true);
  };

  const onInputHandler = useCallback((value: string) => {
    const notEmptyString = value.trim() !== '';
    if (notEmptyString) {
      setSearchString(value);
    } else {
      handleReset && handleReset();
    }
    setIsEmptyString(!notEmptyString);
    setDirtyInput(notEmptyString);
    if (notEmptyString) {
      searchStocks && searchStocks(value);
    }
  }, [searchStocks, handleReset]);

  const onBlurHandler = useCallback(e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedInput(false);
      setDirtyInput(true);
      clearStockSearch && clearStockSearch();
    }
  }, [clearStockSearch]);

  const setValueContainer = useCallback((values) => {
    const handleChildren = () => (values.children ? values.children : '');
    return (
      <components.ValueContainer isFocused {...values} className={[styles.searchWrapper, focusedInput && styles.focusedInput].join(' ')}>
        <img src={SearchIcon} alt="search icon" />
        { searchValue.length > 0 ? handleChildren() : <div>{handleChildren()}</div>}
      </components.ValueContainer>
    );
  }, [focusedInput, searchValue]);

  const preloaderContainer = useMemo(() => (
    <div className={styles.preloaderWrapper}>
      <Preloader owner="SearchStocks" />
    </div>
  ), []);

  const options = useMemo((): GroupedOptions[] => {
    const filteredStocksByFirstLetters = stocks.filter(stock => {
      const { symbol, companyName } = stock;
      return ((symbol && symbol.startsWith(searchString.toUpperCase()))
      || (companyName && companyName.toUpperCase().startsWith(searchString.toUpperCase())));
    });
    setFilteredStocks(filteredStocksByFirstLetters);

    return (
      [
        {
          label: '',
          searchValue,
          options: filteredStocksByFirstLetters.map((stock: StockDescriptor) => {
            const companyLogoAndName = companyLogoAndNameData[stock.symbol];

            return (
              ({
                stockCompanyName: stock.companyName,
                stockSymbol: stock.symbol,
                label: (

                  <div
                    data-value={stock.companyName
                      ? stock.companyName
                      : stock.symbol}
                    className={companyLogoAndName ? styles.wrapperRow : ''}
                  >
                    {
                      companyLogoAndName && (
                        <div className={styles.wrapperLogoAndName}>
                          <CompanyLogo logo={companyLogoAndName.logo} className={styles.logo} symbol={stock.symbol} />
                          <div className={styles.wrapperName}>
                            <p className={styles.symbolTitle}>{stock.symbol}</p>
                            <p className={styles.labelMeta}>{stock.companyName}</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                ),
              })
            );
          }),
        },
      ]
    );
  }, [stocks, searchString, searchValue, companyLogoAndNameData]);

  const filterOption = (searchOption, inputValue: string) => {
    const { label, data } = searchOption;

    if (options[0]) {
      const filteredOptions = options[0].options.filter((option: SearchStocksOption) => option.label.props['data-value'] === label.props['data-value']);
      if (data.stockCompanyName && data.stockSymbol) {
        return data.stockCompanyName.toLowerCase().includes(inputValue.toLowerCase())
        || data.stockSymbol.toLowerCase().includes(inputValue.toLowerCase());
      }
      return filteredOptions.length > 0;
    }
    return false;
  };

  const handleOnFocus = () => {
    searchValue.length > 0 && handleSearchSymbol('');
    setFocusedInput(true);
  };

  useEffect(() => {
    if (stocks) {
      const symbols = stocks.map(stock => stock.symbol);
      getDataCompanyLogoAndName(symbols.join());
    }
  }, [stocks, getDataCompanyLogoAndName]);

  return (
    <>
      { isMobile ? (
        <>
          <div className={searchValue !== '' ? styles.statusWrapperSelected : styles.statusWrapper}>
            <ClickableDiv className={styles.statusContainer} onClick={() => setIsSymbolSearchClicked(true)}>
              <div className={styles.status}>
                {searchValue !== '' ? searchValue : 'Symbol'}
              </div>
            </ClickableDiv>
            {searchValue !== '' && (
              <ClickableImage
                className={styles.clearButton}
                src={ClearIcon}
                alt="clear"
                onClick={() => {
                  handleReset && handleReset();
                  searchStocks('');
                }}
              />
            )}
          </div>
          {isSymbolSearchClicked && (
            <div className={styles.background}>
              <div className={styles.container}>
                <div className={styles.header}>
                  <ClickableImage src={ArrowBack} onClick={() => setIsSymbolSearchClicked(false)} />
                  <input
                    className={styles.input}
                    type="text"
                    placeholder={lang.mobileStockSearchHint()}
                    onChange={(e) => onInputHandler(e.target.value)}
                    onFocus={() => {
                      setSearchString('');
                    }}
                  />
                </div>
                { searchingStocks ? (
                  <div className={styles.loader}>
                    <Preloader text={lang.mobileStockSearchHint()} owner="SearchStocks" />
                  </div>
                ) : (
                  <>
                    { isEmptyString ? (
                      <>
                        <img className={styles.image} src={SearchImage} alt="search" />
                        <div className={styles.text}>{lang.mobileStockSearchDescription()}</div>
                      </>
                    ) : (
                      <>
                        { filteredStocks.length > 0 ? (
                          <>
                            {filteredStocks.map((stock: StockDescriptor) => {
                              const companyLogoAndName = companyLogoAndNameData[stock.symbol];
                              return (
                                <ClickableDiv
                                  className={styles.wrapperRow}
                                  key={stock.symbol}
                                  onClick={() => onMobileSelectHandler(stock.symbol)}
                                >
                                  { companyLogoAndName && (
                                    <div className={styles.wrapperLogoAndName}>
                                      <CompanyLogo
                                        logo={companyLogoAndName.logo}
                                        className={styles.logo}
                                        symbol={stock.symbol}
                                      />
                                      <div className={styles.wrapperName}>
                                        <p className={styles.symbolTitle}>{stock.symbol}</p>
                                        <p className={styles.labelMeta}>{stock.companyName}</p>
                                      </div>
                                    </div>
                                  )}
                                </ClickableDiv>
                              );
                            })}
                          </>
                        ) : (
                          <div className={styles.messageWrapper}>
                            <img className={styles.image} src={noMessagesLogo} alt="no messages" />
                            <div className={styles.text}>{lang.mobileNoStocksFound()}</div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Select
          value={{ label: searchValue.length > 0 ? searchValue : lang.commonSearchForSymbol() }}
          blurInputOnSelect
          options={options}
          onBlur={onBlurHandler}
          isLoading={focusedInput && searchingStocks}
          styles={selectControlStyles}
          onFocus={handleOnFocus}
          onInputChange={(value: string) => onInputHandler(value)}
          noOptionsMessage={() => (dirtyInput ? lang.mobileNoStocksFound() : null)}
          onChange={onChangeHandler}
          filterOption={(option, inputValue) => filterOption(option, inputValue)}
          components={{
            ValueContainer: setValueContainer,
            DropdownIndicator: () => null,
            LoadingIndicator: () => preloaderContainer,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  searchingStocks: isCallStatusPending(FundamentalCall.searchStocks, state.fundamental.statusByCall),
  stocks: state.fundamental.stockResults,
  companyLogoAndNameData: state.fundamental.companyLogoAndName,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  clearStockSearch: () => dispatch(serviceClearStockSearch()),
  searchStocks: (name: string) => dispatch(searchStock(name)),
  getDataCompanyLogoAndName: (symbols: string) => dispatch(getCompanyLogoAndName(symbols)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SearchStocks);
