import { produce } from 'immer';
import { entity } from 'simpler-state';

const INITIAL_STATE = { isLoading: true, isLoadingBlocked: false };

const self = entity(INITIAL_STATE);

const setLoading = (value: boolean): void => {
  self.set(
    produce(state => {
      state.isLoading = state.isLoadingBlocked ? false : value;
    }),
  );
};

const setLoadingBlocked = (value: boolean): void => {
  self.set(
    produce(state => {
      state.isLoadingBlocked = value;
    }),
  );
};

export default {
  use: () => self.use(data => data),
  useLoading: () => self.use(data => data.isLoading),
  isLoading: () => self.get().isLoading,
  setLoadingBlocked,
  setLoading,
};
