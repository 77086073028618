/**
 * This slice is dedicated to Authentication API data
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import config from '../../../config';
import LibraryConfig from '../../../configLib';
import { PredefinedMilisecondsTypeEnum, RecoverPhaseEnum } from '../../enums';
import { IGNORE_GATEWAY_CONNECTION } from '../../libSettings';
import {
  AuthRefreshResultData,
  EmailConfirmationLinkParsedData,
  ExtraUserDetails,
  ManualTokenData,
  RecoveryDataPayload,
  SignupTokenData,
} from '../../models/auth/types';
import {
  ENROLL_REQUEST_STATE,
  EnrollAuthDataState,
  IndividualExtendedInfoData,
  TokenDecodedData,
} from '../../models/enroll';
import {
  ENROLL_AUTH_REQUEST_TYPE,
  EnrollAuthRequestBody,
  REQUEST_CALL_NAME_CONFIRM_EMAIL,
  REQUEST_CALL_NAME_GET_MANUAL_TOKEN,
} from '../../models/enroll-requests';
import { decodeJwt } from '../../util/DataHelpers';
import { appendToErrorsLog } from '../../util/error-handling/ErrorHandlingHelpers';
import { NullableNumber, NullableString, WebAuthUserData } from '../../util/types';
import {
  applicationStartup,
  authRefreshCompleted,
  autoLoginFailed,
  autoLoginSuccess,
  clearErrorsLog,
  enrollAuthCompleted,
  ErrorPayloadFull,
  gatewayConnected,
  gatewayDisconnected,
  getManualTokenSuccess,
  individualExtendedInfoFailed,
  individualExtendedInfoSuccess,
  login,
  loginFailed,
  logout,
  SuccessPayload,
  updateRefreshTokenLockTime,
} from '../common-actions';
import { parseOnboardingErrorCode } from '../crm/helpers';
import { processErrorInReducer } from '../helpers';
import {
  getEnrollRecoveryPhase,
  getRecoverPhase,
  hasAnsweredLastQuestionnaire,
  hasValidToken,
  isUserVerifiedStatus,
} from '../selectors';

export interface AuthState {
  status: 'idle' | 'refreshing' | 'connecting' | 'ready' | 'cancelled' | 'redirectsignup';
  callBackPending: boolean;
  skipRefresh: boolean;
  isChangeUserPassword: boolean;
  refreshTokenUnlockTime: NullableNumber;
  accessToken: string | null; // TODO: Sensitive data (ART-186)
  refreshToken: string | null; // TODO: Sensitive data (ART-186)
  enroll: EnrollAuthDataState; // TODO: Sensitive data (ART-186)
  enrollPending: boolean;
  enrollRecoveryChecked: boolean;
  recoveryPhase?: RecoverPhaseEnum | null;
  recoveryStep?: NullableNumber;
  recoverySubStep?: NullableNumber;
  error: string | null;
  errorData: any;
  errorsLog: string[];
  userData?: WebAuthUserData | null;
  isPasscodeActive: boolean;
  individualAvatar: string;
  isForgetPasscode: boolean;
  passcode: NullableString;
  isBiometricsActive: boolean;
  isNeedPasscodeLogin: boolean;
  createIndividualAttempts: number;
  isAppropriatenessPopupConfirmed: boolean;
  PWPSignupURL: NullableString;
  isFetchingPWPSignupURL: boolean;
}

export const INITIAL_STATE: AuthState = {
  status: 'idle',
  callBackPending: false,
  skipRefresh: false,
  isChangeUserPassword: false,
  accessToken: null,
  refreshToken: null,
  refreshTokenUnlockTime: null,
  enroll: {
    status: ENROLL_REQUEST_STATE.INITIAL,
    confirmedSteps: {},
  } as EnrollAuthDataState,
  enrollPending: false,
  enrollRecoveryChecked: false,
  error: null,
  errorData: null,
  errorsLog: [],
  userData: null,
  isPasscodeActive: false,
  individualAvatar: '',
  isForgetPasscode: false,
  passcode: null,
  isBiometricsActive: false,
  isNeedPasscodeLogin: true,
  createIndividualAttempts: 0,
  isAppropriatenessPopupConfirmed: false,
  PWPSignupURL: null,
  isFetchingPWPSignupURL: false,
};

// TODO: The web part needs to call the same method for expired link check as well.
const isLinkExpired = (state: EnrollAuthDataState): boolean => {
  const { requestCallName } = state;

  return (
    state.status === ENROLL_REQUEST_STATE.ERROR
    && parseOnboardingErrorCode(state.statusCode!) === ENROLL_REQUEST_STATE.REQUEST_ERROR
    && (requestCallName === REQUEST_CALL_NAME_CONFIRM_EMAIL || requestCallName === REQUEST_CALL_NAME_GET_MANUAL_TOKEN));
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    startAuthRefresh(state) {
      state.status = 'refreshing';
    },
    checkAndRefreshToken(state) {
      state.status = 'refreshing';
    },
    loginSucceeded(state, action) {
      const {
        userData,
        accessToken,
        refreshToken,
      } = action.payload;

      const oldEmail = (decodeJwt(state.accessToken) as TokenDecodedData)?.email;
      const { email } = decodeJwt(accessToken) as TokenDecodedData;

      if (!LibraryConfig.isWeb && oldEmail && email !== oldEmail) {
        state.isForgetPasscode = false;
        state.isNeedPasscodeLogin = false;
        state.isBiometricsActive = false;
        state.isPasscodeActive = false;
        state.passcode = '';
      }

      if (state.isPasscodeActive && state.isForgetPasscode) {
        state.isForgetPasscode = false;
      }

      state.userData = userData;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.status = 'ready';
      if (state.enrollPending) {
        state.enroll.status = ENROLL_REQUEST_STATE.READY;
      }
    },
    setRecoveryPhase(state, action: PayloadAction<RecoverPhaseEnum>) {
      state.recoveryPhase = action.payload;
    },
    setRecoveryData(state, action: PayloadAction<RecoveryDataPayload>) {
      const {
        callType,
        // extended info
        extendedInfo,
        // login
        tokenDecodedData,
        // email-sent
        tokenToConfirm,
        emailToConfirm,
        firstName,
        lastName,
        country,
        financialQuestionnaireSections,
      } = action.payload || {};

      if (callType === 'recovered') {
        state.enrollRecoveryChecked = true;
        return;
      }

      const isAppropriatenessPopupConfirmed = state.isAppropriatenessPopupConfirmed;

      const recoverPhase = getRecoverPhase(
        extendedInfo!,
        financialQuestionnaireSections,
        !!isAppropriatenessPopupConfirmed,
      );

      switch (callType) {
        case 'login':
          state.enroll.email = tokenDecodedData?.email;
          state.enroll.confirmedSteps.email = tokenDecodedData?.email_verified === 'true';
          break;

        case 'email-sent':
          state.enroll.emailToConfirm = emailToConfirm!;
          state.enroll.tokenToConfirm = tokenToConfirm!;
          state.enroll.firstName = firstName;
          state.enroll.lastName = lastName;
          state.enroll.country = country;
          state.recoveryPhase = recoverPhase;
          break;

        case 'extended-info': {
          const { accessToken } = state;

          updateStateFromToken(state, { tokenDecoded: decodeJwt(accessToken) as TokenDecodedData });

          if (recoverPhase === RecoverPhaseEnum.Enroll) {
            const { phase, step } = getEnrollRecoveryPhase(extendedInfo!);
            state.recoveryStep = phase;
            state.recoverySubStep = step;
          }
          state.recoveryPhase = recoverPhase;
          break;
        }

        default:
          break;
      }
    },
    getUserPermissions(state, action) { },
    getUserPermissionsSucceeded(state, action) { },
    getUserPermissionsFailed(state, action) { },
    getPermissions(state, action) { },
    getPermissionsSucceeded(state, action) { },
    getPermissionsFailed(state, action) { },
    enrollAuth(state, action) {
      const { requestType } = action.payload as EnrollAuthRequestBody;
      state.enroll.lastType = requestType;

      switch (requestType) {
        // TODO: Remove this enum value and related code with ART-1023
        case ENROLL_AUTH_REQUEST_TYPE.RECOVER: {
          updateStateFromToken(state, action.payload);
          state.enrollPending = true;
          state.enroll.status = ENROLL_REQUEST_STATE.READY;
          return;
        }

        case ENROLL_AUTH_REQUEST_TYPE.REGISTER:
        case ENROLL_AUTH_REQUEST_TYPE.RESEND_EMAIL: {
          const {
            first_name,
            last_name,
            country_of_residence,
          } = (action.payload?.extraDetails || {}) as ExtraUserDetails;
          if (requestType === ENROLL_AUTH_REQUEST_TYPE.REGISTER && first_name && last_name && country_of_residence) {
            state.enroll.firstName = first_name;
            state.enroll.lastName = last_name;
            state.enroll.country = country_of_residence;
          }
          state.enrollRecoveryChecked = true;
          state.enroll.email = action.payload.email;
          if (country_of_residence) {
            state.enroll.tokenToConfirm = undefined;
            state.enroll.emailToConfirm = undefined;
            state.enroll.confirmedSteps = {};
          }

          // TODO: Fix for ART-1329 - if an old (expired) token is left - delete it
          if (state.accessToken) {
            state.accessToken = null;
            state.refreshToken = null;
          }
          break;
        }

        case ENROLL_AUTH_REQUEST_TYPE.CONFIRM_EMAIL: {
          const { firstName, lastName, country } = action.payload;
          state.enrollPending = true;
          state.enroll.confirmedSteps.email = false;
          if (firstName) { // fixes ART-1314 - the data is deleted if not present in payload
            state.enroll.firstName = firstName;
            state.enroll.lastName = lastName;
            state.enroll.country = country;
          }
          break;
        }
        case ENROLL_AUTH_REQUEST_TYPE.SEND_PHONE_CODE: {
          const { phoneCode, phoneNumber } = action.payload;
          state.enroll.phoneCode = phoneCode;
          state.enroll.phoneNumber = phoneNumber;
          break;
        }

        default:
          break;
      }
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
    },
    enrollAuthFailed(state, action) {
      state.enroll.statusCode = action.payload.status;
      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.requestCallName = action.payload.callName;
      state.enroll.isAuthLinkExpired = isLinkExpired(state.enroll);
      appendToErrorsLog(action.payload.errorStr, state);
    },
    clearEnrollStatus(state) {
      state.enroll.statusCode = undefined;
      state.enroll.confirmedSteps.didSendPhone = undefined;
      state.enroll.status = ENROLL_REQUEST_STATE.READY;
    },
    resetPasscode(state) {
      state.passcode = null;
    },
    setIsPasscodeActive(state, action) {
      state.isPasscodeActive = action.payload;
    },
    setIndividualAvatar(state, action) {
      state.individualAvatar = action.payload;
    },
    setIsForgetPasscode(state, action) {
      state.isForgetPasscode = action.payload;
    },
    setPasscode(state, action) {
      state.passcode = action.payload;
    },
    setIsBiometricsActive(state, action) {
      state.isBiometricsActive = action.payload;
    },
    setIsNeedPasscodeLogin(state, action) {
      state.isNeedPasscodeLogin = action.payload;
    },
    getManualToken(state, action: PayloadAction<EmailConfirmationLinkParsedData>) {
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
    },
    getManualTokenFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      state.enroll.statusCode = action.payload.status;
      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.requestCallName = action.payload.callName;
      state.enroll.isAuthLinkExpired = isLinkExpired(state.enroll);
    },
    clearSignupToken(state, action: PayloadAction) {
      state.isFetchingPWPSignupURL = false;
      state.PWPSignupURL = null;
    },
    getSignupToken(state, action: PayloadAction) {
      state.isFetchingPWPSignupURL = true;
    },
    getSignupTokenFailed(state, action: PayloadAction<SignupTokenData>) {
      state.isFetchingPWPSignupURL = false;
      state.PWPSignupURL = null;
    },
    getSignupTokenSuccess(state, action: PayloadAction<SignupTokenData>) {
      const { payload } = action;
      state.isFetchingPWPSignupURL = false;
      state.PWPSignupURL = `${config.pwp.url}authentication/signup-token?email=${encodeURIComponent(payload.email)}&token=${encodeURIComponent(payload.token)}`;
    },
    sendResetPassword(state, action) {},
    setIsChangeUserPassword(state, action) {
      state.isChangeUserPassword = action.payload;
    },
    sendNonAppropriatenessEmail(state: AuthState) {
      state.enroll.status = ENROLL_REQUEST_STATE.INITIAL;
    },
    sendNonAppropriatenessEmailSuccess(state: AuthState) {
      state.enroll.status = ENROLL_REQUEST_STATE.READY;
    },
    sendNonAppropriatenessEmailFail(state: AuthState, action: PayloadAction<ErrorPayloadFull>) {
      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.statusCode = action.payload.status;
    },
    confirmAppropriatenessPopup(state: AuthState) {
      state.isAppropriatenessPopupConfirmed = true;
    },
  },
  extraReducers: {
    [applicationStartup.type]: (state, action) => {
      const isTokenValid = hasValidToken(null, state.accessToken!);
      state.status = isTokenValid ? 'refreshing' : 'idle';
      state.enroll.status = ENROLL_REQUEST_STATE.INITIAL; // needed to reset enroll status and use persisted enroll data
      state.enroll.emailToConfirm = undefined;
      state.enroll.tokenToConfirm = undefined;
      state.recoveryPhase = null;
      state.recoveryStep = null;
      state.recoverySubStep = null;
      if (!isTokenValid) {
        state.accessToken = null;
        state.refreshToken = null;
      }
      if (action.payload) state.enrollPending = false;
    },
    [login.type]: (state, action) => {
      if (LibraryConfig.isWeb) {
        switch (action.payload) {
          case 'login':
            state.callBackPending = true;
            break;

          case 'callback':
            state.callBackPending = false;
            break;

          default:
            console.warn(`[auth/index] login - unknown case '${action.payload}'`);
            break;
        }
      }

      if (action.payload?.isEnroll) {
        state.enrollPending = true;
        state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
      } else {
        state.enrollPending = false;
      }
      state.status = 'connecting';
    },
    [loginFailed.type]: (state, action) => {
      processErrorInReducer(state, action);
      state.accessToken = null;
      state.refreshToken = null;
      state.status = 'idle';
      if (state.enrollPending) {
        state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      }
      const errorMessage = action.payload?.errorStr || action.payload?.error || 'n/a';
      const errorData = action.payload?.errorData;
      const CANCELLED_ERROR_MSG_ANDROID = 'error: user cancelled flow';
      const CANCELLED_ERROR_MSG_IOS = 'org.openid.appauth.general error -3';
      const SIGNUP_ERROR_MSG_IOS = 'error: state mismatch, expecting';
      const SIGNUP_ERROR_MSG_ANDROID = 'error: data intent is null';

      if (errorData) {
        const errorStr = errorData.toString().toLowerCase();
        if (errorStr.indexOf(CANCELLED_ERROR_MSG_IOS) >= 0 || errorStr.indexOf(CANCELLED_ERROR_MSG_ANDROID) >= 0) {
          state.status = 'cancelled';
        } else if (errorStr.indexOf(SIGNUP_ERROR_MSG_IOS) >= 0 || errorStr.indexOf(SIGNUP_ERROR_MSG_ANDROID) >= 0) {
          state.status = 'redirectsignup';
        }
      }

      appendToErrorsLog(errorMessage, state);
    },
    [clearErrorsLog.type]: state => { state.errorsLog = []; },
    [individualExtendedInfoSuccess.type]: (state, action) => {
      state.enroll.userId = (action.payload as IndividualExtendedInfoData).user_correlation_id;
      if (isUserVerifiedStatus(action.payload)) {
        state.enrollPending = false;
      }
      if (state.isAppropriatenessPopupConfirmed && !hasAnsweredLastQuestionnaire(action.payload)) {
        state.isAppropriatenessPopupConfirmed = false;
      }
    },
    [authRefreshCompleted.type]: (state, action: PayloadAction<AuthRefreshResultData>) => {
      const { userData, accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken!;
      state.refreshToken = refreshToken!;
      state.userData = userData;
      state.status = hasValidToken(null, accessToken!) ? 'ready' : 'idle';
    },
    [updateRefreshTokenLockTime.type]: state => {
      state.refreshTokenUnlockTime = moment().add(PredefinedMilisecondsTypeEnum.fifteenMinutes, 'milliseconds').unix();
    },
    [gatewayConnected.type]: state => {
      state.status = 'ready';
    },
    [gatewayDisconnected.type]: state => {
      if (!IGNORE_GATEWAY_CONNECTION) state.status = 'idle';
    },
    [logout.type]: state => ({ ...INITIAL_STATE, errorsLog: state.errorsLog }),
    [getManualTokenSuccess.type]: (state, action: PayloadAction<SuccessPayload & ManualTokenData>) => {
      state.enroll.accessToken = action.payload.access_token;
      state.enroll.refreshToken = action.payload.refresh_token;
      const decodedToken = decodeJwt(state.enroll.accessToken) as TokenDecodedData;
      state.enroll.userId = Number(decodedToken.sub);
      state.enroll.isManualToken = true;
      state.enroll.status = ENROLL_REQUEST_STATE.READY;
      state.userData = {
        ...(state.userData ?? {}),
        expires_at: decodedToken.exp,
      };
    },
    [autoLoginSuccess.type]: state => {
      state.accessToken = state.enroll.accessToken!;
      state.refreshToken = state.enroll.refreshToken!;
      delete state.enroll.accessToken;
      delete state.enroll.refreshToken;
    },
    [autoLoginFailed.type]: state => {
      state.status = 'idle';
      state.enroll.accessToken = undefined;
      state.enroll.refreshToken = undefined;
      state.enroll.isManualToken = undefined;
    },
    [enrollAuthCompleted.type]: (state, action) => {
      const { callName } = action.payload;
      const requestType = ENROLL_AUTH_REQUEST_TYPE[callName.split('/')[1]] as any;

      switch (requestType) {
        case ENROLL_AUTH_REQUEST_TYPE.REGISTER:
          state.enroll.userId = action.payload.id;
          break;

        case ENROLL_AUTH_REQUEST_TYPE.CONFIRM_EMAIL:
          state.enroll.confirmedSteps.email = true;
          break;

        case ENROLL_AUTH_REQUEST_TYPE.VERIFY_PHONE_CODE:
          state.enroll.confirmedSteps.phone = true;
          break;

        case ENROLL_AUTH_REQUEST_TYPE.SEND_PHONE_CODE:
          state.enroll.confirmedSteps.didSendPhone = true;
          break;

        case ENROLL_AUTH_REQUEST_TYPE.SET_CLIENTS:
          state.enroll.clients = action.payload;
          break;

        default:
          break;
      }

      state.enroll.status = ENROLL_REQUEST_STATE.READY;
    },
    [individualExtendedInfoFailed.type]: (state, action) => {
      state.createIndividualAttempts++;
    },
  },
});

function updateStateFromToken(
  state: AuthState,
  actionPayload: {
    tokenDecoded?: TokenDecodedData,
    emailToConfirm?: string,
    tokenToConfirm?: string,
  },
) {
  const {
    email,
    sub,
    email_verified,
    phone_number_verified,
  } = actionPayload.tokenDecoded || {};
  if (actionPayload.tokenDecoded) {
    state.enroll.email = email;
    state.enroll.userId = parseInt(sub!);
    state.enroll.confirmedSteps.email = (email_verified === 'true');
    state.enroll.confirmedSteps.phone = (phone_number_verified === 'true');
  }
  const {
    emailToConfirm,
    tokenToConfirm,
  } = actionPayload || {};
  if (emailToConfirm) {
    state.enroll.emailToConfirm = emailToConfirm;
    state.enroll.tokenToConfirm = tokenToConfirm;
  }
}

export const {
  loginSucceeded,
  startAuthRefresh,
  checkAndRefreshToken,
  // enroll
  setRecoveryData,
  enrollAuth,
  enrollAuthFailed,
  clearEnrollStatus,
  resetPasscode,
  setIsPasscodeActive,
  setIndividualAvatar,
  setIsForgetPasscode,
  setPasscode,
  setIsBiometricsActive,
  setIsNeedPasscodeLogin,
  getManualToken,
  getManualTokenFailed,
  clearSignupToken,
  getSignupToken,
  getSignupTokenSuccess,
  getSignupTokenFailed,
  sendResetPassword,
  setIsChangeUserPassword,
  setRecoveryPhase,
  sendNonAppropriatenessEmail,
  sendNonAppropriatenessEmailSuccess,
  sendNonAppropriatenessEmailFail,
  confirmAppropriatenessPopup,
} = authSlice.actions;

export default authSlice.reducer;
