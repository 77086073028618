import { GWMarketDataSummaryBucket } from '../../../models/gateway/types';
import { OHLCV } from '../../../models/market-data/types';
import { Order } from '../../../models/trading/types';
import { NullableNumber } from '../../../util/types';

export type ChartPoint = {
  x: number
  y: number
  y0?: number
  label?: string
};
export type ChartPointWithData = ChartPoint & {
  close: number
  volume: number
  dateAndTime: string
  xAxis?: any
};
export type BaseChartLineData = {
  close: number
  volume: number,
  x: number,
  y: number,
  dateAndTime: string,
};
export type ChartLineData = BaseChartLineData & {
  time: string,
};
export type ChartDataSet = {
  isValid: boolean
  /** Difference between max and min close value - including lineClose */
  lineDiff: number
  /** Min close value - including lineClose */
  minLineValue: number,
  /** Max close value - including lineClose */
  maxLineValue?: number,
  /** Min close value - excluding lineClose */
  minCloseValue?: number,
  minCloseY?: number,
  /** Max close value - excluding lineClose */
  maxCloseValue?: number,
  maxCloseY?: number,
  /** Min line data y value - including lineClose */
  minLineY?: number,
  /** Max line data y value - including lineClose */
  maxLineY?: number,
  lineData: ChartPointWithData[]
  barData: ChartPoint[]
  rawData: OHLCV[]
  axisValue: string[],
  /** Y axis labels */
  yLabelPoints: number[],
  step: number,
  lineDataRatio: number
  minChartPoint?: number
  lastClose: number,
  fillColor?: string,
  aMonth?: number,
  zeroLineHeight?: number,
  isPeriodShorterThanRange?: boolean,
  yPointAverage?: number,
  scatterMinMaxData?: ChartPointWithData[],
  scatterLabels?: Record<number, string[]> // TODO: Implement if it would optimize rendering
  tickValuesWeb: number[],
  maxChartPointWeb: number,
}
export type ChartDataPortion = Partial<Record<ChartDataRange, ChartDataSet | null>>

export type ChartCombinedPointData = (OHLCV | ChartPointWithData) & {
  orders?: Order[]
}

export type LoadWatchlistChartDataPayload = {
  /**
   * String of - (1) single symbol or (2) a comma separated list of symbols - example 1: 'AAPL', example 2: 'AAPL,TSLA,ITUB'
   */
  symbolOrSymbols: string
  bucket?: GWMarketDataSummaryBucket
}
export type LoadChartDataPayload = {
  symbol: string
  bucket?: GWMarketDataSummaryBucket
  /**
   * Used only for symbol/stock details chart
   */
  hasRangeChanged?: boolean
  caller?: string
}


/**
 * Data of the GWResponseType.MarketDataSummaryResponse.
 * It always comes with only one `symbol` and use this type as of 2022-04-28 - even when called with multiple symbols.
 */
export type ChartDataPayload = {
  symbol: string
  bucket: GWMarketDataSummaryBucket
  data: OHLCV[]
}

export type SymbolPriceData = {
  /** Last price ever received */
  lastPrice: NullableNumber
  /** Last regular hours close - of last working day that has closed regular hours (after 16:00 NY time) */
  close: NullableNumber
  /** Last after hours close - of last working day that has closed after hours (after 20:00 NY time) */
  afterClose: NullableNumber
  /** Previous regular hours close - of last working day before the day with `close` */
  previousClose: NullableNumber
  /** Previous after hours close - of last working day before the day with `afterClose` */
  previousAfterClose: NullableNumber
  /** True if it is a working day after market has opened (9:30 am NY time) and there is chart data for 1D5m range */
  hasDataForToday?: boolean | null
  /** `null` between regular close and after close (16:00-20:00 NY time). `true` if symbol has `afterClose` value set */
  hasAfterMarketData?: boolean | null
  /** Hold last trade time if trade price has been received (not to overwrite it with Instrument Snapshot or other update). `null` otherwise */
  lastTradeUpdateTime?: string | null
  /** Initially used only in Big Chart - to show reference previous close line */
  lineClose?: NullableNumber
}

export type SmallChartData = {
  ohlcvData: OHLCV[],
  closeValues: number[],
  isReady: boolean,
}

export type MyAccountChartRange = '1M' | '3M' | '1Y' | 'YTD' | 'ALL';
export type ChartDataRange = '1D' | '1W' | '1M' | '3M' | '1Y' | '5Y' | 'YTD' | 'ALL';
export const CHART_DATA_SYMBOL_RANGE: ChartDataRange[] = [ '1D', '1W', '1M', '3M', '1Y', '5Y' ];
export const CHART_DATA_NET_INCOME_COMPONENTS_RANGE: ChartDataRange[] = [ '1M', '3M', '1Y', 'YTD', 'ALL' ];
