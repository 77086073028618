import React from 'react';

type Props = {
    color: string,
    id: string,
  }

const GradientFill = (props: Props) => {
  const { id, color } = props;

  return (
    <defs>
      <linearGradient id={id} x1="100%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity="0.15" />
        <stop offset="80%" stopColor={color} stopOpacity="0" />
      </linearGradient>
    </defs>
  );
};

export default GradientFill;
