import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus, OrderSideEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { RootState } from '../../lib/store';
import { getCompanyLogoAndName as getLogoAndName, loadStock as serviceLoadStock } from '../../lib/store/fundamental';
import { subscribeSymbolStatus, unsubscribeSymbolStatus } from '../../lib/store/gateway/index';
import { PaymentCall } from '../../lib/store/payment/types';
import { getAccountTradeHistory, getOpenPositions } from '../../lib/store/reporting';
import {
  PENDING_ORDERS_REQUEST_TENNSTATUS,
  TRADE_HISTORY_ORDERS_TENNSTATUSES,
} from '../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, OpenPosition, PositionType } from '../../lib/store/reporting/types';
import {
  checkIfUserIsETFRestricted,
  getCurrentAccount,
  isSymbolETF as isETF,
  isUserVerifiedStatus,
} from '../../lib/store/selectors';
import { SESCall } from '../../lib/store/ses/types';
import { getBuyingPower } from '../../lib/store/trading/index';
import MarketStateCache from '../../lib/store-util/MarketStateCache';
import SymbolStatusCache from '../../lib/store-util/SymbolStatusCache';
import { isCallStatusReady } from '../../lib/util/error-handling/StatusByCallHelpers';
import { checkIfSymbolIsHalted } from '../../lib/util/HaltSymbolHelper';
import { isMarketRegularHours } from '../../lib/util/MarketDataHelpers';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import { LatestNews,
  NotVerifiedNotification,
  OrderNavigation,
  Preloader,
  RegularTradingHoursStartModal,
  ShortSellOrderPlace,
  SymbolDetailsHeader,
  SymbolDetailsNotActiveBox } from '../../components';
import ETFRestrcitions from '../../components/OrderActions/components/UserRestrictions/ETFRestrictions';
import CreateOrderModal from '../../components/OrderActions/CreateOrder/CreateOrderModal';
import HaltedSymbol from '../../components/OrderActions/HaltedSymbol/HaltedSymbol';
import OrderResponseManager from '../../components/OrderResponseManager/OrderResponseManager';
import CompanyDetails from '../../components/SymbolProfile/CompanyDetails/CompanyDetails';
import KeyStatistics from '../../components/SymbolProfile/KeyStatistics/KeyStatistics';
import PendingOrders from '../../components/SymbolProfile/PendingOrders/PendingOrders';
import PeopleAlsoTrade from '../../components/SymbolProfile/PeopleAlsoTrade/PeopleAlsoTrade';
import SharesOwned from '../../components/SymbolProfile/SharesOwned/SharesOwned';
import TradingRestrictionsModal from '../../components/TradingRestrictionsModal/TradingRestrictionsModal';
import { useIsMobile } from '../../hooks';
import { BASE_WIDTH_SYMBOL_DETAILS } from '../../util/constants';

import ScrollWrapper from './ScrollWrapper';

import styles from './SymbolDetails.module.scss';

type Props = ReduxProps & RouteComponentProps;

const SymbolDetails = (props: Props) => {
  const {
    accountReferenceId,
    openPositions,
    pendingOrders,
    tradeHistoryOrders,
    hasDeposit,
    companyLogoAndName,
    individualData,
    isPaymentsReady,
    canTradeETFsCompleted,
    loadStock,
    getOrders,
    getPositions,
    isSymbolETF,
    isUserETFRestricted,
    getCompanyLogoAndName,
    fetchBuyingPowerAndPutGlobalState,
    subscribeForSymbolStatus,
    unsubscribeForSymbolStatus,
  } = props;
  const isMobile = useIsMobile();
  const { name: symbol }: { name: string } = useParams();
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [visibleHaltedSymbolDialog, setVisibleHaltedSymbolDialog] = useState<boolean>(false);
  const [visibleWarningShortSellDialog, setVisibleWarningShortSellDialog] = useState<boolean>(false);
  const [visibleRegularTradingHoursStartDialog, setVisibleRegularTradingHoursStartDialog] = useState<boolean>(false);
  const [showTradingRestrictionModal, setShowTradingRestrictionModal] = useState<boolean>(false);
  const [isUserRestricted, setIsUserRestricted] = useState<boolean>(false);
  const [closeTradingRestrictionsModal, setCloseTradingRestrictionsModal] = useState<boolean>(false);

  const [openOrderModal, setOpenOrderModal] = useState<{target: string; isModalOpened: boolean}>(
    { target: '', isModalOpened: false },
  );

  const positionsBySymbol = openPositions.find((position: OpenPosition) => (position.symbol === symbol));
  const isUserVerified = isUserVerifiedStatus(individualData);
  const symbolsStatusCache = SymbolStatusCache.use(symbol);
  const history = useHistory();

  useEffect(() => {
    if (accountReferenceId) {
      fetchBuyingPowerAndPutGlobalState();
    }
  }, [accountReferenceId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    MarketStateCache.refresh();
    subscribeForSymbolStatus([symbol]);
    manageSubscriptions('symbol-details', symbol);

    return () => {
      unsubscribeForSymbolStatus();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  useEffect(() => {
    loadStock(symbol);
  }, [symbol, loadStock]);

  useEffect(() => {
    if (!companyLogoAndName[symbol]) {
      getCompanyLogoAndName(symbol);
    }
  }, [companyLogoAndName, getCompanyLogoAndName, symbol]);

  useEffect(() => {
    if (!isInitialised) {
      setIsInitialised(true);
    }
  }, [isInitialised]);

  useEffect(() => {
    if (!openPositions.find(position => position.symbol === symbol)) {
      getPositions(symbol);
    }
    if (!pendingOrders.find(order => order.symbol === symbol)) {
      getOrders({ symbol, tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
    }
    if (!tradeHistoryOrders.find(order => order.symbol === symbol)) {
      getOrders({ symbol, tenNStatus: TRADE_HISTORY_ORDERS_TENNSTATUSES });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  const closeRegularTradingHoursStartDialog = () => {
    setVisibleRegularTradingHoursStartDialog(false);
  };

  const reviewOrder = (target: string) => {
    isMobile && history.replace({
      pathname: `/${target.includes(OrderSideEnum.Buy) ? OrderSideEnum.Buy : OrderSideEnum.Sell}/${symbol}`,
      state: { stateSymbol: symbol, stateTarget: target, origin: history.location.pathname },
    });

    setOpenOrderModal({ target, isModalOpened: true });
  };

  const handleCreateOrderSellOrBuy = (target: string, isModalOpened: boolean) => {
    if (target === OrderSideEnum.Sell && !isMarketRegularHours() && !positionsBySymbol) {
      setVisibleRegularTradingHoursStartDialog(true);
      return;
    }

    if (checkIfSymbolIsHalted(symbolsStatusCache)) {
      setVisibleHaltedSymbolDialog(true);
      return;
    }

    if (isMobile && !positionsBySymbol && isMarketRegularHours() && target === OrderSideEnum.Sell) {
      setVisibleWarningShortSellDialog(true);
      return;
    }

    reviewOrder(target);
  };

  const proceedShortSellOrderPlaceModal = () => {
    setVisibleWarningShortSellDialog(false);
    reviewOrder(OrderSideEnum.SellShort);
  };

  useEffect(() => {
    if (canTradeETFsCompleted) {
      if (isUserETFRestricted(symbol)) {
        if (!closeTradingRestrictionsModal) {
          setShowTradingRestrictionModal(true);
        }
        setIsUserRestricted(true);
      }
    }
  }, [canTradeETFsCompleted, closeTradingRestrictionsModal, isUserETFRestricted, symbol]);

  const handleTradingRestrictionsModalClose = (): void => {
    setShowTradingRestrictionModal(false);
    setCloseTradingRestrictionsModal(true);
  };

  const renderSellAndBuyButtons = () => (
    isUserRestricted ? (
      <ETFRestrcitions />
    ) : (
      <div className={styles.buttonsWrapper}>
        <button
          className={styles.sellBtn}
          type="button"
          onClick={() => {
            handleCreateOrderSellOrBuy(OrderSideEnum.Sell, true);
          }}
        >
          {lang.commonOrderSideButtonSell()}
        </button>
        <button
          className={styles.buyBtn}
          type="button"
          onClick={() => { handleCreateOrderSellOrBuy(OrderSideEnum.Buy, true); }}
        >
          {lang.commonOrderSideButtonBuy()}
        </button>
      </div>
    ));

  return (
    <>
      {showTradingRestrictionModal && isUserVerified && isPaymentsReady && hasDeposit && !isMobile && (
        <TradingRestrictionsModal closeModal={handleTradingRestrictionsModalClose} />
      )}
      <div className={isMobile ? styles.wrapperMobile : styles.wrapper}>
        <div className={isMobile ? styles.symbolInfoWrapperMobile : styles.symbolInfoWrapper}>
          <OrderResponseManager />
          {visibleHaltedSymbolDialog && !positionsBySymbol && (
            <HaltedSymbol
              symbolName={companyLogoAndName[symbol].name}
              toggleModal={() => { setVisibleHaltedSymbolDialog(false); }}
            />
          )}
          {visibleWarningShortSellDialog && !positionsBySymbol && (
            <ShortSellOrderPlace
              processModal={proceedShortSellOrderPlaceModal}
              toggleModal={() => { setVisibleWarningShortSellDialog(false); }}
            />
          )}
          {visibleRegularTradingHoursStartDialog && !positionsBySymbol && (
            <RegularTradingHoursStartModal
              toggleModal={closeRegularTradingHoursStartDialog}
            />
          )}
          {
            (window.innerWidth <= BASE_WIDTH_SYMBOL_DETAILS
              && individualData) && !isMobile
            && (
            <NotVerifiedNotification
              individual={individualData}
              hasUserName={false}
              isPaymentsReady={isPaymentsReady}
            />
            )
          }
          <SymbolDetailsHeader
            symbol={symbol}
            handleCreateOrderSellOrBuy={handleCreateOrderSellOrBuy}
            isUserRestricted={isUserRestricted}
          />
          {isMobile && hasDeposit && isUserVerified && isPaymentsReady && renderSellAndBuyButtons()}
          <SharesOwned symbol={symbol} />
          <PendingOrders symbol={symbol} />
          <KeyStatistics symbol={symbol} />
          {!isSymbolETF(symbol) && <CompanyDetails symbol={symbol} />}
          {isUserVerified && <PeopleAlsoTrade symbol={symbol} />}
          {(isUserVerified && hasDeposit) && <LatestNews selectedSymbol={symbol} />}
          {(!hasDeposit) && window.innerWidth <= BASE_WIDTH_SYMBOL_DETAILS
          && <LatestNews selectedSymbol={symbol} />}
        </div>

        {isMobile ? (
          <>
          </>
        ) : (
          <>
            <ScrollWrapper>
              {
                (isUserVerified && !isPaymentsReady) && <div className={styles.loader}><Preloader text="" /></div>
              }
              {
                (isUserVerified && isPaymentsReady && hasDeposit) && window.innerWidth > BASE_WIDTH_SYMBOL_DETAILS
                && (
                <div className={styles.wrapperNavigation}>
                  <OrderNavigation symbol={symbol} isUserRestricted={isUserRestricted} />
                </div>
                )
              }
              {
                ((!isUserVerified || isPaymentsReady) && (!hasDeposit && individualData))
                && window.innerWidth > BASE_WIDTH_SYMBOL_DETAILS
                && (
                  <>
                    <SymbolDetailsNotActiveBox className={['d-none', 'd-lg-block', styles.notVerified].join(' ')} />
                    <LatestNews selectedSymbol={symbol} />
                  </>
                )
              }
            </ScrollWrapper>
            { hasDeposit && openOrderModal.isModalOpened && (
              <CreateOrderModal symbol={symbol} openOrderModal={openOrderModal} />
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountReferenceId: getCurrentAccount(state),
  companyLogoAndName: state.fundamental.companyLogoAndName,
  hasDeposit: state.payment.hasDeposit,
  openPositions: state.reporting.openPositions,
  pendingOrders: state.reporting.pendingOrders,
  tradeHistoryOrders: state.reporting.tradeHistoryOrders,
  individualData: state.crm.individualExtendedInfo,
  isSymbolETF: (symbol: string) => isETF(symbol, state),
  isUserETFRestricted: (symbol: string) => checkIfUserIsETFRestricted(symbol, state),
  canTradeETFsCompleted: isCallStatusReady(SESCall.getSubsriptionsEntitlements, state.ses.statusByCall),
  isPaymentsReady: state.payment.statusByCall[PaymentCall.getPaymentsAll]?.status === CallStatus.READY,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadStock: (symbol: string) => dispatch(serviceLoadStock({ symbol })),
  getCompanyLogoAndName: (symbol: string) => dispatch(getLogoAndName(symbol)),
  getPositions: (symbol: string) => dispatch(getOpenPositions({ symbol, group: 'symbol-details' })),
  getOrders: (data: AccountTradeHistoryPayloadData) => {
    dispatch(getAccountTradeHistory({ ...data, isSymbolDetails: true }));
  },
  fetchBuyingPowerAndPutGlobalState: () => dispatch(getBuyingPower()),
  subscribeForSymbolStatus: (symbol: string[]) => dispatch(subscribeSymbolStatus(symbol)),
  unsubscribeForSymbolStatus: () => dispatch(unsubscribeSymbolStatus()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SymbolDetails);
