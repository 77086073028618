import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { Action } from 'redux';

import { OrderSideEnum } from '../../../../lib/enums/order-side.enum';
import { OrderStatusEnum } from '../../../../lib/enums/order-status.enum';
import { OrderTypeEnum } from '../../../../lib/enums/order-type.enum';
import lang from '../../../../lib/language';
import { SOROrderTimeInForce } from '../../../../lib/models/gateway/types';
import { Order } from '../../../../lib/models/trading/types';
import { getSummary } from '../../../../lib/store/ams/index';
import { getAccountsAvailableCash } from '../../../../lib/store/common-actions';
import { RootState } from '../../../../lib/store/index';
import {
  getAccountSummary,
  getAccountTradeHistory,
  getOpenPositions as getPositions,
  setHasOrderUpdate,
} from '../../../../lib/store/reporting';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData } from '../../../../lib/store/reporting/types';
import { isAvailableCashChanged } from '../../../../lib/store/trading/helpers';
import { modifyOrder as serviceModifyOrder, resetRequestStatus } from '../../../../lib/store/trading/index';
import MarketStateCache from '../../../../lib/store-util/MarketStateCache';
import SubscribeGroupCache from '../../../../lib/store-util/SubscribeGroupCache';
import SymbolStatusCache from '../../../../lib/store-util/SymbolStatusCache';
import { isOneOf } from '../../../../lib/util/DataHelpers';
import { checkIfSymbolIsHalted } from '../../../../lib/util/HaltSymbolHelper';
import { isMarketClosed } from '../../../../lib/util/MarketDataHelpers';
import { convertOrderSideToText, mapOrderSORtoSide, orderStatusToCallStatus } from '../../../../lib/util/TradingHelpers';

import cancelIcon from '../../../../assets/img/icon-cancel.svg';
import editIcon from '../../../../assets/img/icon-edit.svg';
import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import BaseModal from '../../../BaseModal/BaseModal';
import Button from '../../../Button/Button';
import { ButtonTypesEnum } from '../../../Button/Button.types';
import CancelOrder from '../../../OrderActions/CancelOrder/CancelOrder';
import HaltedSymbol from '../../../OrderActions/HaltedSymbol/HaltedSymbol';
import ModifyOrder from '../../../OrderActions/ModifyOrder/ModifyOrder';
import {
  modifyOrderPopUpBody,
  modifyOrderPopUpTitle,
  proccessPartiallyRejectedMessage,
} from '../../../OrderActions/OrderHelpers';
import useCancelOrderTimer from '../../../OrderActions/useCancelOrderTimer';
import useNewOrderTimer from '../../../OrderActions/useNewOrderTimer';
import ProcessFinishedModal from '../../../ProcessFinishedModal/ProcessFinishedModal';

import styles from './ButtonsCancelAndModify.module.scss';

type Props = {
  isSwipeable?: boolean
  row: any
} & ReduxProps

const ButtonsCancelAndModify = (props: Props) => {
  const {
    row,
    isSwipeable,
    requestStatus,
    submittedOrder,
    getOrders,
    getOpenPositions,
    modifyOrder,
    myStocksUpdate,
    resetStatus,
    getAccSummary,
    getMessageSummary,
    getAccountAvailableCash,
  } = props;

  const isMobile = useIsMobile();
  const history = useHistory();

  const [order, setOrder] = useState<Order>({});
  const [orderType, setOrderType] = useState<string>('');
  const [isHalted, setIsHalted] = useState<boolean>(false);
  const [orderSide, setOrderSide] = useState<OrderSideEnum>(OrderSideEnum.Buy);
  const [showModifyOrder, setShowModifyOrder] = useState<boolean>(false);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [showResultModifyModal, setShowResultModifyModal] = useState<boolean>(false);
  const [showResultModalCancel, setShowResultModalCancel] = useState<boolean>(false);

  const { marketState } = MarketStateCache.use();
  const marketClosed = isMarketClosed(marketState);
  const { id, name, side, plainType, totalQuantity, partialFillQuantity, initialPrice } = row;
  const symbolsStatusCache = SymbolStatusCache.use(name);
  const { status, ordType, side: ordSide, orderQty, filledSoFar } = submittedOrder;
  const finalRejectedOrder = requestStatus === OrderStatusEnum.FinalReject;
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;
  const showResultModal = [OrderStatusEnum.Accepted, OrderStatusEnum.Rejected].includes(requestStatus);
  const orderSideAsText = convertOrderSideToText(orderSide);

  const {
    orderTimeoutPassed, showFillOrder, hasNoResponse,
  } = useNewOrderTimer(showResultModifyModal && showResultModal, OrderTypeEnum.Limit, status, requestStatus);
  const {
    cancelOrderTimeoutSuccess, hasNoCancelResponse,
  } = useCancelOrderTimer(showResultModalCancel && showResultModal, status, requestStatus);
  const showModifyOrderModal = (orderTimeoutPassed || (hasNoResponse && showResultModifyModal)
  || (finalRejectedOrder && showResultModifyModal));
  const showCancelOrderModal = (cancelOrderTimeoutSuccess || (hasNoCancelResponse && showResultModalCancel)
  || (finalRejectedOrder && showResultModalCancel));

  const onCancelClicked = () => {
    setShowModalCancel(true);
    SubscribeGroupCache.setComponent('initial');
  };

  const handleModalModify = () => {
    if (checkIfSymbolIsHalted(symbolsStatusCache)) {
      setIsHalted(true);
      return;
    }
    setOrderType(plainType);
    setOrderSide(mapOrderSORtoSide(side));

    if (isMobile) {
      history.replace({
        pathname: `${UI_ROUTES.modify}`,
        state: {
          symbol: name,
          parentOrderId: id,
          orderSide: convertOrderSideToText(mapOrderSORtoSide(side)),
          orderType: plainType,
          initialPrice,
          tradingSessionId: row.tradingSessionId,
          initialTimeInForce: SOROrderTimeInForce[row.timeInForce],
          orderQuantity: totalQuantity - partialFillQuantity,
          origin: window.location.pathname,
        },
      });
    } else {
      setShowModifyOrder(true);
    }
  };

  useEffect(() => {
    if (showResultModifyModal) {
      modifyOrder(order);
    }
  }, [modifyOrder, order, showResultModifyModal]);

  const handleModalCancel = (isConfirmed) => {
    if (isConfirmed) {
      setShowResultModalCancel(true);
    }
    SubscribeGroupCache.setComponent('initial');
    setShowModalCancel(false);
  };

  const handleCloseModifyModal = () => {
    SubscribeGroupCache.setComponent('initial');
    setShowModifyOrder(false);
  };

  const onCloseOrderResultModal = () => {
    if (orderInProgress) {
      resetStatus();
    }
    if (status && isAvailableCashChanged(status)) {
      getAccountAvailableCash();
    }
    getOrders({ tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
  };

  const onCloseCancelResultModal = () => {
    onCloseOrderResultModal();
    setShowResultModalCancel(false);
    if (isOneOf(status, [
      OrderStatusEnum.Filled,
      OrderStatusEnum.PartiallyFilled,
      OrderStatusEnum.Rejected,
      OrderStatusEnum.Expired,
    ])) {
      getMessageSummary();
    }
    SubscribeGroupCache.setComponent('initial');
  };

  const onCloseModifyResultModal = () => {
    onCloseOrderResultModal();
    if (!marketClosed) {
      getOpenPositions();

      if (submittedOrder?.status === OrderStatusEnum.Filled) {
        getAccSummary();
      }
      if (isOneOf(status, [
        OrderStatusEnum.Filled,
        OrderStatusEnum.PartiallyFilled,
        OrderStatusEnum.Rejected,
        OrderStatusEnum.Expired,
      ])) {
        getMessageSummary();
      }
    }
    SubscribeGroupCache.setComponent('initial');
    setShowResultModifyModal(false);
    myStocksUpdate(false);
  };

  return (
    <>
      {isMobile ? (
        <div className={isSwipeable ? styles.buttonWrapperSwipable : styles.buttonWrapper}>
          <Button
            className={classNames(
              isSwipeable ? styles.modifySwipeable : styles.modifyButton,
              styles.cancelButton,
            )}
            variant={ButtonTypesEnum.Link}
            onClick={() => onCancelClicked()}
          >
            <img src={cancelIcon} alt="cancel icon pink" />
          </Button>
          <Button
            className={classNames(
              isSwipeable ? styles.modifySwipeable : styles.modifyButton,
              styles.editButton,
            )}
            variant={ButtonTypesEnum.Link}
            onClick={() => handleModalModify()}
          >
            <img src={editIcon} alt="edit icon pink" />
          </Button>
        </div>
      ) : (
        <Grid className={styles.containerButtons}>
          <Grid className={styles.wrapperButtons}>
            <Button
              className={classNames(styles.modifyButton, styles.cancelButton)}
              variant={ButtonTypesEnum.Link}
              onClick={() => onCancelClicked()}
            >
              <img src={cancelIcon} alt="cancel icon pink" />
            </Button>
            <Button
              className={classNames(styles.modifyButton, styles.editButton)}
              variant={ButtonTypesEnum.Link}
              onClick={() => handleModalModify()}
            >
              <img src={editIcon} alt="edit icon pink" />
            </Button>
          </Grid>
        </Grid>
      )}
      {isHalted && <HaltedSymbol symbolName={row.companyName ?? name} toggleModal={() => setIsHalted(false)} />}
      {showModalCancel && (
      <BaseModal isOpen={showModalCancel}>
        <CancelOrder orderId={id} handleModalCancel={handleModalCancel} />
      </BaseModal>
      )}
      <BaseModal
        isOpen={showModifyOrder}
        toggle={handleCloseModifyModal}
      >
        <ModifyOrder
          symbol={name}
          parentOrderId={id}
          orderSide={orderSide}
          orderType={orderType}
          initialPrice={initialPrice}
          tradingSessionId={row.tradingSessionId}
          initialTimeInForce={SOROrderTimeInForce[row.timeInForce]}
          orderQuantity={totalQuantity - partialFillQuantity}
          setOrder={(value) => setOrder(value)}
          setModifyOrderRequested={(value) => {
            setShowModifyOrder(false);
            setShowResultModifyModal(value);
          }}
          onCloseModal={handleCloseModifyModal}
        />
      </BaseModal>
      {showCancelOrderModal && !isMobile && (
      <ProcessFinishedModal
        isCancelOrder
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        processSuccessMessageTitle={
          lang.orderMessageCancelSuccessWeb(ordType ?? 0, ordSide ?? '', orderQty ?? 0, name)
        }
        processFailedMessage={lang.orderMessageBodyCancelFail()}
        processPendingMessage={
          status === OrderStatusEnum.PendingCancelOrModify ? {
            title: lang.orderMessageTitleCancel(orderType ?? 0, ordSide ?? ''),
            body: lang.orderMessageBodyCancelPending(orderQty!, name),
          } : undefined
        }
        defaultMessageTitle={orderInProgress ? lang.orderMessageTitleCancel(orderType ?? 0, ordSide ?? '') : ''}
        defaultMessageBody={orderInProgress ? lang.orderMessageBodyCancelPending(orderQty!, name) : ''}
        toggleModal={onCloseCancelResultModal}
      />
      )}
      {showModifyOrderModal && !isMobile && (
      <ProcessFinishedModal
        processSuccessMessageTitle={modifyOrderPopUpTitle(
          orderSideAsText, showFillOrder, name, ordType!, status, order.orderQty,
        )}
        processFailedMessage={lang.orderMessageBodyModifyFail()}
        processPendingMessage={
          status === OrderStatusEnum.PendingCancelOrModify ? {
            title: lang.orderMessageTitleModify(name, orderType ?? 0, ordSide ?? ''),
            body: lang.orderMessageBodyModifyPending(orderQty!, name),
          } : undefined
        }
        processPartiallyRejectedMessage={partiallyRejectedOrder
          ? proccessPartiallyRejectedMessage(name, ordType!, ordSide!, orderQty!, filledSoFar) : undefined}
        processSuccessMessageBody={
          modifyOrderPopUpBody(showFillOrder, name, filledSoFar, status, order.orderQty)
        }
        defaultMessageTitle={orderInProgress ? lang.orderMessageTitleModify(name, orderType ?? 0, ordSide ?? '') : ''}
        defaultMessageBody={orderInProgress ? lang.orderMessageBodyModifyPending(orderQty!, name) : ''}
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        toggleModal={onCloseModifyResultModal}
      />
      )}
    </>
  );
};

const mapStateProps = (state: RootState) => ({
  requestStatus: state.trading.requestStatus,
  submittedOrder: state.trading.order,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetStatus: () => dispatch(resetRequestStatus()),
  modifyOrder: (order: Order) => dispatch(serviceModifyOrder(order)),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getOpenPositions: (symbol?: string) => dispatch(getPositions({ symbol })),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
  getAccSummary: () => dispatch(getAccountSummary()),
  getMessageSummary: () => dispatch(getSummary()),
  getAccountAvailableCash: () => dispatch(getAccountsAvailableCash()),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ButtonsCancelAndModify);
