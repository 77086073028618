import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DynamicFilterBuilder } from '../../lib/services/filter/dynamic-filter-builder';
import { DEFAULT_SEARCH_STOCK_LIMIT } from '../../lib/store/fundamental/constants';
import { filterButtons, getListEmptyMessage } from '../../lib/store/fundamental/helpers';
import { searchStockByParams } from '../../lib/store/fundamental/index';
import { RootState } from '../../lib/store/index';

import Search from '../SearchStocksModal/Search';
import FilterButton from '../StocksResult/componets/FilterButton';
import StocksResult from '../StocksResult/StocksResult';

import styles from './SearchSymbolModal.module.scss';

type Props = {
    onClose: () => void;
}

const SearchSymbolModal = ({ onClose }: Props) => {
  const refSearch = useRef<HTMLElement>() as React.RefObject<HTMLDivElement>;
  const refResults = useRef<HTMLElement>() as React.RefObject<HTMLDivElement>;

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState<string>('');
  const [selectedFilter, setSelctedFilter] = useState<string>(filterButtons[0]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [searchLimit, setSearchLimit] = useState<number>(DEFAULT_SEARCH_STOCK_LIMIT);

  const query = useSelector<RootState, string>((state: RootState) => state.fundamental.stockQuery);

  const dispatch = useDispatch();
  const params: DynamicFilterBuilder = new DynamicFilterBuilder();

  const prepareFilterBySelectedTab = (val: string): void => {
    if (val === filterButtons[1]) {
      params.equal('issueType', 'cs'); // stocks
      return;
    }
    if (val === filterButtons[2]) {
      params.equal('issueType', 'et'); // ETFs
      return;
    }
    params.equal('issueType', null); // All
  };

  useEffect(() => {
    prepareFilterBySelectedTab(selectedFilter);
    dispatch(searchStockByParams({
      query: searchedText,
      limit: DEFAULT_SEARCH_STOCK_LIMIT,
      params: params.build(),
    }));
    setCurrentPageNumber(1);
    setSearchLimit(DEFAULT_SEARCH_STOCK_LIMIT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedText]);

  const handleTabChange = (val: MouseEvent) => {
    const tabText = (val.target as HTMLDivElement).innerHTML;
    setSelctedFilter(tabText);
    prepareFilterBySelectedTab(tabText);

    // TODO: In ART-4133 add condition for request when backend returns to us symbols or company names wich starts with the searched value
    dispatch(searchStockByParams({
      query,
      limit: DEFAULT_SEARCH_STOCK_LIMIT,
      params: params.build(),
    }));
    setCurrentPageNumber(1);
    setSearchLimit(DEFAULT_SEARCH_STOCK_LIMIT);
  };

  const handleLoadMore = (): void => {
    const limit = searchLimit + DEFAULT_SEARCH_STOCK_LIMIT;
    dispatch(searchStockByParams({
      query,
      limit,
      params: params.build(),
    }));

    setSearchLimit(limit);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  // close the modal when clicked outside
  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        refSearch.current && refResults.current
        && ((!refSearch.current.contains(event.target as Node) && event.target !== refResults.current)
            && (!refResults.current.contains(event.target as Node) && event.target !== refSearch.current))
        && isInitialized
      ) {
        onClose();
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    if (!isInitialized) {
      setIsInitialized(true);
    }

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  return (
    <>
      <div id="SearchSymbolModal" className={styles.modal}>
        <div className={styles.wrapper} ref={refSearch}>
          <Search searchStocks={val => setSearchedText(val)} />
        </div>
        <div className={styles.content} ref={refResults}>
          <div className={styles.buttonsWapper}>
            {filterButtons.map((el) => (
              <FilterButton key={el} isActive={selectedFilter === el} name={el} onClick={handleTabChange} />
            ))}
          </div>
          <StocksResult
            currentPageNumber={currentPageNumber}
            closeModal={() => onClose()}
            noSymbolsFoundMessage={getListEmptyMessage(selectedFilter)}
            handleLoadMore={() => handleLoadMore()}
          />
        </div>
      </div>
      <div className={styles.bgColor} />
    </>
  );
};

export default SearchSymbolModal;
