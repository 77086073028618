import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../../../lib/store';
import TradePriceCache from '../../../../../lib/store-util/TradePriceCache';

import { TradePrice } from '../../PeopleAlsoTrade.types';

type OwnProps = {
  symbol: string;
  handleTradePriceCache: (data: TradePrice) => void;
}
type Props = OwnProps & ReduxProps

const CalculatedTradePriceCache = (props: Props) => {
  const { symbol, companyLogoAndNameData, handleTradePriceCache } = props;
  const { currentPrice, changePercent, direction } = TradePriceCache.use(symbol, 'CalculatedTradePriceCache');

  useEffect(() => {
    if (currentPrice) {
      const company = companyLogoAndNameData[symbol];
      handleTradePriceCache({
        currentPrice,
        changePercent,
        direction,
        symbol,
        logoBase64: company ? company.logo : '',
      });
    }
  }, [currentPrice, changePercent, direction, symbol, companyLogoAndNameData, handleTradePriceCache]);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndNameData: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CalculatedTradePriceCache);
