import { OrderSideEnum } from '../../lib/enums/order-side.enum';
import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { formatCurrency } from '../../lib/util/DataHelpers';
import { getMaxBuyLimitPrice, getMaxSellLimitPrice, validateAggressiveBuyLimitOrder, validateAggressiveSellLimitOrder } from '../../lib/util/TradingValidationsHelper';
import { NullableNumber } from '../../lib/util/types';

export const proccessSuccessMessageTitle = (
  orderType: OrderTypeEnum, orderSide: OrderSideEnum, status?: string, hasLimitFilled?: boolean,
): string => {
  let title = lang.orderMessageTitleFilled(orderType, orderSide);

  if ((orderType === OrderTypeEnum.Limit || orderType === OrderTypeEnum.Stop) && !hasLimitFilled) {
    title = lang.orderMessageTitlePlaced(orderType, orderSide);
  }

  if (status === OrderStatusEnum.PartiallyFilled) {
    title = lang.orderMessageTitlePartiallyFilled(orderType, orderSide);
  }

  return title;
};

export const processFailMessageManager = (
  isRejected: boolean, isExpired: boolean, symbol: string, type: OrderTypeEnum, side: OrderSideEnum, quantity: number,
) => {
  switch (true) {
    case isRejected: return lang.orderMessageBodyRejectedWeb(type, side, quantity, symbol);
    case isExpired: return lang.orderMessageExpired(type, side, quantity, symbol);
    default: return lang.orderMessageTitleError(type, side);
  }
};

export const proccessPartiallyRejectedMessage = (
  symbol: string, orderType: OrderTypeEnum, orderSide: OrderSideEnum, quantity: number, filledSoFar?: number,
): { title: string, body: string } => ({
  title: lang.orderMessageTitlePartiallyRejectedTitle(orderType, orderSide),
  body: lang.orderMessageBodyPartiallyRejectedBody(quantity - (filledSoFar ?? 0), quantity, symbol),
});

export const proccessSuccessMessageBody = (
  symbol: string,
  status?: string,
  quantity?: number,
  orderType?: OrderTypeEnum,
  hasLimitFilled?: boolean,
  filledSoFar?: number,
): string => {
  let text = '';
  const limitOrder = orderType === OrderTypeEnum.Limit;
  const marketOrder = orderType === OrderTypeEnum.Market;
  const stopOrder = orderType === OrderTypeEnum.Stop;

  if (quantity) {
    if (marketOrder || (limitOrder && hasLimitFilled)) {
      text = lang.orderMessageBodyFilled(quantity, symbol);
    }

    if ((limitOrder || stopOrder) && !hasLimitFilled) {
      text = lang.orderMessageBodyPlaced(quantity ?? 0, symbol);
    }

    if (status === OrderStatusEnum.PartiallyFilled && filledSoFar) {
      text = lang.orderMessageBodyPartiallyFilled(filledSoFar, quantity, symbol);
    }
  }

  return text;
};

export const modifyOrderPopUpTitle = (
  ordSide: string,
  showFillOrder: boolean,
  symbol: string,
  type: OrderTypeEnum,
  status?: string,
  orderQty?: number,
): string => {
  let modifyOrderTtile = '';

  if (showFillOrder) {
    modifyOrderTtile = status === OrderStatusEnum.PartiallyFilled
      ? lang.orderMessageTitlePartiallyFilled(OrderTypeEnum.Limit, ordSide)
      : lang.orderMessageTitleFilled(OrderTypeEnum.Limit, ordSide);
  } else {
    modifyOrderTtile = lang.orderMessageModifySuccessWeb(type, ordSide, orderQty || 0, symbol);
  }

  return modifyOrderTtile;
};

export const modifyOrderPopUpBody = (
  showFillOrder: boolean,
  symbol: string,
  filledSoFar?: number,
  status?: string,
  orderQty?: number,
) => {
  let message = '';

  if (showFillOrder) {
    message = status === OrderStatusEnum.PartiallyFilled && filledSoFar
      ? lang.orderMessageBodyPartiallyFilled(filledSoFar, orderQty || 0, symbol)
      : lang.orderMessageBodyFilled(orderQty || 0, symbol);
  }

  return message;
};

export const validateOrder = (
  price: number,
  target: number,
  type: string,
  isBuyBtnClicked: boolean,
  setControlCheck: (check: boolean) => void,
  setErrorMessage: (message: string) => void,
) => {
  setControlCheck(true);
  setErrorMessage('');

  if (isNaN(price)) {
    setControlCheck(false);
  }
  if ([OrderTypeEnum.Limit, OrderTypeEnum.Stop].includes(type as OrderTypeEnum)) {
    if (type === OrderTypeEnum.Limit) {
      const limitCheck = isBuyBtnClicked
        ? validateAggressiveBuyLimitOrder(price, target)
        : validateAggressiveSellLimitOrder(price, target);

      if (!limitCheck) {
        const lastPrice = isBuyBtnClicked ? getMaxBuyLimitPrice(price) : getMaxSellLimitPrice(price);

        setErrorMessage(
          isBuyBtnClicked
            ? lang.commonTradingAggressiveLimitPriceError1(formatCurrency(lastPrice))
            : lang.commonTradingValidationNewOrderSellAggressiveLimitError1(formatCurrency(lastPrice)),
        );
        setControlCheck(false);
      }
    }

    if (type === OrderTypeEnum.Stop) {
      const stopCheck = isBuyBtnClicked ? (target < price) : (target > price);

      if (stopCheck) {
        setErrorMessage(
          lang.commonTradingStopPriceError(isBuyBtnClicked ? lang.webTradingMore() : lang.webTradingLess()),
        );
        setControlCheck(false);
      }
    }

    if (target === 0) {
      const priceType = type === OrderTypeEnum.Limit ? lang.webTradingLimit() : lang.webTradingStop();
      setErrorMessage(lang.commonTradingPriceError(priceType));
      setControlCheck(false);
    }
  }
};

export const getValidationPrice = (
  isMarketClose: boolean,
  askPrice: NullableNumber,
  bidPrice: NullableNumber,
  tradePrice: number | null,
  isBuyOrder?: boolean,
) => {
  if (isMarketClose) {
    return tradePrice;
  }
  return isBuyOrder ? askPrice : bidPrice;
};
