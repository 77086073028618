import { formatNumber, formatNumberWithSuffix } from '../../../../lib/util/DataHelpers';
import { INVALID_VALUE, NullableNumber } from '../../../../lib/util/types';

export const priceChange = (change?: NullableNumber, percent?: NullableNumber) => (
  `${
    (change ?? 0) > 0
      ? '+'
      : ''
  }${
    change != null ? formatNumber(change) : INVALID_VALUE
  } ${formatNumberWithSuffix(percent, '%)', '', '(')}`
);

export type InfoStatusType = 'pre' | 'after' | 'close' | 'none';
