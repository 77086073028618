import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import lang from '../../../lib/language';

import ClearIcon from '../../../assets/img/icon-clear.svg';
import { ClickableDiv, ClickableImage } from '../../../components';
import { useBrowserWidth, useIsMobile } from '../../../hooks';
import { ZOOM_BEHAVIOR_WIDTH } from '../../../util/constants';
import { useStyles } from '../TradeHistoryStyles';

import styles from './StatusSelector.module.scss';

type Props = {
    currentStatus: string;
    handleChangeByStatus: (event: React.ChangeEvent<{ value: unknown }> | string) => void;
    handleBtnClose: any;
    statusList: string[];
    handleMenuItem: (item: string) => JSX.Element;
    handleReset: () => void;
}

const StatusSelector = (props: Props) => {
  const { currentStatus, handleChangeByStatus, handleBtnClose, statusList, handleMenuItem, handleReset } = props;
  const { browserWidthSize } = useBrowserWidth();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [isOpenStatusField, setIsOpenStatusField] = useState<boolean>(false);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);

  const handleOnOpen = () => {
    setIsOpenStatusField(true);
  };

  const handleOnClose = () => {
    setIsOpenStatusField(false);
  };

  const handleChange = (status: string) => {
    handleChangeByStatus(status);
    setIsOpenStatusField(false);
    setShowClearButton(true);
  };

  const onButtonClose = () => {
    handleReset();
    setShowClearButton(false);
  };

  return (
    <>
      { isMobile ? (
        <>
          <div className={showClearButton ? styles.statusWrapperSelected : styles.statusWrapper}>
            <ClickableDiv className={styles.statusContainer} onClick={handleOnOpen}>
              <div className={styles.status}>
                {currentStatus}
              </div>
            </ClickableDiv>
            {showClearButton && (
              <ClickableImage
                className={styles.clearButton}
                src={ClearIcon}
                alt="clear"
                onClick={onButtonClose}
              />
            )}
          </div>
          {isOpenStatusField && (
          <div className={styles.wrapperOrderTypeMenu}>
            <ClickableDiv
              className={styles.orderTypeMenuBackground}
              onClick={handleOnClose}
            />
            <div className={styles.orderTypes}>
              {statusList.slice(1).map((status) => (
                <button
                  key={status}
                  type="button"
                  className={styles.orderType}
                  onClick={() => { handleChange(status); }}
                >
                  {status}
                </button>
              ))}
            </div>
          </div>
          )}
        </>
      ) : (
        <FormControl classes={{ root: classes.formControl }}>
          <Select
            value={currentStatus}
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              classes: {
                paper: classes.paper,
              },
              PaperProps: {
                style: {
                  backgroundColor: '#1D3960',
                  color: '#FFFFFF',
                  marginLeft: browserWidthSize <= 400 ? '10%' : '',
                  zoom: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? `${Math.floor(browserWidthSize / 10).toString()}%` : '',
                },
              },
              getContentAnchorEl: null,
            }}
            classes={{
              select: (currentStatus === lang.commonOrderStatusFilterTitle() && !isOpenStatusField)
                ? classes.initialSelect
                : classes.activeSelect,
            }}
            onOpen={handleOnOpen}
            onClose={handleOnClose}
            onChange={handleChangeByStatus}
            IconComponent={handleBtnClose}
          >
            {statusList.map((item) => (
              <MenuItem
                key={item}
                value={item}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selected,
                }}
              >
                {handleMenuItem(item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default StatusSelector;
