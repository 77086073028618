import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../../lib/language';

import { Button } from '../../../../components';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './Modal.module.scss';

type Props = {
    handleModal: () => void;
    textInfoModal: {title: string, description1: string, description2?: string}
};

const CardTransferModal = (props: Props) => {
  const { handleModal, textInfoModal } = props;

  const history = useHistory();
  const { title, description1, description2 } = textInfoModal;

  return (
    <>
      <h6 id="firstDepositModalTitle" className={styles.title}>{title}</h6>
      <span className={styles.description}>{description1}</span>
      <span className={styles.description}>{description2}</span>
      <div className={styles.buttonWrapper}>
        <Button
          id="firstDepositModalCancel"
          variant={ButtonTypesEnum.Tertiary}
          fullWidth
          className={styles.btnCancel}
          onClick={() => handleModal()}
        >
          {lang.commonCancelButton()}
        </Button>
        <Button
          id="firstDepositModalConfirm"
          fullWidth
          onClick={() => history.push({
            pathname: UI_ROUTES.funds + UI_ROUTES.enterAmount,
            state: {
              target: lang.mobileCardDialogTitle() },
          })}
        >
          {lang.commonConfirmButton()}
        </Button>
      </div>
    </>
  );
};

export default CardTransferModal;
