import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { IndividualActivationStepEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { IndividualExtendedInfoData } from '../../lib/models/enroll';
import { RootState } from '../../lib/store';
import { clearSignupToken as clearSignupTokenCall, getSignupToken as getSignupTokenCall } from '../../lib/store/auth';
import { checkIndividualActivationStep } from '../../lib/store/selectors';

import iconNearlythere from '../../assets/img/icon_nearlythere.svg';
import IconArrow from '../../assets/img/icon-arrow-right_24px.svg';
import useIsMobile from '../../hooks/useIsMobile';
import { UI_ROUTES } from '../../util/routes';
import Button from '../Button/Button';
import { ButtonTypesEnum } from '../Button/Button.types';
import { ClickableImage } from '../index';

import styles from './NotVerifiedNotification.module.scss';

type OwnProps = {
  individual: IndividualExtendedInfoData;
  hasUserName: boolean;
  isPaymentsReady?: boolean;
}

type Props = OwnProps & ReduxProps;

const NotVerifiedNotification = (props: Props) => {
  const {
    individual,
    isFetchingPWPSignupURL,
    PWPSignupURL,
    getSignupToken,
    clearSignupToken,
    activationStep,
    hasUserName,
    transactions,
    isPaymentsReady,
  } = props;
  const history = useHistory();
  const isMobile = useIsMobile();

  const firstDescription = lang.activeYourAccountContent().slice(0, 66);
  const secondDescription = lang.activeYourAccountContent().slice(66);

  useEffect(() => {
    if (!isFetchingPWPSignupURL && PWPSignupURL) {
      window.open(PWPSignupURL, isMobile ? '_self' : '_blank');
      clearSignupToken();// removing the signup token URL from the state to avoid the re-redirection on the next render time
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingPWPSignupURL, PWPSignupURL, isMobile]);

  const handleOnProceedClick = (): void => {
    if (isFetchingPWPSignupURL) {
      return;
    }
    getSignupToken();
  };

  return (
    <>
      {
        hasUserName
        && (
        <h4 id="welcomeUser" className={isMobile ? styles.userNameMobile : styles.userName} aria-label="welcome user">
          {lang.commonWatchlistTitle(individual ? `, ${individual.first_name}` : '')}
        </h4>
        )
      }
      {
        (
          activationStep === IndividualActivationStepEnum.InEnrollment
        || activationStep === IndividualActivationStepEnum.InVerification
        )
        && (
        <div className={classNames(styles.boxInPendingVerification, { [styles.mobileBox]: isMobile })}>
          <h6 className={styles.titleMobile}>{lang.mobileWatchlistActivationBoxTitle()}</h6>
          <div className={styles.wrapperDescription}>
            <div className={styles.description}>
              {isMobile ? <span className={styles.descriptionMobile}>{lang.activeYourAccountContent()}</span> : (
                <>
                  <p className={styles.descriptionContent}>{firstDescription}</p>
                  <p className={styles.descriptionContent}>{secondDescription}</p>
                </>
              )}
            </div>
            {isMobile ? (
              <ClickableImage
                src={IconArrow}
                alt="go to verification"
                className={styles.arrowIcon}
                onClick={handleOnProceedClick}
              />
            ) : (
              <Button
                secondarySize
                variant={ButtonTypesEnum.Primary}
                onClick={handleOnProceedClick}
                id="proceedActivation"
                ariaLabel="proceed with activation"
              >
                {lang.commonProceedButton()}
              </Button>
            )}
          </div>
        </div>
        )
      }
      {
        activationStep === IndividualActivationStepEnum.InPendingVerification
        && (
          <div className={classNames(styles.boxInVerification, { [styles.mobileBoxInVerification]: isMobile })}>
            <img src={iconNearlythere} alt="icon nearlythere" />
            <div className={isMobile ? styles.titleWrapperMobile : undefined}>
              <h6 className={isMobile ? styles.titleMobile : styles.title}>
                {lang.commonNotActiveIndividualNotificationPendingVerificationStepTitle()}
              </h6>
              <span className={isMobile ? styles.descriptionMob : styles.description}>
                {lang.commonNotActiveIndividualNotificationPendingVerificationStepDescription()}
              </span>
            </div>
          </div>
        )
      }
      {
        (isPaymentsReady && transactions.length === 0 && activationStep === IndividualActivationStepEnum.Activated)
        && (
          <div className={classNames(styles.boxFundAccount, { [styles.mobileBox]: isMobile })}>
            <div className={styles.wrapperDescription}>
              <h6 className={styles.title}>{lang.commonNextStepsTitleActive()}</h6>
              {isMobile ? (
                <ClickableImage
                  src={IconArrow}
                  alt="go to verification"
                  className={styles.fundArrowIcon}
                  onClick={() => history.push(`${UI_ROUTES.funds}${UI_ROUTES.makeYourFirstDeposit}`)}
                />
              ) : (
                <Button
                  secondarySize
                  variant={ButtonTypesEnum.Primary}
                  onClick={() => history.push(`${UI_ROUTES.funds}${UI_ROUTES.makeYourFirstDeposit}`)}
                  id="proceedActivation"
                  ariaLabel="proceed with activation"
                >
                  {lang.mobileDepositAmountBtn()}
                </Button>
              )}

            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  activationStep: checkIndividualActivationStep(
    state.crm.individualExtendedInfo,
    state.crm.enroll.financialQuestionnaireSections,
    state.auth.isAppropriatenessPopupConfirmed,
  ),
  transactions: state.payment.paymentsAll.data,
  isFetchingPWPSignupURL: state.auth.isFetchingPWPSignupURL,
  PWPSignupURL: state.auth.PWPSignupURL,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getSignupToken: () => dispatch(getSignupTokenCall()),
  clearSignupToken: () => dispatch(clearSignupTokenCall()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NotVerifiedNotification);
