import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Action } from 'redux';

import { OrderSideEnum, TimeInForceEnum } from '../../../lib/enums';
import { OrderTypeEnum } from '../../../lib/enums/order-type.enum';
import lang from '../../../lib/language';
import { Order } from '../../../lib/models/trading/types';
import { RootState } from '../../../lib/store';
import { getOpenPositions as getPositions, setHasOrderUpdate } from '../../../lib/store/reporting';
import { OpenPosition } from '../../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../../lib/store/selectors';
import MarketStateCache from '../../../lib/store-util/MarketStateCache';
import NBBOPriceCache from '../../../lib/store-util/NBBOPriceCache';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { isMarketClosed, isMarketOpen, isMarketRegularHours } from '../../../lib/util/MarketDataHelpers';
import { TextHelpers } from '../../../lib/util/TextHelpers';
import { uuidv4 } from '../../../lib/util/tools';
import { getOrderTotalValue } from '../../../lib/util/TradingValidationsHelper';

import { Button, Error, NotEnoughBuyingPower, ReviewOrder } from '../..';
import HighLowLimitPriceAlert from '../../HighLowLimitPriceAlert/HighLowLimitPriceAlert';
import SharesCalculator from '../../SharesCalculator/SharesCalculator';
import ExpiryDate from '../components/ExpiryDate';
import LimitPriceInput from '../components/LimitPriceInput';
import MarketPriceInfo from '../components/MarketPriceInfo';
import DropDown from '../DropDown/DropDown';
import { OrderTypeDropdownOptions } from '../order.constants';
import { getValidationPrice } from '../OrderHelpers';
import useValidateOrder from '../useValidateOrder';

import styles from './CreateOrder.module.scss';

type OwnProps = {
  symbol: string;
  orderSide: string;
  positionsQuantity: number | null;
  isPopupMode?: boolean;
  hasMyStocksOrderUpdate?: boolean;
  isMyAccount?: boolean;
  setOrderInfo: (order: Order) => void;
  setFromReview: (fromReview: boolean) => void;
}
type Props = OwnProps & ReduxProps;

function CreateOrder(props: Props) {
  const {
    symbol = '',
    account,
    orderSide,
    positionsQuantity,
    isPopupMode,
    buyingPow,
    hasMyStocksOrderUpdate,
    accountOpenPositionsCompleted,
    positions,
    isMyAccount,
    setOrderInfo,
    setFromReview,
    myStocksUpdate,
    getOpenPositions,
  } = props;

  const isBuyOrder = orderSide === OrderSideEnum.Buy;

  const { marketState } = MarketStateCache.use();
  const isMarketHours = isMarketRegularHours(marketState);
  const marketClosed = isMarketClosed(marketState);
  const showMarketInfoIcon = isMarketOpen(marketState);
  const nbboData = NBBOPriceCache.use(symbol);
  const { askprice, bidprice } = nbboData;

  const [order, setOrder] = useState<Order>({});
  const [reviewOrder, setReviewOrder] = useState<boolean>(false);
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [criticalError, setCriticalError] = useState<string>('');
  const [targetPriceError, setTargetPriceError] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [targetPrice, setTargetPrice] = useState<number>(0);
  const [orderType, setOrderType] = useState<string>(isMarketHours ? OrderTypeEnum.Market : OrderTypeEnum.Limit);
  const [timeInForce, setTimeInForce] = useState<string>(TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay));
  const [includeExtendedHours, setIncludeExtendedHours] = useState<boolean>(false);

  const [isReviewOrderCheckTriggered, setIsReviewOrderCheckTriggered] = useState<boolean>(false);
  const [openedEnoughBPModal, setOpenedEnoughBPModal] = useState<boolean>(false);
  const [visibleHighLowLimitPriceAlert, setVisibleHighLowLimitPriceAlert] = useState<boolean>(false);

  const isMarketOrder = orderType === OrderTypeEnum.Market;
  const orderTypeOptions = OrderTypeDropdownOptions(isMarketHours, isBuyOrder);
  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'CreateOrder');
  const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice, isBuyOrder);
  const { errorMessage, controlCheck } = useValidateOrder(orderType, validationPirce ?? 0, targetPrice, isBuyOrder);

  const totalValue = getOrderTotalValue(
    orderType,
    quantity,
    tradePrice,
    targetPrice,
  );

  useEffect(() => {
    if (isReviewOrderCheckTriggered && ((accountOpenPositionsCompleted && !marketClosed) || marketClosed)) {
      setFromReview(true);
      setReviewOrder(false);
      setIsReviewOrderCheckTriggered(false);
    }
  }, [order, setFromReview, isReviewOrderCheckTriggered, accountOpenPositionsCompleted, marketClosed]);

  const setOrderData = useCallback((theOrder: Order) => {
    setOrder(theOrder);
    setOrderInfo(theOrder);
    setReviewOrder(true);
  }, [setOrderInfo]);

  const isValidOrder = useCallback((qty: number, buyingPower: number): boolean => {
    setTargetPriceError(errorMessage);

    const buyingPowerCheck = buyingPower > totalValue;

    if (!positionsQuantity && (controlCheck && !buyingPowerCheck)) {
      setOpenedEnoughBPModal(true);
    }

    if (positionsQuantity) {
      return (positionsQuantity >= qty) && controlCheck;
    }

    return buyingPowerCheck && controlCheck;
  }, [controlCheck, errorMessage, positionsQuantity, totalValue]);

  const onReviewOrderClicked = useCallback((
    sym: string,
    qty: number,
    type: string,
    price: number,
    side: string,
    time: string,
    buyingPower: number,
    target: number,
  ) => {
    setCriticalError('');
    setTargetPriceError('');

    if (isValidOrder(qty, buyingPower)) {
      const orderObject: Order = {};

      if (type === OrderTypeEnum.Stop) {
        orderObject.stopPrice = target;
      }

      const parsedTime = time === TimeInForceEnum.GTC ? time : TimeInForceEnum.EndOfDay;
      orderObject.clientOrderId = uuidv4();
      orderObject.symbol = sym;
      orderObject.side = side as OrderSideEnum;
      orderObject.orderQty = qty;
      orderObject.ordType = type as OrderTypeEnum;
      if (type !== OrderTypeEnum.Market) {
        orderObject.price = target;
      }
      orderObject.timeInForce = parsedTime;
      orderObject.account = account;
      orderObject.includeExtendedHours = includeExtendedHours;

      if (type === OrderTypeEnum.Limit) {
        if (
          (isBuyOrder && (target > price))
          || (!isBuyOrder && (target < price))
        ) {
          setVisibleHighLowLimitPriceAlert(isBuyOrder ? (target > price) : (target < price));
          setOrder(orderObject);
          setOrderInfo(orderObject);

          return;
        }
      }
      setOrderData(orderObject);
    }
  }, [account, isBuyOrder, isValidOrder, setOrderData, setOrderInfo, includeExtendedHours]);

  const handleChangeOrderType = useCallback((option) => {
    setTargetPriceError('');
    setOrderType(option.value);
    setTimeInForce(TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay));
  }, []);

  useEffect(() => {
    positionsQuantity ? setQuantity(positionsQuantity) : setQuantity(1);
  }, [positionsQuantity, orderSide]);

  useEffect(() => {
    if (!isInitialised && positionsQuantity) {
      setQuantity(positionsQuantity);
      setIsInitialised(true);
    }
  }, [isInitialised, positionsQuantity]);

  return (
    <>
      <div className={styles.wrapperOrderContainer}>
        {isPopupMode && <h6 className={styles.title}>{`${orderSide} ${symbol}`}</h6>}
        <div className={styles.wrapperOrderTypeDropDown}>
          <p className={styles.orderTypeText}>{lang.commonTradingOrderType()}</p>
          <DropDown
            options={orderTypeOptions}
            value={orderType}
            handleChange={handleChangeOrderType}
            isPopupMode={isPopupMode}
            isDisabled={isMarketHours}
          />
        </div>
        <div className={styles.wrapperNumberOfShares}>
          <div className={styles.numberOfShares}>
            <p className={styles.numberOfSharesText}>{lang.mobileTradingTicketNumberOfShares()}</p>
            {!!positionsQuantity && (
              <p className={styles.numberOfSharesSubText}>
                {
                  isBuyOrder
                    ? lang.webTradingShortedStocks(positionsQuantity)
                    : lang.webTradingAvailableShares(positionsQuantity)
                }
              </p>
            )}
          </div>
          <div className={styles.wrapperSharesField}>
            <SharesCalculator
              quantity={quantity}
              setQuantity={setQuantity}
              maxQuantity={positionsQuantity || undefined}
            />
          </div>
        </div>
        {(!isMarketOrder) && (
          <LimitPriceInput
            initialPrice={0}
            orderType={orderType}
            targetPriceError={targetPriceError}
            setTargetPrice={(value) => setTargetPrice(value)}
            setTargetPriceError={(value) => setTargetPriceError(value)}
          />
        )}
        <MarketPriceInfo
          askPrice={askprice}
          bidPrice={bidprice}
          symbol={symbol}
          tradePrice={tradePrice}
          isMarketOpen={showMarketInfoIcon}
          isMyAccount={isMyAccount}
        />
        {(!isMarketOrder) && (
          <ExpiryDate
            isMarketHours={isMarketHours}
            time={timeInForce}
            positionsBySymbol={positions.find((position: OpenPosition) => (position.symbol === symbol))}
            orderType={orderType}
            orderSide={orderSide}
            extendedHours={includeExtendedHours}
            setTimeInForce={(value) => setTimeInForce(value)}
            setIncludeExtendedHours={(value) => setIncludeExtendedHours(value)}
          />
        )}
        <div className={styles.horizontalLine} />
        <div className={classNames(styles.totalValueContainer, { [styles.noBuyingPower]: positionsQuantity })}>
          <div>{lang.commonTradingOrderValue()}</div>
          <div className={styles.value}>
            {formatCurrency(totalValue)}
          </div>
        </div>
        {!positionsQuantity && (
          <div className={styles.availableBuyingContainer}>
            <div>{lang.mobileOrderCreateBuyingPower()}</div>
            <div className={styles.value}>{formatCurrency(buyingPow)}</div>
          </div>
        )}
        {(criticalError.trim() !== '') && (
          <div className={styles.errorWrapper}>
            <Error
              hiddenIcon
              errorMessage={criticalError}
            />
          </div>
        )}

        <Button
          className={styles.buttonOrder}
          onClick={() =>
            onReviewOrderClicked(
              symbol,
              quantity,
              orderType,
              tradePrice ?? 0,
              orderSide,
              timeInForce,
              buyingPow,
              targetPrice,
            )}
        >
          {lang.mobileOrderCreateButtonReviewOrder()}
        </Button>
        {openedEnoughBPModal && <NotEnoughBuyingPower closeModal={() => setOpenedEnoughBPModal(false)} />}
        {visibleHighLowLimitPriceAlert && (
          <HighLowLimitPriceAlert
            isBuyOrder={isBuyOrder}
            processModal={() => {
              setVisibleHighLowLimitPriceAlert(false);
              setReviewOrder(true);
            }}
            toggleModal={() => setVisibleHighLowLimitPriceAlert(false)}
          />
        )}
      </div>
      {reviewOrder && (
        <ReviewOrder
          symbol={symbol}
          order={order}
          isBuyOrder={isBuyOrder}
          onSubmit={() => {
            if (!marketClosed) {
              getOpenPositions(symbol);
              hasMyStocksOrderUpdate && myStocksUpdate(true);
            }
            setIsReviewOrderCheckTriggered(true);
          }}
          onCancel={() => setReviewOrder(false)}
        />
      )}
    </>
  );
}

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  account: state.crm.accounts[state.crm.selectedAccount],
  accountOpenPositionsCompleted: state.reporting.accountOpenPositionsCompleted,
  buyingPow: getCurrentBuyingPower(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOpenPositions: (symbol: string) => dispatch(getPositions({ symbol })),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CreateOrder);
