import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { logConfig } from '../../../configDebug';
import { MARKET_DATA_DATE, MARKET_DATA_TIME_ZONE } from '../../constants/date-time.constants';
import { GWMarketDataSummaryBucket } from '../../models/gateway/types';
import { MarketStateInstrumentSnapshot, MDSFeed, OHLCV } from '../../models/market-data/types';
import { BigChartData } from '../../store-util/BigChartCache';
import CallsCache from '../../store-util/calls-cache/CallsCache';
import { composeBigChartCallCacheParam } from '../../store-util/calls-cache/helpers';
import MarketStateCache from '../../store-util/MarketStateCache';
import SubscribeGroupCache from '../../store-util/SubscribeGroupCache';
import { CalculatedSymbolPrices } from '../../util/ChartingHelpers';
import { isOneOf } from '../../util/DataHelpers';
import { isDateEqual } from '../../util/DateTimeHelpers';
import { gatewaySummaryBucketToChartRange } from '../../util/GatewayHelpers';
import { extractSymbolsFromListAsArray, isMarketRegularHours } from '../../util/MarketDataHelpers';
import { addToObjectOfArray } from '../../util/ObjectTools';
import { parseOrderTicketComponentToComponentType } from '../../util/SubscribeHelpers';
import { NullableNumber, OrderTicketComponent, SubscribedComponent, TypedObject } from '../../util/types';
import { deleteWatchlistItemCache } from '../common-actions';
import { RootState } from '../index';
import { getFavoriteStocks, getTopMoversInitialItems } from '../selectors';

import { ChartDataRange, LoadChartDataPayload, SmallChartData, SymbolPriceData } from './charting/types';
import { InstrumentSnapshotData, OHLCVDataForPriceCalculation, ProcessSymbolSubscribesPayload, ProcessSymbolUnSubscribesPayload, SubscribeGroup } from './types';


export const EMPTY_PRICE_DATA: SymbolPriceData = {
  lastPrice: null,
  close: null,
  previousClose: null,
  afterClose: null,
  previousAfterClose: null,
  hasAfterMarketData: null,
  hasDataForToday: null,
};

export const EMPTY_CALCULATED_PRICE_DATA: CalculatedSymbolPrices = {
  lastTradePrice: null,
  lastTradeTime: null,
  currentMainPrice: null,
  prevMainPrice: null,
  hasAfterMarketData: null,
  mainPriceDirection: '',
  extraPriceDirection: '',
};

function getOHLCVItemDateAndTime(item: OHLCV) {
  const { dateAndTime, date, time } = item ?? {};
  return dateAndTime ?? `${date} ${time}`;
}

type CalculationInputType = 'instrument-snapshot' | 'chart-data'
/**
 * This helper function updates `prevData` using `instrument-snapshot` or `chart-data` (bucket 3M1D)
 *
 * Data updates by `inputType`:
 *
 *   instrument-snapshot
 *        Depending on market state updates and availability in snapshot - lastPrice, close, afterClose, previousClose, previousAfterClose, lineClose
 *
 *   chart-data (patches instrument snapshot)
 *        bucket 1D5m
 *          Data set - hasDataForToday
 *        bucket 3M1D
 *          Data set - previousClose/lineClose for Pre Market state only (n/a from Instrument Snapshot)
 */
export function parseSymbolPriceSourceData(
  inputType: CalculationInputType,
  newData: OHLCVDataForPriceCalculation | InstrumentSnapshotData,
  prevData?: SymbolPriceData,
) {
  const result = { ...(prevData ?? EMPTY_PRICE_DATA) };
  const { marketStateInstrumentSnapshot } = MarketStateCache.get();

  switch (inputType) {
    case 'instrument-snapshot': {
      const { prevClose, close, lastPrice, prevLastPrice } = newData as InstrumentSnapshotData;
      switch (marketStateInstrumentSnapshot) {
        case MarketStateInstrumentSnapshot.IS_PreMarket:
          result.lastPrice = lastPrice ?? prevLastPrice ?? prevClose;
          result.close = prevClose;
          result.afterClose = prevLastPrice;
          result.hasAfterMarketData = !!(prevLastPrice && lastPrice && prevLastPrice !== lastPrice);
          // NOTE: Instrument Snapshot is lacking this data - previousClose/lineClose, previousAfterClose
          break;

        case MarketStateInstrumentSnapshot.IS_RegularHours:
          result.lastPrice = lastPrice ?? prevLastPrice ?? prevClose;
          result.close = prevClose;
          result.afterClose = prevLastPrice;
          result.hasAfterMarketData = !!(prevLastPrice && lastPrice && prevLastPrice !== lastPrice);
          result.lineClose = prevClose;
          // NOTE: Instrument Snapshot is lacking this data - previousClose/lineClose, previousAfterClose
          break;

        case MarketStateInstrumentSnapshot.IS_AfterMarket:
          result.lastPrice = lastPrice ?? close;
          result.close = close;
          result.afterClose = null;
          result.hasAfterMarketData = false;
          result.previousClose = prevClose;
          result.previousAfterClose = prevLastPrice;
          result.lineClose = prevClose;
          break;

        case MarketStateInstrumentSnapshot.IS_MarketClosed:
          result.lastPrice = lastPrice ?? close;
          result.close = close;
          result.afterClose = lastPrice;
          result.hasAfterMarketData = !!(close && lastPrice && close !== lastPrice);
          result.previousClose = prevClose;
          result.previousAfterClose = prevLastPrice;
          result.lineClose = prevClose;
          break;

        case MarketStateInstrumentSnapshot.IS_MarketClosedNextDay:
          result.lastPrice = prevLastPrice ?? prevClose;
          result.close = prevClose;
          result.afterClose = prevLastPrice;
          result.hasAfterMarketData = !!(prevLastPrice && lastPrice && prevLastPrice !== lastPrice);
          // NOTE: Instrument Snapshot is lacking this data - previousClose/lineClose, previousAfterClose
          break;

        default:
          console.error(`[market-data/helpers] parseSymbolPriceSourceData - could not use Instrument Snapshot - unknown market state '${marketStateInstrumentSnapshot}'`);
          break;
      }
      break;
    }

    case 'chart-data': {
      const { data, bucket } = newData as OHLCVDataForPriceCalculation;
      const len = data?.length;
      const lastItem = data?.[len - 1];
      const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
      const nowMoment = momenttz().tz(MARKET_DATA_TIME_ZONE);
      const nowAsDateStr = nowMoment.format(MARKET_DATA_DATE);
      const hasDataForToday = isDateEqual(nowAsDateStr, getOHLCVItemDateAndTime(lastItem));
      switch (bucket) {
        case GWMarketDataSummaryBucket.OneDay: {
          result.hasDataForToday = hasDataForToday;
          break;
        }
        case GWMarketDataSummaryBucket.ThreeMonths: {
          const lastButOneItem = data?.[len - 2];
          const lastButTwoItem = data?.[len - 3];
          // The following cases are not available from Instrument Snapshot
          // therefore taken from 3M1D MarketDataSummary
          let previousClose: NullableNumber;
          switch (marketStateInstrumentSnapshot) {
            case MarketStateInstrumentSnapshot.IS_PreMarket:
              previousClose = hasDataForToday ? lastButTwoItem?.close : lastButOneItem?.close;
              result.previousClose = previousClose;
              result.lineClose = previousClose;
              break;
            case MarketStateInstrumentSnapshot.IS_RegularHours:
              previousClose = hasDataForToday ? lastButTwoItem?.close : lastButOneItem?.close;
              result.previousClose = previousClose;
              break;
            case MarketStateInstrumentSnapshot.IS_AfterMarket:
              previousClose = lastButOneItem?.close;
              result.previousClose = previousClose;
              break;
            case MarketStateInstrumentSnapshot.IS_MarketClosedNextDay:
              previousClose = lastButOneItem?.close;
              result.previousClose = previousClose;
              result.lineClose = previousClose;
              break;
            default:
              break;
          }
          break;
        }

        default:
          console.error(`[market-data/helpers] parseSymbolPriceSourceData - unknown chart data bucket '${bucket}'`);
          break;
      }
      break;
    }

    default:
      console.error(`[market-data/helpers] parseSymbolPriceSourceData - unknown inputType = '${inputType}'`);
      break;
  }

  return result;
}

/**
 * This helper has two modes:
 * (1) Prepares subscribes list for a group of symbols
 * (2) In case of switching between a group of old subscriptions to a group of new - prepares
 *     two lists - one unsubscribe list for old and one subscribe list for new. If there are matches - if new feed is same
 *     as old - these symbols are left as is. If the new feed is different from old - the matching
 *     symbols are unsubscribed from old and subscribed to new feed. The example below illustrates
 *     the second case - when there are matches between old and new symbols but feeds differ:
 *     Input:
 *         Old=[AAPL,FB], old-feed='trade'
 *         New=[AAPL], new-feed='trade-nbbo'
 *      Result:
 *          Unsubscribed - AAPL,FB from 'trade'
 *          Subscribed - AAPL to 'trade-nbbo'
 * NOTE: There are 3 different group meanings in this helper:
 *  (1) group         - the new group that the user is switching to
 *  (2) currentGroup  - the currentGroup - currently visible before switching to group
 *  (3) prevGroup     - if currentGroup is same as group (isSameGroup=true) - this is the prevGroup from cache - currentGroup otherwise
 */
export function prepareSubscriptionsForGroup(group: SubscribeGroup, symbols: string[], feed: MDSFeed) {
  const { data, prevData, prevGroup, isSameGroup } = SubscribeGroupCache.get(group);
  const { otherSymbols, otherSymbolsData } = SubscribeGroupCache.getOther(group);
  const isToBeReinitialized = isGroupToBeReinitialized(group);
  const currentSymbols = Object.keys(data).filter(symbol => data[symbol]);
  // get old symbols in cache only if unsubscribing
  const targetSymbols: string[] = uniq((isToBeReinitialized || feed ? [] : currentSymbols).concat(symbols));
  const newAndAlreadySubscribedList: TypedObject<MDSFeed> = {};

  // main logic
  const symbolsToSubscribe: TypedObject<string[]> = {};
  const symbolsToUnsubscribe: TypedObject<string[]> = {};
  targetSymbols.forEach(symbol => {
    const currentFeed = data[symbol];

    if (isSameGroup) {
      const prevFeed = data[symbol];
      if (feed) {
        // subscribe
        const feedCheck = areFeedsMatching(feed, prevFeed, true);
        if (feedCheck === true) {
          newAndAlreadySubscribedList[symbol] = feed;
        } else if (feedCheck === false) {
          if (prevFeed) addToObjectOfArray(symbolsToUnsubscribe, prevFeed as string, [ symbol ]);
          else addToObjectOfArray(symbolsToSubscribe, feed as string, [ symbol ]);
        } else {
          // TODO: Add if any custom case for complex feeds
        }
      } else if (currentFeed) {
        // unsubscribe
        addToObjectOfArray(symbolsToUnsubscribe, currentFeed as string, [ symbol ]);
      }
    } else if (feed) {
      // subscribe
      const prevFeed = prevData[symbol];
      const feedCheck = areFeedsMatching(feed, prevFeed, true);
      if (feedCheck === true) {
        newAndAlreadySubscribedList[symbol] = feed;
      } else if (feedCheck === false) {
        feed.split(',').forEach((theFeed: string) => {
          addToObjectOfArray(symbolsToSubscribe, theFeed, [ symbol ]);
        });
      } else {
        const { subscribes, unsubscribes, alreadySubscribed } = feedCheck;
        subscribes.forEach(theFeed => {
          addToObjectOfArray(symbolsToSubscribe, theFeed, [ symbol ]);
        });
        unsubscribes.forEach(theFeed => {
          addToObjectOfArray(symbolsToUnsubscribe, theFeed, [ symbol ]);
        });
        alreadySubscribed.forEach(theFeed => {
          newAndAlreadySubscribedList[symbol] = theFeed as MDSFeed;
        });
      }
    } else if (currentFeed) {
      // unsubscribe
      addToObjectOfArray(symbolsToUnsubscribe, currentFeed as string, [ symbol ]);
    }
  });
  otherSymbols.forEach(symbol => {
    if (targetSymbols.includes(symbol)) return;

    const otherFeed = otherSymbolsData[symbol];
    if (otherFeed) {
      addToObjectOfArray(symbolsToUnsubscribe, otherFeed, [ symbol ]);
    }
  });
  let subscribeList: string[] = [];
  for (const theFeed in symbolsToSubscribe) {
    subscribeList = subscribeList.concat(symbolsToSubscribe[theFeed]);
  }
  subscribeList = uniq(subscribeList);

  return {
    symbolsToSubscribe,
    subscribeList,
    symbolsToUnsubscribe,
    newAndAlreadySubscribedList,
    prevGroup,
    isSameGroup,
  };
}

export const prepareSubscriptionsForComponent = (component: SubscribedComponent, symbols: string) => (
  component !== 'initial' ? symbols.split(',') : []
);

export function isGroupToBeReinitialized(group: SubscribeGroup) {
  return isOneOf(group, [ 'symbol-details', 'create-order' ] as SubscribeGroup[]);
}

/**
 * Used internally by `getFeedBySubscribeGroup`
 */
function getCustomFeedBySubscribeGroupAndComponent(component?: OrderTicketComponent) {
  let result: MDSFeed | null = null;

  switch (component) {
    case 'people-also-trade':
      result = 'trade';
      break;
    default:
      break;
  }

  return result;
}

export function getFeedBySubscribeGroup(group: SubscribeGroup, component?: OrderTicketComponent) {
  const { isWeb } = require('../../../configLib').default;
  let result: MDSFeed = false;
  let noSuchGroup = false;

  if (group === 'initial') return 'trade';

  const componentFeed = getCustomFeedBySubscribeGroupAndComponent(component);
  if (componentFeed) return componentFeed;

  if (isWeb) {
    switch (group) {
      case 'watchlist':
        result = 'trade';
        break;

      case 'symbol-details':
        result = 'trade,nbbo';
        break;

      case 'chart-details':
        result = 'trade';
        break;

      case 'create-order':
        result = 'nbbo';
        break;

      case 'discover':
        result = 'trade';
        break;

      case 'my-account':
        result = 'trade';
        break;

      case 'history':
        result = 'trade';
        break;

      default:
        noSuchGroup = true;
        break;
    }
  } else {
    switch (group) {
      case 'watchlist':
        result = 'trade';
        break;

      case 'symbol-details':
        result = 'trade';
        break;

      case 'create-order':
      case 'modify-order':
        result = 'trade,nbbo';
        break;

      case 'discover':
        result = 'trade';
        break;

      case 'my-account':
        result = 'trade';
        break;

      default:
        noSuchGroup = true;
        break;
    }
  }

  if (noSuchGroup) {
    console.warn(`[market-data/helpers] getFeedBySubscribeGroup - No such group in ${isWeb ? 'Web' : 'Mobile'} - ${group}`);
  }

  return result;
}

/**
 * @param targetFeed The new feed that is need
 * @param prevFeed The one to compare with
 * @param checkPartialMatch Optional - if true, checks for partial match
 *
 * Returns `true` or `false`, if `checkPartialMatch` is `true` - returns an array of subscribes or unsubscribes
 * (comma separated)
 */
export function areFeedsMatching(targetFeed: MDSFeed, prevFeed: MDSFeed, checkPartialMatch?: boolean) {
  if (targetFeed === false && prevFeed === false) return true;
  if (targetFeed && prevFeed) {
    const isMultiple = targetFeed.includes(',') || prevFeed.includes(',');
    if (targetFeed !== prevFeed && !isMultiple) return false;

    if (isMultiple) {
      const sortedTarget = targetFeed.split(',').sort();
      const sortedPrev = prevFeed.split(',').sort();
      const areSame = sortedTarget.join() === sortedPrev.join();
      if (!checkPartialMatch) return areSame;
      if (areSame) return true;

      const subscribes: string[] = [];
      const alreadySubscribed: string[] = [];
      const unsubscribes: string[] = [];
      sortedTarget.forEach(item => {
        let hasAMatch = false;
        sortedPrev.forEach(item2 => {
          if (item === item2) hasAMatch = true;
        });
        if (hasAMatch) {
          alreadySubscribed.push(item);
        } else {
          subscribes.push(item);
        }
      });
      sortedPrev.forEach(item => {
        let hasAMatch = false;
        sortedTarget.forEach(item2 => {
          if (item === item2) hasAMatch = true;
        });
        if (!hasAMatch) {
          unsubscribes.push(item);
        }
      });

      return { subscribes, unsubscribes, alreadySubscribed };
    }

    return targetFeed === prevFeed;
  }
  return false;
}

/**
 * Gathers lists of input symbols together with group data if existing.
 * Result is clean of duplicates.
 */
export function createSubscribeSymbolsCheckList(symbolOrSymbols: string, group?: SubscribeGroup | null) {
  const inputSymbols = symbolOrSymbols ? symbolOrSymbols.split(',') : [];
  const { symbols: groupSymbols = [] } = group ? SubscribeGroupCache.get(group) : {};
  const resultSymbols = inputSymbols.concat(groupSymbols);
  return uniq(resultSymbols);
}

/**
 * Performs the switching of group (of symbols) depending on mode:
 * (see libSettings.ts -> 'USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH')
 *
 * if `USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH` is `true`
 *   (1) subscribes selected symbols
 *   (2) unsubscribes previous group symbols that are not present in current
 *   (3) writes to cache - updates the SubscribeGroupsCache
 *
 * if `USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH` is `false`
 *   (1) subscribes selected symbols
 *   (2) writes to cache - updates the SubscribeGroupsCache
 *
 * Helpers used:
 *   - `prepareSubscriptionsForGroup`
 *   - `isGroupToBeReinitialized`
 *   - `createSubscribeSymbolsCheckList`
 *   - `SubscribeGroupCache.setSubscribed|get|refreshGroup`
 * */
export function processSymbolSubscribesHelper(actionPayload: ProcessSymbolSubscribesPayload) {
  const { __DEV__ } = require('../../../configLib');
  const {
    symbolOrSymbols, group, reInitializeGroup, skipUnsubscribe, customFeed, caller, component,
  } = actionPayload;

  const componentType = parseOrderTicketComponentToComponentType(component);
  const isInitialLoading = group === 'initial';
  const isEmptyGroupBeforeProcessing = reInitializeGroup || isGroupToBeReinitialized(group);
  const symbolsToCheck = createSubscribeSymbolsCheckList(
    symbolOrSymbols,
    isEmptyGroupBeforeProcessing ? null : group,
  );
  // contains symbols that are left-overs
  let extraUnsubscribes: TypedObject<string[]> = {};
  const newFeed = customFeed ?? getFeedBySubscribeGroup(group, component);

  if (__DEV__ && logConfig.subscriptionsFlow) {
    console.tron.log!(`[SUBSCRIBES-Helper-Summary] [caller: ${caller ?? 'n/a'}] -- symbols: ${symbolOrSymbols.split(',').length}, group: ${group ?? '<no-group>'}, feed: ${newFeed} [s-flow]`);
    console.tron.log!(`[SUBSCRIBES-Helper-Detailed] -- ${symbolOrSymbols.split(',').length} | ${symbolOrSymbols}, group: ${group ?? '<no-group>'}, feed: ${newFeed}  [CALLER: ${caller ?? 'n/a'}] [s-flow]`);
  }

  // prepare empty list if deleting group before processing (for symbol-details for example)
  if (isEmptyGroupBeforeProcessing && !isInitialLoading) {
    const { data } = SubscribeGroupCache.get(group);
    const newSymbols: string[] = symbolOrSymbols.split(',');
    for (const symbol in data) {
      let feedToUnsubscribe = data[symbol];
      if (feedToUnsubscribe && !newSymbols.includes(symbol)) {
        if (!extraUnsubscribes[feedToUnsubscribe]) {
          extraUnsubscribes[feedToUnsubscribe] = [];
        }
        extraUnsubscribes[feedToUnsubscribe].push(symbol);
      }
    }

    SubscribeGroupCache.refreshGroup(group, symbolsToCheck, newFeed);
  }
  if (symbolsToCheck.length === 0) {
    console.warn(`[market-data/helpers] processSymbolSubscribesHelper - calling subscribe without symbols and no subscriptions for group: '${group}'`);
    return;
  }

  const {
    subscribeList, symbolsToSubscribe, symbolsToUnsubscribe, newAndAlreadySubscribedList,
  } = prepareSubscriptionsForGroup(group, symbolsToCheck, newFeed);
  const symbols = prepareSubscriptionsForComponent(componentType, symbolOrSymbols);
  const newAndAlreadySubscribed = Object.keys(newAndAlreadySubscribedList);
  // add component and symbols
  SubscribeGroupCache.setComponent(componentType, symbols);

  // unsubscribe by feed (also covers symbols that need to re-subscribe next if needed)
  const { USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH } = require('../../libSettings');
  if (!skipUnsubscribe && !isInitialLoading && USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH) {
    for (let unsubscribeFeed in symbolsToUnsubscribe) {
      const items = symbolsToUnsubscribe[unsubscribeFeed] as string[] ?? [];
      const extraItems = extraUnsubscribes[unsubscribeFeed] as string[] ?? [];
      const allItems = uniq(items.concat(extraItems));
      processSymbolUnsubscribesHelper({
        symbolOrSymbols: allItems.join(),
        symbolsSkipCache: subscribeList,
        feed: unsubscribeFeed as MDSFeed,
        caller: 'processSymbolSubscribesHelper 1',
      });
      // clean extraUnsubscribes of checked feeds to prepare for next for
      if (extraUnsubscribes[unsubscribeFeed]) delete extraUnsubscribes[unsubscribeFeed];
    }
    for (let unsubscribeFeed in extraUnsubscribes) {
      const extraItems = extraUnsubscribes[unsubscribeFeed] as string[] ?? [];
      processSymbolUnsubscribesHelper({
        symbolOrSymbols: extraItems.join(),
        symbolsSkipCache: subscribeList,
        feed: unsubscribeFeed as MDSFeed,
        caller: 'processSymbolSubscribesHelper 1',
      });
    }
  }

  // subscribe new symbols
  const GlobalService = require('../../services/Global.service').default;
  if (!GlobalService?.globalService?.subscribe) {
    console.error('[market-data/helpers] processSymbolSubscribesHelper - GlobalService not initialized');
  }

  // re-subscribe - for symbols that are changing subscription (for example: trade -> trade,nbbo)
  if (subscribeList.length > 0) {
    SubscribeGroupCache.setSubscribed(subscribeList, group, newFeed);
    for (const theFeed in symbolsToSubscribe) {
      GlobalService?.globalService?.subscribe(symbolsToSubscribe[theFeed], theFeed as any, true, `${caller}/processSymbolSubscribesHelper/${theFeed}`);
    }
  }

  // update cache if it contains symbols that were skipped in subscribeList because already subscribed
  if (newAndAlreadySubscribed.length > 0) {
    SubscribeGroupCache.setSubscribed(newAndAlreadySubscribed, group, newFeed);
  }
}

/**
 * Does 2 things:
 *   (1) unsubscribes `symbolOrSymbols` (calls unsubscribe on Global.service)
 *   (2) updates `SubscribeGroupCache`
 * */
export function processSymbolUnsubscribesHelper(actionPayload: ProcessSymbolUnSubscribesPayload) {
  const { __DEV__ } = require('../../../configLib');
  const { symbolOrSymbols, symbolsSkipCache = [], isRemovingFromFavorites, feed, group, caller } = actionPayload;
  const symbolsToCheck = createSubscribeSymbolsCheckList(symbolOrSymbols, group);

  if (__DEV__ && logConfig.subscriptionsFlow) {
    console.tron.log!(`[UNSUBSCRIBES-Helper-Summary] [CALLER: ${caller ?? 'n/a'}] -- symbols: ${symbolOrSymbols.split(',').length}, group: ${group ?? '<no-group>'} [s-flow]`);
    console.tron.log!(`[UNSUBSCRIBES-Helper-Detailed] -- symbols: ${symbolOrSymbols.split(',').length} | ${symbolOrSymbols}, group: ${group ?? '<no-group>'}  [CALLER: ${caller ?? 'n/a'}] [s-flow]`);
  }

  if (!symbolsToCheck?.length) {
    console.warn('[market-data/helpers] processSymbolUnsubscribesHelper - no symbols to unsubscribe');
    console.tron.log(`[market-data/helpers] processSymbolUnsubscribesHelper - no symbols to unsubscribe - symbols:${symbolOrSymbols} group:${group} feed:${feed}`);
    return;
  }

  let symbolsToSetInCache: string[] = [];
  if (symbolsSkipCache.length === 0) {
    symbolsToSetInCache = symbolsToCheck;
  } else {
    symbolsToSetInCache = symbolsToCheck.filter(item => !symbolsSkipCache.includes(item));
  }
  SubscribeGroupCache.setUnSubscribed(symbolsToSetInCache, null, feed);
  if (isRemovingFromFavorites) {
    SubscribeGroupCache.deleteFromGroup(symbolOrSymbols, 'watchlist');
    // clear Gateway query cache when unsubscribing a favorites item
    const { store } = require('../../../configLib').default;
    symbolsToCheck.forEach(symbol => {
      store.dispatch(deleteWatchlistItemCache({ symbol, allQueryCases: true }));
    });
  }

  const GlobalService = require('../../services/Global.service').default;
  GlobalService.globalService.unsubscribe(symbolsToCheck, feed);
}

export function getAllInitialSymbols(state: RootState) {
  let symbols: string[] = [];

  // favorites
  const favorites = extractSymbolsFromListAsArray(getFavoriteStocks(state));

  // fundamental - popular, top movers & new on the market
  const { popularStocks, newStocks } = state.fundamental;
  const { topGainers, topLosers } = getTopMoversInitialItems();

  // reporting - open positions
  const { openPositions } = state.reporting;

  const watchlist = extractSymbolsFromListAsArray(popularStocks).concat(favorites);
  const discover = (
    extractSymbolsFromListAsArray(topGainers)
      .concat(extractSymbolsFromListAsArray(topLosers))
      .concat(extractSymbolsFromListAsArray(newStocks))
  );
  const myAccount = extractSymbolsFromListAsArray(openPositions);

  symbols.push(...watchlist);
  symbols.push(...discover);
  symbols.push(...myAccount);

  const uniqueSymbols = uniq(symbols);

  return {
    symbols: uniqueSymbols,
    symbolsAsString: uniqueSymbols.join(),
    byGroup: {
      watchlist,
      discover,
      myAccount,
    },
  };
}

/**
 * Returns `true` if cache is not available and a data request is needed
 */
export function checkIsRequestNeededForBigChartData(payload: LoadChartDataPayload) {
  const { CACHED_MARKET_DATA_CALLS } = require('../../libSettings');
  const { default: TradePriceCache } = require('../../store-util/TradePriceCache');
  const { default: BigChartCache } = require('../../store-util/BigChartCache');
  const { default: SmallChartDataCache } = require('../../store-util/SmallChartDataCache');
  const {
    marketDataSummaryBig,
    marketDataSummaryBigPreCacheWithSmall,
    marketDataSummary1D5mInOpenMarket,
  } = CACHED_MARKET_DATA_CALLS;
  const { symbol, bucket } = payload;
  const range = gatewaySummaryBucketToChartRange(bucket) as ChartDataRange;
  const { symbol: cachedSymbol, dataByRange: cachedData } = BigChartCache.get() as BigChartData;
  const smallChartCache = SmallChartDataCache.get(symbol) as SmallChartData;
  const isOneDayBucket = bucket === GWMarketDataSummaryBucket.OneDay;
  const isMarketOpen = isMarketRegularHours();
  const isSameSymbol = symbol === cachedSymbol;
  const isSameBucketCached = cachedData[range]?.isValid ?? false;
  const isFirstOneDayRequest = (
    isOneDayBucket
    && !isSameSymbol
  );
  const lineClose = TradePriceCache.get(symbol).priceData?.lineClose;
  const isPreCachedFromSmallChart: boolean = (
    marketDataSummaryBigPreCacheWithSmall
    && isFirstOneDayRequest
    && smallChartCache.isReady
    && !isEmpty(smallChartCache.ohlcvData)
    && !!lineClose
  );
  const isToBeSkippedIfCached = (
    (!isOneDayBucket && marketDataSummaryBig)
    || (isOneDayBucket && isMarketOpen && marketDataSummary1D5mInOpenMarket)
    || (isOneDayBucket && !isMarketOpen && marketDataSummaryBig)
  );
  const isAlreadyCached = (
    isPreCachedFromSmallChart
    || (isSameSymbol && isSameBucketCached && isToBeSkippedIfCached)
  );
  const isRegularMaretUpdate = isOneDayBucket && isMarketOpen;

  const callsCacheParam = composeBigChartCallCacheParam(symbol, bucket);
  const { toBeUpdated } = CallsCache.checkRequest('getChartingData', callsCacheParam);

  if (!toBeUpdated && isAlreadyCached && !isRegularMaretUpdate) {
    console.warn(`[market-data/helpers] checkIsRequestNeededForBigChartData - skipping current call for symbol: ${symbol}, bucket: ${bucket} - because of valid cache`);
    return { isPreCachedFromSmallChart, hasToMakeRequest: false };
  }

  if (isAlreadyCached) {
    console.warn(`[market-data/helpers] checkIsRequestNeededForBigChartData - skipping already cached data for symbol: ${symbol}, bucket: ${bucket}`);
    return { isPreCachedFromSmallChart, hasToMakeRequest: false };
  }

  return { isPreCachedFromSmallChart, hasToMakeRequest: true };
}
