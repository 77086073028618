import React from 'react';
import classNames from 'classnames';

import lang from '../../../../lib/language';
import { formatCurrency, formatNumber } from '../../../../lib/util/DataHelpers';
import { formatNetComponentsDate } from '../../../../lib/util/DateTimeHelpers';

import styles from '../chart.module.scss';

type Props = {
  date: string,
  isChartPointed: boolean,
  isPrevDayExist: boolean,
  isProfitAndLoss: boolean,
  todayAbsoluteChange: number,
  absolutePeriodChange: number,
  persentPeriodChange?: number,
}

const PerformanceChangeHeader = ({
  date,
  isChartPointed,
  isPrevDayExist,
  isProfitAndLoss,
  persentPeriodChange,
  todayAbsoluteChange,
  absolutePeriodChange,
}: Props): JSX.Element => {
  const absolutePeriodChangeFormatted = absolutePeriodChange > 0
    ? `+${formatCurrency(absolutePeriodChange, false, true)}` : formatCurrency(absolutePeriodChange, false, true);
  const todayAbsoluteChangeFormatted = todayAbsoluteChange >= 0
    ? `+${formatCurrency(todayAbsoluteChange, false, true)}` : formatCurrency(todayAbsoluteChange, false, true);

  const negativeValue = (isChartPointed && absolutePeriodChange < 0) || (!isChartPointed && todayAbsoluteChange < 0);

  return (
    <>
      <span className={classNames(styles.pastYearValue,
        { [styles.negativeValue]: negativeValue })}
      >
        {(!isProfitAndLoss || !isChartPointed) && (
        <>
          <span>
            {isPrevDayExist && (
              isChartPointed ? absolutePeriodChangeFormatted : todayAbsoluteChangeFormatted
            )}
          </span>
          {!isProfitAndLoss && !!persentPeriodChange && isPrevDayExist && (
          <span className={styles.percentageValue}>
            (
            {persentPeriodChange > 1000 ? '>1000' : formatNumber(Math.abs(persentPeriodChange))}
            %)
          </span>
          )}
        </>
        )}
      </span>
      <span className={styles.pastPeriodText}>
        {!isChartPointed ? lang.performanceRangeTextToday() : formatNetComponentsDate(date)}
      </span>
    </>
  );
};

export default PerformanceChangeHeader;
