import React, { Dispatch, useCallback } from 'react';

import lang from '../../../lib/language';

import {
  EnrollProgressBarBackSectionAction,
  EnrollProgressBarNextQuestionAction,
} from '../../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';
import TransitionScreen from '../../TransitionScreen/TransitionScreen';
import EnrollLayout from '../EnrollLayout/EnrollLayout';

type Props = {
  title: string;
  subTitle: string;
  progressBarDispatch: Dispatch<{ type: string }>;
}

const EnrollTransitionScreen = (props: Props) => {
  const {
    title,
    subTitle,
    progressBarDispatch,
  } = props;

  const onContinue = useCallback(() => {
    progressBarDispatch({ type: EnrollProgressBarNextQuestionAction });
  }, [progressBarDispatch]);

  const onBack = useCallback(() => {
    progressBarDispatch({ type: EnrollProgressBarBackSectionAction });
  }, [progressBarDispatch]);

  return (
    <EnrollLayout
      hiddenInlineHelp
      visibleContinueButton
      onBackButtonClicked={onBack}
      onContinueClicked={onContinue}
      continueButtonText={lang.commonOnboardingTransitionButtonTitle()}
      isTransitionScreen
      secondaryRowContent={(
        <TransitionScreen
          title={title}
          subTitle={subTitle}
        />
      )}
    />
  );
};

export default EnrollTransitionScreen;
