import { MarketStateInstrumentSnapshot } from './lib/models/market-data/types';
import { _setMarketStateCache_InUnitTestsAndMockedMode, DEBUG_MARKET_STATE } from './lib/store-util/MarketStateCache';
import { patchConsoleTron } from './lib/util/LogFiltering';

const DEBUG_GET_PARAM_TO_LOG_CONFIG = {
  t1: 'tradePriceUpdates',
  t2: 'tradePriceParsed',
  t3: 'tradePriceCacheUpdates',
  t4: 'tradePriceCacheUpdatesSymbol',
  n1: 'nbboPriceUpdates',
  n2: 'nbboPriceParsed',
  i1: 'instrumentSnapshot',
  gws1: 'gatewayWSMessages',
  gr1: 'gatewayRESTCacheRequest',
  gr2: 'gatewayRESTCacheResponse',
  actions: 'Custom defined as: if present Redux actions are logged',
  chart: 'chart',
  redux: 'Custom defined as: if present redux logging is enabled',
  market: "Custom defined as: market:<marketState>[*<marketState10n>], for example: 'market:1*2', Market states - pre=1, regular=2, after=3, closed=4, closedNext=5",
  'test-header': 'Custom defined as: if present Test Header is enabled',
  pn: 'pushNotifications',
  pf: 'priceFlow',
  sf: 'subscriptionsFlow',
};

/* eslint-disable no-param-reassign */
const debugLogConfig = (options: any, logConfig: any) => {
  if (!options) return;

  const { origConsole } = require('./configDebug'); // eslint-disable-line global-require
  if (options.includes('help')) {
    origConsole.info('[Help-Information] Log Config options', DEBUG_GET_PARAM_TO_LOG_CONFIG);
    return;
  }

  // console
  console = { ...origConsole }; // eslint-disable-line no-native-reassign,no-global-assign
  patchConsoleTron(() => {});
  // eslint-disable-next-line global-require
  require('./configLib')?.default.enableDev();

  // logConfig
  logConfig.enabled = true;
  options.split(',').forEach(key => {
    if (key === 'actions') {
      logConfig.actions = true;
      return;
    }
    if (key === 'redux') return;
    if (key === 'test-header') return;
    if (key.includes('market')) {
      const [k, v, v2] = key.split(key.includes(':') ? ':' : '=');
      const [ arg1, arg2 ]: MarketStateInstrumentSnapshot[] = (v ?? 1).split('*').map(m => Number(m)) as any[];
      DEBUG_MARKET_STATE.enable();
      _setMarketStateCache_InUnitTestsAndMockedMode(arg1, arg2);
      return;
    }
    if (key.includes(':')) {
      const [k, v] = key.split(':');
      logConfig[DEBUG_GET_PARAM_TO_LOG_CONFIG[k]] = !isNaN(Number(v)) ? Number(v) : v;
      return;
    }
    const k = DEBUG_GET_PARAM_TO_LOG_CONFIG[key];
    if (k) logConfig[k] = true;
  });
  console.info(`Log Config Changed (${options})`, logConfig); // eslint-disable-line no-console

  if (options.includes('test-header')) {
    const { MOCK } = require('./configDebug'); // eslint-disable-line global-require
    MOCK.setProp('TEST_HEADER', true);
  }
};
/* eslint-enable no-param-reassign */

export default debugLogConfig;
