import React from 'react';

import lang from '../../../lib/language';

import BaseModal from '../../BaseModal/BaseModal';
import { Button } from '../../index';

import styles from './HaltedSymbol.module.scss';

type Props = {
  symbolName: string;
  toggleModal: () => void;
}

const HaltedSymbol = ({ symbolName, toggleModal }: Props) => (
  <BaseModal isOpen>
    <h6 className={styles.title}>{lang.commonHaltedSymbolPopupTitle()}</h6>
    <div className={styles.contentWrapper}>
      <p>{lang.commonHaltedSymbolPopupContent(symbolName)}</p>
    </div>
    <Button fullWidth onClick={toggleModal}>{lang.commonOkay()}</Button>
  </BaseModal>
);

export default HaltedSymbol;
