import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

import { OrderSideEnum } from '../../lib/enums';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import { NextAccountData } from '../../lib/models/account';
import { GWMarketDataQueryBucket, GWMarketDataSummaryBucket, GWQueryCase, GWRequestType } from '../../lib/models/gateway/types';
import GlobalService from '../../lib/services/Global.service';
import { disconnectGateway, processSymbolSubscribesAction, processSymbolUnSubscribesAction, reconnect } from '../../lib/store/common-actions';
import { getCompanyLogoAndName, loadStock } from '../../lib/store/fundamental';
import { subscribeNews, unsubscribeNews } from '../../lib/store/gateway';
import { getLatestNews, getNewsForSymbol } from '../../lib/store/news';
import { getAccountTradeHistory } from '../../lib/store/reporting/index';
import { newOrder } from '../../lib/store/trading';
import { selectNextAccount } from '../../lib/util/DataHelpers';
import { getDateTime } from '../../lib/util/DateTimeHelpers';

import { MOCK } from '../../configDebug';
import PushNotificationService from '../../services/PushNotification.service';

import styles from './Header.module.scss';

function getAllChartingRangesData(symbol: string, bucket?: GWMarketDataSummaryBucket) {
  GlobalService.globalService.getSymbolDetailsChartData(symbol, bucket);
}

function checkOneSignal() {
  PushNotificationService.checkState();
}

function TestHeader(props: any): JSX.Element {
  const {
    isAppConnected,
    isAppLoaded,
    account,
    accounts,
    selectedAccount,
    login,
    logout,
    getNextAccount,
    getExtendedInfo,
    hasLoadedExtendedInfo,
    dispatch,
  } = props;
  const isLoggedIn = isAppConnected && isAppLoaded;

  const [isTestHeaderVisible, setIsTestHeaderVisible] = useState(true);

  function ohlcvCall(theCase) {
    const symbol = 'AAPL';
    let startTime: string = '';
    let endTime = getDateTime();
    let bucket: GWMarketDataQueryBucket | null = null;

    switch (theCase) {
      case 'last-day':
        startTime = getDateTime(true, ['minus'], [[5, 'days']]);
        endTime = getDateTime();
        bucket = GWMarketDataQueryBucket.OneDay;
        break;
      case 'last-day-ext':
        startTime = getDateTime(true, ['minus'], [[5, 'days']]);
        endTime = getDateTime();
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;
      case 'Test 1':
        startTime = getDateTime(true, [], [], '2020-12-04');
        endTime = getDateTime(true, ['plus'], [[30, 'minutes']], '2020-12-04');
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;
      case 'Test 2':
        startTime = getDateTime(true, [], [], '2021-10-01');
        endTime = getDateTime(true, ['plus'], [[30, 'minutes']], '2021-10-01');
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;
      case '4H':
        startTime = getDateTime(true, [ 'minus' ], [ [ 4, 'hours' ] ]);
        bucket = GWMarketDataQueryBucket.OneSecond;
        break;

      case '5D':
        startTime = getDateTime(true, [ 'minus' ], [ [ 5, 'day' ] ]);
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;

      case '3W':
        startTime = getDateTime(true, [ 'minus' ], [ [ 3, 'week' ] ]);
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;

      case '6M':
        startTime = getDateTime(true, [ 'minus' ], [ [ 6, 'month' ] ]);
        bucket = GWMarketDataQueryBucket.OneMinute;
        break;

      case '6Md':
        startTime = getDateTime(true, [ 'minus' ], [ [ 6, 'month' ] ]);
        bucket = GWMarketDataQueryBucket.OneDay;
        break;

      case '3Y':
        startTime = getDateTime(true, [ 'minus' ], [ [ 3, 'year' ] ]);
        bucket = GWMarketDataQueryBucket.OneDay;
        break;

      case '10Y':
        startTime = getDateTime(true, [ 'minus' ], [ [ 10, 'years' ] ]);
        bucket = GWMarketDataQueryBucket.OneDay;
        break;
    }

    GlobalService.globalService.query(
      [ symbol ],
      startTime,
      endTime,
      bucket!,
      GWQueryCase.OHLCV,
      'ohlcv',
    );
  }

  const nextAccount = () => {
    const nextAccountData: NextAccountData = selectNextAccount(accounts, selectedAccount);
    getNextAccount(nextAccountData);
  };

  if (!isAppLoaded) {
    return (
      <Row className={styles.container}>
        <span className={styles.time}>Loading ...</span>
      </Row>
    );
  }

  return (
    <>
      <Button
        color="secondary"
        size="sm"
        className={styles.toggleTestButton}
        onClick={() => setIsTestHeaderVisible(!isTestHeaderVisible)}
      >
        Toggle Test Header
      </Button>
      { isTestHeaderVisible
        && (
        <>
          <Container fluid className={styles.testContainer}>
            <Row>
              <Col xs="auto">
                <Row className={styles.testStatsRow}>
                  <strong>Backend:</strong>
&nbsp;
                  {MOCK.ENABLED ? 'mocked' : 'real'}
                </Row>
                <Row className={styles.testStatsRow}>
                  <strong>Account:</strong>
&nbsp;
                  {isLoggedIn ? account || 'n/a' : '-'}
                </Row>
                <Row className={styles.testStatsRow}>
                  <strong>Status:</strong>
&nbsp;
                  {isAppConnected && isAppLoaded ? 'connected' : 'disconnected'}
                </Row>
              </Col>
              <Col className={styles.testButtonContainer}>
                <Row>
                  <TestButton title="Reconnect-GW" onClick={() => dispatch(reconnect({ gateway: true }))} visible={isLoggedIn} />
                  <TestButton title="Disconnect-GW" onClick={() => dispatch(disconnectGateway())} visible={isLoggedIn} />
                  <TestButton title="Reconnect-MDS" onClick={() => dispatch(reconnect({ marketData: true }))} visible={isLoggedIn} />
                  <TestButton title="Login" onClick={login} visible={isAppLoaded && !isAppConnected} />
                  <TestButton title="Logout" onClick={logout} visible={isLoggedIn} />
                  <TestButton title={`Extended Info${hasLoadedExtendedInfo ? ': YES' : ''}`} onClick={() => getExtendedInfo()} visible={isLoggedIn} />
                  <TestButton title="Account" onClick={nextAccount} visible={isLoggedIn} />
                  <TestButton title="Orders" onClick={dispatch(getAccountTradeHistory({}))} param={account} visible={isLoggedIn} />
                  <TestButton title="New Order" onClick={() => dispatch(newOrder(createDebugOrder(account)))} visible={isLoggedIn} />
                </Row>
                <Row>
                  <TestButton title="Load-Stock" onClick={() => dispatch(loadStock({ symbol: 'AAPL', size: 5 }))} visible={isLoggedIn} />
                  <TestButton title="Add Fav Item & Subscribe" onClick={() => dispatch(processSymbolSubscribesAction({ symbolOrSymbols: 'AAPL', isFavorites: true, group: 'watchlist', caller: 'TestHeader' }))} visible={isLoggedIn} />
                  <TestButton title="Remove Fav Item & Unsubscribe" onClick={() => dispatch(processSymbolUnSubscribesAction({ symbolOrSymbols: 'AAPL', isRemovingFromFavorites: true, group: 'watchlist', caller: 'TestHeader' }))} visible={isLoggedIn} />
                  <TestButton title="Stock/Symbol-Details" onClick={() => dispatch(loadStock({ symbol: 'AAPL', size: 5 }))} visible={isLoggedIn} />
                  <TestButton title="Subscribe-Trade/NBBO" onClick={() => dispatch(processSymbolSubscribesAction({ symbolOrSymbols: 'AAPL', group: 'watchlist', caller: 'TestHeader' }))} visible={isLoggedIn} />
                  <TestButton title="UnSubscribe-Trade/NBBO" onClick={() => dispatch(processSymbolUnSubscribesAction({ symbolOrSymbols: 'AAPL', group: 'watchlist', caller: 'TestHeader' }))} visible={isLoggedIn} />
                </Row>
                <Row>
                  <TestButton title="Latest-News" onClick={() => dispatch(getLatestNews({}))} visible={isLoggedIn} />
                  <TestButton title="News (old for symbol)" onClick={() => dispatch(getNewsForSymbol({ gwType: GWRequestType.GetNewsArticles, symbol: 'AAPL' }))} visible={isLoggedIn} />
                  <TestButton title="News-For-Symbol" onClick={() => dispatch(getNewsForSymbol({ symbol: 'AAPL' }))} visible={isLoggedIn} />
                  <TestButton title="Subscribe-News" onClick={() => dispatch(subscribeNews('AAPL,TSLA,AMZN'))} visible={isLoggedIn} />
                  <TestButton title="UnSubscribe-News" onClick={() => dispatch(unsubscribeNews('AAPL,TSLA,AMZN'))} visible={isLoggedIn} />
                  <TestButton title="Get-Company-Logo" onClick={() => dispatch(getCompanyLogoAndName('AAPL,TSLA,AMZN'))} visible={isLoggedIn} />
                </Row>
                <Row>
                  <TestButton title="OHLCV Last-Day" onClick={() => ohlcvCall('last-day')} visible={isLoggedIn} />
                  <TestButton title="OHLCV Last-Day-Extended" onClick={() => ohlcvCall('last-day-ext')} visible={isLoggedIn} />
                </Row>
                <Row>
                  <TestButton title="AAPL 1W Charting Data" onClick={() => getAllChartingRangesData('AAPL', GWMarketDataSummaryBucket.OneWeek)} visible={isLoggedIn} />
                  <TestButton title="AAPL Charting Data" onClick={() => getAllChartingRangesData('AAPL')} visible={isLoggedIn} />
                  <TestButton title="CRSR Charting Data" onClick={() => getAllChartingRangesData('CRSR')} visible={isLoggedIn} />
                  <TestButton title="GSAT Charting Data" onClick={() => getAllChartingRangesData('GSAT')} visible={isLoggedIn} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold', paddingTop: 105 }}>Push Notifications:</span>
                <TestButton title="Check" onClick={() => checkOneSignal()} />
              </Col>
            </Row>
          </Container>
        </>
        )}
    </>
  );
}

const TestButton = ({ title, onClick, param, visible = true }: any) => {
  if (!visible) return null;

  return <Button color="link" size="sm" style={{ marginTop: -3.5 }} onClick={() => onClick(param)}>{title}</Button>;
};

function createDebugOrder(account: string) {
  return {
    order: { // an example order
      price: 120.01,
      symbol: 'AAPL',
      orderQty: 1,
      ordType: OrderTypeEnum.Market,
      account,
      side: OrderSideEnum.Buy, // or SELL
      timeInForce: 'DAY',
      buyingPower: 100000,
    },
  };
}

export default TestHeader;
