import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { LegalDeclaration } from '../../../lib/models/crm/types';
import { ENROLL_METHOD, ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import {
  EnrollLegalDeclarationRequestData,
  EnrollPostRequestBody,
} from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enroll } from '../../../lib/store/crm';

import { CheckboxControl, ClickableSpan, TermsAndConditions } from '../../../components';
import CheckboxControlV2 from '../../../components/CheckboxControl/CheckboxControlV2';
import EnrollHeader from '../../../components/Enroll/EnrollHeader/EnrollHeader';
import EnrollLayout from '../../../components/Enroll/EnrollLayout/EnrollLayout';

import PEPDescriptionModal from './PEPDescriptionModal';

import styles from './VerificationTermsConditions.module.scss';

const VerificationTermsConditions = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const legalDeclarations = useSelector<RootState, LegalDeclaration[]>((state: RootState) => (
    state.crm.legalDeclarations
  ));

  const requestState = useSelector<RootState, ENROLL_REQUEST_STATE>((state: RootState) => state.crm.enroll.status);

  const [showPep, setShowPep] = useState<boolean>(false);
  const [agreeToPep, setAgreeToPep] = useState<boolean>(false);
  const [agreeToDeclarations, setAgreeToDeclarations] = useState<boolean>(false);
  const [isRequestMade, setIsRequestMade] = useState<boolean>(false);

  const postLegalDeclarations = useCallback((data: EnrollLegalDeclarationRequestData): void => {
    dispatch(enroll({
      callMethod: ENROLL_METHOD.putIndividualOwnLegalDeclarations,
      callMethodName: ENROLL_METHOD[ENROLL_METHOD.putIndividualOwnLegalDeclarations],
      requestBody: data,
    } as EnrollPostRequestBody));
  }, [dispatch]);

  const postIndividualPepDetails = useCallback(() => {
    dispatch(enroll({
      callMethod: ENROLL_METHOD.postIndividualPepDetail,
      callMethodName: ENROLL_METHOD[ENROLL_METHOD.postIndividualPepDetail],
      requestBody: {},
    } as EnrollPostRequestBody));
  }, [dispatch]);

  const goBack = useCallback((): void => {
    history.goBack();
  }, [history]);

  const handleAgreeToPep = () => {
    if (!showPep) {
      setAgreeToPep(!agreeToPep);
    }
  };

  const goNext = useCallback((): void => {
    const legalDeclarationsIds = legalDeclarations.map((declaration: LegalDeclaration) => declaration.id);

    setIsRequestMade(true);

    if (!agreeToPep) {
      postIndividualPepDetails();
    }

    postLegalDeclarations({ legal_declarations: legalDeclarationsIds });
  }, [legalDeclarations, agreeToPep, postLegalDeclarations, postIndividualPepDetails]);

  useEffect(() => {
    if (isRequestMade && (requestState === ENROLL_REQUEST_STATE.READY)) {
      dispatch(enroll({
        callMethod: ENROLL_METHOD.getIndividualExtendedInfo,
        callMethodName: ENROLL_METHOD[ENROLL_METHOD.getIndividualExtendedInfo] }));
      history.goBack();
    }
  }, [dispatch, history, isRequestMade, requestState]);

  return (
    <>
      <EnrollHeader className={styles.headerLogo} />
      <div className={styles.container}>
        <EnrollLayout
          visibleContinueButton
          onContinueClicked={goNext}
          onBackButtonClicked={goBack}
          title={lang.commonOnboardingLegalTitle()}
          disabledSubmitButton={!agreeToDeclarations || isRequestMade}
          continueButtonText={lang.commonDoneButton()}
        >
          <div className={styles.contentWrapper}>
            <p className={styles.titleText}>{lang.commonTheInformationIHaveProvided()}</p>
            <TermsAndConditions isVerification />
            <div className={styles.textsWrapper}>
              <p>{lang.commonOnboardingLegalText1()}</p>
              <p>{lang.commonOnboardingLegalText2()}</p>
            </div>
            <div className={styles.wrapperCheckBox}>
              <CheckboxControl
                id="clientAgreementCheck"
                ariaLabel="Client Agreement checkbox"
                secondary
                centered={false}
                className={styles.checkboxText}
                checked={agreeToDeclarations}
                label={lang.commonOnboardingLegalCheckBtn1()}
                onChange={setAgreeToDeclarations}
              />
              <CheckboxControlV2
                id="PEPCheck"
                ariaLabel="PEP checkbox"
                checked={agreeToPep}
                centered={false}
                className={styles.checkboxText}
                onChange={handleAgreeToPep}
                component={(
                  <p className={styles.text}>
                    {lang.commonPep1()}
                    <ClickableSpan className={styles.link} onClick={() => setShowPep(true)}>
                      {lang.commonPep2()}
                    </ClickableSpan>
                    {lang.commonPep3()}
                  </p>
                )}
              />
            </div>
          </div>
        </EnrollLayout>
      </div>
      <PEPDescriptionModal visible={showPep} onPressOK={() => setShowPep(false)} />
    </>
  );
};

export default VerificationTermsConditions;
