import { ApiResponse } from 'apisauce';

import config from '../../config';
import { ReportingFilePayloadData } from '../store/reportingFile/types';

import { BaseRequestService } from './BaseRequest.service';
import { IReportingFileService } from './types';

const API_PATH = {
  getFile: '/v1/10n/download-order-confirmation-report',
};

export class ReportingFileService extends BaseRequestService implements IReportingFileService {
  protected getBaseUrl(): string {
    return config.reportingFile.url;
  }

  get(requestBody: ReportingFilePayloadData): Promise<ApiResponse<string>> {
    return this.api.post<string>(API_PATH.getFile, requestBody);
  }
}
