import { isRegExp } from 'lodash';
import { Plugin } from 'simpler-state';

import { ENTITY_DEBUG_ACTION_TYPE } from './constants';
import { calcTime } from './helpers';
import { DebugSlice } from './types';


/**
 * Plugin for debugging caches implemented with simpler-state aka `entity`.
 * Sets Redux state `debugState.<slice-name>` (for example debugState.debugEntity, debugState.debug).
 * @param name The name to appear in logs and debugState
 * @param slice The cache slice name to be written to as `debugEntity.<slice>` in Redux state
 * @return Result is as an array of `Plugin` in order to use directly. Return should be modified if more than one plugin required.
 */
const entityReduxLogger = (name: string, slice: DebugSlice): Plugin[] | undefined => {
  const configLib = require('../../../configLib').default;
  const { logConfig } = require('../../../configDebug');
  const { __DEV__, __TEST__ } = configLib ?? {};
  if (!configLib || !__DEV__ || __TEST__) return undefined;

  let skip = false;
  const MONITOR_LIST = logConfig.entityDebugEnabled ?? [];

  if (!__DEV__ || __TEST__) skip = true;
  if (!MONITOR_LIST.includes(slice)) skip = true;
  if (skip) return undefined;

  return [ {
    init: (origInit, entity) => () => setTimeout(() => {
      origInit();
      const next = entity.get();
      const action = {
        type: ENTITY_DEBUG_ACTION_TYPE,
        payload: { name, slice, prev: null, next },
      };
      setTimeout(() => {
        const { store } = require('../../../configLib').default;
        store?.dispatch(action);
      }, 0);
    }, 10),
    set: (origSet, entity) => (...args) => {
      setTimeout(() => {
        const prev = entity.get();
        const entityCacheTimeFilter = (logConfig as any)?.entityWriteTime;
        if (entityCacheTimeFilter) {
          const isAMatch = !!(
            isRegExp(entityCacheTimeFilter)
              ? name?.match(entityCacheTimeFilter)
              : entityCacheTimeFilter
          );
          if (isAMatch) calcTime(name, true);
          origSet(...args);
          if (isAMatch) calcTime(name);
        } else {
          origSet(...args);
        }
        setTimeout(() => {
          const next = entity.get();
          const { store } = require('../../../configLib').default;
          try {
            const action = {
              type: ENTITY_DEBUG_ACTION_TYPE,
              payload: { name, slice, prev, next },
            };
            store?.dispatch(action);
          } catch (error) {
            console.warn(`[entity-redux-logger][${name}/${slice}] Error - ${(error as any).message}`);
            console.debug(`[entity-redux-logger][${name}/${slice}] Error - `, error);
          }
        }, 5);
      }, 5);
    },
  } ];
};

export default entityReduxLogger;
