import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../../../lib/language';

import leftArrow from '../../../../../assets/img/blue_icon-arrow-left.svg';
import { ClickableImage } from '../../../../index';
import TermsAndConditions from '../../../../TermsAndConditions/TermsAndConditions';

import styles from './Documents.module.scss';

type Props = {
  location: { state: any, dropDownItem: string, pathname: string },
}

const Documents = (props: Props) => {
  const history = useHistory();
  const { location } = props;

  return (
    <div className={styles.wrapper}>
      <ClickableImage
        alt=""
        src={leftArrow}
        className={styles.leftArrow}
        onClick={() => history.goBack()}
      />
      <div className={styles.wrapperDocument}>
        <div className={styles.headerText}>
          <h3>{lang.mobileProfileDocuments()}</h3>
        </div>
        <div className={styles.wrapperNavigation}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Documents;
