import apisauce, { ApisauceInstance } from 'apisauce';
import qs from 'qs';

import config from '../../config';
import { ExtraUserDetails, SignupTokenData } from '../models/auth/types';
import { NullableString } from '../util/types';

import { ICommonAuthService } from './types';


/**
 * Path params marked given as $1, $2 etc.
 */
const API_PATH = {
  signupToken: '/api/v1/User/sign-up-token',
  connectToken: '/connect/token',
  /**
   * Has 3 functions:
   *  - creates a user (new record in Users table)
   *  - gets first name, last name and country of residence as input (to create a new record in Individuals table)
   *  - creates the user for both 10n Web App and Mobile App, no matter which was used to register
   * This endpoint has the following implementations:
   *  1. /api/v1/User - the original one
   *  2. /api/v1/User/with-sending-details - was added to enable country of residence (see CRM.service.ts `/country/list`)
   *  3. /api/v1/User/bulk-insert-clients - was added to extend 2. - when used it also adds all 10n clients to the account,
   *     so that a 10n account registered in 10n Mobile App would also be available for 10n Web App.
   * Only the last number 3. is to be used with 10n apps as it has all needed in one package.
   * */
  registerUserWithAllClients: '/api/v1/User/bulk-insert-clients',
  confirmEmail: '/api/v1/User/confirm-email',
  resendEmail: '/api/v1/User/send-confirmation-email',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  userPermissions: '/api/v1/User/permissions',
  phoneNumberSendCode: '/api/v1/User/phone-number/send-code',
  phoneNumberVerify: '/api/v1.1/User/phone-number/verify',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  getAvailableClients: '/api/v1/User/available-clients',
  postAssignedClients: '/api/v1/User/$1/assigned-clients/$2',
  putAssignedClients: '/api/v1/User/$1/assigned-clients',
  sendResetPassword: '/api/v1/User/send-reset-password',
  sendNonAppropriatenessEmail: '/api/v1/User/send-non-appropriateness/$1',
};

export default class CommonAuthService implements ICommonAuthService {
  private api: ApisauceInstance;
  private phone: string | null = null;

  constructor() {
    this.api = apisauce.create({ baseURL: `${config.auth.issuer}` });
  }

  registerUser(
    email: string,
    password: string,
    confirmPassword: string,
    userExtraDetails = ((null as any) as ExtraUserDetails),
  ) {
    return this.api.post<any>(
      API_PATH.registerUserWithAllClients,
      {
        email,
        password,
        confirm_password: confirmPassword,
        tenant_id: config.auth.tenantId,
        client: config.auth.clientId,
        redirect_url: config.auth.redirectUrlConfirmEmail,
        send_email: true,
        clients: [ '10nMobile', '10nMobileLocal', '10nWeb', '10nWebLocal' ],
        user_type_relation: 1,
        ...(userExtraDetails ?? {}),
      },
    );
  }

  confirmEmail(email: string, token: string) {
    return this.api.post<any>(
      API_PATH.confirmEmail,
      {
        email,
        token,
        client: config.auth.clientId,
        redirect_url: config.auth.redirectUrlEmailConfirmed,
      },
    );
  }

  // eslint-disable-next-line max-len
  resendEmail(email: string, first_name: NullableString, last_name: NullableString, country_of_residence: NullableString) {
    return this.api.post<any>(
      API_PATH.resendEmail,
      {
        email,
        client: config.auth.clientId,
        return_url: config.auth.redirectUrlResendEmail,
        reset_link: config.auth.redirectUrlResetEmail,
        show_current_password: true,
        first_name,
        last_name,
        country_of_residence,
      },
    );
  }

  setToken(token: string) {
    return this.api.setHeader('Authorization', `Bearer ${token}`);
  }

  sendPhoneCode(userId: number, phone: string) {
    this.phone = phone;
    return this.api.post<any>(
      API_PATH.phoneNumberSendCode,
      {
        id: userId,
        phone_number: phone,
      },
    );
  }

  verifyPhoneNumber(userId: number, code: string) {
    return this.api.post<any>(
      API_PATH.phoneNumberVerify,
      {
        id: userId,
        phone_number: this.phone,
        code,
        redirect_url: config.auth.redirectUrlPhoneVerified,
        show_current_password: false,
      },
    );
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  getUserPermissions() {
    return this.api.get<any>(API_PATH.userPermissions);
  }

  async getTokenManually(
    username: string,
    token: string,
    client_id: '10nMobile' | '10nWeb',
    grant_type = 'sign_up_token',
  ) {
    const data = qs.stringify({
      client_id,
      username,
      token: decodeURIComponent(token),
      grant_type,
      scope: config.auth.scopes.join(' '),
    });
    return this.api.post(API_PATH.connectToken, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   */
  getAvailableClients() {
    return this.api.get<any>(API_PATH.getAvailableClients);
  }

  sendResetPassword(email: string, client: string, returnUrl: string) {
    return this.api.post(API_PATH.sendResetPassword, {
      email,
      client,
      return_url: returnUrl,
      show_current_password: true,
    });
  }

  sendNonAppropriatenessEmail(email: string, token: string) {
    return this.api.get(API_PATH.sendNonAppropriatenessEmail.replace('$1', email), {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  public getSignupToken(token: string) {
    this.setToken(token);
    return this.api.post<SignupTokenData>(API_PATH.signupToken);
  }
}
