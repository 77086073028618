import { FinancialQuestionnaireTypesEnum } from '../../enums';
import { ENROLL_METHOD } from '../../models/enroll';

export const INDIVIDUAL_EXTENDED_INFO_CALL_NAME = 'crm/individualExtendedInfo';


export const ENROLL_EXTENDED_INFO_TRIGGER_CALLS = [
  ENROLL_METHOD.postEnrollmentOwnPersonal,
  ENROLL_METHOD.putEnrollmentOwnPersonal,
  ENROLL_METHOD.postIndividualIdentityDetailOwn,
  ENROLL_METHOD.postIndividualIdentityDetailOwnWithFile,
  ENROLL_METHOD.putIndividualIdentityDetailOwn,
  ENROLL_METHOD.postIndividualAddressOwn,
  ENROLL_METHOD.putIndividualAddressOwn,
  ENROLL_METHOD.postIndividualTaxDetailOwnBatch,
  ENROLL_METHOD.putIndividualTaxDetailOwn,
  ENROLL_METHOD.deleteTaxDetail,
  ENROLL_METHOD.deleteIndividualAddress,
  ENROLL_METHOD.postIndividualDocumentOwn,
  ENROLL_METHOD.postDocumentOwnBinary,
  ENROLL_METHOD.putIndividualTransitionToForVerification,
  ENROLL_METHOD.putOwnFinancialQuestionnaire,
  ENROLL_METHOD.putIndividualSourceOfIncome,
  ENROLL_METHOD.putIndividualSourceOfFund,
  ENROLL_METHOD.postIndividualSourceOfFund,
  ENROLL_METHOD.putIndividualOwnPurposeOfTrading,
  ENROLL_METHOD.putIndividualOwnAnnualNetIncome,
  ENROLL_METHOD.putIndividualOwnEstimatedNetWorth,
  ENROLL_METHOD.putIndividualOwnEstimatedLiquidWorth,
  ENROLL_METHOD.postIndividualSourceOfIncome,
  ENROLL_METHOD.putIndividualTransitionToPendingDocuments,
  ENROLL_METHOD.putIndividualTransitionToPendingAcceptance,
  ENROLL_METHOD.putIndividualOwnLegalDeclarations,
  ENROLL_METHOD.getIndividualOwnActiveRiskAssessment,
];

const {
  UNSP, XANI, XENW, ELNW, TEWS, ATPY, AVPY, TKLP, AVFM, POWL, AITI,
} = FinancialQuestionnaireTypesEnum;

export const FINANCIAL_QUESTIONS_PURPOSE_AND_STATUS_FILTER: string[] = [ AITI, XANI, XENW ];
export const FINANCIAL_QUESTIONS_EXPERIENCE_FILTER: string[] = [ TEWS, ATPY, AVPY, TKLP, AVFM, POWL ];
