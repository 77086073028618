import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ModalBody } from 'reactstrap';

import { displayDateFormat } from '../../lib/constants/date-time.constants';
import { TimeInForceEnum } from '../../lib/enums';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { Order } from '../../lib/models/trading/types';
import { RootState } from '../../lib/store';
import { OpenPosition, PositionType } from '../../lib/store/reporting/types';
import TradePriceCache from '../../lib/store-util/TradePriceCache';
import { SHORT_SELL_DAILY_INTEREST_FEE } from '../../lib/util/constants';
import { formatCurrency } from '../../lib/util/DataHelpers';
import { getDateTime } from '../../lib/util/DateTimeHelpers';
import { TextHelpers } from '../../lib/util/TextHelpers';
import { getOrderTotalValue } from '../../lib/util/TradingValidationsHelper';

import exclamationMark from '../../assets/img/icon-exclamation-mark-warning.svg';
import BaseModal from '../BaseModal/BaseModal';
import Button from '../Button/Button';
import { ButtonTypesEnum } from '../Button/Button.types';
import SymbolWithLogo from '../SymbolWithLogo/SymbolWithLogo';

import styles from './ReviewOrder.module.scss';

type OwnProps = {
  order: Order;
  symbol: string;
  isBuyOrder: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

type Props = OwnProps & ReduxProps;

function ReviewOrder(props: Props) {
  const {
    order,
    symbol,
    positions,
    companyLogoAndName,
    isBuyOrder,
    onSubmit,
    onCancel,
  } = props;

  const orderTimeInForceTypes = [
    TimeInForceEnum.GTD,
    TimeInForceEnum.EndOfDay,
    TimeInForceEnum.GTC,
    TimeInForceEnum.ExtendedHours,
  ] as string[];

  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'ReviewOrder');
  const orderSide = order.side?.toLowerCase();
  const orderType = TextHelpers.getOrderTypeName(order.ordType as string);
  const orderTypeName = `${orderType} ${orderSide} ${lang.commonTradingOrder()}`;
  const logo = companyLogoAndName[symbol]?.logo;
  const companyName = companyLogoAndName[symbol]?.name;
  const positionsBySymbol = positions.find((position: OpenPosition) => (position.symbol === symbol));

  const orderTimeInForce = orderTimeInForceTypes.includes(order.timeInForce ?? '')
    ? TextHelpers.getTimeInForceName(order.timeInForce ?? '')
    : getDateTime(true, ['format'], [ [ displayDateFormat ] ], order.timeInForce);

  const orderPriceName = (order.ordType === OrderTypeEnum.Limit)
    ? lang.mobileTradingTicketLimitPrice()
    : lang.mobileTradingTicketStopPrice();

  const orderValue = getOrderTotalValue(
    order.ordType!,
    order.orderQty!,
    tradePrice ?? 0,
    (order.ordType! === OrderTypeEnum.Stop) ? order.stopPrice! : order.price!,
  );

  return (
    <BaseModal
      isOpen
      className={styles.modalWrapper}
    >
      <ModalBody>
        <div className={styles.content}>
          <SymbolWithLogo
            logo={logo}
            symbol={symbol}
            symbolCompany={companyName}
            className={styles.symbolDetails}
          />
          <div className={styles.container}>
            <p className={styles.key}>
              {lang.commonTradingOrderType()}
            </p>
            <p className={styles.value}>
              {orderTypeName}
            </p>
          </div>
          <div className={styles.container}>
            <p className={styles.key}>
              {lang.mobileTradingTicketNumberOfShares()}
            </p>
            <p className={styles.value}>
              {order.orderQty}
            </p>
          </div>
          <div className={styles.container}>
            <p className={styles.key}>
              {lang.mobileTradingTicketMarketPrice()}
            </p>
            <p className={styles.value}>
              {formatCurrency(tradePrice)}
            </p>
          </div>
          {(order.ordType !== OrderTypeEnum.Market) && (
          <>
            <div className={styles.container}>
              <p className={styles.whiteText}>
                {orderPriceName}
              </p>
              <p className={styles.value}>
                {formatCurrency((order.ordType === OrderTypeEnum.Limit) ? order.price : order.stopPrice)}
              </p>
            </div>
            <div className={styles.container}>
              <p className={styles.key}>
                {lang.commonTradingExpiryDate()}
              </p>
              <p className={styles.value}>
                {orderTimeInForce}
              </p>
            </div>
            <div className={styles.container}>
              <p className={styles.key}>
                {lang.commonTradingExtendedHoursTrade()}
              </p>
              <p className={styles.value}>{order.includeExtendedHours ? lang.commonYes() : lang.commonNo()}</p>
            </div>
          </>
          )}
          <hr />
          <div className={[styles.container, styles.totalValue].join(' ')}>
            <p className={styles.key}>
              {lang.commonTradingOrderValue()}
            </p>
            <p className={styles.value}>
              {order.orderQty && formatCurrency(orderValue)}
            </p>
          </div>
        </div>
        {
          (!positionsBySymbol || positionsBySymbol.positionType === PositionType.Sell) && !isBuyOrder
          && (
          <div className={styles.orderSellDescription}>
            <img id="marketStatusDescriptionAlertIcon" src={exclamationMark} alt="exclamationMark icon" />
            <span>{lang.commonRegularTradingShortSellDescription(SHORT_SELL_DAILY_INTEREST_FEE)}</span>
          </div>
          )
        }
        <div className={styles.actions}>
          <Button
            variant={ButtonTypesEnum.Tertiary}
            fullWidth
            onClick={onCancel}
            className={styles.btnCancel}
          >
            {lang.commonCancelButton()}
          </Button>
          <Button fullWidth onClick={onSubmit}>
            {lang.commonConfirmOrderButton()}
          </Button>
        </div>
      </ModalBody>
    </BaseModal>
  );
}

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  companyLogoAndName: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ReviewOrder);
