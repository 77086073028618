import { OidcClient, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import CommonAuthService from '../lib/services/CommonAuth.service';
import { IAuthService } from '../lib/services/types';

import mainConfig from '../config';

interface AuthResult {
  accessToken: string,
  refreshToken: string,
  access_token: string,
  refresh_token: string,
  rawState: any,
}

export default class AuthService extends CommonAuthService implements IAuthService {
  userManager: InstanceType<typeof UserManager>;

  oidcClient: InstanceType<typeof OidcClient>;

  constructor() {
    super();

    const { issuer, clientId, scopes, loginRedirectUrl } = mainConfig.auth;

    const settings: UserManagerSettings = {
      authority: issuer,
      client_id: clientId,
      response_type: 'code',
      response_mode: 'query',
      redirect_uri: loginRedirectUrl,
      scope: scopes.join(' '),
      automaticSilentRenew: false,
      userStore: new WebStorageStateStore({ store: localStorage }),
      accessTokenExpiringNotificationTimeInSeconds: 3,
      silentRequestTimeoutInSeconds: 5,
    };

    this.oidcClient = new OidcClient(settings);
    this.userManager = new UserManager(settings);
  }

  getUserManager = () => this.userManager;

  private createPromiseResult(method: string, ...params: any[]) {
    return new Promise((success: any, fail: any) => {
      withCallbacks(this.getUserManager(), method,
        (result: AuthResult) => {
          if (result != null) {
            if (result.access_token != null) {
              result.rawState = { ...result };
              result.accessToken = result.access_token;
              result.refreshToken = result.refresh_token;
            }
            success(result);
          } else {
            fail(new Error('Failed to get result!'));
          }
        },
        (error: any) => {
          fail(error);
        },
        ...params);
    });
  }

  async authorize(key: string) {
    switch (key) {
      case 'login':
        return this.createPromiseResult('signinRedirect', { prompt: 'login' });
      case 'callback':
        return this.createPromiseResult('signinCallback');
      default:
        throw new Error(`AuthService: Invalid key: ${key}`);
    }
  }

  refresh = () => this.getUserManager().signinSilent();
}

function withCallbacks(userManager: any, name: string, callback: any, errorCallback: any, ...params: any) {
  return userManager[name](...params)
    .then((result: any) => callback(result))
    .catch((error: any) => errorCallback(error));
}
