import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history'; // eslint-disable-line

import lang from '../../lib/language';
import { startAuthRefresh as serviceStartAuthRefresh } from '../../lib/store/auth';
import { login as serviceLogin, logout as serviceLogout } from '../../lib/store/common-actions';

import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import HammerLogo from '../../assets/img/hammer_symbol_logo.svg';
import SearchIron from '../../assets/img/icon-search_24px.svg';
import SearchIcon from '../../assets/img/icon-search_white.svg';
import { MOCK } from '../../configDebug';
import { useIsMobile } from '../../hooks';
import { getTabName } from '../../util/helpers';
import { UI_ROUTES } from '../../util/routes';
import { ClickableImage } from '../index';

import HeaderDropdown from './HeaderDropdown/HeaderDropdown';
import { LogoutActionSelector, RefreshActionSelector } from './HeaderConstants';
import TestHeader from './TestHeader';

import styles from './Header.module.scss';

type OwnProps = {
  isSearchActive: (val: boolean) => void
};
type Props = OwnProps & ReduxProps;

const Header = (props: Props): JSX.Element => (
  <>
    {MOCK.TEST_HEADER && <TestHeader {...props} />}
    <HeaderOriginal {...props} />
  </>
);

const HeaderOriginal = ({ startAuthRefresh, logout, login, isSearchActive }: Props) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const history = useHistory();

  const isShowSearchIcon = [
    UI_ROUTES.earningsCalendar,
    UI_ROUTES.overview,
    UI_ROUTES.discover,
    UI_ROUTES.myAccount,
  ].includes(location.pathname);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleLogout = () => {
    logout();
    login(history);
  };

  const returnBtn = () => (
    <button type="button" className={styles.returnBtn} onClick={handleGoBack}>
      <img src={ArrowBack} alt="Back" />
    </button>
  );

  const onSearchClickWeb = (): void => {
    isSearchActive(true);
  };

  const onSearchClickMob = () => {
    history.push(`${UI_ROUTES.searchSymbolsMob}`);
  };

  return (
    <>
      {isMobile ? (
        <div className={styles.containerMobile}>
          <div className={styles.returnBtnMobile}>
            {getTabName(location) === 0 ? returnBtn() : getTabName(location)}
          </div>
          {isShowSearchIcon && (
          <div className={styles.buttonsMobile}>
            <ClickableImage className={styles.searchBtnMobile} src={SearchIron} alt="search" onClick={onSearchClickMob} />
            <div className={styles.notificationsMobile}>
              <HeaderDropdown isUserProfile={false} />
            </div>
          </div>
          )}
        </div>

      ) : (
        <>
          <div className={styles.logoWrapper}>
            <div className={styles.logo}>
              <img src={HammerLogo} alt="Hammer" />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.contentWrapper}>
              <div className={styles.headerElements}>
                <div className={styles.headerProfileInfo}>
                  <div className={styles.settings}>
                    <HeaderDropdown isUserProfile={false} />
                  </div>
                  <div className={styles.userData}>
                    <HeaderDropdown
                      isUserProfile
                      actions={{
                        [LogoutActionSelector]: handleLogout,
                        [RefreshActionSelector]: startAuthRefresh,
                      }}
                    />
                  </div>
                </div>
                <div className={styles.searchStocksWrapper}>
                  <img src={SearchIcon} alt="search stocks" />
                  <input type="text" readOnly placeholder={lang.commonSearchForStocks()} onClick={onSearchClickWeb} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  dispatch,
  startAuthRefresh: () => dispatch(serviceStartAuthRefresh()),
  logout: () => dispatch(serviceLogout()),
  login: (history: History) => { dispatch(serviceLogin('login')); },
});

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connect(null, mapDispatchToProps)(Header);
