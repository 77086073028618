import {
  isArray, isBoolean, isObjectLike, isString,
} from 'lodash';
import { isDate, isMoment, Moment } from 'moment-timezone';

import {
  calendarPickerRangeFormat,
  CURRENT_YEAR,
  displayDateFormat,
  displayDateFormat_HH_MM,
  displayDateFormat_MMM_DD,
  ELAPSED_TIME_INVALID,
  LOCAL_DATE,
  MARKET_DATA_DATE,
  MARKET_DATA_TIME_AND_DATE,
  MARKET_DATA_TIME_AND_DATE_WITH_TZ,
  MARKET_DATA_TIME_AND_DATE_WITH_TZ2,
  MARKET_DATA_TIME_AND_DATE_WITH_TZ3,
  MARKET_DATA_TIME_AND_DATE2,
  MARKET_DATA_TIME_ZONE,
  MARKET_DATA_TIME_ZONE_SHORT_DST,
  UTC_MOMENT_FORMAT,
  utcISOTruncated,
} from '../constants/date-time.constants';
import marketHours from '../constants/marketHours';
import lang from '../language';
import { MarketStateInstrumentSnapshot } from '../models/market-data/types';
import { ChartDataRange } from '../store/market-data/charting/types';

import { isNumber } from './DataHelpers';
import {
  INVALID_DATE_STRING,
  INVALID_TIME,
  INVALID_VALUE,
  INVALID_YEAR,
  MarketDataWorkingHours,
  MarketDataWorkingHoursAsNumber,
  NullableNumber,
  NullableString,
} from './types';

export enum ParseToType {
  utcForBackend,
  ForBackendDateOnly,
  dateObject,
  displayFormat
}
export enum FallbackToType {
  inputValue,
  todaysDate,
  invalidDateText
}

const {
  IS_MarketClosedNextDay,
  IS_PreMarket,
  IS_RegularHours,
  IS_AfterMarket,
  IS_MarketClosed,
} = MarketStateInstrumentSnapshot;

const isValidDate = (value: Date | Moment | NullableString) => {
  if (!value || isBoolean(value)) return false;

  if (value instanceof Date && !isNaN(value.getTime())) {
    return true;
  }

  if (isMoment(value) && value.isValid()) {
    return true;
  }

  // otherwise the date is string
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const momentValue = isMoment(value) ? value : momenttz(
    value,
    [
      utcISOTruncated,
      displayDateFormat,
      UTC_MOMENT_FORMAT,
      MARKET_DATA_TIME_AND_DATE,
      MARKET_DATA_TIME_AND_DATE_WITH_TZ,
      MARKET_DATA_TIME_AND_DATE_WITH_TZ2,
      MARKET_DATA_TIME_AND_DATE_WITH_TZ3,
      MARKET_DATA_TIME_AND_DATE2,
    ],
    true,
  );

  return momentValue.isValid();
};

export const isValidDateForYear = (value: string | number | null | undefined) => {
  if (value == null) return false;
  if (value === INVALID_YEAR) return false;
  if (value === INVALID_VALUE) return false;
  if (value === Number.POSITIVE_INFINITY) return false;
  if (value === Number.NEGATIVE_INFINITY) return false;
  if (isObjectLike(value)) return false;
  if (isBoolean(value)) return false;
  if (isArray(value)) return false;
  if (isNumber(value) && value <= 0) return false;
  if (!`${value}`.match(/^[1-9][0-9]{3}/)) return false;

  return true;
};

export const isValidYear = (value: string | number | null | undefined) => (
  value !== INVALID_YEAR
  && value !== INVALID_VALUE
  && value !== Number.POSITIVE_INFINITY
  && value !== Number.NEGATIVE_INFINITY
  && value
  && Number(value)
  && Number(value) > 0
  && (isString(value) || isNumber(value))
  && !isObjectLike(value)
);

const determineFallback = (
  value: string | Date | null,
  parseTo: ParseToType,
  fallBackTo: FallbackToType | string,
): string | Date => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  if (typeof fallBackTo === 'string') {
    if (isValidDate(fallBackTo)) {
      return determineReturnValue(fallBackTo, parseTo);
    }
    return 'invalid date && fallback date';
  }

  if (fallBackTo === FallbackToType.inputValue && value) {
    return value;
  } if (fallBackTo === FallbackToType.todaysDate) {
    return determineReturnValue(momenttz.utc().format(utcISOTruncated), parseTo);
  }
  return 'invalid date';
};

const determineReturnValue = (
  value: string | Date | null,
  parseTo: ParseToType,
): string | Date => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  if (parseTo === ParseToType.utcForBackend) {
    return momenttz.utc(value, [ utcISOTruncated, displayDateFormat, UTC_MOMENT_FORMAT ]).format(utcISOTruncated);
  } if (parseTo === ParseToType.ForBackendDateOnly) {
    return momenttz.utc(value, [ utcISOTruncated, displayDateFormat, UTC_MOMENT_FORMAT ]).format(UTC_MOMENT_FORMAT);
  } if (parseTo === ParseToType.displayFormat) {
    return momenttz.utc(value, [ utcISOTruncated, displayDateFormat, UTC_MOMENT_FORMAT ]).format(displayDateFormat);
  }
  return momenttz.utc(value, [ utcISOTruncated, displayDateFormat, UTC_MOMENT_FORMAT ]).toDate();
};

/**
 *
 * @param value string | Date | null
 * Value to be converted. It accepts all formats we use: Date object, displayDateFormat, utcISOTruncated
 *
 * @param parseTo ParseToType
 * Value can be parsed to any format part of our suite as above.
 *
 * @param fallBackTo FallbackToType | string
 * When Value is invalid it returns this parameter.
 * String value will return itself with respect to the parseTo type
 *
 * @returns
 * 1. Valid string formated value
 * 2. Valid Date object
 * 3. When Value is invalid returns fallBack with respect to its type or if not specified, it uses its default.
 */
export const parseDateTo = (
  value: string | Date | null,
  parseTo: ParseToType = ParseToType.dateObject,
  fallBackTo: FallbackToType | string = FallbackToType.todaysDate,
): string | Date => {
  if (!isValidDate(value)) {
    return determineFallback(value, parseTo, fallBackTo);
  }
  return determineReturnValue(value, parseTo);
};


/**
 * Returns a formatted string of date time value  of now or `customDate` if given.
 * Using `utcISOTruncated` to format as string or a custom format if `format` operation given.
 * @param isUtc If `true` - using time in UTC. If no customDate - current time is in UTC, if customDate present - parsed as UTC.
 * @param operations A list of operations to apply on now date and time
 * @param operationArgs A list of arguments per operation from `operations` list - currently based on momenttz
 *        (see the switch in forEach or the DataHelpers.test.ts file for example usage)
 * @param customDate Custom date to use instead of now
 */
export function getDateTime(
  isUtc = true, // eslint-disable-line default-param-last
  operations: ('minus' | 'plus' | 'startOf' | 'endOf' | 'format' | 'unix' | 'tz' | 'local')[] = [], // eslint-disable-line default-param-last
  operationArgs: any[] = [], // eslint-disable-line default-param-last
  customDate?: Moment | Date | NullableString,
) {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  let theFormat = utcISOTruncated;
  let result: Moment = momenttz();
  if (customDate) {
    result = isUtc ? momenttz.utc(customDate) : momenttz(customDate);
  } else if (isUtc) {
    result = result.utc();
  }

  if (operations != null) {
    operations
      .forEach((item, index) => {
        const args = operationArgs![index];
        if (args == null) {
          throw new Error(`[DataHelpers] getDateTime - argument at index ${index} not found.`);
        }

        switch (item) {
          case 'minus':
            result.subtract(...args);
            break;

          case 'plus':
            result.add(...args);
            break;

          case 'startOf':
            result.startOf(args[0]);
            break;

          case 'endOf':
            result.endOf(args[0]);
            break;

          case 'format':
            theFormat = args[0];
            break;

          case 'unix':
            theFormat = 'x'; // x is for milliseconds, X - for seconds (reference: https://momentjs.com/docs/#/displaying/)
            break;

          case 'tz':
            result = result.tz(args[0]);
            break;

          case 'local':
            result = result.local();
            break;

          default:
            throw new Error(`[DataHelpers] getDateTime - unknown operation '${item}'.`);
        }
      });
  }

  return result.format(theFormat);
}

/**
 *
 * @param timeInSec Time in seconds. Two ways to generate - moment().unix() or Math.trunc(new Date().getTime() / 1000)
 * @param nowInSec If custom now is needed - value must be in seconds as well.
 */
export function calculateElapsedTime(
  timeInSec: NullableNumber,
  nowInSec?: NullableNumber,
  ZERO: NullableString = ELAPSED_TIME_INVALID,
  INVALID: NullableString = ELAPSED_TIME_INVALID,
) {
  if (!isNumber(timeInSec) || timeInSec! < 0) return INVALID ?? ELAPSED_TIME_INVALID;

  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const current = nowInSec == null ? momenttz().unix() : nowInSec;
  const diff = (timeInSec! - current);
  if (diff < 0) return INVALID ?? ELAPSED_TIME_INVALID;
  if (diff === 0) return ZERO ?? ELAPSED_TIME_INVALID;

  const duration = momenttz.duration(diff, 'seconds');
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();
  const asDays = duration.asDays();

  const f = (n: number) => n.toString().padStart(2, '0');
  if (asDays > 1) return `${days} day${days > 1 ? 's' : ''}`;

  let result = `${f(minutes)}:${f(seconds)}`;
  if (asDays === 1) result = `${f(duration.asHours())}:${result}`;
  else if (hours > 0) result = `${f(hours)}:${result}`;
  return result;
}

//  *** Date section ***
// TODO: Remove these after synced [WEB] with DateTimeHelpers/parseDateTo ART-942

/**
 *
 * @param value In the format 'DD MM, YYYY'
 */
export function parseDateToUTC(value: string) {
  return parseDateTo(value, ParseToType.utcForBackend);
}

/**
 *
 * @param date Date()
 * Converts Date object to our date display format.
 * It accepts both 'YYYY-MM-DDT00:00:00' and 'YYYY-MM-DDT00:00.000Z' as either Date | string
 */
export const convertDateToFormatedString = (
  date: Date | string | null,
  fallbackValue?: string,
): string => parseDateTo(
  date,
  ParseToType.displayFormat,
  fallbackValue || FallbackToType.todaysDate,
) as string;

/**
 * Get previous trading day from now or based on date.
 */
export const getPreviousWorkday = (date?: string | Moment): Moment | null => {
  let current;
  if (isMoment(date)) current = date;
  else {
    const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
    current = (
      date
        ? momenttz.tz(date, MARKET_DATA_TIME_ZONE)
        : momenttz.tz(MARKET_DATA_TIME_ZONE)
    );
  }

  if (!current.isValid()) {
    console.error(`[DateTimeHelpers] calculateTimeToNextMarketState - invalid Date - '${date}'`);
    return null;
  }

  do {
    current.subtract(1, 'day');
  } while (!isDateMarketDataWorkingDay(current.format('YYYY-MM-DD')));

  return current;
};

/**
 * Get next trading day from now or based on date.
 */
export const getNextWorkday = (date?: NullableString): Moment | null => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup

  let current = (
    date
      ? momenttz.tz(date, MARKET_DATA_TIME_ZONE)
      : momenttz.tz(MARKET_DATA_TIME_ZONE)
  );

  if (!current.isValid()) {
    console.error(`[DateTimeHelpers] calculateTimeToNextMarketState - invalid Date - '${date}'`);
    return null;
  }

  do {
    current.add(1, 'day');
  } while (!isDateMarketDataWorkingDay(current.format('YYYY-MM-DD')));

  return current;
};

export function datesDifference(
  from: string,
  to: string,
  difference: 'days' | 'minutes' | 'seconds' | 'weeks' | 'months' | 'years' = 'days',
) {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const a = momenttz(from);
  const b = momenttz(to);

  return b.diff(a, difference);
}

/**
 * Calculates diff in seconds, milliseconds etc. to next market states - pre market and regular - when applicable.
 * It considers the current market state. If regular hours - skips calculations and returns nulls for diffs.
 * @param dateInUTC Custom time if needed - in UTC
 * @param diffType The unit type that the diff will be presented as - default is 'seconds'.
 * @param asFloat Default is 'false' if set to 'true' returns as floats, for example 1.6 if diffType is `diffType` and diff value is 1.6 seconds
 */
export function calculateTimeToNextMarketState(
  dateInUTC?: NullableString,
  diffType: 's' | 'ms' | 'h' | 'd' | 'milliseconds' | 'seconds' | 'hours' | 'days' = 'seconds',
  asFloat = false,
) {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const { calculateMarketState, convertToMarketState } = require('./MarketDataHelpers');

  let now = (
    dateInUTC
      ? momenttz.tz(dateInUTC, MARKET_DATA_TIME_ZONE)
      : momenttz.tz(MARKET_DATA_TIME_ZONE)
  );

  if (!now.isValid()) {
    console.error(`[DateTimeHelpers] calculateTimeToNextMarketState - invalid Date - '${dateInUTC}'`);
    return {
      nextPreMarketMoment: null,
      nextRegularMoment: null,
      marketState: null,
    };
  }

  const { preMarket, open } = marketHours.workingTime;
  const dateTimeInUTC = now.utc().toISOString();
  const instrumentSnapshotMarketState = calculateMarketState(dateTimeInUTC);

  let nextPreMarketMoment: Moment | null | undefined = null;
  let nextRegularMoment: Moment | null | undefined = null;

  switch (instrumentSnapshotMarketState) {
    case IS_MarketClosedNextDay: {
      const dataDay = isDateMarketDataWorkingDay(now.utc(), false) ? now : getNextWorkday(now.utc());
      // pre market
      nextPreMarketMoment = dataDay.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(preMarket));
      // regular
      nextRegularMoment = dataDay.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(open));
      break;
    }
    case IS_PreMarket:
      // pre market
      // Pre market timer calculation is not needed in this state since all cases use `nextRegularMoment` (market orders message timer, short selling popup timer etc.).
      // regular
      nextRegularMoment = now.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(open));
      break;
    case IS_RegularHours:
      // > No timer calculation is needed in this state since all orders are possible.
      break;
    case IS_AfterMarket: {
      const nextWorkingDayMoment = getNextWorkday();
      // pre market
      // Pre market timer calculation is not needed in this state since the only valid case here (short selling popup timer) uses `nextRegularMoment`.
      // regular
      nextRegularMoment = (
        nextWorkingDayMoment?.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(open))
      );
      break;
    }
    case IS_MarketClosed: {
      const nextWorkingDayMoment = getNextWorkday();
      // pre market
      nextPreMarketMoment = (
        nextWorkingDayMoment?.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(preMarket))
      );
      // regular
      nextRegularMoment = (
        nextWorkingDayMoment?.clone().tz(MARKET_DATA_TIME_ZONE).set(timeAsFloatToMomentSetterParam(open))
      );
      break;
    }
    default:
      break;
  }
  const nextPreMarket = nextPreMarketMoment?.diff(now, diffType, asFloat) ?? null;
  const nextRegular = nextRegularMoment?.diff(now, diffType, asFloat) ?? null;

  return { nextPreMarket, nextRegular, marketState: convertToMarketState(instrumentSnapshotMarketState) };
}

/**
 * Uses `momenttz-timezone` to check the NY time zone and
 * return whether it has DST enabled.
 * All data used by `momenttz-timezone` is kept unpacked here:
 *   https://github.com/momenttz/momenttz-timezone/tree/develop/data/unpacked
 *
 * @dateInUtc Date in UTC time. Accepted string formats: 'YYYY-MM-DD HH:mm'
 */
export function isMarketDataDSTime(dateInUtc: Date | Moment | string): boolean | null {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  if (!isValidDate(dateInUtc)) {
    console.error(`[DateTimeHelpers] isMarketDataDSTime - invalid input date '${dateInUtc}' (${typeof dateInUtc})`);
    return null;
  }
  const theDate = (isMoment(dateInUtc) ? dateInUtc : momenttz.utc(dateInUtc)).tz(MARKET_DATA_TIME_ZONE);
  const res = theDate.format('z');

  return res === MARKET_DATA_TIME_ZONE_SHORT_DST;
}

/**
 * Formats input data:
 *  - converts to string if `asStrings` is `true`.
 *  - converts to UTC if `inUTC` is `true` and `dateAsString` is provided.
 * @param data The working hours data to format `{ startTime: number, endTime: number }`
 * @param asStrings If `true` - return result is as strings for example `{ startTime: '13:30', endTime: '20:00' }`
 * @param inUTC If `true` - times are converted to UTC and `dateAsString` is needed in this case.
 * @param dateAsString If `inUTC` then `dateAsString` is used to convert to `UTC` time using difference to utc.
 */
function formatWorkingHours(
  data: MarketDataWorkingHoursAsNumber,
  asStrings = false, // eslint-disable-line default-param-last
  inUTC?: boolean | null,
  dateAsString?: NullableString,
): MarketDataWorkingHours {
  let { startTime, endTime, endAfter } = data;
  if (inUTC) {
    if (!dateAsString) {
      console.error(`[DateTimeHelpers] formatWorkingHours - 'inUTC' is 'true' but 'dateAsString' is not provided - ${dateAsString}`);
    } else {
      const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
      const asMoment = momenttz.tz(dateAsString, MARKET_DATA_TIME_ZONE);
      if (!asMoment) {
        console.error(`[DateTimeHelpers] formatWorkingHours - 'inUTC' is 'true' but 'dateAsString' is not parseable by moment - ${dateAsString}`);
      } else {
        const tz = asMoment.format('Z');
        const diffInHours = (-1) * timeToFloat(tz.substr(0, 6))!;
        startTime += diffInHours;
        endTime += diffInHours;
        if (endAfter != null) endAfter += diffInHours;
      }
    }
  }

  /**
   * Since market ends at 20:00 NY time - this is either UTC-4 (no daylight saving time DST) or UTC-5 (with DST).
   * For UTC-4 - this code will convert 24:00 to 00:00
   * And for UTC-5 - this code will convert 25:00 to 01:00
   */
  if (endTime >= 24) endTime -= 24;
  if (endAfter != null && endAfter >= 24) endAfter -= 24;

  if (asStrings) {
    return {
      startTime: timeAsFloatToString(startTime!),
      endTime: timeAsFloatToString(endTime!),
      endAfter: endAfter != null ? timeAsFloatToString(endAfter) : null,
    };
  }

  return { startTime, endTime, endAfter };
}

export const timezoneDiffUTCMarketHours = (): number => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const now = momenttz.tz(MARKET_DATA_TIME_ZONE);
  const tz = now.format('Z');
  const timeDiff = (-1) * timeToFloat(tz.substr(0, 6))!;
  return timeDiff;
};

/**
 * Returns working hours for Market Data also checking for DST
 * @param date The input date to check in UTC time or NY time if inUTC is false. Accepted string formats: 'YYYY-MM-DD HH:mm'
 * @param asStrings If `true` - return result is as strings
 * @param inUTC If `true` - return and input result is in New York time (DST aware)
 * @returns Time as float (examples: 12:30 -> 12.3, 9.50 -> 9.5 etc.) or string (examples: '12:30', '13:10' etc.)
 */
export function getMarketWorkingHours(
  date: Date | Moment | string,
  asStrings = false,
  inUTC = true,
): MarketDataWorkingHours {
  const errorResult = { startTime: INVALID_TIME, endTime: INVALID_TIME };
  if (!isValidDate(date)) {
    console.error(`[DateTimeHelpers] getMarketWorkingHours - invalid input date '${date}' (${typeof date})`);
    return formatWorkingHours(errorResult, asStrings);
  }

  const now = parseDateForWorkingHours(date);
  if (isDateMarketSaturdayOrSunday(now)) return formatWorkingHours(errorResult, asStrings);

  const {
    halfDays, holidays,
    workingTime: {
      open, close, closeHalfDay, afterMarketEnd,
    },
  } = marketHours;

  const theDate = now.substr(0, 10);
  const isHalfDay = (halfDays as any)[theDate];
  const isHoliday = (holidays as any)[theDate];

  if (isHoliday) return formatWorkingHours({ startTime: INVALID_TIME, endTime: INVALID_TIME }, asStrings, inUTC, now);
  if (isHalfDay) return formatWorkingHours({ startTime: open, endTime: closeHalfDay }, asStrings, inUTC, now);

  return formatWorkingHours({ startTime: open, endTime: close, endAfter: afterMarketEnd }, asStrings, inUTC, now);
}

/**
 * @param date The date to parse
 * @param type If `ny` - NY time used
 */
function parseDateForWorkingHours(date: NullableString | Date | Moment) {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  if (!date) return INVALID_DATE_STRING;

  if (isMoment(date)) return date.format();
  if (isDate(date)) return momenttz(date).format();
  if (isString(date)) {
    if (date.length < 20) { // no TZ in date string
      return momenttz.tz(date.substr(0, 10), MARKET_DATA_TIME_ZONE).format();
    }

    return momenttz(date).format();
  }

  return INVALID_DATE_STRING;
}

/**
 * Checks if the day is a working or a holiday
 * Ignores time by default
 */
export function isDateMarketDataWorkingDay(dateInUtc: Date | Moment | string, ignoreTime = true): boolean | null {
  if (!isValidDate(dateInUtc)) {
    console.error(`[DateTimeHelpers] isDateMarketDataWorkingDay - invalid input date '${dateInUtc}' (${typeof dateInUtc})`);
    return null;
  }

  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const { holidays } = marketHours;
  const theDate = (
    ignoreTime
      ? parseDateForWorkingHours(dateInUtc)
      : momenttz.utc(dateInUtc).tz(MARKET_DATA_TIME_ZONE).format()
  );
  const asString = theDate.substr(0, 10);
  const isHoliday = (holidays as any)[asString];
  const marketDataDate = momenttz.tz(asString, MARKET_DATA_TIME_ZONE);
  const isWeekend = isDateMarketSaturdayOrSunday(marketDataDate);

  return !isHoliday && !isWeekend;
}

/**
 * Returns boolean if date is valid - `null` otherwise.
 * @param dateInNYTime The date to check - in NY Time considering DST with moment-timezone
 * @returns `true` if it is Saturday (weekday = 6) or Sunday (weekday = 0)
 */
export function isDateMarketSaturdayOrSunday(dateInNYTime: Moment | NullableString) {
  if (!isValidDate(dateInNYTime)) {
    console.error(`[DateTimeHelpers] isDateSaturdayOrSunday - invalid input date '${dateInNYTime}' (${typeof dateInNYTime})`);
    return null;
  }
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const date = (
    isMoment(dateInNYTime)
      ? dateInNYTime
      : momenttz.tz((dateInNYTime as string).substr(0, 10), MARKET_DATA_TIME_ZONE)
  );
  return [ 0, 6 ].includes(date.weekday());
}


// TODO: Rename to timeAsFakeFloat or mark otherwise tha it is using fake float conversion (for example 14:30 becomes 14.3 not 14.5)
export function timeToFloat(time: string | Moment | Date): NullableNumber {
  if (isString(time)) {
    let buff = time.replace(/[.]*/, '');
    if ((buff.match(/[:]/g) ?? []).length > 1) {
      buff = buff.substring(0, buff.lastIndexOf(':'));
    }
    buff = buff.replace(/:00/, '');
    buff = buff.replace(':', '.');
    return Number(buff);
  }

  if (isMoment(time)) {
    return time.hour() + time.minute() / 100;
  }

  if (isDate(time)) {
    return time.getUTCHours() + time.getUTCMinutes() / 100;
  }

  return null;
}

export function timeAsFloatToString(time: number) {
  if (time === INVALID_TIME) return INVALID_VALUE;
  const asArr = time.toString().split('.');
  return `${asArr[0].padStart(2, '0')}:${(asArr[1] ?? '00').padEnd(2, '0')}`;
}

/**
 * Returns an object to use on moment.set(object) to reset it to a certain time.
 * @param timeAsFloat Float value for hours and minutes in a 'fake float' format, for example - 12.3 (meaning 12:30), 11.45 (meaning 11:45)
 */
export function timeAsFloatToMomentSetterParam(timeAsFloat: number) {
  return {
    hour: Math.trunc(timeAsFloat),
    minute: Math.round(100 * (timeAsFloat % 1)),
    second: 0,
    ms: 0,
  };
}

/**
 * Used for preparing year data for sending to server.
 * Please use `dateToYear` to read
 * @param year The year to convert as a string or number (for example 2020, '2020' etc.)
 * @returns An empty string if input is not a string or number
 */
export const yearToDate = (year: NullableString | number): string => {
  if ((isString(year) && year !== '') || isNumber(year)) {
    const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
    let res = momenttz(`${year}`);
    return (
      res.isValid()
        ? res.startOf('year').format(utcISOTruncated)
        : ''
    );
  }

  return '';
};

/**
 * Used for preparing year data for reading from server.
 * Please use `yearToDate` to read
 * @param date Date string YYYY-MM-DD[T]HH:mm or year as number
 * @returns Year as number
 */
export const dateToYear = (date: string | number | null | undefined): number => {
  if (!isValidDateForYear(date)) return INVALID_YEAR;
  if (isString(date)) return Number(date.substr(0, 4));
  if (isNumber(date)) return Number(date);

  return INVALID_YEAR;
};

export const dateToMonth = (date: string | number | null | undefined): number => {
  if (!isValidDateForYear(date)) return INVALID_YEAR;
  if (isString(date)) return Number(date.substr(5, 2));
  if (isNumber(date)) return Number(date);

  return INVALID_YEAR;
};

export const yearToString = (year?: number | string | null): string => {
  if (!isValidYear(year)) return '';

  let result = isString(year) ? parseInt(year as any) : year;
  return (!isNumber(result) || result! <= 0) ? '' : result!.toString();
};

export const checkChartRangeHasTime = (range: ChartDataRange) => [ '1D', '1W', '1M' ].includes(range);
export const formatChartingDateTime = (dateAndTime: string, range: ChartDataRange, tz: 'local' | 'new-york' | 'utc') => {
  if (!isValidDate(dateAndTime)) return INVALID_DATE_STRING;

  const hasTime = checkChartRangeHasTime(range!);
  let format = MARKET_DATA_DATE;
  if (hasTime) {
    if (tz === 'utc') {
      format = MARKET_DATA_TIME_AND_DATE;
    } else {
      format = MARKET_DATA_TIME_AND_DATE;
    }
  }

  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  if (!hasTime) return momenttz.utc(dateAndTime).format(format);

  switch (tz) {
    case 'local':
      return momenttz(dateAndTime!).format(format);
    case 'new-york':
      return momenttz.utc(dateAndTime!).tz(MARKET_DATA_TIME_ZONE).format(format);
    default:
      return momenttz.utc(dateAndTime!).format(format);
  }
};

export const formatNetComponentsDate = (dateAndTime: string) => {
  if (!isValidDate(dateAndTime)) return INVALID_DATE_STRING;
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  return momenttz(dateAndTime).format(calendarPickerRangeFormat);
};

/**
 * Does not take TZ into account
 */
export function isDateEqual(dateOne: NullableString, dateTwo: NullableString) {
  return dateOne?.substr(0, 10) === dateTwo?.substr(0, 10);
}

export const findLatestDate = (dates: string[]): string => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  const latestDateTimeStamp = Math.max(...dates.map(e => +momenttz(e).format('X')));
  return dates.find(e => +momenttz(e).format('X') === latestDateTimeStamp) || '';
};

export const getDocumentExpiryDate = {
  minDocumentDateExp: getDateTime(true, [ 'plus' ], [ [ 30, 'days' ] ]),
  maxDocumentDateExp: getDateTime(true, [ 'plus' ], [ [ 10, 'years' ] ]),
};

export const leapYear = (year: string) => {
  const momenttz = require('moment-timezone'); // using `require` enables jest.mock in test setup
  return momenttz(year).isLeapYear();
};

export const formatInboxreceptionTime = (timestamp: number) => {
  const moment = require('moment'); // using `require` enables jest.mock in test setup
  const oneWeekAgoTimestamp = +moment().subtract(1, 'w');
  const isOlderThanAWeek: boolean = oneWeekAgoTimestamp > timestamp;
  if (isOlderThanAWeek) {
    return moment(timestamp).format(displayDateFormat_MMM_DD);
  }
  return `${moment(timestamp).toNow(true)} ago`;
};

export const formatInboxDescriptionReceptionTime = (timestamp: number) => {
  const moment = require('moment'); // using `require` enables jest.mock in test setup
  const oneWeekAgoTimestamp = +moment().subtract(1, 'w');
  const isOlderThanAWeek: boolean = oneWeekAgoTimestamp > timestamp;

  const today = new Date().setHours(0, 0, 0, 0);
  const thatDay = new Date(timestamp).setHours(0, 0, 0, 0);

  if (today === thatDay) {
    return `${lang.performanceRangeTextToday()} • ${moment.utc(timestamp).local().format(displayDateFormat_HH_MM)}`;
  } if (isOlderThanAWeek) {
    return `${moment(timestamp).format(displayDateFormat_MMM_DD)} • ${moment.utc(timestamp).local().format(displayDateFormat_HH_MM)}`;
  }
  return `${moment(timestamp).toNow(true)} ago • ${moment.utc(timestamp).local().format(displayDateFormat_HH_MM)}`;
};

export const getMinYearOfDeceasedPerson = getDateTime(true, [ 'minus' ], [ [ 120, 'years' ] ], CURRENT_YEAR.toString());

export const isBeforeDate = (value: Date | string | undefined, minDate: Date | string | undefined): boolean => {
  const moment = require('moment'); // using `require` enables jest.mock in test setup

  return moment(value).isBefore(minDate);
};

export const earningsCalendarInitialDate = () => (isDateMarketDataWorkingDay(new Date())
  ? new Date() : getNextWorkday()?.toDate());

export const tradeHistoryCalendarInitialMaxDate = () => (isDateMarketDataWorkingDay(new Date())
  ? new Date() : getPreviousWorkday()?.toDate());

export const isMomentToday = (date: moment.Moment): boolean => {
  const moment = require('moment');
  const today = moment();
  return date && today.isSame(date, 'day') && today.isSame(date, 'month') && today.isSame(date, 'year');
};

export const isMomentSame = (date1: moment.Moment, date2: moment.Moment): boolean => date1 && date2
  && date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year');

export const getMillisecondsToSyncWithClock = () => 1000 - (new Date().getTime() % 1000);

export const formatUTCdateToLocalDate = ((date: string | undefined) => {
  if (date) {
    return getDateTime(
      true,
      [ 'format', 'local' ],
      [ [ `${LOCAL_DATE} \u2022 ${displayDateFormat_HH_MM}` ],
        [] ],
      new Date(`${date}Z`),
    );
  }

  return undefined;
});

/**
 * @param text string
 * @returns string date or undefined
 */
export const extractDateFromString = (text: string) => {
  const date = text.match(/\d{2}(\/)\d{2}(\/)\d{4} \d{2}:\d{2}/g); // Match format - 12/22/2022 12:45

  return date?.toString();
};
