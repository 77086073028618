import { Sector, TopGainerAndLoserData } from '../../models/fundamental/types';
import { RootState } from '../../store';

const ETFS_SECTOR_ID = 9;
export const ETF_SECTOR_NAME = 'ETFs';

// TODO:Cover with test
export const getAllSectors = (state: RootState) => {
  const sectors = state.fundamental.sectors;
  const canTradeETFs = state.ses.canTradeETFs;
  if (!canTradeETFs) {
    return sectors;
  }
  const etfCount = state.fundamental.ETFData.totalElements;
  const etf = { id: ETFS_SECTOR_ID, sector: ETF_SECTOR_NAME, symbolCount: etfCount, logoBase64: '' };
  return [ etf, ...sectors ];
};

export const isSectorETFs = (sectorData: Sector): boolean => ETF_SECTOR_NAME === sectorData.sector;

export const isPredefinedTopGainersTopLosersFilter = (value: string) => value === 'FROM_1_TO_5'
  || value === 'FROM_5_TO_15'
  || value === 'FROM_15_TO_50'
  || value === 'FROM_50_TO_100'
  || value === 'FROM_100';

export const getTopGainersJson = (minValue: number, maxValue: number): TopGainerAndLoserData[] => {
  const range = `FROM_${minValue}_TO_${maxValue}`;
  switch (range) {
    case 'FROM_1_TO_5':
      return require('../../__offline-data__/fundamental/topGainers/topGainersOneToFive.json');
    case 'FROM_5_TO_15':
      return require('../../__offline-data__/fundamental/topGainers/topGainersFiveToFifteen.json');
    case 'FROM_15_TO_50':
      return require('../../__offline-data__/fundamental/topGainers/topGainersFifteenToFifty.json');
    case 'FROM_50_TO_100':
      return require('../../__offline-data__/fundamental/topGainers/topGainersFiftyToHundred.json');
    case 'FROM_100':
      return require('../../__offline-data__/fundamental/topGainers/topGainersOverHundred.json');
    default:
      return require('../../__offline-data__/fundamental/topGainers/topGainersOneToFive.json');
  }
};

export const getTopLosersJson = (minValue: number, maxValue: number): TopGainerAndLoserData[] => {
  const range = `FROM_${minValue}_TO_${maxValue}`;
  switch (range) {
    case 'FROM_1_TO_5':
      return require('../../__offline-data__/fundamental/topLosers/topLosersOneToFive.json');
    case 'FROM_5_TO_15':
      return require('../../__offline-data__/fundamental/topLosers/topLosersFiveToFifteen.json');
    case 'FROM_15_TO_50':
      return require('../../__offline-data__/fundamental/topLosers/topLosersFifteenToFifty.json');
    case 'FROM_50_TO_100':
      return require('../../__offline-data__/fundamental/topLosers/topLosersFiftyToHundred.json');
    case 'FROM_100':
      return require('../../__offline-data__/fundamental/topLosers/topLosersOverHundred.json');
    default:
      return require('../../__offline-data__/fundamental/topLosers/topLosersOneToFive.json');
  }
};
