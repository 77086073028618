import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { SOROrderSide, SOROrderType } from '../../../lib/models/gateway/types';
import { RootState } from '../../../lib/store/index';
import { cancelOrder as serviceCancelOrder } from '../../../lib/store/trading/index';
import { uuidv4 } from '../../../lib/util/tools';

import useIsMobile from '../../../hooks/useIsMobile';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';

import styles from './CancelOrder.module.scss';

type Props = {
  orderId: string;
  handleModalCancel: (isConfirmed: boolean) => void;
} & ReduxProps;

const CancelOrder = (props: Props) => {
  const { orderId, handleModalCancel, pendingOrders, cancelOrder } = props;
  const isMobile = useIsMobile();

  const onConfirm = () => {
    const order = pendingOrders.find(el => el.parentOrderId === orderId);
    const { parentOrderId, side, symbol, type, totalQuantity } = order!;

    if (isMobile) {
      sessionStorage.setItem('showUseDetailsCancelModal', 'true');
    }
    cancelOrder({
      clientOrderId: uuidv4(),
      originalClientOrderId: parentOrderId,
      orderQty: totalQuantity,
      ordType: SOROrderType[type],
      side: SOROrderSide[side],
      symbol,
    });
    handleModalCancel(true);
  };

  return (
    <>
      <h6 className={styles.headerText}>
        {lang.webOrderPopupCancelTitle()}
      </h6>
      <div className={styles.message}>
        {lang.webOrderPopupAreYouSureToCancelLabel()}
      </div>
      <div>
        <Button
          variant={ButtonTypesEnum.Tertiary}
          fullWidth
          className={styles.btnCancel}
          onClick={() => handleModalCancel(false)}
        >
          {lang.commonOrderSideButtonKeepIt()}
        </Button>
        <Button fullWidth onClick={onConfirm}>{lang.commonOrderSideButtonProceed()}</Button>
      </div>
    </>
  );
};

const mapStateProps = (state: RootState) => ({
  pendingOrders: state.reporting.pendingOrders,
  requestStatus: state.trading.requestStatus,
  submittedOrder: state.trading.order,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  cancelOrder: (order) => dispatch(serviceCancelOrder(order)),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CancelOrder);
