import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer, timer } from 'rxjs';
import {
  delay,
  delayWhen, filter, ignoreElements, mapTo, mergeMap, tap,
} from 'rxjs/operators';

import { logConfig } from '../../../configDebug';
import LibraryConfig from '../../../configLib';
import { IGNORE_GATEWAY_CONNECTION } from '../../libSettings';
import {
  GWQueryCase,
  GWQueryResultStatus,
  GWResponseType,
  SORCommonOrderResponse,
} from '../../models/gateway/types';
import GlobalService from '../../services/Global.service';
import { processQueryResultsRestData } from '../../util/GatewayHelpers';
import { NullableString } from '../../util/types';
import { markAsReadByMetadata } from '../ams/index';
import {
  loginFailed,
  logout,
  reconnect,
  ReconnectPayload,
  sorOrderEvent,
} from '../common-actions';
import {
  processErrorInResponse,
  processResponse,
} from '../helpers';
import { getToken, hasValidToken } from '../selectors';
import { isFinalStatus } from '../trading/helpers';
import { newFXOrderCompleted, newFXOrderFailed } from '../trading/index';
import { RootState } from '..';

import { REST_RETRIES } from './constants';
import {
  connectionTimedOut,
  dataQuery,
  dataQueryError,
  dataQuerySuccess,
  restResponseError,
  subscribeNews,
  subscribeSymbolStatus,
  unsubscribeNews,
  unsubscribeSymbolStatus,
} from './index';

const reconnectEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(reconnect.type),
  filter(action => (((action.payload as ReconnectPayload).gateway ?? false))),
  filter(() => hasValidToken(state$.value)),
  tap(() => GlobalService.gatewayWs.reconnect(getToken(state$.value)!)),
  ignoreElements(),
);

const disconnectEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(logout.type, loginFailed.type),
  filter(() => !!GlobalService.gatewayWs),
  filter(() => state$.value.gateway.connected),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    GlobalService.gatewayWs.disconnect();
  })),
);

const connectionTimedOutEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(connectionTimedOut.type),
  filter(() => !(IGNORE_GATEWAY_CONNECTION && !state$.value.gateway.connected)),
  delay(5000),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    observer.next(logout());
  })),
);

/**
 * Covers all GWQueryCase calls including News if Gateway is enabled
 */
const dataQueryEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(dataQuery.type),
  filter(() => LibraryConfig.gatewayEnabled.login && !!GlobalService.gatewayWs),
  delayWhen(action => timer(action.payload.estimatedDeliveryInSec * 1000).pipe(mapTo(action))),
  mergeMap(action => {
    let callName = 'gateway/query';
    let {
      accessId,
      cacheData,
      estimatedDeliveryInSec,
      retries = REST_RETRIES,
    } = action.payload;
    const { requestData, queryCase } = cacheData || {};
    const queryCaseName = GWQueryCase[queryCase];
    const { symbols, symbol } = requestData || {};
    const symbolTitle = symbol != null ? 'symbol=' : 'symbols=';

    return new Observable((observer: Observer<any>) => {
      if (logConfig.gatewayRESTCacheRequest) {
        console.info(`[GW-REST] Request for accessId=${accessId} '${queryCaseName}' (get query results)`);
      }
      GlobalService
        .gatewayRest
        .get(accessId)
        .then((response: any) => {
          let { status: statusCode } = response;
          let { resultCode } = response?.data || {};
          let resultOk = (resultCode == null || resultCode === GWQueryResultStatus.READY);
          let isSuccess = processResponse(
            callName,
            action.payload,
            response,
            observer,
            dataQuerySuccess,
            dataQueryError,
            false,
            action.payload,
          ); // error case only

          if (isSuccess && statusCode === GWQueryResultStatus.READY && resultOk) {
            if (logConfig.gatewayRESTCacheResponse) {
              console.info(`[GW-REST] Response for accessId=${accessId} '${queryCaseName}' received - statusCode=${statusCode}, resultCode=${resultCode}`);
              console.debug(`[GW-REST] Response data for '${queryCaseName}'`, { response, cacheData });
            }
            processQueryResultsRestData(response, observer, cacheData); // success cases - dispatch news-related actions (newsForSymbolLoadCompleted, newsForSymbolLoadFailed etc.)
          } else if (statusCode === GWQueryResultStatus.STILL_IN_PROCESSING && resultOk) {
            retries--;
            estimatedDeliveryInSec *= 2;

            if (retries >= 0) {
              console.warn(`[GW-REST] Retry (${retries} left) getting accessId=${accessId} '${queryCaseName}', ${symbolTitle}${symbol || symbols} - statusCode=${statusCode}, resultCode=${resultCode}, delay=${estimatedDeliveryInSec}s`);
              const newPayload = {
                ...action.payload,
                retries,
                estimatedDeliveryInSec,
              };
              observer.next(dataQuery(newPayload));
            } else {
              console.warn(`[GW-REST] No more retries for accessId=${accessId} '${queryCaseName}', ${symbolTitle}${symbol || symbols} - statusCode=${statusCode}, resultCode=${resultCode}`);
              observer.next(restResponseError(new Error(`No more retries for ${symbolTitle}${symbol || symbols} - ${queryCaseName} - status=${statusCode} result=${resultCode}`)));
              processQueryResultsRestData(response, observer, cacheData, true); // fail case - dispatch news-related actions (newsForSymbolLoadCompleted, newsForSymbolLoadFailed etc.)
            }
          } else {
            console.warn(`[GW-REST] Not retrying request for accessId=${accessId} '${queryCaseName}', ${symbolTitle}${symbol || symbols} - statusCode=${statusCode}, resultCode=${resultCode}`, cacheData);
            observer.next(restResponseError(new Error(`Not retries ${symbolTitle}${symbol || symbols} - ${queryCaseName} - status=${statusCode} result=${resultCode}`)));
            processQueryResultsRestData(response, observer, cacheData, true); // fail case - dispatch news-related actions (newsForSymbolLoadCompleted, newsForSymbolLoadFailed etc.)
          }
        })
        .catch((error: any) => {
          processErrorInResponse(callName, error, action, observer, restResponseError);
          throw error;
        });
    });
  }),
);

const subscribeNewsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(subscribeNews.type),
  tap((action: PayloadAction<NullableString>) => {
    GlobalService.globalService.newsSubscribe(action.payload);
  }),
  ignoreElements(),
);

const unsubscribeNewsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(unsubscribeNews.type),
  tap((action: PayloadAction<NullableString>) => {
    GlobalService.globalService.newsUnSubscribe(action.payload);
  }),
  ignoreElements(),
);

const subscribeSymbolStatusEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(subscribeSymbolStatus.type),
  mergeMap((action: PayloadAction<string[]>) => new Observable((observer: Observer<any>) => {
    GlobalService.globalService.subscribeSymbolStatus(action.payload);
  })),
);

const unsubscribeSymbolStatusEpic = (action$: Observable<any>) => action$.pipe(
  ofType(unsubscribeSymbolStatus.type),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    GlobalService.globalService.unsubscribeSymbolStatus();
  })),
);

const sorOrderEventEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(sorOrderEvent.type),
  mergeMap((action: PayloadAction<SORCommonOrderResponse>) => new Observable((observer: Observer<any>) => {
    const { status, clientOrderId, messageType } = action.payload;
    const { clientOrderId: fxOrderId } = state$.value.trading.fxOrder;

    // Symbol Order
    if (isFinalStatus(status) && clientOrderId !== fxOrderId && messageType === GWResponseType.ExecutionReport) {
      observer.next(markAsReadByMetadata());
    }

    // FX Order
    if (fxOrderId === clientOrderId) {
      if (messageType === GWResponseType.ExecutionReport) {
        observer.next(newFXOrderCompleted(action.payload));
      }
      if (messageType === GWResponseType.OrderRejectedResponse) {
        observer.next(newFXOrderFailed());
      }
    }
  })),
);

export default combineEpics(
  reconnectEpic,
  disconnectEpic,
  connectionTimedOutEpic,
  dataQueryEpic,
  sorOrderEventEpic,
  subscribeNewsEpic,
  unsubscribeNewsEpic,
  subscribeSymbolStatusEpic,
  unsubscribeSymbolStatusEpic,
);
