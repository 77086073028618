/**
 * The `useMarketTimer` has the following functions:
 *    - based on market state calculates one, both or none of these times:
 *         (1) to start of next pre market
 *         (2) time to next regular hours start
 *    - returns updates state with (see MarketTimerCache)
 *    - synchronizes time by:
 *      - adjusting setInterval to start at 0 seconds of next minute - see `getMillisecondsToSyncWithClock` usage below
 *      - uses only one setInterval for all timers started (maximum 2 initially)
 *      - uses and updates only one state - MarketTimerCache
 */
import { useEffect, useRef } from 'react';

import { ELAPSED_TIME_ZERO } from '../../constants/date-time.constants';
import { MarketState } from '../../models/market-data/types';
import MarketStateCache from '../../store-util/MarketStateCache';
import MarketTimerCache, { modesEnabled, setTimerState, TIMER_INITIAL_STATE } from '../../store-util/MarketTimerCache';
import { calculateElapsedTime, calculateTimeToNextMarketState, getMillisecondsToSyncWithClock } from '../DateTimeHelpers';
import { isMarketClosed } from '../MarketDataHelpers';
import { NullableNumber } from '../types';

import { MarketTimerMode } from './types';

let timerSubscribers = 0;
let isPreMarket = false;
let isRegularHours = false;
let isAfterMarket = false;
let isClosed = false;
let timerId: NodeJS.Timeout | null = null;
let calculatedTime: ReturnType<typeof calculateTimeToNextMarketState> = {} as any;

const valuesByMode: Partial<Record<NonNullable<MarketTimerMode>, NullableNumber>> = {};

const checkMarketState = () => {
  calculatedTime = calculateTimeToNextMarketState(null, 's', true);
  const { marketState: currentMarketState } = calculatedTime;
  if (MarketStateCache.get()?.marketState !== currentMarketState) {
    MarketStateCache.refresh();
  }
  isPreMarket = currentMarketState === MarketState.PreMarket;
  isRegularHours = currentMarketState === MarketState.RegularHours;
  isAfterMarket = currentMarketState === MarketState.AfterMarket;
  isClosed = isMarketClosed(currentMarketState);
};

const calculateValues = (mode: MarketTimerMode) => {
  if (!mode) return;

  let toTime: NullableNumber = null;
  const { nextPreMarket, nextRegular } = calculatedTime;

  switch (mode) {
    case 'symbol-details':
      toTime = isClosed ? nextPreMarket : nextRegular;
      break;
    case 'short-sell':
      toTime = nextRegular;
      break;

    default:
      break;
  }

  if (toTime == null) {
    valuesByMode[mode] = 0;
    return;
  }

  valuesByMode[mode] = Math.ceil(toTime); // see where getMillisecondsToSyncWithClock is used below - if you'd like to check how this is synced after using Math.ceil
};

/**
 * Highly customized hook for handling timers. See documentation in file `MarketTimerHook.ts`
 * @param mode The returned result is based on this mode - currently two values - symbol-details and short-sell
 */
export const useMarketTimer = (mode: MarketTimerMode) => {
  if (!mode) return TIMER_INITIAL_STATE;

  const isMainTimer = useRef(timerSubscribers === 0); // eslint-disable-line
  const isFirst = useRef(true); // eslint-disable-line

  if (!modesEnabled.includes(mode)) modesEnabled.push(mode);
  let timerTick: ((...args: any[]) => void) | null = null;

  if (isFirst.current) {
    if (isMainTimer.current) checkMarketState();
    calculateValues(mode);
    const value = valuesByMode[mode];
    const elapsedTime = calculateElapsedTime(value!, 0, ELAPSED_TIME_ZERO);
    setTimerState(mode, value, elapsedTime, isPreMarket, !!value);
  }

  if (isMainTimer.current) {
    timerTick = () => {
      checkMarketState();

      modesEnabled.forEach(theMode => {
        calculateValues(theMode);
        if (isAfterMarket) {
          setTimerState(theMode, null, null, false, false, true);
          return;
        }
        if (theMode === 'create-order') {
          setTimerState(theMode, null, null, false, false, true);
          return;
        }
        if (isRegularHours) {
          valuesByMode[theMode] = null;
          if (MarketTimerCache.get(theMode)?.isEnabled) {
            setTimerState(theMode, null, null, false, false, false);
          }
          return;
        }

        const value = valuesByMode[theMode]!;
        const seconds = calculateElapsedTime(value, 0, ELAPSED_TIME_ZERO);
        if (value == null) return;

        setTimerState(theMode, value, seconds, isPreMarket, true, false);
      });
    };
  }


  // component mount / unmount
  useEffect(() => { // eslint-disable-line
    timerSubscribers++;
    isFirst.current = false;

    const msLeft = getMillisecondsToSyncWithClock();
    // deliberate timeout - to sync with the clock at 0 seconds
    if (isMainTimer.current) {
      setTimeout(() => {
        timerId = setInterval(timerTick!, 1000) as any;
      }, msLeft);
    }

    modesEnabled.forEach(theMode => {
      if (isAfterMarket) {
        setTimerState(theMode, null, null, false, false, true);
      }
    });

    return () => {
      timerSubscribers--;
      modesEnabled.splice(modesEnabled.indexOf(mode), 1);
      if (timerSubscribers === 0 && timerId) {
        clearInterval(timerId);
      }
    };
  }, []); // eslint-disable-line

  return MarketTimerCache.use(mode);
};
