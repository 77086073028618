import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { PeopleAlsoTradeItem, ReportingCall } from '../../../lib/models/reporting/types';
import { getCompanyLogoAndName } from '../../../lib/store/fundamental';
import { RootState } from '../../../lib/store/index';
import { getCustomWatchlistData } from '../../../lib/store/market-data/index';
import { getPeopleAlsoTradeData } from '../../../lib/store/reporting';
import { getCurrentAccount } from '../../../lib/store/selectors';
import { isCallStatusPending, isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { useIsMobile } from '../../../hooks';
import { NoDataAvailable, Preloader } from '../../index';

import CalculatedTradePriceCache from './components/PeopleAlsoTradeCard/CalculatedTradePriceCache';
import PeopleAlsoTradeCard from './components/PeopleAlsoTradeCard/PeopleAlsoTradeCard';
import SlideButtons from './components/SlideButtons/SlideButtons';
import { TradePrice } from './PeopleAlsoTrade.types';

import styles from './PeopleAlsoTrade.module.scss';

type OwnProps = { symbol: string }
type Props = OwnProps & ReduxProps;

let sortedData: TradePrice[] = [];

const PeopleAlsoTrade = (props: Props) => {
  const {
    symbol,
    peopleAlsoTradeData,
    stocks,
    accountReferenceId,
    isPeopleAlsoTradeDataPending,
    isPeopleAlsoTradeDataReady,
    getDataPeopleAlsoTrade,
    reloadData,
  } = props;
  const isMobile = useIsMobile();
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [tempData, setTempData] = useState<TradePrice[]>([]);
  const [dataCards, setDataCards] = useState<PeopleAlsoTradeItem[]>([]);

  const handleTradePriceCache = (data: TradePrice) => {
    if (tempData.every(item => item.symbol !== data.symbol)) {
      setTempData([ ...tempData, data ]);
    }
  };

  const renderPeopleAlsoTradeCards = () => {
    const handleClickCard = () => {
      setSlideIndex(0);
    };

    const peopleAlsoTradeCards = (priceCacheData: TradePrice[]) => (
      priceCacheData.map((item: TradePrice) => (
        <PeopleAlsoTradeCard
          data={item}
          key={item.symbol}
          handleClickCard={handleClickCard}
        />
      ))
    );

    sortedData = tempData.filter(item => item.symbol !== symbol).slice().sort((a, b) =>
      parseFloat(b.changePercent || '') - parseFloat(a.changePercent || ''));

    return (
      <>
        {dataCards.map(item => (
          <CalculatedTradePriceCache
            key={item.symbol}
            symbol={item.symbol}
            handleTradePriceCache={handleTradePriceCache}
          />
        ))}
        {sortedData.length > 0 && peopleAlsoTradeCards(sortedData.slice(slideIndex, slideIndex + 5))}
      </>
    );
  };

  const handleSlideIndex = (index: number) => {
    setSlideIndex(index);
  };

  useEffect(() => {
    if (accountReferenceId) {
      getDataPeopleAlsoTrade();
    }
  }, [accountReferenceId, symbol, getDataPeopleAlsoTrade]);

  useEffect(() => {
    if (peopleAlsoTradeData.length > 0) {
      setDataCards(peopleAlsoTradeData);
    }
  }, [peopleAlsoTradeData]);

  useEffect(() => {
    if (stocks?.length) {
      reloadData();
    }
  }, [stocks, reloadData]);

  return (
    <div className={styles.wrapper}>
      <h6 className={isMobile ? styles.titleMobile : styles.title}>
        {lang.commonStockDetailsStatsPeopleAlsoTradeTitle()}
      </h6>
      <div className={styles.container} style={isMobile ? { width: `${window.innerWidth - 40}px` } : {}}>
        {isPeopleAlsoTradeDataPending && (
        <div className={styles.loader}>
          <Preloader isFullScreen text="" owner="People also trade" />
        </div>
        )}
        {
          (isPeopleAlsoTradeDataReady && peopleAlsoTradeData.length === 0)
          && <div className={styles.noDataAvailable}><NoDataAvailable /></div>
        }
        {(isPeopleAlsoTradeDataReady && peopleAlsoTradeData.length > 0) && renderPeopleAlsoTradeCards()}
        { !isMobile && (
          <SlideButtons
            handleSlideIndex={handleSlideIndex}
            slideIndex={slideIndex}
            peopleAlsoTradeData={sortedData}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  peopleAlsoTradeData: state.reporting.peopleAlsoTradeData,
  stocks: state.fundamental.stockResults,
  accountReferenceId: getCurrentAccount(state),
  isPeopleAlsoTradeDataPending: isCallStatusPending(ReportingCall.getPeopleAlsoTradeData, state.reporting.statusByCall),
  isPeopleAlsoTradeDataReady: isCallStatusReady(ReportingCall.getPeopleAlsoTradeData, state.reporting.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  reloadData: () => dispatch(getCustomWatchlistData({ component: 'people-also-trade', caller: 'PeopleAlsoTrade.tsx' })),
  getDataPeopleAlsoTrade: () => dispatch(getPeopleAlsoTradeData()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PeopleAlsoTrade);
