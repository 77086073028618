import { CallStatus } from '../../enums';
import { ReportingCall } from '../../models/reporting/types';
import { setCallStatus } from '../../util/error-handling/StatusByCallHelpers';
import { NullableNumber } from '../../util/types';

import { ReportingState } from './types';

export function setReportingCallStatus(
  call: ReportingCall,
  status: CallStatus | null,
  state: ReportingState,
  errorCode?: NullableNumber,
  customData?: any,
) {
  setCallStatus(call, ReportingCall[call], status, state.statusByCall, errorCode, state.errorsLog, customData);
}
