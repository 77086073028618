import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OtpInput from 'react18-input-otp';

import lang from '../../../lib/language';
import { ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import { ENROLL_AUTH_REQUEST_TYPE } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enrollAuth } from '../../../lib/store/auth';
import { createFormControlError } from '../../../lib/util/ValidationSchemes/Validate';
import { ControlError } from '../../../lib/util/ValidationSchemes/ValidationTypes';

import { VerificationLayout } from '../../../components';
import { PhoneAutocompleteValue } from '../../../components/PhoneAutocomplete/PhoneAutocomplete.types';
import { UI_ROUTES } from '../../../util/routes';
import { PhoneNumberSelector } from '../Verification.constants';

import Counter from './Counter';

import styles from './VerificationPhoneOtp.module.scss';

type LocationState = {
  [PhoneNumberSelector]: PhoneAutocompleteValue
}

const CODE_NUMBERS = 6;

const VerificationPhoneOTP = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const phoneCodeConfirmed = useSelector<RootState, boolean | undefined>((state: RootState) => (
    state.auth.enroll.confirmedSteps.phone
  ));

  const enrollStatus = useSelector<RootState, ENROLL_REQUEST_STATE | undefined>((state: RootState) => (
    state.auth.enroll.status
  ));

  const [phoneNumber, setPhoneNumber] = useState<PhoneAutocompleteValue>({ phone: '', code: '' });
  const [isSendAgain, setIsSendAgain] = useState<boolean>(false);
  const [hasMadeRequest, setHasMadeRequest] = useState<boolean>(false);
  const [inputCodes, setInputCodes] = useState<string>('');
  const [error, setError] = useState<ControlError>();

  const formattedPhoneNumber = useMemo((): string => {
    let newPhoneNumber = phoneNumber.code.split(' ')[0];
    if (phoneNumber.phone.length) {
      newPhoneNumber += phoneNumber.phone.replace(/^.*([0-9]{2})$/, ` ${'*'.repeat(phoneNumber.phone.length - 2)}$1.`);
    }
    return newPhoneNumber;
  }, [phoneNumber]);

  useEffect(() => {
    if (!history.location.state || !(history.location.state as LocationState)[PhoneNumberSelector]) {
      history.push(UI_ROUTES.verificationPhoneNumber);
    } else {
      setPhoneNumber((history.location.state as LocationState)[PhoneNumberSelector]);
    }
  }, [history]);

  useEffect(() => {
    if (enrollStatus === ENROLL_REQUEST_STATE.ERROR) {
      const err = isSendAgain
        ? createFormControlError(lang.commonOnboardingPhoneVerificationCodeSendAgainErrorMessage())
        : createFormControlError(lang.commonOnboardingPhoneVerificationCodeDoesNotMatch());
      setError(err);
    }
  }, [enrollStatus, isSendAgain]);

  useEffect(() => {
    if (hasMadeRequest && phoneCodeConfirmed) {
      history.push(UI_ROUTES.verification);
    }
  }, [hasMadeRequest, history, phoneCodeConfirmed]);

  return (
    <VerificationLayout
      title={lang.commonOnboardingPhoneVerificationSMSCodeTitle()}
      btnDisabled={inputCodes.length < CODE_NUMBERS}
      onContinueClicked={() => {
        setIsSendAgain(false);
        dispatch(enrollAuth({
          requestType: ENROLL_AUTH_REQUEST_TYPE.VERIFY_PHONE_CODE,
          code: inputCodes,
        }));
        setHasMadeRequest(true);
      }}
      customSubtitle={() => (
        <p style={{ maxWidth: 258 }}>
          {lang.commonOnboardingPhoneVerificationSMSCodeSubitle('')}
          <span className="text-dark">{formattedPhoneNumber}</span>
        </p>
      )}
    >
      <>
        <div className={styles.wrapperPasscodeFields}>
          <OtpInput
            value={inputCodes}
            onChange={setInputCodes}
            numInputs={CODE_NUMBERS}
            inputStyle={styles.field}
            isInputNum
            shouldAutoFocus
          />
        </div>
        {error && (
        <p
          key={error.id}
          id={error.id.toString()}
          className={styles.error}
        >
          {error.error}
        </p>
        )}
        <div className={styles.textsWrapper}>
          <p>{lang.commonOnboardingPhoneVerificationSMSCodeNotReceived()}</p>
          <p id="resendCode" aria-label="resend code">
            {lang.commonOnboardingPhoneVerificationSMSCodeExpriesIn()}
            &nbsp;
            <Counter phoneNumber={phoneNumber} setIsSendAgain={setIsSendAgain} />
          </p>
        </div>
      </>
    </VerificationLayout>
  );
};

export default VerificationPhoneOTP;
