import { isEmpty } from 'lodash';

import { MARKET_CLOSE_NY_TIME, MARKET_OPEN_NY_TIME } from '../../constants/date-time.constants';
import { CallStatus } from '../../enums';
import lang from '../../language';
import { CacheableCalls } from '../../libSettings';
import { PagedResponse } from '../../models/common-response-types';
import { BaseSymbolData, Earning, TopGainerAndLoserData } from '../../models/fundamental/types';
import { DEFAULT_PAGE_RESPONSE } from '../../models/pageable';
import CallsCache, { ProcessCallFunction, ProcessCallReturn } from '../../store-util/calls-cache/CallsCache';
import {
  setTopGainersInitialItems,
  setTopGainersTimeout,
  updateTopGainers,
} from '../../store-util/top-movers/TopGainersCache';
import {
  setTopLosersInitialItems,
  setTopLosersTimeout,
  updateTopLosers,
} from '../../store-util/top-movers/TopLosersCache';
import { TopMoversCaller } from '../../store-util/top-movers/types';
import TradePriceCache, { CalculatedTradePrice, PriceChangeDirection } from '../../store-util/TradePriceCache';
import { defaultLogoBase64 } from '../../util/assets/logos';
import { isOneOf } from '../../util/DataHelpers';
import { setCallStatus } from '../../util/error-handling/StatusByCallHelpers';

import { FundamentalCall, FundamentalState, SectorEnum } from './types';

const MAX_RETRIES_TRADE_PRICE = 5;
const TRADE_PRICE_RETRIES_DELAY = 1000;


export function updateCompanyAndLogoCache(stocks: BaseSymbolData[], state: FundamentalState) {
  stocks?.forEach(item => {
    const { symbol, logoBase64, companyName } = item;
    const theLogo = logoBase64 ?? defaultLogoBase64;
    const currentLogoAndName = state.companyLogoAndName[symbol] ?? { logo: theLogo, name: companyName! };
    const { logo, name } = currentLogoAndName;
    if (!logo) currentLogoAndName.logo = logo!;
    if (!name) currentLogoAndName.logo = companyName!;
    if (!state.companyLogoAndName[symbol] || !logo || !name) {
      state.companyLogoAndName[symbol] = currentLogoAndName;
    }
  });
}

const isValidHourMinuteSecondValue = (time: string): boolean => {
  if (!time) {
    return false;
  }

  const pattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/;
  return pattern?.test(time);
};

const getEarningCalendarDisplayTime = (time: string): string => {
  if (!isValidHourMinuteSecondValue(time)) {
    return lang.commonDiscoverCalenderSectionTimeNotSupplied();
  }

  if (parseInt(time) >= parseInt(MARKET_CLOSE_NY_TIME)) {
    return lang.commonDiscoverCalenderSectionTimeAfterMarketClose();
  }

  if (parseInt(time) < parseInt(MARKET_OPEN_NY_TIME)) {
    return lang.commonDiscoverCalenderSectionTimeBeforeMarketOpen();
  }

  return lang.commonDiscoverCalenderSectionTimeNotSupplied();
};

export const populateEarningCalendarLabels = (payload: PagedResponse<Earning>): PagedResponse<Earning> => {
  if (!payload || isEmpty(payload.content)) {
    return DEFAULT_PAGE_RESPONSE;
  }

  for (const earning of payload.content) {
    earning.displayTime = getEarningCalendarDisplayTime(earning.time);
    earning.displayEpsEst = !earning.epsEst
      ? lang.commonDiscoverCalenderSectionTimeNoEPSEstimate()
      : lang.commonDiscoverCalenderSectionTimeEPSEstimate(earning.epsEst);
  }

  return payload;
};

export function processCallCache(
  symbols: string,
  filterByCacheCallName: CacheableCalls | null,
  newPendingValue?: boolean,
) {
  if (!symbols) return '';

  let result = '';

  if (filterByCacheCallName != null) {
    let asArray = symbols.split(',');
    const filtered = asArray.filter(symbol => {
      if (newPendingValue != null) {
        const processCallFunction: ProcessCallFunction = (
          newPendingValue
            ? CallsCache.processRequest
            : CallsCache.processResponse
        ) as ProcessCallFunction;
        const { toBeUpdated } = processCallFunction(filterByCacheCallName, symbol);
        return toBeUpdated;
      }

      return CallsCache.checkIfDirty(filterByCacheCallName, symbol);
    });
    result = filtered.join(',');
  }

  return result;
}

export function setFundamentalCallStatus(
  call: FundamentalCall,
  status: CallStatus,
  state: FundamentalState,
  errorCode?: number,
) {
  setCallStatus(call, FundamentalCall[call], status, state.statusByCall, errorCode, state.errorsLog);
}

// TODO:This method will be deleted after BOS-2090 is merged
export const getSectorTitleBySectorName = (name: string): string => {
  switch (name) {
    case SectorEnum.Communications:
      return lang.popularGroupCommunications();

    case SectorEnum.ConsumerCyclical:
      return lang.popularGroupConsumerDiscretionary();

    case SectorEnum.ConsumerDefensive:
      return lang.popularGroupConsumerStaples();

    default:
      return name;
  }
};


const sortTopMovers = (topMovers: TopGainerAndLoserData[]) => (
  topMovers.sort((a, b) => Number(b.changePercentage?.slice(1, -1)) - Number(a.changePercentage?.slice(1, -1)))
);

function getTopMoversCurrentPrices(topMovers: TopGainerAndLoserData[], caller: TopMoversCaller) {
  const rawData = TradePriceCache.getAll();
  let count = 0;
  const directionValues: PriceChangeDirection[] = caller === 'TopGainers' ? [ '+', '' ] : [ '-', '' ];
  const symbols = topMovers.map(el => el.symbol);
  const updatedTopMovers: TopGainerAndLoserData[] = [];

  symbols.forEach((symbol, index) => {
    const value: CalculatedTradePrice | undefined = rawData[symbol] as any;
    if (value) {
      const {
        currentPrice,
        direction: itemDirection,
        hasInstrumentSnapshotData,
      } = value ?? {};
      const hasCorrectDirection = isOneOf(itemDirection, directionValues);
      if (
        currentPrice != null
        && hasCorrectDirection
        && hasInstrumentSnapshotData
      ) {
        count++;

        updatedTopMovers.push({
          ...topMovers[index],
          price: currentPrice!,
          changePercentage: value.changePercentWithSign,
        });
      }
    }
    return false;
  });

  const availability = symbols.length && count ? count / symbols.length : 0;
  const data = sortTopMovers(updatedTopMovers);

  return { data, availability };
}

/** The interval id for top movers checks */
let topMoversIntervalId: any = {};
const stopInterval = (caller: TopMoversCaller) => {
  const theinterval = topMoversIntervalId[caller];
  if (theinterval) {
    clearInterval(theinterval);
    delete topMoversIntervalId[caller];
  }
};
/**
 * Checks for price availability for each item in topMovers list
 * @param topMovers A list of the items to check
 * @param caller The caller - gainers or losers
 * @param aimedAvailability The minimum availability to reach - 0.1 by default (1 is maximum, 0 is minimum)
 * @param retriesDelay The delay for each retry
 * @param retriesCount Number of retries
 */
export const getPriceForTopMovers = (
  topMovers: TopGainerAndLoserData[],
  caller: TopMoversCaller,
  aimedAvailability: number = 0.1,
  retriesDelay = TRADE_PRICE_RETRIES_DELAY,
  retriesCount = MAX_RETRIES_TRADE_PRICE,
) => {
  stopInterval(caller);

  if (caller === 'TopGainers') {
    setTopGainersInitialItems(topMovers);
  } else {
    setTopLosersInitialItems(topMovers);
  }

  const { logConfig } = require('../../../configDebug');
  const { enabled: logEnabled, priceFlow: priceFlowEnabled } = logConfig;

  let retries = 0;
  let currentResult = getTopMoversCurrentPrices(topMovers, caller);

  const isAimedAvailabilityReached = currentResult.availability > aimedAvailability;
  const isRetriesLessThanMaximum = (retries <= retriesCount);

  const intervalCallBack = (isSingleCall = false) => {
    currentResult = getTopMoversCurrentPrices(topMovers, caller);
    const { availability, data } = currentResult;

    if (logEnabled && priceFlowEnabled) {
      const { symbol } = data?.[0] ?? {};
      const {
        hasInstrumentSnapshotData, has1DChartData, has3MChartData, hasTradeUpdate, changePercent,
      } = TradePriceCache.get(symbol);
      const singleCall = isSingleCall ? '(single-call) ' : '';
      console.log(`Top-Movers Price Check ${singleCall}(${retries}/${retriesCount}) -- availability:${availability}   symbol-1(${symbol}):${changePercent}${hasInstrumentSnapshotData ? ',IS' : ''}${has1DChartData ? ',1D' : ''}${has3MChartData ? ',3M' : ''}${hasTradeUpdate ? ',trade' : ''} [price-flow]`);
      console.tron.log!(`Top-Movers Price Check ${singleCall}(${retries}/${retriesCount}) -- availability:${availability}   symbol-1(${symbol}):${changePercent}${hasInstrumentSnapshotData ? ',IS' : ''}${has1DChartData ? ',1D' : ''}${has3MChartData ? ',3M' : ''}${hasTradeUpdate ? ',trade' : ''} [price-flow]`);
    }

    if (availability >= aimedAvailability) {
      if (caller === 'TopGainers') {
        updateTopGainers(data);
      } else {
        updateTopLosers(data);
      }
      stopInterval(caller);
      return;
    }

    retries++;
    if (retries > retriesCount) {
      if (caller === 'TopGainers') {
        setTopGainersTimeout();
      } else {
        setTopLosersTimeout();
      }
      stopInterval(caller);
    }
  };

  if (!isAimedAvailabilityReached && isRetriesLessThanMaximum) {
    topMoversIntervalId[caller] = setInterval(intervalCallBack, retriesDelay);
  } else {
    intervalCallBack(true);
  }
};

export const filterButtons = [ lang.commonAll(), lang.mobileStocksLabel(), lang.commonSearchForETFsTitle() ];

export const getListEmptyMessage = (selectedFilter: string) => {
  if (selectedFilter === filterButtons[1]) {
    return lang.mobileNoStocksFound(); // Stock
  }
  if (selectedFilter === filterButtons[2]) {
    return lang.commonNoETFsFound(); // ETFs
  }
  return lang.commonSearchForNoSymbolFound(); // symbol
};

export const parseETFName = (name: string) => {
  if (name.includes('-')) {
    const parts = name.split('-');
    return parts[1].trim();
  }

  return name;
};
