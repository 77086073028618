import { OrderSideEnum } from '../../enums/order-side.enum';
import { TimeInForceEnum } from '../../enums/time-in-force.enum';
import { NullableNumber, TypedObject } from '../../util/types';
import { QueryCacheItemType } from '../trading/types';

export enum GWRequestType {
  Login = 0,
  GetNewsArticles = 1,
  GetNewsSubscriptions = 2,
  MarketDataQuery = 3,
  MarketDataSubscribe = 4,
  MarketDataUnsubscribe = 5,
  NewsArticle = 6,
  NewsSubscribe = 7,
  NewsUnsubscribe = 8,
  GetLatestNewsArticles = 'GetLatestNewsArticles',
  GetLatestNewsArticlesBySymbols = 'GetLatestNewsArticlesBySymbols',
  NewOrderRequest = 'NewOrderRequest',
  CancelOrderRequest = 'CancelOrderRequest',
  ModifyOrderRequest = 'ModifyOrderRequest',
  ParentOrder = 'ParentOrder',
  InstrumentSnapshot = 'InstrumentSnapshot',
  MarketDataSummary = 'MarketDataSummary',
  MarketDataSubscribeTrade = 'MarketDataSubscribeTrade',
  MarketDataUnsubscribeTrade = 'MarketDataUnsubscribeTrade',
  MarketDataSubscribeNbbo = 'MarketDataSubscribeNbbo',
  MarketDataUnsubscribeNbbo = 'MarketDataUnsubscribeNbbo',
  MarketDataSubscribeSymbolStatus = 'MarketDataSubscribeSymbolStatus',
  MarketDataUnsubscribeSymbolStatus = 'MarketDataUnsubscribeSymbolStatus',
  /* internal */
  Ping = 9,
}

export enum GWResponseType {
  Login = 'Login',
  // news
  NewsSubscribe = 'NewsSubscribe',
  // MDS data
  Subscriptions = 'Subscriptions',
  MarketDataQuery = 'MarketDataQuery',
  DataQueryResponse = 'DataQueryResponse',
  MarketDataSummaryResponse = 'DataSummaryResponse',
  InstrumentSnapshotResponse = 'InstrumentSnapshot',
  MarketDataSubscribeSymbolStatus = 'MarketDataSubscribeSymbolStatus',
  MarketDataUnsubscribeSymbolStatus = 'MarketDataUnsubscribeSymbolStatus',
  // order related
  OrderRejectedResponse = 'OrderRejectedResponse',
  ExecutionReport = 'ExecutionReport',
  ModifyOrCancelReject = 'ModifyOrCancelReject',
  // error
  Error = 'Error',
  // TODO: Old - check if not used anymore
  Mixed = 1,
  MarketData = 2,
  News = 3,
  Canary = 4,
  Response = 50
}


export type SORErrorResponse = {
  errorMessage: string;
  messageType?: GWResponseType;
  resultCode?: number;
  traceIdentifier?: string;
  userId?: string;
}

export enum GWMarketDataSubscriptionSubType {
  Unknown = 0,
  Login = 1,
  Heartbeat = 2,
  Trade = 3, // in use
  Bbo = 4,
  Nbbo = 5, // in use
  Subscribe = 6,
  Unsubscribe = 7,
  Ohlcv = 8,
  Query = 9,
  Canary = 10,
  SymbolStatus = 14
}

export enum GWMarketDataQueryBucket {
  OneSecond = 10,
  OneMinute = 20,
  OneDay = 30,
  OneHour = 40
}

export enum GWMarketDataSummaryBucket {
  OneDay = '1D5m',
  OneWeek = '1W15m',
  OneMonth = '1M1h',
  ThreeMonths = '3M1D',
  OneYear = '1Y1W',
  FiveYears = '5Y1M',
  Invalid = 'invalid',
}

export enum GWQueryCase {
  OHLCV = 0,
  News = 1,
  NewsSubscribe = 2,
  NewsUnSubscribe = 3,
  InstrumentSnapshot = 4,
  SmallChartData = 5,
  BigChartData = 6,
  Order = 7,
}

export type GWDataQueryPayload = {
  accessId: string
  traceIdentifier: string
  estimatedDeliveryInSec: number
  cacheData: QueryCacheItemType
  retries?: number
}

export type OHLCVDataRow = {
  close: number
  dateAndTime: string | number// 2021-04-06T10:47:00
  high: number
  low: number
  open: number
  volume: number
}

export type OHLCVGatewayResultsData = {
  materialized: number
  rows: OHLCVDataRow[]
  symbol: string
  type: 'Ohlcv'
}

export type OHLCVResultsSuccessPayload = {
  callName: string
  status?: number
  customData: GWDataQueryPayload
} & {
  [key: number]: OHLCVGatewayResultsData
}

export enum GWQueryResultStatus {
  // Initial state
  INITIAL = -2,
  // Unknown state
  UNKNOWN = -1,
  // Set when request is initiated
  LOADING = 0,
  // Set when response received with status 200
  READY = 200,
  // Set when response received with status 202
  STILL_IN_PROCESSING = 202,
  // Set when response received with status 204
  NOT_FOUND = 204,
}

export type SORCommonOrderResponse = {
  resultCode: number
  requestType: (
    GWRequestType.NewOrderRequest | GWRequestType.ParentOrder
    | GWRequestType.ModifyOrderRequest | GWRequestType.CancelOrderRequest
    | null
    )
  messageType: (
    GWResponseType.OrderRejectedResponse
    | GWResponseType.ExecutionReport
    | GWResponseType.ModifyOrCancelReject
    | GWResponseType.Error
    )
  traceIdentifier: string
  userId: string,
  status: SOROrderStatus,
  clientOrderId: string,
}

export type SORNewOrderResponse = SORCommonOrderResponse & {
  // TODO: SOR-Implement - This type needs to be checked with SOR team and real data responses (when OMS is enabled)
  executionId?: string
  orderId?: string
  message?: string
}

export type SORParentOrderResponse = SORCommonOrderResponse & {
  executionId: string
  orderId: string
  // TODO: SOR-Implement - This type needs to be checked with SOR team and real data responses (when OMS is enabled)
}
export type SORModifyOrCancelOrderResponse = SORCommonOrderResponse & {
  parentOrderId: string;
  cancelRejectReason?: SORCancelRejectReason;
  cancelRejectResponseTo?: any; // TODO: Get enum from Gateway if needed
  fixSequenceNumber?: number;
  text?: string;
}

/** FixOrderStatus in SOR/Gateway */
export enum SOROrderStatus {
  New = 0,
  PartialFill = 1,
  Fill = 2,
  DoneForDay = 3,
  Canceled = 4,
  Replace = 5,
  PendingCancel = 6,
  Rejected = 8,
  PendingNew = 17,
  Expired = 19,
  PendingReplace = 21,
  PartiallyReject = 108,
  RejectedInternally = 111,
  FailedInternally = 112
}
export enum TenNSOROrderStatus {
  Pending = '0',
  PartialFill = '1',
  Filled = '2',
  Cancelled = '3',
  Rejected = '4',
  Expired = '5',
  PartialReject = '41'
}

// TODO: Sync with SOR
export enum SOROrderType {
  Market = 1,
  Limit = 2,
  Stop = 3,
}

export enum SOROrderTimeInForce {
  Day = 0,
  GTC = 1,
  OnOpen = 2,
  ImmediateOrCancel = 3,
  GTD = 6,
  OnClose = 7,
}

export type SORExecutionReportResponse = Omit<SORCommonOrderResponse, 'requestType'> & {
  // TODO: SOR-Implement - Define SORExecutionReportResponse
  status: SOROrderStatus
  executionReportCount: number
  filled: number
  side: 'Buy' | 'Sell'
}

export type SORRequestDataOrderType = 'MarketOrder' | 'LimitOrder' | 'StopOrder' | null

export type SOROrderRequestDataBase = {
  clientOrderId: string
  type?: SORRequestDataOrderType
  account: string
  quantity: number
  stopPrice?: NullableNumber
  // TODO: Synchronize with usage in Reporting as enum (SOROrderTimeInForce) instead of string (TimeInForceEnum).
  timeInForce: TimeInForceEnum
  destination: string
  side: OrderSideEnum
  symbol: string
  price?: number
  tradingSessionId?: SORExtendedHoursMarker,
  traceIdentifier?: string,
}

/**
 * X - include extended hours
 * M - market hours only
 */
export type SORExtendedHoursMarker = 'M' | 'X'

export enum SOROrderSide {
  Buy = 1,
  Sell = 2,
  SellShort = 5
}

export const WebSocketReadyStateAsString: TypedObject<string> = {
  0: 'Connecting',
  1: 'Open',
  2: 'Closing',
  3: 'Closed',
};

export enum SORCancelRejectReason {
  TooLateToCancel = 0,
  UnknownOrCompleted = 1,
  BrokerOption = 2,
  AlreadyPendingCancel = 3,
  DuplicateClientOrderId = 6,
}

export type OHLCVStateData = {
  data: OHLCVDataRow[],
  from?: number,
  to?: number,
}
