import { EnrollPersonalDetailsForm } from './EnrollPersonalDetails.types';

export const EnrollFirstNameSelector = 'enroll-personal-details';
export const EnrollMiddleNameSelector = 'enroll-middle-details';
export const EnrollLastNameSelector = 'enroll-last-details';
export const EnrollDateOfBirthSelector = 'enroll-date-of-birth';
export const EnrollPlaceOfBirthSelector = 'enroll-place-of-birth';
export const EnrollPrimaryCitizenshipSelector = 'enroll-primary-citizenship';
export const EnrollTaxNumberSelector = 'enroll-tax-number';
export const EnrollSecondaryCitizenshipIdSelector = 'enroll-secondary-citizenship';
export const EnrollSecondaryTaxCountryIdSelector = 'enroll-secondary-tax-country';
export const EnrollOtherCitizenshipIdSelector = 'enroll-other-citizenship';
export const EnrollOtherTaxCountryIdSelector = 'enroll-other-tax-country';
export const EnrollOtherTaxNumberSelector = 'enroll-other-tax-number';

export const InitialEnrollPersonalDetailsForm: EnrollPersonalDetailsForm = {
  [EnrollFirstNameSelector]: '',
  [EnrollLastNameSelector]: '',
  [EnrollDateOfBirthSelector]: '',
  [EnrollPlaceOfBirthSelector]: '',
  [EnrollPrimaryCitizenshipSelector]: '',
  [EnrollTaxNumberSelector]: '',
  [EnrollSecondaryTaxCountryIdSelector]: null,
  [EnrollSecondaryCitizenshipIdSelector]: null,
  [EnrollOtherCitizenshipIdSelector]: null,
  [EnrollOtherTaxCountryIdSelector]: null,
  [EnrollOtherTaxNumberSelector]: '',
};
