/* eslint-disable import/named */
import { Dispatch } from 'react';

import { MARKET_DATA_TIME_ZONE } from '../../lib/constants/date-time.constants';
import { PredefinedMilisecondsTypeEnum } from '../../lib/enums/predefined-miliseconds-type.enum';
import { Company } from '../../lib/models/company';
import { GWMarketDataQueryBucket, OHLCVDataRow } from '../../lib/models/gateway/types';
import store from '../../lib/store';
import { getMarketDataQuery } from '../../lib/store/market-data/index';
import TradePriceCache from '../../lib/store-util/TradePriceCache';

import {
  Bar, DatafeedConfiguration, LibrarySymbolInfo, ResolutionString,
} from '../../charting_library/charting_library';

import { compareTradePriceAndOHLCV, extractUnixDays } from './helpers';

// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map();
let daysCounter = 4;
let lastDayFromDate = 0;
let lastMinuteFromDate = 0;
let lastHourFromData = 0;
let interval: NodeJS.Timeout;

const exportedObject = (
  chartMinuteData: OHLCVDataRow[],
  chartHourData: OHLCVDataRow[],
  chartDayData: OHLCVDataRow[],
  dispatch: Dispatch<any>,
  company?: Company,
) => {
  let minuteData = [...chartMinuteData];
  let hourData = [...chartHourData];
  let dayData = [...chartDayData];
  const configurationData: DatafeedConfiguration = {
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
      { name: 'Stock', value: 'stock' },
    ],
    // supports_time: true,
    supports_marks: false,
    supports_timescale_marks: false,
    supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'] as ResolutionString[],
  };

  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData), 0);
    },

    // This feature is not going to be supported by 10n
    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
      onResultReadyCallback([]);
    },

    resolveSymbol: (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
    ) => {
      if (!company) {
        onResolveErrorCallback('Cannot resolve symbol');
      } else {
        const symbolInfo: LibrarySymbolInfo = {
          data_status: 'streaming',
          description: company.companyName,
          exchange: company.exchange,
          format: 'price',
          full_name: company.companyName,
          has_daily: true,
          has_intraday: true,
          intraday_multipliers: ['1', '60'],
          listed_exchange: company.exchange,
          minmov: 1,
          name: company.symbol,
          pricescale: 100,
          delay: PredefinedMilisecondsTypeEnum.oneSecond,
          session: '0930-1600',
          supported_resolutions: configurationData.supported_resolutions as ResolutionString[],
          timezone: MARKET_DATA_TIME_ZONE,
          type: 'stock',
          volume_precision: 2,
          visible_plots_set: 'ohlcv',
        };
        setTimeout(() => { onSymbolResolvedCallback(symbolInfo); }, 0);
      }
    },

    getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      const { countBack, firstDataRequest } = periodParams;
      if (firstDataRequest) {
        minuteData = [ ...chartMinuteData];
        hourData = [...chartHourData];
        dayData = [...chartDayData];
      }

      if (resolution === '1' && !minuteData.length) {
        const fromDate = store.getState()?.gateway.ohlcvData.minuteData?.from;
        const dateStartTime = new Date(fromDate);
        const dateEndTime = new Date(fromDate);

        if (lastMinuteFromDate !== fromDate) {
          dispatch(getMarketDataQuery({
            symbols: [ symbolInfo.name ],
            bucket: GWMarketDataQueryBucket.OneMinute,
            startTime: new Date(dateStartTime.setDate(dateStartTime.getDate() - 5)).toISOString(),
            // substracting one minute is needed in order to avoid date overflows.
            endTime: new Date(dateEndTime.setMinutes(dateEndTime.getMinutes() - 1)).toISOString(),
          }));
          lastMinuteFromDate = fromDate;
        }
        minuteData = [...store.getState()?.gateway.ohlcvData.minuteData.data];
      }

      if (resolution === '60' && !hourData.length) {
        const fromDate = store.getState()?.gateway.ohlcvData.hourData?.from;
        const dateStartTime = new Date(fromDate);
        const dateEndTime = new Date(fromDate);

        if (store.getState().gateway.ohlcvData?.error) {
          onHistoryCallback([], { noData: true });
        } else if (lastHourFromData !== fromDate) {
          dispatch(getMarketDataQuery({
            symbols: [ symbolInfo.name ],
            bucket: GWMarketDataQueryBucket.OneHour,
            startTime: new Date(dateStartTime.setDate(dateStartTime.getDate() - 89)).toISOString(),
            // substracting one hour is needed in order to avoid date overflows.
            endTime: new Date(dateEndTime.setHours(dateEndTime.getHours() - 1)).toISOString(),
          }));
          lastHourFromData = fromDate;
        }
        hourData = [...store.getState().gateway?.ohlcvData.hourData.data];
      }

      if (resolution === '1D' && !dayData.length) {
        const fromDate = store.getState().gateway.ohlcvData.dayData?.from;
        const toDate = store.getState().gateway.ohlcvData.dayData?.to;
        const dateStartTime = new Date(fromDate);
        const dateEndTime = new Date(fromDate);

        /**
         * currentFromDate is needed, beacuse of the asynchronous respond. If we don't have update in our store,
         * currentFromDate will be more than the fromDate
         * daysCounter starts from four, because we can hit a weekend and the rense can skip up to 2 days
        * */
        const currentFromDate = extractUnixDays(
          toDate, daysCounter,
        ).setUTCFullYear(new Date(toDate).getUTCFullYear() - 5);

        if (store.getState().gateway.ohlcvData?.error || fromDate > currentFromDate) {
          onHistoryCallback([], { noData: true });
        } else if (lastDayFromDate !== fromDate) {
          dispatch(getMarketDataQuery({
            symbols: [ symbolInfo.name ],
            bucket: GWMarketDataQueryBucket.OneDay,
            startTime: new Date(dateStartTime.setFullYear(dateStartTime.getFullYear() - 5)).toISOString(),
            // substracting one day is needed in order to avoid date overflows.
            endTime: new Date(dateEndTime.setDate(dateEndTime.getDate() - 1)).toISOString(),
          }));
          lastDayFromDate = fromDate;
          daysCounter += 1;
        }
        dayData = [...store.getState().gateway?.ohlcvData.dayData.data];
      }

      let bars: Bar[] = [];
      let barData = dayData;
      if (resolution === '60') {
        barData = hourData;
      }
      if (resolution === '1') {
        barData = minuteData;
      }

      barData.splice(-countBack).forEach((bar: OHLCVDataRow) => {
        bars = [
          ...bars, {
            time: Number(bar.dateAndTime),
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close,
            volume: bar.volume,
          }];
      });

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
      }

      setTimeout(() => onHistoryCallback(
        bars, { noData: !barData.length },
      ), PredefinedMilisecondsTypeEnum.twoHundredMilliseconds);
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onTick,
      subscriberUID,
      onResetCacheNeededCallback,
    ) => {
      const lastBar = lastBarsCache.get(symbolInfo.full_name);

      interval = setInterval(() => {
        const { currentPrice, tradeTime } = TradePriceCache.get(symbolInfo.name);

        if (tradeTime) {
          const tradeTimeUnix = new Date(tradeTime).getTime();
          const lastUpdate = compareTradePriceAndOHLCV(currentPrice, tradeTimeUnix, lastBar, resolution);
          const { open, high, low, close, time } = lastUpdate;

          onTick({ time, open, high, low, close });
          lastBarsCache.set(symbolInfo.full_name, { ...lastUpdate });
        }
      }, PredefinedMilisecondsTypeEnum.fiveSeconds);
    },

    unsubscribeBars: (subscriberUID) => {
      clearInterval(interval);
    },
  };
};
export default exportedObject;
