import PasswordValidator from 'password-validator';

// --- Password validation ---
export type PasswordValidationResult = {
  eightSymbols: boolean,
  maxCharacters: boolean,
  uppercaseLetter: boolean,
  lowercaseLetter: boolean,
  specialChar: boolean,
  number: boolean,
  onlyLatinCharacters: boolean;
}

export const PasswordValidationResultCleared = {
  eightSymbols: false,
  maxCharacters: true,
  uppercaseLetter: false,
  lowercaseLetter: false,
  specialChar: false,
  number: false,
  onlyLatinCharacters: false,
};

const PasswordSchema = new PasswordValidator();
PasswordSchema
  .is()
  .min(8)
  .max(30)
  .uppercase()
  .lowercase()
  .digits(1)
  .symbols(1);

export const validatePasswordString = (validationTarget: string): PasswordValidationResult => {
  const errorsList = PasswordSchema.validate(validationTarget, { list: true });

  const charCodes = validationTarget.split('').map(item => item.charCodeAt(0));
  const hasOnlyLatinCharacters = charCodes.filter(code => code > 126).length === 0;

  if (typeof errorsList === 'boolean') {
    return {
      eightSymbols: false,
      maxCharacters: false,
      uppercaseLetter: false,
      lowercaseLetter: false,
      specialChar: false,
      number: false,
      onlyLatinCharacters: false,
    };
  }

  return {
    eightSymbols: !(errorsList.indexOf('min') > -1),
    maxCharacters: !(errorsList.indexOf('max') > -1),
    uppercaseLetter: !(errorsList.indexOf('uppercase') > -1),
    lowercaseLetter: !(errorsList.indexOf('lowercase') > -1),
    specialChar: !(errorsList.indexOf('symbols') > -1),
    number: !(errorsList.indexOf('digits') > -1),
    onlyLatinCharacters: validationTarget ? hasOnlyLatinCharacters : false,
  };
};
