import { Dispatch } from 'redux';

import config from '../../config';
import { MOCK } from '../../configDebug';
import configLib from '../../configLib';
import { getProp } from '../util/ObjectTools';

import { FundamentalService } from './fundamental/Fundamental.service';
import {
  OfflineAmsService,
  OfflineAuthService,
  OfflineCRMService,
  OfflineFileService,
  OfflineFundamentalService,
  OfflineGatewayProxy,
  OfflineMarketDataService,
  OfflineNewsService,
  OfflinePaymentService,
  OfflineReportingFileService,
  OfflineReportingService,
  OfflineTradingService,
} from './offline/OfflineGateway';
import { AMSService } from './AMS.service';
import { CRMService } from './CRM.service';
import { FileService } from './File.service';
import GatewayRestService from './GatewayRest.service';
import GatewayWSService from './GatewayWS.service';
import { MarketDataClientService } from './MarketDataClient.service';
import { NewsService } from './News.service';
import { PaymentService } from './Payment.service';
import { ReportingService } from './Reporting.service';
import { ReportingFileService } from './ReportingFile.service';
import { SESService } from './SES.service';
import { TradeClientService } from './TradeClient.service';
import { GatewayServiceType, IMarketDataService } from './types';


function getConfigPath(name: string) {
  switch (name) {
    case 'fund': return 'fundamental.url';
    case 'auth': return 'auth.issuer';
    case 'md': return 'marketData.url';
    case 'trade': return 'trading.url';

    case 'gw-ws':
    case 'gw-rest':
      return 'gateway.url';

    default: return `${name}.url`;
  }
}

function l(s: string, source?: string) {
  let url = getProp(config, getConfigPath(s));
  if (MOCK.ENABLED) {
    console.info(`[${s.toUpperCase()}] Creating MOCK service client ${source != null ? `[${source}]` : ''}`);
  } else {
    console.info(
      `[${s.toUpperCase()}] Creating ONLINE service client ${source != null ? `[${source}]` : ''} - ${url}`,
    );
  }
}

export let marketDataService: IMarketDataService;
export let globalService: any;
export let gatewayWs: GatewayWSService;
export let gatewayRest: GatewayRestService;


class Services implements GatewayServiceType {
  createAuthService(source?: string) { l('auth', source); return MOCK.ENABLED ? new OfflineAuthService() : new (configLib.AuthService())(); }
  createCRMService(token: string | null) { l('crm'); return MOCK.ENABLED ? new OfflineCRMService() : new CRMService(token); }
  createTradingService(dispatch: Dispatch, source?: string) { l('trade', source); return MOCK.ENABLED ? new OfflineTradingService(dispatch) : new TradeClientService(dispatch); }
  createMarketDataService(dispatch: Dispatch) { l('md'); return MOCK.ENABLED ? new OfflineMarketDataService(dispatch) : new MarketDataClientService(dispatch); }
  createFundamentalService(token: string) { l('fund'); return MOCK.ENABLED ? new OfflineFundamentalService() : new FundamentalService(token); }
  createNewsService(token: string) { l('news'); return MOCK.ENABLED ? new OfflineNewsService() : new NewsService(token); }
  createReportingService(token: string) { l('reporting'); return MOCK.ENABLED ? new OfflineReportingService() : new ReportingService(token); }
  createReportingFileService(token: string) { l('reportingFile'); return MOCK.ENABLED ? new OfflineReportingFileService() : new ReportingFileService(token); }
  createFileService(token: string) { l('file'); return MOCK.ENABLED ? new OfflineFileService() : new FileService(token); }
  createPaymentService(token: string) { l('payment'); return MOCK.ENABLED ? new OfflinePaymentService() : new PaymentService(token); }
  createAmsService(token: string) { l('ams'); return MOCK.ENABLED ? new OfflineAmsService() : new AMSService(token); }
  createSESService(token: string) { l('ses'); return new SESService(token); }
  // Gateway
  createGatewayWSService(dispatch: Dispatch) { l('gw-ws'); return MOCK.ENABLED ? new OfflineGatewayProxy(dispatch) as any : new GatewayWSService(dispatch, undefined); }
  createGatewayRestService(token: string) { l('gw-rest'); return MOCK.ENABLED ? new OfflineGatewayProxy(null) as any : new GatewayRestService(token); }
}

const services = new Services();

export default services;
