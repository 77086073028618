import React, { useState } from 'react';

import lang from '../../../lib/language';

import { BaseModal, Button } from '../../../components/index';

import styles from './UploadErrorModal.module.scss';

type Props = {
    handleClose: (isClosed: boolean) => void;
}

const UploadErrorModal = ({ handleClose }: Props) => (
  <BaseModal isOpen>
    <h6 className={styles.title}>{lang.commonUploadDocumentErrorTitle()}</h6>
    <div className={styles.body}>
      <p>{lang.commonUploadDocumentErrorBody()}</p>
      <p>{lang.commonUploadDocumentErrorBody1()}</p>
    </div>
    <div>
      <Button fullWidth onClick={() => handleClose(false)}>
        {lang.commonOkay()}
      </Button>
    </div>
  </BaseModal>
);

export default UploadErrorModal;
