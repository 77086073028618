import { SuccessPayload } from '../store/common-actions';

export interface News {
    id: string;
    dateTime: number;
    headline: string;
    source: string;
    url: string;
    summary: string;
    related: string;
    symbols: Array<string>;
    image: string;
    lang: string;
    hasPaywall: boolean;
}

export type NewsForSymbols = {
  [symbol: string]: News[];
}

export type NewsData = SuccessPayload & {
  totalCount: number
  totalPageCount: number
  pageNumber: number
  pageSize: number
  data: News[]
  resultCode?: number
}

export const DEFAULT_PAGE_SIZE = 5;
