import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Action } from 'redux';

import { OrderSideEnum, OrderStatusEnum, TimeInForceEnum } from '../../lib/enums';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { ReportingCall } from '../../lib/models/reporting/types';
import { Order } from '../../lib/models/trading/types';
import { RootState } from '../../lib/store';
import {
  getAccountTradeHistory,
  getOpenPositions as getPositions,
  setHasOrderUpdate,
} from '../../lib/store/reporting';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, OpenPosition } from '../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../lib/store/selectors';
import { modifyOrder as serviceModifyOrder } from '../../lib/store/trading/index';
import MarketStateCache from '../../lib/store-util/MarketStateCache';
import NBBOPriceCache from '../../lib/store-util/NBBOPriceCache';
import TradePriceCache from '../../lib/store-util/TradePriceCache';
import { formatCurrency, formatNumber } from '../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../lib/util/error-handling/StatusByCallHelpers';
import { isMarketClosed, isMarketOpen, isMarketRegularHours } from '../../lib/util/MarketDataHelpers';
import { checkSymbolPostionType } from '../../lib/util/my-account-helpers';
import { TextHelpers } from '../../lib/util/TextHelpers';
import { uuidv4 } from '../../lib/util/tools';
import { getOrderTotalValue } from '../../lib/util/TradingValidationsHelper';

import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import { Button, CheckboxControl, ClickableDiv, Error, NotEnoughBuyingPower } from '../../components';
import ConfirmOrderPopupMobile from '../../components/ConfirmOrderPopupMobile/ConfirmOrderPopupMobile';
import HighLowLimitPriceAlert from '../../components/HighLowLimitPriceAlert/HighLowLimitPriceAlert';
import ExpiryDate from '../../components/OrderActions/components/ExpiryDate';
import LimitPriceInput from '../../components/OrderActions/components/LimitPriceInput';
import MarketPriceInfo from '../../components/OrderActions/components/MarketPriceInfo';
import { ExpirationDropDownOptions } from '../../components/OrderActions/order.constants';
import { getValidationPrice } from '../../components/OrderActions/OrderHelpers';
import useValidateOrder from '../../components/OrderActions/useValidateOrder';
import SelectorMobile from '../../components/SelectorMobile/SelectorMobile';
import SharesCalculator from '../../components/SharesCalculator/SharesCalculator';

import styles from './Modify.module.scss';

type Props = ReduxProps;

function Modify(props: Props) {
  const {
    positions,
    buyingPow,
    getOrders,
    myStocksUpdate,
    getOpenPositions,
    modifyOrder,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const receivedData = location.state;

  const {
    symbol,
    parentOrderId,
    orderSide,
    orderType,
    initialPrice,
    tradingSessionId,
    initialTimeInForce,
    orderQuantity,
    origin,
  } = receivedData;

  const isBuyOrder = orderSide === OrderSideEnum.Buy;

  const { marketState } = MarketStateCache.use();
  const showMarketInfoIcon = isMarketOpen(marketState);
  const marketClosed = isMarketClosed(marketState);

  const [criticalError, setCriticalError] = useState<string>('');
  const [targetPriceError, setTargetPriceError] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(orderQuantity);
  const [targetPrice, setTargetPrice] = useState<number>(initialPrice);
  const [timeInForce, setTimeInForce] = useState<string>(TextHelpers.getTimeInForceName(initialTimeInForce));
  const [includeExtendedHours, setIncludeExtendedHours] = useState<boolean>(tradingSessionId === 'X');
  const [showBP, setShowBP] = useState<boolean>(true);
  const [openedEnoughBPModal, setOpenedEnoughBPModal] = useState<boolean>(false);
  const [visibleHighLowLimitPriceAlert, setVisibleHighLowLimitPriceAlert] = useState<boolean>(false);
  const [showReviewOrder, setShowReviewOrder] = useState<boolean>(false);
  const [limitPriceFocused, setLimitPriceFocused] = useState<boolean>(false);
  const [isUserDeviceIos, setIsUserDeviceIos] = useState<boolean>(false);

  const nbboData = NBBOPriceCache.use(symbol);
  const { askprice, bidprice } = nbboData;
  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'CreateOrder');
  const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice, isBuyOrder);
  const { errorMessage, controlCheck } = useValidateOrder(orderType, validationPirce ?? 0, targetPrice, isBuyOrder);
  const positionType = checkSymbolPostionType(symbol, positions);

  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
      setIsUserDeviceIos(true);
    }
  }, []);

  useEffect(() => {
    if (positionType) {
      if ((orderSide === OrderSideEnum.Buy && positionType === OrderSideEnum.Sell)
      || (orderSide === OrderSideEnum.Sell && positionType === OrderSideEnum.Buy)) {
        setShowBP(false);
      } else {
        setShowBP(true);
      }
    } else {
      setShowBP(orderSide === OrderSideEnum.Buy);
    }
  }, [orderSide, positionType]);

  const totalValue = getOrderTotalValue(
    orderType,
    quantity,
    tradePrice,
    targetPrice,
  );

  const isValidOrder = useCallback((qty: number, buyingPower: number): boolean => {
    setTargetPriceError(errorMessage);

    const buyingPowerCheck = buyingPower > totalValue;

    if (showBP && (controlCheck && !buyingPowerCheck)) {
      setOpenedEnoughBPModal(true);
    }

    if (!showBP) {
      return (orderQuantity >= qty) && controlCheck;
    }

    return buyingPowerCheck && controlCheck;
  }, [controlCheck, errorMessage, orderQuantity, showBP, totalValue]);

  const onModifyClicked = () => {
    setCriticalError('');
    setTargetPriceError('');

    if (isValidOrder(quantity, buyingPow)) {
      setShowReviewOrder(true);
    }
  };

  const onReviewOrderClicked = () => {
    const price = tradePrice ?? 0;
    if (isValidOrder(quantity, buyingPow)) {
      const orderObject: Order = {};

      if (orderType === OrderTypeEnum.Stop) {
        orderObject.stopPrice = targetPrice;
      }

      const parsedTime = timeInForce === TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay)
        ? TimeInForceEnum.EndOfDay : TimeInForceEnum.GTC;

      orderObject.clientOrderId = uuidv4();
      orderObject.symbol = symbol;
      orderObject.side = orderSide as OrderSideEnum;
      orderObject.orderQty = quantity;
      orderObject.ordType = orderType as OrderTypeEnum;
      orderObject.price = targetPrice;
      orderObject.timeInForce = parsedTime;
      orderObject.originalClientOrderId = parentOrderId;
      orderObject.includeExtendedHours = includeExtendedHours;
      orderObject.traceIdentifier = OrderStatusEnum.Modified;

      if (orderType === OrderTypeEnum.Limit && !visibleHighLowLimitPriceAlert) {
        if (
          (isBuyOrder && (orderObject.price > price))
          || (!isBuyOrder && (orderObject.price < price))
        ) {
          setVisibleHighLowLimitPriceAlert(
            isBuyOrder ? (orderObject.price > price) : (orderObject.price < price),
          );

          return;
        }
      }
      modifyOrder(orderObject);
      myStocksUpdate(true);
      if (!marketClosed) {
        getOpenPositions();
      }
      getOrders({ symbol, tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
      sessionStorage.setItem('showUseDetailsEndModal', 'true');
      history.replace({
        pathname: origin,
        state: { data: orderObject },
      });
    }
  };

  const onLimitFocus = () => {
    setLimitPriceFocused(true);
  };

  const onLimitBlur = () => {
    setTimeout(() => {
      setLimitPriceFocused(false);
    }, 150);
  };

  return (
    <>
      <div className={styles.wrapperOrderContainer}>
        <ClickableDiv
          className={styles.backArrow}
          onClick={() => {
            history.replace({
              pathname: origin,
            });
          }}
        >
          <img src={ArrowBack} alt="Back" />
        </ClickableDiv>
        <h6 className={styles.title}>{`${lang.commonModify()} ${symbol} ${orderSide} ${lang.commonTradingOrder()}`}</h6>
        <div className={styles.wrapperOrderType}>
          <div>{lang.commonTradingOrderType()}</div>
          <div className={styles.rigthAligned}>{orderType}</div>
        </div>
        <div className={styles.wrapperNumberOfShares}>
          <div className={styles.numberOfShares}>
            <div>{lang.mobileTradingTicketNumberOfShares()}</div>
            {!showBP && (
            <p className={styles.numberOfSharesSubText}>
              {
            isBuyOrder
              ? lang.webTradingShortedStocks(formatNumber(orderQuantity, true))
              : lang.webTradingAvailableShares(formatNumber(orderQuantity, true))
          }
            </p>
            )}
          </div>
          <div className={styles.wrapperSharesField}>
            <SharesCalculator
              quantity={quantity}
              setQuantity={setQuantity}
              maxQuantity={!showBP ? orderQuantity : undefined}
            />
          </div>
        </div>
        <LimitPriceInput
          initialPrice={initialPrice}
          orderType={orderType}
          onFocus={onLimitFocus}
          onBlur={onLimitBlur}
          targetPriceError={targetPriceError}
          setTargetPrice={(value) => setTargetPrice(value)}
          setTargetPriceError={(value) => setTargetPriceError(value)}
        />
        <MarketPriceInfo
          askPrice={askprice}
          bidPrice={bidprice}
          isMyAccount
          symbol={symbol}
          tradePrice={tradePrice}
          isMarketOpen={showMarketInfoIcon}
        />
        {orderType !== OrderTypeEnum.Market && (
        <ExpiryDate
          isMarketHours={isMarketRegularHours(marketState)}
          time={timeInForce}
          extendedHours={includeExtendedHours}
          positionsBySymbol={positions.find((position: OpenPosition) => (position.symbol === symbol))}
          orderType={orderType}
          orderSide={orderSide}
          setTimeInForce={(value) => setTimeInForce(value)}
          setIncludeExtendedHours={(value) => setIncludeExtendedHours(value)}
        />
        )}
        <div className={styles.horizontalLine} />
        <div className={styles.availableBuyingContainer}>
          <div>{lang.commonTradingOrderValue()}</div>
          <div className={styles.rigthAligned}>
            {formatCurrency(totalValue)}
          </div>
        </div>
        {showBP && (
        <div className={styles.availableBuyingContainer}>
          <div>{lang.mobileOrderCreateBuyingPower()}</div>
          <div className={styles.rigthAligned}>{formatCurrency(buyingPow)}</div>
        </div>
        )}
        {(criticalError.trim() !== '') && (
        <div className={styles.errorWrapper}>
          <Error
            hiddenIcon
            errorMessage={criticalError}
          />
        </div>
        )}
        <button
          className={
            (isUserDeviceIos && limitPriceFocused) || window.innerHeight < 720
              ? styles.submitButtonIos
              : styles.submitButton
          }
          type="button"
          onClick={onModifyClicked}
        >
          {lang.commonModify()}
        </button>
        { openedEnoughBPModal && <NotEnoughBuyingPower closeModal={() => setOpenedEnoughBPModal(false)} /> }
        {visibleHighLowLimitPriceAlert && (
        <HighLowLimitPriceAlert
          isBuyOrder={isBuyOrder}
          processModal={onReviewOrderClicked}
          toggleModal={() => setVisibleHighLowLimitPriceAlert(false)}
        />
        )}
        {showReviewOrder && (
          <ConfirmOrderPopupMobile
            symbol={symbol}
            action={orderSide}
            orderType={orderType}
            sharescount={quantity}
            marketPrice={tradePrice}
            targetPrice={targetPrice}
            orderValue={totalValue}
            onCancel={setShowReviewOrder}
            onConfirm={onReviewOrderClicked}
          />
        )}
      </div>
    </>
  );
}

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  isOpenPositionsReady: isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall),
  isPendingOrdersReady: isCallStatusReady(ReportingCall.getAccountPendingOrders, state.reporting.statusByCall),
  buyingPow: getCurrentBuyingPower(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  modifyOrder: (order: Order) => dispatch(serviceModifyOrder(order)),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getOpenPositions: (symbol?: string) => dispatch(getPositions({ symbol })),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Modify);
