import { produce } from 'immer';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { MarketStateInstrumentSnapshot } from '../models/market-data/types';
import { BasicSymbolStat, InstrumentSnapshotMap } from '../store/market-data/types';
import { isOneOf } from '../util/DataHelpers';

import MarketStateCache from './MarketStateCache';


const StatsCache = entity<Record<string, BasicSymbolStat>>({}, entityReduxLogger('Stats', 'stats'));

export function updateStatsCache(newData: InstrumentSnapshotMap) {
  const { marketStateInstrumentSnapshot } = MarketStateCache.get();

  const shouldSelectAfterMarket = isOneOf(marketStateInstrumentSnapshot, [
    MarketStateInstrumentSnapshot.IS_AfterMarket,
    MarketStateInstrumentSnapshot.IS_MarketClosed,
  ]);

  const shouldSelectRegularOrMarket = shouldSelectAfterMarket
  || marketStateInstrumentSnapshot === MarketStateInstrumentSnapshot.IS_RegularHours;

  StatsCache.set(produce(state => {
    Object.entries(newData).forEach(([ key, value ]) => {
      state[key] = {
        open: shouldSelectRegularOrMarket ? value.open : value.prevOpen,
        low: shouldSelectRegularOrMarket ? value.low : value.prevLow,
        high: shouldSelectRegularOrMarket ? value.high : value.prevHigh,
        prevClose: shouldSelectAfterMarket ? value.close : value.prevClose,
        prevVolume: shouldSelectRegularOrMarket ? value.volume : value.prevVolume,
      };
    });
  }));
}

export default {
  get: (symbol: string) => StatsCache.get()[symbol],
  use: (symbol: string) => StatsCache.use()[symbol],
  useAll: StatsCache.use,
};
