import lang from '../../lib/language';

export const WATCH_LIST_TABLE_HEADERS = ['Symbol/Name', 'Chart', 'Last Price', 'Change', '%Change'];
export const WATCH_LIST_TABLE_ITEMS = [
  'date',
  'priceRange',
  'industry',
  'time',
  'status',
  'ratio',
  'eventType',
  'ordType',
  'orderQty',
  'price',
  'orderValue',
  'purchasePrice',
  'investments',
  'marketValue',
  'valueShare',
  'type',
  'marketPrice',
  'unrealizedProfit',
  'createdAt',
  'updatedAt',
  'buttons',
  'quantity',
  'type',
  'setPrice',
  'expiryDate',
  'datePlaced',
];
export const TABLE_HEADERS_EARNINGS_CALENDARS = [
  lang.headerAccountBreakdownSymbolName(),
  lang.commonDate(),
  lang.headerEarningsCalendarEarningsCallTime(),
  lang.headerEarningsCalendarEarningsPrevEPS(),
  lang.headerEarningsCalendarEarningsEPSEstimate(),
];
export const TABLE_HEADERS_PENDING_ORDERS = [
  lang.commonDate(),
  lang.headerMyAccountPendingOrderType(),
  lang.headerMyAccountPendingOrderQuantity(),
  lang.headerMyAccountPendingOrderSetPrice(),
  lang.headerMyAccountPendingOrderExpiryDate(),
  lang.headerMyAccountPendingOrderButtons(),
];
export const TABLE_HEADERS_MY_ACCOUNT_MY_STOCKS = [
  lang.headerMyAccountMyStockSymbolName(),
  lang.headerMyAccountMyStockQuantity(),
  lang.headerMyAccountMyStockPurchasePrice(),
  lang.headerMyAccountMyStockMarketPrice(),
  lang.headerMyAccountMyStockUnrealizedProfit(),
  lang.headerMyAccountMyStockButtons(),
];

export const TABLE_HEADERS_SYMBOL_DETAILS_PENDING_ORDERS = [
  lang.headerMyAccountPendingOrderDatePlaced(),
  lang.headerMyAccountPendingOrderType(),
  lang.headerMyAccountPendingOrderQuantity(),
  lang.headerMyAccountPendingOrderSetPrice(),
  lang.headerMyAccountPendingOrderExpiryDate(),
  lang.headerMyAccountPendingOrderButtons(),
];

export const WATCH_LIST_TABLE_HEADERS_MY_ACCOUNT_PENDING_ORDERS = [
  lang.headerMyAccountPendingOrderSymbolName(),
  ...TABLE_HEADERS_SYMBOL_DETAILS_PENDING_ORDERS,
];

export const symbolListData = {
  APH: {
    change: 3,
    changePercent: 3,
    company: 'Test',
    currentPrice: 90,
    openingPrice: 180,
    recentPrices: [],
    state: 0,
    symbol: 'APH',
  },
  EA: {
    change: 0,
    changePercent: 0,
    company: 'Test',
    currentPrice: 5,
    openingPrice: 180,
    recentPrices: [],
    state: 0,
    symbol: 'EA',
  },
  CRSR: {
    change: -0.100,
    changePercent: -1,
    company: 'Test',
    currentPrice: 10,
    openingPrice: 180,
    recentPrices: [],
    state: 0,
    symbol: 'CRSR',
  },
  NVDA: {
    change: 40,
    changePercent: 4,
    company: 'Test',
    currentPrice: 32,
    openingPrice: 180,
    recentPrices: [],
    state: 0,
    symbol: 'NVDA',
  },
  MSTR: {
    change: -3,
    changePercent: -0.30,
    company: 'Test',
    currentPrice: 101,
    openingPrice: 180,
    recentPrices: [],
    state: 0,
    symbol: 'MSTR',
  },
};

export const WATCH_LIST_TABLE_TRANSACTIONS = [
  lang.commonGeneralQuestionsTypeLabel(),
  lang.commonDate(),
  lang.mobileDepositAmountLabel(),
];
