export const basicColors = [
  '#916FDD',
  '#FD9173',
  '#FECF4C',
  '#41B7D4',
  '#00BB68',
  '#256D82',
  '#6CE4E7',
  '#78DC4F',
  '#31356E',
  '#FE6CB7',
  '#D5E95A',
  '#FE95B6',
  '#195397',
  '#158F76',
  '#FBDF70',
  '#EF478B',
  '#708BD2',
  '#398F7F',
  '#C5DD27',
  '#B87285',
  '#F0CE65',
  '#F266CB',
  '#00648E',
  '#00A36B',
  '#FEB76B',
  '#FE7BD4',
  '#487FAB',
  '#069D75',
  '#FECF4C',
  '#885273',
  '#FEB87D',
  '#AF62C5',
  '#186B7B',
  '#51C858',
  '#ED8352',
  '#E996FE',
  '#256D82',
  '#00AC60',
  '#FEA85A',
  '#2F5F97',
  '#F79F95',
  '#6757B0',
  '#367C76',
  '#B4DE5F',
  '#E1164A',
  '#A593F3',
  '#2B6D6D',
  '#58CA1E',
  '#E18284',
  '#2D8BB9',
];

export const portfolioCashColors = [
  '#FFB871',
  '#916FDD',
];

export const hammerBasicColors = [
  '#9FFEFE',
  '#FFB871',
  '#9F9FFE',
  '#EE6AB2',
  '#6DF6B2',
  '#CBCBFE',
  '#FE9F9F',
  '#66CDD0',
  '#FECBCB',
  '#6666CC',
  '#E5FEE5',
  '#FE7F7F',
  '#98ACFE',
  '#54DF8E',
  '#FEFECB',
  '#B55BE4',
  '#FE6666',
  '#539B53',
  '#58B586',
  '#C8D6E9',
  '#FEBFD8',
  '#9FC8FE',
  '#A1FE98',
  '#98FEBA',
  '#7FFE94',
  '#CB6666',
  '#43B743',
  '#66CB98',
  '#CB9833',
  '#BE7EFF',
  '#CBFEB2',
  '#B2FEB2',
  '#CB98E5',
  '#9FE5E5',
  '#CBE5FE',
  '#CB98E5',
  '#FEE5FE',
  '#FE9833',
  '#FE9FB2',
  '#3BB7B7',
  '#CB66CB',
  '#FEFE98',
  '#FEF1F1',
  '#FE8D71',
  '#FE9FCB',
  '#66CB66',
  '#FED8EB',
  '#D7B26A',
  '#B2B2FE',
  '#FE9FE5',
];

export const hammerPortfolioCashColors = [
  '#FFB871',
  '#99FFCC',
];
