import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import lang from '../../lib/language';
import { HighGrowthCompanyData } from '../../lib/models/fundamental/types';
import { RootState } from '../../lib/store';

import { useIsMobile } from '../../hooks';
import { NoDataAvailable } from '..';

import Chart from './Chart/Chart';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import CompanyInfoMobile from './CompanyInfo/CompanyInfoMobile';
import HighGrowthCompaniesInfo from './HighGrowthCompaniesInfo/HighGrowthCompaniesInfo';

import styles from './DividendPyersAndHighGrowthCompanies.module.scss';

type OwnProps = {
  companiesContent: HighGrowthCompanyData[]
}
type Props = OwnProps & ReduxProps;

const HighGrowthCompanies = ({ companiesContent, companyLogoAndName }: Props) => {
  const isMobile = useIsMobile();
  const [company, setCompany] = useState<HighGrowthCompanyData>(companiesContent[0]);
  const onChange = useCallback((key) => {
    const filteredDividends = companiesContent.find(el => el.symbol === key);
    if (filteredDividends) setCompany(filteredDividends);
  }, [companiesContent]);

  if (!companiesContent[0]) {
    return <div className={styles.noDataAvailable}><NoDataAvailable /></div>;
  }

  return (
    <>
      { isMobile ? (
        <div className={styles.wrapper}>
          <div className={styles.chartDetails}>
            <p className={styles.dotWrapper}>
              <span className={styles.activeDot} />
              {lang.commonDiscoverHighGrowthCompaniesLegend()}
              <HighGrowthCompaniesInfo />
            </p>
            <Chart
              data={companiesContent}
              yAxis={'growthIndicator'}
              currentItemId={companiesContent[0].symbol}
              onItemChange={onChange}
              isHighGrowthCompany
            />
          </div>
          <div className={styles.line} />
          <CompanyInfoMobile
            symbol={companiesContent[0].symbol}
            logo={companyLogoAndName[companiesContent[0].symbol].logo}
            companyName={companyLogoAndName[companiesContent[0].symbol].name}
            areDividends={false}
            volume={companiesContent[0].avg30Volume}
            marketCap={companiesContent[0].marketCap}
            prevCloseAndEPS={companiesContent[0].epsLast12Months}
            dividendRateAndRevenue={companiesContent[0].revenue}
          />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <CompanyInfo
            symbol={company.symbol}
            logo={companyLogoAndName[company.symbol].logo}
            companyName={companyLogoAndName[company.symbol].name}
            areDividends={false}
            volume={company.avg30Volume}
            marketCap={company.marketCap}
            prevCloseAndEPS={company.epsLast12Months}
            dividendRateAndRevenue={company.revenue}
          />
          <div className={styles.chartDetails}>
            <p className={styles.dotWrapper}>
              <span className={styles.activeDot} />
              {lang.commonDiscoverHighGrowthCompaniesLegend()}
              <HighGrowthCompaniesInfo />
            </p>
            <Chart
              data={companiesContent}
              yAxis={'growthIndicator'}
              currentItemId={company.symbol}
              onItemChange={onChange}
              isHighGrowthCompany
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndName: state.fundamental.companyLogoAndName,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(HighGrowthCompanies);
