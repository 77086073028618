import { OrderTicketComponent, SubscribedComponent } from './types';

export const parseOrderTicketComponentToComponentType = (component: OrderTicketComponent): SubscribedComponent => {
  let componentType: SubscribedComponent;

  switch (component) {
    case 'discover/popular-group': {
      componentType = 'popularGroup';
      break;
    }
    case 'symbol-details':
    case 'chart-details':
    case 'people-also-trade': {
      componentType = 'symbolDetails';
      break;
    }
    case 'create-order':
    case 'modify-order': {
      componentType = 'createOrder';
      break;
    }
    default:
      componentType = 'initial';
      break;
  }

  return componentType;
};

