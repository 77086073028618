import React, { useEffect, useRef, useState } from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import classNames from 'classnames';
import moment from 'moment';

import { LAST_YEARS } from '../../lib/constants/date-time.constants';
import { DEFAULT_BASIC_DATEPICKER_FORMAT } from '../../lib/constants/datePickerConstants';
import lang from '../../lib/language';
import { ControlError } from '../../lib/util/ValidationSchemes/ValidationTypes';

import CalendarIcon from '../../assets/img/icon-calendar.svg';

import styles from './DatepickerControl.module.scss';

type Props = {
  label: string;
  idDate?: string,
  idInput?: string,
  idPopover?: string,
  value?: string | null;
  variant?: WrapperVariant;
  className?: string;
  minDate?: string;
  maxDate?: string;
  errors?: ControlError[];
  onDateChange?: (date: string | null) => void;
  isYearPicker?: boolean;
  disabled?: boolean;
  onBlur?: (date: string | null) => void;
  onOpen?: () => void;
  dateFormat?: string;
  views?: any;
}

const DatepickerControl = (props: Props): JSX.Element => {
  const {
    idDate,
    idInput,
    idPopover,
    label,
    value,
    onBlur,
    onOpen,
    minDate,
    maxDate,
    className,
    onDateChange,
    errors = [],
    disabled = false,
    variant = 'inline',
    dateFormat,
    views,
    isYearPicker = false,
  } = props;

  const errorsWrapperRef = useRef<HTMLDivElement | null>(null);

  const [isInit, setIsInit] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isControlDirty, setIsControlDirty] = useState<boolean>(false);

  useEffect(() => {
    if (!isControlDirty && value) {
      const newValue = moment(value).format();

      setSelectedDate(newValue);

      if (!isInit) {
        onDateChange && onDateChange(newValue);
        setIsInit(true);
      }
    }
  }, [isControlDirty, isInit, onDateChange, selectedDate, value]);

  const handleHeaderText = () => <h3 className={styles.headerText}>{lang.commonYear()}</h3>;

  return (
    <div className={[styles.contentWrapper, className].join(' ')}>
      <div className={
        classNames(
          'datepicker',
          styles.wrapper,
          (errors.length > 0) && styles.withError,
        )
      }
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            itemID={idDate}
            aria-label={`date picker for ${label}`}
            label={label}
            variant={variant}
            minDate={minDate ?? LAST_YEARS[0].toString()}
            maxDate={maxDate}
            disabled={disabled}
            value={isYearPicker ? value : selectedDate}
            format={dateFormat || DEFAULT_BASIC_DATEPICKER_FORMAT}
            views={views || undefined}
            className={[
              styles.datepicker,
              (label.trim() === '') && styles.noLabelWrapper,
            ].join(' ')}
            onChange={(date: MaterialUiPickersDate) => {
              const newDate = date ? date.format() : null;
              setIsControlDirty(true);
              setSelectedDate(newDate);
              onDateChange && onDateChange(newDate);
            }}
            onBlur={() => onBlur && onBlur(selectedDate)}
            error={false}
            helperText={null}
            onOpen={onOpen ?? (() => {})}
            PopoverProps={{
              id: idPopover,
              'aria-label': `${label} popover`,
              classes: {
                paper: styles.paper,
              },
            }}
            inputProps={{ id: idInput, 'aria-label': `${label} date input` }}
            ToolbarComponent={views && handleHeaderText}
          />
        </MuiPickersUtilsProvider>
        <img src={CalendarIcon} alt="calendar icon" />
        <div
          ref={errorsWrapperRef}
          className={styles.errorsWrapper}
        >
          {errors.map((error: ControlError) => <p key={error.id} className={styles.error}>{error.error}</p>)}
        </div>
      </div>
    </div>
  );
};

export default DatepickerControl;
