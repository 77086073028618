/**
 * This slice is mostly dedicated to CRM API data
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CallStatus, CurrencyEnum } from '../../enums';
import { GenericStatusByCall } from '../../enums/status-by-call';
import lang from '../../language';
import { CALLS_TO_MEMOIZE } from '../../libSettings';
import {
  AccountDetails,
  LegalDeclaration,
  LegalDeclarationsDynamicFilePayload,
  LegalDeclarationsDynamicFileResponse,
  QuestionaireSuccessPayload,
  TradingPlatform,
} from '../../models/crm/types';
import {
  ENROLL_METHOD,
  ENROLL_METHOD_NAME,
  ENROLL_REQUEST_STATE,
  ENROLL_REQUEST_STATE_NAME,
  EnrollCRMState,
  FinancialQuestionnaireSection,
  IndividualExtendedInfoData,
  IndividualOwnLegalDeclarationsData,
} from '../../models/enroll';
import {
  EnrollCall, IdentityDocumentSubTypesEnum,
} from '../../models/enroll-requests';
import {
  extractDataNameFromGetCall, formatCurrency, selectNextAccount,
} from '../../util/DataHelpers';
import { appendToErrorsLog } from '../../util/error-handling/ErrorHandlingHelpers';
import { isCallStatusReady } from '../../util/error-handling/StatusByCallHelpers';
import { NullableNumber } from '../../util/types';
import {
  accountsFailed,
  clearErrorsLog,
  enrollSuccess,
  EnrollSuccessCombinedPayload,
  EnrollSuccessPayload,
  ErrorPayload,
  ErrorPayloadFull,
  getCountryListSuccess,
  getIndividualExtendedInfo,
  individualExtendedInfoFailed,
  individualExtendedInfoSuccess,
  logout,
} from '../common-actions';
import { processErrorInReducer } from '../helpers';

import { parseOnboardingErrorCode, parseQuestionnaireDataToSections, setCRMCallStatus, setEnrollStatusByCall } from './helpers';
import { CRMCall, LegalDeclarations } from './types';

export interface CRMState {
  connected: boolean;
  individualId: number | null; // cached here for convenience, also present in individualExtendedInfo
  individualExtendedInfo: IndividualExtendedInfoData | null;
  hasLoadedExtendedInfo: boolean;
  hasLoadedAccounts: boolean;
  selectedAccount: number;
  accounts: string[];
  accountDetails: AccountDetails;
  /**
   * tradingPlatformId value cases:
   * (1) has a value - call succeeded.
   * (2) is `undefined` - then call is pending.
   * (3) is `null` - then call failed (timeout or error).
   */
  tradingPlatformId: NullableNumber
  totalAccountValue: string;
  accountInfo: any;
  /**
   * Publicly accessible (no token needed)
   */
  enroll: EnrollCRMState;
  enrollQueued: (EnrollCall)[];
  error: string | null;
  errorData: any;
  errorsLog: string[];
  legalDeclarations: LegalDeclaration[];
  statusByCall: GenericStatusByCall<CRMCall>;
  files: { [fileName: string]: string };
}

export const INITIAL_STATE: CRMState = {
  connected: false,
  individualExtendedInfo: null,
  hasLoadedExtendedInfo: false,
  hasLoadedAccounts: false,
  selectedAccount: 0,
  individualId: null,
  accounts: [],
  accountDetails: {} as AccountDetails,
  tradingPlatformId: null,
  totalAccountValue: '0',
  accountInfo: {},
  enroll: {
    callsInQueue: 0,
    financialQuestionnaireCurrency: CurrencyEnum.EUR,
    financialQuestionnaireSections: [] as FinancialQuestionnaireSection[],
    isEditSourceOfFunds: false,
    enrollProgressInfo: {
      isOpenLastQuestions: false,
      isNoExperienceWithStock: false,
    },
    statusByCall: {},
    bankAccountDetailsOnCreate: {
      account_info: {
        bank_name: '',
        bank_country_id: '',
        bic: '',
        iban: '',
        currency_code: '',
      },
    },
    isPoASent: false,
    isPoISent: false,
  } as EnrollCRMState,
  enrollQueued: [],
  error: null,
  errorData: null,
  errorsLog: [],
  legalDeclarations: [],
  statusByCall: {},
  files: {},
};

const crmSlice = createSlice({
  name: 'crm',
  initialState: INITIAL_STATE,
  reducers: {
    accountsCompleted(state, action) {
      state.accounts = action.payload;
      if (state.selectedAccount > state.accounts.length - 1) state.selectedAccount = 0;
      setCRMCallStatus(CRMCall.getAccounts, CallStatus.READY, state);
      setCRMCallStatus(CRMCall.getAccountInfo, CallStatus.PENDING, state);
    },
    accountInfoCompleted(state, action) {
      state.accountInfo = action.payload;
      setCRMCallStatus(CRMCall.getAccountInfo, CallStatus.READY, state);
    },
    accountInfoFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      state.accountInfo = {};
      processErrorInReducer(state, action);
      setCRMCallStatus(CRMCall.getAccountInfo, CallStatus.ERROR, state, action.payload.status);
      setCRMCallStatus(CRMCall.getAccountDetails, CallStatus.PENDING, state);
    },
    accountDetailsCompleted(state, action) {
      state.accountDetails = action.payload;
      state.hasLoadedAccounts = true;
      const { positions_value, unrealized_pn_l, available_cash } = action.payload;
      const totalValue = positions_value + unrealized_pn_l + available_cash;
      state.totalAccountValue = formatCurrency(totalValue, false, true);
    },
    accountDetailsFailed(state, action) {
      state.accountDetails = {} as AccountDetails;
      state.hasLoadedAccounts = false;
      processErrorInReducer(state, action);
      state.totalAccountValue = formatCurrency(0, false, true);
    },
    getNextAccount(state, payload: PayloadAction<string | undefined>) {
      state.hasLoadedAccounts = false;
      const { selected } = selectNextAccount(state.accounts, state.selectedAccount);
      state.selectedAccount = selected;
      state.accountInfo = {};
      state.accountDetails = {} as AccountDetails;
    },
    getTradingPlatforms(state) {
      state.tradingPlatformId = undefined;
      setCRMCallStatus(CRMCall.getTradingPlatforms, CallStatus.PENDING, state);
    },
    getTradingPlatformsSuccess(state, action: PayloadAction<TradingPlatform[]>) {
      const items = action.payload;
      const firstPlatform = items.find(({ trading_platform_name }) => trading_platform_name === '10n');
      state.tradingPlatformId = firstPlatform?.trading_platform_id;
      setCRMCallStatus(CRMCall.getTradingPlatforms, CallStatus.READY, state);
    },
    getTradingPlatformsFailed(state, action) {
      const { status } = action.payload;
      state.tradingPlatformId = null;
      setCRMCallStatus(CRMCall.getTradingPlatforms, CallStatus.ERROR, state, status);
      appendToErrorsLog(`Getting Trading Platforms failed:\n${JSON.stringify(action.payload)}`, state);
    },
    getCountryList(state) {
      if (!CALLS_TO_MEMOIZE.getCountryList || !isCallStatusReady(CRMCall.getCountryList, state.statusByCall)) {
        setCRMCallStatus(CRMCall.getCountryList, CallStatus.PENDING, state);
      }
    },
    getCountryListFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      setCRMCallStatus(CRMCall.getCountryList, CallStatus.ERROR, state, action.payload.status);
    },
    enroll(state, action: PayloadAction<EnrollCall>) {
      const { callMethod, callMethodName, callNo, isQueued } = action.payload;

      if (isQueued) state.enrollQueued.shift();
      else state.enroll.callsInQueue++;

      if (state.enroll.callsInQueue <= 1 || isQueued) {
        const requestState = ENROLL_REQUEST_STATE.PENDING;
        state.enroll.status = state.enroll.status === ENROLL_REQUEST_STATE.ERROR && isQueued
          ? state.enroll.status
          : requestState;
        state.enroll.statusByCall[callNo ? `${callMethod}_${callNo}` : callMethod] = {
          callMethodName: callMethodName as ENROLL_METHOD_NAME,
          requestState,
          requestStateName: ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME,
        };
        state.enroll.callMethod = callMethod;
        state.enroll.callMethodName = callMethodName;
        state.error = null;
        state.errorData = {};
      }
    },
    enrollFail(state, action) {
      const {
        callName: callNameOther,
        callMethodName: callMethodNameCRM,
        callMethod: callMethodCRM,
        customData,
        errorStr,
        error,
        errorData,
      } = action.payload;

      const callMethodName = callMethodNameCRM ?? callNameOther;
      const callMethod = callMethodCRM ?? ENROLL_METHOD[callMethodName];
      const { callNo } = customData ?? {};

      if (state.enroll.callsInQueue > 0) {
        state.enroll.callsInQueue--;
      } else {
        console.warn('[crm/index] Trying to decrease callsInQueue while it is 0');
      }

      const requestState = parseOnboardingErrorCode(errorData.status);
      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.statusByCall[callNo ? `${callMethod as ENROLL_METHOD}_${callNo}` : callMethod as ENROLL_METHOD] = {
        callMethodName,
        requestState,
        requestStateName: ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME,
        errorCode: errorData.status,
      };
      state.enroll.callMethod = callMethod;
      state.enroll.callMethodName = callMethodName;
      state.error = error;
      state.errorData = action.payload;
      appendToErrorsLog(errorStr, state);
    },
    enrollPersonalDetailsWithTaxDetails(state, action) {
      const { callMethod, isPersonalDetailsUpdate } = action.payload;

      state.enroll.callsInQueue++;
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
      state.enroll.callMethod = callMethod;
      state.enroll.callMethodName = ENROLL_METHOD[callMethod];
      state.error = null;
      state.errorData = {};

      const requestState = ENROLL_REQUEST_STATE.PENDING;
      const method = (
        isPersonalDetailsUpdate ? ENROLL_METHOD.putEnrollmentOwnPersonal : ENROLL_METHOD.postEnrollmentOwnPersonal
      );

      state.enroll.statusByCall[method] = {
        callMethodName: ENROLL_METHOD[method] as ENROLL_METHOD_NAME,
        requestState,
        requestStateName: ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME,
      };
    },
    clearEnrollPoiState(state) {
      state.enroll.isPoISent = false;
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
    },
    clearEnrollPoaState(state) {
      state.enroll.isPoASent = false;
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
    },
    clearCrmEnrollStatusByCode(state) {
      state.enroll.statusByCall = {};
    },
    enrollPersonalDetailsSuccess(state, action) {
      const { isPersonalDetailsUpdate } = action.payload.customData || { };
      if (state.enroll.callsInQueue > 0) {
        state.enroll.callsInQueue--;
      }

      state.error = null;
      state.errorData = {};

      const { individualData } = action.payload.customData;

      if (individualData && state.individualExtendedInfo) {
        state.individualExtendedInfo = {
          ...state.individualExtendedInfo,
          ...individualData,
        };
      }

      if (state.enroll.callsInQueue === 0) {
        state.enroll.status = ENROLL_REQUEST_STATE.READY;
      }

      const requestState = ENROLL_REQUEST_STATE.READY;
      const method = (
        isPersonalDetailsUpdate ? ENROLL_METHOD.putEnrollmentOwnPersonal : ENROLL_METHOD.postEnrollmentOwnPersonal
      );

      state.enroll.statusByCall[method].requestState = requestState;
      state.enroll.statusByCall[method].requestStateName = (
        ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME
      );
    },
    enrollPersonalDetailsFailed(state, action) {
      const {
        error,
        errorStr,
        callMethod,
        customData,
      } = action.payload;

      if (state.enroll.callsInQueue > 0) {
        state.enroll.callsInQueue--;
      }

      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.callMethod = callMethod;
      state.enroll.callMethodName = ENROLL_METHOD[callMethod];
      state.error = error;
      state.errorData = action.payload;

      const requestState = ENROLL_REQUEST_STATE.READY;
      const method = (
        customData?.isPersonalDetailsUpdate
          ? ENROLL_METHOD.putEnrollmentOwnPersonal
          : ENROLL_METHOD.postEnrollmentOwnPersonal
      );

      state.enroll.statusByCall[method].requestState = requestState;
      state.enroll.statusByCall[method].requestStateName = (
        ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME
      );

      appendToErrorsLog(errorStr, state);
    },
    enrollTaxDetails(state, action) {},
    enrollAddressesBatch(state, action) {
      const { callMethod, callMethodName } = action.payload;

      state.enroll.callsInQueue++;
      state.enroll.status = ENROLL_REQUEST_STATE.PENDING;
      state.enroll.callMethod = callMethod;
      state.enroll.callMethodName = callMethodName;
      state.error = null;
      state.errorData = {};
    },
    enrollAddressesBatchSuccess(state, action) {
      if (state.enroll.callsInQueue > 0) {
        state.enroll.callsInQueue--;
      }

      state.error = null;
      state.errorData = {};

      if (state.enroll.callsInQueue === 0) {
        state.enroll.status = ENROLL_REQUEST_STATE.READY;
      }
    },
    enrollAddressesBatchFail(state, action) {
      state.enroll.callsInQueue--;
      const {
        error,
        errorStr,
        callMethod,
      } = action.payload;

      state.enroll.status = ENROLL_REQUEST_STATE.ERROR;
      state.enroll.callMethod = callMethod;
      state.enroll.callMethodName = ENROLL_METHOD[callMethod];
      state.error = error;
      state.errorData = action.payload;
      appendToErrorsLog(errorStr, state);
    },
    enrollAddCallToQueue(state, action: PayloadAction<EnrollCall>) {
      state.enrollQueued.push(action.payload);
    },
    enrolSaveState(state, action: PayloadAction<string>) {
      if (
        action.payload === IdentityDocumentSubTypesEnum.identityCard
        || action.payload === IdentityDocumentSubTypesEnum.passport
      ) {
        state.enroll.isPoISent = true;
      } else if (action.payload === 'poa') {
        state.enroll.isPoASent = true;
      }
    },
    enrollSaveCustom(state, action: PayloadAction<any>) {
      state.enroll.custom = action.payload;
    },
    getEnrollFinancialQuestionnaire(state) {
      state.error = null;
      state.errorData = {};

      const method = ENROLL_METHOD.postFinancialQuestionnaire;
      const requestState = ENROLL_REQUEST_STATE.PENDING;

      setEnrollStatusByCall(state, requestState, method);
    },
    getEnrollFinancialQuestionTypesSuccess(state: CRMState, action) {},
    getEnrollFinancialQuestionnaireSuccess(state: CRMState, action: PayloadAction<QuestionaireSuccessPayload>) {
      const data = action.payload.customData;
      const { types, answers } = data;

      state.error = null;
      state.errorData = {};

      if (types && answers) {
        state.enroll.financialQuestionnaireSections = parseQuestionnaireDataToSections(data);
      }

      const method = ENROLL_METHOD.postFinancialQuestionnaire;
      const requestState = ENROLL_REQUEST_STATE.READY;

      setEnrollStatusByCall(state, requestState, method);
    },
    getEnrollFinancialQuestionnaireFail(state: CRMState, action) {
      state.error = action.payload.error;
      state.errorData = action.payload;
      const { error } = action.payload;
      appendToErrorsLog(error, state);
    },
    setFinancialQuestionnaireCurrency(state, action: PayloadAction<CurrencyEnum>) {
      state.enroll.financialQuestionnaireCurrency = action.payload;
    },
    setSourceOfFundsIsEdit(state, action) {
      state.enroll.isEditSourceOfFunds = action.payload;
    },
    setEnrollProgressInfo(state, action) {
      state.enroll.enrollProgressInfo = action.payload;
    },
    getLegalDeclarations(state, action: PayloadAction<{ active: boolean, isVerified: boolean }>) {
      setCRMCallStatus(CRMCall.getLegalDeclarations, CallStatus.PENDING, state);
    },
    getLegalDeclarationsCompleted(state, action: PayloadAction<LegalDeclaration[]>) {
      const { customData: { isVerified } } = action.payload as any;

      const legalDeclarations = action.payload.reduce((acc: LegalDeclaration[], current: LegalDeclaration) => {
        if (acc.length > 0 && current.name !== LegalDeclarations.PrivacyPolicy) {
          let itemName = current.name;

          switch (current.name) {
            case LegalDeclarations.ClientAgreement: itemName = lang.commonClientAgreement(); break;
            case LegalDeclarations.GeneralTerms: itemName = lang.commonGeneralTermsAndConditions(); break;
            case LegalDeclarations.OrderExecutionPolicy: itemName = lang.commonOrderExecutionPolicy(); break;
            case LegalDeclarations.RiskWarningDisclosure: itemName = lang.commonRiskWarningDisclosureAndNotice(); break;
            case LegalDeclarations.ConflictOfInterest: itemName = lang.commonConflictsOfInterestSummary(); break;
            case LegalDeclarations.ClientCategorization: itemName = lang.commonClientCategorizationNotice(); break;
            case LegalDeclarations.ComplaintsHandlingPolicy: itemName = lang.commonComplaintsHandlingPolicy(); break;
            case LegalDeclarations.Tariff: itemName = lang.common10NTariff(); break;
            default: break;
          }

          const tempCurrent = current;
          tempCurrent.name = itemName;
          return [ ...acc, tempCurrent ];
        }

        if (current.name === LegalDeclarations.PrivacyPolicy) {
          return [ ...acc ];
        }

        return [ ...acc, current ];
      }, []);

      if (isVerified) {
        const privacyPolicy = action.payload.find(item => item.name === LegalDeclarations.PrivacyPolicy);
        privacyPolicy!.name = lang.commonOnboardingSignInPrivacyPolicy();
        legalDeclarations.push(privacyPolicy!);
      }

      state.legalDeclarations = legalDeclarations;
      setCRMCallStatus(CRMCall.getLegalDeclarations, CallStatus.READY, state);
    },
    getLegalDeclarationsFailed(state, action: PayloadAction<ErrorPayload>) {
      const error = action.payload?.error || action.payload;
      appendToErrorsLog(error, state);
      setCRMCallStatus(CRMCall.getLegalDeclarations, CallStatus.ERROR, state);
    },
    setBankAccountDetailsOnCreate(state, action) {
      state.enroll.bankAccountDetailsOnCreate.account_info = action.payload;
    },
    setIntialEnrollStatusByCall(state, action) {
      const requestState = ENROLL_REQUEST_STATE.INITIAL;

      if (state.enroll.statusByCall[action.payload]) {
        Object.assign(state.enroll.statusByCall[action.payload], {
          requestState: ENROLL_REQUEST_STATE.INITIAL,
          requestStateName: ENROLL_REQUEST_STATE[requestState],
          errorCode: null,
        });
      }
    },
    getLegalDeclarationsDynamicFile(state: CRMState, action: PayloadAction<LegalDeclarationsDynamicFilePayload>) {
      const method = ENROLL_METHOD.getLegalDeclarationsDynamicFile;
      const requestState = ENROLL_REQUEST_STATE.PENDING;
      setEnrollStatusByCall(state, requestState, method);
    },
    // eslint-disable-next-line max-len
    getLegalDeclarationsDynamicFileSuccess(state: CRMState, action: PayloadAction<LegalDeclarationsDynamicFileResponse>) {
      const { customData: { fileName }, data } = action.payload;
      const method = ENROLL_METHOD.getLegalDeclarationsDynamicFile;
      const requestState = ENROLL_REQUEST_STATE.READY;

      setEnrollStatusByCall(state, requestState, method);
      state.files[fileName] = data;
    },
    getLegalDeclarationsDynamicFileFail(state: CRMState, action: PayloadAction<ErrorPayloadFull>) {
      const { error, errorData } = action.payload;
      const method = ENROLL_METHOD.getLegalDeclarationsDynamicFile;
      const requestState = ENROLL_REQUEST_STATE.ERROR;

      setEnrollStatusByCall(state, requestState, method, errorData.status);
      state.error = error;
    },
  },
  extraReducers: {
    [getCountryListSuccess.type]: (state, action) => {
      setCRMCallStatus(CRMCall.getCountryList, CallStatus.READY, state);
    },
    [clearErrorsLog.type]: state => { state.errorsLog = []; },
    [getIndividualExtendedInfo.type](state) {
      state.hasLoadedExtendedInfo = false;
      setCRMCallStatus(CRMCall.getIndividualExtendedInfo, CallStatus.PENDING, state);
    },
    [individualExtendedInfoSuccess.type]: (state, action) => {
      state.individualId = action.payload?.id;
      state.individualExtendedInfo = action.payload;
      state.connected = true;
      state.hasLoadedExtendedInfo = true;
      setCRMCallStatus(CRMCall.getIndividualExtendedInfo, CallStatus.READY, state);
      setCRMCallStatus(CRMCall.getAccounts, CallStatus.PENDING, state);
    },
    [individualExtendedInfoFailed.type]: (state, action) => {
      state.connected = false;
      state.hasLoadedExtendedInfo = true;
      setCRMCallStatus(
        CRMCall.getIndividualExtendedInfo,
        CallStatus.ERROR,
        state,
        JSON.stringify(action.payload) as any,
      );
    },
    [accountsFailed.type]: (state, action) => {
      state.enroll.callsInQueue--;
      state.accounts = [];
      processErrorInReducer(state, action);
      state.hasLoadedAccounts = false;
      setCRMCallStatus(CRMCall.getAccounts, CallStatus.ERROR, state, (action.payload as ErrorPayloadFull).status);
    },
    [enrollSuccess.type]: (state, action: { payload: EnrollSuccessCombinedPayload }) => {
      if (state.enroll.callsInQueue > 0) {
        state.enroll.callsInQueue--;
      } else {
        console.warn('[crm/index] Trying to decrease callsInQueue while it is 0');
      }

      state.error = null;
      state.errorData = {};

      const { callName, status, customData } = (action.payload as EnrollSuccessPayload);
      const { callNo } = customData ?? {};

      if (!callName) {
        console.warn(`[crm/index] Callname not defined for '${action}' => ${callName}`, action);
        return;
      }

      const isGet = callName.indexOf('get') === 0;
      const isPost = callName.indexOf('post') === 0;
      const isPut = callName.indexOf('put') === 0;
      const isDelete = callName.indexOf('delete') === 0;

      const requestState = ENROLL_REQUEST_STATE.READY;

      if (isGet) {
        const dataName: string = extractDataNameFromGetCall(callName);
        if (callName === ENROLL_METHOD[ENROLL_METHOD.getIndividualExtendedInfo]) {
          state.individualExtendedInfo = action.payload as IndividualExtendedInfoData;
        } else if (ENROLL_METHOD[callName] === ENROLL_METHOD.getBusinessOwn) {
          const dataPayload: any = action.payload;

          // ART-2713 Removing first item - "Not Specified"
          (state.enroll as any)[dataName] = dataPayload.slice(1);
        } else {
          (state.enroll as any)[dataName] = action.payload;
        }
      } else if (isPost) {
        // nothing so far
      } else if (isPut) {
        if (callName === ENROLL_METHOD[ENROLL_METHOD.putIndividualOwnLegalDeclarations]) {
          // This case is the only currently known PUT that is used also for populating data in Redux state
          // Its data is also populated by the `isGet` `if` above - using generic approach with these two code fragments:
          //  1) const dataName: string = extractDataNameFromGetCall(callName);
          //  2) (state.enroll as any)[dataName] = action.payload;
          state.enroll.individualOwnLegalDeclarations = action.payload as any as IndividualOwnLegalDeclarationsData;
        }
      } else if (isDelete) {
        // nothing so far
      } else {
        console.warn(`[crm/index] Unknown enroll success case - ${callName}`);
      }
      if (state.enroll.callsInQueue === 0) state.enroll.status = requestState;

      const callMethod = ENROLL_METHOD[callName];
      state.enroll.statusByCall[callNo ? `${callMethod}_${callNo}` : callMethod] = {
        callMethodName: callName,
        requestState,
        requestStateName: ENROLL_REQUEST_STATE[requestState] as ENROLL_REQUEST_STATE_NAME,
      };
    },
    [logout.type]: state => ({
      ...INITIAL_STATE,
      errorsLog: state.errorsLog,
    }),
  },
});

export const {
  accountsCompleted,
  accountInfoCompleted,
  accountInfoFailed,
  accountDetailsCompleted,
  accountDetailsFailed,
  getNextAccount,
  getTradingPlatforms,
  getTradingPlatformsSuccess,
  getTradingPlatformsFailed,
  getCountryList,
  getCountryListFailed,
  enroll,
  enrollFail,
  enrollTaxDetails,
  enrollPersonalDetailsWithTaxDetails,
  enrollPersonalDetailsFailed,
  enrollPersonalDetailsSuccess,
  clearEnrollPoiState,
  clearEnrollPoaState,
  clearCrmEnrollStatusByCode,
  enrollAddressesBatch,
  enrollAddressesBatchFail,
  enrollAddressesBatchSuccess,
  enrollAddCallToQueue,
  enrolSaveState,
  enrollSaveCustom,
  getEnrollFinancialQuestionnaire,
  getEnrollFinancialQuestionnaireFail,
  getEnrollFinancialQuestionnaireSuccess,
  setFinancialQuestionnaireCurrency,
  setSourceOfFundsIsEdit,
  setEnrollProgressInfo,
  getLegalDeclarations,
  getLegalDeclarationsCompleted,
  getLegalDeclarationsFailed,
  setBankAccountDetailsOnCreate,
  setIntialEnrollStatusByCall,
  getLegalDeclarationsDynamicFile,
  getLegalDeclarationsDynamicFileSuccess,
  getLegalDeclarationsDynamicFileFail,
} = crmSlice.actions;

export default crmSlice.reducer;
