/**
 * This slice is dedicated to Trading API data
 * TODO: To be migrated to SOR or a combination of SOR/Reporting APIs.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { OrderStatusEnum } from '../../enums';
import {
  GWRequestType,
  GWResponseType,
  SORCommonOrderResponse,
  SORErrorResponse,
  SOROrderSide,
  SOROrderStatus,
} from '../../models/gateway/types';
import { BuyingPower, Execution, FXOrderPayload, Order, RawBalanceData, RawOrderData, RawPositionData } from '../../models/trading/types';
import MarketStateCache from '../../store-util/MarketStateCache';
import OrderInfoCache from '../../store-util/OrderInfoCache';
import { formatCurrency, parseBalance, parseOrder, parsePosition } from '../../util/DataHelpers';
import { appendToErrorsLog } from '../../util/error-handling/ErrorHandlingHelpers';
import { isMarketClosed } from '../../util/MarketDataHelpers';
import { uuidv4 } from '../../util/tools';
import { clearErrorsLog, ErrorPayloadFull, logout, newOrderInit, sorOrderEvent, tradingDisconnected, webSocketResponseError } from '../common-actions';
import { addOrderInfoLog } from '../helpers';
import { AccountTradeHistoryResponseData } from '../reporting/types';

import { updateOrder } from './helpers';
import { TradeState } from './types';

export const INITIAL_STATE: TradeState = {
  connected: false,
  isFinalOrderStatus: false,
  order: {} as Order,
  lastSorOrderData: {} as SORCommonOrderResponse,
  orders: [],
  positions: [],
  executions: [],
  lastOrders: {},
  balances: [],
  requestStatus: 'new' as OrderStatusEnum,
  errorsLog: [],
  orderInfo: [],
  getPositionsRequestStatus: OrderStatusEnum.New,
  sumOfPendingOrders: 0,
  fxOrderStatus: 'initial',
  fxOrder: {} as Order,
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState: INITIAL_STATE,
  reducers: {
    connected(state) { state.connected = true; },
    newOrder(state, action) {
      const { order } = action.payload;
      const clientOrderId = uuidv4();
      const traceIdentifier = uuidv4();
      state.order = {
        clientOrderId,
        traceIdentifier,
        ...order,
        requestType: GWRequestType.NewOrderRequest,
      };
      state.requestStatus = OrderStatusEnum.InProgress;
      state.lastSorOrderData = null;
      const { symbol, orderQty, price, stopPrice, side, ordType } = order;
      const data = { ...order, index: OrderInfoCache.getLength() };
      addOrderInfoLog(state, 'START@@neworder', `${side}-${ordType} - ${symbol} - ${orderQty}x ${formatCurrency(price ?? stopPrice)}`, data);
    },
    getOrders(state, action: PayloadAction<string | undefined>) {},
    getOrdersRejected(state, action) {
      state.orders = [];
    },
    getOrdersCompleted(state, action) {
      const rawOrders = action.payload.orders as RawOrderData[];
      state.orders = rawOrders.map(order => parseOrder(order));
      cleanFromLastOrders(state);
    },

    getPositions(state, action) {
      state.getPositionsRequestStatus = OrderStatusEnum.New;
    },
    getPositionsRejected(state, action) {
      state.positions = [];
      state.getPositionsRequestStatus = OrderStatusEnum.Rejected;
    },
    getPositionsCompleted(state, action) {
      const rawPositions = action.payload.positions as RawPositionData[];
      state.positions = rawPositions.map(pos => parsePosition(pos));
      state.getPositionsRequestStatus = OrderStatusEnum.Accepted;
      cleanFromLastOrders(state);
    },

    setExecutions(state, action: PayloadAction<Execution[]>) {
      state.executions = action.payload;
    },
    getBalancesRejected(state, action) {
      if (!isEmpty(state.balances)) {
        return;
      }
      state.balances = [];
    },
    getBalancesCompleted(state, action) {
      const rawBalances = action.payload.balances as RawBalanceData[];
      state.balances = rawBalances.map(balance => parseBalance(balance));
    },
    ordersReceived(state, action) {
      state.orders = action.payload.orders;
      cleanFromLastOrders(state);
    },
    positionsReceived(state, action) {
      const rawPositions = action.payload.positions as RawPositionData[];
      state.positions = rawPositions.map(pos => parsePosition(pos));
    },
    balancesReceived(state, action) {
      if (action.payload?.balances && !isMarketClosed(MarketStateCache.get().marketState)) {
        const rawBalances = action.payload.balances as RawBalanceData[];
        state.balances = rawBalances.map(balance => parseBalance(balance));
      }
    },
    getPendingNewOrderCompleted(state, action: PayloadAction<AccountTradeHistoryResponseData[]>) {
      let sumOrderPrice = 0;
      if (action.payload.length) {
        action.payload.forEach(el => {
          // TODO: Sell pending orders to be discussed
          if (el.side === SOROrderSide.Buy) {
            sumOrderPrice += el.orderRequestedPrice * el.totalQuantity;
          }
        });
      }
      state.sumOfPendingOrders = sumOrderPrice;
    },

    getPendingNewOrderFailed(state, action) {
      state.sumOfPendingOrders = 0;
    },
    tradingError(state, action) {
      appendToErrorsLog(action.payload, state);
    },
    cancelOrder(state: TradeState, action: PayloadAction<Order>) {
      const clientOrderId = uuidv4();
      const traceIdentifier = uuidv4();
      state.order = {
        clientOrderId,
        traceIdentifier,
        ...action.payload,
        requestType: GWRequestType.CancelOrderRequest,
      };
      state.requestStatus = OrderStatusEnum.InProgress;
      state.lastSorOrderData = null;
      const { symbol, orderQty, price, stopPrice, side, ordType } = action.payload;
      addOrderInfoLog(state, 'START@@cancelOrder', `${side}-${ordType} - ${symbol} - ${orderQty}x ${formatCurrency(price ?? stopPrice)}`);
    },
    cancelOrderFailed(state, action) {
      state.requestStatus = OrderStatusEnum.Rejected;
    },
    modifyOrder(state, action: PayloadAction<Order>) {
      state.requestStatus = OrderStatusEnum.InProgress;
      state.lastSorOrderData = null;
      const clientOrderId = uuidv4();
      const traceIdentifier = uuidv4();
      state.order = {
        clientOrderId,
        traceIdentifier,
        ...action.payload,
        requestType: GWRequestType.ModifyOrderRequest,
      };
      const { symbol, orderQty, price, stopPrice, side, ordType } = action.payload;
      addOrderInfoLog(state, 'START@@modifyOrder', `${side}-${ordType} - ${symbol} - ${orderQty}x ${formatCurrency(price ?? stopPrice)}`);
    },
    resetRequestStatus(state: TradeState) {
      state.requestStatus = OrderStatusEnum.Initial;
      if (state.order?.requestType) state.order.requestType = null;
    },
    getBuyingPower(state: TradeState) {},
    getBuiyngPowerSuccess(state: TradeState, action: PayloadAction<BuyingPower>) {
      const availBp = action.payload.buyingPowerId;
      const account = action.payload.accountReferenceId;
      /**
       * If we are in after market and we passed to closed market we will have balance state,
       * which is populated from the trade service ws communication
      */
      let balance = { account, availBp };
      if (state.balances.length) {
        balance = { ...state.balances[0], account, availBp };
      }
      state.balances[0] = balance;
    },
    getBuiyngPowerFailed(state: TradeState, action: PayloadAction<ErrorPayloadFull>) {
      const { callName, status } = action.payload;
      state.errorsLog.push(`Error in ${callName} with a status ${status}`);
    },
    newFXOrder(state: TradeState, action: PayloadAction<FXOrderPayload>) {
      const clientOrderId = uuidv4();
      const traceIdentifier = uuidv4();

      state.fxOrderStatus = 'pending';
      state.fxOrder = { clientOrderId, traceIdentifier };
    },
    newFXOrderCompleted(state: TradeState, action: PayloadAction<SORCommonOrderResponse>) {
      const { status } = action.payload;

      if (status === SOROrderStatus.Fill) {
        state.fxOrderStatus = 'completed';
      } else if (status === SOROrderStatus.New) {
        state.fxOrderStatus = 'pending';
      } else {
        state.fxOrderStatus = 'failed';
      }
    },
    newFXOrderFailed(state: TradeState) {
      state.fxOrderStatus = 'failed';
    },
  },
  extraReducers: {
    [sorOrderEvent.type]: (state, action: PayloadAction<SORCommonOrderResponse>) => {
      state.lastSorOrderData = action.payload;
      updateOrder(state, action.payload);
    },
    [clearErrorsLog.type]: state => {
      state.errorsLog = [];
      state.orderInfo = [];
    },
    [tradingDisconnected.type]: state => { state.connected = false; },
    [logout.type]: state => ({ ...INITIAL_STATE, errorsLog: state.errorsLog }),
    [newOrderInit.type]: state => {
      state.requestStatus = OrderStatusEnum.New;
    },
    [webSocketResponseError.type]: (state, action: PayloadAction<SORErrorResponse>) => {
      const { traceIdentifier, messageType } = action.payload;

      if (state.order.traceIdentifier === traceIdentifier && messageType === GWResponseType.Error) {
        updateOrder(state, action.payload);
      }
      // TODO: Add more cases where we can receive GE Error
    },
  },
});

/**
 * Exported only for testing purposes - not to be used outside of this file
 * @param state TradeState
 */
export function cleanFromLastOrders(state: TradeState) {
  const { orders, lastOrders } = state;

  orders.forEach(order => {
    if (lastOrders[order.clientOrderId as string] != null) {
      delete lastOrders[order.clientOrderId as string];
    }
  });
}

export const {
  connected,
  tradingError,
  getOrders,
  getOrdersCompleted,
  getOrdersRejected,
  getPositions,
  getPositionsCompleted,
  getPositionsRejected,
  setExecutions,
  getBalancesCompleted,
  getBalancesRejected,
  newOrder,
  ordersReceived,
  positionsReceived,
  balancesReceived,
  cancelOrder,
  cancelOrderFailed,
  modifyOrder,
  resetRequestStatus,
  getPendingNewOrderCompleted,
  getPendingNewOrderFailed,
  getBuiyngPowerFailed,
  getBuiyngPowerSuccess,
  getBuyingPower,
  newFXOrder,
  newFXOrderCompleted,
  newFXOrderFailed,
} = tradeSlice.actions;

export default tradeSlice.reducer;
