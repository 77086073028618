import React from 'react';

import leftArrow from '../../../../../assets/img/icon-arrow-left_24px.svg';
import rightArrow from '../../../../../assets/img/icon-arrow-right_24px.svg';
import { TradePrice } from '../../PeopleAlsoTrade.types';

import styles from './SlideButtons.module.scss';

type Props = {
  handleSlideIndex: (index: number) => void;
  slideIndex: number;
  peopleAlsoTradeData: TradePrice[];
}

const SlideButtons = (props: Props) => {
  const { slideIndex, peopleAlsoTradeData, handleSlideIndex } = props;

  return (
    <>
      {
        ((
          slideIndex === 0 && peopleAlsoTradeData.length > 5)
          || (slideIndex === 5 && peopleAlsoTradeData.length > 10)
          || (slideIndex === 10 && peopleAlsoTradeData.length > 15)
        ) && (
          <button onClick={() => handleSlideIndex(slideIndex + 5)} type="button" className={styles.buttonNext}>
            <img src={rightArrow} alt="right arrow" />
          </button>
        )
      }
      {
        slideIndex >= 5 && (
          <button onClick={() => handleSlideIndex(slideIndex - 5)} type="button" className={styles.buttonPrevious}>
            <img src={leftArrow} alt="left arrow" />
          </button>
        )
      }
    </>
  );
};

export default SlideButtons;
