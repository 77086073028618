import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../../../../lib/language';

import leftArrow from '../../../../../../assets/img/icon-arrow-left.svg';
import noMessagesLogo from '../../../../../../assets/img/image_empty.png';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { UI_ROUTES } from '../../../../../../util/routes';
import { ClickableImage } from '../../../../../index';
import HeaderLogo from '../../../../HeaderLogo';

import styles from '../AllNotifications.module.scss';

const NoMessages = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={styles.wrapperNoMessagesLogo}>
        <img src={noMessagesLogo} alt="no messages logo" />
        <p className={styles.noMessages}>{lang.commonInboxNoMessagesText2()}</p>
      </div>
    );
  }

  return (
    <>
      <div className={styles.wrapperLayout}>
        <div className={styles.wrapperMessages}>
          <h3>{lang.commonInboxTitle()}</h3>
        </div>
      </div>
      <div className={styles.wrapperNoMessagesLogo}>
        <img src={noMessagesLogo} alt="no messages logo" />
        <p className={styles.noMessages}>{lang.commonInboxNoMessagesText2()}</p>
      </div>
    </>
  );
};

export default NoMessages;
