import React, { useState } from 'react';
import { useHistory } from 'react-router';
// eslint-disable-next-line
import {
  SwipeableList,
  SwipeableListItem,
  Type as ListType,
} from 'react-swipeable-list';
import classNames from 'classnames';

import lang from '../../lib/language';
import { PositionType } from '../../lib/store/reporting/types';
import { NullableString } from '../../lib/util/types';

import { UI_ROUTES } from '../../util/routes';
import { ClickableDiv, CompanyLogo } from '../index';
import ButtonsCancelAndModify from '../TableView/tableContainer/ButtonsCancelAndModify/ButtonsCancelAndModify';
import ButtonsSellAndBuy from '../TableView/tableContainer/ButtonsSellAndBuy/ButtonsSellAndBuy';

import styles from './MyAccountButtons.module.scss';

type Props = {
    data: any;
    companyLogoAndNameData: any
    hasSymbol?: boolean;
    companyLogos?: boolean;
    pendingOrders?: boolean;
    selectedPieChartElement?: { colors: string[], symbolName: string };
}

const DEFAULT_NAME_LENGTH = 20;

const MyAccountButtons = ({
  data, hasSymbol, pendingOrders, selectedPieChartElement, companyLogoAndNameData, companyLogos,
}: Props) => {
  const history = useHistory();
  const [selectedRowKey, setSelectedRowKey] = useState<NullableString>();

  const handleClickRowMob = (symbolName: string, e: Event): void => {
    e.preventDefault();
    history.push(`${UI_ROUTES.symbol}/${symbolName}`);
  };

  const onSwipeProgress = (rowKey:string, progress: number, direction: string) => {
    const MIN_PROGRESS_TO_OPEN_ROW = pendingOrders ? 28 : 40;
    if (rowKey !== selectedRowKey && progress >= MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(rowKey);
      return;
    }

    if (selectedRowKey && rowKey !== selectedRowKey) {
      setSelectedRowKey(null);
      return;
    }

    if (rowKey === selectedRowKey && progress < MIN_PROGRESS_TO_OPEN_ROW) {
      setSelectedRowKey(null);
    }
  };

  const trimName = (name: string) => {
    if (!name) {
      return '';
    }

    if (name.length > DEFAULT_NAME_LENGTH) {
      return `${name.slice(0, DEFAULT_NAME_LENGTH)}...`;
    }
    return name;
  };

  return (

    <SwipeableList
      fullSwipe={false}
      type={ListType.IOS}
    >
      {data.map((row, index: number) => {
        let companyLogoAndName;
        if (!hasSymbol) {
          companyLogoAndName = companyLogoAndNameData[row.name];
        }

        const rowKey = row.id;
        const activeRowStyle = classNames(
          styles.row,
          pendingOrders ? styles.rowActivePendingOrder : styles.rowMyStockActice,
        );

        return (
          <SwipeableListItem
            key={rowKey}
            onSwipeProgress={(progress: number, direction: string) => onSwipeProgress(rowKey, progress, direction)}
            trailingActions={(
              <div className={styles.trailingActions} style={{ width: pendingOrders ? 106 : 150 }}>
                {pendingOrders
                  ? <ButtonsCancelAndModify row={row} isSwipeable />
                  : <ButtonsSellAndBuy row={row} isSwipeable />}
              </div>
                   )}
            maxSwipe={10}
            className={selectedRowKey === rowKey ? activeRowStyle : styles.row}
          >
            <ClickableDiv
              className={styles.rowClickable}
              onClick={(e) => handleClickRowMob(row.name, e)}
            >
              {
                selectedPieChartElement && (
                  <div
                    style={{ background: selectedPieChartElement.colors[index] }}
                    className={styles.colorVerticalLine}
                  />
                )
              }
              <div className={styles.logo}>
                {
                  companyLogos
                    ? (
                      <CompanyLogo
                        logo={companyLogoAndName?.logo}
                        className={styles.image}
                        symbol={row.name}
                      />
                    )
                    : <img src={row.icon} className={styles.image} alt="" />
                }
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.companyStocksAndPrice}>
                  <div className={styles.stocks}>
                    {pendingOrders ? (
                      <>
                        {[row.totalQuantity, row.name, 'x', row.setPrice].join(' ')}
                      </>
                    ) : (
                      <>
                        {[row.quantity, row.name, 'x', row.purchasePrice].join(' ')}
                        {row.positionType === PositionType.Sell
                          && <span className={styles.positionType}>{lang.commonShort()}</span>}
                      </>
                    )}
                  </div>
                  <div className={styles.expiryDate}>
                    {pendingOrders ? row.expiryDate : row.marketPrice}
                  </div>
                </div>
                <div className={styles.companyNameAndUnrealizedProfit}>
                  <div className={styles.companyName}>
                    {pendingOrders ? row.type : trimName(row.subtitle)}
                  </div>
                  <div
                    className={classNames(styles.unrealizedProfit, {
                      [styles.positive]: !pendingOrders && row.unrealizedProfitNumber > 0,
                      [styles.negative]: !pendingOrders && row.unrealizedProfitNumber < 0,
                    })}
                  >
                    {pendingOrders ? row.quantity ?? '' : row.unrealizedProfit}
                  </div>
                </div>
              </div>
            </ClickableDiv>
          </SwipeableListItem>
        );
      })}
    </SwipeableList>
  );
};
export default MyAccountButtons;
