import {
  errorHandling,
  logConfig,
  logFiltering,
  popupErrors,
  popupErrorsFilter,
} from '../../configDebug';
import LibraryConfig from '../../configLib';
import { BBOData, TradeData } from '../models/market-data/types';

import { patchConsoleTron } from './LogFiltering';


// ===========   DEVELOP Settings   ===========
if (LibraryConfig?.__DEV__) {
  if (!popupErrors) LibraryConfig?.disablePopups(true);
  if (popupErrorsFilter != null) LibraryConfig?.disablePopupsFilter(popupErrorsFilter);
  if (logFiltering) require('./LogFiltering').patchConsole();
  if (errorHandling) LibraryConfig?.errorHandlingInit();
}


// ===========   RELEASE Settings   ===========

export function noop(...args: any[]) {}

if (console.tron == null) {
  patchConsoleTron(noop);
}
if (!LibraryConfig?.__DEV__ || !logConfig?.enabled) {
  console.log = noop;
  console.info = noop;
  console.debug = noop;
  console.warn = noop;
  console.error = noop;
  console.time = noop;
  console.timeEnd = noop;
}
if (!console.time) {
  console.time = () => {};
  console.timeEnd = () => {};
}


export function parseNBBODataToString(source: BBOData[]) {
  return source.map(
    ({
      sym, bidprice, askprice, bidsize, asksize, quotetime,
    }) => `[${quotetime}] ${sym}: ${bidprice}/${askprice}, size: ${bidsize}/${asksize} (bid/ask)`,
  );
}

export function parseTradeDataToString(source: TradeData[]) {
  return source.map(
    ({
      sym, tradeprice, tradesize, tradetime,
    }) => `[${tradetime}] ${sym}  ${tradeprice}, vol: ${tradesize}`,
  );
}

export function parseInstrumentSnapshotToString(source: any[]) {
  return source.map(
    ({
      sym, symbol, date, close, prevClose, volume, prevVolume, prevDate, lastPrice, prevLastPrice,
    }) => `${symbol ?? sym}\n[${date}] close: ${close}, vol: ${volume}, lastPrice: ${lastPrice}\n[${prevDate}] prevClose: ${prevClose}, prevVol: ${prevVolume}, prevLastPrice: ${prevLastPrice}`,
  );
}

LibraryConfig?.getRandomValuesInit();
export const uuidv4 = require('uuid').v4;
