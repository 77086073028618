import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../lib/language';

import leftArrow from '../../assets/img/icon-arrow-left.svg';
import EnrollHeader from '../Enroll/EnrollHeader/EnrollHeader';
import { PhoneAutocompleteErrors } from '../PhoneAutocomplete/PhoneAutocomplete.types';
import { Button, ClickableImage } from '..';

import styles from './VerificationLayout.module.scss';

type Props = {
  title: string;
  children: JSX.Element;
  onContinueClicked: () => void;
  subtitle?: string;
  btnDisabled?: boolean;
  errors?: PhoneAutocompleteErrors;
  customSubtitle?: () => JSX.Element;
  onBackClicked?: () => void;
}

const VerificationLayout = (props: Props) => {
  const {
    title,
    subtitle,
    children,
    btnDisabled = false,
    errors,
    onBackClicked,
    customSubtitle,
    onContinueClicked,
  } = props;

  const history = useHistory();

  return (
    <>
      <EnrollHeader className={styles.headerLogo} />
      <div className={styles.wrapperContainer}>
        <div className={styles.wrapperHeader}>
          <ClickableImage
            alt=""
            src={leftArrow}
            className={styles.leftArrow}
            onClick={() => (!onBackClicked ? history.goBack() : onBackClicked())}
          />
          <h3 id="verificationTitleLayout" className={styles.headerText} aria-label="verification title">{title}</h3>
        </div>
        {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
        {customSubtitle && (
          <div className={styles.subTitle}>
            {customSubtitle()}
          </div>
        )}
        <div className={styles.wrapperContent}>
          {children}
        </div>
        <div className={(errors && (errors.code.length > 0 || errors.phone.length > 0))
          ? styles.errorWrapperBtn : styles.wrapperBtn}
        >
          <Button
            fullWidth
            id="continueVerification"
            ariaLabel="continue with verification"
            className={styles.btnContinue}
            disabled={btnDisabled}
            onClick={onContinueClicked}
          >
            {lang.commonContinueButton()}
          </Button>
        </div>
      </div>
    </>
  );
};

export default VerificationLayout;
