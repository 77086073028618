/**
 * This API is only partially used - for balances for example
 */
import config from '../../config';
import { tronLog } from '../../configDebug';
import LibraryConfig from '../../configLib';
import { OrderTypeEnum } from '../enums/order-type.enum';
import { GWRequestType } from '../models/gateway/types';
import { GetDetailsFunctionalityType, Order } from '../models/trading/types';
import { tradingDisconnected } from '../store/common-actions';
import {
  balancesReceived,
  connected,
  ordersReceived,
  positionsReceived,
  tradingError,
} from '../store/trading';
import { tradingConfig } from '../trading-settings';
import { checkFilter, getObjectKeysAsString } from '../util/ObjectTools';
import { uuidv4 } from '../util/tools';

import { ITradingService } from './types';

const { __DEV__, WebSocket: WebSocketImpl } = LibraryConfig;

export enum TradeClientStatus {
  INITIAL,
  SOCKET_CONNECTING,
  SOCKET_CONNECTED,
  SOCKET_CONNECTION_FAILED,
  LOGGING_IN,
  LOGIN_FAILED,
  SUCCESSFUL,
  CLOSED
}


type Dispatcher = (action: any) => any;

export class TradeClientService implements ITradingService {
  private ws?: WebSocket;

  authToken?: string;

  dispatch: Dispatcher;

  status = TradeClientStatus.INITIAL;

  // TODO: Clean connectionPromise... code - not needed since connection is opened once.
  //       Check if any other reason to stay in codebase.
  connectionPromise!: Promise<any>;

  connectionPromiseResolver?: (value?: any) => void;

  connectionPromiseRejector?: (err: any) => void;

  asyncRef = 0;

  promiseCompleters: { [key: string]: { resolve: (data?: any) => any, reject: (err?: any) => any } } = {};

  private connectCalledAfterClose: boolean = false;

  constructor(dispatch: Dispatcher) {
    this.dispatch = dispatch;
    this.sendRequest = this.sendRequest.bind(this);
    this.handleSuccessResponse = this.handleSuccessResponse.bind(this);
    this.handleLoginResult = this.handleLoginResult.bind(this);
    this.handleErrorResponse = this.handleErrorResponse.bind(this);
    this.handleTWebsocketMessages = this.handleTWebsocketMessages.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.modifyOrder = this.modifyOrder.bind(this);
  }


  public isConnected = () => this.connectCalledAfterClose;

  public setToken = (token: string): void => {
    this.authToken = token;
  };

  connect(token?: string) {
    if (token == null) {
      throw new Error('[T] Must specify a token in order to connect');
    }
    if (this.status === TradeClientStatus.SOCKET_CONNECTED) {
      console.warn(`[T] Connecting Websocket while previous connection is up (${this.ws?.readyState})`);
      return;
    }

    const { url } = config.trading;

    this.authToken = token;
    this.connectCalledAfterClose = true;
    console.info(`[T] Connecting to ${url}`);

    this.connectionPromise = new Promise((resolve, reject) => {
      this.connectionPromiseResolver = resolve;
      this.connectionPromiseRejector = reject;
    });

    this.status = TradeClientStatus.SOCKET_CONNECTING;

    this.ws = new WebSocketImpl(url);
    this.checkConnectionTimeout(this.ws as any, 10000);

    this.ws!.onopen = () => {
      console.info('[T] Websocket connection established');
      this.status = TradeClientStatus.LOGGING_IN;

      const ref = `${this.asyncRef++}`;
      this.sendRequest('login', {
        reqId: `LOGIN-TEST${ref}`,
        messageType: 'login',
        accessToken: token,
      });
    };

    this.ws!.onclose = (event: any) => {
      this.connectCalledAfterClose = false;
      console.info('[T] Websocket connection closed', event);

      // Since close is called after failures as well, we set closed status only if it is not a failure
      if (!this.isConnectionFailed()) {
        this.status = TradeClientStatus.CLOSED;
      }

      // Reject the promise if it is not resolved previously
      // if (currentStatus !== TradeClientStatus.SUCCESSFUL && this.connectionPromiseRejector) {
      //   this.connectionPromiseRejector('[T] Connection closed');
      // }
      this.dispatch(tradingDisconnected());
    };

    this.ws!.onmessage = this.handleTWebsocketMessages;

    this.ws!.onerror = (err: any) => {
      console.error('[T] Web socket error', err);
      const currentStatus = this.status;
      this.status = TradeClientStatus.SOCKET_CONNECTION_FAILED;

      // Reject the promise if it is not resolved previously
      // if (currentStatus !== TradeClientStatus.SUCCESSFUL && this.connectionPromiseRejector) {
      //   this.connectionPromiseRejector('[T] Web socket connection error');
      // }
    };
  }

  isConnecting() {
    return [ TradeClientStatus.SOCKET_CONNECTED, TradeClientStatus.SOCKET_CONNECTING,
      TradeClientStatus.LOGGING_IN ].includes(this.status);
  }

  isConnectionFailed() {
    return [ TradeClientStatus.SOCKET_CONNECTION_FAILED, TradeClientStatus.LOGIN_FAILED ].includes(this.status);
  }

  private checkConnectionTimeout(ws: WebSocket, timeout: number = 20000) {
    setTimeout(() => {
      if ((ws as any).readyState !== WebSocketImpl.OPEN) {
        (ws as any).close();
        this.status = TradeClientStatus.SOCKET_CONNECTION_FAILED;
      }
    }, timeout);
  }

  reconnect(token?: string) {
    if (this.status === TradeClientStatus.SOCKET_CONNECTED) {
      return;
    }
    this.connect(token ?? this.authToken);
  }

  disconnect() {
    console.info('[T] Disconnecting');
    if (this.ws) {
      console.info('[T] Disconnecting socket');
      try {
        this.ws!.close();
      } catch (exp) {
        console.warn('[T] Socket close error', exp);
        this.dispatch(tradingError('[T] Disconnecting trading service'));
      }
    }
  }

  sendRequest(callName: string, request: any) {
    if (__DEV__) {
      console.info(`[T] Sending request '${request.messageType}' (req: ${request.reqId}; props - ${getObjectKeysAsString(request)})`);
      console.debug(`[T] Request full body for '${request.messageType}' (req: ${request.reqId})`, request);
      if (checkFilter(tronLog.t, callName)) {
        console.tron.display({
          name: `T-request "${request.messageType || callName}"`,
          value: request,
          important: false,
        });
      }
    }
    this.ws!.send(JSON.stringify(request));
  }

  handleTWebsocketMessages(msg: any) {
    const response = JSON.parse(msg.data);
    const {
      messageType, reqId, error, rc,
    } = response;

    if (error) {
      this.dispatch(tradingError(`[T] ${messageType} - ${error} (${rc})`));
    }

    if (__DEV__) {
      const debugMessage = `[T] Message '${messageType}' (req: ${reqId}; props - ${getObjectKeysAsString(response)})`;
      if (error) console.error(`${debugMessage}\n[ERROR]: ${error}`);
      else console.info(debugMessage);
      console.debug(`[T] Message full body - '${messageType}' (req: ${reqId})`, response);
      if (checkFilter(tronLog.t, messageType)) {
        console.tron.display({
          name: `T-msg "${messageType}"`,
          value: response,
          important: false,
        });
      }
    }

    if (response) {
      switch (messageType) {
        case 'login': this.handleLoginResult(response); break;
        case 'neworder': this.handleSuccessResponse(response); break;
        case 'orders': this.handleSuccessResponse(response); break;
        case 'positions': this.handleSuccessResponse(response); break;
        case 'balances': this.handleSuccessResponse(response); break;
        case 'cancelorder':
        case 'modifyOrder':
          this.handleSuccessResponse(response);
          break;
        default:
          if (error) this.handleErrorResponse(response);
          else console.warn('[T] Unknown message:', response);
          break;
      }
    }
  }

  handleSuccessResponse(response: any) {
    const { messageType } = response;
    const promise = (response.reqId) ? this.promiseCompleters[response.reqId] : null;
    if (!promise) {
      if (response.rc && response.rc >= 300) {
        console.error('[T][handleSuccessResponse] Error response', response);
      } else {
        switch (messageType) {
          case 'orders':
            this.dispatch(ordersReceived(response));
            break;
          case 'positions':
            this.dispatch(positionsReceived(response));
            break;
          case 'balances':
            this.dispatch(balancesReceived(response));
            break;
          case 'neworder':
          case 'cancelorder':
          case 'modifyOrder':
            this.dispatch({ type: messageType, payload: response });
            break;
          default:
            console.warn(`[T][handleSuccessResponse] Unknown message '${messageType}' for success case -without promise`, response);
            break;
        }
      }
      return;
    }

    if (response.rc < 300) {
      promise.resolve(response);
    } else {
      promise.reject(response);
    }
    delete this.promiseCompleters[response.reqId];
  }

  handleErrorResponse(response: any) {
    if (response.ref) {
      const completers = this.promiseCompleters[response.ref];
      if (completers) {
        completers.reject(response.error);
      }
      delete this.promiseCompleters[response.ref];
    }
  }

  handleNewOrderResult(response: any) {
    if (response.rc < 300) {
      this.promiseCompleters[response.reqId].resolve(response);
    } else {
      this.promiseCompleters[response.reqId].reject(response);
    }
    delete this.promiseCompleters[response.reqId];
  }

  handleGetOrdersResult(response: any) {
    if (response.rc < 300) {
      this.promiseCompleters[response.reqId].resolve(response);
    } else {
      this.promiseCompleters[response.reqId].reject(response);
    }
    delete this.promiseCompleters[response.reqId];
  }

  handleLoginResult(response: any) {
    if (response.rc < 300) {
      this.status = TradeClientStatus.SUCCESSFUL;
      this.dispatch(connected());
      // this.connectionPromiseResolver!();
    } else {
      console.error('[T] Login failed', response);
      this.status = TradeClientStatus.LOGIN_FAILED;
      // this.connectionPromiseRejector!('Login failed');
      this.dispatch(tradingDisconnected());
    }
  }

  async newOrder(order: Order) {
    // await this.connectionPromise; // ART-346
    return new Promise((resolve, reject) => {
      const reqId = `${this.asyncRef++}`;
      this.promiseCompleters[reqId] = { resolve, reject }; // ART-346
      const newOrderRequest = {
        ...tradingConfig,
        messageType: 'neworder',
        reqId,
        ...order,
      };
      this.sendRequest('newOrder', newOrderRequest);
    });
  }

  async getDetails(account: string, functionality: GetDetailsFunctionalityType) {
    return new Promise((resolve, reject) => {
      const reqId = `${this.asyncRef++}${functionality.toUpperCase()}`;
      this.promiseCompleters[reqId] = { resolve, reject };

      this.sendRequest(functionality, { reqId, account, messageType: functionality.toLowerCase() });
    });
  }

  cancelOrder(order: Order, account: string) {
    const cancelOrderRequest = {
      ...tradingConfig,
      reqId: uuidv4(),
      messageType: 'cancelorder',
      account,
      clOrdId: order.clientOrderId,
      symbol: order.symbol,
    };

    this.sendRequest('cancelorder', cancelOrderRequest);
  }

  modifyOrder(order: Order, account: string) {
    const modifyOrderRequest = {
      ...tradingConfig,
      reqId: uuidv4(),
      messageType: GWRequestType.ModifyOrderRequest,
      account,
      clOrdId: order.clientOrderId,
      symbol: order.symbol,
      side: order.side,
      orderQty: order.orderQty,
      ordType: order.ordType,
      price: order.price,
      timeInForce: order.timeInForce,
      stopPrice: (order.ordType === OrderTypeEnum.Stop) ? order.stopPrice : null,
      cancelOrig: false,
    };

    this.sendRequest('modifyOrderRequest', modifyOrderRequest);
  }
}
