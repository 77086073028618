import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { CURRENT_YEAR } from '../../../lib/constants/date-time.constants';
import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { setSourceOfFundsIsEdit } from '../../../lib/store/crm';
import { getSourceOfFundsData } from '../../../lib/store/selectors';
import { getAddressValidity, getNameOfEmployerValidity, validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import InputControl from '../../InputControl/InputControl';
import { GeneralQuestionsCustomAnswers } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import {
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_IDENTITY_NAME,
  KEY_SOURCE_OF_FUND_TO,
  KEY_SOURCE_OF_FUND_TYPE,
} from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';
import YearDatePicker from '../YearDatePicker/YearDatePicker';

import styles from '../EnrollSourceOfFunds/EnrollSourceOfFunds.module.scss';

type ReduxStateProps = {
  sourceOfFunds?: any;
  isEditSourceOfFunds?: boolean;
  isClickedBtnContinue: boolean;
}

type Props = {
  formData: GeneralQuestionsCustomAnswers;
  handleChange: (formKey: string, value: string | number) => void;
} & ReduxStateProps

const EnrollSalary = ({
  handleChange,
  formData,
  sourceOfFunds,
  isEditSourceOfFunds,
  isClickedBtnContinue,
}: Props) => {
  const [customData, setCustomData] = useState(formData);
  const dispatch = useDispatch();

  if (sourceOfFunds && !isEditSourceOfFunds) {
    customData[KEY_SOURCE_OF_FUND_FROM] = sourceOfFunds.from;
    customData[KEY_SOURCE_OF_FUND_TO] = sourceOfFunds.to;
    customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] = sourceOfFunds.identity_name;
    customData[KEY_SOURCE_OF_FUND_TYPE] = sourceOfFunds.fund_type;
    dispatch(setSourceOfFundsIsEdit(true));
    handleChange('', '');
  }

  const datePickerFromYear = customData[KEY_SOURCE_OF_FUND_FROM] as string || undefined;
  const datePickerToYear = customData[KEY_SOURCE_OF_FUND_TO] as string || CURRENT_YEAR.toString();

  return (
    <div className={styles.wrapper}>
      <YearDatePicker
        formErrors={isClickedBtnContinue
          ? validateControl(
            datePickerFromYear as string,
            [],
            lang.commonSourceOfFundsDateFromLabel(),
            true,
          ).errors
          : []}
        datePickerToYear={datePickerToYear}
        datePickerFromYear={datePickerFromYear}
        handleChange={handleChange}
        setCustomData={setCustomData}
      />
      <Row className={styles.row}>
        <Col md={12} className={styles.secondaryMargin}>
          <InputControl
            name=""
            placeholder=""
            id="nameEmployer"
            ariaLabel="name of employer"
            label={lang.commonSourceOfFundsNameOfEmplLabel()}
            value={customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string}
            onBlur={(value: string | number) => {
              handleChange(KEY_SOURCE_OF_FUND_IDENTITY_NAME, value);
            }}
            onValueChange={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_IDENTITY_NAME]: value,
              }));
            }}
            errors={isClickedBtnContinue
              ? getNameOfEmployerValidity(customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string).errors
              : []}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col md={12}>
          <InputControl
            name=""
            placeholder=""
            id="addressEmployer"
            ariaLabel="address of employer"
            label={lang.commonSourceOfFundsAddressOfEmplLabel()}
            value={customData[KEY_SOURCE_OF_FUND_TYPE] as string}
            onBlur={(value: string | number) => {
              handleChange(KEY_SOURCE_OF_FUND_TYPE, value);
            }}
            onValueChange={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_TYPE]: value,
              }));
            }}
            errors={isClickedBtnContinue
              ? getAddressValidity(customData[KEY_SOURCE_OF_FUND_TYPE] as string).errors
              : []}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState): any => ({
  sourceOfFunds: getSourceOfFundsData(state, SourceOfFundEnum.SalaryAlaric),
  isEditSourceOfFunds: state.crm.enroll.isEditSourceOfFunds,
});

export default connect(mapStateToProps)(EnrollSalary);
