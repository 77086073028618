import React from 'react';
import { useHistory } from 'react-router';

import ArrotBack from '../../assets/img/blue_icon-arrow-left.svg';
import { ClickableImage } from '..';

import styles from './BaseModal.module.scss';

type Props = {
    children: any;
    onClose: () => void;
    isOpen: boolean;
}

const BaseModalMobile = (props: Props) => {
  const { children, onClose, isOpen } = props;
  const history = useHistory();

  return (
    <>
      {isOpen && (
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.arrowBack}>
            <ClickableImage src={ArrotBack} onClick={onClose} />
          </div>
          {children}
        </div>
      </div>
      )}
    </>
  );
};

export default BaseModalMobile;
