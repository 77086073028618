import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../lib/language';
import { RootState } from '../../lib/store/index';

import { Button, Preloader } from '../../components';
import { ButtonTypesEnum } from '../../components/Button/Button.types';
import { useIsMobile } from '../../hooks';
import { UI_ROUTES } from '../../util/routes';
import { DateRangeState } from '../../util/types';

import Table from './Table/Table';
import { DEFAULT_EARNINGS_CONTENT_LENGTH } from './EarningCalendat.constants';

import styles from './EarningsCalendar.module.scss';

const EarningsCalendar = ({ earnings }: ReduxProps) => {
  const isMobile = useIsMobile();
  const [showViewAllBtn, setShowViewAllBtn] = useState<boolean>(false);
  const [range, setRange] = useState<DateRangeState>({ startDate: undefined, endDate: undefined, key: 'selection' });
  const history = useHistory();

  const handleStateChange = (value) => {
    setRange(value);
  };

  useEffect(() => {
    setShowViewAllBtn(earnings.content.length > 5);
  }, [earnings.content.length]);

  return (
    <>
      <div className={styles.wrapperTitle}>
        <h6 className={styles.title}>{lang.commonDiscoverCalenderSectionEarningsTitle()}</h6>
        {showViewAllBtn && !isMobile && (
        <Button
          variant={ButtonTypesEnum.Link}
          onClick={() => history.push(UI_ROUTES.discover + UI_ROUTES.earningsCalendar, range)}
        >
          {lang.mobileFundsScreenViewAllBtn()}
        </Button>
        )}
      </div>
      <Table maxElements={DEFAULT_EARNINGS_CONTENT_LENGTH} onStateChange={handleStateChange} />
      {showViewAllBtn && isMobile && (
        <Button
          className={styles.viewAllBtn}
          variant={ButtonTypesEnum.Link}
          onClick={() => history.push(UI_ROUTES.discover + UI_ROUTES.earningsCalendar, range)}
        >
          {lang.mobileFundsScreenViewAllBtn()}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  earnings: state.fundamental.earningsSearchResponse,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EarningsCalendar);
