import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';

import lang from '../../../lib/language';

import { BaseModal, Button } from '../../../components';

import styles from './PEPDescriptionModal.module.scss';

interface Props {
  visible: boolean;
  onPressOK: () => void;
}

const bullet = (text: string, index: number) => (
  <div key={index} className={styles.row}>
    <p className={styles.item}>{'\u2022 '}</p>
    <div className={styles.bulletText}>
      <p className={styles.item}>{text}</p>
    </div>
  </div>
);

const PEPDescriptionModal = (props: Props) => {
  const { onPressOK, visible } = props;
  const descriptionItems = lang.commonPepDescription().split('\n');
  return (
    <BaseModal isOpen={visible}>
      <ModalHeader>{lang.commonPepDescriptionTitle()}</ModalHeader>
      <ModalBody>
        {descriptionItems.map(bullet)}
        <Button
          fullWidth
          className={styles.button}
          onClick={onPressOK}
        >
          {lang.commonOK()}
        </Button>
      </ModalBody>
    </BaseModal>
  );
};

export default PEPDescriptionModal;
