import OneSignal from 'react-onesignal';
import apisauce, { ApiResponse } from 'apisauce';

import { SUBDOMAIN_POSTFIX_BY_ENV } from '../lib/libSettings';
import { NullableNumber, NullableString } from '../lib/util/types';

import config from '../config';
import { logConfig } from '../configDebug';
import { ONE_SIGNAL_APP_ID } from '../constants';

if (!config) {
  console.error(`[PushNotificationService] Config not defined - ${config}`);
}

const OneSignalVerifyUrl = `https://ams-dispatcher${SUBDOMAIN_POSTFIX_BY_ENV}.alaricsecurities.net/api`;
type OneSignalVerifyResponse = { hash: string, user_id: number }

const API_PATH = {
  verify: '/v1/OneSignal/verify',
};

export default class PushNotificationService {
  // eslint-disable-next-line no-empty-function
  static async init(token: NullableString) {
    if (window?.location?.href?.includes('localhost:4210')) {
      // eslint-disable-next-line no-console
      console.warn('[PushNotificationService] Skipping initialization while running on localhost');
      return;
    }
    // eslint-disable-next-line global-require
    const { __DEV__ } = require('../configLib').default;
    const logEnabled = logConfig.pushNotifications && __DEV__;
    if (logEnabled) {
      // eslint-disable-next-line no-console
      console.info('[PushNotificationService] init - at function entrance');
    }
    if (logEnabled) {
      (window as any).PushNotification = PushNotificationService;
      if (!token) {
        console.error('[PushNotificationService] init - token not valid', token);
      }
    }
    if (logEnabled) {
      // eslint-disable-next-line no-console
      console.info(`[PushNotificationService] init - before checking token (${!!token})`);
    }
    if (!token) {
      return;
    }

    if (logEnabled) {
      // eslint-disable-next-line no-console
      console.info(`[PushNotificationService] init - before calling OneSignal.init with '${ONE_SIGNAL_APP_ID}'`);
    }
    OneSignal.init({ appId: ONE_SIGNAL_APP_ID }).then(async () => {
      OneSignal.showSlidedownPrompt()
        .then(() => {
          if (logEnabled) {
            // eslint-disable-next-line no-console
            console.info('[PushNotificationService] init - showSlidedownPrompt');
          }
          PushNotificationService.verify(token || '');
        })
        .catch((error) => {
          console.error(`[PushNotificationService] Error in init - ${error}`);
        });
    });
  }

  private static verify = (token: string) => {
    // eslint-disable-next-line global-require
    const { __DEV__ } = require('../configLib').default;
    const logEnabled = logConfig.pushNotifications && __DEV__;
    if (logEnabled) {
      // eslint-disable-next-line no-console
      console.info(`[PushNotificationService] verify - hasToken: ${!!token}`);
    }
    if (!token) return;
    try {
      const api = apisauce.create({
        baseURL: OneSignalVerifyUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      api.get<OneSignalVerifyResponse>(API_PATH.verify, { appId: ONE_SIGNAL_APP_ID })
        .then(PushNotificationService.onVerify)
        .catch((error) => console.error(`[PushNotificationService] Error onVerify - ${error}`));
    } catch (error) {
      console.error(`[PushNotificationService] Error 2, onVerify - ${error}`);
    }
  };

  private static onVerify(response: ApiResponse<OneSignalVerifyResponse>) {
    // eslint-disable-next-line global-require
    const { __DEV__ } = require('../configLib').default;
    const logEnabled = logConfig.pushNotifications && __DEV__;
    const { data } = response ?? {};
    if (!response.ok) {
      console.error('[PushNotificationService] OneSignalVerifyUrl error in response', response);
      return;
    }

    const { user_id, hash } = data ?? {};

    if (logEnabled) {
      console.info(`[PushNotificationService] onVerify - user: ${user_id}`); // eslint-disable-line no-console
      console.debug(`[PushNotificationService] onVerify - user: ${user_id}`, data); // eslint-disable-line no-console
    }

    PushNotificationService.subscribe(user_id, hash);

    if (logEnabled) {
      try {
        OneSignal.on('subscriptionChange', (isSubscribed) => {
          console.info(`[PushNotificationService] on subscriptionChange:  is subscribed -> ${isSubscribed}`); // eslint-disable-line no-console
        });
        OneSignal.on('permissionPromptDisplay', (...args: any[]) => {
          console.info(`[PushNotificationService] on permissionPromptDisplay - args: ${args?.length}`); // eslint-disable-line no-console
          console.debug(`[PushNotificationService] on permissionPromptDisplay - args: ${args?.length}`, args); // eslint-disable-line no-console
        });
        OneSignal.on('notificationPermissionChange', (...args: any[]) => {
          console.info(`[PushNotificationService] on notificationPermissionChange - args: ${args?.length}`); // eslint-disable-line no-console
          console.debug(`[PushNotificationService] on notificationPermissionChange - args: ${args?.length}`, args); // eslint-disable-line no-console
        });
        OneSignal.on('notificationDisplay', (...args: any[]) => {
          console.info(`[PushNotificationService] on notificationDisplay - args: ${args?.length}`); // eslint-disable-line no-console
          console.debug(`[PushNotificationService] on notificationDisplay - args: ${args?.length}`, args); // eslint-disable-line no-console
        });
        OneSignal.on('notificationDismiss', (...args: any[]) => {
          console.info(`[PushNotificationService] on notificationDismiss - args: ${args?.length}`); // eslint-disable-line no-console
          console.debug(`[PushNotificationService] on notificationDismiss - args: ${args?.length}`, args); // eslint-disable-line no-console
        });
      } catch (error) {
        console.error('[PushNotificationService] OneSignalVerifyUrl error in interval', error); // eslint-disable-line no-console
      }
    }
  }

  public static subscribe(userId: NullableNumber, hash: NullableString) {
    // eslint-disable-next-line global-require
    const { __DEV__ } = require('../configLib').default;
    const logEnabled = logConfig.pushNotifications && __DEV__;

    if (logEnabled) {
      console.info(`[PushNotificationService] subscribing - user: ${userId} (Alaric Individual ID)`); // eslint-disable-line no-console
      console.debug(`[PushNotificationService] subscribing - user: ${userId} (Alaric Individual ID)`, { userId, hash }); // eslint-disable-line no-console
    }
    if (!userId || !hash) {
      console.error('[PushNotificationService] subscribe - user id or hash not valid', { userId, hash }); // eslint-disable-line no-console
      return;
    }

    OneSignal.setExternalUserId(`${userId}`, hash);
  }

  public static unsubscribe(hash: NullableString) {
    // eslint-disable-next-line global-require
    const { __DEV__ } = require('../configLib').default;
    const logEnabled = logConfig.pushNotifications && __DEV__;
    if (logEnabled) {
      console.info('[PushNotificationService] Unsubscribing'); // eslint-disable-line no-console
    }
    if (!hash) {
      console.error('[PushNotificationService] unsubscribe - user id or hash not valid', { hash }); // eslint-disable-line no-console
      return;
    }

    OneSignal.setExternalUserId('', hash);
  }

  public static checkState() {
  /* eslint-disable no-console */
    console.info('[OneSignal] [PushNotification] Requesting OneSignal information ...');

    try {
      OneSignal.getSubscription()
        .then(value => console.info(`    [OneSignal] [PushNotification] Is Subscribed: ${value}`))
        .catch(error => console.error(`    [OneSignal] [PushNotification] Error checking OneSignal Is Subscribed: ${error}`));
      OneSignal.isPushNotificationsEnabled()
        .then(value => console.info(`    [OneSignal] [PushNotification] Has Push Notification Permission: ${value}`))
        .catch(error => console.error(`    [OneSignal] [PushNotification] Error checking Push Notification Permission: ${error}`));
      OneSignal.getUserId()
        .then(value => console.info(`    [OneSignal] [PushNotification] User ID (Alaric Individual ID): ${value}`))
        .catch(error => console.error(`    [OneSignal] [PushNotification] Error checking User ID (Alaric Individual ID): ${error}`));
    } catch (error) {
      console.error(`[OneSignal] [PushNotification] Error checking state - ${error}`);
    }
    /* eslint-enable no-console */
  }
}
