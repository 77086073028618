import React from 'react';
import classNames from 'classnames';

import { ChartDataRange } from '../../../../lib/store/market-data/charting/types';
import { performanceRangeText } from '../../../../lib/store/reporting/charting/helpers';
import { formatCurrency, formatNumber } from '../../../../lib/util/DataHelpers';
import { formatNetComponentsDate } from '../../../../lib/util/DateTimeHelpers';

import styles from '../chart.module.scss';

type Props = {
  firstDate: string,
  range: ChartDataRange,
  absolutePeriodChange: number,
  persentPeriodChange?: number,
  isPeriodShorterThanRange?: boolean,
}

const PerformanceChangeTotalValue = ({
  range,
  firstDate,
  persentPeriodChange,
  absolutePeriodChange,
  isPeriodShorterThanRange,
}: Props): JSX.Element => (
  <>
    <span className={classNames(styles.pastYearValue,
      { [styles.negativeValue]: absolutePeriodChange && absolutePeriodChange < 0 })}
    >
      <span>
        { absolutePeriodChange >= 0
          ? `+${formatCurrency(absolutePeriodChange, false, true)}`
          : formatCurrency(absolutePeriodChange, false, true)}
      </span>
      {!isPeriodShorterThanRange && !!persentPeriodChange && (
      <span className={styles.percentageValue}>
        (
          {Math.abs(persentPeriodChange) > 1000 ? '>1000' : formatNumber(Math.abs(persentPeriodChange))}
        %)
      </span>
      )}
    </span>
    <span className={styles.pastPeriodText}>
      {performanceRangeText(range, formatNetComponentsDate(firstDate))}
    </span>

  </>
);

export default PerformanceChangeTotalValue;
