import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { OrderSideEnum } from '../../../../../lib/enums';
import lang from '../../../../../lib/language';
import { MarketState } from '../../../../../lib/models/market-data/types';
import { RootState } from '../../../../../lib/store';
import { checkIfUserIsETFRestricted } from '../../../../../lib/store/selectors';
import MarketStateCache from '../../../../../lib/store-util/MarketStateCache';
import SymbolStatusCache from '../../../../../lib/store-util/SymbolStatusCache';
import { CalculatedSymbolPrices } from '../../../../../lib/util/ChartingHelpers';
import { formatCurrency } from '../../../../../lib/util/DataHelpers';
import { checkIfSymbolIsHalted } from '../../../../../lib/util/HaltSymbolHelper';

import { useIsMobile } from '../../../../../hooks';
import { Button } from '../../../../index';
import HaltedSymbol from '../../../../OrderActions/HaltedSymbol/HaltedSymbol';
import TradingRestrictionsModal from '../../../../TradingRestrictionsModal/TradingRestrictionsModal';
import { getChangeStyle } from '../../../getChangeStyle';
import TradeViewChartButton from '../../../TradingViewChartButton/TradeViewChartButton';
import { InfoStatusType, priceChange } from '../helpers';

import ExtraPriceInfo from './ExtraPriceInfo';
import InfoStatus from './InfoStatus';

import styles from '../ChartHeader.module.scss';

const { PreMarket, RegularHours, AfterMarket, MarketClosed } = MarketState;

type OwnProps = {
  symbol: string,
  companyName?: string,
  handleCreateOrderSellOrBuy: (target: string, isHeaderBtn: boolean) => void;
};
type Props = OwnProps & CalculatedSymbolPrices;

const MainPriceInfo = (props: Props): JSX.Element => {
  const {
    currentMainPrice,
    changeMainPrice,
    changedPercentMainPrice,
    mainPriceDirection,
    hasAfterMarketData,
    symbol,
    companyName,
    handleCreateOrderSellOrBuy,
  } = props;
  const isMobile = useIsMobile();
  const { marketState } = MarketStateCache.use();
  const symbolStatus = SymbolStatusCache.use(symbol);

  const [isSymbolHalted, setIsSymbolHalted] = useState<boolean>(false);
  const hasDeposit = useSelector<RootState, boolean>((state: RootState) => state.payment.hasDeposit);
  const [showETFRestrictionModal, setShowETFRRestrictionModal] = useState<boolean>(false);
  const isUserETFRestricted = useSelector<RootState, boolean>(
    (state: RootState) => checkIfUserIsETFRestricted(symbol, state),
  );

  const noChangeData = changedPercentMainPrice == null;
  const changeStyle = getChangeStyle(mainPriceDirection);

  const priceChangeStyle = classNames(
    styles.mainPriceChange,
    { [styles.mainPriceChangeHidden]: noChangeData },
  );

  let infoType: InfoStatusType = 'none';
  switch (marketState) {
    case PreMarket: infoType = 'pre'; break;
    case RegularHours: infoType = 'none'; break;
    case AfterMarket:
      infoType = 'after';
      break;
    case MarketClosed:
      infoType = hasAfterMarketData ? 'after' : 'close';
      break;
  }

  const handleClickButtonSellOrBuy = (target: string) => {
    if (isUserETFRestricted) {
      setShowETFRRestrictionModal(true);
    } else if (checkIfSymbolIsHalted(symbolStatus)) {
      setIsSymbolHalted(true);
    } else {
      handleCreateOrderSellOrBuy(target, true);
    }
  };

  const showRestrictionsModals = (): JSX.Element => {
    if (showETFRestrictionModal) {
      return (<TradingRestrictionsModal closeModal={() => setShowETFRRestrictionModal(false)} />);
    }
    if (isSymbolHalted) {
      return (<HaltedSymbol symbolName={companyName ?? symbol} toggleModal={() => setIsSymbolHalted(false)} />);
    }
    return (< > </>);
  };

  return (
    <>
      {showRestrictionsModals()}
      <div className={isMobile ? styles.wrapperPriceHeaderMobile : styles.wrapperPriceHeader}>
        <div>
          {isMobile ? (
            <div className={styles.mainPriceMobile}>
              { formatCurrency(currentMainPrice) }
            </div>
          ) : (
            <span className={styles.mainPrice}>
              { formatCurrency(currentMainPrice) }
            </span>
          )}
          <span className={priceChangeStyle} style={changeStyle}>
            { priceChange(changeMainPrice, changedPercentMainPrice) }
          </span>
          <InfoStatus type={infoType} />
          <ExtraPriceInfo {...props} />
        </div>
        {
        hasDeposit
        && !isMobile
        && (
        <div className={styles.wrapperButtons}>
          <Button
            className={styles.btnSell}
            onClick={() => handleClickButtonSellOrBuy(OrderSideEnum.Sell)}
          >
            {lang.mobileTradingTicketSell()}
          </Button>
          <Button
            className={styles.btnBuy}
            onClick={() => handleClickButtonSellOrBuy(OrderSideEnum.Buy)}
          >
            {lang.mobileTradingTicketBuy()}
          </Button>
        </div>
        )
      }
        { isMobile && (
          <div className={styles.tradingView}>
            <TradeViewChartButton symbol={symbol!} />
          </div>
        )}
      </div>
    </>
  );
};

export default MainPriceInfo;
