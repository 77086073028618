import { NullableString } from '../../util/types';

export type BaseCacheableType = {
    cacheKey: string,
    cacheTTL: number,
}

export type CacheableData = {
    data: any;
    cacheTime: number,
    cacheTTL: number,
}

export type CacheableDataType = {
    url: string,
    cacheName: CacheName,
    cacheKeys?: string[]
    cacheConditions?: Record<string, NullableString[]>
    cacheTTL: number,
    method: HttpMethods,
}

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    HEAD = 'HEAD',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE'
}

export enum CacheName{
    topGainers = 'cache:top-gainers',
    topLosers = 'cache:top-losers',
    searchCompanies = 'cache:search-companies',
    earningsBySymbols = 'cache:earnings-by-symbols',
    keyStatsBySymbols = 'cache:key-stats-by-symbols',
    companyBySymbols = 'cache:company-by-symbols',
    companyLogoBySymbols = 'cache:company-logo-by-symbols',
    companyLogoAndNameBySymbols = 'cache:company-logo-and-name-by-symbols',
    companyLogoMD5BySymbols = 'cache:company-logo-md-by-symbols',
    popularStocks = 'cache:popular-stocks',
    newStocks = 'cache:new-stocks',
    sectors = 'cache:sectors',
    etfs = 'cache:etfs',
    etfDetail = 'cache:etf-detail',
    symbolsBySector = 'cache:symbols-by-sectors',
    ipos = 'cache:ipos',
    splitsBySymbols = 'cache:splits-b-symbols',
    accountDetails = 'cache:account-details',
    accounts = 'cache:accounts',
    accountInfo = 'cache:account-info',
    countries = 'cache:countries',
    linkedAccountsByIndividualId = 'cache:linked-accounts-by-individual-id',
    employmentStatus = 'cache:employment-status',
    purposeOfTrading = 'cache:trading-purpose',
    messages = 'cache:messages',
    messageById = 'cache:message-by-id',
    messageSummary = 'cache:message-summary',
    availableCash = 'cache:available-cash',
    accountSummary = 'cache:account-summary',
    openPositions = 'cache:open-positions',
    dividendPayers = 'cache:dividend-payers',
    highGrowthCompanies = 'cache:high-growth-companies',
    financialQuestionnaireTypes = 'cache:financial-questionnaire-types',
    financialQuestionnaireOwnList = 'cache:financial-questionnaire-own-list',
    ownerTradingAccounts = 'cache:owner-trading-accounts',
    sourceOfFundOwn = 'cache:source-of-fund-own',
    sourceOfFundList = 'cache:source-of-fund-list',
    entitlements = 'cache:entitlements',
    orderDetailData = 'cache:order-detail',
    peopleAlsoTrade = 'cache:people-also-trade',
    wireTransferBankAccount = 'cache:wire-transfer-bank-account',
    legalDeclarations = 'cache:legal-declarations',
    clientPlatforms = 'cache:client-platforms',
}
