import { PredefinedMilisecondsTypeEnum } from '../../lib/enums/predefined-miliseconds-type.enum';
import { NullableNumber } from '../../lib/util/types';

export const getNextDailyBarTime = (barTime: number): number => {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
};

export const getNextMinuteBarTime = (barTime: number) => {
  const date = new Date(barTime * 1000);
  date.setMinutes(date.getMinutes() + 1);
  return date.getTime() / 1000;
};

export const compareTradePriceAndOHLCV = (
  currentPrice: NullableNumber, tradeTime: number, lastBar: any, resolution: any,
) => {
  const nextBarTime = resolution === '1D' ? getNextDailyBarTime(lastBar.time) : getNextMinuteBarTime(lastBar.time);
  let bar;

  if (tradeTime >= nextBarTime) {
    bar = {
      time: nextBarTime,
      open: currentPrice,
      high: currentPrice,
      low: currentPrice,
      close: currentPrice,
    };
  } else {
    bar = {
      ...lastBar,
      high: Math.max(lastBar.high, currentPrice ?? 0),
      low: Math.min(lastBar.low, currentPrice ?? 0),
      close: currentPrice,
    };
  }

  return bar;
};

export const extractUnixDays = (unixDate: number, days: number) => (
  new Date(unixDate + (PredefinedMilisecondsTypeEnum.oneDay * days))
);
