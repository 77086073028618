import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { IndividualActivationStepEnum } from '../../lib/enums';
import lang from '../../lib/language';
import { RootState } from '../../lib/store';
import { checkIndividualActivationStep } from '../../lib/store/selectors';
import { VerifyIdentityStatus } from '../../lib/util/types';

import config from '../../config';
import { UI_ROUTES } from '../../util/routes';

import ListItem from './components/ListItem';

import styles from './SymbolDetailsNotActiveBox.module.scss';

type Props = {
  className?: string
} & ReduxProps

const SymbolDetailsNotActiveBox = ({ className, activationStep }: Props) => {
  const [title, seTitle] = useState<string>('');
  const [activateAccountFinished, setActivateAccountFinished] = useState<boolean>(true);
  const [verifyIdentityStatus, setVerifyIdentityStatus] = useState<VerifyIdentityStatus>(
    { finished: false, disabled: false, pending: false },
  );
  const [firstDepositDisabled, setFirstDepositDisabled] = useState<boolean>(true);

  useEffect(() => {
    switch (activationStep) {
      case IndividualActivationStepEnum.InEnrollment:
        setActivateAccountFinished(false);
        setVerifyIdentityStatus(prevState => ({ ...prevState, disabled: true }));
        seTitle(lang.commonNextStepsTitleVerification()); break;
      case IndividualActivationStepEnum.InVerification:
        seTitle(lang.commonNextStepsTitleVerification());
        break;
      case IndividualActivationStepEnum.InPendingVerification:
        seTitle(lang.commonNextStepsTitleVerification());
        setVerifyIdentityStatus(prevState => ({ ...prevState, pending: true }));
        break;
      case IndividualActivationStepEnum.Activated:
        seTitle(lang.commonNextStepsTitleActive());
        setFirstDepositDisabled(false);
        setVerifyIdentityStatus(prevState => ({ ...prevState, finished: true }));
        break;
      default: break;
    }
  }, [activationStep]);

  return (
    <div className={[styles.wrapper, className].join(' ')}>
      <h6>{title}</h6>
      <ul>
        <ListItem
          finished
          title={lang.commonNextStepsButtonTitle_1()}
        />
        <ListItem
          finished={verifyIdentityStatus.finished && activateAccountFinished}
          pending={verifyIdentityStatus.pending}
          isRedirectPWP
          title={lang.commonNextStepsButtonTitle_2()}
        />
        <ListItem
          disabled={firstDepositDisabled}
          redirectPath={UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit}
          title={lang.commonNextStepsButtonTitle_3()}
        />
      </ul>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
  activationStep: checkIndividualActivationStep(
    state.crm.individualExtendedInfo,
    state.crm.enroll.financialQuestionnaireSections,
    state.auth.isAppropriatenessPopupConfirmed,
  ),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SymbolDetailsNotActiveBox);
