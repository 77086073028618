import React from 'react';

import Flags from '../../assets/flags';

import styles from './Country.module.scss';

type Props = {
  id: string;
  name?: string;
  phoneCode?: string;
}

const Country = ({ id, name, phoneCode }: Props) => {
  const countryId = id.toUpperCase();
  const flag = Flags[countryId];

  return (
    <div className={styles.country}>
      <img className={styles.flag} src={flag} alt="flag country" />

      <div className={styles.innerContent}>
        {phoneCode && <p>{`(${phoneCode})`}</p>}
        {name && <p className={styles.name}>{name}</p>}
      </div>
    </div>
  );
};

export default Country;
