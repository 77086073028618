import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { Action } from 'redux';

import { OrderSideEnum, TimeInForceEnum } from '../../lib/enums';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import lang from '../../lib/language';
import { Order } from '../../lib/models/trading/types';
import { RootState } from '../../lib/store';
import { OpenPosition } from '../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../lib/store/selectors';
import { newOrder as serviceNewOrder, resetRequestStatus } from '../../lib/store/trading/index';
import MarketStateCache from '../../lib/store-util/MarketStateCache';
import NBBOPriceCache from '../../lib/store-util/NBBOPriceCache';
import SymbolStatusCache from '../../lib/store-util/SymbolStatusCache';
import TradePriceCache from '../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../lib/util/DataHelpers';
import { checkIfSymbolIsHalted } from '../../lib/util/HaltSymbolHelper';
import { isMarketClosed, isMarketOpen, isMarketRegularHours } from '../../lib/util/MarketDataHelpers';
import { TextHelpers } from '../../lib/util/TextHelpers';
import { uuidv4 } from '../../lib/util/tools';
import { getOrderTotalValue } from '../../lib/util/TradingValidationsHelper';

import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import { CheckboxControl, ClickableDiv, HighLowLimitPriceAlert, NotEnoughBuyingPower } from '../../components';
import ConfirmOrderPopupMobile from '../../components/ConfirmOrderPopupMobile/ConfirmOrderPopupMobile';
import Error from '../../components/Error/Error';
import ExpiryDate from '../../components/OrderActions/components/ExpiryDate';
import LimitPriceInput from '../../components/OrderActions/components/LimitPriceInput';
import MarketPriceInfo from '../../components/OrderActions/components/MarketPriceInfo';
import { ExpirationDropDownOptions, OrderTypeDropdownOptions } from '../../components/OrderActions/order.constants';
import { getValidationPrice } from '../../components/OrderActions/OrderHelpers';
import useValidateOrder from '../../components/OrderActions/useValidateOrder';
import SelectorMobile from '../../components/SelectorMobile/SelectorMobile';
import SharesCalculator from '../../components/SharesCalculator/SharesCalculator';

import styles from './BuyAndSell.module.scss';

type Props = ReduxProps & RouteComponentProps;

const BuyAndSell = (props: Props) => {
  const { positions, account, buyingPow, newOrder } = props;
  const history = useHistory();
  const location = useLocation();

  const [symbol, setSymbol] = useState<string>('');
  const [target, setTarget] = useState<string>('');
  const [originPath, setOriginPath] = useState<string>('');

  useEffect(() => {
    const { stateSymbol, stateTarget, origin } = (location.state as any) || {};
    setSymbol(stateSymbol);
    setTarget(stateTarget);
    setOriginPath(origin);
  }, [location.state]);

  const isBuyOrder = target === 'Buy';
  const symbolPosition = positions.find(el => el.symbol === symbol);
  const symbolsStatusCache = SymbolStatusCache.use(symbol);
  const { marketState } = MarketStateCache.use();
  const showMarketInfoIcon = isMarketOpen(marketState);
  const marketClosed = isMarketClosed(marketState);
  const isMarketHours = isMarketRegularHours(marketState);
  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'CreateOrder');
  const nbboData = NBBOPriceCache.use(symbol);
  const { askprice, bidprice } = nbboData;

  const [timeInForce, setTimeInForce] = useState<string>(TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay));
  const [includeExtendedHours, setIncludeExtendedHours] = useState<boolean>(false);
  const [targetPrice, setTargetPrice] = useState<number>(0);
  const [targetPriceError, setTargetPriceError] = useState<string>('');
  const [orderType, setOrderType] = useState<string>(isMarketHours ? OrderTypeEnum.Market : OrderTypeEnum.Limit);
  const [quantity, setQuantity] = useState<number>(1);
  const [numberOfSharesError, setNumberOfSharesError] = useState<string>('');
  const [showReviewOrder, setShowReviewOrder] = useState<boolean>(false);
  const [openedEnoughBPModal, setOpenedEnoughBPModal] = useState<boolean>(false);
  const [limitPriceFocused, setLimitPriceFocused] = useState<boolean>(false);
  const [isUserDeviceIos, setIsUserDeviceIos] = useState<boolean>(false);
  const [showSharesCount, setShowSharesCount] = useState<boolean>(false);
  const [visibleHighLowLimitPriceAlert, setVisibleHighLowLimitPriceAlert] = useState<boolean>(false);
  const isMarketOrder = orderType === OrderTypeEnum.Market;

  const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice, isBuyOrder);
  const { errorMessage, controlCheck } = useValidateOrder(orderType, validationPirce ?? 0, targetPrice, isBuyOrder);

  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
      setIsUserDeviceIos(true);
    }
  }, []);

  const checkIfOrderIsDisabled = (option: string) => {
    if (isMarketHours) return false;
    if (option === OrderTypeEnum.Market) return true;
    if (option === OrderTypeEnum.Stop) return true;
    if (option === OrderTypeEnum.Limit) return false;
    return true;
  };

  const orderTypeOptions = OrderTypeDropdownOptions(isMarketHours, isBuyOrder).map((option) => ({
    ...option,
    isDisabled: checkIfOrderIsDisabled(option.value as string),
  }));

  const totalValue = getOrderTotalValue(
    orderType,
    quantity,
    tradePrice,
    targetPrice,
  );

  useEffect(() => {
    setShowSharesCount(!!((target === OrderSideEnum.Sell && symbolPosition && symbolPosition.quantity > 0)
      || (target === OrderSideEnum.Buy && symbolPosition && symbolPosition.quantity < 0)));
  }, [symbolPosition, target]);

  const isValidOrder = useCallback((): boolean => {
    setTargetPriceError(errorMessage);
    const buyingPowerCheck = totalValue < buyingPow;

    if (!showSharesCount && (controlCheck && !buyingPowerCheck)) {
      setOpenedEnoughBPModal(true);
    }

    if (showSharesCount && symbolPosition) {
      return (Math.abs(symbolPosition.quantity) >= quantity) && controlCheck;
    }

    return buyingPowerCheck;
  }, [buyingPow, controlCheck, errorMessage, quantity, showSharesCount, symbolPosition, totalValue]);

  const createOrder = () => {
    const orderObject: Order = {};
    if (orderType === OrderTypeEnum.Stop) {
      orderObject.stopPrice = targetPrice;
    }

    const parsedTime = timeInForce === TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay)
      ? TimeInForceEnum.EndOfDay : TimeInForceEnum.GTC;

    orderObject.account = account;
    orderObject.clientOrderId = uuidv4();
    orderObject.includeExtendedHours = includeExtendedHours;
    orderObject.ordType = orderType as OrderTypeEnum;
    orderObject.orderQty = quantity;
    orderObject.side = target as OrderSideEnum;
    orderObject.symbol = symbol;
    orderObject.timeInForce = parsedTime;

    if (!isMarketOrder) {
      orderObject.price = targetPrice;
    }

    sessionStorage.setItem('showUseDetailsEndModal', 'true');
    newOrder(orderObject);
    setShowReviewOrder(false);
    history.replace({
      pathname: originPath,
      state: { data: orderObject },
    });
  };

  const onSubmit = () => {
    if (orderType === OrderTypeEnum.Limit && tradePrice) {
      if (
        (isBuyOrder && (targetPrice > tradePrice))
        || (!isBuyOrder && (targetPrice < tradePrice))
      ) {
        setVisibleHighLowLimitPriceAlert(
          isBuyOrder ? (targetPrice > tradePrice) : (targetPrice < tradePrice),
        );
        return;
      }
    }
    if (isValidOrder()) {
      setShowReviewOrder(true);
    }
  };

  const onLimitFocus = () => {
    setLimitPriceFocused(true);
  };

  const onLimitBlur = () => {
    setTimeout(() => {
      setLimitPriceFocused(false);
    }, 150);
  };

  return (
    <div className={styles.wrapper}>
      <ClickableDiv
        className={styles.backArrow}
        onClick={() => {
          history.replace({
            pathname: originPath,
          });
        }}
      >
        <img src={ArrowBack} alt="Back" />
      </ClickableDiv>
      <div className={styles.headerText}>
        {`${symbol} ${isBuyOrder ? OrderSideEnum.Buy : OrderSideEnum.Sell} Order`}
      </div>
      <div className={styles.wrapperOrderContainer}>
        <div className={styles.wrapperOrderTypeDropDown}>
          <SelectorMobile value={orderType} options={orderTypeOptions} setSelected={setOrderType} />
        </div>
        <div className={styles.wrapperNumberOfShares}>
          <div className={showSharesCount
            ? undefined
            : styles.sharesInfo}
          >
            <div className={styles.numberOfSharesText}>
              {lang.mobileTradingTicketNumberOfShares()}
            </div>
            {showSharesCount && symbolPosition && (
            <div className={styles.numberOfSharesSubText}>
              {isBuyOrder
                ? lang.webTradingShortedStocks(Math.abs(symbolPosition.quantity))
                : lang.webTradingAvailableShares(Math.abs(symbolPosition.quantity))}
            </div>
            )}
          </div>
          <div className={styles.wrapperSharesField}>
            <SharesCalculator
              disabled={checkIfSymbolIsHalted(symbolsStatusCache)}
              quantity={quantity}
              setQuantity={setQuantity}
              maxQuantity={symbolPosition?.quantity || undefined}
              clearNumberOfSharesError={setNumberOfSharesError}
            />
          </div>
        </div>
        {orderType !== OrderTypeEnum.Market && (
          <div className={styles.wrapperInputPrice}>
            <LimitPriceInput
              disabled={checkIfSymbolIsHalted(symbolsStatusCache)}
              initialPrice={0}
              orderType={orderType}
              onFocus={onLimitFocus}
              onBlur={onLimitBlur}
              setTargetPrice={(value) => setTargetPrice(value)}
              targetPriceError={targetPriceError}
              setTargetPriceError={(value) => setTargetPriceError(value)}
            />
          </div>
        )}
        {numberOfSharesError && (numberOfSharesError.trim() !== '') && (
        <div className={styles.errorWrapper}>
          <Error hiddenIcon errorMessage={numberOfSharesError} />
        </div>
        )}
        <div className={styles.wrapperPriceInfo}>
          <MarketPriceInfo
            askPrice={askprice}
            bidPrice={bidprice}
            symbol={symbol}
            tradePrice={tradePrice}
            isMarketOpen={showMarketInfoIcon}
            isSymbolDetailsBigScreen
          />
        </div>
        {!isMarketOrder && (
        <ExpiryDate
          isMarketHours={isMarketRegularHours(marketState)}
          time={timeInForce}
          extendedHours={includeExtendedHours}
          positionsBySymbol={positions.find((position: OpenPosition) => (position.symbol === symbol))}
          orderType={orderType}
          orderSide={target}
          setTimeInForce={(value) => setTimeInForce(value)}
          setIncludeExtendedHours={(value) => setIncludeExtendedHours(value)}
        />
        )}
        <div className={styles.borderLine} />
        <div className={styles.totalValueContainer}>
          <div className={styles.text}>{lang.commonTradingOrderValue()}</div>
          <div className={styles.value}>
            {formatCurrency(totalValue)}
          </div>
        </div>
        { !showSharesCount && (
          <div className={styles.availableBuyingPowerContainer}>
            <div className={styles.text}>{lang.mobileOrderCreateBuyingPower()}</div>
            <div className={styles.value}>{formatCurrency(buyingPow)}</div>
          </div>
        )}
        <button
          className={
            (isUserDeviceIos && limitPriceFocused) || window.innerHeight < 720
              ? styles.submitButtonIos
              : styles.submitButton
          }
          type="button"
          onClick={onSubmit}
        >
          {lang.mobileOrderCreateButtonReviewOrder()}
        </button>
        { openedEnoughBPModal && <NotEnoughBuyingPower closeModal={() => setOpenedEnoughBPModal(false)} /> }
        {visibleHighLowLimitPriceAlert && (
          <HighLowLimitPriceAlert
            isBuyOrder={isBuyOrder}
            processModal={() => {
              setVisibleHighLowLimitPriceAlert(false);
              setShowReviewOrder(true);
            }}
            toggleModal={() => setVisibleHighLowLimitPriceAlert(false)}
          />
        )}
        {showReviewOrder && (
          <ConfirmOrderPopupMobile
            symbol={symbol}
            action={target}
            orderType={orderType}
            sharescount={quantity}
            marketPrice={tradePrice}
            targetPrice={targetPrice}
            orderValue={totalValue}
            onCancel={setShowReviewOrder}
            onConfirm={createOrder}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  buyingPow: getCurrentBuyingPower(state),
  account: state.crm.accounts[state.crm.selectedAccount],
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  newOrder: (order: Order) => dispatch(serviceNewOrder({ order })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(BuyAndSell);
