// TODO: Refactor this code to a readable state - ART-3530
// For example these methods: 'monthlyAxisValues', 'threeMonthsAxisValues', 'yearlyAxisValues' etc. have lots of one-liners that do too many operations as well as TypeScript magic in order to avoid errors.
import moment from 'moment';

import { MarketState, MarketStateInstrumentSnapshot, OHLCV, TradeData } from '../models/market-data/types';
import { Order } from '../models/trading/types';
import {
  HUNDRED,
  HUNDRED_THOUSAND,
  HUNDRED_THOUSANDS_BOUNDARY,
  HUNDREDS_BOUNDARY,
  ONE,
  ONE_HUNDREDTH,
  ONE_TENTH,
  ONE_TENTHS_BOUNDARY,
  ONE_THOUSANDTH,
  ONE_VALUE_MIN_BOUNDARY,
  ONES_BOUNDARY,
  TEN,
  TEN_THOUSAND,
  TEN_THOUSANDS_BOUNDARY,
  TENS_BOUNDARY,
  THOUSAND,
  THOUSAND_BOUNDARY,
} from '../store/market-data/charting/constants';
import {
  ChartCombinedPointData,
  ChartPointWithData,
  SymbolPriceData,
} from '../store/market-data/charting/types';
import { NetIncomeComponentsChartData } from '../store/reporting/types';
import MarketStateCache from '../store-util/MarketStateCache';
import { PriceChangeDirection } from '../store-util/TradePriceCache';

import { calculateChange, calculateDirectionFromChange } from './DataChangeHelpers';
import { formatNumber, isOneOf } from './DataHelpers';
import { CombinedOHLCVOrNetIncomeChartData, CombinedOHLCVOrNetIncomeChartDataProperty, NullableNumber } from './types';


/**
 * Returns `ohlcvItem` if `sortedOrders` length is 0
 * Otherwise - modifies `sortedOrders` removing all that have `updatedAt` <  `pointDate`
 * Then returns a combined `ohlcvItem` data with `orders` if any with `updatedAt` <  `pointDate` found.
 * If none found - returns `ohlcvItem` with `orders` undefined
 */
function processOrders(ohlcvItem: (OHLCV | ChartPointWithData), sortedOrders: Order[]): ChartCombinedPointData | OHLCV {
  if (sortedOrders.length === 0) return ohlcvItem;

  let orders: Order[] | undefined = [];
  const { dateAndTime } = ohlcvItem as ChartPointWithData;
  const pointDate = moment.utc(`${dateAndTime}`);
  let currentOrder = sortedOrders[0];
  let { updatedAt } = currentOrder;
  let orderDate = moment.utc(updatedAt);

  while (currentOrder && orderDate <= pointDate) {
    orders.push(currentOrder);
    sortedOrders.shift();
    currentOrder = sortedOrders[0]; // eslint-disable-line
    if (currentOrder) {
      updatedAt = currentOrder.updatedAt;
      orderDate = moment.utc(updatedAt);
    }
  }

  if (orders.length === 0) orders = undefined;

  return {
    ...ohlcvItem,
    orders,
  };
}

export const addOrdersToLineData = (lineData: (OHLCV | ChartPointWithData)[], orders: Order[]) => {
  const sortedOrders = orders.sort((a, b) => {
    const aDate = new Date(`${a.updatedAt!}Z`);
    const bDate = new Date(`${b.updatedAt!}Z`);

    switch (true) {
      case (aDate < bDate): return -1;
      case (aDate > bDate): return 1;
      default: return 0;
    }
  });

  return lineData.map(item => processOrders(item, sortedOrders));
};

export const orderPoints = (orders: Order[], data: any[]) => (
  orders.map(({ updatedAt, side }) => ({
    x: ordersChart(orders, data).find(({ date }) => date === updatedAt)?.x,
    y: ordersChart(orders, data).find(({ date }) => date === updatedAt)?.y,
    side,
  })));

export const ordersChart = (orders: Order[], data: any[]) => (
  // data.filter(val => orders.map(order => order.updatedAt).includes(val.date)).map(({ x, y }) => ({ x, y })));
  data.filter(val => orders.map(order => order.updatedAt).includes(val.date)));

export type CalculatedSymbolPrices = ReturnType<typeof calculateSymbolPrices>;
/**
 * Calculates prices to be displayed in header for a certain symbol (instrument, stock).
 * Usually called in UI component (tsx) on tradePrice change.
 * @param symbolPriceData Price data for symbol
 * @param marketState The current state of market, calculated against now
 * @param tradeData Last trade data of the symbol (aka instrument)
 * @returns CalculatedSymbolPrices
 */
export function calculateSymbolPrices(
  symbolPriceData: SymbolPriceData,
  tradeData?: TradeData | null,
) {
  const {
    lastPrice,
    close,
    afterClose,
    previousClose,
    previousAfterClose,
    hasDataForToday,
    hasAfterMarketData,
  } = symbolPriceData;
  let currentMainPrice: NullableNumber;
  let prevMainPrice: NullableNumber;
  let currentExtraPrice: NullableNumber;
  let prevExtraPrice: NullableNumber;
  const { tradeprice = null, tradetime = null } = tradeData ?? {};
  const { marketStateInstrumentSnapshot } = MarketStateCache.get();

  switch (marketStateInstrumentSnapshot) {
    case MarketStateInstrumentSnapshot.IS_PreMarket:
      currentMainPrice = tradeprice ?? lastPrice;
      prevMainPrice = close;
      currentExtraPrice = close;
      prevExtraPrice = previousClose;
      break;

    case MarketStateInstrumentSnapshot.IS_RegularHours:
      currentMainPrice = tradeprice ?? lastPrice;
      prevMainPrice = close;
      currentExtraPrice = null;
      prevExtraPrice = null;
      break;

    case MarketStateInstrumentSnapshot.IS_AfterMarket:
      currentMainPrice = tradeprice ?? lastPrice;
      prevMainPrice = close;
      currentExtraPrice = close;
      prevExtraPrice = previousClose;
      break;

    case MarketStateInstrumentSnapshot.IS_MarketClosed:
    case MarketStateInstrumentSnapshot.IS_MarketClosedNextDay:
      if (hasAfterMarketData) {
        currentMainPrice = lastPrice;
        prevMainPrice = close;
        currentExtraPrice = close;
        prevExtraPrice = previousClose;
      } else {
        currentMainPrice = close;
        prevMainPrice = previousClose;
        currentExtraPrice = null;
        prevExtraPrice = null;
      }
      break;

    default:
      break;
  }

  if (!currentMainPrice) {
    return {
      lastTradePrice: tradeprice,
      lastTradeTime: tradetime,
      currentMainPrice,
      prevMainPrice,
      hasAfterMarketData,
      mainPriceDirection: '' as PriceChangeDirection,
      extraPriceDirection: '' as PriceChangeDirection,
    };
  }

  const {
    change: changeMainPrice,
    percent: changedPercentMainPrice,
  } = calculateChange(currentMainPrice, prevMainPrice);
  const mainPriceDirection = calculateDirectionFromChange(changeMainPrice);

  const {
    change: changeExtraPrice,
    percent: changedPercentExtraPrice,
  } = calculateChange(currentExtraPrice, prevExtraPrice);
  const extraPriceDirection = calculateDirectionFromChange(changeExtraPrice);

  return {
    lastTradePrice: tradeprice,
    lastTradeTime: tradetime,
    currentMainPrice,
    prevMainPrice,
    currentExtraPrice,
    prevExtraPrice,
    mainPriceDirection,
    changeMainPrice,
    changedPercentMainPrice,
    extraPriceDirection,
    changeExtraPrice,
    changedPercentExtraPrice,
    hasDataForToday,
    hasAfterMarketData,
  };
}

export const monthlyAxisValues = (
  data: CombinedOHLCVOrNetIncomeChartData[],
  key: CombinedOHLCVOrNetIncomeChartDataProperty,
): string[] => {
  const theData: (typeof data extends OHLCV[] ? OHLCV[] : NetIncomeComponentsChartData[]) = data as any;
  const theKey: (typeof data[0] extends OHLCV ? keyof OHLCV : keyof NetIncomeComponentsChartData) = key as any;

  let monthlyValues: string[] = [];
  let isChangeWeek = true;

  for (let index = 0; index < data.length; index++) {
    if (moment(theData[index][theKey]).weekday() === 1 && isChangeWeek) {
      isChangeWeek = false;
      monthlyValues.push(`${(theData[index][theKey] as string)?.substring(8, 10)}/${(theData[index][theKey] as string)?.substring(5, 7)}`);
    } else {
      monthlyValues.push('');
    }
    if (moment(theData[index][theKey]).weekday() !== 1) {
      isChangeWeek = true;
    }
  }

  return monthlyValues;
};

export const threeMonthsAxisValues = (
  data: CombinedOHLCVOrNetIncomeChartData[],
  key: CombinedOHLCVOrNetIncomeChartDataProperty,
): string[] => {
  const theData: (typeof data extends OHLCV[] ? OHLCV[] : NetIncomeComponentsChartData[]) = data as any;
  const theKey: (typeof data[0] extends OHLCV ? keyof OHLCV : keyof NetIncomeComponentsChartData) = key as any;

  let helper = 0;
  let threeMonthsValues: string[] = [];

  for (let index = 0; index < data.length; index++) {
    if (moment(theData[index][theKey]).weekday() === 1) {
      if (helper % 3 === 0) {
        threeMonthsValues.push(`${(theData[index][theKey] as string)?.substring(8, 10)}/${(theData[index][theKey] as string)?.substring(5, 7)}`);
      } else {
        threeMonthsValues.push('');
      }
      helper++;
    } else {
      threeMonthsValues.push('');
    }
  }

  return threeMonthsValues;
};


export const yearlyAxisValues = (
  data: CombinedOHLCVOrNetIncomeChartData[],
  key: CombinedOHLCVOrNetIncomeChartDataProperty,
): string[] => {
  const theData: (typeof data extends OHLCV[] ? OHLCV[] : NetIncomeComponentsChartData[]) = data as any;
  const theKey: (typeof data[0] extends OHLCV ? keyof OHLCV : keyof NetIncomeComponentsChartData) = key as any;

  let helper = 0;
  let yearValues: string[] = [];
  const month = (theData[0]?.[theKey] as string)?.substring(5, 7);
  if (!month) return [];

  yearValues.push(`${moment().month(Number(month) - 1).format('MMM')} `);

  for (let index = 0; index < data.length - 1; index++) {
    const prevMonth = (theData[index][theKey] as string)?.substring(5, 7);
    const currentMonth = (theData[index + 1][theKey] as string)?.substring(5, 7);
    if (prevMonth !== currentMonth) {
      helper++;
      if (helper % 3 === 0) {
        yearValues.push(moment().month(Number(currentMonth) - 1).format('MMM'));
      } else {
        yearValues.push('');
      }
    } else {
      yearValues.push('');
    }
  }
  yearValues.push('');

  return yearValues;
};

export const fiveYearAxisValues = (
  data: CombinedOHLCVOrNetIncomeChartData[],
  key: CombinedOHLCVOrNetIncomeChartDataProperty,
): string[] => {
  const theData: (typeof data extends OHLCV[] ? OHLCV[] : NetIncomeComponentsChartData[]) = data as any;
  const theKey: (typeof data[0] extends OHLCV ? keyof OHLCV : keyof NetIncomeComponentsChartData) = key as any;
  let fiveYeasDateValues: string[] = [];
  const firstYear = (theData[0][theKey] as string)?.substring(0, 4);

  fiveYeasDateValues.push(firstYear ?? '');

  for (let index = 1; index < data.length; index++) {
    const prevYear = (theData[index - 1][theKey] as string)?.substring(0, 4);
    const currentYear = (theData[index][theKey] as string)?.substring(0, 4);
    if (currentYear !== prevYear) {
      fiveYeasDateValues.push(currentYear ?? '');
    } else {
      fiveYeasDateValues.push('');
    }
  }

  return fiveYeasDateValues;
};

export const roundValue = (value: number, step: number) => {
  switch (step) {
    case HUNDRED_THOUSAND: case TEN_THOUSAND: case THOUSAND: case HUNDRED:
      return Math.round(value / (step / TEN)) * (step / TEN);
    case TEN: case ONE: return Math.round(value);
    case ONE_TENTH: return formatNumber(value, false, false, false, 1, false);
    default: return formatNumber(value, false, false, false, 2, false);
  }
};

const calculatePrecision = (value: number): number => {
  let precision = 0;
  if (value / 100 > 1) {
    precision = 5;
  } else if ((value / 10) > 1) {
    precision = 4;
  } else {
    precision = 3;
  }

  return precision;
};

const calculateStep = (diff: Number): number => {
  let step = 0;
  switch (true) {
    case diff >= HUNDRED_THOUSANDS_BOUNDARY: step = HUNDRED_THOUSAND; break;
    case diff >= TEN_THOUSANDS_BOUNDARY: step = TEN_THOUSAND; break;
    case diff >= THOUSAND_BOUNDARY: step = THOUSAND; break;
    case diff >= HUNDREDS_BOUNDARY: step = HUNDRED; break;
    case diff >= TENS_BOUNDARY: step = TEN; break;
    case diff >= ONES_BOUNDARY: step = ONE; break;
    case diff >= ONE_TENTHS_BOUNDARY: step = ONE_TENTH; break;
    default: step = ONE_HUNDREDTH;
  }
  return step;
};

export const calculatePoints = (minValue: number, maxValue: number) => {
  let step = 0;
  let normMin = 0;
  let normMax = 0;
  let yLabelPoints: number[] = [];
  const diff = maxValue - minValue;
  let normDiff = Math.round(diff);

  if (maxValue === minValue) {
    let diffCoef: number;
    if (minValue === 0) {
      return { step: 0, yLabelPoints: [ -1, 0, 1 ], normMin: 0 };
    }
    if (Math.abs(minValue) >= THOUSAND_BOUNDARY) {
      diffCoef = ONE_TENTH;
      yLabelPoints = [ minValue - ONE_HUNDREDTH * minValue, minValue, minValue + minValue * ONE_HUNDREDTH ];
    } else if (Math.abs(minValue) < ONE_VALUE_MIN_BOUNDARY) {
      diffCoef = ONE_TENTH;
      yLabelPoints = [ minValue - ONE * minValue, minValue, minValue + minValue * ONE ];
    } else if (Math.abs(minValue) < ONE) {
      diffCoef = ONE_TENTH;
      yLabelPoints = [ minValue - ONE_TENTH * minValue, minValue, minValue + minValue * ONE_TENTH ];
    } else {
      diffCoef = ONE_HUNDREDTH;
      yLabelPoints = [ minValue - ONE_HUNDREDTH * minValue, minValue, minValue + ONE_HUNDREDTH * minValue ];
    }
    const difference = Math.abs((minValue + minValue * diffCoef) - (minValue - diffCoef * minValue));
    step = calculateStep(difference);

    return { step, yLabelPoints, normMin };
  }

  step = calculateStep(diff);
  const updatedStep = step / TEN;

  // calculate rounded min and max values
  switch (step) {
    case ONE_TENTH:
      normMin = Number(formatNumber(Math.floor(minValue / step) * step, false, false, false, 1, false));
      normMax = Number(formatNumber(Math.ceil(maxValue / step) * step, false, false, false, 1, false));
      break;
    case ONE_HUNDREDTH: case ONE_THOUSANDTH:
      normMin = Math.floor(Number((minValue / step).toPrecision(calculatePrecision(minValue)))) * step;
      normMax = Math.ceil(Number((maxValue / step).toPrecision(calculatePrecision(maxValue)))) * step;
      break;
    case HUNDRED: case THOUSAND: case TEN_THOUSAND: case HUNDRED_THOUSAND:
      normMin = Math.floor(minValue / updatedStep) * updatedStep;
      normMax = Math.ceil(maxValue / updatedStep) * updatedStep;
      break;
    default:
      normMin = Math.floor(minValue / step) * step;
      normMax = Math.ceil(maxValue / step) * step;
      break;
  }

  // calculate rounded difference
  switch (step) {
    case HUNDRED_THOUSAND: case TEN_THOUSAND: case THOUSAND:
    case HUNDRED: case TEN: case ONE: normDiff = normMax - normMin; break;
    case ONE_TENTH: normDiff = Number(formatNumber(normMax - normMin, false, false, false, 1)); break;
    case ONE_HUNDREDTH: normDiff = Number(formatNumber(normMax - normMin, false, false, false)); break;
    default: normDiff = Number(formatNumber(normMax - normMin, false, false, false, 3)); break;
  }

  const finalDiff = Math.round(normDiff / step);
  const newDiff = finalDiff + 1;
  const newNormalDiff = normDiff + step;

  switch (true) {
    case finalDiff % 3 === 0: // we show 4 values
      yLabelPoints = [ normMin, normMin + (normDiff / 3), normMin + ((normDiff / 3) * 2), normMax ];
      break;
    case finalDiff % 2 === 0: // we show 3 values
      yLabelPoints = [ normMin, normMin + normDiff / 2, normMax ];
      break;
    case newDiff % 3 === 0: // we show 4 values
      yLabelPoints = [ normMin, normMin + (newNormalDiff / 3), normMin + ((newNormalDiff / 3) * 2), normMax + step ];
      break;
    case newDiff % 2 === 0: // we show 3 values
      yLabelPoints = [ normMin, normMin + newNormalDiff / 2, normMax + step ];
      break;
    default: break;
  }

  return { step, yLabelPoints, normMin };
};

export const getPriceColorByDirection = (direction: PriceChangeDirection): string => (
  direction === '-' ? '#EF5B5B' : '#4AD295'
);

export const getChartLineColorByLineClose = (lineClose: NullableNumber, lastCloseValue: NullableNumber) => (
  !lineClose || !lastCloseValue || (lineClose > lastCloseValue) ? '#EF5B5B73' : '#4AD295C3'
);

export const getChartFillByLineClose = (lineClose: NullableNumber, lastCloseValue: NullableNumber) => (
  !lineClose || !lastCloseValue || (lineClose > lastCloseValue) ? '#EF5B5B33' : '#4AD29593'
);

/** Returns `true` if Extra Price Info (Line 2) in Chart Header in Symbol Details needs to be hidden */
export function checkHideExtraPriceInfoInChartHeader(
  marketState: MarketState | null,
  hasAfterMarketData: boolean | null | undefined,
) {
  return isOneOf(marketState, [ MarketState.PreMarket, MarketState.RegularHours ]) || !hasAfterMarketData;
}

export const chartLineClose = (lineClose: number, minDomainY: number, diff: number, chartHeight: number) => (
  Math.abs(lineClose - minDomainY) * (chartHeight / diff)
);
