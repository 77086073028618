import { ThrottleSettings } from 'lodash';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

import mainConfig from '../config';
import configLib from '../configLib';

import { PredefinedMilisecondsTypeEnum } from './enums/predefined-miliseconds-type.enum';

export const toggleLibSettingsOption = (option: 'auto-unsubscribe') => {
  switch (option) {
    case 'auto-unsubscribe':
      USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH = !USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH;
      break;

    default:
      break;
  }
};


/**
 * Default definition is in configLib.__TEST__
 * Re-Defined here to avoid import of configLib. It is `true` when code is run in unit testing jest mode.
 */
const __TEST__ = process.env.NODE_ENV === 'test'; // eslint-disable-line no-underscore-dangle


//   === Order Ticket related   ===

// --- Modals ---
// Mobile
export const ORDER_DRAWER_WAIT_TIME_MS = 1000;
export const ORDER_ACCEPTED_MODAL_STAY_MS = 3000;
export const ORDER_REJECTED_MODAL_STAY_MS = 3000;
// Web
export const PROCESS_FINISHED_MODAL_DELAY_MS = 3500;
export const PROCESS_CANCEL_MODAL_DELAY_MS = 3000;
// Order managing time (1000) + reporting get-account-trade-history-pending-new time (3500)
export const UPDATE_BALANCES_ORDER_DELAY = 4000;

export let USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH: boolean = __TEST__ || false;
/**
 * Used to optimize subscriptions processing - only if USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH is `false`
 * When enabled - only visible symbols are updated in cache.
 */
export let USE_ONLY_VISIBLE_SYMBOLS_IF_NOT_AUTO_UNSUBSCRIBE: boolean = !__TEST__ && true;

export const THROTTLE_TIME_PRICE_UPDATES_MS = configLib.isWeb ? 0 : 2000;
export const THROTTLE_OPTIONS: ThrottleSettings = { leading: true };

/**
 * Probably will not be used. Meant to delete other groups from cache.
 */
export const DELETE_PREVIOUS_SUBSCRIBE_GROUP = __TEST__ || false;


//   === Connection related   ===

export const IGNORE_GATEWAY_CONNECTION = true;

export const PRELOADER_TIMEOUT_MS = 5000;
export const PRELOADER_CACHE_TIMEOUT_MS = 3000;

/**
 * Flags for solving connection related issues (lock/unlock, minimise/restore, token refresh etc.)
 * Used in ART-3968, ART-4000 etc.
 */
export const DISCONNECT_ON_APP_MINIMISE_OR_LOCK = true; // Implemented in ART-3968
export const RECONNECT_TAPI_AND_GW_ON_GW_DOWN = true; // Implemented in ART-3968
export const TOKEN_REFRESH_ENABLED = true; // Used in ART-4000


//   === Charts Data   ===

export const BATCH_LOAD_WATCHLST_CHART = true;
export const CHARTING_TIME_TZ: 'local' | 'new-york' | 'utc' = 'new-york';
/** chart is ~80 points max -> maximum 17 symbols per request (1499 maximum rows before paing / 80 max points = 18.7 max symbols per request) */
export const CHART_MAX_SYMBOLS_PER_REQUEST_LIMIT_1D5m = 17;
/** chart is ~~65 points max -> maximum 22 symbols maximum per request (1499 maximum rows before paing / 65 max points = 23 max symbols per request) */
export const CHART_MAX_SYMBOLS_PER_REQUEST_LIMIT_3M1D = 22;


//   === Onboarding related   ===

export const TEN_MB = 10;


//   === Push Notifications related   ===

const getSubdomainPostfixByEnv = (): string => (mainConfig?.currentEnv !== 'prod' ? `-${mainConfig?.currentEnv}` : '');
export let SUBDOMAIN_POSTFIX_BY_ENV = getSubdomainPostfixByEnv();

//   === Cache related   ===

// used independently as flags in epics
export const CACHED_MARKET_DATA_CALLS = {
  instrumentSnapshot: true,
  /** If false - MarketDataSummary calls with call 1D5m are not cached during open market */
  marketDataSummary1D5mInOpenMarket: false,
  /** MarketDataSummary calls for Symbol Details - see also `marketDataSummary1D5mInOpenMarket` */
  marketDataSummaryBig: true,
  /** if enabled - adds 1D and 3M caches from SmallChart to BigChart */
  marketDataSummaryBigPreCacheWithSmall: true,
};
// used together with CallsCache
export const CALLS_TO_MEMOIZE = {
  // gateway
  getChartingData: {
    expire: [ 10, 'seconds' ],
    timeout: [ 5, 'seconds' ],
    isCached: true,
  } as FullMemoizedCallSettings,
  getInstrumentSnapshot: true,
  subscribe: true,
  // crm
  /**
   * @deprecated
   * HTTP response automatically was cached in the Axios interceptor.
   * Cache related operations are in CacheService
   */
  getCountryList: true,
  financialQuestionnaire: true,
  // fundamental
  // TODO: Delete getCompanyLogo and getCompany after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  getCompanyLogo: true,
  getCompany: true,
  getCompanyLogoAndName: true,
  // for custom stocks lists that are dynamically build and need to use CallsCache - 'People also trade' is the only case for now
  customStockList: {
    expire: [ 5, 'minutes' ],
    timeout: [ 5, 'seconds' ],
    isCached: true,
  },
};

export type CacheableCalls = keyof (typeof CALLS_TO_MEMOIZE)
export type FullMemoizedCallSettings = {
  expire?: [DurationInputArg1, DurationInputArg2] | null
  timeout?: [DurationInputArg1, DurationInputArg2] | null
  isCached?: boolean
}
export type MemoizedCallSettings = boolean | FullMemoizedCallSettings
export type MemoizedCalls = Record<CacheableCalls, MemoizedCallSettings>


//   === Timeout for initial loading ===
export const SHOW_UI_AFTER_CONNECTED_DELAY = 1000;
export const INITIAL_LOADING_TIMEOUT = 15000;

//   === Timeout for rest calls   ===
export const REQUEST_TIMEOUT = 25000;
export const FILE_REQUEST_TIMEOUT = 40000;

//   === Automatic token refresh ===
export const TOKEN_MIN_TIME_TO_EXPIRATION = PredefinedMilisecondsTypeEnum.tenMinutes;
