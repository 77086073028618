import { PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorResponse, ApiResponse } from 'apisauce';
import { isArrayLike } from 'lodash';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { forkJoin, from, Observable, Observer } from 'rxjs';
import { debounceTime, delay, filter, mergeMap } from 'rxjs/operators';

import { MOCK } from '../../../configDebug';
import LibraryConfig from '../../../configLib';
import { EnrollChanelEnum, HttpStatusCodeEnum } from '../../enums';
import { CALLS_TO_MEMOIZE, UPDATE_BALANCES_ORDER_DELAY } from '../../libSettings';
import { LegalDeclarationsDynamicFilePayload, LookupInterface } from '../../models/crm/types';
import {
  CountryData,
  EmploymentStatus,
  ENROLL_METHOD,
  FinancialQuestionnaireTypeData,
  IndividualExtendedInfoData,
  PurposeOfTradingData,
  SourceOfFundsOwnData,
  TokenDecodedData,
} from '../../models/enroll';
import {
  AppDocumentUploadCategories,
  ENROLL_AUTH_REQUEST_TYPE,
  EnrollDeleteRequestBody,
  EnrollGetRequestBody,
  EnrollIndividualAddressRequestData,
  EnrollIndividualRequestData,
  EnrollIndividualTaxDetailData,
  EnrollIndividualTaxDetailRequestData,
  EnrollPostRequestBody,
  EnrollPutRequestBody,
  FileUploadRegisterData,
  GenderTypeEnum,
} from '../../models/enroll-requests';
import { SOROrderStatus } from '../../models/gateway/types';
import { CRMService } from '../../services/CRM.service';
import GlobalService from '../../services/Global.service';
import CountriesCache, { updateCountriesCache } from '../../store-util/CountriesCache';
import { decodeJwt, isOneOf } from '../../util/DataHelpers';
import { getManualTokenFailed } from '../auth/index';
import {
  accountsFailed,
  applicationStartup,
  enrollAuthCompleted,
  enrollSuccess,
  EnrollSuccessPayload,
  getBalances,
  getCountryListSuccess,
  getIndividualExtendedInfo,
  getManualTokenSuccess,
  individualExtendedInfoFailed,
  individualExtendedInfoSuccess,
  servicesReady,
  sorOrderEvent,
} from '../common-actions';
import { processErrorInResponse, processResponse } from '../helpers';
import {
  getToken,
  hasAllDocumentsUploaded,
  hasLegalDeclarations,
  hasReachedUserEnrollStepPendingAcceptance,
  hasValidToken,
  isUserEnrollStepForVerification,
  isUserEnrollStepPendingAcceptance,
  isUserEnrollStepPendingDocuments,
  isUserVerifiedStatus,
} from '../selectors';
import { hasBPUpdated } from '../trading/helpers';
import { getBuyingPower } from '../trading/index';
import { RootState } from '..';

import { ENROLL_EXTENDED_INFO_TRIGGER_CALLS, INDIVIDUAL_EXTENDED_INFO_CALL_NAME } from './constants';
import { getBinaryDocumentRequestBody, getBinaryPOIDocumentRequestBody, getIndividualPOIRequestBody } from './helpers';
import {
  accountDetailsCompleted,
  accountDetailsFailed,
  accountInfoCompleted,
  accountInfoFailed,
  accountsCompleted,
  enroll,
  enrollAddCallToQueue,
  enrollAddressesBatch,
  enrollAddressesBatchFail,
  enrollAddressesBatchSuccess,
  enrollFail,
  enrollPersonalDetailsFailed,
  enrollPersonalDetailsSuccess,
  enrollPersonalDetailsWithTaxDetails,
  getCountryList,
  getCountryListFailed,
  getEnrollFinancialQuestionnaire,
  getEnrollFinancialQuestionnaireFail,
  getEnrollFinancialQuestionnaireSuccess,
  getLegalDeclarations,
  getLegalDeclarationsCompleted,
  getLegalDeclarationsDynamicFile,
  getLegalDeclarationsDynamicFileFail,
  getLegalDeclarationsDynamicFileSuccess,
  getLegalDeclarationsFailed,
  getNextAccount,
  getTradingPlatforms,
  getTradingPlatformsFailed,
  getTradingPlatformsSuccess,
} from './index';


const extendedInfoEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    getIndividualExtendedInfo.type,
    servicesReady.type,
    enrollSuccess.type,
    enrollAddressesBatchSuccess.type,
    enrollPersonalDetailsSuccess.type,
  ),
  filter(action => (action.type === enrollSuccess.type
    ? ENROLL_EXTENDED_INFO_TRIGGER_CALLS.includes(ENROLL_METHOD[action.payload?.callName] as any)
        && state$.value.crm.enroll.callsInQueue === 0
    : hasValidToken(state$.value))),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = INDIVIDUAL_EXTENDED_INFO_CALL_NAME; // used in `store/helpers.ts@processResponse` for handling 401

    GlobalService
      .crmService
      .enrollGet(ENROLL_METHOD[ENROLL_METHOD.getIndividualExtendedInfo])
      .then((response: ApiResponse<any>) => {
        processResponse(callName, {}, response, observer, individualExtendedInfoSuccess, individualExtendedInfoFailed);
      })
      .catch(error => processErrorInResponse(callName, error, action, observer, individualExtendedInfoFailed));
  })),
);

const getAccountsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(individualExtendedInfoSuccess.type),
  filter(action => !state$.value.auth.enrollPending),
  filter(action => isUserVerifiedStatus(action.payload) || MOCK.ENABLED),
  filter(action => hasValidToken(state$.value)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'crm/accounts';
    GlobalService
      .crmService
      .getAccounts()
      .then((response: ApiResponse<any>) => {
        processResponse(callName, {}, response, observer, accountsCompleted, accountsFailed);
      })
      .catch(error => processErrorInResponse(callName, error, action, observer, accountsFailed));
  })),
);

const accountsReadyEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(accountsCompleted.type, getNextAccount.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    let callName = 'crm/accountInfo';
    let accountReferenceId: string;

    if (action.type === getNextAccount.type) accountReferenceId = action.payload.account;
    if (action.type === accountsCompleted.type) accountReferenceId = action.payload[state$.value.crm.selectedAccount];

    GlobalService
      .crmService
      .getAccountInfo(accountReferenceId!)
      .then((response: any) => {
        processResponse(callName, { accountReferenceId }, response, observer, accountInfoCompleted, accountInfoFailed);
      })
      .catch((error: any) => processErrorInResponse(callName, error, action, observer, accountInfoFailed));
  })),
);

const accountInfoReadyEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(accountInfoCompleted.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    if (!(action.payload instanceof Array) || action.payload.length === 0) {
      observer.next(accountDetailsFailed({ error: new Error('Invalid account info data in action'), errorData: action }));
      return;
    }

    // TODO: Any better way to get the account_id
    const { account_id } = action.payload[0];
    let callName = 'crm/accountDetails';

    GlobalService
      .crmService
      .getAccountDetails(account_id)
      .then((response: any) => {
        processResponse(callName, { account_id }, response, observer, accountDetailsCompleted, accountDetailsFailed);
      })
      .catch((error: any) => processErrorInResponse(callName, error, action, observer, accountDetailsFailed));
  })),
);

const getBalancesEpic = (action$: Observable<any>) => action$.pipe(
  ofType(sorOrderEvent.type),
  debounceTime(UPDATE_BALANCES_ORDER_DELAY),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const { status } = action.payload;

    if (hasBPUpdated(status)) {
      observer.next(getBuyingPower());
    } else {
      observer.next(getBalances());
    }
  })),
);

const getCountryListEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(applicationStartup.type, getCountryList.type),
  filter(() => !CountriesCache.get()?.length || !CALLS_TO_MEMOIZE.getCountryList),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'crm/getCountryList';

    GlobalService
      .crmService
      .getCountryList()
      .then(response => {
        if (processResponse(callName, null, response, observer, getCountryListSuccess, getCountryListFailed)) {
          updateCountriesCache(response.data);
        } else {
          updateCountriesCache([]);
        }
      })
      .catch(error => {
        updateCountriesCache([]);
        processErrorInResponse(callName, error, action, observer, getCountryListFailed);
      });
  })),
);


const enrollEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(enroll.type),
  filter(() => hasValidToken(state$.value)),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const {
      callMethod, callMethodName: callName, params, requestBody, isQueued, id, callNo,
    } = action.payload;

    if (state$.value.crm.enroll.callsInQueue > 1 && !isQueued) {
      observer.next(enrollAddCallToQueue(action.payload));
      return;
    }

    // TODO: Fix this case with getIndividualFilesCompleted producing an enroll actions with payload {}
    //       Dispatching `enrollSuccess` is needed for the purpose of isQueued - if any action is queued
    if (!callName) {
      observer.next(enrollSuccess({ callName: 'unknown', originalAction: action } as any));
      console.warn('[crm/epics] enrollEpic - unknown action', action);
      return;
    }

    const isGet = callName.indexOf('get') === 0;
    const isPost = callName.indexOf('post') === 0;
    const isPut = callName.indexOf('put') === 0;
    const isDelete = callName.indexOf('delete') === 0;

    let promiseCreator: () => Promise<any>;
    let customData: any;

    if (isGet) {
      promiseCreator = () => GlobalService.crmService.enrollGet(callName, params);
    } else if (isPost) {
      const { accessToken } = state$.value.auth;
      const documentOwnBinary = callMethod === ENROLL_METHOD.postDocumentOwnBinary;
      const individualIdentityDetailOwnWithFile = callMethod === ENROLL_METHOD.postIndividualIdentityDetailOwnWithFile;
      const { files, filesInfo: filesInfoRaw } = requestBody || {};
      const filesInfo: FileUploadRegisterData[] = isArrayLike(filesInfoRaw) ? filesInfoRaw : [ filesInfoRaw ];
      const firstFileInfo: FileUploadRegisterData = isArrayLike(filesInfoRaw) ? filesInfoRaw[0] : filesInfoRaw;

      if (documentOwnBinary) {
        const fileNames = filesInfo.map(({ fileName }) => fileName);
        const payload = getBinaryDocumentRequestBody(files, firstFileInfo, state$.value);
        promiseCreator = () => GlobalService.crmService.registerBinaryDocument(payload, accessToken!, fileNames);
      } else if (individualIdentityDetailOwnWithFile) {
        const fileNames = filesInfo.map(({ fileName }) => fileName);
        const payload = getBinaryPOIDocumentRequestBody(files, firstFileInfo, state$.value);
        promiseCreator = () => GlobalService.crmService.registerBinaryDocument(payload, accessToken!, fileNames);
      } else {
        promiseCreator = () => GlobalService.crmService.enrollPost(callName, requestBody);
      }
    } else if (isPut) {
      promiseCreator = () => GlobalService.crmService.enrollPut(callName, requestBody, params);
    } else if (isDelete) {
      promiseCreator = () => GlobalService.crmService.enrollDelete(callName, id);
    } else {
      console.debug(`[crm/epics] enrollEpic - '${callName}' is not recognised`, action.payload);
      throw new Error(`[crm/epics] enrollEpic - '${callName}' is not recognised`);
    }
    if (callNo) customData = { ...customData, callNo };

    promiseCreator()
      .then((response: any) => {
        processResponse(callName, {}, response, observer, enrollSuccess, enrollFail, false, customData);
      })
      .catch((error: any) => processErrorInResponse(callName, error, action, observer, enrollFail));
  })),
);

const enrollCallQueueEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    enrollSuccess.type,
    enrollFail.type,
    enrollPersonalDetailsSuccess.type,
    enrollPersonalDetailsFailed.type,
  ),
  filter(() => state$.value.crm.enrollQueued.length > 0),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    observer.next(enroll({ ...state$.value.crm.enrollQueued[0], isQueued: true }));
  })),
);


// TODO: Clean the usage of external variables when possible (was done for quick impl of adding poi id parameter to id document registration)
const POI_FILENAMES = [
  AppDocumentUploadCategories.ID_BACK.fileName,
  AppDocumentUploadCategories.ID_FRONT.fileName,
];

/**
 * This epic implements switching the user individual status to statuses 101, 102 and 103
 * It is organised in 2 cases:
 *    1. transition state from 100 to 101 - pending documents
 *    2. This one has 3 substeps
 *      2.1 transition state from 101 to 102 - pending acceptance
 *      2.2 transition state from 102 to 103 - for verification
 *      2.3 initiate risk assessment /inherited from old onboarding/
 */
const enrollTransitionsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    individualExtendedInfoSuccess.type,
    enrollSuccess.type,
    enrollAuthCompleted.type,
  ),
  filter(action => {
    const {
      isTaxDetailsCall, isAcceptLegalDeclarationsCall, isRegisterDocumentCall, isPhoneVerifiedCall,
    } = checkEnrollActionType(action);
    return isTaxDetailsCall || isAcceptLegalDeclarationsCall || isRegisterDocumentCall || isPhoneVerifiedCall;
  }),
  delay(1000), // this aids in waiting for extended info to be refreshed
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const {
      isTaxDetailsCall,
      isAcceptLegalDeclarationsCall,
      isRegisterDocumentCall,
      isPhoneVerifiedCall,
    } = checkEnrollActionType(action);

    let calls = [] as {
      callMethod: ENROLL_METHOD
      params?: any
    }[];
    if (isTaxDetailsCall && !isUserEnrollStepPendingDocuments(state$.value.crm.individualExtendedInfo || null)
    && state$.value.crm.enroll.callMethod !== ENROLL_METHOD.putIndividualTransitionToPendingDocuments) {
      // 1. one call - transition from 100 to 101 - pending documents
      calls = [ { callMethod: ENROLL_METHOD.putIndividualTransitionToPendingDocuments } ];
    } else {
      if ((isRegisterDocumentCall || isPhoneVerifiedCall)
      && hasAllDocumentsUploaded(state$.value)
      && !isUserEnrollStepForVerification(state$.value.crm.individualExtendedInfo)
      ) {
      /*
        2. 3 calls:
          2.1 transition from 100 to 101 - pending acceptance
          2.2 transition from 101 to 102 - for verification
       */
        if (!hasReachedUserEnrollStepPendingAcceptance(state$.value.crm.individualExtendedInfo)) {
          calls.push({ callMethod: ENROLL_METHOD.putIndividualTransitionToPendingAcceptance });
        }
      }
      if (isAcceptLegalDeclarationsCall && hasLegalDeclarations(state$.value)) {
        calls.push({ callMethod: ENROLL_METHOD.putIndividualTransitionToForVerification });
      }
    }

    if (calls.length > 0) {
      if (state$.value.crm.individualId === null) {
        console.error('[crm/index] enrollTransitionsEpic - individual id not found');
        return;
      }
      calls
        .forEach(call => {
          const { callMethod, params } = call;
          observer.next(enroll({
            callMethod,
            callMethodName: ENROLL_METHOD[callMethod],
            params: params ?? [ (state$.value.crm.individualId!).toString() ],
          }));
        });
    }
  })),
);


const enrollPersonalDetailsEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(enrollPersonalDetailsWithTaxDetails.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'crm/enrollmentOwnPersonalPutOrPost';

    const { isPersonalDetailsUpdate, individualData } = action.payload;

    const requestBody = { ...individualData } as EnrollIndividualRequestData;

    // Temporary fix, must be deleted when fully integrated new login

    const individual = state$.value.crm.individualExtendedInfo;

    if (isPersonalDetailsUpdate && individual) {
      requestBody.id = individual.id;
      requestBody.user_correlation_id = individual.user_correlation_id;
    } else {
      const { tradingPlatformId } = state$.value.crm;
      requestBody.trading_platform_id = tradingPlatformId;
    }

    const params = (individual && individual.id) ? [ individual.id ] : undefined;
    const crmService = GlobalService.crmService;
    const request = isPersonalDetailsUpdate
      ? crmService.enrollPut(ENROLL_METHOD[ENROLL_METHOD.putEnrollmentOwnPersonal], individualData, params)
      : crmService.enrollPost(ENROLL_METHOD[ENROLL_METHOD.postEnrollmentOwnPersonal], requestBody);

    request
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          enrollPersonalDetailsSuccess,
          enrollPersonalDetailsFailed,
          false,
          { individualData, isPersonalDetailsUpdate },
        );
      }))
      .catch(error => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        enrollPersonalDetailsFailed,
        { isPersonalDetailsUpdate },
      ));
  })),
);

const enrollTaxDetailsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(enrollPersonalDetailsWithTaxDetails.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const { taxDetails, isAddAnotherTaxCountryClicked } = action.payload;
    const individual = state$.value.crm.individualExtendedInfo;
    const countries = CountriesCache.get();

    if (countries) {
      const countryIds = countries.map((country: CountryData) => country.id);

      const registerAction = createTaxDetailsAction(countryIds, individual, taxDetails);
      const updateAction = createTaxDetailsUpdatAction(countryIds, individual, taxDetails);
      const deleteAction = createTaxDetailsDeleteAction(individual, isAddAnotherTaxCountryClicked);

      if (registerAction && registerAction.length > 0) {
        registerAction.map(registeredValue => observer.next(registeredValue));
      }

      if (updateAction && updateAction.length > 0) {
        updateAction.map(updatedValue => observer.next(updatedValue));
      }

      if (deleteAction) {
        observer.next(deleteAction);
      }
    }
  })),
);

const createTaxDetailsAction = (
  countryIds: string[],
  individual: IndividualExtendedInfoData | null,
  taxDetails: EnrollIndividualTaxDetailRequestData,
) => {
  let models: EnrollIndividualTaxDetailData[];
  let callNo: number[] = [];

  models = taxDetails.filter((detail: EnrollIndividualTaxDetailData, detailIndex: number) => {
    const individualTaxDetail = individual ? individual.tax_details[detailIndex] : null;
    const hasPostRequest = (
      !individualTaxDetail
      && detail.tax_number
      && detail.country_id
      && countryIds.includes(detail.country_id)
    );

    if (hasPostRequest) {
      callNo.push(detailIndex + 1);
    }

    return hasPostRequest;
  });

  const callMethod = ENROLL_METHOD.postIndividualTaxDetailOwnBatch;
  if (models.length > 0) {
    return models.map((el, index) => enroll({
      callMethod,
      callMethodName: ENROLL_METHOD[callMethod],
      requestBody: [ el ],
      callNo: callNo[index],
    } as EnrollPostRequestBody));
  }

  return null;
};

const createTaxDetailsUpdatAction = (
  countryIds: string[],
  individual: IndividualExtendedInfoData | null,
  taxDetails: EnrollIndividualTaxDetailRequestData,
) => {
  let callNo: number[] = [];
  const filteredTaxDetail = taxDetails.filter((detail: EnrollIndividualTaxDetailData, detailIndex: number) => {
    const individualTaxDetail = individual ? individual.tax_details[detailIndex] : null;
    let hasUpdateRequest = (
      countryIds.includes(detail.country_id)
      && individualTaxDetail
      && (detail.id === individualTaxDetail.id)
      && (
        (detail.country_id !== individualTaxDetail.country_id)
        || (detail.tax_number !== individualTaxDetail.tax_number))
    );
    if (hasUpdateRequest) {
      callNo.push(detailIndex + 1);
    }
    return hasUpdateRequest;
  });

  if (filteredTaxDetail.length > 0) {
    const callMethod = ENROLL_METHOD.putIndividualTaxDetailOwn;
    return filteredTaxDetail.map((el, index) => enroll({
      callMethod,
      callMethodName: ENROLL_METHOD[callMethod],
      requestBody: el,
      callNo: callNo[index],
    } as EnrollPutRequestBody));
  }

  return null;
};

const createTaxDetailsDeleteAction = (
  individual: IndividualExtendedInfoData | null,
  isAddAnotherTaxCountryClicked: boolean,
) => (
  individual && individual.tax_details.length > 1 && isAddAnotherTaxCountryClicked === false
    ? enroll({
      callMethod: ENROLL_METHOD.deleteTaxDetail,
      callMethodName: ENROLL_METHOD[ENROLL_METHOD.deleteTaxDetail],
      id: individual.tax_details[1].id,
      callNo: 2,
    } as EnrollDeleteRequestBody)
    : null
);

const enrollAddressesBatchEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(enrollAddressesBatch.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const {
      callMethodName: callName,
      addresses,
    } = action.payload;
    const individual = state$.value.crm.individualExtendedInfo;
    let requestStack = [];

    if (addresses.length > 0) {
      requestStack = addresses.map((address: EnrollIndividualAddressRequestData) => {
        if (address.id) {
          const params = (individual && individual.id) ? [ individual.id ] : undefined;

          return GlobalService.crmService.enrollPut(callName, address, params);
        }

        return GlobalService.crmService.enrollPost(callName, address);
      });
    }

    Promise.all(requestStack)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          enrollAddressesBatchSuccess,
          enrollAddressesBatchFail,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, enrollAddressesBatchFail));
  })),
);

const getEnrollFinancialQuestionnaireEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(getEnrollFinancialQuestionnaire.type),
  filter(() => (
    !state$.value.crm.enroll.financialQuestionnaireSections?.length
    || !CALLS_TO_MEMOIZE.financialQuestionnaire
  )),
  mergeMap(action => {
    const callName = 'crm/getEnrollFinancialQuestionnaire';

    /**
     * NB!
     * The order of the items in this `requestStack` array is important
     * Must be left as is.
     * */
    const requestStack: Observable<any>[] = [
      from(GlobalService.crmService.getFinancialQuestionnaireTypes()),
      from(GlobalService.crmService.getFinancialQuestionnaireList()),
      from(GlobalService.crmService.getPurposeOfTrading()),
      from(GlobalService.crmService.getEmploymentStatusList()),
      from(GlobalService.crmService.getSourceOfFundOwn(true)),
    ];

    return forkJoin(requestStack).pipe(
      mergeMap((responses: ApiResponse<any>[]) => {
        const errors: ApiErrorResponse<any>[] = [];

        responses.forEach((response: ApiResponse<any>) => {
          if (response.status !== HttpStatusCodeEnum.Ok) {
            errors.push(response as ApiErrorResponse<any>);
          }
        });

        let types: LookupInterface = {};
        let answers: FinancialQuestionnaireTypeData[] = [];
        let purposeOfTrading: PurposeOfTradingData[] = [];
        let employmentStatusList: EmploymentStatus[] = [];
        let sourceOfFundList: SourceOfFundsOwnData[] = [];

        if (responses[0].data) {
          types = responses[0].data;
        }

        if (responses[1].data && Array.isArray(responses[1].data)) {
          answers = responses[1].data;
        }

        if (responses[2].data && Array.isArray(responses[2].data)) {
          purposeOfTrading = responses[2].data;
        }

        if (responses[3].data && Array.isArray(responses[3].data)) {
          employmentStatusList = responses[3].data;
        }

        if (responses[4].data && Array.isArray(responses[4].data)) {
          sourceOfFundList = responses[4].data;
        }

        return new Observable((observer: Observer<any>) => {
          if (errors.length > 0) {
            processErrorInResponse(callName, errors, action, observer, getEnrollFinancialQuestionnaireFail);
          }

          processResponse(
            callName,
            {},
            responses,
            observer,
            getEnrollFinancialQuestionnaireSuccess,
            getEnrollFinancialQuestionnaireFail,
            false,
            {
              types,
              answers,
              purposeOfTrading,
              sourceOfFundList,
              employmentStatusList,
            },
          );
        });
      }),
    );
  }),
);

const getTradingPlatformsEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getTradingPlatforms.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'getTradingPlatforms';
    GlobalService
      .crmService
      .getTradingPlatforms()
      .then((response: any) => {
        processResponse(
          callName,
          null,
          response,
          observer,
          getTradingPlatformsSuccess,
          getTradingPlatformsFailed,
          false,
          response,
        );
      })
      .catch((error: any) => processErrorInResponse(callName, error, action, observer, getManualTokenFailed));
  })),
);

const createIndividualEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getManualTokenSuccess.type, getTradingPlatformsSuccess.type),
  filter(() => hasValidToken(null, state$.value.auth.enroll.accessToken)),
  filter(() => !state$.value.crm.individualExtendedInfo),
  filter(() => state$.value.crm.tradingPlatformId !== undefined),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    const {
      enroll: {
        email,
        firstName,
        lastName,
        country,
      },
    } = state$.value.auth;
    const { tradingPlatformId } = state$.value.crm;
    const token = getToken(state$.value, false)!;

    const tokenDecoded = decodeJwt(token) as TokenDecodedData;
    const { email: tokenEmail } = tokenDecoded;

    if (tokenEmail !== email && !!email) {
      console.error(`[crm/epics] createIndividualEpic - emails do not match from-token: ${tokenEmail} cached: ${email}`);
      return;
    }
    const temporaryCrmService = new CRMService(state$.value.auth.enroll.accessToken!);

    // create individual
    const callMethodIndividual = ENROLL_METHOD[ENROLL_METHOD.postEnrollmentOwnPersonal];
    const requestBodyIndividual = {
      first_name: firstName,
      last_name: lastName,
      birth_place_id: country,
      nationalities: [ country ],
      gender: GenderTypeEnum.unspecified,
      enroll_channel: LibraryConfig.isWeb ? EnrollChanelEnum.Web : EnrollChanelEnum.Mobile,
      trading_platform_id: tradingPlatformId,
    };

    // create residential address
    const callMethodAddress = ENROLL_METHOD[ENROLL_METHOD.postIndividualAddressOwn];
    const requestBodyAddress = {
      address_type: 'R',
      country_id: country,
    };

    temporaryCrmService
      .enrollPost(callMethodIndividual, requestBodyIndividual)
      .then(response => {
        // TODO: error handling if respone body has error
        temporaryCrmService.enrollPost(callMethodAddress, requestBodyAddress);
      })
      .catch(error => {
        // TODO: error handling
      });
  })),
);

// eslint-disable-next-line max-len
const getOnboardingLegalAndQuestionsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(servicesReady.type, individualExtendedInfoSuccess.type),
  filter(action => (
    action.type === servicesReady.type
    || isUserEnrollStepPendingAcceptance(state$.value.crm.individualExtendedInfo)
    || isUserEnrollStepForVerification(state$.value.crm.individualExtendedInfo)
  )),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callMethod = ENROLL_METHOD.getIndividualOwnLegalDeclarations;

    observer.next(
      enroll({
        callMethod,
        callMethodName: ENROLL_METHOD[callMethod],
      } as EnrollGetRequestBody),
    );

    observer.next(getEnrollFinancialQuestionnaire());
  })),
);

export function checkEnrollActionType(action: PayloadAction<EnrollSuccessPayload>) {
  const { callName } = action.payload;
  const isTaxDetailsCall = (
    callName === ENROLL_METHOD[ENROLL_METHOD.postIndividualTaxDetailOwnBatch]
    || callName === ENROLL_METHOD[ENROLL_METHOD.putIndividualTaxDetailOwn]
    || callName === ENROLL_METHOD[ENROLL_METHOD.deleteTaxDetail]
  );
  // TODO: Edit in ART-3128
  const isRegisterDocumentCall = isOneOf(callName, [
    ENROLL_METHOD[ENROLL_METHOD.postIndividualDocumentOwn],
    ENROLL_METHOD[ENROLL_METHOD.postDocumentOwnBinary],
  ]);
  const isAcceptLegalDeclarationsCall = callName === ENROLL_METHOD[ENROLL_METHOD.putIndividualOwnLegalDeclarations];
  const isPhoneVerifiedCall = ENROLL_AUTH_REQUEST_TYPE[callName.split('/')[1] as any] === ENROLL_AUTH_REQUEST_TYPE.VERIFY_PHONE_CODE as any;

  return {
    isTaxDetailsCall,
    isRegisterDocumentCall,
    isAcceptLegalDeclarationsCall,
    isPhoneVerifiedCall,
  };
}

const getLegalDeclarationsEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(getLegalDeclarations.type),
  mergeMap(
    (action: PayloadAction<{ active: boolean, isVerified: boolean }>) => new Observable((observer: Observer<any>) => {
      const callName = 'crm/getLegalDeclarationsList';
      const { active, isVerified } = action.payload;
      GlobalService
        .crmService
        .getLegalDeclarationsList(active, isVerified)
        .then((response => {
          processResponse(
            callName,
            {},
            response,
            observer,
            getLegalDeclarationsCompleted,
            getLegalDeclarationsFailed,
            false,
            { isVerified },
          );
        }))
        .catch(error => processErrorInResponse(callName, error, action, observer, getLegalDeclarationsFailed));
    }),
  ),
);

const getLegalDeclarationsDynamicFileNameEpic = (
  action$: Observable<any>,
) => action$.pipe(
  ofType(getLegalDeclarationsDynamicFile.type),
  mergeMap((action: PayloadAction<LegalDeclarationsDynamicFilePayload>) => new Observable((observer: Observer<any>) => {
    const callName = 'crm/getLegalDeclarationsDynamicFile';
    const { fileName, templateName } = action.payload;

    GlobalService
      .crmService
      .getLegalDeclarationsDynamicFile(templateName)
      .then((response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getLegalDeclarationsDynamicFileSuccess,
          getLegalDeclarationsDynamicFileFail,
          false,
          { fileName },
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getLegalDeclarationsDynamicFileFail));
  })),
);

export default combineEpics(
  extendedInfoEpic,
  getAccountsEpic,
  accountsReadyEpic,
  accountInfoReadyEpic,
  getBalancesEpic,
  getCountryListEpic,
  enrollEpic,
  enrollCallQueueEpic,
  enrollTransitionsEpic,
  enrollPersonalDetailsEpic,
  enrollTaxDetailsEpic,
  enrollAddressesBatchEpic,
  getEnrollFinancialQuestionnaireEpic,
  getTradingPlatformsEpic,
  createIndividualEpic,
  getOnboardingLegalAndQuestionsEpic,
  getLegalDeclarationsEpic,
  getLegalDeclarationsDynamicFileNameEpic,
);
