import { TopGainerAndLoserData } from '../../models/fundamental/types';

import { TopMoversInitialItems } from './types';

export const EMPTY_ITEMS = [] as TopGainerAndLoserData[];

export const INITIAL_STATE = {
  /**
    * `initialData` is used for detecting that symbols list is received from FDS (Fundamental Service).
    */
  initialData: [] as TopMoversInitialItems[],
  isInitialDataReady: false,
  /**
    * `data` is used for populating items when prices are received (Instrument Snapshot, Trade)
    */
  data: EMPTY_ITEMS,
  isReady: false,
  isTimeout: false,
};
