import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { CURRENT_YEAR } from '../../../lib/constants/date-time.constants';
import { SourceOfFundEnum } from '../../../lib/enums/source-of-fund.enum';
import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { setSourceOfFundsIsEdit } from '../../../lib/store/crm';
import { getSourceOfFundsData } from '../../../lib/store/selectors';
import { getAddressValidity, getNameOfCustomerValidity, validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import CheckboxControl from '../../CheckboxControl/CheckboxControl';
import InputControl from '../../InputControl/InputControl';
import { GeneralQuestionsCustomAnswers } from '../EnrollGeneralQuestions/EnrollGeneralQuesions.types';
import {
  KEY_SOURCE_OF_FUND_ADDRESS,
  KEY_SOURCE_OF_FUND_FROM,
  KEY_SOURCE_OF_FUND_HAS_MAIN,
  KEY_SOURCE_OF_FUND_IDENTITY_NAME,
  KEY_SOURCE_OF_FUND_TO,
} from '../EnrollGeneralQuestions/EnrollGeneralQuestions.constants';
import YearDatePicker from '../YearDatePicker/YearDatePicker';

import styles from '../EnrollSourceOfFunds/EnrollSourceOfFunds.module.scss';
import ownStyles from './EnrollSelfEmployed.module.scss';

type Props = {
  sourceOfFunds?: any;
  formData: GeneralQuestionsCustomAnswers;
  isClickedBtnContinue: boolean;
  handleChange: (formKey: string, value: string | number) => void;
} & ReduxProps

const EnrollSelfEmployed = ({
  formData,
  handleChange,
  sourceOfFunds,
  isEdit,
  isClickedBtnContinue,
}: Props) => {
  const dispatch = useDispatch();
  const [customData, setCustomData] = useState(formData);

  if (sourceOfFunds && !isEdit) {
    customData[KEY_SOURCE_OF_FUND_FROM] = sourceOfFunds.from;
    customData[KEY_SOURCE_OF_FUND_TO] = sourceOfFunds.to;
    customData[KEY_SOURCE_OF_FUND_HAS_MAIN] = sourceOfFunds.fund_type;
    customData[KEY_SOURCE_OF_FUND_ADDRESS] = sourceOfFunds.identity_name.slice(sourceOfFunds.identity_name.indexOf('|') + 1);
    customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] = sourceOfFunds.identity_name.slice(0, sourceOfFunds.identity_name.indexOf('|'));
    dispatch(setSourceOfFundsIsEdit(true));
    handleChange('', '');
  }

  const datePickerFromYear = customData[KEY_SOURCE_OF_FUND_FROM] as string;
  const datePickerToYear = customData[KEY_SOURCE_OF_FUND_TO] as string || CURRENT_YEAR.toString();

  return (
    <div className={styles.wrapper}>
      <YearDatePicker
        formErrors={isClickedBtnContinue
          ? validateControl(
            datePickerFromYear as string,
            [],
            lang.commonSourceOfFundsDateFromLabel(),
            true,
          ).errors
          : []}
        datePickerToYear={datePickerToYear}
        datePickerFromYear={datePickerFromYear}
        handleChange={handleChange}
        setCustomData={setCustomData}
      />
      <Row className={ownStyles.checkboxRow}>
        <Col md={12}>
          <CheckboxControl
            id="mainCustomerCheck"
            ariaLabel="main customer check"
            checked={!!customData[KEY_SOURCE_OF_FUND_HAS_MAIN]}
            label={lang.commonSourceOfFundsHaveMainCustomer()}
            onChange={(value: boolean) => {
              handleChange(KEY_SOURCE_OF_FUND_HAS_MAIN, value ? '1' : '');
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_HAS_MAIN]: value ? 1 : 0,
              }));
            }}
          />
        </Col>
      </Row>
      {!!customData[KEY_SOURCE_OF_FUND_HAS_MAIN] && (
      <Row>
        <Col md={12} className={ownStyles.secondaryMargin}>
          <InputControl
            name=""
            placeholder=""
            label={lang.commonSourceOfFundsNameOfCustomer()}
            value={customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string}
            onBlur={(value: string | number) => {
              handleChange(KEY_SOURCE_OF_FUND_IDENTITY_NAME, value);
            }}
            onValueChange={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_IDENTITY_NAME]: value,
              }));
            }}
            errors={isClickedBtnContinue
              ? getNameOfCustomerValidity(customData[KEY_SOURCE_OF_FUND_IDENTITY_NAME] as string).errors
              : []}
          />
        </Col>
        <Col md={12}>
          <InputControl
            name=""
            placeholder=""
            label={lang.commonSourceOfFundsAddressOfCustomerLabel()}
            value={customData[KEY_SOURCE_OF_FUND_ADDRESS] as string}
            onBlur={(value: string | number) => {
              handleChange(KEY_SOURCE_OF_FUND_ADDRESS, value);
            }}
            onValueChange={(value: string | number) => {
              setCustomData((currentState: GeneralQuestionsCustomAnswers) => ({
                ...currentState,
                [KEY_SOURCE_OF_FUND_ADDRESS]: value,
              }));
            }}
            errors={isClickedBtnContinue
              ? getAddressValidity(customData[KEY_SOURCE_OF_FUND_ADDRESS] as string).errors
              : []}
          />
        </Col>
      </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sourceOfFunds: getSourceOfFundsData(state, SourceOfFundEnum.SelfEmploymentAlaric) as any,
  isEdit: state.crm.enroll.isEditSourceOfFunds,
});

const connector = connect(mapStateToProps);
type ReduxProps = ReturnType<typeof mapStateToProps>;

export default connector(EnrollSelfEmployed);
