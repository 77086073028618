import PasswordValidator from 'password-validator';

const StartWithLetter = new PasswordValidator();
StartWithLetter.has(/^[a-zA-Z]/);

const StartWithLetterOrNumber = new PasswordValidator();
StartWithLetterOrNumber.has(/^[a-zA-Z0-9]/);

const LetterSpaceChars = new PasswordValidator();
LetterSpaceChars.has(/^([a-zA-Z\s]*)$/);

const LetteHypenSpaceInvCommaChars = new PasswordValidator();
LetteHypenSpaceInvCommaChars.has(/^[a-zA-Z'\s-]*$/);

const LetteHypenSpaceChars = new PasswordValidator();
LetteHypenSpaceChars.has(/^[a-zA-Z.\s-]*$/);

const LetterNumberHypenSpaceChars = new PasswordValidator();
LetterNumberHypenSpaceChars.has(/^[a-zA-Z0-9-\s]*$/);

const AddressSpecialSymbols = new PasswordValidator();
AddressSpecialSymbols.has(/^([a-zA-Z0-9\s\-.,#&/]*)$/);

const CompanyNameSpecialSymbols = new PasswordValidator();
CompanyNameSpecialSymbols.has(/^([a-zA-Z0-9\s\-.#&]*)$/);

const TaxNumberSpecialSymbols = new PasswordValidator();
TaxNumberSpecialSymbols.has(/^([a-zA-Z0-9\s\-./]*)$/);

const EmailSpecialSymbols = new PasswordValidator();
EmailSpecialSymbols.has(/^([a-zA-Z0-9\-._+@]*)$/);

const CityNameValidator = new PasswordValidator();
CityNameValidator.has(/^([a-zA-Z\s-]*)$/);

const AlphaNumericValidator = new PasswordValidator();
AlphaNumericValidator.has(/^([a-zA-Z0-9]*)$/);

const NotOnlyNumbers = new PasswordValidator();
NotOnlyNumbers.has(/.*?[^0-9].*/);

export const validateAlphaNumericString = (value: string): boolean | string[] => AlphaNumericValidator
  .validate(value, { list: false });
export const validateStartWithLetter = (value: string): boolean | string[] => StartWithLetter
  .validate(value, { list: false });
export const validateStartWithLetterOrNumber = (value: string): boolean | string[] => StartWithLetterOrNumber
  .validate(value, { list: false });
export const validateSpecialSymbolsName = (value: string): boolean | string[] => LetteHypenSpaceInvCommaChars
  .validate(value, { list: false });
export const validateSpecialSymbolsCity = (value: string): boolean | string[] => CityNameValidator
  .validate(value, { list: false });
export const validateSpecialSymbolsPostCode = (value: string): boolean | string[] => LetterNumberHypenSpaceChars
  .validate(value, { list: false });
export const validateSpecialSymbolsAddress = (value: string): boolean | string[] => AddressSpecialSymbols
  .validate(value, { list: false });
export const validateSpecialSymbolsIDDocument = (value: string): boolean | string[] => LetterNumberHypenSpaceChars
  .validate(value, { list: false });
export const validateSpecialSymbolsEmploymentName = (value: string): boolean | string[] => CompanyNameSpecialSymbols
  .validate(value, { list: false });
export const validateSpecialSymbolsTaxNumber = (value: string): boolean | string[] => TaxNumberSpecialSymbols
  .validate(value, { list: false });
export const validateSpecialSymbolsEmail = (value: string): boolean | string[] => EmailSpecialSymbols
  .validate(value, { list: false });
export const validateInvestmentsAndSavingsSpecify = (value: string): boolean | string[] => LetterSpaceChars
  .validate(value, { list: false });
export const validateAddressNumbersOnly = (value: string): boolean | string[] => NotOnlyNumbers
  .validate(value, { list: false });
