import lang from '../../lib/language';

export const options = [
  {
    name: lang.totalBySymbol(),
    value: 'totalBySymbol',
    description: lang.totalBySymbolDescription(),
  },
  {
    name: lang.totalByDate(),
    value: 'totalByDate',
    description: lang.totalByDateDescription(),
  },
  {
    name: lang.summaryByDate(),
    value: 'summaryByDate',
    description: lang.summaryByDateDescription(),
  },
  {
    name: lang.detailedReport(),
    value: 'detailedReport',
    description: lang.detailedReportDescription(),
  },
  {
    name: lang.feesByDestination(),
    value: 'feesByDestination',
    description: lang.feesByDestinationDescription(),
  },
  {
    name: lang.adjustments(),
    value: 'adjustments',
    description: lang.adjustmentsDescription(),
  },
];
