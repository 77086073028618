import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResponse } from 'apisauce';
import moment from 'moment';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import config from '../../../config';
import configLib from '../../../configLib';
import { CurrencyEnum, HttpStatusCodeEnum, PaymentMethodEnum, PaymentProcessEnum, PaymentProviderEnum } from '../../enums';
import { FundingWireTransferPostRequest, GetPaymentsPayload, Payment, PaymentsFilter, WithdrawalRequest } from '../../models/crm/types';
import { CreateCardTransferRequest } from '../../models/payment/types';
import GlobalService from '../../services/Global.service';
import { isAllStartupPaymentCallsDone, isCallStatusReady } from '../../util/error-handling/StatusByCallHelpers';
import { ErrorPayloadFull, getAccountsAvailableCash, getAccountsAvailableCashSuccess, getOwnerTradingAccountsFailed, refreshPayments, refreshPaymentsDone } from '../common-actions';
import { accountInfoCompleted } from '../crm';
import { processErrorInResponse, processResponse } from '../helpers';
import { isUserVerifiedStatus } from '../selectors';
import { RootState } from '..';

import { ALL_TRANSACTIONS_FILTER, DEPOSIT_WIRE_TRANSFER_FILTER, TRANSACTIONS_FILTER } from './constants';
import { getBankStatementRequestBody } from './helpers';
import {
  confirmInitialPaymentsLoaded,
  createBankTransfer,
  createBankTransferFailed,
  createBankTransferSuccess,
  createCardTransfer,
  createCardTransferFail,
  createCardTransferSuccess,
  getCardTransferStatus,
  getCardTransferStatusFail,
  getCardTransferStatusSuccess,
  getIndividualLinkedAccounts,
  getIndividualLinkedAccountsFailed,
  getIndividualLinkedAccountsSuccess,
  getOwnerTradingAccounts,
  getOwnerTradingAccountsSuccess,
  getPayments,
  getPaymentsAllCompleted,
  getPaymentsAllFailed,
  getPaymentsCompleted,
  getPaymentsFailed,
  getWireTransferBankAccount,
  getWireTransferBankAccountFailed,
  getWireTransferBankAccountSuccess,
  registerLinkedAccount,
  registerLinkedAccountFailed,
  registerLinkedAccountSuccess,
  registerWithdrawalRequest,
  registerWithdrawalRequestFail,
  registerWithdrawalRequestSuccess,
  uploadDocumentForLinkedAccount,
  uploadDocumentForLinkedAccountFailed,
} from './index';
import { PaymentCall, RegisterLinkedAccountPayload, UploadBankStatementPayload } from './types';


/**
 * Startup loading of basic payments data - transactions, bank accounts, cash
 */
const initialLoadEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(accountInfoCompleted.type),
  filter(() => !(state$.value.payment.payments?.data?.length > 0)),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    observer.next(getOwnerTradingAccounts()); // 1
    observer.next(getWireTransferBankAccount(DEPOSIT_WIRE_TRANSFER_FILTER)); // vendor bank account
    observer.next(getIndividualLinkedAccounts()); // linked accounts / aka bank accounts
    observer.next(getAccountsAvailableCash()); // cash
    observer.next(getPayments(ALL_TRANSACTIONS_FILTER)); // all transactions + pending deposits sum (see `processAllTransactionsEpic`)
  })),
);

const checkInitialLoadEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(
    getOwnerTradingAccountsSuccess.type,
    getWireTransferBankAccountSuccess.type,
    getIndividualLinkedAccountsSuccess.type,
    getAccountsAvailableCashSuccess.type,
    getPaymentsAllCompleted.type,
  ),
  filter(() => !state$.value.payment.isInitialPaymentsDataLoaded),
  filter(() => !state$.value.app.connected),
  filter(() => (
    isAllStartupPaymentCallsDone(state$.value.payment.statusByCall)
    || !isUserVerifiedStatus(state$.value.crm.individualExtendedInfo)
  )),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    observer.next(confirmInitialPaymentsLoaded());
  })),
);


/**
 * Reloading of basic payments data - transactions, bank accounts, cash
 */
const refreshEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(refreshPayments.type),
  filter(action => !!action.payload || !!state$.value.payment.callsForRefresh),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callsForRefresh = action.payload ?? state$.value.payment.callsForRefresh;
    if (callsForRefresh[PaymentCall.getIndividualLinkedAccounts]) {
      observer.next(getIndividualLinkedAccounts());
    }
    if (callsForRefresh[PaymentCall.getAvailableCash]) {
      observer.next(getAccountsAvailableCash());
    }
    if (callsForRefresh[PaymentCall.getPayments]) {
      observer.next(getPayments(TRANSACTIONS_FILTER));
    }
    if (callsForRefresh[PaymentCall.getPaymentsAll]) {
      observer.next(getPayments(ALL_TRANSACTIONS_FILTER));
    }

    observer.next(refreshPaymentsDone());
  })),
);


/**
 * Get bank accounts
 */
const getLinkedAccountsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getIndividualLinkedAccounts.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'payment/linkedAccountsByIndividual';

    const { individualId } = state$.value.crm;

    if (!individualId) {
      processErrorInResponse(
        callName,
        new Error('[Payment] Individual id not found'),
        action,
        observer,
        getIndividualLinkedAccountsFailed,
      );

      return;
    }

    const id = typeof individualId !== 'number' ? parseInt(individualId, 10) : individualId;

    GlobalService
      .crmService
      .getLinkedAccountsByIndividualId(id)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getIndividualLinkedAccountsSuccess,
          getIndividualLinkedAccountsFailed,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getIndividualLinkedAccountsFailed));
  })),
);

/**
 * Upload bank statement
 */
const uploadDocumentForLinkedAccountEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(uploadDocumentForLinkedAccount.type),
  mergeMap((action: PayloadAction<UploadBankStatementPayload>) => new Observable((observer: Observer<any>) => {
    const callName = 'payment/uploadDocumentLinkedAccount';
    const { individualId } = state$.value.crm;

    if (!individualId) {
      processErrorInResponse(
        callName,
        new Error('[Payment] Individual id not found'),
        action,
        observer,
        uploadDocumentForLinkedAccountFailed,
      );

      return;
    }

    const {
      file,
      iban,
      bankName,
      swiftCode,
      currencyCode,
      bankCountryId,
    } = action.payload;

    GlobalService
      .fileService
      .upload(file.base64Data, file.fileName)
      .then((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          registerLinkedAccount,
          uploadDocumentForLinkedAccountFailed,
          false,
          {
            uploadDocumentName: response.data as string,
            iban,
            bankName,
            swiftCode,
            currencyCode,
            bankCountryId,
          },
        );
      })
      .catch((error: any) => processErrorInResponse(
        callName,
        error,
        action,
        observer,
        uploadDocumentForLinkedAccountFailed,
      ));
  })),
);


/**
 * Get transactions
 */
const getPaymentsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getPayments.type),
  filter(action => (
    state$.value.payment.payments.callsInQueue.length === 0
    || action.payload.isQueued
  )),
  mergeMap((
    action: PayloadAction<GetPaymentsPayload>,
  ) => new Observable((observer: Observer<any>) => {
    if (state$.value.crm.individualId === null) {
      console.error('[payment/epics] getPaymentsEpic - individual id not found');
      return;
    }
    const { paymentProcess, pageNumber, dateOffset, pageSize, paymentStatus } = action.payload;
    const { individualId } = state$.value.crm;
    const { payments, paymentsAll } = state$.value.payment;
    const isAllTransactionsCall = !!payments.isAllTransactionsCall;
    const transactions = isAllTransactionsCall ? paymentsAll : payments;

    // TODO: const dateFrom = getDateTime()
    const dateFrom = moment().subtract(dateOffset, 'd').format('YYYY-MM-DD');
    const dateTo = moment().format('YYYY-MM-DD');
    const paymentsFilter: PaymentsFilter = {
      account_owner_id: individualId,
      is_account_owner_company: false,
      date_from: dateFrom,
      date_to: dateTo,
      payment_process: paymentProcess,
      page_number: pageNumber,
      page_size: pageSize ?? transactions.pageSize,
      order_column: transactions.orderColumn,
      order_ascending: transactions.orderAscending,
      payment_status: paymentStatus,
    };

    const callName = 'payment/getPayments';
    let successAction: any;
    let failAction: any;
    if (isAllTransactionsCall) {
      successAction = getPaymentsAllCompleted;
      failAction = getPaymentsAllFailed;
    } else {
      successAction = getPaymentsCompleted;
      failAction = getPaymentsFailed;
    }

    GlobalService
      .crmService
      .getPayments(paymentsFilter)
      .then((rawResponse: ApiResponse<Payment[]>) => {
        /**
         * CRM returns status code 404(Not found) if there aren't records by filter criteria
         */
        let response = { ...rawResponse };
        if (response.status === HttpStatusCodeEnum.NotFound) {
          response.status = HttpStatusCodeEnum.Ok;
          response.data = [];
        }

        processResponse(
          callName,
          {},
          response,
          observer,
          successAction,
          failAction,
          false,
          null,
          null,
          null,
          null,
          { 404: [] },
        );
      })
      .catch(error => {
        processErrorInResponse(callName, error, action, observer, failAction);
      });
  })),
);

const getPaymentsQueuedEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getPaymentsCompleted.type, getPaymentsFailed.type),
  filter(() => state$.value.payment.payments.callsInQueue.length > 0),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    observer.next(getPayments(state$.value.payment.payments.callsInQueue[0]));
  })),
);


/**
 * Get data for initiating a bank deposit (vendor bank)
 */
const getGetWireTransferBankAccountEpic = (action$: Observable<any>) => action$.pipe(
  ofType(getWireTransferBankAccount.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'payment/getWireTransferBankAccount';
    const bankAccountFilter = action.payload;

    if (!bankAccountFilter) {
      processErrorInResponse(
        callName,
        new Error('[payment/epics] No bank account filter found!'),
        action,
        observer,
        getWireTransferBankAccountFailed,
      );

      return;
    }

    GlobalService
      .crmService
      .getWireTransferBankAccount(bankAccountFilter)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getWireTransferBankAccountSuccess,
          getWireTransferBankAccountFailed,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getWireTransferBankAccountFailed));
  })),
);

/**
 * Register bank account
 */
const registerLinkedAccountEpic = (
  action$: Observable<any>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  ofType(registerLinkedAccount.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'payment/registerLinkedAccount';
    const { individualId } = state$.value.crm;

    if (!individualId) {
      processErrorInResponse(callName, new Error('[Payment] Individual id not found'), action, observer, registerLinkedAccountFailed);
      return;
    }

    const requestBody = getBankStatementRequestBody(individualId, action.payload);
    const { accessToken } = state$.value.auth;

    GlobalService
      .crmService
      .registerLinkedAccount(requestBody, accessToken!)
      .then((response: any) => {
        processResponse(callName, {}, response, observer, registerLinkedAccountSuccess, registerLinkedAccountFailed);
      })
      .catch((error: any) => {
        processErrorInResponse(callName, error, action, observer, registerLinkedAccountFailed);
      });
  })),
);

/**
 * Initiate bank deposit
 */
const createBankTransferEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(createBankTransfer.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'payment/createBankTransfer';
    const requestFundingData = action.payload as FundingWireTransferPostRequest;

    if (!requestFundingData) {
      processErrorInResponse(
        callName,
        new Error('[Payment] No data found for the Bank Transfer funding request!'),
        action,
        observer,
        createBankTransferFailed,
      );

      return;
    }

    const paymentState = state$.value.payment;

    if (!requestFundingData.to_internal_account_id) {
      requestFundingData.to_internal_account_id = (
        paymentState.tradingAccounts.find(acc => !acc.is_cash_account)?.id
      );
    }

    if (!requestFundingData.is_initial_deposit) {
      requestFundingData.is_initial_deposit = state$.value.crm.individualExtendedInfo?.initial_deposit_id === 0;
    }

    if (paymentState.vendorBankAccount.bic) {
      requestFundingData.recipient_bank_details = paymentState.vendorBankAccount;
    }

    const hasLoadedLinkedAccounts = (
      isCallStatusReady(PaymentCall.getIndividualLinkedAccounts, paymentState.statusByCall)
    );
    if (!requestFundingData?.sender_bank_details?.bic && hasLoadedLinkedAccounts) {
      requestFundingData.sender_bank_details = paymentState.linkedAccounts[0]?.account_info;
    }

    requestFundingData.payment_process = PaymentProcessEnum.Deposit;
    requestFundingData.payment_method = PaymentMethodEnum.BankTransfer;
    requestFundingData.payment_provider = PaymentProviderEnum.UnknownBank;
    requestFundingData.currency_code = requestFundingData.currency_code ? requestFundingData.currency_code : 'usd';

    GlobalService
      .crmService
      .createBankTransfer(requestFundingData)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          createBankTransferSuccess,
          createBankTransferFailed,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, createBankTransferFailed));
  })),
);

const getOwnerTradingAccountsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getOwnerTradingAccounts.type),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    const callName = 'payment/getOwnerTradingAccounts';
    if (!state$.value.crm.individualExtendedInfo?.id) {
      const errorStr = '[getOwnerTradingAccountsEpic] Individual id not available';
      observer.next(getOwnerTradingAccountsFailed({
        callName: 'getOwnerTradingAccounts',
        errorData: {},
        errorStr,
      } as ErrorPayloadFull));
      return;
    }

    GlobalService
      .crmService
      .getOwnerTradingAccounts(state$.value.crm.individualExtendedInfo?.id)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getOwnerTradingAccountsSuccess,
          getOwnerTradingAccountsFailed,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getOwnerTradingAccountsFailed));
  })),
);

/**
 * Initiate withdrawal
 */
const registerWithdrawalRequestEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(registerWithdrawalRequest.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'payment/registerWithdrawalRequest';
    const data = action.payload as WithdrawalRequest;

    data.to_internal_account_id = state$.value.payment.tradingAccounts.find(acc => !acc.is_cash_account)?.id;
    data.currency_code = 'USD';
    data.payment_process = PaymentProcessEnum.Withdrawal;
    data.payment_method = PaymentMethodEnum.BankTransfer;
    data.payment_provider = PaymentProviderEnum.UnknownBank;

    GlobalService
      .crmService
      .registerWithdrawalRequest(data)
      .then(((response: any) => {
        processResponse(
          callName,
          data,
          response,
          observer,
          registerWithdrawalRequestSuccess,
          registerWithdrawalRequestFail,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, registerWithdrawalRequestFail));
  })),
);

/**
 * Initiate card deposit
 */
const createCardTransferEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(createCardTransfer.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const data: CreateCardTransferRequest = {
      payment_provider: PaymentProviderEnum.DSK,
      amount: action.payload.amount,
      currency_code: CurrencyEnum.USD,
      customer_email: state$.value.crm.individualExtendedInfo?.email || '',
      to_internal_account_id: state$.value.payment.tradingAccounts.find(acc => !acc.is_cash_account)?.id || 0,
      return_url: config.auth.redirectUrlFunds,
      is_initial_deposit: action.payload.isInitialDeposit,
    };

    GlobalService
      .crmService
      .postCreateCardTransfer(data)
      .then((response: any) => {
        processResponse(
          'createCardTransfer',
          data,
          response,
          observer,
          createCardTransferSuccess,
          createCardTransferFail,
        );
      });
  })),
);

/**
 * Check card deposit status
 */
const getCardTransferStatusEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getCardTransferStatus.type),
  mergeMap(() => new Observable((observer: Observer<any>) => {
    const paymentState = state$.value.payment;
    const { webLastCardTransfer, lastCardTransfer } = paymentState;

    const lastPaymentRef = (
      configLib.isWeb
        ? webLastCardTransfer.payment_reference
        : lastCardTransfer.createTransferData?.payment_reference
    );

    if (lastPaymentRef) {
      GlobalService
        .paymentService
        .getCardTransferStatus(lastPaymentRef)
        .then((response: any) => {
          if (response.ok) {
            observer.next(getCardTransferStatusSuccess(response.data));
          } else {
            observer.next(getCardTransferStatusFail(response.data));
          }
        });
    } else {
      console.warn(
        `[payment/epics] Skipping 'getCardTransferStatus' call to Payment API - no payment reference~ '${lastPaymentRef}'`,
        { lastPaymentRef, webLastCardTransfer, lastCardTransfer },
      );
    }
  })),
);

export default combineEpics(
  initialLoadEpic,
  checkInitialLoadEpic,
  refreshEpic,
  getPaymentsEpic,
  getPaymentsQueuedEpic,
  getLinkedAccountsEpic,
  registerLinkedAccountEpic,
  uploadDocumentForLinkedAccountEpic,
  getGetWireTransferBankAccountEpic,
  createBankTransferEpic,
  getOwnerTradingAccountsEpic,
  registerWithdrawalRequestEpic,
  createCardTransferEpic,
  getCardTransferStatusEpic,
);
