import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { StockDescriptor } from '../../../lib/models/stock-descriptor';
import { RootState } from '../../../lib/store/index';

import { UI_ROUTES } from '../../../util/routes';
import { ClickableDiv, CompanyLogo } from '../../index';

import styles from './StockRow.module.scss';

type Props = {
    stock: StockDescriptor;
    logo?: string;
    isMob?: boolean;
    closeModal?: () => void;
}

const StockRow = ({ stock, logo, isMob, closeModal }: Props) => {
  const query = useSelector<RootState, string>((state: RootState) => state.fundamental.stockQuery);

  const history = useHistory();
  const handleRowClick = (): void => {
    const { symbol } = stock;
    history.replace(`${UI_ROUTES.symbol}/${symbol}`);
    closeModal && closeModal();
  };

  const sliceResultString = (result: string): JSX.Element => (
    result.toUpperCase().startsWith(query.toUpperCase()) ? (
      <>
        <span className={styles.searchPosition}>{result.slice(0, query.length)}</span>
        <span>{result.slice(query.length)}</span>
      </>
    ) : (
      <span>{result}</span>
    )
  );

  return (
    <ClickableDiv
      className={classNames(styles.wrapperRow, { [styles.hoverColor]: !isMob })}
      onClick={handleRowClick}
    >
      <>
        <div className={styles.contentWrapper}>
          <div className={styles.wrapperLogoAndName}>
            {logo && <CompanyLogo logo={logo} className={styles.logo} symbol={stock.symbol} />}
            <p className={styles.symbolTitle}>{sliceResultString(stock.symbol)}</p>
          </div>
          <div className={styles.wrapperName}>
            <p className={styles.labelMeta}>{sliceResultString(stock.companyName)}</p>
          </div>
        </div>
      </>
    </ClickableDiv>
  );
};

export default StockRow;
