/* eslint-disable import/named */
/* eslint-disable new-cap */
/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Company } from '../../lib/models/company';
import { GWMarketDataQueryBucket, OHLCVDataRow, OHLCVStateData } from '../../lib/models/gateway/types';
import { getCompany } from '../../lib/store/fundamental/index';
import { resetMarketDataQuery } from '../../lib/store/gateway/index';
import { RootState } from '../../lib/store/index';
import { getMarketDataQuery } from '../../lib/store/market-data/index';
import { getCompany as companyInfo } from '../../lib/store/selectors';
import { RegExStrings } from '../../lib/util/DataHelpers';
import { getDateTime } from '../../lib/util/DateTimeHelpers';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import {
  ChartingLibraryWidgetOptions, LanguageCode, ResolutionString, TimeFrameType, widget,
} from '../../charting_library/charting_library';
import { Preloader } from '../../components/index';

import Datafeed from './datafeed';

import styles from './ChartWrapper.module.scss';

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp(RegExStrings.languageCode);
  const results = regex.exec(location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(RegExStrings.plusChar, ' ')) as LanguageCode;
};

export const TVChartContainer = () => {
  const { name: symbolName }: { name: string } = useParams();
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const company = useSelector<RootState, Company | undefined>(
    (state: RootState) => companyInfo(symbolName, state),
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { symbol, minuteData, hourData, dayData } = useSelector<RootState, {
  symbol: string, minuteData: OHLCVStateData; hourData: OHLCVStateData, dayData: OHLCVStateData; }>(
    (state: RootState) => state.gateway.ohlcvData,
  );

  const [chartMinuteData, setChartMinuteData] = useState<OHLCVDataRow[]>([]);
  const [chartHourData, setChartHourData] = useState<OHLCVDataRow[]>([]);
  const [chartDayData, setChartDayData] = useState<OHLCVDataRow[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    // temporary solution for subscription after refresh
    manageSubscriptions('chart-details', symbolName);

    if (!minuteData.data.length || symbol !== symbolName) {
      dispatch(getMarketDataQuery({
        symbols: [ symbolName ],
        bucket: GWMarketDataQueryBucket.OneMinute,
        startTime: getDateTime(true, [ 'minus' ], [ [5, 'days' ] ]),
        endTime: getDateTime(),
      }));
    }
    if (!hourData.data.length || symbol !== symbolName) {
      dispatch(getMarketDataQuery({
        symbols: [ symbolName ],
        bucket: GWMarketDataQueryBucket.OneHour,
        /**
           * We add one hour since the backend returns an error when we subtract the precise number of days
           * when we approach the server's supported date boundary.
          * */
        startTime: getDateTime(true, [ 'minus', 'plus'], [ [90, 'days' ], [ 60, 'minutes' ] ]),
        endTime: getDateTime(),
      }));
    }
    if (!dayData.data.length || symbol !== symbolName) {
      dispatch(getMarketDataQuery({
        symbols: [ symbolName ],
        bucket: GWMarketDataQueryBucket.OneDay,
        /**
         * We add one day since the backend returns an error when we subtract the precise number of years
         * when we approach the server's supported date boundary.
        * */
        startTime: getDateTime(true, [ 'minus', 'plus' ], [ [5, 'years' ], [1, 'day'] ]),
        endTime: getDateTime(),
      }));
    }
    return () => {
      dispatch(resetMarketDataQuery());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (minuteData.data.length) {
      setChartMinuteData([...minuteData.data]);
    }
  }, [minuteData]);

  useEffect(() => {
    if (dayData.data.length) {
      setChartDayData([...dayData.data]);
    }
  }, [dayData]);

  useEffect(() => {
    if (hourData.data.length) {
      setChartHourData([...hourData.data]);
    }
  }, [hourData]);

  useEffect(() => {
    if (!company) {
      dispatch(getCompany(symbolName));
    }
  }, [company, dispatch, symbolName]);

  useEffect(() => {
    setShowLoader(!isDataLoaded);
  }, [isDataLoaded]);

  useEffect(() => {
    if (company && chartMinuteData.length && chartDayData.length && chartHourData && !isDataLoaded) {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: symbolName,
        datafeed: Datafeed(chartMinuteData, chartHourData, chartDayData, dispatch, company),
        interval: '5' as ResolutionString,
        container: chartContainerRef.current,
        custom_translate_function: (key) => {
          if (key === 'Script name') {
            return 'Choose indicator';
          }
          return null;
        },
        debug: true,
        locale: getLanguageFromURL() || 'en',
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        fullscreen: true,
        autosize: true,
        library_path: '/charting_library/',
        timezone: 'America/New_York',
        overrides: {
          priceScaleSelectionStrategyName: 'right',
        },
        loading_screen: {
          backgroundColor: '#0C2333',
        },
        disabled_features: ['header_fullscreen_button', 'header_symbol_search', 'header_compare'],
        studies_access: {
          type: 'black',
          tools: [{ name: '52 Week High/Low' }, { name: 'Script name' }],
        },
        time_frames: [
          { text: '5Y', resolution: '1M' as ResolutionString, description: '5 Years' },
          { text: '1Y', resolution: '1W' as ResolutionString, description: '1 Year' },
          { text: '3M', resolution: '1D' as ResolutionString, description: '3 Months' },
          { text: '1M', resolution: '60' as ResolutionString, description: '1 Month' },
          { text: '1W', resolution: '15' as ResolutionString, description: '1 Week' },
          { text: '1D', resolution: '5' as ResolutionString, description: '1 Day' },
        ],
        custom_css_url: '../styles/chartStyle.css',
        theme: 'dark',
      };
      const tvWidget = new widget(widgetOptions);
      setIsDataLoaded(true);

      tvWidget.onChartReady(() => {
        const volumeStudyId = tvWidget.activeChart().getAllStudies().find(x => x.name === 'Volume')?.id;
        if (volumeStudyId) {
          const volume = tvWidget.activeChart().getStudyById(volumeStudyId);
          volume.applyOverrides({ 'volume.color.0': 'rgba(255, 10, 239, 0.1)', 'volume.color.1': 'rgba(59, 176, 255, 0.1)' });
        }

        tvWidget.chart().getSeries().setChartStyleProperties(1, {
          upColor: '#ffffff',
          downColor: '#ffffff',
        });
        tvWidget.applyOverrides({
          'mainSeriesProperties.candleStyle.downColor': '#FF0AEF',
          'mainSeriesProperties.candleStyle.borderDownColor': '#FF0AEF',
          'mainSeriesProperties.candleStyle.wickDownColor': '#FF0AEF',
          'mainSeriesProperties.candleStyle.upColor': '#3BB0FF',
          'mainSeriesProperties.candleStyle.borderUpColor': '#3BB0FF',
          'mainSeriesProperties.candleStyle.wickUpColor': '#3BB0FF',
          'paneProperties.backgroundGradientStartColor': '#0E1A60',
          'paneProperties.backgroundGradientEndColor': '#181C37',
          'crossHairProperties.color': '#FDFFFF',
          'paneProperties.vertGridProperties.color': 'rgba(59, 176, 255, 0.1)',
          'paneProperties.horzGridProperties.color': 'rgba(59, 176, 255, 0.1)',
          'paneProperties.separatorColor': '#1D3960',
          'scalesProperties.lineColor': '#1D3960',
          'scalesProperties.textColor': '#FDFFFF',
          'scalesProperties.crosshairLabelBgColorDark': '#0C2333',
          'mainSeriesProperties.areaStyle.color1': 'rgba(59, 176, 255, 0.4)',
          'mainSeriesProperties.areaStyle.color2': 'rgba(59, 176, 255, 0)',
          'mainSeriesProperties.areaStyle.linecolor': '#3BB0FF',
        });

        const chart = tvWidget.chart();
        chart.setTimeFrame({
          val: { type: 'period-back' as TimeFrameType.PeriodBack, value: '1D' }, res: '5' as ResolutionString,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbolName, company, chartMinuteData, chartHourData, chartDayData]);

  return (
    <>
      <div
        ref={chartContainerRef}
        className={'TVChartContainer'}
      />
      {showLoader && <div className={styles.loadingIndicator}><div className={styles.spinner} /></div>}
    </>
  );
};
