import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { formatCurrency } from '../../../../lib/util/DataHelpers';

import exclamationMark from '../../../../assets/img/icon-exclamation-mark-warning.svg';
import { Button, HeaderLogo } from '../../../../components';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './DepositSlipModal.module.scss';

type Props = {} & ReduxProps;

const DepositSlipModal = (props: Props) => {
  const { bankTransferInfo } = props;
  const history = useHistory();

  const handleClickBtn = () => {
    history.push({
      pathname: UI_ROUTES.funds,
      state: { target: lang.mobileDepositSlipDialogTitle() },
    });
  };

  const [ iban ] = lang.mobileAddBankAccInputIBAN().split('/');
  const bank = bankTransferInfo.recipient;

  return (
    <div className={styles.wrapperContent}>
      <h3 id="depositSlipTitle" className={styles.title}>{lang.mobileDepositSlipTitle()}</h3>
      <span id="depositSlipSubTitle" className={styles.description}>
        {lang.mobileDepositSlipSubtitle()}
      </span>
      <div className={styles.wrapperDepositInfo}>
        <div className={styles.rowInfo}>
          <span id="depositSlipAmountLabel">{lang.mobileDepositAmountLabel()}</span>
          <span id="depositSlipAmountValue">{formatCurrency(bankTransferInfo.amount)}</span>
        </div>
        <div className={styles.rowInfo}>
          <span id="depositSlipIBANLabel">{iban}</span>
          <span id="depositSlipIBANValue">{bank && (bank.iban ?? bank.account)}</span>
        </div>
        <div className={styles.rowInfo}>
          <span id="depositSlipBenBankLabel">{lang.mobileDepositSlipInputBank()}</span>
          <span id="depositSlipBenBankValue">{bank && bank.bank_name}</span>
        </div>
        <div className={styles.rowInfo}>
          <span id="depositSlipBenNameLabel">{lang.mobileDepositSlipInputName()}</span>
          <span id="depositSlipBenNameValue">{bank && bank.name}</span>
        </div>
        <div className={styles.rowInfo}>
          <span id="depositSlipBicLabel">{lang.mobileAddBankAccInputBicSwift()}</span>
          <span id="depositSlipBicValue">{bank && bank.bic}</span>
        </div>
      </div>
      <div className={styles.wrapperPaymentCode}>
        <div className={styles.rowInfo}>
          <span id="depositSlipPaymentCodeLabel">{lang.mobileDepositSlipLabelPaymentCode()}</span>
          <span id="depositSlipPaymentCodeValue">{bankTransferInfo.payment_reference}</span>
        </div>
        <div className={styles.rowInfo}>
          <img id="depositSlipAlertIcon" src={exclamationMark} alt="exclamationMark icon" />
          <span id="depositSlipAlert">{lang.mobileDepositSlipAlert()}</span>
        </div>
      </div>
      <Button
        id="depositSlipDone"
        fullWidth
        className={styles.btnDone}
        onClick={handleClickBtn}
      >
        {lang.commonDoneButton()}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  bankTransferInfo: state.payment.bankTransferInfo,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DepositSlipModal);
