import React, { useEffect, useState } from 'react';

import lang from '../../../lib/language';
import { calculateCashPortfolioProportion } from '../../../lib/util/my-account-helpers';

import PieChart from '../PieChart/PieChart';

import CashDetails from './CashDetails/CashDetails';

import styles from './CashPortfolioChart.module.scss';

type Props = {
  isSmallScreen?: boolean;
}

const CashPortfolioChart = ({ isSmallScreen }: Props) => {
  const [chartData, setChartData ] = useState<number[]>([]);
  const [cashValue, setCashValue] = useState<number>(0);
  const [portfolioValue, setPortfolioValue] = useState<number>(0);

  useEffect(() => {
    if (cashValue || portfolioValue) {
      setChartData([cashValue > 0 ? cashValue : 0, portfolioValue > 0 ? portfolioValue : 0]);
    }
  }, [cashValue, portfolioValue]);

  return (
    <div className={styles.wrapperChart}>
      <svg width="100%" height="250" viewBox="0 0 400 250">
        <PieChart
          chartData={chartData}
          chartValue={calculateCashPortfolioProportion(cashValue, portfolioValue)}
          labelText={`${lang.commonAccountBreakdownCashToInvest()} / ${lang.portfolioValue()}`}
        />
      </svg>
      <CashDetails
        isSmallScreen={isSmallScreen}
        setCashValue={val => setCashValue(val)}
        setPortfolioValue={val => setPortfolioValue(val)}
      />
    </div>
  );
};

export default CashPortfolioChart;
