import React, { useEffect } from 'react';

import lang from '../../lib/language';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import { useIsMobile } from '../../hooks';
import Navigation from '../Navigation/Navigation';

import FavouritesWatchList from './WatchListTable/FavouritesWatchList';
import PopularWatchList from './WatchListTable/PopularWatchList';

import styles from './WatchList.module.scss';

const WatchList = () => {
  const isMobile = useIsMobile();
  const tabs = [
    { text: lang.mobileWatchlistStocks(),
      component: <PopularWatchList />,
    },
    { text: lang.commonFavourites(),
      component: <FavouritesWatchList />,
    },
  ];

  useEffect(() => {
    manageSubscriptions('watchlist', null, false, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.navigationWrapperMobile}>
          <Navigation tabs={tabs} />
        </div>
      ) : (
        <>
          <h6 className={styles.headerText}>{lang.commonWatchlist()}</h6>
          <Navigation tabs={tabs} />
        </>
      )}
    </div>
  );
};

export default WatchList;
