import React, { useState } from 'react';

import lang from '../../lib/language';

import iconExpandDown from '../../assets/img/icon-expand-more-down.svg';
import { ClickableDiv, ClickableImage } from '..';

import styles from './SelectorMobile.module.scss';

type Props = {
    value: string;
    options: any[];
    setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const SelectorMobile = (props: Props) => {
  const { value, options, setSelected } = props;
  const [isOrderTypeMenuOpened, setIsOrderTypeMenuOpened] = useState(false);

  const handleChange = (option: any) => {
    setSelected(option);
    setIsOrderTypeMenuOpened(!isOrderTypeMenuOpened);
  };

  return (
    <div className={styles.wrapperOrderTypeDropDown}>
      <div className={styles.orderTypeTitle}>{lang.commonTradingOrderType()}</div>
      <div className={styles.orderTypeContainer}>
        <div className={styles.orderTypeText}>
          {value}
        </div>
        <ClickableImage
          alt=""
          src={iconExpandDown}
          className={styles.orderTypeSelectIcon}
          onClick={() => { setIsOrderTypeMenuOpened(!isOrderTypeMenuOpened); }}
        />
      </div>
      {isOrderTypeMenuOpened && (
        <div className={styles.wrapperOrderTypeMenu}>
          <ClickableDiv
            className={styles.orderTypeMenuBackground}
            onClick={() => { setIsOrderTypeMenuOpened(!isOrderTypeMenuOpened); }}
          />
            {/* TODO: Separate this part into a component */}
          <div className={styles.orderTypes}>
            {options.map((option) => (
              <button
                key={option.value}
                type="button"
                disabled={option.isDisabled}
                className={option.isDisabled ? styles.orderTypeDisabled : styles.orderType}
                onClick={() => { handleChange(option.value); }}
              >
                {option.icon && (
                  <div className={styles.orderTypeIcon}>
                    <img src={option.icon} alt="" />
                  </div>
                )}
                <div className={styles.orderTypeText}>
                  <div className={styles.orderTypeTitle}>
                    {option.text}
                  </div>
                  <div className={styles.orderTypeDescription}>
                    {option.subText}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectorMobile;
