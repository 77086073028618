import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { OrderSideEnum } from '../../../lib/enums';
import { OrderTypeEnum } from '../../../lib/enums/order-type.enum';
import { TimeInForceEnum } from '../../../lib/enums/time-in-force.enum';
import lang from '../../../lib/language';
import { OpenPosition, PositionType } from '../../../lib/store/reporting/types';
import { TextHelpers } from '../../../lib/util/TextHelpers';

import { CheckboxControl } from '../../index';
import DropDown from '../DropDown/DropDown';
import { ExpirationDropDownOptions } from '../order.constants';

import styles from '../styles.module.scss';

type Props = {
    isMarketHours: boolean;
    time: string;
    extendedHours: boolean;
    positionsBySymbol?: OpenPosition;
    orderType: string;
    isHalted?: boolean;
    orderSide?: string;
    isUserRestricted?: boolean;
    setTimeInForce: (value: string) => void;
    setIncludeExtendedHours: (value: boolean) => void;
}

const ExpiryDate = (props: Props) => {
  const {
    isMarketHours,
    time,
    extendedHours,
    positionsBySymbol,
    orderType,
    orderSide,
    isHalted,
    isUserRestricted,
    setTimeInForce,
    setIncludeExtendedHours,
  } = props;

  const [isDisbled, setIsDisabled] = useState<boolean>(false);
  const handleExpiryDateChange = useCallback((option) => {
    setTimeInForce(option.value);
  }, [setTimeInForce]);

  useEffect(() => {
    if (!isHalted && ((orderSide && orderSide === OrderSideEnum.Buy && orderType === OrderTypeEnum.Limit)
    || (!orderSide && orderType !== OrderTypeEnum.Stop))) {
      setIsDisabled(false);
    } else if (
      orderType === OrderTypeEnum.Stop
      || !positionsBySymbol
      || positionsBySymbol.positionType === PositionType.Sell
      || isHalted
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isHalted, orderSide, orderType, positionsBySymbol]);

  return (
    <>
      <div className={styles.expiryDate}>
        <div className={classNames(styles.expiryDateText, { [styles.disabled]: isUserRestricted })}>
          {lang.headerMyAccountPendingOrderExpiryDate()}
        </div>
        <DropDown
          options={ExpirationDropDownOptions}
          value={time === TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay)
            ? ExpirationDropDownOptions[0].value
            : ExpirationDropDownOptions[1].value}
          handleChange={handleExpiryDateChange}
          isDisabled={isMarketHours}
          isHalted={isHalted}
        />
      </div>
      <div className={classNames({
        [styles.disbaledCheckBoxControl]: isUserRestricted || isDisbled,
        [styles.wrapperCheckboxControl]: !isDisbled,
      })}
      >
        <CheckboxControl
          checked={extendedHours}
          disabled={isDisbled}
          label={lang.webTradingIncludeExtendedHoursTrading()}
          onChange={(value: boolean) => setIncludeExtendedHours(value)}
        />
      </div>
    </>
  );
};

export default ExpiryDate;
