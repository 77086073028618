import { PriceChangeDirection } from '../store-util/TradePriceCache';

import { formatNumber } from './DataHelpers';
import { INVALID_VALUE, NullableNumber } from './types';


/**
 * Calculates `change` and `percent` as follows:
 *  change -> valueOne - valueTwo
 *  percent -> (change / valueTwo) * 100
 * Handles null and 0 cases
 */
export function calculateChange(valueOne: NullableNumber, valueTwo: NullableNumber) {
  let change;
  if (valueOne && valueTwo == null) change = 0;
  else change = valueOne && valueTwo ? valueOne - valueTwo : null;

  let percent;
  if (change != null) percent = change === 0 ? 0 : Math.abs(100 * (change! / valueTwo!));
  // if (change != null) percent = change === 0 ? 0 : valueTwo!;

  return { change, percent };
}

export function calculateDirectionFromChange(change: NullableNumber): PriceChangeDirection {
  const roundedChange = formatNumber(change);
  const roundedChangeAsNumber = Number(roundedChange);

  if (roundedChange === INVALID_VALUE || roundedChangeAsNumber === 0) return '';
  if (roundedChangeAsNumber > 0) return '+';
  if (roundedChangeAsNumber < 0) return '-';

  console.error(`[DataChangeHelpers] calculateDirectionFromChange - unknown change value ${roundedChange}`);

  return '';
}
