import { GWMarketDataSummaryBucket } from '../../models/gateway/types';
import { SubscribeGroup } from '../../store/market-data/types';
import { OrderTicketComponent } from '../../util/types';

export const composeSmallChartCallCacheParam = (component: OrderTicketComponent, extraData?: string) => {
  const { parseOrderTicketComponentToSubscribeGroup } = require('../../util/TradingHelpers');
  const group: SubscribeGroup | undefined | null = parseOrderTicketComponentToSubscribeGroup(component);
  if (group === 'watchlist') {
    return `smallchart_${group}`;
  }

  return `smallchart_${component}${extraData ? `/${extraData}` : ''}`;
};

export const composeBigChartCallCacheParam = (symbol: string, bucket?: GWMarketDataSummaryBucket) => `big_${symbol}_${bucket ?? GWMarketDataSummaryBucket.OneDay}`;
