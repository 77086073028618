import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModalBody } from 'reactstrap';

import lang from '../../lib/language';

import { UI_ROUTES } from '../../util/routes';
import BaseModal from '../BaseModal/BaseModal';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button } from '../index';

import styles from './NotEnoughBuyingPower.module.scss';

type Props = {
  closeModal: () => void;
}

const NotEnoughBuyingPower = ({ closeModal }: Props) => {
  const history = useHistory();

  return (
    <BaseModal isOpen>
      <h6 className={styles.header}>{lang.commonTradingNotEnoughBuyingPowerTitle()}</h6>
      <ModalBody>
        <p className={styles.text}>{lang.commonTradingNotEnoughBuyingPowerDescription()}</p>
        <div className={styles.actions}>
          <Button
            variant={ButtonTypesEnum.Tertiary}
            fullWidth
            className={styles.btnCancel}
            onClick={() => closeModal()}
          >
            {lang.commonCancelButton()}
          </Button>
          <Button fullWidth onClick={() => history.push(UI_ROUTES.funds)}>
            {lang.commonFundButton()}
          </Button>
        </div>
      </ModalBody>
    </BaseModal>
  );
};

export default NotEnoughBuyingPower;
