import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';

import styles from './CheckboxControl.module.scss';

type Props = {
  id?: string;
  error?: string
  errorId?: string
  ariaLabel?: string;
  component?: React.ReactElement;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  centered?: boolean;
  secondary?: boolean;
  onChange?: (value: boolean) => void;
};

/**
 * Checkbox with a label component.
 * Created to support texts with spanned parts like this:
 *
 * <p className={styles.text}>
 *   {lang.someText()}
 *   <ClickableSpan className={styles.link} onClick={onClick}>
 *     {lang.someText()}
 *   </ClickableSpan>
 *   {lang.someText()}
 * </p>
 *
 */
const CheckboxControlV2 = (props: Props) => {
  const {
    id,
    ariaLabel,
    component,
    checked = false,
    disabled = false,
    className,
    centered,
    secondary,
    onChange,
    error,
    errorId,
  } = props;

  return (
    <>
      <FormControlLabel
        label={''}
        className={classNames(
          styles.checkboxWrapper,
          className,
          {
            [styles.topAligned]: !centered,
            [styles.secondary]: secondary,
            [styles.disabled]: disabled,
          },
        )}
        control={(
          <>
            <Checkbox
              id={id}
              aria-label={ariaLabel}
              checked={checked}
              disabled={disabled}
              icon={<span className={classNames(styles.checkBox, { [styles.errorState]: error })} />}
              checkedIcon={
                <span className={classNames(styles.checkBox, styles.checked)} />
            }
              onChange={() => onChange?.(!checked)}
            />
            {component}
          </>
      )}
      />
      {error && (<p id={errorId} className={styles.error}>{error}</p>)}
    </>
  );
};

export default CheckboxControlV2;
