import { isObjectLike, isRegExp, isString, uniq } from 'lodash';


export type ObjectMap = { [key: string]: any };

export function getObjectKeysAsString(object: any): string {
  return Object.keys(object).join(', ');
}

export function propsToString(object: any, startTrunc = 0, endTrunc = 20) {
  if (!isObjectLike(object)) return typeof object;

  return (
    Object
      .entries(object)
      .map(([ i1, i2 ]) => `${i1.substr(startTrunc, startTrunc + endTrunc)}:${i2 ?? 'na'}|${i2 ? typeof i2 : ''}`)
      .join(' '));
}


export function isNull(root: ObjectMap, path: string) {
  if (!root) return true;

  let steps: string[] = path.split('.');

  let current = root;
  while (steps.length) {
    current = current[steps.shift()!];
    if (current == null) {
      return true;
    }
  }

  return current == null;
}

export function getProp(root: ObjectMap, path: string): any {
  if (!root) return null;

  let result = root;
  let steps: string[] = path.split('.');

  while (steps.length) {
    result = result[steps.shift()!];
    if (result == null) {
      return null;
    }
  }

  return result;
}

export function getPropName(root: ObjectMap, path: string): any {
  if (!root) return null;

  let result = root;
  let steps: string[] = path.split('.');
  let lastStep = '';

  while (steps.length) {
    lastStep = steps.shift()!;
    result = result[lastStep];
    if (result == null) {
      return null;
    }
  }

  return lastStep;
}

/**
 * Returns `true` if `filter` (string|RegExp) matches `value` - returns `true`, `false` otherwise.
 * If `filter` is a boolean - returns `filter`
 * */
export function checkFilter(filter: null | boolean | string | number | RegExp, value: string | null): boolean {
  if (value === null) return false;
  if (typeof value === 'number') return false;
  if (filter === null || filter === false) return false;
  if (filter === true) return true;

  return (isRegExp(filter) || isString(filter))
          && isString(value)
          && (value as any).match(filter as any) != null;
}

export function concatKeys(...args: any[]) {
  let result = '';

  args.forEach((item: any, index: number) => {
    if (index > 0) result += '_';
    result += `${item}`;
  });

  return result;
}

export function valueToString(value: any, title?: string) {
  let result;
  if (value === null) result = 'null';
  if (value === undefined) result = 'undefined';
  if (value === true) result = 'true';
  if (value === false) result = 'false';
  if (!isNaN(Number(value))) result = `${value}`;
  if (isString(value)) result = value;
  if (result) {
    return (title ? `${title}: ` : '') + result;
  }

  const theTitle = title ? `${title}\n` : '';
  let valueAsString = '';
  for (let key in value) valueAsString += `${key}: ${value[key]}\n`;

  return `${theTitle}${valueAsString}`;
}

/* eslint-disable no-param-reassign */
export function addToObjectOfArray(object: any, key: string | number, items: any[]) {
  if (!object) return;

  if (!object[key]) {
    object[key] = items;
    return;
  }

  object[key] = uniq(object[key].concat(items));
}
/* eslint-enable no-param-reassign */
