import { ApiErrorResponse, ApiResponse } from 'apisauce';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { forkJoin, from, Observable, Observer, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { DocumentStatusEnum, HttpStatusCodeEnum } from '../../enums';
import { IndividualExtendedInfoData } from '../../models/enroll';
import { EnrollIndividualDocumentOwnRequestData } from '../../models/enroll-requests';
import GlobalService from '../../services/Global.service';
import { IFileService } from '../../services/types';
import {
  deleteFileByName,
  individualExtendedInfoSuccess,
} from '../common-actions';
import {
  createSuccessResponse,
  processErrorInResponse,
  processResponse,
} from '../helpers';
import { RootState } from '..';

import {
  deleteFileByNameFailed,
  deleteFileByNameSuccess,
  getFileByName,
  getFileByNameCancel,
  getFileByNameFailed,
  getFileByNameSuccess,
  getIndividualFilesCompleted,
  getIndividualFilesFailed,
} from './index';

type MappedResponse = {
  filesContents: { [fileId: string]: string };
  errors: ApiErrorResponse<string>[]
}

const createFilesRequests = (
  individual: IndividualExtendedInfoData | null,
  state: StateObservable<RootState>,
  service: IFileService,
): {
  filesIds: string[],
  filesRequests: Observable<any>[]
} => (
  individual && individual.documents
    ? individual.documents
      .reduce(
        (
          accumulator: { filesIds: string[], filesRequests: Observable<any>[] },
          document: EnrollIndividualDocumentOwnRequestData,
        ) => {
          if (document.status === DocumentStatusEnum.Approved) {
            accumulator.filesIds.push(...document.file_storage_id);

            document.file_storage_id.forEach((fileId: string) => {
              const fileContent = state.value.file.files[fileId];

              const request = (typeof fileContent === 'undefined') ? from(service.get(fileId))
                : of(createSuccessResponse(fileContent));

              accumulator.filesRequests.push(request);
            });
          }

          return accumulator;
        },
        { filesIds: [], filesRequests: [] },
      )
    : { filesIds: [], filesRequests: [] }
);

const mapFilesResponses = (
  filesIds: string[],
  responses: ApiResponse<string>[],
): MappedResponse => (
  responses.reduce((
    accumulator: MappedResponse,
    response: ApiResponse<string>,
    index: number,
  ) => {
    if (response.status !== HttpStatusCodeEnum.Ok) {
      accumulator.errors.push(response as ApiErrorResponse<string>);
    } else {
      accumulator.filesContents[filesIds[index]] = response.data as string;
    }

    return accumulator;
  }, { filesContents: {}, errors: [] })
);

const getIndividualFilesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(individualExtendedInfoSuccess.type),
  mergeMap(action => {
    const callName = 'file/getIndividualFiles';
    const individual = state$.value.crm.individualExtendedInfo;
    const { filesIds, filesRequests } = createFilesRequests(individual, state$, GlobalService.fileService);

    if (filesRequests.length === 0) {
      return new Observable(observer => {
        const response = createSuccessResponse({ filesContent: {} });

        processResponse(
          callName,
          {},
          response,
          observer,
          getIndividualFilesCompleted,
          getIndividualFilesFailed,
        );
      });
    }

    /**
     * `forkJoin` RxJS operator combine multiple observables, execute them in parallel
     * and when all observables complete, emit the last emitted value from each.
     */
    return forkJoin(filesRequests).pipe(
      mergeMap((responses: ApiResponse<string>[]) => {
        const { filesContents, errors } = mapFilesResponses(filesIds, responses);

        return new Observable(observer => {
          if (Object.keys(filesContents).length > 0 && Object.keys(errors).length === 0) {
            processResponse(
              callName,
              {},
              createSuccessResponse({ filesContents }),
              observer,
              getIndividualFilesCompleted,
              getIndividualFilesFailed,
            );
          } else if (Object.keys(filesContents).length === 0 && Object.keys(errors).length > 0) {
            processErrorInResponse(callName, errors, action, observer, getIndividualFilesFailed);
          } else {
            processResponse(
              callName,
              {},
              createSuccessResponse({ filesContents }),
              observer,
              getIndividualFilesCompleted,
              getIndividualFilesFailed,
            );

            processErrorInResponse(callName, errors, action, observer, getIndividualFilesFailed);
          }
        });
      }),
    );
  }),
);

const deleteDocumentByNameEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(deleteFileByName.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'file/deleteByName';
    const documentName = !action.payload.customData ? action.payload : action.payload.customData.documentName;

    if (!documentName) {
      processErrorInResponse(
        callName,
        new Error('[File] No document name not found'),
        action,
        observer,
        deleteFileByNameFailed,
      );

      return;
    }

    GlobalService
      .fileService
      .deleteByName(documentName)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          deleteFileByNameSuccess,
          deleteFileByNameFailed,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, deleteFileByNameFailed));
  })),
);

const getDocumentByNameEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getFileByName.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'crm/getFileByName';
    const { documentName } = action.payload;

    if (!documentName) {
      processErrorInResponse(
        callName,
        new Error('[File] No document name not found'),
        action,
        observer,
        getFileByNameFailed,
      );

      return;
    }

    GlobalService
      .fileService
      .get(documentName)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getFileByNameSuccess,
          getFileByNameFailed,
          false,
          { fileName: documentName },
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getFileByNameFailed));
  })),
  takeUntil(action$.pipe(ofType(getFileByNameCancel.type))),
);


export default combineEpics(
  getIndividualFilesEpic,
  deleteDocumentByNameEpic,
  getDocumentByNameEpic,
);
