import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import lang from '../../../lib/language';
import { ENROLL_METHOD, ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import { AppDocumentUploadCategories } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enroll } from '../../../lib/store/crm/index';
import { getIdentityDocumentSubType, hasUploadDocumentPOIFinished } from '../../../lib/store/selectors';
import { TextHelpers } from '../../../lib/util/TextHelpers';

import { Document, VerificationLayout } from '../../../components';
import { UI_ROUTES } from '../../../util/routes';
import UploadErrorModal from '../ErrorModals/UploadErrorModal';

import Bullet from './Bullet/Bullet';

import styles from './VerificationPoI.module.scss';

const VerificationPoI = (): JSX.Element => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [uploadIDFront, setUploadIDFront] = useState('');
  const [uploadIDBack, setUploadIDBack] = useState('');
  const [uploadPassport, setUploadPassport] = useState('');
  const [isFrontSent, setIsFrontSent] = useState(false);
  const [isBackSent, setIsBackSent] = useState(false);
  const [isPassportSent, setIsPassportSent] = useState(false);
  const [showErroModal, setShowErroModal] = useState<boolean>(false);
  const [uploadDocumentFront, setUploadDocumentFront] = useState<File>();
  const [uploadDocumentBack, setUploadDocumentBack] = useState<File>();

  const history = useHistory();
  const dispatch = useDispatch();

  const typeOfIdentityDocument = useSelector<RootState>((state: RootState) =>
    getIdentityDocumentSubType(state));
  const isPending = useSelector<RootState, boolean>((state: RootState) => state.crm.enroll.callsInQueue > 0);
  const hasUploadRequestFinished = useSelector<RootState, boolean>(
    (state: RootState) => hasUploadDocumentPOIFinished(state),
  );
  const hasError = useSelector<RootState, boolean>(
    (state: RootState) => state.crm.enroll.status === ENROLL_REQUEST_STATE.ERROR,
  );

  const isDocTypePassport = typeOfIdentityDocument === 'PAS';

  useEffect(() => {
    if ((uploadIDFront && uploadIDBack) || uploadPassport) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [uploadIDBack, uploadIDFront, uploadPassport]);

  const submitDocuments = () => {
    const callMethod = ENROLL_METHOD.postIndividualIdentityDetailOwnWithFile;
    if (uploadIDFront && uploadIDBack) {
      const files = [uploadDocumentFront, uploadDocumentBack];

      dispatch(enroll({
        callMethod,
        callMethodName: ENROLL_METHOD[callMethod],
        requestBody: { files, filesInfo: AppDocumentUploadCategories.ID_FRONT },
      }));

      setIsFrontSent(true);
      setIsBackSent(true);
    }
    if (uploadPassport) {
      const files = [uploadDocumentFront];

      dispatch(enroll({
        callMethod,
        callMethodName: ENROLL_METHOD[callMethod],
        requestBody: { files, filesInfo: AppDocumentUploadCategories.PASSPORT },
      }));
      setIsPassportSent(true);
    }
  };

  useEffect(() => {
    const isDocumentsUploaded = (isFrontSent && isBackSent) || isPassportSent;

    if (isDocumentsUploaded) {
      setBtnDisabled(false);
    }

    if (hasError && ((isFrontSent && isBackSent) || isPassportSent)) {
      setShowErroModal(true);
    }
    if (((isFrontSent && isBackSent) || isPassportSent) && !hasError && hasUploadRequestFinished) {
      history.push(UI_ROUTES.verification);
    }
  }, [isFrontSent, isBackSent, isPassportSent, history, hasError, hasUploadRequestFinished]);

  return (
    <>
      <VerificationLayout
        onContinueClicked={submitDocuments}
        title={!isDocTypePassport
          ? lang.commonVerificationUploadPoITitle() : lang.commonVerificationUploadPoIPassportTitle()}
        subtitle={lang.commonVerificationUploadPoISubTitle()}
        btnDisabled={btnDisabled || isPending}
      >
        <>
          <ul className={styles.bulletsWrapper}>
            {!isDocTypePassport && <Bullet title={lang.commonVerificationUploadPoIBullet1()} />}
            <Bullet title={lang.commonVerificationUploadPoIBullet2()} />
            <Bullet title={lang.commonVerificationUploadPoIBullet3()} />
          </ul>

          <Row className={styles.documentsWrapper}>
            <Col>
              {!isDocTypePassport && <p>{lang.commonVerificationUploadFrontIDImageTitle()}</p>}
              <Document
                idInput="uploadPOIFront"
                idRemove="removePOIFront"
                ariaLabelInput="upload proof of identity front"
                ariaLabelRemove="remove proof of identity front"
                handleUploadDocument={isDocTypePassport
                  ? setUploadPassport : setUploadIDFront}
                handleUploadFileAsFileFormat={setUploadDocumentFront}
                disabledUpload={isPending}
              />
            </Col>
            {!isDocTypePassport && (
            <Col>
              <p>{lang.commonVerificationUploadBackIDImageTitle()}</p>
              <Document
                idInput="uploadPOIBack"
                idRemove="removePOIBack"
                ariaLabelInput="upload proof of identity  back"
                ariaLabelRemove="remove proof of identity  back"
                handleUploadDocument={setUploadIDBack}
                handleUploadFileAsFileFormat={setUploadDocumentBack}
                disabledUpload={isPending}
              />
            </Col>
            )}
            <Col md={12}>
              <p className={styles.hint}>
                {lang.commonUploadDocumentTypesHint(TextHelpers.getAllowedFileExtensionNames())}
              </p>
            </Col>
          </Row>
        </>
      </VerificationLayout>
      {showErroModal && <UploadErrorModal handleClose={(value) => setShowErroModal(value)} />}
    </>
  );
};

export default VerificationPoI;
