import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store';
import { isUserVerifiedStatus } from '../../../lib/store/selectors';

import imageFirstDeposit from '../../../assets/bigImages/image_first-deposit.png';
import iconBank from '../../../assets/img/icon-bank.svg';
import iconDebitCredit from '../../../assets/img/icon-debit-credit.svg';
import infoIcon from '../../../assets/img/icon-info-outline.svg';
import { BaseModal, Button, ClickableDiv } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { UI_ROUTES } from '../../../util/routes';

import BankTransferModal from './modal/BankTransferModal';
import CardTransferModal from './modal/CardTransferModal';
import FeeBreakdownModal from './modal/feeBreakdownModal/FeeBreakdownModal';

import styles from './MakeYourFirstDeposit.module.scss';

type Props = {} & ReduxProps;

const MakeYourFirstDeposit = (props: Props) => {
  const { individual, hasDeposit } = props;

  const history = useHistory();
  const locationState = history.location.state as { previousPage?: string};
  const isUserVerified = isUserVerifiedStatus(individual);

  const [showModalCardTransfer, setShowModalCardTransfer] = useState<boolean>(false);
  const [textInfoModalCardTransfer, setTextInfoModalCardTransfer] = useState({ title: '', description1: '', description2: '' });
  const [showModalBankTransfer, setShowModalBankTransfer] = useState<boolean>(false);
  const [textInfoModalBankTransfer, setTextInfoModalBankTransfer] = useState({ title: '', description1: '', description2: '' });
  const [showModalFeeBreakdown, setShowModalFeeBreakdown] = useState<boolean>(false);

  const handleModalCardTransfer = () => {
    setShowModalCardTransfer(!showModalCardTransfer);
  };
  const handleClickCardTransfer = (title: string, description1: string, description2: string) => {
    setTextInfoModalCardTransfer({ ...textInfoModalCardTransfer, title, description1, description2 });
    handleModalCardTransfer();
  };

  const handleModalBankTransfer = () => {
    setShowModalBankTransfer(!showModalBankTransfer);
  };
  const handleClickBankTransfer = (title: string, description1: string, description2: string) => {
    setTextInfoModalBankTransfer({ ...textInfoModalBankTransfer, title, description1, description2 });
    handleModalBankTransfer();
  };

  const handleModalFeeBreakdown = () => {
    setShowModalFeeBreakdown(!showModalFeeBreakdown);
  };

  return (
    <>
      {(!isUserVerified || hasDeposit) && <Redirect to={UI_ROUTES.funds} />}
      <div className={styles.wrapperContainer}>
        <div className={styles.containerContent}>
          <div className={styles.wrapperContent}>
            <div className={styles.content}>
              <div className={styles.header}>
                <h3 className={styles.title} id="firstDepositTitle">{lang.commonNextStepsButtonTitle_3()}</h3>
                <Button
                  id="buttonClose"
                  variant={ButtonTypesEnum.Link}
                  className={styles.btnClose}
                  onClick={() => (locationState ? history.push(UI_ROUTES.myAccount) : history.goBack())}
                >
                  {lang.commonClose()}
                </Button>
              </div>
              <img src={imageFirstDeposit} className={styles.image} alt="first deposit" />
              <ClickableDiv
                id="cardTransferButton"
                className={styles.wrapperCardBankTransfer}
                onClick={() => handleClickCardTransfer(
                  lang.commonFirstDepositCard(),
                  lang.webPopupCardTransferDescription1(),
                  lang.webPopupCardTransferDescription2(),
                )}
              >
                <img src={iconDebitCredit} alt="icon debit credit" />
                <div className={styles.wrapperText}>
                  <div className={styles.transferText}>
                    <p>{lang.commonFirstDepositCard()}</p>
                  </div>
                  <p className={styles.transferDescription}>
                    {lang.commonFirstDepositCardInstant()}
                  </p>
                </div>
              </ClickableDiv>
              <ClickableDiv
                id="bankTransferButton"
                className={styles.wrapperCardBankTransfer}
                onClick={() => handleClickBankTransfer(
                  lang.mobileBankDialogTitle(),
                  lang.webPopupBankTransferDescription1(),
                  lang.webPopupBankTransferDescription2(),
                )}
              >
                <img src={iconBank} alt="icon bank" />
                <div className={styles.wrapperText}>
                  <div className={styles.transferText}>{lang.commonFirstDepositBank()}</div>
                  <p className={styles.transferDescription}>
                    {lang.commonFirstDepositBankDescription()}
                  </p>
                </div>
              </ClickableDiv>
              <ClickableDiv
                className={styles.wrapperInfoText}
                onClick={handleModalFeeBreakdown}
              >
                <img src={infoIcon} alt="infoIcon" />
                <span>{lang.fundsCheckTheFeeBreakdown()}</span>
              </ClickableDiv>
            </div>
          </div>
        </div>
      </div>
      <BaseModal isOpen={showModalCardTransfer || showModalBankTransfer || showModalFeeBreakdown}>
        {showModalCardTransfer
        && <CardTransferModal handleModal={handleModalCardTransfer} textInfoModal={textInfoModalCardTransfer} />}

        {showModalBankTransfer
        && <BankTransferModal handleModal={handleModalBankTransfer} textInfoModal={textInfoModalBankTransfer} />}

        {showModalFeeBreakdown
        && <FeeBreakdownModal handleModal={handleModalFeeBreakdown} />}
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
  hasDeposit: state.payment.hasDeposit,
});
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MakeYourFirstDeposit);
