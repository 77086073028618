export enum CallStatus {
  ERROR = 0,
  INITIAL = 1,
  PENDING = 2,
  PENDING_QUEUED = 3,
  READY = 4,
  /**
   * Extra statuses to mark data handling in UI after being received.
   * (for example a popup was shown and there is no need to show it again)
   */
   UI_PENDING = 5,
   UI_DONE = 6,
}
export type CallStatusName = keyof typeof CallStatus
