import React from 'react';
import classNames from 'classnames';

import { ClickableDiv } from '../../index';

import styles from './FilterButton.module.scss';

type Props = {
    isActive: boolean;
    name: string;
    onClick: (val: MouseEvent) => void;
}

const FilterButton = ({ isActive, name, onClick }: Props) => (
  <ClickableDiv className={classNames(styles.filterButton, { [styles.active]: isActive })} onClick={onClick}>
    {name}
  </ClickableDiv>
);

export default FilterButton;
