import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';

import lang from '../../../../../../../lib/language';
import { RootState } from '../../../../../../../lib/store';

import leftArrow from '../../../../../../../assets/img/blue_icon-arrow-left.svg';
import config from '../../../../../../../config';
import { useIsMobile } from '../../../../../../../hooks';
import AppVersion from '../../../../../../AppVersion/AppVersion';
import { ClickableImage, Preloader, UserPhoto } from '../../../../../../index';
import PersonalAddressDetails from '../personalAddressDetails/PersonalAddressDetails';
import PersonalContactDetails from '../personalContactDetails/PersonalContactDetails';
import PersonalNameDetails from '../personalNameDetails/PersonalNameDetails';

import styles from './PersonalDetailsComponent.module.scss';

const PersonalDetailsComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { individual } = props;
  const isMobile = useIsMobile();

  if (!individual) {
    return <div className={styles.loader}><Preloader owner="Personal Details" text="" /></div>;
  }

  const { first_name, middle_name, last_name, email, phone_number, addresses } = individual;

  return (
    <div className={styles.wrapper}>
      { !isMobile && (
        <div className={styles.wrapperPhoto}>
          <UserPhoto fullSize />
        </div>
      )}
      <div className={styles.wrapperPersonalDetails}>
        { isMobile && (
          <div className={styles.personalDetailsHeader}>
            <ClickableImage
              alt=""
              src={leftArrow}
              className={styles.leftArrow}
              onClick={() => history.goBack()}
            />
            <div className={styles.headerText}>
              <h3>{lang.commonPersonalDetails()}</h3>
            </div>
          </div>
        )}
        <PersonalNameDetails firstName={first_name} middleName={middle_name || ''} lastName={last_name} />
        <PersonalContactDetails email={email} phoneNumber={phone_number || ''} />
        <PersonalAddressDetails
          residentialAddress={addresses[0]}
          mailingAddress={addresses[1] ? addresses[1] : null}
        />
        <div className={styles.wrapperContactUs}>
          <span>{lang.commonPersonalDetailsContactUsDescription()}</span>
          <Link className={styles.btnContactUs} target="_blank" href={config.links.helpCenterUrl}>
            {lang.commonPersonalDetailsContactUsButton()}
          </Link>
          <p>
            <span>{lang.accountDelitionPart1()}</span>
            <Link className={styles.btnContactUs} target="_blank" href={config.links.privacyPolicyUrl}>
              {lang.accountDelitionPart2()}
            </Link>
            <span>{lang.accountDelitionPart3()}</span>
          </p>
        </div>
      </div>
      {!isMobile && <AppVersion />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PersonalDetailsComponent);
