import React from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { History } from 'history';

import { setIsChangeUserPassword, startAuthRefresh as serviceStartAuthRefresh } from '../../lib/store/auth';
import { login as serviceLogin, logout as serviceLogout } from '../../lib/store/common-actions';
import { RootState } from '../../lib/store/index';
import { isUserVerifiedStatus } from '../../lib/store/selectors';

import AppVersion from '../../components/AppVersion/AppVersion';
import { BaseModal } from '../../components/index';
import config from '../../config';

import ChangePasswordModal from './ChangePassword/ChangePasswordModal';
import { MENU_LIST, myProfileIds } from './MyProfile.constants';
import { MyProfileItem } from './MyProfile.types';

import styles from './MyProfile.module.scss';

type OwnProps = {};
type Props = OwnProps & ReduxProps;

const MyProfile = ({ logout, login, isUserVerified }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isChangeUserPassword = useSelector<RootState, boolean>((state: RootState) => state.auth.isChangeUserPassword);

  const handleLogout = () => {
    logout();
    login(history);
  };

  const handleOptionClick = (id: string) => {
    if (id === myProfileIds.logout) {
      handleLogout();
    }

    if (id === myProfileIds.security) {
      handleModal();
      return;
    }
    if (id === myProfileIds.helpCenter) {
      window.open(config.links.helpCenterUrl, '_blank');
    }
  };

  const handleModal = () => {
    dispatch(setIsChangeUserPassword(!isChangeUserPassword));
  };
  const isDisabledLink = (item: MyProfileItem):boolean => item.id === myProfileIds.documents && !isUserVerified;

  return (
    <div className={styles.wrapper}>
      {MENU_LIST.map(item => (
        <NavLink
          className={isDisabledLink(item) ? styles.linkDisabled : styles.link}
          id={item.id}
          key={item.id}
          to={{
            pathname: item?.path && !isDisabledLink(item) ? item.path : '',
          }}
          onClick={() => handleOptionClick(item.id)}
        >
          <div key={item.id} className={styles.item}>
            <div className={styles.imgageWrapper}>
              <img
                className={isDisabledLink(item) ? styles.imageDisabled : styles.image}
                src={item.img}
                alt={item.label}
              />
            </div>
            <div className={styles.label}>{item.label}</div>
          </div>
        </NavLink>
      ))}
      <BaseModal isOpen={isChangeUserPassword}>
        { isChangeUserPassword && <ChangePasswordModal handleModal={handleModal} /> }
      </BaseModal>
      <AppVersion />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  dispatch,
  startAuthRefresh: () => dispatch(serviceStartAuthRefresh()),
  logout: () => dispatch(serviceLogout()),
  login: (history: History) => {
    dispatch(serviceLogin('login'));
  },
});

const mapStateToProps = (state: RootState) => ({
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
