import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import lang from '../../../lib/language';
import { BasePagedResponse } from '../../../lib/models/common-response-types';
import { ETF, SectorDetail } from '../../../lib/models/fundamental/types';
import { RootState } from '../../../lib/store';
import { loadDiscoverChartData } from '../../../lib/store/common-actions';
import { getETFs, getSectorDetail } from '../../../lib/store/fundamental';
import { DEFAULT_SEARCH_STOCK_LIMIT, NO_MORE_PAGES_TO_READ } from '../../../lib/store/fundamental/constants';
import { FundamentalCall } from '../../../lib/store/fundamental/types';
import { isCallStatusPending } from '../../../lib/util/error-handling/StatusByCallHelpers';
import { manageSubscriptions } from '../../../lib/util/TradingHelpers';

import { BaseTable, Button, LatestNews, Preloader } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { useIsMobile } from '../../../hooks';
import groups from '../PopularGroupItems/groups';
import { NewOnThePopularGroupsData } from '../PopularGroups.types';

import PopularGroupTableItem from './PopularGroupTableItem';

import styles from './PopularGroupTable.module.scss';

const POPULAR_GROUP_TABLE_HEADERS = ['Symbol/Name', 'Chart', 'Last Price', 'Change', '%Change'];

const PopularGroupTable = () => {
  const isMobile = useIsMobile();
  const canTradeETFs = useSelector<RootState, boolean>((state: RootState) => state.ses.canTradeETFs);
  const ETFData = useSelector<RootState, BasePagedResponse<ETF>>((state: RootState) => state.fundamental.ETFData);
  const sectorDetails = useSelector<RootState, SectorDetail>((state: RootState) => state.fundamental.sectorDetails);
  const isGettingETFDetails = useSelector<RootState, boolean>((state: RootState) =>
    isCallStatusPending(FundamentalCall.getETFs, state.fundamental.statusByCall));
  const isGettingSectorDetails = useSelector<RootState, boolean>((state: RootState) =>
    isCallStatusPending(FundamentalCall.getSectorDetails, state.fundamental.statusByCall));

  const dispatch = useDispatch();
  const [showTableContent, setShowTableContent] = useState<boolean>(false);

  const history = useHistory();
  const locationState = history.location.state as { group: NewOnThePopularGroupsData, sector: string };
  const { id, symbolCount } = locationState.group;
  const { sector } = locationState;

  const tableHeaders = useMemo(() => POPULAR_GROUP_TABLE_HEADERS.map((item: string) => <th key={item}>{item}</th>), []);
  manageSubscriptions('discover/popular-group', null, false);

  useEffect(() => {
    if (sectorDetails.sectorId === id && sectorDetails.sector.length > 0) {
      setShowTableContent(true);
    }
  }, [sectorDetails, id]);

  useEffect(() => {
    if (sectorDetails.sectorId === id) {
      const sectorSymbols = sectorDetails.content.map(el => el.symbol).join(',');
      dispatch(loadDiscoverChartData(sectorSymbols));
    } else {
      dispatch(getSectorDetail(Number(id)));
    }
  }, [dispatch, id, sectorDetails.content, sectorDetails.sectorId]);

  useEffect(() => {
    if (canTradeETFs && sector === 'ETFs') {
      dispatch(getETFs({ size: DEFAULT_SEARCH_STOCK_LIMIT, page: ETFData.nextPage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canTradeETFs, sector, dispatch]);

  const handleButtonLoadMore = () => {
    if (canTradeETFs && sector === 'ETFs') {
      dispatch(getETFs({ size: DEFAULT_SEARCH_STOCK_LIMIT, page: ETFData.nextPage }));
    } else {
      dispatch(getSectorDetail(Number(id)));
    }
  };

  return (
    <div className={classNames(styles.wrapperLayout, { [styles.wrapperLayoutMob]: isMobile })}>
      { ((sectorDetails.sectorId !== id && (isGettingSectorDetails || sectorDetails.content.length === 0))
     || (canTradeETFs && sector === 'ETFs' && ETFData.content.length === 0))
      && <div className={styles.loader}><Preloader text="" owner="PopularGroup" /></div>}
      <div className={styles.wrapperFirstSection}>
        <h6 className={isMobile ? styles.titleMobile : styles.title}>{sector}</h6>
        <p className={isMobile ? styles.stocksLabelMobile : styles.stocksLabel}>
          {`${symbolCount} ${lang.accountBreakdownTableStockLabel()}`}
        </p>
        {
          sectorDetails.sectorId === id
          && (
            <>
              <BaseTable className={isMobile ? styles.tableMobile : styles.table}>
                {!isMobile && <thead><tr>{tableHeaders}</tr></thead>}
                <tbody>
                  {showTableContent && (canTradeETFs && sector === 'ETFs' ? ETFData.content : sectorDetails.content).map(
                    (item, index) => <PopularGroupTableItem key={index as number} item={item} />,
                  )}
                </tbody>
              </BaseTable>
              <Button
                className={(sectorDetails.nextPageToRead === NO_MORE_PAGES_TO_READ
                || isGettingSectorDetails
                || isGettingETFDetails
                || ETFData.nextPage === NO_MORE_PAGES_TO_READ)
                  ? styles.hideBtnLoadMore : styles.btnLoadMore}
                variant={ButtonTypesEnum.Link}
                onClick={handleButtonLoadMore}
              >
                {lang.commonLoadMore()}
              </Button>
            </>
          )
        }
      </div>
      {!isMobile && <div className={styles.wrapperSecondSection}><LatestNews /></div>}
    </div>
  );
};

export default PopularGroupTable;
