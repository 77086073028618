import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import lang from '../../../lib/language';
import { RootState } from '../../../lib/store/index';
import { AccountTradeHistoryResponseData } from '../../../lib/store/reporting/types';

import {
  usePendingOrdersTableContent,
} from '../../../containers/MyAccount/PendingOrders/helpers/usePendingOrdersTableContent';
import { useIsMobile } from '../../../hooks';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';
import { TABLE_HEADERS_SYMBOL_DETAILS_PENDING_ORDERS } from '../../WatchList/WatchList.constans';

import PendingOrdersContainer from './PendingOrdersContainer/PendingOrdersContainer';

import styles from './PendingOrders.module.scss';

type Props = {
    symbol: string
} & ReduxProps

const defaultRowsNumber = 3;

const PendingOrders = ({ symbol, pendingOrders }: Props) => {
  const isMobile = useIsMobile();
  const [symbolOrders, setSymbolOrders] = useState<AccountTradeHistoryResponseData[]>([]);
  const [lenghtRows, setLenghtRows] = useState(defaultRowsNumber);
  const [showLess, setShowLess] = useState<boolean>(false);

  const pendingOrdersTableContent = usePendingOrdersTableContent(symbolOrders, true);

  useEffect(() => {
    setSymbolOrders(pendingOrders.filter(order => order.symbol === symbol));
  }, [pendingOrders, symbol]);

  const handleShowMore = () => {
    setLenghtRows(Number.MAX_VALUE);
    setShowLess(true);
  };

  const handleShowLess = () => {
    setLenghtRows(defaultRowsNumber);
    setShowLess(false);
  };

  const hasShowMore = pendingOrdersTableContent.length > defaultRowsNumber;
  const headerItems = TABLE_HEADERS_SYMBOL_DETAILS_PENDING_ORDERS.map(
    (item: string) => <th key={item}>{item}</th>,
  );

  if (!pendingOrdersTableContent.length) {
    return null;
  }

  return (
    <div className={styles.rowWrapper}>
      <div>
        <div className={styles.pendingWrapper}>
          <h6 className={isMobile ? styles.titleMobile : styles.title}>{lang.commonPendingOrders()}</h6>
          {hasShowMore && !showLess && (
          <Button
            variant={ButtonTypesEnum.Link}
            onClick={handleShowMore}
          >
            {lang.commonStockDetailsShowMore()}
          </Button>
          )}
          {showLess && (
          <Button
            variant={ButtonTypesEnum.Link}
            onClick={handleShowLess}
          >
            {lang.commonStockDetailsShowLess()}
          </Button>
          )}
        </div>
      </div>
      <div className={styles.wrapperTable}>
        <PendingOrdersContainer
          tableHeaders={headerItems}
          data={hasShowMore ? pendingOrdersTableContent.slice(0, lenghtRows) : pendingOrdersTableContent}
          isMultipleDivOfRow
          isButtonsInRow
          companyLogos
          hasSymbol
          otherButtons={lang.commonMyAccountButtonPendingOrders()}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  pendingOrders: state.reporting.pendingOrders,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PendingOrders);
