
// TODO remove this temporary approach for error handling when there is proper server error handling.
export const checkErrorSatusCode = (
  statusCode: number,
  clientErrorMessage: string,
  serverErrorMessage: string,
): string => {
  if (statusCode >= 400 && statusCode < 500) return clientErrorMessage;
  if (statusCode >= 500) return serverErrorMessage;
  return '';
};

export function appendToErrorsLog(message: string, state: { errorsLog: string[] } | null, errorsLog?: string[]) {
  const theLogs = state?.errorsLog ?? errorsLog;
  if (!theLogs) return;

  theLogs.push(`${message}@@${new Date().toISOString()}`);
  if (theLogs.length > 15) theLogs.shift();
}
