import React from 'react';
import classNames from 'classnames';

import { PaymentProcessEnum } from '../../../lib/enums';
import { PaymentStatusEnum } from '../../../lib/enums/payments/payment-status.enum';
import lang from '../../../lib/language';
import { Payment } from '../../../lib/models/crm/types';
import { isDeposit, isWithdrawal } from '../../../lib/store/selectors';
import { formatTransaction } from '../../../lib/util/DataHelpers';
import { parseDateTo, ParseToType } from '../../../lib/util/DateTimeHelpers';
import { TextHelpers } from '../../../lib/util/TextHelpers';

import iconUpArrow from '../../../assets/img/icon_deposit.svg';
import iconDownArrow from '../../../assets/img/icon_withdraw.svg';
import { useIsMobile } from '../../../hooks';

import styles from './Transactions.module.scss';

type Props = {
    payment: Payment
}

const TransactionItem = ({ payment }: Props) => {
  const isMobile = useIsMobile();
  const displayStatus = (paymentStatus: PaymentStatusEnum) => {
    if (payment.payment_process === PaymentProcessEnum.Adjustment) {
      return <p className={styles.secondaryText}>{TextHelpers.getAdjustmentCategoryName(payment.category)}</p>;
    }
    if (payment.payment_status === PaymentStatusEnum.Completed) {
      return (
        <p className={styles.hidePendingText}>
          {TextHelpers.getPaymentStatusName(payment.payment_status)}
        </p>
      );
    }
    if (payment.payment_status === PaymentStatusEnum.Rejected) {
      return (
        <p className={styles.errorText}>
          {TextHelpers.getPaymentStatusName(payment.payment_status)}
        </p>
      );
    }
    if (payment.payment_status === PaymentStatusEnum.Canceled) {
      return (
        <p className={styles.errorText}>
          {TextHelpers.getPaymentStatusName(payment.payment_status)}
        </p>
      );
    }
    if (payment.payment_status === PaymentStatusEnum.ForApproval) {
      return (
        <p className={styles.pendingText}>
          {lang.commonOrderStatusPendingApproval()}
        </p>
      );
    }
    return null;
  };

  return (
    <>
      { isMobile ? (
        <>
          <div className={styles.wrapperInfoMobile}>
            <div className={styles.iconWrapper}>
              <img
                className={styles.icon}
                src={
                  (payment.amount < 0 || isWithdrawal(payment))
                    ? iconDownArrow
                    : iconUpArrow
                  }
                alt="icon arrow"
              />
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.wrapperLine}>
                <div className={styles.paymentType}>
                  {TextHelpers.getPaymentProcessName(payment.payment_process)}
                </div>
                <div className={styles.paymentAmount}>
                  {formatTransaction(payment, 'amount')}
                </div>
              </div>
              <div className={styles.wrapperLine}>
                <div className={styles.paymentDate}>
                  { `${parseDateTo(new Date(payment.create_date), ParseToType.displayFormat)}` }
                </div>
                <div className={styles.paymentStatus}>
                  {displayStatus(payment.payment_status)}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <td>
            <div
              className={classNames(styles.wrapperTransaction,
                { [styles.wrapperTransactionCompleted]: payment.payment_status
                    === PaymentStatusEnum.Completed })}
            >
              <img
                className={styles.icon}
                src={
                    (payment.amount < 0 || isWithdrawal(payment))
                      ? iconDownArrow
                      : iconUpArrow
                    }
                alt="icon arrow"
              />
              <div className={styles.paymentType}>
                { isWithdrawal(payment) ? (
                  <p>Withdraw</p>
                ) : (
                  <p>Deposit</p>
                )}
                {displayStatus(payment.payment_status)}
              </div>
            </div>
          </td>
          <td>
            <div className={styles.date}>
              { `${parseDateTo(new Date(payment.create_date), ParseToType.displayFormat)}` }
            </div>
          </td>
          <td>
            <div className={styles.paymentAmount}>
              {formatTransaction(payment, 'amount')}
            </div>
          </td>
        </>
      )}
    </>
  );
};

export default TransactionItem;
