import React, { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'reactstrap';
import classNames from 'classnames';

import useIsMobile from '../../hooks/useIsMobile';

import styles from './BaseModal.module.scss';

type Props = {
  isOpen: boolean;
  width?: number | null;
  unmountOnClose?: boolean;
  children?: any;
  className?: string;
  customStyle?: CSSProperties | undefined;
  toggle?: () => void;
}

const BaseModal = (props: Props) => {
  const { width = 424, customStyle, isOpen, unmountOnClose = true, children, className, toggle } = props;

  const isMobile = useIsMobile();
  const modalWidthBasicStyles = !width
    ? {}
    : ({
      maxWidth: width,
      margin: 'auto',
      position: 'absolute' as any,
      left: '8px',
      right: '8px',
    });

  return (
    <Modal
      centered
      isOpen={isOpen}
      modalClassName={className}
      backdropClassName={styles.backdrop}
      contentClassName={classNames(styles.modalContent, { [styles.mobile]: isMobile, [styles.web]: !isMobile })}
      zIndex={2100}
      style={customStyle || modalWidthBasicStyles}
      unmountOnClose={unmountOnClose}
      toggle={toggle}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
