import React, { useState } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';

import lang from '../../../lib/language';

import BaseModal from '../../BaseModal/BaseModal';
import { Button } from '../../index';

import styles from './ExpiredVerificationModal.module.scss';

const ExpiredVerificationModal = () => {
  const [showModal, setShowModal] = useState<boolean>(true);

  return (
    <BaseModal isOpen={showModal}>
      <ModalHeader>{lang.commonOnboardingExpiredVerificationLinkTitle()}</ModalHeader>
      <ModalBody>
        <p className={styles.text}>{lang.commonOnboardingExpiredVerificationLinkBody()}</p>
        <Button
          fullWidth
          className={styles.button}
          onClick={() => setShowModal(false)}
        >
          {lang.commonOkay()}
        </Button>
      </ModalBody>
    </BaseModal>
  );
};

export default ExpiredVerificationModal;
