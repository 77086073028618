import React from 'react';

import lang from '../../../../../../lib/language';
import { InboxResponse } from '../../../../../../lib/models/ams/types';

import Button from '../../../../../Button/Button';
import { ButtonTypesEnum } from '../../../../../Button/Button.types';

import styles from './DeleteMessageModal.module.scss';

type Props = {
    handleKeepMessages: () => void;
    handleDeleteMessages: () => void;
    listMessages: InboxResponse[];
};

const DeleteMessageModal = (props: Props) => {
  const { handleKeepMessages, handleDeleteMessages, listMessages } = props;

  return (
    <>
      <h6 className={styles.title}>{lang.commonInboxDeleteMessagesTitle(listMessages.length)}</h6>
      <span className={styles.description}>{lang.commonInboxAreYouSureYouWantToDeleteMessageText1()}</span>
      <span className={styles.description}>{lang.commonInboxAreYouSureYouWantToDeleteMessageText2()}</span>
      <Button
        variant={ButtonTypesEnum.Tertiary}
        fullWidth
        className={styles.btnCancel}
        onClick={() => handleKeepMessages()}
      >
        {lang.commonOrderSideButtonKeepIt()}
      </Button>
      <Button fullWidth onClick={() => handleDeleteMessages()}>
        {lang.commonOrderSideButtonProceed()}
      </Button>
    </>
  );
};

export default DeleteMessageModal;
