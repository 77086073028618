import React from 'react';
import { useSelector } from 'react-redux';

import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store/index';
import { MyStocksContent, PortfolioChartElement } from '../../../lib/store/reporting/types';
import { basicColors } from '../../../lib/util/chart-colors';
import { isCallStatusPending, isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { Preloader } from '../../../components/index';
import TableContainerView from '../../../components/TableView/tableContainer/TableContainerView';
import { TABLE_HEADERS_MY_ACCOUNT_MY_STOCKS } from '../../../components/WatchList/WatchList.constans';
import { useBrowserWidth, useIsMobile } from '../../../hooks';
import { BASE_WIDTH_SYMBOL_DETAILS } from '../../../util/constants';
import ErrorContent from '../ErrorContent/ErrorContent';

import AccountBreakDownPieChart from './chart/AcountBreakDownPieChart';
import NavigationButtons from './navigation/NavigationButtons';

type Props = {
  activeTab: string;
  tableData: MyStocksContent[];
  chartData: PortfolioChartElement[];
  setSelectedElement: (chartData: PortfolioChartElement) => void;
  selectedElement: PortfolioChartElement;
  setActivePosition: (val: string) => void;
  totalValue?: number;
  hasShortAndLongPos?: boolean;
}

const MyStocksTable = ({
  activeTab,
  chartData,
  tableData,
  selectedElement,
  totalValue,
  hasShortAndLongPos,
  setSelectedElement,
  setActivePosition,
}: Props) => {
  const openPostionsPending = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusPending(ReportingCall.getOpenPositions, state.reporting.statusByCall)
  ));
  const openPostionsFetched = useSelector<RootState, boolean>((state: RootState) => (
    isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall)
  ));

  const { browserWidthSize } = useBrowserWidth();
  const isMobile = useIsMobile();

  const headerItems = TABLE_HEADERS_MY_ACCOUNT_MY_STOCKS.map((item: string) => <th key={item}>{item}</th>);

  const emptyContentManager = () => {
    if (openPostionsPending) {
      return <Preloader style={{ transform: 'scale(0.50)' }} owner={'MyStocks'} />;
    }
    if (openPostionsFetched && !!tableData.length) {
      return <ErrorContent tabTitle={lang.commonMyAccountNoStocks()} />;
    }
    return null;
  };

  return (
    <>
      { (browserWidthSize <= BASE_WIDTH_SYMBOL_DETAILS) && !isMobile && (
      <div>
        {hasShortAndLongPos
          && (
          <NavigationButtons
            activeTab={activeTab}
            handleClick={(value) => { setActivePosition(value); return false; }}
          />
          )}
        <svg width="100%" height="290" viewBox="0 0 400 290">
          <AccountBreakDownPieChart
            chartData={chartData}
            selectedChartIndex={selectedElement}
            totalStockValue={totalValue}
            setSelectedChartIndex={setSelectedElement}
          />
        </svg>
      </div>
      )}
      { tableData.length ? (
        <TableContainerView
          tableHeaders={headerItems}
          data={tableData}
          isMultipleDivOfRow
          isButtonsInRow
          companyLogos
          selectedPieChartElement={{
            symbolName: selectedElement.symbol,
            colors: basicColors,
          }}
          otherButtons={lang.commonMyAccountButtonMyStocks()}
        />
      ) : emptyContentManager()}
    </>
  );
};

export default MyStocksTable;
