import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import lang from '../../../lib/language';
import { ENROLL_METHOD, ENROLL_REQUEST_STATE, IndividualExtendedInfoData } from '../../../lib/models/enroll';
import {
  EnrollIndividualIdentityDetailRequestData,
  EnrollPostRequestBody,
  IdentityDocumentSubTypesEnum,
  IdentityDocumentSubTypesLabels,
} from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enroll } from '../../../lib/store/crm';
import { EnrollDetailsEnum, getEnrollDetailId, hasEnrollDetails } from '../../../lib/store/selectors';
import { isObjectModified } from '../../../lib/util/DataHelpers';
import { getDocumentExpiryDate } from '../../../lib/util/DateTimeHelpers';
import { getDocumentNumberValidity, validateControl } from '../../../lib/util/ValidationSchemes/Validate';

import {
  EnrollProgressBarBackQuestionAction,
  EnrollProgressBarNextSectionAction,
} from '../../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';
import { AutocompleteControl, DatepickerControl, InputControl, SlideToggleControl } from '../../index';
import { IOption } from '../../SelectControl/SelectControl.types';
import EnrollLayout from '../EnrollLayout/EnrollLayout';
import { EnrollProgressBarData } from '../ProgressBar/ProggressBar.types';

import { EnrollDocumentDetailsFormInit } from './EnrollDocumentDetails.constants';
import { EnrollDocumentDetailsForm } from './EnrollDocumentDetails.types';

import styles from './EnrollDocumentDetails.module.scss';

type Props = {
  progressData: EnrollProgressBarData;
  progressBarDispatch: Dispatch<{ type: string }>;
};

const options: IOption[] = IdentityDocumentSubTypesLabels
  .filter((option: {label: string, value: IdentityDocumentSubTypesEnum}) => (
    (option.value !== IdentityDocumentSubTypesEnum.unspecified)
  && (option.value !== IdentityDocumentSubTypesEnum.drivingLicense)
  ))
  .map((option: {label: string, value: IdentityDocumentSubTypesEnum}) => ({
    label: option.label,
    value: option.value,
  }));

const EnrollDocumentDetails = (props: Props) => {
  const { progressBarDispatch, progressData } = props;
  const dispatch = useDispatch();

  const requestState = useSelector<RootState, ENROLL_REQUEST_STATE>((state: RootState) => state.crm.enroll.status);
  const individual = useSelector<RootState, IndividualExtendedInfoData | null>((state: RootState) => (
    state.crm.individualExtendedInfo
  ));
  const hasDetails = useSelector((state: RootState) => (
    (type: EnrollDetailsEnum, customIndex?: number) => hasEnrollDetails(type, state, null, customIndex)
  ));

  const [isInit, setIsInit] = useState<boolean>(false);
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);
  const [formData, setFormData] = useState<EnrollDocumentDetailsForm>(EnrollDocumentDetailsFormInit);

  const goBack = useCallback((): void => {
    progressBarDispatch({ type: EnrollProgressBarBackQuestionAction });
  }, [progressBarDispatch]);

  const goNext = useCallback((): void => {
    const isEdit = hasDetails(EnrollDetailsEnum.DOCUMENT_DETAILS);

    const data = {
      identity_document_type: formData.documentType,
      identity_number: formData.documentNumber,
      expiration_date: !isExpiring ? formData.dateOfExpiring : undefined,
    } as EnrollIndividualIdentityDetailRequestData;

    if (isEdit) {
      data.id = getEnrollDetailId(EnrollDetailsEnum.DOCUMENT_DETAILS, null, individual);
    }

    const hasChanges = isEdit && isObjectModified(data, individual!.identity_details[0]);

    if (hasChanges || !isEdit) {
      const callMethod = isEdit
        ? ENROLL_METHOD.putIndividualIdentityDetailOwn
        : ENROLL_METHOD.postIndividualIdentityDetailOwn;

      dispatch(enroll({
        callMethod,
        callMethodName: ENROLL_METHOD[callMethod],
        requestBody: data,
      } as EnrollPostRequestBody));

      if (requestState === ENROLL_REQUEST_STATE.READY) {
        progressBarDispatch({ type: EnrollProgressBarNextSectionAction });
      }
    } else {
      progressBarDispatch({ type: EnrollProgressBarNextSectionAction });
    }
  }, [
    dispatch,
    individual,
    isExpiring,
    hasDetails,
    requestState,
    progressBarDispatch,
    formData.documentType,
    formData.dateOfExpiring,
    formData.documentNumber,
  ]);

  const validateForm = (): boolean => {
    const typeCheck = validateControl(
      formData.documentType,
      [],
      lang.commonOnboardingPersonalDetailsDocumentType(),
      true,
    );

    const numberCheck = getDocumentNumberValidity(formData.documentNumber);
    const expirationDateCheck = validateControl(
      formData.dateOfExpiring,
      [],
      lang.commonOnboardingPersonalDetailsDateOfExpiry(),
      !isExpiring,
    );

    return (typeCheck.isValid && numberCheck.isValid && expirationDateCheck.isValid);
  };

  useEffect(() => {
    if (!isInit) {
      if (individual && (individual.identity_details && (individual.identity_details.length > 0))) {
        const sortedIdentities = individual.identity_details
          .sort((
            current: EnrollIndividualIdentityDetailRequestData,
            next: EnrollIndividualIdentityDetailRequestData,
          ) => current.id! - next.id!);

        const expirationDate = sortedIdentities[0].expiration_date;
        setIsExpiring(!expirationDate);

        setFormData({
          documentType: sortedIdentities[0].identity_document_type,
          documentNumber: sortedIdentities[0].identity_number,
          dateOfExpiring: expirationDate ?? '',
        });
      }

      setIsInit(true);
    }
  }, [individual, isInit]);

  return (
    <EnrollLayout
      visibleContinueButton
      onBackButtonClicked={goBack}
      subTitle={lang.commonOnboardingPersonalDetailsIDDocumentSubtitle()}
      title={`${progressData.currentPhaseIndex}.${progressData.currentStepIndex} ${lang.commonOnboardingPersonalDetailsDocumentTitle()}`}
      onContinueClicked={() => {
        setIsClickedBtnContinue(true);

        if (validateForm()) {
          goNext();
        }
      }}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.documentTypeWrapper}>
          <AutocompleteControl
            isReadOnly
            id="enrollDocument"
            options={options}
            placeholder={lang.commonOnboardingPersonalDetailsDocumentType()}
            displayWithHighlights
            value={formData.documentType}
            onValueChanged={(value: string | number) => {
              setFormData((currentState: EnrollDocumentDetailsForm) => ({
                ...currentState,
                documentType: value as IdentityDocumentSubTypesEnum,
              }));
            }}
            errors={isClickedBtnContinue
              ? validateControl(
                formData.documentType,
                [],
                lang.commonOnboardingPersonalDetailsDocumentType(),
                true,
              ).errors
              : []}
          />
        </div>

        <InputControl
          name=""
          placeholder=""
          id="documentId"
          value={formData.documentNumber}
          ariaLabel="Document ID"
          label={lang.commonOnboardingPersonalDetailsDocumentNumber()}
          onValueChange={(value: string | number) => {
            setFormData((currentState: EnrollDocumentDetailsForm) => ({
              ...currentState,
              documentNumber: value as string,
            }));
          }}
          errors={isClickedBtnContinue ? getDocumentNumberValidity(formData.documentNumber).errors : []}
        />

        <div className={styles.dateWrapper}>
          <DatepickerControl
            idDate="dateExpiry"
            idInput="dateExpiryInput"
            idPopover="dateExpiryPopover"
            value={formData.dateOfExpiring}
            className={classNames(styles.showDatePicker, { [styles.hideDatePicker]: isExpiring })}
            label={lang.commonOnboardingPersonalDetailsDateOfExpiry()}
            maxDate={getDocumentExpiryDate.maxDocumentDateExp}
            minDate={getDocumentExpiryDate.minDocumentDateExp}
            onDateChange={(value: string | null) => {
              setFormData((currentState: EnrollDocumentDetailsForm) => ({
                ...currentState,
                dateOfExpiring: value as string,
              }));
            }}
            errors={isClickedBtnContinue
              ? validateControl(
                formData.dateOfExpiring,
                [],
                lang.commonOnboardingPersonalDetailsDateOfExpiry(),
                !isExpiring,
              ).errors
              : []}
          />

          <SlideToggleControl
            inlineLabel
            value={isExpiring}
            className={styles.toggle}
            label={lang.commonOnboardingPersonalDetailsDocumentNonExpring()}
            onToggleChange={(checked: boolean) => setIsExpiring(checked)}
          />
        </div>
      </div>
    </EnrollLayout>
  );
};

export default EnrollDocumentDetails;
