import React from 'react';
import classNames from 'classnames';

import lang from '../../lib/language';

import IconClose from '../../assets/img/icon-close-hammer.svg';
import { ClickableImage } from '../index';

import styles from './FavouriteSymbolsLimitModal.module.scss';

type Props = {
    closeMessage: () => void
    isMobile?: boolean
}

const FavouriteSymbolsLimitModal = ({ closeMessage, isMobile }: Props) => (
  <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
    <div>{lang.commonWatchlistFavoutesLimitReached()}</div>
    <ClickableImage
      id="showPrtfolioChart"
      alt="portfolio pie chart"
      src={IconClose}
      className={classNames(styles.iconClose, { [styles.mobileViewIcon]: isMobile })}
      onClick={closeMessage}
    />
  </div>
);

export default FavouriteSymbolsLimitModal;
