import numeral from 'numeral';

const timestamps = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const chartData = {
  labels: timestamps,
  datasets: [
    {
      data: [0, 5000, 10000, 7500, 9500, 15000, 6000, 25000, 8000, 9000, 20000, 15000],
      backgroundColor: '#F2EEFB',
      borderColor: '#916FDD',
      pointHoverBackgroundColor: '#916FDD',
      pointBackgroundColor: '#916FDD',
      borderWidth: 1,
      pointRadius: 2,
      fill: true,
      pointHoverBorderColor: ['rgba(0, 9, 132, 0.2)'],
    },
  ],
};

export const chartOptions = {
  responsive: true,
  spanGaps: true,

  lineHeightAnnotation: {
    always: false,
    hover: true,
    noDash: true,
    color: 'grey',
  },
  hover: {
    mode: 'point',
    onHover: (event: any, chartElement: any) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
  },
  tooltips: {
    mode: 'point',
    callbacks: {
      label(tooltipItem: any) {
        return numeral(tooltipItem.value).format('($0,0)').replace(',', ' ');
      },
    },
  },
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          show: true,
          labelString: 'Month',
        },
        gridLines: { display: false },
        ticks: {
          labelOffset: -12,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          drawBorder: false,
        },
        scaleLabel: {
          show: true,
        },
        ticks: {
          display: true,
          beginAtZero: true,
          callback(value: number, index: number, array: number[]) {
            return `${numeral(value).format('($0,0)').replace(',', ' ')}    `;
          },
        },
      },
    ],
  },
};
