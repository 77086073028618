import React, { useEffect, useState } from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';

import { TopGainersAndLosersPriceOption } from '../../../../lib/models/fundamental/types';

import iconExpandUp from '../../../../assets/img/icon-expand-more_24px.svg';
import iconFilter from '../../../../assets/img/icon-filter_24px.svg';
import { BaseModal, Button, ClickableDiv } from '../../../../components';
import { useBrowserWidth, useIsMobile } from '../../../../hooks';
import { ZOOM_BEHAVIOR_WIDTH } from '../../../../util/constants';

import { FILTER_RANGE_OPTIONS } from './FilterDropDownOptions';

import styles from '../../TopGainersAndLosers.module.scss';

const useStyles = makeStyles(() => ({
  select: {
    boxShadow: 'none',
    borderRadius: '0px 6px 6px 0px',
    padding: '7px 0px',
    fontSize: '16px',
    fontFamily: 'Source Sans Pro',
    backgroundColor: '#1D3960',

    '&, &:before, &:after': {
      color: '#3BB0FF',
      borderRadius: '0px 6px 6px 0px',
    },

    '&, &:active, &:focus': {
      background: (hasBackground) => {
        if (hasBackground) {
          return '#1D3960';
        }
        return 'none';
      },
      borderRadius: '0px 6px 6px 0px',
    },
  },

  paper: {
    border: '1px solid #3BB0FF',
    background: '#1D3960',
  },

  wrapper: {
    display: 'flex',
  },

  iconWrapper: {
    background: (hasBackground) => {
      if (hasBackground) {
        return '#1D3960';
      }
      return 'none';
    },

    borderRadius: '6px 0px 0px 6px',
    display: 'inline-block',
    height: '33px',
  },

  icon: {
    marginTop: '4px',
    margin: '0px 6px 0px 12px',
  },

  option: {
    height: '56px',
    color: '#fff',

    '&:first-child': {
      marginTop: '-8px',
    },

    '&:last-child': {
      marginBottom: '-8px',
    },

    '&:hover': {
      opacity: 1,
    },
  },

  selected: {
    color: '#CBD5E0',
    opacity: 1,
    fontFamily: 'Cabin',
    fontSize: '16px',
  },
}));

type Props = {
    handleFilterOption: (option: number[], optionValue: string) => void;
    hasBackground: boolean;
    hasExpandArrow: boolean;
    optionValue?: string;
    currentTabTopMovers?: number;
}

const FilterDropDown = ({
  handleFilterOption,
  hasBackground,
  hasExpandArrow,
  optionValue,
  currentTabTopMovers,
}: Props) => {
  const classes = useStyles(hasBackground);
  const { browserWidthSize } = useBrowserWidth();
  const isMobile = useIsMobile();
  const [value, setValue] = useState<string>(optionValue || TopGainersAndLosersPriceOption.fifteenToFifty);
  const [paperDropDown, setPaperDropDown] = useState<number>(90);
  const [isFilterModal, setFilterModal] = useState<boolean>(false);

  useEffect(() => {
    !optionValue && setValue(TopGainersAndLosersPriceOption.fifteenToFifty);
  }, [currentTabTopMovers, optionValue]);

  const handleChange = (event) => {
    const indexOption = Object.values(TopGainersAndLosersPriceOption).indexOf(event.target.value);
    handleFilterOption(FILTER_RANGE_OPTIONS[indexOption], event.target.value);
    setValue(event.target.value);
  };

  useEffect(() => {
    setPaperDropDown(90 - Math.floor(window.innerWidth / 10));
  }, [browserWidthSize]);

  return (
    <div className={classes.wrapper}>
      <div className={isMobile ? styles.iconWrapperMobile : classes.iconWrapper}>
        <img className={isMobile ? '' : classes.icon} src={iconFilter} alt="icon filter" />
      </div>
      {isMobile ? (
        <>
          <ClickableDiv className={styles.mobFilterButton} onClick={() => setFilterModal(true)}>
            <span className={styles.mobFilterButtonText}>{value}</span>
          </ClickableDiv>
          <BaseModal
            customStyle={{
              paddingBottom: 0,
              margin: 0,
              width: window.innerWidth,
              marginTop: window.innerHeight / 2.3,
              position: 'fixed',
              alignSelf: 'flex-end',
            }}
            className={styles.filterModalWrapper}
            isOpen={isFilterModal}
            toggle={() => setFilterModal(false)}
          >
            <div className={styles.rangeButtonWrapper}>
              {
              Object.values(TopGainersAndLosersPriceOption).map((option) => (
                <Button
                  className={styles.rangeButton}
                  key={option}
                  onClick={() => {
                    const indexOption = Object.values(TopGainersAndLosersPriceOption).indexOf(option);
                    handleFilterOption(FILTER_RANGE_OPTIONS[indexOption], option);
                    setValue(option);
                    setFilterModal(false);
                  }}
                >
                  <span className={styles.rangeButtonText}>{option}</span>
                </Button>
              ))
            }
            </div>
          </BaseModal>
        </>
      ) : (
        <FormControl>
          <Select
            value={value}
            disableUnderline
            displayEmpty
            onChange={handleChange}
            classes={{ select: classes.select }}
            IconComponent={(props) =>
              (hasExpandArrow
                ? <div {...props}><img src={iconExpandUp} alt="icon filter" /></div>
                : <></>)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              PaperProps: {
                style: isMobile
                  ? {}
                  : {
                    width: 170,
                    marginLeft: (browserWidthSize <= ZOOM_BEHAVIOR_WIDTH && !hasBackground) ? `${paperDropDown + 5}%` : -42,
                    zoom: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? `${Math.floor(browserWidthSize / 10).toString()}%` : '',
                  },
              },
              classes: {
                paper: classes.paper,
              },
              getContentAnchorEl: null,
            }}
          >
            {
            Object.values(TopGainersAndLosersPriceOption).map((option) => (
              <MenuItem
                key={option}
                value={option}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                <span>{option}</span>
              </MenuItem>
            ))
          }
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default FilterDropDown;
