import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { Action } from 'redux';

import { UTC_MOMENT_FORMAT } from '../../../lib/constants/date-time.constants';
import { DEFAULT_DATERANGEPICKER_FORMAT } from '../../../lib/constants/datePickerConstants';
import lang from '../../../lib/language';
import { Earning } from '../../../lib/models/fundamental/types';
import { RootState } from '../../../lib/store';
import {
  getCompanyLogoAndName as getLogoAndName,
  searchEarnings as searchEarningsData,
} from '../../../lib/store/fundamental/index';
import { FundamentalCall, SearchEarningsPayload } from '../../../lib/store/fundamental/types';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { earningsCalendarInitialDate } from '../../../lib/util/DateTimeHelpers';
import { isCallStatusError, isCallStatusPending } from '../../../lib/util/error-handling/StatusByCallHelpers';
import { INVALID_VALUE } from '../../../lib/util/types';

import noContentImage from '../../../assets/img/image_empty.png';
import { BaseTable, Button, CompanyLogo, Preloader } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import DateRangePickerWrapper from '../../../components/DateRangePickerWrapper/DateRangePickerWrapper';
import MobileFromToDatePicker from '../../../components/MobileFromToDatePicker/MobileFromToDatePicker';
import { TABLE_HEADERS_EARNINGS_CALENDARS } from '../../../components/WatchList/WatchList.constans';
import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import { DateRangeState } from '../../../util/types';
import {
  DEFAULT_EARNINGS_CONTENT_LENGTH,
  EARNINGS_CONTENT_PER_PAGE_LENGTH,
  EARNINGS_CONTENT_UNLIMIT_LENGTH,
} from '../EarningCalendat.constants';

import styles from './Table.module.scss';

const formatDate = (date): string => moment(date).format(UTC_MOMENT_FORMAT);

type OwnProps = {
  maxElements: number;
  minDateState?: string;
  maxDateState?: string;
  onStateChange?: (value: DateRangeState) => void
}
type Props = OwnProps & ReduxProps;

const Table = (props: Props) => {
  const {
    earnings,
    maxElements,
    minDateState,
    maxDateState,
    onStateChange,
    isGettingEarning,
    companyLogoAndName,
    isGettingCompanyLogoAndName,
    searchEarnings,
  } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState<boolean>(false);
  const [range, setRange] = useState<DateRangeState>({ startDate: minDateState, endDate: maxDateState, key: 'selection' });
  const isSameDateSelected = range.startDate && range.startDate === range.endDate;

  const { content } = earnings;

  useEffect(() => {
    if (
      (!content.length && (!range.startDate || !range.endDate))
      || (content.length && maxElements === DEFAULT_EARNINGS_CONTENT_LENGTH)) {
      searchEarnings({
        startDate: formatDate(earningsCalendarInitialDate()),
        endDate: formatDate(earningsCalendarInitialDate()),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (range.startDate && range.endDate) {
      setIsLoadMoreClicked(false);
      searchEarnings({ endDate: formatDate(range.endDate), startDate: formatDate(range.startDate) });
      setPage(1);
    }
  }, [range, searchEarnings]);

  const tableHeaders = TABLE_HEADERS_EARNINGS_CALENDARS.map((item: string) => <th key={item}>{item}</th>);

  const tableItems = content.slice(0, maxElements).map((value: Earning) => {
    const logo = companyLogoAndName[value.symbol]?.logo;
    const name = companyLogoAndName[value.symbol]?.name;

    return (
      <>
        <tr key={`${value.time} ${value.symbol}`} onClick={() => history.push(`${UI_ROUTES.symbol}/${value.symbol}`, { range })}>
          {isMobile ? (
            <div className={styles.wrapperCompany}>
              <div className={styles.logoWrapper}>
                {logo && <CompanyLogo logo={logo} symbol={value.symbol} />}
              </div>
              <div className={styles.companyInfo}>
                <div className={styles.companySymbolAndPrice}>
                  <p>{value.symbol}</p>
                  <p>{value.epsEst ? formatCurrency(value.epsEst) : INVALID_VALUE}</p>
                </div>
                <div className={styles.companyDetails}>
                  <p className={styles.displayTime}>
                    {name && name.length > 20 ? `${name.slice(0, 20)}...` : name }
                  </p>
                  <p className={styles.displayTime}>{value.displayTime}</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <td>
                <div className={styles.wrapperCompany}>
                  {logo && <CompanyLogo logo={logo} symbol={value.symbol} />}
                  <div className={styles.companyInfo}>
                    <p className={styles.symbol}>{value.symbol}</p>
                    <p className={styles.symbol}>{name}</p>
                  </div>
                </div>
              </td>
              <td>{moment(value.date).format(DEFAULT_DATERANGEPICKER_FORMAT)}</td>
              <td>{value.displayTime}</td>
              <td>{value.epsPrior ? formatCurrency(value.epsPrior) : INVALID_VALUE}</td>
              <td>{value.epsEst ? formatCurrency(value.epsEst) : INVALID_VALUE}</td>
            </>
          )}
        </tr>
      </>
    );
  });

  const handleDateChange = (value) => {
    setRange(value);
    onStateChange && onStateChange(value);
  };

  useEffect(() => {
    if (content.length > page * 15 && !isGettingCompanyLogoAndName && !isGettingEarning) {
      setLoading(false);
      setPage(prevState => prevState + 1);
    }
  }, [content.length, isGettingCompanyLogoAndName, isGettingEarning, page]);

  useEffect(() => {
    if (content.length >= page * EARNINGS_CONTENT_PER_PAGE_LENGTH
      && maxElements === EARNINGS_CONTENT_UNLIMIT_LENGTH
      && content.length < earnings.totalElements) {
      setCanLoadMore(true);
    } else {
      setCanLoadMore(false);
    }
  }, [content.length, earnings.totalElements, maxElements, page]);

  const handleButtonLoadMore = () => {
    searchEarnings({ endDate: formatDate(range.endDate), startDate: formatDate(range.startDate), page });
    setLoading(true);
    setIsLoadMoreClicked(true);
  };

  const renderLoadMore = (): JSX.Element => {
    if (canLoadMore) {
      return (
        <>
          {loading ? <Preloader style={{ transform: 'scale(0.5)' }} owner="LatestNews" /> : (
            <Button className={styles.btnLoadMore} variant={ButtonTypesEnum.Link} onClick={handleButtonLoadMore}>
              {lang.commonLoadMore()}
            </Button>
          )}
        </>
      );
    } return <></>;
  };

  const renderTable = () => (content.length ? (
    <>
      <BaseTable>
        {!isMobile && (
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
        )}
        <tbody>{tableItems}</tbody>
      </BaseTable>
      {renderLoadMore()}
    </>
  ) : (
    <div className={styles.noContentWrapper}>
      <img src={noContentImage} alt="no messages logo" />
      <p>{lang.commonTradeHistoryNothingHere()}</p>
    </div>
  ));
  const location = useLocation();
  const isDiscoverScreen = UI_ROUTES.discover === location.pathname;

  return (
    <>
      <div className={styles.wrapperDateRangerPicker}>
        { isMobile && (
        <div
          className={
          classNames(
            !isDiscoverScreen ? styles.datePickerWrapperMob : undefined,
            styles.datePickerWrapperWidth,
          )
        }
        >
          {!isDiscoverScreen && (
          <div className={styles.lengthEventsMobile}>
            <span>{earnings.totalElements}</span>
            <span>{lang.commonDiscoverCalenderItems()}</span>
          </div>
          )}
          <div className={styles.epsEstAndDate}>
            <MobileFromToDatePicker
              label={lang.commonDiscoverCalenderEventsToday()}
              initialState={{
                startDate: minDateState ? new Date(minDateState) : earningsCalendarInitialDate(),
                endDate: maxDateState ? new Date(maxDateState) : earningsCalendarInitialDate(),
              }}
              minDateState={minDateState}
              minDateCalendar={earningsCalendarInitialDate()}
              maxDateState={maxDateState}
              onDateSelect={handleDateChange}
            />
            <div className={styles.epsEst}>
              {isSameDateSelected
                ? lang.headerEarningsCalendarEarningsEPSEstimate()
                : lang.headerEarningsCalendarEarningsEPSEst()}
            </div>
          </div>
        </div>
        )}
        { !isMobile && (
          <>
            <DateRangePickerWrapper
              initialState={{
                startDate: minDateState ? new Date(minDateState) : earningsCalendarInitialDate(),
                endDate: maxDateState ? new Date(maxDateState) : earningsCalendarInitialDate(),
              }}
              minDateState={minDateState}
              minDateCalendar={earningsCalendarInitialDate()}
              maxDateState={maxDateState}
              onDateSelect={handleDateChange}
            />
            <div className={styles.lengthEvents}>
              <span>{earnings.totalElements}</span>
              <span>{lang.commonDiscoverCalenderItems()}</span>
            </div>
          </>
        )}
      </div>
      {(!content.length && isGettingEarning) || (isGettingCompanyLogoAndName && !isLoadMoreClicked)
        ? <Preloader /> : renderTable()}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  earnings: state.fundamental.earningsSearchResponse,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  isGettingEarning: isCallStatusPending(FundamentalCall.searchEarnings, state.fundamental.statusByCall),
  didGettingEarningsFailed: isCallStatusError(FundamentalCall.searchEarnings, state.fundamental.statusByCall),
  isGettingCompanyLogoAndName: isCallStatusPending(
    FundamentalCall.getCompanyLogoAndName, state.fundamental.statusByCall,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  searchEarnings: (payload: SearchEarningsPayload) => dispatch(searchEarningsData(payload)),
  getCompanyLogoAndName: (symbols: string) => dispatch(getLogoAndName(symbols)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Table);
