import { produce } from 'immer';
import { entity, persistence, Plugin } from 'simpler-state';

import configLib from '../../configLib';
import entityReduxLogger from '../debug/helpers/entity-redux-logger';

/**
 * Used for storing `NewOrderRequest` body to SOR (sent through Gateway)
 * For more details see `Global.service@order()`.
 *
 * Only two places where this data can be modified:
 *   (1) `Global.service@order()`
 *   (2) The `clearErrorsLog` reducer in gateway slice
 */
const plugins: Plugin[] = entityReduxLogger('OrderInfo', 'order-info') ?? [];
const options = {
  storage: configLib.storage as any,
  serializeFn: JSON.stringify,
  deserializeFn: JSON.parse,
};
plugins.unshift(persistence('entityOrderInfo', options));
const OrderInfoCache = entity([] as Record<string, any>[], plugins);

export default {
  /** Get an item by index */
  get: (index: number) => OrderInfoCache.get()[index],
  /** Add a new item */
  push: (data: any) => { OrderInfoCache.set(produce(state => { state.push(data); })); },
  /** Get items count available in cache */
  getLength: () => OrderInfoCache.get().length,
  /** Empties the cache */
  deleteAll: () => { OrderInfoCache.set(produce(state => { state.splice(0, state.length); })); },
};
