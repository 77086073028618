import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { DynamicFilterBuilder } from '../../lib/services/filter/dynamic-filter-builder';
import { DEFAULT_SEARCH_STOCK_LIMIT } from '../../lib/store/fundamental/constants';
import { filterButtons, getListEmptyMessage } from '../../lib/store/fundamental/helpers';
import { searchStockByParams } from '../../lib/store/fundamental/index';
import { RootState } from '../../lib/store/index';

import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import { ClickableImage } from '../index';
import FilterButton from '../StocksResult/componets/FilterButton';
import StocksResult from '../StocksResult/StocksResult';

import SearchMob from './Search/SearchMob';

import styles from './SearchSymbolsMob.module.scss';

const SearchSymbolsMob = () => {
  const [searchedText, setSearchedText] = useState<string>('');
  const [selectedFilter, setSelctedFilter] = useState<string>(filterButtons[0]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [searchLimit, setSearchLimit] = useState<number>(DEFAULT_SEARCH_STOCK_LIMIT);

  const query = useSelector<RootState, string>((state: RootState) => state.fundamental.stockQuery);

  const dispatch = useDispatch();
  const history = useHistory();
  const params: DynamicFilterBuilder = new DynamicFilterBuilder();

  const prepareFilterBySelectedTab = (val: string): void => {
    if (val === filterButtons[1]) {
      params.equal('issueType', 'cs'); // stocks
      return;
    }
    if (val === filterButtons[2]) {
      params.equal('issueType', 'et'); // ETFs
      return;
    }
    params.equal('issueType', null); // All
  };

  useEffect(() => {
    prepareFilterBySelectedTab(selectedFilter);
    dispatch(searchStockByParams({
      query: searchedText,
      limit: DEFAULT_SEARCH_STOCK_LIMIT,
      params: params.build(),
    }));
    setCurrentPageNumber(1);
    setSearchLimit(DEFAULT_SEARCH_STOCK_LIMIT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedText]);

  const handleTabChange = (val: MouseEvent) => {
    const tabText = (val.target as HTMLDivElement).innerHTML;
    setSelctedFilter(tabText);
    prepareFilterBySelectedTab(tabText);

    // TODO: In ART-4133 add condition for request when backend returns to us symbols or company names wich starts with the searched value
    dispatch(searchStockByParams({
      query,
      limit: DEFAULT_SEARCH_STOCK_LIMIT,
      params: params.build(),
    }));
    setCurrentPageNumber(1);
    setSearchLimit(DEFAULT_SEARCH_STOCK_LIMIT);
  };

  const handleLoadMore = (): void => {
    const limit = searchLimit + DEFAULT_SEARCH_STOCK_LIMIT;
    dispatch(searchStockByParams({
      query,
      limit,
      params: params.build(),
    }));

    setSearchLimit(limit);
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const onClickBack = () => {
    history.goBack();
  };

  return (
    <div id="SearchSymbolsMobile" className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <ClickableImage className={styles.arrowBack} src={ArrowBack} onClick={onClickBack} />
        <SearchMob searchStocks={val => setSearchedText(val)} />
      </div>
      <div>
        <div className={styles.buttonsWapper}>
          {filterButtons.map((el) => (
            <FilterButton key={el} isActive={selectedFilter === el} name={el} onClick={handleTabChange} />
          ))}
        </div>
        <StocksResult
          currentPageNumber={currentPageNumber}
          noSymbolsFoundMessage={getListEmptyMessage(selectedFilter)}
          handleLoadMore={() => handleLoadMore()}
          isMob
        />
      </div>
    </div>
  );
};

export default SearchSymbolsMob;
