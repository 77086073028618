import React from 'react';

import lang from '../../lib/language';
import { useMarketTimer } from '../../lib/util/hooks/MarketTimerHook';

import BaseModal from '../BaseModal/BaseModal';
import { Button } from '../index';

import styles from './RegularTradingHoursStartModal.module.scss';

type Props = {
  toggleModal: () => void;
}

const RegularTradingHoursStartModal = ({ toggleModal }: Props) => {
  const { timerValueAsString, isEnabled } = useMarketTimer('short-sell');
  if (!isEnabled) return null;

  return (
    <BaseModal isOpen>
      <h6 className={styles.title}>{lang.commonRegularTradingHoursStartDialogTitle()}</h6>
      <div className={styles.description}>
        <p>
          {lang.commonRegularTradingHoursStartDialogDescription()}
          <span className={styles.marketTimer}>{timerValueAsString}</span>
        </p>
      </div>
      <Button
        fullWidth
        className={styles.button}
        onClick={toggleModal}
      >
        {lang.commonOkay()}
      </Button>
    </BaseModal>
  );
};

export default RegularTradingHoursStartModal;
