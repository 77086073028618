import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../lib/store';

import styles from './UserDetails.module.scss';

type Props = { isSecondary?: boolean } & ReduxProps;

const UserDetails = ({ individual, isSecondary = false }: Props) => {
  const transformName = () => {
    if (individual && individual.first_name?.length + individual.last_name?.length > 100) {
      return ([individual?.first_name[0], '. ', individual?.last_name].join(''));
    }
    return ([individual?.first_name, individual?.last_name].join(' '));
  };

  return (
    <div id="userDetails" aria-label="user details" className={[styles.user, !isSecondary ? '' : styles.secondary].join(' ')}>
      <p id="userDetailsName" aria-label="user details name" className={styles.name}>{transformName()}</p>
      <p id="userDetailsEmail" aria-label="user details email" className={styles.email}>{individual?.email}</p>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  individual: state.crm.individualExtendedInfo,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserDetails);
