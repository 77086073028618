import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';

import noMessagesLogo from '../../../assets/img/image_empty.png';
import { Button } from '../../../components';
import { ButtonTypesEnum } from '../../../components/Button/Button.types';
import { UI_ROUTES } from '../../../util/routes';

import styles from './ErrorContent.module.scss';

type Props = {
  tabTitle: string;
}

const ErrorContent = ({ tabTitle }: Props) => {
  const history = useHistory();

  return (
    <div className={styles.wrapperNoStocks}>
      <img src={noMessagesLogo} alt="no messages logo" />
      <p className={styles.title}>{tabTitle}</p>
      <p>{lang.commonMyAccountNoStocksMessage1()}</p>
      <div className={styles.wrapperBtnDiscover}>
        <Button variant={ButtonTypesEnum.Link} onClick={() => history.push(UI_ROUTES.discover)}>
          {lang.commonDiscoverTitle()}
        </Button>
        <p>{lang.commonMyAccountNoStocksMessage2().replace(lang.commonDiscoverTitle().toLowerCase(), '')}</p>
      </div>
    </div>
  );
};

export default ErrorContent;
