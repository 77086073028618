import React, { useEffect, useRef, useState } from 'react';

import { PredefinedMilisecondsTypeEnum } from '../../lib/enums/predefined-miliseconds-type.enum';

import { useIsMobile } from '../../hooks';

import { options } from './LoginCarousel.constants';

import styles from './LoginCarousel.module.scss';

const LoginCarousel = () => {
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isAutomaticScrolling, setIsAutomaticScrolling] = useState<boolean>(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const renderDots = () => options.map((option, index) => (
    <div
      key={option.title}
      className={index === activeTab ? styles.activeDot : styles.inactiveDot}
    />
  ));

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % options.length);
    }, PredefinedMilisecondsTypeEnum.fiveSeconds);
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && isAutomaticScrolling) {
      const maxScrollLeft = contentRef.current ? contentRef.current.scrollWidth - contentRef.current.clientWidth : 0;
      if (activeTab === 0) {
        contentRef.current.scrollTo({ left: 0, behavior: 'smooth' });
      }
      if (activeTab === 1) {
        contentRef.current.scrollTo({ left: maxScrollLeft / 2, behavior: 'smooth' });
      }
      if (activeTab === 2) {
        contentRef.current.scrollTo({ left: maxScrollLeft, behavior: 'smooth' });
      }
    }
  }, [activeTab, isAutomaticScrolling]);

  const stopSliding = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      setIsAutomaticScrolling(false);
    }
  };

  const onTouchEnd = () => {
    const currentLeft = contentRef.current?.scrollLeft;
    const maxScrollLeft = contentRef.current ? contentRef.current.scrollWidth - contentRef.current.clientWidth : 0;
    if (currentLeft && contentRef.current) {
      if (currentLeft <= maxScrollLeft / 3) {
        contentRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        setActiveTab(0);
      }
      if (currentLeft >= maxScrollLeft / 3 && currentLeft <= maxScrollLeft / 3 * 2) {
        contentRef.current.scrollTo({ left: maxScrollLeft / 2, behavior: 'smooth' });
        setActiveTab(1);
      }
      if (currentLeft >= maxScrollLeft / 3 * 2) {
        contentRef.current.scrollTo({ left: maxScrollLeft, behavior: 'smooth' });
        setActiveTab(2);
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <div
          className={styles.container}
          onTouchStart={stopSliding}
          onTouchEnd={onTouchEnd}
        >
          <div className={styles.content} ref={contentRef}>
            {options.map((option) => (
              <div key={option.title}>
                <img className={styles.icon} src={option.icon} alt="Hammer Login" />
                <div className={styles.title}>{option.title}</div>
                <div className={styles.description}>{option.description}</div>
              </div>
            ))}
          </div>
          <div className={styles.dotWrapper}>
            {renderDots()}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <img className={styles.icon} src={options[activeTab].icon} alt="Hammer Login" />
          <div className={styles.title}>{options[activeTab].title}</div>
          <div className={styles.description}>{options[activeTab].description}</div>
          <div className={styles.dotWrapper}>
            {renderDots()}
          </div>
        </div>
      )}
    </>
  );
};

export default LoginCarousel;
