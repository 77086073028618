import { clone, random } from 'lodash';
import { Dispatch } from 'redux';

import { logConfig, MOCK } from '../../../configDebug';
import configLib from '../../../configLib';
import { OrderStatusEnum } from '../../enums';
import { THROTTLE_OPTIONS, THROTTLE_TIME_PRICE_UPDATES_MS } from '../../libSettings';
import { BBOData, TradeData } from '../../models/market-data/types';
import { Balance, Execution, Order, Position, RawBalanceData } from '../../models/trading/types';
import { BBODataCache, InstrumentSnapshotMap, TradeDataCache } from '../../store/market-data/types';
import { balancesReceived, ordersReceived, positionsReceived, setExecutions } from '../../store/trading';
import { updateNBBOPricesCache } from '../../store-util/NBBOPriceCache';
import { updateTradePriceCacheFromTrade } from '../../store-util/TradePriceCache';
import { isValidCurrencyValue, parseBalance } from '../../util/DataHelpers';
import { getDateTime } from '../../util/DateTimeHelpers';
import { parseGatewayData } from '../../util/GatewayHelpers';
import { dataArrayToMap } from '../../util/MarketDataHelpers';
import { NullableString, TypedObject } from '../../util/types';
import { ThrottleFuncSetup, throttleFunctionSetupForSymbolDataUpdates } from '../helpers';

import { calculateOMSState, generateOfflineResponse, OMSState } from './offline-helpers';


const MIN_PRICE = 0.02;
const MAX_PRICE = 9289;

const {
  InProgress, Submitted, Accepted, Rejected, Filled, Cancelled, TimedOut,
} = OrderStatusEnum;
const ORDER_HISTORY_FILTER = [ Rejected, Filled, Cancelled, TimedOut ];

let throttleFunctionSetupTrade: ThrottleFuncSetup | null = null;
let throttleFunctionSetupNbbo: ThrottleFuncSetup | null = null;


const calcRandPrice = (base: number, alt: number, label: string) => {
  let theBase = isValidCurrencyValue(base) ? base : alt;
  let res = 0;
  let rand = 0;
  if (MOCK.TRADING_MODE === 'up') {
    rand = random(theBase * 0.03, theBase * 0.07);
    res = theBase + rand;
  } else if (MOCK.TRADING_MODE === 'down') {
    rand = random(theBase * 0.02, theBase * 0.0845);
    res = theBase - rand;
  } else {
    rand = random(-theBase * 0.067243, theBase * 0.0845);
    res = theBase + rand;
  }
  // restart price updates on reaching min or max values
  if (res < MIN_PRICE) res = random(12.8923, 7939.78);
  if (res > MAX_PRICE) res = random(32.3415, 6939.37);

  return res;
};
const ORDER_CYCLE = [ InProgress, Submitted, Accepted ];
class MockedOMSAndMDS {
  private dispatch: Dispatch;
  private urpnl: number = 0;
  private lastPrices: TypedObject<{trade: number, nbbo: { ask: number, bid: number }}> = {};
  private subscriptions: string[] = [];
  private tradedSymbols: string[] = [];
  private mdTickId?: any;
  private omsTickId?: any;
  private orders: Order[] = [];
  private positions: Position[] = [];
  private balances: Balance[] = (
    clone(require('../../__offline-data__/trading/balances.json').balances as RawBalanceData[])
      .map(b => parseBalance(clone(b)))
  );

  private mdsTickNo = -1;
  private omsTickNo = -1;

  private omsRefreshRate = [ 1400, 5700 ];
  private mdsRefreshRate = [ 2400, 4200 ];

  constructor(dispatch: any) {
    this.dispatch = dispatch;
    if (MOCK.TRADING_OMS_REFRESH_RATE) {
      this.omsRefreshRate = [
        0.9 * MOCK.TRADING_OMS_REFRESH_RATE * 1000,
        1.1 * MOCK.TRADING_OMS_REFRESH_RATE * 1000,
      ];
    }
    if (MOCK.TRADING_MDS_REFRESH_RATE) {
      this.mdsRefreshRate = [
        0.9 * MOCK.TRADING_MDS_REFRESH_RATE * 1000,
        1.1 * MOCK.TRADING_MDS_REFRESH_RATE * 1000,
      ];
    }

    console.info(`[MockedOMSAndMDS] Created.
      Refresh Rates
        OMS: ${this.omsRefreshRate}
        MDS: ${this.mdsRefreshRate}
      Force Fills: ${!!MOCK.TRADING_FORCE_FILL}
      Predefined Trading Sequence: ${(MOCK.TRADING_MDS_TRADE_SEQUENCE || []).length}
      Trading Mode: ${MOCK.TRADING_MODE ?? 'default (random)'}
    `);
  }

  update(symbolFilter?: NullableString): Position | null | undefined {
    const results: TypedObject<OMSState | null> = {};
    let balance = this.balances[0];

    balance.rpnl = 0;
    balance.ordExp = 0;
    balance.posExp = 0;
    balance.availBp = 124547;
    balance.usedBp = 0;
    this.urpnl = 0;
    this.positions = [];

    this.tradedSymbols.forEach(symbol => {
      const { trade, nbbo: { ask, bid } } = this.lastPrices[symbol!];
      const state = calculateOMSState(symbol, this.orders, bid, ask, trade);
      results[symbol] = state;
      const {
        position, urpnl, ordExp,
      } = state ?? {};
      if (position) this.positions.push(position);
      if (state) {
        balance.availBp -= ordExp!;
        this.urpnl += urpnl!;
      }
    });

    this.dispatch(ordersReceived({
      orders: (
        this.getOrders().orders.map(item => ({
          ...item,
          orderQty: `${item.orderQty}`,
          price: `${item.price}`,
          stopPrice: `${item.stopPrice}`,
        }))
      ),
      rc: 100,
    }));

    this.dispatch(positionsReceived({
      positions: (
        this.getPositions().positions.map(item => ({
          ...item,
          qty: `${item.qty}`,
          avgPrice: `${item.avgPrice}`,
        }))
      ),
      rc: 100,
    }));
    this.dispatch(setExecutions(this.getExecutions()));
    // this.dispatch(balancesReceived({
    //   balances: this.balances.map(({availBp, totalBp, usedBp, posExp, ordExp}) => ({
    //     availBp: `${availBp}`,
    //     totalBp: `${totalBp}`,
    //     usedBp: `${usedBp}`,
    //     posExp: `${posExp}`,
    //     ordExp: `${ordersReceived}`,
    //   })),
    this.dispatch(balancesReceived({ balances: [ balance, this.balances[1] ], rc: 100 }));

    return symbolFilter ? results[symbolFilter!]?.position : null;
  }

  neworder(order: Order) {
    this.orders.push(order);
    this.update();
    if (!this.tradedSymbols.includes(order.symbol!)) this.tradedSymbols.push(order.symbol!);
  }

  getOrders = () => ({ orders: clone(this.orders), rc: 100, callName: 'getOrders' });
  getOrderHistory = () => ({ orders: clone(this.orders.filter(({ status }) => ORDER_HISTORY_FILTER.includes(status as any))), rc: 100, callName: 'getOrderHistory' });
  getPositions = () => ({ positions: this.positions, rc: 100, callName: 'getPositions' });
  getBalances = () => ({ balances: this.balances, rc: 100, callName: 'getBalances' });
  getExecutions = () => {
    let executions: Execution[] = [];
    this.orders.forEach(order => {
      if (order.fills) {
        order.fills.forEach(fill => {
          executions.push({
            ...fill,
            parentOrder: order,
          });
        });
      } else if (order.status === OrderStatusEnum.Filled) {
        executions.push({
          execId: `${random(0.78, 0.99)}`,
          fillPrice: order.stopPrice ?? order.price!,
          fillQty: order.orderQty!,
          timeStamp: order.updatedAt!,
          parentOrder: order,
        });
      }
    });

    return executions;
  };
  getURPNL = () => this.urpnl;

  tickMDS() {
    if (this.mdTickId !== -1) {
      clearTimeout(this.mdTickId);
      this.mdTickId = -1;
    }

    if ((this.subscriptions?.length ?? 0) > 0) {
      this.mdsTickNo++;
    }

    let nbbos: BBOData[] = [];
    let trades: TradeData[] = [];
    this
      .subscriptions
      .forEach(symbol => {
        const tickData: any = (MOCK.TRADING_MDS_TRADE_SEQUENCE || [])[this.mdsTickNo];
        let nbbo: BBOData = (clone(parseGatewayData(generateOfflineResponse('gateway/nbbo'))!) as BBOData[])[0];
        let trade: TradeData = (clone(parseGatewayData(generateOfflineResponse('gateway/trade'))!) as TradeData[])[0];
        const last = this.lastPrices[symbol] ?? { trade: null, nbbo: { ask: null, bid: null } };
        const lastPrice = last.trade;
        const { ask: lastAsk, bid: lastBid } = last.nbbo;

        let newPrice = 0;
        let newAsk = 0;
        let newBid = 0;

        if (MOCK.TRADING_MDS_TRADE_SEQUENCE && tickData) {
          // trading mode - static sequence
          newPrice = tickData.T;
          newAsk = tickData.A;
          newBid = tickData.B;
        } else {
          // trading mode - up, down or random
          newPrice = calcRandPrice(lastPrice, random(15.38, 5083.89), 'trade');
          newAsk = calcRandPrice(lastAsk, newPrice * 1.02, 'ask');
          newBid = calcRandPrice(lastBid, newPrice * 0.9739, 'bid');
        }

        this.lastPrices[symbol] = {
          nbbo: { ask: newAsk, bid: newBid },
          trade: newPrice,
        };

        const newTrade = newPrice;
        nbbo.sym = symbol;
        nbbo.symbolonly = symbol;
        trade.sym = symbol;
        trade.symbolonly = symbol;
        nbbo.askprice = newAsk;
        nbbo.bidprice = newBid;
        nbbos.push(nbbo);
        trade.tradeprice = newTrade;
        trades.push(trade);
      });

    if (trades.length > 0) {
      const parsedData = dataArrayToMap(trades);

      if (THROTTLE_TIME_PRICE_UPDATES_MS) {
        const { enabled, throttleTradePriceUpdates } = logConfig;
        const throttleLogEnabledTrade = enabled && throttleTradePriceUpdates && configLib.__DEV__;

        if (throttleFunctionSetupTrade) {
          throttleFunctionSetupTrade.setData(parsedData);
          throttleFunctionSetupTrade.call();
        } else {
          throttleFunctionSetupTrade = throttleFunctionSetupForSymbolDataUpdates(
            'trade',
            {
              logPrefixDataUpdate: '[MockedOMSAndMDS][Trade-Throttle] PRICE UPDATE',
              logPrefixStartAndFirstCall: '[MockedOMSAndMDS][Trade-Throttle] START & First Call',
              logPrefixExec: '[MockedOMSAndMDS][Trade-Throttle] EXEC',
              logEnabled: throttleLogEnabledTrade,
            },
            parsedData,
            THROTTLE_TIME_PRICE_UPDATES_MS,
            THROTTLE_OPTIONS,
            updateTradePriceCacheFromTrade,
          );
        }
      } else {
        updateTradePriceCacheFromTrade(parsedData as TradeDataCache);
      }
    }
    if (nbbos.length > 0) {
      const parsedData = dataArrayToMap(nbbos);

      if (THROTTLE_TIME_PRICE_UPDATES_MS) {
        const { enabled, throttleNbboPriceUpdates } = logConfig;
        const throttleLogEnabledNbbo = enabled && throttleNbboPriceUpdates && configLib.__DEV__;

        if (throttleFunctionSetupNbbo) {
          throttleFunctionSetupNbbo.setData(parsedData);
          throttleFunctionSetupNbbo.call();
        } else {
          throttleFunctionSetupNbbo = throttleFunctionSetupForSymbolDataUpdates(
            'nbbo',
            {
              logPrefixDataUpdate: '[MockedOMSAndMDS][Nbbo-Throttle] PRICE UPDATE',
              logPrefixStartAndFirstCall: '[MockedOMSAndMDS][Nbbo-Throttle] START & First Call',
              logPrefixExec: '[MockedOMSAndMDS][Nbbo-Throttle] EXEC',
              logEnabled: throttleLogEnabledNbbo,
            },
            parsedData,
            THROTTLE_TIME_PRICE_UPDATES_MS,
            THROTTLE_OPTIONS,
            updateNBBOPricesCache,
          );
        }
      } else {
        updateNBBOPricesCache(parsedData as BBODataCache | InstrumentSnapshotMap, 'nbbo');
      }
    }


    this.mdTickId = setTimeout(() => {
      this.tickMDS();
    }, random(...this.mdsRefreshRate as any[])); // random price updates interval in milliseconds
  }

  tickOMS() {
    this.omsTickNo++;

    if (this.omsTickId !== -1) {
      clearTimeout(this.omsTickId);
      this.omsTickId = -1;
    }

    this
      .orders
      .forEach((item, orderIndex) => {
        let order = clone(item);
        const {
          symbol, status, orderQty, filledSoFar = 0,
        } = order;
        const index = ORDER_CYCLE.indexOf(order.status as any);
        let newStatus;
        if (status == null) newStatus = ORDER_CYCLE[1];
        else if (index === -1) {
          if (!status.toLowerCase().match(/fill|reject/)) console.error(`[MockedOMSAndMDS] Unknown order status - ${status}`);
        } else if (index === 2 || status === OrderStatusEnum.PartiallyFilled) {
          // random choice whether to fill, partially fill or reject
          const randomChoice = random(1, MOCK.TRADING_FORCE_FILL === false ? 3 : 2);
          if (randomChoice === 1 && status !== OrderStatusEnum.PartiallyFilled) {
            newStatus = OrderStatusEnum.Filled;
            if (!order.fills) order.fills = [];
            order.filledSoFar = orderQty;
            order.fills.push({
              fillPrice: this.lastPrices[symbol!].trade,
              fillQty: orderQty! - filledSoFar,
              execId: `${random(0.1, 0.99)}`,
              timeStamp: getDateTime(),
            });
          } else if (randomChoice === 2 || status === OrderStatusEnum.PartiallyFilled) {
            const toFill = random(1, (filledSoFar != null ? filledSoFar : orderQty!) - 1);
            if (orderQty! <= toFill + filledSoFar) {
              newStatus = OrderStatusEnum.Filled;
              order.filledSoFar = orderQty;
            }
            if (orderQty === 1) {
              newStatus = OrderStatusEnum.Filled;
              order.filledSoFar = 1;
            } else {
              if (toFill + filledSoFar < orderQty!) newStatus = OrderStatusEnum.PartiallyFilled;
              order.filledSoFar = toFill;
              if (!order.fills) order.fills = [];
              order.fills.push({
                fillPrice: this.lastPrices[symbol!].trade,
                fillQty: orderQty! - order.filledSoFar,
                execId: `${random(0.1, 0.99)}`,
                timeStamp: getDateTime(),
              });
            }
          } else {
            newStatus = OrderStatusEnum.Rejected;
            order.error = '[MockedOMSAndMDS] Random rejection of order';
          }
        } else {
          newStatus = ORDER_CYCLE[index + 1];
        }
        if (newStatus) {
          this.orders[orderIndex] = {
            ...order,
            status: newStatus,
            updatedAt: getDateTime(),
          };

          this.update();
        }
      });

    this.omsTickId = setTimeout(() => {
      this.tickOMS();
    }, random(...this.omsRefreshRate as any[])); // random price updates interval in milliseconds
  }

  subscribe(symbols: string[]) {
    symbols?.forEach(symbol => {
      if (!symbol) {
        console.error(`[MockedOMSAndMDS] subscribe - invalid item '${symbol}'`, { symbols });
        return;
      }

      const current = this.subscriptions.filter(itemSymbol => itemSymbol === symbol);
      if (current.length === 0) {
        this.subscriptions.push(symbol);
        // initial random price by topic
        const ask = random(100.01, 3000.39);
        const bid = ask - random(1.01, 10.043);
        this.lastPrices[symbol] = { nbbo: { ask, bid }, trade: ask - bid / 2 };
      }
    });

    if (this.mdsTickNo === -1) {
      this.tickOMS();
      this.tickMDS();
    }
  }

  unsubscribe(symbols: string[]) {
    symbols?.forEach(symbol => this.subscriptions.splice(this.subscriptions.indexOf(symbol), 1));
  }
}

export default MockedOMSAndMDS;
