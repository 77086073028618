import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontFamily: 'Cabin',
  },
  wrapperHeaderItems: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    alignItems: 'flex-end',
  },
  dateAndStatus: {
    display: 'flex',
  },
  wrapperStatusByItems: {
    alignSelf: 'end',
    marginLeft: '16px',
    borderRadius: '6px',
    display: 'flex',
    marginBottom: '16px',
  },
  wrapperSearchField: {
    marginBottom: '16px',
  },
  wrapperSearchFieldMobile: {
    margin: '0px 0px 16px 16px',
  },
  btnClose: {
    position: 'relative',
    marginLeft: '-32px',
    cursor: 'pointer',
  },
  formControl: {
    minWidth: '66px',
    marginBottom: '2px',
  },
  paper: {
    border: '1px solid #3BB0FF',
  },
  initialSelect: {
    color: '#3BB0FF',
    padding: '7px 12px',
    background: '#1D3960',
    borderRadius: '6px',
    fontFamily: 'Cabin',

    '&:focus': {
      background: '#F6F6F8',
      borderRadius: '6px',
    },
  },
  activeSelect: {
    color: '#3BB0FF',
    padding: '7px 12px',
    background: '#1D3960',
    borderRadius: '6px',
    fontFamily: 'Cabin',

    '&:focus': {
      background: '#1D3960',
      borderRadius: '6px',
      color: '#3BB0FF',
    },
  },
  menuItemRoot: {
    height: '56px',
    fontSize: '16px',
    fontFamily: 'Cabin',

    '&:first-child': {
      display: 'none',
    },

    '&:hover': {
      opacity: 1,
    },
  },
  selected: {
    color: '#2D2D2D',
    background: '#F6F6F8 !important',
    fontFamily: 'Cabin',
    fontSize: '16px',
  },
  initialText: {
    marginRight: '-12px',
    paddingTop: '1px',
  },
  selectedText: {
    marginRight: '16px',
    paddingTop: '1px',
  },
}));

export { useStyles };
