import React from 'react';

import lang from '../../lib/language';

import iconGroup from '../../assets/img/Group.svg';

import styles from './NoDataAvailable.module.scss';

const NoDataAvailable = () => (
  <div className={styles.wrapperNoData}>
    <img src={iconGroup} alt="icon group" />
    <p>{lang.commonMessageNoData()}</p>
  </div>
);

export default NoDataAvailable;
