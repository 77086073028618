import React from 'react';
import { useSelector } from 'react-redux';

import { OrderSideEnum } from '../../lib/enums/order-side.enum';
import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import lang from '../../lib/language';
import { CompanyLogoAndNameState } from '../../lib/models/fundamental/types';
import { Order } from '../../lib/models/trading/types';
import { RootState } from '../../lib/store/index';
import { orderStatusToCallStatus } from '../../lib/util/TradingHelpers';
import { TypedObject } from '../../lib/util/types';

import {
  modifyOrderPopUpBody,
  modifyOrderPopUpTitle,
  proccessPartiallyRejectedMessage,
  proccessSuccessMessageBody,
  proccessSuccessMessageTitle,
  processFailMessageManager,
} from '../OrderActions/OrderHelpers';
import useNewOrderTimer from '../OrderActions/useNewOrderTimer';
import FinalPopupOrderMobile from '../OrderPopupsMobile/FinalPopupOrderMobile';

type Props = {
    symbol: string;
    notEnoughStocks: boolean;
    closeModal: () => void;
    isModify?: boolean;
}

const OrderResultModal = ({ notEnoughStocks, symbol, closeModal, isModify }: Props) => {
  const companyLogoAndName = useSelector<RootState, TypedObject<CompanyLogoAndNameState>>(
    (state: RootState) => state.fundamental.companyLogoAndName,
  );

  const submittedOrder = useSelector<RootState, Order>((state: RootState) => state.trading.order);
  const requestStatus = useSelector<RootState, OrderStatusEnum>((state: RootState) => state.trading.requestStatus);

  const { ordType, orderQty, side, status, filledSoFar, traceIdentifier } = submittedOrder;
  const { showFillOrder, showExpired } = useNewOrderTimer(true, ordType, status, requestStatus);
  const rejectedOrder = requestStatus === OrderStatusEnum.Rejected;
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;

  const renderResponse = () => {
    if (isModify) {
      if (traceIdentifier === OrderStatusEnum.Modified) {
        return (
          <FinalPopupOrderMobile
            logo={companyLogoAndName[symbol]?.logo}
            callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
            processFailedMessage={lang.orderMessageBodyModifyFail()}
            processPartiallyRejectedMessage={partiallyRejectedOrder
              ? proccessPartiallyRejectedMessage(symbol, ordType!, side!, orderQty!, filledSoFar) : undefined}
            processSuccessMessageTitle={modifyOrderPopUpTitle(
              side === OrderSideEnum.Buy ? OrderSideEnum.Buy : OrderSideEnum.Sell,
              showFillOrder,
              symbol,
          ordType!,
          status,
          orderQty,
            )}
            processSuccessMessageBody={modifyOrderPopUpBody(showFillOrder, symbol, filledSoFar, status, orderQty)}
            defaultMessageTitle={orderInProgress ? lang.orderMessageTitleModify(symbol, ordType ?? 0, side ?? '') : ''}
            toggleModal={() => {
              document.body.classList.remove('modal-open');
              closeModal();
            }}
          />
        );
      }
      return (
        <FinalPopupOrderMobile
          logo={companyLogoAndName[symbol]?.logo}
          callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
          processFailedMessage={
              processFailMessageManager(rejectedOrder, showExpired, symbol, ordType!, side!, orderQty ?? 0)
            }
          processPartiallyRejectedMessage={partiallyRejectedOrder
            ? proccessPartiallyRejectedMessage(symbol, ordType!, side!, orderQty!, filledSoFar) : undefined}
          processSuccessMessageTitle={rejectedOrder
            ? '' : proccessSuccessMessageTitle(ordType!, side!, status, showFillOrder)}
          processSuccessMessageBody={rejectedOrder
            ? '' : proccessSuccessMessageBody(symbol, status, orderQty, ordType, showFillOrder, filledSoFar)}
          defaultMessageTitle={orderInProgress ? lang.orderMessageBodyPendingWeb(ordType!, side!, orderQty ?? 0, symbol) : ''}
          toggleModal={() => {
            document.body.classList.remove('modal-open');
            closeModal();
          }}
        />
      );
    }
    return (
      <FinalPopupOrderMobile
        isCancelOrder
        logo={companyLogoAndName[symbol]?.logo}
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        processSuccessMessageTitle={
        lang.orderMessageCancelSuccessWeb(ordType ?? 0, side ?? '', orderQty ?? 0, symbol)
      }
        processFailedMessage={lang.orderMessageBodyCancelFail()}
        processPendingMessage={
        status === OrderStatusEnum.PendingCancelOrModify ? {
          title: lang.orderMessageTitleCancel(ordType ?? 0, side ?? ''),
          body: lang.orderMessageBodyCancelPending(orderQty!, symbol),
        } : undefined
      }
        defaultMessageTitle={orderInProgress ? lang.orderMessageTitleCancel(ordType ?? 0, side ?? '') : ''}
        defaultMessageBody={orderInProgress ? lang.orderMessageBodyCancelPending(orderQty!, symbol) : ''}
        toggleModal={() => {
          document.body.classList.remove('modal-open');
          closeModal();
        }}
      />
    );
  };

  return (
    <>
      {notEnoughStocks && (
        <FinalPopupOrderMobile
          logo={companyLogoAndName[symbol]?.logo}
          processSuccessMessageTitle=""
          callStatus={orderStatusToCallStatus(OrderStatusEnum.Rejected)}
          processFailedMessage={lang.webTradingIncorrectStocks()}
          toggleModal={() => {
            document.body.classList.remove('modal-open');
            closeModal();
          }}
        />
      )}

      {!notEnoughStocks && renderResponse()}
    </>
  );
};

export default OrderResultModal;
