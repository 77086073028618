import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action, Dispatch } from 'redux';

import lang from '../../lib/language';
import { RootState } from '../../lib/store';
import { getCompanyLogoAndName as getLogoAndName } from '../../lib/store/fundamental';
import { formatCurrency } from '../../lib/util/DataHelpers';

import { ClickableDiv } from '..';

import styles from './ConfirmOrderPopupMobile.module.scss';

type OwnProps = {
  symbol: string;
  action: string;
  orderType: string;
  sharescount: number | string;
  marketPrice?: number | string | null;
  targetPrice?: number | string;
  orderValue: number | string;
  onCancel: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
};

type Props = OwnProps & ReduxProps;

const ConfirmOrderPopupMobile = (props: Props) => {
  const {
    symbol,
    action,
    orderType,
    sharescount,
    marketPrice,
    targetPrice,
    orderValue,
    companyLogoAndName,
    onCancel,
    onConfirm,
    getCompanyLogoAndName,
  } = props;

  useEffect(() => {
    if (!companyLogoAndName[symbol]) {
      getCompanyLogoAndName();
    }
  }, [companyLogoAndName, getCompanyLogoAndName, symbol]);

  const logo = useMemo(() => (companyLogoAndName[symbol]
    ? companyLogoAndName[symbol]?.logo : undefined), [companyLogoAndName, symbol]);

  const symbolName = companyLogoAndName[symbol].name;

  return (
    <ClickableDiv className={styles.popupWrapper}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.detailsContainer}>
            <div className={styles.logoAndNameWrapper}>
              <div className={styles.logoWrapper}>
                <img src={`data:image/png;base64,${logo}`} className={styles.logo} alt="logo" />
              </div>
              <div className={styles.symbolAndNameWrapper}>
                <div className={styles.symbol}>
                  {symbol}
                </div>
                <div className={styles.name}>
                  {symbolName}
                </div>
              </div>
            </div>
            <div className={styles.textLine}>
              <div>{lang.commonTradingOrderType()}</div>
              <div>
                {action}
                {' '}
                {orderType}
                {' '}
                Order
              </div>
            </div>
            <div className={styles.textLine}>
              <div>{lang.mobileTradingTicketNumberOfShares()}</div>
              <div>{sharescount}</div>
            </div>
            <div className={styles.textLine}>
              <div>
                {orderType}
                {' '}
                {lang.mobileOrderDetailsPrice()}
              </div>
              {marketPrice ? <div>{formatCurrency(marketPrice)}</div> : <div>{formatCurrency(targetPrice)}</div>}
            </div>
            <div className={styles.borderLine} />
            <div className={styles.textLine}>
              <div>{lang.commonTradingOrderValue()}</div>
              <div>{formatCurrency(orderValue)}</div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.cancelButton}
              type="button"
              onClick={() => { onCancel(false); }}
            >
              {' '}
              {lang.commonCancelButton()}
              {' '}
            </button>
            <button
              className={styles.confirmButton}
              type="button"
              onClick={onConfirm}
            >
              {' '}
              {lang.commonConfirmOrderButton()}
              {' '}
            </button>
          </div>
        </div>
      </div>
    </ClickableDiv>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  companyLogoAndName: state.fundamental.companyLogoAndName,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps) => ({
  getCompanyLogoAndName: () => dispatch(getLogoAndName(ownProps.symbol)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ConfirmOrderPopupMobile);
