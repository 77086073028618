import React from 'react';

import checkIcon from '../../../../assets/img/icon-check-white.svg';

import styles from '../VerificationPoI.module.scss';

type Props = {
    title: string;
}

const Bullet = ({ title }: Props): JSX.Element => (
  <li>
    <div className={styles.circle}>
      <img src={checkIcon} alt="check" />
    </div>
    <p>{title}</p>
  </li>
);

export default Bullet;
