import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Landscape } from '@material-ui/icons';
import moment from 'moment';
import { Action } from 'redux';

import { TRADE_HISTORY_DETAILS_FILLED_DATE } from '../../lib/constants/date-time.constants';
import { FileMimeTypeEnum } from '../../lib/enums/file-mime-type.enum';
import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import lang from '../../lib/language';
import { SOROrderSide, SOROrderTimeInForce, SOROrderType, TenNSOROrderStatus } from '../../lib/models/gateway/types';
import { RootState } from '../../lib/store/index';
import { DOWNLOAD_ORDER_REPORT_STATUS } from '../../lib/store/reporting/constants';
import { calculateOrderValue, handleColors, hasPartialFillDetail } from '../../lib/store/reporting/trading/order-helpers';
import { getReportingFileByOrder } from '../../lib/store/reportingFile';
import { formatCurrency, formatNumber } from '../../lib/util/DataHelpers';
import { formatUTCdateToLocalDate } from '../../lib/util/DateTimeHelpers';
import { mapOrderType } from '../../lib/util/TradingHelpers';
import { INVALID_VALUE } from '../../lib/util/types';

import IconDocument from '../../assets/img/icon-document.svg';
import { useIsMobile } from '../../hooks';
import { handleOpenFileInNewTab } from '../../util/helpers';
import { ButtonTypesEnum } from '../Button/Button.types';
import { Button, CompanyLogo } from '../index';

import RegulatoryFeesInfo from './RegulatoryFeesInfo';

import styles from './DetailTradeModule.module.scss';

type OwnProps = {
    closeModule: () => void;
}

const fillPriceInfo = (fillPrice: number): JSX.Element => (
  <div className={styles.content}>
    <span className={styles.rawTitle}>{lang.headerTradeHistoryFillPrice()}</span>
    <span>{formatCurrency(fillPrice)}</span>
  </div>
);

type Props = OwnProps & ReduxProps

const DetailTradeModule = ({
  closeModule,
  getReportingFile,
  orderDetails,
  companyLogoAndName,
  tradeHistoryOrders,
  file,
  isGettingFile,
}: Props) => {
  const {
    sec,
    taf,
    side,
    type,
    symbol,
    orderDate,
    stopPrice,
    orderValue,
    timeInForce,
    parentOrderId,
    totalQuantity,
    expirationDate,
    regulatoryFees,
    grossRealizedPnl,
    tradingSessionId,
    averageFilledPrice,
    aqxExecutedQuantity,
    orderRequestedPrice,
  } = orderDetails;
  const isMobile = useIsMobile();
  const [isDownloadFile, setIsDownloadFile] = useState<boolean>(false);

  const logo = companyLogoAndName[symbol]?.logo;
  const name = companyLogoAndName[symbol]?.name;
  const isLimitOrder = type === SOROrderType.Limit;
  const isStopOrder = type === SOROrderType.Stop;
  const limitOrStopOrder = isLimitOrder || isStopOrder;
  const showPL = grossRealizedPnl !== null;
  const showRegulatoryFees = !!regulatoryFees;
  const order = tradeHistoryOrders.find(el => el.parentOrderId === parentOrderId);
  const date = order?.aqxLastExecutionDate ?? order?.lastUpdatedDate;
  const orderStatus = order?.tenNParsedStatus ?? TenNSOROrderStatus.Cancelled;
  const isFilled = orderStatus.toLowerCase() === OrderStatusEnum.Filled;
  const parsedOrderValue = calculateOrderValue(isLimitOrder ? orderRequestedPrice : stopPrice, totalQuantity);
  const expiryDate = timeInForce === SOROrderTimeInForce.Day ? orderDate : expirationDate;
  const expiredOrder = orderStatus.toLowerCase() === OrderStatusEnum.Expired;
  const cancelledOrder = orderStatus.toLowerCase() === OrderStatusEnum.Cancelled;
  const rejectedOrder = orderStatus.toLowerCase() === OrderStatusEnum.Rejected;
  const showThirdSection = !cancelledOrder && !expiredOrder && !rejectedOrder && (showRegulatoryFees || showPL);

  const handleGetFile = (orderId: string) => {
    const hammerOrderId = `Hammer_${orderId}`;
    getReportingFile(hammerOrderId);
    setIsDownloadFile(true);
  };

  useEffect(() => {
    if (isDownloadFile && isGettingFile) {
      setIsDownloadFile(false);
      handleOpenFileInNewTab(file, FileMimeTypeEnum.Pdf);
    }
  }, [file, isDownloadFile, isGettingFile]);

  let localDate = formatUTCdateToLocalDate(date);
  localDate = `${localDate?.split(',')[0]} • ${localDate?.split('• ')[1]}`;

  return (
    <>
      { isMobile && (
        <div className={styles.title}>
          {lang.commonOrderSummary()}
        </div>
      )}
      <div className={styles.companyDetails}>
        <div className={styles.logoAndName}>
          <CompanyLogo logo={logo} className={styles.logo} symbol={symbol} />
          <div className={styles.companyInfo}>
            <span className={styles.symbol}>{symbol}</span>
            <span className={styles.company}>{name}</span>
          </div>
        </div>
        <div className={styles.generalOrderInfo}>
          <div className={styles.date}>
            {localDate || INVALID_VALUE }
          </div>
          <div>
            {!!aqxExecutedQuantity && totalQuantity !== aqxExecutedQuantity && (
            <span className={styles.partFilled}>
              <span className={styles.quantityInfo}>{formatNumber(aqxExecutedQuantity, true)}</span>
              <span className={styles.quantityInfo}>/</span>
              <span className={styles.quantityInfo}>{formatNumber(totalQuantity, true)}</span>
              <span>•</span>
            </span>
            )}
            <span style={{ color: (handleColors(orderStatus)) }}>{orderStatus}</span>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonOrderNo()}</span>
          <span className={styles.parentOrderId}>{parentOrderId}</span>
        </div>
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonOrderDate()}</span>
          <span className={styles.parentOrderId}>
            { localDate ? moment(orderDate).format(TRADE_HISTORY_DETAILS_FILLED_DATE) : INVALID_VALUE }
          </span>
        </div>
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonGeneralQuestionsTypeLabel()}</span>
          <span>{mapOrderType(side, type)}</span>
        </div>
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.mobileTradingTicketNumberOfShares()}</span>
          <span>{formatNumber(totalQuantity, true)}</span>
        </div>
        {limitOrStopOrder && !isFilled && (
        <div className={styles.content}>
          <>
            <span className={styles.rawTitle}>
              {lang.commonTradeHistoryDetailsPrice(SOROrderType[type])}
            </span>
            <span>{formatCurrency(isLimitOrder ? orderRequestedPrice : stopPrice)}</span>
          </>
        </div>
        )}
        {isFilled && fillPriceInfo(averageFilledPrice)}
        {orderStatus.toLowerCase() !== OrderStatusEnum.Expired && limitOrStopOrder && (
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonTradingExpiryDate()}</span>
          <span>{moment(expiryDate).format(TRADE_HISTORY_DETAILS_FILLED_DATE) }</span>
        </div>
        )}
        {isLimitOrder && side !== SOROrderSide.SellShort && tradingSessionId && (
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonTradingExtendedHoursTrade()}</span>
          <span>{tradingSessionId === 'X' ? lang.commonYes() : lang.commonNo()}</span>
        </div>
        )}
        <div className={styles.horizontalLine} />
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonTradingOrderValue()}</span>
          <span>{limitOrStopOrder && !isFilled ? parsedOrderValue : formatCurrency(orderValue)}</span>
        </div>
      </div>
      {hasPartialFillDetail(orderStatus, aqxExecutedQuantity, totalQuantity) && (
      <div className={styles.body}>
        <div className={styles.content}>
          <span className={styles.rawTitle}>
            {side === SOROrderSide.Buy ? lang.commonBoughtShares() : lang.commonSoldShares()}
          </span>
          <span>{aqxExecutedQuantity ?? 0}</span>
        </div>
        {fillPriceInfo(averageFilledPrice)}
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.mobileDepositAmountLabel()}</span>
          <span>{formatCurrency(aqxExecutedQuantity * averageFilledPrice)}</span>
        </div>
      </div>
      )}
      {showThirdSection && (
      <div className={styles.body}>
        {showPL && (
        <div className={styles.content}>
          <span className={styles.rawTitle}>{lang.commonPAndL()}</span>
          <span className={grossRealizedPnl < 0 ? styles.red : styles.green}>{formatCurrency(grossRealizedPnl)}</span>
        </div>
        )}
        {showRegulatoryFees && (
        <div className={styles.content}>
          <div className={styles.rawTitle}>
            {lang.commonRegulatoryFees()}
            <RegulatoryFeesInfo
              fee={formatCurrency(Math.abs(regulatoryFees))}
              taf={formatCurrency(Math.abs(taf ?? 0))}
              sec={formatCurrency(Math.abs(sec ?? 0))}
            />
          </div>
          <span>{formatCurrency(Math.abs(regulatoryFees))}</span>
        </div>
        )}
      </div>
      )}
      {DOWNLOAD_ORDER_REPORT_STATUS.includes((orderStatus.toLowerCase() as OrderStatusEnum)) && (
      <div className={styles.viewReportLink}>
        <img src={IconDocument} alt={'report'} />
        <Button variant={ButtonTypesEnum.Link} onClick={() => handleGetFile(parentOrderId)}>
          {lang.commonViewDetailedReport()}
        </Button>
      </div>
      )}
      { !isMobile && (
        <Button
          fullWidth
          onClick={closeModule}
        >
          {lang.commonClose()}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  orderDetails: state.reporting.orderDetail,
  tradeHistoryOrders: state.reporting.tradeHistoryOrders,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  file: state.reportingFile.file,
  isGettingFile: state.reportingFile.isGettingFile,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getReportingFile: (orderId: string) => dispatch(getReportingFileByOrder(orderId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DetailTradeModule);
