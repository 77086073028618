import React, { Dispatch, useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import { Action } from 'redux';

import { LinkedAccountStatusEnum } from '../../../lib/enums/linked-account-status.enum';
import lang from '../../../lib/language';
import { WithdrawalRequest } from '../../../lib/models/crm/types';
import { AccountInfo } from '../../../lib/models/linked-account/types';
import { RootState } from '../../../lib/store';
import { isActiveAccount } from '../../../lib/store/payment/helpers';
import { registerWithdrawalRequest, setBankAccountIdByLastWithdrawal } from '../../../lib/store/payment/index';
import { PaymentCall } from '../../../lib/store/payment/types';
import { fixToFloat, formatCurrency } from '../../../lib/util/DataHelpers';
import { isCallStatusUIDone } from '../../../lib/util/error-handling/StatusByCallHelpers';

import imageWithdrawal from '../../../assets/bigImages/image_withdrawal.png';
import backArrow from '../../../assets/img/icon-arrow_back.svg';
import iconExpandDown from '../../../assets/img/icon-expand-more-down.svg';
import iconExpandUp from '../../../assets/img/icon-expand-more-up.svg';
import InfoIcon from '../../../assets/img/icon-info-outline.svg';
import { BaseModal, Button, ClickableImage, Preloader } from '../../../components';
import config from '../../../config';
import { useBrowserWidth } from '../../../hooks';
import { SIZE_OF_AN_IMAGE_FOR_THE_SCREEN } from '../../../util/constants';

import BankAccounts from './components/BankAccounts';
import Modal from './modal/Modal';

import styles from './WithdrawalAmount.module.scss';

type Props = {} & ReduxProps;

const WithdrawalAmount = (props: Props) => {
  const {
    calculatedCash,
    tradingAccounts,
    statusByCall,
    linkedAccounts,
    linkedAccountIdByLastWithdrawal,
    sendWithdrawalRequest,
    setBankAccountIdValueByLastWithdrawal,
  } = props;

  const { browserWidthSize } = useBrowserWidth();

  const dropDownDivRef = useRef<HTMLInputElement | null>(null);

  const history = useHistory();
  const location = history.location.state as { target: string, previousPage: string };

  const convertedActiveLinkedAccounts = linkedAccounts.filter(
    linkedAccount => linkedAccount.status === LinkedAccountStatusEnum.Active,
  ).reverse();

  let linkedAccountsData = convertedActiveLinkedAccounts;

  if (linkedAccountIdByLastWithdrawal) {
    linkedAccountsData = linkedAccounts.filter(account => account.id === linkedAccountIdByLastWithdrawal);
  }

  const linkedAccountName = linkedAccountsData.length > 0 ? linkedAccountsData[0].account_info.bank_name : '';
  let linkedAccountIban = linkedAccountsData.length > 0 ? linkedAccountsData[0].account_info.iban : '';

  if (linkedAccountIban === null) {
    linkedAccountIban = linkedAccountsData[0].account_info.account;
  }

  const [isOpenBankAccounts, setIsOpenBankAccounts] = useState<boolean>(false);
  const [openPopoverInfo, setOpenPopoverInfo] = useState(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isShowNoActiveAccountModal, setIsShowNoActiveAccountModal] = useState(false);
  const [dataValues, setDataValues] = useState({
    linkedAccontData: linkedAccountsData[0],
    linkedAccountName,
    linkedAccountIban,
    amountValue: '',
  });
  const handleClickBankAccount = (linkedAccount) => {
    setDataValues({
      ...dataValues,
      linkedAccontData: linkedAccount,
      linkedAccountName: linkedAccount.account_info.bank_name,
      linkedAccountIban: linkedAccount.account_info.iban || linkedAccount.account_info.account,
    });
    setIsOpenBankAccounts(!isOpenBankAccounts);
  };

  const handleRequestWithdrawal = () => {
    const activeTradingAccount = tradingAccounts.find((tradingAccount) => isActiveAccount(tradingAccount));
    if (!activeTradingAccount) {
      setIsShowNoActiveAccountModal(true);
      return;
    }

    setBankAccountIdValueByLastWithdrawal(
      dataValues.linkedAccontData
        ? dataValues.linkedAccontData.id
        : linkedAccountsData[0].id,
    );
    sendWithdrawalRequest({
      amount: Number(dataValues.amountValue),
      bank_details: dataValues.linkedAccontData
        ? dataValues.linkedAccontData.account_info as AccountInfo
        : linkedAccountsData[0].account_info as AccountInfo,
    });
    setShowModal(true);
  };

  useEffect(() => {
    const handleClickInside = (event) => {
      if (event.target.nodeName === 'IMG' && isOpenBankAccounts) {
        setIsOpenBankAccounts(false);
      } else {
        dropDownDivRef.current && dropDownDivRef.current.contains(event.target)
          ? setIsOpenBankAccounts(true)
          : setIsOpenBankAccounts(false);
      }
    };
    document.addEventListener('click', handleClickInside);
    return () => document.removeEventListener('click', handleClickInside);
  }, [dropDownDivRef, isOpenBankAccounts]);

  const withdrawMessages = lang.mobileWithdrawScreenModal().split('\n\n');

  return (
    <>
      <div className={styles.wrapperContainer}>
        <div className={styles.containerContent}>
          <div className={styles.wrapperContent}>
            <ClickableImage
              alt=""
              src={backArrow}
              className={styles.backArrow}
              onClick={() => history.push(location.previousPage)}
            />
            <h3 className={styles.title}>{lang.mobileWithdrawScreenTitle()}</h3>
            <span className={styles.description}>{lang.mobileWithdrawScreenSubtitle(6)}</span>
            <div className={styles.wrapperFieldAmount}>
              <div className={styles.wrapperAmountText}>
                {
                  !isCallStatusUIDone(PaymentCall.cashCalculated, statusByCall) && (
                  <div className={styles.preloader}>
                    <Preloader text={''} owner="withdrawal" />
                  </div>
                  )
                }
                <div className={styles.currencyInputWrapper}>
                  <div className={styles.firstSection}>
                    <div className={styles.amountText}>
                      {lang.mobileDepositAmountLabel()}
                    </div>
                    <CurrencyInput
                      autoFocus
                      defaultValue={dataValues.amountValue}
                      onBlur={({ target }) => {
                        !openPopoverInfo && target.focus();
                      }}
                      onValueChange={(value) => setDataValues({ ...dataValues, amountValue: value as string })}
                      className={classNames(styles.input,
                        { [styles.errorInput]:
                          Number(dataValues.amountValue) > fixToFloat(calculatedCash) })}
                      placeholder="$0.00"
                      allowNegativeValue={false}
                      prefix={'$'}
                    />
                  </div>
                  <ClickableImage
                    src={InfoIcon}
                    onClick={(event) => setOpenPopoverInfo(event.currentTarget)}
                    variant="contained"
                    className={styles.popoverTrigger}
                  />
                </div>
                <BaseModal
                  isOpen={Boolean(openPopoverInfo)}
                >
                  <p className={styles.messageAvailableCashTitle}>
                    {lang.mobileWithdrawScreenInfoModalTitle(formatCurrency(calculatedCash))}
                  </p>
                  <p className={styles.messageAvailableCash}>{lang.mobileWithdrawScreenInfoModalDescription()}</p>
                  <Button
                    fullWidth
                    onClick={() => setOpenPopoverInfo(null)}
                  >
                    {lang.commonContinueButton()}
                  </Button>
                </BaseModal>
                <span className={classNames(styles.textAvailableCash,
                  { [styles.textAvailableCashError]:
                     Number(dataValues.amountValue) > fixToFloat(calculatedCash) })}
                >
                  {`${lang.mobileFundsScreenAvailableCash()} ${formatCurrency(calculatedCash)}`}
                </span>
              </div>
            </div>
            <div ref={dropDownDivRef}>
              <div className={classNames(styles.wrapperShowBankContentBefore,
                { [styles.wrapperShowBankContentAfter]: isOpenBankAccounts })}
              >
                <div className={styles.wrapperInfo}>
                  <p
                    className={classNames(styles.titleInfoBefore,
                      { [styles.titleInfoAfter]: isOpenBankAccounts })}
                  >
                    {lang.mobileWithdrawScreenBankAccount()}
                  </p>
                  <p className={styles.methodName}>{dataValues.linkedAccountName || linkedAccountName}</p>
                  <p>{dataValues.linkedAccountIban || linkedAccountIban}</p>
                </div>
                <ClickableImage
                  alt=""
                  src={isOpenBankAccounts ? iconExpandUp : iconExpandDown}
                  className={styles.iconExpand}
                />
              </div>
              { isOpenBankAccounts && (
              <BankAccounts
                handleClickBankAccount={handleClickBankAccount}
                convertedActiveLinkedAccounts={convertedActiveLinkedAccounts}
              />
              )}
            </div>
            <Button
              fullWidth
              className={styles.btnRequestWithdrawal}
              disabled={
                !dataValues.amountValue
                || Number(dataValues.amountValue) === 0
                || Number(dataValues.amountValue) > fixToFloat(calculatedCash)
                || isOpenBankAccounts
              }
              onClick={handleRequestWithdrawal}
            >
              {lang.mobileWithdrawScreenRequestBtn()}
            </Button>
          </div>
        </div>
      </div>
      <BaseModal isOpen={showModal || isShowNoActiveAccountModal}>
        {showModal && (
        <Modal
          title={lang.mobileWithdrawScreenModalTitle()}
          description1={withdrawMessages[0]}
          description2={withdrawMessages[1]}
        />
        )}
        {isShowNoActiveAccountModal && (
        <Modal
          title={lang.walletWithdrawalsRestricted()}
          descriptionComponent={(
            <div className={styles.modalReportWrapper}>
              <span className={styles.description}>
                {lang.walletNoActiveAccountPleaseContact()}
                <a
                  target="_blank"
                  href={config.links.helpCenterUrl}
                  className={styles.descriptionSpport}
                  rel="noreferrer"
                >
                  {lang.commonSupport()}
                </a>
              </span>
            </div>
          )}
        />
        )}
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  statusByCall: state.payment.statusByCall,
  tradingAccounts: state.payment.tradingAccounts,
  calculatedCash: state.payment.calculatedCash,
  linkedAccounts: state.payment.linkedAccounts,
  linkedAccountIdByLastWithdrawal: state.payment.linkedAccountIdByLastWithdrawal,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendWithdrawalRequest: (data: WithdrawalRequest) => dispatch(registerWithdrawalRequest(data)),
  setBankAccountIdValueByLastWithdrawal: (id: number) => dispatch(setBankAccountIdByLastWithdrawal(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(WithdrawalAmount);
