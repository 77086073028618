import { clone, isArray, isFunction } from 'lodash';
import { decompressBlock } from 'lz4js';
import moment from 'moment';

import { MOCK } from '../../configDebug';
import configLib from '../../configLib';
import {
  GWMarketDataSubscriptionSubType, GWMarketDataSummaryBucket, GWQueryCase, GWResponseType,
} from '../models/gateway/types';
import { BBOData, SymbolStatus, TradeData } from '../models/market-data/types';
import { NewsData } from '../models/news';
import { QueryCacheItemType } from '../models/trading/types';
import { ChartDataRange } from '../store/market-data/charting/types';
import {
  latestNewsLoadCompleted, latestNewsLoadFailed, newsForSymbolLoadCompleted, newsForSymbolLoadFailed,
} from '../store/news';

import { changeResponseSymbolDataConversion, processMDQueryData } from './MarketDataHelpers';
import { TypedObject } from './types';

/**
 * Implements reading header of received from Gateway API responses.
 * Then decompresses the data by using 'lz4-js' library - decompressBlock method
 * @param source The source byte array in Uint8Array format
 * @returns Array of bytes, representing uncompressed data in MsgPack format
 */
export function lz4Unpickle(source: Uint8Array) {
  const flags = source[0];

  let sizeBytes = (flags >> 6) & 0x3; // eslint-disable-line no-bitwise
  if (sizeBytes === 0) return source.slice(1);
  sizeBytes = (sizeBytes === 3 ? 4 : sizeBytes);

  if (source.length < sizeBytes) throw new Error('[Unpickle] Source data is too small');

  let resultSizeDiff = source.length - 1;
  let sizeData: Uint8Array | null = null;

  if (sizeBytes > 0) {
    sizeData = source.slice(1, sizeBytes + 1);

    let dataView = new DataView(sizeData.buffer);
    let readUint = (bytesOffset: number, isLE: boolean) => bytesOffset;
    switch (sizeBytes) {
      case 1: readUint = dataView.getUint8; break;
      case 2: readUint = dataView.getUint16; break;
      case 4: readUint = dataView.getUint32; break;
      default: console.warn(`[GatewayHelpers] lz4Unpickle - unknown sizeBytes value '${sizeBytes}'`); break;
    }

    resultSizeDiff = readUint.apply(dataView, [ 0, true ]);
  }

  const dataOffset = 1 + sizeBytes;
  const dataSize = source.length - dataOffset;

  const uncompressedSize = dataSize + resultSizeDiff;
  const compressedData = source.slice(dataOffset);
  let uncompressedData = new Array(uncompressedSize).fill(0);

  // lz4 decompress
  decompressBlock(compressedData, uncompressedData, 0, compressedData.length, 0);

  return uncompressedData;
}

/**
 * Parses Gateway data  that was:
 *   1. uncompressed (from LZ4 Pickler format) - see lz4Unpickle
 *   2. and decoded (from MsgPack) data - see @msgpack/msgpack.decode
 * @param data An array of arrays (all arrays are JavaScript)
 * @returns null or an array of BBOData/TradeData
 */
export function parseGatewayData(data: any): (BBOData | TradeData | SymbolStatus)[] | NewsData | null {
  if (data == null) return null;

  if (
    MOCK.ENABLED
      && Object.prototype.hasOwnProperty.call(data, 'messageType')
      && data.messageType.includes('NewsArticles')
  ) {
    const { resultCode } = data;

    return {
      ...data.newsArticles,
      resultCode,
    };
  }

  const { 0: responseType, 1: { 0: subType } } = data;
  let result: (BBOData | TradeData | SymbolStatus)[] | null = null;
  let messageName: string = GWResponseType[responseType] ?? responseType;
  let subName: string = '';

  switch (responseType) {
    case GWResponseType.MarketData: {
      subName = GWMarketDataSubscriptionSubType[subType];
      messageName += `/${subName}`;
      const rawItems = isFunction(data?.slice) ? data?.slice(1, data.length - 1) : [];

      switch (subType) {
        case GWMarketDataSubscriptionSubType.Nbbo: {
          result = [];
          rawItems.forEach((rawItem: any) => {
            try {
              const { 3: items } = rawItem ?? [];
              items?.forEach((item: any) => {
                let {
                  0: askpartid,
                  1: askprice,
                  2: asksize,
                  3: bidpartid,
                  4: bidprice,
                  5: bidsize,
                  6: quotetime,
                  7: symbolonly,
                } = item;

                const parsedSymbol = changeResponseSymbolDataConversion(symbolonly ?? '');

                // TODO: Review this patch and possibly request a fix on Gateway - should be string not object
                if (quotetime instanceof Date) {
                  const isWrongYear = quotetime.getFullYear() < 100;
                  const asMoment = isWrongYear ? moment.utc(quotetime) : moment(quotetime);
                  quotetime = asMoment.format('HH:mm:ss.SSS');
                }

                result!.push({
                  sym: parsedSymbol,
                  symbolonly: parsedSymbol,
                  askpartid,
                  askprice,
                  asksize,
                  bidpartid,
                  bidprice,
                  bidsize,
                  quotetime,
                  askdirection: '',
                  biddirection: '',
                } as BBOData);
              });
            } catch (error) {
              if (configLib.__DEV__) console.error(`[GatewayHelpers] parseGatewayData - Nbbo data could not be parsed - ${error}`);
            }
          });
          break;
        }

        case GWMarketDataSubscriptionSubType.Trade: {
          result = [];
          rawItems.forEach((rawItem: any) => {
            try {
              const { 3: items } = rawItem ?? [];
              items?.forEach((item: any) => {
                let {
                  0: tradedirection,
                  1: symbolonly,
                  2: totalvolume,
                  3: tradepartid,
                  4: tradeprice,
                  5: tradesize,
                  6: tradetime,
                } = item as any;

                const parsedSymbol = changeResponseSymbolDataConversion(symbolonly ?? '');

                // TODO: Review this patch and possibly request a fix on Gateway - should be string not object
                if (tradetime instanceof Date) {
                  const isWrongYear = tradetime.getFullYear() < 100;
                  const date = isWrongYear ? addUTCDate(tradetime) : tradetime;
                  const dateToISOString = date.toISOString();
                  tradetime = dateToISOString;
                }

                result!.push({
                  tradedirection,
                  sym: parsedSymbol,
                  symbolonly: parsedSymbol,
                  totalvolume,
                  tradepartid,
                  tradeprice,
                  tradesize,
                  tradetime,
                } as TradeData);
              });
            } catch (error) {
              if (configLib.__DEV__) console.error(`[GatewayHelpers] parseGatewayData - Trade data could not be parsed - ${error}`);
            }
          });
          break;
        }

        case GWMarketDataSubscriptionSubType.SymbolStatus: {
          result = [];
          rawItems.forEach((rawItem: any) => {
            try {
              const { 3: items } = rawItem ?? [];
              items?.forEach((item: any) => {
                let {
                  0: symbol,
                  1: kdbRecvTime,
                  2: updateTime,
                  3: trade,
                  4: reason,
                  5: ssri,
                  6: limitHigh,
                  7: limitLow,
                } = item;

                // TODO: Review this patch and possibly request a fix on Gateway - should be string not object
                if (kdbRecvTime instanceof Date) {
                  const isWrongYear = kdbRecvTime.getFullYear() < 100;
                  const asMoment = isWrongYear ? moment.utc(kdbRecvTime) : moment(kdbRecvTime);
                  kdbRecvTime = asMoment.format('HH:mm:ss.SSS');
                }
                if (updateTime instanceof Date) {
                  const isWrongYear = updateTime.getFullYear() < 100;
                  const asMoment = isWrongYear ? moment.utc(updateTime) : moment(updateTime);
                  updateTime = asMoment.format('HH:mm:ss.SSS');
                }
                const parsedSymbol = changeResponseSymbolDataConversion(symbol ?? '');

                result!.push({
                  sym: parsedSymbol,
                  kdbRecvTime,
                  updateTime,
                  trade,
                  reason,
                  ssri,
                  limitHigh,
                  limitLow,
                } as SymbolStatus);
              });
            } catch (err) {
              if (configLib.__DEV__) {
                console.error(`[GatewayHelpers] parseGatewayData - SymbolStatus data could not be parsed - ${err}`);
              }
            }
          });
          break;
        }

        default:
          console.warn(`[DataHelpers::parseGatewayData] Unhandled MarketData subType '${messageName}'`);
          break;
      }
      break;
    }

    default:
      console.warn(`[DataHelpers::parseGatewayData] Unhandled response '${messageName}'`);
      break;
  }

  return result;
}

/**
 * This is a wrapper to adapt Gateway Rest response to normal rest response -
 * it converts the `rows` array to `data` and preserves `status`, `ok` and `problem` fields
 * @param response The raw response object as parsed in ApiSauce
 * @param observer The observer in the epic calling this helper
 * @param cacheData Query cases cache by traceId
 * @param isFailed Forces failed action to be dispatched
 */
export function processQueryResultsRestData(
  response: any,
  observer: any,
  cacheData: QueryCacheItemType,
  isFailed = false,
  dispatch = (action: any) => observer.next(action),
) {
  const {
    status, ok, problem, data,
  } = response;
  const { queryCase, symbol } = cacheData;

  switch (queryCase) {
    case GWQueryCase.News: {
      let actionPayload;
      if (isFailed) {
        actionPayload = { ...data, status };
        if (symbol) {
          actionPayload.customData = symbol;
          observer.next(newsForSymbolLoadFailed(actionPayload));
        } else {
          observer.next(latestNewsLoadFailed(actionPayload));
        }
      } else {
        actionPayload = { ...data.newsArticles, status };
        if (symbol) {
          actionPayload.customData = symbol;
          observer.next(newsForSymbolLoadCompleted(actionPayload));
        } else {
          observer.next(latestNewsLoadCompleted(actionPayload));
        }
      }
      break;
    }

    default: {
      let parsedRows: any[] = [];
      let adaptedResponse = {
        status,
        ok,
        problem,
        data: [] as any[],
      };

      if (isArray(data)) {
        data.forEach((rowsObject: any) => {
          parsedRows = parsedRows.concat(parseRows(rowsObject.rows, rowsObject));
        });
      }

      break;
    }
  }
}

/**
 * Exported only for testing purposes. Used internally in `GatewayHelpers`.
 */
export function parseRows(rows: any, fullData?: any) {
  if (rows == null || rows.length === 0) return rows;

  let resultRows = clone(rows);

  if (isArray(rows) && (rows[0]?.dateAndTime || rows[0]?.date)) {
    resultRows = rows.map(item => {
      // Convert `symbol` to `sym` for instrument snapshot items
      if (isInstrumentSnapshot(fullData)) {
        const parsedItem = { ...item, sym: (changeResponseSymbolDataConversion(item.sym ?? item.symbol ?? '')) };
        delete parsedItem.symbol;
        return parsedItem;
      }
      let parsedOHLCVItem = { ...item };
      // Add symbol from row root for ohlcv items
      if (isOHLCVData(fullData)) {
        parsedOHLCVItem = { ...parsedOHLCVItem, sym: fullData.symbol };
      }
      // Parse dateAndTime (gateway format) to date and time (redux format)
      if (item?.dateAndTime) {
        parsedOHLCVItem = { ...parsedOHLCVItem, date: moment.utc(item.dateAndTime).format('YYYY-MM-DD') };
        parsedOHLCVItem = { ...parsedOHLCVItem, time: moment.utc(item.dateAndTime).format('HH:mm:ss.SSS') };
      }

      return parsedOHLCVItem;
    });
  }

  return resultRows;
}

export function parseMarketDataSummaries(rows: any) {
  if (rows == null || rows.length === 0) return rows;

  let result = {} as Record<string, any>;


  if (isArray(rows) && (rows[0]?.dateAndTime || rows[0]?.date)) {
    rows.forEach(item => {
      // Parse dateAndTime (gateway format) to date and time (redux format)
      if (item?.dateAndTime) {
        item.date = moment.utc(item.dateAndTime).format('YYYY-MM-DD');
        item.time = moment.utc(item.dateAndTime).format('HH:mm:ss.SSS');
      }

      const parsedSymbol = changeResponseSymbolDataConversion(item?.symbol ?? '');
      item.symbol = parsedSymbol;
      if (!result[parsedSymbol]) {
        result[parsedSymbol] = [];
      }

      result[parsedSymbol].push(item);
    });
  }

  return result;
}

const GW_OHLCV_DATA_TYPES = [ 'ohlcv', 'summary' ];
function isOHLCVData(data: any) {
  if (!data) return false;
  return GW_OHLCV_DATA_TYPES.includes(data?.type?.toLowerCase());
}
function isInstrumentSnapshot(data: any) {
  if (!data) return false;
  return data?.type === GWResponseType.InstrumentSnapshotResponse;
}

export const QueryResponseMessageByStatus: TypedObject<string> = {
  '-2': 'Initial',
  '-1': 'Unknown',
  0: 'Loading',
  200: 'Ready',
  202: 'Still processing',
  204: 'Not found',
};

export const QueryResponseMessageByResultCode: TypedObject<string> = {
  404: 'Not found - most probably an external service call failed with 404',
};

export function chartingRangeToGatewaySummaryBucket(range?: ChartDataRange | null) {
  switch (range) {
    case '1D': return GWMarketDataSummaryBucket.OneDay;
    case '1W': return GWMarketDataSummaryBucket.OneWeek;
    case '1M': return GWMarketDataSummaryBucket.OneMonth;
    case '3M': return GWMarketDataSummaryBucket.ThreeMonths;
    case '1Y': return GWMarketDataSummaryBucket.OneYear;
    case '5Y': return GWMarketDataSummaryBucket.FiveYears;
    default:
      console.error(`[GatewayHelpers] chartingRangeToGatewaySummaryBucket - invalid range '${range}'`);
      return GWMarketDataSummaryBucket.Invalid;
  }
}

export function gatewaySummaryBucketToChartRange(bucket?: GWMarketDataSummaryBucket | null) {
  if (!bucket) return '';
  return bucket.substring(0, 2) as ChartDataRange;
}

export function parseBase64String(value: string) {
  let jsonString;
  let data;

  try {
    jsonString = configLib.base64.decode(value)?.replace(/:[ ]*NaN[ ]*,/g, ':null,');
    data = JSON.parse(jsonString);
  } catch (error) {
    console.error(`[GatewayHelpers] parseBase64String - could not parse base64 string -- ${error}`);
    console.debug(`[GatewayHelpers] parseBase64String - could not parse base64 string -- ${error}`, { jsonString, data, error });
  }

  return data;
}
export const addUTCDate = (date: Date) => {
  const currentDate = new Date();
  const days = currentDate.getUTCDate();
  const months = currentDate.getUTCMonth();
  const years = currentDate.getUTCFullYear();

  date.setUTCFullYear(date.getUTCFullYear() + years - 1);
  date.setUTCMonth(date.getUTCMonth() + months);
  date.setUTCDate(date.getUTCDate() + days - 1);

  return date;
};
