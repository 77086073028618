/**
 * These helpers return boolean flags for statusByCall.
 * The pattern is:
 *   `isCallStatus<Name>(callEnum: number, statusByCall: GenericStatusByCall)`
 * where callEnum is any call enum like `ReportingCall`, `CRMCall`, `FundamentalCall` etc.
 * It is defined as number in order to not make type complicated like `ReportingCall | CRMCall | FundamentalCall`
 *
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 */
import { CallStatus, CallStatusName, HttpStatusCodeEnum } from '../../enums/index';
import { CallState, GenericStatusByCall } from '../../enums/status-by-call';
import { PaymentCall } from '../../store/payment/types';
import { isOneOf } from '../DataHelpers';
import { NullableNumber, TypedObject } from '../types';

import { appendToErrorsLog } from './ErrorHandlingHelpers';


/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusInitial = (callEnum: number, statusByCall: GenericStatusByCall) => (
  !statusByCall[callEnum] || statusByCall[callEnum]!.status <= CallStatus.INITIAL
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusPending = (callEnum: number, statusByCall: GenericStatusByCall) => (
  isOneOf(statusByCall[callEnum]?.status, [ CallStatus.PENDING, CallStatus.PENDING_QUEUED ])
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusPendingQueued = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.status === CallStatus.PENDING_QUEUED
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusReady = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.status === CallStatus.READY
);

/**
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusPendingOrBigger = (callEnum: number, statusByCall: GenericStatusByCall) => (
  (statusByCall[callEnum]?.status ?? -1) >= CallStatus.PENDING_QUEUED
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusReadyOrBigger = (callEnum: number, statusByCall: GenericStatusByCall) => (
  (statusByCall[callEnum]?.status ?? -1) >= CallStatus.READY
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusUIPending = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.status === CallStatus.UI_PENDING
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusUIDone = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.status === CallStatus.UI_DONE
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * If `customCallStatusData` given both `callEnum` and `statusByCall` are ignored
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 * @param customCallStatusData Custom value customCallStatusData that is alread read from `statusByCall`.
 */
export const isCallStatusError = (
  callEnum: number | null,
  statusByCall: GenericStatusByCall | null,
  customCallStatusData?: CallState,
) => (
  (statusByCall?.[callEnum!] ?? customCallStatusData ?? {})?.status === CallStatus.ERROR
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const getCallErrorCodeOrFalse = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.status === CallStatus.ERROR
    ? statusByCall[callEnum]!.errorCode
    : false
);

/**
 * Usually used in `mapToState`. Examples can be found in .tsx files, here is a quick reference:
 *   const mapStateToProps = (state: RootState) => ({
 *     ...
 *     isHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
 *   });
 * @param callEnum The call to check status for - ReportingCall, FundamentalCall, CRMCall etc.
 * @param statusByCall Where to get the status from - for example state.reporting.statusByCall[callEnum] should return the status information for callEnum: ReportingCall
 */
export const isCallStatusOneOf = (callStatus: number, values: CallStatus[]) => (
  isOneOf(callStatus, values)
);

/**
 * Used only in `checkInitialLoadEpic` of `payment/epics.ts`
 */
export function isAllStartupPaymentCallsDone(statusByCall: GenericStatusByCall<PaymentCall>) {
  let result = true;
  const allCalls = [
    PaymentCall.getPaymentsAll,
    PaymentCall.getAvailableCash,
    PaymentCall.getIndividualLinkedAccounts,
  ];
  for (let callKey of allCalls) {
    if (!statusByCall[callKey] || !isCallStatusReadyOrBigger(callKey, statusByCall)) {
      result = false;
      break;
    }
  }
  return result;
}

export const checkIfNotFoundError = (callEnum: number, statusByCall: GenericStatusByCall) => (
  statusByCall[callEnum]?.errorCode === HttpStatusCodeEnum.NotFound
);

/**
 *
 * @param callEnum The enum of the call - for example ReportingCall, CRMCall, FundamentalCall etc.
 * @param callName As a string.
 * @param status A status enum or `null`. If `null` then old status is used. This happens when only setting `customData` for example
 * @param statusByCall The object where data is kept and will be read and updated.
 * @param errorCode The code of the error when available.
 * @param errorsLog The log to append - if provided.
 * @param customData If available.
 */
export function setCallStatus(
  callEnum: number,
  callName: string,
  status: CallStatus | null,
  statusByCall: GenericStatusByCall,
  errorCode?: NullableNumber,
  errorsLog?: string[],
  customData?: any,
) {
  const data = statusByCall[callEnum];
  const dateTime = new Date().toISOString();
  let callsCount: number = data?.callsCount ?? 0;
  let theStatus: CallStatus = status ?? data?.status ?? CallStatus.INITIAL;

  if (status === CallStatus.PENDING && data?.status !== CallStatus.PENDING) {
    callsCount++;
  } else if (status === CallStatus.PENDING && data?.status === CallStatus.PENDING) {
    theStatus = CallStatus.PENDING_QUEUED;
  }

  if (data && !errorCode) {
    data.status = theStatus;
    data.statusName = CallStatus[theStatus] as CallStatusName;
    data.dateTime = dateTime;
    data.callsCount = callsCount;
    data.lastCustomData = customData;
    if (data.errorCode) delete data.errorCode;
    return;
  }

  // eslint-disable-next-line no-param-reassign
  statusByCall[callEnum] = {
    callName,
    status: theStatus,
    statusName: CallStatus[theStatus] as CallStatusName,
    errorCode,
    dateTime,
    callsCount,
    lastCustomData: customData,
  };
  if (errorCode != null && errorsLog) appendToErrorsLog(`Error in call ${callName} - ${errorCode}`, null, errorsLog);
}

export function extractStatusNamesFromStatusByCall(statusByCall: GenericStatusByCall) {
  const result: TypedObject<string | undefined> = {};
  if (!statusByCall) return result;

  for (const callEnum in statusByCall) {
    const callStatusData = statusByCall[callEnum];
    if (callStatusData) {
      result[callStatusData.callName] = formatCallStatus(callStatusData);
    }
  }

  return result;
}

export function formatCallStatus(data: CallState | undefined | null) {
  const { errorCode, statusName } = data ?? {};
  if (isCallStatusError(null, null, (data ?? {}) as any)) {
    return `${statusName} (${errorCode})`;
  }
  return statusName;
}
