import { isString } from 'lodash';

import {
  LinkedAccountStatusEnum,
  PaymentMethodEnum,
  PaymentProcessEnum,
  PaymentStatusEnum,
  TimeInForceEnum,
} from '../enums';
import { OrderTypeEnum } from '../enums/order-type.enum';
import { AdjustmentCategoryEnum } from '../enums/payments/adjustment-category.enum';

import { AllowedFileExtensions, NullableString } from './types';

export class TextHelpers {
  static getLinkedAccountStatusName(status: number) {
    switch (status) {
      case LinkedAccountStatusEnum.New:
        return 'Pending';
      case LinkedAccountStatusEnum.Active:
        return 'Active';
      case LinkedAccountStatusEnum.Inactive:
        return 'Inactive';
      default:
        return '';
    }
  }

  static getPaymentStatusName(status: number) {
    switch (status) {
      case PaymentStatusEnum.New:
      case PaymentStatusEnum.Pending:
      case PaymentStatusEnum.OnHold:
      case PaymentStatusEnum.ForApproval:
      case PaymentStatusEnum.Processed:
      case PaymentStatusEnum.Approved:
        return 'Pending';
      case PaymentStatusEnum.Completed:
        return 'Completed';
      case PaymentStatusEnum.Canceled:
      case PaymentStatusEnum.CanceledByClient:
        return 'Canceled';
      case PaymentStatusEnum.Rejected:
        return 'Rejected';
      default:
        return '';
    }
  }

  static getPaymentProcessName(id: number) {
    switch (id) {
      case PaymentProcessEnum.Deposit:
        return 'Deposit';
      case PaymentProcessEnum.Withdrawal:
        return 'Withdrawal';
      case PaymentProcessEnum.Transfer:
        return 'Transfer';
      case PaymentProcessEnum.Adjustment:
        return 'Adjustment';
      default:
        return '';
    }
  }

  static getAdjustmentCategoryName(id: string) {
    switch (id) {
      case AdjustmentCategoryEnum.ShortMarginInterest:
        return 'Short Margin Interest';
      case AdjustmentCategoryEnum.OvernightBorrow:
        return 'Overnight Borrow';
      case AdjustmentCategoryEnum.StockLocate:
        return 'Stock Locate';
      case AdjustmentCategoryEnum.MarketData:
        return 'Market Data';
      default:
        return id;
    }
  }

  static getPaymentMethodName(id: number) {
    switch (id) {
      case PaymentMethodEnum.BankTransfer:
        return 'Bank Transfer';
      case PaymentMethodEnum.Cash:
        return 'Cash';
      case PaymentMethodEnum.eWallet:
        return 'eWallet';
      case PaymentMethodEnum.Card:
        return 'Card Transfer';
      case PaymentMethodEnum.InternalTransfer:
        return 'Internal Transfer';
      default:
        return '';
    }
  }

  static getTimeInForceName(timeInForce: string) {
    switch (timeInForce) {
      case TimeInForceEnum.EndOfDay:
        return 'End of Day';
      case TimeInForceEnum.ExtendedHours:
        return 'Extended Hours';
      case TimeInForceEnum.GTD:
        return 'Good till Date';
      case TimeInForceEnum.GTC:
        return 'Good till Cancelled';
      default:
        return '';
    }
  }

  static getOrderTypeName(type: string) {
    switch (type) {
      case OrderTypeEnum.Market:
        return 'Market';
      case OrderTypeEnum.Limit:
        return 'Limit';
      case OrderTypeEnum.Stop:
        return 'Stop';
      case OrderTypeEnum.StopLimit:
        return 'Stop Limit';
      case OrderTypeEnum.Pegged:
        return 'Pegged';
      default:
        return '';
    }
  }

  static getAllowedFileExtensionNames(): string {
    return (
      AllowedFileExtensions
        .map((extension: string) => ` ${extension}`)
        .join(',')
    );
  }
}

export function trimObjectProperties(object: any) {
  if (!object) return object;

  let result: any = { };
  for (const key in object) {
    const element = object[key];
    if (isString(element)) {
      if (element.indexOf(PROPS_DELIMITER) > 0) {
        result[key] = compactPropsIntoString(parsePropsFromString(element));
      } else {
        result[key] = element.trim();
      }
    } else {
      result[key] = element;
    }
  }

  return result;
}

const PROPS_DELIMITER = '|';

export const parsePropsFromString = (value: NullableString, autoTrim = true) => {
  if (!value || !isString(value)) return [ '', '' ];

  let result = (
    value
      .split(PROPS_DELIMITER) ?? [ '', '' ]
      .map(item => item ?? '')
  );

  if (autoTrim) result = result.map(s => s.trim());

  return result;
};

export const compactPropsIntoString = (items: NullableString[], autoTrim = true) => {
  let result = items.map(item => item ?? '');
  if (autoTrim) result = result.map(item => item.trim());

  return result.join(PROPS_DELIMITER);
};

export const popularGroupTitleToName = (title: string) => title.replace(/ /gi, '-').toLowerCase();
