import React, { useState } from 'react';

import {
  EnrollAddresses,
  EnrollDocumentDetails,
  EnrollPersonalDetails,
} from '../../../../components';
import { EnrollContentProps } from '../EnrollContentProps.types';

const PersonalDetailsContent = ({ progressBar, progressBarDispatch }: EnrollContentProps): JSX.Element => {
  const [isBack, setIsBack] = useState<boolean>(false);

  switch (progressBar.currentStepIndex) {
    case 1:
      return (
        <EnrollPersonalDetails
          isBack={isBack}
          setIsBack={setIsBack}
          progressData={progressBar}
          progressBarDispatch={progressBarDispatch}
        />
      );
    case 2:
      return (
        <EnrollAddresses
          setIsBack={setIsBack}
          progressData={progressBar}
          progressBarDispatch={progressBarDispatch}
        />
      );
    case 3:
      return (
        <EnrollDocumentDetails
          progressData={progressBar}
          progressBarDispatch={progressBarDispatch}
        />
      );
    default:
      return <></>;
  }
};

export default PersonalDetailsContent;
