import React from 'react';
import { Col, Row } from 'reactstrap';

import styles from './DividedPage.module.scss';

interface IProps {
  rightSideComponent: React.ReactNode | React.ReactNodeArray;
  leftSideComponent: React.ReactNode | React.ReactNodeArray;
}

function DividedPage({ rightSideComponent, leftSideComponent }: IProps) {
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col className={styles.leftSide} xl={5} lg={7} md={12} sm={12} xs={12}>
          <div className={styles.borderContainer}>{leftSideComponent}</div>
        </Col>

        <Col className={styles.rightSide} xl={7} lg={5} md={12} sm={12} xs={12}>
          {rightSideComponent}
        </Col>
      </Row>
    </>
  );
}

export default DividedPage;
