export enum SourceOfFundEnum {
  SalaryAlaric = 17,
  SelfEmploymentAlaric = 18,
  AtHomeTradingAlaric = 19,
  FamilyMemberAlaric = 20,
  InheritanceAlaric = 21,
  InvestmentsAndSavingsAlaric = 22,
  PensionOrBenefitProgramAlaric = 23,
  LoanAlaric = 24,
  AtHomeTradingFlb = 9,
  SelfEmploymentFlb = 10,
  InheritanceFlb = 11,
  ParentalAllowanceFlb = 12,
  PartnerIncomeFlb = 13,
  PensionOrGovernmentBenefitsFlb = 14,
  InvestmentsAndSavingsFlb = 15,
  TrustFundsFlb = 16
}
