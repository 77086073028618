import config from '../../config';
import { News } from '../models/news';
import { processErrorInResponse, processResponse } from '../store/helpers';
import {
  latestNewsLoadCompleted,
  latestNewsLoadFailed,
  newsForSymbolLoadCompleted,
  newsForSymbolLoadFailed,
} from '../store/news';
import { NullableString } from '../util/types';

import { BaseRequestService } from './BaseRequest.service';
import { INewsService, RxJsData } from './types';

const API_URLS = {
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   * This is an outdated integration. We fetch news from the WS.
   */
  getNews: '/News/latest/',
  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   * This is an outdated integration. We fetch news from the WS.
   */
  getNewsFromCache: '/News/latest/from-cache',
};

export class NewsService extends BaseRequestService implements INewsService {
  protected getBaseUrl(): string {
    return config.news.url;
  }

  /**
   * @deprecated Integrated before but there is no usage in WEB and MOBILE
   * This is an outdated integration. We fetch news from the WS.
   */
  // eslint-disable-next-line default-param-last
  public getNews(symbol: NullableString, pageSize = 5, page?: number, rxJsData?: RxJsData) {
    const params = { pageSize, pageNumber: page };
    const { action, observer } = rxJsData || {};

    const callName = 'news/getNews';
    let successAction: any;
    let failAction: any;
    let promise;
    const isLatestNews = symbol == null;

    if (isLatestNews) {
      successAction = latestNewsLoadCompleted;
      failAction = latestNewsLoadFailed;
      promise = this.api.get<News[]>(API_URLS.getNewsFromCache, params);
    } else {
      successAction = newsForSymbolLoadCompleted;
      failAction = newsForSymbolLoadFailed;
      promise = this.api.get<News[]>(API_URLS.getNews + symbol, params);
    }

    promise
      .then(result => {
        processResponse(callName, null, result, observer!, successAction, failAction, false, symbol);
      })
      .catch(error => {
        processErrorInResponse(callName, error, action, observer!, failAction);
      });
  }
}
