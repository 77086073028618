import { DebugSlice } from './lib/debug/helpers/types';
import { NullableString } from './lib/util/types';

export const origConsole = { ...console };

// ===========   DEVELOP Options   ===========

export const MOCK: any = {
  ENABLED: false,
  TEST_HEADER: false,

  TRADING_FORCE_FILL: true, // if true or undefined - does not include rejected status when progressing order status
  TRADING_MODE: 'up', // random, up, down, static - random is default, disabled if TRADING_MDS_TRADE_SEQUENCE is defined for the mdsTickNo
  TRADING_OMS_REFRESH_RATE: 4, // in seconds, default - ~ 2-4 seconds
  TRADING_MDS_REFRESH_RATE: 1, // in seconds, default - ~ 2-4 seconds
  TRADING_MDS_TRADE_SEQUENCE: [ // custom updates to prices
    // { B: 148.1, T: 150.2, A: 152.3 }
  ],
  TRADING_NEW_ORDER: 'accepted', // 'accepted' or 'rejected'

  THROW_ON_ERROR: true,
  GATEWAY: {
    disableConnect: false,
    disableReconnect: false,
  },
  setEnabled: (value: boolean) => {},
  setProp: (propName: string, value: boolean) => {},
};
MOCK.setEnabled = (value: boolean) => { MOCK.ENABLED = value; };
MOCK.setProp = (propName: string, value: boolean) => { MOCK[propName] = value; };

// Error handling
// react-native-exception-handler
export const errorHandling = false;
export const errorHandlingPopup = true;
export const redScreenInDevMode = true;
// react warn and error popups
export const popupErrors = true;
export const popupErrorsFilter = null;

// Logging
export const logConfig: any = {
  enabled: true,
  throttleTradePriceUpdates: false,
  tradePriceUpdates: false,
  tradePriceParsed: false,
  tradePriceCacheUpdates: false,
  tradePriceCacheUpdatesSymbol: null,
  throttleNbboPriceUpdates: false,
  nbboPriceUpdates: false,
  nbboPriceParsed: false,
  instrumentSnapshot: false,
  gatewayWSMessages: false,
  gatewayWSMessagesPriceUpdates: true,
  gatewayRESTCacheRequest: false,
  gatewayRESTCacheResponse: false,
  actions: false,
  chart: false,
  pushNotifications: false,
  subscriptionsFlow: false, // marked as [s-flow] in Reactotron logs
  priceFlow: false, // marked as [price-flow] in Reactotron and console logs
  entityDebugEnabled: [
    // 'trade',
    // 'nbbo',
    // 'subscribes',
    // 'visible-symbols',
    // 'instrument-snapshot',
    // 'market-state',
    // 'market-timer',
    // 'big-chart',
    // 'small-chart',
    // 'order-info',
    // 'stats',
    // 'countries',
    // 'top-gainers',
    // 'top-losers',
  ] as DebugSlice[],
  entityWriteTime: false,
  debugNetworkAndDataEnabled: false,
  callsCacheDebugEnabled: false,
  callsCacheDebugFilterInclude: /.*/i,
  callsCacheDebugFilterExclude: /getCompany/i as RegExp | boolean, // set to false to include everything
};
// Redux actions logging
export const reduxLogging = window.location.search.includes('redux') || false;
export const collapsedReduxLogging = true;
export const reduxLoggingInclude = /applic|serv|appload|popular|watchl|chart|subscr|login/i; // true - include all, RegExp - match it
export const reduxLoggingExclude = /NBBOReceived|tradeDataReceived/; // false - don't exclude anything, RegExp - match it
// log filtering (filter console.log calls)
export const logFiltering = false;
export const logFilterOptions = {
  include: /next|prev|action|@@@/, // redux actions and marked with @@@
  includeToJSON: /@@@JSON/,
  exclude: {
    log: false,
    info: false,
    debug: /priceNBBOReceived/,
    error: false,
  },
  showNonString: false,
  toDebug: true, // logs filtered out to console.debug
  toReactotron: false, // duplicate console.log calls to Reactotron
  toReactotronWarn: false, // duplicate console.warn calls to Reactotron
  toReactotronError: false, // duplicate console.error calls to Reactotron
  heartbeat: false,
};
/** Rectotron - async-storage, api-sauce, console.tron.log etc.
 * More details at:
 * (1) app/util/ReactotronConfig.ts
 * (2) https://github.com/infinitered/reactotron/tree/master/docs
 */
export const reactotronEnabled = true;
export const tronLog = {
  t: true,
  md: /login|query|subscr|bbo|ohlcv/,
  gw: false,
};

export function setLogConfig(options: NullableString) {
  require('./debugLogConfig').default(options, logConfig); // eslint-disable-line global-require
}
