export enum AMSCall {
  getAllMessages,
  getSummary,
  getMessage,
  deleteMessages,
  markAllAsRead,
  markAsRead,
  markAsReadByMetadata,
  notifyUnreadCount,
}

export type AMSCallName = keyof typeof AMSCall
