import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../lib/store';
import { getEnrollRecoveryPhase } from '../../lib/store/selectors';

import { Preloader } from '../../components';
import EnrollHeader from '../../components/Enroll/EnrollHeader/EnrollHeader';
import { EnrollProgressBarInitialData } from '../../components/Enroll/ProgressBar/ProgressBar.constants';
import { EnrollProgressBarReducer } from '../../reducers/EnrollProgressBar/EnrollProgressBarReducer';
import { EnrollProgressBarInitialiseAction } from '../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';

import EnrollContent from './EnrollContent/EnrollContent';

import styles from './Enroll.module.scss';

const Enroll = (): JSX.Element => {
  const dispatch = useDispatch();

  const sections = useSelector((state: RootState) => state.crm.enroll.financialQuestionnaireSections);
  const enrollProgressInfo = useSelector((state: RootState) => state.crm.enroll.enrollProgressInfo);
  const individual = useSelector((state: RootState) => state.crm.individualExtendedInfo);

  const [progressBar, progressBarDispatch] = useReducer(EnrollProgressBarReducer, EnrollProgressBarInitialData);
  const [isBack, setIsBack] = useState<boolean>(false);

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [triggeredForceUpdate, setTriggeredForceUpdate] = useState<boolean>(false);
  const [stepAndPhase, setStepAndPhase] = useState({ phase: 1, step: 1 });

  const isComponentFullyInitialised = isInitialised && (progressBar.phases.length > 1);

  useEffect(() => {
    if (!triggeredForceUpdate) {
      const redirectData = getEnrollRecoveryPhase(individual);

      let currentPhaseIndex = redirectData.phase;
      let currentStepIndex = redirectData.step;
      if (enrollProgressInfo.isOpenLastQuestions) {
        currentPhaseIndex = 3;
        currentStepIndex = 5;
      } else if (enrollProgressInfo.isNoExperienceWithStock) {
        currentPhaseIndex = 3;
        currentStepIndex = 4;
      }

      if (redirectData) {
        setStepAndPhase({ phase: currentPhaseIndex, step: currentStepIndex });
      }

      setTriggeredForceUpdate(true);
    }
  }, [individual, isInitialised, sections, triggeredForceUpdate, enrollProgressInfo]);

  useEffect(() => {
    if (!isInitialised) {
      setIsInitialised(true);
    } else {
      progressBarDispatch({
        type: EnrollProgressBarInitialiseAction,
        payload: { sections, ...stepAndPhase },
      });
    }
  }, [dispatch, stepAndPhase, isInitialised, sections]);

  return (
    <>
      <EnrollHeader
        visibleProgressBar
        progressBarData={progressBar}
        isParentInitialised={isComponentFullyInitialised}
      />
      {isComponentFullyInitialised ? (
        <div className={styles.wrapperEnrollContent}>
          <EnrollContent
            progressBar={progressBar}
            progressBarDispatch={progressBarDispatch}
          />
        </div>
      ) : (
        <div className={styles.preloader}>
          <Preloader owner="Enroll" />
        </div>
      )}
    </>
  );
};

export default Enroll;
