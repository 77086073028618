export enum PredefinedMilisecondsTypeEnum {
  twoHundredMilliseconds = 200,
  halfSecond = 500,
  oneSecond = 1000,
  twoSeconds = 2000,
  threeSeconds = 3000,
  fourSeconds = 4000,
  fiveSeconds = 5000,
  sixSeconds = 6000,
  sevenSeconds = 7000,
  eightSeconds = 8000,
  nineSeconds = 9000,
  tenSeconds = 10000,
  fifteenSeconds = 15000,
  twentySeconds = 20000,

  oneMinute = 60000,
  twoMinutes = 120000,
  threeMinutes = 180000,
  fourMinutes = 240000,
  fiveMinutes = 300000,
  tenMinutes = 600000,
  fifteenMinutes = 90000,
  twentyMinutes = 1200000,

  oneHour=3600000,
  twoHour=7200000,

  oneDay=86400000,
  oneWeek=604800000,
  fifteenDays = 1296000000,
  oneMonth=2592000000, // 30days
}
