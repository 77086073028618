import * as React from 'react';

import { TVChartContainer } from './TVChartContainer';

import styles from './ChartWrapper.module.scss';

const App = () => (
  <div
    className={styles.chartContainer}
  >
    <TVChartContainer />
  </div>
);

export default App;
