import React, { useEffect, useState } from 'react';
import {
  Rect,
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import lang from '../../../lib/language';
import { Order } from '../../../lib/models/trading/types';
import { DAILY_MAX_DOMAIN, MAX_VALUE } from '../../../lib/store/market-data/charting/constants';
import {
  calculateYLabelValue,
  oneValueRounding,
  scatterLabel,
  tickFormatCalculation,
} from '../../../lib/store/market-data/charting/helpers';
import { ChartCombinedPointData, ChartDataRange, ChartDataSet } from '../../../lib/store/market-data/charting/types';
import { formatOrdinateValues } from '../../../lib/store/reporting/charting/helpers';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { addOrdersToLineData, roundValue } from '../../../lib/util/ChartingHelpers';
import { formatNumber } from '../../../lib/util/DataHelpers';
import { checkChartRangeHasTime } from '../../../lib/util/DateTimeHelpers';
import { useLineClose } from '../../../lib/util/hooks/TradingDataHooks';

import { useIsMobile } from '../../../hooks';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';
import {
  CHART_CONTAINER_PADDING,
  CHART_CONTAINER_WIDTH,
  LINE_CLOSE_LABEL_X,
  LINE_CLOSE_LABEL_Y_ABOVE,
  LINE_CLOSE_LABEL_Y_UNDER,
  MOCKED_ORDERS,
  VICTORY_ZERO_VALUE,
} from '../constants';
import GradientFill from '../GradientFill';

import styles from './ChartContainer.module.scss';

type Props = {
  dataSet?: ChartDataSet;
  symbol: string;
  range: ChartDataRange;
  hasNoData: boolean;
  handleBtnRetry: () => void;
}

const USE_CHART_ANIMATION = false;

let lineCloseLocation;

export const ChartContainer = ({ dataSet, symbol, range, hasNoData, handleBtnRetry }: Props) => {
  const isMobile = useIsMobile();
  const [isRetryLoading, setIsRetryLoading] = useState<boolean>(false);
  const [hasHigherTooltip, setHasHigherTooltip] = useState<boolean>(false);
  const [lineClose, setLineClose] = useState(TradePriceCache.get(symbol)?.priceData.lineClose);
  useLineClose(symbol, lineClose, setLineClose, 'SymbolDetails/ChartContainer');
  const hasAllData = !!(lineClose && dataSet?.isValid && dataSet?.lineData.length);
  const lineCloseData = hasAllData ? dataSet?.lineData : [];
  const lastClosePoint = hasAllData ? lineCloseData[lineCloseData.length - 1].close : null;
  const lineCloseLabelAlign: 'under' | 'above' = (!hasAllData || lineClose < lastClosePoint!) ? 'under' : 'above';

  useEffect(() => {
    setHasHigherTooltip(['1W', '1M' ].includes(range) && isMobile);
  }, [isMobile, range]);

  if (hasNoData && !dataSet?.lineData.length) {
    // TODO: Add proper screen for invalid data case
    return (
      <>
        <div className={styles.noData} style={{ flexDirection: 'column' }}>
          {isRetryLoading ? lang.commonMessageLoading() : lang.commonMessageNoData()}
          {hasNoData && !isRetryLoading && (
            <Button
              variant={ButtonTypesEnum.Link}
              onClick={() => {
                setIsRetryLoading(true);
                handleBtnRetry();
                setTimeout(() => {
                  setIsRetryLoading(false);
                }, 1000);
              }}
            >
              {lang.commonWatchlistRetry()}
            </Button>
          )}
        </div>
      </>
    );
  }
  const {
    step,
    barData,
    lineData,
    lineDiff,
    minLineValue,
    axisValue,
    yLabelPoints,
    lineDataRatio,
    tickValuesWeb: tickValues,
    maxChartPointWeb,
  } = dataSet!;

  const combineDataAndOrders: ChartCombinedPointData[] = addOrdersToLineData(lineData, MOCKED_ORDERS);

  const tooltipDx = ['1W', '1M'].includes(range!) ? -76 : -63;
  let fillColor: string = '';
  if (lineData.length) {
    fillColor = '#3BB0FF';
  }

  if (lineClose) {
    lineCloseLocation = calculateYLabelValue(
      lineClose, lineDiff, minLineValue, lineDataRatio,
    );
  } else {
    return (
      <div className={styles.rootContainerStyle} />
    );
  }

  const calculateLineClose = () => {
    const closeUnder = isMobile ? 35 : LINE_CLOSE_LABEL_Y_UNDER;
    const closeAbove = isMobile ? -35 : LINE_CLOSE_LABEL_Y_ABOVE;
    return lineCloseLabelAlign === 'under' ? closeUnder : closeAbove;
  };

  const calculateChartPadding = () => {
    if (formatNumber(lineClose).toString().length > 5) return 120;
    return 105;
  };

  const victoryScatterSize = isMobile ? 10 : 7;

  const tooltipDy = (): number => {
    if (isMobile) {
      return hasHigherTooltip ? 75 : 60;
    }
    return -7;
  };

  const tooltipPadding = (orders: Order[]) => {
    let left = 0;
    let right = 0;

    if (isMobile) {
      left = hasHigherTooltip ? 30 : 20;
      right = hasHigherTooltip ? -10 : 10;
    } else {
      left = orders ? 30 : 40;
      right = orders ? 0 : -10;
    }

    return (
      { top: 12, left, right, bottom: 12 }
    );
  };

  const flyoutHeight = (): number | undefined => {
    if (isMobile) {
      return hasHigherTooltip ? 150 : 120;
    }
    return undefined;
  };

  return (
    <div className={styles.rootContainerStyle}>
      <VictoryChart
        height={isMobile ? 500 : 300}
        width={CHART_CONTAINER_WIDTH}
        padding={isMobile
          ? { right: calculateChartPadding(), top: 50 }
          : { ...CHART_CONTAINER_PADDING, top: 50 }}
        maxDomain={{
          x: range === '1D' ? DAILY_MAX_DOMAIN : lineData.length,
          y: maxChartPointWeb,
        }}
        minDomain={{ x: 0, y: 0 }}
        containerComponent={(<VictoryVoronoiContainer voronoiDimension="x" mouseFollowTooltips />)}
      >
        <VictoryAxis
          dependentAxis
          fixLabelOverlap
          tickValues={
              lineDiff === 0 ? [lineDataRatio * MAX_VALUE, (lineDataRatio * MAX_VALUE + MAX_VALUE) / 2, MAX_VALUE]
                : yLabelPoints?.map(el => calculateYLabelValue(el, lineDiff, minLineValue, lineDataRatio))
            }
          tickFormat={(t) => {
            if (lineDiff === 0) {
              return formatOrdinateValues(
                Number(oneValueRounding(t, lineDataRatio, yLabelPoints[0], yLabelPoints[1], yLabelPoints[2], step)),
              );
            }
            const value = tickFormatCalculation(t, lineDiff, minLineValue, lineDataRatio);
            return formatOrdinateValues(Number(roundValue(value, step)));
          }}
          orientation={isMobile ? 'right' : 'left'}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: isMobile ? 36 : 12, fill: '#A0A3B9' },
          }}
        />
        {!!lineClose && (
        <VictoryLine
          animate={undefined}
          data={[
            { x: 0, y: lineCloseLocation },
            { x: range === '1D' ? DAILY_MAX_DOMAIN : lineData.length - 1, y: lineCloseLocation },
          ]}
          style={{ data: { stroke: '#c6c8da', strokeDasharray: 3 } }}
        />
        )}
        <VictoryGroup data={lineData}>
          <GradientFill color={fillColor} id={'chart'} />
          <VictoryLine
            width={CHART_CONTAINER_WIDTH}
            standalone={false}
            animate={USE_CHART_ANIMATION ? {
              duration: 500,
              onLoad: { duration: 0 },
            } : undefined}
            style={{
              data: {
                stroke: fillColor,
                strokeWidth: isMobile ? 5 : 2,
              },
            }}
          />
          <VictoryArea
            width={CHART_CONTAINER_WIDTH}
            standalone={false}
            style={{ data: { fill: 'url(#chart)' } }}
            animate={USE_CHART_ANIMATION ? {
              duration: 500,
              onLoad: { duration: 0 },
            } : undefined}
          />
          <VictoryBar
            style={{
              data: {
                fill: '#A0A3B9',
                width: ({ active }) => (active ? 1 : VICTORY_ZERO_VALUE),
              } }}
          />
        </VictoryGroup>
        {!!lineClose && (
          <VictoryScatter
            data={[{ x: 0, y: lineCloseLocation }]}
            labels={() => formatNumber(lineClose)}
            style={{ data: { fill: 'white' } }}
            labelComponent={(
              <VictoryLabel
                dx={isMobile ? 80 : LINE_CLOSE_LABEL_X}
                dy={calculateLineClose()}
                backgroundComponent={<Rect rx={5} ry={5} />}
                style={{ fill: 'white', fontSize: isMobile ? 30 : 15 }}
                className={styles.lineClose}
                backgroundStyle={{ fill: 'rgba(59, 176, 255, 0.4)', filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))', border: '2px solid green' }}
                backgroundPadding={isMobile ? 15 : { top: 10, bottom: 5, left: 8, right: 8 }}
              />
            )}
          />
        )}
        <VictoryBar
          data={barData}
          animate={USE_CHART_ANIMATION ? {
            duration: 500,
            onLoad: { duration: 0 },
          } : undefined}
          style={{
            data: { fill: '#1D3960', width: 4 },
          }}
          labels={[isMobile ? '' : 'Vol']}
          labelComponent={(
            <VictoryLabel
              style={[
                { fontSize: 16, fill: '#A0A3B9' },
              ]}
              textAnchor="end"
              x={35}
              y={285}
            />
          )}
        />
        <VictoryScatter
          data={combineDataAndOrders}
          standalone={false}
          containerComponent={<VictoryVoronoiContainer />}
          size={({ active }) => (active ? victoryScatterSize : 0)}
          style={{ data: {
            fill: fillColor,
            stroke: '#fff',
            strokeWidth: 3,
          } }}
          labels={({ datum }) => {
            let result = scatterLabel(datum, range);
            if (hasHigherTooltip) {
              const data = result[2].split(' ');
              result = [result[0], result[1], ...data];
            }
            return result;
          }}
          labelComponent={(
            <VictoryTooltip
              dy={tooltipDy()}
              constrainToVisibleArea={isMobile}
              dx={isMobile ? 84 : undefined}
              flyoutWidth={isMobile ? 146 : undefined}
              flyoutHeight={flyoutHeight()}
              cornerRadius={6}
              flyoutPadding={({ datum: { orders } }) => tooltipPadding(orders)}
              flyoutStyle={{ fill: '#2665A4', stroke: '#3BB0FF', strokeWidth: 2 }}
              labelComponent={(
                <VictoryLabel
                  lineHeight={1.4}
                  textAnchor="start"
                  dx={tooltipDx}
                  style={[
                    { fill: '#fff', fontSize: isMobile ? 25 : undefined },
                    { fill: ({ datum: { orders } }) => (orders && orders.length > 1 ? '#fff' : '#CBD5E0'), fontSize: isMobile ? 25 : undefined },
                    { fill: '#CBD5E0', fontSize: isMobile ? 25 : undefined },
                    { fill: '#CBD5E0', fontSize: isMobile ? 25 : undefined },
                  ]}
                />
              )}
            />
          )}
        />
        <VictoryScatter
          data={combineDataAndOrders.filter(el => el.orders)}
          size={9}
          labels={({ datum: { orders } }) => {
            if (orders.length === 1) {
              return orders[0].side === 'SELL' ? '-' : '+';
            }
            return '...';
          }}
          labelComponent={(
            <VictoryLabel dy={({ datum: { orders } }) => {
              if (orders.length === 1) {
                return orders[0]?.side === 'SELL' ? 9 : 12;
              }
              return 5;
            }}
            />
          )}
          style={{ data: {
            fill: fillColor,
            stroke: '#fff',
            strokeWidth: 3,
            opacity: 1,
          },
          labels: { fill: '#fff', fontSize: 25 },
          }}
        />
        {!isMobile && (
          <VictoryAxis
            tickFormat={index => axisValue[index]}
            tickValues={tickValues}
            crossAxis={false}
            style={{
              axis: { stroke: '#A0A3B9' },
              ticks: { stroke: '#A0A3B9', size: (datum) => (axisValue[datum?.tick] ? 5 : 0) },
              tickLabels: { fontSize: 15, padding: 0, fill: '#A0A3B9' },
            }}
          />
        )}
      </VictoryChart>
    </div>
  );
};
