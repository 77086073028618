import moment from 'moment/moment';

export const validateUnderEighteen = (value: string): boolean => {
  const date = moment(value);

  if (!date.isValid()) {
    return false;
  }

  const now = moment();
  const diff = now.diff(date, 'years');

  return diff >= 18;
};
