import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { PredefinedMilisecondsTypeEnum } from '../../lib/enums/predefined-miliseconds-type.enum';
import { RootState } from '../../lib/store';
import { getMarketIndexes as storeGetMarketIndexes } from '../../lib/store/fundamental';
import { marketIndexesArray } from '../../lib/store/fundamental/constants';
import { formatCurrency, formatNumber } from '../../lib/util/DataHelpers';
import { isMarketOpen } from '../../lib/util/MarketDataHelpers';

import { useIsMobile } from '../../hooks';

import styles from './MarketIndexes.module.scss';

type Item = {
  symbol: string
  changesPercentage: number
  price: number | string
}

type Props = ReduxProps;

const MarketIndexes = (props: Props) => {
  const { indexes, getMarketIndexes } = props;
  const isMobile = useIsMobile();

  useEffect(() => {
    getMarketIndexes();
    let interval;

    if (isMarketOpen()) {
      interval = setInterval(getMarketIndexes, PredefinedMilisecondsTypeEnum.tenSeconds);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
}, [isMarketOpen]); //eslint-disable-line

  const getShowName = (symbol: string): string | undefined => {
    const item = marketIndexesArray.find((obj) => obj.symbol === symbol);
    return item?.shownAs;
  };

  return (
    <div className={styles.wrapper}>
      {indexes.map((item: Item) => (
        <div key={item.symbol} className={styles.card}>
          {isMobile ? (
            <>
              <div className={styles.nameAndPercentageWrapper}>
                <div className={styles.name}>
                  {getShowName(item.symbol)}
                </div>
              </div>
              {item.changesPercentage > 0 ? (
                <div className={styles.positivePercentage}>
                  +
                  {formatNumber(item.changesPercentage)}
                  %
                </div>
              ) : (
                <div className={styles.negativePercentage}>
                  {formatNumber(item.changesPercentage)}
                  %
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.nameAndPercentageWrapper}>
                <div className={styles.name}>
                  {getShowName(item.symbol)}
                </div>
                {item.changesPercentage > 0 ? (
                  <div className={styles.positivePercentage}>
                    +
                    {formatNumber(item.changesPercentage)}
                    %
                  </div>
                ) : (
                  <div className={styles.negativePercentage}>
                    {formatNumber(item.changesPercentage)}
                    %
                  </div>
                )}
              </div>
              <div className={styles.price}>
                {formatCurrency(item.price)}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  marketIndexData: state.fundamental.marketIndexes,
  indexes: state.fundamental.marketIndexes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMarketIndexes: () => dispatch(storeGetMarketIndexes({ symbols: marketIndexesArray.map((item) => item.symbol) })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(MarketIndexes);
