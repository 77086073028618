import React from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../../../lib/language';

import { Button } from '../../../../components';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './Modal.module.scss';

 type Props = {
     title: string,
     descriptionComponent?: React.ReactElement;
     description1?: string,
     description2?: string
}

const Modal = ({ title, descriptionComponent, description1, description2 }:Props) => {
  const history = useHistory();

  return (
    <div className={styles.wrapperModal}>
      <h6 className={styles.title}>{title}</h6>
      {descriptionComponent}
      {!descriptionComponent && (
        <>
          <span className={styles.description}>{description1}</span>
          <span className={styles.description}>{description2}</span>
        </>
      )}
      <Button
        fullWidth
        onClick={() => history.push(UI_ROUTES.funds)}
      >
        {lang.commonOkay()}
      </Button>
    </div>
  );
};

export default Modal;
