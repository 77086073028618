import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../lib/store/index';
import { ChartDataRange } from '../../../lib/store/market-data/charting/types';
import { NetComponent } from '../../../lib/store/reporting/types';
import { getChartPnL, getChartTotalValue } from '../../../lib/store/selectors';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';

const useCurrentValue = (
  showPerformance: boolean,
  chartValue: number,
  netComponent: string,
  currentRange: ChartDataRange,
  hasMyStocksOrderUpdate: boolean,
) => {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const priceCache = TradePriceCache.useCustom();
  const totalNetValue = useSelector<RootState, number>(
    (state: RootState) => getChartTotalValue(state, priceCache),
  );
  const totalPnLValue = useSelector<RootState, number>(
    (state: RootState) => getChartPnL(state, priceCache, currentRange),
  );

  useEffect(() => {
    if (!hasMyStocksOrderUpdate) {
      if (showPerformance) {
        setCurrentValue(netComponent === NetComponent.NetIncome ? totalNetValue : totalPnLValue);
      } else {
        setCurrentValue(chartValue);
      }
    }
  }, [chartValue, hasMyStocksOrderUpdate, netComponent, showPerformance, totalNetValue, totalPnLValue]);

  return { currentValue };
};

export default useCurrentValue;
