import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { RootState } from '../../../lib/store';
import { FundamentalCall } from '../../../lib/store/fundamental/types';
import { isCallStatusPending } from '../../../lib/util/error-handling/StatusByCallHelpers';

import Preloader from '../../Preloader/Preloader';

import WatchListTable from './WatchListTable';

type Props = ReduxProps;

const PopularWatchList = (props: Props) => {
  const { popularStocks } = props;

  const filteredStocks = popularStocks.map(({ popularity, symbolUniverseSymbol, ...rest }) => rest);

  return (
    <>
      { popularStocks.length === 0
        ? <Preloader text={''} owner="popular watchlist" />
        : <WatchListTable watchListStocks={filteredStocks} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isGettingPopularStocks: isCallStatusPending(FundamentalCall.getPopularStocks, state.fundamental.statusByCall),
  popularStocks: state.fundamental.popularStocks,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PopularWatchList);
