import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { OrderSideEnum, TimeInForceEnum } from '../../../lib/enums';
import { OrderTypeEnum } from '../../../lib/enums/order-type.enum';
import lang from '../../../lib/language';
import { MarketState } from '../../../lib/models/market-data/types';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { Order } from '../../../lib/models/trading/types';
import { RootState } from '../../../lib/store';
import {
  getAccountTradeHistory,
  getOpenPositions as getPositions,
  setHasOrderUpdate,
} from '../../../lib/store/reporting';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, OpenPosition } from '../../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../../lib/store/selectors';
import MarketStateCache from '../../../lib/store-util/MarketStateCache';
import NBBOPriceCache from '../../../lib/store-util/NBBOPriceCache';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency, formatNumber } from '../../../lib/util/DataHelpers';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';
import { isMarketClosed, isMarketOpen, isMarketRegularHours } from '../../../lib/util/MarketDataHelpers';
import { checkSymbolPostionType } from '../../../lib/util/my-account-helpers';
import { TextHelpers } from '../../../lib/util/TextHelpers';
import { uuidv4 } from '../../../lib/util/tools';
import { getOrderTotalValue } from '../../../lib/util/TradingValidationsHelper';

import { Button, Error, NotEnoughBuyingPower } from '../..';
import { ButtonTypesEnum } from '../../Button/Button.types';
import HighLowLimitPriceAlert from '../../HighLowLimitPriceAlert/HighLowLimitPriceAlert';
import SharesCalculator from '../../SharesCalculator/SharesCalculator';
import ExpiryDate from '../components/ExpiryDate';
import LimitPriceInput from '../components/LimitPriceInput';
import MarketPriceInfo from '../components/MarketPriceInfo';
import { getValidationPrice } from '../OrderHelpers';
import useValidateOrder from '../useValidateOrder';

import styles from './ModifyOrder.module.scss';

type OwnProps = {
  symbol: string;
  orderSide: string;
  orderType: string;
  initialPrice: number;
  orderQuantity: number;
  parentOrderId: string;
  tradingSessionId: string;
  initialTimeInForce: string;
  onCloseModal: () => void;
  setOrder: (order: Order) => void;
  setModifyOrderRequested: (hasModifiedOrder: boolean) => void;
}
type Props = OwnProps & ReduxProps;

function ModifyOrder(props: Props) {
  const {
    symbol,
    positions,
    orderSide,
    orderType,
    buyingPow,
    initialPrice,
    orderQuantity,
    parentOrderId,
    tradingSessionId,
    initialTimeInForce,
    isOpenPositionsReady,
    isPendingOrdersReady,
    setOrder,
    getOrders,
    onCloseModal,
    myStocksUpdate,
    getOpenPositions,
    setModifyOrderRequested,
  } = props;

  const isBuyOrder = orderSide === OrderSideEnum.Buy;

  const { marketState } = MarketStateCache.use();
  const marketClosed = isMarketClosed(marketState);
  const showMarketInfoIcon = isMarketOpen(marketState);

  const [hasUpdatesOrders, setHasUpdatesOrders] = useState<boolean>(false);
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [criticalError, setCriticalError] = useState<string>('');
  const [targetPriceError, setTargetPriceError] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [targetPrice, setTargetPrice] = useState<number>(initialPrice);
  const [timeInForce, setTimeInForce] = useState<string>(TextHelpers.getTimeInForceName(initialTimeInForce));
  const [includeExtendedHours, setIncludeExtendedHours] = useState<boolean>(tradingSessionId === 'X');
  const [showBP, setShowBP] = useState<boolean>(true);
  const [openedEnoughBPModal, setOpenedEnoughBPModal] = useState<boolean>(false);
  const [visibleHighLowLimitPriceAlert, setVisibleHighLowLimitPriceAlert] = useState<boolean>(false);
  const nbboData = NBBOPriceCache.use(symbol);
  const { askprice, bidprice } = nbboData;
  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'CreateOrder');
  const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice, isBuyOrder);
  const { errorMessage, controlCheck } = useValidateOrder(orderType, validationPirce ?? 0, targetPrice, isBuyOrder);

  const positionType = checkSymbolPostionType(symbol, positions);

  useEffect(() => {
    if (positionType) {
      if ((orderSide === OrderSideEnum.Buy && positionType === OrderSideEnum.Sell)
      || (orderSide === OrderSideEnum.Sell && positionType === OrderSideEnum.Buy)) {
        setShowBP(false);
      } else {
        setShowBP(true);
      }
    } else {
      setShowBP(orderSide === OrderSideEnum.Buy);
    }
  }, [orderSide, positionType]);

  const totalValue = getOrderTotalValue(
    orderType,
    quantity,
    tradePrice,
    targetPrice,
  );

  useEffect(() => {
    if (hasUpdatesOrders && ((isPendingOrdersReady && isOpenPositionsReady && !marketClosed) || marketClosed)) {
      setModifyOrderRequested(true);
    }
  }, [marketClosed, hasUpdatesOrders, setModifyOrderRequested, isPendingOrdersReady, isOpenPositionsReady]);

  const isValidOrder = useCallback((qty: number, buyingPower: number): boolean => {
    setTargetPriceError(errorMessage);

    const buyingPowerCheck = buyingPower > totalValue;

    if (showBP && (controlCheck && !buyingPowerCheck)) {
      setOpenedEnoughBPModal(true);
    }

    if (!showBP) {
      return (orderQuantity >= qty) && controlCheck;
    }

    return buyingPowerCheck && controlCheck;
  }, [controlCheck, errorMessage, orderQuantity, showBP, totalValue]);

  const onReviewOrderClicked = () => {
    setCriticalError('');
    setTargetPriceError('');

    const price = tradePrice ?? 0;
    if (isValidOrder(quantity, buyingPow)) {
      const orderObject: Order = {};

      if (orderType === OrderTypeEnum.Stop) {
        orderObject.stopPrice = targetPrice;
      }

      const parsedTime = timeInForce === TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay)
        ? TimeInForceEnum.EndOfDay : TimeInForceEnum.GTC;

      orderObject.clientOrderId = uuidv4();
      orderObject.symbol = symbol;
      orderObject.side = orderSide as OrderSideEnum;
      orderObject.orderQty = quantity;
      orderObject.ordType = orderType as OrderTypeEnum;
      orderObject.price = targetPrice;
      orderObject.timeInForce = parsedTime;
      orderObject.originalClientOrderId = parentOrderId;
      orderObject.includeExtendedHours = includeExtendedHours;

      if (orderType === OrderTypeEnum.Limit && !visibleHighLowLimitPriceAlert) {
        if (
          (isBuyOrder && (orderObject.price > price))
          || (!isBuyOrder && (orderObject.price < price))
        ) {
          setVisibleHighLowLimitPriceAlert(
            isBuyOrder ? (orderObject.price > price) : (orderObject.price < price),
          );

          return;
        }
      }
      setHasUpdatesOrders(true);
      myStocksUpdate(true);
      if (!marketClosed) {
        getOpenPositions();
      }
      setOrder(orderObject);
      getOrders({ symbol, tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
    }
  };

  useEffect(() => {
    if (!isInitialised) {
      setQuantity(orderQuantity);
      setIsInitialised(true);
    }
  }, [isInitialised, orderQuantity]);

  return (
    <>
      <div className={styles.wrapperOrderContainer}>
        <h6 className={styles.title}>{`${lang.commonModify()} ${symbol} ${orderSide} ${lang.commonTradingOrder()}`}</h6>
        <div className={styles.wrapperOrderType}>
          <div>{lang.commonTradingOrderType()}</div>
          <div className={styles.rigthAligned}>{orderType}</div>
        </div>
        <div className={styles.wrapperNumberOfShares}>
          <div className={styles.numberOfShares}>
            <div>{lang.mobileTradingTicketNumberOfShares()}</div>
            {!showBP && (
            <p className={styles.numberOfSharesSubText}>
              {
            isBuyOrder
              ? lang.webTradingShortedStocks(formatNumber(orderQuantity, true))
              : lang.webTradingAvailableShares(formatNumber(orderQuantity, true))
          }
            </p>
            )}
          </div>
          <div className={styles.wrapperSharesField}>
            <SharesCalculator
              quantity={quantity}
              setQuantity={setQuantity}
              maxQuantity={!showBP ? orderQuantity : undefined}
            />
          </div>
        </div>
        <LimitPriceInput
          initialPrice={initialPrice}
          orderType={orderType}
          targetPriceError={targetPriceError}
          setTargetPrice={(value) => setTargetPrice(value)}
          setTargetPriceError={(value) => setTargetPriceError(value)}
        />
        <MarketPriceInfo
          askPrice={askprice}
          bidPrice={bidprice}
          isMyAccount
          symbol={symbol}
          tradePrice={tradePrice}
          isMarketOpen={showMarketInfoIcon}
        />
        <ExpiryDate
          isMarketHours={isMarketRegularHours(marketState)}
          time={timeInForce}
          extendedHours={includeExtendedHours}
          positionsBySymbol={positions.find((position: OpenPosition) => (position.symbol === symbol))}
          orderType={orderType}
          orderSide={orderSide}
          setTimeInForce={(value) => setTimeInForce(value)}
          setIncludeExtendedHours={(value) => setIncludeExtendedHours(value)}
        />
        <div className={styles.horizontalLine} />
        <div className={styles.availableBuyingContainer}>
          <div>{lang.commonTradingOrderValue()}</div>
          <div className={styles.rigthAligned}>
            {formatCurrency(totalValue)}
          </div>
        </div>
        {showBP && (
        <div className={styles.availableBuyingContainer}>
          <div>{lang.mobileOrderCreateBuyingPower()}</div>
          <div className={styles.rigthAligned}>{formatCurrency(buyingPow)}</div>
        </div>
        )}
        {(criticalError.trim() !== '') && (
        <div className={styles.errorWrapper}>
          <Error
            hiddenIcon
            errorMessage={criticalError}
          />
        </div>
        )}
        <div className={styles.buttons}>
          <Button
            fullWidth
            variant={ButtonTypesEnum.Tertiary}
            onClick={onCloseModal}
          >
            {lang.commonCancelButton()}
          </Button>
          <Button fullWidth onClick={onReviewOrderClicked}>
            {lang.commonConfirmOrderButton()}
          </Button>
        </div>
        { openedEnoughBPModal && <NotEnoughBuyingPower closeModal={() => setOpenedEnoughBPModal(false)} /> }
        {visibleHighLowLimitPriceAlert && (
        <HighLowLimitPriceAlert
          isBuyOrder={isBuyOrder}
          processModal={() => {
            onReviewOrderClicked();
          }}
          toggleModal={() => setVisibleHighLowLimitPriceAlert(false)}
        />
        )}
      </div>
    </>
  );
}

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  isOpenPositionsReady: isCallStatusReady(ReportingCall.getOpenPositions, state.reporting.statusByCall),
  isPendingOrdersReady: isCallStatusReady(ReportingCall.getAccountPendingOrders, state.reporting.statusByCall),
  buyingPow: getCurrentBuyingPower(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOpenPositions: () => dispatch(getPositions()),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ModifyOrder);
