import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { CallStatus } from '../../lib/enums/call-status.enum';
import { TopGainerAndLoserData, TopGainerAndLoserPayload } from '../../lib/models/fundamental/types';
import { RootState } from '../../lib/store';
import { getTopGainers, getTopLosers } from '../../lib/store/fundamental';
import { FundamentalCall } from '../../lib/store/fundamental/types';
import { isCallStatusError } from '../../lib/util/error-handling/StatusByCallHelpers';

import { FILTER_RANGE_OPTIONS } from '../../containers/TopGainersAndLosers/components/FilterDropDown/FilterDropDownOptions';
import { useIsMobile } from '../../hooks';
import { usePrevious } from '../../util/helpers';
import { NoDataAvailable, Preloader } from '../index';

import SlideButtons from './components/slideButtons/SlideButtons';
import SymbolTradeInfoCard from './components/symbolTradeInfoCard/SymbolTradeInfoCard';

import styles from './SymbolTradeInfo.module.scss';

type OwnProps = {
  currentTab: number;
  optionFilter: number[];
  topMovers: TopGainerAndLoserData[];
  isInitialStateOptionFilter: boolean;
  isTopMoversTimeout: boolean;
  isUILoading: boolean;
}

type Props = OwnProps & ReduxProps;

const SymbolTradeInfo = (props: Props) => {
  // TODO : This component will be simplified within ART-3785
  const {
    topMovers,
    currentTab,
    optionFilter,
    isGettingTopMoversFailed,
    isTopMoversTimeout,
    isInitialStateOptionFilter,
    statusByCallTopGainers,
    statusByCallTopLosers,
    getTopGainersData,
    getTopLosersData,
    isUILoading,
  } = props;
  const isMobile = useIsMobile();
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [changeFilter, setChangeFilter] = useState<boolean>(true);

  const handlePreLoader = () => (
    <div className={styles.loader}>
      <Preloader isFullScreen text="" owner="TopMovers" />
    </div>
  );

  const prevAmount = usePrevious({ topMovers });
  useEffect(() => {
    if (prevAmount?.topMovers !== topMovers) {
      setChangeFilter(false);
    }
  }, [prevAmount, topMovers]);

  const renderTopMoverssData = (index: number) => {
    const cardsTopMovers = (topMoversData: TopGainerAndLoserData[]) => {
      // TODO : this logic should be simplified within ART-3785
      if (!isGettingTopMoversFailed && (isUILoading || (!topMovers.length && !isTopMoversTimeout)
        || statusByCallTopGainers?.status === CallStatus.PENDING || (changeFilter && !isTopMoversTimeout))) {
        return handlePreLoader();
      }
      if (!topMovers.length
        || isGettingTopMoversFailed
        || statusByCallTopLosers?.status === CallStatus.ERROR
        || (changeFilter && isTopMoversTimeout)) {
        return <div className={styles.noDataAvailable}><NoDataAvailable /></div>;
      }

      return (
        topMoversData.map((item: TopGainerAndLoserData) => {
          if (item?.symbol) {
            return (
              <SymbolTradeInfoCard
                data={item}
                key={item.symbol}
              />
            );
          }
          return null;
        })
      );
    };

    return (
      <>
        {
          !index
            ? cardsTopMovers(topMovers.slice(0, 5))
            : cardsTopMovers(topMovers.slice(5, 10))
        }
      </>
    );
  };

  const handleSlideIndex = (index: number) => {
    setSlideIndex(index);
  };

  useEffect(() => {
    setSlideIndex(0);
  }, [currentTab, optionFilter]);

  useEffect(() => {
    if (isInitialStateOptionFilter) {
      !currentTab
        ? getTopGainersData({ minValue: FILTER_RANGE_OPTIONS[2][0], maxValue: FILTER_RANGE_OPTIONS[2][1] })
        : getTopLosersData({ minValue: FILTER_RANGE_OPTIONS[2][0], maxValue: FILTER_RANGE_OPTIONS[2][1] });
    } else {
      !currentTab
        ? getTopGainersData({ minValue: optionFilter[0], maxValue: optionFilter[1] })
        : getTopLosersData({ minValue: optionFilter[0], maxValue: optionFilter[1] });
      setChangeFilter(true);
    }
  }, [currentTab, optionFilter, isInitialStateOptionFilter, getTopGainersData, getTopLosersData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {renderTopMoverssData(slideIndex)}
        {
          topMovers.length > 0 && !changeFilter && !isMobile && (
            <SlideButtons
              handleSlideIndex={handleSlideIndex}
              slideIndex={slideIndex}
              topMovers={topMovers}
            />
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  statusByCallTopGainers: state.fundamental.statusByCall[FundamentalCall.getTopGainers],
  statusByCallTopLosers: state.fundamental.statusByCall[FundamentalCall.getTopLosers],
  isGettingTopMoversFailed: isCallStatusError(FundamentalCall.getTopLosers, state.fundamental.statusByCall)
    || isCallStatusError(FundamentalCall.getTopGainers, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getTopGainersData: (filter: TopGainerAndLoserPayload) => dispatch(getTopGainers(filter)),
  getTopLosersData: (filter: TopGainerAndLoserPayload) => dispatch(getTopLosers(filter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SymbolTradeInfo);
