import { produce } from 'immer';
import { isEmpty, random } from 'lodash';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { CallStatus } from '../enums/index';
import { GWMarketDataSummaryBucket } from '../models/gateway/types';
import { OHLCV } from '../models/market-data/types';
import { EMPTY_CHART_DATA } from '../store/market-data/charting/constants';
import { prepareChartData } from '../store/market-data/charting/helpers';
import { ChartDataPayload, ChartDataPortion, ChartDataRange, LoadChartDataPayload, SmallChartData } from '../store/market-data/charting/types';
import { isNumber } from '../util/DataHelpers';
import { gatewaySummaryBucketToChartRange } from '../util/GatewayHelpers';
import { NullableNumber, NullableString } from '../util/types';

import CallsCache from './calls-cache/CallsCache';
import MarketStateCache from './MarketStateCache';
import SmallChartDataCache from './SmallChartDataCache';
import TradePriceCache from './TradePriceCache';

export type BigChartData = {
  symbol: NullableString | string
  callsCount1D: number
  dataByRange: ChartDataPortion
  status: CallStatus
}

const INITIAL_STATE = {
  symbol: null,
  callsCount1D: 0,
  dataByRange: {} as ChartDataPortion,
  status: CallStatus.INITIAL,
};
const BigChartCache = entity<BigChartData>(INITIAL_STATE, entityReduxLogger('BigChart', 'big-chart'));

/** Used only by `loadChartData` reducer in market-data/index. */
export function updateBigChartCacheOnLoadChartData(
  actionPayload: LoadChartDataPayload,
  hasToMakeRequest: boolean,
  isPreCachedFromSmallChart: boolean,
) {
  const { bucket, symbol, hasRangeChanged } = actionPayload;
  const range = gatewaySummaryBucketToChartRange(bucket) as ChartDataRange;

  MarketStateCache.refresh();

  BigChartCache.set(
    produce((state: BigChartData) => {
      /* eslint-disable no-param-reassign */
      const hasSymbolChanged = state.symbol !== symbol;
      const isInvalidBucket = bucket === GWMarketDataSummaryBucket.Invalid || !bucket;
      const isOneDayBucket = bucket === GWMarketDataSummaryBucket.OneDay;

      if (isInvalidBucket) {
        console.warn(`[market-data/index] updateBigChartCacheOnLoadChartData - invalid bucket '${bucket}'`);
        return;
      }

      if (isOneDayBucket) {
        if (hasSymbolChanged) {
          state.callsCount1D = 1;
          state.dataByRange = { } as ChartDataPortion;
        } else {
          state.callsCount1D++;
        }
      }

      state.status = isPreCachedFromSmallChart && !hasToMakeRequest ? CallStatus.READY : CallStatus.PENDING;
      state.symbol = symbol;

      const lineClose = TradePriceCache.get(symbol).priceData?.lineClose;
      const smallChartCache = SmallChartDataCache.get(symbol) as SmallChartData;

      switch (true) {
        case isPreCachedFromSmallChart: {
          // Add pre-cached chart data for 1D from small chart when available
          state.dataByRange = {
            '1D': prepareChartData(lineClose, smallChartCache.ohlcvData, '1D'),
          };
          break;
        }

        case hasRangeChanged && hasToMakeRequest:
          state.dataByRange[range] = null;
          break;

        default:
          break;
      }

      const { __DEV__, isWeb } = require('../../configLib').default;
      const { logConfig } = require('../../configDebug');

      if (__DEV__ && logConfig.chart) {
        const debugTitle = `[BigChartCache] State updated on request for ${bucket}`;
        const debugData = {
          state: { ...state }, // a copy is used to make state (a Proxy) printable
          isPreCachedFromSmallChart,
          isEmpty: isEmpty(smallChartCache.ohlcvData),
          smallChartCache,
          callsCount1D: state.callsCount1D,
        };
        console.info(debugTitle);
        console.debug(debugTitle, debugData);
        console.tron.display!({ name: debugTitle, value: debugData });
        // eslint-disable-next-line prefer-spread
      }
      /* eslint-enable no-param-reassign */
    }),
  );
}

/** Used only by Gateway response message MarketDataSummaryResponse or DataSummaryResponse. */
export function updateBigChartCacheOnLoadChartDataCompleted(newData: ChartDataPayload, traceId: string) {
  CallsCache.processResponseByTraceId(traceId);
  BigChartCache.set(
    produce((state: BigChartData) => {
      /* eslint-disable no-param-reassign */
      const { data, bucket, symbol } = newData;
      const range = (isNumber(bucket) || !bucket ? null : bucket)?.substring(0, 2) as ChartDataRange;
      state.status = CallStatus.READY;
      if (generateDataIfMockedMode(state, data, range)) return;

      const { priceData } = TradePriceCache.get(symbol);
      const { lineClose } = priceData ?? {};
      const parsedData = prepareChartData(lineClose, data, range);

      state.dataByRange[range] = parsedData;

      const { isWeb, __DEV__ } = require('../../configLib').default;
      const { logConfig } = require('../../configDebug');
      if (__DEV__ && logConfig.chart) {
        // eslint-disable-next-line prefer-spread
        const debugTitle = `[BigChart] [Response] State updated from response for bucket: ${bucket}, range: ${range}`;
        const debugData = {
          newData,
          state: { ...state },
          callsCount1D: state.callsCount1D,
        };
        console.info(debugTitle);
        console.debug(debugTitle, debugData);
        console.tron.display!({
          name: debugTitle,
          value: debugData,
          important: false,
        });
      }
      /* eslint-enable no-param-reassign */
    }),
  );
}

export function updateBigChartLineClose(lineClose: NullableNumber) {
  if (lineClose == null) return;

  BigChartCache.set(
    produce((state: BigChartData) => {
      const data = state.dataByRange?.['1D'];
      if (data && !data.isValid && data.lineData?.length) {
        state.dataByRange['1D'] = prepareChartData(lineClose, data.rawData, '1D');
      }
    }),
  );
}

function generateDataIfMockedMode(state: BigChartData, data: OHLCV[], range: ChartDataRange) {
  const { MOCK } = require('../../configDebug');
  if (MOCK.ENABLED) {
    /* eslint-disable no-param-reassign */
    // 1W range is `null` - to demonstrate the no data case
    const randomLineClose = random(22.07, 135, true);
    state.dataByRange[range] = range === '1W'
      ? EMPTY_CHART_DATA(false)
      : prepareChartData(randomLineClose, data, range);

    MarketStateCache.refresh();
    /* eslint-enable no-param-reassign */

    return true;
  }

  return false;
}

// exporting setter only for unit testing and mocked mode
export const _setBigChartCache_InUnitTestsAndMockedMode = BigChartCache.set; // eslint-disable-line no-underscore-dangle

export default {
  use: BigChartCache.use,
  get: BigChartCache.get,
  clear: () => BigChartCache.set(INITIAL_STATE),
};
