import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views'; // eslint-disable-line
import { Action } from 'redux';

import lang from '../../lib/language';
import {
  getDividendPayers as getDividendPayersCompanies,
  getHighGrowthCompanies as getGrowthCompanies,
} from '../../lib/store/fundamental/index';
import { FundamentalCall } from '../../lib/store/fundamental/types';
import { RootState } from '../../lib/store/index';
import { isCallStatusReady } from '../../lib/util/error-handling/StatusByCallHelpers';

import { Button, DividendPayers, HighGrowthCompanies, Navigation, NoDataAvailable } from '../../components';
import { ButtonTypesEnum } from '../../components/Button/Button.types';
import { useIsMobile } from '../../hooks';
import { NavigationState } from '../../util/constants';
import { UI_ROUTES } from '../../util/routes';

import styles from './LongTermInvestments.module.scss';

const LongTermInvestments = (props: ReduxProps) => {
  const {
    highGrowthCompanies,
    dividendPayers,
    isDividendPayersStatusReady,
    isHighGrowthCompaniesStatusReady,
    getDividendPayers,
    getHighGrowthCompanies,
  } = props;
  const isMobile = useIsMobile();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const history = useHistory();

  const dividendsViewAllLink = {
    text: lang.commonViewAll(),
    link: UI_ROUTES.discover + UI_ROUTES.dividendPayersAndHighGrowthCompaniesPath,
    state: (dividendPayers.content.length && highGrowthCompanies.content.length)
      ? { currentTab: null, currentTabIndex: currentTab }
      : { currentTab },
  };

  useEffect(() => {
    if (!highGrowthCompanies.content.length || !dividendPayers.content.length) {
      getDividendPayers();
      getHighGrowthCompanies();
    }
  }, [dividendPayers.content.length, getDividendPayers, getHighGrowthCompanies, highGrowthCompanies.content.length]);

  const dividendsAndHighGrowthTabs = [
    {
      text: lang.commonDiscoverDividendPayers(),
      component: <DividendPayers dividendPayerContent={dividendPayers.content.slice(0, 10)} />,
    },
    {
      text: lang.commonDiscoverHighGrowthCompanies(),
      component: <HighGrowthCompanies companiesContent={highGrowthCompanies.content.slice(0, 10)} />,
    },
  ];

  const handleCurrentTabIndex = (index: number) => {
    !index
      ? setCurrentTab(0)
      : setCurrentTab(1);
  };

  const handlebuttonViewAll = () => {
    if (!currentTab) {
      return dividendPayers.content.length > 0 ? dividendsViewAllLink : undefined;
    }
    if (currentTab) {
      return highGrowthCompanies.content.length > 0 ? dividendsViewAllLink : undefined;
    }
    return undefined;
  };

  const maxCandleCount = isMobile ? 6 : 10;
  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.titleWrapper}>
            <h6 className={styles.title}>
              {isScrolled ? lang.commonDiscoverHighGrowthCompanies() : lang.commonDiscoverDividendPayers()}
            </h6>
            <div>
              <Button
                variant={ButtonTypesEnum.Link}
                onClick={() => {
                  if (dividendsViewAllLink.state) {
                    history.push({
                      pathname: dividendsViewAllLink.link,
                      state: {
                        [NavigationState]: dividendsViewAllLink.state,
                      },
                    });
                  } else {
                    history.push(dividendsViewAllLink.link);
                  }
                }}
                className={styles.viewAll}
              >
                {dividendsViewAllLink.text}
              </Button>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.cards}>
              <SwipeableViews onChangeIndex={(index) => {
                setIsScrolled(index > 0);
              }}
              >
                <div className={styles.card}>
                  <DividendPayers dividendPayerContent={dividendPayers.content.slice(0, maxCandleCount)} />
                </div>
                <div className={styles.card}>
                  <HighGrowthCompanies companiesContent={highGrowthCompanies.content.slice(0, maxCandleCount)} />
                </div>
              </SwipeableViews>
            </div>
          </div>
          <div className={styles.dotsWrapper}>
            {isScrolled ? (
              <>
                <span className={styles.inactiveDot} />
                <span className={styles.activeDot} />
              </>
            ) : (
              <>
                <span className={styles.activeDot} />
                <span className={styles.inactiveDot} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h6 className={styles.title}>{lang.commonDiscoverSubtitleLongTermInvestments()}</h6>
          {
                        (isDividendPayersStatusReady && isHighGrowthCompaniesStatusReady)
                        && (dividendPayers.content.length === 0 && highGrowthCompanies.content.length === 0)
                        && <div className={styles.noDataAvailable}><NoDataAvailable /></div>
                    }
          {
                        (isDividendPayersStatusReady && isHighGrowthCompaniesStatusReady)
                        && (dividendPayers.content.length > 0 || highGrowthCompanies.content.length > 0)
                        && (
                        <Navigation
                          tabs={dividendsAndHighGrowthTabs}
                          rightSideLink={handlebuttonViewAll()}
                          sendCurrentTabIndex={handleCurrentTabIndex}
                        />
                        )
                    }
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  highGrowthCompanies: state.fundamental.highGrowthCompanies,
  dividendPayers: state.fundamental.dividendPayers,
  isDividendPayersStatusReady:
        isCallStatusReady(FundamentalCall.getDividendPayers, state.fundamental.statusByCall),
  isHighGrowthCompaniesStatusReady:
        isCallStatusReady(FundamentalCall.getHighGrowthCompanies, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getHighGrowthCompanies: () => dispatch(getGrowthCompanies()),
  getDividendPayers: () => dispatch(getDividendPayersCompanies()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LongTermInvestments);
