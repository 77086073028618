import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lang from '../../../lib/language';
import { ConfirmedStepsData, ENROLL_REQUEST_STATE } from '../../../lib/models/enroll';
import { RootState } from '../../../lib/store';
import { autoLogin } from '../../../lib/store/common-actions';

import IconClose from '../../../assets/img/icon-close.svg';
import messageEffect2Image from '../../../assets/img/message_2_effects.png';
import { BaseModal, Button, ClickableImage } from '../../index';

import styles from './EmailConfirmedModal.module.scss';

type Props = {
  isOpened: boolean;
  confirmedSteps?: ConfirmedStepsData;
  authStatus?: ENROLL_REQUEST_STATE | undefined;
}

const EmailConfirmedModal = ({ isOpened, confirmedSteps, authStatus }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(isOpened);

  const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

  const descriptions = lang.commonOnboardingEmailConfirmedDescription1()
    .split(/\r\n|\r|\n/g)
    .map((description: string) => <p key={description} className={styles.text}>{description}</p>);

  useEffect(() => {
    if (redirectToLogin && (authStatus === ENROLL_REQUEST_STATE.READY)) {
      dispatch(autoLogin());
    }
  }, [dispatch, redirectToLogin, authStatus]);

  const closeModal = () => {
    history.push('/');
    setShowModal(!showModal);
  };

  // Here we send information about the confirmed email to the previous tab ( VerifyEmailModal )
  try {
    const channelEmailConfirmedModalPage = new BroadcastChannel('confirmedEmail');
    channelEmailConfirmedModalPage.postMessage(confirmedSteps?.email);
  } catch {
    console.error('BroadcastChannel is not supported by the browser');
  }

  return (
    <BaseModal isOpen={showModal}>
      <div className={styles.wrapperContent}>
        <h6 id="emailConfirmedTitle" aria-label="email confirmed title">
          {lang.commonOnboardingEmailConfirmedTitle()}
        </h6>
        <ClickableImage
          alt=""
          src={IconClose}
          aid="closeModalImg"
          aria-label="close email confirmed modal"
          className={styles.closeIcon}
          onClick={closeModal}
        />
        <img src={messageEffect2Image} alt="envelop icon with ring bell" className={styles.image} />
        {descriptions}
        <p className={styles.text}>{lang.commonOnboardingEmailConfirmedDescription2()}</p>
        <Button
          id="continue"
          fullWidth
          ariaLabel="continue"
          className={styles.button}
          onClick={() => {
            setRedirectToLogin(true);
          }}
        >
          {lang.commonContinueButton()}
        </Button>
      </div>
    </BaseModal>
  );
};

const mapStateToProps = (state: RootState): any => ({
  confirmedSteps: state.auth.enroll.confirmedSteps,
  authStatus: state.auth.enroll.status,
});

export default connect(mapStateToProps)(EmailConfirmedModal);
