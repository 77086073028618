import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { OrderTypeEnum } from '../../../lib/enums/order-type.enum';
import lang from '../../../lib/language';

import { Error } from '../..';

import styles from '../styles.module.scss';

type Props = {
  initialPrice: number;
  orderType: string;
  targetPriceError: string;
  setTargetPrice: (value: number) => void;
  setTargetPriceError: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const LimitPriceInput = ({
  disabled, orderType, initialPrice, targetPriceError, setTargetPrice, setTargetPriceError, onFocus, onBlur,
}: Props) => {
  const [isIosUser, setIsIosUser] = useState(false);
  // TODO: Create separate hook for this in HW-286
  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
      setIsIosUser(true);
    }
  }, []);

  return (
    <>
      <div className={styles.wrapperLimitMarketPrice}>
        <div className={disabled ? styles.limitInputDisabled : ''}>
          {orderType === OrderTypeEnum.Stop
            ? lang.mobileTradingTicketStopPrice()
            : lang.mobileTradingTicketLimitPrice()}
        </div>
        <div className={disabled ? styles.limitInputDisabled : styles.limitInput}>
          <CurrencyInput
            decimalSeparator={isIosUser ? ',' : '.'}
            groupSeparator={isIosUser ? '.' : ','}
            defaultValue={initialPrice}
            onValueChange={(amount) => {
              if (amount && isIosUser) {
                setTargetPrice(Number(amount.replace(',', '.')));
              } else {
                setTargetPrice(Number(amount));
              }
              setTargetPriceError('');
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            className={styles.wrapperPriceField}
            placeholder="$0.00"
            allowNegativeValue={false}
            prefix={'$'}
            maxLength={14}
          />
        </div>
      </div>
      {(targetPriceError.trim() !== '') && (
        <div className={styles.errorWrapper}>
          <Error hiddenIcon errorMessage={targetPriceError} />
        </div>
      )}
    </>
  );
};

export default LimitPriceInput;
