export type BasePageableRequest = {
  filters?: any,
  page?: number,
  size?: number,
  sort?: string
  sortAscending?: boolean;
}

export enum SortingType {
  ASC = 'asc',
  DESC = 'desc',
}

export const DEFAULT_PAGE_RESPONSE = {
  content: [],
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  numberOfElements: 0,
  status: 0,
  sort: null,
};
