import { isEmpty } from 'lodash';

import { LogLevel, LogType } from './types';

export class LoggerService {
  private static logger: LoggerService;

  private level: LogLevel = LogLevel.Error;

  private logTypes: LogType[] = [ LogType.Console ];

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    // In order to avoid having multiple instances constructor is set as private
  }

  static getLogger(): LoggerService {
    if (!this.logger) {
      this.logger = new LoggerService();
    }
    return this.logger;
  }

  setLevel(level: LogLevel): void {
    this.level = level;
  }

  setType(logType: LogType): void {
    this.logTypes = [ logType ];
  }

  addType(logType: LogType): void {
    this.logTypes.push(logType);
  }

  resetToDefault(): void {
    this.logTypes = [ LogType.Console ];
    this.level = LogLevel.Error;
  }

  private getMessageWithDate(message: any): string {
    return `${new Date().toISOString()} - ${message}`;
  }

  log(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Info) {
      return;
    }

    this.tronLogger(console.tron.log, message, params);
    this.consoleLogger(console.log, message, params);
    this.fileLogger(message, params);
  }

  trace(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Trace) {
      return;
    }

    this.tronLogger(console.tron.trace, message, params);
    this.consoleLogger(console.trace, message, params);
    this.fileLogger(message, params);
  }

  debug(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Debug) {
      return;
    }

    this.tronLogger(console.tron.debug, message, params);
    this.consoleLogger(console.debug, message, params);
    this.fileLogger(message, params);
  }

  info(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Info) {
      return;
    }

    this.tronLogger(console.tron.info, message, params);
    this.consoleLogger(console.info, message, params);
    this.fileLogger(message, params);
  }

  warn(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Warn) {
      return;
    }

    this.tronLogger(console.tron.warn, message, params);
    this.consoleLogger(console.warn, message, params);
    this.fileLogger(message, params);
  }

  error(message?: any, ...params: any[]): void {
    if (this.level < LogLevel.Error) {
      return;
    }

    this.tronLogger(console.tron.error, message, params);
    this.consoleLogger(console.error, message, params);
    this.fileLogger(message, params);
  }

  private tronLogger(loggerFunction: Function, message: any, params: any[]): void {
    if (!this.logTypes.includes(LogType.Tron)) {
      return;
    }

    this.printLog(loggerFunction ?? console.tron.log, message, params);
  }

  private consoleLogger(loggerFunction: Function, message: any, params: any[]): void {
    if (!this.logTypes.includes(LogType.Console)) {
      return;
    }
    this.printLog(loggerFunction ?? console.log, message, params);
  }

  private printLog(loggerFunction: Function, message: any, params: any[]): void {
    if (isEmpty(params)) {
      loggerFunction(this.getMessageWithDate(message));
      return;
    }

    loggerFunction(this.getMessageWithDate(message), params);
  }

  private fileLogger(message: any, ...params: any[]): void {
    if (!this.logTypes.includes(LogType.File)) {
      return;
    }
    throw new Error('Not implemented yet!');
  }
}
