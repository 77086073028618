export enum ExtendedInfoMockEnum {
  DEFAULT,
  NULL,
  ENROLL,
  ENROLL_1_1_PD,
  ENROLL_1_2_PD,
  ENROLL_2_1_FIN_QS,
  ENROLL_2_4_SOF,
  ENROLL_3_5_FIN_QS,
  ENROLL_VERIFICATION,
  ENROLL_DOCUMENT_VERIFICATION,
}
