class CrashReportingService {
  public static logError(errorName: string, error: Error) {
    // TODO:This will be impletented in ART-4119
  }

  public static logEvent(eventName: string) {
    // TODO:This will be impletented in ART-4119
  }

  public static crash() {
    // TODO:This will be impletented in ART-4119
  }
}

export default CrashReportingService;
