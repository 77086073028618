import React from 'react';

import lang from '../../../../../lib/language';

import { InfoStatusType } from '../helpers';

import styles from '../ChartHeader.module.scss';

const InfoStatus = ({ type }: { type: InfoStatusType }) => (
  <span className={styles.infoStatus}>
    { type === 'pre' && lang.commonMarketHoursPre() }
    { type === 'after' && lang.commonMarketHoursAfter() }
    { type === 'close' && lang.commonMarketHoursAtClose() }
  </span>
);

export default InfoStatus;
