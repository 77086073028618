/**
 * This slice is dedicated to Market Data Service API data.
 * The data is currently consumed through Gateway.
 * TODO: To be deprecated in favour of Gateway. Currently direct
 *       MDS connection is used for debugging purposes only.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyLogoAndNameResponse } from '../../models/fundamental/types';
import { OHLCV } from '../../models/market-data/types';
import { StockDescriptor } from '../../models/stock-descriptor';
import { AppStateStatus } from '../../store-util/AppStateCache';
import { appendToErrorsLog } from '../../util/error-handling/ErrorHandlingHelpers';
import {
  clearErrorsLog,
  DisconnectedPayload,
  loginFailed,
  logout,
  marketDataDisconnected,
  processSymbolSubscribesAction,
  processSymbolUnSubscribesAction,
  setAppStatus,
} from '../common-actions';

import {
  ChartDataPayload, LoadChartDataPayload,
} from './charting/types';
import {
  GetCustomWatchlistDataPayload,
  GetWatchlistDataPayload,
  MarketDataQueryPayload,
  ProcessSymbolSubscribesPayload,
  ProcessSymbolUnSubscribesPayload,
  UserFavoriteStocks,
} from './types';


export type MarketDataState = {
  connected: boolean;
  favouriteStocks: UserFavoriteStocks;
  companies: {[key: string]: string}; // TODO: Remove this quickfix for reloading watchlist
  stockHistory: OHLCV[];
  stockRecent: OHLCV[];
  errorsLog: string[];
  /**
   * For reference of Market State times see:
   *  (1) Google Presentation - https://docs.google.com/presentation/d/17j2erZF0NBqA67IF71b8ub0JK5p7-__OPhYCpyMRd00/edit#slide=id.g10947438435_0_36
   *  (2) Library constants at `alaric-retail-terminal-library/constants/marketHours.ts`
   */
  openPositionsSubscribed: boolean;
  isInitialPriceDataLoaded: boolean;
}
export const INITIAL_STATE: MarketDataState = {
  connected: false,
  favouriteStocks: {},
  companies: {}, // TODO: Remove this quickfix for reloading watchlist
  stockHistory: [],
  stockRecent: [],
  errorsLog: [],
  openPositionsSubscribed: false,
  /**
   * Marks if the main groups of symbols have price data loaded:
   *  1. WatchList (popular & favorites)
   *  2. Discover (top gainers, top losers, new on the market)
   *  3. My Account (my stocks - open positions)
   */
  isInitialPriceDataLoaded: false,
};

const marketDataSlice = createSlice({
  name: 'marketData',
  initialState: INITIAL_STATE,
  reducers: {
    connected(state) { state.connected = true; },
    /** Dispatched only by InitialSymbolsDataLoadingStatusCache.updateStatus */
    confirmInitialPriceDataLoaded(state) {
      state.isInitialPriceDataLoaded = true;
    },
    getWatchlistData(state, action: PayloadAction<GetWatchlistDataPayload>) {},
    getCustomWatchlistData(state, action: PayloadAction<GetCustomWatchlistDataPayload>) {},
    stockHistoryLoaded(state, action) {
      state.stockHistory = action.payload;
    },
    stockRecentLoaded(state, action) {
      state.stockRecent = action.payload;
    },
    loadChartData(state, action: PayloadAction<LoadChartDataPayload>) {},
    loadChartDataCompleted(state, action: PayloadAction<ChartDataPayload>) {},
    loadChartDataFailed(state, action) {
      appendToErrorsLog(`[market-data/index] loadChartDataFailed - ${JSON.stringify(action)}`, state);
    },
    marketDataError(state, action) {
      appendToErrorsLog(action.payload, state);
    },
    addCompanyNameToCache(state: MarketDataState, action: PayloadAction<StockDescriptor>) {
      const { symbol, companyName } = action.payload;

      if (companyName && symbol && !state.companies[symbol]) {
        state.companies[symbol] = companyName;
      } else if (!state.companies[symbol]) {
        console.warn('[market-data/index]: could not add company to cache - case 1, addCompanyNameToCache', action.payload);
      }
    },
    /**
     * The payload of the action has `symbols` - this is one symbol or comma separated list as string
     */
    addCompanyToCache(state: MarketDataState, action: PayloadAction<{
      responseData: CompanyLogoAndNameResponse[], symbols: string
    }>) {
      const companies = action.payload.responseData ?? [];
      const { symbols } = action.payload;

      if (companies.length > 0) {
        const symbolsArray: string[] = symbols.split(',');

        symbolsArray.forEach(item => {
          const { symbol, companyName } = companies.find(c => c.symbol === item) || {};

          if (symbol && companyName && !state.companies[item]) {
            state.companies[symbol] = companyName;
          } else if (!state.companies[symbol!]) {
            console.warn('[market-data/index] addCompanyToCache - could not add company to cache', { symbol, companyName });
          }
        });
      }
    },
    /** Used only by loadSymbolDataForInitialListsEpic */
    setInitialSymbolDataLoaded(state: MarketDataState) {
      state.isInitialPriceDataLoaded = true;
    },
    getMarketDataQuery(state: MarketDataState, action: PayloadAction<MarketDataQueryPayload>) {},
  },
  extraReducers: {
    [setAppStatus.type]: (
      state,
      action: PayloadAction<AppStateStatus>,
    ) => {
      if (action.payload === 'background') {
        state.isInitialPriceDataLoaded = false;
      }
    },
    [loginFailed.type]: state => { state.isInitialPriceDataLoaded = false; },
    [clearErrorsLog.type]: state => { state.errorsLog = []; },
    [marketDataDisconnected.type]: (
      state,
      action: PayloadAction<DisconnectedPayload>,
    ) => {
      state.connected = false;
      if (action.payload && action.payload?.message) {
        appendToErrorsLog(action.payload.message, state);
      }
    },
    [logout.type]: state => ({ ...INITIAL_STATE, errorsLog: state.errorsLog, favouriteStocks: state.favouriteStocks }),
    [processSymbolSubscribesAction.type]: (
      state,
      action: PayloadAction<ProcessSymbolSubscribesPayload>,
    ) => {
      const { symbolOrSymbols, isFavorites, userCorrelationId } = action.payload;

      const symbols = symbolOrSymbols.split(',');
      symbols.forEach(symbol => {
        if (isFavorites && userCorrelationId) {
          if (!state.favouriteStocks[userCorrelationId]) {
            state.favouriteStocks[userCorrelationId] = [ ...[], { symbol } ];
          } else {
            state.favouriteStocks[userCorrelationId] = [ ...state.favouriteStocks[userCorrelationId], { symbol } ];
          }
        }
      });
    },
    [processSymbolUnSubscribesAction.type]: (
      state,
      action: PayloadAction<ProcessSymbolUnSubscribesPayload>,
    ) => {
      const { symbolOrSymbols, isRemovingFromFavorites, userCorrelationId } = action.payload;
      const symbols = symbolOrSymbols.split(',');

      symbols.forEach(symbol => {
        if (isRemovingFromFavorites && userCorrelationId) {
          const filteredFavouriteStocks = state.favouriteStocks[userCorrelationId]
            .filter(item => item.symbol !== symbol);
          state.favouriteStocks[userCorrelationId] = filteredFavouriteStocks;
        }
      });
    },
  },
});

export const {
  connected,
  confirmInitialPriceDataLoaded,
  getMarketDataQuery,
  getWatchlistData,
  getCustomWatchlistData,
  stockHistoryLoaded,
  stockRecentLoaded,
  loadChartData,
  loadChartDataCompleted,
  loadChartDataFailed,
  marketDataError,
  addCompanyNameToCache,
  addCompanyToCache,
  setInitialSymbolDataLoaded,
} = marketDataSlice.actions;

export default marketDataSlice.reducer;
