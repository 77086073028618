import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { GWQueryResultStatus } from '../../models/gateway/types';
import { DEFAULT_PAGE_SIZE } from '../../models/news';
import GlobalService from '../../services/Global.service';
import { loadStock } from '../fundamental';
import { isNewsAvailable } from '../selectors';
import { GetNewsParams } from '../types';
import { RootState } from '..';

import {
  addCallToQueue,
  getLatestNews,
  getNewsForSymbol,
  latestNewsLoadCompleted,
  latestNewsLoadFailed,
  newsForSymbolLoadCompleted,
  newsForSymbolLoadFailed,
  removeCallFromQueue,
  resetIsLoading,
} from './index';


export let isLoadingLocked = false;
let pageNumber: number = 1;

const getNewsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getLatestNews.type, getNewsForSymbol.type, loadStock.type),
  mergeMap((action: PayloadAction<GetNewsParams>) => {
    let { symbol, page, pageSize, isQueued } = action.payload ?? {};

    const resultingPageSize = pageSize || DEFAULT_PAGE_SIZE;

    const news = (
      symbol != null
        ? (state$.value.news.newsForSymbols[symbol] || [])
        : state$.value.news.latestNews
    );

    if (news.length > 0) {
      pageNumber++;
    } else {
      pageNumber = 1;
    }

    const resultingPage = page || pageNumber;
    const hasMore = isNewsAvailable(state$.value, symbol, true);

    return new Observable((observer: Observer<any>) => {
      if (!isLoadingLocked) {
        if (hasMore) {
          isLoadingLocked = true;
          GlobalService
            .globalService
            .getNews(
              symbol,
              resultingPageSize,
              resultingPage,
              { action, observer, data: news },
            );
        } else {
          observer.next(resetIsLoading());
        }
      } else if (!isQueued) {
        observer.next(addCallToQueue(action.payload));
      }
    });
  }),
);

const newsLoadCompletedEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(
    latestNewsLoadCompleted.type,
    latestNewsLoadFailed.type,
    newsForSymbolLoadCompleted.type,
    newsForSymbolLoadFailed.type,
  ),
  mergeMap(action => new Observable((observer: Observer<any>) => {
    isLoadingLocked = false;

    const { status } = action.payload;
    const { queuedCalls } = state$.value.news;

    if (status === GWQueryResultStatus.READY && queuedCalls.length > 0) {
      let payload = { ...queuedCalls[0] };
      payload!.isQueued = true;
      let newAction = payload.symbol != null ? getNewsForSymbol : getLatestNews;

      observer.next(removeCallFromQueue());
      observer.next(newAction(payload));
    }
  })),
);

export default combineEpics(
  getNewsEpic,
  newsLoadCompletedEpic,
);
