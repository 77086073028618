import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import GlobalService from '../../services/Global.service';
import {
  processErrorInResponse,
  processResponse,
} from '../helpers';
import { getCurrentAccount } from '../selectors';
import { RootState } from '..';

import {
  getReportingFileByOrder,
  getReportingFileByOrderFailed,
  getReportingFileByOrderSuccess,
} from './index';
import { ReportingFilePayloadData } from './types';

const getReportingFileByOrderEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getReportingFileByOrder.type),
  mergeMap((action: PayloadAction<string>) => new Observable((observer: Observer<any>) => {
    const callName = 'download-order-confirmation-report';

    const accountReferenceId = getCurrentAccount(state$.value);
    const first_name = state$.value.crm.individualExtendedInfo?.first_name;
    const last_name = state$.value.crm.individualExtendedInfo?.last_name;

    let isHammerOrder = false;

    let parentOrderId = action.payload;
    if (parentOrderId.includes('Hammer_')) {
      parentOrderId = parentOrderId.replace('Hammer_', '');
      isHammerOrder = true;
    }

    const requestData: ReportingFilePayloadData = {
      accountReferenceId,
      parentOrderId,
      customerFullName: `${first_name} ${last_name}`,
      isHammerWebRequest: isHammerOrder,
    };

    GlobalService
      .reportingFileService
      .get(requestData)
      .then(((response: any) => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getReportingFileByOrderSuccess,
          getReportingFileByOrderFailed,
          false,
        );
      }))
      .catch(error => processErrorInResponse(callName, error, action, observer, getReportingFileByOrderFailed));
  })),
);

export default combineEpics(
  getReportingFileByOrderEpic,
);
