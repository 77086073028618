/**
 * Created to serve for initial loading of symbols only - watchlist (popular, favorites), discover (top movers, new stocks), my account (my stocks)
 */

import { produce } from 'immer';
import { Entity, entity } from 'simpler-state';

import { confirmInitialPriceDataLoaded } from '../store/market-data/index';

const INITIAL_STATE = {
  tradeSubscription: false,
  instrumentSnapshot: false,
  chart_1D5m: false,
  chart_3M1D: false,
  isConfirmed: false,
};
export type InitialSymbolsDataLoadingStatusType = typeof INITIAL_STATE;
export type InitialSymbolsDataLoadingStatusKey = keyof InitialSymbolsDataLoadingStatusType;

let InitialSymbolsDataLoadingStatusCache: Entity<InitialSymbolsDataLoadingStatusType | null> = (
  entity(INITIAL_STATE) as any
);

const self = InitialSymbolsDataLoadingStatusCache;

function checkIfDone() {
  const { tradeSubscription, instrumentSnapshot, chart_1D5m, chart_3M1D } = (
    self?.get() as InitialSymbolsDataLoadingStatusType
  );
  return tradeSubscription && instrumentSnapshot && chart_1D5m && chart_3M1D;
}

function get() {
  return self.get() as InitialSymbolsDataLoadingStatusType;
}

function updateStatus(key: InitialSymbolsDataLoadingStatusKey, value: boolean) {
  self?.set(produce(state => {
    state[key] = value;
  }));

  const { isConfirmed } = self.get() as InitialSymbolsDataLoadingStatusType;


  if (checkIfDone()) {
    if (!isConfirmed) {
      self?.set(produce(state => {
        state.isConfirmed = true;
      }));
    }
    const store = require('../store/index').default;
    store?.dispatch?.(confirmInitialPriceDataLoaded());
  }
}

export default {
  get,
  use: self.use,
  updateStatus,
  checkIfDone,
};
