
export function getExecutionStack() {
  return new Error('Execution Stack for caught Error').stack?.split('\n');
}

export function restResponseWrapper(data: any, status = 200, errorData = {}) {
  return {
    data,
    rc: status,
    status,
    ...errorData,
  };
}

// eslint-disable-next-line no-promise-executor-return
export const sleep = (milliSeconds: number) => new Promise(resolve => setTimeout(resolve, milliSeconds));
