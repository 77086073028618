import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { RootState } from '../../../../lib/store';
import { setIsChangeUserPassword } from '../../../../lib/store/auth';

import iconDocument from '../../../../assets/img/icon-document.svg';
import iconLock from '../../../../assets/img/icon-lock.svg';
import iconLogout from '../../../../assets/img/icon-logout.svg';
import iconPersonalDetails from '../../../../assets/img/icon-personal-details.svg';
import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import { BaseModal } from '../../..';
import { HeaderDropdownItem, MyProfileOptions } from '../../Header.types';

import ModalChangePassword from './modalChangePassword/modal/ModalChangePassword';

import styles from './MyProfile.module.scss';

const icons = [ iconPersonalDetails, iconDocument, iconLock, iconLogout ];

type Props = {
  dropdownItemsMyProfile: HeaderDropdownItem[],
  dropDownItem: string | null,
  handleClickBtnDropDown: () => void;
} & ReduxProps

const MyProfile = (props: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    isChangeUserPassword,
    dropdownItemsMyProfile,
    dropDownItem,
    setIsChangePassword,
    handleClickBtnDropDown,
  } = props;

  const handleModal = () => {
    setIsChangePassword(!isChangeUserPassword);
    handleClickBtnDropDown();
  };

  const handleClick = (dropdownItem) => {
    if (dropdownItem.id === MyProfileOptions.CHANGE_PASSWORD) {
      handleModal();
    } else if (dropdownItem.id === MyProfileOptions.LOG_OUT) {
      dropdownItem.action();
    } else {
      handleClickBtnDropDown();
    }
  };

  const getLink = (link: string): string => {
    if (!isMobile && link === UI_ROUTES.myAccountDocuments) {
      return UI_ROUTES.myAccountPersonalDetails;
    }
    return link;
  };

  return (
    <>
      <div className={styles.dropdownMenuItemsContainer}>
        {dropdownItemsMyProfile.map((dropdownItem: HeaderDropdownItem, index) => (
          <div key={dropdownItem.text} className={dropdownItem.text === dropDownItem ? styles.btnBackground : ''}>
            <div
              className={classNames(styles.dropdownMenuItemContainer,
                { [styles.border]: index === dropdownItemsMyProfile.length - 1 })}
            >
              <div className={styles.wrapperRow}>
                <img
                  className={dropdownItem.isDisabled ? styles.disabledIcon : ''}
                  src={icons[index]}
                  alt="icon my profile"
                />
                <Link
                  id={`profile_option_${index}`}
                  aria-label={`my profile option ${dropdownItem.text}`}
                  to={{
                    pathname: getLink(dropdownItem.link),
                    state: {
                      ...(history.location.state as object),
                      dropDownItem: dropdownItem.text,
                      pathname: history.location.pathname,
                    } }}
                  className={dropdownItem.isDisabled
                    ? styles.disabledDropdownMenuItemsText
                    : styles.dropdownMenuItemsText}
                  onClick={() => handleClick(dropdownItem)}
                >
                  {dropdownItem.text}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <BaseModal isOpen={isChangeUserPassword}>
        { isChangeUserPassword && <ModalChangePassword handleModal={handleModal} /> }
      </BaseModal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isChangeUserPassword: state.auth.isChangeUserPassword,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setIsChangePassword: (value: boolean) => dispatch(setIsChangeUserPassword(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MyProfile);
