import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus } from '../../../../lib/enums';
import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { registerLinkedAccount, setBankAccountIdByLastPayment, setPaymentCallStatusToInitial } from '../../../../lib/store/payment';
import { PaymentCall, RegisterLinkedAccountPayload } from '../../../../lib/store/payment/types';
import { TextHelpers } from '../../../../lib/util/TextHelpers';

import imageUploadBankStatement from '../../../../assets/bigImages/image_bank-statement.png';
import leftArrow from '../../../../assets/img/icon-arrow_back.svg';
import iconFilledCheck from '../../../../assets/img/icon-filled-check.svg';
import { BaseModal, Button, ClickableImage, Document, Preloader } from '../../../../components';
import UploadBankStatementImage from '../../../../components/UploadBankStatementImage/UploadBankStatementImage';
import { useBrowserWidth, useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import AddBankAccountScreen from '../../AddBankAccountScreen/AddBankAccountScreen';
import AddBankAccountModal from '../../makeYourFirstDeposit/modal/AddBankAccountModal';

import styles from './UploadBankStatement.module.scss';

type Props = {} & ReduxProps;

const UploadBankStatement = (props: Props) => {
  const {
    bankAccountDetailsOnCreate,
    requestRegisterLinkedAccount,
    setBankAccountIdValueByLastPayment,
    regLinkedAccount,
    setPaymentStatusByCall,
  } = props;

  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const locationState = history.location.state as { target: string, previousPage: string };

  const [uploadBackStatement, setUploadBackStatement] = useState<File>();
  const [hasRequest, setHasRequest] = useState<boolean>(false);
  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!isInitialised) {
      setPaymentStatusByCall();
      setIsInitialised(true);
    }
  }, [isInitialised, setPaymentStatusByCall]);

  const handleConfirm = () => {
    if (uploadBackStatement) {
      const dataToSaveRegLinkedAccount = {
        file: uploadBackStatement,
        iban: bankAccountDetailsOnCreate.account_info.iban,
        bankName: bankAccountDetailsOnCreate.account_info.bank_name,
        swiftCode: bankAccountDetailsOnCreate.account_info.bic,
        bankCountryId: bankAccountDetailsOnCreate.account_info.bank_country_id,
        currencyCode: bankAccountDetailsOnCreate.account_info.currency_code,
      };

      regLinkedAccount(dataToSaveRegLinkedAccount);
      setBankAccountIdValueByLastPayment();
      setHasRequest(true);
    }
  };

  useEffect(() => {
    if (hasRequest) {
      if (requestRegisterLinkedAccount && (requestRegisterLinkedAccount.status === CallStatus.ERROR)
      ) {
        const dataToSaveRegLinkedAccount = {
          file: uploadBackStatement,
          iban: bankAccountDetailsOnCreate.account_info.iban,
          bankName: bankAccountDetailsOnCreate.account_info.bank_name,
          swiftCode: bankAccountDetailsOnCreate.account_info.bic,
          bankCountryId: bankAccountDetailsOnCreate.account_info.bank_country_id,
          currencyCode: bankAccountDetailsOnCreate.account_info.currency_code,
        };
        history.push({
          pathname: UI_ROUTES.error,
          state: {
            errorCodeAddBankAccount: requestRegisterLinkedAccount.errorCode,
            target: location.pathname,
            dataToSaveRegLinkedAccount,
          },
        });
      } else if (requestRegisterLinkedAccount && requestRegisterLinkedAccount.status === CallStatus.READY) {
        setHasRequest(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRequest, requestRegisterLinkedAccount]);

  return (
    <>
      {hasRequest && <Preloader owner="Upload bank statement" isFullScreen stylePreset="customOne" />}
      <div className={styles.wrapperContainer}>
        <div className={styles.containerContent}>
          <div className={styles.wrapperContent}>
            <ClickableImage
              id="uploadBankStatementBackArrow"
              alt=""
              src={leftArrow}
              className={styles.leftArrow}
              onClick={() => history.push(UI_ROUTES.funds + UI_ROUTES.addYourBankAccount)}
            />
            { isMobile && <img className={styles.image} src={imageUploadBankStatement} alt="Bank Statement" />}
            <h3 className={styles.title} id="uploadBankStatementTitle">{lang.mobileAddBankAccUploadBankStatementBtn()}</h3>
            <div className={styles.descriptionWrapper}>
              <span className={styles.description}>
                {lang.commonUploadDocumentWebTitle1()}
              </span>
              <span className={styles.description}>
                {lang.commonUploadDocumentWebTitle2()}
              </span>
            </div>
            <div className={styles.wrapperDocumentInfo}>
              <img className={styles.iconCheck} src={iconFilledCheck} alt="icon filled check" />
              <p className={styles.text}>{lang.commonUploadDocumentWebLabel1()}</p>
            </div>
            <div className={styles.wrapperDocumentInfo}>
              <img className={styles.iconCheck} src={iconFilledCheck} alt="icon filled check" />
              <p className={styles.text}>{lang.commonUploadDocumentWebLabel2()}</p>
            </div>
            <div className={styles.wrapperDocumentInfo}>
              <img className={styles.iconCheck} src={iconFilledCheck} alt="icon filled check" />
              <p className={styles.text}>{lang.commonUploadDocumentWebLabel3()}</p>
            </div>
            { isMobile ? (
              <UploadBankStatementImage
                uploadDisabled={
                  !uploadBackStatement
                  && requestRegisterLinkedAccount
                  && requestRegisterLinkedAccount.status === CallStatus.PENDING
                }
                handleUploadFileAsFileFormat={setUploadBackStatement}
                handleConfirm={handleConfirm}
              />
            ) : (
              <>
                <div className={styles.wrapperDropYourImage}>
                  <Document
                    idInput="uploadStatement"
                    idRemove="removeStatement"
                    ariaLabelInput="upload statement"
                    ariaLabelRemove="remove statement"
                    handleUploadFileAsFileFormat={setUploadBackStatement}
                  />
                  <p className={styles.hint}>
                    {lang.commonUploadDocumentTypesHint(TextHelpers.getAllowedFileExtensionNames())}
                  </p>
                </div>
                <Button
                  id="uploadBankStatementBackConfirm"
                  fullWidth
                  disabled={
                    !uploadBackStatement
                    && requestRegisterLinkedAccount
                    && requestRegisterLinkedAccount.status === CallStatus.PENDING
                  }
                  onClick={handleConfirm}
                >
                  {lang.commonConfirmButton()}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <BaseModal isOpen={
        !isMobile
        && !!requestRegisterLinkedAccount
        && requestRegisterLinkedAccount.status === CallStatus.READY
        }
      >
        <AddBankAccountModal
          previousPage={locationState && locationState.previousPage}
          textInfoModal={{
            title: lang.mobileBankDetailsSetTitle(),
            description1: lang.webPopupBankDetailsDescription1(),
            description2: lang.webPopupBankDetailsDescription2(),
          }}
        />
      </BaseModal>
      <AddBankAccountScreen
        previousPage={locationState && locationState.previousPage}
        isOpen={
          isMobile
          && !!requestRegisterLinkedAccount
          && requestRegisterLinkedAccount.status === CallStatus.READY
        }
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  bankAccountDetailsOnCreate: state.crm.enroll.bankAccountDetailsOnCreate,
  requestRegisterLinkedAccount: state.payment.statusByCall[PaymentCall.registerLinkedAccount],
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  regLinkedAccount: (data: RegisterLinkedAccountPayload) => dispatch(registerLinkedAccount(data)),
  setBankAccountIdValueByLastPayment: () => dispatch(setBankAccountIdByLastPayment(null)),
  setPaymentStatusByCall: () => dispatch(setPaymentCallStatusToInitial(null)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UploadBankStatement);
