import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Action } from 'redux';

import { GWMarketDataSummaryBucket } from '../../../lib/models/gateway/types';
import { RootState } from '../../../lib/store';
import { loadWatchlistChartData } from '../../../lib/store/common-actions';
import { isUserVerifiedStatus } from '../../../lib/store/selectors';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { calculatePriceMovement, roundToCurrencyFormat } from '../../../lib/util/lastPriceChangeHelpers';
import { INVALID_VALUE, PriceMovement } from '../../../lib/util/types';

import iconDownBlack from '../../../assets/img/icon-sort-down_black_24px.svg';
import iconDownGray from '../../../assets/img/icon-sort-down_gray_24px.svg';
import iconUpBlack from '../../../assets/img/icon-sort-up_black_24px.svg';
import iconUpGray from '../../../assets/img/icon-sort-up_gray_24px.svg';
import { useIsMobile } from '../../../hooks';
import { UI_ROUTES } from '../../../util/routes';
import BaseTable from '../../BaseTable/BaseTable';
import ChartLine from '../../Chart/ChartLine/ChartLine';
import ClickableImage from '../../ClickableImage/ClickableImage';
import CompanyLogo from '../../CompanyLogo/CompanyLogo';
import { WATCH_LIST_TABLE_HEADERS } from '../WatchList.constans';
import { WatchListHeaderEnum, WatchListSortedStocks, WatchListStocks } from '../WatchList.types';

import styles from './WatchListTable.module.scss';

type Props = {
  watchListStocks: WatchListStocks[];
} & ReduxProps;

/**
 * These variables are to take every time the last data of stocks,
 * because the data of the table is changing in a real time.
* */
let tempDataStocks: WatchListSortedStocks[] = [];
let tempDataStock;
let priceMovement: PriceMovement;

const WatchListTable = ({
  watchListStocks,
  companyLogosAndNames,
  transactions,
  isUserVerified,
  loadChartData,
}: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [isAscendingOrderLastPrice, setIsAscendingOrderLastPrice] = useState<null | boolean>(null);
  const [isAscendingOrderChange, setIsAscendingOrderChange] = useState<null | boolean>(null);
  const [isAscendingOrderPercentChange, setIsAscendingOrderPercentChange] = useState<null | boolean>(null);
  const [isSortedStocks, setIsSortedStocks] = useState<boolean>(false);
  const [sortedStocks, setSortedStocks] = useState<WatchListSortedStocks[]>([]);

  useEffect(() => {
    loadChartData(watchListStocks.map(el => el.symbol).join(','));
  }, [loadChartData, watchListStocks]);

  const handleIconUp = (item: number) => {
    switch (item) {
      case WatchListHeaderEnum.COLUMN_LAST_PRICE:
        if (isAscendingOrderLastPrice === null) {
          return iconUpGray;
        }
        return isAscendingOrderLastPrice ? iconUpBlack : iconUpGray;
      case WatchListHeaderEnum.COLUMN_CHANGE:
        if (isAscendingOrderChange === null) {
          return iconUpGray;
        }
        return isAscendingOrderChange ? iconUpBlack : iconUpGray;
      default:
        if (isAscendingOrderPercentChange === null) {
          return iconUpGray;
        }
        return isAscendingOrderPercentChange ? iconUpBlack : iconUpGray;
    }
  };

  const handleIconDown = (item: number) => {
    switch (item) {
      case WatchListHeaderEnum.COLUMN_LAST_PRICE:
        if (isAscendingOrderLastPrice === null) {
          return iconDownGray;
        }
        return !isAscendingOrderLastPrice ? iconDownBlack : iconDownGray;
      case WatchListHeaderEnum.COLUMN_CHANGE:
        if (isAscendingOrderChange === null) {
          return iconDownGray;
        }
        return !isAscendingOrderChange ? iconDownBlack : iconDownGray;
      default:
        if (isAscendingOrderPercentChange === null) {
          return iconDownGray;
        }
        return !isAscendingOrderPercentChange ? iconDownBlack : iconDownGray;
    }
  };

  const handleChangeUpStates = (currentItem: number) => {
    setIsSortedStocks(true);
    let sortedData: WatchListSortedStocks[] = [];

    // Sort current data in ascending order.
    switch (currentItem) {
      case WatchListHeaderEnum.COLUMN_LAST_PRICE:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => a.currentPrice - b.currentPrice);
        setSortedStocks(sortedData);
        setIsAscendingOrderLastPrice(true);
        setIsAscendingOrderChange(null);
        setIsAscendingOrderPercentChange(null);
        break;
      case WatchListHeaderEnum.COLUMN_CHANGE:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => a.change - b.change);
        setSortedStocks(sortedData);
        setIsAscendingOrderChange(true);
        setIsAscendingOrderLastPrice(null);
        setIsAscendingOrderPercentChange(null);
        break;
      default:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => a.changePercent - b.changePercent);
        setSortedStocks(sortedData);
        setIsAscendingOrderPercentChange(true);
        setIsAscendingOrderChange(null);
        setIsAscendingOrderLastPrice(null);
    }
  };

  // TODO: Consider separating interaction - sorting and tab changing - from price updates
  //       See StockList.tsx in Mobile and future updates in usage of TradePriceCache and NBBOPriceCache
  const handleChangeDownStates = (currentItem: number) => {
    setIsSortedStocks(true);
    let sortedData: WatchListSortedStocks[] = [];

    // Sort current data in descending order.
    switch (currentItem) {
      case WatchListHeaderEnum.COLUMN_LAST_PRICE:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => b.currentPrice - a.currentPrice);
        setSortedStocks(sortedData);
        setIsAscendingOrderLastPrice(false);
        setIsAscendingOrderChange(null);
        setIsAscendingOrderPercentChange(null);
        break;
      case WatchListHeaderEnum.COLUMN_CHANGE:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => b.change - a.change);
        setSortedStocks(sortedData);
        setIsAscendingOrderChange(false);
        setIsAscendingOrderLastPrice(null);
        setIsAscendingOrderPercentChange(null);
        break;
      default:
        sortedData = tempDataStocks.slice(-watchListStocks.length).sort((a, b) => b.changePercent - a.changePercent);
        setSortedStocks(sortedData);
        setIsAscendingOrderPercentChange(false);
        setIsAscendingOrderChange(null);
        setIsAscendingOrderLastPrice(null);
    }
  };

  const tableHeaders = () => WATCH_LIST_TABLE_HEADERS.map((item: string, index) => {
    const handleStyleIconUp = (headerItem: number) => {
      if (
        (isAscendingOrderLastPrice && headerItem === WatchListHeaderEnum.COLUMN_LAST_PRICE)
        || (isAscendingOrderChange && headerItem === WatchListHeaderEnum.COLUMN_CHANGE)
        || (isAscendingOrderPercentChange && headerItem === WatchListHeaderEnum.COLUMN_PERCENT_CHANGE)) {
        return styles.iconUpActive;
      }
      return styles.iconUp;
    };

    const renderHeaderSortItems = (headerItem: number) => (
      <th key={item}>
        <span className={
            (
              (isAscendingOrderLastPrice !== null && headerItem === WatchListHeaderEnum.COLUMN_LAST_PRICE)
              || (isAscendingOrderChange !== null && headerItem === WatchListHeaderEnum.COLUMN_CHANGE)
              || (isAscendingOrderPercentChange !== null && headerItem === WatchListHeaderEnum.COLUMN_PERCENT_CHANGE))
              ? styles.activeHeaderText
              : ''
            }
        >
          {item}
        </span>
        <div className={styles.wrapperIcons}>
          <div className={styles.icons}>
            <ClickableImage
              alt=""
              src={handleIconUp(headerItem)}
              className={handleStyleIconUp(headerItem)}
              onClick={() => handleChangeUpStates(headerItem)}
            />
            <ClickableImage
              alt=""
              src={handleIconDown(headerItem)}
              className={styles.iconDown}
              onClick={() => handleChangeDownStates(headerItem)}
            />
          </div>
        </div>
      </th>
    );

    switch (index) {
      case WatchListHeaderEnum.COLUMN_LAST_PRICE:
        return renderHeaderSortItems(WatchListHeaderEnum.COLUMN_LAST_PRICE);
      case WatchListHeaderEnum.COLUMN_CHANGE:
        return renderHeaderSortItems(WatchListHeaderEnum.COLUMN_CHANGE);
      case WatchListHeaderEnum.COLUMN_PERCENT_CHANGE:
        return renderHeaderSortItems(WatchListHeaderEnum.COLUMN_PERCENT_CHANGE);

      default: return <th key={item}>{item}</th>;
    }
  });

  if (tempDataStocks.length > watchListStocks.length) {
    tempDataStocks = tempDataStocks.slice(-watchListStocks.length);
  }

  const renderName = (name) => {
    if (!isMobile || name.length <= 15) return name;
    return name.slice(0, 11).concat('...');
  };

  const getHeight = () => {
    if (!isUserVerified && transactions.length === 0) {
      return 320;
    }
    if (isUserVerified && transactions.length === 0) {
      return 260;
    }
    return 180;
  };

  return (
    <div
      className={isMobile ? styles.wrapperMobile : styles.wrapper}
      style={isMobile ? { maxHeight: window.innerHeight - getHeight() } : {}}
    >
      <BaseTable>
        {isMobile ? null : tableHeaders()}
        <>
          <tbody>
            {
              (isSortedStocks ? sortedStocks : watchListStocks).map(stock => {
                const { logo, name } = companyLogosAndNames[stock?.symbol] ?? {};
                const {
                  direction, change, changePercentWithSign, currentPrice,
                } = TradePriceCache.use(stock.symbol, 'WatchListTable');
                priceMovement = calculatePriceMovement(currentPrice, stock.symbol);
                // TODO: Consider removing
                tempDataStock = {
                  change: parseFloat(change || ''),
                  changePercent: parseFloat(changePercentWithSign || ''),
                  currentPrice: roundToCurrencyFormat(currentPrice || 0),
                  logoBase64: logo,
                  companyName: stock.companyName,
                  symbol: stock.symbol,
                };
                tempDataStocks = [...tempDataStocks, tempDataStock];

                return (
                  <>
                    {isMobile ? (
                      // Mobile version of the row
                      <tr
                        className={styles.wrapperRow}
                        key={stock.symbol}
                        onClick={() => history.push(`${UI_ROUTES.symbol}/${stock.symbol}`)}
                      >
                        <td
                          className={
                          classNames(
                            styles.columnSymbolName,
                            styles.columnSymbolNameWidthMobile,
                          )
                        }
                        >
                          <div className={styles.wrapperContent}>
                            <div className={styles.logo}>
                              <CompanyLogo logo={logo || ''} className={styles.logo} symbol={stock.symbol} />
                            </div>
                            <div className={styles.companyInfo}>
                              <span className={styles.symbol}>{stock.symbol}</span>
                              <span className={styles.company}>{renderName(name)}</span>
                            </div>
                          </div>
                        </td>
                        <td className={styles.columnChartMobile}><ChartLine symbol={stock.symbol} /></td>
                        <td className={styles.columnLastPriceMobile}>

                          <div
                            className={classNames({
                              [styles.noPriceChange]: priceMovement === 'equal',
                              [styles.negativeChange]: priceMovement === 'down',
                              [styles.positiveChange]: priceMovement === 'up',
                            })}
                            style={{
                              display: 'inline-block',
                              width: 'fit-content',
                            }}
                          >
                            {formatCurrency(roundToCurrencyFormat(currentPrice || 0))}
                          </div>
                          <div className={styles.columnPercentChange}>
                            {
                              changePercentWithSign
                                ? (
                                  <span className={direction === '-'
                                    ? styles.priceTextMinus
                                    : styles.priceTextPlus}
                                  >
                                    {changePercentWithSign}
                                  </span>
                                )
                                : INVALID_VALUE
                            }
                          </div>
                        </td>
                      </tr>

                    ) : (
                      // Web version of the row
                      <tr
                        className={styles.wrapperRow}
                        key={stock.symbol}
                        onClick={() => history.push(`${UI_ROUTES.symbol}/${stock.symbol}`)}
                      >
                        <td className={styles.columnSymbolName}>
                          <div className={styles.wrapperContent}>
                            <CompanyLogo logo={logo || ''} className={styles.logo} symbol={stock.symbol} />
                            <div className={styles.companyInfo}>
                              <span className={styles.symbol}>{stock.symbol}</span>
                              <span className={styles.company}>{name}</span>
                            </div>
                          </div>
                        </td>
                        <td className={styles.columnChart}><ChartLine symbol={stock.symbol} /></td>
                        <td className={styles.columnLastPrice}>
                          <span className={classNames(styles.currentPrice, {
                            [styles.noPriceChange]: priceMovement === 'equal',
                            [styles.negativeChange]: priceMovement === 'down',
                            [styles.positiveChange]: priceMovement === 'up',
                          })}
                          >
                            {formatCurrency(roundToCurrencyFormat(currentPrice || 0))}
                          </span>

                        </td>
                        <td className={styles.columnChange}>
                          {
                            change
                              ? (
                                <span
                                  className={direction === '-'
                                    ? styles.priceTextMinus
                                    : styles.priceTextPlus}
                                >
                                  {direction === '+' ? `+${formatCurrency(change)}` : formatCurrency(change)}
                                </span>
                              )
                              : '-'
                          }
                        </td>
                        <td className={styles.columnPercentChange}>
                          {
                            changePercentWithSign
                              ? (
                                <span className={direction === '-'
                                  ? styles.priceTextMinus
                                  : styles.priceTextPlus}
                                >
                                  {changePercentWithSign}
                                </span>
                              )
                              : INVALID_VALUE
                          }
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            }
          </tbody>
        </>
      </BaseTable>
    </div>
  );
  /**/
};

const mapStateToProps = (state: RootState) => ({
  companyLogosAndNames: state.fundamental.companyLogoAndName,
  transactions: state.payment.paymentsAll.data,
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  loadChartData: (symbols: string) => dispatch(
    loadWatchlistChartData({ symbolOrSymbols: symbols, bucket: GWMarketDataSummaryBucket.OneDay }),
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(WatchListTable);
