import React, { useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store/index';
import { getNetEquityComponents } from '../../../lib/store/reporting/index';
import { GetMyAccountChartDataPayload } from '../../../lib/store/reporting/types';
import { checkIfNotFoundError, isCallStatusError } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { ButtonTypesEnum } from '../../Button/Button.types';
import { Button, Preloader } from '../../index';

import styles from './chart.module.scss';

type Props = {
    payload: GetMyAccountChartDataPayload
} & ReduxProps

const ErrorState = ({ payload, hasRequestError, hasNotFoundError }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    dispatch(getNetEquityComponents(payload));
  }, [dispatch, payload]);

  return hasRequestError ? (
    <div className={styles.noData} style={{ flexDirection: 'column' }}>
      {lang.commonMessageNoData()}
      { !hasNotFoundError && (
      <Button
        variant={ButtonTypesEnum.Link}
        onClick={getData}
      >
        {lang.commonWatchlistRetry()}
      </Button>
      )}
    </div>
  ) : (
    <div className={styles.noData}>
      <Preloader isTextOnly owner="Chart" />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  hasRequestError: isCallStatusError(ReportingCall.getNetEquityComponents, state.reporting.statusByCall),
  hasNotFoundError: checkIfNotFoundError(ReportingCall.getNetEquityComponents, state.reporting.statusByCall),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ErrorState);
