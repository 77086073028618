import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import lang from '../../lib/language';
import { TenNSOROrderStatus } from '../../lib/models/gateway/types';
import { RootState } from '../../lib/store';
import { clearAccountTradeHistory, getAccountTradeHistory } from '../../lib/store/reporting';
import {
  ORDERS_REQUEST_PAGE_NUMBER,
  PENDING_ORDERS_REQUEST_FROM_DATE,
  TRADE_HISTORY_ORDERS_TENNSTATUSES,
} from '../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData } from '../../lib/store/reporting/types';
import { getCurrentAccount } from '../../lib/store/selectors';
import { getDateTime, tradeHistoryCalendarInitialMaxDate } from '../../lib/util/DateTimeHelpers';
import { manageSubscriptions } from '../../lib/util/TradingHelpers';

import ArrowBack from '../../assets/img/blue_icon-arrow-left.svg';
import iconClear from '../../assets/img/icon-clear.svg';
import { ClickableImage } from '../../components';
import DateRangePickerWrapper from '../../components/DateRangePickerWrapper/DateRangePickerWrapper';
import MobileFromToDatePicker from '../../components/MobileFromToDatePicker/MobileFromToDatePicker';
import { useIsMobile } from '../../hooks';

import StatusSelector from './StatusSelector/StatusSelector';
import TradeHistoryTable from './TradeHistoryTable/TradeHistoryTable';
import SearchSymbols from './SearchSymbols';
import { statusOptionsLabels } from './TradeHistoryOptions';
import { useStyles } from './TradeHistoryStyles';

import styles from './TradeHistory.module.scss';

const statusFilterTitle = lang.commonOrderStatusFilterTitle();
const statusFiltersWithStatusTitle = [statusFilterTitle, ...statusOptionsLabels];

type Props = {} & ReduxProps;

const TradeHistory = (props: Props) => {
  const {
    accountReferenceId,
    accountTradeHistory,
    getTradeHistory,
    clearAccountTradeHistoryData,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  // const { browserWidthSize } = useBrowserWidth();
  const history = useHistory();

  let locationState = history.location.state as { symbol: string } | null;
  if (!locationState || !locationState.symbol) {
    locationState = null;
  }

  const [statusOption, setStatusOption] = useState<string>(statusFilterTitle);
  const [searchValue, setSearchValue] = useState<string>(locationState ? locationState.symbol : '');
  const [pageNumber, setPageNumber] = useState<number>(ORDERS_REQUEST_PAGE_NUMBER);
  const [rangeDate, setRangeDate] = useState({ startDate: '', endDate: '' });

  const handleChangeByStatus = (event) => {
    if (isMobile) {
      setStatusOption(event);
    } else {
      const orderStatus: OrderStatusEnum = event.target.value;
      setStatusOption(orderStatus);
    }
  };

  const resetTradeHistory = (symbol: string) => {
    clearAccountTradeHistoryData();
    const { startDate, endDate } = rangeDate;

    getTradeHistory({
      fromDate: startDate.length > 0 ? startDate : PENDING_ORDERS_REQUEST_FROM_DATE,
      toDate: endDate.length > 0 ? endDate : getDateTime(),
      tenNStatus: TRADE_HISTORY_ORDERS_TENNSTATUSES,
      pageNumber: ORDERS_REQUEST_PAGE_NUMBER,
      symbol: symbol.length !== 0 ? symbol : undefined,
    });
  };

  const handleSymbolReset = () => {
    setSearchValue('');
    resetTradeHistory('');
  };

  const handleReset = () => {
    setStatusOption(statusFilterTitle);
    resetTradeHistory(searchValue);
  };

  const handleBtnClose = () => (
    <>
      {
        (statusOption !== statusFilterTitle)
          ? <ClickableImage src={iconClear} onClick={handleReset} className={classes.btnClose} />
          : <></>
      }
    </>
  );

  const handleMenuItem = (item: string) => {
    if (item === lang.commonOrderStatusFilterTitle()) {
      return <div className={classes.initialText}>{item}</div>;
    }
    return <div className={classes.selectedText}>{item}</div>;
  };

  const handleSearchSymbol = (symbol: string) => {
    setSearchValue(symbol);
    setPageNumber(ORDERS_REQUEST_PAGE_NUMBER);
    clearAccountTradeHistoryData();

    const { startDate, endDate } = rangeDate;

    if (symbol.length === 0) {
      getTradeHistory({
        fromDate: startDate.length > 0 ? startDate : PENDING_ORDERS_REQUEST_FROM_DATE,
        toDate: endDate.length > 0 ? endDate : getDateTime(),
        pageNumber: ORDERS_REQUEST_PAGE_NUMBER,
        tenNStatus: statusOption !== lang.commonOrderStatusFilterTitle()
          ? TenNSOROrderStatus[statusOption.replace(/\s/g, '')]
          : TRADE_HISTORY_ORDERS_TENNSTATUSES,
      });
    }
  };

  const handleRowsPerPage = () => {
    setPageNumber(num => num + ORDERS_REQUEST_PAGE_NUMBER);
  };

  useEffect(() => {
    if (rangeDate.startDate !== '' && rangeDate.endDate !== '') {
      clearAccountTradeHistoryData();
      setPageNumber(ORDERS_REQUEST_PAGE_NUMBER);

      const { startDate, endDate } = rangeDate;

      getTradeHistory({
        fromDate: startDate,
        toDate: endDate,
        pageNumber: ORDERS_REQUEST_PAGE_NUMBER,
        tenNStatus: statusOption !== lang.commonOrderStatusFilterTitle()
          ? TenNSOROrderStatus[statusOption.replace(/\s/g, '')]
          : TRADE_HISTORY_ORDERS_TENNSTATUSES,
        symbol: searchValue.length > 0 ? searchValue : undefined,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate.startDate, rangeDate.endDate]);

  useEffect(() => {
    if (statusOption !== lang.commonOrderStatusFilterTitle()) {
      const { startDate, endDate } = rangeDate;

      clearAccountTradeHistoryData();
      setPageNumber(ORDERS_REQUEST_PAGE_NUMBER);
      getTradeHistory({
        fromDate: startDate.length > 0 ? startDate : PENDING_ORDERS_REQUEST_FROM_DATE,
        toDate: endDate.length > 0 ? endDate : getDateTime(),
        pageNumber: ORDERS_REQUEST_PAGE_NUMBER,
        tenNStatus: TenNSOROrderStatus[statusOption.replace(/\s/g, '')],
        symbol: searchValue.length !== 0 ? searchValue : undefined,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusOption]);

  useEffect(() => {
    if (searchValue.length !== 0) {
      const { startDate, endDate } = rangeDate;

      getTradeHistory({
        fromDate: startDate.length > 0 ? startDate : PENDING_ORDERS_REQUEST_FROM_DATE,
        toDate: endDate.length > 0 ? endDate : getDateTime(),
        pageNumber: ORDERS_REQUEST_PAGE_NUMBER,
        tenNStatus: statusOption !== lang.commonOrderStatusFilterTitle()
          ? TenNSOROrderStatus[statusOption.replace(/\s/g, '')]
          : TRADE_HISTORY_ORDERS_TENNSTATUSES,
        symbol: searchValue,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (pageNumber > ORDERS_REQUEST_PAGE_NUMBER) {
      const { startDate, endDate } = rangeDate;

      getTradeHistory({
        fromDate: startDate.length > 0 ? startDate : PENDING_ORDERS_REQUEST_FROM_DATE,
        toDate: endDate.length > 0 ? endDate : getDateTime(),
        pageNumber,
        tenNStatus: statusOption !== lang.commonOrderStatusFilterTitle()
          ? TenNSOROrderStatus[statusOption.replace(/\s/g, '')]
          : TRADE_HISTORY_ORDERS_TENNSTATUSES,
        symbol: searchValue.length > 0 ? searchValue : undefined,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    clearAccountTradeHistoryData();
    if (!locationState && accountReferenceId) {
      getTradeHistory({ pageNumber: ORDERS_REQUEST_PAGE_NUMBER, tenNStatus: TRADE_HISTORY_ORDERS_TENNSTATUSES });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, clearAccountTradeHistoryData, getTradeHistory, accountReferenceId]);

  useEffect(() => {
    manageSubscriptions('history', null, false, true);
  }, []);

  const handleDateChange = (value) => {
    const { startDate, endDate } = value;

    if (startDate === new Date().toISOString().split('T')[0] && endDate === new Date().toISOString().split('T')[0]) {
      setRangeDate({
        ...rangeDate,
        startDate: getDateTime(),
        endDate: getDateTime(),
      });
    } else {
      setRangeDate({ ...rangeDate, startDate, endDate });
    }
  };

  return (
    <div className={styles.wrapper}>
      { isMobile && (
        <div className={styles.arrowBack}>
          <ClickableImage
            src={ArrowBack}
            onClick={() => history.goBack()}
          />
        </div>
      )}
      <h6 className={styles.title}>{lang.commonTitleHistory()}</h6>
      <div className={classes.wrapperHeaderItems}>
        <div className={styles.dateAndStatusWrapper}>
          <div className={styles.dateAndStatus}>
            { isMobile ? (
              <MobileFromToDatePicker
                label={lang.commonDate()}
                initialState={{
                  startDate: new Date(getDateTime(true, [ 'minus' ], [ [ 3, 'months' ] ])),
                  endDate: tradeHistoryCalendarInitialMaxDate(),
                }}
                maxDateCalendar={tradeHistoryCalendarInitialMaxDate()}
                onDateSelect={handleDateChange}
              />
            ) : (
              <DateRangePickerWrapper
                initialState={{
                  startDate: new Date(getDateTime(true, [ 'minus' ], [ [ 3, 'months' ] ])),
                  endDate: tradeHistoryCalendarInitialMaxDate(),
                }}
                maxDateCalendar={tradeHistoryCalendarInitialMaxDate()}
                onDateSelect={handleDateChange}
              />
            )}
            <div className={classes.wrapperStatusByItems}>
              <StatusSelector
                currentStatus={statusOption}
                handleChangeByStatus={handleChangeByStatus}
                handleBtnClose={handleBtnClose}
                statusList={statusFiltersWithStatusTitle}
                handleMenuItem={handleMenuItem}
                handleReset={handleReset}
              />
            </div>
            { isMobile && (
              <div className={classes.wrapperSearchFieldMobile}>
                <SearchSymbols
                  handleSearchSymbol={handleSearchSymbol}
                  searchValue={searchValue}
                  handleReset={handleSymbolReset}
                />
              </div>
            )}
          </div>
        </div>
        { !isMobile && (
          <div className={classes.wrapperSearchField}>
            <SearchSymbols
              handleSearchSymbol={handleSearchSymbol}
              searchValue={searchValue}
            />
          </div>
        )}
      </div>
      <TradeHistoryTable
        accountTradeHistory={accountTradeHistory}
        handleRowsPerPage={handleRowsPerPage}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountTradeHistory: state.reporting.tradeHistoryOrders,
  accountReferenceId: getCurrentAccount(state),
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getTradeHistory: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  clearAccountTradeHistoryData: () => dispatch(clearAccountTradeHistory()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TradeHistory);
