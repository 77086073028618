import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { HighGrowthCompanyData, PageableFilterRequest } from '../../../lib/models/fundamental/types';
import { getHighGrowthCompanies as getGrowthCompanies } from '../../../lib/store/fundamental/index';
import { FundamentalCall } from '../../../lib/store/fundamental/types';
import { RootState } from '../../../lib/store/index';
import { isCallStatusPending } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { useIsMobile } from '../../../hooks';
import BaseTable from '../../BaseTable/BaseTable';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';
import Preloader from '../../Preloader/Preloader';
import {
  HighGrowthTableHeaders,
  INITIAL_COMPANIES_PER_PAGE,
} from '../DividendPyersAndHighGrowthCompanies.constants';

import TableItem from './TableItem';

import styles from './TableItem.module.scss';

type Props = ReduxProps & {
  content: HighGrowthCompanyData[]
}

const HighGrowthCompaniesTable = (props: Props) => {
  const { content,
    highGrowthCompanies,
    companyLogoAndName,
    isHighGrowthCompaniesStatusPending,
    getHighGrowthCompanies,
  } = props;
  const isMobile = useIsMobile();
  const [tableContent, setTableContent] = useState<HighGrowthCompanyData[]>(content);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [counter, setCounter] = useState<number>(highGrowthCompanies.content.length ? 1 : 0);

  const tableHeaders = useMemo(() => (
    HighGrowthTableHeaders.map((item: string) => <th key={item}>{item}</th>)
  ), []);

  useEffect(() => {
    if (highGrowthCompanies.content.length > counter * INITIAL_COMPANIES_PER_PAGE) {
      highGrowthCompanies.content.length <= tableContent.length
        ? setTableContent((prevState) => (
          [...prevState, ...highGrowthCompanies.content.slice(
            counter * INITIAL_COMPANIES_PER_PAGE, page * INITIAL_COMPANIES_PER_PAGE,
          )]
        ))
        : setTableContent(highGrowthCompanies.content);
      setPage(prevState => prevState + 1);
      setCounter(prevState => prevState + 1);
      setLoading(false);
    }
  }, [counter, highGrowthCompanies, page, tableContent.length]);

  const tableItems = tableContent.map((item: HighGrowthCompanyData, index) => (
    <TableItem
      // there is no anything unique and the array will not be filtered or sorted, the additional element are pushed to the end
      key={index as number}
      logo={item.logoBase64 ?? companyLogoAndName[item.symbol].logo}
      companyName={item.companyName ?? companyLogoAndName[item.symbol].name}
      symbol={item.symbol}
      yield_indicator={item.growthIndicator}
      rate_revenue={item.revenue}
      marketCap_eps={item.epsLast12Months}
      volume_marketCap={item.marketCap}
    />
  ));

  const handleButtonLoadMore = () => {
    getHighGrowthCompanies({ page });
    setLoading(true);
  };

  if (!highGrowthCompanies.content.length && isHighGrowthCompaniesStatusPending) {
    return <div className={styles.loader}><Preloader owner="HighGrowthCompaniesTable" /></div>;
  }

  return (
    <>
      <BaseTable>
        { !isMobile && (
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
        )}
        <tbody>{tableItems}</tbody>
      </BaseTable>
      {(loading && highGrowthCompanies.totalPages > 0) && <Preloader style={{ transform: 'scale(0.5)' }} owner="HighGrowthCompaniesTable" />}
      {
        highGrowthCompanies.totalPages > 0
        && (
        <Button
          className={styles.btnLoadMore}
          variant={ButtonTypesEnum.Link}
          onClick={handleButtonLoadMore}
        >
          {lang.commonLoadMore()}
        </Button>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  highGrowthCompanies: state.fundamental.highGrowthCompanies,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  isHighGrowthCompaniesStatusPending:
  isCallStatusPending(FundamentalCall.getHighGrowthCompanies, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getHighGrowthCompanies: (payload: PageableFilterRequest) => dispatch(getGrowthCompanies(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(HighGrowthCompaniesTable);
