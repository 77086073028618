import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Action } from 'redux';

import { OrderSideEnum, TimeInForceEnum } from '../../../lib/enums';
import { OrderTypeEnum } from '../../../lib/enums/order-type.enum';
import lang from '../../../lib/language';
import { Order } from '../../../lib/models/trading/types';
import { RootState } from '../../../lib/store';
import { getOpenPositions as getPositions, setHasOrderUpdate } from '../../../lib/store/reporting';
import { OpenPosition, PositionType } from '../../../lib/store/reporting/types';
import { getCurrentBuyingPower } from '../../../lib/store/selectors';
import MarketStateCache from '../../../lib/store-util/MarketStateCache';
import NBBOPriceCache from '../../../lib/store-util/NBBOPriceCache';
import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { isMarketClosed, isMarketOpen, isMarketRegularHours } from '../../../lib/util/MarketDataHelpers';
import { TextHelpers } from '../../../lib/util/TextHelpers';
import { uuidv4 } from '../../../lib/util/tools';
import { getOrderTotalValue } from '../../../lib/util/TradingValidationsHelper';

import { Button, Error, NotEnoughBuyingPower, RegularTradingHoursStartModal, ReviewOrder, ShortSellOrderPlace } from '../..';
import HighLowLimitPriceAlert from '../../HighLowLimitPriceAlert/HighLowLimitPriceAlert';
import SharesCalculator from '../../SharesCalculator/SharesCalculator';
import ExpiryDate from '../components/ExpiryDate';
import LimitPriceInput from '../components/LimitPriceInput';
import MarketPriceInfo from '../components/MarketPriceInfo';
import ETFRestrcitions from '../components/UserRestrictions/ETFRestrictions';
import DropDown from '../DropDown/DropDown';
import { OrderTypeDropdownOptions } from '../order.constants';
import { getValidationPrice, validateOrder } from '../OrderHelpers';

import styles from './CreateOrder.module.scss';

type OwnProps = {
  symbol: string;
  positionsQuantity: number | null;
  isSymbolHalted: boolean;
  isUserRestricted?: boolean;
  hasMyStocksOrderUpdate?: boolean;
  setOrderInfo: (order: Order) => void;
  setFromReview: (fromReview: boolean) => void;
}
type Props = OwnProps & ReduxProps;

function CreateTicketOrder(props: Props) {
  const {
    symbol = '',
    account,
    company,
    positionsQuantity,
    buyingPow,
    hasMyStocksOrderUpdate,
    accountOpenPositionsCompleted,
    positions,
    isSymbolHalted,
    isUserRestricted,
    setOrderInfo,
    setFromReview,
    myStocksUpdate,
    getOpenPositions,
  } = props;
  const { marketState } = MarketStateCache.use();
  const { currentPrice: tradePrice = 0 } = TradePriceCache.use(symbol, 'CreateOrder');
  const isMarketHours = isMarketRegularHours(marketState);
  const showMarketInfoIcon = isMarketOpen(marketState);

  const [order, setOrder] = useState<Order>({});
  const [reviewOrder, setReviewOrder] = useState<boolean>(false);
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [targetPriceError, setTargetPriceError] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [targetPrice, setTargetPrice] = useState<number>(0);
  const [orderType, setOrderType] = useState<string>(isMarketHours ? OrderTypeEnum.Market : OrderTypeEnum.Limit);
  const [timeInForce, setTimeInForce] = useState<string>(TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay));
  const [includeExtendedHours, setIncludeExtendedHours] = useState<boolean>(false);
  const [visibleWarningShortSellDialog, setVisibleWarningShortSellDialog] = useState<boolean>(false);
  const [visibleRegularTradingHoursStartDialog, setVisibleRegularTradingHoursStartDialog] = useState<boolean>(false);
  const [isReviewOrderCheckTriggered, setIsReviewOrderCheckTriggered] = useState<boolean>(false);
  const [openedEnoughBPModal, setOpenedEnoughBPModal] = useState<boolean>(false);
  const [visibleHighLowLimitPriceAlert, setVisibleHighLowLimitPriceAlert] = useState<boolean>(false);
  const [buyBtnClicked, setBuyBtnClicked] = useState<boolean>(false);
  const [sellBtnClicked, setSellBtnClicked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [controlCheck, setControlCheck] = useState<boolean>(true);
  const [numberOfSharesError, setNumberOfSharesError] = useState<string>('');
  const nbboData = NBBOPriceCache.use(symbol);
  const { askprice, bidprice } = nbboData;
  const positionsBySymbol = positions.find((position: OpenPosition) => (position.symbol === symbol));
  const isBuyOrder = positionsBySymbol?.positionType === PositionType.Buy;
  const marketClosed = isMarketClosed(marketState);
  const isMarketOrder = orderType === OrderTypeEnum.Market;
  const orderTypeOptions = OrderTypeDropdownOptions(isMarketHours, isBuyOrder);

  const totalValue = getOrderTotalValue(
    orderType,
    quantity,
    tradePrice,
    targetPrice,
  );

  useEffect(() => {
    if (isReviewOrderCheckTriggered && ((accountOpenPositionsCompleted && !marketClosed) || marketClosed)) {
      setFromReview(true);
      setReviewOrder(false);
      setIsReviewOrderCheckTriggered(false);
    }
  }, [order, setFromReview, isReviewOrderCheckTriggered, accountOpenPositionsCompleted, marketClosed]);

  const setOrderData = useCallback((theOrder: Order) => {
    setOrder(theOrder);
    setOrderInfo(theOrder);
    setReviewOrder(true);
  }, [setOrderInfo]);

  const reviewTicketOrder = useCallback((side: string) => {
    const price = tradePrice ?? 0;
    const orderObject: Order = {};
    const isBuySide = side === OrderSideEnum.Buy;
    setTargetPriceError('');
    setNumberOfSharesError('');

    if (orderType === OrderTypeEnum.Stop) {
      orderObject.stopPrice = targetPrice;
    }
    const parsedIncludeExtendedHours = side === OrderSideEnum.Sell && !isBuyOrder ? false : includeExtendedHours;
    const parsedTime = timeInForce === TimeInForceEnum.GTC ? timeInForce : TimeInForceEnum.EndOfDay;

    orderObject.clientOrderId = uuidv4();
    orderObject.symbol = symbol;
    orderObject.side = side as OrderSideEnum;
    orderObject.orderQty = quantity;
    orderObject.ordType = orderType as OrderTypeEnum;
    if (orderType !== OrderTypeEnum.Market) {
      orderObject.price = targetPrice;
    }
    orderObject.timeInForce = parsedTime;
    orderObject.account = account;
    orderObject.includeExtendedHours = parsedIncludeExtendedHours;

    if (orderType === OrderTypeEnum.Limit) {
      if (
        (isBuySide && (targetPrice > price))
          || (!isBuySide && (targetPrice < price))
      ) {
        setVisibleHighLowLimitPriceAlert(isBuySide ? (targetPrice > price) : (targetPrice < price));
        setOrder(orderObject);
        setOrderInfo(orderObject);

        return;
      }
    }

    if (!positionsBySymbol && isMarketRegularHours(marketState) && side === OrderSideEnum.Sell) {
      setVisibleWarningShortSellDialog(true);
      setOrder(orderObject);
      setOrderInfo(orderObject);

      return;
    }

    setOrderData(orderObject);
  }, [
    account,
    includeExtendedHours,
    isBuyOrder,
    orderType,
    quantity,
    marketState,
    setOrderData,
    setOrderInfo,
    symbol,
    targetPrice,
    timeInForce,
    tradePrice,
    positionsBySymbol,
  ]);

  const handleChangeOrderType = useCallback((option) => {
    setTargetPriceError('');
    setOrderType(option.value);
    setIncludeExtendedHours(false);
    setTimeInForce(TextHelpers.getTimeInForceName(TimeInForceEnum.EndOfDay));
  }, []);

  useEffect(() => {
    if (!isInitialised && positionsQuantity) {
      setIsInitialised(true);
    }
  }, [isInitialised, positionsQuantity]);

  const validateBuyingPower = useCallback((buyingPower: number, isSellBtnClicked = false) => {
    const buyingPowerCheck = buyingPower > totalValue;

    return controlCheck
      && !buyingPowerCheck
      && ((isSellBtnClicked && !isBuyOrder) || (!isSellBtnClicked && (isBuyOrder || !positionsBySymbol)));
  }, [controlCheck, isBuyOrder, positionsBySymbol, totalValue]);

  const areInputFieldsValid = useCallback((validCheck, posQty, curQty, isBuyPos, sellClicked, buyClicked, errMsg) => {
    let hasValid = true;
    if (posQty && curQty > posQty) {
      if ((isBuyPos && sellClicked) || (!isBuyPos && buyClicked)) {
        hasValid = false;
        setNumberOfSharesError(lang.commonNotEnoughSharesAvailable());
      }
    }
    if (!validCheck) {
      hasValid = false;
      setTargetPriceError(errMsg);
    }

    return hasValid;
  }, []);

  useEffect(() => {
    if ((sellBtnClicked || buyBtnClicked)) {
      if (areInputFieldsValid(
        controlCheck, positionsQuantity, quantity, isBuyOrder, sellBtnClicked, buyBtnClicked, errorMessage,
      )) {
        if ((validateBuyingPower(buyingPow) && buyBtnClicked)
      || (validateBuyingPower(buyingPow, true) && sellBtnClicked)) {
          setOpenedEnoughBPModal(true);
        } else if (!isBuyOrder && !isMarketRegularHours(marketState) && sellBtnClicked) {
          setVisibleRegularTradingHoursStartDialog(true);
        } else {
          reviewTicketOrder(buyBtnClicked ? OrderSideEnum.Buy : OrderSideEnum.Sell);
        }
      }
      setBuyBtnClicked(false);
      setSellBtnClicked(false);
    }
  }, [
    buyBtnClicked,
    buyingPow,
    controlCheck,
    isBuyOrder,
    marketState,
    positionsBySymbol,
    positionsQuantity,
    quantity,
    errorMessage,
    reviewTicketOrder,
    sellBtnClicked,
    areInputFieldsValid,
    validateBuyingPower,
  ]);

  const onSellClicked = () => {
    setSellBtnClicked(true);
    const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice);
    validateOrder(validationPirce ?? 0, targetPrice, orderType, false, setControlCheck, setErrorMessage);
  };

  const onBuyClicked = () => {
    setBuyBtnClicked(true);
    const validationPirce = getValidationPrice(marketClosed, askprice, bidprice, tradePrice, true);
    validateOrder(validationPirce ?? 0, targetPrice, orderType, true, setControlCheck, setErrorMessage);
  };

  const proceedShortSellOrderPlaceModal = () => {
    setVisibleWarningShortSellDialog(false);
    setReviewOrder(true);
  };

  const closeShortSellOrderPlaceModal = () => {
    setVisibleWarningShortSellDialog(false);
  };

  const closeRegularTradingHoursStartDialog = () => {
    setVisibleRegularTradingHoursStartDialog(false);
  };

  const proceedHighLowLimitPriceAlert = () => {
    setVisibleHighLowLimitPriceAlert(false);
    if (!positionsBySymbol && isMarketRegularHours(marketState) && order?.side === OrderSideEnum.Sell) {
      setVisibleWarningShortSellDialog(true);
    } else {
      setReviewOrder(true);
    }
  };

  const renderTradeButtons = (): JSX.Element => {
    if (isUserRestricted) {
      return (
        <ETFRestrcitions />
      );
    }
    if (isSymbolHalted) {
      return (
        <div className={styles.haltedSymbolInfo}>
          {lang.commonHaltedSymbolPopupContent(company[symbol]?.name ?? symbol)}
        </div>
      );
    }
    return (
      <div className={styles.wrapperButtons}>
        <Button className={styles.btnSell} onClick={onSellClicked}>
          {lang.commonOrderSideButtonSell().toUpperCase()}
        </Button>
        <Button className={styles.btnBuy} onClick={onBuyClicked}>
          {lang.commonOrderSideButtonBuy().toUpperCase()}
        </Button>
      </div>
    );
  };

  return (
    <>
      {visibleWarningShortSellDialog && (
        <ShortSellOrderPlace
          toggleModal={closeShortSellOrderPlaceModal}
          processModal={proceedShortSellOrderPlaceModal}
        />
      )}
      {visibleRegularTradingHoursStartDialog && (
        <RegularTradingHoursStartModal
          toggleModal={closeRegularTradingHoursStartDialog}
        />
      )}
      <div className={classNames(styles.wrapperOrderContainer, { [styles.isRestricted]: isUserRestricted })}>
        <div className={styles.wrapperOrderTypeDropDown}>
          <p className={styles.orderTypeText}>{lang.commonTradingOrderType()}</p>
          <DropDown
            options={orderTypeOptions}
            value={orderType}
            handleChange={handleChangeOrderType}
            isDisabled={isMarketHours}
            isHalted={isSymbolHalted || isUserRestricted}
          />
        </div>
        <div className={styles.wrapperNumberOfShares}>
          <div className={styles.numberOfShares}>
            <p className={styles.numberOfSharesText}>{lang.mobileTradingTicketNumberOfShares()}</p>
            {!!positionsQuantity && (
              <p className={styles.numberOfSharesSubText}>
                {
                  isBuyOrder
                    ? lang.webTradingAvailableShares(positionsQuantity)
                    : lang.webTradingShortedStocks(positionsQuantity)
                }
              </p>
            )}
          </div>
          <div className={styles.wrapperSharesField}>
            <SharesCalculator
              disabled={isSymbolHalted || isUserRestricted}
              quantity={quantity}
              setQuantity={setQuantity}
              clearNumberOfSharesError={setNumberOfSharesError}
            />
          </div>
        </div>
        {numberOfSharesError && (numberOfSharesError.trim() !== '') && (
        <div className={styles.errorWrapper}>
          <Error hiddenIcon errorMessage={numberOfSharesError} />
        </div>
        )}
        {(!isMarketOrder) && (
          <LimitPriceInput
            disabled={isSymbolHalted || isUserRestricted}
            initialPrice={0}
            orderType={orderType}
            targetPriceError={targetPriceError}
            setTargetPrice={(value) => setTargetPrice(value)}
            setTargetPriceError={(value) => setTargetPriceError(value)}
          />
        )}
        <MarketPriceInfo
          askPrice={askprice}
          bidPrice={bidprice}
          symbol={symbol}
          tradePrice={tradePrice}
          isMarketOpen={showMarketInfoIcon}
          isUserRestricted={isUserRestricted}
          isSymbolDetailsBigScreen
        />
        {(!isMarketOrder) && (
          <ExpiryDate
            isHalted={isSymbolHalted || isUserRestricted}
            isMarketHours={isMarketHours}
            time={timeInForce}
            positionsBySymbol={positionsBySymbol}
            orderType={orderType}
            extendedHours={includeExtendedHours}
            isUserRestricted={isUserRestricted}
            setTimeInForce={(value) => setTimeInForce(value)}
            setIncludeExtendedHours={(value) => setIncludeExtendedHours(value)}
          />
        )}
        <div className={styles.horizontalLine} />
        <div className={classNames(styles.totalValueContainer, { [styles.isRestricted]: isUserRestricted })}>
          <div>{lang.commonTradingOrderValue()}</div>
          <div className={styles.value}>
            {formatCurrency(totalValue)}
          </div>
        </div>
        <div className={classNames(styles.availableBuyingContainer, { [styles.isRestricted]: isUserRestricted })}>
          <div>{lang.mobileOrderCreateBuyingPower()}</div>
          <div className={styles.value}>{formatCurrency(buyingPow)}</div>
        </div>
        {renderTradeButtons()}
        {openedEnoughBPModal && <NotEnoughBuyingPower closeModal={() => setOpenedEnoughBPModal(false)} />}
        {visibleHighLowLimitPriceAlert && (
          <HighLowLimitPriceAlert
            isBuyOrder={order?.side === OrderSideEnum.Buy}
            processModal={proceedHighLowLimitPriceAlert}
            toggleModal={() => setVisibleHighLowLimitPriceAlert(false)}
          />
        )}
      </div>
      {reviewOrder && (
        <ReviewOrder
          symbol={symbol}
          order={order}
          isBuyOrder={order.side === OrderSideEnum.Buy}
          onSubmit={() => {
            if (!marketClosed) {
              getOpenPositions(symbol);
              hasMyStocksOrderUpdate && myStocksUpdate(true);
            }
            setIsReviewOrderCheckTriggered(true);
          }}
          onCancel={() => setReviewOrder(false)}
        />
      )}
    </>
  );
}

const mapStateProps = (state: RootState) => ({
  positions: state.reporting.openPositions,
  account: state.crm.accounts[state.crm.selectedAccount],
  accountOpenPositionsCompleted: state.reporting.accountOpenPositionsCompleted,
  buyingPow: getCurrentBuyingPower(state),
  company: state.fundamental.companyLogoAndName,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOpenPositions: (symbol: string) => dispatch(getPositions({ symbol })),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CreateTicketOrder);
