import React from 'react';

import lang from '../../../../lib/language';
import { LinkedAccount } from '../../../../lib/models/linked-account/types';

import iconBank from '../../../../assets/img/icon-bank.svg';
import { ClickableDiv } from '../../../../components';

import styles from './BankAccounts.module.scss';

type Props = {
    handleClickBankAccount: (data: any) => void;
    convertedActiveLinkedAccounts: LinkedAccount[];
}

const BankAccounts = ({ handleClickBankAccount, convertedActiveLinkedAccounts }: Props) => (
  <div className={styles.container}>
    <div className={styles.wrapperBankAccountDropDown}>
      <span className={styles.labelBankTransfer}>{lang.commonFirstDepositBank()}</span>
      {
        convertedActiveLinkedAccounts.map(linkedAccount => (
          <ClickableDiv
            className={styles.wrapperBank}
            key={linkedAccount.id}
            onClick={() => handleClickBankAccount(linkedAccount)}
          >
            <img src={iconBank} alt="icon bank" />
            <div className={styles.contentBank}>
              <div className={styles.bankDescription}>
                <p>{linkedAccount.account_info.bank_name}</p>
              </div>
              <p className={styles.ibanBank}>
                {linkedAccount.account_info.iban
                  ? linkedAccount.account_info.iban
                  : linkedAccount.account_info.account}
              </p>
            </div>
          </ClickableDiv>
        ))
      }
    </div>
  </div>
);

export default BankAccounts;
