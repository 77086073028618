import React from 'react';

import { Order } from '../../../lib/models/trading/types';

import { BaseModal, CreateOrder } from '../../../components';

type Props = {
    symbol: string,
    orderSide: string,
    isOpenModalCreateOrder: boolean,
    positionsQuantity: number | null,
    isMyAccount?: boolean,
    hasMyStocksOrderUpdate?: boolean,
    setOrderInfo: (order: Order) => void,
    handlePopUp: (isOpen: boolean) => void,
    setFromReview: (fromReview: boolean) => void,
}

const ModalCreateOrder = ({
  symbol,
  orderSide,
  isMyAccount,
  handlePopUp,
  setOrderInfo,
  setFromReview,
  positionsQuantity,
  isOpenModalCreateOrder,
  hasMyStocksOrderUpdate,
}: Props) =>
  (
    <BaseModal
      isOpen={isOpenModalCreateOrder}
      toggle={() => handlePopUp(false)}
    >
      <CreateOrder
        symbol={symbol}
        isMyAccount={isMyAccount}
        orderSide={orderSide}
        setOrderInfo={setOrderInfo}
        setFromReview={setFromReview}
        isPopupMode={isOpenModalCreateOrder}
        positionsQuantity={positionsQuantity}
        hasMyStocksOrderUpdate={hasMyStocksOrderUpdate}
      />
    </BaseModal>
  );

export default ModalCreateOrder;
