import React, { useState } from 'react';

import lang from '../../../lib/language';

import InfoIcon from '../../../assets/img/icon-info-outline.svg';
import { BaseModal, Button, ClickableImage } from '../..';
import { ButtonTypesEnum } from '../../Button/Button.types';

import styles from './HighGrowthCompaniesInfo.module.scss';

const HighGrowthCompaniesInfo = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModal = () => setShowModal(!showModal);

  return (
    <>
      <span>
        <ClickableImage
          src={InfoIcon}
          onClick={handleModal}
          variant="contained"
          className={styles.popoverTrigger}
        />
      </span>
      <BaseModal isOpen={showModal}>
        <h2 className={styles.title}>{lang.commonDiscoverHighGrowthCompaniesLegend()}</h2>
        <span className={styles.description}>{lang.commonGrowthIndicatorDescription()}</span>
        <Button
          onClick={handleModal}
          variant={ButtonTypesEnum.Primary}
          className={styles.button}
        >
          {lang.commonClose()}
        </Button>
      </BaseModal>
    </>
  );
};

export default HighGrowthCompaniesInfo;
