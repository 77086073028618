import { IndividualAddressTypeEnum } from '../../../lib/models/enroll';
import { EnrollIndividualAddressRequestData } from '../../../lib/models/enroll-requests';

import { EnrollAddressesForm } from './EnrollAddresses.types';

export const EnrollAddressInfoInit = (isMailingAddress: boolean = false): EnrollIndividualAddressRequestData => ({
  address_line: '',
  city: '',
  country_id: '',
  zip_code: '',
  address_type: isMailingAddress ? IndividualAddressTypeEnum.mailing : IndividualAddressTypeEnum.residential,
});

export const EnrollAddressesFormDataInit: EnrollAddressesForm = {
  residentialAddress: EnrollAddressInfoInit(),
  mailingAddress: EnrollAddressInfoInit(true),
};
