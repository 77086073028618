import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TradePriceCache from '../../../lib/store-util/TradePriceCache';
import { getPriceColorByDirection } from '../../../lib/util/ChartingHelpers';
import { formatCurrency } from '../../../lib/util/DataHelpers';

import { UI_ROUTES } from '../../../util/routes';
import ClickableDiv from '../../ClickableDiv/ClickableDiv';
import CompanyLogo from '../../CompanyLogo/CompanyLogo';

import styles from './NewOnMarketItem.module.scss';

type Props = {
  logo: string;
  symbol: string;
}

const NewOnMarketItem = ({ logo, symbol }: Props) => {
  const history = useHistory();
  const tradeCache = TradePriceCache.use(symbol, 'NewOnMarketItem');
  const { changePercentWithSign, direction, currentPrice } = tradeCache;
  const directionColor = getPriceColorByDirection(direction);

  const redirectToSymbol = useCallback(() => {
    history.push(`${UI_ROUTES.symbol}/${symbol}`);
  }, [history, symbol]);

  return (
    <ClickableDiv
      className={styles.card}
      onClick={redirectToSymbol}
    >
      {logo && <CompanyLogo logo={logo} className={styles.logo} symbol={symbol} />}
      <p>{symbol}</p>
      <p>{formatCurrency(currentPrice)}</p>
      <p>
        <span style={{ color: directionColor }}>{changePercentWithSign}</span>
      </p>
    </ClickableDiv>
  );
};

export default NewOnMarketItem;
