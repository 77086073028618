import React from 'react';
import { Link } from '@material-ui/core';

import lang from '../../lib/language';

import AlertIcon from '../../assets/img/icon-exclamation-mark-warning.svg';
import config from '../../config';
import useIsMobile from '../../hooks/useIsMobile';
import { BaseModal, Button } from '../index';

import styles from './TradingRestrictionsModal.module.scss';

type Props = {
    closeModal: () => void;
}

const TradingRestrictionsModal = ({ closeModal }: Props) => {
  const isMobile = useIsMobile();

  const renderContent = (
    <>
      {!isMobile && (
      <h6 className={styles.title}>
        {lang.etfTradingRestrictionsModalTitle()}
      </h6>
      )}
      <div className={(isMobile ? styles.wrapperContent : '')}>
        {isMobile && (<img className={styles.icon} src={AlertIcon} alt="attention" />)}
        <p className={styles.content}>
          <p className={styles.text}>
            {lang.etfTradingRestrictionsModalContentHW()}
          </p>
          <Link className={styles.etfInfo} target="_blank" href={config.links.etfInfo}>
            {lang.commonLearnMoreAt()}
          </Link>
        </p>
      </div>
      <Button
        id="close trading restrictions modal"
        fullWidth
        ariaLabel="continue"
        className={(isMobile ? styles.mobileButton : styles.webButton)}
        onClick={() => closeModal()}
      >
        {lang.commonOkay()}
      </Button>
    </>
  );

  return isMobile ? (
    <div className={styles.mobileWrapper}>
      <div className={styles.container}>
        {renderContent}
      </div>
    </div>
  ) : (
    <BaseModal isOpen>
      {renderContent}
    </BaseModal>
  );
};

export default TradingRestrictionsModal;
