import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { Popover } from '@material-ui/core';

import lang from '../../lib/language';
import { ControlError } from '../../lib/util/ValidationSchemes/ValidationTypes';

import InfoIcon from '../../assets/img/icon-info-outline.svg';

import styles from './InputControl.module.scss';

type Props = {
  name: string;
  placeholder: string;
  id?: string;
  errorId?: string;
  ariaLabel?: string;
  errorAriaLabel?: string;
  value?: string | number;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string | number) => void;
  type?: 'email' | 'text' | 'number' | 'password';
  errors?: ControlError[];
  disabled?: boolean;
  onBlur?: (value: string | number) => void;
  className?: string;
  showInlineHelp?: boolean;
  withMinimumValue?: boolean;
  helperText?: string;
  onSubmit?: () => void;
}

const isInt = (value): boolean => /^[0-9]\d*$/.test(value);

const InputControl = (props: Props): JSX.Element => {
  const {
    id,
    errorId,
    ariaLabel,
    errorAriaLabel,
    name,
    placeholder,
    value,
    label,
    onChange,
    type = 'text',
    errors = [],
    disabled = false,
    showInlineHelp = false,
    onValueChange,
    onBlur,
    className,
    withMinimumValue,
    helperText,
    onSubmit,
  } = props;

  const [inputValue, setInputValue] = useState<string | number>(!value ? '' : value);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isLabelFloated, setIsLabelFloated] = useState<boolean>(false);
  const [infoPopover, setInfoPopover] = useState(null);

  const handlePopoverClick = (event: any) => setInfoPopover(event.currentTarget);
  const handlePopoverClose = () => setInfoPopover(null);

  const minValue = 1;
  const isInteger = useCallback(isInt, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    let targetValue = target.value;

    if (type === 'number') {
      if (
        withMinimumValue
        && (!isInteger(targetValue) || (isInteger(targetValue) && parseInt(targetValue, 10) < minValue))
      ) {
        targetValue = minValue.toString();
      }

      target.value = targetValue;
    }

    setInputValue(targetValue);

    onChange && onChange(event);
    onValueChange && onValueChange(targetValue);
  }, [type, onChange, onValueChange, withMinimumValue, isInteger]);

  const handleFocus = useCallback((): void => {
    setIsFocused(true);
    setIsLabelFloated(true);
  }, []);

  const handleOnKeyPress = useCallback((event: any): boolean | void => {
    const target = event.target as HTMLInputElement;
    const { key } = event;

    const targetValue = target.value + key;

    if ((type === 'number') && !isInteger(targetValue)) {
      event.preventDefault();
    }

    key === 'Enter' && onSubmit?.();
  }, [isInteger, type, onSubmit]);

  const handleBlur = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;

    let targetValue = target.value;

    const noWhitespacesCheck = targetValue.trim() !== '';

    setIsFocused(false);
    setIsLabelFloated(noWhitespacesCheck);

    if (!noWhitespacesCheck) {
      targetValue = '';
      setInputValue('');

      target.value = targetValue;

      onChange && onChange(event);
    }

    onBlur && onBlur(targetValue);
  }, [onChange, onBlur]);

  useEffect(() => {
    if (value) {
      setInputValue(value);
      setIsLabelFloated(true);
    } else {
      setIsLabelFloated(false);
    }

    return () => {
      setInputValue('');
    };
  }, [inputValue, value, setInputValue]);

  return (
    <div className={className}>
      <InputGroup
        className={[
          styles.inputContainer,
          disabled && styles.disabled,
          !label && styles.inputContainerSecondary,
        ].join(' ')}
      >
        <Input
          id={id}
          aria-label={ariaLabel}
          autoComplete="off"
          type={type}
          name={name}
          value={inputValue}
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          className={[
            styles.input,
            showInlineHelp && styles.secondaryInput,
          ].join(' ')}
          placeholder={placeholder}
          onKeyPress={handleOnKeyPress}
        />

        {label && <Label className={[styles.label, (isLabelFloated || isFocused) && styles.floatedLabel].join(' ')}>{label}</Label>}

        {showInlineHelp && (
          <>

            <InputGroupAddon
              addonType="append"
              className={styles.groupAddon}
              onClick={handlePopoverClick}
            >
              <img id="infoIcon" aria-label={`${label} info icon`} src={InfoIcon} alt="exclamation mark" />
            </InputGroupAddon>
            <Popover
              open={Boolean(infoPopover)}
              anchorEl={infoPopover}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              classes={{ paper: styles.popoverPaper }}
            >
              <p>
                {lang.commonOnboardingPersonalDetailsTaxNumberMessage()}
              </p>
            </Popover>
          </>
        )}
      </InputGroup>

      {helperText && <small className={styles.helper}>{helperText}</small>}
      {errors.length > 0 && (
      <p key={errors[0].id} id={errorId} aria-label={errorAriaLabel} className={styles.error}>{errors[0].error}</p>)}
    </div>
  );
};

export default InputControl;
