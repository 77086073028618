/**
 * This slice is dedicated to Payments data.
 * It is using CRM, Pay and Reporting (rpt) APIs and 3rd party merchant API through Pay API.
 *
 * Quick dictionary:
 *  payment - a transaction (can be, deposit, withdrawal, adjustment) - Payments table in CRM DB
 *  linked account - a bank account (with IBAN or account number, bank name etc.) - LinkedAccounts table in CRM DB
 *  wire transfer - initiating a transaction - no DB representation
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import configLib from '../../../configLib';
import { CallStatus, LinkedAccountStatusEnum } from '../../enums';
import { GenericStatusByCall } from '../../enums/status-by-call';
import {
  FundingWireTransferPostRequest,
  FundingWireTransferResponse,
  GetPaymentsPayload,
  GetWireTransferBankAccountPostRequest,
  OwnerTradingAccount,
  Payment,
  WithdrawalRequest,
} from '../../models/crm/types';
import { AccountInfo, LinkedAccount } from '../../models/linked-account/types';
import {
  CreateCardTransferSuccessResponse,
  GetCardTransferStatusSuccessResponse,
  TransferFailResponse,
} from '../../models/payment/types';
import { isCallStatusPending } from '../../util/error-handling/StatusByCallHelpers';
import {
  clearErrorsLog,
  ErrorPayloadFull,
  getAccountsAvailableCash,
  getAccountsAvailableCashFailed,
  getAccountsAvailableCashSuccess,
  getOwnerTradingAccountsFailed,
  loginFailed,
  logout,
  refreshPayments,
  refreshPaymentsDone,
} from '../common-actions';
import { EMPTY_ACCOUNT_REFERENCE } from '../reporting/constants';
import { GetAccountsAvailableCashResponse } from '../reporting/types';
import { isDeposit, isPendingTransaction, isWithdrawal } from '../selectors';

import { ALL_TRANSACTIONS_PAGE_SIZE, INITIAL_TRANSACTIONS_STATE } from './constants';
import {
  isAccountForPlatform,
  isActiveOrWithdrawalRestrictedAccount,
  parsePayments,
  setCalculatedCash,
  setCallForRefresh,
  setPaymentCallStatus,
} from './helpers';
import {
  CreateCardTransferPayload,
  LastCardTransferType,
  PaymentCall,
  PaymentStatusUIStatePayload,
  RefreshPaymentsPayload,
  RegisterLinkedAccountPayload,
  TransactionsState,
  UploadBankStatementPayload,
  WebLastPaymentCache,
} from './types';


export interface PaymentState {
  isInitialPaymentsDataLoaded: boolean
  // TODO: Move to server calculation
  /**
   * Calculated when available cash is received and pending deposits summed up
   */
  calculatedCash: number
  positiveInstaCash: number
  availableCash: number
  // TODO: Move to server calculation
  /**
   * Used for temporary calculation of cash
   */
  pendingWithdrawalsTotalAmount: number
  lastUserTransaction: Payment | null
   /**
   * First 15 transactions of any kind
   */
  payments: TransactionsState
  /**
   * All transactions of any kind - for the last 5 years, limited to 1000
   */
  paymentsAll: TransactionsState
  /**
   * Bank accounts
   */
  linkedAccountIdByLastPayment: null | number
  linkedAccountIdByLastWithdrawal: null | number
  linkedAccounts: LinkedAccount[]
  selectedLinkedAccountIndex: number,
  /**
   * Bank account used for Bank Transfer (aka wire transfer)
   */
  vendorBankAccount: AccountInfo
  bankTransferInfo: FundingWireTransferResponse
  /**
   * Account used for reference in calls and DB
   */
  tradingAccounts: OwnerTradingAccount[]
  /**
   * Neded for Web only.
   * Cached last payment (initially needed for Card Transfers only)
   * Web uses it when coming back from merchant redirect (DSK etc.)
   * and app is loaded from scratch
   */
  webLastCardTransfer: WebLastPaymentCache
  /**
    *Cached last payment (mostly used by mobile, Web uses `webLastCardTransfer`)
    */
  lastCardTransfer: LastCardTransferType
  /**
   * Marks if there is at least one Deposit performed by this user
   */
  hasDeposit: boolean
  /**
   * Marks if there is at least one Bank Account added by this user
   */
   hasBankAccount: boolean
  /**
   * Payment calls register of statuses
   */
  statusByCall: GenericStatusByCall<PaymentCall>
  /**
   * State holding payment calls that need to be reloded
   * For example: getPayments, getAvailableCash etc.
   */
  callsForRefresh: RefreshPaymentsPayload | null
  errorsLog: string[],
}

export const INITIAL_PAYMENT_STATE: PaymentState = {
  isInitialPaymentsDataLoaded: false,
  availableCash: 0,
  positiveInstaCash: 0,
  calculatedCash: 0,
  pendingWithdrawalsTotalAmount: 0,
  lastUserTransaction: null,
  payments: INITIAL_TRANSACTIONS_STATE,
  paymentsAll: INITIAL_TRANSACTIONS_STATE,
  linkedAccountIdByLastPayment: null,
  linkedAccountIdByLastWithdrawal: null,
  linkedAccounts: [],
  selectedLinkedAccountIndex: 0,
  vendorBankAccount: {} as AccountInfo,
  bankTransferInfo: {} as FundingWireTransferResponse,
  tradingAccounts: [] as OwnerTradingAccount[],
  webLastCardTransfer: {},
  lastCardTransfer: {
    amount: 0,
  },
  statusByCall: {},
  hasDeposit: false,
  hasBankAccount: false,
  callsForRefresh: null,
  errorsLog: [],
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState: INITIAL_PAYMENT_STATE,
  reducers: {
    /** Meant to be called only by `checkInitialLoadEpic` in `payments/epics.ts` */
    confirmInitialPaymentsLoaded(state: PaymentState) {
      state.isInitialPaymentsDataLoaded = true;
    },
    getPayments(state: PaymentState, action: PayloadAction<GetPaymentsPayload>) {
      if (isCallStatusPending(PaymentCall.getPayments, state.statusByCall)) {
        const newPayload = cloneDeep(action.payload);
        newPayload.isQueued = true;
        state.payments.callsInQueue.push(newPayload);
        return;
      }

      const { isQueued, isAllTransactions } = action.payload;

      if (isQueued) state.payments.callsInQueue.shift();

      state.payments.isAllTransactionsCall = !!isAllTransactions;

      if (!isAllTransactions && action.payload.pageNumber === action.payload.defaultPageNumber) {
        setPaymentCallStatus(PaymentCall.getPayments, CallStatus.PENDING, state);
        state.payments.data.splice(0, state.payments.data.length);
      } else if (isAllTransactions) {
        setPaymentCallStatus(PaymentCall.getPaymentsAll, CallStatus.PENDING, state);
      }
    },
    getPaymentsCompleted(state: PaymentState, action: PayloadAction<Payment[]>) {
      const payments = action.payload;

      /**
       * If the records are `page_size + 1` it means that this is not last page (`page_size + 1` is BE indicator for more data available on next page)
       */
      const hasMoreData = payments.length > state.payments.pageSize;

      if (hasMoreData) {
        /**
         * remove the record which indicates that this is not last page
         */
        payments.pop();
      }

      parsePayments(payments, state.payments);
      setPaymentCallStatus(PaymentCall.getPayments, CallStatus.READY, state);
    },
    getPaymentsFailed(state: PaymentState, action: PayloadAction<ErrorPayloadFull>) {
      setPaymentCallStatus(PaymentCall.getPayments, CallStatus.ERROR, state, action.payload.status);
      state.payments = cloneDeep(INITIAL_TRANSACTIONS_STATE);
    },
    getPaymentsAllCompleted(state: PaymentState, action: PayloadAction<Payment[]>) {
      const receivedTransactions = action.payload;
      let deposits: Payment[] = [];
      let withdrawals: Payment[] = [];
      setPaymentCallStatus(PaymentCall.getPaymentsAll, CallStatus.READY, state);

      // parse all payments
      state.payments = cloneDeep(INITIAL_TRANSACTIONS_STATE);
      state.paymentsAll = cloneDeep(INITIAL_TRANSACTIONS_STATE);
      state.paymentsAll.pageSize = ALL_TRANSACTIONS_PAGE_SIZE;
      state.paymentsAll.filteredOut = [];
      state.paymentsAll.ignored = [];
      const { payments, paymentsAll } = state;
      parsePayments(receivedTransactions, paymentsAll, payments, deposits, withdrawals);
      state.lastUserTransaction = receivedTransactions.find(item => isDeposit(item) || isWithdrawal(item)) ?? null;

      // process deposits
      if (deposits.length) state.hasDeposit = true;

      // process withdrawals
      state.pendingWithdrawalsTotalAmount = (
        withdrawals
          .reduce(
            (prev, current) => (isPendingTransaction(current) ? current.amount : 0) + prev,
            0,
          )
      );
      const { availableCash, positiveInstaCash: instaCash } = state;
      setCalculatedCash(state, { availableCash, instaCash }, state.pendingWithdrawalsTotalAmount);
      setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.UI_DONE, state);
    },
    getPaymentsAllFailed(state: PaymentState, action: PayloadAction<ErrorPayloadFull>) {
      state.paymentsAll = cloneDeep(INITIAL_TRANSACTIONS_STATE);
      setPaymentCallStatus(PaymentCall.getPaymentsAll, CallStatus.ERROR, state, action.payload.status);
      setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.ERROR, state);
    },
    getIndividualLinkedAccounts(state) {
      setPaymentCallStatus(PaymentCall.getIndividualLinkedAccounts, CallStatus.PENDING, state);
    },
    getIndividualLinkedAccountsSuccess: (state, action) => {
      const newAccounts = action.payload
        .filter((acc: LinkedAccount) => acc.status !== LinkedAccountStatusEnum.Inactive);
      if (state.linkedAccounts.length !== newAccounts.length) {
        state.selectedLinkedAccountIndex = newAccounts.length - 1;
      }
      state.linkedAccounts = newAccounts;
      if (state.linkedAccounts.length === 0) {
        state.hasBankAccount = false;
      } else {
        state.hasBankAccount = state.linkedAccounts.length >= 1;
      }
      setPaymentCallStatus(PaymentCall.getIndividualLinkedAccounts, CallStatus.READY, state);
    },
    getIndividualLinkedAccountsFailed: (state, action: PayloadAction<ErrorPayloadFull>) => {
      setPaymentCallStatus(PaymentCall.getIndividualLinkedAccounts, CallStatus.ERROR, state, action.payload.status);
    },
    updateSelectedLinkedAccountIndex(state, action: PayloadAction<number>) {
      state.selectedLinkedAccountIndex = action.payload;
    },
    uploadDocumentForLinkedAccount(state, action: PayloadAction<UploadBankStatementPayload>) {
      setPaymentCallStatus(PaymentCall.uploadDocumentForLinkedAccount, CallStatus.PENDING, state);
    },
    uploadDocumentForLinkedAccountFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      setPaymentCallStatus(PaymentCall.uploadDocumentForLinkedAccount, CallStatus.ERROR, state, action.payload.status);
    },
    registerLinkedAccount(state, action: PayloadAction<RegisterLinkedAccountPayload>) {
      setPaymentCallStatus(PaymentCall.uploadDocumentForLinkedAccount, CallStatus.READY, state);
      setPaymentCallStatus(PaymentCall.registerLinkedAccount, CallStatus.PENDING, state);
    },
    registerLinkedAccountSuccess: (state, action: PayloadAction<LinkedAccount>) => {
      setPaymentCallStatus(PaymentCall.registerLinkedAccount, CallStatus.READY, state);
      setCallForRefresh(state, PaymentCall.getIndividualLinkedAccounts);
    },
    registerLinkedAccountFailed: (state, action: PayloadAction<ErrorPayloadFull>) => {
      setPaymentCallStatus(PaymentCall.registerLinkedAccount, CallStatus.ERROR, state, action.payload.status);
    },
    getWireTransferBankAccount: (state: PaymentState, action: PayloadAction<GetWireTransferBankAccountPostRequest>) => {
      setPaymentCallStatus(PaymentCall.getWireTransferBankAccount, CallStatus.PENDING, state);
      state.vendorBankAccount = {} as AccountInfo;
    },
    getWireTransferBankAccountSuccess: (state: PaymentState, action: PayloadAction<AccountInfo>) => {
      setPaymentCallStatus(PaymentCall.getWireTransferBankAccount, CallStatus.READY, state);
      state.vendorBankAccount = action.payload;
    },
    getWireTransferBankAccountFailed: (state: PaymentState, action: PayloadAction<ErrorPayloadFull>) => {
      setPaymentCallStatus(PaymentCall.getWireTransferBankAccount, CallStatus.ERROR, state, action.payload.status);
      state.vendorBankAccount = {} as AccountInfo;
    },
    createBankTransfer: (state: PaymentState, action: PayloadAction<FundingWireTransferPostRequest>) => {
      state.bankTransferInfo = {} as FundingWireTransferResponse;
      setPaymentCallStatus(PaymentCall.createBankTransfer, CallStatus.PENDING, state);
    },
    createBankTransferSuccess: (state: PaymentState, action: PayloadAction<FundingWireTransferResponse>) => {
      setPaymentCallStatus(PaymentCall.createBankTransfer, CallStatus.READY, state);
      state.bankTransferInfo = action.payload;
      setCallForRefresh(state, PaymentCall.getPaymentsAll);
      setCallForRefresh(state, PaymentCall.getAvailableCash);
    },
    createBankTransferFailed: (state: PaymentState, action: PayloadAction<ErrorPayloadFull>) => {
      setPaymentCallStatus(PaymentCall.createBankTransfer, CallStatus.ERROR, state, action.payload.status);
      state.bankTransferInfo = {} as FundingWireTransferResponse;
    },
    getOwnerTradingAccounts: (state: PaymentState) => {
      state.tradingAccounts = [] as OwnerTradingAccount[];
      setPaymentCallStatus(PaymentCall.getOwnerTradingAccounts, CallStatus.PENDING, state);
    },
    getOwnerTradingAccountsSuccess: (state: PaymentState, action: PayloadAction<OwnerTradingAccount[]>) => {
      state.tradingAccounts = action.payload.filter(
        account => isAccountForPlatform(account) && isActiveOrWithdrawalRestrictedAccount(account),
      );
      setPaymentCallStatus(PaymentCall.getOwnerTradingAccounts, CallStatus.READY, state);
    },
    registerWithdrawalRequest: (state: PaymentState, action: PayloadAction<WithdrawalRequest>) => {
      setPaymentCallStatus(PaymentCall.registerWithdrawalRequest, CallStatus.PENDING, state);
    },
    registerWithdrawalRequestSuccess: (state: PaymentState, action: PayloadAction<WithdrawalRequest>) => {
      setPaymentCallStatus(PaymentCall.registerWithdrawalRequest, CallStatus.READY, state);
      setCallForRefresh(state, PaymentCall.getAvailableCash);
      setCallForRefresh(state, PaymentCall.getPaymentsAll);
    },
    registerWithdrawalRequestFail: (state: PaymentState, action: PayloadAction<ErrorPayloadFull>) => {
      // eslint-disable-next-line max-len
      setPaymentCallStatus(PaymentCall.registerWithdrawalRequest, CallStatus.ERROR, state, action.payload.errorData?.status);
    },
    createCardTransfer(state: PaymentState, action: PayloadAction<CreateCardTransferPayload>) {
      const { amount } = action.payload;
      state.lastCardTransfer.amount = amount;
      if (configLib.isWeb) {
        state.webLastCardTransfer.amount = amount;
      }
      delete state.lastCardTransfer.createTransferData;
      delete state.lastCardTransfer.statusCheckData;
      delete state.lastCardTransfer.transferFailedData;
      setPaymentCallStatus(PaymentCall.createCardTransfer, CallStatus.PENDING, state);
    },
    createCardTransferSuccess(state: PaymentState, action: PayloadAction<CreateCardTransferSuccessResponse>) {
      state.lastCardTransfer.createTransferData = action.payload;

      const { payment_reference } = action.payload;
      if (configLib.isWeb) {
        state.webLastCardTransfer = {
          ...state.webLastCardTransfer,
          payment_reference,
        };
      }
      setPaymentCallStatus(PaymentCall.createCardTransfer, CallStatus.READY, state);
    },
    createCardTransferFail(state: PaymentState, action: PayloadAction<TransferFailResponse>) {
      setPaymentCallStatus(PaymentCall.createCardTransfer, CallStatus.ERROR, state, action.payload.status);
      state.lastCardTransfer.transferFailedData = action.payload;
    },
    getCardTransferStatus(state: PaymentState) {
      setPaymentCallStatus(PaymentCall.getCardTransferStatus, CallStatus.PENDING, state);
    },
    getCardTransferStatusSuccess(state: PaymentState, action: PayloadAction<GetCardTransferStatusSuccessResponse>) {
      setPaymentCallStatus(PaymentCall.getCardTransferStatus, CallStatus.READY, state);
      state.lastCardTransfer.statusCheckData = action.payload;
      setCallForRefresh(state, PaymentCall.getPaymentsAll);
      setCallForRefresh(state, PaymentCall.getAvailableCash);
    },
    getCardTransferStatusFail(state: PaymentState, action: PayloadAction<TransferFailResponse>) {
      setPaymentCallStatus(PaymentCall.getCardTransferStatus, CallStatus.ERROR, state, action.payload.status);
      state.lastCardTransfer.transferFailedData = action.payload;
    },
    /**
     * For marking UI state - a certain popup has been shown, redirect url used etc.
     */
    setPaymentStatusUIState(state: PaymentState, action: PayloadAction<PaymentStatusUIStatePayload>) {
      const { call, isDone } = action.payload;
      const status = isDone ? CallStatus.UI_DONE : CallStatus.UI_PENDING;
      setPaymentCallStatus(call, status, state);
    },
    closeCardTransferStatus(state) {
      delete state.lastCardTransfer.createTransferData;
      delete state.lastCardTransfer.statusCheckData;
      delete state.lastCardTransfer.transferFailedData;
      state.lastCardTransfer.amount = 0;
      if (configLib.isWeb) {
        state.webLastCardTransfer = {};
      }
    },
    setBankAccountIdByLastPayment(state: PaymentState, action) {
      state.linkedAccountIdByLastPayment = action.payload;
    },
    setBankAccountIdByLastWithdrawal(state: PaymentState, action) {
      state.linkedAccountIdByLastWithdrawal = action.payload;
    },
    setPaymentCallStatusToInitial: (state, action) => {
      setPaymentCallStatus(PaymentCall.registerLinkedAccount, CallStatus.INITIAL, state);
    },
  },
  extraReducers: {
    [loginFailed.type]: state => { state.isInitialPaymentsDataLoaded = false; },
    [clearErrorsLog.type]: state => { state.errorsLog = []; },
    [getOwnerTradingAccountsFailed.type]: (state: PaymentState, action: PayloadAction<ErrorPayloadFull>) => {
      // eslint-disable-next-line max-len
      setPaymentCallStatus(PaymentCall.getOwnerTradingAccounts, CallStatus.ERROR, state, action.payload.errorData?.status);
    },
    [getAccountsAvailableCash.type]: (state: PaymentState) => {
      setPaymentCallStatus(PaymentCall.getAvailableCash, CallStatus.PENDING, state);
    },
    [getAccountsAvailableCashSuccess.type]: (
      state: PaymentState,
      action: PayloadAction<GetAccountsAvailableCashResponse>,
    ) => {
      const data = action.payload ?? EMPTY_ACCOUNT_REFERENCE;
      if (data?.availableCash == null) {
        console.error(`[payment/index] getAccountsAvailableCashSuccess - available cash data not valid - ${data?.availableCash}`, data);
        state.calculatedCash = 0;
        setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.UI_DONE, state);
        setPaymentCallStatus(PaymentCall.getAvailableCash, CallStatus.ERROR, state);
        return;
      }

      state.availableCash = data.availableCash;
      setCalculatedCash(state, data, state.pendingWithdrawalsTotalAmount);
      setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.UI_DONE, state);
      setPaymentCallStatus(PaymentCall.getAvailableCash, CallStatus.READY, state);
    },
    [getAccountsAvailableCashFailed.type]: (
      state: PaymentState,
      action: PayloadAction<ErrorPayloadFull>,
    ) => {
      setPaymentCallStatus(PaymentCall.getAvailableCash, CallStatus.ERROR, state, action.payload.status);
      setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.ERROR, state);
    },
    [refreshPayments.type]: (state: PaymentState, action: PayloadAction<RefreshPaymentsPayload>) => {
      const {
        [PaymentCall.cashCalculated]: willUpdateCash,
        [PaymentCall.getPaymentsAll]: willUpdateAllPayments,
      } = (action.payload ?? state.callsForRefresh) ?? {};
      if (willUpdateCash || willUpdateAllPayments) {
        setPaymentCallStatus(PaymentCall.cashCalculated, CallStatus.PENDING, state);
      }
    },
    [refreshPaymentsDone.type]: (state: PaymentState) => {
      state.callsForRefresh = null;
    },
    [logout.type]: state => {
      const newState = cloneDeep(INITIAL_PAYMENT_STATE);
      newState.errorsLog = state.errorsLog;

      return newState;
    },
  },
});

export const {
  confirmInitialPaymentsLoaded,
  createCardTransfer,
  createCardTransferSuccess,
  createCardTransferFail,
  getCardTransferStatus,
  getCardTransferStatusSuccess,
  getCardTransferStatusFail,
  closeCardTransferStatus,
  getPayments,
  getPaymentsCompleted,
  getPaymentsFailed,
  getPaymentsAllCompleted,
  getPaymentsAllFailed,
  getIndividualLinkedAccounts,
  getIndividualLinkedAccountsFailed,
  getIndividualLinkedAccountsSuccess,
  registerLinkedAccount,
  registerLinkedAccountFailed,
  registerLinkedAccountSuccess,
  updateSelectedLinkedAccountIndex,
  uploadDocumentForLinkedAccount,
  uploadDocumentForLinkedAccountFailed,
  getWireTransferBankAccount,
  getWireTransferBankAccountFailed,
  getWireTransferBankAccountSuccess,
  createBankTransfer,
  createBankTransferFailed,
  createBankTransferSuccess,
  getOwnerTradingAccounts,
  getOwnerTradingAccountsSuccess,
  registerWithdrawalRequest,
  registerWithdrawalRequestSuccess,
  registerWithdrawalRequestFail,
  setPaymentStatusUIState,
  setBankAccountIdByLastPayment,
  setBankAccountIdByLastWithdrawal,
  setPaymentCallStatusToInitial,
} = paymentSlice.actions;

export default paymentSlice.reducer;
