import { CallState, GenericStatusByCall } from '../../enums/status-by-call';
import { isOneOf } from '../../util/DataHelpers';
import { isCallStatusError } from '../../util/error-handling/StatusByCallHelpers';

const CONSOLE: 'both' | 'reactotron' | 'console' = 'both';
const PRINT_START = false;
let startTime: any = {};

export const getTime = () => new Date().getTime();

// eslint-disable-next-line default-param-last
export const calcTime = (title: string, forceStart = false, data?: any, defaultLabel = 'Cache update') => {
  const useReactotron = isOneOf(CONSOLE, [ 'both', 'reactotron' ]);
  const useConsole = isOneOf(CONSOLE, [ 'both', 'console' ]);
  const useConsoleTime = isOneOf(CONSOLE, [ 'time' ]);

  if (!startTime || forceStart) {
    startTime[title] = getTime();
    if (PRINT_START) {
      if (useReactotron) console.tron.log!(`[${title ?? 'calcTime'}] Start cache update`);
      if (useConsole) console.log(`[${title ?? 'calcTime'}] Start cache update`);
    }
    if (useConsoleTime) console.time(`[${title ?? 'calcTime'}]`);
    if (data) {
      if (useReactotron) {
        console.tron.display!({
          name: `[calcTime] Data -- ${title}`,
          value: data,
          important: false,
        });
      }
      if (useConsole) {
        console.debug(`[calcTime] Data -- ${title}`, data);
      }
    }
  } else {
    const newStart = getTime();
    const diff = newStart - startTime[title];
    startTime[title] = newStart;
    let label = defaultLabel;
    if (diff > 100) label += ': BIG';
    else if (diff > 20) label += ': MID';
    else if (diff > 10) label += ': small';
    if (useReactotron) console.tron.log!(`[${title ?? 'calcTime'}] ${label} - ${diff}ms`);
    if (useConsole) console.log(`[${title ?? 'calcTime'}] ${label} - ${diff}ms`);
    if (useConsoleTime) console.timeEnd(`[${title ?? 'calcTime'}]`);
  }
};
