/**
 * To get price updates in mocked mode - do:
 *   (1) In `configDebug.ts` - set MOCK.TRADING_MODE to `up`, `down` or `random` to get dynamic price updates.
 *   (2) In `configDebug.ts` - also check the TRADING_MDS_REFRESH_RATE and set it to your needs.
 *   (3) In `libSettings.ts` - check the THROTTLE_TIME_PRICE_UPDATES_MS and set it to your needs.
 *       It will affect TRADING_MDS_REFRESH_RATE by throttling these updates.
 *
 * NOTE: If the above is not done correctly - price updates will not be visible.
 */
import { produce } from 'immer';
import { isObject, random } from 'lodash';
import { entity } from 'simpler-state';

import entityReduxLogger from '../debug/helpers/entity-redux-logger';
import { USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH, USE_ONLY_VISIBLE_SYMBOLS_IF_NOT_AUTO_UNSUBSCRIBE } from '../libSettings';
import { GWMarketDataSummaryBucket } from '../models/gateway/types';
import { OHLCV, TradeData } from '../models/market-data/types';
import { SymbolPriceData } from '../store/market-data/charting/types';
import { EMPTY_CALCULATED_PRICE_DATA, EMPTY_PRICE_DATA, parseSymbolPriceSourceData } from '../store/market-data/helpers';
import { InstrumentSnapshotData, InstrumentSnapshotMap, MultipleOHLCVDataForPriceCalculation, TradeDataCache } from '../store/market-data/types';
import { CalculatedSymbolPrices, calculateSymbolPrices } from '../util/ChartingHelpers';
import { formatNumber, formatNumberWithSuffix } from '../util/DataHelpers';
import { NullableNumber, NullableString, TypedObject, ZERO_PERCENT, ZERO_VALUE } from '../util/types';

import VisibleSymbolsCache from './VisibleSymbolsCache';


export type PriceChangeDirection = '+' | '-' | ''
export type CalculatedTradePrice = {
  symbol: string
  prevPrice: NullableNumber
  currentPrice: NullableNumber
  currentPriceDate?: string
  change: string
  changePercent: string
  direction: PriceChangeDirection
  priceData: SymbolPriceData
  calculatedSymbolPrices: CalculatedSymbolPrices
  changePercentWithSign: string
  hasTradeUpdate?: boolean
  hasInstrumentSnapshotData?: boolean
  has1DChartData?: boolean
  has3MChartData?: boolean
  tradeTime?: string
}

export type CalculatedTradePriceDataMap = Partial<Record<string, CalculatedTradePrice>>

const generateEmptyTradePrice = (symbol: NullableString): CalculatedTradePrice => ({
  symbol: symbol ?? '',
  currentPrice: null,
  prevPrice: null,
  change: ZERO_VALUE,
  changePercent: ZERO_PERCENT,
  direction: '',
  priceData: EMPTY_PRICE_DATA,
  calculatedSymbolPrices: EMPTY_CALCULATED_PRICE_DATA,
  changePercentWithSign: '',
  tradeTime: '',
});

type TemporaryDataMap = { [symbol: string]: TradeData | InstrumentSnapshotData | OHLCV[] }

function generateDataIfMockedMode(state: CalculatedTradePriceDataMap, newDataMap: TemporaryDataMap) {
  const { MOCK } = require('../../configDebug');
  if (MOCK.ENABLED && MOCK.TRADING_MODE === 'static') {
    const priceData = {
      lastPrice: random(26.84, 33.52, true),
      close: random(23.12, 31.89, true),
      afterClose: random(24.67, 30.73, true),
      previousClose: random(20.00, 70.00, true),
      previousAfterClose: random(24.67, 30.73, true),
      prevButOneClose: random(20.00, 70.00, true),
      hasDataForToday: true,
      hasAfterMarketData: true,
    };
    for (const symbol in newDataMap) {
      let tradeItem: CalculatedTradePrice = state[symbol] ?? generateEmptyTradePrice(symbol);
      updateItemWithPriceData(tradeItem, priceData, symbol);
    }
    return true;
  }

  return false;
}

function updateItemWithPriceData(
  itemToUpdate: CalculatedTradePrice,
  data: SymbolPriceData | TradeData,
  symbol?: string,
) {
  /* eslint-disable no-param-reassign */
  const isSymbolPriceData = (
    isObject(data)
    && (
      Object.keys(data).includes('lastPrice')
      || Object.keys(data).includes('afterClose')
      || Object.keys(data).includes('hasDataForToday')
      || Object.keys(data).includes('hasAfterMarketData')
    )
  );
  let priceData: SymbolPriceData = EMPTY_PRICE_DATA;
  let tradeData: TradeData | null = null;

  if (isSymbolPriceData) {
    priceData = data as SymbolPriceData;
  } else {
    priceData = itemToUpdate.priceData;
    tradeData = data as TradeData;
  }

  // don't update prices unless it is a trade price update after it has been received (see if at the end of this function)
  if (itemToUpdate.hasTradeUpdate && !tradeData) return;

  const calculatedSymbolPrices = calculateSymbolPrices(priceData, tradeData);
  const {
    prevMainPrice, currentMainPrice, changeMainPrice, changedPercentMainPrice,
    mainPriceDirection,
  } = calculatedSymbolPrices;

  itemToUpdate.direction = mainPriceDirection;
  itemToUpdate.prevPrice = prevMainPrice;
  itemToUpdate.currentPrice = currentMainPrice;
  itemToUpdate.change = formatNumber(changeMainPrice);
  itemToUpdate.changePercent = formatNumberWithSuffix(changedPercentMainPrice, '%');
  itemToUpdate.priceData = priceData;
  itemToUpdate.calculatedSymbolPrices = calculatedSymbolPrices;
  itemToUpdate.changePercentWithSign = `${mainPriceDirection}${formatNumberWithSuffix(changedPercentMainPrice, '%')}`;
  itemToUpdate.tradeTime = (data as TradeData).tradetime;
  // lock updating price once trade price has been received and prev price exists
  if (!itemToUpdate.hasTradeUpdate && tradeData && itemToUpdate.prevPrice != null) {
    itemToUpdate.hasTradeUpdate = true;
  }
  /* eslint-enable no-param-reassign */
}

function updateNewState(
  newData: TradeDataCache | InstrumentSnapshotMap | MultipleOHLCVDataForPriceCalculation,
  state: CalculatedTradePriceDataMap,
  source: 'instr' | 'trade' | 'chart-data',
) {
  const newDataMap: TemporaryDataMap = (
    source === 'chart-data' ? newData?.data : newData
  ) as any;
  if (generateDataIfMockedMode(state, newDataMap)) return;


  const { logConfig } = require('../../configDebug');
  const { priceFlow } = logConfig;

  if (priceFlow) {
    const debugData: any = newData?.data ?? newData;
    const debugSymbols = Object.keys(debugData);
    const debugState = {} as any;
    const debugInstrItems = source === 'instr' ? debugSymbols.join() : '';
    const debugChartBucket = newData?.bucket ? `-${newData.bucket}` : '';
    const debugChartItems = source === 'chart-data' ? Object.keys(newData.data).join() : '';
    debugSymbols.forEach(symbol => { debugState[symbol] = debugData[symbol]; });
    console.log(`Trade-update -- ${source}${debugChartBucket} ${debugChartItems} ${debugInstrItems} [price-flow]`); // eslint-disable-line
    console.debug(`Trade-update -- ${source}${debugChartBucket} ${debugChartItems} ${debugInstrItems} [price-flow]`, {newData, state}); // eslint-disable-line
    console.tron.display!({
      name: `Trade-update ${source.slice(0, 5)}${debugChartBucket} ${debugChartItems} ${debugInstrItems} [price-flow]`,
      value: { newData, state },
      important: false,
    });
  }

  const symbols = Object.keys(newDataMap);
  const isOneSymbol = symbols.length === 1;

  symbols.forEach(symbol => {
    const isNewItem = !state[symbol];
    let tradeItem: CalculatedTradePrice = state[symbol] ?? generateEmptyTradePrice(symbol);
    const { currentPrice, prevPrice } = tradeItem;

    if (isNewItem) state[symbol] = tradeItem;

    switch (source) {
      case 'instr': {
        const instrumentSnapshot = (newData as InstrumentSnapshotMap)[symbol] as InstrumentSnapshotData;
        const priceData = parseSymbolPriceSourceData('instrument-snapshot', instrumentSnapshot, tradeItem.priceData);
        tradeItem.hasInstrumentSnapshotData = true;
        updateItemWithPriceData(tradeItem, priceData, symbol);
        if (isOneSymbol) {
          const { updateBigChartLineClose } = require('./BigChartCache');
          updateBigChartLineClose(priceData.lineClose);
        }
        break;
      }

      case 'trade': {
        const trade = (newData as TradeDataCache)[symbol] as TradeData;
        updateItemWithPriceData(tradeItem, trade, symbol);
        break;
      }

      case 'chart-data': {
        const { bucket, data: dataFromCache } = newData as MultipleOHLCVDataForPriceCalculation;
        const data = dataFromCache[symbol]; // check if no data available
        if (!data) break;

        switch (bucket) {
          case GWMarketDataSummaryBucket.OneDay:
            tradeItem.has1DChartData = true;
            break;
          case GWMarketDataSummaryBucket.ThreeMonths:
            tradeItem.has3MChartData = true;
            break;
          default:
            break;
        }

        const priceData = parseSymbolPriceSourceData('chart-data', { bucket, data }, tradeItem.priceData);
        updateItemWithPriceData(tradeItem, priceData, symbol);
        break;
      }

      default:
        break;
    }
  });

  const { __DEV__ } = require('../../configLib').default;
  if (__DEV__ && logConfig.tradePriceCacheUpdates) {
    const { tradePriceCacheUpdatesSymbol: rawSymbol } = logConfig;
    const filterSymbol = rawSymbol ? `${rawSymbol}`.toUpperCase() : null;
    let debugState: CalculatedTradePriceDataMap = { ...(
      filterSymbol
        ? { [filterSymbol]: state[filterSymbol] }
        : state
    ) };
    let titles = '\n';
    const debugData: any = {};
    Object.entries(debugState).forEach(([ debugSymbol, debugSymbolState ]) => {
      const { prevPrice, currentPrice, change, changePercent } = (debugSymbolState ?? {}) as CalculatedTradePrice;
      titles += `\t\t${debugSymbol}  => ${prevPrice}->${currentPrice}  ${change} (${changePercent})\n`;
      debugData[debugSymbol] = { debugSymbolState, newData };
    });
    const src = source === 'chart-data' ? `${source}/${newData.bucket}` : source;
    const msg = `[TradePriceCache] State updated (${src}) for ${titles}`;
    console.log(msg);
    console.debug(msg, debugData);
    console.tron.display({ name: `[TradePriceCache] State updated (${src})`, value: debugData });
  }
}

const defaultTransform = (symbol: NullableString) => ((data: CalculatedTradePriceDataMap) => data[symbol ?? '']);
type TransformFunction = (
  (data: CalculatedTradePriceDataMap) => CalculatedTradePrice | NullableNumber | NullableString
)

const TradePriceCache = entity({} as CalculatedTradePriceDataMap, entityReduxLogger('TradePrice', 'trade'));

/** Used only by Gateway service to update cache from response messages. */
export const updateTradePriceCacheFromInstrumentSnapshot = (newItems: InstrumentSnapshotMap) => {
  TradePriceCache.set(
    produce(state => updateNewState(newItems, state, 'instr')),
  );
};

/** Used only by Gateway service to update cache from response messages. */
export const updateTradePriceCacheFromTrade = (newItemsRaw: TradeDataCache) => {
  if (!newItemsRaw) return;

  const { newItems, count } = (
    !USE_AUTO_UNSUBSCRIBE_ON_GROUP_SWITCH && USE_ONLY_VISIBLE_SYMBOLS_IF_NOT_AUTO_UNSUBSCRIBE
      ? (() => {
        const rawKeys = Object.keys(newItemsRaw);
        const { component, symbols } = VisibleSymbolsCache.get();
        const result: TradeDataCache = {};
        if (symbols.length) {
          // if it is a component with stocks - add current visible symbols to update
          rawKeys.forEach(symbol => {
            if (symbols.includes(symbol)) result[symbol] = newItemsRaw[symbol];
          });
        }
        const resKeys = !result ? [] : Object.keys(result);
        const { logConfig } = require('../../configDebug');
        if (logConfig.subscriptionsFlow) {
          console.tron.log!(`[UPDATE-ONLY-VISIBLE-SYMBOLS] input: ${rawKeys}   real: ${resKeys}`);
        }
        return { newItems: result, count: resKeys.length };
      })()
      : { newItems: newItemsRaw, count: Object.keys(newItemsRaw).length }
  );
  if (count) {
    TradePriceCache.set(
      produce(state => updateNewState(newItems, state, 'trade')),
    );
  }
};

/** Used only by Gateway service to update cache from response messages. */
export const updateTradePriceCacheFromChartData = (newData: MultipleOHLCVDataForPriceCalculation) => {
  TradePriceCache.set(
    produce(state => updateNewState(newData, state, 'chart-data')),
  );
};

// exporting setter only for unit testing and mocked mode
export const _setTradePricesCache_InUnitTestsAndMockedMode = TradePriceCache.set; // eslint-disable-line no-underscore-dangle

// hiding direct setter by not including it in exports
export default {
  /**
   * @param caller Used when debugging to differentiate between cases. The default one extracts the particular symbol changes only.
   */
  use: (symbol: NullableString, caller: string) => (
    (TradePriceCache.use(defaultTransform(symbol)) ?? generateEmptyTradePrice(symbol))
  ),
  /**
   * Be careful when using `useCustom` - read simpler-state documentation to use the right transform function
   */
  useCustom: TradePriceCache.use,
  get: (symbol: NullableString) => TradePriceCache.get()[symbol!] ?? generateEmptyTradePrice(symbol),
  getAll: TradePriceCache.get,
};
