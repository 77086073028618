import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { CallStatus } from '../../lib/enums';
import { PROCESS_CANCEL_MODAL_DELAY_MS, PROCESS_FINISHED_MODAL_DELAY_MS } from '../../lib/libSettings';

import ModalContent from './ModalBody';

import styles from './ProcessFinishedModal.module.scss';

type MessageStructure = {
  title: string;
  body: string;
}

type Props = {
  processFailedMessage: string;
  processSuccessMessageTitle: string;
  toggleModal: (arg?: any) => void;
  isSelfClosing?: boolean;
  callStatus?: CallStatus;
  isCancelOrder?: boolean;
  processSuccessMessageBody?: string;
  defaultMessageTitle?: string;
  defaultMessageBody?: string;
  processPendingMessage?: MessageStructure;
  processPartiallyRejectedMessage?: MessageStructure;
};

const ProcessFinishedModal = (
  {
    callStatus,
    toggleModal,
    processFailedMessage,
    processSuccessMessageBody,
    processSuccessMessageTitle,
    isSelfClosing = true,
    isCancelOrder = false,
    processPendingMessage,
    processPartiallyRejectedMessage,
    defaultMessageTitle,
    defaultMessageBody,
  }: Props,
): JSX.Element => {
  const [modalOpened, setModalOpened] = useState<boolean>(true);

  useEffect(() => {
    if (isSelfClosing) {
      setTimeout(() => {
        setModalOpened(false);
      }, isCancelOrder ? PROCESS_CANCEL_MODAL_DELAY_MS : PROCESS_FINISHED_MODAL_DELAY_MS);
    }
  }, []); // eslint-disable-line

  return (
    <>
      <Modal
        centered
        isOpen={modalOpened}
        className={styles.modal}
        backdropClassName={styles.backdrop}
        contentClassName={styles.modalContent}
        onClosed={() => toggleModal(null)}
        toggle={() => !isSelfClosing && toggleModal(null)}
      >
        {/* <Container fluid> */}
        <ModalBody className={styles.content}>
          <div className={styles.contentWrapper}>
            {
              <ModalContent
                callStatus={callStatus}
                processFailedMessage={processFailedMessage}
                defaultMessageBody={defaultMessageBody}
                defaultMessageTitle={defaultMessageTitle}
                processPendingMessage={processPendingMessage}
                processSuccessMessageTitle={processSuccessMessageTitle}
                processSuccessMessageBody={processSuccessMessageBody}
                processPartiallyRejectedMessage={processPartiallyRejectedMessage}
              />
            }
          </div>
        </ModalBody>
        {/* </Container> */}
      </Modal>
    </>
  );
};

export default ProcessFinishedModal;
