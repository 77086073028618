import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { TopGainerAndLoserData } from '../../../../lib/models/fundamental/types';
import TradePriceCache from '../../../../lib/store-util/TradePriceCache';
import { getPriceColorByDirection } from '../../../../lib/util/ChartingHelpers';
import { formatCurrency } from '../../../../lib/util/DataHelpers';

import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import ChartLine from '../../../Chart/ChartLine/ChartLine';
import ClickableDiv from '../../../ClickableDiv/ClickableDiv';
import CompanyLogo from '../../../CompanyLogo/CompanyLogo';

import styles from './SymbolTradeInfoCard.module.scss';

type Props = {
  data: TopGainerAndLoserData;
}

const SymbolTradeInfoCard = ({ data }: Props) => {
  const history = useHistory();
  const { logoBase64, symbol } = data;
  const { currentPrice, changePercent, direction } = TradePriceCache.use(symbol, 'SymbolTradeInfoCard');
  const color = getPriceColorByDirection(direction);
  const isMobile = useIsMobile();

  const redirect = () => {
    history.push(`${UI_ROUTES.symbol}/${symbol}`);
  };

  return (
    <div className={styles.container}>
      <ClickableDiv
        className={styles.card}
        onClick={redirect}
      >
        {/* Fix the scrolling problem on mobile devices by creating an invisible div in front of the chart */}
        { isMobile && (
          <ClickableDiv
            className={styles.invisibleDiv}
            onClick={redirect}
          />
        )}
        <div className={styles.header}>
          <div><CompanyLogo logo={logoBase64 || ''} symbol={symbol} /></div>
          <Typography noWrap className={styles.companyName}>{symbol}</Typography>
        </div>
        <div className={styles.chart}>
          <ChartLine symbol={symbol} isChartInCard />
        </div>
        <div className={styles.footer}>
          <p>{formatCurrency(currentPrice)}</p>
          <p style={{ color }}>
            {direction}
            {changePercent}
          </p>
        </div>
      </ClickableDiv>
    </div>
  );
};

export default SymbolTradeInfoCard;
