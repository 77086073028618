import React from 'react';

import lang from '../../../../../../../lib/language';
import { EnrollIndividualAddressRequestData } from '../../../../../../../lib/models/enroll-requests';

import styles from './PersonalAddressDetails.module.scss';

type Props = {
  residentialAddress: EnrollIndividualAddressRequestData;
  mailingAddress: EnrollIndividualAddressRequestData | null;
}

let residentialAddressText: string = '';
let mailingAddressText: string = '';

const PersonalAddressDetails = ({ residentialAddress, mailingAddress }: Props) => {
  if (residentialAddress.city) {
    residentialAddressText = `${residentialAddress.city}, ${residentialAddress.zip_code}, ${residentialAddress.address_line}`;
    mailingAddressText = lang.commonSameAsResidentialAddress();
  }

  if (mailingAddress) {
    mailingAddressText = `${mailingAddress.city}, ${mailingAddress.zip_code}, ${mailingAddress.address_line}`;
  }

  const addressDetails = {
    [lang.commonResidentialAddress()]: residentialAddressText,
    [lang.commonMailingAddress()]:
      (mailingAddress?.city === residentialAddress.city
        && mailingAddress?.zip_code === residentialAddress.zip_code
        && mailingAddress?.address_line === residentialAddress.address_line
      )
        ? lang.commonSameAsResidentialAddress()
        : mailingAddressText,
  };

  return (
    <div className={styles.wrapperAddressDetails}>
      <div className={styles.detailsHeader}>
        {lang.commonAddresses()}
      </div>
      <div className={styles.containerDetails}>
        <div className={styles.card}>
          {
            Object.entries(addressDetails).map(([key, value]) => (
              <div className={styles.cardContent} key={key}>
                <span className={styles.cardKey}>{key}</span>
                <span className={styles.cardValue}>{value}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default PersonalAddressDetails;
