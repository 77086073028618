import { produce } from 'immer';
import { entity } from 'simpler-state';

import { SymbolStatus } from '../models/market-data/types';
import { SymbolStatusDataCache } from '../store/market-data/types';

const SymbolStatusCache = entity({} as SymbolStatusDataCache);

const initialSymbolStatus: SymbolStatus = {
  sym: '',
  kdbRecvTime: '',
  updateTime: '',
  trade: '',
  reason: '',
  ssri: '',
  limitHigh: 0,
  limitLow: 0,
};

export const updateSymbolStatusCache = (newItems: SymbolStatusDataCache) => {
  SymbolStatusCache.set(
    produce(state => {
      for (const sym in newItems) {
        state[sym] = newItems[sym];
      }
      if (state?.initial) delete state.initial;
    }),
  );
};

export default {
  use: (symbol: string) => SymbolStatusCache.use()[symbol],
  get: (symbol: string) => SymbolStatusCache.get()[symbol],
  clear: () => SymbolStatusCache.set({ initial: initialSymbolStatus }),
  getAll: SymbolStatusCache.get,
  useAll: SymbolStatusCache.use,
};
