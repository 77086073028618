import React from 'react';

import lang from '../../../lib/language';

import {
  EnrollGeneralQuestions,
  EnrollTransitionScreen,
} from '../../../components';

import PersonalDetailsContent from './components/PersonalDetailsContent';
import { EnrollContentProps } from './EnrollContentProps.types';

const EnrollContent = ({ progressBar, progressBarDispatch }: EnrollContentProps): JSX.Element => {
  switch (progressBar.currentPhaseIndex) {
    case 1:
      return <PersonalDetailsContent progressBar={progressBar} progressBarDispatch={progressBarDispatch} />;
    case 2:
    case 3:
      if (progressBar.currentStepIndex === 0) {
        const title = progressBar.currentPhaseIndex === 2
          ? lang.commonOnboardingTransitionPurposeAndStatus()
          : lang.commonOnboardingSectionTitleKnowledgeAndExperience();

        const subTitle = progressBar.currentPhaseIndex === 2
          ? lang.commonOnboardingTransitionPurposeAndStatusDescription()
          : lang.commonOnboardingTransitionExperienceDescription();

        return (
          <EnrollTransitionScreen
            title={title}
            subTitle={subTitle}
            progressBarDispatch={progressBarDispatch}
          />
        );
      }

      return (
        <EnrollGeneralQuestions
          progressBarData={progressBar}
          progressBarDispatch={progressBarDispatch}
        />
      );
    default:
      return <></>;
  }
};

export default EnrollContent;
