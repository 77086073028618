import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { ReportingCall } from '../../../lib/models/reporting/types';
import { RootState } from '../../../lib/store';
import { getCompanyLogoAndName } from '../../../lib/store/fundamental';
import { getOrderDetail as getDetail } from '../../../lib/store/reporting';
import { NO_MORE_PAGES_TO_READ, ORDERS_REQUEST_PAGE_NUMBER } from '../../../lib/store/reporting/constants';
import { TradeHistoryParsedOrders } from '../../../lib/store/reporting/types';
import { isUserVerifiedStatus } from '../../../lib/store/selectors';
import { isCallStatusReady } from '../../../lib/util/error-handling/StatusByCallHelpers';

import noMessagesLogo from '../../../assets/img/image_empty.png';
import { BaseModal, BaseModalMobile, BaseTable, ClickableDiv, CompanyLogo, Preloader } from '../../../components';
import DetailTradeModule from '../../../components/DetailTradeModule/DetailTradeModule';
import { useIsMobile } from '../../../hooks';
import { TRADE_HISTORY_TABLE_HEADERS } from '../TradeHistoryOptions';

import styles from './TradeHistoryTable.module.scss';

type OwnProps = {
  accountTradeHistory: TradeHistoryParsedOrders[],
  handleRowsPerPage: () => void;
}

type Props = OwnProps & ReduxProps;

const TradeHistoryTable = (props: Props) => {
  const {
    accountTradeHistory,
    companyLogoAndNameData,
    isTradeHistoryReady,
    orderDetail,
    orderDetailCompleted,
    tradeHistoryNextPageToRead,
    isUserVerified,
    getOrderDetail,
    handleRowsPerPage,
    getDataCompanyLogoAndName,
  } = props;
  const isMobile = useIsMobile();
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
  const tableHeaders = useMemo(() => TRADE_HISTORY_TABLE_HEADERS.map((item: string) => <th key={item}>{item}</th>), []);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showLoadingState = (): JSX.Element => (
    <div className={styles.loader}>
      <Preloader owner="Trade History" text="" />
    </div>
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (isMobile && loadMoreButtonRef.current) {
      const buttonRef = loadMoreButtonRef.current;
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && buttonRef) {
            buttonRef.click();
          }
        });
      });

      observer.observe(buttonRef);
      // Clean up the observer when component unmounts
      return () => {
        buttonRef && observer.unobserve(buttonRef);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (orderDetailCompleted && loading) {
      setShowDetails(true);
      setLoading(false);
    }
  }, [loading, orderDetail, orderDetailCompleted]);

  useEffect(() => {
    if (accountTradeHistory) {
      const symbols = accountTradeHistory.map(stock => stock.symbol);
      getDataCompanyLogoAndName(symbols.join());
    }
  }, [accountTradeHistory, getDataCompanyLogoAndName]);

  const showTradeDetails = (id: string) => {
    getOrderDetail(id);
    setLoading(true);
  };

  return (
    <div className={styles.wrapper}>
      {(isUserVerified && (!isTradeHistoryReady || loading)) && showLoadingState()}
      { isMobile ? (
        <div className={styles.resultsWrapper}>
          { accountTradeHistory.map((order, index) => {
            const {
              type, quantity, price, orderValue, orderDate, color, parentOrderId, tenNParsedStatus, symbol,
            } = order;
            const companyLogoAndName = companyLogoAndNameData[symbol];
            const rowKey = `${parentOrderId}-${index}`;

            return companyLogoAndName && (
            <ClickableDiv key={rowKey} className={styles.row} onClick={() => showTradeDetails(parentOrderId)}>
              <div className={styles.logo}>
                <CompanyLogo logo={companyLogoAndName.logo ? companyLogoAndName.logo : ''} symbol={symbol} />
              </div>
              <div className={styles.companyInfo}>
                <div className={styles.sharesPriceAndDate}>
                  <div className={styles.sharesAndPrice}>
                    {quantity}
                    {' '}
                    {symbol}
                    { price !== '-' && (
                      ' x '.concat(price.toString())
                    )}
                  </div>
                  <div className={styles.date}>
                    {orderDate}
                  </div>
                </div>
                <div className={styles.orderTypeAndStatus}>
                  <div className={styles.orderType}>
                    {type}
                  </div>
                  <div className={styles.status} style={{ color }}>
                    {tenNParsedStatus}
                  </div>
                </div>
              </div>
            </ClickableDiv>
            );
          })}
        </div>
      ) : (
        <BaseTable>
          <thead><tr>{tableHeaders}</tr></thead>
          {
            accountTradeHistory.length > 0 && (
              <tbody>
                {
                accountTradeHistory.map(order => {
                  const {
                    type, quantity, price, orderValue, orderDate, color, parentOrderId, tenNParsedStatus, symbol,
                  } = order;
                  const companyLogoAndName = companyLogoAndNameData[symbol];

                  return companyLogoAndName && (
                    <tr
                      key={order.rowNumber}
                      data-parentorderid={parentOrderId}
                      onClick={() => showTradeDetails(parentOrderId)}
                    >
                      <td className={styles.columnSymbolName}>
                        <div className={styles.wrapperContent}>
                          <CompanyLogo logo={companyLogoAndName.logo ? companyLogoAndName.logo : ''} symbol={symbol} />
                          <div className={styles.companyInfo}>
                            <span className={styles.symbol}>{symbol}</span>
                            <span className={styles.companyName}>{companyLogoAndName.name}</span>
                          </div>
                        </div>
                      </td>
                      <td>{type}</td>
                      <td>{quantity}</td>
                      <td>{price}</td>
                      <td>{orderValue}</td>
                      <td>{orderDate}</td>
                      <td style={{ color }}><span>{tenNParsedStatus}</span></td>
                    </tr>
                  );
                })
              }
              </tbody>
            )
          }
        </BaseTable>
      )}
      <button
        type="button"
        ref={loadMoreButtonRef}
        className={
            (!isTradeHistoryReady
              || tradeHistoryNextPageToRead === NO_MORE_PAGES_TO_READ
              || accountTradeHistory.length === 0
              || accountTradeHistory[0].pages === ORDERS_REQUEST_PAGE_NUMBER
            )
              ? styles.hideBtnLoadMore : styles.btnLoadMore
          }
        onClick={handleRowsPerPage}
      >
        {isMobile ? '' : lang.commonLoadMore()}
      </button>
      {
        (!isUserVerified || (isTradeHistoryReady && accountTradeHistory.length === 0)) && (
        <div className={styles.wrapperNoTradeStocks}>
          <img className={styles.noMessagesLogo} src={noMessagesLogo} alt="no messages logo" />
          <p className={styles.noMessagesText}>{lang.commonInboxNoMessagesText()}</p>
        </div>
        )
      }
      { isMobile ? (
        <BaseModalMobile isOpen={showDetails} onClose={() => setShowDetails(false)}>
          <DetailTradeModule closeModule={() => setShowDetails(false)} />
        </BaseModalMobile>
      ) : (
        <BaseModal isOpen={showDetails}>
          <DetailTradeModule closeModule={() => setShowDetails(false)} />
        </BaseModal>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyLogoAndNameData: state.fundamental.companyLogoAndName,
  orderDetail: state.reporting.orderDetail,
  orderDetailCompleted: state.reporting.orderDetailCompleted,
  tradeHistoryNextPageToRead: state.reporting.tradeHistoryNextPageToRead,
  isUserVerified: isUserVerifiedStatus(state.crm.individualExtendedInfo),
  isTradeHistoryReady: isCallStatusReady(ReportingCall.getAccountTradeHistory, state.reporting.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOrderDetail: (parentOrderId: string) => dispatch(getDetail(parentOrderId)),
  getDataCompanyLogoAndName: (symbols: string) => dispatch(getCompanyLogoAndName(symbols)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(TradeHistoryTable);
