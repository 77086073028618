import React from 'react';

import lang from '../../lib/language';

import iconFilledCheck from '../../assets/img/icon-filled-check.png';
import { VerificationStatusEnum } from '../../containers/Verification/Verification.types';

import styles from './VerificationStatus.module.scss';

type Props = {
  id: string;
  status: VerificationStatusEnum;
  numberOfRow: number;
}

const VerificationStatus = (props: Props): JSX.Element => {
  const {
    id,
    status,
    numberOfRow,
  } = props;

  const handlePhoneNumber = () => (
    status === VerificationStatusEnum.Unspecified
      ? lang.commonVerificationVerifyPhoneNumberButton()
      : lang.commonVerificationPhoneVerified());

  const handleIdDocument = () => (
    status === VerificationStatusEnum.Unspecified
      ? lang.commonVerificationUploadIdDocumentButton()
      : lang.commonVerificationIdDocumentUploaded());

  const handleProofOfAddress = () => (
    status === VerificationStatusEnum.Unspecified
      ? lang.commonVerificationPoAButton()
      : lang.commonVerificationPoAUploaded());

  const handleTermsOfServices = () => (
    status === VerificationStatusEnum.Unspecified
      ? lang.commonVerificationTermsButton()
      : lang.commonVerificationTermsButton());

  return (
    <div id={id} className={styles.wrapperRow}>
      <div className={styles.row}>
        {
          status === VerificationStatusEnum.Unspecified
          && <div className={styles.circleGrey}>{numberOfRow}</div>
        }
        {
          status === VerificationStatusEnum.Accepted
          && <img className={styles.iconFilledCheck} src={iconFilledCheck} alt="icon filled check" />
        }
        {
          status === VerificationStatusEnum.Pending
          && <div className={styles.circleOrange}>{numberOfRow}</div>
        }
        {numberOfRow === 1 && handlePhoneNumber()}
        {numberOfRow === 2 && handleIdDocument()}
        {numberOfRow === 3 && handleProofOfAddress()}
        {numberOfRow === 4 && handleTermsOfServices()}
      </div>
      <p className={status === VerificationStatusEnum.Accepted ? styles.rowStatusAccepted : styles.rowStatusPending}>
        {status === VerificationStatusEnum.Accepted && lang.commonVerificationStepStatusAccepted()}
        {status === VerificationStatusEnum.Pending && lang.commonVerificationStepStatusPending()}
      </p>
    </div>
  );
};

export default VerificationStatus;
