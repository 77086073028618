import React, { useEffect, useRef, useState } from 'react';

import iconExpandDown from '../../../assets/img/icon-expand-more-down.svg';
import iconExpandUp from '../../../assets/img/icon-expand-more-up.svg';
import { ClickableImage } from '../../../components';

import ContentDropDown from './ContentDropDown';

import styles from './Selector.module.scss';

const Selector = () => {
  const [isSelectTabOpened, setIsSelectTabOpened] = useState(false);
  const [reportType, setReportType] = useState<string>('File Format');

  const dropDownDivRef = useRef<HTMLInputElement | null>(null);

  const handleOpenMenu = () => {
    document.body.classList.add('modal-open');
    setIsSelectTabOpened(true);
  };

  const handleCloseMenu = () => {
    document.body.classList.remove('modal-open');
    setIsSelectTabOpened(false);
  };

  useEffect(() => {
    const handleClickInside = (event) => {
      if (event.target.nodeName === 'IMG' && isSelectTabOpened) {
        handleCloseMenu();
      } else {
        dropDownDivRef.current && dropDownDivRef.current.contains(event.target)
          ? handleOpenMenu()
          : handleCloseMenu();
      }
    };
    document.addEventListener('click', handleClickInside);
    return () => document.removeEventListener('click', handleClickInside);
  }, [dropDownDivRef, isSelectTabOpened]);

  const handleSelectedOption = (option: string) => {
    setReportType(option);
    handleCloseMenu();
  };

  return (
    <div ref={dropDownDivRef}>
      <div className={styles.container}>
        <span>
          {reportType}
        </span>
        <ClickableImage
          alt=""
          src={isSelectTabOpened ? iconExpandUp : iconExpandDown}
          className={styles.iconExpand}
        />
      </div>
      { isSelectTabOpened && (
      <ContentDropDown
        onChange={handleSelectedOption}
        closeModal={() => setIsSelectTabOpened(false)}
        options={['CSV', 'PDF']}
      />
      )}
    </div>
  );
};

export default Selector;
