import configLib from '../../../configLib';
import { CallStatus, PaymentMethodEnum, PaymentStatusEnum } from '../../enums';
import {
  AccountPrefixByTradingPlatformEnum,
  OwnerTradingAccount,
  Payment,
  TradingAccountStatusesEnum,
} from '../../models/crm/types';
import { LinkedAccountRequestData } from '../../models/linked-account/types';
import { isOneOf } from '../../util/DataHelpers';
import { setCallStatus } from '../../util/error-handling/StatusByCallHelpers';
import { validate } from '../../util/ValidationSchemes/Validate';
import { ValidationType } from '../../util/ValidationSchemes/ValidationTypes';
import { AccountReference } from '../reporting/types';
import { isCardDeposit, isDeposit, isPendingCardTransfer, isWithdrawal } from '../selectors';

import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from './constants';
import { PaymentState } from './index';
import { PaymentCall, RegisterLinkedAccountPayload, TransactionsState } from './types';


export function setPaymentCallStatus(call: PaymentCall, status: CallStatus, state: PaymentState, errorCode?: number) {
  setCallStatus(call, PaymentCall[call], status, state.statusByCall, errorCode, state.errorsLog);
}

const hasCRMStatus = (transaction: Payment, statuses: PaymentStatusEnum[]) => (
  isOneOf(transaction.payment_status, statuses)
);

export const shouldDisplayTransaction = (transaction: Payment) => (
  hasCRMStatus(transaction, [
    PaymentStatusEnum.Completed,
    PaymentStatusEnum.ForApproval,
    PaymentStatusEnum.Rejected,
    PaymentStatusEnum.Canceled,
  ])
  || isCardDeposit(transaction)
);

export function parsePayments(
  receivedTransactions: Payment[] | null | undefined,
  allTransactions: TransactionsState,
  firstTransactions?: TransactionsState,
  deposits?: Payment[],
  withdrawals?: Payment[],
) {
  const { filteredOut, ignored } = allTransactions;

  receivedTransactions?.forEach((payment: Payment, index: number) => {
    if (isPendingCardTransfer(payment)) {
      ignored.push(payment);
      return; // Web case - back button from DSK page
    }

    if (shouldDisplayTransaction(payment)) {
      allTransactions.data.push(payment);
      if (firstTransactions && firstTransactions.data.length < DEFAULT_TRANSACTIONS_PAGE_SIZE) {
        firstTransactions.data.push(payment);
      }
    } else {
      filteredOut.push(payment);
    }

    if (deposits && isDeposit(payment)) deposits.push(payment);
    if (withdrawals && isWithdrawal(payment)) withdrawals.push(payment);
  });
}

export function setCallForRefresh(state: PaymentState, call: PaymentCall) {
  if (!state.callsForRefresh) state.callsForRefresh = {} as any;
  state.callsForRefresh![call] = true;
}

// TODO: Remove when BE implementation provided
export function setCalculatedCash(state: PaymentState, rawData: AccountReference, pendingWithdrawalsTotal: number) {
  if (rawData?.availableCash == null) console.error(`[payment/helpers] setCalculatedCash - available cash data not valid - ${rawData?.availableCash}`);
  if (pendingWithdrawalsTotal == null) console.error(`[payment/helpers] setCalculatedCash - pendingWithdrawalsTotal data not valid - ${pendingWithdrawalsTotal}`);


  const total = (rawData?.availableCash ?? 0) - (pendingWithdrawalsTotal ?? 0);
  state.calculatedCash = total > 0 ? total : 0;
  state.positiveInstaCash = rawData?.instaCash > 0 ? rawData?.instaCash : 0;
}

// eslint-disable-next-line max-len
export const getBankStatementRequestBody = (individualId: number | null, bankAccountDetails: RegisterLinkedAccountPayload): LinkedAccountRequestData => {
  const {
    bankName, bankCountryId, file, swiftCode, currencyCode, iban,
  } = bankAccountDetails;
  const ibanToUpperCase = iban.toUpperCase();

  let ibanOrAccount = {};
  if (validate(iban, ValidationType.IBAN)) {
    ibanOrAccount = { iban: ibanToUpperCase };
  } else {
    ibanOrAccount = { account: ibanToUpperCase };
  }

  return {
    individualId,
    companyId: '',
    file: file ?? '',
    paymentMethod: PaymentMethodEnum.BankTransfer,
    currencyCode,
    fileStorageId: '',
    bankName,
    bankCountryId,
    bic: swiftCode.toUpperCase(),
    ...ibanOrAccount,
  };
};

// eslint-disable-next-line max-len
export const isActiveAccount = (account: OwnerTradingAccount): boolean => account.status === TradingAccountStatusesEnum.Active;

// eslint-disable-next-line max-len
export const isActiveOrWithdrawalRestrictedAccount = (account: OwnerTradingAccount): boolean => isActiveAccount(account) || account.status === TradingAccountStatusesEnum.WithdrawalRestricted;

// eslint-disable-next-line max-len
export const isAccountForPlatform = (account: OwnerTradingAccount): boolean => account.reference_id.startsWith(configLib.isHW ? AccountPrefixByTradingPlatformEnum.HammerWeb : AccountPrefixByTradingPlatformEnum.TenN);
