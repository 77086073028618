import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddressTypeEnum } from '../../../lib/enums';
import lang from '../../../lib/language';
import {
  CountryData,
  ENROLL_METHOD,
  ENROLL_REQUEST_STATE,
  IndividualAddressTypeEnum,
  IndividualExtendedInfo,
  IndividualExtendedInfoData,
} from '../../../lib/models/enroll';
import { EnrollIndividualAddressRequestData } from '../../../lib/models/enroll-requests';
import { RootState } from '../../../lib/store';
import { enrollAddressesBatch } from '../../../lib/store/crm';
import { EnrollDetailsEnum, hasEnrollDetails } from '../../../lib/store/selectors';
import CountriesCache from '../../../lib/store-util/CountriesCache';
import { isObjectModified } from '../../../lib/util/DataHelpers';
import {
  getAddressValidity, getCityValidity, getPostCodeValidity, validateControl,
} from '../../../lib/util/ValidationSchemes/Validate';

import {
  EnrollProgressBarBackQuestionAction, EnrollProgressBarNextQuestionAction,
} from '../../../reducers/EnrollProgressBar/EnrollProgressBarReducer.constants';
import { ICountryControlOptions } from '../../CountryControl/CountryControl.types';
import { CheckboxControl } from '../../index';
import EnrollLayout from '../EnrollLayout/EnrollLayout';
import { EnrollProgressBarData } from '../ProgressBar/ProggressBar.types';

import EnrollAddressesInfo from './EnrollAddressesInfo/EnrollAddressesInfo';
import { EnrollAddressesFormDataInit } from './EnrollAddresses.constants';
import { EnrollAddressesForm } from './EnrollAddresses.types';

import styles from './EnrollAddresses.module.scss';

type Props = {
  progressData: EnrollProgressBarData;
  progressBarDispatch: Dispatch<{ type: string }>;
  setIsBack: Dispatch<React.SetStateAction<boolean>>;
};

const EnrollAddresses = (props: Props) => {
  const { progressBarDispatch, progressData, setIsBack } = props;

  const dispatch = useDispatch();

  const countries = CountriesCache.use();
  const requestState = useSelector<RootState, ENROLL_REQUEST_STATE>((state: RootState) => state.crm.enroll.status);

  const individual = useSelector<RootState, IndividualExtendedInfoData | null>((state: RootState) => (
    state.crm.individualExtendedInfo
  ));

  const hasDetails = useSelector((state: RootState) => (
    (type: EnrollDetailsEnum, customIndex?: number) => hasEnrollDetails(type, state, null, customIndex)
  ));

  const [isInit, setIsInit] = useState<boolean>(false);
  const [isSameAddress, setIsSameAddress] = useState<boolean>(true);
  const [isClickedBtnContinue, setIsClickedBtnContinue] = useState<boolean>(false);

  const [formData, setFormData] = useState<EnrollAddressesForm>(EnrollAddressesFormDataInit);

  const countryIds = useMemo((): string[] => {
    if (countries) {
      return countries.map((country: CountryData) => country.id);
    }

    return [];
  }, [countries]);

  const goBack = useCallback((): void => {
    setIsBack(true);
    progressBarDispatch({ type: EnrollProgressBarBackQuestionAction });
  }, [progressBarDispatch, setIsBack]);

  const goNext = useCallback((): void => {
    const isEdit = hasDetails(EnrollDetailsEnum.ADDRESS_DETAILS);
    const updatedAddresses: EnrollIndividualAddressRequestData[] = [];
    let hasChanges = false;

    // eslint-disable-next-line prefer-const
    let { residentialAddress, mailingAddress } = formData;

    if (isSameAddress) {
      mailingAddress = {
        ...mailingAddress,
        country_id: residentialAddress.country_id,
        city: residentialAddress.city,
        zip_code: residentialAddress.zip_code,
        address_line: residentialAddress.address_line,
      };
    }

    if (individual) {
      const model = new IndividualExtendedInfo(individual);

      const individualResidentialAddress = model.getAddressByType(AddressTypeEnum.Residential);
      const individualMailingAddress = model.getAddressByType(AddressTypeEnum.Mailing);

      const isResidentialAddressModified = isObjectModified(residentialAddress, individualResidentialAddress);
      const isMailingAddressModified = isObjectModified(mailingAddress, individualMailingAddress);

      hasChanges = (isEdit && (isResidentialAddressModified || isMailingAddressModified));

      if (isResidentialAddressModified && countryIds.includes(residentialAddress.country_id)) {
        updatedAddresses.push(residentialAddress);
      }

      if (isMailingAddressModified && countryIds.includes(mailingAddress.country_id)) {
        updatedAddresses.push(mailingAddress);
      }
    } else if (
      !isEdit
      && countryIds.includes(residentialAddress.country_id)
      && countryIds.includes(mailingAddress.country_id)
    ) {
      updatedAddresses.push(residentialAddress, mailingAddress);
    }

    if (hasChanges || !isEdit) {
      const callName = isEdit ? ENROLL_METHOD.putIndividualAddressOwn : ENROLL_METHOD.postIndividualAddressOwn;

      dispatch(enrollAddressesBatch({
        callName,
        callMethodName: ENROLL_METHOD[callName],
        addresses: updatedAddresses,
      }));

      if (requestState === ENROLL_REQUEST_STATE.READY) {
        progressBarDispatch({ type: EnrollProgressBarNextQuestionAction });
      }
    } else {
      progressBarDispatch({ type: EnrollProgressBarNextQuestionAction });
    }
  }, [
    formData,
    dispatch,
    individual,
    countryIds,
    hasDetails,
    requestState,
    isSameAddress,
    progressBarDispatch,
  ]);

  const options = useMemo((): ICountryControlOptions[] => {
    if (countries && (countries.length > 0)) {
      return countries
        .filter((country: CountryData) => country.id && country.name)
        .sort((current: CountryData, next: CountryData) => {
          if (current.name.toLowerCase() < next.name.toLowerCase()) {
            return -1;
          } if (current.name.toLowerCase() > next.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((country: CountryData) => ({
          label: country.name,
          value: country.id,
        }));
    }

    return [];
  }, [countries]);

  useEffect(() => {
    if (!isInit) {
      if (individual) {
        const model = new IndividualExtendedInfo(individual);

        if (hasDetails(EnrollDetailsEnum.ADDRESS_DETAILS)) {
          const individualResidentialAddress = model.getAddressByType(AddressTypeEnum.Residential);
          const individualMailingAddress = model.getAddressByType(AddressTypeEnum.Mailing);

          const isAddressSameCheck = individual?.addresses?.length <= 1 || (
            (individualResidentialAddress.country_id === individualMailingAddress.country_id)
            && (individualResidentialAddress.address_line === individualMailingAddress.address_line)
            && (individualResidentialAddress.zip_code === individualMailingAddress.zip_code)
            && (individualResidentialAddress.city === individualMailingAddress.city)
          );

          setIsSameAddress(isAddressSameCheck);
          if (isAddressSameCheck) {
            let mailingAddressDetails = individualMailingAddress;

            if (!mailingAddressDetails.address_type) {
              mailingAddressDetails.address_type = IndividualAddressTypeEnum.mailing;
              mailingAddressDetails.country_id = individualResidentialAddress.country_id;
            } else {
              mailingAddressDetails = { ...mailingAddressDetails, city: '', zip_code: '', address_line: '' };
            }

            setFormData({
              mailingAddress: mailingAddressDetails,
              residentialAddress: individualResidentialAddress,
            });
          } else {
            setFormData({
              residentialAddress: individualResidentialAddress,
              mailingAddress: individualMailingAddress,
            });
          }
        } else {
          const individualResidentialAddress = model.getAddressByType(AddressTypeEnum.Residential);
          setFormData((currentState: EnrollAddressesForm) => ({
            ...currentState,
            residentialAddress: {
              ...currentState.residentialAddress,
              country_id: individualResidentialAddress?.country_id ?? individual.birth_place_id,
            },
            mailingAddress: {
              ...currentState.mailingAddress,
            },
          }));
        }
      }

      setIsInit(true);
    }
  }, [dispatch, hasDetails, individual, isInit]);

  const validateAddressData = (isMailingAddress: boolean = false): boolean => {
    const countryCheck = validateControl(
      isMailingAddress ? formData.mailingAddress.country_id : formData.residentialAddress.country_id,
      [],
      lang.commonOnboardingPersonalDetailsCountryOfBirth(),
      true,
    );

    const cityCheck = getCityValidity(
      isMailingAddress ? formData.mailingAddress.city : formData.residentialAddress.city,
    );

    const postCodeCheck = getPostCodeValidity(
      isMailingAddress ? formData.mailingAddress.zip_code : formData.residentialAddress.zip_code,
    );

    const addressLineCheck = getAddressValidity(
      isMailingAddress ? formData.mailingAddress.address_line?.replace(/\s+$/, '')
        : formData.residentialAddress.address_line?.replace(/\s+$/, ''),
    );

    if (isMailingAddress) {
      if (!isSameAddress) {
        return (
          countryCheck.isValid
          && cityCheck.isValid
          && postCodeCheck.isValid
          && addressLineCheck.isValid
        );
      }

      return true;
    }

    return (
      countryCheck.isValid
      && cityCheck.isValid
      && postCodeCheck.isValid
      && addressLineCheck.isValid
    );
  };

  const validateForm = (): boolean => {
    const residentialAddressCheck = validateAddressData();
    const mailingAddressCheck = validateAddressData(true);

    return (residentialAddressCheck && mailingAddressCheck);
  };

  return (
    <EnrollLayout
      visibleContinueButton
      onBackButtonClicked={goBack}
      title={`${progressData.currentPhaseIndex}.${progressData.currentStepIndex} ${lang.commonOnboardingPersonalDetailsResidentialAddress()}`}
      subTitle={lang.commonOnboardingPersonalDetailsResidentialAddressSubtitle()}
      onContinueClicked={() => {
        setIsClickedBtnContinue(true);

        if (validateForm()) {
          goNext();
        }
      }}
    >
      <div className={styles.contentWrapper}>
        <EnrollAddressesInfo
          options={options}
          setAddressData={setFormData}
          addressData={formData.residentialAddress}
          isClickedBtnContinue={isClickedBtnContinue}
        />
        <div className={styles.checkboxRow}>
          <CheckboxControl
            id="sameAddressCheck"
            checked={isSameAddress}
            ariaLabel="is same address"
            onChange={(checked: boolean) => setIsSameAddress(checked)}
            label={lang.commonOnboardingPersonalDetailsSameAsMailingAddress()}
          />
        </div>
        {!isSameAddress && (
        <EnrollAddressesInfo
          isMailingAddress
          options={options}
          setAddressData={setFormData}
          addressData={formData.mailingAddress}
          isClickedBtnContinue={isClickedBtnContinue}
        />
        )}
      </div>
    </EnrollLayout>
  );
};

export default EnrollAddresses;
