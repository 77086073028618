
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import GlobalService from '../../services/Global.service';
import { processResponse } from '../helpers';
import { RootState } from '../index';

import {
  getSubsriptionsEntitlements,
  getSubsriptionsEntitlementsFail,
  getSubsriptionsEntitlementsSuccess,
  SESState,
} from './index';

const getSubsriptionsEntitlementsEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => action$.pipe(
  ofType(getSubsriptionsEntitlements.type),
  mergeMap((action: any) => new Observable((observer: Observer<any>) => {
    const callName = 'ses/getSubsriptionsEntitlements';

    GlobalService.sesService.getSubscriptionsEntitlements()
      .then(response => {
        processResponse(
          callName,
          {},
          response,
          observer,
          getSubsriptionsEntitlementsSuccess,
          getSubsriptionsEntitlementsFail,
        );
      });
  })),
);

export default combineEpics(getSubsriptionsEntitlementsEpic);
