import React, { useEffect, useState } from 'react';

import RadioGroupOption from './RadioGroupOption/RadioGroupOption';
import { IRadioGroupOption, IRadioGroupProps } from './RadioGroup.types';

import styles from './RadioGroup.module.scss';

const RadioGroup = (props: IRadioGroupProps) => {
  const {
    onChange,
    placeholder,
    value = '',
    options = [],
  } = props;

  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <>
      {!placeholder ? '' : <h5>{placeholder}</h5>}

      <div className={styles.wrapper}>
        {options.map(({ value: optionValue, label, ariaLabel, id }: IRadioGroupOption) => (
          <RadioGroupOption
            id={id}
            key={optionValue}
            ariaLabel={ariaLabel}
            value={optionValue}
            label={label}
            active={selectedOption === optionValue}
            onSelect={() => {
              setSelectedOption(optionValue);
              onChange && onChange(optionValue);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default RadioGroup;
