import { NullableNumber, PriceMovement, WatchlistPriceMovement, WatchlistPriceValue } from './types';

const price: WatchlistPriceValue = {};
const priceMovement: WatchlistPriceMovement = {};
export const roundToCurrencyFormat = (currentPrice: number) => Math.round(currentPrice * 100) / 100;

export const calculatePriceMovement = (currentPrice: NullableNumber, symbol: string): PriceMovement => {
  if (currentPrice && symbol) {
    const roundedCurrentPrice = roundToCurrencyFormat(currentPrice);

    if (Object.prototype.hasOwnProperty.call(price, symbol)) {
      switch (true) {
        case price[symbol] < roundedCurrentPrice:
          priceMovement[symbol] = 'up';
          break;
        case price[symbol] > roundedCurrentPrice:
          priceMovement[symbol] = 'down';
          break;
        default: priceMovement[symbol] = 'equal';
      }
    }

    price[symbol] = roundedCurrentPrice;
  }

  return priceMovement[symbol];
};
