import React, { useState } from 'react';

import { OrderStatusEnum } from '../../lib/enums/order-status.enum';
import { OrderTypeEnum } from '../../lib/enums/order-type.enum';
import { useTimeout } from '../../lib/util/hooks/TimeoutHooks';

const useNewOrderTimer = (
  showFinishModal: boolean, orderType?: OrderTypeEnum, currentOrderStatus?: string, requestStatus?: string,
) => {
  const [showPlacedimit, setShowPlacedLimit] = useState<boolean>(false);
  const [showFillOrder, setShowFillOrder] = useState<boolean>(false);
  const [showPartialFill, setShowPartialFill] = useState<boolean>(false);
  const [showRejected, setShowRejected] = useState<boolean>(false);
  const [showPartiallyRejected, setShowPartiallyRejected] = useState<boolean>(false);
  const [hasNoResponse, setHasNoResponse] = useState<boolean>(false);
  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [showPendingModify, setShowPendingModify] = useState<boolean>(false);

  const limitOrder = orderType === OrderTypeEnum.Limit;
  const stopOrder = orderType === OrderTypeEnum.Stop;

  useTimeout(() => {
    if (showFinishModal) {
      setShowFillOrder(currentOrderStatus === OrderStatusEnum.Filled);
      setShowPlacedLimit((limitOrder || stopOrder) && (
        currentOrderStatus === OrderStatusEnum.Accepted || currentOrderStatus === OrderStatusEnum.Modified));
      setShowPartialFill(currentOrderStatus === OrderStatusEnum.PartiallyFilled);
      setShowPartiallyRejected(currentOrderStatus === OrderStatusEnum.PartiallyReject);
      setShowRejected(currentOrderStatus === OrderStatusEnum.Rejected);
      setShowExpired(currentOrderStatus === OrderStatusEnum.Expired);
      setShowPendingModify(currentOrderStatus === OrderStatusEnum.PendingCancelOrModify);
    } else {
      setShowFillOrder(false);
      setShowPlacedLimit(false);
      setShowPartialFill(false);
      setShowExpired(false);
      setShowRejected(false);
      setShowPartiallyRejected(false);
      setShowPendingModify(false);
    }
    setHasNoResponse(requestStatus === OrderStatusEnum.InProgress);
  }, 1000);

  return {
    orderTimeoutPassed:
      showFillOrder
      || showExpired
      || showPartialFill
      || showPartiallyRejected
      || showPendingModify
      || showPlacedimit
      || showRejected,
    showFillOrder,
    showExpired,
    hasNoResponse,
    showPendingModify,
    showPartiallyRejected,
  };
};

export default useNewOrderTimer;
