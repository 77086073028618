import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import lang from '../../../lib/language';
import { DividendPayerData, PageableFilterRequest } from '../../../lib/models/fundamental/types';
import { getDividendPayers as getDividendPayersCompanies } from '../../../lib/store/fundamental/index';
import { FundamentalCall } from '../../../lib/store/fundamental/types';
import { RootState } from '../../../lib/store/index';
import { formatCurrency } from '../../../lib/util/DataHelpers';
import { isCallStatusPending } from '../../../lib/util/error-handling/StatusByCallHelpers';

import { useIsMobile } from '../../../hooks';
import BaseTable from '../../BaseTable/BaseTable';
import Button from '../../Button/Button';
import { ButtonTypesEnum } from '../../Button/Button.types';
import Preloader from '../../Preloader/Preloader';
import {
  DividendPayersTableHeaders,
  INITIAL_COMPANIES_PER_PAGE,
} from '../DividendPyersAndHighGrowthCompanies.constants';

import TableItem from './TableItem';

import styles from './TableItem.module.scss';

type Props = ReduxProps & {
  content: DividendPayerData[]
}

const DividendPayersTable = (props: Props) => {
  const {
    content,
    companyLogoAndName,
    dividendPayers,
    isDividendPayersStatusPending,
    getDividendPayers,
  } = props;
  const isMobile = useIsMobile();
  const tableHeaders = useMemo(() => (
    DividendPayersTableHeaders.map((item: string) => <th key={item}>{item}</th>)
  ), []);

  const [tableContent, setTableContent] = useState<DividendPayerData[]>(content);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [counter, setCounter] = useState<number>(dividendPayers.content.length ? 1 : 0);

  useEffect(() => {
    if (dividendPayers.content.length > counter * INITIAL_COMPANIES_PER_PAGE) {
      dividendPayers.content.length <= tableContent.length
        ? setTableContent((prevState) => (
          [...prevState, ...dividendPayers.content.slice(
            counter * INITIAL_COMPANIES_PER_PAGE, page * INITIAL_COMPANIES_PER_PAGE,
          )]
        ))
        : setTableContent(dividendPayers.content);
      setPage(prevState => prevState + 1);
      setCounter(prevState => prevState + 1);
      setLoading(false);
    }
  }, [counter, dividendPayers, page, tableContent.length]);

  const tableItems = tableContent.map((item: DividendPayerData, index) => (
    <TableItem
        // there is no anything unique and the array will not be filtered or sorted, the additional element are pushed to the end
      key={index as number}
      logo={item.logoBase64 ?? companyLogoAndName[item.symbol].logo}
      companyName={item.companyName ?? companyLogoAndName[item.symbol].name}
      symbol={item.symbol}
      yield_indicator={item.dividendYield}
      rate_revenue={item.dividendRate}
      marketCap_eps={formatCurrency(item.marketCap, false, true).toUpperCase()}
      volume_marketCap={item.volume}
    />
  ));

  const handleButtonLoadMore = () => {
    getDividendPayers({ page });
    setLoading(true);
  };

  if (!dividendPayers.content.length && isDividendPayersStatusPending) {
    return <div className={styles.loader}><Preloader owner="DividendPayersTable" /></div>;
  }

  return (
    <>
      <BaseTable>
        {!isMobile && (
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
        )}
        <tbody>{tableItems}</tbody>
      </BaseTable>
      {(loading && dividendPayers.totalPages > 0) && <Preloader style={{ transform: 'scale(0.5)' }} owner="DividendPayersTable" />}
      {
        dividendPayers.totalPages > 0
        && (
        <Button
          className={styles.btnLoadMore}
          variant={ButtonTypesEnum.Link}
          onClick={handleButtonLoadMore}
        >
          {lang.commonLoadMore()}
        </Button>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dividendPayers: state.fundamental.dividendPayers,
  companyLogoAndName: state.fundamental.companyLogoAndName,
  isDividendPayersStatusPending:
  isCallStatusPending(FundamentalCall.getDividendPayers, state.fundamental.statusByCall),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getDividendPayers: (payload: PageableFilterRequest) => dispatch(getDividendPayersCompanies(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DividendPayersTable);
