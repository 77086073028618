import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './lib/util/tools';
import store, { persistor } from './lib/store';

import App from './App';
import { GOOGLE_TAG_MANAGER_ID } from './constants';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

if (GOOGLE_TAG_MANAGER_ID) { // For the local env, it will be null by default. And this block will not be triggered
  TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
}

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
