import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useLocation } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { calendarPickerRangeFormat, displayDateFormatNoDay, MARKET_DATA_DATE } from '../../lib/constants/date-time.constants';
import lang from '../../lib/language';
import { datesDifference, getDateTime, isBeforeDate } from '../../lib/util/DateTimeHelpers';

import ClearIcon from '../../assets/img/icon-clear_24.svg';
import ExpandIcon from '../../assets/img/icon-expand-more-down.svg';
import { useBrowserWidth, useIsMobile } from '../../hooks';
import { ZOOM_BEHAVIOR_WIDTH } from '../../util/constants';
import { UI_ROUTES } from '../../util/routes';
import { DateRangeState } from '../../util/types';
import { ClickableImage } from '../index';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../MobileFromToDatePicker/calendarOverride.scss';
import styles from './FullsizeDateRangePickerWrapper.module.scss';
import mobileStyles from './FullsizeDateRangePickerWrapperMobile.module.scss';

type Props = {
  minDateState?: string;
  maxDateState?: string;
  minDateCalendar?: Date | string;
  maxDateCalendar?: Date | string;
  initialState: DateRangeState;
  onDateSelect?: (date: DateRangeState) => void;
}

const FullsizeDateRangePickerWrapper = ({
  minDateState,
  maxDateState,
  minDateCalendar,
  maxDateCalendar,
  initialState,
  onDateSelect,
}: Props) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const { browserWidthSize } = useBrowserWidth();

  const [dateRangeState, setDateRangeState] = useState<DateRangeState>(
    {
      startDate: minDateState ? new Date(minDateState) : initialState?.startDate,
      endDate: maxDateState ? new Date(maxDateState) : initialState?.endDate,
      key: 'selection',
    },
  );
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
  const [inputValueStartDate, setInputValueStartDate] = useState<string | undefined>(
    minDateState ?? moment(initialState?.startDate).format(MARKET_DATA_DATE),
  );
  const [inputValueEndDate, setInputValueEndDate] = useState<string | undefined>(
    maxDateState ?? moment(initialState?.endDate).format(MARKET_DATA_DATE),
  );
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [isDatesSelected, setIsDatesSelected] = useState<boolean>(false);
  const [paperPopover, setPaperPopover] = useState<number>(90);

  const selectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValueFrom = '';
    let inputValueTo = '';
    if (startDate) inputValueFrom += moment(startDate).format(MARKET_DATA_DATE);
    if (endDate) inputValueTo += moment(endDate).format(MARKET_DATA_DATE);

    setErrorMessage('');
    setShowClearButton(true);
    setInputValueStartDate(inputValueFrom);
    setInputValueEndDate(inputValueTo);
    setDateRangeState({ startDate, endDate, key: selection.key });
  };

  const selectDateRangesMobile = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValueFrom = '';
    let inputValueTo = '';
    if (startDate) inputValueFrom += moment(startDate).format(MARKET_DATA_DATE);
    if (endDate) inputValueTo += moment(endDate).format(MARKET_DATA_DATE);

    if (inputValueFrom !== inputValueTo) {
      setDisplayCalendar(false);
      setIsDatesSelected(true);
      setAnchorEl(null);
      onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueEndDate });
    }

    setErrorMessage('');
    setInputValueStartDate(inputValueFrom);
    setInputValueEndDate(inputValueTo);
    setDateRangeState({ startDate, endDate, key: selection.key });
  };

  const inputChange = e => {
    let date;
    const { value, name } = e.target;
    date = moment(value, MARKET_DATA_DATE);
    date = date.isValid() ? date.toDate() : undefined;

    if (name === 'dateRangeInputFrom') {
      setInputValueStartDate(value);
      setDateRangeState((prevState) => ({ ...prevState, startDate: date }));
    } else {
      setInputValueEndDate(value);
      setDateRangeState((prevState) => ({ ...prevState, endDate: date }));
    }
    setErrorMessage('');
    setShowClearButton(true);
  };

  const popoverClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const openDateRangeCalendar = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const monthChange = (value) => {
    const element = document.getElementById('dateRangeWrapper');
    let diffMaxDate = 1;
    let diffMinDate = 1;
    const localizedValue = getDateTime(false, ['local'], [ [], [] ], value);
    if (maxDateCalendar) {
      diffMaxDate = datesDifference(localizedValue, getDateTime(false, ['local'], [ [], [] ], maxDateCalendar), 'months');
    }
    if (minDateCalendar) {
      diffMinDate = datesDifference(localizedValue, getDateTime(false, ['local'], [ [], [] ], minDateCalendar), 'months');
    }

    diffMaxDate < 1 ? element!.classList.add('disabledRigthArrow') : element!.classList.remove('disabledRigthArrow');
    diffMinDate < 1 ? element!.classList.add('disabledLeftArrow') : element!.classList.remove('disabledLeftArrow');
  };

  const checkDates = () => {
    const element = document.getElementById('dateRangeWrapper');
    const { startDate, endDate } = dateRangeState;
    let diffMaxDate = 1;
    let diffMinDate = 1;

    if (startDate && minDateCalendar) {
      diffMinDate = datesDifference(
        getDateTime(false, ['local'], [ [], [] ], startDate),
        getDateTime(false, ['local'], [ [], [] ], minDateCalendar),
        'months',
      );
    }
    if (endDate && maxDateCalendar) {
      diffMaxDate = datesDifference(
        getDateTime(false, ['local'], [ [], [] ], endDate),
        getDateTime(false, ['local'], [ [], [] ], maxDateCalendar),
        'months',
      );
    }

    diffMaxDate < 1 ? element!.classList.add('disabledRigthArrow') : element!.classList.remove('disabledRigthArrow');
    diffMinDate < 1 ? element!.classList.add('disabledLeftArrow') : element!.classList.remove('disabledLeftArrow');
  };

  const clearDates = () => {
    setErrorMessage('');
    setInputValueEndDate(moment(initialState?.endDate).format(MARKET_DATA_DATE));
    setInputValueStartDate(moment(initialState?.startDate).format(MARKET_DATA_DATE));
    setShowClearButton(false);
    setDateRangeState((prevState) => ({
      ...prevState,
      startDate: initialState?.startDate,
      endDate: initialState?.endDate,
    }));
    onDateSelect && onDateSelect({
      startDate: moment(initialState?.startDate).format(MARKET_DATA_DATE),
      endDate: moment(initialState?.endDate).format(MARKET_DATA_DATE),
    });
  };

  const chosenRange = () => {
    onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueEndDate });
  };

  const validateInput = e => {
    const { value, name } = e.target;
    const changedInputFromValue = name === 'dateRangeInputFrom';
    const isMaxDateValid = maxDateCalendar && isBeforeDate(value, maxDateCalendar);
    const isMinDateValid = minDateCalendar && (
      isBeforeDate(getDateTime(true, [ 'minus' ], [ [ 1, 'day' ] ], minDateCalendar), value)
    );

    if ((!!maxDateCalendar && !isMaxDateValid) || (minDateCalendar && !isMinDateValid)) {
      if (!!maxDateCalendar && !isMaxDateValid && !!value) {
        setErrorMessage(lang.dateRangeMaxDateErrorMessage(moment(maxDateCalendar).format(calendarPickerRangeFormat)));
        changedInputFromValue ? setInputValueStartDate(moment(initialState?.startDate).format(MARKET_DATA_DATE))
          : setInputValueEndDate(moment(maxDateCalendar).format(MARKET_DATA_DATE));
        changedInputFromValue ? setDateRangeState((prevState) => ({ ...prevState, startDate: initialState?.startDate }))
          : setDateRangeState((prevState) => ({ ...prevState, endDate: maxDateCalendar }));
        onDateSelect && onDateSelect({
          startDate: changedInputFromValue
            ? moment(initialState?.startDate).format(MARKET_DATA_DATE) : inputValueStartDate,
          endDate: changedInputFromValue ? inputValueEndDate : moment(maxDateCalendar).format(MARKET_DATA_DATE) });
      }
      if (!!minDateCalendar && !isMinDateValid && !!value) {
        setErrorMessage(lang.dateRangeMinDateErrorMessage(moment(minDateCalendar).format(calendarPickerRangeFormat)));
        changedInputFromValue ? setInputValueStartDate(moment(minDateCalendar).format(MARKET_DATA_DATE))
          : setInputValueEndDate(moment(initialState?.endDate).format(MARKET_DATA_DATE));
        changedInputFromValue ? setDateRangeState((prevState) => ({ ...prevState, startDate: minDateCalendar }))
          : setDateRangeState((prevState) => ({ ...prevState, endDate: initialState?.endDate }));
        onDateSelect && onDateSelect({
          startDate: changedInputFromValue ? moment(minDateCalendar).format(MARKET_DATA_DATE) : inputValueStartDate,
          endDate: changedInputFromValue
            ? inputValueEndDate : moment(initialState?.endDate).format(MARKET_DATA_DATE) });
      }
    } else {
      if (isBeforeDate(inputValueEndDate, inputValueStartDate)) {
        const date = moment(inputValueStartDate, MARKET_DATA_DATE).toDate();
        setInputValueEndDate(inputValueStartDate);
        setDateRangeState((prevState) => ({ ...prevState, endDate: date }));
        onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueStartDate });
      } else {
        onDateSelect && onDateSelect({ startDate: inputValueStartDate, endDate: inputValueEndDate });
      }
      setErrorMessage('');
    }
  };

  useEffect(() => {
    setPaperPopover(90 - Math.floor(window.innerWidth / 10));
  }, [browserWidthSize]);

  return (
    <>

      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          <form noValidate>
            <div className={styles.input}>
              <ClickableImage
                id="dateFromIcon"
                src={ExpandIcon}
                className={classNames(styles.icon, styles.leftIcon)}
                alt="cal icon"
                onClick={openDateRangeCalendar}
              />
              <input
                id="dateFrom"
                className={styles.dateRanges}
                type="date"
                required
                name="dateRangeInputFrom"
                onClick={(e => e.preventDefault())}
                onBlur={validateInput}
                value={inputValueStartDate?.toString()}
                onChange={inputChange}
              />
            </div>
            <div>
              <input
                id="dateTo"
                type="date"
                name="dateRangeInputTo"
                required
                className={styles.dateRanges}
                onClick={(e => e.preventDefault())}
                onBlur={validateInput}
                onChange={inputChange}
                value={inputValueEndDate?.toString()}
              />
              <ClickableImage
                id="dateToIcon"
                src={ExpandIcon}
                className={classNames(styles.icon, {
                  [styles.rightIcon]: !showClearButton,
                  [styles.rightIconWithClearIcon]: showClearButton,
                })}
                alt="cal icon"
                onClick={openDateRangeCalendar}
              />
              {showClearButton && <ClickableImage src={ClearIcon} className={styles.clearIcon} onClick={clearDates} />}
            </div>
          </form>
        </div>
        {errorMessage && <p className={styles.errorMsg}>{errorMessage}</p>}
        {isMobile ? (
          <Popover
            classes={{ paper: mobileStyles.paper }}
            style={{
              backgroundColor: 'rgba(45, 45, 45, 0.9',
              zIndex: 4000,
            }}
            open={displayCalendar}
            className={mobileStyles.popover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            TransitionProps={{ onEntered: checkDates, onExit: chosenRange }}
            onClose={popoverClose}
          >
            <div id="dateRangeWrapper" className={mobileStyles.dateRange}>
              <DateRange
                className={mobileStyles.dateRangePicker}
                onChange={selectDateRangesMobile}
                onShownDateChange={monthChange}
                anchorEl={anchorEl}
                minDate={minDateCalendar}
                maxDate={maxDateCalendar}
                ranges={[dateRangeState]}
                showDateDisplay={false}
                monthDisplayFormat={displayDateFormatNoDay}
                rangeColors={['#1D3960', '#00FF00']}
                weekdayDisplayFormat={'eeeeee'}
              />
            </div>
          </Popover>
        ) : (
          <Popover
            classes={{ paper: styles.paper }}
            open={displayCalendar}
            anchorEl={anchorEl}
            style={{ zoom: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? `${Math.floor(browserWidthSize / 10).toString()}%` : '' }}
            className={styles.popover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                boxShadow: '0px 3px 6px  rgba(160,163,185,0.2)',
                marginTop: (browserWidthSize <= ZOOM_BEHAVIOR_WIDTH && location.pathname === UI_ROUTES.discover)
                  ? `${paperPopover > 30 ? paperPopover + 20 : paperPopover}%`
                  : undefined,
                marginLeft: browserWidthSize <= ZOOM_BEHAVIOR_WIDTH ? `${paperPopover / 10}%` : undefined,
              },
            }}
            TransitionProps={{ onEntered: checkDates, onExit: chosenRange }}
            onClose={popoverClose}
          >
            <div id="dateRangeWrapper" className={styles.dateRange}>
              <DateRange
                className={styles.dateRangePicker}
                onChange={selectDateRanges}
                onShownDateChange={monthChange}
                showMonthAndYearPickers={false}
                minDate={minDateCalendar}
                maxDate={maxDateCalendar}
                ranges={[dateRangeState]}
                showDateDisplay={false}
                monthDisplayFormat={displayDateFormatNoDay}
                rangeColors={['#1D3960', '#00FF00']}
                weekdayDisplayFormat={'eeeeee'}
              />
            </div>
          </Popover>
        )}
      </div>
    </>
  );
};

export default FullsizeDateRangePickerWrapper;
