export enum UI_ROUTES {
  baseURL = '/',
  emailConfirm = '/email-confirm',
  emailResend = '/email-resend',
  sell = '/Sell',
  buy = '/Buy',
  modify = '/modify',
  symbol = '/symbol',
  overview = '/overview',
  authResult = '/auth-result',
  discover = '/discover',
  payments = '/payments',
  signUp = '/sign-up',
  enroll = '/enroll',
  error = '/error',
  enrollCompleted = '/completed',
  makeYourFirstDeposit = '/make-your-first-deposit',
  addYourBankAccount = '/add-your-bank-account',
  uploadBankStatement = '/upload-bank-statement',
  enterAmount = '/enter-amount',
  depositSlip = '/deposit-slip',
  verification = '/verification',
  verificationPhoneNumber = '/phone-number',
  verificationPhoneOTP = '/phone-otp',
  verificationPoI = '/proof-of-identity',
  verificationPoA = '/proof-of-address',
  verificationTermsAndConditions = '/terms-and-conditions',
  recovery = '/recovery',
  dividendPayersAndHighGrowthCompaniesPath = '/dividend-payers-high-growth-companies',
  topMovers = '/top-movers',
  popularGroup = '/popular-group',
  newOnTheMarket = '/new-on-the-market',
  earningsCalendar = '/earnings-calendar',
  tradeHistory = '/trade-history',
  myProfile = '/my-profile',
  myAccount = '/my-account',
  myAccountNotifications = '/my-account-notifications',
  myAccountDocuments = '/my-account-documents',
  myAccountReports = '/my-account-reports',
  myAccountSecurity = '/my-account-security',
  myAccountChangePassword = '/my-account-change-password',
  myAccountPersonalDetails = '/my-account-personal-details',
  accountBreakDown = '/account-breakDown',
  funds = '/funds',
  withdrawalAmount = '/withdrawal-amount',
  searchSymbolsMob = '/searchSymbol',
  tradingView = '/tradingView-chart'
}

export const UI_ROUTE_MOCKED = '/mocked';
