import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Action } from 'redux';

import { OrderStatusEnum } from '../../../../lib/enums/order-status.enum';
import lang from '../../../../lib/language';
import { Order } from '../../../../lib/models/trading/types';
import { getSummary } from '../../../../lib/store/ams/index';
import { getAccountsAvailableCash } from '../../../../lib/store/common-actions';
import { RootState } from '../../../../lib/store/index';
import {
  getAccountSummary,
  getAccountTradeHistory,
  getOpenPositions as getPositions,
  setHasOrderUpdate,
} from '../../../../lib/store/reporting';
import { PENDING_ORDERS_REQUEST_TENNSTATUS } from '../../../../lib/store/reporting/constants';
import { AccountTradeHistoryPayloadData, PositionType } from '../../../../lib/store/reporting/types';
import { isAvailableCashChanged } from '../../../../lib/store/trading/helpers';
import { newOrder as serviceNewOrder, resetRequestStatus } from '../../../../lib/store/trading/index';
import MarketStateCache from '../../../../lib/store-util/MarketStateCache';
import SubscribeGroupCache from '../../../../lib/store-util/SubscribeGroupCache';
import SymbolStatusCache from '../../../../lib/store-util/SymbolStatusCache';
import { isOneOf } from '../../../../lib/util/DataHelpers';
import { checkIfSymbolIsHalted } from '../../../../lib/util/HaltSymbolHelper';
import { isMarketClosed, isMarketRegularHours } from '../../../../lib/util/MarketDataHelpers';
import { manageSubscriptions, orderStatusToCallStatus } from '../../../../lib/util/TradingHelpers';

import ModalCreateOrder from '../../../../containers/SymbolDetails/modalCreateOrder/ModalCreateOrder';
import { useIsMobile } from '../../../../hooks';
import { UI_ROUTES } from '../../../../util/routes';
import Button from '../../../Button/Button';
import { ButtonTypesEnum } from '../../../Button/Button.types';
import { ProcessFinishedModal, RegularTradingHoursStartModal } from '../../../index';
import HaltedSymbol from '../../../OrderActions/HaltedSymbol/HaltedSymbol';
import {
  proccessPartiallyRejectedMessage,
  proccessSuccessMessageBody,
  proccessSuccessMessageTitle,
  processFailMessageManager,
} from '../../../OrderActions/OrderHelpers';
import useNewOrderTimer from '../../../OrderActions/useNewOrderTimer';

import styles from './ButtonsSellAndBuy.module.scss';

type Props = {
  isSwipeable?: boolean;
  row: any;
} & ReduxProps

const ButtonsSellAndBuy = (props: Props) => {
  const {
    requestStatus,
    row,
    isSwipeable,
    submittedOrder,
    getOpenPositions,
    getOrders,
    myStocksUpdate,
    newOrder,
    resetStatus,
    getAccSummary,
    getMessageSummary,
    getAccountAvailableCash,
  } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const [isSell, setIsSell] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>({});
  const [modalTitle, setModalTitle] = useState<string>('');
  const [fromReview, setFromReview] = useState<boolean>(false);
  const [isHalted, setIsHalted] = useState<boolean>(false);
  const [positionsQuantity, setPositionsQuantity] = useState<number>(0);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [notEnoughStocks, setNotEnoughStocks] = useState<boolean>(false);
  const [showCreateOrderModal, setShowCreateOrderModal] = useState<boolean>(false);
  const [visibleRegularTradingHoursStartDialog, setVisibleRegularTradingHoursStartDialog] = useState<boolean>(false);
  const { marketState } = MarketStateCache.use();
  const { name, id, positionType, quantity } = row;
  const symbolsStatusCache = SymbolStatusCache.use(name);

  const { ordType, orderQty, side, status, filledSoFar } = submittedOrder;
  const rejectedOrder = requestStatus === OrderStatusEnum.Rejected;
  const orderInProgress = requestStatus === OrderStatusEnum.InProgress;
  const partiallyRejectedOrder = status === OrderStatusEnum.PartiallyReject;

  const {
    orderTimeoutPassed, showExpired, showFillOrder, hasNoResponse,
  } = useNewOrderTimer(showFinishModal, ordType, status, requestStatus);

  const marketClosed = isMarketClosed(marketState);

  useEffect(() => {
    if (fromReview && requestStatus !== OrderStatusEnum.Pending && !showFinishModal) {
      if (
        !marketClosed
        && ((!isSell && positionType === PositionType.Sell
          && order.orderQty && Math.abs(quantity) < order.orderQty)
        || (isSell && positionType === PositionType.Buy && order.orderQty && quantity < order.orderQty))
      ) {
        setNotEnoughStocks(true);
      } else {
        newOrder(order);
        setShowFinishModal(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromReview, requestStatus]);

  useEffect(() => {
    if (showFinishModal) {
      setShowCreateOrderModal(false);
      setFromReview(false);
    }
  }, [showFinishModal]);

  const handleBuySellClick = (isSellClicked: boolean) => {
    if (checkIfSymbolIsHalted(symbolsStatusCache)) {
      setIsHalted(true);
      return;
    }

    if (isSellClicked && positionType === PositionType.Sell && !isMarketRegularHours(marketState)) {
      setVisibleRegularTradingHoursStartDialog(true);
      return;
    }

    let title = `${isSellClicked ? lang.commonOrderSideButtonSell() : lang.commonOrderSideButtonBuy()}`;
    setIsSell(isSellClicked);
    setShowFinishModal(false);

    if ((!isSellClicked
      && positionType === PositionType.Sell)
      || (isSellClicked && positionType === PositionType.Buy)) {
      setPositionsQuantity(quantity);
    } else {
      setPositionsQuantity(0);
    }
    if (positionType === PositionType.Sell && isSellClicked) {
      title = `${lang.commonShort()} ${title}`;
    }

    if (isMobile) {
      const target = isSellClicked ? PositionType.Sell : PositionType.Buy;
      history.replace({
        pathname: `/${target}/${id}`,
        state: { stateSymbol: id, stateTarget: target, origin: UI_ROUTES.myAccount },
      });
    } else {
      setModalTitle(title);
      setShowCreateOrderModal(!showCreateOrderModal);
    }
  };

  const onCloseResultOrderModal = () => {
    if (orderInProgress) {
      resetStatus();
    }
    if (!marketClosed) {
      getOpenPositions();
      myStocksUpdate(false);

      if (status === OrderStatusEnum.Filled) {
        getAccSummary();
      }

      if (isOneOf(status, [
        OrderStatusEnum.Filled,
        OrderStatusEnum.PartiallyFilled,
        OrderStatusEnum.Rejected,
        OrderStatusEnum.Expired,
      ])) {
        getMessageSummary();
      }
    }
    if (status && isAvailableCashChanged(status)) {
      getAccountAvailableCash();
    }
    SubscribeGroupCache.setComponent('initial');
    getOrders({ tenNStatus: PENDING_ORDERS_REQUEST_TENNSTATUS, isPendingOrdersCall: true });
  };

  const handleCloseOrder = (value) => {
    SubscribeGroupCache.setComponent('initial');
    manageSubscriptions('my-account/positions');
    setShowCreateOrderModal(value);
  };

  const handleRenderModalCreateOrder = () => {
    if (modalTitle === lang.commonOrderSideButtonSell()
    || modalTitle === lang.commonOrderSideButtonBuy()
    || ((modalTitle.includes(lang.commonOrderSideButtonSell()) && isMarketRegularHours(marketState)))) {
      return (
        <ModalCreateOrder
          isMyAccount
          symbol={name}
          orderSide={modalTitle.includes(lang.commonShort()) ? lang.commonOrderSideButtonSell() : modalTitle}
          hasMyStocksOrderUpdate
          positionsQuantity={positionsQuantity}
          isOpenModalCreateOrder={showCreateOrderModal}
          setOrderInfo={(value) => setOrder(value)}
          handlePopUp={handleCloseOrder}
          setFromReview={(value) => setFromReview(value)}
        />
      );
    }

    return null;
  };

  return (
    <>
      {isMobile ? (
        <div className={isSwipeable ? styles.wrapperButtonsSwipable : styles.wrapperButtonsMobile}>
          <Button
            className={isSwipeable ? styles.btnSellMobileSwipable : styles.btnSellMobile}
            variant={ButtonTypesEnum.Link}
            onClick={() => handleBuySellClick(true)}
          >
            {lang.commonOrderSideButtonSell()}
          </Button>
          <Button
            className={isSwipeable ? styles.btnBuyMobileSwipable : styles.btnBuyMobile}
            variant={ButtonTypesEnum.Link}
            onClick={() => handleBuySellClick(false)}
          >
            {lang.commonOrderSideButtonBuy()}
          </Button>
        </div>
      ) : (
        <Grid className={styles.containerButtons}>
          <Grid className={styles.wrapperButtons}>
            <Grid className={styles.wrapperButtunSell}>
              <Button
                className={styles.btnSell}
                variant={ButtonTypesEnum.Link}
                onClick={() => handleBuySellClick(true)}
              >
                {lang.commonOrderSideButtonSell()}
              </Button>
            </Grid>
            <Grid className={styles.wrapperButtunBuy}>
              <Button
                className={styles.btnBuy}
                variant={ButtonTypesEnum.Link}
                onClick={() => handleBuySellClick(false)}
              >
                {lang.commonOrderSideButtonBuy()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isHalted && <HaltedSymbol symbolName={row.companyName ?? name} toggleModal={() => setIsHalted(false)} />}
      {visibleRegularTradingHoursStartDialog
        ? (
          <RegularTradingHoursStartModal
            toggleModal={() => setVisibleRegularTradingHoursStartDialog(false)}
          />
        )
        : handleRenderModalCreateOrder()}
      {((orderTimeoutPassed || hasNoResponse || rejectedOrder) && showFinishModal) && (
      <ProcessFinishedModal
        callStatus={orderStatusToCallStatus(status as OrderStatusEnum)}
        processFailedMessage={
          processFailMessageManager(rejectedOrder, showExpired, name, ordType!, side!, orderQty!)
        }
        processPartiallyRejectedMessage={partiallyRejectedOrder
          ? proccessPartiallyRejectedMessage(name, ordType!, side!, orderQty!, filledSoFar) : undefined}
        processSuccessMessageTitle={rejectedOrder
          ? '' : proccessSuccessMessageTitle(ordType!, side!, status, showFillOrder)}
        processSuccessMessageBody={rejectedOrder
          ? '' : proccessSuccessMessageBody(name, status, orderQty, ordType, showFillOrder, filledSoFar)}
        defaultMessageTitle={orderInProgress ? lang.orderMessageBodyPendingWeb(ordType!, side!, orderQty ?? 0, name) : ''}
        toggleModal={onCloseResultOrderModal}
      />
      )}
      {notEnoughStocks && (
      <ProcessFinishedModal
        processSuccessMessageTitle=""
        callStatus={orderStatusToCallStatus(OrderStatusEnum.Rejected)}
        processFailedMessage={lang.webTradingIncorrectStocks()}
        toggleModal={() => { setNotEnoughStocks(false); myStocksUpdate(false); }}
      />
      )}
    </>
  );
};

const mapStateProps = (state: RootState) => ({
  requestStatus: state.trading.requestStatus,
  submittedOrder: state.trading.order,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetStatus: () => dispatch(resetRequestStatus()),
  newOrder: (order: Order) => dispatch(serviceNewOrder({ order })),
  getOrders: (data: AccountTradeHistoryPayloadData) => dispatch(getAccountTradeHistory(data)),
  getOpenPositions: (symbol?: string) => dispatch(getPositions({ symbol })),
  myStocksUpdate: (hasUpdate: boolean) => dispatch(setHasOrderUpdate(hasUpdate)),
  getAccSummary: () => dispatch(getAccountSummary()),
  getMessageSummary: () => dispatch(getSummary()),
  getAccountAvailableCash: () => dispatch(getAccountsAvailableCash()),
});

const connector = connect(mapStateProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ButtonsSellAndBuy);
