import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { PriceChangeDirection } from '../../../../../lib/store-util/TradePriceCache';
import { getPriceColorByDirection } from '../../../../../lib/util/ChartingHelpers';
import { formatCurrency } from '../../../../../lib/util/DataHelpers';

import { UI_ROUTES } from '../../../../../util/routes';
import ChartLine from '../../../../Chart/ChartLine/ChartLine';
import ClickableDiv from '../../../../ClickableDiv/ClickableDiv';
import CompanyLogo from '../../../../CompanyLogo/CompanyLogo';
import { TradePrice } from '../../PeopleAlsoTrade.types';

import styles from './PeopleAlsoTradeCard.module.scss';

type Props = {
  data: TradePrice;
  handleClickCard: () => void;
}

const PeopleAlsoTradeCard = ({ data, handleClickCard }: Props) => {
  const history = useHistory();
  const { logoBase64, symbol, currentPrice, direction, changePercent } = data;
  const directionColor = getPriceColorByDirection(direction as PriceChangeDirection);

  const handleClick = () => {
    handleClickCard();
    history.push(`${UI_ROUTES.symbol}/${symbol}`);
  };

  return (
    <div className={styles.container}>
      <ClickableDiv
        className={styles.card}
        onClick={() => handleClick()}
      >
        <div className={styles.header}>
          <div><CompanyLogo logo={logoBase64 || ''} symbol={symbol} /></div>
          <Typography noWrap className={styles.companyName}>{symbol}</Typography>
        </div>
        <div className={styles.chart}>
          <ChartLine symbol={symbol} isChartInCard />
        </div>
        <div className={styles.footer}>
          <p>{formatCurrency(currentPrice)}</p>
          <p style={{ color: directionColor }}>
            {
              direction === '-'
                ? `-${changePercent}`
                : `+${changePercent}`
            }
          </p>
        </div>
      </ClickableDiv>
    </div>
  );
};

export default PeopleAlsoTradeCard;
