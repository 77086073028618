import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';

import lang from '../../../../lib/language';
import { EnrollIndividualAddressRequestData } from '../../../../lib/models/enroll-requests';
import { getAddressValidity, getCityValidity, getPostCodeValidity, validateControl } from '../../../../lib/util/ValidationSchemes/Validate';

import { ICountryControlOptions } from '../../../CountryControl/CountryControl.types';
import { CountryControl, InputControl } from '../../../index';
import { EnrollAddressesForm } from '../EnrollAddresses.types';

import styles from './EnrollAddressesInfo.module.scss';

type Props = {
  options: ICountryControlOptions[];
  addressData: EnrollIndividualAddressRequestData;
  setAddressData: Dispatch<SetStateAction<EnrollAddressesForm>>;
  isMailingAddress?: boolean;
  isClickedBtnContinue: boolean;
}

const EnrollAddressesInfo = (props: Props): JSX.Element => {
  const {
    options,
    addressData,
    isMailingAddress = false,
    isClickedBtnContinue,
    setAddressData,
  } = props;

  const ariaLabelText = isMailingAddress ? 'mailing' : 'residential';

  return (
    <Row className={styles.detailsWrapper}>
      <Col md={12} className={styles.contentItem}>
        <CountryControl
          id={isMailingAddress ? 'mailingAddressCountry' : 'residentialAddressCountry'}
          name=""
          ariaLabel={isMailingAddress ? 'mailing address country' : 'residential address country'}
          options={options}
          value={addressData.country_id}
          placeholder={lang.commonOnboardingPersonalDetailsCountry()}
          onCountryChange={(countryId: string) => {
            setAddressData((currentState: EnrollAddressesForm) => {
              if (isMailingAddress) {
                return ({
                  ...currentState,
                  mailingAddress: {
                    ...currentState.mailingAddress,
                    country_id: countryId,
                  },
                });
              }

              return ({
                ...currentState,
                residentialAddress: {
                  ...currentState.residentialAddress,
                  country_id: countryId,
                },
              });
            });
          }}
          errors={isClickedBtnContinue ? validateControl(addressData.country_id).errors : []}
        />
      </Col>
      <Col md={12}>
        <Row>
          <Col md={6} className={styles.contentItem}>
            <InputControl
              id={`${ariaLabelText}AddressCity`}
              name=""
              placeholder=""
              className={styles.colWrapper}
              value={addressData.city}
              label={lang.commonOnboardingPersonalDetailsCity()}
              ariaLabel={`${ariaLabelText} address city`}
              onValueChange={(value: string | number) => {
                setAddressData((currentState: EnrollAddressesForm) => {
                  if (isMailingAddress) {
                    return ({
                      ...currentState,
                      mailingAddress: {
                        ...currentState.mailingAddress,
                        city: value as string,
                      },
                    });
                  }

                  return ({
                    ...currentState,
                    residentialAddress: {
                      ...currentState.residentialAddress,
                      city: value as string,
                    },
                  });
                });
              }}
              errors={isClickedBtnContinue ? getCityValidity(addressData.city).errors : []}
            />
          </Col>
          <Col md={6} className={styles.contentItem}>
            <InputControl
              name=""
              placeholder=""
              id={`${ariaLabelText}AddressPost`}
              className={styles.colWrapper}
              value={addressData.zip_code}
              ariaLabel={`${ariaLabelText} address post code`}
              label={lang.commonOnboardingPersonalDetailsPostCode()}
              onValueChange={(value: string | number) => {
                setAddressData((currentState: EnrollAddressesForm) => {
                  if (isMailingAddress) {
                    return ({
                      ...currentState,
                      mailingAddress: {
                        ...currentState.mailingAddress,
                        zip_code: value as string,
                      },
                    });
                  }

                  return ({
                    ...currentState,
                    residentialAddress: {
                      ...currentState.residentialAddress,
                      zip_code: value as string,
                    },
                  });
                });
              }}
              errors={isClickedBtnContinue ? getPostCodeValidity(addressData.zip_code).errors : []}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12}>
        <InputControl
          name=""
          placeholder=""
          id={`${ariaLabelText}AddressInput`}
          value={addressData.address_line}
          ariaLabel={`${ariaLabelText} address input`}
          label={lang.commonOnboardingPersonalDetailsAddress()}
          helperText={lang.commonOnboardingPersonalDetailsAddressUnderlineText()}
          onValueChange={(value: string | number) => {
            setAddressData((currentState: EnrollAddressesForm) => {
              if (isMailingAddress) {
                return ({
                  ...currentState,
                  mailingAddress: {
                    ...currentState.mailingAddress,
                    address_line: value as string,
                  },
                });
              }

              return ({
                ...currentState,
                residentialAddress: {
                  ...currentState.residentialAddress,
                  address_line: value as string,
                },
              });
            });
          }}
          errors={isClickedBtnContinue ? getAddressValidity(addressData.address_line).errors : []}
        />
      </Col>
    </Row>
  );
};

export default EnrollAddressesInfo;
