import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import lang from '../../lib/language';
import TopGainersCache from '../../lib/store-util/top-movers/TopGainersCache';
import TopLosersCache from '../../lib/store-util/top-movers/TopLosersCache';

import { Button, Navigation, SymbolTradeInfo } from '../../components';
import { ButtonTypesEnum } from '../../components/Button/Button.types';
import { UI_ROUTES } from '../../util/routes';

import { FILTER_RANGE_OPTIONS } from './components/FilterDropDown/FilterDropDownOptions';

import styles from './TopGainersAndLosers.module.scss';

let isInitialStateOptionFilter: boolean = true;

const TopGainersAndLosers = () => {
  const history = useHistory();

  const [currentTab, setCurrentTabIndex] = useState<number>(0);
  const [optionFilter, setOptionFilter] = useState<number[]>(FILTER_RANGE_OPTIONS[2]);
  const [optionValue, setOptionValue] = useState<string>('');
  const [isUILoading, setIsUILoading] = useState(true);

  const isTopGainers = currentTab === 0;

  const { data, isInitialDataReady, isTimeout } = isTopGainers ? TopGainersCache.use() : TopLosersCache.use();

  useEffect(() => {
    if (isUILoading) {
      setTimeout(() => {
        if (isUILoading) setIsUILoading(false);
      }, 300);
    }
  }, [
    optionValue,
    isTopGainers,
    isUILoading,
    data,
    isTimeout,
  ]);

  useEffect(() => {
    if (isUILoading && isInitialDataReady) setIsUILoading(false);
  }, [isUILoading, isInitialDataReady]);

  const tabs = [
    { text: lang.commonTopGainers(),
      component: <SymbolTradeInfo
        currentTab={currentTab}
        optionFilter={optionFilter}
        isInitialStateOptionFilter={isInitialStateOptionFilter}
        topMovers={data}
        isTopMoversTimeout={isTimeout}
        isUILoading={isUILoading}
      />,
    },
    { text: lang.commonTopLosers(),
      component: <SymbolTradeInfo
        currentTab={currentTab}
        optionFilter={optionFilter}
        isInitialStateOptionFilter={isInitialStateOptionFilter}
        topMovers={data}
        isTopMoversTimeout={isTimeout}
        isUILoading={isUILoading}
      />,
    },
  ];

  const handleFilterOption = (option: number[], valueOption: string) => {
    setOptionFilter(option);
    setOptionValue(valueOption);
    isInitialStateOptionFilter = false;
  };

  const handleCurrentTabIndex = (index: number) => {
    isInitialStateOptionFilter = true;
    setCurrentTabIndex(index);
    setOptionFilter(FILTER_RANGE_OPTIONS[2]);
    setOptionValue('');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTitle}>
        <h6 className={styles.title}>{lang.commonTopMovers()}</h6>
        {
          data.length > 10
            ? (
              <Button
                variant={ButtonTypesEnum.Link}
                onClick={() => history.push({
                  pathname: UI_ROUTES.discover + UI_ROUTES.topMovers,
                  state: { currentTab, optionValue, optionFilter },
                })}
              >
                {lang.mobileFundsScreenViewAllBtn()}
              </Button>
            )
            : null
        }
      </div>
      <Navigation
        tabs={tabs}
        currentTabTopMovers={currentTab}
        handleFilterOption={handleFilterOption}
        sendCurrentTabIndex={handleCurrentTabIndex}
      />
    </div>
  );
};

export default TopGainersAndLosers;
