import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { applicationStartup, login } from '../lib/store/common-actions';
import { getFileMimeType } from '../lib/util/DataHelpers';

import { MOCK } from '../configDebug';
import configLib from '../configLib';

import { UI_ROUTES } from './routes';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/** Forces a render of a component defined as a function
 * Usage:
 *        const [forcedRendersCount, forceUpdate] = useForceUpdate();
 *        ...
 *        <div>Forced Updates: {forcedRendersCount}</div>
 *        <button onClick={forceUpdate}>Force Update</button>
 * Exammple: See `LatestNews.tsx`
 */
export const useForceUpdate = (): [number, () => void] => {
  const [value, setValue] = useState(0);
  return [value, (customValue?: number) => {
    setValue((currentValue) => (customValue != null ? customValue : currentValue + 1));
  }];
};

export const handleDownloadFile = (fileName: string, base64Data: string): void => {
  const fileType = getFileMimeType(fileName);
  const blob = convertBase64ToBlob(base64Data, fileType);

  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
  } else {
    const link = document.createElement('a');

    document.body.appendChild(link);

    link.style.display = 'none';

    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName;

    link.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(link);
  }
};

export function convertBase64ToBlob(data: string, contentType = '', sliceSize = 512): Blob {
  const byteCharacters = atob(data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export const handleOpenFileInNewTab = (file: string, type: string) => {
  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type });
  };
  const blob = base64toBlob(file);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

export const startMockedMode = () => {
  MOCK.setEnabled(true);
  configLib.store.dispatch(applicationStartup());
  configLib.store.dispatch(login());
};

export const handlePathname = (pathname: string) => (
  pathname === '/'
  || pathname === UI_ROUTES.error
  || pathname === UI_ROUTES.signUp
  || pathname === UI_ROUTES.enroll
  || pathname === (UI_ROUTES.enroll + UI_ROUTES.enrollCompleted)
  || pathname === UI_ROUTES.verification
  || (pathname === UI_ROUTES.verification + UI_ROUTES.verificationPhoneNumber)
  || (pathname === UI_ROUTES.verification + UI_ROUTES.verificationPhoneOTP)
  || (pathname === UI_ROUTES.verification + UI_ROUTES.verificationPoI)
  || (pathname === UI_ROUTES.verification + UI_ROUTES.verificationPoA)
  || (pathname === UI_ROUTES.verification + UI_ROUTES.verificationTermsAndConditions)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.makeYourFirstDeposit)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.addYourBankAccount)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.uploadBankStatement)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.enterAmount)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.withdrawalAmount)
  || (pathname === UI_ROUTES.funds + UI_ROUTES.depositSlip)
);

export const getTabName = (location) => {
  if (location.pathname === '/overview') return 'Watchlist';
  if (location.pathname === '/my-account') return 'My Account';
  if (location.pathname === '/discover') return 'Discover';
  if (location.pathname === '/funds') return 'Wallet';
  if (location.pathname === '/trade-history') return 'Trade History';
  if (location.pathname === '/my-profile') return 'My Profile';
  return 0;
};
