import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CallStatus } from '../../enums';
import { PagedResponse } from '../../models/common-response-types';
import { Company, CompanyLogo } from '../../models/company';
import {
  CompanyLogoAndNameResponse,
  DividendPayerData,
  Earning,
  ETF,
  ETFDetailParams,
  ETFDetailResponse,
  ETFParams,
  HighGrowthCompanyData,
  IPO,
  NewStockData,
  PageableFilterRequest,
  PopularStock,
  PopularStockParams,
  Sector,
  SectorDetail,
  Split,
  StocksSymbolSet,
  TopGainerAndLoserData,
  TopGainerAndLoserPayload,
} from '../../models/fundamental/types';
import { defaultLogoBase64 } from '../../util/assets/logos';
import { NullableNumber, NullableString } from '../../util/types';
import { clearErrorsLog, ErrorPayloadFull } from '../common-actions';
import { getWatchlistData } from '../market-data';

import {
  DEFAULT_SEARCH_STOCK_LIMIT,
  INITIAL_STATE,
  NAMES_SECTORS_CONTAINING_IMAGES,
  NO_MORE_PAGES_TO_READ,
} from './constants';
import {
  parseETFName,
  populateEarningCalendarLabels,
  processCallCache,
  setFundamentalCallStatus,
  updateCompanyAndLogoCache,
} from './helpers';
import {
  FundamentalCall,
  FundamentalState,
  GetSectorsResponse,
  SearchEarningsPayload,
  SearchStockPayload,
} from './types';

const fundamentalSlice = createSlice({
  name: 'fundamental',
  initialState: INITIAL_STATE,
  reducers: {
    searchStockByParams(state, action: PayloadAction<SearchStockPayload>) {
      const { stockQuery, limit: stateLimit } = state;
      const { query, limit } = action.payload;
      state.stockQuery = query;

      if (!query || query !== stockQuery) {
        state.limit = DEFAULT_SEARCH_STOCK_LIMIT;
        state.stockResults = [];
      } else {
        state.limit = limit;
      }

      setFundamentalCallStatus(FundamentalCall.searchStocks, !query ? CallStatus.READY : CallStatus.PENDING, state);
    },
    searchStock(state, action: PayloadAction<string>) {
      state.stockQuery = action.payload;
      setFundamentalCallStatus(FundamentalCall.searchStocks, CallStatus.PENDING, state);
    },
    stockSearchCompleted(state, action) {
      state.stockResults = action.payload;
      setFundamentalCallStatus(FundamentalCall.searchStocks, CallStatus.READY, state);
    },
    stockSearchFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.searchStocks, CallStatus.ERROR, state, status);
    },
    clearStockSearch(state) {
      state.stockQuery = '';
      state.stockResults = [];
      setFundamentalCallStatus(FundamentalCall.searchStocks, CallStatus.INITIAL, state);
    },
    stockSearchReset(state, action: PayloadAction<boolean>) {
      state.stockResults = [];
      if (action.payload) {
        setFundamentalCallStatus(FundamentalCall.searchStocks, CallStatus.INITIAL, state);
      }
    },
    loadStock(state, action: PayloadAction<{ symbol: string, size?: NullableNumber }>) {
      state.symbol = action.payload.symbol;
    },
    getEarnings(state, action: PayloadAction<{ startDate?: string, endDate?: string }>) {
      setFundamentalCallStatus(FundamentalCall.getEarnings, CallStatus.PENDING, state);
    },
    getEarningsCompleted(state, action: PayloadAction<Earning[]>) {
      state.earnings = action.payload;
      setFundamentalCallStatus(FundamentalCall.getEarnings, CallStatus.READY, state);
    },
    getEarningsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getEarnings, CallStatus.ERROR, state, status);
    },
    searchEarnings(state, action: PayloadAction<SearchEarningsPayload>) {
      setFundamentalCallStatus(FundamentalCall.searchEarnings, CallStatus.PENDING, state);
    },
    searchEarningsCompleted(state, action: PayloadAction<PagedResponse<Earning>>) {
      const { number: page, content } = populateEarningCalendarLabels(action.payload) ?? {};
      state.earningsSearchResponse = page === 0 ? populateEarningCalendarLabels(action.payload) : {
        ...populateEarningCalendarLabels(action.payload),
        content: state.earningsSearchResponse.content.concat(content),
      };
      setFundamentalCallStatus(FundamentalCall.searchEarnings, CallStatus.READY, state);
    },
    searchEarningsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.searchEarnings, CallStatus.ERROR, state, status);
    },
    keyStatsFailed(state, action) {
      state.keyStats = [];
      state.error = action.payload;
    },
    keyStatsCompleted(state, action) {
      state.keyStats = action.payload;
    },
    searchCompany(state, action: PayloadAction<{symbol: string, limit?: number}>) {},
    searchCompanyCompleted(state: FundamentalState, action: PayloadAction<Company[]>) {
      state.companies = action.payload;
    },
    searchCompanyFailed(state, action) {
      state.companies = [];
      state.error = action.payload;
    },
    getCompany(state, action: PayloadAction<string>) {},
    getCompanyCompleted(state: FundamentalState, action: PayloadAction<Company[]>) {
      state.companies = [ ...state.companies, ...action.payload ];
    },
    getCompanyFailed(state, action) {
      state.companies = [];
      state.error = action.payload;
    },
    // TODO: Delete after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
    getCompanyLogo(state, action: PayloadAction<string>) {},
    getCompanyLogoCompleted(state, action: PayloadAction<CompanyLogo[]>) {
      action.payload?.forEach(item => {
        state.logosBase64[item.symbol] = item.base64Data;
      });
    },
    getCompanyLogoFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getEarnings, CallStatus.ERROR, state, status);
    },
    getCompanyLogoAndName(state, action: PayloadAction<string>) {
      if (processCallCache(action.payload, 'getCompanyLogoAndName') !== '') {
        setFundamentalCallStatus(FundamentalCall.getCompanyLogoAndName, CallStatus.PENDING, state);
      }
    },
    getCompanyLogoAndNameCompleted(state, action: PayloadAction<CompanyLogoAndNameResponse[]>) {
      action.payload?.forEach(item => {
        state.companyLogoAndName[item.symbol] = { logo: item.base64Data ?? defaultLogoBase64, name: item.companyName };
      });
      setFundamentalCallStatus(FundamentalCall.getCompanyLogoAndName, CallStatus.READY, state);
    },
    getCompanyLogoAndNameFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getCompanyLogoAndName, CallStatus.ERROR, state, status);
    },
    getPopularStocks(state, action: PayloadAction<PopularStockParams | undefined>) {
      setFundamentalCallStatus(FundamentalCall.getPopularStocks, CallStatus.PENDING, state);
    },
    getPopularStocksCompleted(state, action: PayloadAction<PopularStock[]>) {
      const stocks = action.payload;
      state.popularStocks = stocks.map(
        ({ symbol, popularity, symbolUniverseSymbol }) => ({ symbol, popularity, symbolUniverseSymbol }),
      );
      setFundamentalCallStatus(FundamentalCall.getPopularStocks, CallStatus.READY, state);
      updateCompanyAndLogoCache(stocks, state);
    },
    getPopularStocksFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getPopularStocks, CallStatus.ERROR, state, status);
    },
    getNewStocks(state, action: PayloadAction<StocksSymbolSet | undefined>) {
      setFundamentalCallStatus(FundamentalCall.getNewStocks, CallStatus.PENDING, state);
    },
    getNewStocksCompleted(state, action: PayloadAction<NewStockData[]>) {
      const stocks = action.payload;
      state.newStocks = stocks;
      setFundamentalCallStatus(FundamentalCall.getNewStocks, CallStatus.READY, state);
      updateCompanyAndLogoCache(stocks, state);
    },
    getNewStocksFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getNewStocks, CallStatus.ERROR, state, status);
    },
    getDividendPayers(state, action: PayloadAction<PageableFilterRequest|undefined>) {
      setFundamentalCallStatus(FundamentalCall.getDividendPayers, CallStatus.PENDING, state);
    },
    getDividendPayersCompleted(state, action: PayloadAction<PagedResponse<DividendPayerData>>) {
      const { content, number } = action.payload;
      const set = content.reduce<Record<string, DividendPayerData>>((uniqueSet, current) => {
        // eslint-disable-next-line no-param-reassign
        uniqueSet[current.symbol] = current;
        return uniqueSet;
      }, {});
      const uniqueContent = Object.values(set).sort((a, b) => (a.dividendYield > b.dividendYield ? 1 : 0));
      state.dividendPayers = number === 0 ? { ...action.payload, content: uniqueContent } : {
        ...action.payload,
        content: state.dividendPayers.content.concat(uniqueContent),
      };
      setFundamentalCallStatus(FundamentalCall.getDividendPayers, CallStatus.READY, state);
      updateCompanyAndLogoCache(content, state);
    },
    getDividendPayersFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getDividendPayers, CallStatus.ERROR, state, status);
    },
    getHighGrowthCompanies(state, action: PayloadAction<PageableFilterRequest|undefined>) {
      setFundamentalCallStatus(FundamentalCall.getHighGrowthCompanies, CallStatus.PENDING, state);
    },
    getHighGrowthCompaniesCompleted(state, action: PayloadAction<PagedResponse<HighGrowthCompanyData>>) {
      const { content, number } = action.payload;
      const set = content.reduce<Record<string, HighGrowthCompanyData>>((uniqueSet, current) => {
        // eslint-disable-next-line no-param-reassign
        uniqueSet[current.symbol] = current;
        return uniqueSet;
      }, {});
      const uniqueContent = Object.values(set).sort((a, b) => (a.growthIndicator > b.growthIndicator ? 1 : 0));
      state.highGrowthCompanies = number === 0 ? { ...action.payload, content: uniqueContent } : {
        ...action.payload,
        content: state.highGrowthCompanies.content.concat(uniqueContent),
      };
      setFundamentalCallStatus(FundamentalCall.getHighGrowthCompanies, CallStatus.READY, state);
      updateCompanyAndLogoCache(content, state);
    },
    getHighGrowthCompaniesFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getHighGrowthCompanies, CallStatus.ERROR, state, status);
    },
    getSectors(state, action: PayloadAction<undefined>) {
      setFundamentalCallStatus(FundamentalCall.getSectors, CallStatus.PENDING, state);
    },
    getSectorsCompleted(state, action: PayloadAction<GetSectorsResponse[]>) {
      const tempSectors: Sector[] = [];
      action.payload
        .filter(sector => sector.sector !== 'N/A' && sector.sector !== 'NULL')
        .filter(sector => NAMES_SECTORS_CONTAINING_IMAGES.includes(sector.sector))
        .forEach(sec => {
          const {
            id,
            sector,
            symbolUniverseSymbolCount: symbolCount,
            logoBase64,
          } = sec;

          tempSectors.push({ id, sector, symbolCount: symbolCount || 0, logoBase64 });
        });

      state.sectors = tempSectors;
      setFundamentalCallStatus(FundamentalCall.getSectors, CallStatus.READY, state);
    },
    getSectorsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getSectors, CallStatus.ERROR, state, status);
    },
    getSectorDetail(state, action: PayloadAction<number>) {
      setFundamentalCallStatus(FundamentalCall.getSectorDetails, CallStatus.PENDING, state);
    },
    getSectorDetailCompleted(state, action: PayloadAction<SectorDetail>) {
      const response = {
        ...action.payload,
        // This is for adding default logo if no logo is available
        content: action.payload.content?.map(el => (el?.logoBase64 ? el : { ...el, logoBase64: defaultLogoBase64 })),
      };

      if (state.sectorDetails.sectorId === response.sectorId) {
        response.content.map(item => state.sectorDetails.content.push(item));
      } else {
        state.sectorDetails = { ...state.sectorDetails, content: response.content, nextPageToRead: 0 };
      }

      state.sectorDetails.nextPageToRead = state.sectorDetails.content?.length >= response.totalElements!
        ? NO_MORE_PAGES_TO_READ
        : state.sectorDetails.nextPageToRead += 1;
      state.sectorDetails.sector = response.sector;
      state.sectorDetails.sectorId = response.sectorId;
      setFundamentalCallStatus(FundamentalCall.getSectorDetails, CallStatus.READY, state);
      updateCompanyAndLogoCache(response.content, state);
    },
    getSectorDetailFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getSectorDetails, CallStatus.ERROR, state, status);
    },
    getETFs(state, action: PayloadAction<ETFParams>) {
      setFundamentalCallStatus(FundamentalCall.getETFs, CallStatus.PENDING, state);
    },
    getETFsCompleted(state, action: PayloadAction<PagedResponse<ETF>>) {
      const { content, totalElements } = action.payload;
      state.ETFData.totalElements = totalElements;
      if (content.length > 1) {
        const etfs = content.map((etf: ETF) => {
          const { name, ...rest } = etf;
          return { companyName: name && parseETFName(name), ...rest };
        });
        state.ETFData.content = [ ...state.ETFData.content, ...etfs ];
        if (state.ETFData.content.length === state.ETFData.totalElements) {
          state.ETFData.nextPage = -1;
        } else {
          state.ETFData.nextPage += 1;
        }
      }
      setFundamentalCallStatus(FundamentalCall.getETFs, CallStatus.READY, state);
    },
    getETFsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getETFs, CallStatus.ERROR, state, status);
    },
    getETFDetails(state, action: PayloadAction<ETFDetailParams>) {
      setFundamentalCallStatus(FundamentalCall.getETFDetails, CallStatus.PENDING, state);
    },
    getETFDetailsCompleted(state, action: PayloadAction<PagedResponse<ETFDetailResponse>>) {
      const details = action.payload.content;
      const updatedETFs = state.ETFData.content.map(etf1 => {
        const matchingEtf = details.find((etf2: ETFDetailResponse) => etf2.symbol === etf1.symbol);
        if (matchingEtf) {
          return { ...etf1, ...matchingEtf };
        }
        return etf1;
      });

      state.ETFData.content = [ ...updatedETFs ];
      setFundamentalCallStatus(FundamentalCall.getETFDetails, CallStatus.READY, state);
    },
    getETFDetailsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getETFDetails, CallStatus.ERROR, state, status);
    },
    setTopGainersAndLosersFilterValues(state, action: PayloadAction<TopGainerAndLoserPayload>) {
      state.topGainersAndLosersFilterValues = action.payload;
    },
    getMarketIndexes(state, action: PayloadAction<{ symbols: string[] }>) {
      setFundamentalCallStatus(FundamentalCall.getMarketIndexes, CallStatus.PENDING, state);
    },
    getMarketIndexesCompleted(state, action: PayloadAction<[]>) {
      setFundamentalCallStatus(FundamentalCall.getMarketIndexes, CallStatus.READY, state);
      state.marketIndexes = action.payload;
    },
    getMarketIndexesFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getMarketIndexes, CallStatus.ERROR, state, status);
    },
    getTopGainers(state, action: PayloadAction<TopGainerAndLoserPayload>) {
      setFundamentalCallStatus(FundamentalCall.getTopGainers, CallStatus.PENDING, state);
    },
    getTopGainersCompleted(state, action: PayloadAction<TopGainerAndLoserData[]>) {
      setFundamentalCallStatus(FundamentalCall.getTopGainers, CallStatus.READY, state);
      updateCompanyAndLogoCache(action.payload, state);
    },
    getTopGainersFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getTopGainers, CallStatus.ERROR, state, status);
    },
    getTopLosers(state, action: PayloadAction<TopGainerAndLoserPayload>) {
      setFundamentalCallStatus(FundamentalCall.getTopLosers, CallStatus.PENDING, state);
    },
    getTopLosersCompleted(state, action: PayloadAction<TopGainerAndLoserData[]>) {
      setFundamentalCallStatus(FundamentalCall.getTopLosers, CallStatus.READY, state);
      updateCompanyAndLogoCache(action.payload, state);
    },
    getTopLosersFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getTopLosers, CallStatus.ERROR, state, status);
    },
    getIPOs(state, action: PayloadAction<{ startDate: NullableString, endDate: NullableString }>) {
      setFundamentalCallStatus(FundamentalCall.getIPOs, CallStatus.PENDING, state);
    },
    getIPOsCompleted(state, action: PayloadAction<IPO[]>) {
      setFundamentalCallStatus(FundamentalCall.getIPOs, CallStatus.READY, state);
      state.IPOs = action.payload;
    },
    getIPOsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getIPOs, CallStatus.ERROR, state, status);
    },
    getSplits(state, action: PayloadAction<{
      symbols: string[],
      startDate: NullableString,
      endDate: NullableString,
    }>) {
      setFundamentalCallStatus(FundamentalCall.getSplits, CallStatus.PENDING, state);
    },
    getSplitsCompleted(state, action: PayloadAction<Split[]>) {
      state.splits = action.payload;
      setFundamentalCallStatus(FundamentalCall.getSplits, CallStatus.READY, state);
    },
    getSplitsFailed(state, action: PayloadAction<ErrorPayloadFull>) {
      const { status } = action.payload;
      setFundamentalCallStatus(FundamentalCall.getDividendPayers, CallStatus.ERROR, state, status);
    },
  },
  extraReducers: {
    [clearErrorsLog.type]: state => { state.errorsLog = []; },
  },
});

export const {
  clearStockSearch,
  searchStockByParams,
  searchStock,
  stockSearchFailed,
  stockSearchCompleted,
  stockSearchReset,
  loadStock,
  getEarnings,
  getEarningsCompleted,
  getEarningsFailed,
  searchEarnings,
  searchEarningsCompleted,
  searchEarningsFailed,
  keyStatsFailed,
  keyStatsCompleted,
  searchCompany,
  searchCompanyCompleted,
  searchCompanyFailed,
  getCompany,
  getCompanyCompleted,
  getCompanyFailed,
  // TODO: Delete getCompanyLogo after merging ART-1918 and use getCompanyLogoAndName for both mobile and web
  getCompanyLogo,
  getCompanyLogoCompleted,
  getCompanyLogoFailed,
  getCompanyLogoAndName,
  getCompanyLogoAndNameCompleted,
  getCompanyLogoAndNameFailed,
  getPopularStocks,
  getPopularStocksCompleted,
  getPopularStocksFailed,
  getNewStocks,
  getNewStocksCompleted,
  getNewStocksFailed,
  getDividendPayers,
  getDividendPayersCompleted,
  getDividendPayersFailed,
  getHighGrowthCompanies,
  getHighGrowthCompaniesCompleted,
  getHighGrowthCompaniesFailed,
  getSectors,
  getSectorsCompleted,
  getSectorsFailed,
  getSectorDetail,
  getSectorDetailCompleted,
  getSectorDetailFailed,
  getETFs,
  getETFsCompleted,
  getETFsFailed,
  getETFDetails,
  getETFDetailsCompleted,
  getETFDetailsFailed,
  setTopGainersAndLosersFilterValues,
  getMarketIndexes,
  getMarketIndexesCompleted,
  getMarketIndexesFailed,
  getTopGainers,
  getTopGainersCompleted,
  getTopGainersFailed,
  getTopLosers,
  getTopLosersCompleted,
  getTopLosersFailed,
  getIPOs,
  getIPOsCompleted,
  getIPOsFailed,
  getSplits,
  getSplitsCompleted,
  getSplitsFailed,
} = fundamentalSlice.actions;

export default fundamentalSlice.reducer;
