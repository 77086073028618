import React, { useCallback, useMemo } from 'react';

import { formatNumber } from '../../../lib/util/DataHelpers';

import { EnrollProgressBarData, EnrollProgressBarPhase } from './ProggressBar.types';

import styles from './ProgressBar.module.scss';

type Props = {
  data: EnrollProgressBarData;
  className: string;
}

const ProgressBar = ({ data, className }: Props): JSX.Element => {
  const { phases, currentPhaseIndex, currentStepIndex } = data;

  const basePercentageChange = useMemo((): number => {
    const currentPhase = phases.find((phase: EnrollProgressBarPhase) => phase.index === currentPhaseIndex);

    if (!currentPhase) {
      return 0;
    }

    return ((1 / currentPhase.steps) * 100);
  }, [currentPhaseIndex, phases]);

  const isCurrentPhase = useCallback((phase: EnrollProgressBarPhase): boolean =>
    (phase.index === currentPhaseIndex), [currentPhaseIndex]);

  const getProgress = useCallback((phase: EnrollProgressBarPhase): string => {
    if (!isCurrentPhase(phase)) {
      return phase.index < currentPhaseIndex ? '100%' : '0';
    }

    const progress = currentStepIndex * basePercentageChange;

    return `${formatNumber(progress)}%`;
  }, [basePercentageChange, currentPhaseIndex, currentStepIndex, isCurrentPhase]);

  const sectionsList = useMemo((): JSX.Element[] => phases.map((phase: EnrollProgressBarPhase) => (
    <li
      key={phase.index}
      className={styles.itemWrapper}
    >
      <p
        className={[
          (isCurrentPhase(phase) || (currentPhaseIndex >= phase.index)) && styles.textDark,
        ].join(' ')}
      >
        {phase.title}
      </p>

      <div className={styles.progressLine}>
        <div className={styles.circle} />

        <div
          style={{ width: getProgress(phase) }}
          className={[
            styles.progressLineInner,
            (getProgress(phase) !== '100%') && styles.inProgress,
            (getProgress(phase) === '100%') && styles.completed,
          ].join(' ')}
        />
      </div>

    </li>
  )), [currentPhaseIndex, getProgress, isCurrentPhase, phases]);

  return <ul className={[styles.wrapper, className].join(' ')}>{sectionsList}</ul>;
};

export default ProgressBar;
