import React from 'react';

import headerLogo from '../../assets/img/hammer_symbol_logo.svg';

type Props = {
    className?: string;
};

const HeaderLogo = ({ className }: Props) => (
  <img src={headerLogo} alt="logo" id="10nLogo" aria-label="10n logo" className={className} />
);

export default HeaderLogo;
