import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { TimeInForceEnum } from '../../../lib/enums/time-in-force.enum';

import disabledIconExpand from '../../../assets/img/icon-expand-disabled.svg';
import iconExpandDown from '../../../assets/img/icon-expand-more-down.svg';
import iconExpandUp from '../../../assets/img/icon-expand-more-up.svg';
import { DropdownOption } from '../../Dropdown/Dropdown.types';
import { ClickableDiv, ClickableImage } from '../../index';

import styles from './DropDown.module.scss';

type Props = {
   options: DropdownOption[],
   value?: string | number,
   handleChange: (option: DropdownOption) => void,
   isPopupMode?: boolean,
   isDisabled: boolean,
   isHalted?: boolean,
}

const DropDown = ({ options, value, isPopupMode, isDisabled, isHalted, handleChange }: Props) => {
  const dropDownDivRef = useRef<HTMLInputElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleSelectItem = (order: DropdownOption) => {
    setIsOpen(!isOpen);
    handleChange(order);
  };

  useEffect(() => {
    const handleClickInside = (event) => {
      if (isHalted) {
        return;
      }
      if (event.target.nodeName === 'IMG' && isOpen) {
        setIsOpen(false);
      } else {
        dropDownDivRef.current && dropDownDivRef.current.contains(event.target)
          ? setIsOpen(true)
          : setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickInside, true);
    return () => document.removeEventListener('click', handleClickInside);
  }, [dropDownDivRef, isHalted, isOpen]);

  return (
    <div ref={dropDownDivRef} className={classNames(styles.wrapperField, { [styles.disabledDropDown]: isHalted })}>
      <div className={classNames(styles.fieldBefore, { [styles.fieldAfter]: isOpen })}>
        <div className={styles.currentOrderType}><span>{value}</span></div>
        <ClickableImage
          alt=""
          src={isHalted ? disabledIconExpand : iconExpandDown}
          className={classNames(styles.iconExpand, {
            [styles.iconExpandSelected]: isOpen,
            [styles.active]: !isHalted,
          })}
        />
      </div>
      {
        isOpen && (
          <div className={isPopupMode ? styles.wrapperPopupContent : styles.wrapperDesktopContent}>
            {
              options.map(order => {
                const { value: optionValue, icon, text, subText } = order;

                return (
                  <ClickableDiv
                    key={optionValue}
                    onClick={() => handleSelectItem(order)}
                    className={order.disabled ? styles.disabledOption : ''}
                  >
                    <div className={value === optionValue
                      ? styles.activeRowOrderType
                      : styles.rowOrderType}
                    >
                      {icon && <img src={icon} alt="order type icon" />}
                      <div className={styles.wrapperContent}>
                        <p className={styles.orderText}>{optionValue === TimeInForceEnum.GTC ? `${text} (${optionValue})` : text}</p>
                        <p className={styles.orderSubText}>{subText}</p>
                      </div>
                    </div>
                  </ClickableDiv>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};

export default DropDown;
