import { CSSProperties } from 'react';

import { PriceChangeDirection } from '../../lib/store-util/TradePriceCache';
import { getPriceColorByDirection } from '../../lib/util/ChartingHelpers';

export const getChangeStyle = (direction: PriceChangeDirection): CSSProperties => ({
  // eslint-disable-next-line no-nested-ternary
  color: getPriceColorByDirection(direction),
  fontSize: '16px',
});
