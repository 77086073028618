import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';

import { CallStatus } from '../../../../lib/enums/call-status.enum';
import lang from '../../../../lib/language';
import { RootState } from '../../../../lib/store';
import { getOwnerTradingAccounts } from '../../../../lib/store/payment';
import { PaymentCall } from '../../../../lib/store/payment/types';

import imageError from '../../../../assets/bigImages/Error_effects_new.png';
import { ButtonTypesEnum } from '../../../../components/Button/Button.types';
import { Button, HeaderLogo, Preloader } from '../../../../components/index';
import { UI_ROUTES } from '../../../../util/routes';

import styles from './ErrorWalletPage.module.scss';

type Props = ReduxProps;

const ErrorWalletPage = (props: Props) => {
  const {
    isGetOwnerTradingAccountsPending,
    isGetOwnerTradingAccountsReady,
    tradingAccounts,
    getOwnerTradingAccountsData,
  } = props;

  const history = useHistory() as any;
  const { isErrorTradingAccounts, isLoadedWalletPageFromDskPage } = history.location.state as {
    isErrorTradingAccounts: boolean,
    isLoadedWalletPageFromDskPage: boolean
  };

  const handleTryAgain = () => {
    isErrorTradingAccounts && getOwnerTradingAccountsData();
  };

  const handleGoToHomePage = () => {
    history.push(UI_ROUTES.overview);
  };

  if (isGetOwnerTradingAccountsReady && tradingAccounts.length > 0) {
    history.goBack();
  }

  return (
    <>
      {isGetOwnerTradingAccountsPending && <div className={styles.loader}><Preloader text="" /></div>}
      <div className={styles.logoImgContainer}>
        <HeaderLogo className={styles.headerImg} />
      </div>
      <div className={styles.wrapperContent}>
        <div className={styles.content}>
          <h3 className={styles.message}>{lang.requestErrorOver500()}</h3>
          <img src={imageError} className={styles.image} alt="error" />
          <Button
            fullWidth
            disabled={isGetOwnerTradingAccountsPending}
            onClick={isLoadedWalletPageFromDskPage ? handleGoToHomePage : handleTryAgain}
          >
            {isLoadedWalletPageFromDskPage ? lang.commonReturnToHomePage() : lang.commonButtonsTryAgain()}
          </Button>
          {
            !isLoadedWalletPageFromDskPage
            && (
            <Button
              id="fundsErrorGoBack"
              variant={ButtonTypesEnum.Tertiary}
              fullWidth
              className={styles.btnGoBack}
              onClick={() => history.go(-2)}
            >
              {lang.commonGoBackBtn()}
            </Button>
            )
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  tradingAccounts: state.payment.tradingAccounts,
  isGetOwnerTradingAccountsPending:
  state.payment.statusByCall[PaymentCall.getOwnerTradingAccounts]?.status === CallStatus.PENDING,
  isGetOwnerTradingAccountsReady:
  state.payment.statusByCall[PaymentCall.getOwnerTradingAccounts]?.status === CallStatus.READY,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getOwnerTradingAccountsData: () => dispatch(getOwnerTradingAccounts()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ErrorWalletPage);
